import { Box, TableCell, TableRow } from "@mui/material";
import { useMasterDataBreadCrumbProvider } from "../../useMasterDataBreadCrumbProvider";
import { useTranslation } from "../../../localization/useTranslation";
import masterIcon from "../../masterData.svg?url";
import DotLegalPaper from "../../../common/components/dotLegalPaper/DotLegalPaper";
import { DotLegalOverflowMenu, DotLegalPageHeader, DotLegalTableWithControls, ITableHeader, useIsOnSmallScreen } from "@dotlegal/dotlegal-ui-components";
import CheckIcon from "@mui/icons-material/Check";
import { RiskVersionViewModel } from "./RiskAssessmentVersions.types";
import useRiskAssessmentVersionsDataMapping from "./RiskAssessmentVersions.hooks";
import RiskAssessmentVersionDialog from "../riskAssessmentVersionDialog/RiskAssessmentVersionDialog";
import { getSettings } from "../../../common/settingsProvider";
import { useRiskAssessmentVersionsStyles } from "./RiskAssessmentVersions.styles";
import { ChangeRiskAssessmentVersionLockedDialog } from "../changeRiskAssessmentVersionLockedDialog/ChangeRiskAssessmentVersionLockedDialog";
import { DeleteRiskAssessmentVersionDialog } from "../deleteRiskAssessmentVersionDialog/DeleteRiskAssessmentVersionDialog";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";

function RiskAssessmentVersions() {
    const { translateString, translateDate, translateRiskAssessmentVersionAreas } = useTranslation();
    const { getBreadCrumbs } = useMasterDataBreadCrumbProvider();
    const { permissions, customerName } = useUserContext();

    const {
        data,
        isLoading,
        selectedVersion,
        setSelectedVersion,
        setShowDialog,
        showDialog,
        onDialogClose,
        setShowChangeLockedStatusDialog,
        showChangeLockedStatusDialog,
        onSave,
        setShowDeleteDialog,
        showDeleteDialog,
        onDelete,
    } = useRiskAssessmentVersionsDataMapping();
    const settings = getSettings();
    const isOnSmallScreen = useIsOnSmallScreen();
    const styles = useRiskAssessmentVersionsStyles();

    const getMenuItems = (row: RiskVersionViewModel) => {
        let menuItems = [];

        if (permissions.riskVersionPermissions.edit) {
            menuItems.push({
                menuItem: translateString("edit"),
                onClick: () => {
                    setSelectedVersion(row);
                    setShowDialog(true);
                },
            });
        }

        if (permissions.riskVersionPermissions.delete) {
            menuItems.push({
                menuItem: translateString("delete"),
                onClick: () => {
                    setSelectedVersion(row);
                    setShowDeleteDialog(true);
                },
            });
        }

        if (permissions.riskVersionPermissions.edit) {
            menuItems.push({
                menuItem: row.isLocked ? translateString("unlock") : translateString("lock"),
                onClick: () => {
                    setSelectedVersion(row);
                    setShowChangeLockedStatusDialog(true);
                },
            });
        }

        return menuItems;
    };

    function getHeaders() {
        let headers = Array<ITableHeader<RiskVersionViewModel>>();
        headers.push({ property: "name", text: translateString("name"), align: "left", showOnMobile: true });
        headers.push({ property: "description", text: translateString("description"), align: "left", showOnMobile: true });
        headers.push({ property: "created", text: translateString("created"), align: "left", showOnMobile: true });
        headers.push({ property: "area", text: translateString("riskArea"), align: "left", showOnMobile: true });
        headers.push({ property: "isLocked", text: translateString("locked"), align: "left", showOnMobile: true });

        return headers;
    }

    return (
        <Box>
            <DotLegalPageHeader userContext={{ customerName }} breadCrumbs={getBreadCrumbs(translateString("riskVersions"))} />
            <DotLegalPaper sx={styles.paperHeader} background={"blueWave"} backgroundCover>
                {!isOnSmallScreen && <Box component={"img"} sx={styles.headerImg} src={`${settings.mainSiteBaseUrl}${masterIcon}`} alt={masterIcon}></Box>}
            </DotLegalPaper>

            <DotLegalPaper>
                <DotLegalTableWithControls
                    headers={getHeaders()}
                    getUserSpecificPageLength={() => 30}
                    hideRowsPerPage
                    clickableRows={false}
                    defaultOrderBy={"created"}
                    defaultOrder={"asc"}
                    isLoading={isLoading}
                    emptyText={translateString("noData")}
                    labelRowsPerPage={translateString("showEntites")}
                    labelSearch={translateString("search")}
                    noOptionsLabel={translateString("noOptions")}
                    paginationLabelOf={translateString("labelOf")}
                    data={data}
                    renderRow={(row) => (
                        <TableRow key={row.id}>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.description}</TableCell>
                            <TableCell>{translateDate(row.created)}</TableCell>
                            <TableCell>{translateRiskAssessmentVersionAreas(row.area.toString())}</TableCell>
                            <TableCell>
                                <Box>{row.isLocked ? <CheckIcon /> : ""}</Box>
                            </TableCell>
                            <TableCell align={"right"}>
                                {permissions.riskVersionPermissions.edit && <DotLegalOverflowMenu items={getMenuItems(row)} />}
                            </TableCell>
                        </TableRow>
                    )}
                />
            </DotLegalPaper>

            {showDialog && (
                <RiskAssessmentVersionDialog
                    onDialogClose={() => onDialogClose()}
                    onVersionSaved={() => {
                        onDialogClose();
                        onSave();
                    }}
                    selectedVersion={selectedVersion}
                    riskAssessmentArea={undefined}
                    riskComplianceArea={undefined}
                />
            )}

            {showChangeLockedStatusDialog && (
                <ChangeRiskAssessmentVersionLockedDialog
                    selectedVersion={selectedVersion!}
                    onCloseDialog={() => {
                        setShowChangeLockedStatusDialog(false);
                        setSelectedVersion(undefined);
                    }}
                    onSave={() => {
                        setShowChangeLockedStatusDialog(false);
                        setSelectedVersion(undefined);
                        onSave();
                    }}
                />
            )}

            {showDeleteDialog && (
                <DeleteRiskAssessmentVersionDialog onCloseDialog={() => setShowDeleteDialog(false)} onDeletion={onDelete} selectedVersion={selectedVersion!} />
            )}
        </Box>
    );
}

export default RiskAssessmentVersions;
