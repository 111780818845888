import { DotLegalHeader, DotLegalDialog } from "@dotlegal/dotlegal-ui-components";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "../../localization/useTranslation";
import { useUrlProvider } from "../../useUrlProvider";
import { SelectableProcessingActivityItem } from "../ProcessingActivity.types";
import { useProcessingActivityOverviewDialogStyles } from "./ProcessingActivityOverviewDialog.styles";
import DotLegalLink from "../../common/components/dotLegalLink/DotLegalLink";

export interface IProcessingActivityOverviewDialog {
    onCloseDialog: () => void;
    processingActivities: Array<SelectableProcessingActivityItem> | undefined;
    numberOfProcessingWithoutaccess?: number | null;
    header: string;
}

function ProcessingActivityOverviewDialog(props: IProcessingActivityOverviewDialog) {
    const { translateString } = useTranslation();
    const { getProcessingActivityOverviewUrl } = useUrlProvider();
    const styles = useProcessingActivityOverviewDialogStyles();

    return (
        <DotLegalDialog
            header={translateString("processingActivities")}
            buttonOkText={""}
            hideOkButton
            open
            onDialogClose={() => props.onCloseDialog()}
            onOkClick={() => {}}
            size="sm"
        >
            <Typography sx={styles.header}>{props.header}</Typography>

            <Box sx={{ marginTop: 3 }}>
                <Box sx={styles.baContainer}>
                    {props.processingActivities?.map((activity) => {
                        return (
                            <DotLegalHeader key={activity.id} headerStyle={"extraSmall"} toolTip={activity.description} fontWeight={400}>
                                <DotLegalLink to={getProcessingActivityOverviewUrl(activity.id)}>
                                    <Box sx={styles.link}>{activity.name}</Box>
                                </DotLegalLink>
                            </DotLegalHeader>
                        );
                    })}

                    {props.numberOfProcessingWithoutaccess && (
                        <DotLegalHeader headerStyle={"extraSmall"} fontWeight={400}>
                            {translateString("noOfProcessingActivitiesWithoutAccess", { count: props.numberOfProcessingWithoutaccess })}
                        </DotLegalHeader>
                    )}
                </Box>
            </Box>
        </DotLegalDialog>
    );
}

export default ProcessingActivityOverviewDialog;
