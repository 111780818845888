import { AnnualWheelActivityType } from "../annualWheel/annualWheelDialog/AnnualWheelDialog.types";

export interface NotificationViewModel {
    id: string;
    taskId?: string;
    description: string;
    deadLine?: Date;
    contentUrl?: string;
    created: Date;
    notificationType: NotificationType;
    annualWheelActivityType?: AnnualWheelActivityType;
    isRead: boolean;
    processingActivityTaskViewModel: Array<ProcessingActivityValidationTaskViewModel>;
    notificationPeriodInDays: number;
    processingActivityName?: string;
    processingActivityId?: string;
    sender?: string;
    fromUser: string | undefined;
}

export interface ProcessingActivityValidationTaskViewModel {
    processingActivityId: string;
    processingActivityName: string;
}

export enum NotificationType {
    Task = 1,
    OnBoardingTask = 2,
    Release = 3,
    LegalUpdates = 4,
    ProcessingActivityCommentType = 5,
    TaskComment = 7,
    TaskAssignee = 8,
    TaskResponsible = 9,
    TaskCompleted = 10,
    TaskReadyForApproval = 11,
}

export const getNotificationHeader = (notificationType: NotificationType, translateString: (key: string, interpolation?: any) => string) => {
    switch (notificationType) {
        case NotificationType.LegalUpdates:
            return translateString("legalUpdatesNotification");
        case NotificationType.Release:
            return translateString("newFunctionsHeader");
        case NotificationType.ProcessingActivityCommentType:
            return translateString("notificationNewCommentNotification");
        case NotificationType.TaskComment:
            return translateString("notificationNewCommentNotification");
        case NotificationType.TaskCompleted:
            return translateString("taskCompleted");
        case NotificationType.TaskReadyForApproval:
            return translateString("taskReadyForApproval");
        default:
            return translateString("taskNotification");
    }
};

export function getNotificationDescription(notification: NotificationViewModel, translateString: any, cardType: string) {
    switch (notification.notificationType) {
        case NotificationType.OnBoardingTask:
            return translateString("onBoardingNotificationDescription");
        case NotificationType.Release:
            return translateString("newFunctionsNotification");
        case NotificationType.ProcessingActivityCommentType:
            return cardType === "expanded"
                ? translateString("notificationNewCommentNotificationDescription", { name: notification.processingActivityName })
                : translateString("notificationNewCommentFullNotificationHeader");
        case NotificationType.TaskComment:
            return cardType === "expanded"
                ? translateString("notificationNewTaskCommentNotificationDescription", { name: notification.description })
                : translateString("notificationNewCommentFullNotificationHeader");
        case NotificationType.TaskAssignee:
            return cardType === "expanded"
                ? translateString("assigneeNotificationNormalDescription", { name: notification.description })
                : translateString("assigneeNotificationLimitedDescription");
        case NotificationType.TaskResponsible:
            return cardType === "expanded"
                ? translateString("taskResponsibleNotificationNormalDescription", { name: notification.description })
                : translateString("taskResponsibleNotificationLimitedDescription");
        default:
            return notification.description;
    }
}
