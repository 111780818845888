import { useState } from "react";
import { useMutation } from "react-query";
import { post, put } from "../../common/api/apiShared";
import { isNullOrWhitespace } from "../../common/stringOperations";
import { useTranslation } from "../../localization/useTranslation";
import { AnnualWheelActivityTemplateSaveModel, AnnualWheelTemplateValidationModel } from "./AnnualWheelActivityTemplate.types";
import { AnnualWheelActivityTemplateItem } from "../annualWheelDialog/AnnualWheelDialog.types";

// TODO THE Skal nok slettes
export function useAnnualWheelActivityTemplateDataMapping(
    onCopyComplete: () => void,
    onTemplateCreated: (id: string, planActivity: boolean, standardActivityCopy?: AnnualWheelActivityTemplateItem) => void
) {
    const [templateModel, setTemplateModel] = useState<AnnualWheelActivityTemplateSaveModel>({
        id: "",
        name: "",
        description: "",
        subTasks: [],
        planActivity: true,
    });
    const { translateString } = useTranslation();
    const [templateValidation, setTemplateValidation] = useState(new AnnualWheelTemplateValidationModel(translateString("fieldMandatory")));
    const [standardActivityCopy, setStandardActivityCopy] = useState<AnnualWheelActivityTemplateItem>();

    const isEdit = !isNullOrWhitespace(templateModel.id);

    const saveTemplate = () => {
        if (isTemplateValid()) {
            if (isEdit) {
                updateTemplate();
            } else {
                createTemplate();
            }
        }
    };

    const createTemplate = async () => {
        await saveMutation.mutateAsync();
    };

    const updateTemplate = async () => {
        await updateMutation.mutateAsync();
    };

    const updateActiveTemplate = async () => {
        await updateActiveTemplateMutation.mutateAsync();
    };

    function save() {
        return post<{}>("/annualWheelActivityTemplate", templateModel);
    }

    function update() {
        return put<{}>(`/annualWheelActivityTemplate/${templateModel.id}/`, templateModel);
    }

    const saveMutation = useMutation(save, {
        onSuccess: (response: any) => {
            const value = response.value();
            onTemplateCreated(value.id, templateModel.planActivity, standardActivityCopy);
            setTemplateModel({ name: "", description: "", subTasks: [], planActivity: true, id: "" });
        },
    });

    const updateMutation = useMutation(update, {
        onSuccess: () => {
            onTemplateCreated(templateModel.id, templateModel.planActivity, standardActivityCopy);
            setTemplateModel({ name: "", description: "", subTasks: [], planActivity: true, id: "" });
        },
    });

    const updateActiveTemplateMutation = useMutation(update, {
        onSuccess: () => {
            setTemplateModel({ name: "", description: "", subTasks: [], planActivity: true, id: "" });
        },
    });

    const copyMutation = useMutation(copy, {
        onSuccess: (response: any) => {
            const value = response.value();
            setTemplateModel(value);
            onCopyComplete();
        },
    });

    const copyTemplate = async (data: { id: string; name: string }) => {
        await copyMutation.mutateAsync(data);
    };

    function copy(data: { id: string; name: string }) {
        return post<{}>(`/annualWheelActivityTemplate/${data.id}/copy`, { name: translateString("copyName", { name: data.name }) });
    }

    function isTemplateValid() {
        let tempValidationModel = new AnnualWheelTemplateValidationModel(translateString("fieldMandatory"));

        tempValidationModel.validateName(templateModel.name);
        setTemplateValidation(tempValidationModel);

        return tempValidationModel.isValid();
    }

    function validateName(name: string) {
        templateValidation.validateName(name);
        setTemplateValidation(templateValidation);
    }

    return {
        templateModel,
        setTemplateModel,
        validateName,
        saveTemplate,
        templateValidation,
        isEdit,
        updateActiveTemplate,
        inProgress: saveMutation.isLoading || updateMutation.isLoading,
        copyTemplate,
        isTemplateValid,
        standardActivityCopy,
        setStandardActivityCopy,
    };
}
