import React from "react";
import { useTranslation } from "../../localization/useTranslation";
import { useAssociationStepStyle } from "./AssociationStep.styles";
import StepSegment from "../stepSegment/StepSegment";
import { DotLegalMultiSelect, DotLegalSelect } from "@dotlegal/dotlegal-ui-components";
import DotLegalStepHeader from "../../common/components/dotLegalStepHeader/DotLegalStepHeader";
import LegalEntityTable from "../../legalEntity/legalEntityTable/LegalEntityTable";
import { useAssociationStepDataMapping } from "./AssociationStep.hooks";
import { ValidationError } from "../../common/validationError";
import BusinessAreaDialog from "../../masterData/businessArea/businessAreaDialog/BusinessAreaDialog";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import DotLegalMultiSelectWithButton from "../../common/components/dotLegalSelectWithButton/DotLegalMultiSelectWithButton";
import { useUrlProvider } from "../../useUrlProvider";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";
import TransferResponsibilityDialog from "./transferResponsibilityDialog/TransferResponsibilityDialog";
import { Box } from "@mui/material";
import { PurpleLockIcon } from "../../common/icons";
import PlanInputFieldWrapper from "../../plan/planInputFieldWrapper/PlanInputFieldWrapper";

export interface IAssociationStepProps {
    owner: string | null;
    processingAreas: Array<string>;
    selectedEntities: Array<string>;
    selectedSharedResponsibles: Array<string>;
    validationErrors: Array<ValidationError>;
    hasDataCreationPermission: boolean;
    onProcessingAreaChange: (areas: Array<string>) => void;
    onOwnerChange: (owner: string | null) => void;
    onLegalEntitiesChange: (entities: Array<string>) => void;
    onSharedResponsiblesChange: (responsibles: Array<string>) => void;
    isReadOnly: boolean;
    processingActivityId: string;
    name?: string;
    hasMultipleGroupCompanies: boolean;
}

function AssociationStep(props: IAssociationStepProps) {
    const { permissions } = useUserContext();
    const styles = useAssociationStepStyle();
    const { translateString } = useTranslation();
    const { dataProtectionFeatures } = usePlanContext();
    const { getUserUrl } = useUrlProvider();

    const {
        data,
        isLoading,
        ownersData,
        areasData,
        areasLoading,
        ownersLoading,
        onSaveBusinessArea,
        searchValue,
        setSearchValue,
        setShowBusinessAreaDialog,
        showBusinessAreaDialog,
        sharedResponsiblesData,
        setTransferDialogContent,
        transferDialogContent,
        updateOwner,
    } = useAssociationStepDataMapping(props);

    const stepPermissions = permissions.processingActivityAssociationPermissions;

    return (
        <React.Fragment>
            <StepSegment size="small" id={"responsible-wrapper"}>
                {stepPermissions.hasOwnershipPermission && (
                    <DotLegalSelect
                        onChange={(user) => updateOwner(user)}
                        label={translateString("owner")}
                        options={ownersData}
                        placeholder={translateString("owner")}
                        selectedItem={props.owner}
                        isLoading={ownersLoading}
                        toolTipText={translateString("ownerHelperText")}
                        disabled={props.isReadOnly}
                        errorText={props.validationErrors.find((x) => x.field === "inActiveUserAffiliation")?.error}
                        link={props.owner && permissions.canManageUsers ? getUserUrl(props.owner!) : undefined}
                        noOptionsLabel={translateString("noOptions")}
                    />
                )}

                {stepPermissions.hasOwnershipPermission && (
                    <PlanInputFieldWrapper hasAccess={dataProtectionFeatures.sharedResponsibility} hoverText={translateString("upgradeDataProtectionPlan")}>
                        <DotLegalMultiSelect
                            isLoading={ownersLoading}
                            options={sharedResponsiblesData}
                            chosenOptions={props.selectedSharedResponsibles}
                            onChange={(responsibles) => props.onSharedResponsiblesChange(responsibles)}
                            placeholder={translateString("sharedResponsibility")}
                            label={translateString("sharedResponsibility")}
                            noOptionsLabel={translateString("noOptions")}
                            disabled={props.isReadOnly || !dataProtectionFeatures.sharedResponsibility}
                            toolTipText={translateString("sharedResponsibilityToolTip")}
                            icon={!dataProtectionFeatures.sharedResponsibility ? PurpleLockIcon : undefined}
                        ></DotLegalMultiSelect>
                    </PlanInputFieldWrapper>
                )}

                {stepPermissions.hasBusinessAreaPermission && (
                    <DotLegalMultiSelectWithButton
                        searchValue={searchValue}
                        onSearchValueChange={setSearchValue}
                        isLoading={areasLoading}
                        options={areasData!}
                        label={translateString("processingActivityAreas")}
                        placeholder={translateString("processingActivityAreas")}
                        onChange={(e) => props.onProcessingAreaChange(e)}
                        chosenOptions={props.processingAreas}
                        toolTipText={translateString("businessAreaHelperText")}
                        disabled={props.isReadOnly}
                        onButtonClick={() => setShowBusinessAreaDialog(true)}
                        buttonLabel={translateString("createNew")}
                        hasPermission={props.hasDataCreationPermission}
                        noOptionsLabel={translateString("noOptions")}
                    />
                )}
            </StepSegment>

            {stepPermissions.hasGroupEntitiesPermission && props.hasMultipleGroupCompanies && (
                <React.Fragment>
                    <Box sx={styles.info}>
                        <DotLegalStepHeader
                            toolTipText={translateString("selectGroupEntitiesHelperText")}
                            record={false}
                            topMargin
                            subHeader={translateString("partOfFlow")}
                            bottomMargin
                        >
                            {translateString("associationStepSelectGroupEntities")}
                        </DotLegalStepHeader>
                    </Box>

                    <StepSegment size="large">
                        <LegalEntityTable
                            errorText={props.validationErrors.find((v) => v.field === "legalEntities")?.error}
                            isLoading={isLoading}
                            legalEntities={data}
                            selectedEntities={props.selectedEntities}
                            showAddNewEntityButton={false}
                            showAddAllEntityButton
                            onLegalEntitiesChange={(entities) => props.onLegalEntitiesChange(entities)}
                            disabled={props.isReadOnly}
                        />
                    </StepSegment>
                </React.Fragment>
            )}
            {showBusinessAreaDialog && (
                <BusinessAreaDialog
                    onHideDialog={() => setShowBusinessAreaDialog(false)}
                    selectedBusinessArea={{ name: searchValue, description: "", id: "", active: true, color: undefined, isCustom: true }}
                    onSave={onSaveBusinessArea}
                />
            )}

            {transferDialogContent.showDialog && transferDialogContent.userFrom && transferDialogContent.userTo && (
                <TransferResponsibilityDialog
                    processingActivityId={props.processingActivityId}
                    toUser={transferDialogContent.userTo}
                    fromUser={transferDialogContent.userFrom}
                    onCloseDialog={() => setTransferDialogContent({ showDialog: false, userFrom: undefined, userTo: undefined })}
                />
            )}
        </React.Fragment>
    );
}

export default AssociationStep;
