import { useState } from "react";

export function useLegalBasisSeletorHooks() {
    const [showAddNewDeletionPeriod, setShowAddNewDeletionPeriod] = useState(false);
    const [typedDeletionPeriod, setTypedDeletionPeriod] = useState("");
    const [showAddNationalLawDialog, setShowAddNationalLawDialog] = useState(false);
    const [typedNationalLaw, setTypedNationalLaw] = useState("");

    return {
        typedDeletionPeriod,
        setTypedDeletionPeriod,
        showAddNewDeletionPeriod,
        setShowAddNewDeletionPeriod,
        showAddNationalLawDialog,
        setShowAddNationalLawDialog,
        typedNationalLaw,
        setTypedNationalLaw,
    };
}
