import { useTranslation } from "../../localization/useTranslation";
import { DotLegalDialog, DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import { Box } from "@mui/material";
import React from "react";
import { useOverviewDialogStyles } from "./IncidentLogOverviewDialog.styles";

export interface IIncidentLogOverviewDataCategoriesDialogProps {
    onCloseDialog: () => void;
    dataCategories: Array<string> | undefined;
}
function IncidentLogOverviewDataCategoriesDialog(props: IIncidentLogOverviewDataCategoriesDialogProps) {
    const { translateString } = useTranslation();
    const styles = useOverviewDialogStyles();

    return (
        <DotLegalDialog
            header={translateString("incidentTableDataCategories")}
            buttonOkText={""}
            hideOkButton
            open
            onDialogClose={() => props.onCloseDialog()}
            onOkClick={() => {}}
            size="sm"
        >
            {props.dataCategories?.map((category) => {
                return (
                    <Box key={category} sx={styles.container}>
                        <DotLegalHeader headerStyle={"extraSmall"} fontWeight={400}>
                            {category}
                        </DotLegalHeader>
                    </Box>
                );
            })}
        </DotLegalDialog>
    );
}

export default IncidentLogOverviewDataCategoriesDialog;
