import React from "react";
import PublicInternalAssessmentContainer from "./PublicInternalAssessmentContainer.tsx";
import { DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation.ts";
import { usePublicInternalAssessmentContainer } from "./PublicInternalAssessmentContainer.hooks.ts";
import { Box } from "@mui/material";

export interface IPublicInternalAssessmentReceiptProps {}

function PublicInternalAssessmentReceipt(props: IPublicInternalAssessmentReceiptProps) {
    const { translateString } = useTranslation();
    const { data } = usePublicInternalAssessmentContainer();
    const mailTo = `mailto:${data?.responsibleEmail}`;
    return (
        <PublicInternalAssessmentContainer>
            <DotLegalHeader headerStyle={"xxxl"}>{translateString("internalAssessmentPublicRegistrationHeader")}</DotLegalHeader>
            <Box sx={{ mt: 2, whiteSpace: "pre-wrap", fontSize: 14, color: "primary.dark" }}>
                {translateString("internalAssessmentPublicRegistrationContent", {
                    responsibleName: data?.responsibleName,
                })}
                <a href={mailTo}>{data?.responsibleEmail}</a>.
            </Box>
        </PublicInternalAssessmentContainer>
    );
}

export default PublicInternalAssessmentReceipt;
