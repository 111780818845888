import { Box, Collapse } from "@mui/material";
import React from "react";
import { DotLegalCheckbox, DotLegalDialog, DotLegalMultiSelect, DotLegalSelect, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { useCreateEditLegalEntityDialogStyle } from "./AddLegalEntityDialog.styles";
import { LevelOfProtectionType } from "./AddLegalEntityDialog.types";
import { useAddEditLegalEntityHook } from "./AddLegalEntityDialog.hooks";

export interface IAddLegalEntityDialog {
    onDialogClose: () => void;
    onEntityCreated: (id: string) => void;
    succesMsg?: string;
    isSimpleDialog: boolean;
}

function AddLegalEntityDialog(props: IAddLegalEntityDialog) {
    const styles = useCreateEditLegalEntityDialogStyle();
    const { translateString } = useTranslation();

    const {
        countriesIsLoading,
        viewModel,
        setViewModel,
        getSelectableCountries,
        onSaveClick,
        saveMutationLoading,
        getLegalEntityTypeOptions,
        isThirdCountry,
        validation,
        showProtectionLevel,
        lvlOfProtectionOptions,
    } = useAddEditLegalEntityHook(props);

    validation?.check(viewModel);

    return (
        <DotLegalDialog
            header={translateString("createCompany")}
            buttonOkText={translateString("create")}
            secondaryButtonText={translateString("cancel")}
            size="md"
            open
            onOkClick={() => {
                onSaveClick();
            }}
            onDialogClose={props.onDialogClose}
            inProgress={saveMutationLoading}
            disableBackdropOnCloseIfValueChange={viewModel}
        >
            <React.Fragment>
                <Box sx={styles.bodyContainer}>
                    <Box sx={styles.bodySubContainer}>
                        <DotLegalTextField
                            label={translateString("name")}
                            value={viewModel.name}
                            onChange={(name) => {
                                const newLegalEntityModel = { ...viewModel! };
                                newLegalEntityModel.name = name;
                                setViewModel(newLegalEntityModel);
                            }}
                            errorText={validation?.getErrorsForDisplay("name")}
                            debounce={false}
                        />

                        <DotLegalTextField
                            label={translateString("address")}
                            value={viewModel.address}
                            onChange={(address) => {
                                const newLegalEntityModel = { ...viewModel! };
                                newLegalEntityModel.address = address;
                                setViewModel(newLegalEntityModel);
                            }}
                            debounce={false}
                        />

                        <DotLegalTextField
                            label={translateString("zipCode")}
                            value={viewModel.zipCode}
                            onChange={(zipCode) => {
                                const newLegalEntityModel = { ...viewModel! };
                                newLegalEntityModel.zipCode = zipCode;
                                setViewModel(newLegalEntityModel);
                            }}
                            debounce={false}
                        />

                        <DotLegalTextField
                            label={translateString("city")}
                            value={viewModel.city}
                            onChange={(city) => {
                                const newLegalEntityModel = { ...viewModel! };
                                newLegalEntityModel.city = city;
                                setViewModel(newLegalEntityModel);
                            }}
                            debounce={false}
                        />
                    </Box>

                    <Box sx={styles.bodySubContainer}>
                        <DotLegalSelect
                            isLoading={countriesIsLoading}
                            label={translateString("country")}
                            selectedItem={viewModel!.country === "" ? null : viewModel!.country}
                            onChange={(country) => {
                                const newLegalEntityModel = { ...viewModel! };
                                newLegalEntityModel.country = country ?? "";
                                newLegalEntityModel.levelOfProtection = null;

                                if (country === null || !isThirdCountry(country)) {
                                    newLegalEntityModel.isTIAUnnecessary = false;
                                    newLegalEntityModel.isTransferBasisUnnecessary = false;
                                }

                                setViewModel(newLegalEntityModel);
                            }}
                            options={getSelectableCountries()}
                            placeholder={translateString("country")}
                            errorText={validation?.getErrorsForDisplay("country")}
                            noOptionsLabel={translateString("noOptions")}
                        />

                        <Collapse in={isThirdCountry(viewModel?.country)}>
                            <React.Fragment>
                                <DotLegalCheckbox
                                    checked={viewModel!.isTIAUnnecessary}
                                    onChange={(isTIAUnnecessary) => {
                                        const newLegalEntityModel = { ...viewModel! };
                                        newLegalEntityModel.isTIAUnnecessary = isTIAUnnecessary;
                                        setViewModel(newLegalEntityModel);
                                    }}
                                    label={translateString("tiaIsUnnecessary")}
                                />

                                <DotLegalCheckbox
                                    checked={viewModel!.isTransferBasisUnnecessary}
                                    onChange={(isUnnecessary) => {
                                        const newLegalEntityModel = { ...viewModel! };
                                        newLegalEntityModel.isTransferBasisUnnecessary = isUnnecessary;
                                        setViewModel(newLegalEntityModel);
                                    }}
                                    label={translateString("transferBasisNotRequired")}
                                />
                            </React.Fragment>
                        </Collapse>

                        <Collapse in={showProtectionLevel}>
                            <DotLegalSelect
                                label={translateString("levelOfProtection")}
                                options={lvlOfProtectionOptions}
                                placeholder={translateString("levelOfProtection")}
                                selectedItem={viewModel!.levelOfProtection === null ? null : LevelOfProtectionType[viewModel!.levelOfProtection]}
                                onChange={(protection) => {
                                    const newLegalEntityModel = { ...viewModel! };
                                    newLegalEntityModel.levelOfProtection =
                                        protection !== null ? LevelOfProtectionType[protection as keyof typeof LevelOfProtectionType] : null;

                                    setViewModel(newLegalEntityModel);
                                }}
                                errorText={validation?.getErrorsForDisplay("levelOfProtection")}
                                noOptionsLabel={translateString("noOptions")}
                            />
                        </Collapse>

                        <DotLegalTextField
                            label={translateString("companyRegistrationNumber")}
                            value={viewModel!.companyRegistrationNumber}
                            onChange={(companyNumber) => {
                                const newLegalEntityModel = { ...viewModel! };
                                newLegalEntityModel.companyRegistrationNumber = companyNumber;
                                setViewModel(newLegalEntityModel);
                            }}
                            debounce={false}
                        />

                        <DotLegalTextField
                            label={translateString("website")}
                            value={viewModel.website}
                            onChange={(website) => {
                                const newLegalEntityModel = { ...viewModel! };
                                newLegalEntityModel.website = website;
                                setViewModel(newLegalEntityModel);
                            }}
                            debounce={false}
                        />

                        <DotLegalMultiSelect
                            options={getLegalEntityTypeOptions()}
                            chosenOptions={viewModel.types.map((x) => x.toString())}
                            onChange={(types) => {
                                const newLegalEntityModel = { ...viewModel! };
                                newLegalEntityModel.types = types.map((type) => Number(type));
                                setViewModel(newLegalEntityModel);
                            }}
                            placeholder={translateString("type")}
                            label={translateString("type")}
                            noOptionsLabel={translateString("noOptions")}
                        />
                        <DotLegalCheckbox
                            onChange={(isApproved) => {
                                const newLegalEntityModel = { ...viewModel! };
                                newLegalEntityModel.isApproved = isApproved;
                                setViewModel(newLegalEntityModel);
                            }}
                            tooltip={translateString("legalEntityApprovedTooltip")}
                            label={translateString("legalEntityApproved")}
                            checked={viewModel.isApproved}
                        />
                    </Box>
                </Box>
            </React.Fragment>
        </DotLegalDialog>
    );
}

export default AddLegalEntityDialog;
