import { useTheme } from "@mui/material";
import { createSxStyles } from "../../createSxStyles";

export const useDotLegalTemplateDialog = () => {
    const theme = useTheme();
    return createSxStyles({
        whiteArrow: {
            "& svg": {
                fill: theme.palette.common.white,
            },
        },
        tabsContainer: {
            "& .MuiTabs-flexContainer": {
                justifyContent: "center",
            },
        },
    });
};
