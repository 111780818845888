import { useTheme } from "@mui/material";
import { createSxStyles } from "../common/createSxStyles";

export const useDataControllerRecordStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        bold: {
            fontWeight: theme.typography.fontWeightBold,
        },
        italic: {
            fontStyle: "italic",
        },
    });
};
