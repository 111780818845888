const DATA_SUBJECTS = "datasubjects";
const PURPOSES = "purposes";
const DELETION_PERIODS = "deletionperiods";
const DATA_PROCESSORS = "dataprocessors";
const LEGAL_BASES = "legalbases";
const DATA_CATEGORIES = "datacategories";

export function parseTemplate(template: string): Array<QuestionChunk> {
    var chunks = template.split("|");

    return chunks.map((x) => {
        return new QuestionChunk(x);
    });
}

export class QuestionChunk {
    text: string;
    type: QuestionChunkType;

    constructor(text: string) {
        this.text = text;
        this.type = getQuestionChunkType(text);
    }
}

export enum QuestionChunkType {
    Text,
    DataSubjects,
    Purposes,
    DeletionPeriods,
    DataProcessors,
    LegalBases,
    DataCategories,
}

function getQuestionChunkType(text: string) {
    switch (text) {
        case DATA_SUBJECTS:
            return QuestionChunkType.DataSubjects;
        case PURPOSES:
            return QuestionChunkType.Purposes;
        case DELETION_PERIODS:
            return QuestionChunkType.DeletionPeriods;
        case DATA_PROCESSORS:
            return QuestionChunkType.DataProcessors;
        case LEGAL_BASES:
            return QuestionChunkType.LegalBases;
        case DATA_CATEGORIES:
            return QuestionChunkType.DataCategories;
        default:
            return QuestionChunkType.Text;
    }
}
