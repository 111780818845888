import { Box, Grid, RadioGroup, Typography } from "@mui/material";
import { useTranslation } from "../../../localization/useTranslation";
import { DotLegalRadioButton } from "@dotlegal/dotlegal-ui-components";

export interface ISystemAIRadioGroupProps {
    isChecked: boolean;
    onCheckedChange: (value: boolean) => void;
    isReadOnly: boolean;
    label: string;
}

function SystemAIRadioGroup(props: ISystemAIRadioGroupProps) {
    const { translateString } = useTranslation();

    return (
        <Grid container sx={(theme) => ({ display: "inline-block", marginTop: theme.spacing(2) })} alignContent="center" justifyContent="center">
            <RadioGroup
                row
                value={props.isChecked}
                onChange={(event) => {
                    const value = (event.target as HTMLInputElement).value === "true";
                    props.onCheckedChange(value);
                }}
            >
                <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                    <Typography
                        sx={(theme) => ({
                            fontSize: theme.typography.pxToRem(14),
                            color: theme.palette.primary.dark,
                        })}
                    >
                        {props.label}
                    </Typography>

                    <DotLegalRadioButton value label={translateString("yes")} disabled={props.isReadOnly} />

                    <DotLegalRadioButton value={false} label={translateString("no")} disabled={props.isReadOnly} />
                </Box>
            </RadioGroup>
        </Grid>
    );
}

export default SystemAIRadioGroup;
