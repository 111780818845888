import React from "react";
import DotLegalDeleteDialogWrapper from "../../common/components/dotLegalDeleteDialog/DotLegalDeleteDialogWrapper";
import { DeleteUserDialogHook } from "./DeleteUserDialog.hooks";

export interface IDeleteUserDialog {
    userId: string;
    onCloseDialog: () => void;
    onSaveCorrect: () => void;
    userName: string;
}

export function DeleteUserDialog(props: IDeleteUserDialog) {
    const { removeUser, inProgess } = DeleteUserDialogHook(props);

    return (
        <DotLegalDeleteDialogWrapper open itemForDeletion={props.userName} onDialogClose={props.onCloseDialog} onOkClick={removeUser} inProgress={inProgess} />
    );
}
