import { LegalEntityType } from "../addLegalEntityDialog/AddLegalEntityDialog.types";

export class LegalEntityManagementModel {
    id: string;
    name: string | undefined;
    address: string | undefined;
    description: string | undefined;
    zipCode: string | undefined;
    city: string | undefined;
    country: string | undefined;
    website: string | undefined;
    companyRegistrationNumber: string | undefined;
    responsible?: string;
    businessAreaIds: Array<string> = [];
    complianceAreaIds: Array<string> = [];
    certificationIds: Array<string> = [];
    types: Array<LegalEntityType> = [];
    isApproved: boolean = false;
    isCustomerOwned: boolean = false;
    auditType?: LegalEntityAuditType;
    auditFrequency?: LegalEntityAuditFrequency;

    constructor() {
        this.id = "";
    }
}

export interface LegalEntityUpdateModel {
    name?: string;
    description?: string;
    address?: string;
    zipCode?: string;
    city?: string;
    country: string;
    companyRegistrationNumber?: string;
    types: Array<LegalEntityType>;
    website?: string;
    responsible?: string;
    businessAreaIds: Array<string>;
    complianceAreaIds: Array<string>;
    certificationIds: Array<string>;
    isApproved: boolean;
    auditType?: LegalEntityAuditType;
    auditFrequency?: LegalEntityAuditFrequency;
}

export enum LegalEntityAuditType {
    AuditNotNecessary = 1,
    WrittenConfirmation = 2,
    Declaration = 3,
    AuditStatement = 4,
    SelfAssessment = 5,
    AuditOrAssessment = 7,
    Other = 6,
}

export enum LegalEntityAuditFrequency {
    Quarterly = 1,
    SemiAnnually = 2,
    Annual = 3,
    EveryTwoYears = 4,
    EveryThreeYears = 5,
}
