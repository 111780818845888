import { Badge, Box, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "../../../localization/useTranslation";
import { NotificationType } from "../../../notification/Notification.types";
import { newCommentIcon, newFunctionalityIcon, newRulesIcon, notificationTaskIcon } from "../../icons";
import { DotLegalHeader, UrlToPathObject } from "@dotlegal/dotlegal-ui-components";
import DotLegalPaper from "../dotLegalPaper/DotLegalPaper";
import { useDotLegalNotificationCard } from "./DotLegalNotificationCard.styles";

export type notificationCardSize = "compact" | "expanded";

export interface IDotLegalNotificationCardProps {
    notificationType: NotificationType;
    size: notificationCardSize;
    header: string;
    description?: string;
    taskDate?: Date;
    taskHref: string;
    taskHrefText: string;
    isRead: boolean;
    onDelete: () => void;
    onClick?: () => void;
    isClicked?: boolean;
    clickable: boolean;
    onReadMoreClick?: () => void;
}

function DotLegalNotificationCard(props: IDotLegalNotificationCardProps) {
    const navigate = useNavigate();
    const { translateDate } = useTranslation();
    const [showDelete, setShowDelete] = useState(false);
    const styles = useDotLegalNotificationCard(props);

    const getIcon = () => {
        switch (props.notificationType) {
            case NotificationType.LegalUpdates:
                return newRulesIcon;
            case NotificationType.Release:
                return newFunctionalityIcon;
            case NotificationType.ProcessingActivityCommentType:
                return newCommentIcon;
            default:
                return notificationTaskIcon;
        }
    };

    function onClick() {
        if (props.onClick && props.size === "expanded") props.onClick();
        else {
            if (props.onReadMoreClick) props.onReadMoreClick();

            // TODO - Skal ændres til at kunne håndtere at åbne new tab
            navigate(props.taskHref);
        }
    }

    const getTaskDate = () => {
        if (props.taskDate === undefined || props.taskDate === null) return "";

        return translateDate(props.taskDate);
    };

    return (
        <DotLegalPaper
            onClick={onClick}
            sx={styles.paper}
            onMouseEnter={() => setShowDelete(true)}
            onMouseLeave={() => setShowDelete(false)}
            clickableRows={props.clickable}
        >
            <Box sx={styles.container}>
                <Box sx={styles.columnWrapper}>
                    <div>{!props.isRead ? <Badge variant="dot" sx={styles.badge} /> : <Box component={"span"} sx={styles.styleCorrection}></Box>}</div>

                    <DotLegalHeader marginBottom={0} color="secondary" headerStyle="small">
                        {props.header}
                    </DotLegalHeader>
                </Box>

                <Box sx={styles.columnWrapper}>
                    {props.size === "compact" && <Box sx={styles.dateImage}>{getIcon()} </Box>}
                    <Typography sx={styles.date}>{getTaskDate()}</Typography>

                    {props.size === "expanded" && (
                        <Box sx={styles.deleteWrapper}>
                            {showDelete && (
                                <IconButton aria-label="close" onClick={props.onDelete}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12.828" height="12.828" viewBox="0 0 14.828 14.828">
                                        <g id="Group_967" data-name="Group 967" transform="translate(-1108.086 -210.086)">
                                            <line
                                                id="Line_169"
                                                data-name="Line 169"
                                                x2="12"
                                                y2="12"
                                                transform="translate(1109.5 211.5)"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeWidth="2"
                                            />
                                            <line
                                                id="Line_170"
                                                data-name="Line 170"
                                                x1="12"
                                                y2="12"
                                                transform="translate(1109.5 211.5)"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeWidth="2"
                                            />
                                        </g>
                                    </svg>
                                </IconButton>
                            )}
                        </Box>
                    )}
                </Box>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ ml: "10px", mr: 1 }}>
                    <DotLegalHeader marginBottom={0} fontWeight={400} headerStyle="small">
                        {props.description}
                    </DotLegalHeader>
                </Box>
                {props.size === "compact" ? (
                    <Box
                        component={Link}
                        onClick={() => (props.onReadMoreClick ? props.onReadMoreClick() : {})}
                        sx={styles.link}
                        to={UrlToPathObject(props.taskHref)}
                    >
                        {props.taskHrefText}
                    </Box>
                ) : (
                    <Box sx={{ mr: 2, alignSelf: "flex-end" }}>{getIcon()}</Box>
                )}
            </Box>
        </DotLegalPaper>
    );
}

export default DotLegalNotificationCard;
