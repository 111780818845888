import { useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";
import { INotificationEmptyState } from "./NotificationEmptyState";

export const useNotificationEmptyStateStyles = ({ isPopOver }: INotificationEmptyState) => {
    const theme = useTheme();
    return createSxStyles({
        emptyState: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "100%",
        },
        emptyStateText: {
            color: theme.palette.grey.A400,
            marginTop: theme.spacing(1),
            fontWeight: theme.typography.fontWeightBold,
            fontSize: theme.typography.pxToRem(12),
            textTransform: "uppercase",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
        },
        imgContainer: {
            display: "flex",
            justifyContent: "center",
            "& svg": {
                width: isPopOver ? "80%" : "auto",
            },
        },
    });
};
