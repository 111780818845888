import React from "react";
import { useTranslation } from "../../../localization/useTranslation.ts";
import { DotLegalInfoDialog } from "@dotlegal/dotlegal-ui-components";
import { Trans } from "react-i18next";
import { Box } from "@mui/material";
import { useCompleteDeclarationControlDialog } from "./CompleteDeclarationControlDialog.hooks.ts";

export interface ICompleteDeclarationControlDialogProps {
    controlId: string;
    isCompleted: boolean;
    controlName: string;
    onDialogClose: () => void;
    onCompletionChanged: () => void;
}

function CompleteDeclarationControlDialog(props: ICompleteDeclarationControlDialogProps) {
    const { translateString } = useTranslation();
    const { isSaving, changeCompletionForControl } = useCompleteDeclarationControlDialog(props);
    return (
        <DotLegalInfoDialog
            header={props.isCompleted ? translateString("cancelCompletion") : translateString("completeControl")}
            btnText={props.isCompleted ? translateString("cancel") : translateString("complete")}
            content={
                <Trans
                    i18nKey={props.isCompleted ? "cancelCompletionDeclarationControlInfo" : "completeDeclarationControlInfo"}
                    values={{
                        name: props.controlName,
                    }}
                    components={{
                        wrapper: (
                            <Box sx={(theme) => ({ color: theme.palette.secondary.main, fontWeight: theme.typography.fontWeightBold })} component="span"></Box>
                        ),
                    }}
                />
            }
            onCloseDialog={props.onDialogClose}
            onBtnClick={() => changeCompletionForControl(!props.isCompleted)}
            inProgress={isSaving}
        />
    );
}

export default CompleteDeclarationControlDialog;
