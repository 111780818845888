import { DotLegalDialog, DotLegalRadioButton, DotLegalSelect, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { Collapse, Grid, RadioGroup } from "@mui/material";
import { Box } from "@mui/system";
import { _arrayBufferToBase64 } from "../../../common/arrayBufferToBase64";
import DotLegalFileUploadWrapper from "../../../common/components/dotLegalFileUpload/DotLegalFileUploadWrapper";
import { isNullOrWhitespace } from "../../../common/stringOperations";
import { useTranslation } from "../../../localization/useTranslation";
import { useAgreementDialogHooks } from "./DataProcessorAgreementDialog.hooks";
import { DataProcessorAgreementSaveModel } from "./DataProcessorAgreementDialog.types";
import { StepTypeEnum } from "../../ProcessingActivity.types";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import GroupEntityAcceesSelector from "../../../legalEntity/groupEntityAccessSelector/GroupEntityAccessSelector";

export interface IAgreementDialog {
    onDialogClose: () => void;
    onSave: (agreementId: string) => void;
    saveModel: DataProcessorAgreementSaveModel;
    stepType: StepTypeEnum;
}

function AgreementDialog(props: IAgreementDialog) {
    const { translateString } = useTranslation();
    const {
        inProgress,
        viewModel,
        documentTypes,
        documentStatus,
        setViewModel,
        documentationOption,
        onDocumentationTypeChange,
        validation,
        createAgreement,
        onGroupEntityAccessIdsChange,
    } = useAgreementDialogHooks(props);

    const { permissions } = useUserContext();

    validation?.check(viewModel);
    return (
        <DotLegalDialog
            header={translateString("createAgreement")}
            size="md"
            inProgress={inProgress}
            buttonOkText={translateString("save")}
            open
            onDialogClose={() => props.onDialogClose()}
            onOkClick={() => createAgreement()}
            disableBackdropOnCloseIfValueChange={viewModel}
        >
            <Grid container columnSpacing={2}>
                <Grid item md={12}>
                    <DotLegalTextField
                        label={translateString("name")}
                        value={viewModel?.name ?? ""}
                        debounce={false}
                        onChange={(text) => {
                            let tempModel = { ...viewModel! };
                            tempModel.name = text;
                            setViewModel(tempModel);
                        }}
                        errorText={validation?.getErrorsForDisplay("name")}
                    />
                </Grid>
                <Grid item md={12}>
                    <DotLegalSelect
                        selectedItem={viewModel?.type ? viewModel?.type.toString() : undefined}
                        options={documentTypes}
                        placeholder={translateString("type")}
                        label={translateString("type")}
                        disableClearable
                        noOptionsLabel={translateString("noOptions")}
                        onChange={(type) => {
                            let tempModel = { ...viewModel! };
                            tempModel.type = Number(type);
                            setViewModel(tempModel);
                        }}
                        errorText={validation?.getErrorsForDisplay("type")}
                    />
                </Grid>

                <Grid item md={12}>
                    <DotLegalSelect
                        selectedItem={viewModel?.status ? viewModel?.status.toString() : undefined}
                        disabled={!permissions.canSetDocumentStatus}
                        options={documentStatus}
                        placeholder={translateString("status")}
                        label={translateString("status")}
                        disableClearable
                        errorText={validation?.getErrorsForDisplay("status")}
                        noOptionsLabel={translateString("noOptions")}
                        onChange={(status) => {
                            let tempModel = { ...viewModel! };
                            tempModel.status = Number(status);
                            setViewModel(tempModel);
                        }}
                    />
                </Grid>
            </Grid>

            <Box marginTop={2}>
                <RadioGroup
                    row
                    value={documentationOption}
                    onChange={(e) => {
                        onDocumentationTypeChange(Number((e.target as HTMLInputElement).value));
                    }}
                >
                    <DotLegalRadioButton value={1} labelPlacement="end" label={translateString("uploadDocument")} />
                    <DotLegalRadioButton value={0} labelPlacement="end" label={translateString("insertLink")} />
                </RadioGroup>

                <Collapse in={documentationOption === 1}>
                    <Box sx={{ height: 70 }}>
                        <DotLegalFileUploadWrapper
                            errorText={validation?.getErrorsForDisplay("fileName")}
                            files={!isNullOrWhitespace(viewModel?.fileName) ? [viewModel?.fileName!] : []}
                            onFilesChanged={async (file) => {
                                let tempDoc = { ...viewModel! };
                                tempDoc.base64Data = file ? await _arrayBufferToBase64(file[0].data) : "";
                                tempDoc.fileName = file ? file[0].fileName : "";

                                if (isNullOrWhitespace(tempDoc.name)) {
                                    tempDoc.name = tempDoc.fileName.substring(0, tempDoc.fileName.lastIndexOf("."));
                                }

                                setViewModel(tempDoc);
                            }}
                        />
                    </Box>
                </Collapse>
                <Collapse in={documentationOption === 0}>
                    <Box sx={{ height: 70, "& > *": { marginTop: 0 } }}>
                        <DotLegalTextField
                            label={translateString("insertLink")}
                            value={viewModel?.link ?? ""}
                            debounce={false}
                            errorText={validation?.getErrorsForDisplay("link")}
                            onChange={(value) => {
                                let tempDoc = { ...viewModel! };
                                tempDoc.link = value;
                                setViewModel(tempDoc);
                            }}
                        ></DotLegalTextField>
                    </Box>
                </Collapse>
            </Box>
            {!props.saveModel.isGroupEntity && (
                <GroupEntityAcceesSelector
                    errorMsg={validation?.getErrorsForDisplay("groupEntityAccessIds")}
                    onGroupEntityAccessIdsChange={onGroupEntityAccessIdsChange}
                    groupEntityAccessIds={viewModel?.groupEntityAccessIds}
                    allGroupEntities={viewModel?.allGroupEntities}
                    isEdit={false}
                />
            )}
        </DotLegalDialog>
    );
}

export default AgreementDialog;
