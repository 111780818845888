import React from "react";
import { DotLegalHeader, DotLegalOverflowMenu, DotLegalTooltip } from "@dotlegal/dotlegal-ui-components";
import DotLegalPaper from "../../common/components/dotLegalPaper/DotLegalPaper";
import { useTranslation } from "../../localization/useTranslation";
import { pinnedCommentIcon, unPinnedCommentIcon } from "../../common/icons";
import { Box, Paper } from "@mui/material";
import { useCommentCardStyle } from "./CommentCard.styles";
import { useComment } from "./CommentCard.hooks";
import { CommentViewModel } from "./CommentCard.types";

export interface CommentProps {
    comment: CommentViewModel;
    onDeleteClick: () => void;
    disableOverFlowMenu: boolean;
    onReplyClick?: () => void;
    onEditClick: () => void;
    isSubComment: boolean;
    hasSubComments: boolean;
    onPinClick?: (isPinned: boolean) => void;
}

function CommentCard(props: CommentProps) {
    const { translateString, translateStatus } = useTranslation();
    const isPinned = props.comment.pinned;
    const { hoveredComment, setHoveredComment } = useComment();
    const styles = useCommentCardStyle();

    function onReplyClick() {
        if (props.onReplyClick) {
            props.onReplyClick();
        }
    }

    const getMenuItems = () => {
        let menuItems = [];

        if (props.comment.isOwner) {
            menuItems.push({
                menuItem: translateString("edit"),
                onClick: () => props.onEditClick(),
            });
        }

        if (props.onReplyClick) {
            menuItems.push({
                menuItem: translateString("reply"),
                onClick: () => onReplyClick(),
            });
        }

        if (props.comment.isOwner && !props.hasSubComments) {
            menuItems.push({
                menuItem: translateString("delete"),
                onClick: () => props.onDeleteClick(),
            });
        }

        return menuItems;
    };

    function showOverFlowMenu() {
        return hoveredComment === props.comment.id && (props.comment.isOwner || !props.isSubComment) && !props.disableOverFlowMenu;
    }

    const getCommentHeader = () => {
        const pinIcon = (
            <DotLegalTooltip text={translateString("pinThisComment")}>
                <Box sx={{ display: "flex" }}>{isPinned ? pinnedCommentIcon : unPinnedCommentIcon}</Box>
            </DotLegalTooltip>
        );
        return (
            <Box sx={styles.headerContanier}>
                <Box sx={{ marginRight: 3 }}>
                    <DotLegalHeader marginBottom={0} headerStyle="extraSmall">
                        {/* {props.comment.userFullName ? props.comment.userFullName : translateString("deletedUser")} */}
                        {props.comment.title}
                    </DotLegalHeader>
                </Box>
                <DotLegalHeader marginBottom={0} fontWeight={500} color="lightgrey" headerStyle="extraSmall">
                    <React.Fragment>
                        {/* {translateDate(props.comment.created)} {props.comment.isEdited ? `(${translateString("edited").toLowerCase()})` : ""} */}
                        {props.comment.date}
                    </React.Fragment>
                </DotLegalHeader>

                {props.onPinClick && (
                    <Box
                        onClick={() => {
                            props.onPinClick!(!isPinned);
                        }}
                        sx={{ marginLeft: 1, display: "flex", cursor: props.onPinClick! ? "pointer" : "default" }}
                    >
                        {pinIcon}
                    </Box>
                )}
            </Box>
        );
    };

    const getCommentContent = () => {
        return (
            <Box sx={styles.paperContainer} onMouseEnter={() => setHoveredComment(props.comment.id)} onMouseLeave={() => setHoveredComment(undefined)}>
                <Box>
                    {getCommentHeader()}
                    <Box sx={styles.content}>{props.comment.content}</Box>
                </Box>
                <Box sx={styles.overflowMenu}>
                    <Box
                        sx={(theme) => ({
                            visibility: showOverFlowMenu() ? "visible" : "hidden",
                            "& .MuiButtonBase-root": {
                                marginTop: -1,
                                marginBottom: -1,
                            },
                            "& svg": { fill: theme.palette.primary.main },
                        })}
                    >
                        <DotLegalOverflowMenu items={getMenuItems()}></DotLegalOverflowMenu>
                    </Box>
                </Box>
            </Box>
        );
    };

    const getStatusUpdateContent = () => {
        return (
            <DotLegalPaper removePadding backgroundColor="lightBlue">
                <Box sx={{ fontStyle: "italic" }}>
                    <Box sx={[styles.content, { padding: 1 }]}>
                        {translateString("processingActivityStatusUpdate", {
                            previousStatus: translateStatus(props.comment.previousStatus!.toString()),
                            currentStatus: translateStatus(props.comment.currentStatus!.toString()),
                        })}
                    </Box>
                </Box>
            </DotLegalPaper>
        );
    };

    const getStatusUpdate = () => {
        return (
            <React.Fragment>
                {getCommentHeader()}
                {getStatusUpdateContent()}
            </React.Fragment>
        );
    };

    const getSubComment = () => {
        return (
            <Box key={props.comment.id} sx={[styles.commentHover, { display: "flex", marginLeft: 1, marginTop: "-6px" }]}>
                <Paper sx={(theme) => ({ backgroundColor: theme.palette.primary.main, width: 4, marginRight: "4px", boxShadow: 0 })} />
                <Box marginLeft={0.5} sx={{ flex: 1 }}>
                    <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                        <Box sx={{ width: "100%" }}>{getCommentContent()}</Box>
                    </Box>
                </Box>
            </Box>
        );
    };

    const getMainComment = () => {
        return <Box sx={[isPinned ? styles.pinnedComment : {}, styles.commentHover]}>{getCommentContent()}</Box>;
    };

    const getComment = () => {
        if (props.comment.isStatusChange) {
            return getStatusUpdate();
        }

        if (props.isSubComment) {
            return getSubComment();
        }

        return getMainComment();
    };

    return (
        <React.Fragment>
            <Box marginBottom={"8px"} sx={{ width: "100%" }} key={props.comment.id}>
                {getComment()}
            </Box>
        </React.Fragment>
    );
}

export default CommentCard;
