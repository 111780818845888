import { createElement, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { get } from "../../common/api/apiShared";
import { DotLegalSelectOption } from "../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { useStateUrlParamsArray } from "../../common/hooks/useStateUrlParams";
import { isNullOrWhitespace } from "../../common/stringOperations";
import { useTranslation } from "../../localization/useTranslation";
import {
    riskAreaAndComplianceAreaLocalStorageModel,
    RiskAssessmentArea,
    RiskAssessmentRowViewModel,
    RiskAssessmentViewModel,
    RiskComplianceArea,
} from "./RiskAssessments.types";
import { useLocation, useNavigate } from "react-router-dom";
import { downloadFile } from "../../common/api/downloadFile";
import { getSettings } from "../../common/settingsProvider";
import { useUrlProvider } from "../../useUrlProvider";
import { BreadCrumbsType, useDotLegalSnackbar, useStateLocalStorage, useStateSessionStorage } from "@dotlegal/dotlegal-ui-components";
import { SelectableItem } from "../../processingActivity/ProcessingActivity.types";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { createPdfUrl } from "../../common/pdfUrlHelper";
import { useSetPdfPageSize } from "../../common/pdfPageSizeHelper";
import { Trans } from "react-i18next";
import { SelectableRiskAssessmentVersionViewModel } from "../../masterData/riskAssessmentVersion/riskAssessmentVersions/RiskAssessmentVersions.types";

export const riskAreaAndComplianceAreaKeyStorageKey = "riskComplianceArea";
export function getRiskName(score: number | null, translateString: (key: string, interpolation?: any) => string, isClassification?: boolean) {
    switch (score) {
        case 1: {
            return translateString("veryLow");
        }
        case 2: {
            return translateString("low");
        }
        case 3: {
            return translateString("moderate");
        }
        case 4: {
            return translateString("high");
        }
        case 5: {
            return translateString("veryHigh");
        }
        default: {
            return isClassification ? translateString("noData") : translateString("notAnswered");
        }
    }
}

export function useRiskAssessmentsDataMapping() {
    useSetPdfPageSize("landscape");
    const { getRiskAssessmentsUrl, getRiskAssessmentsPDF } = useUrlProvider();
    const { translateString } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const snackBar = useDotLegalSnackbar();
    const { language, permissions, customerId } = useUserContext();

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const isPdf = Boolean(urlParams.get("isPdf"));
    const pdfOnlyUserSpecific = urlParams.get("riskassessmenOnlyUserSpecific");
    const pdfRiskArea = Number(urlParams.get("riskArea"));
    const pdfComplianceArea = Number(urlParams.get("complianceArea"));
    const pdfVersionId = urlParams.get("versionId");

    const questionsScoreUrlKey = "questionsScore";
    const scenarioScoreUrlKey = "scenariosScore";
    const businessAreaUrlKey = "businessAreas";

    const [onlyUserSpecific, setOnlyUserSpecific] = useStateSessionStorage(
        "riskassessmenOnlyUserSpecific",
        isPdf ? pdfOnlyUserSpecific == "true" : !permissions.canAccessAllData
    );
    const [riskAreaAndComplianceArea, setRiskAreaAndComplianceArea] = useStateLocalStorage<riskAreaAndComplianceAreaLocalStorageModel>(
        riskAreaAndComplianceAreaKeyStorageKey,
        {
            riskArea: isPdf ? pdfRiskArea : RiskAssessmentArea.ProcessingActivities,
            complianceArea: isPdf ? pdfComplianceArea : RiskComplianceArea.GDPR,
            versionId: isPdf ? pdfVersionId : null,
        }
    );
    const [searchedQuestionsScore, setSearchedQuestionsScore] = useStateUrlParamsArray(questionsScoreUrlKey, []);
    const [searchedScenariosScore, setSearchedScenariosScore] = useStateUrlParamsArray(scenarioScoreUrlKey, []);
    const [searchedBusinessAreas, setSearchedBusinessAreas] = useStateUrlParamsArray(businessAreaUrlKey, []);
    const [showCreateVersion, setShowCreateVersion] = useState(false);

    let riskAssessmentArea: RiskAssessmentArea = riskAreaAndComplianceArea.riskArea!;
    let riskComplianceArea: RiskComplianceArea = riskAreaAndComplianceArea.complianceArea!;

    const [searchedOwner, setSearchedOwner] = useStateUrlParamsArray("owner", []);
    const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);
    const [gridClicked, setGridClicked] = useState(false);

    const setSearchParams = (riskAssessmentArea: number | null, riskComplianceArea: number | null, versionId: string | null) => {
        setRiskAreaAndComplianceArea({
            riskArea: riskAssessmentArea ?? RiskAssessmentArea.ProcessingActivities,
            complianceArea: riskComplianceArea ?? RiskComplianceArea.GDPR,
            versionId: versionId,
        });
    };

    let versionsQueryUrl = `/riskassesmentversions/getAllOrCreate?riskAssessmentArea=${riskAssessmentArea}&riskComplianceArea=${riskComplianceArea}`;
    let versionsQuery = useQuery(versionsQueryUrl + customerId, () => get<Array<SelectableRiskAssessmentVersionViewModel>>(versionsQueryUrl));

    let currentVersion = getCurrentVersion(versionsQuery.data);
    useEffect(() => {
        if (versionsQuery.data && (riskAreaAndComplianceArea.versionId === null || riskAreaAndComplianceArea.versionId !== currentVersion)) {
            setRiskAreaAndComplianceArea({
                riskArea: riskAssessmentArea ?? RiskAssessmentArea.ProcessingActivities,
                complianceArea: riskComplianceArea ?? RiskComplianceArea.GDPR,
                versionId: currentVersion!,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [versionsQuery.data]);

    let queryPart = `?onlyUserSpecific=${onlyUserSpecific}&complianceArea=${riskComplianceArea}&versionId=${currentVersion}`;
    let url =
        riskAssessmentArea === RiskAssessmentArea.ProcessingActivities
            ? `/ProcessingActivityRiskOverview${queryPart}`
            : `/SystemRiskAssessmentOverview${queryPart}`;

    let enableRiskOverviewQuery = permissions.riskPermissions.read && currentVersion !== undefined;
    let { isLoading, data } = useQuery(url, () => get<RiskAssessmentViewModel>(url), {
        enabled: enableRiskOverviewQuery,
    });
    let isProcessingActivityRiskAssessment = riskAssessmentArea === RiskAssessmentArea.ProcessingActivities;

    const getBreadCrumbs = () => {
        let breadCrumbs: BreadCrumbsType = [];

        breadCrumbs.push({ name: `${translateString("risk")} ${isPdf ? `- ${versionsQuery.data?.find((x) => x.id === currentVersion)?.name}` : ""}` });

        return breadCrumbs;
    };

    function GetQuestionScoreByRiskCoordinates(x: number, y: number) {
        var combinedValues = x + y;

        if (combinedValues >= 9) return 5;
        else if (combinedValues >= 7) return 4;
        else if (combinedValues === 6) return 3;
        else if (combinedValues >= 4) return 2;
        else return 1;
    }

    const handleMatrixClick = (xCord: number | null, yCord: number | null) => {
        const isNotNull = xCord !== null && yCord !== null;
        setGridClicked(isNotNull);

        const urlSearchParams = new URLSearchParams(location.search);
        if (isNotNull) {
            var score = GetQuestionScoreByRiskCoordinates(xCord, yCord);
            urlSearchParams.set(questionsScoreUrlKey, score.toString());
        } else {
            urlSearchParams.delete(questionsScoreUrlKey);
        }

        navigate({
            pathname: location.pathname,
            search: urlSearchParams.toString(),
        });
    };

    let questionsScoreItems: Array<DotLegalSelectOption> = [];
    let scenarioScoreItems: Array<DotLegalSelectOption> = [];

    function createRiskSelectOptions(number: number | null, items: Array<DotLegalSelectOption>, isClassification?: boolean) {
        var value = String(null);

        if (number !== null) value = number?.toString();

        if (!items.some((x) => x.id === value)) items.push({ id: value, name: getRiskName(number, translateString, isClassification) });
    }

    const getFilteredRows = (overviewRows: Array<RiskAssessmentRowViewModel>) => {
        let tempData = [...overviewRows];

        if (searchedQuestionsScore.length > 0 && !isNullOrWhitespace(searchedQuestionsScore[0])) {
            tempData = tempData.filter((row) => {
                if (row.totalScore === null) return searchedQuestionsScore.includes("null");

                return searchedQuestionsScore.includes(row.totalScore!.toString());
            });
        }

        if (searchedScenariosScore.length > 0 && !isNullOrWhitespace(searchedScenariosScore[0])) {
            tempData = tempData.filter((row) => {
                if (row.scenariosScore === null) return searchedScenariosScore.includes("null");

                return searchedScenariosScore.includes(row.scenariosScore!.toString());
            });
        }

        if (searchedOwner.length > 0 && !isNullOrWhitespace(searchedOwner[0])) {
            tempData = tempData.filter((row) => {
                let owner = data?.users.find((x) => x.id === row.ownerId);
                if (!owner) return searchedOwner.includes("null");

                return searchedOwner.includes(owner!.id);
            });
        }

        if (searchedBusinessAreas.length > 0 && searchedBusinessAreas[0] !== "") {
            tempData = tempData.filter((row) => row.businessAreas.filter((x) => searchedBusinessAreas.indexOf(x.id) >= 0).length > 0);
        }

        return tempData;
    };

    let riskData;
    let ownerFilterData: Array<DotLegalSelectOption> = [];
    let businessAreaFilterItems: Array<DotLegalSelectOption> = [];
    let overallStatus = 0;
    if (data) {
        riskData = { ...data };

        data.rows.forEach((x) => {
            createRiskSelectOptions(x.totalScore, questionsScoreItems);
            createRiskSelectOptions(x.scenariosScore, scenarioScoreItems, true);
            createBusinessAreaSelectOptions(x.businessAreas);
        });
        createOwnerSelectOptions(data.users);

        sortSelectOptions();

        riskData = getFilteredRows(data.rows);

        setOverallStatus(riskData);
    }

    function setOverallStatus(rows: Array<RiskAssessmentRowViewModel>) {
        let tempOverallStatus = 0;
        rows.forEach((row) => {
            tempOverallStatus += row.status;
        });

        if (Math.floor(tempOverallStatus / riskData.length)) {
            overallStatus = Math.floor(tempOverallStatus / riskData.length);
        }
    }

    function createBusinessAreaSelectOptions(businessAreas: SelectableItem[]) {
        businessAreas.forEach((b) => {
            if (!businessAreaFilterItems.some((x) => x.id === b.id)) {
                var businessArea = data!.businessAreas.find((x) => x.id === b.id);

                if (businessArea) businessAreaFilterItems.push(businessArea);
            }
        });
    }

    function createOwnerSelectOptions(owners: Array<SelectableItem>) {
        data?.rows.forEach((d) => {
            if (!ownerFilterData.some((x) => x.id === d.ownerId)) {
                var owner = data!.users.find((x) => x.id === d.ownerId);
                if (owner) ownerFilterData.push({ name: owner.name, id: owner.id });
            }
        });
    }

    function sortSelectOptions() {
        businessAreaFilterItems.sort((a, b) => (a.name > b.name ? 1 : -1));
        questionsScoreItems.sort((a, b) => (a.id > b.id ? 1 : -1));
        scenarioScoreItems.sort((a, b) => (a.id > b.id ? 1 : -1));
        ownerFilterData.sort((a, b) => (a.name > b.name ? 1 : -1));
    }

    async function onDownloadPdfClick() {
        const fileName = "risk-overview";
        setIsDownloadingPdf(true);
        let queryUrl =
            getSettings().mainSiteBaseUrl +
            getRiskAssessmentsUrl() +
            `?isPdf=true&riskArea=${riskAssessmentArea}&complianceArea=${riskComplianceArea}&versionId=${currentVersion!}&businessAreas=${searchedBusinessAreas}&owner=${searchedOwner}&questionsScore=${searchedQuestionsScore}&scenariosScore=${searchedScenariosScore}&riskassessmenOnlyUserSpecific=${onlyUserSpecific}`;
        await downloadFile(getSettings().apiBaseUrl + "/pdf/generate?url=" + createPdfUrl(queryUrl, language), `${fileName}.pdf`).then((x) => {
            snackBar.show(createElement(Trans, { i18nKey: "pdfDownloadComplete", values: { fileName: fileName } }));
            setIsDownloadingPdf(false);
        });
    }

    async function onDownloadPDFForAssessmentRisk(id: string, name: string) {
        snackBar.show(translateString("exporting"), "info", true);
        const fileName = `${name} - risk`;
        const riskDialogUrl = getRiskAssessmentsPDF(id, riskAssessmentArea, riskComplianceArea, currentVersion!);
        let queryUrl = getSettings().mainSiteBaseUrl + riskDialogUrl;
        await downloadFile(getSettings().apiBaseUrl + "/pdf/generate?url=" + createPdfUrl(queryUrl, language), `${fileName}.pdf`).then((x) => {
            snackBar.show(createElement(Trans, { i18nKey: "pdfDownloadComplete", values: { fileName: fileName } }));
        });
    }

    return {
        getBreadCrumbs,
        isLoading: isLoading || versionsQuery.isLoading || !enableRiskOverviewQuery,
        riskData,
        allData: data,
        versions: versionsQuery.data,
        questionsScoreItems,
        gridClicked,
        setGridClicked,
        searchedQuestionsScore,
        setSearchedQuestionsScore,
        handleMatrixClick,
        onlyUserSpecific,
        setOnlyUserSpecific,
        ownerFilterData,
        searchedOwner,
        setSearchedOwner,
        searchedScenariosScore,
        setSearchedScenariosScore,
        scenarioScoreItems,
        onDownloadPdfClick,
        isDownloadingPdf,
        isPdf,
        businessAreaFilterItems,
        searchedBusinessAreas,
        setSearchedBusinessAreas,
        onDownloadPDFForAssessmentRisk,
        overallStatus,
        selectedRiskArea: riskAssessmentArea,
        isProcessingActivityRiskAssessment,
        selectedComplianceArea: riskComplianceArea,
        riskAssessmentArea,
        riskComplianceArea,
        showCreateVersion,
        setShowCreateVersion,
        selectedVersion: currentVersion,
        refetchVersions: versionsQuery.refetch,
        setSearchParams,
    };

    function getCurrentVersion(data: Array<SelectableRiskAssessmentVersionViewModel> | undefined) {
        if (riskAreaAndComplianceArea.versionId === null) {
            return data?.find((x) => x.isCurrentVersion)?.id;
        }

        const foundVersionFromdata = data?.filter((x) => x.id === riskAreaAndComplianceArea.versionId);
        if (foundVersionFromdata?.length === 0) {
            return data?.find((x) => x.isCurrentVersion)?.id;
        }

        return riskAreaAndComplianceArea.versionId;
    }
}
