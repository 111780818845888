import { useTheme } from "@mui/material";
import { createSxStyles } from "../../../common/createSxStyles";

export const useSystemRelationStepStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        addSystem: {
            marginTop: theme.spacing(3),
            display: "flex",
            justifyContent: "center",
        },
        checkboxContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: theme.spacing(1),
        },
        collapseHeaderText: {
            color: theme.palette.primary.dark,
            fontWeight: theme.typography.fontWeightBold,
        },
    });
};
