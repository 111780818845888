import { DotLegalSelect, IDotLegalSelect } from "@dotlegal/dotlegal-ui-components";
import React from "react";
import { useDataSubjectSelectHooks } from "./DataSubjectSelect.hooks";
import { useDataSubjectSelectStyles } from "./DataSubjectSelect.styles";
import { Box } from "@mui/material";

export interface IDataSubjectSelect extends IDotLegalSelect {
    dataSubjects: Array<string>;
    dataSubjectTooltip?: string;
}

export function DataSubjectSelect(props: IDataSubjectSelect) {
    const { dataSubjectOptions, getSelectedItems } = useDataSubjectSelectHooks(props);
    const styles = useDataSubjectSelectStyles();

    return (
        <React.Fragment>
            {props.dataSubjects.length > 1 && (
                <Box sx={styles.container}>
                    <DotLegalSelect
                        {...props}
                        options={dataSubjectOptions}
                        selectedItem={getSelectedItems()}
                        width={"260px"}
                        toolTipText={props.dataSubjectTooltip}
                    />
                </Box>
            )}
        </React.Fragment>
    );
}
