import { DotLegalCheckbox, DotLegalDialog, DotLegalMultiSelect, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import React from "react";
import { useTranslation } from "../../../localization/useTranslation";
import { Grid } from "@mui/material";
import { contactTypeOptions, useLegalEntityContactDialogHook } from "./LegalEntityContactDialog.hooks";
import { LegalEntityContactRow, LegalEntityContactViewModel } from "../LegalEntityContactTab.types";

export interface ILegalEntityContactDialog {
    onCloseDialog: () => void;
    selectedContact?: LegalEntityContactRow;
    isEdit: boolean;
    onSave: (contactId: string) => void;
    forLegalEntityId?: string;
}

function LegalEntityContactDialog(props: ILegalEntityContactDialog) {
    const { translateString } = useTranslation();
    const {
        onSaveClick,
        onNameChange,
        onEmailChange,
        onCountryCodeChange,
        onPhoneNumberChange,
        onPrimaryContactChange,
        onContactTypeChange,
        contact,
        validation,
        isSaving,
    } = useLegalEntityContactDialogHook(props);

    validation.check(contact);

    return (
        <DotLegalDialog
            header={props.isEdit ? translateString("editContactPerson") : translateString("addContactPerson")}
            buttonOkText={translateString("save")}
            size="sm"
            inProgress={isSaving}
            open
            onDialogClose={() => props.onCloseDialog()}
            onOkClick={() => onSaveClick()}
            disableBackdropOnCloseIfValueChange={contact}
        >
            <React.Fragment>
                <DotLegalTextField
                    label={translateString("name")}
                    onChange={(value) => onNameChange(value)}
                    value={contact.name}
                    debounce={false}
                    errorText={validation.getErrorsForDisplay("name")}
                />
                <DotLegalTextField
                    label={translateString("email")}
                    onChange={(value) => onEmailChange(value)}
                    value={contact.email}
                    debounce={false}
                    errorText={validation.getErrorsForDisplay("email")}
                />
                <Grid container spacing={1}>
                    <Grid item sm={3}>
                        <DotLegalTextField
                            label={translateString("countryCode")}
                            onChange={(value) => {
                                onCountryCodeChange(value);
                            }}
                            value={contact.countryCode ?? ""}
                            debounce={false}
                            errorText={validation.getErrorsForDisplay("countryCode")}
                        />
                    </Grid>
                    <Grid item sm={9}>
                        <DotLegalTextField
                            label={translateString("phone")}
                            onChange={(value) => onPhoneNumberChange(value)}
                            value={contact.phoneNumber ?? ""}
                            debounce={false}
                            errorText={validation.getErrorsForDisplay("phoneNumber")}
                        />
                    </Grid>
                </Grid>
                <DotLegalMultiSelect
                    options={contactTypeOptions(translateString)}
                    chosenOptions={contact.contactTypes.map((x) => x.toString())}
                    onChange={(types) => onContactTypeChange(types)}
                    placeholder={translateString("type")}
                    label={translateString("type")}
                    noOptionsLabel={translateString("noOptions")}
                />

                <DotLegalCheckbox
                    checked={contact.primaryContact}
                    onChange={(value) => onPrimaryContactChange(value)}
                    label={translateString("primaryContact")}
                />
            </React.Fragment>
        </DotLegalDialog>
    );
}

export default LegalEntityContactDialog;
