import React from "react";
import { usePlanContext } from "../../../auth/planProvider/PlanProvider";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import { useTranslation } from "../../../localization/useTranslation";
import { LegalEntityAuditStatus } from "../LegalEntityAudit.types";
import { useLegalEntityAuditOverflowMenu } from "./LegalEntityAuditTableOverflowMenu.hooks";
import { DotLegalOverflowMenu, DotLegalOverflowMenuItem, useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { getSettings } from "../../../common/settingsProvider";
import { useUrlProvider } from "../../../useUrlProvider";
import DotLegalNoteDialog from "../../../common/components/dotLegalNoteDialog/DotLegalNoteDialog";
import CloseAuditDialog from "../closeAuditDialog/CloseAuditDialog";
import ReopenAuditDialog from "../reopenAuditDialog/ReopenAuditDialog";
import DeleteAuditDialog from "../deleteAuditDialog/DeleteAuditDialog";
import ChangeResponsibles from "../changeResponsible/ChangeResponsible";
import ChangeContact from "../changeContact/ChangeContact";
import SendReminderDialog from "../sendReminderDialog/SendReminderDialog";
import { EvaluationStatus } from "../../../common/components/questionnaireComponents/evaluateQuestionnaireDialog/EvaluateQuestionnaireDialog.types";
import EvaluateAuditDialog from "../evaluateAuditDialog/EvaluateAuditDialog";
import ToggleAuditRemindersDialog from "../toggleAuditRemindersDialog/ToggleAuditRemindersDialog";

export interface LegalEntityAuditTableOverflowMenuProps {
    auditId: string;
    isClosed: boolean;
    status: LegalEntityAuditStatus;
    canBeDeleted: boolean;
    auditGroupId?: string;
    canSelectAuditGroups?: boolean;
    disableChangeResponsible?: boolean;
    partOfCurrentPlan: boolean;

    questionnaireName: string;
    deadline: Date;
    auditNote: string | undefined;
    responsibleId: string;
    contactId: string;
    contactName: string;
    legalEntityId: string;
    responseExternalId: string;
    questionnaireResponseId: string;
    questionnaireId: string;
    legalEntityName: string;

    additionalMenuItems?: Array<DotLegalOverflowMenuItem>;
    showNote: boolean;

    evaluation: string | undefined;
    evaluationStatus?: EvaluationStatus;
    remindersPaused: boolean;

    onAuditClosed: () => void;
    onAuditReopened: () => void;
    onAuditDeleted: () => void;
    onResponsibleChanged: () => void;
    onContactChanged: () => void;
    onReminderSent: () => void;
    saveNote: (note: string) => void;
    onCloseNoteDialog: () => void;
    onAuditEvaluated: () => void;
    onRemindersToggled: () => void;
}

function LegalEntityAuditTableOverflowMenu(props: LegalEntityAuditTableOverflowMenuProps) {
    const { permissions } = useUserContext();
    const { translateString } = useTranslation();
    const legalEntityPermissions = permissions.companyPermissions;
    const snackbar = useDotLegalSnackbar();
    const urlProvider = useUrlProvider();
    const {
        showCloseDialog,
        setShowCloseDialog,
        showReopenDialog,
        setShowReopenDialog,
        showDeleteDialog,
        setShowDeleteDialog,
        showReminderDialog,
        setShowReminderDialog,
        showNote,
        setShowNote,
        showChangeResponsible,
        setShowChangeResponsible,
        showChangeContact,
        setShowChangeContact,
        showEvaluateDialog,
        setShowEvaluateDialog,
        showToggleRemindersDialog,
        setShowToggleRemindersDialog,
    } = useLegalEntityAuditOverflowMenu(props);

    function getMenuItems() {
        let menuItems = [];

        const partOfCurrentPlan = props.partOfCurrentPlan;

        if (partOfCurrentPlan) {
            menuItems.push({
                menuItem: translateString("note"),
                onClick: () => {
                    setShowNote(true);
                },
            });
        }

        if (legalEntityPermissions.edit) {
            if (partOfCurrentPlan) {
                if (props.isClosed || props.status === LegalEntityAuditStatus.Submitted) {
                    menuItems.push({
                        menuItem: props.isClosed ? translateString("reopenAudit") : translateString("reopenCompledAudit"),
                        onClick: () => {
                            setShowReopenDialog(true);
                        },
                    });
                }
            }

            if (!props.isClosed) {
                menuItems.push({
                    menuItem: translateString("closeAudit"),
                    onClick: () => {
                        setShowCloseDialog(true);
                    },
                });
            }
        }

        if (partOfCurrentPlan) {
            if (props.status !== LegalEntityAuditStatus.Submitted && !props.isClosed) {
                menuItems.push({
                    menuItem: translateString("sendReminder"),
                    onClick: () => {
                        setShowReminderDialog(true);
                    },
                    disabled: props.remindersPaused,
                    tooltip: props.remindersPaused ? translateString("remindersIsPaused") : "",
                });
            }

            if (props.status !== LegalEntityAuditStatus.Submitted && !props.isClosed) {
                menuItems.push({
                    menuItem: props.remindersPaused ? translateString("resumeReminders") : translateString("pauseReminders"),
                    onClick: () => {
                        setShowToggleRemindersDialog(true);
                    },
                });
            }
        }

        if (legalEntityPermissions.delete) {
            if (props.canBeDeleted) {
                menuItems.push({
                    menuItem: translateString("deleteAudit"),
                    onClick: () => {
                        setShowDeleteDialog(true);
                    },
                });
            }
        }

        if (partOfCurrentPlan) {
            if (!props.disableChangeResponsible && legalEntityPermissions.edit) {
                menuItems.push({
                    menuItem: translateString("changeResponsible"),
                    onClick: () => {
                        setShowChangeResponsible(true);
                    },
                });
            }

            if (legalEntityPermissions.edit) {
                menuItems.push({
                    menuItem: translateString("changeContact"),
                    onClick: () => {
                        setShowChangeContact(true);
                    },
                });
            }

            if (!props.isClosed) {
                menuItems.push({
                    menuItem: translateString("CopyLinkToAudit"),
                    onClick: () => {
                        const url = getSettings().mainSiteBaseUrl + urlProvider.getExternalQuestionnaireResponseUrl(props.responseExternalId);
                        navigator.clipboard.writeText(url);
                        snackbar.show(translateString("linkCopied", { link: url }));
                    },
                });
            }
        }

        if (props.additionalMenuItems) {
            for (let menuItem of props.additionalMenuItems) {
                menuItems.push(menuItem);
            }
        }
        if (partOfCurrentPlan) {
            if (props.status === LegalEntityAuditStatus.Submitted && legalEntityPermissions.edit) {
                menuItems.push({
                    menuItem: translateString("evaluateAuditHeader"),
                    onClick: () => {
                        setShowEvaluateDialog(true);
                    },
                });
            }
        }

        return menuItems;
    }

    return (
        <React.Fragment>
            <DotLegalOverflowMenu items={getMenuItems()} />

            {showNote && (
                <DotLegalNoteDialog
                    showDialog={showNote}
                    noteText={props.auditNote}
                    onDialogClose={() => {
                        setShowNote(false);
                        props.onCloseNoteDialog();
                    }}
                    onOkClick={(text) => {
                        setShowNote(false);
                        props.saveNote(text);
                    }}
                    disabled={!legalEntityPermissions.edit}
                />
            )}

            {showCloseDialog && (
                <CloseAuditDialog
                    onAuditClosed={() => {
                        props.onAuditClosed();
                        setShowCloseDialog(false);
                    }}
                    onDialogClose={() => setShowCloseDialog(false)}
                    auditId={props.auditId}
                    questionnaireName={props.questionnaireName}
                    deadline={props.deadline}
                />
            )}

            {showReopenDialog && (
                <ReopenAuditDialog
                    onDialogClose={() => setShowReopenDialog(false)}
                    onAuditReopened={() => {
                        props.onAuditReopened();
                        setShowReopenDialog(false);
                    }}
                    auditId={props.auditId}
                    isSubmitted={props.status === LegalEntityAuditStatus.Submitted}
                    name={props.questionnaireName}
                    deadline={props.deadline}
                />
            )}

            {showDeleteDialog && (
                <DeleteAuditDialog
                    onDialogClose={() => setShowDeleteDialog(false)}
                    onSuccessDelete={props.onAuditDeleted}
                    auditId={props.auditId}
                    questionnaireName={props.questionnaireName}
                    deadline={props.deadline}
                />
            )}

            {showChangeResponsible && (
                <ChangeResponsibles
                    onDialogClose={() => setShowChangeResponsible(false)}
                    onChangedResponsible={() => {
                        setShowChangeResponsible(false);
                        props.onResponsibleChanged();
                    }}
                    responsible={props.responsibleId}
                    auditId={props.auditId}
                />
            )}

            {showChangeContact && (
                <ChangeContact
                    onChangedContact={() => {
                        props.onContactChanged();
                        setShowChangeContact(false);
                    }}
                    onDialogClose={() => setShowChangeContact(false)}
                    legalEntityId={props.legalEntityId}
                    auditId={props.auditId}
                    contactId={props.contactId}
                    questionnaireName={props.questionnaireName}
                    deadline={props.deadline}
                />
            )}

            {showReminderDialog && (
                <SendReminderDialog
                    onCloseDialog={() => setShowReminderDialog(false)}
                    onReminderSent={() => {
                        props.onReminderSent();
                        setShowReminderDialog(false);
                    }}
                    auditId={props.auditId}
                    questionnaireName={props.questionnaireName}
                    contactName={props.contactName}
                />
            )}

            {showEvaluateDialog && (
                <EvaluateAuditDialog
                    onEvaluated={() => {
                        props.onAuditEvaluated();
                        setShowEvaluateDialog(false);
                    }}
                    onDialogClose={() => setShowEvaluateDialog(false)}
                    evaluation={props.evaluation}
                    evaluationStatus={props.evaluationStatus}
                    id={props.auditId}
                    questionnaireName={props.questionnaireName}
                />
            )}

            {showToggleRemindersDialog && (
                <ToggleAuditRemindersDialog
                    onDialogClose={() => setShowToggleRemindersDialog(false)}
                    onInternalAssessmentPaused={() => {
                        props.onRemindersToggled();
                        setShowToggleRemindersDialog(false);
                    }}
                    remindersPaused={props.remindersPaused}
                    name={props.questionnaireName}
                    id={props.auditId}
                />
            )}
        </React.Fragment>
    );
}

export default LegalEntityAuditTableOverflowMenu;
