import React, { useEffect } from "react";

function ChangeOrganization() {
    useEffect(() => {
        localStorage.removeItem("customerId");
        window.location.href = window.location.origin.toString();
    });

    return <div></div>;
}

export default ChangeOrganization;
