import { createSxStyles } from "../../../createSxStyles";

export const useProcessingActivityValidationBoxStyles = () => {
    return createSxStyles({
        background: {
            border: 0,
            boxShadow: "0px 3px 6px #00000029",
            width: 600 + "px",
        },
    });
};
