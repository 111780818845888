import React from "react";
import { Box, Skeleton } from "@mui/material";
import GreyWave from "./svgs/grey-wave.svg?url";

export interface IWidgetProps {
    disabled?: boolean;
    noPadding?: boolean;
    children?: React.ReactNode;
    isPdf?: boolean;
}

function Widget(props: IWidgetProps) {
    const cardStyles = { padding: props.noPadding ? 0 : 2, backgroundColor: "#EEEFFA", borderRadius: "20px", height: props.isPdf ? "auto" : "100%" };
    return (
        <>
            {props.disabled ? (
                <Box
                    sx={{
                        backgroundColor: "#EDEDED",
                        backgroundImage: `url("${GreyWave}")`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "100% 105%",
                        backgroundSize: "100% 50%",
                        borderRadius: "20px",
                        height: "100%",
                        minHeight: 250,
                    }}
                ></Box>
            ) : (
                <Box sx={{ ...cardStyles, display: props.isPdf ? "block" : "flex", flexDirection: "column" }}>{props.children}</Box>
            )}
        </>
    );
}

export default Widget;
