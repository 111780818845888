import { useMutation, useQuery } from "react-query";
import { get, post } from "../../common/api/apiShared";
import { useOptimisticUpdate } from "../../common/hooks/useOptimisticUpdate";

export function useCreateCommentDataMapping(getSubscriptionUrl: string, setSubscriptionUrl: string, disabled?: boolean) {
    const { putOnQueueAndSetQueryData } = useOptimisticUpdate();

    const url = getSubscriptionUrl;
    const { isLoading, data } = useQuery(url, () => get<boolean>(url), {
        enabled: !disabled,
    });

    const onSubscriptionChange = async (subscribe: boolean) => {
        putOnQueueAndSetQueryData(subscribe, url, saveSubscriptionMutation.mutateAsync, subscribe);
    };

    const saveSubscriptionMutation = useMutation(updateSubscription);

    function updateSubscription(subscribe: boolean) {
        return post<{}>(setSubscriptionUrl, subscribe);
    }

    return {
        isLoading,
        subscribed: data,
        onSubscriptionChange,
        subscriptionSaving: saveSubscriptionMutation.isLoading,
    };
}
