import React from "react";
import DotLegalDeleteDialogWrapper from "../../common/components/dotLegalDeleteDialog/DotLegalDeleteDialogWrapper";
import { CustomerSaveModel } from "../addEditCustomerDialog/AddEditCustomer.types";
import { useDeleteCustomerDialogHooks } from "./DeleteCustomerDialog.hooks";

export interface IDeleteCustomerDialog {
    onCloseDialog: () => void;
    onDeletion: () => void;
    selectedCustomer: CustomerSaveModel;
}

export function DeleteCustomerDialog(props: IDeleteCustomerDialog) {
    const { removeCustomer, inProgess } = useDeleteCustomerDialogHooks(props);

    return (
        <DotLegalDeleteDialogWrapper
            open
            itemForDeletion={props.selectedCustomer.name}
            onDialogClose={props.onCloseDialog}
            onOkClick={removeCustomer}
            inProgress={inProgess}
        />
    );
}
