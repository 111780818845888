import { currentVersion } from "../../VersionProvider";
import { getSettings } from "../settingsProvider";
import { isNullOrWhitespace } from "../stringOperations";
import { Result } from "./result";

let _token: string;
let _externalToken: string;

export function setJwtToken(token: string) {
    _token = token;
}

export function getJwtToken() {
    return _token;
}

export function setExternalToken(externalToken: string) {
    _externalToken = externalToken;
}

export function getExternalToken() {
    return _externalToken;
}

export async function post<T>(url: string, body: any): Promise<Result<T>> {
    const response = await doFetch(url, "POST", body);
    return await handleResponse(response);
}

export async function postGet<T>(url: string, body: any): Promise<T> {
    const response = await doFetch(url, "POST", body);
    return await response.json();
}

export async function get<T>(url: string): Promise<T> {
    const response = await doFetch(url);
    return await response.json();
}

export async function getRaw(url: string, method?: string, body?: any): Promise<Response> {
    return await doFetch(url, method, body);
}

export async function put<T>(url: string, body: any): Promise<Result<T>> {
    const response = await doFetch(url, "PUT", body);
    return await handleResponse(response);
}

export async function deleteHttp<T>(url: string): Promise<Result<T>> {
    const response = await doFetch(url, "DELETE");
    return await handleResponse(response);
}

async function doFetch(url: string, method?: string, body?: any, requestJwtOnUnauthorized: boolean = true): Promise<Response> {
    const headers: HeadersInit = new Headers();

    headers.set("Authorization", "Bearer " + getJwt());
    headers.set("pragma", "no-cache");
    headers.set("cache-control", "no-cache");

    if (method === "POST" || method === "PUT") {
        headers.set("Content-Type", "application/json");
    }
    let apiBaseUrl = getSettings().apiBaseUrl;
    const fullUrl = url.startsWith("http") ? url : apiBaseUrl + url;

    const response = await fetch(fullUrl, { method: method, body: JSON.stringify(body), headers: headers });

    if (fullUrl.startsWith(apiBaseUrl)) {
        const backendVersion = response.headers.get("releaseVersion");
        if (isNullOrWhitespace(backendVersion ?? undefined)) {
            throw new Error("Could not get releaseVersion from response header (apiShared)");
        }
        if (currentVersion !== backendVersion) {
            // eslint-disable-next-line no-console
            console.log(`Current version: ${currentVersion} - Backend version: ${backendVersion}`);
            window.location.reload();
        }
    }

    if (response.status === 403) {
        window.location.href = "/privacy/forbidden";
    }

    if (response.status === 404) {
        window.location.href = "/privacy/resource-not-found";
    }

    if (response.status === 401) {
        if (requestJwtOnUnauthorized) {
            await getAndSaveJwt();
            return await doFetch(url, method, body, false);
        } else {
            throw new Error("Could not fetch JWT");
        }
    }

    if (!isOkOrClientErrorStatus(response.status)) {
        throw new Error(`Error calling API. Status code: ${response.status}. URL: ${response.url}`);
    }

    sessionStorage.removeItem("challengeIdp");
    return response;
}

function isOkOrClientErrorStatus(status: number) {
    return (status >= 200 && status < 300) || (status >= 400 && status < 500);
}

export async function getAndSaveJwt() {
    const customerId = localStorage.getItem("customerId");
    const language = localStorage.getItem("language");
    let jwtTokenUrl = getSettings().jwtTokenUrl + "?externalToken=" + _externalToken;
    if (customerId) {
        jwtTokenUrl = jwtTokenUrl + "&customerId=" + customerId;
    }

    if (language) {
        jwtTokenUrl = jwtTokenUrl + "&language=" + language;
    }

    const response = await fetch(jwtTokenUrl, {
        redirect: "manual",
        credentials: "include",
    });

    if (response.type === "opaqueredirect") {
        window.location.href = response.url;
    } else if (response.status === 401) {
        if (sessionStorage.getItem("challengeIdp") === "true") {
            // To prevent page refresh loop
            throw new Error("JWT could not be validated");
        } else {
            sessionStorage.setItem("challengeIdp", "true");
            // Makes the AuthProvider challenge the IDP
            // eslint-disable-next-line no-console
            console.log("Could not retrieve new Privacy JWT. External token is probably expired. Refreshing page...");
            window.location.reload();
        }
    } else if (response.status === 400) {
        let reason = await response.text();
        if (reason === "usernotfound") window.location.href = "/privacy/user-not-found";
        else if (reason === "inactivecustomer") window.location.href = "/privacy/account-closed";
        else if (reason === "inactiveuser") {
            window.location.href = "/privacy/inactive-user";
        } else throw new Error("Unknown reason: " + reason);
    } else {
        let token = await response.text();
        setJwtToken(token);
    }
}

function getJwt() {
    return getJwtToken();
}

async function handleResponse<T>(response: Response): Promise<Result<T>> {
    const responseBody = await response.text();
    if (response.status === 400) {
        if (responseBody) {
            return new Promise<Result<T>>(async (resolve) => resolve(new Result<T>(undefined, await JSON.parse(responseBody))));
        } else {
            return new Promise<Result<T>>(async (resolve) => resolve(new Result<T>(undefined, ["error"])));
        }
    }

    if (responseBody) {
        return new Promise<Result<T>>(async (resolve) => {
            return resolve(new Result<T>(await JSON.parse(responseBody)));
        });
    }
    return new Promise<Result<T>>(async (resolve) => {
        return resolve(new Result<T>());
    });
}
