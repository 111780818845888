import { useTheme } from "@mui/material";
import { createSxStyles } from "../../../common/createSxStyles";

export const useSharingsAgreeementStyles = (disableHover: boolean) => {
    const theme = useTheme();
    return createSxStyles({
        container: {
            display: "flex",
            flexDirection: "column",
        },
        descriptionLabel: {
            alignSelf: "end",
            fontSize: theme.typography.pxToRem(12),
            color: theme.palette.primary.main,
            "&:hover": {
                cursor: getCursor(disableHover),
                textDecoration: getTextDecoration(disableHover),
            },
        },
    });
};

function getCursor(disableHover: boolean) {
    return disableHover ? "default" : "pointer";
}

function getTextDecoration(disableHover: boolean) {
    return disableHover ? "none" : "underline";
}
