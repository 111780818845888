import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

export enum TrackingEvent {
    DashboardLoaded = 0,
    WidgetAllMineToggeled = 2,
    TaskResponseClicked = 5,
    AddProcessingActivityClicked = 6,
    ProcessingActivityAdded = 7,
    EditProcessingActivityClicked = 8,
    DeleteProcessingActivityClicked = 9,
    ProcessingActivityDeleted = 10,
    ProcessingActivityOverviewClicked = 11,
    SystemAddedToProcessingActivity = 12,
    DataProcessorAddedToProcessingActivity = 13,
    DisclosureAddedToProcessingActivity = 14,
    SourceAddedToProcessingActivity = 15,
    AnnualWheelActivityCreated = 16,
    AnnualWheelActivityDeleted = 17,
    AnnualWheelActivityTaskUpdated = 18,
    ProcessingActivityRiskAssessmentScenarioAdded = 19,
    ProcessingActvitiyRiskAssessmentScenarioDeleted = 20,
    ProcessingActivityRiskAssessmentQuestionAdded = 21,
    ProcessingActivtyRiskAssessmentQuestionDeleted = 22,
    TaskAddedToOutlook = 23,
    CustomListAdded = 24,
    CustomListColumnAdded = 25,
    CustomListColumnDeleted = 26,
    CustomListOpened = 27,
    CustomListDeleted = 28,
    ProcessingActivityCloned = 29,
    JointControllerAddedToProcessingActivity = 30,
}

export function useTrackAIEvent() {
    const appInsights = useAppInsightsContext();

    return function trackAIEvent(event: TrackingEvent, customProperties?: any) {
        appInsights.trackEvent({ name: TrackingEvent[event] }, customProperties);
    };
}
