import { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { get } from "../../common/api/apiShared";
import { PolicyDocument } from "../policyDocuments/PolkcyDocuments.types";

export function usePolicyDocumentDataMapping() {
    const { policyId } = useParams<{ policyId: string }>();

    const [selectedDocument, setSelectedDocument] = useState<PolicyDocument>();
    const [showDocumentDialog, setShowDocumentDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const url = `/Policy/${policyId}/documents`;
    let { isLoading, data, refetch } = useQuery(url, () => get<Array<PolicyDocument>>(url));

    const hasDocuments = isLoading || (data && data?.length > 0);

    return {
        selectedDocument,
        setSelectedDocument,
        data,
        isLoading,
        showDocumentDialog,
        setShowDocumentDialog,
        hasDocuments,
        refetch,
        showDeleteDialog,
        setShowDeleteDialog,
        policyId,
    };
}
