import { SelectableItem } from "../processingActivity/ProcessingActivity.types";

export interface UsersViewModel {
    users: Array<UserViewModel>;
    businessAreas: Array<SelectableItem>;
    userRoles: Array<SelectableItem>;
}

export interface UserViewModel {
    id: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
    businessAreas: Array<SelectableItem>;
    userRoles: Array<SelectableItem>;
    gdpo: boolean;
    isActive: boolean;
    lastLogin: string | undefined;
    fullName: string;
    integrationIdentifier: string;
    userGroupEntityNames: Array<string>;
}

export interface UserGroupEntities {
    legalEntityId: string;
    isSelected: boolean;
    name: string;
    address: string;
    zipCode: string;
    city: string;
    country: string;
}

export interface UserSaveModel {
    firstName: string;
    lastName: string;
    emailAddress: string;
    businessAreas: Array<string>;
    userRoles: Array<string>;
    gdpo: boolean;
    integrationIdentifier: string;
}

export enum LoginProvider {
    DotLegalIdp = 1,
    AzureAd = 2,
}

export interface UserSelectableItem {
    name: string;
    id: string;
    active: boolean;
    email: string;
}

export function getLoginProviderName(loginProvider: LoginProvider) {
    switch (loginProvider) {
        case LoginProvider.DotLegalIdp:
            return ".legal IDP";
        case LoginProvider.AzureAd:
            return "Azure AD";
        default:
            throw new Error(`Unknown loginProvider ${loginProvider}`);
    }
}
