import {
    DotLegalButton,
    DotLegalCompletedPercentage,
    DotLegalEmptyState,
    DotLegalFixedHeightText,
    DotLegalHeading,
    DotLegalMultiSelect,
    DotLegalOverflowMenu,
    DotLegalPageHeader,
    DotLegalPaper,
    DotLegalSelect,
    DotLegalTableWithControls,
    ITableHeader,
} from "@dotlegal/dotlegal-ui-components";
import React from "react";
import { useTranslation } from "../localization/useTranslation";
import { useUserContext } from "../auth/userContextProvider/UserContextProvider";
import EmptyState from "./declarations/DeclarationsEmptyState.svg?react";
import { Alert, AlertTitle, Box, Grid, GridSize, IconButton, TableCell, TableRow } from "@mui/material";
import ComplianceAreaWrapper from "../common/components/complianceAreaWrapper/ComplianceAreaWrapper";
import { AuditFrequency, DeclarationApprovalOption } from "./declarations/Declarations.types";
import { isNullOrWhitespace, setFirstLetterToLowerCase } from "../common/stringOperations";
import { SelectableItem } from "../processingActivity/ProcessingActivity.types";
import DeclarationGroupEntityDisplayDialog from "./declarations/declarationGroupEntityDisplayDialog/DeclarationGroupEntityDisplayDialog";
import { NoteSVG, samplingIcon } from "../common/icons";
import { useUrlProvider } from "../useUrlProvider";
import { DeclarationControlStatusEnum, DeclarationControlTableModel, DeclarationControlViewModel } from "./Declaration.types";
import { useDeclaration } from "./Declaration.hooks";
import DeclarationControlStatus from "./declarationControl/declarationControlStatus/DeclarationControlStatus";
import DeleteDeclarationDialog from "./deleteDeclarationDialog/DeleteDeclarationDialog";
import CreateEditDeclarationDialog from "./createEditDeclarationDialog/CreateEditDeclarationDialog";
import { useNavigate } from "react-router-dom";
import DeclarationControlDialog from "./declarationControl/declarationControlDialog/DeclarationControlDialog";
import CompleteDeclarationDialog from "./completeDeclarationDialog/CompleteDeclarationDialog";
import DotLegalNoteDialog from "../common/components/dotLegalNoteDialog/DotLegalNoteDialog";
import DeleteDeclarationControlDialog from "./declarationControl/deleteDeclarationControlDialog/DeleteDeclarationControlDialog";
import ApproveDeclarationControlDialog from "./declarationControl/approveDeclarationControlDialog/ApproveDeclarationControlDialog";
import RevisionDialog from "./revisionDialog/RevisionDialog";
import CompleteDeclarationControlDialog from "./declarationControl/completeDeclarationControlDialog/CompleteDeclarationControlDialog.tsx";
import PageHeaderEditEntityButton, { PageHeaderEditEntityButtonMenuItem } from "../common/components/pageHeaderEditEntityButton/PageHeaderEditEntityButton.tsx";

export interface DeclarationProps {}

function Declaration() {
    const { getDeclarationsUrl, getDeclarationUrl } = useUrlProvider();
    const { translateString, translateDateWithFullMonthAndYear } = useTranslation();
    const { customerName, permissions } = useUserContext();
    const navigate = useNavigate();
    const urlProvider = useUrlProvider();
    const {
        isLoading,
        data,
        controlsToDisplay,
        showGroupEntitiesDialog,
        setShowGroupEntitiesDialog,
        showDeleteDeclarationDialog,
        setShowDeleteDeclarationDialog,
        searchedCategory,
        setSearchedCategory,
        searchedResponsible,
        setSearchedResponsible,
        categoryFilterOptions,
        responsibleFilterOptions,
        showEditDeclarationDialog,
        setShowEditDeclarationDialog,
        setShowDeclarationControlDialog,
        showDeclarationControlDialog,
        refetch,
        setShowNoteDialog,
        showNoteDialog,
        selectedControl,
        setSelectedControl,
        isSavingNote,
        saveNote,
        setShowCompleteDialog,
        showCompleteDialog,
        setShowDeleteControlDialog,
        showDeleteControlDialog,
        showApproveControlDialog,
        setShowApproveControlDialog,
        showCompleteControlDialog,
        setShowCompleteControlDialog,
        showReadyForRevisionDialog,
        setShowReadyForRevisionDialog,
        showAuditorNoteDialog,
        setShowAuditorNoteDialog,
        saveAuditorNote,
        isSavingAuditorNote,
        showAuditorDeviationNoteDialog,
        setShowAuditorDeviationNoteDialog,
        isSavingAuditorDeviationNote,
        saveAuditorDeviationNote,
        onSetSampling,
    } = useDeclaration();

    const isLocked = data === undefined || data.isLocked;
    const showAuditorNote = data && data.markedAsReadyForRevision && data.approval === DeclarationApprovalOption.ExternalApprovalRequired;

    const gridProps: {
        item: boolean;
        xs: GridSize;
        sm: GridSize;
        md: GridSize | boolean;
        lg: GridSize | boolean;
        xl: GridSize | boolean;
    } = {
        item: true,
        xs: 12,
        sm: 6,
        md: 4,
        lg: 4,
        xl: 2,
    };

    const styles = {
        hover: {
            "&:hover": {
                cursor: "pointer",
            },
        },
    };

    function getGroupEntityTableCell(groupEntities: Array<SelectableItem>, noOfGroupEntitiesWithoutAccess: number): React.ReactNode {
        let content;
        let canClickTableCell = false;

        if (groupEntities.length === 0 && noOfGroupEntitiesWithoutAccess === 0) {
            content = <></>;
        }

        if (groupEntities.length > 0) {
            let additionalEntities = groupEntities.length - 1 + noOfGroupEntitiesWithoutAccess;
            canClickTableCell = additionalEntities > 0;
            content = (
                <Box sx={canClickTableCell ? styles.hover : {}}>
                    {isNullOrWhitespace(groupEntities[0].name) ? translateString("deletedGroupCompany") : groupEntities[0].name}{" "}
                    {getAdditionalGroupEntities(additionalEntities)}
                </Box>
            );
        } else {
            canClickTableCell = noOfGroupEntitiesWithoutAccess > 0;
            content = <Box>{getAdditionalGroupEntities(noOfGroupEntitiesWithoutAccess)}</Box>;
        }

        return (
            <Box
                onClick={(e) => {
                    if (canClickTableCell) {
                        e.stopPropagation();
                        setShowGroupEntitiesDialog({
                            groupEntities: groupEntities,
                            entitiesWithoutAccess: noOfGroupEntitiesWithoutAccess,
                        });
                    }
                }}
            >
                {content}
            </Box>
        );
    }

    function getAdditionalGroupEntities(numberOfAdditionalEntities: number) {
        if (numberOfAdditionalEntities === 0) return;

        return (
            <Box component={"span"} sx={(theme) => ({ fontSize: theme.typography.pxToRem(12), fontWeight: 700, color: "primary.dark" })}>
                + {numberOfAdditionalEntities}
            </Box>
        );
    }

    function getHeaders() {
        let headers = Array<ITableHeader<DeclarationControlTableModel>>();
        headers.push({ property: "customControlId", text: translateString("id"), align: "left", showOnMobile: true });
        headers.push({ property: "name", text: translateString("controlActivity"), align: "left", showOnMobile: true });
        headers.push({
            property: "categoryString",
            text: translateString("category"),
            align: "left",
            showOnMobile: true,
        });
        headers.push({
            property: "responsibleName",
            text: translateString("responsible"),
            align: "left",
            showOnMobile: true,
        });
        headers.push({
            property: "totalTasks",
            text: translateString("documentation"),
            align: "left",
            showOnMobile: true,
        });
        headers.push({ property: "statusString", text: translateString("status"), align: "left", showOnMobile: true });
        headers.push({ property: "note", text: translateString("note"), align: "left", showOnMobile: true });

        if (showAuditorNote) {
            headers.push({ property: "sampling", text: translateString("sample"), align: "left", showOnMobile: true });
            headers.push({ property: "auditorNote", text: translateString("auditorNote"), align: "left", showOnMobile: true });
            headers.push({ property: "auditorDeviationNote", text: translateString("auditorDeviations"), align: "left", showOnMobile: true });
        }

        return headers;
    }

    function getFilterControls() {
        const tableGridProps: {
            item: boolean;
            xs: GridSize;
            sm: GridSize;
            md: GridSize;
            lg: GridSize;
            xl: GridSize;
        } = {
            item: true,
            xs: 12,
            sm: 6,
            md: 4,
            lg: 2,
            xl: 2,
        };

        return (
            <>
                <Grid {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedCategory}
                        chosenOptions={searchedCategory}
                        label={translateString("category")}
                        placeholder={translateString("category")}
                        isLoading={isLoading}
                        options={categoryFilterOptions}
                        noMargin
                        limit={1}
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>
                <Grid {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedResponsible}
                        chosenOptions={searchedResponsible}
                        label={translateString("responsible")}
                        placeholder={translateString("responsible")}
                        isLoading={isLoading}
                        options={responsibleFilterOptions}
                        noMargin
                        limit={1}
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>
            </>
        );
    }

    function formatNote(row: DeclarationControlViewModel) {
        return (
            row.note && (
                <IconButton
                    onClick={(e) => {
                        e.stopPropagation();
                        setSelectedControl(row);
                        setShowNoteDialog(true);
                    }}
                >
                    {NoteSVG}
                </IconButton>
            )
        );
    }

    function formatAuditorNote(row: DeclarationControlViewModel) {
        return (
            row.auditorNote && (
                <IconButton
                    onClick={(e) => {
                        e.stopPropagation();
                        setSelectedControl(row);
                        setShowAuditorNoteDialog(true);
                    }}
                >
                    {NoteSVG}
                </IconButton>
            )
        );
    }

    function formatAuditorDeviationNote(row: DeclarationControlViewModel) {
        return (
            row.auditorDeviationNote && (
                <IconButton
                    onClick={(e) => {
                        e.stopPropagation();
                        setSelectedControl(row);
                        setShowAuditorDeviationNoteDialog(true);
                    }}
                >
                    {NoteSVG}
                </IconButton>
            )
        );
    }

    function getOverflowMenuItems(row: DeclarationControlViewModel) {
        let menuItems = [];

        if (isLocked) {
            menuItems.push({
                menuItem: translateString("open"),
                onClick: () => {
                    setSelectedControl(row);
                    setShowDeclarationControlDialog(true);
                },
            });

            if (showAuditorNote && permissions.hasDeclarationAuditorApproval) {
                menuItems.push({
                    menuItem: row.auditorNote ? translateString("editAuditorNote") : translateString("addAuditorNote"),
                    onClick: () => {
                        setShowAuditorNoteDialog(true);
                        setSelectedControl(row);
                    },
                    disabled: data?.isApproved ?? false,
                });
                menuItems.push({
                    menuItem: row.auditorDeviationNote ? translateString("editAuditorDeviationNote") : translateString("addAuditorDeviationNote"),
                    onClick: () => {
                        setShowAuditorDeviationNoteDialog(true);
                        setSelectedControl(row);
                    },
                    disabled: data?.isApproved ?? false,
                });

                menuItems.push({
                    menuItem: row.sampling ? translateString("removeSample") : translateString("sampling"),
                    onClick: () => onSetSampling(row.id, !row.sampling),
                    disabled: data?.isApproved ?? false,
                });
            }
        } else {
            if (permissions.declarationPermissions.edit) {
                menuItems.push({
                    menuItem: translateString("edit"),
                    onClick: () => {
                        setSelectedControl(row);
                        setShowDeclarationControlDialog(true);
                    },
                    disabled: isLocked,
                });

                if (row.canApprove) {
                    menuItems.push({
                        menuItem: row.status === DeclarationControlStatusEnum.ApprovedInternal ? translateString("decline") : translateString("approve"),
                        onClick: () => {
                            setSelectedControl(row);
                            setShowApproveControlDialog(true);
                        },
                        disabled: isLocked,
                    });
                }

                menuItems.push({
                    menuItem: translateString("delete"),
                    onClick: () => {
                        setSelectedControl(row);
                        setShowDeleteControlDialog(true);
                    },
                    disabled: isLocked,
                });

                menuItems.push({
                    menuItem: translateString("note"),
                    onClick: () => {
                        setSelectedControl(row);
                        setShowNoteDialog(true);
                    },
                    disabled: isLocked,
                });
            }
        }

        if (permissions.hasDeclarationAuditorApproval) {
            if (data?.approval === DeclarationApprovalOption.ExternalApprovalRequired) {
                if (data?.markedAsReadyForRevision) {
                    if (row.status === DeclarationControlStatusEnum.ApprovedInternal) {
                        menuItems.push({
                            menuItem: translateString("complete"),
                            onClick: () => {
                                setSelectedControl(row);
                                setShowCompleteControlDialog(true);
                            },
                        });
                    }
                    if (row.status === DeclarationControlStatusEnum.Completed) {
                        menuItems.push({
                            menuItem: translateString("cancelCompletion"),
                            onClick: () => {
                                setSelectedControl(row);
                                setShowCompleteControlDialog(true);
                            },
                        });
                    }
                }
            }
        }

        return menuItems;
    }

    const showIsReadyForRevisionButton =
        !isLocked &&
        permissions.declarationPermissions.edit &&
        data?.approval === DeclarationApprovalOption.ExternalApprovalRequired &&
        !data.markedAsReadyForRevision;
    const showCompleteButton =
        !data?.isApproved &&
        ((permissions.declarationPermissions.edit && data?.approval === DeclarationApprovalOption.InternalApprovalSufficient) ||
            (data?.approval === DeclarationApprovalOption.ExternalApprovalRequired &&
                data.markedAsReadyForRevision &&
                permissions.hasDeclarationAuditorApproval));

    const getPageHeaderOverflowMenuItems = () => {
        let menuItems: Array<PageHeaderEditEntityButtonMenuItem> = [];

        if (isLocked) {
            return menuItems;
        }

        menuItems.push({
            title: translateString("manageCategories"),
            onClick: () => {
                navigate(urlProvider.getDeclarationCategoriesUrl(data!.id));
            },
        });

        if (permissions.declarationPermissions.edit) {
            menuItems.push({
                title: translateString("edit"),
                onClick: () => {
                    navigate(urlProvider.getDeclarationCategoriesUrl(data!.id));
                },
            });
        }

        if (permissions.declarationPermissions.delete) {
            menuItems.push({
                title: translateString("delete"),
                onClick: () => {
                    setShowDeleteDeclarationDialog(true);
                },
            });
        }

        return menuItems;
    };

    return (
        <React.Fragment>
            <DotLegalPageHeader
                userContext={{ customerName }}
                breadCrumbs={[
                    { name: translateString("declarations"), link: getDeclarationsUrl() },
                    { name: data?.name, tooltip: data?.description ?? undefined },
                ]}
                rightComponent={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box
                            sx={(theme) => ({
                                marginLeft: theme.spacing(0.5),
                                "& svg": {
                                    height: 25,
                                    stroke: theme.palette.primary.main,
                                    fill: theme.palette.primary.light,
                                },
                            })}
                        >
                            <Box sx={{ display: "flex", gap: 1, height: 41 }}>
                                {data && data.versions.length > 1 && (
                                    <DotLegalSelect
                                        selectedItem={data.id}
                                        options={data?.versions ?? []}
                                        onChange={(version) => {
                                            navigate(getDeclarationUrl(version!));
                                        }}
                                        label={translateString("version")}
                                        noOptionsLabel={""}
                                        disableClearable
                                        width={"250px"}
                                        noMargin
                                    />
                                )}
                                <PageHeaderEditEntityButton menuItems={getPageHeaderOverflowMenuItems()} />
                            </Box>
                        </Box>
                    </Box>
                }
            />

            {data && data.isApproved && (
                <Alert
                    sx={(theme) => ({
                        marginTop: 1,
                        marginBottom: 2,
                        alignItems: "center",
                        "& .MuiTypography-root": {
                            fontSize: theme.typography.pxToRem(14),
                            marginTop: "4px",
                            marginBottom: "0px",
                        },
                        "& .MuiSvgIcon-root": { fill: theme.palette.warning.main },
                    })}
                    severity="warning"
                >
                    <AlertTitle>{translateString("completeControlEditWarning")}</AlertTitle>
                </Alert>
            )}

            <DotLegalPaper sx={{ marginBottom: 2, minHeight: 132 }} background="smallPurpleWave" backgroundCover>
                <Grid container>
                    <Grid {...gridProps}>
                        <DotLegalHeading name={translateString("complianceAreas")}>
                            <DotLegalFixedHeightText
                                isLoading={isLoading}
                                fontSize="lg"
                                content={
                                    <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
                                        {data?.complianceAreas.map((x, i) => {
                                            return <ComplianceAreaWrapper key={x.id} complianceArea={x.name} />;
                                        })}
                                    </Box>
                                }
                            />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...gridProps}>
                        <DotLegalHeading name={translateString("auditFrequency")}>
                            <DotLegalFixedHeightText
                                isLoading={isLoading}
                                fontSize="sm"
                                content={data ? translateString(setFirstLetterToLowerCase(AuditFrequency[data.auditFrequency].toString())) : undefined}
                            />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...gridProps}>
                        <DotLegalHeading name={translateString("nextAudit")}>
                            <DotLegalFixedHeightText isLoading={isLoading} fontSize="sm" content={translateDateWithFullMonthAndYear(data?.auditDate)} />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...gridProps}>
                        <DotLegalHeading name={translateString("approval")}>
                            <DotLegalFixedHeightText
                                isLoading={isLoading}
                                fontSize="sm"
                                content={data ? translateString(setFirstLetterToLowerCase(DeclarationApprovalOption[data.approval].toString())) : undefined}
                            />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...gridProps}>
                        <DotLegalHeading name={translateString("groupCompaniesParenthesis")}>
                            <DotLegalFixedHeightText
                                isLoading={isLoading}
                                fontSize="sm"
                                content={data ? getGroupEntityTableCell(data.groupEntities, data.noOfGroupEntitiesWithoutAccess) : undefined}
                            />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...gridProps} sx={{ display: "grid", alignContent: "center" }}>
                        <DotLegalHeading name={translateString("nextAuditProgress")}>
                            <DotLegalFixedHeightText
                                isLoading={isLoading}
                                fontSize="sm"
                                content={
                                    data ? (
                                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                                            <Box width={238}>
                                                <DotLegalCompletedPercentage completed={data.auditProgress} />
                                            </Box>
                                        </Box>
                                    ) : undefined
                                }
                            />
                        </DotLegalHeading>
                    </Grid>
                </Grid>
            </DotLegalPaper>

            <DotLegalPaper sx={{ display: "flex", flexDirection: "column" }}>
                {isLoading || (data?.controls ?? []).length > 0 ? (
                    <DotLegalTableWithControls
                        headers={getHeaders()}
                        extraControls={getFilterControls()}
                        hideRowsPerPage
                        defaultOrderBy={"name"}
                        defaultOrder={"asc"}
                        clickableRows
                        isLoading={isLoading}
                        paginationLabelOf={translateString("labelOf")}
                        getUserSpecificPageLength={() => 30}
                        labelRowsPerPage={translateString("showEntites")}
                        labelSearch={translateString("search")}
                        noOptionsLabel={translateString("noOptions")}
                        data={mapToDeclarationControlTableModel(controlsToDisplay)}
                        emptyText={translateString("noData")}
                        renderRow={(row) => {
                            return (
                                <TableRow
                                    key={row.id}
                                    onClick={() => {
                                        setSelectedControl(row);
                                        setShowDeclarationControlDialog(true);
                                    }}
                                >
                                    <TableCell>{row.customControlId}</TableCell>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>{row.category?.name}</TableCell>
                                    <TableCell>{row.responsible?.name}</TableCell>
                                    <TableCell>{row.totalTasks}</TableCell>
                                    <TableCell>
                                        <DeclarationControlStatus
                                            approvalType={data!.approval}
                                            status={row.status}
                                            completedTasks={row.completedTasks}
                                            totalNumberOfTasks={row.totalTasks}
                                        />
                                    </TableCell>
                                    <TableCell>{formatNote(row)}</TableCell>
                                    {showAuditorNote && <TableCell>{row.sampling ? <Box p={1}>{samplingIcon}</Box> : undefined}</TableCell>}
                                    {showAuditorNote && <TableCell>{formatAuditorNote(row)}</TableCell>}
                                    {showAuditorNote && <TableCell>{formatAuditorDeviationNote(row)}</TableCell>}
                                    {(permissions.declarationPermissions.edit || (permissions.hasDeclarationAuditorApproval && showAuditorNote)) && (
                                        <TableCell align={"right"}>
                                            <DotLegalOverflowMenu items={getOverflowMenuItems(row)} />
                                        </TableCell>
                                    )}
                                </TableRow>
                            );
                        }}
                    />
                ) : (
                    <DotLegalEmptyState icon={<EmptyState />} text={translateString("declarationControlEmptyState")} />
                )}

                <Box sx={{ alignSelf: "flex-end", justifySelf: "flex-end", display: "flex", gap: 1 }}>
                    {data && data.controls.length > 0 && (
                        <React.Fragment>
                            {showIsReadyForRevisionButton && (
                                <DotLegalButton
                                    disabled={!data.canMarkAsReadyForRevision}
                                    btnLength={180}
                                    buttonType={"primary"}
                                    onClick={() => setShowReadyForRevisionDialog(true)}
                                >
                                    {translateString("readyForRevision")}
                                </DotLegalButton>
                            )}

                            {showCompleteButton && (
                                <DotLegalButton disabled={!data.canComplete} btnLength={180} buttonType={"primary"} onClick={() => setShowCompleteDialog(true)}>
                                    {translateString("completeDeclaration")}
                                </DotLegalButton>
                            )}
                        </React.Fragment>
                    )}

                    {!isLocked && permissions.declarationPermissions.edit && (
                        <DotLegalButton btnLength={180} buttonType={"primary"} onClick={() => setShowDeclarationControlDialog(true)}>
                            {translateString("addControl")}
                        </DotLegalButton>
                    )}
                </Box>

                {!isLocked && <React.Fragment>{}</React.Fragment>}
            </DotLegalPaper>

            {(showGroupEntitiesDialog.groupEntities.length > 0 || showGroupEntitiesDialog.entitiesWithoutAccess > 0) && (
                <DeclarationGroupEntityDisplayDialog
                    onCloseDialog={() => setShowGroupEntitiesDialog({ groupEntities: [], entitiesWithoutAccess: 0 })}
                    groupEntities={showGroupEntitiesDialog.groupEntities}
                    numberOfGroupEntitiesWithoutaccess={showGroupEntitiesDialog.entitiesWithoutAccess}
                />
            )}

            {data && showDeleteDeclarationDialog && (
                <DeleteDeclarationDialog id={data.id} name={data.name} onCloseDialog={() => setShowDeleteDeclarationDialog(false)} />
            )}

            {data && showEditDeclarationDialog && (
                <CreateEditDeclarationDialog
                    onDialogClose={() => {
                        setShowEditDeclarationDialog(false);
                    }}
                    selectedDeclaration={{
                        id: data.id,
                        name: data.name,
                        description: data.description,
                        approval: data.approval,
                        auditDate: data.auditDate,
                        auditFrequency: data.auditFrequency,
                        complianceAreaIds: data.complianceAreas.map((x) => x.id),
                        groupEntityIds: data.groupEntities.filter((x) => x.id !== "null").map((x) => x.id),
                    }}
                    onUpdatedDeclaration={() => {
                        refetch();
                        setShowEditDeclarationDialog(false);
                    }}
                />
            )}

            {showDeclarationControlDialog && (
                <DeclarationControlDialog
                    declarationId={data!.id}
                    deadline={data!.auditDate}
                    onDialogClose={() => {
                        setShowDeclarationControlDialog(false);
                        setSelectedControl(undefined);
                    }}
                    onControlSaved={() => {
                        refetch();
                        setShowDeclarationControlDialog(false);
                        setSelectedControl(undefined);
                    }}
                    declarationControlId={selectedControl?.id}
                    isLocked={isLocked}
                />
            )}

            {showNoteDialog && (
                <DotLegalNoteDialog
                    showDialog
                    noteText={selectedControl?.note}
                    onDialogClose={() => {
                        setShowNoteDialog(false);
                        setSelectedControl(undefined);
                    }}
                    onOkClick={(text) => {
                        saveNote(text);
                        setSelectedControl(undefined);
                    }}
                    isSaving={isSavingNote}
                    disabled={isLocked || !permissions.declarationPermissions.edit}
                />
            )}

            {showDeleteControlDialog && (
                <DeleteDeclarationControlDialog
                    onDialogClose={() => {
                        setSelectedControl(undefined);
                        setShowDeleteControlDialog(false);
                    }}
                    onDeleted={() => {
                        refetch();
                        setShowDeleteControlDialog(false);
                        setSelectedControl(undefined);
                    }}
                    selectedControl={selectedControl!}
                />
            )}

            {showApproveControlDialog && selectedControl && (
                <ApproveDeclarationControlDialog
                    controlId={selectedControl.id}
                    controlName={selectedControl.name}
                    isApproved={selectedControl.status == DeclarationControlStatusEnum.ApprovedInternal}
                    onDialogClose={() => {
                        setSelectedControl(undefined);
                        setShowApproveControlDialog(false);
                    }}
                    onApprovedChanged={function (): void {
                        refetch();
                        setShowApproveControlDialog(false);
                        setSelectedControl(undefined);
                    }}
                />
            )}

            {showCompleteControlDialog && selectedControl && (
                <CompleteDeclarationControlDialog
                    controlId={selectedControl.id}
                    controlName={selectedControl.name}
                    isCompleted={selectedControl.status == DeclarationControlStatusEnum.Completed}
                    onDialogClose={() => {
                        setSelectedControl(undefined);
                        setShowCompleteControlDialog(false);
                    }}
                    onCompletionChanged={function (): void {
                        refetch();
                        setShowCompleteControlDialog(false);
                        setSelectedControl(undefined);
                    }}
                />
            )}

            {data && showReadyForRevisionDialog && (
                <RevisionDialog
                    declarationId={data.id}
                    declarationName={data.name}
                    auditDate={data.auditDate}
                    onDialogClose={() => setShowReadyForRevisionDialog(false)}
                    onReadyForRevisionSaved={() => {
                        refetch();
                        setShowReadyForRevisionDialog(false);
                    }}
                />
            )}

            {showAuditorNoteDialog && selectedControl && (
                <DotLegalNoteDialog
                    showDialog
                    noteText={selectedControl.auditorNote}
                    onDialogClose={() => {
                        setShowAuditorNoteDialog(false);
                        setSelectedControl(undefined);
                    }}
                    onOkClick={(text) => {
                        saveAuditorNote(selectedControl.id, text);
                        setSelectedControl(undefined);
                    }}
                    isSaving={isSavingAuditorNote}
                    disabled={data?.isApproved || !permissions.hasDeclarationAuditorApproval}
                    label={translateString("writeAuditorNote")}
                    header={translateString("auditorNote")}
                />
            )}

            {showAuditorDeviationNoteDialog && selectedControl && (
                <DotLegalNoteDialog
                    showDialog
                    noteText={selectedControl.auditorDeviationNote}
                    onDialogClose={() => {
                        setShowAuditorDeviationNoteDialog(false);
                        setSelectedControl(undefined);
                    }}
                    onOkClick={(text) => {
                        saveAuditorDeviationNote(selectedControl.id, text);
                        setSelectedControl(undefined);
                    }}
                    isSaving={isSavingAuditorDeviationNote}
                    disabled={data?.isApproved || !permissions.hasDeclarationAuditorApproval}
                    label={translateString("writeAuditorDeviations")}
                    header={translateString("auditorDeviations")}
                />
            )}

            {data && showCompleteDialog && <CompleteDeclarationDialog selectedDeclaration={data} onDialogClose={() => setShowCompleteDialog(false)} />}
        </React.Fragment>
    );

    function mapToDeclarationControlTableModel(controlsToDisplay: Array<DeclarationControlViewModel>): Array<DeclarationControlTableModel> {
        return controlsToDisplay.map((row) => {
            return {
                ...row,
                categoryString: row.category?.name ?? "",
                responsibleName: row.responsible.name,
                statusString: translateString(setFirstLetterToLowerCase(DeclarationControlStatusEnum[row.status].toString())),
            };
        });
    }
}

export default Declaration;
