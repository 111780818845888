import { DotLegalButton, DotLegalHeader, DotLegalRadioButton, DotLegalSelect, DotLegalTextField, DotLegalTooltip } from "@dotlegal/dotlegal-ui-components";
import DotLegalCollapse from "../../../../common/components/dotLegalCollapse/DotLegalCollapse";
import React from "react";
import { Box, Collapse, RadioGroup, Typography } from "@mui/material";
import DotLegalStepHeader from "../../../../common/components/dotLegalStepHeader/DotLegalStepHeader";
import { isNullOrWhitespace } from "../../../../common/stringOperations";
import { DotLegalDocumentType } from "../../../../documents/Documents.types";
import { filterTransferBasis } from "../../../../legalBasis/TransferBasis.Helper";
import { showTia, showTransferBasis } from "../../../../legalEntity/isInsecureThirdCountry";
import EditLegalEntityDocumentDialog from "../../../../legalEntity/legalEntityDocumentTab/editLegalEntityDocumentDIalog/EditLegalEntityDocumentDialog";
import { SelectableItem, SelectableLegalUnitsItem, StepTypeEnum } from "../../../../processingActivity/ProcessingActivity.types";
import {
    getSelectableDocumentItems,
    getSelectableTiaDocumentItems,
    getSelectedDocument,
    getSelectedDocuments,
} from "../../../../processingActivity/hooks/useDataProcessingAgreement";
import SharingsAgreementBox from "../../../../processingActivity/sharingOfDataStep/sharingsAgreementBox/sharingsAgreementBox";
import SharingsLegalEntity from "../../../../processingActivity/sharingOfDataStep/sharingsLegalEntityBox/sharingsLegalEntity";
import SegmentStep from "../../../../processingActivity/stepSegment/StepSegment";
import { useTranslation } from "../../../../localization/useTranslation";
import { SystemModel, SystemSupplierViewModel } from "../../SystemEdit.types";
import { useSystemSupplierStyles } from "./SystemSupplier.styles";
import { useUserContext } from "../../../../auth/userContextProvider/UserContextProvider";
import { DotLegalSelectOption } from "../../../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { usePlanContext } from "../../../../auth/planProvider/PlanProvider";
import { useSystemSupplierHooks } from "./SystemSupplier.hooks";
import { ValidationError } from "../../../../common/validationError";
import SystemSecondarySupplier from "../systemSecondarySupplier/SystemSecondarySupplier";
import SystemSupplierDescriptionDialog from "./systemSupplierDescriptionDialog/SystemSupplierDescriptionDialog";

export interface ISystemSupplier {
    supplier: SystemSupplierViewModel;
    readOnly: boolean;
    validationErrors: Array<ValidationError>;
    legalEntityData: SelectableLegalUnitsItem[] | undefined;
    legalEntityLoading: boolean;
    transferBasisData: SelectableItem[] | undefined;
    transferBasisLoading: boolean;
    countryList: Array<DotLegalSelectOption>;
    countriesLoading: boolean;
    system: SystemModel;
    getExpandedId: () => any;
    setExpandedId: (id: string) => void;
    onExpandedClick: () => void;
    removeSupplier: () => void;
}

function SystemSupplier(props: ISystemSupplier) {
    const { translateString, translateDocumentType } = useTranslation();
    const styles = useSystemSupplierStyles();
    const { permissions, gdpo } = useUserContext();
    const { informationSecurityFeatures } = usePlanContext();

    const {
        dataProcessorAgreementDocs,
        dataProcessorAgreementSaveModel,
        documentsLoading,
        refetchDocuments,
        saveAgreement,
        selectedDocument,
        setDataProcessorAgreementSaveModel,
        setSelectedDocument,
        setShowEditDocumentDialog,
        setShowSecondarySuppliers,
        showEditDocumentDialog,
        showSecondarySuppliers,
        subProcessorsTIADocs,
        subProcessorsTIADocsLoading,
        transferImpactAssessmentDocs,
        supplierUpdate,
        addSecondarySupplier,
        removeSecondarySupplier,
        setShowDescription,
        showDescription,
        isAddingSecondarySupplier,
    } = useSystemSupplierHooks(props);

    const hasSecondarySuppliers = props.supplier.secondarySuppliers?.length > 0 || showSecondarySuppliers;
    const hasDocuments = props.supplier.dataProcessorAgreementIds.length !== 0;
    let isThirdCountry = showTransferBasis(props.legalEntityData, props.supplier.legalEntityId);
    const selectedSupplier = props.legalEntityData?.find((x) => x.id === props.supplier.legalEntityId);

    const getGenerateSharingsRadioGroup = (generateSharings: boolean) => {
        const radioGroup = (
            <RadioGroup
                row
                value={generateSharings ? 1 : 0}
                onChange={(e) => {
                    var generateSharing = Number((e.target as HTMLInputElement).value) === 1 ? true : false;
                    supplierUpdate.onGenerateSharingsChange(generateSharing);
                }}
            >
                <DotLegalRadioButton
                    disabled={!informationSecurityFeatures.automaticRegistrationOfDataProcessorTransfers || props.readOnly}
                    value={1}
                    labelPlacement="end"
                    label={translateString("yes")}
                />
                <DotLegalRadioButton
                    disabled={!informationSecurityFeatures.automaticRegistrationOfDataProcessorTransfers || props.readOnly}
                    value={0}
                    label={translateString("no")}
                />
            </RadioGroup>
        );

        if (!informationSecurityFeatures.automaticRegistrationOfDataProcessorTransfers) {
            return <DotLegalTooltip text={translateString("upgradeDataProtectionOrInfoSecPlan")}>{radioGroup}</DotLegalTooltip>;
        }

        return radioGroup;
    };

    return (
        <DotLegalCollapse
            centerContent={
                <Typography sx={styles.collapseHeaderText}>{props.legalEntityData?.find((x) => x.id === props.supplier.legalEntityId)?.name}</Typography>
            }
            sx={{ marginBottom: 2 }}
            key={props.supplier.id}
            isExpanded={props.supplier.id === props.getExpandedId()}
            onExpandClick={() => props.onExpandedClick()}
            onRemoveClick={() => props.removeSupplier()}
            removeItemText={translateString("removeSupplier")}
            error={props.validationErrors.some((e) => e.field.startsWith(props.supplier.id.toString()))}
            disabled={props.readOnly}
        >
            <React.Fragment>
                <SegmentStep size="small">
                    <DotLegalStepHeader toolTipText={translateString("systemChoseDataProcessorTooltip")} record={false} topMargin>
                        {translateString("dataProcessorOnSystem")}
                    </DotLegalStepHeader>

                    <SharingsLegalEntity
                        isLoading={props.legalEntityLoading}
                        selectedItem={props.supplier.legalEntityId}
                        placeholder={translateString("supplier")}
                        disabled={props.readOnly}
                        label={translateString("supplier")}
                        options={props.legalEntityData}
                        toolTipText={translateString("systemDataProcessorTooltip")}
                        onLegalEntityChange={(processorWId) => {
                            supplierUpdate.onLegalEntityChange(processorWId);
                        }}
                        showAddNewEntityButton
                        isThirdCountry={isThirdCountry && permissions.systemDataProcessorPermissions?.hasTiaAndTransferBasis}
                    />

                    {permissions.systemDataProcessorPermissions.hasDataProcessorAgreementPermission && (
                        <SharingsAgreementBox
                            options={getSelectableDocumentItems(
                                dataProcessorAgreementDocs,
                                translateString,
                                translateDocumentType,
                                props.supplier.hasDataProcessorAgreement,
                                props.supplier.dataProcessorAgreementIds,
                                props.supplier.containsNotAccessibleDocuments
                            )}
                            showAddAgreementBox={!isNullOrWhitespace(props.supplier.legalEntityId ?? "") ? true : false}
                            getSelectedDocuments={getSelectedDocuments(
                                props.supplier.dataProcessorAgreementIds,
                                props.supplier.hasDataProcessorAgreement,
                                props.supplier.containsNotAccessibleDocuments
                            )}
                            saveAgreement={(agreementId) => saveAgreement(agreementId)}
                            id={props.supplier.legalEntityId}
                            disabled={props.readOnly}
                            onYesOrNoChange={(hasAgreement) => {
                                supplierUpdate.onHasAgreementChange(hasAgreement);
                            }}
                            dataProcessorAgreementSaveModel={dataProcessorAgreementSaveModel}
                            onAddNewAgreementClick={() => {
                                setDataProcessorAgreementSaveModel({
                                    legalEntityId: props.supplier.legalEntityId!,
                                    name: "",
                                    type: DotLegalDocumentType.DataProcessorAgreement,
                                    base64Data: "",
                                    fileName: "",
                                    link: "",
                                    isLink: false,
                                    owner: props.system.responsibleId,
                                    status: undefined,
                                    groupEntityAccessIds: [],
                                    allGroupEntities: gdpo || selectedSupplier!.isCustomerOwned,
                                    isGroupEntity: selectedSupplier?.isCustomerOwned,
                                });
                            }}
                            onChipClick={
                                hasDocuments
                                    ? (id) => {
                                          setSelectedDocument({
                                              documentId: id,
                                              legalEntityId: props.supplier.legalEntityId!,
                                              name: "",
                                              isGroupEntity: selectedSupplier?.isCustomerOwned,
                                          });
                                          setShowEditDocumentDialog(true);
                                      }
                                    : undefined
                            }
                            singleSelect={!hasDocuments}
                            isLoading={documentsLoading}
                            stepType={StepTypeEnum.dataprocessor}
                            onAddedAgreement={supplierUpdate.onAddAgreement}
                            onDeletedAgreement={supplierUpdate.onDeleteAgreement}
                        />
                    )}

                    <DotLegalStepHeader record={false} topMargin>
                        {translateString("describeSupplierProduction")}
                    </DotLegalStepHeader>

                    <DotLegalTextField
                        label={translateString("descriptionSupplierProduction")}
                        value={props.supplier.service}
                        debounce
                        onChange={supplierUpdate.onServiceChange}
                        disabled={props.readOnly}
                    />
                    <Typography onClick={() => setShowDescription(true)} sx={styles.descriptionLabel}>
                        {props.supplier.description || props.readOnly ? translateString("openDescription") : translateString("addDescription")}{" "}
                    </Typography>

                    <Box sx={{ marginTop: 4 }}>
                        <DotLegalStepHeader topMargin toolTipText={translateString("isTheSupplierADataProcessorToolTip")}>
                            {translateString("isTheSupplierADataProcessor")}
                        </DotLegalStepHeader>
                    </Box>
                    <Box id="isTheSupplierADataProcessorBox" sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 3 }}>
                        <DotLegalHeader headerStyle={"small"} fontWeight={100} marginBottom={0}>
                            {translateString("dataProcessor")}
                        </DotLegalHeader>
                        <RadioGroup
                            row
                            value={props.supplier.isSupplierADataProcessor ? 1 : 0}
                            onChange={(e) => {
                                var generateSharing = Number((e.target as HTMLInputElement).value) === 1 ? true : false;
                                supplierUpdate.onIsSupplierADataProcessorChange(generateSharing);
                            }}
                        >
                            <DotLegalRadioButton disabled={props.readOnly} value={1} labelPlacement="end" label={translateString("yes")} />
                            <DotLegalRadioButton disabled={props.readOnly} value={0} label={translateString("no")} />
                        </RadioGroup>
                    </Box>
                </SegmentStep>
            </React.Fragment>

            <React.Fragment>
                {props.supplier.isSupplierADataProcessor && (
                    <React.Fragment>
                        <SegmentStep size="small">
                            <React.Fragment>
                                {isThirdCountry && permissions.systemDataProcessorPermissions?.hasTiaAndTransferBasis && (
                                    <DotLegalSelect
                                        selectedItem={props.supplier.transferBasisId}
                                        isLoading={props.transferBasisLoading}
                                        label={translateString("transferBasis")}
                                        placeholder={translateString("transferBasis")}
                                        disabled={props.readOnly}
                                        options={filterTransferBasis(
                                            props.transferBasisData,
                                            props.legalEntityData?.find((x) => x.id === props.supplier.legalEntityId)?.countryCode
                                        )}
                                        toolTipText={translateString("systemDataProcessorDisclosureBasisTooltip")}
                                        errorText={props.validationErrors.find((v) => v.field === `${props.supplier.id}transferBasisId`)?.error}
                                        onChange={(transferBasis) => supplierUpdate.onTransferBasisChange(transferBasis)}
                                        noOptionsLabel={translateString("noOptions")}
                                    />
                                )}

                                {showTia(
                                    props.legalEntityData,
                                    props.supplier.legalEntityId,
                                    permissions.systemDataProcessorPermissions?.hasTiaAndTransferBasis,
                                    props.supplier.transferBasisId
                                ) && (
                                    <DotLegalSelect
                                        disableClearable
                                        selectedItem={getSelectedDocument(
                                            props.supplier.transferImpactAssessmentId,
                                            props.supplier.hasTransferImpactAssessment,
                                            props.supplier.containsNotAccessibleTia
                                        )}
                                        isLoading={documentsLoading}
                                        label={translateString("tia")}
                                        placeholder={translateString("tia")}
                                        disabled={props.readOnly}
                                        options={getSelectableTiaDocumentItems(
                                            transferImpactAssessmentDocs,
                                            translateString,
                                            translateDocumentType,
                                            props.supplier.hasTransferImpactAssessment,
                                            props.supplier.containsNotAccessibleTia
                                        )}
                                        toolTipText={translateString("tiaTooltip")}
                                        errorText={props.validationErrors.find((v) => v.field === `${props.supplier.id}transferImpactAssessment`)?.error}
                                        onChange={(tia) => supplierUpdate.onTransferImpactAssessmentChange(tia)}
                                        noOptionsLabel={translateString("noOptions")}
                                    />
                                )}
                            </React.Fragment>
                        </SegmentStep>

                        <DotLegalHeader marginTop={5} headerStyle="small">
                            {translateString("generateSharingsFromSystem")}
                        </DotLegalHeader>

                        <Box id="generateSharingsFromSystemBox" sx={{ display: "flex", alignItems: "center", gap: 3 }}>
                            <DotLegalHeader headerStyle={"small"} fontWeight={100} marginBottom={0}>
                                {translateString("automaticTransfers")}
                            </DotLegalHeader>
                            {getGenerateSharingsRadioGroup(props.supplier.generateSharings)}
                        </Box>
                    </React.Fragment>
                )}

                <React.Fragment>
                    {permissions.systemDataProcessorPermissions.hasSubProcessorPermission && (
                        <React.Fragment>
                            <Box>
                                <DotLegalHeader headerStyle="small" marginTop={5}>
                                    {translateString("supplierUseSecondarySuppliers")}
                                </DotLegalHeader>
                            </Box>
                            <SegmentStep size="small">
                                <Box
                                    id="supplierUseSecondarySuppliersBox"
                                    sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 3, marginBottom: 5 }}
                                >
                                    <DotLegalHeader headerStyle={"small"} fontWeight={100} marginBottom={0}>
                                        {translateString("secondarySuppliers")}
                                    </DotLegalHeader>
                                    <RadioGroup
                                        row
                                        value={hasSecondarySuppliers ? 1 : 0}
                                        onChange={(e) => {
                                            var showSecondarySuppliers = Number((e.target as HTMLInputElement).value) === 1 ? true : false;
                                            setShowSecondarySuppliers(showSecondarySuppliers);
                                        }}
                                    >
                                        <DotLegalRadioButton
                                            disabled={props.readOnly || props.supplier.secondarySuppliers.length > 0}
                                            value={1}
                                            labelPlacement="end"
                                            label={translateString("yes")}
                                        />
                                        <DotLegalRadioButton
                                            disabled={props.readOnly || props.supplier.secondarySuppliers.length > 0}
                                            value={0}
                                            label={translateString("no")}
                                        />
                                    </RadioGroup>
                                </Box>
                            </SegmentStep>

                            <Collapse in={hasSecondarySuppliers} style={{ width: "100%" }}>
                                {props.supplier.secondarySuppliers?.map((subprocessor, subProcessorIndex) => {
                                    return (
                                        <SystemSecondarySupplier
                                            supplierId={props.supplier.id}
                                            system={props.system}
                                            key={subprocessor.id}
                                            readOnly={props.readOnly}
                                            secondarySupplier={subprocessor}
                                            documentsLoading={subProcessorsTIADocsLoading}
                                            documentsData={subProcessorsTIADocs}
                                            secondarySupplierIndex={subProcessorIndex}
                                            legalEntitiesLoading={props.legalEntityLoading}
                                            legalEntityData={props.legalEntityData}
                                            transferBasisLoading={props.transferBasisLoading}
                                            transferBasisData={props.transferBasisData}
                                            onRemoveSecondarySupplier={() => removeSecondarySupplier(subprocessor.id)}
                                            validationErrors={props.validationErrors}
                                            countriesLoading={props.countriesLoading}
                                            countryList={props.countryList}
                                            supplierIsDataProcessor={props.supplier.isSupplierADataProcessor}
                                        />
                                    );
                                })}

                                <Box sx={{ marginTop: 3, display: "flex", justifyContent: "center", gap: 1 }}>
                                    <DotLegalButton
                                        buttonType="secondary"
                                        onClick={() => {
                                            addSecondarySupplier(false);
                                        }}
                                        disabled={props.readOnly}
                                        isLoading={isAddingSecondarySupplier}
                                    >
                                        {props.supplier.secondarySuppliers?.length > 0
                                            ? translateString("addAnotherSecondarySupplier")
                                            : translateString("addSecondarySupplier")}
                                    </DotLegalButton>

                                    <DotLegalButton buttonType="secondary" disabled={props.readOnly} onClick={() => addSecondarySupplier(true)}>
                                        {translateString("insertLinkToSystemSecondarySupplier")}
                                    </DotLegalButton>
                                </Box>
                            </Collapse>
                        </React.Fragment>
                    )}
                </React.Fragment>
            </React.Fragment>

            {showDescription && (
                <SystemSupplierDescriptionDialog
                    setShowDescriptionDialog={() => setShowDescription(false)}
                    readonly={props.readOnly}
                    description={props.supplier.description}
                    onOkClick={(description: string) => {
                        supplierUpdate.onDescriptionChange(description);
                        setShowDescription(false);
                    }}
                />
            )}

            {showEditDocumentDialog && (
                <EditLegalEntityDocumentDialog
                    onCloseDialog={() => {
                        setShowEditDocumentDialog(false);
                    }}
                    selectedDocument={selectedDocument!}
                    isExtended={false}
                    onSave={() => refetchDocuments()}
                    stepType={StepTypeEnum.dataprocessor}
                />
            )}
        </DotLegalCollapse>
    );
}

export default SystemSupplier;
