import { createSxStyles } from "../../common/createSxStyles";
import upgradePlan from "../../common/svgs/upgradePlan.svg?url";

export const usePlanDialogStyle = () => {
    return createSxStyles({
        dialog: {
            "& .MuiPaper-root": {
                backgroundImage: `url("${upgradePlan}")`,
                backgroundPosition: "top",
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 118%",
                height: 550,
            },
        },
        closeButtonWrapper: {
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: 1,
            paddingTop: 1,
        },
        dialogHeader: {
            textTransform: "uppercase",
        },
        dialogContent: {
            padding: 2,
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            alignItems: "center",
            marginLeft: 10,
            marginRight: 10,
        },
        dialogSection: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        link: {
            color: "#767676",
        },
        btnWrapper: {
            display: "flex",
            justifyContent: "center",
            gap: 2,
        },
    });
};
