import { useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";

export const useColumnTypeContextStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        radioGroup: {
            marginTop: theme.spacing(-2),
        },
    });
};
