import { Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "../../../localization/useTranslation";
import { DotLegalButton, DotLegalLink, DotLegalMultiSelect, DotLegalSelect } from "@dotlegal/dotlegal-ui-components";
import DotLegalPaper from "../dotLegalPaper/DotLegalPaper";
import { useDotLegalRecordHeaderDataMapping } from "./DotLegalRecordHeader.hooks";
import { useDotLegalRecordHeaderStyles } from "./DotLegalRecordHeader.styles";
import ExportSvg from "./export.svg?react";
import WhiteLockSvg from "../../svgs/whiteLock.svg?react";
import { usePlanContext } from "../../../auth/planProvider/PlanProvider";
import PlanDialogButtonWrapper from "../../../plan/planDialogButtonWrapper/planDialogButtonWrapper";

export interface IDotLegalRecordheader {
    processingActivityIds: string[];
    selectedGroupEntity: string | undefined | null;
    processingAreaIds: string[];
    setSelectedGroupEntity: (x: string | null) => void;
    setProcessingAreaIds: (x: string[]) => void;
    setProcessingActivityIds: (x: string[]) => void;
    isExporting: boolean;
    onExportClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
    disableExport: boolean;
    onlyUserSpecific: boolean;
    isProcessingActivityLimitExceeded?: boolean;
}

function DotLegalRecordHeader(props: IDotLegalRecordheader) {
    const styles = useDotLegalRecordHeaderStyles();
    const { dataProtectionFeatures } = usePlanContext();
    const { translateString } = useTranslation();
    const { groupEntities, isAreasLoading, isLoadingGroupEntities, isProcessingActivityNamesLoading, processingActivities, processingAreas } =
        useDotLegalRecordHeaderDataMapping(props);

    const getIcon = () => {
        return dataProtectionFeatures.recordsExport ? <ExportSvg /> : <WhiteLockSvg />;
    };

    return (
        <DotLegalPaper>
            <Grid container spacing={2} sx={styles.controls}>
                <Grid item xs={12} md={3}>
                    <DotLegalSelect
                        options={groupEntities}
                        onChange={props.setSelectedGroupEntity}
                        placeholder={translateString("groupEntities")}
                        label={translateString("groupEntities")}
                        isLoading={isLoadingGroupEntities}
                        selectedItem={props.selectedGroupEntity}
                        noMargin
                        helperText={translateString("groupEntityNeededForSearch")}
                        noOptionsLabel={translateString("noOptions")}
                        disabled={props.isProcessingActivityLimitExceeded}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <DotLegalMultiSelect
                        options={processingActivities}
                        chosenOptions={props.processingActivityIds}
                        onChange={props.setProcessingActivityIds}
                        placeholder={translateString("processingActivities")}
                        label={translateString("processingActivities")}
                        isLoading={isProcessingActivityNamesLoading}
                        noMargin
                        noOptionsLabel={translateString("noOptions")}
                        disabled={props.isProcessingActivityLimitExceeded}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <DotLegalMultiSelect
                        options={processingAreas}
                        chosenOptions={props.processingAreaIds}
                        onChange={props.setProcessingAreaIds}
                        placeholder={translateString("processingActivityAreas")}
                        label={translateString("processingActivityAreas")}
                        isLoading={isAreasLoading}
                        noMargin
                        noOptionsLabel={translateString("noOptions")}
                        disabled={props.isProcessingActivityLimitExceeded}
                    />
                </Grid>
                <Grid item xs={12} md={3} sx={styles.exportContainer}>
                    <PlanDialogButtonWrapper text={translateString("upgradeDataProtectionPlan")} enabled={!dataProtectionFeatures.recordsExport}>
                        <DotLegalButton
                            buttonType={"primary"}
                            isLoading={props.isExporting}
                            onClick={props.onExportClick}
                            disabled={props.disableExport}
                            endIcon={getIcon()}
                            toolTip={dataProtectionFeatures.recordsExport ? undefined : translateString("upgradeDataProtectionPlan")}
                        >
                            {translateString("export")}
                        </DotLegalButton>
                    </PlanDialogButtonWrapper>
                </Grid>
            </Grid>
        </DotLegalPaper>
    );
}

export default DotLegalRecordHeader;
