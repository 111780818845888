import { createSxStyles } from "../../createSxStyles";
import { useTheme } from "@mui/material";

export const useDotLegalSecurityPeriodMetStyles = () => {
    const theme = useTheme();

    return createSxStyles({
        container: {
            display: "inline-block",
            marginTop: theme.spacing(1),
        },
        name: {
            fontSize: theme.typography.pxToRem(12),
            color: theme.palette.primary.dark,
        },
        centerItem: {
            alignSelf: "center",
            marginRight: 1,
        },
    });
};
