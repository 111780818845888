import { useTheme } from "@mui/material";
import { createSxStyles } from "../../../common/createSxStyles";

export const useRiskAssessmentTemplateCollapseStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        collapse: {
            border: "none !important",
            backgroundColor: theme.palette.grey[100] + " !important",
            "& .MuiIconButton-label": {
                "& .MuiSvgIcon-root": {
                    fill: theme.palette.secondary.main,
                },
            },
        },
        selected: {
            backgroundColor: theme.palette.primary.light,
            border: "1px solid " + theme.palette.primary.dark,
        },
        collapseHeader: {
            marginBottom: theme.spacing(1),
        },
        businessAreas: {
            marginTop: theme.spacing(2),
        },
    });
};
