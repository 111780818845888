import { useState } from "react";
import { SystemSecondarySupplierViewModel } from "../../SystemEdit.types";
import { ISystemSecondarySupplier } from "./SystemSecondarySupplier";
import { useMutation, useQueryClient } from "react-query";
import { getSystemEditQueryKey } from "../../SystemEdit.hooks";
import { ajaxQueue } from "../../../../common/api/ajaxQueue";
import { put } from "../../../../common/api/apiShared";
import { EUToUSDataPrivacyFrameworkId } from "../../../../legalBasis/TransferBasis.Helper";
import { LevelOfProtectionType } from "../../../../legalEntity/addLegalEntityDialog/AddLegalEntityDialog.types";
import { isBool } from "../../../../common/booleanOperations";

export default function useSystemSecondarySupplierHooks(props: ISystemSecondarySupplier) {
    const queryClient = useQueryClient();

    const [onHover, setOnHover] = useState(false);

    const queryKey = getSystemEditQueryKey(props.system.id);

    const updateSecondarySupplierMutation = useMutation(updateSecondarySupplierAPI, {
        onSuccess: (data) => {
            const system = data.value();
            queryClient.setQueryData(queryKey, system);
        },
    });

    const secondarySupplierUpdater = {
        onTransferBasisChange: async (transferBasisId: string | null) => {
            await updateSecondarySupplier((s) => (s.transferBasisId = transferBasisId ?? undefined));
        },
        onAgreementChange: async (hasAgreement: boolean) => {
            await updateSecondarySupplier((s) => (s.hasDataProcessorAgreement = hasAgreement));
        },
        onDataProcessorChange: async (dataProcessorId: string | null) => {
            var selectedSubProcessor = props.legalEntityData?.find((x) => x.id === dataProcessorId);

            await updateSecondarySupplier((s) => {
                s.dataProcessorId = dataProcessorId ?? undefined;
                s.transferBasisId = undefined;
                s.transferImpactAssessmentId = undefined;
                s.hasTransferImpactAssessment = false;

                if (selectedSubProcessor?.levelOfProtection === LevelOfProtectionType.DataPrivacyFramework) {
                    s.transferBasisId = EUToUSDataPrivacyFrameworkId;
                }
            });
        },
        onTransferImpactAssessmentChange: async (tia: string | null) => {
            await updateSecondarySupplier((s) => {
                if (tia) {
                    if (isBool(tia)) {
                        let bool = tia.toLowerCase() === "true";
                        s.hasTransferImpactAssessment = bool;
                        s.transferImpactAssessmentId = undefined;
                    } else {
                        s.transferImpactAssessmentId = tia;
                        s.hasTransferImpactAssessment = false;
                    }
                }
            });
        },
        onSupportAccesssLocationChange: async (locations: Array<string>) => {
            await updateSecondarySupplier((s) => (s.supportAccessLocations = locations));
        },
        onDataStorageLocationChange: async (locations: Array<string>) => {
            await updateSecondarySupplier((s) => (s.dataStorageLocations = locations));
        },
        onIsSubDataProcessorChange: async (isSubDataProcessor: boolean) => {
            await updateSecondarySupplier((s) => {
                s.isSubDataProcessor = isSubDataProcessor;

                if (!isSubDataProcessor) {
                    s.hasTransferImpactAssessment = false;
                    s.transferBasisId = undefined;
                    s.transferImpactAssessmentId = undefined;
                }
            });
        },
        onLinkChange: async (link: string) => {
            await updateSecondarySupplier((s) => (s.link = link));
        },
    };

    const updateSecondarySupplier = async (changeFunc: (subProcessor: SystemSecondarySupplierViewModel) => void) => {
        const temp = { ...props.system };
        let supplier = temp.suppliers.find((x) => x.id === props.supplierId)!;
        let tempSecondarySupplier = supplier.secondarySuppliers.find((x) => x.id === props.secondarySupplier.id)!;

        changeFunc(tempSecondarySupplier);

        queryClient.setQueryData(queryKey, temp);
        await ajaxQueue().addRequest(updateSecondarySupplierMutation.mutateAsync, {
            subProcessor: tempSecondarySupplier,
        });
    };

    return { onHover, setOnHover, secondarySupplierUpdater };

    async function updateSecondarySupplierAPI(data: { subProcessor: SystemSecondarySupplierViewModel }) {
        return await put(`/system/${props.system.id}/supplier/${props.supplierId}/secondarySupplier/${data.subProcessor.id}`, data.subProcessor);
    }
}
