import React from "react";
import { Trans } from "react-i18next";
import { useTranslation } from "../../../localization/useTranslation";
import { DeletionPeriodViewModel } from "../deletionPeriods/DeletionPeriods.types";
import { useChangeDeletionPeriodStatusDialogHooks } from "./ChangeDeletionPeriodStatusDialog.hooks";
import { DotLegalInfoDialog } from "@dotlegal/dotlegal-ui-components";

export interface IChangeDeletionPeriodStatusDialog {
    selectedDeletionPeriod: DeletionPeriodViewModel;
    onCloseDialog: () => void;
    refetch: () => void;
}

export function ChangeDeletionPeriodStatusDialog(props: IChangeDeletionPeriodStatusDialog) {
    const { changeActiveStatus, inProgress } = useChangeDeletionPeriodStatusDialogHooks(props);
    const { translateString } = useTranslation();

    return (
        <DotLegalInfoDialog
            btnText={props.selectedDeletionPeriod.active ? translateString("deactivate") : translateString("activate")}
            header={props.selectedDeletionPeriod.active ? translateString("deactivateDeletionPeriodHeader") : translateString("activateDeletionPeriodHeader")}
            inProgress={inProgress}
            content={
                props.selectedDeletionPeriod.active ? (
                    <Trans i18nKey="deactivateDataInfo" values={{ name: props.selectedDeletionPeriod.name }}></Trans>
                ) : (
                    <Trans i18nKey="activateDataInfo" values={{ name: props.selectedDeletionPeriod.name }}></Trans>
                )
            }
            onBtnClick={() => changeActiveStatus(props.selectedDeletionPeriod)}
            onCloseDialog={props.onCloseDialog}
        />
    );
}
