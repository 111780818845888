import { DotLegalMultiSelect, DotLegalSelect, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import StepSegment from "../../../processingActivity/stepSegment/StepSegment";
import { useTranslation } from "../../../localization/useTranslation";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import { dataTypeOptions, entityTypeOptions, storageTypeOptions, useSystemDataAndStorageStepHooks } from "./SystemDataAndStorageStep.hooks";
import { SystemDataType, SystemEntityType, SystemStorageType } from "./SystemDataAndStorageStep.types";
import { usePlanContext } from "../../../auth/planProvider/PlanProvider";
import PlanInputFieldWrapper from "../../../plan/planInputFieldWrapper/PlanInputFieldWrapper";
import { PurpleLockIcon } from "../../../common/icons";

export interface ISystemDataAndStorageStep {
    dataTypes: Array<SystemDataType>;
    entityType?: SystemEntityType;
    storageType?: SystemStorageType;
    supportAccessLocations: Array<string>;
    dataStorageLocations: Array<string>;
    network?: string;
    onSupportAccesssLocationChange: (location: Array<string>) => void;
    onDataStorageLocationChange: (location: Array<string>) => void;
    onEntityTypeChange: (type: string | null) => void;
    onStorageTypeChange: (type: string | null) => void;
    onNetworkChange: (name: string) => void;
    onDataTypeChange: (types: Array<string>) => void;
    readOnly: boolean;
}

function SystemDataAndStorageStep(props: ISystemDataAndStorageStep) {
    const { informationSecurityFeatures, addonsFeatures } = usePlanContext();
    const { translateString } = useTranslation();
    const { permissions } = useUserContext();
    const { countriesLoading, countryList } = useSystemDataAndStorageStepHooks();

    return (
        <StepSegment size="small">
            {!addonsFeatures.research && (
                <PlanInputFieldWrapper hasAccess={informationSecurityFeatures.storageType} hoverText={translateString("upgradeInfoSecPlan")}>
                    <DotLegalSelect
                        options={storageTypeOptions(translateString)}
                        selectedItem={props.storageType === undefined || props.storageType === null ? null : props.storageType.toString()}
                        onChange={(type) => props.onStorageTypeChange(type)}
                        placeholder={translateString("storageType")}
                        label={translateString("storageType")}
                        noOptionsLabel={translateString("noOptions")}
                        toolTipText={translateString("storageTypeTooltip")}
                        disabled={props.readOnly || !informationSecurityFeatures.storageType}
                        icon={!informationSecurityFeatures.storageType ? PurpleLockIcon : undefined}
                    />
                </PlanInputFieldWrapper>
            )}

            {!addonsFeatures.research && (
                <PlanInputFieldWrapper hasAccess={informationSecurityFeatures.entityType} hoverText={translateString("upgradeInfoSecPlan")}>
                    <DotLegalSelect
                        options={entityTypeOptions(translateString)}
                        selectedItem={props.entityType === undefined || props.entityType === null ? null : props.entityType.toString()}
                        onChange={(type) => props.onEntityTypeChange(type)}
                        placeholder={translateString("entityType")}
                        label={translateString("entityType")}
                        noOptionsLabel={translateString("noOptions")}
                        toolTipText={translateString("entityType")}
                        disabled={props.readOnly || !informationSecurityFeatures.entityType}
                        icon={!informationSecurityFeatures.entityType ? PurpleLockIcon : undefined}
                    />
                </PlanInputFieldWrapper>
            )}

            {!addonsFeatures.research && (
                <PlanInputFieldWrapper hasAccess={informationSecurityFeatures.dataType} hoverText={translateString("upgradeInfoSecPlan")}>
                    <DotLegalMultiSelect
                        options={dataTypeOptions(translateString)}
                        chosenOptions={props.dataTypes.map((x) => x.toString())}
                        onChange={(types) => props.onDataTypeChange(types)}
                        placeholder={translateString("dataType")}
                        label={translateString("dataType")}
                        noOptionsLabel={translateString("noOptions")}
                        toolTipText={translateString("dataTypeTooltip")}
                        disabled={props.readOnly || !informationSecurityFeatures.dataType}
                        icon={!informationSecurityFeatures.dataType ? PurpleLockIcon : undefined}
                    />
                </PlanInputFieldWrapper>
            )}

            <DotLegalTextField
                label={translateString("network")}
                value={props.network ?? ""}
                onChange={(name) => props.onNetworkChange(name)}
                disabled={props.readOnly}
                toolTipText={translateString("systemNetworkTooltip")}
                debounce
            />

            {permissions.systemDataAndStoragePermissions.hasStorageLocationPermission && (
                <DotLegalMultiSelect
                    isLoading={countriesLoading}
                    options={countryList}
                    disabled={props.readOnly}
                    chosenOptions={props.dataStorageLocations}
                    placeholder={translateString("storageLocation")}
                    label={translateString("storageLocation")}
                    toolTipText={translateString("systemStorageTooltip")}
                    onChange={(countries) => props.onDataStorageLocationChange(countries)}
                    noOptionsLabel={translateString("noOptions")}
                />
            )}

            {permissions.systemDataAndStoragePermissions.hasSupportAccessPermission && (
                <DotLegalMultiSelect
                    isLoading={countriesLoading}
                    options={countryList}
                    disabled={props.readOnly}
                    chosenOptions={props.supportAccessLocations}
                    placeholder={translateString("supportAccess")}
                    label={translateString("supportAccess")}
                    toolTipText={translateString("systemSupportTooltip")}
                    onChange={(countries) => props.onSupportAccesssLocationChange(countries)}
                    noOptionsLabel={translateString("noOptions")}
                />
            )}
        </StepSegment>
    );
}

export default SystemDataAndStorageStep;
