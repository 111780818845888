import { useTheme } from "@mui/material";
import { createSxStyles } from "../common/createSxStyles";

export const useErrorStyles = () => {
    const theme = useTheme();
    const errorColor = "#182975";
    return createSxStyles({
        subContainer: {
            marginBottom: theme.spacing(1),
            textAlign: "center",
            marginLeft: 1,
            marginRight: 1,
        },
        errorText: {
            fontSize: theme.typography.pxToRem(23),
            fontWeight: theme.typography.fontWeightBold,
            whiteSpace: "pre-line",
            textAlign: "center",
            color: errorColor,
        },
        errorTextContact: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightBold,
            whiteSpace: "pre-line",
            textAlign: "center",
            color: errorColor,
            "& a": {
                textDecoration: "none",
                "&:visited": {
                    color: "inherit",
                },
                "&:active": {
                    color: "inherit",
                },
            },
        },
    });
};
