export interface HelpTextViewModel {
    id: string;
    header: string;
    content: string;
    type: HelpTextType;
}

export interface HelpTextSaveModel {
    id: string;
    header: string;
    content: string;
    type?: HelpTextType;
}

export enum HelpTextType {
    ProcessingActivityMasterData = 1,
    ProcessingActivityDataSubject = 2,
    ProcessingActivityAssociation = 3,
    ProcessingActivityDataCategory = 4,
    ProcessingActivityLegalBasis = 5,
    ProcessingActivitySystem = 6,
    ProcessingActivitySharing = 7,
    ProcessingActivityPolicy = 8,
}
