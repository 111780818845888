import { useTheme } from "@mui/material";
import { ILegalEntityTable } from "./LegalEntityTable";
import { createSxStyles } from "../../common/createSxStyles";

export const useLegalEntityTableStyles = (props: ILegalEntityTable) => {
    const theme = useTheme();
    return createSxStyles({
        remove: {
            fill: theme.palette.error.main,
        },
        noDataContainer: {
            height: "300px",
            width: "100%",
            backgroundColor: theme.palette.grey["100"],
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            border: props.errorText ? "2px solid " + theme.palette.error.main : "none",
            cursor: "pointer",
            borderRadius: "14px",
        },
        noDataInfo: {
            color: props.errorText ? theme.palette.error.main : theme.palette.grey.A400,
            marginTop: theme.spacing(2),
            fontWeight: theme.typography.fontWeightBold,
            fontSize: theme.typography.pxToRem(14),
        },
        btnContent: {
            marginTop: theme.spacing(2),
            display: "flex",
            justifyContent: "space-between",
        },
        noDataImage: {
            [theme.breakpoints.down("sm")]: {
                display: "none",
            },
        },
    });
};
