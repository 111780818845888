import { DotLegalDialog, DotLegalSelect } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { ProcessingActivityStatus } from "../../processingActivities/ProcessingActivities.types";
import { useProcessingActivityStatusDialogHooks } from "./ProcessingActivityStatusDialog.hooks";

export interface IProcessingActivityStatusDialog {
    Id: string | undefined;
    Status: ProcessingActivityStatus;
    onSaveClick: () => void;
    onDialogClose: () => void;
}

export default function ProcessingActivityStatusDialog(props: IProcessingActivityStatusDialog) {
    const { translateString } = useTranslation();
    const { processingActivityStatus, setProcessingActivityStatus, getStatusDotLegalSelectOptions, inProgress, saveForm } =
        useProcessingActivityStatusDialogHooks(props);

    return (
        <DotLegalDialog
            header={translateString("editStatus")}
            onDialogClose={() => props.onDialogClose()}
            buttonOkText={translateString("save")}
            open
            onOkClick={() => saveForm()}
            size="sm"
            inProgress={inProgress}
            disableBackdropOnCloseIfValueChange={processingActivityStatus}
        >
            <DotLegalSelect
                disableClearable
                options={getStatusDotLegalSelectOptions()}
                selectedItem={processingActivityStatus.toString()}
                onChange={(chosen) => {
                    setProcessingActivityStatus(Number(chosen));
                }}
                placeholder={translateString("status")}
                label={translateString("status")}
                noOptionsLabel={translateString("noOptions")}
            />
        </DotLegalDialog>
    );
}
