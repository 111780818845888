import { useState } from "react";
import { useParams } from "react-router-dom";
import { useDownloadLegalEntityAuditReport } from "../../DowloadLegalEntityAuditReport.hooks.ts";
import { AuditGroupHeaderProps } from "./AuditGroupHeader.tsx";

export function useAuditGroupHeader(props: AuditGroupHeaderProps) {
    const { id } = useParams<{ id: string }>();
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const { onDownloadManagementReport, isDownloadingManagementReport } = useDownloadLegalEntityAuditReport();

    const onDownloadManagementReportClick = async () => {
        await onDownloadManagementReport(id!, props.auditGroupModel?.name!, props.auditGroupModel?.sender ?? props.customerName);
    };

    return {
        showEditDialog,
        setShowEditDialog,
        id,
        showDeleteDialog,
        setShowDeleteDialog,
        onDownloadManagementReportClick,
        isDownloadingManagementReport,
    };
}
