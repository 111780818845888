import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useMutation } from "react-query";
import { deleteHttp } from "../../../common/api/apiShared";
import { createElement, useState } from "react";
import { Trans } from "react-i18next";
import { IDeleteClassificationDialog } from "./DeleteClassificationDialog";

export default function useDeleteClassificationDialogHooks(props: IDeleteClassificationDialog) {
    const snackbar = useDotLegalSnackbar();

    const [confirmDelete, setConfirmDelete] = useState(false);

    const removeMutation = useMutation(remove);

    const removeClassificationClick = () => {
        removeMutation.mutateAsync(undefined, {
            onSuccess: (response: any) => {
                props.onSuccessDelete();
                snackbar.show(createElement(Trans, { i18nKey: "classificationDeleted", values: { name: props.selectedClassification.questionnaireName } }));
            },
        });
    };

    return {
        removeClassificationClick,
        inProgress: removeMutation.isLoading,
        confirmDelete,
        setConfirmDelete,
    };

    async function remove() {
        return await deleteHttp(`/legalentityclassification/${props.selectedClassification.id}`);
    }
}
