import { useQuery, useQueryClient } from "react-query";
import { get } from "../../common/api/apiShared";
import { useTranslation } from "../../localization/useTranslation";
import {
    ProcessingActivitiesProgressViewModel,
    ProcessingActivityOverViewModel,
    ProcessingActivityOverViewRow,
    ProcessingActivityRiskRow,
    ProcessingActivityStatus,
    ProcessingActivityStatusEnum,
    ProcessingActivityValidationDueViewModel,
    SelectedProcessingActivityReport,
} from "./ProcessingActivities.types";
import { DotLegalSelectOption } from "../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { useStateUrlParamsArray } from "../../common/hooks/useStateUrlParams";
import { isNullOrWhitespace } from "../../common/stringOperations";
import { useState } from "react";
import { SelectableCompany } from "../../common/components/dotLegalCompanySelector/DotLegalCompanySelector.types";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { UserPermissions } from "../../auth/userContextProvider/UserContextProvider.types";
import { getEnumValues } from "../../common/enumOperations";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";
import { useIsOnSmallScreen } from "@dotlegal/dotlegal-ui-components";
import { useGroupEntitiesForUserQuery } from "../../common/hooks/useSelectableItemQueries";
import { useStateSessionStorage } from "../../common/hooks/useStateSessionStorage";
import { useDotLegalCompanySelector } from "../../common/components/dotLegalCompanySelector/GroupCompanySelectorContext";

export const processingActivityAllOnlyUserSpecificStorageKey = "processingActivitiesOnlyUserSpecific";
export function useProcessingActivitiesDataMapping() {
    const isOnSmallScreen = useIsOnSmallScreen();
    const { translateString, translateStatus } = useTranslation();
    const { permissions } = useUserContext();
    const { platformFeatures: platform, addonsFeatures: addons } = usePlanContext();
    const queryClient = useQueryClient();
    const [onlyUserSpecific, setOnlyUserSpecific] = useStateSessionStorage(processingActivityAllOnlyUserSpecificStorageKey, !permissions.canAccessAllData);
    const [searchedStatus, setSearchedStatus] = useStateUrlParamsArray("search", []);
    const [searchedDataCategories, setSearchedDataCategories] = useStateUrlParamsArray("dataCategories", []);
    const [searchedDataSubjects, setSearchedDataSubjects] = useStateUrlParamsArray("dataSubjects", []);
    const [searchedBusinessAreas, setSearchedBusinessAreas] = useStateUrlParamsArray("businessAreas", []);
    const [searchedRisks, setSearchedRisks] = useStateUrlParamsArray("risks", []);
    const [searchedPurposes, setSearchedPurposes] = useStateUrlParamsArray("purposes", []);
    const [status, setStatus] = useStateUrlParamsArray("status", []);
    const { selectedGroupCompany: selectedCompany } = useDotLegalCompanySelector();
    const [showAddStandardProcessings, setShowAddStandardProcessings] = useState(false);
    const [showUpdateProcessingTemplateInfo, setShowUpdateProcessingTemplateInfo] = useState({ showDialog: false, processingActivityId: "" });
    const [showProcessingActivityReport, setShowProcessingActivityReport] = useState<SelectedProcessingActivityReport | undefined>(undefined);

    const queryKey = "processingActivities" + onlyUserSpecific + selectedCompany;
    let { isLoading, data, isIdle } = useQuery(queryKey, () =>
        get<ProcessingActivityOverViewModel>(`/ProcessingActivities?onlyuserspecific=${onlyUserSpecific}&groupentityid=${selectedCompany}`)
    );

    let groupEntitiesForUserQuery = useGroupEntitiesForUserQuery();

    const progreesUrl = getProcessingActivitiesProgressUrl(onlyUserSpecific, selectedCompany);
    let progressQuery = useQuery(progreesUrl, () => get<ProcessingActivitiesProgressViewModel>(progreesUrl), {
        enabled: !isOnSmallScreen && data !== undefined && !addons.research,
    });

    const riskUrl = `/processingActivities/risk?onlyuserspecific=${onlyUserSpecific}&groupentityid=${selectedCompany}`;
    let riskQuery = useQuery(riskUrl, () => get<Array<ProcessingActivityRiskRow>>(riskUrl), {
        enabled: !isOnSmallScreen && data !== undefined && permissions.canManageRiskAssessment,
    });

    let dueProcessingQuery = useQuery("processingActivityValidationDue", () => {
        return get<Array<ProcessingActivityValidationDueViewModel>>("/processingActivityValidationDue");
    });
    let dueProcessingsLoading = dueProcessingQuery.isLoading;
    let dueProcessingsData = dueProcessingQuery.data;

    const deleteProcessingActivtitySuccess = (id: string) => {
        const newProcessingActivityModel = { ...data! };
        newProcessingActivityModel.processingActivityOverviewRows = data!.processingActivityOverviewRows.filter((x) => x.id !== id);
        queryClient.setQueryData(queryKey, newProcessingActivityModel);
    };

    let progressData: Array<DotLegalSelectOption> = [];
    let dataCategoryData: Array<DotLegalSelectOption> = [];
    let dataSubjectData: Array<DotLegalSelectOption> = [];
    let businessAreaData: Array<DotLegalSelectOption> = [];
    let selectableCompanyItems: Array<SelectableCompany> = [];
    let risksItems: Array<DotLegalSelectOption> = [];
    let statusItems: Array<DotLegalSelectOption> = [];
    let purposeData: Array<DotLegalSelectOption> = [];

    const [showCloneDialog, setShowCloneDialog] = useState(false);
    const [copyModel, setCopyModel] = useState({ id: "", name: "" });

    const onProcessingActivityDialogClose = () => {
        setShowAddStandardProcessings(false);
        queryClient.refetchQueries(queryKey);
    };

    const getFilteredRows = (overviewRow: Array<ProcessingActivityOverViewRow>) => {
        let tempData = [...overviewRow];

        if (searchedStatus.length > 0 && !isNullOrWhitespace(searchedStatus[0]))
            tempData = tempData.filter((activity) => {
                switch (activity.progress) {
                    case 0:
                        return searchedStatus.includes(ProcessingActivityStatusEnum.notStarted);
                    case 100:
                        return searchedStatus.includes(ProcessingActivityStatusEnum.completed);
                    default:
                        return searchedStatus.includes(ProcessingActivityStatusEnum.inProgress);
                }
            });

        if (searchedDataCategories.length > 0 && !isNullOrWhitespace(searchedDataCategories[0])) {
            tempData = tempData.filter((activity) => activity.dataCategoryIds.filter((x) => searchedDataCategories.includes(x)).length > 0);
        }

        if (searchedPurposes.length > 0 && !isNullOrWhitespace(searchedPurposes[0])) {
            tempData = tempData.filter((purpose) => purpose.purposeIds.filter((x) => searchedPurposes.includes(x)).length > 0);
        }

        if (searchedDataSubjects.length > 0 && !isNullOrWhitespace(searchedDataSubjects[0])) {
            tempData = tempData.filter((activity) => activity.dataSubjectIds.filter((x) => searchedDataSubjects.includes(x)).length > 0);
        }

        if (searchedBusinessAreas.length > 0 && !isNullOrWhitespace(searchedBusinessAreas[0])) {
            tempData = tempData.filter(
                (activity) =>
                    activity.businessAreaIds.filter((x) => searchedBusinessAreas.includes(x)).length > 0 ||
                    (searchedBusinessAreas.includes("nobusinessareas") && activity.businessAreaIds.length === 0)
            );
        }

        if (searchedRisks.length > 0 && !isNullOrWhitespace(searchedRisks[0])) {
            tempData = tempData.filter((activity) => {
                if (activity.riskAssessmentTotalScore === null) return searchedRisks.includes("null");

                return searchedRisks.includes(activity.riskAssessmentTotalScore!.toString());
            });
        }

        if (status.length > 0 && !isNullOrWhitespace(status[0])) {
            tempData = tempData.filter((p) => status.includes(p.processingActivityStatus.toString()));
        }

        return tempData;
    };

    function getProgressType(score: number) {
        switch (score) {
            case 0:
                return { name: translateString("progressTypeNotStarted"), id: ProcessingActivityStatusEnum.notStarted };
            case 100:
                return { name: translateString("progressTypeCompleted"), id: ProcessingActivityStatusEnum.completed };
            default:
                return { name: translateString("progressTypeInProgress"), id: ProcessingActivityStatusEnum.inProgress };
        }
    }

    let processingActivityData: ProcessingActivityOverViewModel = {
        businessAreaItems: [],
        dataCategoryItems: [],
        dataSubjectItems: [],
        processingActivityOverviewRows: [],
        purposeItems: [],
        currentRiskAssessmentVersionId: undefined,
        hasMultipleGroupCompanies: false,
    };

    const hasProcessingActivities = isIdle || isLoading || (data && data.processingActivityOverviewRows.length > 0);

    if (data) {
        processingActivityData = { ...data };

        data.processingActivityOverviewRows.forEach((x) => {
            createDataCategorySelectOptions(x.dataCategoryIds);
            createDataSubjectSelectOptions(x.dataSubjectIds);
            createBusinessAreaSelectOptions(x.businessAreaIds);
            createRiskSelectOptions(x.riskAssessmentTotalScore!);
            createPurposesSelectOptions(x.purposeIds);
        });

        businessAreaData.push({ id: "nobusinessareas", name: translateString("noBusinessAreas") });
        createStatusSelectOptions();

        sortSelectOptions();
    }

    if (riskQuery.data) {
        riskQuery.data.forEach((x) => {
            var processingActivity = processingActivityData.processingActivityOverviewRows!.find((r) => r.id === x.id);

            if (processingActivity) {
                processingActivity!.riskAssessmentTotalScore = x.riskAssessmentTotalScore;
                processingActivity!.scenariosScore = x.scenariosScore;
            }
        });
    }

    if (progressQuery.data) {
        progressQuery.data.rows.forEach((x) => {
            var processingActivity = processingActivityData.processingActivityOverviewRows!.find((r) => r.id === x.id);

            if (processingActivity) {
                processingActivity.progress = x.progressScore;
                processingActivity.canValidateProcessingActivity = x.canValidateProcessingActivity;
                createProgressTypeSelectOptions(x.progressScore!);
            }
        });
    }

    processingActivityData.processingActivityOverviewRows = getFilteredRows(processingActivityData.processingActivityOverviewRows);

    function createProgressTypeSelectOptions(status: number) {
        var progressType = getProgressType(status);

        if (!progressData.some((x) => x.id === progressType.id)) progressData.push(progressType);
    }

    function createDataCategorySelectOptions(dataCategoryIds: Array<string>) {
        dataCategoryIds.forEach((d) => {
            if (!dataCategoryData.some((x) => x.id === d)) {
                var dataCategory = data!.dataCategoryItems.find((x) => x.id === d);
                dataCategoryData.push(dataCategory!);
            }
        });
    }

    function createPurposesSelectOptions(purposeIds: Array<string>) {
        purposeIds.forEach((d) => {
            if (!purposeData.some((x) => x.id === d)) {
                var purpose = data!.purposeItems.find((x) => x.id === d);
                purposeData.push(purpose!);
            }
        });
    }

    function createStatusSelectOptions() {
        return getEnumValues(ProcessingActivityStatus).forEach((x) => {
            var name = translateStatus([x].toString());
            if (!statusItems.some((s) => s.id === x.toString())) {
                var processingActivitiesHasStatus = data!.processingActivityOverviewRows
                    .map((d) => ProcessingActivityStatus[d.processingActivityStatus].toString())
                    .find((t) => t === ProcessingActivityStatus[x].toString());

                if (processingActivitiesHasStatus) statusItems.push({ name: name, id: x.toString() });
            }
        });
    }

    function createDataSubjectSelectOptions(dataSubjectIds: Array<string>) {
        dataSubjectIds.forEach((d) => {
            if (!dataSubjectData.some((x) => x.id === d)) {
                var dataSubject = data!.dataSubjectItems.find((x) => x.id === d);
                dataSubjectData.push(dataSubject!);
            }
        });
    }

    function createBusinessAreaSelectOptions(businessAreaIds: Array<string>) {
        businessAreaIds.forEach((d) => {
            if (!businessAreaData.some((x) => x.id === d)) {
                var businessArea = data!.businessAreaItems.find((x) => x.id === d);
                businessAreaData.push(businessArea!);
            }
        });
    }

    function createRiskSelectOptions(number: number | null) {
        var value = String(null);

        if (number !== null) value = number?.toString();

        if (!risksItems.some((x) => x.id === value)) risksItems.push({ id: value, name: getRiskName(number) });
    }

    function getRiskName(score: number | null) {
        switch (score) {
            case 1:
                return translateString("veryLow");
            case 2:
                return translateString("low");
            case 3:
                return translateString("moderate");
            case 4:
                return translateString("high");
            case 5:
                return translateString("veryHigh");
            default:
                return translateString("notAnswered");
        }
    }

    function sortSelectOptions() {
        progressData.sort((a, b) => (a.id > b.id ? 1 : -1));
        dataCategoryData.sort((a, b) => (a.name > b.name ? 1 : -1));
        dataSubjectData.sort((a, b) => (a.name > b.name ? 1 : -1));
        businessAreaData.sort((a, b) => (a.name > b.name ? 1 : -1));
        risksItems.sort((a, b) => (a.id > b.id ? 1 : -1));
    }

    function refetchPage() {
        progressQuery.refetch();
        queryClient.refetchQueries(queryKey);
    }

    return {
        isLoading: isLoading || dueProcessingsLoading || groupEntitiesForUserQuery.isLoading,
        processingActivityData,
        deleteProcessingActivtitySuccess,
        onlyUserSpecific,
        setOnlyUserSpecific,
        progressData,
        dataCategoryData,
        dataSubjectData,
        businessAreaData,
        selectableCompanyItems: groupEntitiesForUserQuery.data ?? [],
        risksItems,
        searchedStatus,
        setSearchedStatus,
        searchedDataCategories,
        setSearchedDataCategories,
        searchedDataSubjects,
        searchedPurposes,
        setSearchedPurposes,
        purposeData,
        setSearchedDataSubjects,
        searchedBusinessAreas,
        setSearchedBusinessAreas,
        showAddStandardProcessings,
        setShowAddStandardProcessings,
        searchedRisks,
        setSearchedRisks,
        onProcessingActivityDialogClose,
        hasProcessingActivities,
        overAllStatus: progressQuery.data?.overallProgress ?? 0,
        showUpdateProcessingTemplateInfo,
        setShowUpdateProcessingTemplateInfo,
        refetchPage,
        dueProcessingsData,
        showCloneDialog,
        setShowCloneDialog,
        copyModel,
        setCopyModel,
        statusItems,
        status,
        setStatus,
        riskAndProgreesIsLoading: progressQuery.isLoading,
        showProcessingActivityReport,
        setShowProcessingActivityReport,
    };
}

export function hasAccessToProcessingActivitySteps(permissions: UserPermissions) {
    return (
        (permissions.processingActivityAssociationPermissions?.permissions.read &&
            permissions.processingActivityAssociationPermissions.hasAnySubPermissionAccess) ||
        permissions.processingActivityMasterDataPermissions?.permissions.read ||
        permissions.processingActivityDataSubjectPermissions?.permissions.read ||
        permissions.processingActivitySystemPermissions?.permissions.read ||
        permissions.processingActivityLegalBasesPermissions?.permissions.read ||
        permissions.processingActivityDataCategoryPermissions?.permissions.read ||
        permissions.processingActivityDataProcessorsPermissions?.permissions.read ||
        permissions.processingActivitySourcesPermissions?.permissions.read ||
        permissions.processingActivityDisclosuresPermissions?.permissions.read ||
        permissions.processingActivityDataControllersPermissions?.permissions.read
    );
}

export function getProcessingActivitiesProgressUrl(onlyUserSpecific: boolean, selectedCompany: string | undefined) {
    return `/processingActivities/progress?onlyuserspecific=${onlyUserSpecific}&groupentityid=${selectedCompany}`;
}
