import React, { MutableRefObject, useRef } from "react";
import { Box, Typography } from "@mui/material";
import { DotLegalButton, DotLegalPrettyScrollbar, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { useDotLegalTextFieldWithSnippetsStyles } from "./DotLegalTextFieldWithSnippets.styles";
import { isNullOrWhitespace } from "../../stringOperations";

// Temp types -> should be removed when we can export types through our library
export type inputVariant = "standard" | "outlined";
export type textDecoration = "line-through";

// Temp props -> should be removed when we can export types through our library
interface DotLegalTextFieldProps {
    label: string;
    value: string | null;
    disabled?: boolean;
    onChange?: (value: string) => void;
    multiline?: boolean;
    rows?: number;
    placeholder?: string;
    errorText?: string;
    size?: "small" | "medium";
    toolTipText?: string;
    isLoading?: boolean;
    handleNumber?: boolean;
    noMargin?: boolean;
    debounce: boolean;
    helperText?: string;
    hasError?: boolean;
    variant?: inputVariant;
    id?: string;
    alwaysShowUnderline?: boolean;
    textDecoration?: textDecoration;
    inputRef?: MutableRefObject<HTMLTextAreaElement | undefined>;
    pdfClassName?: string;
}

export interface IDotLegalTextFieldWithSnippets extends DotLegalTextFieldProps {
    snippets: Array<string>;
    error: string;
    select: string;
    disableValidation?: boolean;
    limitHeight?: boolean;
    children?: React.ReactElement;
}

function DotLegalTextFieldWithSnippets(props: IDotLegalTextFieldWithSnippets) {
    const styles = useDotLegalTextFieldWithSnippetsStyles(props);
    const inputRef = useRef<HTMLTextAreaElement>();

    return (
        <Box sx={styles.wrapper}>
            <Box sx={styles.container}>
                <Box sx={styles.margin}>
                    <DotLegalTextField inputRef={inputRef} multiline variant={"standard"} noMargin {...props} />

                    {props.snippets.length > 0 && <Typography sx={styles.snippetHeader}>{props.select}</Typography>}

                    <DotLegalPrettyScrollbar>
                        <Box sx={styles.snippetContainer}>
                            {props.snippets.map((snippet) => {
                                return (
                                    <Box sx={styles.snippets} key={snippet}>
                                        <DotLegalButton
                                            disabled={props.disabled}
                                            onClick={() => {
                                                const tempIndex = inputRef.current!.selectionStart;
                                                const snippetToInsert = `${isNullOrWhitespace(props.value ?? undefined) ? snippet : `\n${snippet}`}`;
                                                const temp =
                                                    props.value && inputRef.current
                                                        ? props.value.slice(0, tempIndex) + snippetToInsert + props.value.slice(tempIndex)
                                                        : snippetToInsert;
                                                props.onChange!(temp!);
                                                inputRef.current?.focus();
                                            }}
                                            size="small"
                                            buttonType="secondary"
                                        >
                                            {snippet}
                                        </DotLegalButton>
                                    </Box>
                                );
                            })}
                            {props.children}
                        </Box>
                    </DotLegalPrettyScrollbar>
                </Box>
            </Box>
            {props.error && <Typography sx={styles.error}>{props.error}</Typography>}
        </Box>
    );
}

export default DotLegalTextFieldWithSnippets;
