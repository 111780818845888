import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import { DotLegalSelectOption } from "../../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { useStateUrlParamsArray } from "../../../common/hooks/useStateUrlParams";
import { isNullOrWhitespace } from "../../../common/stringOperations";
import { LevelOfProtectionType } from "../../../legalEntity/addLegalEntityDialog/AddLegalEntityDialog.types";
import { useTranslation } from "../../../localization/useTranslation";
import { useUrlProvider } from "../../../useUrlProvider";
import { StepTypeEnum } from "../../ProcessingActivity.types";
import { ControllerProcess, SharingRowModel, SharingTypeEnum } from "../ProcessingActivityOverview.types";
import { IProcessingActivitySharingsTab } from "./ProcessingActivitySharingsTab";

export default function useProcessingActivitySharingsTabHooks(props: IProcessingActivitySharingsTab) {
    const { translateString } = useTranslation();
    const { permissions } = useUserContext();
    const { getEditProcessingActivityUrl } = useUrlProvider();

    const [searchedSenders, setSearchedSenders] = useStateUrlParamsArray("senders", []);
    const [searchedRecipients, setSearchedRecipients] = useStateUrlParamsArray("recipients", []);
    const [searchedSharingType, setSearchedSharingType] = useStateUrlParamsArray("sharingTypes", []);
    const [searchedLevelOfProtection, setsearchedLevelOfProtection] = useStateUrlParamsArray("levelOfProtection", []);
    const [searchedDataCategories, setSearchedDataCategories] = useStateUrlParamsArray("dataCategories", []);

    const filterRowsOnDataProcessorSearch = (activity: SharingRowModel) => {
        return (
            searchedSharingType.indexOf(SharingTypeEnum.dataProcessor.toString()) >= 0 &&
            (activity.sharingOrigin.systemId || activity.sharingOrigin.dataProcessorId)
        );
    };
    const filterRowsOnDisclosureSearch = (activity: SharingRowModel) => {
        return searchedSharingType.indexOf(SharingTypeEnum.disclosure.toString()) >= 0 && activity.sharingOrigin.transferId;
    };
    const filterRowsOnSourceSearch = (activity: SharingRowModel) => {
        return searchedSharingType.indexOf(SharingTypeEnum.source.toString()) >= 0 && activity.sharingOrigin.sourceId;
    };
    const filterRowsOnControllerSearch = (activity: SharingRowModel) => {
        return searchedSharingType.indexOf(SharingTypeEnum.dataController.toString()) >= 0 && activity.sharingOrigin.dataControllerId;
    };
    const filterRowsOnJointDataControllerSearch = (activity: SharingRowModel) => {
        return searchedSharingType.indexOf(SharingTypeEnum.jointDataController.toString()) >= 0 && activity.sharingOrigin.jointDataControllerId;
    };
    function recipientsContainsSearchedLevelOfProtection(sharing: SharingRowModel) {
        return searchedLevelOfProtection.indexOf(sharing.recipientLevelOfProtection.toString()) >= 0;
    }

    const getFilteredRows = (sharingRowModel: Array<SharingRowModel>) => {
        let tempData = [...sharingRowModel];

        if (searchedSharingType.length > 0 && !isNullOrWhitespace(searchedSharingType[0])) {
            tempData = tempData.filter(
                (activity) =>
                    filterRowsOnDataProcessorSearch(activity) ||
                    filterRowsOnDisclosureSearch(activity) ||
                    filterRowsOnSourceSearch(activity) ||
                    filterRowsOnControllerSearch(activity) ||
                    filterRowsOnJointDataControllerSearch(activity)
            );
        }

        if (searchedSenders.length > 0 && !isNullOrWhitespace(searchedSenders[0])) {
            tempData = tempData.filter((activity) => searchedSenders.indexOf(activity.senderId) >= 0);
        }

        if (searchedRecipients.length > 0 && !isNullOrWhitespace(searchedRecipients[0])) {
            tempData = tempData.filter((activity) => searchedRecipients.indexOf(activity.recipientId) >= 0);
        }

        if (searchedDataCategories.length > 0 && !isNullOrWhitespace(searchedDataCategories[0])) {
            tempData = tempData.filter((activity) => activity.dataCategories.filter((x) => searchedDataCategories.indexOf(x.id) >= 0).length > 0);
        }

        if (searchedLevelOfProtection.length > 0 && !isNullOrWhitespace(searchedLevelOfProtection[0])) {
            tempData = tempData.filter((activity) => recipientsContainsSearchedLevelOfProtection(activity));
        }

        return tempData;
    };

    const hasSharings = props.isLoading || props.sharings.length > 0;
    let data;
    if (props.sharings.length > 0) {
        data = {
            filteredRows: getFilteredRows(props.sharings).map((sharing) => {
                return {
                    name: sharing.name,
                    description: sharing.description,
                    senderName: sharing.senderName,
                    senderId: sharing.senderId,
                    senderRole: translateString(ControllerProcess[Number(sharing.senderRole)]),
                    dataCategories: sharing.dataCategories,
                    recipientName: sharing.recipientName,
                    recipientId: sharing.recipientId,
                    recipientRole: translateString(ControllerProcess[Number(sharing.recipientRole)]),
                    legalBasisList: sharing.legalBasisList,
                    transferBasis: sharing.transferBasis,
                    system: sharing.system,
                    systemId: sharing.systemId,
                    recipientInThirdCountry: sharing.recipientInThirdCountry,
                    subStep: sharing.subStep,
                    sharingId: sharing.sharingId,
                    sharingOrigin: sharing.sharingOrigin,
                    recipientLevelOfProtection: sharing.recipientLevelOfProtection,
                    deletionPeriods: sharing.deletionPeriods,
                    thirdCountryOrigin: sharing.thirdCountryOrigin,
                };
            }),
            datacategoriesData: props.dataCategories.map((datacategory) => {
                return {
                    name: datacategory.name,
                    id: datacategory.id,
                };
            }),
            legalEntitiesData: props.senders.map((entity) => {
                return {
                    name: entity.name,
                    id: entity.id,
                };
            }),
            recipientsEntitiesData: props.recipients.map((entity) => {
                return {
                    name: entity.name,
                    id: entity.id,
                };
            }),
            sharingsTypeOptions: createSharingTypeOptions(),
            sharingsLevelOfProtectionOptions: createLevelOfProtectionOptions(),
        };
    }

    function createSharingTypeOptions() {
        let sharingsTypeOptions: Array<DotLegalSelectOption> = [];

        if (props.sharings.some((x) => x.sharingOrigin.dataProcessorId || x.sharingOrigin.systemId)) {
            sharingsTypeOptions.push({ name: translateString("dataProcessors"), id: SharingTypeEnum.dataProcessor.toString() });
        }
        if (props.sharings.some((x) => x.sharingOrigin.dataControllerId)) {
            sharingsTypeOptions.push({ name: translateString("dataControllers"), id: SharingTypeEnum.dataController.toString() });
        }
        if (props.sharings.some((x) => x.sharingOrigin.transferId)) {
            sharingsTypeOptions.push({ name: translateString("disclosures"), id: SharingTypeEnum.disclosure.toString() });
        }
        if (props.sharings.some((x) => x.sharingOrigin.sourceId)) {
            sharingsTypeOptions.push({ name: translateString("sources"), id: SharingTypeEnum.source.toString() });
        }
        if (props.sharings.some((x) => x.sharingOrigin.jointDataControllerId)) {
            sharingsTypeOptions.push({ name: translateString("jointDataControllers"), id: SharingTypeEnum.jointDataController.toString() });
        }

        return sharingsTypeOptions;
    }

    function createLevelOfProtectionOptions() {
        let sharingsLevelOfProtectionOptions: Array<DotLegalSelectOption> = [];

        if (props.sharings.some((x) => x.recipientLevelOfProtection === LevelOfProtectionType.EU)) {
            sharingsLevelOfProtectionOptions.push({ name: translateString("eu"), id: LevelOfProtectionType.EU.toString() });
        }
        if (props.sharings.some((x) => x.recipientLevelOfProtection === LevelOfProtectionType.EEA)) {
            sharingsLevelOfProtectionOptions.push({ name: translateString("eea"), id: LevelOfProtectionType.EEA.toString() });
        }
        if (props.sharings.some((x) => x.recipientLevelOfProtection === LevelOfProtectionType.ThirdCountry)) {
            sharingsLevelOfProtectionOptions.push({ name: translateString("unsafeThirdCountry"), id: LevelOfProtectionType.ThirdCountry.toString() });
        }
        if (props.sharings.some((x) => x.recipientLevelOfProtection === LevelOfProtectionType.AdequateProtection)) {
            sharingsLevelOfProtectionOptions.push({ name: translateString("adequiteCountry"), id: LevelOfProtectionType.AdequateProtection.toString() });
        }

        return sharingsLevelOfProtectionOptions;
    }

    function getSharingLink(id: string, step: StepTypeEnum, subStep: StepTypeEnum | undefined, subId: string) {
        return getEditProcessingActivityUrl(id, step, subStep, subId);
    }

    function hasAccessToSharing(subStep: StepTypeEnum | undefined) {
        if (subStep === StepTypeEnum.sources) {
            return permissions.processingActivitySourcesPermissions?.permissions.read;
        } else if (subStep === StepTypeEnum.disclosure) {
            return permissions.processingActivityDisclosuresPermissions?.permissions.read;
        } else if (subStep === StepTypeEnum.dataController) {
            return permissions.processingActivityDataControllersPermissions?.permissions.read;
        } else if (subStep === StepTypeEnum.dataprocessor) {
            return permissions.processingActivityDataProcessorsPermissions?.permissions.read;
        } else {
            return permissions.processingActivityJointDataControllerPermissions?.permissions.read;
        }
    }

    return {
        searchedDataCategories,
        setSearchedDataCategories,
        searchedLevelOfProtection,
        setsearchedLevelOfProtection,
        searchedRecipients,
        setSearchedRecipients,
        searchedSenders,
        setSearchedSenders,
        searchedSharingType,
        setSearchedSharingType,
        data,
        getSharingLink,
        hasAccessToSharing,
        hasSharings,
    };
}
