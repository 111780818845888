import { DotLegalDialog, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { NationalLawSaveModel } from "./NationalLawDialog.types";
import { useNationalLawDialogDataMapping } from "./NationalLawDialog.hooks";
import DotLegalSelectWithButton from "../../../common/components/dotLegalSelectWithButton/DotLegalSelectWithButton";
import React from "react";

export interface INationalLawDialog {
    nationalLaw?: NationalLawSaveModel;
    open: boolean;
    nationalLawId?: string;
    isAdd: boolean;
    onDialogClose: () => void;
    onOkClick: (response: any, name: string) => void;
    refetch: () => void;
}

export default function NationalLawDialog(props: INationalLawDialog) {
    const { translateString } = useTranslation();
    const {
        nationalLawViewModel,
        setNationalLawViewModel,
        nationalLawErrors,
        isSaving,
        legislationItemsData,
        legislationItemsIsLoading,
        validateNationalLawName,
        validateNationalLawProvision,
        validateNationalLawLegislation,
        searchValue,
        setSearchValue,
        showDialogType,
        setShowDialogType,
        legislationViewmodel,
        setLegislationViewModel,
        legislationErrors,
        OnSave,
        setSearchedValue,
        validateLegislationName,
    } = useNationalLawDialogDataMapping(props);

    const onDialogClose = () => {
        if (showDialogType === "NationalLawDialog") props.onDialogClose();
        else setShowDialogType("NationalLawDialog");
    };

    const getDialogHeader = () => {
        if (showDialogType === "NationalLawDialog") return props.isAdd ? translateString("createNationalLaw") : translateString("editNationalLaw");
        else return translateString("createLegislation");
    };

    const getButtonText = () => {
        if (showDialogType === "NationalLawDialog") return props.isAdd ? translateString("create") : translateString("save");
        else return translateString("create");
    };

    return (
        <DotLegalDialog
            size="sm"
            inProgress={isSaving}
            header={getDialogHeader()}
            buttonOkText={getButtonText()}
            open={props.open}
            onDialogClose={onDialogClose}
            onOkClick={OnSave}
            disableBackdropOnCloseIfValueChange={nationalLawViewModel}
        >
            {showDialogType === "NationalLawDialog" ? (
                <React.Fragment>
                    <DotLegalTextField
                        label={translateString("name")}
                        value={nationalLawViewModel?.name!}
                        disabled={!props.isAdd && !nationalLawViewModel?.isCustom}
                        debounce={false}
                        errorText={nationalLawErrors.name}
                        onChange={(name) => {
                            let tempViewModel = { ...nationalLawViewModel! };
                            tempViewModel.name = name;
                            validateNationalLawName(name);
                            setNationalLawViewModel(tempViewModel);
                        }}
                    />

                    <DotLegalTextField
                        label={translateString("description")}
                        value={nationalLawViewModel?.description!}
                        debounce={false}
                        multiline
                        rows={5}
                        onChange={(description) => {
                            let tempViewModel = { ...nationalLawViewModel! };
                            tempViewModel.description = description;
                            setNationalLawViewModel(tempViewModel);
                        }}
                    />

                    <DotLegalTextField
                        label={translateString("provisionOrArticle")}
                        value={nationalLawViewModel?.provision!}
                        disabled={!props.isAdd && !nationalLawViewModel?.isCustom}
                        debounce={false}
                        errorText={nationalLawErrors.provision}
                        onChange={(provision) => {
                            let tempViewModel = { ...nationalLawViewModel! };
                            tempViewModel.provision = provision;
                            validateNationalLawProvision(provision);
                            setNationalLawViewModel(tempViewModel);
                        }}
                    />

                    <DotLegalTextField
                        label={translateString("section")}
                        value={nationalLawViewModel?.section!}
                        disabled={!props.isAdd && !nationalLawViewModel?.isCustom}
                        debounce={false}
                        onChange={(section) => {
                            let tempViewModel = { ...nationalLawViewModel! };
                            tempViewModel.section = section;
                            setNationalLawViewModel(tempViewModel);
                        }}
                    />

                    <DotLegalTextField
                        label={translateString("numberOrLitra")}
                        value={nationalLawViewModel?.number!}
                        disabled={!props.isAdd && !nationalLawViewModel?.isCustom}
                        debounce={false}
                        onChange={(number) => {
                            let tempViewModel = { ...nationalLawViewModel! };
                            tempViewModel.number = number;
                            setNationalLawViewModel(tempViewModel);
                        }}
                    />

                    <DotLegalTextField
                        label={translateString("cf")}
                        value={nationalLawViewModel?.cf!}
                        disabled={!props.isAdd && !nationalLawViewModel?.isCustom}
                        debounce={false}
                        onChange={(cf) => {
                            let tempViewModel = { ...nationalLawViewModel! };
                            tempViewModel.cf = cf;
                            setNationalLawViewModel(tempViewModel);
                        }}
                    />

                    <DotLegalSelectWithButton
                        errorText={nationalLawErrors.legislation}
                        label={translateString("legislation")}
                        options={legislationItemsData}
                        disabled={!props.isAdd && !nationalLawViewModel?.isCustom}
                        isLoading={legislationItemsIsLoading}
                        placeholder={""}
                        disableClearable
                        selectedItem={nationalLawViewModel?.legislationId === undefined || null ? null : nationalLawViewModel?.legislationId}
                        onChange={(legislationdId) => {
                            let tempViewModel = { ...nationalLawViewModel! };
                            tempViewModel.legislationId = legislationdId!;
                            validateNationalLawLegislation(legislationdId!);
                            setNationalLawViewModel(tempViewModel);
                        }}
                        hasPermission
                        buttonLabel={translateString("createNew")}
                        searchValue={searchValue}
                        onSearchValueChange={setSearchValue}
                        onButtonClick={() => {
                            setShowDialogType("LegislationDialog");
                            setSearchedValue();
                        }}
                        noOptionsLabel={translateString("noOptions")}
                    />
                </React.Fragment>
            ) : (
                <DotLegalTextField
                    label={translateString("name")}
                    value={legislationViewmodel?.name!}
                    debounce={false}
                    errorText={legislationErrors}
                    onChange={(name) => {
                        let tempViewModel = { ...legislationViewmodel! };
                        tempViewModel.name = name;
                        validateLegislationName(name);
                        setLegislationViewModel(tempViewModel);
                    }}
                />
            )}
        </DotLegalDialog>
    );
}
