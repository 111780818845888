import DotLegalDeleteDialogWrapper from "../../common/components/dotLegalDeleteDialog/DotLegalDeleteDialogWrapper";
import { DeleteAnnualWheelActivityTemplateDialogHook } from "./DeleteAnnualWheelActivityTemplateDialog.hooks";

export interface IDeleteAnnualWheelActivityTemplateDialog {
    onCloseDialog: () => void;
    onSaveCorrect: () => void;
    id: string;
    name: string;
}

function DeleteAnnualWheelActivityTemplateDialog(props: IDeleteAnnualWheelActivityTemplateDialog) {
    const { deleteTemplate, inProgress } = DeleteAnnualWheelActivityTemplateDialogHook(props);

    return (
        <DotLegalDeleteDialogWrapper
            itemForDeletion={props.name}
            onDialogClose={props.onCloseDialog}
            open
            onOkClick={() => deleteTemplate()}
            inProgress={inProgress}
        ></DotLegalDeleteDialogWrapper>
    );
}

export default DeleteAnnualWheelActivityTemplateDialog;
