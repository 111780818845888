import { Box, darken, useTheme } from "@mui/material";
import React from "react";
import { AuditGroupChartData } from "../AllocationOfRiskLevelChart.types";
import { useTranslation } from "../../../../../localization/useTranslation";
import { useControllerScoreGraphStyles } from "../AllocationOfRiskLevelChart.styles";
import { ResponsiveBar } from "@nivo/bar";
import { DotLegalHeader, useIsOnMediumScreen } from "@dotlegal/dotlegal-ui-components";
import { useRiskLevelChart } from "./RiskLevelChart.hooks";
import { RiskLevel } from "../../../../../legalEntity/legalEntityClassificationTab/LegalEntityClassificationTab.types";

export interface RiskLevelChartProps {
    chartHeader: React.ReactNode;
    isPdf: boolean;
    chartData: Array<AuditGroupChartData>;
    shouldDisplayHasWeightOfZero: boolean;
    width?: string;
}

function RiskLevelChart(props: RiskLevelChartProps) {
    const { translateString } = useTranslation();
    const { graphMaxValue } = useRiskLevelChart(props);

    const styles = useControllerScoreGraphStyles();
    const isOnMediumScreen = useIsOnMediumScreen();

    let graphWidth = props.isPdf || isOnMediumScreen ? "100%" : "60%";
    const theme = useTheme();

    const isPdf = props.isPdf;

    const redColor = "rgb(247, 68, 122, 0.5)";
    const yellowColor = "rgb(255, 234, 0, 0.5)";
    const greenColor = "rgb(44, 213, 196, 0.5)";
    const greyColor = "rgb(214, 214, 214, 0.5)";

    const getData = () => {
        return props.chartData.map((x) => {
            return {
                ...x,
                count: props.shouldDisplayHasWeightOfZero ? 0 : x.count,
            };
        });
    };

    return (
        <Box mt={3} className="avoid-page-break">
            <Box sx={{ display: "flex", gap: 1, justifyContent: "center" }}>{props.chartHeader}</Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    height: "245px",
                    width: props.width ? props.width : isPdf ? "270mm" : "100%",
                }}
            >
                <Box
                    sx={{
                        width: graphWidth,
                        height: "245px",
                        position: "relative",
                    }}
                >
                    <ResponsiveBar
                        data={getData()}
                        axisBottom={{
                            legend: translateString("percentage"),
                            legendPosition: "middle",
                            legendOffset: 40,
                        }}
                        axisLeft={{
                            legend: translateString("numberOfLegalEntities"),
                            legendPosition: "middle",
                            legendOffset: -40,
                            tickValues: [],
                        }}
                        indexBy="intervalName"
                        keys={["count"]}
                        margin={{ top: 10, right: 20, bottom: 50, left: 60 }}
                        padding={0.3}
                        maxValue={graphMaxValue}
                        labelFormat={(d) => (<tspan y={-10}>{d}</tspan>) as any}
                        isInteractive={false}
                        colors={(obj) => {
                            if (obj.data.riskLevel === RiskLevel.Medium) {
                                return yellowColor;
                            }

                            if (obj.data.riskLevel === RiskLevel.None || obj.data.riskLevel === undefined) {
                                return greyColor;
                            }

                            if (obj.data.riskLevel === RiskLevel.Low || obj.data.riskLevel === RiskLevel.VeryLow) {
                                return greenColor;
                            }

                            return redColor;
                        }}
                        borderColor={(obj) => {
                            if (obj.data.data.riskLevel === RiskLevel.Medium) {
                                return darken(yellowColor, 0.7);
                            }

                            if (obj.data.data.riskLevel === RiskLevel.None || obj.data.data.riskLevel === undefined) {
                                return darken(greyColor, 0.7);
                            }

                            if (obj.data.data.riskLevel === RiskLevel.Low || obj.data.data.riskLevel === RiskLevel.VeryLow) {
                                return darken(greenColor, 0.7);
                            }

                            return darken(redColor, 1);
                        }}
                        theme={{
                            text: {
                                fontWeight: "bold",
                                fill: theme.palette.primary.dark,
                                fontSize: 14,
                            },
                            axis: {
                                legend: {
                                    text: {
                                        fontWeight: "bold",
                                        fontSize: 14,
                                    },
                                },
                                ticks: {
                                    text: {
                                        fontWeight: "bold",
                                        fontSize: 11,
                                    },
                                },
                            },
                            legends: {
                                title: {
                                    text: {
                                        fontSize: 11,
                                        fill: "#333333",
                                        outlineWidth: 0,
                                        outlineColor: "transparent",
                                    },
                                },
                            },
                        }}
                    />
                    {props.shouldDisplayHasWeightOfZero && (
                        <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: "max-content" }}>
                            <DotLegalHeader marginBottom={0} headerStyle={"large"}>
                                {translateString("sectionHasWeightZero")}
                            </DotLegalHeader>
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
}

export default RiskLevelChart;
