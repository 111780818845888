import { useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";

export const useRiskQuestionDialogStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        headerWrapper: {
            display: "flex",
            justifyContent: "space-between",
            paddingRight: theme.spacing(2),
            paddingTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
        },
        spinnerWrapper: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            minHeight: 50,
        },
        content: {
            marginBottom: theme.spacing(6),
        },
        questionCategoryHeader: {
            color: theme.palette.primary.dark,
            fontSize: theme.typography.pxToRem(14),
            fontWeight: "bold",
            marginTop: theme.spacing(2),
        },
    });
};
