export interface EvaluationStatusSaveModel {
    evaluationStatus?: EvaluationStatus;
    evaluation?: string;
}

export enum EvaluationStatus {
    NotSatisfied = 1,
    PartlySatisfied = 2,
    Satisfied = 3,
}
