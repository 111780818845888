import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { post } from "../../common/api/apiShared";
import { useUrlProvider } from "../../useUrlProvider";

export function useProcessingActivityValidation() {
    const navigate = useNavigate();
    const { getProcessingActivityValidationUrl } = useUrlProvider();

    const createValidation = useMutation(createProcessingActivityValidation);
    async function addProcessingActivityValidation(processingActivityId: string) {
        await createValidation.mutateAsync(processingActivityId, {
            onSuccess: () => {
                navigate(getProcessingActivityValidationUrl(processingActivityId));
            },
        });
    }

    function createProcessingActivityValidation(processingActivityId: string) {
        return post<{}>("/ProcessingActivityValidations", { processingActivityId: processingActivityId });
    }

    return {
        addProcessingActivityValidation,
    };
}
