import React, { useState } from "react";
import { DotLegalDialog } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { ProcessingActivityStatus } from "../../processingActivity/processingActivities/ProcessingActivities.types";
import { useProcessingActivityReportDialogHooks } from "./ProcessingActivityReportDialog.hooks";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { ProcessingActivityStatusManagement } from "../../auth/userContextProvider/UserContextProvider.types";
import ProcessingActivityReportDialogHeader from "../processingActivityReportDialogHeader/ProcessingActivityReportDialogHeader";
import RegistrationOfResearchProjectReport from "../registrationOfResearchProjectReport/RegistrationOfResearchProjectReport";
import InternalAcquisitionOfSignatureReport from "../internalAcquisitionOfSignatureReport/InternalAcquisitionOfSignatureReport";
import { CustomerReportType } from "../../customer/addEditCustomerDialog/AddEditCustomer.types";
import ResearchEthicsApprovalReport from "../researchEthicsApprovalReport/ResearchEthicsApprovalReport";

export interface IProcessingActivtyReportDialog {
    processingActivityName: string | undefined;
    processingActivityId: string | undefined;
    onDialogClose: () => void;
    processingActivityStatus: ProcessingActivityStatus | undefined;
    onSubmitReport: () => void;
}

export default function ProcessingActivtyReportDialog(props: IProcessingActivtyReportDialog) {
    const { translateString } = useTranslation();
    const { permissions } = useUserContext();
    const { onDownloadPdfClick, isDownloadingPdf, submitReport, submitInProgress, reportType, setReportType } = useProcessingActivityReportDialogHooks(props);
    const canSubmitProcessingActivity =
        reportType !== CustomerReportType.ResearchEthicsApproval &&
        permissions.processingActivityStatusManagement &&
        permissions.processingActivityStatusManagement === ProcessingActivityStatusManagement.Draft &&
        (props.processingActivityStatus === ProcessingActivityStatus.Draft || props.processingActivityStatus === ProcessingActivityStatus.AwaitingResearcher);

    const [isValid, setIsValid] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    return (
        <DotLegalDialog
            setCustomHeader
            header={getHeader()}
            onDialogClose={props.onDialogClose}
            buttonOkText={translateString("submit")}
            secondaryButtonText={translateString("download")}
            open
            inProgress={submitInProgress}
            size={"lg"}
            hideOkButton={!canSubmitProcessingActivity}
            onOkClick={() => {
                if (reportType === CustomerReportType.RegistrationOfResearchProject) {
                    if (isValid) {
                        submitReport();
                    } else {
                        setIsSubmitting(true);
                    }
                } else {
                    submitReport();
                }
            }}
            onSecondaryButtonClick={() => onDownloadPdfClick()}
            secondaryButtonLoading={isDownloadingPdf}
            fullHeight
        >
            <React.Fragment>{getContent()}</React.Fragment>
        </DotLegalDialog>
    );

    function getHeader() {
        return <ProcessingActivityReportDialogHeader reportType={reportType} setReportType={setReportType} />;
    }

    function getContent() {
        switch (reportType) {
            case CustomerReportType.RegistrationOfResearchProject:
                return (
                    <RegistrationOfResearchProjectReport
                        isPDF={false}
                        processingActivityId={props.processingActivityId}
                        onValidChange={setIsValid}
                        showError={!isValid && isSubmitting}
                        processingActivityStatus={props.processingActivityStatus}
                    />
                );
            case CustomerReportType.InternalAcquisitionOfSignature:
                return <InternalAcquisitionOfSignatureReport isPDF={false} processingActivityId={props.processingActivityId} />;
            case CustomerReportType.ResearchEthicsApproval:
                return <ResearchEthicsApprovalReport processingActivityId={props.processingActivityId} />;
        }
    }
}
