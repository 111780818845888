import { createElement, useState } from "react";
import { Trans } from "react-i18next";
import { useMutation } from "react-query";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import { ProcessingActivityStatusManagement } from "../../../auth/userContextProvider/UserContextProvider.types";
import { put } from "../../../common/api/apiShared";
import { DotLegalSelectOption } from "../../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { getEnumValues } from "../../../common/enumOperations";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { ProcessingActivityStatus } from "../../processingActivities/ProcessingActivities.types";
import { IProcessingActivityStatusDialog } from "./ProcessingActivityStatusDialog";

export function useProcessingActivityStatusDialogHooks(props: IProcessingActivityStatusDialog) {
    const snackbar = useDotLegalSnackbar();
    const { translateStatus } = useTranslation();
    const { permissions } = useUserContext();
    const [processingActivityStatus, setProcessingActivityStatus] = useState<ProcessingActivityStatus>(props.Status);

    const saveMutation = useMutation(save);
    const updateProcessingActivityStatus = async () => {
        await saveMutation.mutateAsync(undefined, {
            onSuccess: () => {
                snackbar.show(
                    createElement(Trans, {
                        i18nKey: "processingActivityStatusUpdated",
                        values: { status: translateStatus(processingActivityStatus.toString()) },
                    })
                );
                props.onSaveClick();
                props.onDialogClose();
            },
        });
    };

    function getStatusDotLegalSelectOptions(): Array<DotLegalSelectOption> {
        var statusManagement = permissions.processingActivityStatusManagement;

        var includeedList: Array<string> = [];

        if (statusManagement === ProcessingActivityStatusManagement.Draft) {
            includeedList.push(ProcessingActivityStatus.Draft.toString());
            if (permissions.processingActivityStatusManagement !== ProcessingActivityStatusManagement.Draft) {
                includeedList.push(ProcessingActivityStatus.DraftSubmitted.toString());
            }
        }

        if (statusManagement === ProcessingActivityStatusManagement.Full) {
            if (props.Status === ProcessingActivityStatus.Draft) {
                includeedList.push(ProcessingActivityStatus.Draft.toString());
                includeedList.push(ProcessingActivityStatus.DraftSubmitted.toString());
            } else if (props.Status === ProcessingActivityStatus.AwaitingResearcher) {
                includeedList.push(ProcessingActivityStatus.AwaitingResearcher.toString());
                includeedList.push(ProcessingActivityStatus.DraftSubmitted.toString());
            } else {
                includeedList.push(ProcessingActivityStatus.Active.toString());
                includeedList.push(ProcessingActivityStatus.Archived.toString());
                includeedList.push(ProcessingActivityStatus.AwaitingResearcher.toString());
                includeedList.push(ProcessingActivityStatus.Cancelled.toString());
            }
        }

        if (statusManagement === ProcessingActivityStatusManagement.Regular) {
            includeedList.push(ProcessingActivityStatus.Active.toString());
            includeedList.push(ProcessingActivityStatus.Draft.toString());
            includeedList.push(ProcessingActivityStatus.Archived.toString());
        }

        var option = includeedList.find((x) => x === props.Status.toString());

        if (!option) {
            includeedList.push(props.Status.toString());
        }

        return getEnumValues(ProcessingActivityStatus)
            .filter((x) => includeedList.includes(x.toString()))
            .map((x) => {
                return {
                    name: translateStatus([x].toString()),
                    id: x.toString(),
                };
            });
    }

    return {
        processingActivityStatus,
        setProcessingActivityStatus,
        getStatusDotLegalSelectOptions,
        inProgress: saveMutation.isLoading,
        saveForm: updateProcessingActivityStatus,
    };

    function save() {
        return put(`/ProcessingActivity/${props.Id}/status/`, processingActivityStatus);
    }
}
