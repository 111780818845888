import { createElement, useState } from "react";
import { CertificationSaveModel } from "./CertificationDialog.types";
import { useMutation } from "react-query";
import { post, put } from "../../../common/api/apiShared";
import { ICertificationDialog } from "./CertificationDialog";
import { Result } from "../../../common/api/result";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { Trans } from "react-i18next";
import { useValidator } from "../../../common/hooks/validation";

export function useCertificationDialogHooks(props: ICertificationDialog) {
    const snackbar = useDotLegalSnackbar();
    const { translateString } = useTranslation();
    const [certification, setCertification] = useState<CertificationSaveModel>(getCertificationSaveModel(props.selectedCertification));

    const isEdit = props.certificationId !== undefined;

    const validation = useValidator<CertificationSaveModel>((validators) => ({
        name: validators.validateNotEmpty((item) => item.name, translateString("name")),
    }));

    const saveMutation = useMutation(save, {
        onSuccess: (response: Result<string>) => {
            if (response.success()) {
                props.onCertificationSaved(response.value());
                snackbar.show(createElement(Trans, { i18nKey: "dataCreated", values: { name: certification?.name } }));
            } else {
                snackbar.show(translateString("certificationExists"), "warning");
            }
        },
    });

    const createCertification = async () => {
        await saveMutation.mutateAsync();
    };

    function save() {
        return post<string>("/certifications/", certification);
    }

    const editMutation = useMutation(edit);

    function edit(doc: CertificationSaveModel) {
        return put<boolean>(`/certifications/${props.certificationId}`, doc);
    }

    const editCertification = () => {
        editMutation.mutateAsync(certification!, {
            onSuccess: (response: Result<boolean>) => {
                if (response.success()) {
                    props.onCertificationSaved("");
                    snackbar.show(translateString("certificationUpdated"));
                } else {
                    snackbar.show(translateString("certificationExists"), "warning");
                }
            },
        });
    };

    const onSaveClick = () => {
        if (validation.anyHasErrors) {
            validation.setShowErrors(true);
            return;
        }

        if (isEdit) {
            editCertification();
        } else {
            createCertification();
        }
    };

    const onNameChange = (name: string) => {
        let temp = { ...certification };
        temp.name = name;
        setCertification(temp);
    };

    const onDescriptionChange = (description: string) => {
        let temp = { ...certification };
        temp.description = description;
        setCertification(temp);
    };

    return {
        onSaveClick,
        inProgress: saveMutation.isLoading || editMutation.isLoading,
        isEdit,
        certification,
        onDescriptionChange,
        onNameChange,
        validation,
    };

    function getCertificationSaveModel(model?: CertificationSaveModel) {
        return {
            name: model?.name ?? "",
            description: model?.description ?? "",
        };
    }
}
