import React from "react";
import { useTranslation } from "../../../localization/useTranslation";
import { DotLegalDialog, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { useDotLegalNoteDialogHook } from "./DotLegalNoteDialog.hooks";

export interface IDotLegalNoteDialogProps {
    showDialog: boolean;
    noteText: string | undefined;
    onDialogClose: () => void;
    onOkClick: (note: string) => void;
    isSaving?: boolean;
    isLoading?: boolean;
    disabled?: boolean;
    label?: string;
    header?: string;
}

function DotLegalNoteDialog(props: IDotLegalNoteDialogProps) {
    const { translateString } = useTranslation();
    const { note, setNote } = useDotLegalNoteDialogHook(props);

    return (
        <DotLegalDialog
            isLoading={props.isLoading}
            size="sm"
            header={props.header ?? translateString("note")}
            buttonOkText={translateString("save")}
            open={props.showDialog}
            onDialogClose={props.onDialogClose}
            onOkClick={() => props.onOkClick(note)}
            inProgress={props.isSaving}
            okButtonDisabled={props.disabled}
            disableBackdropOnCloseIfValueChange={note}
        >
            <DotLegalTextField
                disabled={props.disabled}
                label={props.label ?? translateString("notelabel")}
                value={note}
                debounce={false}
                multiline
                rows={12}
                onChange={(text) => setNote(text)}
            />
        </DotLegalDialog>
    );
}

export default DotLegalNoteDialog;
