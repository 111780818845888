import { useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";

export const usePoliciesStyle = () => {
    const theme = useTheme();
    return createSxStyles({
        link: {
            color: theme.palette.secondary.main + "!important",
            textDecoration: "none",
            fontWeight: 700,
            "&:hover": {
                color: theme.palette.primary.main + "!important",
            },
        },
        bold: {
            fontWeight: theme.typography.fontWeightBold,
        },
    });
};
