import { useLocation, useNavigate } from "react-router-dom";
import { DateRange } from "../../processingActivity/processingActivityStatistics/ProcessingActivityStatistics.types";

export function useStateUrlParamsArray(key: string, defaultValue: Array<string>): [Array<string>, (v: Array<string>) => void] {
    const [value, setValue] = useStateUrlParams(key, defaultValue.join(","));
    return [
        typeof value !== "string" ? [] : value.split(","),
        (newValue: Array<string>) => {
            setValue(newValue.join(","));
        },
    ];
}

export function useStateUrlParams(key: string, defaultValue: string | null): [string | null, (v: string | null) => void] {
    const navigate = useNavigate();
    const location = useLocation();
    const valueFromParams = new URLSearchParams(location.search.toLowerCase());

    let value = defaultValue;
    let lowerCasedKey = key.toLowerCase();
    if (valueFromParams.has(lowerCasedKey)) {
        value = valueFromParams.get(lowerCasedKey);
    }
    return [
        value,
        (newValue) => {
            const urlSearchParams = new URLSearchParams(location.search);
            urlSearchParams.set(key, newValue === null ? "" : newValue.toString());
            navigate({
                pathname: location.pathname,
                search: urlSearchParams.toString(),
            });
        },
    ];
}

export function useStateUrlParamsNumber(key: string, defaultValue: number | null): [number | null, (n: number | null) => void] {
    const navigate = useNavigate();
    const location = useLocation();
    const valueFromParams = new URLSearchParams(location.search);
    let value = defaultValue;
    if (valueFromParams.has(key)) {
        value = Number(valueFromParams.get(key));
    }

    return [
        value,
        (newValue) => {
            const urlSearchParams = new URLSearchParams(location.search);
            urlSearchParams.set(key, newValue === null ? "" : newValue.toString());
            navigate({
                pathname: location.pathname,
                search: urlSearchParams.toString(),
            });
        },
    ];
}

export function useStateUrlParamsDate(key: string, defaultValue: Date | null): [Date | null, (n: Date | null) => void] {
    const navigate = useNavigate();
    const location = useLocation();
    const valueFromParams = new URLSearchParams(location.search);
    let value = defaultValue;
    if (valueFromParams.has(key)) {
        value = valueFromParams.get(key) === "" ? null : new Date(valueFromParams.get(key)!);
    }

    return [
        value,
        (newValue) => {
            const urlSearchParams = new URLSearchParams(location.search);
            urlSearchParams.set(key, newValue === null ? "" : newValue?.toISOString().substring(0, 10));
            navigate({
                pathname: location.pathname,
                search: urlSearchParams.toString(),
            });
        },
    ];
}

export function useStateUrlParamsDateRange(defaultValue: DateRange): [DateRange, (n: DateRange | null) => void] {
    const fromDateKey = "fromDate";
    const toDateKey = "toDate";

    const navigate = useNavigate();
    const location = useLocation();
    const valueFromParams = new URLSearchParams(location.search);
    let value = defaultValue;
    if (valueFromParams.has(fromDateKey) && valueFromParams.has(toDateKey)) {
        const fromDate = valueFromParams.get(fromDateKey) === "" ? null : new Date(valueFromParams.get(fromDateKey)!);
        const toDate = valueFromParams.get(toDateKey) === "" ? null : new Date(valueFromParams.get(toDateKey)!);
        value = new DateRange(fromDate, toDate);
    }

    return [
        value,
        (newValue) => {
            const urlSearchParams = new URLSearchParams(location.search);
            urlSearchParams.set(fromDateKey, newValue?.fromDate === null ? "" : newValue!.fromDate!.toISOString().substring(0, 10));
            urlSearchParams.set(toDateKey, newValue?.toDate === null ? "" : newValue!.toDate!.toISOString().substring(0, 10));
            navigate({
                pathname: location.pathname,
                search: urlSearchParams.toString(),
            });
        },
    ];
}
