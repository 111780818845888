import React from "react";
import { DotLegalDialog, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { useLegislationDialogDataMapping } from "./LegislationDialog.hooks";
import { LegislationSaveModel } from "./LegislationDialog.types";

export interface ILegislationDialog {
    legislation?: LegislationSaveModel;
    isAdd: boolean;
    onDialogClose: () => void;
    onSave: (response: any) => void;
}

export default function LegislationDialog(props: ILegislationDialog) {
    const { translateString } = useTranslation();
    const { viewModel, setViewModel, isSaving, onSaveClick, validation } = useLegislationDialogDataMapping(props);

    validation.check(viewModel);

    return (
        <DotLegalDialog
            size="sm"
            inProgress={isSaving}
            header={props.isAdd ? translateString("createLegislation") : translateString("editLegislation")}
            buttonOkText={props.isAdd ? translateString("create") : translateString("save")}
            open
            onDialogClose={props.onDialogClose}
            onOkClick={onSaveClick}
            disableBackdropOnCloseIfValueChange={viewModel}
        >
            <DotLegalTextField
                label={translateString("name")}
                value={viewModel?.name!}
                debounce={false}
                errorText={validation.getErrorsForDisplay("name")}
                onChange={(name) => {
                    let tempViewModel = { ...viewModel! };
                    tempViewModel.name = name;
                    setViewModel(tempViewModel);
                }}
            />
        </DotLegalDialog>
    );
}
