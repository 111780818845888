import React from "react";
import DotLegalCollapse from "../../../common/components/dotLegalCollapse/DotLegalCollapse";
import { useTranslation } from "../../../localization/useTranslation";
import { SelectableColoredItem } from "../../../processingActivity/ProcessingActivity.types";
import { useRiskAssessmentTemplateCollapseStyles } from "./RiskAssessmentTemplateCollapse.styles";
import { DotLegalButton, DotLegalChip, DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import RiskRadioGroup from "../../riskRadioGroup/RiskRadioGroup";
import { Box, Grid } from "@mui/material";
import { RiskAssessmentTemplateItemType } from "../RiskAssessmentTemplate.types";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import { RiskAssessmentArea } from "../../riskAssessments/RiskAssessments.types";
import { setFirstLetterToLowerCase } from "../../../common/stringOperations";

export interface IRiskAssessmentTemplate {
    componentKey: string;
    header: JSX.Element;
    score: number | null;
    probabilityScore: number | null;
    consequenceScore: number | null;
    onScoreChange: (type: RiskAssessmentTemplateItemType, newScore: number | null) => void;
    businessAreas: Array<SelectableColoredItem>;
    selectedBusinessAreas: Array<string>;
    onBusinessAreaToggled: (businessAreaId?: string, resetBusinessAreas?: boolean) => void;
    expandedKey: string;
    onExpandToggle: () => void;
    isQuestion: boolean;
    riskAssessmentArea: RiskAssessmentArea;
    isDisabled?: boolean;
}

function RiskAssessmentTemplateCollapse(props: IRiskAssessmentTemplate) {
    const { translateString } = useTranslation();
    const styles = useRiskAssessmentTemplateCollapseStyles();
    const { permissions } = useUserContext();

    const canEditTemplate = permissions.riskTemplatePermissions.edit && !props.isDisabled;

    const setDisabledResetButton = (type: RiskAssessmentTemplateItemType) => {
        if (type === RiskAssessmentTemplateItemType.Probability) return props.probabilityScore === null;
        if (type === RiskAssessmentTemplateItemType.Consequence) return props.consequenceScore === null;

        return props.score === null;
    };

    const isProcessingRiskArea = props.riskAssessmentArea === RiskAssessmentArea.ProcessingActivities;
    const getQuestion = (header: string, type: RiskAssessmentTemplateItemType, score: number | null) => {
        return (
            <>
                <DotLegalHeader
                    headerStyle={"small"}
                    toolTip={translateString("templateDefaultAnswerTooltip", {
                        riskAreaPlural: setFirstLetterToLowerCase(isProcessingRiskArea ? translateString("processingActivities") : translateString("systems")),
                        riskAreaSingular: setFirstLetterToLowerCase(isProcessingRiskArea ? translateString("processingActivity") : translateString("system")),
                    })}
                    marginBottom={0}
                >
                    {header}
                </DotLegalHeader>

                <RiskRadioGroup
                    showResetButton
                    disableResetButton={setDisabledResetButton(type) || !canEditTemplate}
                    isConsequenceQuestion={type === RiskAssessmentTemplateItemType.Consequence}
                    score={score}
                    onResetClick={() => props.onScoreChange(type, null)}
                    onScoreChange={(score) => props.onScoreChange(type, score)}
                    disabled={!canEditTemplate}
                />
            </>
        );
    };

    return (
        <Box sx={styles.collapseHeader} className={"collapse-header"}>
            <DotLegalCollapse
                key={props.componentKey}
                isExpanded={props.componentKey === props.expandedKey}
                onExpandClick={props.onExpandToggle}
                collapseName={props.header}
                sx={styles.collapse}
                leftAlign
                persistCollapseName
                hideCollapseCenterName
            >
                {props.isQuestion && (
                    <div>
                        {getQuestion(translateString("consequenceQuestionHeader"), RiskAssessmentTemplateItemType.Consequence, props.consequenceScore)}
                        <br />
                        {getQuestion(translateString("chooseStandardAnswer"), RiskAssessmentTemplateItemType.Probability, props.probabilityScore)}
                    </div>
                )}

                <Box sx={styles.businessAreas}>
                    <DotLegalHeader
                        headerStyle={"small"}
                        toolTip={
                            props.isQuestion ? translateString("templateQuestionBusinessAreaTooltip") : translateString("templateScenarioBusinessAreaTooltip")
                        }
                    >
                        {props.isQuestion ? translateString("chooseBusinessAreasForQuestion") : translateString("chooseBusinessAreasForScenario")}
                    </DotLegalHeader>
                    <Box display={"flex"}>
                        <Grid container spacing={1}>
                            {props.businessAreas.map((x) => {
                                return (
                                    <Grid key={x.id} item>
                                        <DotLegalChip
                                            value={x.name}
                                            id={x.id}
                                            isSelected={props.selectedBusinessAreas.includes(x.id)}
                                            onClick={() => {
                                                if (canEditTemplate) {
                                                    props.onBusinessAreaToggled(x.id);
                                                }
                                            }}
                                            color={x.color}
                                            disabled={!canEditTemplate}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                        <Box sx={(theme) => ({ whiteSpace: "nowrap" })}>
                            <DotLegalButton
                                buttonType={"primary"}
                                onClick={() => {
                                    if (canEditTemplate) {
                                        props.onBusinessAreaToggled(undefined, true);
                                    }
                                }}
                                disabled={!canEditTemplate || props.selectedBusinessAreas.length === 0}
                            >
                                {translateString("deselectAll")}
                            </DotLegalButton>
                        </Box>
                    </Box>
                </Box>
            </DotLegalCollapse>
        </Box>
    );
}

export default RiskAssessmentTemplateCollapse;
