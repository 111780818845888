import { useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";

export const useEditCustomDialogStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        templateContainer: {
            marginBottom: theme.spacing(2),
            marginLeft: 20 + "px",
            marginRight: 20 + "px",
            ".collapse-header": {
                marginBottom: theme.spacing(2),
            },
            height: "48px",
        },
        mandatoryContainer: {
            borderRadius: 10 + "px",
            marginLeft: 20 + "px",
            marginRight: 20 + "px",
            "& .MuiPaper-root": {
                backgroundColor: theme.palette.primary.light,
            },
            "& .MuiInputBase-root": {
                backgroundColor: theme.palette.background.paper,
            },
        },
    });
};
