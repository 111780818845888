import { DocumentUploadViewModel, DotLegalFile, useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { SystemDocumentDialogProps } from "./SystemDocumentDialog";
import { useTranslation } from "../../../../localization/useTranslation";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useValidator } from "../../../../common/hooks/validation";
import { useMutation } from "react-query";
import { Result } from "../../../../common/api/result";
import { post, put } from "../../../../common/api/apiShared";
import { _arrayBufferToBase64 } from "../../../../common/arrayBufferToBase64";
import { SystemDocumentSaveModel, SystemDocumentViewModel } from "../SystemDocuments.types";
import { isNullOrWhitespace } from "../../../../common/stringOperations";

export function useSystemDocumentDialog(props: SystemDocumentDialogProps) {
    const snackbar = useDotLegalSnackbar();
    const { translateString } = useTranslation();
    const isEdit = props.selectedDocument !== undefined;

    const [document, setDocument] = useState<SystemDocumentViewModel>(new SystemDocumentViewModel(props.selectedDocument?.name));
    const [documentUploadModel, setDocumentUploadModel] = useState<DocumentUploadViewModel>(
        new DocumentUploadViewModel(isEdit && !props.selectedDocument?.link ? [{ data: new ArrayBuffer(0), fileName: "" }] : [], props.selectedDocument?.link)
    );
    const { id } = useParams<{ id: string }>();

    const validation = useValidator<SystemDocumentViewModel>(
        (validators) => ({
            name: validators.validateNotEmpty((item) => item.name, translateString("name")),
        }),
        []
    );

    const editMutation = useMutation(edit, {
        onSuccess: (response: Result<string>) => {
            snackbar.show(translateString("documentWasUpdated"));
            props.onDialogClose();
            props.onSave();
        },
    });

    const saveMutation = useMutation(create, {
        onSuccess: (response: Result<string>) => {
            snackbar.show(translateString("documentCreated"));
            props.onDialogClose();
            props.onSave();
        },
    });

    function create(saveModel: SystemDocumentSaveModel) {
        return post<string>(`/System/${id}/Document`, saveModel);
    }

    function edit(saveModel: SystemDocumentSaveModel) {
        return put<string>(`/System/${props.selectedDocument?.documentId}/Document`, saveModel);
    }

    const save = async () => {
        validation.setShowErrors(true);

        if (validation.isValid && documentUploadModel.IsModelValid()) {
            if (isEdit) {
                await editMutation.mutateAsync(await mapToSaveModel());
            } else {
                await saveMutation.mutateAsync(await mapToSaveModel());
            }
        }
    };

    const mapToSaveModel = async () => {
        const hasFiles = documentUploadModel.files && documentUploadModel.files.length;
        const model: SystemDocumentSaveModel = {
            base64Data: hasFiles ? await _arrayBufferToBase64(documentUploadModel.files[0].data) : "",
            fileName: hasFiles ? documentUploadModel.files[0].fileName : "",
            name: document.name ?? "",
            link: documentUploadModel.link,
        };

        return model;
    };

    async function onDocumentUpload(files: DotLegalFile[] | undefined) {
        let tempDoc = { ...document! };

        let fileName = files && files!.length > 0 ? files![0].fileName : "";

        if (isNullOrWhitespace(tempDoc.name)) {
            tempDoc.name = fileName.substring(0, fileName.lastIndexOf("."));
        }

        setDocument(tempDoc);
    }

    function onNameChange(value: string) {
        let tempDoc = { ...document };

        tempDoc.name = value;
        setDocument(tempDoc);
    }

    return {
        document,
        save,
        validation,
        isEdit,
        onNameChange,
        isSaving: saveMutation.isLoading || editMutation.isLoading,
        documentUploadModel,
        setDocumentUploadModel,
        isLink: documentUploadModel.link ? true : false,
        onDocumentUpload,
    };
}
