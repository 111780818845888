import React from "react";
import MicroFrontend from "../microFrontend/MicroFrontend.tsx";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "../localization/useTranslation.ts";
import CustomizeIntroductionEmailDialog from "./customizeIntroductionEmailDialog/CustomizeIntroductionEmailDialog.tsx";
import CustomizeEmailLogoDialog from "./customizeEmailLogoDialog/CustomizeEmailLogoDialog.tsx";

export interface IQuestionnairesListOverviewProps {}

function QuestionnairesListOverview(props: IQuestionnairesListOverviewProps) {
    const location = useLocation();
    const { translateString } = useTranslation();
    const navigate = useNavigate();

    function onDialogClose() {
        navigate(location.pathname);
    }

    return (
        <>
            {location.search.includes("addLogoToEmails") && <CustomizeEmailLogoDialog onDialogClose={onDialogClose} />}
            {location.search.includes("customizeIntroductionEmails") && <CustomizeIntroductionEmailDialog onDialogClose={onDialogClose} />}
            <MicroFrontend />
        </>
    );
}

export default QuestionnairesListOverview;
