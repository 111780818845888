import { useState } from "react";
import { useQuery } from "react-query";
import { get } from "../../common/api/apiShared";
import { AuditFrequency, DeclarationsViewModel } from "./Declarations.types";
import { DotLegalSelectOption } from "../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { SelectableItem } from "../../processingActivity/ProcessingActivity.types";
import { isNullOrWhitespace, setFirstLetterToLowerCase } from "../../common/stringOperations";
import { useTranslation } from "../../localization/useTranslation";
import { useStateUrlParamsArray } from "../../common/hooks/useStateUrlParams";

export default function useDeclarationsHooks() {
    const [showAddDialog, setShowAddDialog] = useState(false);
    const [showGroupEntitiesDialog, setShowGroupEntitiesDialog] = useState({ groupEntities: [] as Array<SelectableItem>, entitiesWithoutAccess: 0 });
    const [searchedComplianceAreas, setSearchedComplianceAreas] = useStateUrlParamsArray("complianceAreas", []);
    const [searchedAuditFrequencies, setSearchedAuditFrequencies] = useStateUrlParamsArray("auditFrequencies", []);
    const [searchedGroupEntities, setSearchedGroupEntities] = useStateUrlParamsArray("groupEntities", []);

    const { translateString } = useTranslation();
    let url = "/declarations";
    let { isLoading, data } = useQuery(url, () => get<Array<DeclarationsViewModel>>(url));

    let complianceAreaFilterOptions: Array<DotLegalSelectOption> = [];
    let auditFrequencyFilterOptinos: Array<DotLegalSelectOption> = [];
    let groupEntitiesFilterOptions: Array<DotLegalSelectOption> = [];

    let rows: Array<DeclarationsViewModel> = [];
    if (data) {
        createComplianceAreaFilterOptions(data.flatMap((x) => x.complianceAreas));
        createAuditFrequencyFilterOptions(data.flatMap((x) => x.auditFrequency));
        createGroupEntittyFilterOptions(data.flatMap((x) => x.groupEntities));
        sortSelectOptions();

        rows = getFilteredRows(data);
    }

    return {
        isLoading,
        rows,
        showAddDialog,
        setShowAddDialog,
        hasData: isLoading || (data && data.length > 0),
        searchedComplianceAreas,
        setSearchedComplianceAreas,
        searchedAuditFrequencies,
        setSearchedAuditFrequencies,
        searchedGroupEntities,
        setSearchedGroupEntities,
        complianceAreaFilterOptions,
        auditFrequencyFilterOptinos,
        groupEntitiesFilterOptions,
        showGroupEntitiesDialog,
        setShowGroupEntitiesDialog,
    };

    function createComplianceAreaFilterOptions(complianceAreas: Array<SelectableItem>) {
        for (let complianceArea of complianceAreas) {
            if (!complianceAreaFilterOptions.some((x) => x.id === complianceArea.id)) {
                complianceAreaFilterOptions.push({ id: complianceArea.id, name: complianceArea.name });
            }
        }
    }

    function createAuditFrequencyFilterOptions(auditFrequencies: Array<AuditFrequency>) {
        auditFrequencies.forEach((frequency) => {
            if (frequency && !auditFrequencyFilterOptinos.some((x) => x.id === frequency?.toString())) {
                auditFrequencyFilterOptinos.push({ id: frequency.toString(), name: translateString(setFirstLetterToLowerCase(AuditFrequency[frequency])) });
            }
        });
    }
    function createGroupEntittyFilterOptions(groupEntities: Array<SelectableItem>) {
        for (let groupEntity of groupEntities) {
            if (!groupEntitiesFilterOptions.some((x) => x.id === groupEntity.id) && !isNullOrWhitespace(groupEntity.name)) {
                groupEntitiesFilterOptions.push({
                    id: groupEntity.id,
                    name: groupEntity.name,
                });
            }
        }

        if (groupEntities.some((x) => x.id === "null")) {
            groupEntitiesFilterOptions.push({
                id: "null",
                name: translateString("deletedGroupCompany"),
            });
        }
    }

    function sortSelectOptions() {
        complianceAreaFilterOptions.sort((a, b) => (a.name > b.name ? 1 : -1));
        auditFrequencyFilterOptinos.sort((a, b) => (a.name > b.name ? 1 : -1));
        groupEntitiesFilterOptions.sort((a, b) => (a.name === "" ? 1 : b.name === "" ? -1 : a.name.localeCompare(b.name)));
    }

    function getFilteredRows(rows: Array<DeclarationsViewModel>) {
        let tempData = [...rows];

        if (searchedAuditFrequencies.length > 0 && searchedAuditFrequencies[0] !== "") {
            tempData = tempData.filter((row) => searchedAuditFrequencies.indexOf(row.auditFrequency.toString()) >= 0);
        }

        if (searchedComplianceAreas.length > 0 && searchedComplianceAreas[0] !== "") {
            tempData = tempData.filter((row) => row.complianceAreas.filter((area) => searchedComplianceAreas.indexOf(area.id) >= 0).length > 0);
        }

        if (searchedGroupEntities.length > 0 && searchedGroupEntities[0] !== "") {
            tempData = tempData.filter(
                (row) =>
                    row.groupEntities.filter((grpupEntity) => searchedGroupEntities.indexOf(grpupEntity.id) >= 0).length > 0 ||
                    (searchedGroupEntities.includes("null") && row.groupEntities.some((x) => x.id === "null"))
            );
        }

        return tempData;
    }
}
