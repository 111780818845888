import { Box, Grid, Skeleton } from "@mui/material";
import DotLegalPaper from "../../common/components/dotLegalPaper/DotLegalPaper";
import { useTranslation } from "../../localization/useTranslation";
import { ProcessingActivityStatisticsDataMapping } from "./ProcessingActivityStatistics.hooks";
import React from "react";
import { useProcessingActivityStatisticsStyles } from "./processingActivityStatistics.styles";
import { DotLegalButton, DotLegalDatePicker, DotLegalHeader, DotLegalPageHeader, DotLegalSelect } from "@dotlegal/dotlegal-ui-components";
import { DateRange, ProcessingActivityStatisticsPeriodType } from "./ProcessingActivityStatistics.types";
import { isNullOrWhitespace } from "../../common/stringOperations";
import NewCaseCounter from "./components/NewCaseCounter";
import ContractCounter from "./components/ContractCounter";
import CompletedCases from "./components/CompletedCases";
import CompletedContractCounter from "./components/CompletedContractCounter";
import CaseManagerAverage from "./components/CaseManagerAverage";
import ResearcherAverage from "./components/ResearcherAverage";
import DraftSubmittedToActiveAverage from "./components/DraftSubmittedToActiveAverage";
import TotalNumberOfCases from "./components/TotalNumberOfCases";
import AdvisoryCases from "./components/AdvisoryCases";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";

export function getCounter(translation: string, value: number) {
    return (
        <Grid item xs={6}>
            <Box sx={{ display: "flex", justifyContent: "space-between", marginLeft: 1, marginRight: 1 }}>
                <div>{translation}:</div>
                <div>{value}</div>
            </Box>
        </Grid>
    );
}

export function getBoxWrapper(children: JSX.Element, key?: number) {
    return (
        <Grid key={key} item xs={12} sm={6} md={6} lg={4} xl={3} sx={(theme) => ({})}>
            {children}
        </Grid>
    );
}

function ProcessingActivityStatistics() {
    const { translateString, getDateTimePickerLanguage } = useTranslation();
    const { customerName } = useUserContext();
    const {
        isLoading,
        data,
        dateRange,
        setDateRange,
        selectablePeriods,
        period,
        onPeriodChange,
        caseManager,
        researcher,
        setCaseManager,
        setResearcher,
        users,
        usersIsLoading,
        downloadPdf,
        isDownloadingPdf,
    } = ProcessingActivityStatisticsDataMapping();
    const styles = useProcessingActivityStatisticsStyles();

    const breadCrumbs = [
        {
            name: translateString("processingActivities"),
            link: "/privacy/processingactivities",
        },
        {
            name: translateString("statistic"),
        },
    ];

    function getSkeletons(height: number, marginBottom: number) {
        return (
            <Grid container marginTop={1} spacing={4}>
                {[0, 1, 2, 3].map((i) => {
                    return getBoxWrapper(<Skeleton variant="rectangular" height={height} sx={{ marginBottom: marginBottom }}></Skeleton>, i);
                })}
            </Grid>
        );
    }
    const datePickerLanguage = getDateTimePickerLanguage();
    return (
        <React.Fragment>
            <DotLegalPageHeader userContext={{ customerName }} breadCrumbs={breadCrumbs} />
            <Box sx={{ marginBottom: 2 }}>
                <DotLegalPaper>
                    <React.Fragment>
                        <DotLegalHeader color="lightgrey" headerStyle="small">
                            {translateString("dateRange")}
                        </DotLegalHeader>

                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Box sx={{ width: "100%" }}>
                                <Grid container columnGap={2}>
                                    <Grid xs={2}>
                                        <DotLegalDatePicker
                                            value={dateRange!.fromDate}
                                            onChange={(date) => setDateRange(new DateRange(date, dateRange!.toDate))}
                                            label={translateString("from")}
                                            maxDate={dateRange?.toDate ?? undefined}
                                            error={!dateRange?.fromDate}
                                            helperMessage={translateString("fieldMandatory")}
                                            disabled={period !== ProcessingActivityStatisticsPeriodType.Custom.toString()}
                                            language={datePickerLanguage}
                                            noMargin
                                        />
                                    </Grid>
                                    <Grid xs={2}>
                                        <DotLegalDatePicker
                                            value={dateRange!.toDate}
                                            onChange={(date) => setDateRange(new DateRange(dateRange!.fromDate, date))}
                                            label={translateString("to")}
                                            minDate={dateRange!.fromDate ?? undefined}
                                            error={!dateRange!.toDate}
                                            helperMessage={translateString("fieldMandatory")}
                                            disabled={period !== ProcessingActivityStatisticsPeriodType.Custom.toString()}
                                            language={datePickerLanguage}
                                            noMargin
                                        />
                                    </Grid>
                                    <Grid xs={2}>
                                        <DotLegalSelect
                                            options={selectablePeriods()}
                                            onChange={(item) => onPeriodChange(item!)}
                                            selectedItem={period}
                                            placeholder={translateString("period")}
                                            label={translateString("period")}
                                            noOptionsLabel={""}
                                            noMargin
                                            disableClearable
                                        />
                                    </Grid>
                                    <Grid xs={2}>
                                        <DotLegalSelect
                                            isLoading={usersIsLoading}
                                            options={users?.caseManagers}
                                            onChange={(item) => setCaseManager(item ?? "")}
                                            selectedItem={caseManager}
                                            placeholder={translateString("caseManager")}
                                            label={translateString("caseManager")}
                                            noOptionsLabel={""}
                                            noMargin
                                            disabled={!isNullOrWhitespace(researcher ?? undefined)}
                                        />
                                    </Grid>
                                    <Grid xs={2}>
                                        <DotLegalSelect
                                            isLoading={usersIsLoading}
                                            options={users?.researchers}
                                            onChange={(item) => setResearcher(item ?? "")}
                                            selectedItem={researcher}
                                            placeholder={translateString("researcher")}
                                            label={translateString("researcher")}
                                            noOptionsLabel={""}
                                            noMargin
                                            disabled={!isNullOrWhitespace(caseManager ?? undefined)}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <DotLegalButton onClick={() => downloadPdf()} buttonType={"primary"} isLoading={isDownloadingPdf}>
                                {translateString("download")}
                            </DotLegalButton>
                        </Box>
                    </React.Fragment>
                </DotLegalPaper>
            </Box>

            <DotLegalPaper>
                <React.Fragment>
                    {isLoading ? (
                        getSkeletons(230, 1)
                    ) : (
                        <React.Fragment>
                            <Box sx={styles.paperContainer} paddingBottom={2}>
                                <Grid container spacing={4}>
                                    {getBoxWrapper(<NewCaseCounter newCaseCounter={data?.newCaseCounter} />)}
                                    {getBoxWrapper(<ContractCounter contractCounter={data?.contractCounter} />)}
                                    {getBoxWrapper(<CompletedCases completedCases={data?.completedCases} />)}
                                    {getBoxWrapper(<CompletedContractCounter completedContractCounter={data?.completedContractCounter} />)}
                                </Grid>
                            </Box>
                        </React.Fragment>
                    )}
                </React.Fragment>
            </DotLegalPaper>

            <Box sx={{ marginTop: 2 }}>
                <DotLegalHeader marginBottom={0} headerStyle="medium">
                    {translateString("casesDividedByProcessingTime")}
                </DotLegalHeader>
                <DotLegalPaper>
                    {isLoading ? (
                        getSkeletons(104, 3)
                    ) : (
                        <React.Fragment>
                            <Box sx={styles.paperContainer} paddingBottom={4}>
                                <Grid justifyContent="center" container spacing={4}>
                                    {getBoxWrapper(<CaseManagerAverage caseManagerAverage={data?.caseManagerAverage} />)}
                                    {getBoxWrapper(<ResearcherAverage researcherAverage={data?.researcherAverage} />)}
                                    {getBoxWrapper(<DraftSubmittedToActiveAverage submittedToActiveAverage={data?.submittedToActiveAverage} />)}
                                </Grid>
                            </Box>
                        </React.Fragment>
                    )}
                </DotLegalPaper>
            </Box>

            <Box sx={{ marginTop: 2 }}>
                <DotLegalHeader marginBottom={0} headerStyle="medium">
                    {translateString("casesDividedByStatus")}
                </DotLegalHeader>
                <DotLegalPaper fullHeight>
                    {isLoading ? (
                        getSkeletons(186, -1)
                    ) : (
                        <Box sx={styles.paperContainer}>
                            <Grid justifyContent="center" container spacing={4}>
                                {getBoxWrapper(<TotalNumberOfCases processingActivityCounter={data?.processingActivityCounter} />)}
                                {getBoxWrapper(<AdvisoryCases advisoryCases={data?.advisoryCases} />)}
                            </Grid>
                        </Box>
                    )}
                </DotLegalPaper>
            </Box>
        </React.Fragment>
    );
}

export default ProcessingActivityStatistics;
