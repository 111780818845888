/* eslint-disable no-console */
import { Plugin } from "vite";
import { getSettings } from "./settingsProvider.ts";

interface ErrorPayload {
    message: string;
    stack?: string;
}

export function consoleErrorToPluginCheckerOverlay(): Plugin {
    return {
        name: "vite-plugin-console-error-overlay",
        apply: "serve",
        configureServer(server) {
            server.middlewares.use((request, response, next) => {
                if (request.url === "/__console_error__" || request.url === "/__console_warn__") {
                    let body = "";
                    request.on("data", (chunk) => {
                        body += chunk.toString();
                    });
                    request.on("end", () => {
                        const error: ErrorPayload & { type: "error" | "warn" } = JSON.parse(body);
                        const timeId = `${new Date().getUTCMilliseconds()}`;
                        // Send event to Vite server, to be handled by vite-plugin-checker, and shown in its overlay
                        server.hot.send("vite-plugin-checker", {
                            event: "vite-plugin-checker:error",
                            data: {
                                checkerId: `consoleError${timeId}`,
                                diagnostics: [
                                    {
                                        message: error.message,
                                        stack: error.stack,
                                        id: `console-${error.type}`,
                                        checkerId: `Console ${error.type === "error" ? "Error" : "Warning"}`,
                                        level: error.type === "error" ? 1 : 2,
                                        frame: undefined,
                                        plugin: undefined,
                                        pluginCode: undefined,
                                        loc: {
                                            file: undefined,
                                            line: undefined,
                                            column: undefined,
                                        },
                                    },
                                ],
                            },
                        });
                        response.end("ok");
                    });
                } else {
                    next();
                }
            });
        },
    };
}

export function setupCaptureConsoleError(options?: { overwriteWarn: boolean; overwriteError: boolean; additionalErrorFilters: string[] }) {
    if (!getSettings().mainSiteBaseUrl.includes("localhost")) {
        return;
    }

    const originalConsoleError = console.error;
    const originalConsoleWarn = console.warn;
    const formatErrorMessage = (args: unknown[]): string => {
        const [message, ...rest] = args;
        if (typeof message === "string") {
            return message.replace(/%[sdifoO]/g, () => String(rest.shift()));
        }
        return args.join(" ");
    };
    const sendToViteServer = function (type: "error" | "warn", error: Error) {
        fetch(`/__console_${type}__`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                type: type,
                message: error.message,
                stack: error.stack ?? "",
            }),
        });
    };

    const isFilteredMessage = (error: Error) => {
        // Filtered: is potentially unsafe when doing server-side rendering
        // Reason: We don't use server-side rendering and can safely be ignore
        //
        // Filtered: Vue warn
        // Reason: We don't use Vue, not completely sure why it shows up.
        //
        // Filtered: Connect(Droppable): Support for defaultProps
        // Reason: Caused by 'react-beautiful-dnd', no longer developed, can be replaced with '@atlassian/pragmatic-drag-and-drop' at some point
        const filteredConsoleMessages = [
            ...(options?.additionalErrorFilters ?? []),
            "is potentially unsafe when doing server-side rendering.",
            "Vue warn",
            "Connect(Droppable): Support for defaultProps",
        ];
        return filteredConsoleMessages.some((filter) => error.message.includes(filter));
    };

    function captureConsole(type: "error" | "warn", args: unknown[]) {
        const error = args[0] instanceof Error ? args[0] : new Error(formatErrorMessage(args));
        if (isFilteredMessage(error)) return;
        if (type === "error") originalConsoleError.apply(console, args);
        else originalConsoleWarn(console, args);
        sendToViteServer(type, error);
    }

    if (options?.overwriteError !== false)
        console.error = function (...args: unknown[]) {
            captureConsole("error", args);
        };

    if (options?.overwriteWarn !== false)
        console.warn = function (...args: unknown[]) {
            captureConsole("warn", args);
        };
}
