import { useQuery } from "react-query";
import { get } from "../../common/api/apiShared";
import {
    DateRange,
    ProcessingActivityStatisticUsersViewModel,
    ProcessingActivityStatisticsPeriodType,
    ProcessingActivityStatisticsViewModel,
} from "./ProcessingActivityStatistics.types";
import { useState } from "react";
import { useTranslation } from "../../localization/useTranslation";
import { useStateUrlParams, useStateUrlParamsDateRange } from "../../common/hooks/useStateUrlParams";
import { useUrlProvider } from "../../useUrlProvider";
import { getSettings } from "../../common/settingsProvider";
import { createPdfUrl } from "../../common/pdfUrlHelper";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { downloadFile } from "../../common/api/downloadFile";

export function ProcessingActivityStatisticsDataMapping() {
    const { translateString } = useTranslation();
    const { getProcessingActivityStatisticPdfUrl } = useUrlProvider();
    const { language } = useUserContext();
    const [period, setPeriod] = useState<string>(ProcessingActivityStatisticsPeriodType.Week.toString());
    const [dateRange, setDateRange] = useStateUrlParamsDateRange(setDefaultFromDate());
    const [caseManager, setCaseManager] = useStateUrlParams("caseManager", "");
    const [researcher, setResearcher] = useStateUrlParams("researcher", "");
    const [isDownloadingPdf, setIsDownloadingPdf] = useState<boolean>(false);

    const urlKey = "processingActivityStatistics" + dateRange?.fromDate?.toDateString() + dateRange?.toDate?.toDateString() + caseManager + researcher;

    let { data, isLoading } = useQuery(urlKey, () =>
        get<ProcessingActivityStatisticsViewModel>(
            `/processingActivityStatistics?fromDateAsString=${dateRange?.fromDate?.toDateString()}&toDateAsString=${dateRange?.toDate?.toDateString()}&businessAreaId=${caseManager}&researcher=${researcher}`
        )
    );

    let userQuery = useQuery("processingActivituStatisticUsers", () => get<ProcessingActivityStatisticUsersViewModel>("/processingActivityStatistics/users"));

    function setDefaultFromDate() {
        let now = new Date();
        return new DateRange(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7), now);
    }

    const selectablePeriods = () => {
        return [
            { id: ProcessingActivityStatisticsPeriodType.Day.toString(), name: translateString("today") },
            { id: ProcessingActivityStatisticsPeriodType.Week.toString(), name: translateString("lastWeek") },
            { id: ProcessingActivityStatisticsPeriodType.Month.toString(), name: translateString("lastMonth") },
            { id: ProcessingActivityStatisticsPeriodType.ThreeMonths.toString(), name: translateString("last3Months") },
            { id: ProcessingActivityStatisticsPeriodType.Year.toString(), name: translateString("lastYear") },
            { id: ProcessingActivityStatisticsPeriodType.YDT.toString(), name: translateString("yearToDate") },
            { id: ProcessingActivityStatisticsPeriodType.Custom.toString(), name: translateString("custom") },
        ];
    };

    function onPeriodChange(period: string) {
        if (period !== ProcessingActivityStatisticsPeriodType.Custom.toString()) {
            setDateRange(getDateRangeByPeriod(period));
        }

        setPeriod(period);
    }

    function getDateRangeByPeriod(period: string): DateRange {
        let now = new Date();

        let dateFrom = new Date();
        let dateTo = new Date();

        switch (period) {
            case ProcessingActivityStatisticsPeriodType.Week.toString(): {
                dateFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
                break;
            }
            case ProcessingActivityStatisticsPeriodType.Month.toString(): {
                dateFrom = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
                break;
            }
            case ProcessingActivityStatisticsPeriodType.ThreeMonths.toString(): {
                dateFrom = new Date(now.getFullYear(), now.getMonth() - 3, now.getDate());
                break;
            }
            case ProcessingActivityStatisticsPeriodType.Year.toString(): {
                dateFrom = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
                break;
            }
            case ProcessingActivityStatisticsPeriodType.YDT.toString(): {
                dateFrom = new Date(now.getFullYear(), 0, 1);
                break;
            }
            default:
                break;
        }

        return new DateRange(dateFrom, dateTo);
    }

    async function downloadPdf() {
        const fileName = "processing-activity-statistics";
        setIsDownloadingPdf(true);
        let queryUrl =
            getSettings().mainSiteBaseUrl +
            getProcessingActivityStatisticPdfUrl() +
            `?fromDate=${dateRange?.fromDate?.toISOString().substring(0, 10)}&toDate=${dateRange?.toDate
                ?.toISOString()
                .substring(0, 10)}&caseManager=${caseManager}&researcher=${researcher}`;
        await downloadFile(getSettings().apiBaseUrl + "/pdf/generate?url=" + createPdfUrl(queryUrl, language), `${fileName}.pdf`).then((x) => {
            setIsDownloadingPdf(false);
        });
    }

    return {
        data,
        isLoading,
        dateRange,
        setDateRange,
        selectablePeriods,
        period,
        setPeriod,
        onPeriodChange,
        caseManager,
        setCaseManager,
        researcher,
        setResearcher,
        users: userQuery.data,
        usersIsLoading: userQuery.isLoading,
        downloadPdf,
        isDownloadingPdf,
    };
}
