import React from "react";
import { useTranslation } from "../localization/useTranslation";
import { ErrorBoundary } from "./ErrorBoundary";

export interface IErrorBoundaryWrapperProps {
    children: JSX.Element;
}

function ErrorBoundaryWrapper(props: IErrorBoundaryWrapperProps) {
    const { translateString } = useTranslation();
    return <ErrorBoundary errorText={translateString("unexpectedError")}>{props.children}</ErrorBoundary>;
}

export default ErrorBoundaryWrapper;
