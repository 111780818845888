import { useTheme } from "@mui/material";
import { createSxStyles } from "../../createSxStyles";
import { IDotLegalPersonalDataSelector } from "./DotLegalPersonalDataSelector";

export const useDotLegalPersonalDataSelectorStyles = (props: IDotLegalPersonalDataSelector) => {
    const theme = useTheme();
    return createSxStyles({
        root: {
            width: "100%",
            "& .data-category-box": {
                [theme.breakpoints.down("xs")]: {
                    flexDirection: "column",
                },
            },
            "& .data-category-box:not(:last-child)": {
                marginBottom: theme.spacing(3),
            },
            textAlign: "left",
        },
        infoHeaderGrid: {
            display: "flex",
            alignContent: "baseline",
        },
        infoHeader: {
            fontSize: theme.typography.pxToRem(12),
            color: theme.palette.primary.dark,
            fontWeight: theme.typography.fontWeightBold,
        },
        infoHeaderChildMargin: {
            marginRight: theme.spacing(2),
        },
        errorText: {
            fontSize: theme.typography.pxToRem(12),
            color: theme.palette.error.main,
            marginTop: theme.spacingAsNumber(2) * -1 + "px",
        },
    });
};
