import { Box, Grid } from "@mui/material";
import React, { Fragment } from "react";
import { DotLegalCheckbox, DotLegalChip, DotLegalDialog, DotLegalHeader, DotLegalSelect, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import LegalEntityTable from "../../../legalEntity/legalEntityTable/LegalEntityTable";
import { useTranslation } from "../../../localization/useTranslation";
import { SecurityMeasureSaveModel, SecurityMeasureType } from "../securityMeasures/SecurityMeasures.types";
import { useAddEditSecurityMeasureDialog } from "./AddEditSecurityMeasureDialog.hooks";
import { useAddEditSecurityMeasureDialogStyles } from "./AddEditSecurityMeasureDialog.styles";

export interface IAddEditSecurityMeasureProps {
    selectSecurityMeasure?: SecurityMeasureSaveModel;
    open: boolean;
    isAdd: boolean;
    onDialogClose: () => void;
    onOkClick: (id: string, name: string) => void;
    showGlobal?: boolean;
    refetch: () => void;
}

function AddEditSecurityMeasureDialog(props: IAddEditSecurityMeasureProps) {
    const styles = useAddEditSecurityMeasureDialogStyles();
    const { translateString } = useTranslation();
    const {
        viewModel,
        setViewModel,
        getSecurityMeasureTypeOptions,
        isSaving,
        errors,
        validateSecurityMeasureName,
        validateSecurityMeasureType,
        validateSecurityMeasureForm,
        businessAreaData,
        businessAreaIsLoading,
        toggleBusinessArea,
        businessAreaIds,
        groupEntityIsLoading,
        groupEntityData,
        groupEntityIds,
    } = useAddEditSecurityMeasureDialog(props);

    return (
        <DotLegalDialog
            size="md"
            inProgress={isSaving}
            header={props.isAdd ? translateString("createSecurityMeasure") : translateString("editSecurityMeasure")}
            buttonOkText={props.isAdd ? translateString("create") : translateString("save")}
            open={props.open}
            onDialogClose={props.onDialogClose}
            onOkClick={validateSecurityMeasureForm}
            disableBackdropOnCloseIfValueChange={viewModel}
        >
            <DotLegalTextField
                label={translateString("name")}
                disabled={!props.isAdd && !viewModel?.isCustom}
                value={viewModel?.name!}
                debounce={false}
                errorText={errors.nameError}
                onChange={(name) => {
                    let tempViewModel = { ...viewModel! };
                    tempViewModel.name = name;
                    validateSecurityMeasureName(name);
                    setViewModel(tempViewModel);
                }}
            />

            <DotLegalTextField
                label={translateString("description")}
                value={viewModel?.description!}
                debounce={false}
                multiline
                rows={5}
                onChange={(description) => {
                    let tempViewModel = { ...viewModel! };
                    tempViewModel.description = description;
                    setViewModel(tempViewModel);
                }}
            />

            <DotLegalSelect
                errorText={errors.securitymeasureTypeError}
                label={translateString("type")}
                options={getSecurityMeasureTypeOptions()}
                disabled={!props.isAdd && !viewModel?.isCustom}
                placeholder={""}
                disableClearable
                selectedItem={viewModel?.securityMeasureType === undefined || null ? null : viewModel?.securityMeasureType!.toString()}
                onChange={(type) => {
                    let tempViewModel = { ...viewModel! };
                    let sType = SecurityMeasureType[type! as keyof typeof SecurityMeasureType];
                    tempViewModel.securityMeasureType = Number(type!);
                    validateSecurityMeasureType(sType);
                    setViewModel(tempViewModel);
                }}
                noOptionsLabel={translateString("noOptions")}
            />

            {props.showGlobal && (
                <React.Fragment>
                    <Box sx={styles.globalContainer}>
                        <DotLegalCheckbox
                            tooltip={translateString("globalSecurityMeasureToolTip")}
                            label={translateString("global")}
                            checked={viewModel?.isGlobal!}
                            onChange={(isGlobal) => {
                                let tempViewModel = { ...viewModel! };
                                tempViewModel.isGlobal = isGlobal;

                                if (tempViewModel.isGlobal) {
                                    tempViewModel.businessAreaIds = businessAreaIds;
                                    tempViewModel.groupEntitiesIds = groupEntityIds;
                                } else {
                                    tempViewModel.businessAreaIds = [];
                                    tempViewModel.groupEntitiesIds = [];
                                }

                                setViewModel(tempViewModel);
                            }}
                        />
                    </Box>
                    {viewModel?.isGlobal && (
                        <Fragment>
                            <Box sx={styles.businessAreaContainer}>
                                <DotLegalHeader headerStyle={"small"}>{translateString("chooseBusinessAreaOnSecurityMeasure")}</DotLegalHeader>
                                <Grid container rowSpacing={2} columnSpacing={4}>
                                    {!businessAreaIsLoading &&
                                        businessAreaData!.map((x, index) => {
                                            return (
                                                <Grid xs={6} sm={4} md={3} key={x.id} item sx={styles.grid}>
                                                    <DotLegalChip
                                                        value={x.name}
                                                        id={x.id}
                                                        isSelected={viewModel.businessAreaIds.includes(x.id)}
                                                        onClick={() => toggleBusinessArea(x.id)}
                                                        color={x.color}
                                                    />
                                                </Grid>
                                            );
                                        })}
                                </Grid>
                            </Box>
                            <Box sx={styles.groupEntityAreaContaioner}>
                                <DotLegalHeader headerStyle={"small"}>{translateString("chooseBusinessAreaOnSecurityMeasure")}</DotLegalHeader>
                                <Grid container spacing={1}>
                                    <LegalEntityTable
                                        isLoading={groupEntityIsLoading}
                                        legalEntities={groupEntityData}
                                        selectedEntities={viewModel.groupEntitiesIds ?? []}
                                        showAddNewEntityButton={false}
                                        onLegalEntitiesChange={(entities) => {
                                            let tempViewModel = { ...viewModel! };
                                            tempViewModel.groupEntitiesIds = entities;
                                            setViewModel(tempViewModel);
                                        }}
                                        DontShowNoDataContainer
                                    />
                                </Grid>
                            </Box>
                        </Fragment>
                    )}
                </React.Fragment>
            )}
        </DotLegalDialog>
    );
}

export default AddEditSecurityMeasureDialog;
