import { useQuery } from "react-query";
import { get } from "../../common/api/apiShared";

export function useLegalBasesQuery() {
    let url = "/LegalBasis/LegalObligationId/";
    let query = useQuery(url, () => get<string>(url));

    return {
        legalObligationId: query.data ?? "",
    };
}
