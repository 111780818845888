import React from "react";
import { Box } from "@mui/material";

export interface IDotLegalTabPanel {
    children?: React.ReactNode;
    dir?: string;
    index: any;
    value: any;
    noPadding?: boolean;
}

export const dotLegalTabPanelNoPadding = 0.5;
function DotLegalTabPanel(props: IDotLegalTabPanel) {
    const { children, value, index, noPadding, ...other } = props;

    const padding = noPadding ? 0 : 3;
    return (
        <Box role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ paddingTop: noPadding ? dotLegalTabPanelNoPadding : 3, paddingLeft: padding, paddingRight: padding, paddingBottom: padding }}>
                    {children}
                </Box>
            )}
        </Box>
    );
}

export default DotLegalTabPanel;
