import { createElement, useState } from "react";
import { useMutation } from "react-query";
import { post, put } from "../../../common/api/apiShared";
import { Result } from "../../../common/api/result";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { Trans } from "react-i18next";
import { useValidator } from "../../../common/hooks/validation";
import { AreaSaveModel } from "./AreaDialog.types";
import { IAreaDialog } from "./AreaDialog";

export function useAreaDialogHooks(props: IAreaDialog) {
    const snackbar = useDotLegalSnackbar();
    const { translateString } = useTranslation();
    const [area, setArea] = useState<AreaSaveModel>(getAreaSaveModel(props.selectedArea));

    const isEdit = props.areaId !== undefined;

    const validation = useValidator<AreaSaveModel>((validators) => ({
        name: validators.validateNotEmpty((item) => item.name, translateString("name")),
    }));

    const saveMutation = useMutation(save, {
        onSuccess: (response: Result<string>) => {
            if (response.success()) {
                props.onAreaSaved(response.value());
                snackbar.show(createElement(Trans, { i18nKey: "dataCreated", values: { name: area?.name } }));
            } else {
                snackbar.show(translateString("areaExists"), "warning");
            }
        },
    });

    const createArea = async () => {
        await saveMutation.mutateAsync();
    };

    function save() {
        return post<string>("/areas/", area);
    }

    const editMutation = useMutation(edit);

    function edit(doc: AreaSaveModel) {
        return put<boolean>(`/areas/${props.areaId}`, doc);
    }

    const editArea = () => {
        editMutation.mutateAsync(area!, {
            onSuccess: (response: Result<boolean>) => {
                if (response.success()) {
                    props.onAreaSaved("");
                    snackbar.show(translateString("areaUpdated"));
                } else {
                    snackbar.show(translateString("areaExists"), "warning");
                }
            },
        });
    };

    const onSaveClick = () => {
        if (validation.anyHasErrors) {
            validation.setShowErrors(true);
            return;
        }

        if (isEdit) {
            editArea();
        } else {
            createArea();
        }
    };

    const onNameChange = (name: string) => {
        let temp = { ...area };
        temp.name = name;
        setArea(temp);
    };

    const onDescriptionChange = (description: string) => {
        let temp = { ...area };
        temp.description = description;
        setArea(temp);
    };

    return {
        onSaveClick,
        inProgress: saveMutation.isLoading || editMutation.isLoading,
        isEdit,
        area,
        onDescriptionChange,
        onNameChange,
        validation,
    };

    function getAreaSaveModel(model?: AreaSaveModel) {
        return {
            name: model?.name ?? "",
            description: model?.description ?? "",
        };
    }
}
