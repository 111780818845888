import React from "react";
import { LegalEntityAuditStatus } from "../../../../legalEntity/legalEntityAuditTab/LegalEntityAudit.types";
import { RiskLevel } from "../../../../legalEntity/legalEntityClassificationTab/LegalEntityClassificationTab.types";
import { useRiskIndicator } from "../../../../riskAssessment/riskIndicator/RiskIndicator.hooks";
import { useTranslation } from "../../../../localization/useTranslation";
import { Box } from "@mui/material";
import { useQuestionnaireRiskStyles } from "./QuestionnaireRisk.styles";
import { warning } from "../../../../common/icons";

export interface IQuestionnaireRisk {
    riskName?: string;
    scoreEnabled: boolean;
    status: LegalEntityAuditStatus;
    riskLevel?: RiskLevel;
}

function QuestionnaireRisk(props: IQuestionnaireRisk) {
    const { getRiskIcon } = useRiskIndicator();
    const { translateString } = useTranslation();
    const styles = useQuestionnaireRiskStyles();

    function getIcon() {
        if (!props.scoreEnabled) {
            return getRiskIcon(RiskLevel.None);
        }

        const riskLevel = props.riskLevel;
        if (riskLevel !== undefined) {
            return getRiskIcon(riskLevel);
        }

        return warning;
    }

    function getRiskName() {
        if (!props.scoreEnabled) {
            return translateString("none");
        }
        return props.riskName;
    }

    function getRisk() {
        if (props.status !== LegalEntityAuditStatus.Submitted) {
            return <Box></Box>;
        }

        var icon = getIcon();
        var name = getRiskName();
        return (
            <Box sx={styles.risk}>
                <Box sx={styles.icon}>{icon}</Box>
                <Box>{name}</Box>
            </Box>
        );
    }

    return <React.Fragment>{getRisk()}</React.Fragment>;
}

export default QuestionnaireRisk;
