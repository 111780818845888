import { Box, Step, StepButton, StepLabel, Stepper } from "@mui/material";
import React from "react";
import { useDotLegalCoreStepperStyles } from "./DotLegalCoreStepper.styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { StepIconProps } from "@mui/material/StepIcon";
import { StepTypeEnum } from "../../../processingActivity/ProcessingActivity.types";
import { DotLegalTooltip } from "@dotlegal/dotlegal-ui-components";
import { StepHover } from "./DotLegalCoreStepper.types";
import QontoConnector from "./QontoConnector";
import { PurpleLockIcon, WhiteLockIcon } from "../../icons";

export interface IDotLegalCoreStepper {
    steps: Array<{
        name: string;
        error: boolean;
        visited: boolean;
        stepType: StepTypeEnum;
        stepHover?: StepHover;
        stepValidated?: { validationId: string; isValidated: boolean };
        disabled?: boolean;
        disabledToolTip?: string;
    }>;
    activeStepIndex: number;
    onStepClick: (step: StepTypeEnum) => void;
    isSubStep?: boolean;
}

function DotLegalCoreStepper(props: IDotLegalCoreStepper) {
    const styles = useDotLegalCoreStepperStyles();

    function StepIcon(props: StepIconProps, stepNo: number, isDisabled?: boolean, stepValidated?: { validationId: string; isValidated: boolean }) {
        const { active, completed, error } = props;

        if (isDisabled)
            return (
                <Box
                    sx={{
                        "& svg": {
                            width: 16,
                            height: 22,
                        },
                    }}
                >
                    {PurpleLockIcon}
                </Box>
            );

        let stepIcon = (
            <>
                {" "}
                {active
                    ? getCurrentIcon(stepNo, stepValidated?.isValidated ?? false)
                    : error
                      ? getNotVisitedOrErrorIcon(stepNo, true, stepValidated?.isValidated ?? false, isDisabled)
                      : completed
                        ? getCompletedIcon(stepNo, stepValidated?.isValidated ?? false, isDisabled)
                        : getNotVisitedOrErrorIcon(stepNo, false, stepValidated?.isValidated ?? false, isDisabled)}
            </>
        );

        return active ? (
            <Box sx={styles.stepWrapper}>
                <KeyboardArrowDownIcon sx={styles.activeIcon} />
                {stepIcon}
            </Box>
        ) : (
            <div>{stepIcon}</div>
        );
    }

    const getStep = (
        step: {
            name: string;
            error: boolean;
            visited: boolean;
            stepType: StepTypeEnum;
            stepHover?: StepHover;
            stepValidated?: { validationId: string; isValidated: boolean };
            disabled?: boolean;
            disabledToolTip?: string;
        },
        index: number
    ) => {
        const stepProps: { completed?: boolean } = {};
        const labelProps: { optional?: React.ReactNode; error?: boolean } = {};
        stepProps.completed = step.visited && !step.error;
        let validated = step.stepValidated?.isValidated ?? false;

        if (step.error) {
            labelProps.error = true;
        }

        const stepJsx = (
            <Step key={step.name} {...stepProps} sx={validated ? styles.validatedLine : undefined} completed={step.visited}>
                <StepButton disabled={step.disabled} sx={styles.stepButton} onClick={() => props.onStepClick(step.stepType)}>
                    <StepLabel
                        sx={{ ...styles.label, ...(step.visited || step.error ? styles.completedOrErrorClass : {}) }}
                        StepIconComponent={(p) => StepIcon(p, index + 1, step.disabled, step.stepValidated)}
                        {...labelProps}
                    >
                        {getTooltip(step.name, step.stepHover)}
                    </StepLabel>
                </StepButton>
            </Step>
        );

        if (step.disabled) {
            return (
                <DotLegalTooltip key={step.name} text={step.disabledToolTip}>
                    {stepJsx}
                </DotLegalTooltip>
            );
        }

        return stepJsx;
    };

    return (
        <Stepper sx={styles.root} activeStep={props.activeStepIndex} alternativeLabel connector={<QontoConnector />} nonLinear>
            {props.steps.map((step, index) => {
                return getStep(step, index);
            })}
        </Stepper>
    );

    function getTooltip(stepName: string, stepHover: StepHover | undefined) {
        return (
            <DotLegalTooltip
                text={stepHover === undefined ? "" : stepHover.hoverContent}
                toolTipMaxWidth={stepHover === undefined ? 300 : stepHover.toolTipMaxWidth}
            >
                <span>{stepName}</span>
            </DotLegalTooltip>
        );
    }

    function getCompletedIcon(stepNo: number, isValidated: boolean, isDisabled?: boolean) {
        return (
            <Box sx={isValidated ? { ...styles.validatedIcon, ...styles.stepIcon } : { ...styles.completedIcon, ...styles.stepIcon }}>
                {isDisabled ? WhiteLockIcon : props.isSubStep ? "" : stepNo}
            </Box>
        );
    }

    function getCurrentIcon(stepNo: number, isValidated: boolean) {
        return (
            <Box sx={isValidated ? { ...styles.validatedIcon, ...styles.stepIcon } : { ...styles.completedIcon, ...styles.stepIcon }}>
                {props.isSubStep ? "" : stepNo}
            </Box>
        );
    }

    function getNotVisitedOrErrorIcon(stepNo: number, error: boolean, isValidated: boolean, isDisabled?: boolean) {
        return (
            <Box
                sx={
                    isValidated
                        ? { ...styles.validatedIcon, ...styles.stepIcon }
                        : { ...styles.stepIcon, ...(error ? styles.errorIcon : styles.notVisitedIcon) }
                }
            >
                {isDisabled ? (
                    <Box
                        sx={{
                            "& svg path": {
                                fill: "red",
                                width: 14,
                            },
                        }}
                    >
                        {PurpleLockIcon}
                    </Box>
                ) : props.isSubStep ? (
                    ""
                ) : (
                    stepNo
                )}
            </Box>
        );
    }
}

export default DotLegalCoreStepper;
