import { SecurityMeasureOverviewModel } from "../processingActivity/dataSubjectStep/DataSubjectStep.types";
import { RiskBusinessArea } from "./riskAssessmentDialog/riskBusinessAreas/RiskBusinessArea.types";

export enum RiskAssessmentType {
    None = 0,
    Consequence = 1,
    Probability = 2,
    Average = 3,
    Classification = 4,
    Risk = 5,
}

interface BaseViewModel {
    id: string;
    score: number | null;
    note: string | null;
    header: string;
    toolTip: string;
}

export interface QuestionViewModel extends BaseViewModel {
    questionType: QuestionType;
    probabilityScoreNote: ScoreNoteViewModel;
    consequenceScoreNote: ScoreNoteViewModel;
    customQuestionId: string;
    isExcluded: boolean;
}

export interface ScoreNoteViewModel {
    id: string | null;
    score: number | null;
    note: string | null;
    isAnswerScore: boolean;
    isOverridden: boolean;
}

export interface ScenarioViewModel extends BaseViewModel {
    scenarioType: ScenarioType;
    isOverridden: boolean;
}

export interface QuestionReadModel {
    id: string;
    questionType: QuestionType;
    score: number | null;
    note: string | null;
}

export interface ScenarioReadModel {
    id: string;
    scenarioType: ScenarioType;
    score: number;
    isOverridden: boolean;
    note: string | null;
    calculationExplanation: string;
    calculatedScore: number;
}

export interface ScenarioSaveModel {
    scenarioType: ScenarioType;
    score: number;
    note: string | null;
}

export interface QuestionSaveModel {
    questionType: QuestionType;
    riskAssessmentType: RiskAssessmentType;
    score: number | null;
    note: string | null;
    customQuestionId: string;
}

export interface RiskAssessmentQuestionApiModel {
    score: number | undefined;
    averageScore: number | undefined;
    questions: Array<QuestionViewModel>;
    processingActivityId: string | undefined;
    processingActivityName: string | undefined;
    businessAreas: Array<RiskBusinessArea>;
    questionData: QuestionData;
    securityMeasures: Array<SecurityMeasureOverviewModel>;
    systemId: string | undefined;
    systemName: string | undefined;
    isLocked: boolean;
    currentRiskAssessmentVersionName: string;
}

export interface QuestionOverviewViewModel {
    questions: Array<QuestionViewModel>;
    score: number;
    averageScore: number;
}

export interface QuestionInfoViewModel {
    questions: Array<QuestionViewModel>;
    riskAssessmentType: RiskAssessmentType;
    questionCategories: Array<QuestionCategoryQuestions>;
    score?: number;
    averageScore?: number;
}

export interface QuestionData {
    purposes: Array<string>;
    dataSubjects: Array<string>;
    deletionPeriods: Array<string>;
    legalBases: Array<string>;
    dataProcessors: Array<QuestionDataProcessorData>;
    dataCategories: Array<string>;
}

export interface QuestionDataProcessorData {
    name: string;
    sharingId: string;
}

export enum QuestionCategory {
    Confidentiality = 1,
    Integrity = 2,
    Availability = 3,
    DataMinimizationAndStorageLimitation = 4,
    Transparency = 5,
    CustomQuestion = 6,
}

export enum QuestionType {
    CustomQuestion = -1,
    ProcessingActivityUnauthorizedGetsAccessToDataSubjects = 17,
    ProcessingActivityPersonalDataSentToUnauthorizedPerson = 18,
    ProcessingActivityEmployeeNoWorkRelatedAccessToData = 19,
    ProcessingActivityDataProcessorsNotEnsureAdequateProtection = 20,
    ProcessingActivityUnwantedUnintentionalChangeMadeToSubjectsData = 22,
    ProcessingActivityDataSubjectsLossOfServices = 23,
    ProcessingActivityPersonalDataInaccessableToDataSubjects = 24,
    ProcessingActivityDataSubjectsLoseControlOverOwnData = 25,
    ProcessingActivityPersonalDataProcessedGreaterThanNecessary = 26,
    ProcessingActivityPersonalDataNotDeletedWithinDeadline = 27,
    ProcessingActivityDataProcessedWithIncompatiblePurposes = 28,
    ProcessingActivitySystemNotAutomaticallyDeletedData = 29,
    ProcessingActivityDataUsedForIndependantPurposes = 30,
    ProcessingActivitySubjectsNotInformedAboutDataProcessing = 31,
    ProcessingActivitySubjectsNotAbleToExerciseDataProtectionRights = 32,
    ProcessingActivityInsufficientLegalBasisForProcessing = 33,

    SystemUnauthorizedPhysicalOrDigitalAccess = 1000,
    SystemVictimToTargetedCyberattack = 1001,
    SystemSensitiveOrConfidentailDataLeakedDueToInadequateDataSecurity = 1002,
    SystemCompromisedThroughThirdPartySupplier = 1003,
    SystemInadequateUpdatingAndMaintenance = 1004,
    SystemEmployessUnintentionallyOrDeliberatelyAbusingAccessRights = 1005,
    SystemUnavailableDueToHardwareFailureNetworkProblemsNaturalDisasters = 1006,
    SystemLegislativeChangesNonComplianceWithCurrentLawsAndRegulations = 1007,
    SystemManipulatedThroughSocialEngineeringPhishingOrOther = 1008,

    ProcessingActivityNis2IntentionalActionsAimedAtDamagingSystems = 2000,
    ProcessingActivityNis2InformationMisusedForPersonalGain = 2001,
    ProcessingActivityNis2OrganizationBeingExposedToPhishingAttacks = 2002,
    ProcessingActivityNis2OrganizationBeingExposedToRansomware = 2003,
    ProcessingActivityNis2OrganizationExposedToOtherCyberAttacks = 2004,
    ProcessingActivityNis2OrganizationExperienceITRelatedIncidentBySupplierError = 2005,
    ProcessingActivityNis2UnexpectedDegradationBreakdownOrFailure = 2006,
    ProcessingActivityNis2HumanErrorsRelatedToTheOrganizationsOperation = 2007,
    ProcessingActivityNis2SystemOrAssetBeingUsedTheWrongWayOrWrongPurpose = 2008,
    ProcessingActivityNis2BreachOfContractRelatedToTheSystemOrAsset = 2009,
    ProcessingActivityNis2NonComplianceWithApplicableLawsOrInternalRegulationsGoverning = 2010,
    ProcessingActivityNis2SeriousUnwantedIncidentDueToNaturalClimateTemperatureProcesses = 2011,
    ProcessingActivityNis2MinorOrLocalNaturalPhenomenaNegativelyImpacting = 2012,

    SystemNis2RiskOfIntentionalActionsAimedAtDamagingTheSystem = 3000,
    SystemNis2RiskOfSystemBeingMisused = 3001,
    SystemNis2RiskOfOrganizationExposedToPhishing = 3002,
    SystemNis2RiskOfOrganizationExposedToRansomware = 3003,
    SystemNis2RiskOfOrganizationExposedToOtherCyberAttacks = 3004,
    SystemNis2OrganizationExperienceITRelatedIncidentBySupplierError = 3005,
    SystemNis2UnexpectedDegradationBreakdownOrFailure = 3006,
    SystemNis2HumanErrorsRelatedToTheOrganizationsOperation = 3007,
    SystemNis2SystemOrAssetBeingUsedTheWrongWayOrWrongPurpose = 3008,
    SystemNis2BreachOfContractRelatedToTheSystemOrAsset = 3009,
    SystemNis2NonComplianceWithApplicableLawsOrInternalRegulationsGoverning = 3010,
    SystemNis2SeriousUnwantedIncidentDueToNaturalClimateTemperatureProcesses = 3011,
    SystemNis2MinorOrLocalNaturalPhenomenaNegativelyImpacting = 3012,
}

export class QuestionCategoryQuestions {
    constructor(questionCategory: QuestionCategory) {
        this.questionCategory = questionCategory;
        this.questionTypes = [];

        this.setQuestionTypesByQuestionDataCategory(questionCategory);
    }

    questionCategory: QuestionCategory;
    questionTypes: Array<QuestionType>;

    setQuestionTypesByQuestionDataCategory(questionCategory: QuestionCategory) {
        switch (questionCategory) {
            case QuestionCategory.Confidentiality:
                this.questionTypes = Array<QuestionType>(
                    QuestionType.ProcessingActivityUnauthorizedGetsAccessToDataSubjects,
                    QuestionType.ProcessingActivityPersonalDataSentToUnauthorizedPerson,
                    QuestionType.ProcessingActivityEmployeeNoWorkRelatedAccessToData,
                    QuestionType.ProcessingActivityDataProcessorsNotEnsureAdequateProtection
                );
                break;
            case QuestionCategory.Integrity:
                this.questionTypes = Array<QuestionType>(
                    QuestionType.ProcessingActivityUnwantedUnintentionalChangeMadeToSubjectsData,
                    QuestionType.ProcessingActivityDataSubjectsLossOfServices
                );
                break;
            case QuestionCategory.Availability:
                this.questionTypes = Array<QuestionType>(
                    QuestionType.ProcessingActivityPersonalDataInaccessableToDataSubjects,
                    QuestionType.ProcessingActivityDataSubjectsLoseControlOverOwnData
                );
                break;
            case QuestionCategory.DataMinimizationAndStorageLimitation:
                this.questionTypes = Array<QuestionType>(
                    QuestionType.ProcessingActivityPersonalDataProcessedGreaterThanNecessary,
                    QuestionType.ProcessingActivityPersonalDataNotDeletedWithinDeadline,
                    QuestionType.ProcessingActivityDataProcessedWithIncompatiblePurposes,
                    QuestionType.ProcessingActivitySystemNotAutomaticallyDeletedData,
                    QuestionType.ProcessingActivityDataUsedForIndependantPurposes
                );
                break;
            case QuestionCategory.Transparency:
                this.questionTypes = Array<QuestionType>(
                    QuestionType.ProcessingActivitySubjectsNotInformedAboutDataProcessing,
                    QuestionType.ProcessingActivitySubjectsNotAbleToExerciseDataProtectionRights,
                    QuestionType.ProcessingActivityInsufficientLegalBasisForProcessing
                );
                break;
            case QuestionCategory.CustomQuestion:
                this.questionTypes = [QuestionType.CustomQuestion] as [QuestionType];
                break;
        }
    }
}

export interface ProcessingActivityRiskAssessmentViewModel {
    processingActivityId: string;
    processingActivityName: string;
    score: number;
    averageScore: number;
    businessAreas: Array<string>;
    scenarios: Array<ScenarioReadModel>;
    questions: Array<QuestionReadModel>;
}

export interface ProcessingActivityRiskAssessmentScenarioViewModel {
    processingActivityId: string;
    processingActivityName: string;
    score: number;
    averageScore: number;
    businessAreas: Array<RiskBusinessArea>;
    scenarios: Array<ScenarioReadModel>;
    isLocked: boolean;
    currentRiskAssessmentVersionName: string;
}

export interface RiskAssessmentScoreViewModel {
    consequenceScore: number | null;
    probabilityScore: number | null;
    riskAssessmentTotalScore: number | null;
    scenariosScore: number | null;
    currentRiskAssessmentVersionId: string | null;
}

export enum ScenarioType {
    SensitiveConsequence = 1,
    DataToDataProcessorConsequence = 2,
    DataProcessorToSubProcessorConsequence = 3,
    DataToUnsecureThirdCountryConsequence = 4,
    NumberOfProcessorsProbability = 5,
    NumberOfSubjectsProbability = 6,
    DeletionPeriodLengthProbability = 7,
    GroupEntityToGroupEntitySharing = 8,
}

export class QuestionTypeStandardAnswers {
    constructor(questionType: QuestionType | undefined, translateString: (key: string, interpolation?: any) => string) {
        this.consequenceAnswers = [];

        if (questionType) this.setAnswersByQuestionType(questionType, translateString);
    }
    consequenceAnswers: Array<string>;

    setAnswersByQuestionType(questionType: QuestionType, translateString: (key: string, interpolation?: any) => string) {
        switch (questionType) {
            case QuestionType.ProcessingActivityUnauthorizedGetsAccessToDataSubjects:
                this.consequenceAnswers = Array<string>(
                    translateString("privacyBreach"),
                    translateString("violationOfPrivacyProtection"),
                    translateString("lossOfConfidentiality"),
                    translateString("lackOfControlOverOwnRights"),
                    translateString("exclusionOfServices"),
                    translateString("financialLosses"),
                    translateString("lossAndChangeOfPersonalData"),
                    translateString("unintentionalInquiriesAndMarketing"),
                    translateString("undueInfluence")
                );
                break;
            case QuestionType.ProcessingActivityPersonalDataSentToUnauthorizedPerson:
                this.consequenceAnswers = Array<string>(
                    translateString("privacyBreach"),
                    translateString("violationOfPrivacyProtection"),
                    translateString("lossOfConfidentiality"),
                    translateString("bullying"),
                    translateString("harassment"),
                    translateString("threats")
                );
                break;
            case QuestionType.ProcessingActivityEmployeeNoWorkRelatedAccessToData:
                this.consequenceAnswers = Array<string>(
                    translateString("violationOfPrivacyProtection"),
                    translateString("usedLaterForInappropriatePurposes"),
                    translateString("imbalanceInThePowerRelationship"),
                    translateString("lackOfControlOverOwnRights"),
                    translateString("improperlyInfluencedByInformation"),
                    translateString("lossOfConfidentiality"),
                    translateString("damageToReputation"),
                    translateString("breachOfConfidentiality")
                );
                break;
            case QuestionType.ProcessingActivityDataProcessorsNotEnsureAdequateProtection:
                this.consequenceAnswers = Array<string>(
                    translateString("privacyBreach"),
                    translateString("violationOfPrivacyProtection"),
                    translateString("lossOfConfidentiality"),
                    translateString("financialLosses"),
                    translateString("lossAndChangeOfPersonalData"),
                    translateString("unintentionalInquiriesAndMarketing"),
                    translateString("undueInfluence")
                );
                break;
            case QuestionType.ProcessingActivityUnwantedUnintentionalChangeMadeToSubjectsData:
                this.consequenceAnswers = Array<string>(
                    translateString("privacyBreach"),
                    translateString("violationOfPrivacyProtection"),
                    translateString("erroneousDecisions"),
                    translateString("irritationAndWasteOfTime")
                );
                break;
            case QuestionType.ProcessingActivityDataSubjectsLossOfServices:
                this.consequenceAnswers = Array<string>(
                    translateString("privacyBreach"),
                    translateString("violationOfPrivacyProtection"),
                    translateString("irritationOfServices"),
                    translateString("delayInDelivery")
                );
                break;
            case QuestionType.ProcessingActivityPersonalDataInaccessableToDataSubjects:
                this.consequenceAnswers = Array<string>(
                    translateString("privacyBreach"),
                    translateString("violationOfPrivacyProtection"),
                    translateString("lossOfConfidentiality"),
                    translateString("lackOfControlOverOwnRights"),
                    translateString("irritationOfServices"),
                    translateString("delayInDelivery")
                );
                break;
            case QuestionType.ProcessingActivityDataSubjectsLoseControlOverOwnData:
                this.consequenceAnswers = Array<string>(
                    translateString("lossOfConfidentiality"),
                    translateString("lackOfControlOverOwnRights"),
                    translateString("violationOfPrivacyProtection"),
                    translateString("financialConsequences"),
                    translateString("repeatingFilling")
                );
                break;
            case QuestionType.ProcessingActivityPersonalDataProcessedGreaterThanNecessary:
                this.consequenceAnswers = Array<string>(
                    translateString("violationOfPrivacyProtection"),
                    translateString("usedLaterForInappropriatePurposes"),
                    translateString("imbalanceInThePowerRelationship"),
                    translateString("lackOfControlOverOwnRights"),
                    translateString("personalDataCanBeHackedAndExposed"),
                    translateString("undulyInfluencedByInformation")
                );
                break;
            case QuestionType.ProcessingActivityPersonalDataNotDeletedWithinDeadline:
                this.consequenceAnswers = Array<string>(
                    translateString("violationOfPrivacyProtection"),
                    translateString("usedLaterForInappropriatePurposes"),
                    translateString("imbalanceInThePowerRelationship"),
                    translateString("lackOfControlOverOwnRights"),
                    translateString("personalDataCanBeHackedAndExposed"),
                    translateString("undulyInfluencedByInformation")
                );
                break;
            case QuestionType.ProcessingActivityDataProcessedWithIncompatiblePurposes:
                this.consequenceAnswers = Array<string>(
                    translateString("privacyBreach"),
                    translateString("violationOfPrivacyProtection"),
                    translateString("imbalanceInThePowerRelationship"),
                    translateString("lackOfControlOverOwnRights"),
                    translateString("unintentionalInquiriesAndMarketing"),
                    translateString("undueInfluence")
                );
                break;
            case QuestionType.ProcessingActivitySystemNotAutomaticallyDeletedData:
                this.consequenceAnswers = Array<string>(
                    translateString("privacyBreach"),
                    translateString("violationOfPrivacyProtection"),
                    translateString("lossOfConfidentiality"),
                    translateString("imbalanceInThePowerRelationship"),
                    translateString("lackOfControlOverOwnRights"),
                    translateString("personalDataCanBeHackedAndExposed"),
                    translateString("undulyInfluencedByInformation")
                );
                break;
            case QuestionType.ProcessingActivityDataUsedForIndependantPurposes:
                this.consequenceAnswers = Array<string>(
                    translateString("privacyBreach"),
                    translateString("violationOfPrivacyProtection"),
                    translateString("lossOfConfidentiality"),
                    translateString("lackOfControlOverOwnData"),
                    translateString("canNotDefendAgainstLegalClaims"),
                    translateString("irritation"),
                    translateString("riskOfSecurityBreachesAndHackerAttacks")
                );
                break;
            case QuestionType.ProcessingActivitySubjectsNotInformedAboutDataProcessing:
                this.consequenceAnswers = Array<string>(
                    translateString("privacyBreach"),
                    translateString("violationOfPrivacyProtection"),
                    translateString("otherPurposesPersonalDataWasIntendedTo"),
                    translateString("cannotExerciseYourDataProtectionRights")
                );
                break;
            case QuestionType.ProcessingActivitySubjectsNotAbleToExerciseDataProtectionRights:
                this.consequenceAnswers = Array<string>(
                    translateString("privacyBreach"),
                    translateString("violationOfPrivacyProtection"),
                    translateString("lossOfConfidentiality"),
                    translateString("lackOfControlOverOwnData"),
                    translateString("undulyInfluencedByInformation")
                );
                break;
            case QuestionType.ProcessingActivityInsufficientLegalBasisForProcessing:
                this.consequenceAnswers = Array<string>(
                    translateString("privacyBreach"),
                    translateString("violationOfPrivacyProtection"),
                    translateString("subjectedToUnjustifiedMarketing"),
                    translateString("timeConsumptionAndIrritation"),
                    translateString("notExerciseHisRights"),
                    translateString("undulyInfluencedInRelationToFinancialDecisions")
                );
                break;
        }
    }
}
