import React from "react";
import { useTranslation } from "../../localization/useTranslation";
import { useProcessingActivityCountWidgetProps } from "./ProcessingActivityCountWiget.hooks";
import DashboardCountCard from "../DashboardCountCard";
import Widget from "../Widget";
import { useUrlProvider } from "../../useUrlProvider";
import { Box, CircularProgress } from "@mui/material";
import { processingActivityAllOnlyUserSpecificStorageKey } from "../../processingActivity/processingActivities/ProcessingActivities.hooks";
import { setFirstLetterToLowerCase } from "../../common/stringOperations";
import LargeCountText from "../LargeCountText.tsx";
import SmallCountText from "../SmallCountText.tsx";

export interface ProcessingActivityCountWigetProps {
    onlyUserSpecific: boolean;
    selectedGroupCompany: string | undefined;
    isPdf?: boolean;
}

function ProcessingActivityCountWiget(props: ProcessingActivityCountWigetProps) {
    const { translateString } = useTranslation();
    const { getProcessingActivitiesOverviewUrl } = useUrlProvider();
    const { isLoading, data, isEnabled } = useProcessingActivityCountWidgetProps(props);

    const noOfCompletedPas = data?.rows?.filter((x) => x.progressScore === 100)?.length ?? 0;

    function CircularProgressWithLabel() {
        return (
            <Box>
                <Box sx={{ position: "relative", display: "inline-flex" }}>
                    <CircularProgress value={100} sx={{ color: "#58649a" }} size={60} variant="determinate" />
                    <CircularProgress
                        variant="determinate"
                        sx={(theme) => ({
                            color: theme.palette.secondary.main,
                            position: "absolute",
                            left: 0,
                        })}
                        size={60}
                        value={data?.overallProgress ?? 0}
                    />
                    <Box
                        sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: "absolute",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Box sx={(theme) => ({ color: theme.customPalette.white, fontSize: 14 })}>{`${data?.overallProgress ?? 0} %`}</Box>
                    </Box>
                </Box>
            </Box>
        );
    }

    return (
        <Widget noPadding disabled={!isEnabled}>
            <DashboardCountCard
                isLoading={isLoading}
                header={translateString("processingActivities")}
                background="darkblue"
                url={{
                    url: getProcessingActivitiesOverviewUrl(),
                    text: translateString("goToProcessingActivities"),
                    allMyPageKey: processingActivityAllOnlyUserSpecificStorageKey,
                    onlyUserSpecific: props.onlyUserSpecific,
                }}
                topRightElement={isLoading ? <></> : <CircularProgressWithLabel />}
                isPdf={props.isPdf}
            >
                {!isLoading && (
                    <Box sx={{ display: "flex", gap: 1, alignItems: "flex-end" }}>
                        <LargeCountText count={noOfCompletedPas} />
                        <SmallCountText>/</SmallCountText>
                        <SmallCountText>{data?.rows.length}</SmallCountText>
                        <SmallCountText>
                            {setFirstLetterToLowerCase(noOfCompletedPas < 2 ? translateString("completed") : translateString("completedPlural"))}
                        </SmallCountText>
                    </Box>
                )}
            </DashboardCountCard>
        </Widget>
    );
}

export default ProcessingActivityCountWiget;
