import React from "react";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import { DotLegalSelect } from "@dotlegal/dotlegal-ui-components";
import DotLegalMultiSelectWithButton from "../../../common/components/dotLegalSelectWithButton/DotLegalMultiSelectWithButton";
import { ValidationError } from "../../../common/validationError";
import { useTranslation } from "../../../localization/useTranslation";
import BusinessAreaDialog from "../../../masterData/businessArea/businessAreaDialog/BusinessAreaDialog";
import StepSegment from "../../../processingActivity/stepSegment/StepSegment";
import { useUrlProvider } from "../../../useUrlProvider";
import { SystemType } from "../SystemEdit.types";

import { useSystemAssociationStepHooks } from "./SystemAssociationStep.hooks";

export interface IAssociationStep {
    responsible: string | null;
    systemType: SystemType;
    businessAreas: Array<string>;
    validationErrors: Array<ValidationError>;
    canManageMasterData: boolean;
    onBusinessAreaChange: (areas: Array<string>) => void;
    onSystemTypeChange: (type: SystemType) => void;
    onResponsibleChange: (owner: string | null) => void;
    readOnly?: boolean;
}

export function SystemAssociationStep(props: IAssociationStep) {
    const { translateString } = useTranslation();
    const { getUserUrl } = useUrlProvider();
    const { permissions } = useUserContext();
    const {
        businessAreasData,
        businessAreasLoading,
        systemTypeOptions,
        onSaveBusinessArea,
        searchValue,
        setSearchValue,
        showBusinessAreaDialog,
        setShowBusinessAreaDialog,
        responsibleData,
        responsibleLoading,
    } = useSystemAssociationStepHooks(props);

    const systemTypeChange = (type: string | null) => {
        if (type) props.onSystemTypeChange(Number(type));
    };

    return (
        <React.Fragment>
            <StepSegment size="small" id={"responsible-wrapper"}>
                <DotLegalSelect
                    onChange={(user) => props.onResponsibleChange(user)}
                    label={translateString("responsible")}
                    options={responsibleData}
                    placeholder={translateString("responsible")}
                    selectedItem={props.responsible}
                    disabled={props.readOnly}
                    isLoading={responsibleLoading}
                    toolTipText={translateString("systemResponsibleTooltip")}
                    errorText={props.validationErrors.find((x) => x.field === "inActiveUserAffiliation")?.error}
                    link={permissions.canManageUsers && props.responsible ? getUserUrl(props.responsible!) : undefined}
                    noOptionsLabel={translateString("noOptions")}
                />

                <DotLegalMultiSelectWithButton
                    label={translateString("businessAreas")}
                    placeholder={translateString("businessAreas")}
                    chosenOptions={props.businessAreas}
                    isLoading={businessAreasLoading}
                    options={businessAreasData}
                    onChange={(businessArea) => props.onBusinessAreaChange(businessArea)}
                    toolTipText={translateString("systemBusinessAreasTooltip")}
                    onButtonClick={() => setShowBusinessAreaDialog(true)}
                    buttonLabel={translateString("createNew")}
                    searchValue={searchValue}
                    onSearchValueChange={setSearchValue}
                    hasPermission={props.canManageMasterData}
                    noOptionsLabel={translateString("noOptions")}
                    disabled={props.readOnly}
                />
                {permissions.systemAssociationPermissions.hasSystemTypePermission && (
                    <DotLegalSelect
                        label={translateString("systemType")}
                        placeholder={translateString("systemType")}
                        selectedItem={props.systemType !== undefined ? props.systemType.toString() : ""}
                        options={systemTypeOptions}
                        disabled={props.readOnly}
                        onChange={(type) => systemTypeChange(type)}
                        disableClearable
                        toolTipText={translateString("systemTypeTooltip")}
                        noOptionsLabel={translateString("noOptions")}
                    />
                )}
            </StepSegment>

            {showBusinessAreaDialog && !props.readOnly && (
                <BusinessAreaDialog
                    onHideDialog={() => setShowBusinessAreaDialog(false)}
                    selectedBusinessArea={{ name: searchValue, description: "", id: "", active: true, color: undefined, isCustom: true }}
                    onSave={onSaveBusinessArea}
                />
            )}
        </React.Fragment>
    );
}
