import { useQuery } from "react-query";
import { get } from "../common/api/apiShared";
import { TrialInformationViewModel } from "./Trial.types";
import { useState } from "react";
import { PlanType } from "../auth/planProvider/PlanProvider.types.ts";
import { usePlanContext } from "../auth/planProvider/PlanProvider.tsx";

export function useTrialInformationHook() {
    let oneHour = 3600000;
    const [showOverviewOfTrialsDialog, setShowOverviewOfTrialsDialog] = useState(false);
    const { dataProtectionFeatures, informationSecurityFeatures, vendorManagementFeatures } = usePlanContext();
    let { isLoading, data } = useQuery("customerPlan", () => get<TrialInformationViewModel>("/plan/trialinformation/"), { staleTime: oneHour });

    return {
        isLoading,
        data,
        showOverviewOfTrialsDialog,
        setShowOverviewOfTrialsDialog,
        hasAnyActiveLivePremiumPlan: hasAnyActiveLivePremiumPlan(),
        hasActivePremiumTrial: hasActivePremiumTrial(),
        hasActiveVendorManagementTrial: hasActiveVendorManagementTrial(),
    };

    function hasActivePremiumTrial(): boolean {
        if (data) {
            return data.plansOnTrials.some((x) => !x.isExpired);
        }

        return false;
    }

    function hasActiveVendorManagementTrial(): boolean {
        if (data) {
            return data.plansOnTrials.some((x) => !x.isExpired && x.planType === PlanType.VendorManagement);
        }

        return false;
    }

    function hasAnyActiveLivePremiumPlan() {
        const activePremiumPlans = [
            { hasPlan: dataProtectionFeatures.hasDataProtection, planType: PlanType.DataProtection },
            { hasPlan: informationSecurityFeatures.hasInfoSec, planType: PlanType.InformationSecurity },
            { hasPlan: vendorManagementFeatures.hasVendorManagement, planType: PlanType.VendorManagement },
        ]
            .filter((x) => x.hasPlan)
            .map((x) => x.planType);

        const activeTrials = data?.plansOnTrials.filter((plan) => !plan.isExpired).map((plan) => plan.planType) ?? [];

        return activePremiumPlans.length > activeTrials.length;
    }
}
