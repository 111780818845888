import { IEditPublicInternalAssessmentTemplateDialogProps } from "./EditPublicInternalAssessmentTemplateDialog.tsx";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation.ts";
import { createElement, useState } from "react";
import { PublicInternalAssessmentTemplateUpdateModel, PublicInternalAssessmentTemplateUpdateViewModel } from "../PublicInternalAssessmentTemplates.types.ts";
import { useResponsibleQuery } from "../../../user/hooks/useResponsibleQuery.ts";
import { useValidator } from "../../../common/hooks/validation";
import { useMutation } from "react-query";
import { Trans } from "react-i18next";
import { put } from "../../../common/api/apiShared.ts";

export default function useEditPublicInternalAssessmentTemplate(props: IEditPublicInternalAssessmentTemplateDialogProps) {
    const snackBar = useDotLegalSnackbar();
    const { translateString } = useTranslation();

    const [model, setModel] = useState<PublicInternalAssessmentTemplateUpdateViewModel>(getDefaultModel());
    const responsibleQuery = useResponsibleQuery(true);

    let selectableResponsiblesData = responsibleQuery.data(props.responsibleId);
    let selectableResponsiblesLoading = responsibleQuery.isLoading;

    const validation = useValidator<PublicInternalAssessmentTemplateUpdateViewModel>((validators) => ({
        name: validators.validateNotEmpty((item) => item.name, translateString("assessmentName")),
        responsibleId: validators.validateNotEmpty((item) => item.responsibleId, translateString("responsible")),
    }));

    const updater = {
        onAssessmentNameChange: (name: string) => {
            var temp = { ...model };
            temp.name = name;
            setModel(temp);
        },
        onDescriptionChange: (description: string) => {
            var temp = { ...model };
            temp.description = description;
            setModel(temp);
        },
        onResponsibleChanged: (responsible: string | null) => {
            const temp = { ...model! };
            temp.responsibleId = responsible ?? undefined;
            setModel(temp);
        },
    };

    const sendMutation = useMutation(sendApi);
    const isSending = sendMutation.isLoading;

    async function send() {
        await sendMutation.mutateAsync(undefined, {
            onSuccess: () => {
                props.onDialogClose();
                props.onCreate();
                snackBar.show(createElement(Trans, { i18nKey: "itemEditedSuccess", values: { name: model?.name } }));
            },
        });
    }

    function sendApi() {
        const saveModel: PublicInternalAssessmentTemplateUpdateModel = {
            responsibleId: model.responsibleId!,
            description: model.description ?? undefined,
            name: model.name,
        };

        return put<{}>(`/publicInternalAssessmentTemplates/${props.id}`, saveModel);
    }

    const onSendClick = async () => {
        if (validation.anyHasErrors) {
            validation.setShowErrors(true);
            return;
        }

        await send();
    };

    function getDefaultModel(): PublicInternalAssessmentTemplateUpdateViewModel {
        return {
            name: props.name,
            description: props.description ?? null,
            responsibleId: props.responsibleId,
        };
    }

    return {
        model,
        validation,
        updater,
        onSendClick,
        selectableResponsiblesData,
        selectableResponsiblesLoading,
        isSending,
    };
}
