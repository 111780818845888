import React, { useCallback, useMemo, useState } from "react";
import {
    arrowLeft,
    changeOrganization,
    dashboardMenuIcon,
    dotLegalExpandedNavIcon,
    helpIcon,
    internalAssessments,
    legalEntitiesIcon,
    policiesIcon,
    processingActivitiesIcon,
    riskAssessmentIcon,
    settingsIcon,
    signOutIcon,
    systemsMenuIcon,
    taskManagementIcon,
} from "../../common/icons";
import { useNavMenuStyles } from "./NavMenu.styles";
import PrettyScrollbar from "../../common/PrettyScrollbar";
import NavigationLink from "../navigationLink/NavigationLink";
import { useTranslation } from "../../localization/useTranslation";
import { useUrlProvider } from "../../useUrlProvider";
import DotLegalLogo from "./dotLegalLogo.svg?react";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { Accordion, AccordionDetails, AccordionSummary, Badge, Box } from "@mui/material";
import { useNavMenuDataMapping } from "./NavMenu.hooks";
import LanguageMenu from "../languageMenu/LanguageMenu";
import NotificationMenu from "../notificationMenu/NotificationMenu";
import { DotLegalHeader, ToDto, useHistoryWithReferer, useStateLocalStorage } from "@dotlegal/dotlegal-ui-components";
import { hasSettingsAccess } from "../../privacySettings/Settings";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";
import { SubMenuItem } from "../navigationLink/NavigationLink.types";
import { usePermissionsProvider } from "../../auth/usePermissionsProvider";
import UpgradeCard from "./upgradeCard/UpgradeCard.tsx";

export interface INavMenuProps {
    isExpanded: boolean;
    onLinkClick: () => void;
    hoveringOnDrawer?: boolean;
    isExpandedBigScreen?: boolean;
    isExpandedSmallScreen?: boolean;
    trialRibbonShown?: boolean;
}

function NavMenu(props: INavMenuProps) {
    const styles = useNavMenuStyles(props);
    const urlProvider = useUrlProvider();
    const { translateString } = useTranslation();
    const userContext = useUserContext();
    const permissionsProvider = usePermissionsProvider();
    const { hasVendorManagementAccess, addonsFeatures, platformFeatures } = usePlanContext();
    const { showNotification, notifications, isLoading, showOnBoardingNotification } = useNavMenuDataMapping();
    const historyWithReferer = useHistoryWithReferer();
    const [isAccountMenuExpanded, setIsAccountMenuExpanded] = useStateLocalStorage("account-menu-expanded", true);
    const [subMenuExpanded, setSubMenuExpanded] = useState<string | false>(false);

    const GetTaskManagementIcon = useCallback(
        (icon: JSX.Element) => {
            if (showOnBoardingNotification)
                return (
                    <Badge sx={{ "& .MuiBadge-badge": { backgroundColor: "#FA9CAE" } }} variant="dot">
                        {icon}
                    </Badge>
                );

            return icon;
        },
        [showOnBoardingNotification]
    );

    const navigationLink = useCallback(
        (
            text: string,
            icon: JSX.Element,
            link: ToDto | string,
            extraPaths: Array<string>,
            htmlId: string,
            isInGroup?: boolean,
            newTab?: boolean,
            externalLink?: boolean,
            tooltip?: string,
            subMenuItems?: Array<SubMenuItem>
        ) => {
            return (
                <NavigationLink
                    text={text}
                    icon={icon}
                    isExpanded={props.isExpanded}
                    linkTo={link}
                    onClick={props.onLinkClick}
                    isInGroup={isInGroup}
                    openNewTab={newTab}
                    externalLink={externalLink}
                    tooltip={tooltip}
                    subMenuItems={subMenuItems}
                    extraPaths={extraPaths}
                    htmlId={htmlId}
                    subMenuExpanded={subMenuExpanded}
                    onSubMenuExpanded={setSubMenuExpanded}
                    isExpandedSmallScreen={props.isExpandedSmallScreen}
                />
            );
        },
        [props.isExpanded, props.isExpandedSmallScreen, props.onLinkClick, subMenuExpanded]
    );

    const regularNavLinks = useMemo(() => {
        let legalEntitiesSubMenuItems: Array<SubMenuItem> = [];
        if (userContext.permissions.companyPermissions.read) {
            legalEntitiesSubMenuItems.push({
                name: translateString("vendors"),
                link: historyWithReferer.addSearchIfReferer(urlProvider.getLegalEntitiesUrl()),
                extraPaths: ["privacy/legal-entities"],
                tooltip: translateString("menuItemSuplierLegalEntityDescription"),
                htmlId: "vendor-legal-entities",
            });
            legalEntitiesSubMenuItems.push({
                name: translateString("customers"),
                link: historyWithReferer.addSearchIfReferer(urlProvider.getCustomerLegalEntities()),
                extraPaths: ["privacy/customer-legal-entity"],
                tooltip: translateString("menuItemCustomerLegalEntityDescription"),
                htmlId: "customer-legal-entities",
            });
        }

        if (userContext.permissions.canManageGroupEntities) {
            legalEntitiesSubMenuItems.push({
                name: translateString("groupCompanies"),
                link: historyWithReferer.addSearchIfReferer(urlProvider.getGroupEntitiesUrl()),
                extraPaths: ["privacy/group-entities"],
                tooltip: translateString("menuItemGroupEntityDescription"),
                htmlId: "group-entities",
            });
        }

        if (userContext.permissions.companyPermissions.read) {
            legalEntitiesSubMenuItems.push({
                name: translateString("others"),
                link: historyWithReferer.addSearchIfReferer(urlProvider.getOtherLegalEntities()),
                extraPaths: ["privacy/other-legal-entity"],
                tooltip: translateString("menuItemOtherLegalEntityDescription"),
                htmlId: "other-legal-entities",
            });
        }

        let assessmentSubMenuItems: Array<SubMenuItem> = [];
        if (userContext.permissions.canManageAuditGroups && hasVendorManagementAccess) {
            assessmentSubMenuItems.push({
                name: translateString("externalAudits"),
                link: historyWithReferer.addSearchIfReferer(urlProvider.getAuditGroupsUrl()),
                extraPaths: ["privacy/external-assessments", "privacy/audit-group"],
                tooltip: translateString("menuItemExternalAuditsDescription"),
                htmlId: "audit-groups",
            });
        }

        if (userContext.permissions.canManageInternalAssessment && hasVendorManagementAccess) {
            assessmentSubMenuItems.push({
                name: translateString("internalAssessments"),
                link: historyWithReferer.addSearchIfReferer(urlProvider.getInternalAssessmentUrl()),
                extraPaths: ["privacy/internal-assessments", "privacy/internal-assessment-templates"],
                tooltip: translateString("menuItemInternalAuditsDescription"),
                htmlId: "internal-assessments",
            });
        }

        let processingSubMenuItems: Array<SubMenuItem> = [];
        if (userContext.permissions.processingActivityPermissions.read) {
            processingSubMenuItems.push({
                name: translateString("processingActivities"),
                link: historyWithReferer.addSearchIfReferer(urlProvider.getProcessingActivitiesOverviewUrl()),
                tooltip: translateString("menuItemProcessingActivitiesDescription"),
                extraPaths: ["privacy/processingactivity"],
                htmlId: "processing-activities",
            });
        }

        if (userContext.permissions.canAccessRecord301 || userContext.permissions.canAccessRecord302) {
            processingSubMenuItems.push({
                name: translateString("record"),
                link: historyWithReferer.addSearchIfReferer(urlProvider.getRecordUrl()),
                tooltip: translateString("menuItemRecordDescription"),
                extraPaths: ["privacy/record"],
                htmlId: "processing-activity-record",
            });
        }

        let taskManagementSubMenuItems: Array<SubMenuItem> = [];
        if (permissionsProvider.hasTaskManagementAccess()) {
            taskManagementSubMenuItems.push({
                name: translateString("tasks"),
                link: historyWithReferer.addSearchIfReferer(urlProvider.getTaskManagementUrl()),
                tooltip: translateString("menuItemTasksDescription"),
                extraPaths: ["privacy/task-management"],
                htmlId: "tasks",
            });
        }

        if (permissionsProvider.hasAnnualWheelActivityAccess()) {
            taskManagementSubMenuItems.push({
                name: translateString("annualWheel"),
                link: historyWithReferer.addSearchIfReferer(urlProvider.getAnnualWheelUrl()),
                tooltip: translateString("menuItemAnnualWheelDescription"),
                extraPaths: ["privacy/annual-wheel"],
                htmlId: "annual-wheel",
            });
        }

        if (userContext.permissions.declarationPermissions.read && addonsFeatures.declarations) {
            taskManagementSubMenuItems.push({
                name: translateString("declarations"),
                link: historyWithReferer.addSearchIfReferer(urlProvider.getDeclarationsUrl()),
                extraPaths: ["privacy/declarations"],
                htmlId: "declarations",
            });
        }

        let policyAndProceduresSubmenuItems: Array<SubMenuItem> = [];
        if (userContext.permissions.policyPermissions.read) {
            policyAndProceduresSubmenuItems.push({
                name: translateString("policies"),
                link: historyWithReferer.addSearchIfReferer(urlProvider.getPoliciesUrl()),
                tooltip: translateString("menuItemPolicyDescription"),
                extraPaths: ["privacy/policies", "privacy/policy"],
                htmlId: "policies",
            });
        }

        if (userContext.permissions.gdprIncidentLogPermissions.read) {
            policyAndProceduresSubmenuItems.push({
                name: translateString("incidentLog"),
                link: historyWithReferer.addSearchIfReferer(urlProvider.getIncidentLogUrl()),
                tooltip: translateString("incidentLogNavMenuDescription"),
                extraPaths: ["privacy/incident-log"],
                htmlId: "incident-log",
            });
        }

        return (
            <>
                {platformFeatures.dashboard &&
                    navigationLink(
                        translateString("dashboard"),
                        dashboardMenuIcon,
                        historyWithReferer.addSearchIfReferer(urlProvider.getDashboardUrl()),
                        ["privacy/dashboard"],
                        "dashboard",
                        undefined,
                        undefined,
                        undefined,
                        translateString("menuItemDashboardDescription")
                    )}

                {processingSubMenuItems.length > 0 &&
                    navigationLink(
                        translateString("processes"),
                        processingActivitiesIcon,
                        processingSubMenuItems[0].link,
                        ["privacy/processingactivity", "privacy/record"],
                        "processes",
                        undefined,
                        undefined,
                        undefined,
                        translateString("menuItemProcessesDescription"),
                        processingSubMenuItems
                    )}

                {userContext.permissions.systemPermissions.read &&
                    navigationLink(
                        translateString("systems"),
                        systemsMenuIcon,
                        historyWithReferer.addSearchIfReferer(urlProvider.getSystemsUrl()),
                        ["privacy/systems", "privacy/system"],
                        "assets",
                        undefined,
                        undefined,
                        undefined,
                        translateString("menuItemSystemDescription")
                    )}

                {userContext.permissions.riskPermissions.read &&
                    navigationLink(
                        translateString("risk"),
                        riskAssessmentIcon,
                        historyWithReferer.addSearchIfReferer(urlProvider.getRiskAssessmentsUrl()),
                        ["privacy/risk-assessments", "privacy-riskassessments/template"],
                        "risk-assessments",
                        undefined,
                        undefined,
                        undefined,
                        translateString("menuItemRiskWithISMSDescription")
                    )}

                {taskManagementSubMenuItems.length > 0 &&
                    navigationLink(
                        translateString("taskManagement"),
                        GetTaskManagementIcon(taskManagementIcon),
                        taskManagementSubMenuItems[0].link,
                        ["privacy/annual-wheel", "privacy/declarations", "privacy/task-management"],
                        "task-management",
                        undefined,
                        undefined,
                        undefined,
                        translateString("menuItemTaskManagementDescription"),
                        taskManagementSubMenuItems
                    )}

                {policyAndProceduresSubmenuItems.length > 0 &&
                    navigationLink(
                        translateString("documentation"),
                        policiesIcon,
                        policyAndProceduresSubmenuItems[0].link,
                        ["privacy/policies", "privacy/policy", "privacy/incident-log"],
                        "incident-log",
                        undefined,
                        undefined,
                        undefined,
                        translateString("menuItemPolicyAndProceduresDescription"),
                        policyAndProceduresSubmenuItems
                    )}

                {legalEntitiesSubMenuItems.length > 0 &&
                    navigationLink(
                        translateString("legalEntities"),
                        legalEntitiesIcon,
                        legalEntitiesSubMenuItems[0].link,
                        ["privacy/legal-entities", "privacy/group-entities", "privacy/customer-legal-entities", "privacy/other-legal-entities"],
                        "legal-entities",
                        undefined,
                        undefined,
                        undefined,
                        translateString("menuItemLegalEntityDescription"),
                        legalEntitiesSubMenuItems
                    )}

                {assessmentSubMenuItems.length > 0 &&
                    navigationLink(
                        translateString("auditsAndAssessments"),
                        internalAssessments,
                        assessmentSubMenuItems[0].link,
                        ["privacy/internal-assessments", "privacy/audit-groups", "privacy/internal-assessment-templates", "privacy/audit-group"],
                        "audits-and-assessments",
                        undefined,
                        undefined,
                        undefined,
                        translateString("menuItemAuditsDescription"),
                        assessmentSubMenuItems
                    )}
            </>
        );
    }, [
        GetTaskManagementIcon,
        historyWithReferer,
        navigationLink,
        permissionsProvider,
        translateString,
        urlProvider,
        userContext.permissions.canAccessRecord301,
        userContext.permissions.canAccessRecord302,
        userContext.permissions.canManageAuditGroups,
        userContext.permissions.canManageGroupEntities,
        userContext.permissions.canManageInternalAssessment,
        userContext.permissions.companyPermissions.read,
        userContext.permissions.declarationPermissions.read,
        userContext.permissions.gdprIncidentLogPermissions.read,
        userContext.permissions.policyPermissions.read,
        userContext.permissions.processingActivityPermissions.read,
        userContext.permissions.riskPermissions.read,
        userContext.permissions.systemPermissions.read,
        addonsFeatures,
        hasVendorManagementAccess,
        platformFeatures.dashboard,
    ]);

    const notification = useMemo(() => {
        return userContext.permissions.taskPermissions.read || userContext.permissions.canManageProcessingActivtyComments ? (
            <NotificationMenu data={notifications} isLoading={isLoading} showNotificationIcon={showNotification} isExpanded={props.isExpanded} />
        ) : (
            <></>
        );
    }, [
        isLoading,
        notifications,
        props.isExpanded,
        showNotification,
        userContext.permissions.canManageProcessingActivtyComments,
        userContext.permissions.taskPermissions.read,
    ]);

    const settings = useMemo(() => {
        return (
            hasSettingsAccess(userContext.permissions) &&
            navigationLink(
                translateString("settings"),
                settingsIcon,
                urlProvider.getSettingsUrl(),
                ["privacy/users", "privacy/user", "privacy/masterdata", "privacy/customers", "privacy/roles"],
                "settings",
                true
            )
        );
    }, [navigationLink, translateString, urlProvider, userContext.permissions]);

    const userDisplayName = useMemo(() => {
        const customerName = `${userContext.customerName ? userContext.customerName : ""}`;
        const customerEmail = `${userContext.emailAddress ? "(" + userContext.emailAddress + ")" : ""}`;
        return `${customerName}${customerName ? " " : ""}${customerEmail}`;
    }, [userContext.customerName, userContext.emailAddress]);

    const myPrivacyAccountNavLinksHeader = useMemo(() => {
        return (
            <Box
                sx={(theme) => ({
                    display: "flex",
                    flexDirection: "row",
                    padding: props.isExpanded
                        ? `0px ${theme.spacingAsNumber(2)}px 0 ${theme.spacingAsNumber(1)}px`
                        : `0px ${theme.spacingAsNumber(1)}px 0 ${theme.spacingAsNumber(1)}px`,
                    margin: "0",
                    height: "40px",
                    justifyContent: props.isExpanded ? "space-between" : "center",
                    alignContent: "center",
                    alignItems: "center",
                })}
            >
                {props.isExpanded ? (
                    <Box sx={(theme) => ({ display: "flex", justifyContent: "flex-start", width: "100%" })} title={userDisplayName}>
                        <DotLegalHeader headerStyle={"small"} color={"lightgrey"} marginBottom={1} marginTop={1} center>
                            {translateString("myPrivacyAccount").toUpperCase()}
                        </DotLegalHeader>
                    </Box>
                ) : (
                    <></>
                )}
                <Box
                    sx={(theme) => ({
                        transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;",
                        transform: isAccountMenuExpanded ? "rotate(90deg)" : "rotate(270deg)",
                        display: "flex",
                        justifyContent: "flex-end",
                        height: "30px",
                        width: "30px",
                    })}
                >
                    <Box
                        onClick={() => {
                            setIsAccountMenuExpanded(!isAccountMenuExpanded);
                        }}
                        sx={styles.expandButton}
                    >
                        {arrowLeft}
                    </Box>
                </Box>
            </Box>
        );
    }, [styles.expandButton, isAccountMenuExpanded, props.isExpanded, setIsAccountMenuExpanded, translateString, userDisplayName]);

    const myPrivacyAccountNavLinks = useMemo(() => {
        return (
            <Box
                id={"my-privacy-account"}
                sx={(theme) => ({
                    paddingTop: "15px",
                    paddingBottom: "15px",
                    borderRadius: "15px",
                    backgroundColor: theme.palette.grey["100"],
                    marginBottom: 2,
                })}
            >
                <Accordion
                    expanded={isAccountMenuExpanded}
                    sx={(theme) => ({
                        backgroundColor: theme.palette.grey["100"],
                        boxShadow: "none",
                        "& .MuiAccordionDetails-root": {
                            margin: 0,
                        },
                        "& .MuiAccordionSummary-root": {
                            minHeight: "0 !important",
                        },
                    })}
                >
                    <AccordionSummary
                        sx={(theme) => ({
                            padding: 0,
                            "& .MuiAccordionSummary-content": {
                                margin: 0,
                                padding: "0",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                overflow: "hidden",
                                minHeight: 0,
                                "&.Mui-expanded": {
                                    margin: 0,
                                },
                                "li:last-child": {
                                    marginBottom: isAccountMenuExpanded ? 1 : 0,
                                },
                            },
                            "&:hover:not(.Mui-disabled)": {
                                cursor: "default",
                            },
                        })}
                    >
                        {myPrivacyAccountNavLinksHeader}
                        <Box component={"ul"} sx={styles.navList}>
                            {notification}
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails
                        sx={(theme) => ({
                            padding: "0",
                        })}
                    >
                        <Box component={"ul"} sx={styles.navList}>
                            {settings}
                            {navigationLink(translateString("help"), helpIcon, urlProvider.getHelpUrl(userContext.helpLink), [], "help", false, true, true)}

                            {localStorage.getItem("customerId") !== null &&
                                navigationLink(
                                    translateString("changeOrganization"),
                                    changeOrganization,
                                    urlProvider.getChangeOrganizationUrl(),
                                    [],
                                    "change-organization",
                                    true
                                )}

                            {navigationLink(translateString("signOut"), signOutIcon, urlProvider.getSignOutUrl(), [], "sign-out", true)}

                            <LanguageMenu isExpanded={props.isExpanded} />
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>
        );
    }, [
        isAccountMenuExpanded,
        myPrivacyAccountNavLinksHeader,
        navigationLink,
        notification,
        props.isExpanded,
        settings,
        translateString,
        urlProvider,
        userContext.helpLink,
        styles,
    ]);

    return (
        <Box sx={styles.innerContainer}>
            <PrettyScrollbar>
                <Box sx={styles.list}>
                    <Box>
                        <Box id={"regular-nav-links"} component={"ul"} sx={styles.navList}>
                            {regularNavLinks}
                        </Box>
                        {myPrivacyAccountNavLinks}
                        {(props.isExpandedBigScreen || props.isExpandedSmallScreen) && <UpgradeCard />}
                    </Box>
                    <Box sx={styles.logo}>
                        <a href="https://dotlegal.dk">{props.isExpanded ? dotLegalExpandedNavIcon : <DotLegalLogo />}</a>
                    </Box>
                </Box>
            </PrettyScrollbar>
        </Box>
    );
}

export default NavMenu;
