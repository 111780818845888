import { useTheme } from "@mui/material";
import { createSxStyles } from "../../../../common/createSxStyles";

export const useSystemSupplierStyles = () => {
    const theme = useTheme();

    return createSxStyles({
        radioContainer: {
            display: "flex",
            justifyContent: "center",
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
            [theme.breakpoints.down("xs")]: {
                flexDirection: "column",
            },
            gap: 3,
        },
        yesBox: {
            marginRight: theme.spacing(3),
            [theme.breakpoints.down("xs")]: {
                marginRight: 0,
                marginBottom: theme.spacing(2),
            },
        },
        dataProcessorContainer: {
            width: 200,
        },
        radiobtnLabel: {
            fontSize: theme.typography.pxToRem(14),
            color: theme.palette.primary.dark,
        },
        collapseHeaderText: {
            color: theme.palette.primary.dark,
            fontWeight: theme.typography.fontWeightBold,
        },
        descriptionLabel: {
            display: "flex",
            justifyContent: "flex-end",
            fontSize: theme.typography.pxToRem(12),
            color: theme.palette.primary.main,
            "&:hover": {
                cursor: "pointer",
                textDecoration: "underline",
            },
        },
    });
};
