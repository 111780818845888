import { LegalEntityAuditGroupAuditModel } from "../../legalEntityAuditGroupOverview/LegalEntityAuditGroupOverview.types";
import { useTranslation } from "../../../localization/useTranslation";
import { ManagementReportInProgressAuditsProps } from "./ManagementReportInProgressAudits";
import { LegalEntityAuditStatus } from "../../../legalEntity/legalEntityAuditTab/LegalEntityAudit.types";
import { DotLegalSelectOption } from "../../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { useStateUrlParamsArray } from "../../../common/hooks/useStateUrlParams";
import { useDownloadLegalEntityAuditReport } from "../../DowloadLegalEntityAuditReport.hooks";

export function useManagementReportInProgressAudits(props: ManagementReportInProgressAuditsProps) {
    const [searchedStatus, setSearchedStatus] = useStateUrlParamsArray("status", []);
    const [searchedRemindersSent, setSearchedRemindersSent] = useStateUrlParamsArray("remindersSent", []);

    const { translateDate, translateLegalEntityAuditStatus } = useTranslation();
    const { onDownloadReportAsPdf, getReportPdfUrl, isDownloadingPdf } = useDownloadLegalEntityAuditReport();

    let statusFilterOptions: Array<DotLegalSelectOption> = [];
    let remindersSentFilterOptions: Array<DotLegalSelectOption> = [];
    if (props.audits) {
        createStatusFilterOptinos(props.audits.flatMap((x) => x.status));
        createReminderSentFilterOptinos(props.audits.flatMap((x) => x.reminderSent));

        sortSelectOptions();
    }

    function createStatusFilterOptinos(allAuditStatus: Array<LegalEntityAuditStatus>) {
        allAuditStatus.forEach((status) => {
            if (!statusFilterOptions.some((x) => x.id == status.toString())) {
                statusFilterOptions.push({
                    id: status.toString(),
                    name: translateLegalEntityAuditStatus(status.toString()),
                });
            }
        });
    }

    function createReminderSentFilterOptinos(allRemindersSent: Array<Date | null>) {
        allRemindersSent.forEach((reminder) => {
            const reminderSent = reminder !== null ? new Date(reminder) : null;
            if (reminderSent !== null && !remindersSentFilterOptions.some((x) => x.id == reminderSent.getTime().toString())) {
                remindersSentFilterOptions.push({ id: reminderSent.getTime().toString(), name: translateDate(reminderSent) });
            }
        });
    }

    return {
        rows: getFilteredRows(props.audits),
        isDownloadingPdf,
        statusFilterOptions,
        searchedStatus,
        onDownloadReportAsPdf,
        getReportPdfUrl,
        setSearchedStatus,
        searchedRemindersSent,
        setSearchedRemindersSent,
        remindersSentFilterOptions,
    };

    function getFilteredRows(rows: Array<LegalEntityAuditGroupAuditModel>) {
        let tempData = [...rows];

        if (searchedStatus.length > 0 && searchedStatus[0] !== "") {
            tempData = tempData.filter((row) => searchedStatus.indexOf(row.status.toString()) >= 0);
        }

        if (searchedRemindersSent.length > 0 && searchedRemindersSent[0] !== "") {
            tempData = tempData.filter(
                (row) => row.reminderSent !== null && searchedRemindersSent.indexOf(new Date(row.reminderSent).getTime().toString()) >= 0
            );
        }

        return tempData;
    }

    function sortSelectOptions() {
        statusFilterOptions.sort((a, b) => (a.name > b.name ? 1 : -1));
        remindersSentFilterOptions.sort((a, b) => (a.name > b.name ? 1 : -1));
    }
}
