import { useMutation, useQuery } from "react-query";
import { get, post } from "../../common/api/apiShared";
import { CustomizeIntroductionEmailViewModel } from "./CustomizeIntroductionEmailDialog.types";
import { createElement, useEffect, useState } from "react";
import { ICustomizeIntroductionEmailDialog } from "./CustomizeIntroductionEmailDialog";
import { ajaxQueue, useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { Trans } from "react-i18next";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { isNullOrWhitespace } from "../../common/stringOperations";

export default function useCustomizeIntroductionEmailDialogHooks(props: ICustomizeIntroductionEmailDialog) {
    const { emailAddress } = useUserContext();
    const snackbar = useDotLegalSnackbar();
    const queryKey = "QuestionnaireEmailCustomization";
    const [introduction, setIntroduction] = useState<string | undefined>(undefined);

    let { data, isLoading } = useQuery(queryKey, () => get<CustomizeIntroductionEmailViewModel>("/QuestionnaireEmailCustomization/IntroductionText"));

    useEffect(() => {
        if (data) {
            setIntroduction(data.introductionText);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isLoading]);

    const sendEmailMutation = useMutation(sendTestEmailAPI, {
        onSuccess: (response: any) => {
            snackbar.show(createElement(Trans, { i18nKey: "testEmailSent", values: { email: emailAddress } }));
        },
    });

    const onSendEmailClick = async () => {
        await ajaxQueue().addRequest(sendEmailMutation.mutateAsync, undefined);
    };

    const saveMutation = useMutation(saveAPI, {
        onSuccess: (response: any) => {
            props.onDialogClose();
        },
    });

    const onIntroductionChange = async (introductionText: string) => {
        setIntroduction(introductionText);
    };

    const onSaveClick = async () => {
        await saveMutation.mutateAsync();
    };

    return {
        introductionText: introduction,
        isLoading,
        onIntroductionChange,
        onSendEmailClick,
        sendingEmail: sendEmailMutation.isLoading,
        onSaveClick,
        isSavingIntroduction: saveMutation.isLoading,
    };

    function saveAPI() {
        return post<{}>("/QuestionnaireEmailCustomization/IntroductionText", introduction);
    }

    function sendTestEmailAPI() {
        return post<{}>("/QuestionnaireEmailCustomization/SendTestTextEmail", introduction);
    }
}
