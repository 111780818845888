import { put } from "../../../common/api/apiShared.ts";
import { ICompleteDeclarationControlDialogProps } from "./CompleteDeclarationControlDialog.tsx";
import { useMutation } from "react-query";

export function useCompleteDeclarationControlDialog(props: ICompleteDeclarationControlDialogProps) {
    const completeControlMutation = useMutation(completeControlApi);
    const cancelCompletionControlMutation = useMutation(cancelCompletionControlApi);
    const changeCompletionForControl = async (completed: boolean) => {
        if (completed) {
            await completeControlMutation.mutateAsync(undefined, {
                onSuccess: () => {
                    props.onCompletionChanged();
                },
            });
        } else {
            await cancelCompletionControlMutation.mutateAsync(undefined, {
                onSuccess: () => {
                    props.onCompletionChanged();
                },
            });
        }
    };

    return { changeCompletionForControl, isSaving: completeControlMutation.isLoading || cancelCompletionControlMutation.isLoading };

    function completeControlApi() {
        return put(`/Declarationcontrol/${props.controlId}/complete`, undefined);
    }

    function cancelCompletionControlApi() {
        return put(`/Declarationcontrol/${props.controlId}/cancelCompletion`, undefined);
    }
}
