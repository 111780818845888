import { ISystemIconProps } from "./SystemIcon";
import Adform from "./icons/Adform.png";
import Amazon_Web_Services from "./icons/Amazon_Web_Services.png";
import Canva from "./icons/Canva.png";
import Confluence from "./icons/Confluence.png";
import Danloen from "./icons/Danloen.png";
import Dataloen from "./icons/Dataloen.png";
import Dinero from "./icons/Dinero.png";
import DocuSign from "./icons/DocuSign.png";
import Dropbox from "./icons/Dropbox.png";
import EConomic from "./icons/e-conomic.svg?url";
import Eboks from "./icons/Eboks.png";
import Excel from "./icons/Excel.png";
import Facebook from "./icons/Facebook.png";
import GoogleAnalytics from "./icons/Google Analytics.png";
import GoogleSuite from "./icons/Google suite.png";
import HubSpot from "./icons/hubspot.png";
import Instagram from "./icons/Instagram.png";
import Intercom from "./icons/Intercom.png";
import Jira from "./icons/Jira.png";
import Joomla from "./icons/Joomla.png";
import LinkedIn from "./icons/LinkedIn.png";
import MailChimp from "./icons/MailChimp.png";
import Microsoft_Teams from "./icons/Microsoft Teams.png";
import Microsoft_365 from "./icons/Microsoft_365.png";
import Microsoft_Azure from "./icons/Microsoft_Azure.png";
import Microsoft_Office_OneDrive from "./icons/Microsoft_Office_OneDrive.png";
import Outlook from "./icons/Outlook.png";
import Penneo from "./icons/Penneo.png";
import PowerBI from "./icons/Power BI.png";
import Salesforce from "./icons/Salesforce.png";
import SAP from "./icons/SAP.png";
import Sharepoint from "./icons/Sharepoint.png";
import Shopify from "./icons/Shopify.png";
import Sitecore from "./icons/Sitecore.png";
import Slack from "./icons/Slack.png";
import Sleeknote from "./icons/Sleeknote.png";
import SurveyMonkey from "./icons/SurveyMonkey.png";
import SurveyXact from "./icons/SurveyXact.png";
import Teamtailor from "./icons/Teamtailor.png";
import Timeplan from "./icons/Timeplan.png";
import TOPdesk from "./icons/TOPdesk.png";
import Trello from "./icons/Trello.png";
import Trustpilot from "./icons/Trustpilot.png";
import Ucommerce from "./icons/Ucommerce.png";
import Umbraco from "./icons/Umbraco.png";
import Uniconta from "./icons/Uniconta.png";
import Virk from "./icons/Virk.png";
import Visma from "./icons/Visma.png";
import webCRM from "./icons/webCRM.png";
import WhistleB from "./icons/WhistleB.png";
import Whistleblower_software from "./icons/Whistleblower_software.png";
import Wix from "./icons/Wix.png";
import WordPress from "./icons/WordPress.png";
import YouTube from "./icons/YouTube.png";
import Zendesk from "./icons/Zendesk.png";
import Zexpense from "./icons/Zexpense.png";
import Pactius from "./icons/Pactius.png";
import Privacy from "./icons/Privacy.png";
import MarketingPlatform from "./icons/marketingplatform.png";

export function useSystemIcon(props: ISystemIconProps) {
    const getIcon = () => {
        if (!props.systemName) return null;
        if (contains("hubspot")) return HubSpot;
        if (contains("adform")) return Adform;
        if (contains("Amazon Web Services")) return Amazon_Web_Services;
        if (contains("Confluence")) return Confluence;
        if (contains("Danløn")) return Danloen;
        if (contains("Dataløn")) return Dataloen;
        if (contains("Dinero")) return Dinero;
        if (contains("Dropbox")) return Dropbox;
        if (contains("Google Analytics")) return GoogleAnalytics;
        if (contains("Google suite")) return GoogleSuite;
        if (contains("Intercom")) return Intercom;
        if (contains("Jira")) return Jira;
        if (contains("Joomla")) return Joomla;
        if (contains("MailChimp")) return MailChimp;
        if (contains("Microsoft 365")) return Microsoft_365;
        if (contains("Azure")) return Microsoft_Azure;
        if (contains("Outlook (Mail)")) return Outlook;
        if (contains("Outlook")) return Outlook;
        if (contains("SAP") && !contains("Whatsapp")) return SAP;
        if (contains("Salesforce")) return Salesforce;
        if (contains("Sharepoint")) return Sharepoint;
        if (contains("Shopify")) return Shopify;
        if (contains("Sitecore")) return Sitecore;
        if (contains("slack")) return Slack;
        if (contains("Sleeknote")) return Sleeknote;
        if (contains("Trello")) return Trello;
        if (contains("Ucommerce")) return Ucommerce;
        if (contains("UniConta")) return Uniconta;
        if (contains("Whistleblowersoftware")) return Whistleblower_software;
        if (contains("WhistleB")) return WhistleB;
        if (contains("Wix")) return Wix;
        if (contains("WordPress")) return WordPress;
        if (contains("Zendesk")) return Zendesk;
        if (contains("youtube")) return YouTube;
        if (contains("zexpense")) return Zexpense;
        if (contains("Visma")) return Visma;
        if (contains("Facebook")) return Facebook;
        if (contains("LinkedIn")) return LinkedIn;
        if (contains("e-conomic")) return EConomic;
        if (contains("canva")) return Canva;
        if (contains("DocuSign")) return DocuSign;
        if (contains("Eboks")) return Eboks;
        if (contains("E-boks")) return Eboks;
        if (contains("Excel")) return Excel;
        if (contains("Instagram")) return Instagram;
        if (contains("Penneo")) return Penneo;
        if (contains("Power BI")) return PowerBI;
        if (contains("webCRM")) return webCRM;
        if (contains("Virk.dk")) return Virk;
        if (contains("Umbraco")) return Umbraco;
        if (contains("Trustpilot")) return Trustpilot;
        if (contains("TOPdesk")) return TOPdesk;
        if (contains("Timeplan")) return Timeplan;
        if (contains("Teamtailor")) return Teamtailor;
        if (contains("SurveyXact")) return SurveyXact;
        if (contains("SurveyMonkey")) return SurveyMonkey;
        if (contains("onedrive")) return Microsoft_Office_OneDrive;
        if (contains("Microsoft Teams")) return Microsoft_Teams;
        if (contains("MarketingPlatform")) return MarketingPlatform;
        if (contains("Pactius")) return Pactius;
        if (contains("Privacy")) return Privacy;

        return null;
    };

    function contains(name: string): boolean {
        return props.systemName?.toLowerCase().indexOf(name.toLowerCase()) !== -1;
    }

    return {
        getIcon,
    };
}
