import { useQuery } from "react-query";
import { get } from "../../../common/api/apiShared";
import { SharingBaseTableViewModel } from "../../../processingActivity/components/sharingsTable/SharingsTable.types";
import { SystemSharingTabProps } from "./SystemSharingsTab";

export function useSystemSharingsTab(props: SystemSharingTabProps) {
    const url = `/SystemOverview/${props.systemId}/sharings`;
    const { isLoading, data } = useQuery(url, () => get<SharingBaseTableViewModel>(url));

    return {
        isLoading,
        data,
    };
}
