import { createSxStyles } from "../../../../../common/createSxStyles.ts";

export const useLeftColumnStyles = () => {
    return createSxStyles({
        leftContainer: {
            borderRadius: "25px",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right bottom",
            backgroundSize: "contain",
        },
    });
};
