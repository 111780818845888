import { useState } from "react";
import { QuestionnaireSelectableItem } from "../../legalEntityAuditTab/LegalEntityAudit.types";
import { LegalEntityClassificationModel } from "./LegalEntityClassificationDialog.types";
import { useValidator } from "../../../common/hooks/validation";
import { useTranslation } from "../../../localization/useTranslation";
import { useMutation } from "react-query";
import { post } from "../../../common/api/apiShared";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { ILegalEntityClassificationDialog } from "./LegalEntityClassificationDialog";
import { SelectableItem } from "../../../processingActivity/ProcessingActivity.types";

export default function useLegalEntityClassificationDialogHooks(props: ILegalEntityClassificationDialog) {
    const snackbar = useDotLegalSnackbar();
    const { translateString } = useTranslation();
    const [saveModel, setSaveModel] = useState<LegalEntityClassificationModel>(new LegalEntityClassificationModel());

    const validation = useValidator<LegalEntityClassificationModel>(
        (validators) => ({
            questionnaireIds: validators.validateNotEmpty((item) => item.questionnaireIds, translateString("audit")),
        }),
        []
    );

    function onSaveClick() {
        if (validation.anyHasErrors) {
            validation.setShowErrors(true);
            return;
        }

        addTemplates();
    }

    const saveMutation = useMutation(saveAPI, {
        onSuccess: (response: any) => {
            snackbar.show(translateString("classificationTemplatesAreCreated"));
            props.onCreated();
        },
    });

    const addTemplates = async () => {
        await saveMutation.mutateAsync();
    };

    const onQuestionnaireChanged = (questionnaires: Array<QuestionnaireSelectableItem>) => {
        var temp = { ...saveModel! };
        temp.questionnaireIds = questionnaires.map((x) => x.id);

        setSaveModel(temp);
    };

    const onSystemChange = (item: SelectableItem | undefined) => {
        var temp = { ...saveModel! };
        temp.systemId = item?.id;

        setSaveModel(temp);
    };

    return { onQuestionnaireChanged, saveModel, validation, onSaveClick, onSystemChange, isSaving: saveMutation.isLoading };

    function saveAPI() {
        return post(`/LegalEntityClassification/${props.legalEntityId}`, saveModel);
    }
}
