import DotLegalDeleteDialogWrapper from "../../../common/components/dotLegalDeleteDialog/DotLegalDeleteDialogWrapper";
import { useDeleteLegalEntityContactHooks } from "./DeleteLegalEntityContactDialog.hooks";

export interface IDeleteLegalEntityContactDialog {
    onCloseDialog: () => void;
    onDeleted: () => void;
    contactId: string;
    contactName: string;
}

function DeleteLegalEntityContactDialog(props: IDeleteLegalEntityContactDialog) {
    const { deleteContact, inProgess } = useDeleteLegalEntityContactHooks(props);

    return (
        <DotLegalDeleteDialogWrapper
            open
            itemForDeletion={props.contactName}
            onDialogClose={props.onCloseDialog}
            onOkClick={deleteContact}
            inProgress={inProgess}
        />
    );
}

export default DeleteLegalEntityContactDialog;
