import { useQuery } from "react-query";
import { get } from "../../common/api/apiShared";
import { NotificationType, NotificationViewModel } from "../../notification/Notification.types";

export function useNavMenuDataMapping() {
    const url = "/notification/notifications";

    let { isLoading, data, refetch } = useQuery(url, () => get<Array<NotificationViewModel>>(url));

    let showNotification = (data && data?.filter((not) => not.isRead === false).length > 0) ?? false;
    let showOnBoardingNotification =
        (data && data?.filter((not) => not.isRead === false && not.notificationType === NotificationType.OnBoardingTask).length > 0) ?? false;

    return {
        showNotification,
        notifications: data,
        isLoading,
        showOnBoardingNotification,
        refetchMenuNotifications: refetch,
    };
}
