export interface DeletionPeriodViewModel {
    id: string;
    name: string;
    description: string;
    deletionPeriodType: DeletionPeriodFrequencyType | undefined;
    deletionPeriodLength: number | undefined;
    active: boolean;
    isCustom: boolean;
}

export enum DeletionPeriodFrequencyType {
    None = 0,
    Days = 1,
    Weeks = 2,
    Months = 3,
    Years = 4,
}
