import { useTheme } from "@mui/material";
import { createSxStyles } from "../common/createSxStyles";

export const useTaskManagementStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        tasksStatusContainer: {
            marginBottom: theme.spacing(2),
        },
        breadCrumbContainer: {
            display: "flex",
        },
        yearPicker: {
            marginLeft: theme.spacing(2),
        },
        priorityIcon: {
            marginLeft: theme.spacing(1),
        },
        priorityContainer: {
            display: "flex",
            alignItems: "center",
        },
    });
};
