import { useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";

export const useAssociationStepStyle = () => {
    const theme = useTheme();
    return createSxStyles({
        info: {
            marginBottom: theme.spacing(1),
        },
        subInfo: {
            marginBottom: theme.spacing(3),
            marginTop: theme.spacing(1),
            fontWeight: theme.typography.fontWeightBold,
            textAlign: "center",
            fontSize: theme.typography.pxToRem(12),
            color: theme.palette.grey.A400,
        },
    });
};
