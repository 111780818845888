export function isTemplateCustomer() {
    const customerId = localStorage.getItem("customerId");

    const dkCustomerid = "0f8513bf-2ba7-47e7-bca3-5591e935aba4";
    const enCustomerid = "cee5a141-3f19-46e6-9155-b959aa573173";

    if (customerId === dkCustomerid || customerId === enCustomerid) return true;

    return false;
}
