import { DotLegalButton } from "@dotlegal/dotlegal-ui-components";
import { Box } from "@mui/material";
import { useTranslation } from "../../localization/useTranslation";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { getSettings } from "../../common/settingsProvider";
import Sparkle from "../../common/images/Sparkle 4 x.png";
import { useTrialInformationHook } from "../../trial/TrialInformation.hooks";

function PlanDialogUpgradeButton() {
    const { translateString } = useTranslation();
    const { customerId } = useUserContext();
    const settings = getSettings();

    const purchasePrivacy = () => {
        let returnUrl = window.location.href;
        const url = settings.customerAdminWeb + `/purchase?productCustomerId=${customerId}&returnUrl=${returnUrl}`;
        window.location.href = url;
    };

    return (
        <DotLegalButton
            onClick={purchasePrivacy}
            startIcon={<Box component={"img"} height={20} width={20} src={Sparkle} alt={""} sx={{ marginRight: -1 }} />}
            endIcon={<Box component={"img"} height={20} width={20} src={Sparkle} alt={""} sx={{ marginRight: -1 }} />}
            btnLength={300}
            buttonType="primary"
        >
            {translateString("upgradeNow")}
        </DotLegalButton>
    );
}

export default PlanDialogUpgradeButton;
