import React from "react";
import { DotLegalHeader, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { Box, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "../localization/useTranslation.ts";
import { BaseViewModel } from "../system/systemOverview/SystemOverview.types.ts";

export interface IAnnualWheelSubTasksProps {
    subTasks: Array<BaseViewModel>;
    onChange: (subTasks: Array<BaseViewModel>) => void;
}

function AnnualWheelSubTasks(props: IAnnualWheelSubTasksProps) {
    const { translateString } = useTranslation();
    return (
        <>
            <DotLegalHeader marginBottom={0.5} marginTop={4} headerStyle="small">
                {translateString("createActivitySubTasks")}
            </DotLegalHeader>
            {/*TODO THE Flyt knap ned under tasks*/}
            {/*TODO THE cursor skal sættes i textfelt efter tryk på add*/}
            <Typography
                sx={(theme) => ({
                    color: theme.palette.primary.main,
                    display: "inline-block",
                    fontSize: theme.typography.pxToRem(12),
                    "&:hover": {
                        textDecoration: "underline",
                        cursor: "pointer",
                    },
                })}
                onClick={() => {
                    let tempModel = [...props.subTasks];
                    tempModel.push({ name: "", id: "" });
                    props.onChange(tempModel);
                }}
            >
                + {translateString("addSubTask")}
            </Typography>
            {props.subTasks.map((x, index) => {
                return (
                    <Box key={index} sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Box sx={{ width: "100%" }}>
                            <DotLegalTextField
                                noMargin
                                variant="standard"
                                key={index}
                                debounce={false}
                                label=""
                                onChange={(value) => {
                                    let tempModel = [...props.subTasks];
                                    tempModel[index] = { name: value, id: x.id };
                                    props.onChange(tempModel);
                                }}
                                value={x.name}
                                alwaysShowUnderline
                            />
                        </Box>
                        <IconButton
                            size="small"
                            onClick={() => {
                                let tempModel = [...props.subTasks];
                                tempModel.splice(index, 1);
                                props.onChange(tempModel);
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                );
            })}
        </>
    );
}

export default AnnualWheelSubTasks;
