import { alpha, useTheme } from "@mui/material";
import { createSxStyles } from "../../../../common/createSxStyles";

export const useSystemSecondarySupplierStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        removeSubproces: {
            display: "flex",
            justifyContent: "flex-end",
        },
        onRemoveHover: {
            "&:hover": {
                background: alpha(theme.palette.primary.light, 0.5),
            },
        },
        subProcessorContainer: {
            position: "relative",
            textAlign: "center",
            padding: theme.spacing(3),
        },
    });
};
