import React from "react";
import { Trans } from "react-i18next";
import { useTranslation } from "../../../localization/useTranslation";
import { LegislationSaveModel } from "../legislationDialog/LegislationDialog.types";
import { useChangeLegislationStatusDialogDataMapping } from "./ChangeLegislationStatusDialog.hooks";
import { DotLegalInfoDialog } from "@dotlegal/dotlegal-ui-components";

export interface IChangeLegislationStatusDialogDataMapping {
    selectedLegislation: LegislationSaveModel;
    onCloseDialog: () => void;
    onStatusChange: () => void;
}

export function ChangeLegislationStatusDialog(props: IChangeLegislationStatusDialogDataMapping) {
    const { changeActiveStatus, inProgress } = useChangeLegislationStatusDialogDataMapping(props);
    const { translateString } = useTranslation();

    return (
        <DotLegalInfoDialog
            btnText={props.selectedLegislation.active ? translateString("deactivate") : translateString("activate")}
            header={props.selectedLegislation.active ? translateString("deactivateLegislationHeader") : translateString("activateLegislationHeader")}
            inProgress={inProgress}
            content={
                props.selectedLegislation.active ? (
                    <Trans i18nKey="deactivateDataInfo" values={{ name: props.selectedLegislation.name }}></Trans>
                ) : (
                    <Trans i18nKey="activateDataInfo" values={{ name: props.selectedLegislation.name }}></Trans>
                )
            }
            onBtnClick={() => changeActiveStatus(props.selectedLegislation)}
            onCloseDialog={props.onCloseDialog}
        />
    );
}
