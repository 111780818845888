import React from "react";
import { DotLegalPlanUpgradeDialog } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { Trans } from "react-i18next";
import { Box } from "@mui/material";
import { useUrlProvider } from "../../useUrlProvider.ts";

export interface IPlanUpgradeDialogProps {
    onDialogClose: () => void;
    text: string;
}

function PlanUpgradeDialogWrapper(props: IPlanUpgradeDialogProps) {
    const { translateString } = useTranslation();
    const { getPurchasePlansUrl } = useUrlProvider();
    return (
        <DotLegalPlanUpgradeDialog
            onDialogClose={() => props.onDialogClose()}
            text={props.text}
            purchaseUrl={getPurchasePlansUrl()}
            upgradePlanDialogText={{
                upgradeNow: translateString("upgradeNow"),
                content: translateString("upgradePlanDialogContent"),
                planDialogText: {
                    contactDotLegalText: (
                        <Trans
                            i18nKey={"contactDotLegal"}
                            components={{
                                phone: (
                                    <Box sx={{ color: "#767676" }} component={"a"} target="_blank" href="tel:+4570270127">
                                        +45 7027 0127
                                    </Box>
                                ),
                                mail: (
                                    <Box sx={{ color: "#767676" }} component={"a"} href="mailto:support@dotlegal.com">
                                        support@dotlegal.com
                                    </Box>
                                ),
                            }}
                        />
                    ),
                },
            }}
        />
    );
}

export default PlanUpgradeDialogWrapper;
