import { Box, Grid, Paper, Typography } from "@mui/material";
import { Skeleton } from "@mui/material";
import React from "react";
import { useIsOnSmallScreen } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { RiskAssessmentRowViewModel } from "../riskAssessments/RiskAssessments.types";
import { highRiskIcon, lowRiskIcon, moderateRiskIcon, reallyHighRiskIcon, reallyLowRiskIcon } from "../riskIndicator/riskIcons";
import { useProcessingActityRiskMatrixDataMapping } from "./RiskAssessmentMatrix.hooks";
import { useProcessingActityRiskMatrixStyle } from "./RiskAssessmentMatrix.styles";

export interface IProcessingActivityRiskMatrixProps {
    isLoading: boolean;
    onMatrixClick: (consequence: number | null, probability: number | null) => void;
    data: Array<RiskAssessmentRowViewModel> | undefined;
    gridClicked: boolean;
    isPdf?: boolean;
}

function ProcessingActivityRiskMatrix(props: IProcessingActivityRiskMatrixProps) {
    let isSmallScreen = useIsOnSmallScreen();

    const styles = useProcessingActityRiskMatrixStyle(isSmallScreen, props.isPdf);
    const { translateString } = useTranslation();
    const { matrixData, selectedGrid, setSelectedGrid } = useProcessingActityRiskMatrixDataMapping(props);

    const onMatrixClick = (xValue: number | null, yValue: number | null) => {
        props.onMatrixClick(xValue, yValue);
        setSelectedGrid({ propbability: yValue ?? 0, consequence: xValue ?? 0 });
    };

    return (
        <Box sx={styles.wrapper}>
            <Box sx={styles.topRow}>
                <Box sx={styles.topLeft}>
                    <Typography sx={[styles.axisLabels, styles.leftLegend]}>{translateString("probability")}</Typography>
                </Box>
                <Box sx={styles.yCordinateHelperContainer}>
                    <Typography sx={[styles.leftLegendTest, styles.cordinateHelperLabels]}>{translateString("veryHigh")}</Typography>
                    <Typography sx={[styles.leftLegendTest, styles.cordinateHelperLabels]}>{translateString("high")}</Typography>
                    <Typography sx={[styles.leftLegendTest, styles.cordinateHelperLabels]}>{translateString("moderate")}</Typography>
                    <Typography sx={[styles.leftLegendTest, styles.cordinateHelperLabels]}>{translateString("low")}</Typography>
                    <Typography sx={[styles.leftLegendTest, styles.cordinateHelperLabels]}>{translateString("veryLow")}</Typography>
                </Box>
                <Box>
                    <Grid item xs container direction="column">
                        {[5, 4, 3, 2, 1].map((yValue, yIndex) => (
                            <Grid id={yValue.toString()} key={yValue} container>
                                {[1, 2, 3, 4, 5].map((xValue, xIndex) => {
                                    let gridValue = matrixData[Number(`${xValue}${yValue}`)];
                                    return (
                                        <Grid
                                            id={xValue.toString()}
                                            onClick={() => {
                                                if (gridValue) {
                                                    if (props.gridClicked && selectedGrid.consequence === xValue && selectedGrid.propbability === yValue) {
                                                        onMatrixClick(null, null);
                                                    } else {
                                                        onMatrixClick(xValue, yValue);
                                                    }
                                                }
                                            }}
                                            sx={GetGridBoxStyling(gridValue, styles)}
                                            key={`${yValue}${xValue}`}
                                            item
                                            xs
                                        >
                                            <Paper elevation={0} sx={GetPaperStyling(yIndex, xIndex, xValue, yValue, styles, selectedGrid, props.gridClicked)}>
                                                {props.isLoading ? (
                                                    <Skeleton variant={"rectangular"} width={"100%"} height={"100%"} sx={{ borderRadius: "10px" }} />
                                                ) : (
                                                    <Typography>{gridValue ?? ""}</Typography>
                                                )}
                                            </Paper>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        ))}
                    </Grid>
                    <Box sx={styles.bottomContainer}>
                        <Grid sx={styles.xCordinateHelperContainer} container>
                            <Grid item xs>
                                <Typography sx={styles.cordinateHelperLabels}>{translateString("fewDisadvantages")}</Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography sx={styles.cordinateHelperLabels}>{translateString("disadvantages")}</Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography sx={styles.cordinateHelperLabels}>{translateString("significantDisadvantages")}</Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography sx={styles.cordinateHelperLabels}>{translateString("difficultDisadvantages")}</Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography sx={styles.cordinateHelperLabels}>{translateString("IrreparableDisadvantages")}</Typography>
                            </Grid>
                        </Grid>
                        <Box sx={styles.xAxisContainer}>
                            <Typography sx={styles.axisLabels}>{translateString("consequence")}</Typography>
                        </Box>
                        <Grid container sx={styles.bottomLegendsContainer}>
                            <Grid item xs sx={styles.bottomLegendContainer}>
                                {reallyLowRiskIcon}
                                <Typography> {translateString("veryLow")} </Typography>
                            </Grid>
                            <Grid item xs sx={styles.bottomLegendContainer}>
                                {lowRiskIcon}
                                <Typography> {translateString("low")} </Typography>
                            </Grid>
                            <Grid item xs sx={styles.bottomLegendContainer}>
                                {moderateRiskIcon}
                                <Typography> {translateString("moderate")} </Typography>
                            </Grid>
                            <Grid item xs sx={styles.bottomLegendContainer}>
                                {highRiskIcon}
                                <Typography> {translateString("high")} </Typography>
                            </Grid>
                            <Grid item xs sx={styles.bottomLegendContainer}>
                                {reallyHighRiskIcon}
                                <Typography> {translateString("veryHigh")} </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default ProcessingActivityRiskMatrix;

function GetPaperStyling(
    y: number,
    x: number,
    xValue: number,
    yValue: number,
    classes: any,
    selectedGrid: { propbability: number; consequence: number },
    isGridClicked: boolean
) {
    let stylesToAdd = [classes.paper];
    if (y === x) {
        stylesToAdd.push(classes.yellowPaper);
    } else if (y > x) {
        stylesToAdd.push(classes.greenPaper);
        stylesToAdd.push(GetGreenOpacity(y, x, classes));
    } else if (x > y) {
        stylesToAdd.push(classes.redPaper);
        stylesToAdd.push(GetRedOpacity(y, x, classes));
    }

    if (selectedGrid.consequence === xValue && selectedGrid.propbability === yValue && isGridClicked) {
        stylesToAdd.push(classes.selectedPaper);
    }

    return stylesToAdd;
}

function GetRedOpacity(yIndex: number, xIndex: number, classes: any) {
    let num: number = xIndex - 1;

    if (num === yIndex || xIndex - yIndex === 2) return classes.paleRedPaper;
}

function GetGreenOpacity(yIndex: number, xIndex: number, classes: any) {
    let num: number = xIndex + 1;
    if (num === yIndex || yIndex - xIndex === 2) return classes.paleGreenPaper;
}

function GetGridBoxStyling(gridValue: any, styles: any) {
    let stylesToAdd = [styles.gridBox];

    if (gridValue) stylesToAdd.push(styles.gridBoxHover);

    return stylesToAdd;
}
