import React from "react";
import { Trans } from "react-i18next";
import { useTranslation } from "../../localization/useTranslation";
import { ChangeLegalEntityStatusDialogHooks } from "./ChangeLegalEntityStatusDialog.hooks";
import { DotLegalInfoDialog } from "@dotlegal/dotlegal-ui-components";

export interface IChangeLegalEntityStatusDialog {
    activeStatus: boolean;
    legalEntityId: string | undefined;
    onSaveCorrect: () => void;
    companyName?: string;
    onCloseDialog: () => void;
}

export function ChangeLegalEntityStatusDialog(props: IChangeLegalEntityStatusDialog) {
    const { changeActiveStatus, isSaving } = ChangeLegalEntityStatusDialogHooks(props);
    const { translateString } = useTranslation();

    return (
        <DotLegalInfoDialog
            btnText={props.activeStatus ? translateString("deactivate") : translateString("activate")}
            header={props.activeStatus ? translateString("deactivateCompanyHeader") : translateString("activateCompanyHeader")}
            inProgress={isSaving}
            content={
                props.activeStatus ? (
                    <Trans i18nKey="deactivateDataInfo" values={{ name: props.companyName }}></Trans>
                ) : (
                    <Trans i18nKey="activateDataInfo" values={{ name: props.companyName }}></Trans>
                )
            }
            onBtnClick={() => changeActiveStatus()}
            onCloseDialog={props.onCloseDialog}
        />
    );
}
