import { Grid, GridSize, RadioGroup } from "@mui/material";
import React from "react";
import { DotLegalCheckbox, DotLegalDatePicker, DotLegalMultiSelect, DotLegalRadioButton, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { getTranslationKey, hasFilterData } from "../customListOperations";
import { ContentType } from "../CustomLists.types";
import { ColumnFilterType, CustomListColumnViewModel } from "../editCustomListDialog/EditCustomListDialog.types";
import { useColumnTypeContext } from "./ColumnTypeContext.hooks";
import { useColumnTypeContextStyles } from "./ColumnTypeContext.styles";

export interface IColumnTypeContextProps {
    column: CustomListColumnViewModel;
    customListId: string | undefined;
    showHideColumnCbk: boolean;
}

function ColumnTypeContext(props: IColumnTypeContextProps) {
    const { translateString, translatePropertyName, getDateTimePickerLanguage } = useTranslation();
    const { getListOptions, onListUpdate, onTextUpdate, onRadioButtonChange, onNumberUpdate, onDateUpdate, onCheckBoxUpdate } = useColumnTypeContext(props);
    const styles = useColumnTypeContextStyles();

    const gridProps: { item: boolean; xs: GridSize; sm: GridSize; md: GridSize } = {
        item: true,
        xs: 12,
        sm: 6,
        md: 4,
    };

    const getHideColumnCheckBox = () => {
        return (
            props.showHideColumnCbk &&
            hasFilterData(props.column.filter) && (
                <DotLegalCheckbox
                    margin={{ marginTop: 1, marginBottom: -1 }}
                    label={translateString("hideColumn")}
                    checked={props.column.hideColumn}
                    onChange={onCheckBoxUpdate}
                />
            )
        );
    };

    const getContent = () => {
        var filter = props.column.filter!;
        var label = translatePropertyName(getTranslationKey(props.column.type));
        switch (props.column.contentType) {
            case ContentType.Text:
                return (
                    <React.Fragment>
                        <RadioGroup value={filter.filterType} sx={styles.radioGroup} row onChange={(event) => onRadioButtonChange(event)}>
                            <Grid item>
                                <DotLegalRadioButton
                                    value={filter.filterType === ColumnFilterType.None ? ColumnFilterType.None : ColumnFilterType.StringContainsValue}
                                    label={translateString("hasToContain")}
                                />
                            </Grid>
                            <Grid item>
                                <DotLegalRadioButton value={ColumnFilterType.StringNotContainsValue} label={translateString("mustNotContain")} />
                            </Grid>
                        </RadioGroup>
                        <DotLegalTextField label={label} value={filter.textValue ?? ""} debounce noMargin onChange={(text) => onTextUpdate(text)} />
                        {getHideColumnCheckBox()}
                    </React.Fragment>
                );
            case ContentType.List: {
                var query = getListOptions();
                return (
                    <React.Fragment>
                        <RadioGroup value={filter.filterType} sx={styles.radioGroup} row onChange={(event) => onRadioButtonChange(event)}>
                            <Grid item>
                                <DotLegalRadioButton
                                    value={filter.filterType === ColumnFilterType.None ? ColumnFilterType.None : ColumnFilterType.ListContainsAnyOf}
                                    label={translateString("hasToContain")}
                                />
                            </Grid>
                            <Grid item>
                                <DotLegalRadioButton value={ColumnFilterType.ListContainsNoneOf} label={translateString("mustNotContain")} />
                            </Grid>
                        </RadioGroup>
                        <DotLegalMultiSelect
                            label={label}
                            isLoading={query?.isLoading}
                            options={query?.data}
                            chosenOptions={props.column.filter.listValues}
                            onChange={(data) => onListUpdate(data)}
                            placeholder={""}
                            noMargin
                            noOptionsLabel={translateString("noOptions")}
                        />
                        {getHideColumnCheckBox()}
                    </React.Fragment>
                );
            }
            case ContentType.Boolean: {
                return (
                    <React.Fragment>
                        <RadioGroup value={filter.filterType} sx={styles.radioGroup} row onChange={(event) => onRadioButtonChange(event)}>
                            <Grid>
                                <DotLegalRadioButton
                                    value={filter.filterType === ColumnFilterType.None ? ColumnFilterType.None : ColumnFilterType.BooleanShowAll}
                                    label={translateString("seeAll")}
                                />
                            </Grid>
                            <Grid>
                                <DotLegalRadioButton value={ColumnFilterType.BooleanShowTrue} label={translateString("yes")} />
                            </Grid>
                            <Grid>
                                <DotLegalRadioButton value={ColumnFilterType.BooleanShowFalse} label={translateString("no")} />
                            </Grid>
                        </RadioGroup>
                        {getHideColumnCheckBox()}
                    </React.Fragment>
                );
            }
            case ContentType.Date: {
                return (
                    <React.Fragment>
                        <RadioGroup value={filter.filterType} sx={styles.radioGroup} row onChange={(event) => onRadioButtonChange(event)}>
                            <Grid>
                                <DotLegalRadioButton
                                    value={filter.filterType === ColumnFilterType.None ? ColumnFilterType.None : ColumnFilterType.DateSpecific}
                                    label={translateString("specificDate")}
                                />
                            </Grid>
                            <Grid>
                                <DotLegalRadioButton value={ColumnFilterType.DateBefore} label={translateString("beforeChosenDate")} />
                            </Grid>
                            <Grid>
                                <DotLegalRadioButton value={ColumnFilterType.DateAfter} label={translateString("afterChosenDate")} />
                            </Grid>
                        </RadioGroup>

                        <DotLegalDatePicker
                            label={label}
                            noMargin
                            onChange={(date) => onDateUpdate(date)}
                            value={filter.date}
                            language={getDateTimePickerLanguage()}
                        />

                        {getHideColumnCheckBox()}
                    </React.Fragment>
                );
            }
            case ContentType.Number: {
                return (
                    <React.Fragment>
                        <RadioGroup value={filter.filterType} sx={styles.radioGroup} row onChange={(event) => onRadioButtonChange(event)}>
                            <Grid {...gridProps}>
                                <DotLegalRadioButton
                                    value={filter.filterType === ColumnFilterType.None ? ColumnFilterType.None : ColumnFilterType.NumberIsEqualTo}
                                    label={translateString("numberIsEqualTo")}
                                />
                            </Grid>
                            <Grid {...gridProps}>
                                <DotLegalRadioButton value={ColumnFilterType.NumberIsNotEqualTo} label={translateString("numberisNotEqualTo")} />
                            </Grid>
                            <Grid {...gridProps}>
                                <DotLegalRadioButton value={ColumnFilterType.NumberIsGreaterThan} label={translateString("numberIsGreaterThan")} />
                            </Grid>
                            <Grid {...gridProps}>
                                <DotLegalRadioButton
                                    value={ColumnFilterType.NumberIsGreaterThanOrEqualTo}
                                    label={translateString("numberisGreaterThanOrEqualTo")}
                                />
                            </Grid>
                            <Grid {...gridProps}>
                                <DotLegalRadioButton value={ColumnFilterType.NumberIsLessThan} label={translateString("numberIsLessThan")} />
                            </Grid>
                            <Grid {...gridProps}>
                                <DotLegalRadioButton value={ColumnFilterType.NumberIsLessThanOrEqualTo} label={translateString("numberIsLessThanOrEqualTo")} />
                            </Grid>
                        </RadioGroup>
                        <DotLegalTextField
                            label={label}
                            value={
                                props.column.filter.numberValue !== undefined && props.column.filter.numberValue !== null
                                    ? props.column.filter.numberValue.toString()
                                    : ""
                            }
                            onlyNumber={"integer"}
                            onChange={(value) => onNumberUpdate(value)}
                            debounce
                        />
                        {getHideColumnCheckBox()}
                    </React.Fragment>
                );
            }
        }
    };

    return getContent();
}

export default ColumnTypeContext;
