export class LegalEntityRiskAssessmentQuestionnaireViewModel {
    questions: Array<LegalEntityRiskAssessmentQuestion> = [];
    calculatedRisk?: LegalEntityRiskAssessment;
}

export interface LegalEntityRiskAssessmentQuestion {
    questionId: LegalEntityRiskAssessmentQuestionId;
    answerId?: LegalEntityRiskAssessmentQuestionAnswerId;
}

export enum LegalEntityRiskAssessmentQuestionId {
    Question1,
    Question2,
    Question3,
    Question4,
}

export enum LegalEntityRiskAssessmentQuestionAnswerId {
    Answer1,
    Answer2,
    Answer3,
}

export enum LegalEntityRiskAssessment {
    Low = 0,
    Moderat = 1,
    High = 2,
    Critical = 3,
}
