import { LevelOfProtectionType } from "./addLegalEntityDialog/AddLegalEntityDialog.types.ts";
import { useTranslation } from "../localization/useTranslation.ts";

export function useLevelsOfProtectionForSelection() {
    const { translateString } = useTranslation();
    return (country: string) => {
        if (country === "CA") {
            return [
                { name: translateString("cpieda"), id: LevelOfProtectionType[LevelOfProtectionType.CPIEDA] },
                { name: translateString("thirdcountry"), id: LevelOfProtectionType[LevelOfProtectionType.ThirdCountry] },
            ];
        } else if (country === "US") {
            return [
                { name: translateString("dataPrivacyFramework"), id: LevelOfProtectionType[LevelOfProtectionType.DataPrivacyFramework] },
                { name: translateString("thirdcountry"), id: LevelOfProtectionType[LevelOfProtectionType.ThirdCountry] },
            ];
        }
    };
}
