import { useState } from "react";
import { useQuery } from "react-query";
import { useUserContext } from "../auth/userContextProvider/UserContextProvider";
import { get } from "../common/api/apiShared";
import { downloadFile } from "../common/api/downloadFile";
import { useDotLegalSnackbar, useStateSessionStorage } from "@dotlegal/dotlegal-ui-components";
import { useStateUrlParams, useStateUrlParamsArray } from "../common/hooks/useStateUrlParams";
import { DataControllerRecordExportTranslations, DataControllerRecordModel } from "../DataControllerRecord/DataControllerRecord.types";
import { DataProcessorRecordExportTranslations, DataProcessorRecordRow, DataProcessorRecordTableModel } from "../DataProcessorRecord/DataProcessorRecord.types";
import { useTranslation } from "../localization/useTranslation";
import { RecordType } from "./Record.types";
import { usePlanContext } from "../auth/planProvider/PlanProvider";
import { ControllerProcess } from "../processingActivity/processingActivityOverview/ProcessingActivityOverview.types";

export function useRecordDataMapping() {
    const { translateDate, translateString } = useTranslation();
    const { permissions } = useUserContext();
    const { dataProtectionFeatures } = usePlanContext();
    const snackBar = useDotLegalSnackbar();

    const [groupEntityId, setGroupEntityId] = useStateUrlParams("groupEntityId", null);
    const [onlyUserSpecific, setOnlyUserSpecific] = useStateSessionStorage("recordOnlyUserSpecific", !permissions.canAccessAllData);
    const [isExporting, setIsExporting] = useState(false);
    const [processingActivityIds, setProcessingActivityIds] = useStateUrlParamsArray("processingActivityIds", []);
    const [processingAreaIds, setProcessingAreaIds] = useStateUrlParamsArray("processingAreaIds", []);
    const [searchString, setSearchString] = useState("");
    const [selectedRecord, setSelectedRecord] = useStateUrlParams(
        "record",
        permissions.canAccessRecord301 ? RecordType.DataController : RecordType.DataProcessor
    );
    const queryPart = `?groupEntityId=${groupEntityId}&onlyUserSpecific=${onlyUserSpecific}&processingActivityIds=${processingActivityIds}&processingAreaIds=${processingAreaIds}&isExtended=${
        selectedRecord === RecordType.ExtentedDataController
    }`;

    const dataProcessorUrl = `/DataProcessorRecord${queryPart}`;
    const dataProcessorQuery = useQuery(dataProcessorUrl, () => get<Array<DataProcessorRecordTableModel>>(dataProcessorUrl), {
        enabled: selectedRecord === RecordType.DataProcessor,
    });

    const dataControllerUrl = `/DataControllerRecord${queryPart}`;
    const dataControllerQuery = useQuery(dataControllerUrl, () => get<Array<DataControllerRecordModel>>(dataControllerUrl), {
        enabled: selectedRecord !== RecordType.DataProcessor,
    });

    const onGroupEntityChange = (groupEntity: string | null) => {
        setGroupEntityId(groupEntity);
    };

    const onExportClick = async () => {
        const exportData = getExportData();
        const date = new Date();
        const fileName =
            exportData.name + translateDate(date) + " " + date.getHours().toString().padStart(2) + "_" + date.getMinutes().toString().padStart(2) + ".xlsx";
        setIsExporting(true);
        await downloadFile(exportData.url + queryPart, fileName, "POST", exportData.translations);
        setIsExporting(false);
        snackBar.show(fileName + " " + translateString("downloaded"));
    };

    const getExportData = () => {
        return selectedRecord === RecordType.DataProcessor
            ? { name: "DataProcessorRecordExport-", url: "/DataProcessorRecord/export", translations: getDataProcessorTranslations() }
            : { name: "DataControllerRecordExport-", url: "/DataControllerRecord/export", translations: getDataControllerTranslations() };
    };

    const hasData =
        selectedRecord === RecordType.DataProcessor
            ? dataProcessorQuery.isLoading || dataProcessorQuery.data!.length > 0
            : dataControllerQuery.isLoading || dataControllerQuery.data!.length > 0;

    const getFilteredRows = (rows: Array<DataProcessorRecordRow>, formattedSearchString: string) => {
        let tempData = [...rows];

        tempData = tempData.filter(
            (y) =>
                y.processingActivityName.toLowerCase().includes(formattedSearchString) ||
                y.securityMeasures.some((s) => s.toLowerCase().includes(formattedSearchString)) ||
                y.thirdCountryTransfers.some((t) => t.toLowerCase().includes(formattedSearchString))
        );

        return tempData;
    };

    const disableExport = () => {
        if (!dataProtectionFeatures.recordsExport) return true;

        if (selectedRecord === RecordType.DataProcessor) return dataProcessorQuery.isLoading || dataProcessorQuery.data!.length === 0;
        else return dataControllerQuery.isLoading || dataControllerQuery.data!.length === 0;
    };

    let dataProcessorRows: Array<DataProcessorRecordTableModel> = [];

    if (dataProcessorQuery.data) {
        let tempData = [...dataProcessorQuery.data];

        var formattedSearchString = searchString.trim().toLowerCase();

        tempData.forEach((x) => {
            if (!x.name.toLowerCase().includes(formattedSearchString))
                dataProcessorRows.push({ name: x.name, rows: getFilteredRows(x.rows, formattedSearchString) });
            else dataProcessorRows.push({ name: x.name, rows: x.rows });
        });

        dataProcessorRows = dataProcessorRows.filter((x) => x.rows.length > 0);
    }

    function getRecordSelectOptions() {
        const recordSelectOptions = [];

        if (permissions.canAccessRecord301) {
            recordSelectOptions.push({ name: translateString("dataControllerRecord"), id: RecordType.DataController });
            recordSelectOptions.push({ name: translateString("dataControllerRecordExtended"), id: RecordType.ExtentedDataController });
        }

        if (permissions.canAccessRecord302) {
            recordSelectOptions.push({
                name: translateString("dataProcessorRecord"),
                id: RecordType.DataProcessor,
                hoverText: dataProtectionFeatures.recordDataProcessor === false ? translateString("upgradeDataProtectionPlan") : undefined,
                disabled: dataProtectionFeatures.recordDataProcessor === false ? true : false,
            });
        }

        return recordSelectOptions;
    }

    return {
        dataProcessorRows,
        dataProcessorRowsIsLoading: dataProcessorQuery.isLoading,
        dataControllerRows: dataControllerQuery.data ?? [],
        dataControllerRowsIsLoading: dataControllerQuery.isLoading,
        onGroupEntityChange,
        selectedGroupEntity: groupEntityId,
        processingActivityIds,
        setProcessingActivityIds,
        processingAreaIds,
        setProcessingAreaIds,
        onlyUserSpecific,
        setOnlyUserSpecific,
        onExportClick,
        isExporting,
        setSearchString,
        searchString,
        recordSelectOptions: getRecordSelectOptions(),
        selectedRecord,
        setSelectedRecord,
        isExportDisabled: disableExport(),
        hasData,
    };

    function getDataProcessorTranslations(): DataProcessorRecordExportTranslations {
        return {
            name: translateString("name"),
            address: translateString("address"),
            city: translateString("city"),
            countryCode: translateString("countryCode"),
            companyRegistrationNumber: translateString("companyRegistrationNumber"),
            processingActivityName: translateString("processingActivity"),
            securityMeasures: translateString("securityMeasures"),
            thirdCountryTransfers: translateString("recipientsThirdCountry"),
            record: translateString("dataProcessorRecord"),
            dataController: translateString("dataController"),
            dataCategories: translateString("dataCategoryHeader"),
        };
    }

    function getDataControllerTranslations(): DataControllerRecordExportTranslations {
        return {
            name: translateString("name"),
            address: translateString("address"),
            city: translateString("city"),
            countryCode: translateString("countryCode"),
            companyRegistrationNumber: translateString("companyRegistrationNumber"),
            purposes: translateString("purposes"),
            dataCategories: translateString("dataCategoryHeader"),
            dataSubjects: translateString("dataSubjects"),
            deletionPeriod: translateString("deletionPeriod"),
            securityMeasures: translateString("securityMeasures"),
            recipientsEuEaa: translateString("recipientsEuEaa"),
            recipientsAdequate: translateString("recipientsAdequate"),
            recipientsThirdCountry: translateString("recipientsThirdCountry"),
            record: translateString("dataControllerRecord"),
            processingActivity: translateString("processingActivity"),
            dataProtectionOfficer: translateString("dataProtectionOfficer"),
            businessAreas: translateString("businessAreas"),
            description: translateString("description"),
            disclosureBases: translateString("basisOfDisclosures"),
            legalBases: translateString("legalBasisDataProcessorRecord"),
            systems: translateString("systems"),
            transferBases: translateString("transferBasis"),
            sharingRoleController: translateString(ControllerProcess[ControllerProcess.Controller]),
            sharingRoleJointControllers: translateString(ControllerProcess[ControllerProcess.JointControllers]),
            sharingRoleProcessorW: translateString(ControllerProcess[ControllerProcess.ProcessorW]),
            sharingRoleSubprocessor: translateString(ControllerProcess[ControllerProcess.Subprocessor]),
            gDPRContact: translateString("gDPRContact"),
        };
    }
}
