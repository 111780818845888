import { useTheme } from "@mui/material";
import { createSxStyles } from "../../../common/createSxStyles";

export const useSourceStepStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        addNewSource: {
            marginTop: theme.spacing(3),
            display: "flex",
            justifyContent: "center",
        },
        errorBorder: {
            borderColor: theme.palette.error.main,
        },
        collapse: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        collapseArrow: {
            fill: theme.palette.secondary.main,
            transform: "rotate(180deg)",
            marginRight: theme.spacing(1.5),
            marginLeft: theme.spacing(1.5),
        },
        systemImage: {
            "& .collapseSVG svg": {
                stroke: theme.palette.primary.main,
                fill: theme.palette.primary.light,
                width: theme.spacing(3),
                height: theme.spacing(3),
            },
        },
        disableSources: {
            display: "flex",
            justifyContent: "center",
            paddingTop: theme.spacing(2),
            flexDirection: "column",
            alignItems: "center",
        },
        error: {
            fontSize: theme.typography.pxToRem(12),
            color: theme.palette.error.main,
            textAlign: "center",
            marginTop: theme.spacing(1),
        },
    });
};
