import { DotLegalDialog, DotLegalHeader, DotLegalSelect, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { Box, Divider, Grid } from "@mui/material";
import useDeclarationControlDialogHooks from "./DeclarationControlDialog.hooks";
import RelatedTasksSelector from "../../../taskManagement/editTaskDialog/taskRelations/relatedTasksSelector/RelatedTasksSelector";
import DotLegalSelectApi from "../../../common/components/dotLegalSelectApi/DotLegalSelectApi";
import { apiUrls } from "../../../common/api/apiUrls";
import { DeclarationControlSaveModel } from "./DeclarationControlDialog.types";

export interface IDeclarationControlDialog {
    onDialogClose: () => void;
    deadline: Date;
    declarationId: string;
    onControlSaved: () => void;
    declarationControlId?: string;
    isLocked?: boolean;
}

function DeclarationControlDialog(props: IDeclarationControlDialog) {
    const { translateString } = useTranslation();
    const { saveModel, modelUpdater, data, validation, onSaveClick, isSaving, selectableResponsiblesData, selectableresponsiblesLoading, isLoading, isEdit } =
        useDeclarationControlDialogHooks(props);

    validation.check(saveModel);

    return (
        <DotLegalDialog
            header={props.isLocked ? translateString("controlActivity") : isEdit ? translateString("editControl") : translateString("addControl")}
            buttonOkText={translateString("save")}
            open
            onDialogClose={props.onDialogClose}
            onOkClick={() => onSaveClick()}
            size="lg"
            inProgress={isSaving}
            isLoading={isLoading}
            hideOkButton={props.isLocked}
            disableBackdropOnCloseIfValueChange={saveModel}
        >
            <Box sx={{ marginLeft: 3, marginRight: 3 }}>
                <Grid container columnSpacing={8}>
                    <Grid item sm={6}>
                        <DotLegalTextField
                            disabled={props.isLocked}
                            label={translateString("nameOfControl")}
                            errorText={validation.getErrorsForDisplay("name")}
                            value={saveModel.name}
                            onChange={modelUpdater.onNameChange}
                            debounce={false}
                            toolTipText={translateString("declarationControlNameTooltip")}
                        ></DotLegalTextField>
                    </Grid>
                    <Grid item sm={6}>
                        <DotLegalTextField
                            disabled={props.isLocked}
                            label="ID"
                            value={saveModel.customId}
                            debounce={false}
                            toolTipText={translateString("declarationControlIdTooltip")}
                            onChange={modelUpdater.onIDChange}
                        ></DotLegalTextField>
                    </Grid>
                    <Grid item sm={6}>
                        <DotLegalSelectApi
                            disabled={props.isLocked}
                            url={apiUrls().declarations.selectableItems(props.declarationId)}
                            selectedItem={saveModel.categoryId}
                            errorText={validation.getErrorsForDisplay("categoryId")}
                            onChange={modelUpdater.onCategoryChanged}
                            label={translateString("category")}
                            noOptionsLabel={translateString("noOptions")}
                            toolTipText={translateString("declarationControlCategoryTooltip")}
                        ></DotLegalSelectApi>
                    </Grid>
                    <Grid item sm={6}>
                        <DotLegalSelect
                            disabled={props.isLocked}
                            options={selectableResponsiblesData}
                            isLoading={selectableresponsiblesLoading}
                            selectedItem={saveModel.responsibleId}
                            onChange={modelUpdater.onResponsibleChanged}
                            label={translateString("responsible")}
                            noOptionsLabel={translateString("noOptions")}
                            toolTipText={translateString("declarationControlResponsibleTooltip")}
                            errorText={validation.getErrorsForDisplay("responsibleId")}
                        ></DotLegalSelect>
                    </Grid>
                    <Grid item sm={6}>
                        <DotLegalTextField
                            disabled={props.isLocked}
                            label={translateString("description")}
                            value={saveModel.description}
                            debounce={false}
                            toolTipText={translateString("declarationControlDescriptionTooltip")}
                            multiline
                            rows={3}
                            onChange={modelUpdater.onDescriptionChange}
                        ></DotLegalTextField>
                    </Grid>
                </Grid>

                <Divider sx={(theme) => ({ marginTop: 4, marginBottom: 4, borderColor: theme.palette.primary.light })} />

                <Box sx={{ display: "flex", justifyContent: "center", textTransform: "uppercase" }}>
                    <DotLegalHeader center headerStyle={"small"} toolTip={translateString("declarationControlDocumentationTooltip")}>
                        {translateString("linkDocumentation")}
                    </DotLegalHeader>
                </Box>

                <RelatedTasksSelector
                    firstColumnHeader={translateString("tasksToBeCompleted")}
                    readOnly={props.isLocked}
                    showRelatedTasks={false}
                    selectableTasks={data?.filter((x) => !saveModel.taskIds.includes(x.taskId)) ?? []}
                    shouldAddRelatedTask={false}
                    updateRelatedTaskScenario={() => {}}
                    onAddTask={modelUpdater.onAddTask}
                    onDeleteTaskClick={modelUpdater.onRemoveTask}
                    selectedTasks={data?.filter((x) => saveModel.taskIds.includes(x.taskId))}
                />
            </Box>
        </DotLegalDialog>
    );
}

export default DeclarationControlDialog;
