import React from "react";
import DotLegalDeleteDialogWrapper from "../../../common/components/dotLegalDeleteDialog/DotLegalDeleteDialogWrapper";
import { SelectedLegalEntityDocument } from "../LegalEntityDocumentTab.types";
import { useDeleteLegalEntityDocumentHook } from "./DeleteLegalEntityDocumentDialog.hooks";

export interface IDeleteLegalEntityDocumentDialogProps {
    onCloseDialog: () => void;
    onSaveCorrect: () => void;
    selectedDocument: SelectedLegalEntityDocument;
    legalEntityId: string | undefined;
}

export function DeleteLegalEntityDocumentDialog(props: IDeleteLegalEntityDocumentDialogProps) {
    const { deleteDocumentClick, inProgess } = useDeleteLegalEntityDocumentHook(props);

    return (
        <DotLegalDeleteDialogWrapper
            open
            itemForDeletion={props.selectedDocument!.name}
            onDialogClose={props.onCloseDialog}
            onOkClick={() => deleteDocumentClick(props.selectedDocument!.documentId)}
            inProgress={inProgess}
        />
    );
}
