import { DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import Widget from "../../Widget";
import { Box } from "@mui/material";
import { useTranslation } from "../../../localization/useTranslation";
import RiskWidgetAssessment from "../../riskWidget/riskWidgetAssessment/RiskWidgetAssessment";
import RiskWidgetList from "../../riskWidget/riskWidgetList/RiskWidgetList";
import { IRiskWidgetProps } from "../../riskWidget/RiskWidget";
import { useRiskWidget } from "../../riskWidget/RiskWidget.hooks";
import { usePlanContext } from "../../../auth/planProvider/PlanProvider";

function RiskWidgetReport(props: IRiskWidgetProps) {
    const { translateString } = useTranslation();
    const { data, isEnabled, isLoading } = useRiskWidget(props);
    return (
        <Widget disabled={!isEnabled} isPdf>
            <DotLegalHeader headerStyle={"medium"} marginBottom={0}>
                {translateString("risk").toUpperCase()}
            </DotLegalHeader>
            <DotLegalHeader headerStyle={"small"} marginBottom={0}>
                {props.isSystemChosen ? translateString("assets") : translateString("processingActivities")}
            </DotLegalHeader>

            <RiskWidgetAssessment isLoading={isLoading} percentage={data?.gdprPercentage} type="GDPR" marginTop={1} />

            <RiskWidgetAssessment isLoading={isLoading} percentage={data?.nisPercentage} type="NIS2" marginTop={2} />

            <RiskWidgetList systemsChosen={props.isSystemChosen ?? false} isLoading={isLoading} data={data} idPdf />
        </Widget>
    );
}

export default RiskWidgetReport;
