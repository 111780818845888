import { useQuery } from "react-query";

export function useNotificationMarkDownContentDataMapping(url: string) {
    let { data, isLoading } = useQuery(url, () =>
        fetch(url).then((response) => {
            return response.text();
        })
    );

    return {
        content: data ?? "",
        isLoading,
    };
}
