import { useMemo } from "react";
import { RiskLevelChartProps } from "./RiskLevelChart";

export function useRiskLevelChart(props: RiskLevelChartProps) {
    const graphMaxValue = useMemo(() => {
        return props.chartData.map((d) => d.count).reduce((a, b) => Math.max(a, b)) + 0.5;
    }, [props.chartData]);

    return {
        graphMaxValue,
    };
}
