import { DeclarationCategoriesViewModel, DeclarationCategoryViewModel } from "./DeclarationCategories.types.ts";
import { useState } from "react";
import { useQuery } from "react-query";
import { get } from "../common/api/apiShared.ts";
import { useParams } from "react-router-dom";

export default function useDeclarationCategories() {
    const { id } = useParams<{ id: string }>();
    const [showAddEditDialog, setShowAddEditDialog] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState<DeclarationCategoryViewModel | undefined>(undefined);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const url = `/declarationCategories/all/${id}`;
    let { isLoading, data, refetch } = useQuery(url, () => get<DeclarationCategoriesViewModel>(url));

    return {
        isLoading,
        data,
        hasData: isLoading || (data && data.rows.length > 0),
        showAddEditDialog,
        setShowAddEditDialog,
        selectedCategory,
        setSelectedCategory,
        showDeleteDialog,
        setShowDeleteDialog,
        categoryId: id,
        refetch,
    };
}
