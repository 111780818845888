import { Box } from "@mui/material";
import ThisMonthIcon from "../../svgs/this-month.svg?react";
import OverdueIcon from "../../svgs/overdue.svg?react";
import { DotLegalCompletedPercentage, DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import WhiteDashboardBox from "../../WhiteDashboardBox";

export interface ITaskWidgetStatus {
    numberOfTasks: number;
    showCurrentMonth: boolean;
    progress: number;
}

export default function TasksWidgetStatus(props: ITaskWidgetStatus) {
    return (
        <WhiteDashboardBox marginTop={1}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 1,
                }}
            >
                <Box sx={{ display: "flex" }}>{props.showCurrentMonth ? <ThisMonthIcon /> : <OverdueIcon />}</Box>

                <DotLegalHeader headerStyle={"xxxl"} marginBottom={0} fontWeight={400}>
                    {props.numberOfTasks}
                </DotLegalHeader>

                <DotLegalCompletedPercentage completed={props.progress} fixedColor={!props.showCurrentMonth && props.progress < 100 ? "red" : undefined} />
            </Box>
        </WhiteDashboardBox>
    );
}
