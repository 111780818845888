import { Box } from "@mui/material";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import React from "react";
import { DotLegalTooltip } from "@dotlegal/dotlegal-ui-components";

export interface DotLegalWarningTriangleProps {
    warningText?: string;
}

function DotLegalWarningTriangle(props: DotLegalWarningTriangleProps) {
    return (
        <Box
            sx={(theme) => ({
                display: "flex",
                alignContent: "center",
                height: 17,
                width: 17,
                marginLeft: theme.spacing(0.5),
                "& svg": {
                    fill: theme.palette.error.light,
                    height: 17,
                    width: 17,
                },
            })}
        >
            <DotLegalTooltip text={props.warningText} disableHoverlistener={!props.warningText}>
                <ReportProblemOutlinedIcon fontSize={"small"} />
            </DotLegalTooltip>
        </Box>
    );
}

export default DotLegalWarningTriangle;
