export function getEnumValues(e: any) {
    return Object.keys(e)
        .filter((q) => isNaN(Number(q)))
        .map((q) => e[q as keyof typeof e]);
}

export function convertEnumToStringOrNull(value: any) {
    if (value !== undefined && value !== null) {
        return value.toString();
    }
    return null;
}
