import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { get, put } from "../../common/api/apiShared";
import { DotLegalSelectOption } from "../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { useTranslation } from "../../localization/useTranslation";
import { ProcessingActivityIndustryAreaEnum } from "../ProcessingActivity.types";
import { IUpdateprocessingTemplateInfoDialogProps } from "./UpdateProcessingTemplateInfoDialog";

export interface ProcessingActivitTemplateInfoModel {
    mostPopular: boolean;
    industryAreas: Array<ProcessingActivityIndustryAreaEnum>;
}

export interface ProcessingActivitTemplateInfoViewModel {
    mostPopular: boolean;
    industryAreas: Array<string>;
}

export function useUpdateProcessingTemplateInfo(props: IUpdateprocessingTemplateInfoDialogProps) {
    const { translateString } = useTranslation();
    const [model, setModel] = useState<ProcessingActivitTemplateInfoViewModel>();
    let { isLoading, data } = useQuery("templateInfo" + props.processingActivityId, () =>
        get<ProcessingActivitTemplateInfoModel>(`/ProcessesingActivityTemplates/${props.processingActivityId}/templateInfo`)
    );

    useEffect(() => {
        if (data) {
            setModel({
                mostPopular: data.mostPopular,
                industryAreas: data.industryAreas.map((x) => {
                    return x.toString();
                }),
            });
        }
    }, [props.processingActivityId, data]);

    let industryAreasSelectOptions: Array<DotLegalSelectOption> = Object.entries(ProcessingActivityIndustryAreaEnum)
        .filter((q) => isNaN(Number(q[0])))
        .map((q) => {
            return {
                name: translateString(q[0].toLowerCase()),
                id: q[1].toString(),
            };
        });

    const saveMutation = useMutation(save, {
        onSuccess: (response: any) => {
            props.closeDialog();
        },
    });

    const saveTemplateInfo = async () => {
        await saveMutation.mutateAsync();
    };

    function save() {
        return put<{}>(`/ProcessesingActivityTemplates/${props.processingActivityId}/templateInfo`, {
            mostPopular: model?.mostPopular,
            industryAreas: model?.industryAreas.map((x) => {
                return Number(x);
            }),
        });
    }

    return {
        isLoading,
        data,
        industryAreasSelectOptions,
        saveTemplateInfo,
        inProgress: saveMutation.isLoading,
        model,
        setModel,
    };
}
