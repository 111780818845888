import { useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";

export const useAddEditUserDialogStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        dialogBody: {
            paddingLeft: theme.spacing(10),
            paddingRight: theme.spacing(10),
        },
        dpoContainer: {
            display: "flex",
            alignItems: "center",
        },
        dpoText: {
            color: theme.palette.primary.dark,
        },
        dpoToolTip: {
            marginBottom: -4 + "px",
        },
    });
};
