import { DotLegalTooltip } from "@dotlegal/dotlegal-ui-components";
import { ReactNode } from "react";

export interface IPlanInputFieldWrapperProps {
    children: any;
    hasAccess: boolean | undefined;
    hoverText: string;
}

export default function PlanInputFieldWrapper(props: IPlanInputFieldWrapperProps) {
    function getInput() {
        if (props.hasAccess) {
            return props.children;
        }

        return (
            <DotLegalTooltip text={props.hoverText}>
                <span>{props.children}</span>
            </DotLegalTooltip>
        );
    }

    return getInput();
}
