import React from "react";
import { Trans } from "react-i18next";
import { useTranslation } from "../../localization/useTranslation";
import { ChangeCustomerOwnedDialogDataMapping } from "./ChangeCustomerOwnedDialog.hooks";
import { DotLegalInfoDialog } from "@dotlegal/dotlegal-ui-components";

export interface IChangeCustomerOwnedDialog {
    isCustomerOwned: boolean;
    legalEntityId: string | undefined;
    companyName?: string;
    onCloseDialog: () => void;
    onUpdate: () => void;
}

export function ChangeCustomerOwnedDialog(props: IChangeCustomerOwnedDialog) {
    const { changeCustomerOwned, isSaving } = ChangeCustomerOwnedDialogDataMapping(props);
    const { translateString } = useTranslation();

    return (
        <DotLegalInfoDialog
            btnText={translateString("save")}
            header={translateString("changeToGroupCompany")}
            inProgress={isSaving}
            content={<Trans i18nKey="changeToGroupCompanyInfo" values={{ name: props.companyName }}></Trans>}
            onBtnClick={() => changeCustomerOwned()}
            onCloseDialog={props.onCloseDialog}
        />
    );
}
