import React from "react";
import { useTranslation } from "../../localization/useTranslation";
import { TrialPlanViewModel } from "../../trial/Trial.types";
import PlanDialog from "../planDialog/PlanDialog";
import PlanDialogUpgradeButton from "../planDialogComponents/PlanDialogUpgradeButton";
import PlanStatusMessage from "../planStatusMessage/PlanStatusMessage";
import { DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import { Box } from "@mui/material";
import { Trans } from "react-i18next";

export interface IOverviewOfTrialsDialogProps {
    onDialogClose: () => void;
    plansOnTrials: Array<TrialPlanViewModel>;
}

function OverviewOfTrialsDialog(props: IOverviewOfTrialsDialogProps) {
    const { translateString, translatePlanType } = useTranslation();

    function getContent() {
        return (
            <DotLegalHeader marginBottom={1} fontWeight={500} center headerStyle="small">
                <React.Fragment>
                    {props.plansOnTrials.map((plan) => (
                        <Box key={plan.planType} sx={{ marginBottom: 1.5 }}>
                            <Trans
                                i18nKey={"trialDaysRemaningOnPlan"}
                                values={{
                                    plan: translatePlanType(plan.planType),
                                    days: plan.trialDaysRemaining,
                                    daysText: plan.trialDaysRemaining === 1 ? translateString("day").toLowerCase() : translateString("days").toLowerCase(),
                                }}
                                components={{
                                    span: (
                                        <Box
                                            component={"span"}
                                            sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, color: theme.palette.primary.dark })}
                                        />
                                    ),
                                    plan: (
                                        <Box
                                            component={"span"}
                                            sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, color: theme.palette.primary.main })}
                                        />
                                    ),
                                }}
                            />
                        </Box>
                    ))}
                </React.Fragment>
            </DotLegalHeader>
        );
    }

    return (
        <PlanDialog
            showDialog
            onDialogClose={props.onDialogClose}
            content={getContent()}
            header={translateString("overviewOfTrials")}
            buttons={<PlanDialogUpgradeButton />}
        ></PlanDialog>
    );
}

export default OverviewOfTrialsDialog;
