import { useParams } from "react-router-dom";
import { StepModel } from "../../common/components/dotLegalStepper/DotLegalCoreStepper.types";
import { useTranslation } from "../../localization/useTranslation";
import { LegalEntityManagementModel, LegalEntityAuditFrequency, LegalEntityAuditType, LegalEntityUpdateModel } from "./LegalEntityManagement.types";
import { useLegalEntityManagementSteps } from "./steps/useLegalEntitySteps";
import { useQuery } from "react-query";
import { get, put } from "../../common/api/apiShared";
import { useOptimisticUpdate } from "../../common/hooks/useOptimisticUpdate";
import { useState } from "react";
import { useUrlProvider } from "../../useUrlProvider";
import { LegalEntityPageEnum } from "../legalEntities/LegalEntities.types";
import { ILegalEntityManagement } from "./LegalEntityManagement";
import { useHistoryWithReferer } from "@dotlegal/dotlegal-ui-components";
import { useValidateDescriptionStep } from "./steps/legalEntityDescriptionStep/LegalEntityDescriptionStep.hooks";

export default function useLegalEntityManagementHooks(props: ILegalEntityManagement) {
    const { putOnQueueAndSetQueryData } = useOptimisticUpdate();
    const { translateString } = useTranslation();
    const historyWithReferer = useHistoryWithReferer();
    const [saveClicked, setSaveClicked] = useState(false);
    const { getLegalEntityUrl, getGroupEntityUrl, getCustomerLegalEntityUrl, getOtherLegalEntityUrl } = useUrlProvider();

    const { id } = useParams<{ id: string }>();

    const queryKey = "legalEntityManagement" + id;
    let { data, isLoading } = useQuery(queryKey, () => get<LegalEntityManagementModel>(`/legalEntityManagement/${id}`));

    const validateDescriptionStepStep = useValidateDescriptionStep();
    const steps = useLegalEntityManagementSteps(data ?? new LegalEntityManagementModel(), getSteps());

    async function onSaveLegalEntity() {
        setSaveClicked(true);
        await updateLegalEntityAPI(data!);
        setSaveClicked(false);
        onGoToLegalEntityClick(id!);
    }

    const onGoToLegalEntityClick = (legalEntityId: string) => {
        switch (props.legalEntityPage) {
            case LegalEntityPageEnum.Vendor:
                historyWithReferer.push(getLegalEntityUrl(legalEntityId));
                break;
            case LegalEntityPageEnum.GroupEntity:
                historyWithReferer.push(getGroupEntityUrl(legalEntityId));
                break;
            case LegalEntityPageEnum.Customer:
                historyWithReferer.push(getCustomerLegalEntityUrl(legalEntityId));
                break;
            case LegalEntityPageEnum.Other:
                historyWithReferer.push(getOtherLegalEntityUrl(legalEntityId));
                break;
            default: {
                throw new Error(`The given page '${props.legalEntityPage}' for legal entity was not found`);
            }
        }
    };

    function changeStep(step: number) {
        steps.changeStep(step);
    }

    async function onLegalEntityChange(legalEntity: LegalEntityManagementModel) {
        await putOnQueueAndSetQueryData(legalEntity, queryKey, updateLegalEntityAPI, legalEntity);
    }

    const legalEntityUpdater = {
        onNameChange: (name: string) => {
            let tempData = { ...data! };
            tempData.name = name;
            onLegalEntityChange(tempData);
        },
        onAddressChange: (address: string) => {
            let tempData = { ...data! };
            tempData.address = address;
            onLegalEntityChange(tempData);
        },
        onDescriptionChange: (description: string) => {
            let tempData = { ...data! };
            tempData.description = description;
            onLegalEntityChange(tempData);
        },
        onZipCodeChange: (zipCode: string) => {
            let tempData = { ...data! };
            tempData.zipCode = zipCode;
            onLegalEntityChange(tempData);
        },
        onCityChange: (city: string) => {
            let tempData = { ...data! };
            tempData.city = city;
            onLegalEntityChange(tempData);
        },
        onCompanyRegistrationNumberChange: (companyRegistrationNumber: string) => {
            let tempData = { ...data! };
            tempData.companyRegistrationNumber = companyRegistrationNumber;
            onLegalEntityChange(tempData);
        },
        onWebsiteChange: (website: string) => {
            let tempData = { ...data! };
            tempData.website = website;
            onLegalEntityChange(tempData);
        },
        onTypesChange: (types: Array<string>) => {
            let tempData = { ...data! };
            tempData.types = types.map((x) => Number(x));
            onLegalEntityChange(tempData);
        },
        onCountryChange: (country: string) => {
            let tempData = { ...data! };
            tempData.country = country;
            onLegalEntityChange(tempData);
        },
        onResponsibleChange: (responsible: string | null) => {
            let tempData = { ...data! };
            tempData.responsible = responsible ?? undefined;
            onLegalEntityChange(tempData);
        },
        onBusinessAreaChange: (businessAreas: Array<string>) => {
            let tempData = { ...data! };
            tempData.businessAreaIds = businessAreas;
            onLegalEntityChange(tempData);
        },
        onComplianceAreaChange: (complianceAreas: Array<string>) => {
            let tempData = { ...data! };
            tempData.complianceAreaIds = complianceAreas;
            onLegalEntityChange(tempData);
        },
        onCertificationChange: (certifications: Array<string>) => {
            let tempData = { ...data! };
            tempData.certificationIds = certifications;
            onLegalEntityChange(tempData);
        },
        onIsApprovedChange: (isApproved: boolean) => {
            let tempData = { ...data! };
            tempData.isApproved = isApproved;
            onLegalEntityChange(tempData);
        },
        onAuditTypeChange: (auditType?: LegalEntityAuditType) => {
            let tempData = { ...data! };
            tempData.auditType = auditType;
            onLegalEntityChange(tempData);
        },
        onAuditFrequencyChange: (auditFrequency?: LegalEntityAuditFrequency) => {
            let tempData = { ...data! };
            tempData.auditFrequency = auditFrequency;
            onLegalEntityChange(tempData);
        },
    };

    return {
        steps: steps.getSteps(),
        isLoading,
        activeStep: steps.getActiveStep(),
        changeStep,
        legalEntity: data!,
        legalEntityUpdater,
        onSaveLegalEntity,
        saveClicked,
        validationErrors: steps.validationErrors,
    };

    function getSteps() {
        let steps: Array<StepModel> = [];

        steps.push(new StepModel(translateString("description"), 0, validateDescriptionStepStep));
        steps.push(new StepModel(translateString("affiliationHeader"), 1));
        steps.push(new StepModel(translateString("legalEntityAudit"), 2));
        steps.push(new StepModel(translateString("complianceAreasAndCertifications"), 3));

        return steps;
    }

    async function updateLegalEntityAPI(viewModel: LegalEntityManagementModel) {
        return await put<LegalEntityUpdateModel>("/legalEntityManagement/" + id, convertToUpdateModel(viewModel));
    }

    function convertToUpdateModel(viewModel: LegalEntityManagementModel) {
        const updateModel: LegalEntityUpdateModel = {
            name: viewModel.name,
            description: viewModel.description,
            country: viewModel.country!,
            address: viewModel.address,
            city: viewModel.city,
            companyRegistrationNumber: viewModel.companyRegistrationNumber,
            types: viewModel.types,
            zipCode: viewModel.zipCode,
            website: viewModel.website,
            responsible: viewModel.responsible,
            businessAreaIds: viewModel.businessAreaIds,
            certificationIds: viewModel.certificationIds,
            complianceAreaIds: viewModel.complianceAreaIds,
            isApproved: viewModel.isApproved,
            auditFrequency: viewModel.auditFrequency,
            auditType: viewModel.auditType,
        };

        return updateModel;
    }
}
