import { useTheme } from "@mui/material";
import { createSxStyles } from "../../../common/createSxStyles";

export const useStepDescriptonBoxStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        container: {
            display: "flex",
            flexDirection: "column",
            padding: theme.spacing(2),
        },
        centerContainer: {
            display: "flex",
            flexDirection: "column",
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        centerContainerImages: {
            display: "flex",
            alignItems: "stretch",
        },
        header: {
            fontSize: theme.typography.pxToRem(16),
            fontWeight: "bold",
        },
        textYou: {
            fontSize: theme.typography.pxToRem(16),
            textAlign: "center",
        },
        arrow: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        descriptionText: {
            textAlign: "center",
            fontSize: theme.typography.pxToRem(12),
        },
    });
};
