import React from "react";
import { DotLegalCheckbox, DotLegalDialog, DotLegalMultiSelect, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { useAddEditUserDialogHook } from "./AddEditUserDialog.hooks";
import { useAddEditUserDialogStyles } from "./AddEditUserDialog.styles";
import { Box } from "@mui/material";

export interface IAddEditUserDialogProps {
    isEdit: boolean;
    onCloseDialog: () => void;
    onSaveCorrect: () => void;
    userId?: string;
}

function AddEditUserDialog(props: IAddEditUserDialogProps) {
    const styles = useAddEditUserDialogStyles();
    const { translateString } = useTranslation();
    const {
        isLoading,
        viewModel,
        setViewModel,
        errors,
        validateUserForm,
        validateName,
        validateEmailAddress,
        isSaving,
        userRolesLoading,
        userRolesData,
        businessAreasLoading,
        businessAreaData,
        validation,
        permissions,
    } = useAddEditUserDialogHook(props);

    validation.check(viewModel);
    return (
        <DotLegalDialog
            header={props.isEdit ? translateString("editUser") : translateString("createUser")}
            buttonOkText={props.isEdit ? translateString("save") : translateString("create")}
            open
            onDialogClose={props.onCloseDialog}
            onOkClick={() => validateUserForm(viewModel?.firstName!, viewModel?.lastName!, viewModel?.emailAddress!)}
            size="sm"
            isLoading={isLoading}
            inProgress={isSaving}
            disableBackdropOnCloseIfValueChange={viewModel}
        >
            <Box sx={styles.dialogBody}>
                <DotLegalTextField
                    label={translateString("firstName")}
                    value={viewModel?.firstName!}
                    debounce={false}
                    onChange={(firstName) => {
                        let tempViewModel = { ...viewModel! };
                        tempViewModel.firstName = firstName;
                        validateName(firstName, true);
                        setViewModel(tempViewModel);
                    }}
                    errorText={errors.firstNameError}
                />
                <DotLegalTextField
                    label={translateString("lastName")}
                    value={viewModel?.lastName!}
                    debounce={false}
                    onChange={(lastName) => {
                        let tempViewModel = { ...viewModel! };
                        tempViewModel.lastName = lastName;
                        validateName(lastName, false);
                        setViewModel(tempViewModel);
                    }}
                    errorText={errors.lastNameError}
                />
                <DotLegalTextField
                    label={translateString("emailAddress")}
                    value={viewModel?.emailAddress!}
                    debounce={false}
                    onChange={(emailAddress) => {
                        let tempViewModel = { ...viewModel! };
                        tempViewModel.emailAddress = emailAddress;
                        validateEmailAddress(emailAddress);
                        setViewModel(tempViewModel);
                    }}
                    errorText={errors.emailError}
                />

                <DotLegalMultiSelect
                    isLoading={userRolesLoading}
                    label={translateString("role")}
                    placeholder={translateString("role")}
                    options={userRolesData}
                    chosenOptions={viewModel?.userRoles!}
                    onChange={(userRoles) => {
                        let tempViewModel = { ...viewModel! };
                        tempViewModel.userRoles = userRoles;
                        setViewModel(tempViewModel);
                    }}
                    noOptionsLabel={translateString("noOptions")}
                />

                <DotLegalMultiSelect
                    isLoading={businessAreasLoading}
                    label={translateString("businessAreas")}
                    placeholder={translateString("businessAreas")}
                    options={businessAreaData}
                    chosenOptions={viewModel?.businessAreas!}
                    onChange={(businessAreas) => {
                        let tempViewModel = { ...viewModel! };
                        tempViewModel.businessAreas = businessAreas;
                        setViewModel(tempViewModel);
                    }}
                    noOptionsLabel={translateString("noOptions")}
                />

                {permissions.canManageCustomers && props.isEdit && (
                    <DotLegalTextField
                        label={"IntegrationIdentifier"}
                        value={viewModel?.integrationIdentifier!}
                        debounce={false}
                        onChange={(value) => {
                            let tempViewModel = { ...viewModel! };
                            tempViewModel.integrationIdentifier = value;
                            setViewModel(tempViewModel);
                        }}
                        errorText={validation?.getErrorsForDisplay("integrationIdentifier")}
                    />
                )}

                <Box sx={styles.dpoContainer}>
                    <DotLegalCheckbox
                        label={translateString("fullGroupAccess")}
                        tooltip={translateString("fullGroupAccessToolTip")}
                        checked={viewModel?.gdpo ?? false}
                        onChange={(isGdpo) => {
                            let tempViewModel = { ...viewModel! };
                            tempViewModel.gdpo = isGdpo;
                            setViewModel(tempViewModel);
                        }}
                    />
                </Box>
            </Box>
        </DotLegalDialog>
    );
}

export default AddEditUserDialog;
