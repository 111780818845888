export interface GdprIncidentLogSaveModel {
    timeOfIncident: Date | null;
    affectedPeopleNotified: Boolean | null;
    dateOfAffectedPeopleNotified: Date | null;
    reasonForNotNotifyingAffectedPeople: string | null;
    reasonForNotReportingToAgency: string | null;
    dateOfReportedToAgency: Date | null;
    reportedToAgency: boolean | null;
    measures: string | null;
    consequences: string | null;
    description: string | null;
    cause: string | null;
    unknownDateOfIncident: boolean;
    dateOfIncident: Date | null;
    name: string;
    dataCategories: Array<string>;
    types: Array<number>;
    severity: SeverityLevelEnum;
    tags: Array<string> | undefined;
}

export interface GdprIncidentLogListViewModel {
    id: string;
    name: string;
    reportedToAgency: boolean | null;
    affectedPeopleNotified: boolean | null;
    dateAndTimeOfIncident: Date | null;
    hasTime: boolean;
    types: Array<number>;
    dataCategories: Array<string>;
    hasWarning: boolean;
    severity: SeverityLevelEnum;
}

export interface GdprIncidentLogEntryOverviewViewModel {
    id: string;
    createdAt: Date;
    name: string;
    cause: string | null;
    dateOfIncident: Date | null;
    timeOfIncident: Date | null;
    description: string | null;
    dataCategories: Array<string>;
    consequences: string | null;
    measures: string | null;
    reportedToAgency: boolean | null;
    dateOfReportedToAgency: Date | null;
    reasonForNotReportingToAgency: string | null;
    affectedPeopleNotified: boolean | null;
    dateOfAffectedPeopleNotified: Date | null;
    reasonForNotNotifyingAffectedPeople: string | null;
    types: Array<number> | null;
    severity: SeverityLevelEnum;
}

export enum GdprIncidentType {
    Theft,
    Hacking,
    Malware,
    Phishing,
    DoS,
    ExposureOfFalseInformation,
    UnintentionalDisclosures,
    UnintentionalAccess,
    Other,
    Unknown,
}

export enum SeverityLevelEnum {
    VeryLow = 1,
    Low = 2,
    Moderate = 3,
    High = 4,
    VeryHigh = 5,
}
