import { alpha, darken, useTheme } from "@mui/material";
import { createSxStyles } from "../../../common/createSxStyles";
import { buttonColor } from "../EditTaskDialog";

export const useTaskCommentsStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        buttonStyles: {
            "&:disabled": {
                backgroundColor: alpha(buttonColor!, 0.7),
                color: `${alpha(theme.palette.primary.dark!, 0.7)} !important`,
            },
        },
        activeButton: {
            backgroundColor: theme.palette.primary.main,
            color: theme.customPalette.white,
            "&:hover": {
                backgroundColor: darken(theme.palette.primary.main, 0.1),
                borderColor: darken(theme.palette.primary.main, 0.1),
            },
            "&:active": {
                boxShadow: "none",
                backgroundColor: darken(theme.palette.primary.main, 0.1),
                borderColor: darken(theme.palette.primary.main, 0.1),
            },
            "&:disabled": {
                backgroundColor: alpha(theme.palette.primary.main!, 0.7),
                color: `${alpha(theme.customPalette.white!, 0.7)} !important`,
            },
        },
        topWrapper: {
            display: "flex",
            alignItems: "center",
        },
    });
};
