import { DotLegalFile } from "@dotlegal/dotlegal-ui-components";
import { useState } from "react";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { post, put } from "../../../common/api/apiShared";
import { Result } from "../../../common/api/result";
import { _arrayBufferToBase64 } from "../../../common/arrayBufferToBase64";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useValidator } from "../../../common/hooks/validation";
import { isNullOrWhitespace } from "../../../common/stringOperations";
import { useTranslation } from "../../../localization/useTranslation";
import { IPolicyDocumentDialog } from "./PolicyDocumentDialog";
import { PolicyDocumentSaveModel } from "./PolicyDocumentDialog.types";

export function usePolicyDocumentDialogDataMapping(props: IPolicyDocumentDialog) {
    const snackbar = useDotLegalSnackbar();
    const { translateString } = useTranslation();

    const [document, setDocument] = useState<PolicyDocumentSaveModel>(new PolicyDocumentSaveModel(props.selectedDocument?.name, props.selectedDocument?.link));
    const [documentationOption, setDocumentationOption] = useState(1);
    const { policyId } = useParams<{ policyId: string }>();

    const isEdit = props.selectedDocument !== undefined;

    const validation = useValidator<PolicyDocumentSaveModel>(
        (validators) => ({
            name: validators.validateNotEmpty((item) => item.name, translateString("name")),
            fileName: isEdit || documentationOption === 0 ? undefined : validators.validateNotEmpty((item) => item.fileName, translateString("file")),
            link: !document.isLink && documentationOption === 1 ? undefined : validators.validateUrlAndNotEmpty((item) => item.link, translateString("link")),
        }),
        [documentationOption]
    );

    const editMutation = useMutation(edit, {
        onSuccess: (response: Result<string>) => {
            snackbar.show(translateString("documentWasUpdated"));
            props.onDialogClose();
            props.onSave();
        },
    });

    const saveMutation = useMutation(create, {
        onSuccess: (response: Result<string>) => {
            snackbar.show(translateString("documentCreated"));
            props.onDialogClose();
            props.onSave();
        },
    });

    function create() {
        return post<string>(`/Policy/${policyId}/Documents`, document);
    }

    function edit() {
        return put<string>(`/Policy/${props.selectedDocument?.documentId}/Documents`, document);
    }

    const save = async () => {
        if (validation.anyHasErrors) {
            validation.setShowErrors(true);
            return;
        }

        if (isEdit) {
            await editMutation.mutateAsync();
        } else {
            await saveMutation.mutateAsync();
        }
    };

    async function onDocumentUpload(file: DotLegalFile[] | undefined) {
        let tempDoc = { ...document! };

        tempDoc.base64Data = file ? await _arrayBufferToBase64(file[0].data) : "";
        tempDoc.fileName = file ? file[0].fileName : "";

        if (isNullOrWhitespace(tempDoc.name)) {
            tempDoc.name = tempDoc.fileName.substring(0, tempDoc.fileName.lastIndexOf("."));
        }

        setDocument(tempDoc);
    }

    function onNameChange(value: string) {
        let tempDoc = { ...document };

        tempDoc.name = value;
        setDocument(tempDoc);
    }

    function onLinkChange(value: string) {
        let tempDoc = { ...document };

        tempDoc.link = value;
        setDocument(tempDoc);
    }

    function onDocumentationTypeChange(option: number) {
        let tempModel = { ...document };
        if (option === 0) {
            tempModel.base64Data = "";
            tempModel.fileName = "";
            setDocument(tempModel);
            setDocumentationOption(0);
        }

        if (option === 1) {
            tempModel.link = "";
            setDocument(tempModel);
            setDocumentationOption(1);
        }
    }

    return {
        document,
        save,
        validation,
        isEdit,
        onDocumentUpload,
        onNameChange,
        onLinkChange,
        isSaving: saveMutation.isLoading,
        documentationOption,
        onDocumentationTypeChange,
    };
}
