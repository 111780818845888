import { useQuery } from "react-query";
import { get } from "../../../common/api/apiShared";
import { useState } from "react";
import { AreaViewModel } from "./Areas.types";

export function useAreasHooks() {
    const [showDialog, setShowDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedArea, setSelectedArea] = useState<AreaViewModel | undefined>(undefined);

    const url = "/areas";
    let { isLoading, data, refetch } = useQuery(url, () => get<Array<AreaViewModel>>(url));

    const onDialogClose = () => {
        setShowDialog(false);
        setSelectedArea(undefined);
    };

    const onDeleteDialogClose = () => {
        setShowDeleteDialog(false);
        setSelectedArea(undefined);
    };

    return {
        isLoading,
        data,
        showDialog,
        setShowDialog,
        refetch,
        selectedArea,
        setSelectedArea,
        showDeleteDialog,
        setShowDeleteDialog,
        onDialogClose,
        onDeleteDialogClose,
    };
}
