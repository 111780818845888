import { DotLegalDialog, DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import React from "react";
import { Box } from "@mui/material";
import { useTranslation } from "../../localization/useTranslation";

import { Trans } from "react-i18next";
import { InternalAssessmentApiRow } from "../InternalAssessments.types";
import useToogleInternalAssessmentRemindersDialogHooks from "./ToggleInternalAssessmentRemindersDialog.hooks";

export interface IToggleInternalAssessmentRemindersDialog {
    onDialogClose: () => void;
    onInternalAssessmentPaused: () => void;
    internalAssessment: InternalAssessmentApiRow;
}

function ToggleInternalAssessmentRemindersDialog(props: IToggleInternalAssessmentRemindersDialog) {
    const { translateString } = useTranslation();

    const { onPauseClick, isSaving } = useToogleInternalAssessmentRemindersDialogHooks(props);

    return (
        <DotLegalDialog
            buttonOkText={props.internalAssessment.remindersPaused ? translateString("resume") : translateString("pause")}
            header={props.internalAssessment.remindersPaused ? translateString("resumeReminders") : translateString("pauseReminders")}
            size="sm"
            open
            onDialogClose={props.onDialogClose}
            onOkClick={() => onPauseClick()}
            inProgress={isSaving}
        >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <DotLegalHeader fontWeight={400} center headerStyle="small">
                    <Trans
                        i18nKey={props.internalAssessment.remindersPaused ? "resumeRemindersContent" : "pauseRemindersContent"}
                        values={{
                            name: props.internalAssessment.name.trim(),
                        }}
                        components={{
                            wrapper: (
                                <Box
                                    sx={(theme) => ({ color: theme.palette.secondary.main, fontWeight: theme.typography.fontWeightBold })}
                                    component="span"
                                ></Box>
                            ),
                        }}
                    />
                </DotLegalHeader>
            </Box>
        </DotLegalDialog>
    );
}

export default ToggleInternalAssessmentRemindersDialog;
