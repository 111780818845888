import { useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";

export const useLegalBasesStepstyles = () => {
    const theme = useTheme();
    return createSxStyles({
        EmptyState: {
            textAlign: "center",
            marginTop: theme.spacing(10),
            color: "#707070",
            fontSize: theme.typography.pxToRem(14),
        },
        divider: {
            backgroundColor: "#B6D5EF",
        },
        purposeSelector: {
            width: 260,
            "& > *": {
                marginTop: 0,
            },
        },
    });
};
