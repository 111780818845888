import { Box } from "@mui/material";
import React from "react";
import { UserPermissions } from "../../auth/userContextProvider/UserContextProvider.types";
import { StepTypeEnum } from "../../processingActivity/ProcessingActivity.types";
import { QuestionData, QuestionDataProcessorData } from "../RiskAssessment.types";
import DotLegalLink from "../../common/components/dotLegalLink/DotLegalLink";
import { ToDto } from "@dotlegal/dotlegal-ui-components";
import { QuestionChunk, QuestionChunkType } from "./riskAssessmentTemplateParser.ts";

export class QuestionDataLinkProvider {
    purposes: Array<JSX.Element> = [];
    deletionPeriods: Array<JSX.Element> = [];
    dataSubjects: Array<JSX.Element> = [];
    dataProcessors: Array<JSX.Element> = [];
    legalBases: Array<JSX.Element> = [];
    dataCategories: Array<JSX.Element> = [];

    private processingActivityId: string;
    private getUrl: (processingActivityId: string, step?: StepTypeEnum, subStep?: StepTypeEnum, entityId?: string, create?: boolean) => ToDto;

    constructor(
        questionData: QuestionData | undefined,
        processingActivityId: string,
        getUrl: (processingActivityId: string, step?: StepTypeEnum, subStep?: StepTypeEnum, entityId?: string, create?: boolean) => ToDto,
        permissions: UserPermissions
    ) {
        this.processingActivityId = processingActivityId;
        this.getUrl = getUrl;

        if (questionData) {
            this.purposes = this.getMasterDataLinks(
                questionData.purposes,
                StepTypeEnum.masterData,
                permissions.processingActivityMasterDataPermissions?.permissions?.read
            );
            this.deletionPeriods = this.getMasterDataLinks(
                questionData.deletionPeriods,
                StepTypeEnum.association,
                permissions.processingActivityAssociationPermissions?.permissions?.read
            );
            this.dataSubjects = this.getMasterDataLinks(
                questionData.dataSubjects,
                StepTypeEnum.dataSubject,
                permissions.processingActivityDataSubjectPermissions?.permissions?.read
            );
            this.legalBases = this.getMasterDataLinks(
                questionData.legalBases,
                StepTypeEnum.legalBases,
                permissions.processingActivityLegalBasesPermissions?.permissions?.read
            );
            this.dataProcessors = this.getSharingsLink(questionData.dataProcessors, permissions.processingActivityDataProcessorsPermissions?.permissions?.read);
            this.dataCategories = this.getMasterDataLinks(
                questionData.dataCategories,
                StepTypeEnum.dataCategories,
                permissions.processingActivityDataCategoryPermissions?.permissions?.read
            );
        }
    }

    getMasterDataLinks(masterData: Array<string>, stepType: StepTypeEnum, hasAccess: boolean) {
        return masterData.map((element, index) => {
            if (hasAccess) {
                return (
                    <DotLegalLink key={index} to={this.getUrl(this.processingActivityId, stepType)} stopPropagation linkColor="primary">
                        {element}
                    </DotLegalLink>
                );
            }

            return this.getSpan(element, index);
        });
    }

    getSharingsLink(data: Array<QuestionDataProcessorData>, hasAccess: boolean) {
        return data.map((element, index) => {
            if (hasAccess) {
                return (
                    <DotLegalLink
                        key={index}
                        to={this.getUrl(this.processingActivityId, StepTypeEnum.sharings, StepTypeEnum.dataprocessor, element.sharingId)}
                        stopPropagation
                        linkColor="primary"
                    >
                        {element.name}
                    </DotLegalLink>
                );
            }

            return this.getSpan(element.name, index);
        });
    }

    getSpan(name: string, index: number) {
        return (
            <Box component={"span"} sx={(theme) => ({ color: theme.palette.secondary.main, fontWeight: 600 })} key={index}>
                {name}
            </Box>
        );
    }

    hasPurposes() {
        return this.purposes.length > 0;
    }

    hasDataSubjects() {
        return this.dataSubjects.length > 0;
    }

    hasDeletionPeriods() {
        return this.deletionPeriods.length > 0;
    }

    hasLegalBases() {
        return this.legalBases.length > 0;
    }

    hasDataProcessors() {
        return this.dataProcessors.length > 0;
    }

    hasDataCategories() {
        return this.dataCategories.length > 0;
    }
}

export function generateQuestionWithLink(
    chunks: Array<QuestionChunk>,
    linkProvider: QuestionDataLinkProvider,
    className: {},
    translateString: (key: string, interpolation?: any) => string
): JSX.Element {
    const temp = Array<JSX.Element>();

    chunks.forEach((chunk) => {
        if (chunk.type === QuestionChunkType.Text) {
            temp.push(<span>{chunk.text}</span>);
        } else if (chunk.type === QuestionChunkType.DataSubjects) {
            temp.push(formatLinkArray(linkProvider.dataSubjects, chunk.type, className, translateString));
        } else if (chunk.type === QuestionChunkType.DeletionPeriods) {
            temp.push(formatLinkArray(linkProvider.deletionPeriods, chunk.type, className, translateString));
        } else if (chunk.type === QuestionChunkType.Purposes) {
            temp.push(formatLinkArray(linkProvider.purposes, chunk.type, className, translateString));
        } else if (chunk.type === QuestionChunkType.LegalBases) {
            temp.push(formatLinkArray(linkProvider.legalBases, chunk.type, className, translateString));
        } else if (chunk.type === QuestionChunkType.DataProcessors) {
            temp.push(formatLinkArray(linkProvider.dataProcessors, chunk.type, className, translateString));
        } else if (chunk.type === QuestionChunkType.DataCategories) {
            temp.push(formatLinkArray(linkProvider.dataCategories, chunk.type, className, translateString));
        }
    });

    return (
        <React.Fragment>
            {temp.map((x, i) => (
                <React.Fragment key={i}>{x}</React.Fragment>
            ))}
        </React.Fragment>
    );
}

function formatLinkArray(
    links: Array<JSX.Element>,
    type: QuestionChunkType,
    className: {},
    translateString: (key: string, interpolation?: any) => string
): JSX.Element {
    if (links.length === 0) return <React.Fragment />;
    else if (links.length === 1) return <React.Fragment>{links[0]}</React.Fragment>;
    else if (links.length <= 5)
        return (
            <React.Fragment>
                {getSeperatedLinksByValue(getSeperator(type), links.slice(0, -1)).map((x, i) => {
                    return <React.Fragment key={type + i}>{x}</React.Fragment>;
                })}{" "}
                {getSeparator(type, translateString)} {links.slice(-1)}
            </React.Fragment>
        );
    else {
        var temp = Array.from(links);
        let chunk = temp.splice(0, 5);
        return (
            <React.Fragment>
                {getSeperatedLinksByValue(getSeperator(type), chunk).map((x) => {
                    return x;
                })}{" "}
                <Box sx={className}>
                    (+{temp.length} {translateString("more")})
                </Box>
            </React.Fragment>
        );
    }
}

function getSeperator(type: QuestionChunkType) {
    if (type === QuestionChunkType.LegalBases) return " : ";
    else return ", ";
}

function getSeparator(type: QuestionChunkType, translateString: (key: string, interpolation?: any) => string) {
    if (type === QuestionChunkType.DataProcessors || type === QuestionChunkType.DeletionPeriods) return translateString("or");

    return translateString("and");
}

function getSeperatedLinksByValue(seperator: string, links: Array<JSX.Element>) {
    const temp = Array<JSX.Element>();

    for (var i = 0; i <= links.length; i++) {
        temp.push(links[i]);

        if (links.length - 1 > i) temp.push(<span>{seperator}</span>);
    }

    return temp;
}
