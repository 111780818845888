import { useState } from "react";
import { RiskAssessmentRowViewModel } from "../riskAssessments/RiskAssessments.types";
import { IProcessingActivityRiskMatrixProps } from "./RiskAssessmentMatrix";

export function useProcessingActityRiskMatrixDataMapping(props: IProcessingActivityRiskMatrixProps) {
    const [selectedGrid, setSelectedGrid] = useState({ propbability: 0, consequence: 0 });

    let matrixData: { [key: number]: any } = {};

    if (!props.isLoading) {
        props.data!.forEach((activity) => {
            if (activity.consequenceScore && activity.probabilityScore) {
                let exist = matrixData[convertActivityScoreToKey(activity)];
                if (exist) matrixData[convertActivityScoreToKey(activity)]++;
                else matrixData[convertActivityScoreToKey(activity)] = 1;
            }
        });
    }

    function convertActivityScoreToKey(activity: RiskAssessmentRowViewModel) {
        return Number(`${activity.consequenceScore}${activity.probabilityScore}`);
    }

    return {
        matrixData,
        selectedGrid,
        setSelectedGrid,
    };
}
