import React, { Fragment } from "react";
import { useTranslation } from "../../localization/useTranslation";
import { useDataMapping } from "./AddEditCustomer.hooks";
import { DotLegalCheckbox, DotLegalDialog, DotLegalMultiSelect, DotLegalSelect, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { LoginProvider } from "../../user/User.types";
import { Box, CircularProgress } from "@mui/material";
import { useAddEditCustomerStyles } from "./AddEditCustomer.styles";
import { CustomerReportType, CustomerSaveModel } from "./AddEditCustomer.types";
import { PlanStatus } from "../customers/Customers.types";
import { PlanType } from "../../auth/planProvider/PlanProvider.types";

export interface IAddEditCustomerDialogProps {
    onCloseDialog: () => void;
    onSave?: () => void;
    selectedCustomer: CustomerSaveModel | undefined;
}

function AddEditCustomerDialog(props: IAddEditCustomerDialogProps) {
    const { isLoading, inProgress, saveModel, setSaveModel, languages, validateCustomerViewModel, validateCustomerName, isEdit, errors } =
        useDataMapping(props);
    const { translateString } = useTranslation();
    const styles = useAddEditCustomerStyles();

    return (
        <DotLegalDialog
            inProgress={inProgress}
            header={isEdit() ? translateString("edit") : translateString("add")}
            open
            onDialogClose={props.onCloseDialog}
            onOkClick={() => validateCustomerViewModel(saveModel?.name!)}
            buttonOkText={isEdit() ? translateString("save") : translateString("create")}
            size="sm"
            disableBackdropOnCloseIfValueChange={saveModel}
        >
            {isLoading ? (
                <Box sx={styles.spinnerWrapper}>
                    <CircularProgress />
                </Box>
            ) : (
                <Fragment>
                    <DotLegalTextField
                        label={translateString("name")}
                        value={saveModel?.name!}
                        debounce={false}
                        onChange={(name) => {
                            let tempViewModel = { ...saveModel! };
                            tempViewModel.name = name;
                            validateCustomerName(name);
                            setSaveModel(tempViewModel);
                        }}
                        errorText={errors.customerNameError}
                    />

                    <DotLegalSelect
                        options={languages}
                        onChange={(language) => {
                            let tempViewModel = { ...saveModel! };
                            tempViewModel.language = language ?? "en";
                            setSaveModel(tempViewModel);
                        }}
                        placeholder={translateString("language")}
                        label={translateString("language")}
                        selectedItem={saveModel?.language!}
                        noOptionsLabel={translateString("noOptions")}
                    />

                    <DotLegalSelect
                        label={translateString("loginMethod")}
                        placeholder={translateString("loginMethod")}
                        selectedItem={saveModel?.loginProvider?.toString()}
                        options={[
                            { id: LoginProvider.DotLegalIdp.toString(), name: ".legal IDP" },
                            { id: LoginProvider.AzureAd.toString(), name: "Azure AD" },
                        ]}
                        onChange={(item) => {
                            let tempViewModel = { ...saveModel! };
                            tempViewModel.loginProvider = Number(item);
                            setSaveModel(tempViewModel);
                        }}
                        disableClearable
                        noOptionsLabel={translateString("noOptions")}
                    />

                    <DotLegalTextField
                        label={translateString("helpLink")}
                        value={saveModel?.helpLink!}
                        debounce={false}
                        onChange={(helpLink) => {
                            let tempViewModel = { ...saveModel! };
                            tempViewModel.helpLink = helpLink;
                            setSaveModel(tempViewModel);
                        }}
                    />

                    <DotLegalSelect
                        label={"Core Plan"}
                        placeholder={"Core Plan"}
                        selectedItem={saveModel?.planType?.toString()}
                        options={[
                            { id: PlanType.DotLegalCompliance.toString(), name: "GRC Platform" },
                            { id: PlanType.Research.toString(), name: "Research" },
                        ]}
                        onChange={(item) => {
                            let tempViewModel = { ...saveModel! };
                            tempViewModel!.planType = Number(item);

                            if (tempViewModel.planType === PlanType.Research) {
                                tempViewModel.reportTypes = [CustomerReportType.RegistrationOfResearchProject];
                            } else {
                                tempViewModel.reportTypes = [];
                            }

                            setSaveModel(tempViewModel);
                        }}
                        disabled={isEdit()}
                        disableClearable
                        noOptionsLabel={translateString("noOptions")}
                    />

                    {saveModel?.planType === PlanType.Research && (
                        <DotLegalMultiSelect
                            label={translateString("reports")}
                            placeholder={translateString("reports")}
                            chosenOptions={saveModel?.reportTypes === undefined ? [] : saveModel?.reportTypes.map((x) => x.toString())}
                            options={[
                                {
                                    id: CustomerReportType.RegistrationOfResearchProject.toString(),
                                    name: translateString("registrationOfResearchProject"),
                                },
                                {
                                    id: CustomerReportType.InternalAcquisitionOfSignature.toString(),
                                    name: translateString("internalAcquisitionOfSignature"),
                                },
                                {
                                    id: CustomerReportType.ResearchEthicsApproval.toString(),
                                    name: translateString("researchEthicsApproval"),
                                },
                            ]}
                            onChange={(items) => {
                                let tempViewModel = { ...saveModel! };
                                tempViewModel.reportTypes = items.map((x) => Number(x));

                                if (!tempViewModel.reportTypes.includes(CustomerReportType.RegistrationOfResearchProject)) {
                                    tempViewModel.reportTypes.unshift(CustomerReportType.RegistrationOfResearchProject);
                                }

                                setSaveModel(tempViewModel);
                            }}
                            disableClearable
                            noOptionsLabel={translateString("noOptions")}
                        />
                    )}
                    <DotLegalCheckbox
                        label={"German enabled"}
                        checked={saveModel?.germanEnabled ?? false}
                        onChange={(enabled) => {
                            let tempViewModel = { ...saveModel! };
                            tempViewModel.germanEnabled = enabled;
                            setSaveModel(tempViewModel);
                        }}
                    />
                </Fragment>
            )}
        </DotLegalDialog>
    );
}

export default AddEditCustomerDialog;
