import { useState } from "react";
import { useQuery } from "react-query";
import { get } from "../../common/api/apiShared";
import { CustomerSaveModel } from "../addEditCustomerDialog/AddEditCustomer.types";
import { CustomerListModel } from "./Customers.types";
import { LoginProvider } from "../../user/User.types";
import { useTranslation } from "../../localization/useTranslation";
import { useUrlProvider } from "../../useUrlProvider";
import { DotLegalBreadCrumbModel } from "@dotlegal/dotlegal-ui-components";
import { PlanType } from "../../auth/planProvider/PlanProvider.types";

export function useDataMapping() {
    const url = "/customers";
    const query = useQuery(url, () => get<Array<CustomerListModel>>(url));
    const [showDeleteCustomerDialog, setShowDeleteCustomerDialog] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState<CustomerSaveModel>();
    const [showAddEditDialog, setShowAddEditDialog] = useState(false);
    const { translateString } = useTranslation();
    const urlProvider = useUrlProvider();
    const [showAddNotificationsDialog, setShowAddNotificationsDialog] = useState(false);

    async function refetch() {
        await query.refetch();
    }

    const getBreadCrumbs = () => {
        let breadCrumbs: Array<DotLegalBreadCrumbModel> = [];

        breadCrumbs.push({ name: translateString("settings"), link: urlProvider.getSettingsUrl() });
        breadCrumbs.push({ name: translateString("customers") });

        return breadCrumbs;
    };

    const createNewCustomerProps: CustomerSaveModel = {
        id: "",
        name: "",
        language: "",
        active: true,
        loginProvider: LoginProvider.DotLegalIdp,
        helpLink: "",
        planType: PlanType.DotLegalCompliance,
        reportTypes: [],
        germanEnabled: false,
    };

    return {
        isLoading: query.isLoading,
        customers: query.data,
        refetch,
        showDeleteCustomerDialog,
        setShowDeleteCustomerDialog,
        selectedCustomer,
        setSelectedCustomer,
        showAddEditDialog,
        setShowAddEditDialog,
        createNewCustomerProps,
        getBreadCrumbs,
        setShowAddNotificationsDialog,
        showAddNotificationsDialog,
    };
}
