import { createElement, useRef, useState } from "react";
import { Trans } from "react-i18next";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { post, put } from "../../common/api/apiShared";
import { Result } from "../../common/api/result";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { TrackingEvent, useTrackAIEvent } from "../../processingActivity/hooks/useTracking";
import { useUrlProvider } from "../../useUrlProvider";
import { CallPage, CustomListSaveModel } from "../CustomLists.types";
import { ICreateCustomListDialogProps } from "./CreateCustomListDialog";

export function useCreateCustomListDialog(props: ICreateCustomListDialogProps) {
    const { getCustomListViewWithOpenDialogUrl } = useUrlProvider();
    const navigate = useNavigate();
    const snackbar = useDotLegalSnackbar();
    const { translateString } = useTranslation();
    const [inProgress, setInProgress] = useState(false);
    const isNameValid = useRef(false);
    const [saveModel, setSaveModel] = useState<CustomListSaveModel>({
        name: props.customList.name,
        type: props.customListType,
        description: props.customList.description,
    });
    const trackEvent = useTrackAIEvent();
    isNameValid.current = saveModel.name.trimEnd().length > 0;

    const saveMutation = useMutation(save, {
        onSuccess: (response: Result<{ id: string }>) => {
            if (response.success()) {
                let value = response.value().id;
                trackEvent(TrackingEvent.CustomListAdded, { customList: value });

                let url = getCustomListViewWithOpenDialogUrl(value);
                if (props.callPage === CallPage.GroupCompanies) {
                    url += "&groupcompanies=true";
                }

                navigate(url);
            } else {
                setInProgress(false);
                snackbar.show(createElement(Trans, { i18nKey: "customListExist", values: { name: saveModel.name } }), "warning");
            }
        },
    });

    const updateMutation = useMutation(update, {
        onSuccess: (response: Result<{ id: string }>) => {
            if (response.success()) {
                props.onSave();
                snackbar.show(translateString("customListUpdated", { name: saveModel.name }));
            } else {
                snackbar.show(createElement(Trans, { i18nKey: "customListExist", values: { name: saveModel.name } }), "warning");
                setInProgress(false);
            }
        },
    });

    const createCustomList = async () => {
        if (isEdit) await updateMutation.mutateAsync();
        else await saveMutation.mutateAsync();
    };

    function save() {
        setInProgress(true);
        var saveUrl = `/CustomLists?copyListId=${props.customList.id}`;
        return post<{ id: string }>(saveUrl, saveModel);
    }

    function update() {
        setInProgress(true);
        return put<{ id: string }>("/CustomLists/" + props.customList.id + "/", saveModel);
    }

    const isEdit = props.customList.id !== "" && props.copy === false;

    return {
        inProgress,
        setInProgress,
        createCustomList,
        saveModel,
        setSaveModel,
        isNameValid,
        isEdit,
    };
}
