import { DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import { Box } from "@mui/material";
import React from "react";

export interface ComplianceAreaWrapperProps {
    complianceArea: string | undefined;
}

function ComplianceAreaWrapper(props: ComplianceAreaWrapperProps) {
    return (
        <Box
            sx={(theme) => ({
                border: 1,
                paddingRight: 0.5,
                paddingLeft: 0.5,
                color: theme.palette.primary.main,
                borderRadius: "8px",
                marginTop: 0.5,
                marginBottom: 0.5,
                backgroundColor: theme.customPalette.white,
                whiteSpace: "nowrap",
            })}
        >
            <DotLegalHeader color="secondary" fontWeight={500} headerStyle={"small"} marginBottom={0}>
                {props.complianceArea}
            </DotLegalHeader>
        </Box>
    );
}

export default ComplianceAreaWrapper;
