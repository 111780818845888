import { createElement } from "react";
import { Trans } from "react-i18next";
import { useMutation } from "react-query";
import { put } from "../../../common/api/apiShared";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { IChangeRiskAssessmentVersionLockedDialog } from "./ChangeRiskAssessmentVersionLockedDialog";
import { RiskVersionViewModel } from "../riskAssessmentVersions/RiskAssessmentVersions.types";

export function useChangeRiskAssessmentVersionLockedDialogHooks(props: IChangeRiskAssessmentVersionLockedDialog) {
    const snackbar = useDotLegalSnackbar();

    const changeLockMutation = useMutation(changeLocked);

    const changeIsLocked = (version: RiskVersionViewModel) => {
        changeLockMutation.mutateAsync(version, {
            onSuccess: () => {
                snackbar.show(
                    createElement(Trans, {
                        i18nKey: version.isLocked ? "riskAssessmentVersionIsUnLocked" : "riskAssessmentVersionIsLocked",
                        values: { name: version.name },
                    })
                );
                props.onSave();
            },
        });
    };

    function changeLocked(version: RiskVersionViewModel) {
        return put<string>(`/riskassesmentversions/${version.id}/changeLockStatus`, !version.isLocked);
    }

    return {
        changeIsLocked,
        inProgress: changeLockMutation.isLoading,
    };
}
