import { Typography } from "@mui/material";
import React from "react";
import { useProcessingActivityStatisticsStyles } from "../processingActivityStatistics.styles";
import { useTranslation } from "../../../localization/useTranslation";
import DotLegalPaper from "../../../common/components/dotLegalPaper/DotLegalPaper";

export interface ResearcherAverageProps {
    researcherAverage: number | undefined;
}

function ResearcherAverage(props: ResearcherAverageProps) {
    const styles = useProcessingActivityStatisticsStyles();
    const { translateString } = useTranslation();
    return (
        <DotLegalPaper backgroundColor="limeGreen" fullHeight>
            <Typography sx={styles.header}>{translateString("researcherAverage")}</Typography>
            <Typography sx={styles.body}>
                {props?.researcherAverage} {translateString("days").toLowerCase()}
            </Typography>
        </DotLegalPaper>
    );
}

export default ResearcherAverage;
