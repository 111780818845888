import { useState } from "react";

export function useComment() {
    const [hoveredComment, setHoveredComment] = useState<string | undefined>(undefined);

    return {
        hoveredComment,
        setHoveredComment,
    };
}
