import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { get } from "../../../common/api/apiShared";
import { useStateLocalStorage } from "@dotlegal/dotlegal-ui-components";
import { DataSubjectSaveModel, DataSubjectViewModel } from "../DataSubject.types";

export function useDataSubjectsDataMapping() {
    const queryClient = useQueryClient();
    const [showAllDataSubjects, setshowAllDataSubjects] = useStateLocalStorage("onlyActiveDataSubjects", false);
    const [showAddEditDialog, setShowAddEditDialog] = useState(false);
    const [showDeleteDataSubjectDialog, setShowDeleteDataSubjectDialog] = useState(false);
    const [selectedDataSubject, setSelectedDataSubject] = useState<DataSubjectSaveModel | undefined>(undefined);
    const [showChangeStatusDialog, setShowChangeStausDialog] = useState(false);

    const queryPart = `?showAllDataSubjects=${showAllDataSubjects}`;
    const url = `/dataSubjects${queryPart}`;
    let { isLoading, data } = useQuery(url, () => get<Array<DataSubjectViewModel>>(url));

    const OnSave = () => {
        queryClient.refetchQueries(url);
    };

    return {
        isLoading,
        data,
        OnSave,
        showChangeStatusDialog,
        setShowChangeStausDialog,
        showAllDataSubjects,
        setshowAllDataSubjects,
        showAddEditDialog,
        setShowAddEditDialog,
        showDeleteDataSubjectDialog,
        setShowDeleteDataSubjectDialog,
        selectedDataSubject,
        setSelectedDataSubject,
    };
}
