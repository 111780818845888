import { DotLegalEmptyState, DotLegalPaper, DotLegalTableWithControls, ITableHeader } from "@dotlegal/dotlegal-ui-components";
import React from "react";
import useLegalEntitySystemTabHooks from "./LegalEntitySystemTab.hooks";
import { useTranslation } from "../../localization/useTranslation";
import { LegalEntitySystemTableModel } from "./LegalEntitySystemTab.types";
import { Box, TableCell, TableRow } from "@mui/material";
import SystemIcon from "../../system/systemIcon/SystemIcon";
import EmptyState from "../../common/svgs/emptyStates/emptyState_1.svg?react";
import DotLegalLink from "../../common/components/dotLegalLink/DotLegalLink";
import { useUrlProvider } from "../../useUrlProvider";

export interface ILegalEntityAssetsTab {
    legalEntityId: string;
}

function LegalEntitySystemTab(props: ILegalEntityAssetsTab) {
    const { systems, isLoading, hasData } = useLegalEntitySystemTabHooks(props);
    const { translateString } = useTranslation();
    const { getSystemOverviewUrl } = useUrlProvider();

    function getHeaders() {
        const headers = Array<ITableHeader<LegalEntitySystemTableModel>>();
        headers.push({ property: "icon", text: "", align: "left", showOnMobile: true });
        headers.push({ property: "name", text: translateString("asset"), align: "left", showOnMobile: true });
        headers.push({ property: "roles", text: translateString("role"), align: "left", showOnMobile: true });
        return headers;
    }

    return (
        <React.Fragment>
            <DotLegalPaper>
                <React.Fragment>
                    {hasData ? (
                        <DotLegalTableWithControls
                            headers={getHeaders()}
                            defaultOrderBy={"name"}
                            clickableRows={false}
                            data={systems}
                            isLoading={isLoading}
                            defaultOrder={"asc"}
                            labelRowsPerPage={""}
                            labelSearch={translateString("search")}
                            noOptionsLabel={""}
                            hideRowsPerPage
                            hidePagination
                            emptyText={translateString("noData")}
                            renderRow={(row, i) => (
                                <TableRow key={row.id}>
                                    <TableCell width={75}>
                                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <SystemIcon systemName={row.name} size="small" />
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box key={row.id} component={"span"} sx={{ fontWeight: "bold" }}>
                                            <DotLegalLink linkColor={"primary"} to={getSystemOverviewUrl(row.id)}>
                                                {row.name}
                                            </DotLegalLink>
                                        </Box>
                                    </TableCell>
                                    <TableCell>{row.roles}</TableCell>
                                </TableRow>
                            )}
                            paginationLabelOf={""}
                            getUserSpecificPageLength={() => 30}
                        ></DotLegalTableWithControls>
                    ) : (
                        <DotLegalEmptyState icon={<EmptyState />} text={translateString("legalEntityAssetsEmptyState")} />
                    )}
                </React.Fragment>
            </DotLegalPaper>
        </React.Fragment>
    );
}

export default LegalEntitySystemTab;
