import { DotLegalButton, DotLegalCheckbox, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { isNullOrWhitespace } from "../../common/stringOperations";
import { useTranslation } from "../../localization/useTranslation";
import { useCreateCommentStyle } from "./CreateComment.styles";
import { CommentType } from "../commentDialog/CommentDialog.types";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { useUrlProvider } from "../../useUrlProvider";
import { Link } from "react-router-dom";
import { useStateSessionStorage } from "../../common/hooks/useStateSessionStorage";

export interface ICreateEditComment {
    onCommentSubmitted: (content: string) => void;
    onSubscriptionChanged: (value: boolean) => void;
    onCancelClicked: () => void;
    id: string;
    commentType: CommentType;
    content?: string;
    subscribed?: boolean;
    isSaving: boolean | undefined;
    notificationText: string;
    expandInputOnFocus?: boolean;
    disabled?: boolean;
    disableInitialTextFieldFocus?: boolean;
    textFieldOnTop?: boolean;
    disabledText?: string;
    shouldResetSessionContent: boolean;
}

function CreateEditComment(props: ICreateEditComment) {
    const [saveModel, setSaveModel] = useStateSessionStorage<string | undefined>(props.id, props.content);
    const { helpLink } = useUserContext();
    const { getHelpUrl } = useUrlProvider();

    useEffect(() => {
        if (props.content) {
            setSaveModel(props.content);
            if (textFieldRef.current) {
                textFieldRef.current.selectionStart = textFieldRef.current.value.length;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.content]);

    // Runs on mount - Handle when the components mouunts. First time or refresh
    useEffect(() => {
        if (props.shouldResetSessionContent) {
            setSaveModel(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.shouldResetSessionContent]);

    /* props.CommentType can not be in the first useEffect. A reply to a initial comment will result in infinite loop */
    useEffect(() => {
        if (!props.disableInitialTextFieldFocus || props.commentType !== CommentType.CreateComment) {
            if (textFieldRef.current) {
                textFieldRef.current.selectionStart = textFieldRef.current.value.length;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.commentType]);

    useEffect(() => {
        if (!props.disableInitialTextFieldFocus || props.commentType !== CommentType.CreateComment) {
            textFieldRef.current?.focus();
        }
    }, [props.commentType, props.disableInitialTextFieldFocus]);

    const { translateString } = useTranslation();
    const styles = useCreateCommentStyle();

    const textFieldRef = React.useRef<HTMLTextAreaElement>();
    const isEdit = props.commentType === CommentType.EditComment || props.commentType === CommentType.EditSubComment;

    return (
        <Box sx={{ flexShrink: 0 }}>
            <DotLegalTextField
                inputRef={textFieldRef}
                disabled={props.isSaving || props.disabled}
                onChange={(content) => {
                    setSaveModel(content);
                }}
                multiline={!props.expandInputOnFocus}
                debounce={false}
                label={
                    isEdit
                        ? translateString("editComment")
                        : props.commentType === CommentType.CreateComment
                          ? translateString("addComment")
                          : translateString("replyComment")
                }
                rows={4}
                value={props.disabled && props.disabled ? props.disabledText! : (saveModel ?? "")}
                expandToMultilineOnFocus={props.expandInputOnFocus}
            />
            {props.disabled && (
                <Box
                    component={Link}
                    sx={(theme) => ({
                        fontSize: 12,
                        color: theme.palette.primary.main,
                        textDecoration: "none",
                        "&:hover": {
                            textDecoration: "underline",
                        },
                    })}
                    to={getHelpUrl(helpLink)}
                    target={"_blank"}
                >
                    {translateString("getMoreInfoHere")}
                </Box>
            )}

            <Box sx={styles.btnContainer}>
                <React.Fragment>
                    {props.commentType === CommentType.CreateComment ? (
                        <Box sx={{ marginLeft: props.textFieldOnTop ? "-4px" : "-12px" }}>
                            <DotLegalCheckbox
                                label={props.notificationText}
                                onChange={async (subscribe: boolean) => props.onSubscriptionChanged(subscribe)}
                                checked={props.subscribed ?? false}
                                fontSize="s"
                                disabled={props.disabled}
                            />
                        </Box>
                    ) : (
                        <DotLegalButton
                            onClick={async () => {
                                setSaveModel(undefined);
                                props.onCancelClicked();
                            }}
                            buttonType={"danger"}
                        >
                            {translateString("cancel")}
                        </DotLegalButton>
                    )}

                    <DotLegalButton
                        disabled={props.isSaving || isNullOrWhitespace(saveModel) || props.disabled}
                        onClick={async () => {
                            props.onCommentSubmitted(saveModel!);
                            setSaveModel(undefined);
                        }}
                        buttonType={"primary"}
                    >
                        {isEdit ? translateString("save") : translateString("send")}
                    </DotLegalButton>
                </React.Fragment>
            </Box>
        </Box>
    );
}

export default CreateEditComment;
