import { useTheme } from "@mui/material";
import { IDotLegalStepper } from "./DotLegalStepper";
import stepOne from "../../../processingActivity/stepOneGraphic.svg?url";
import stepTwo from "../../../processingActivity/stepTwoGraphic.svg?url";
import stepFive from "../../../processingActivity/stepFiveGraphic.svg?url";
import stepEight from "../../../processingActivity/stepEightGraphic.svg?url";
import systemEdit from "../../../system/systemEdit/systemEdit.svg?url";
import systemInformation from "../../../system/systemEdit/systemInformation.svg?url";
import { createSxStyles } from "../../createSxStyles";

export const useDotLegalStepperStyles = (props: IDotLegalStepper) => {
    const theme = useTheme();
    return createSxStyles({
        root: {
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
        },
        instructions: {
            fontWeight: theme.typography.fontWeightBold,
            fontSize: theme.typography.pxToRem(16),
            color: theme.palette.primary.dark,
            textAlign: "center",
        },
        container: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flex: "0 1 88%",
            position: "relative",
        },
        buttonWrapper: {
            marginTop: theme.spacing(3),
            display: "flex",
            alignItems: "flex-start",
            "& button": {
                marginBottom: theme.spacing(1),
                width: 110 + "px",
            },
        },
        nextDraftWrapper: {
            marginLeft: theme.spacing(1),
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
        },
        /*         buttonContainer: {
                "&:hover": {
                    "& svg": {
                        transform: "translateX(10px)",
                        transition: "0.2s",
                    },
                },
            }, */
        whiteArrow: {
            "& svg": {
                fill: theme.palette.common.white,
            },
        },
        leftArrow: {
            transform: "rotate(180deg)",
        },
        childContainer: {
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginTop: theme.spacing(3),
        },
        step: {
            color: theme.palette.primary.dark,
            textTransform: "uppercase",
            textAlign: "center",
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(3),
            fontSize: theme.typography.pxToRem(14),
            fontWeight: theme.typography.fontWeightBold,
        },
        stepper: {
            display: "flex",
            alignItems: "start",
            "& .MuiStepper-root": {
                width: "100%",
                background: "inherit",
            },
        },
        completedOrErrorClass: {
            color: theme.palette.primary.dark,
        },
        mobileStepper: {
            "& .MuiMobileStepper-dot": {
                backgroundColor: theme.palette.grey["400"],
            },
            "& .MuiMobileStepper-dotActive": {
                backgroundColor: theme.palette.primary.main,
            },
        },
        sticky: {
            position: "sticky",
            bottom: 0,
        },
        contentWrapper: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            height: "100%",
            width: "100%",
        },
        childWrapper: {
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
        },
        stepGraphic: getStepGraphic(props),
    });
};

function getStepGraphic(props: IDotLegalStepper) {
    if (props.graphicType && props.graphicType.graphic) {
        let image = "";

        switch (props.graphicType.graphic) {
            case "stepOne":
                image = stepOne;
                break;

            case "stepTwo":
                image = stepTwo;
                break;

            case "stepFive":
                image = stepFive;
                break;

            case "stepEight":
                image = stepEight;
                break;
            case "systemEdit":
                image = systemEdit;
                break;
            case "systemInfomation":
                image = systemInformation;
                break;
        }

        let isRight = "auto";
        let isLeft = "auto";
        const positionProcentage = "12%";

        if (props.graphicType.position === "right") isRight = positionProcentage;
        if (props.graphicType.position === "left") isLeft = positionProcentage;

        return {
            content: `url('${image}')`,
            right: isRight,
            left: isLeft,
            bottom: 0,
            width: "13%",
        };
    }
    return {};
}
