import { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { ajaxQueue } from "../../common/api/ajaxQueue";
import { deleteHttp, get, put } from "../../common/api/apiShared";
import { useExpandedId } from "../../common/hooks/useExpandedId";
import { useOptimisticUpdate } from "../../common/hooks/useOptimisticUpdate";
import { ProcessingActivityRiskAssessmentScenarioViewModel, ScenarioSaveModel, ScenarioViewModel } from "../RiskAssessment.types";

export function UseProcessingActivityRiskScenariosDialogHook(riskAssessmentVersionId: string | undefined) {
    const expandedHook = useExpandedId();
    const queryClient = useQueryClient();
    const optimisticUpdate = useOptimisticUpdate();

    const { processingActivityId } = useParams<{ processingActivityId: string }>();

    var controllerToCall = "ProcessingActivityRiskAssessment";
    let url = `/${controllerToCall}/${processingActivityId}/scenarios?riskAssessmentVersionId=${riskAssessmentVersionId}`;
    const { data, isLoading, isFetching } = useQuery(url, () => get<ProcessingActivityRiskAssessmentScenarioViewModel>(url));

    let riskAssessmentModel;
    if (data) {
        riskAssessmentModel = {
            processingActivityId: data.processingActivityId,
            processingActivityName: data.processingActivityName,
            score: data.score,
            averageScore: data.averageScore,
            businessAreas: data.businessAreas,
            isLocked: data.isLocked,
            scenarios: data.scenarios.map((scenario) => {
                return {
                    id: scenario.id,
                    scenarioType: scenario.scenarioType,
                    score: scenario.score,
                    note: scenario.note,
                    header: scenario.calculationExplanation,
                    isOverridden: scenario.isOverridden,
                };
            }),
            currentRiskAssessmentVersionName: data?.currentRiskAssessmentVersionName,
        };
    }

    const OnComponentUnmount = () => {
        useEffect(() => {
            return () => {
                expandedHook.setExpandedId("");
            };
        }, []);
    };

    const removeScenarioMutation = useMutation(resetScenario);
    const resetScenarioClick = async (scenarioId: string) => {
        let newData = { ...data! };
        let scenario = newData.scenarios.find((x) => x.id === scenarioId)!;
        scenario.score = scenario.calculatedScore;

        optimisticUpdate.setQueryData(url, data);
        removeScenarioMutation.mutateAsync(scenarioId, {
            onSuccess: (response: any) => {
                queryClient.refetchQueries(url);
            },
        });
    };

    const editScenarioMutation = useMutation(convertToScenarioSaveModel);
    const saveScenario = async (model: ScenarioViewModel) => {
        let newData = { ...data! };
        let scenario = newData.scenarios.find((x) => x.scenarioType === model.scenarioType)!;
        scenario.score = model.score!;

        optimisticUpdate.setQueryData(url, data);

        await ajaxQueue()
            .addRequest(editScenarioMutation.mutateAsync, model!)
            ?.then((x) => {
                queryClient.refetchQueries(url);
            });
    };

    return {
        data: riskAssessmentModel,
        isLoading,
        isSaving: isFetching,
        saveScenario,
        resetScenarioClick,
        OnComponentUnmount,
    };

    async function convertToScenarioSaveModel(model: ScenarioViewModel) {
        const saveModel: ScenarioSaveModel = {
            score: model.score!,
            note: model.note,
            scenarioType: model.scenarioType,
        };

        return await put<{}>(`/${controllerToCall}/${processingActivityId}/scenario?riskAssessmentVersionId=${riskAssessmentVersionId}`, saveModel);
    }

    async function resetScenario(scenarioId: string) {
        return await deleteHttp<string>(`/${controllerToCall}/${scenarioId}/resetScenario?riskAssessmentVersionId=${riskAssessmentVersionId}`);
    }
}
