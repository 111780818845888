import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { NewCasesCounterViewModel } from "../ProcessingActivityStatistics.types";
import { useProcessingActivityStatisticsStyles } from "../processingActivityStatistics.styles";
import { useTranslation } from "../../../localization/useTranslation";
import DotLegalPaper from "../../../common/components/dotLegalPaper/DotLegalPaper";
import { getCounter } from "../ProcessingActivityStatistics";

export interface NewCaseCounterProps {
    newCaseCounter: NewCasesCounterViewModel | undefined;
}

function NewCaseCounter(props: NewCaseCounterProps) {
    const styles = useProcessingActivityStatisticsStyles();
    const { translateString } = useTranslation();
    return (
        <Box>
            <DotLegalPaper backgroundColor="darkLightBlue" fullHeight>
                <Typography sx={styles.header}>{translateString("numberOfReviews")}</Typography>
                <Typography sx={styles.body}>{props?.newCaseCounter?.totalNumber}</Typography>
            </DotLegalPaper>
            <Grid sx={styles.footer} container columnSpacing={5} rowSpacing={0.5}>
                {getCounter(translateString("new"), props?.newCaseCounter?.numberOfNew ?? 0)}
                {getCounter(translateString("changes"), props?.newCaseCounter?.numberOfChanged ?? 0)}
            </Grid>
        </Box>
    );
}

export default NewCaseCounter;
