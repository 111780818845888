import { useTheme } from "@mui/material";
import { createSxStyles } from "../../../common/createSxStyles";

export const useRiskDialogHeader = () => {
    const theme = useTheme();
    return createSxStyles({
        headerContainer: {
            textAlign: "center",
        },
        fontSize: {
            fontSize: theme.typography.pxToRem(16),
            fontWeight: theme.typography.fontWeightBold,
            textTransform: "uppercase",
        },
        subHeaderText: {
            color: theme.palette.primary.dark,
            fontSize: theme.typography.pxToRem(14),
            fontWeight: "bold",
            marginTop: theme.spacing(1),
        },
    });
};
