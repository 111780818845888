import React from "react";
import { useDeleteDeclarationDialog } from "./DeleteDeclarationDialog.hooks";
import { DotLegalDeleteDialog } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { Trans } from "react-i18next";

export interface DeleteDeclarationDialogProps {
    id: string;
    name: string;
    onCloseDialog: () => void;
}

function DeleteDeclarationDialog(props: DeleteDeclarationDialogProps) {
    const { translateString } = useTranslation();
    const { onDeleteDeclaration, isDeleting } = useDeleteDeclarationDialog(props);

    return (
        <DotLegalDeleteDialog
            open
            headerText={translateString("deleteDeclaration")}
            alertBodyText={<Trans i18nKey="deleteDeclarationWarning" values={{ name: props.name }} />}
            warningTranslation={""}
            deleteBtnText={translateString("delete")}
            cancelBtnText={translateString("cancel")}
            onDialogClose={props.onCloseDialog}
            onOkClick={onDeleteDeclaration}
            inProgress={isDeleting}
        />
    );
}

export default DeleteDeclarationDialog;
