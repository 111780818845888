import { isAfter, isEqual } from "date-fns";
import { ValidatorFn } from "./types";
import { TOptions } from "i18next";
import { isNullOrWhitespace } from "../../stringOperations";

export class Validators<TItem> {
    private urlRegex = RegExp(/^(https?|ftp):\/\/(-\.)?([^\s/?.#-]+\.?)+([^\s]*)$/i);
    private readonly translateString: (key: string, options?: TOptions | string) => string;
    constructor(translateString: (key: string, options?: TOptions | string) => string) {
        this.translateString = translateString;
    }

    public validateNotEmpty(
        valueSelector: (item: TItem) => any,
        valueName: string,
        disableRule?: (item: TItem) => boolean,
        errroMsg?: string
    ): ValidatorFn<TItem> {
        return (item) => {
            const value = valueSelector(item);
            const ruleDisabled = disableRule?.(item);

            if (((value || value === 0) && !isNullOrWhitespace(value.toString())) || ruleDisabled) {
                return undefined;
            }

            if (errroMsg) {
                return errroMsg;
            }

            return this.translateString("validateNotEmpty", { name: valueName });
        };
    }
    public validateAnyNotEmpty(valueSelector: (item: TItem) => any[], valueName: string): ValidatorFn<TItem> {
        return (item) => {
            const values = valueSelector(item);
            const errors = values.map((value) => {
                if (!value || !value.toString().trim()) {
                    return this.translateString("validateNotEmpty", { name: valueName });
                }
                return undefined;
            });
            return errors.some((x) => x === undefined) ? undefined : errors.find((x) => x !== undefined);
        };
    }

    public validateChecked(valueSelector: (item: TItem) => any, valueName: string): ValidatorFn<TItem> {
        return (item) => {
            const value = valueSelector(item);
            if (!value) {
                return this.translateString("validateChecked", { name: valueName });
            }
            return undefined;
        };
    }

    public validateEmail(valueSelector: (item: TItem) => any, valueName: string): ValidatorFn<TItem> {
        return (item) => {
            const value = valueSelector(item);
            if (!value) return this.translateString("validateNotEmpty", { name: valueName });
            const emailValidator = RegExp(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
            return emailValidator.test(value.toString()) ? undefined : this.translateString("validateEmail");
        };
    }

    public validateTodayOrInTheFuture(valueSelector: (item: TItem) => any, valueName: string, disableRule?: (item: TItem) => boolean): ValidatorFn<TItem> {
        return (item) => {
            const value = valueSelector(item);
            const ruleDisabled = disableRule?.(item);

            if (!value) return this.translateString("validateNotEmpty", { name: valueName });
            let date = new Date();
            date.setHours(0, 0, 0, 0);
            if (value >= date || ruleDisabled) return undefined;
            return this.translateString("validateTodayOrInTheFuture", { name: valueName });
        };
    }

    public validateUrl(valueSelector: (item: TItem) => any): ValidatorFn<TItem> {
        return (item) => {
            const value = valueSelector(item);
            if (!value) return undefined;
            return this.urlRegex.test(value.toString()) ? undefined : this.translateString("validateUrl");
        };
    }

    public validateUrlAndNotEmpty(valueSelector: (item: TItem) => any, valueName: string, disableRule?: (item: TItem) => boolean): ValidatorFn<TItem> {
        return (item) => {
            const value = valueSelector(item);
            const ruleDisabled = disableRule?.(item);
            if (((value || value === 0) && !isNullOrWhitespace(value.toString())) || ruleDisabled) {
                return this.urlRegex.test(value.toString()) ? undefined : this.translateString("validateUrl");
            }

            return this.translateString("validateNotEmpty", { name: valueName });
        };
    }

    public validateAfterStart(
        startLabel: string,
        endLabel: string,
        valueSelector: (item: TItem) => { start?: Date | null; end?: Date | null }
    ): ValidatorFn<TItem> {
        return (item) => {
            const value = valueSelector(item);
            if (!value) return undefined;
            const { start, end } = value;
            if (!start || !end) return undefined;

            if (isAfter(end, start)) return undefined;

            return this.translateString("validateAfterStart", { endLabel, startLabel });
        };
    }

    public validateSameOrAfterStart(
        startLabel: string,
        endLabel: string,
        valueSelector: (item: TItem) => { start?: Date | null; end?: Date | null }
    ): ValidatorFn<TItem> {
        return (item) => {
            const value = valueSelector(item);
            if (!value) return undefined;
            const { start, end } = value;
            if (!start || !end) return undefined;

            if (isEqual(end, start) || isAfter(end, start)) return undefined;

            return this.translateString("validateSameOrAfterStart", { endLabel, startLabel });
        };
    }

    public validateCountryCode(valueSelector: (item: TItem) => any, valueName: string, disableRule?: (item: TItem) => boolean): ValidatorFn<TItem> {
        const regex = new RegExp(/^(?:[+\d].*\d|\d)$/);

        return (item) => {
            const value = valueSelector(item);
            const ruleDisabled = disableRule?.(item);

            if (ruleDisabled) {
                return undefined;
            }

            if (value && !isNullOrWhitespace(value.toString())) {
                if (regex.test(value)) {
                    return undefined;
                } else {
                    return this.translateString("invalidCountryCode");
                }
            }

            return this.translateString("validateNotEmpty", { name: valueName });
        };
    }
}
