import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { get } from "../../common/api/apiShared";
import { LegalEntityAuditGroupOverviewModel } from "../legalEntityAuditGroupOverview/LegalEntityAuditGroupOverview.types";
import { AuditGroupChartDataViewModel } from "./managementReportCharts/allocationOfRiskLevelChart/AllocationOfRiskLevelChart.types";

export function useLegalEntityAuditGroupManagementReport() {
    const { id } = useParams<{ id: string }>();

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const isPdf = Boolean(urlParams.get("isPdf"));

    const url = `/legalEntityAuditGroups/${id}/overview`;
    const { isLoading, data } = useQuery(url, () => get<LegalEntityAuditGroupOverviewModel>(url));

    const chartDataUrl = `/legalEntityAuditGroups/${id}/chartData`;
    const chartDataQuery = useQuery(chartDataUrl, () => get<AuditGroupChartDataViewModel>(chartDataUrl), { enabled: data !== undefined && data.scoreEnabled });

    return {
        isLoading: isLoading || chartDataQuery.isLoading,
        data,
        isPdf,
        chartDataLoading: chartDataQuery.isLoading,
        chartData: chartDataQuery.data,
    };
}
