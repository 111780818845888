import { SelectableItem } from "../ProcessingActivity.types";

export interface ProcessingActivityStatisticsViewModel {
    newCaseCounter: NewCasesCounterViewModel;
    contractCounter: ContractCounterViewModel;
    processingActivityCounter: ProcessingActivityCounterViewModel;
    completedContractCounter: ContractCounterViewModel;
    completedCases: number;
    caseManagerAverage: number;
    researcherAverage: number;
    submittedToActiveAverage: number;
    advisoryCases: number;
}

export interface NewCasesCounterViewModel {
    totalNumber: number;
    numberOfNew: number;
    numberOfChanged: number;
}

export interface ContractCounterViewModel {
    totalNumber: number;
    numberOfCTA: number;
    numberOfPHDAgreements: number;
    numberOfJointDataControllerAgreements: number;
    numberOfDisclosureAgreements: number;
    numberOfCooperationAgreements: number;
    numberOfDataProcessorAgreements: number;
    numberOfServiceAgreements: number;
    numberOfOtherAgreements: number;
}

export interface ProcessingActivityCounterViewModel {
    totalNumber: number;
    numberOfActive: number;
    numberOfDraftSubmitted: number;
    numberOfDrafts: number;
    numberOfAwaitingResearcher: number;
    numberOfArchived: number;
    numberOfCancelled: number;
}

export class DateRange {
    fromDate: Date | null;
    toDate: Date | null;

    constructor(startDate: Date | null, endDate: Date | null) {
        this.fromDate = startDate;
        this.toDate = endDate;
    }
}

export interface ProcessingActivityStatisticUsersViewModel {
    caseManagers: Array<SelectableItem>;
    researchers: Array<SelectableItem>;
}

export enum ProcessingActivityStatisticsPeriodType {
    Day,
    Week,
    Month,
    ThreeMonths,
    Year,
    YDT,
    Custom,
}
