import { alpha, useTheme } from "@mui/material";
import { createSxStyles } from "../../../common/createSxStyles";
import { toolTipTextSize } from "../../../privacyTheme";

export const useProcessingActivitySharingsTabStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        legalEntity: {
            color: theme.palette.primary.dark,
            display: "flex",
            alignItems: "center",
            "& svg": {
                fill: theme.palette.error.light,
                marginLeft: theme.spacing(0.5),
                height: 17,
                width: 17,
            },
        },
        entityRole: {
            color: alpha(theme.palette.primary.main, 0.7),
            "& a": {
                fontWeight: "bold",
            },
        },
        pre: {
            fontFamily: theme.typography.fontFamily,
            fontSize: toolTipTextSize,
        },
        tooltipIcon: {
            marginTop: 5 + "px",
            "& svg": {
                width: 14,
                height: 14,
            },
        },
    });
};
