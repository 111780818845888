import React from "react";
import { DotLegalCheckbox, DotLegalInformationTooltip } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { SecurityMeasureOverviewModel } from "../../../processingActivity/dataSubjectStep/DataSubjectStep.types";
import { useUrlProvider } from "../../../useUrlProvider";
import { QuestionData, QuestionType, QuestionTypeStandardAnswers, QuestionViewModel, RiskAssessmentType } from "../../RiskAssessment.types";
import RiskAssessmentAnswerCollapse from "../../riskAssessmentAnswerCollapse/RiskAssessmentAnswerCollapse";
import { generateQuestionWithLink, QuestionDataLinkProvider } from "../QuestionTextGenerator";
import RiskAnswerGroup from "../riskAnswerGroup/RiskAnswerGroup";
import { useQuestionsCollapseStyles } from "./QuestionsCollapse.styles";
import { CustomQuestionType } from "../../riskAssessmentTemplateCustomQuestions/RiskAssessmentCustomQuestions.types";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import { Box } from "@mui/material";
import { parseTemplate } from "../riskAssessmentTemplateParser.ts";

export interface IQuestionCollapse {
    questions: Array<QuestionViewModel>;
    customQuestions?: CustomQuestionType[];
    getCustomQuestionDisplayValue?: (customQuestionId: string) => string;
    riskAssessmentType: RiskAssessmentType;
    saveQuestion: (model: QuestionViewModel, riskAssessmentType: RiskAssessmentType) => void;
    disableResetButton: boolean;
    resetQuestionClick: (questionId: string, questionType: QuestionType, riskAssessmentType: RiskAssessmentType, customQuestionId?: string) => void;
    isPDF?: boolean;
    questionData?: QuestionData;
    id?: string;
    securityMeasues: Array<SecurityMeasureOverviewModel>;
    onExcludeChange?: (model: QuestionViewModel, excluded: boolean) => void;
    disabled: boolean;
}

export default function QuestionsCollapse(props: IQuestionCollapse) {
    const styles = useQuestionsCollapseStyles();
    const { getEditProcessingActivityUrl } = useUrlProvider();
    const { translateString } = useTranslation();
    const { permissions } = useUserContext();

    const questionDataLinkProvider =
        props.riskAssessmentType === RiskAssessmentType.Probability
            ? new QuestionDataLinkProvider(props.questionData, props.id!, getEditProcessingActivityUrl, permissions)
            : undefined;

    const getAverageScore = (consequenceScore: number | null, probabilityScore: number | null) => {
        if (consequenceScore && probabilityScore) {
            const sum = consequenceScore + probabilityScore - 1;
            const categories = [1, 1, 2, 2, 3, 4, 4, 5, 5]; // Matches the risk Matrix (with grid 5) (1 is veryLow, 2 is low. 3 is Moderate, 4 high and 5 VeryHigh)
            const categoryIndex = Math.min(Math.max(sum - 1, 0), 8); // Ensure the result is between 0 and 8

            return categories[categoryIndex];
        }
        return 0;
    };

    const hasError = (question: QuestionViewModel) => {
        if (
            question.isExcluded ||
            props.disabled ||
            (question.consequenceScoreNote.score &&
                question.consequenceScoreNote.note &&
                question.probabilityScoreNote.score &&
                question.probabilityScoreNote.note)
        )
            return false;

        return true;
    };

    const getSecurityMeasureSnippets = () => {
        return props.securityMeasues.map((s) => {
            return `${s.name} (${s.isMet ? translateString("compliant").toLowerCase() : translateString("notCompliant").toLowerCase()})`;
        });
    };

    const getQuestionKey = (question: QuestionViewModel) => {
        return question.customQuestionId ? question.customQuestionId : question.questionType.toString();
    };

    return (
        <React.Fragment>
            {props.questions.map((obj, index) => (
                <Box key={getQuestionKey(obj)} sx={styles.dropdown} className={`avoid-page-break`}>
                    <RiskAssessmentAnswerCollapse
                        componentKey={getQuestionKey(obj)}
                        header={getHeader(obj, props.riskAssessmentType)}
                        score={getAverageScore(obj.consequenceScoreNote.score, obj.probabilityScoreNote.score)}
                        isPDF={props.isPDF}
                        isScoreOverrridden={obj.consequenceScoreNote.isOverridden || obj.probabilityScoreNote.isOverridden}
                        error={hasError(obj)}
                        isExcluded={obj.isExcluded}
                    >
                        <RiskAnswerGroup
                            headerText={translateString("consequenceOfReality")}
                            notePlaceholder={translateString("stateTheReason")}
                            showHeader
                            showResetButton={obj.consequenceScoreNote.isAnswerScore}
                            disableResetButton={props.disableResetButton}
                            score={obj.consequenceScoreNote.score}
                            note={obj.consequenceScoreNote.note}
                            isPdf={props.isPDF}
                            isConsequence
                            noteMandatory
                            answerTooltip={translateString("riskConsequenceAnswerTooltip")}
                            onScoreChange={(score) => {
                                let object = props.questions[index];
                                object.consequenceScoreNote.score = score;
                                props.saveQuestion(object as QuestionViewModel, RiskAssessmentType.Consequence);
                            }}
                            onNoteChange={(note) => {
                                let object = props.questions[index];
                                object.consequenceScoreNote.note = note;
                                props.saveQuestion(object as QuestionViewModel, RiskAssessmentType.Consequence);
                            }}
                            onResetOverrideClick={() =>
                                props.resetQuestionClick(
                                    props.questions[index].id,
                                    props.questions[index].questionType,
                                    RiskAssessmentType.Consequence,
                                    props.questions[index].customQuestionId
                                )
                            }
                            snippets={new QuestionTypeStandardAnswers(obj.questionType, translateString).consequenceAnswers}
                            isDisabled={obj.isExcluded || props.disabled}
                        />
                        <RiskAnswerGroup
                            headerText={translateString("probabilityOfReality")}
                            notePlaceholder={translateString("stateTheReason")}
                            showHeader
                            showResetButton={obj.probabilityScoreNote.isAnswerScore}
                            disableResetButton={props.disableResetButton}
                            score={obj.probabilityScoreNote.score}
                            note={obj.probabilityScoreNote.note}
                            isPdf={props.isPDF}
                            isConsequence={false}
                            answerTooltip={translateString("riskProbabilityAnswerTooltip")}
                            noteMandatory
                            onScoreChange={(score) => {
                                let object = props.questions[index];
                                object.probabilityScoreNote.score = score;
                                props.saveQuestion(object as QuestionViewModel, RiskAssessmentType.Probability);
                            }}
                            onNoteChange={(note) => {
                                let object = props.questions[index];
                                object.probabilityScoreNote.note = note;
                                props.saveQuestion(object as QuestionViewModel, RiskAssessmentType.Probability);
                            }}
                            onResetOverrideClick={() =>
                                props.resetQuestionClick(
                                    props.questions[index].id,
                                    props.questions[index].questionType,
                                    RiskAssessmentType.Probability,
                                    props.questions[index].customQuestionId
                                )
                            }
                            snippets={getSecurityMeasureSnippets()}
                            isDisabled={obj.isExcluded || props.disabled}
                            noMargin
                        />
                        <Box sx={{ marginTop: 1 }}>
                            <DotLegalCheckbox
                                disabled={props.disabled}
                                onChange={(exclude) => {
                                    if (props.onExcludeChange) {
                                        props.onExcludeChange(obj, exclude);
                                    }
                                }}
                                checked={obj.isExcluded}
                                label={translateString("excludeRiskScenario")}
                                tooltip={translateString("excludedRiskScenarioTooltip")}
                            />
                        </Box>
                    </RiskAssessmentAnswerCollapse>
                </Box>
            ))}
        </React.Fragment>
    );

    function getHeader(questionViewModel: QuestionViewModel, riskAssessmentType: RiskAssessmentType) {
        return (
            <Box sx={styles.questionText}>
                {getHeaderValue(questionViewModel, riskAssessmentType)}
                <Box component={"span"} sx={styles.tooltip}>
                    {!!questionViewModel.toolTip ? <DotLegalInformationTooltip text={questionViewModel.toolTip} /> : <></>}
                </Box>
            </Box>
        );
    }

    function getHeaderValue(questionViewModel: QuestionViewModel, riskAssessmentType: RiskAssessmentType) {
        if (questionViewModel.questionType === QuestionType.CustomQuestion) {
            const customQuestionDisplayValue = props.getCustomQuestionDisplayValue
                ? props?.getCustomQuestionDisplayValue(questionViewModel.customQuestionId)
                : "";
            const chunks = parseTemplate(customQuestionDisplayValue);

            return generateQuestionWithLink(chunks, questionDataLinkProvider!, styles.questionLinkSeperator, translateString);
        }

        const type = QuestionType[questionViewModel.questionType];
        if (riskAssessmentType === RiskAssessmentType.Consequence) return translateString(`${type}`);

        const templateString = `${type}${containsTemplateData(questionViewModel.questionType) ? "" : "Empty"}`;
        const chunks = parseTemplate(translateString(templateString));
        return generateQuestionWithLink(chunks, questionDataLinkProvider!, styles.questionLinkSeperator, translateString);
    }

    function containsTemplateData(questionType: QuestionType) {
        if (
            questionType === QuestionType.ProcessingActivityUnauthorizedGetsAccessToDataSubjects ||
            questionType === QuestionType.ProcessingActivityUnwantedUnintentionalChangeMadeToSubjectsData ||
            questionType === QuestionType.ProcessingActivityPersonalDataInaccessableToDataSubjects ||
            questionType === QuestionType.ProcessingActivityDataSubjectsLoseControlOverOwnData ||
            questionType === QuestionType.ProcessingActivitySubjectsNotInformedAboutDataProcessing
        ) {
            return questionDataLinkProvider?.hasDataSubjects() && questionDataLinkProvider.hasDataCategories();
        } else if (
            questionType === QuestionType.ProcessingActivityPersonalDataSentToUnauthorizedPerson ||
            questionType === QuestionType.ProcessingActivityDataSubjectsLossOfServices ||
            questionType === QuestionType.ProcessingActivitySubjectsNotAbleToExerciseDataProtectionRights
        ) {
            return questionDataLinkProvider?.hasDataSubjects();
        } else if (
            questionType === QuestionType.ProcessingActivityEmployeeNoWorkRelatedAccessToData ||
            questionType === QuestionType.ProcessingActivitySystemNotAutomaticallyDeletedData
        ) {
            return questionDataLinkProvider?.hasDataCategories();
        } else if (questionType === QuestionType.ProcessingActivityDataProcessorsNotEnsureAdequateProtection) {
            return questionDataLinkProvider?.hasDataProcessors() && questionDataLinkProvider.hasDataCategories() && questionDataLinkProvider.hasDataSubjects();
        } else if (
            questionType === QuestionType.ProcessingActivityPersonalDataProcessedGreaterThanNecessary ||
            questionType === QuestionType.ProcessingActivityDataProcessedWithIncompatiblePurposes
        ) {
            return questionDataLinkProvider?.hasDataCategories() && questionDataLinkProvider.hasPurposes();
        } else if (questionType === QuestionType.ProcessingActivityPersonalDataNotDeletedWithinDeadline) {
            return questionDataLinkProvider?.hasDataCategories() && questionDataLinkProvider.hasDeletionPeriods();
        } else if (questionType === QuestionType.ProcessingActivityDataUsedForIndependantPurposes) {
            return questionDataLinkProvider?.hasDataProcessors() && questionDataLinkProvider.hasDataCategories();
        } else if (questionType === QuestionType.ProcessingActivityInsufficientLegalBasisForProcessing) {
            return questionDataLinkProvider?.hasLegalBases() && questionDataLinkProvider.hasDataCategories();
        } else if (
            questionType === QuestionType.SystemUnauthorizedPhysicalOrDigitalAccess ||
            questionType === QuestionType.SystemVictimToTargetedCyberattack ||
            questionType === QuestionType.SystemSensitiveOrConfidentailDataLeakedDueToInadequateDataSecurity ||
            questionType === QuestionType.SystemCompromisedThroughThirdPartySupplier ||
            questionType === QuestionType.SystemInadequateUpdatingAndMaintenance ||
            questionType === QuestionType.SystemEmployessUnintentionallyOrDeliberatelyAbusingAccessRights ||
            questionType === QuestionType.SystemUnavailableDueToHardwareFailureNetworkProblemsNaturalDisasters ||
            questionType === QuestionType.SystemLegislativeChangesNonComplianceWithCurrentLawsAndRegulations ||
            questionType === QuestionType.SystemManipulatedThroughSocialEngineeringPhishingOrOther
        ) {
            return false;
        } else if (isNis2Question(questionType)) {
            return false;
        }

        return true;
    }
}

function isNis2Question(questionType: QuestionType) {
    return questionType >= 2000 || questionType < 4000;
}
