import {
    getDataSources as getDataSourcesTranslation,
    getDocumentTypes as getDocumentTranslation,
    getLegalEntityRiskAssessment,
    getLegalEntityTypes as getLegalEntityTypesTranslation,
    getLocations,
    getProcessingActivityRisks,
    getSharingRoles,
    getSharingTypes,
    getStatusTypes,
    getSystemStatusTypes,
    getSystemTypes,
} from "../columnTypeContext/useColumnTypeContextQueryProvider.ts";
import { CustomListColumnTranslation, CustomListExportTranslations, IdNamePair } from "./CustomListView.types.ts";
import { PropertyType } from "../../common/PropertyTypes.ts";
import { post } from "../../common/api/apiShared.ts";
import { downloadFile } from "../../common/api/downloadFile.ts";
import { usePlanContext } from "../../auth/planProvider/PlanProvider.tsx";
import { useTranslation } from "../../localization/useTranslation.ts";
import { getNoOfDataSubjectSelectOptions, getNoOfProcessorsSelectOptions } from "../../processingActivity/dataSubjectStep/DataSubjectStep.hooks.ts";

export function useCustomListExport() {
    const { addonsFeatures } = usePlanContext();
    const {
        translateString,
        translateDate,
        translateLegalEntityRiskAssessment,
        translateCountriesObject,
        translateStatus,
        translateDocumentType,
        getAllPropertyNames,
        translateDataSubjectsEnum,
        translateProcessorsEnum,
    } = useTranslation();

    let exportListInstantly = async (listId: string, listName: string) => {
        const date = new Date();
        const fileName = `${listName} - ${translateDate(date)} ${date.getHours().toString().padStart(2)}_${date.getMinutes().toString().padStart(2)}.xlsx`;
        await downloadFile(`/CustomListExport/${listId}/export`, fileName, "POST", getExportBody());
    };
    let exportListInBackground = (listId: string) => {
        return post<{ id: string }>(`/CustomListExport/${listId}`, getExportBody());
    };
    let exportAllDataInBackground = () => {
        return post(`/CustomListExport/FullExport`, getExportBody());
    };
    return {
        exportListInstantly,
        exportListInBackground,
        exportAllDataInBackground,
    };
    function getExportBody() {
        const locations = getLocations(translateString);
        const documentTypes = getDocumentTranslation(translateDocumentType);
        const systemStatusTypes = getSystemStatusTypes(translateString);
        const countriesTranslations: IdNamePair[] = Object.entries(translateCountriesObject()).map(([key, value]) => ({ id: key, name: value }) as IdNamePair);
        const propertyTypeStrings = Object.values(PropertyType);
        const columnTranslationsForCustomLists = getAllPropertyNames()
            .filter((p) => propertyTypeStrings.some((s) => s === p.key))
            .map((p) => {
                return { id: p.key, name: p.value };
            });
        return {
            customListColumnHeaderTranslations: columnTranslationsForCustomLists,
            boolTranslations: new Array<IdNamePair>(
                { id: "yes", name: translateString("yes") },
                { id: "no", name: translateString("no") },
                { id: "True", name: translateString("yes") },
                { id: "False", name: translateString("no") }
            ),
            countriesTranslations: countriesTranslations,
            columnTranslations: {
                legalEntityRisk: getLegalEntityRiskAssessment(translateLegalEntityRiskAssessment, translateString),
                risk: getProcessingActivityRisks(PropertyType.Risk, translateString),
                processingActivityClassification: getProcessingActivityRisks(PropertyType.ProcessingActivityClassification, translateString),
                systemType: getSystemTypes(translateString),
                legalEntityType: getLegalEntityTypesTranslation(translateString),
                roles: getSharingRoles(PropertyType.Roles, translateString),
                sharingTypes: getSharingTypes(translateString),
                statusTypes: getStatusTypes(translateStatus, addonsFeatures.research),
                senderRoles: getSharingRoles(PropertyType.SenderRoles, translateString),
                recipientRoles: getSharingRoles(PropertyType.RecipientRoles, translateString),
                receivingLocation: locations,
                dataProcessorLocations: locations,
                location: locations,
                documentTypes: documentTypes,
                systemStatus: systemStatusTypes,
                dataSource: getDataSourcesTranslation(translateString),
                numberOfDataSubjects: getNoOfDataSubjectSelectOptions(translateDataSubjectsEnum),
                numberOfProcessors: getNoOfProcessorsSelectOptions(translateProcessorsEnum),
            } as CustomListColumnTranslation,
        } as CustomListExportTranslations;
    }
}
