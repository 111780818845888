import { Typography } from "@mui/material";
import React from "react";
import { useProcessingActivityStatisticsStyles } from "../processingActivityStatistics.styles";
import { useTranslation } from "../../../localization/useTranslation";
import DotLegalPaper from "../../../common/components/dotLegalPaper/DotLegalPaper";

export interface CaseManagerAverageProps {
    caseManagerAverage: number | undefined;
}

function CaseManagerAverage(props: CaseManagerAverageProps) {
    const styles = useProcessingActivityStatisticsStyles();
    const { translateString } = useTranslation();
    return (
        <DotLegalPaper backgroundColor="darkGreen" fullHeight>
            <Typography sx={styles.header}>{translateString("caseManagerAverage")}</Typography>
            <Typography sx={styles.body}>
                {props?.caseManagerAverage} {translateString("days").toLowerCase()}
            </Typography>
        </DotLegalPaper>
    );
}

export default CaseManagerAverage;
