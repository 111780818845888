import { PropertyType } from "../../common/PropertyTypes";
import { ContentType } from "../CustomLists.types";

export interface EditCustomListViewModel {
    id: string;
    selectedColumns: Array<CustomListColumnViewModel>;
    allColumns: Array<ColumnDefinition>;
}

export interface CustomListColumnViewModel {
    type: PropertyType;
    contentType: ContentType;
    isMandatory: boolean;
    filter: FilterViewModel;
    hideColumn: boolean;
}

export interface ColumnDefinition {
    type: PropertyType;
    contentType: ContentType;
    isMandatory: boolean;
}

export enum ColumnFilterType {
    None,
    ListContainsAnyOf,
    ListContainsNoneOf,
    StringContainsValue,
    StringNotContainsValue,
    NumberIsEqualTo,
    NumberIsNotEqualTo,
    NumberIsGreaterThan,
    NumberIsGreaterThanOrEqualTo,
    NumberIsLessThan,
    NumberIsLessThanOrEqualTo,
    BooleanShowAll,
    BooleanShowTrue,
    BooleanShowFalse,
    DateSpecific,
    DateBefore,
    DateAfter,
}

export class FilterViewModel {
    listValues: Array<string> = [];
    filterType: ColumnFilterType = ColumnFilterType.None;
    textValue: string | undefined;
    numberValue: number | undefined;
    date: Date | null = null;
}

export interface ColumnDisplayModel {
    type: PropertyType;
    contentType: ContentType;
    isMandatory: boolean;
    header: string;
    tooltip: string;
}
