import EvaluateQuestionnaireDialog from "../../common/components/questionnaireComponents/evaluateQuestionnaireDialog/EvaluateQuestionnaireDialog";
import { EvaluationStatus } from "../../common/components/questionnaireComponents/evaluateQuestionnaireDialog/EvaluateQuestionnaireDialog.types";
import { useTranslation } from "../../localization/useTranslation";
import { InternalAssessmentApiRow } from "../InternalAssessments.types";
import useEvaluateInternalAssessmentDialogHooks from "./EvaluateInternalAssessmentDialog.hooks";

export interface IEvaluateInternalAssessmentDialog {
    onEvaluated: () => void;
    onDialogClose: () => void;
    selectedInternalAssessment: InternalAssessmentApiRow;
}

function EvaluateInternalAssessmentDialog(props: IEvaluateInternalAssessmentDialog) {
    const { translateString } = useTranslation();
    const { saveEvaluation, isSaving } = useEvaluateInternalAssessmentDialogHooks(props);

    return (
        <EvaluateQuestionnaireDialog
            onDialogClose={props.onDialogClose}
            onOkClick={saveEvaluation}
            header={translateString("evaluateInternalAssessmentHeader")}
            info={translateString("evaluateInternalAssessmentDescription")}
            isSaving={isSaving}
            evaluation={props.selectedInternalAssessment.evaluation}
            evaluationStatus={props.selectedInternalAssessment.evaluationStatus}
        />
    );
}

export default EvaluateInternalAssessmentDialog;
