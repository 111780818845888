import { Box, Divider, Grid, GridSize } from "@mui/material";
import React from "react";
import { useTranslation } from "../../../localization/useTranslation";
import { DataPrivacyClassification } from "../../DataCategoriesStep/DataCategoriesStep.types";
import { LegalBasesDialogViewModel } from "../ProcessingActivityOverview.types";
import { DataCategoryOverviewStyles } from "./DataCategoryOverview.styles";
import { DotLegalDialog } from "@dotlegal/dotlegal-ui-components";

export interface IDataCategoryOverviewTable {
    legalBases: Array<LegalBasesDialogViewModel>;
    dialogOpen: boolean;
    dialogClose: () => void;
}

export default function DataCategoryOverviewTable(props: IDataCategoryOverviewTable) {
    const { translateString } = useTranslation();
    const styles = DataCategoryOverviewStyles();

    const GetByDataPrivacyClassification = (classification: DataPrivacyClassification) => {
        return props.legalBases.filter((x) => x.classification === classification);
    };

    const ContainsClassification = (classification: DataPrivacyClassification) => {
        return GetByDataPrivacyClassification(classification).length > 0;
    };

    return (
        <DotLegalDialog
            open={props.dialogOpen}
            onDialogClose={props.dialogClose}
            header={translateString("dataCategories")}
            buttonOkText={""}
            hideOkButton
            size={"xl"}
            onOkClick={props.dialogClose}
        >
            <React.Fragment>
                {ContainsClassification(DataPrivacyClassification.Ordinary) && (
                    <Box sx={styles.marginBottom}>
                        {getGridData(
                            getHeaders(DataPrivacyClassification.Ordinary),
                            GetByDataPrivacyClassification(DataPrivacyClassification.Ordinary),
                            DataPrivacyClassification.Ordinary
                        )}
                    </Box>
                )}

                {ContainsClassification(DataPrivacyClassification.NationalIdentificationNumber) && (
                    <Box sx={styles.marginBottom}>
                        {getGridData(
                            getHeaders(DataPrivacyClassification.NationalIdentificationNumber),
                            GetByDataPrivacyClassification(DataPrivacyClassification.NationalIdentificationNumber),
                            DataPrivacyClassification.NationalIdentificationNumber
                        )}
                    </Box>
                )}

                {ContainsClassification(DataPrivacyClassification.CriminalOffenses) && (
                    <Box sx={styles.marginBottom}>
                        {getGridData(
                            getHeaders(DataPrivacyClassification.CriminalOffenses),
                            GetByDataPrivacyClassification(DataPrivacyClassification.CriminalOffenses),
                            DataPrivacyClassification.CriminalOffenses
                        )}
                    </Box>
                )}

                {ContainsClassification(DataPrivacyClassification.None) && (
                    <Box sx={styles.topHeader}>
                        {getGridData(
                            getHeaders(DataPrivacyClassification.None),
                            GetByDataPrivacyClassification(DataPrivacyClassification.None),
                            DataPrivacyClassification.None
                        )}
                    </Box>
                )}
                {ContainsClassification(DataPrivacyClassification.Sensitive) && (
                    <Box sx={styles.topHeader}>
                        {getGridData(
                            getHeaders(DataPrivacyClassification.Sensitive),
                            GetByDataPrivacyClassification(DataPrivacyClassification.Sensitive),
                            DataPrivacyClassification.Sensitive
                        )}
                    </Box>
                )}
            </React.Fragment>
        </DotLegalDialog>
    );

    function getGridData(headers: Array<string>, legalBases: Array<LegalBasesDialogViewModel>, classification: DataPrivacyClassification) {
        let isSensitive = classification === DataPrivacyClassification.Sensitive;
        const gridProps: { item: boolean; xs: GridSize } = {
            item: true,
            xs: isSensitive ? 3 : 4,
        };

        return (
            <Grid spacing={2} container>
                <Grid sx={styles.header} item xs={3}>
                    {getClassificationName(classification)}
                </Grid>
                <Grid item xs={9} container>
                    {headers.map((element) => (
                        <Grid key={element} sx={styles.header} {...gridProps}>
                            {element}
                        </Grid>
                    ))}
                </Grid>

                {legalBases.map((dataCategory) => (
                    <React.Fragment key={dataCategory.id}>
                        <Grid sx={styles.tableCell} item xs={3}>
                            {dataCategory.name}
                        </Grid>
                        <Grid item sx={styles.tableCell} xs={9} container>
                            {dataCategory.dataCategoryViewItems?.map((row, index) => (
                                <React.Fragment key={index}>
                                    <Grid {...gridProps} sx={styles.gridPadding}>
                                        {`${row.directive} ${
                                            row.directive && dataCategory.dataCategoryViewItems.length > 1
                                                ? ` - ${translateString("purpose")}: ${row.purpose}`
                                                : ""
                                        }`}
                                    </Grid>
                                    {isSensitive ? (
                                        <Grid {...gridProps} sx={styles.gridPadding}>
                                            {row.doubleBasis}
                                        </Grid>
                                    ) : undefined}
                                    <Grid {...gridProps} sx={styles.gridPadding}>
                                        {row.nationalLaw}
                                    </Grid>
                                    <Grid {...gridProps}>{row.deletionPeriod}</Grid>
                                </React.Fragment>
                            ))}
                        </Grid>

                        <Grid sx={styles.gridDivider} item xs={12}>
                            <Divider sx={styles.divider} />
                        </Grid>
                    </React.Fragment>
                ))}
            </Grid>
        );
    }

    function getClassificationName(classification: DataPrivacyClassification) {
        switch (classification) {
            case DataPrivacyClassification.None:
                return translateString("dataCategoriNone");
            case DataPrivacyClassification.Ordinary:
                return translateString("dataCategoriOrdinary");
            case DataPrivacyClassification.Sensitive:
                return translateString("dataCategoriSensitive");
            case DataPrivacyClassification.NationalIdentificationNumber:
                return translateString("nationalidentificationnumber");
            case DataPrivacyClassification.CriminalOffenses:
                return translateString("criminaloffenses");
            default:
                return classification;
        }
    }

    function getHeaders(classification: DataPrivacyClassification) {
        let headers = Array<string>();
        headers.push(translateString("legalBasis"));

        if (classification === DataPrivacyClassification.Sensitive) headers.push(translateString("doubleLegalBasis"));

        headers.push(translateString("nationalLaw"));
        headers.push(translateString("deletionPeriod"));

        return headers;
    }
}
