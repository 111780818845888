import React from "react";
import { Trans } from "react-i18next";
import { DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { useTrialRibbonStyles } from "./TrialRibbon.styles";
import { TrialInformationViewModel, TrialPlanViewModel } from "../Trial.types";
import { PlanType } from "../../auth/planProvider/PlanProvider.types";
import { Box } from "@mui/material";
import PlanDialogButtonWrapper from "../../plan/planDialogButtonWrapper/planDialogButtonWrapper";
import { getSettings } from "../../common/settingsProvider";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";

export interface ITrialRibbonProps {
    data: TrialInformationViewModel | undefined;
    onShowTrialsOverClick: () => void;
}

function TrialRibbon(props: ITrialRibbonProps) {
    const styles = useTrialRibbonStyles();
    const { translateString, translatePlanType } = useTranslation();
    const plansOnActiveTrial = props.data?.plansOnTrials?.filter((x) => !x.isExpired) ?? [];
    const settings = getSettings();
    const { customerId } = useUserContext();

    const purchasePrivacy = () => {
        let returnUrl = window.location.href;
        const url = settings.customerAdminWeb + `/purchase?productCustomerId=${customerId}&returnUrl=${returnUrl}`;
        window.location.href = url;
    };

    function getOnePlanContent(plan: TrialPlanViewModel) {
        return (
            <>
                <DotLegalHeader fontWeight={300} color="white" marginBottom={0} headerStyle={"extraSmall"}>
                    <Trans
                        i18nKey={"trialRibbonWithOnePlan"}
                        values={{
                            days: plan.trialDaysRemaining,
                            plan: getPlans(),
                            daysText: plan.trialDaysRemaining === 1 ? translateString("day").toLowerCase() : translateString("days").toLowerCase(),
                        }}
                    />
                </DotLegalHeader>
                &nbsp;
                {getUpgradeButton(translateString("clickToUpgrade"))}
            </>
        );
    }

    function getMultiplePlansContent() {
        return (
            <>
                <Box component={"p"} sx={styles.link} onClick={props.onShowTrialsOverClick}>
                    {translateString("clickHere")}
                </Box>
                &nbsp;
                <DotLegalHeader fontWeight={300} color="white" marginBottom={0} headerStyle={"extraSmall"}>
                    <Trans
                        i18nKey={"trialRibbonWithMultiplePlans"}
                        values={{
                            plans: getPlans(),
                        }}
                    />
                </DotLegalHeader>
                &nbsp;
                {getUpgradeButton(translateString("upgradeImmediately").toLowerCase())}
            </>
        );
    }

    function getPlans() {
        let numberOfPlans = plansOnActiveTrial.length;

        if (numberOfPlans === 1) {
            return translatePlanType(plansOnActiveTrial[0].planType);
        } else if (numberOfPlans === 2) {
            return `${translatePlanType(plansOnActiveTrial[0].planType)} ${translateString("and")} ${translatePlanType(plansOnActiveTrial[1].planType)}`;
        } else {
            return `${plansOnActiveTrial
                .slice(0, numberOfPlans - 1)
                .map((plan) => translatePlanType(plan.planType))
                .join(", ")} ${translateString("and")} ${translatePlanType(plansOnActiveTrial[numberOfPlans - 1].planType)}`;
        }
    }

    function getUpgradeButton(btnText: string) {
        return (
            <Box onClick={() => purchasePrivacy()} component={"p"} sx={styles.link}>
                {btnText}
            </Box>
        );
    }

    return plansOnActiveTrial.length > 0 ? (
        //pdfRibbon skal være en del af className, da den bruges til export.
        <Box sx={styles.ribbon} className={`pdfRibbon`}>
            {plansOnActiveTrial.length === 1 ? getOnePlanContent(plansOnActiveTrial[0]) : getMultiplePlansContent()}
        </Box>
    ) : null;
}

export default TrialRibbon;
