import React from "react";
import { useAddEditDataTypeDialog } from "./AddEditDataTypeDialog.hooks.ts";
import { DotLegalDialog, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation.ts";
import { DataType } from "./DataTypesList.types.ts";

export interface IAddEditDataTypeDialogProps {
    isAdd: boolean;
    toUpdate: DataType | undefined;
    onDialogClose: () => void;
    onSaved: () => void;
}

function AddEditDataTypeDialog(props: IAddEditDataTypeDialogProps) {
    const { translateString } = useTranslation();
    const { isSaving, validation, onModelChange, saveModel, onSave } = useAddEditDataTypeDialog(props);
    return (
        <DotLegalDialog
            size="sm"
            inProgress={isSaving}
            header={props.isAdd ? translateString("createDataType") : translateString("editDataType")}
            buttonOkText={props.isAdd ? translateString("create") : translateString("save")}
            open
            onDialogClose={props.onDialogClose}
            onOkClick={onSave}
        >
            <DotLegalTextField
                label={translateString("name")}
                value={saveModel.name}
                debounce={false}
                errorText={validation.getErrorsForDisplay("name")}
                onChange={(name) => onModelChange((model) => (model.name = name))}
            />
        </DotLegalDialog>
    );
}

export default AddEditDataTypeDialog;
