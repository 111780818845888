import { useState } from "react";
import { useUrlProvider } from "../../../useUrlProvider";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import { useSelectableDocumentsForLegalEntitiesQuery } from "../../../processingActivity/hooks/useSelectableDocumentQuery";
import { useQuery } from "react-query";
import { GeneralTabContentProps } from "./GeneralTabContent";
import { get } from "../../../common/api/apiShared";
import { SystemGeneralTabContentViewModel } from "./GeneralTabContent.types";

export function useGeneralTabContent(props: GeneralTabContentProps) {
    const [showProcessingActivitiesDialog, setShowProcessingActivitiesDialog] = useState(false);
    const [showPoliciesDialog, setShowPoliciesDialog] = useState(false);
    const [showDataProcessorAgreementDialog, setShowDataProcessorAgreementDialog] = useState(false);
    const [showNoteDialog, setShowNoteDialog] = useState(false);
    const [showSuppliers, setShowSuppliers] = useState(false);
    const [showSubSuppliers, setShowSubSuppliers] = useState(false);

    const { getLegalEntityUrl } = useUrlProvider();
    const { permissions } = useUserContext();

    const queryKey = "systemOverview" + props.systemId + "genereltab";
    const infoQuery = useQuery(`${queryKey}`, () => get<SystemGeneralTabContentViewModel>(`/SystemOverview/${props.systemId}/generaltab`));
    const privacyDocumentQuery = useSelectableDocumentsForLegalEntitiesQuery(true, infoQuery.data?.suppliers?.map((x) => x.id) ?? []);

    const getDocument = (documentId: string) => {
        const doc = privacyDocumentQuery.dataProcessorDocuments?.find((x) => x.id === documentId);
        return { name: doc ? doc.name : "", fileName: doc ? doc?.fileDownloadName : "", link: doc ? doc.link : "" };
    };

    return {
        isLoading: infoQuery.isLoading,
        infoData: infoQuery.data,
        showProcessingActivitiesDialog,
        setShowProcessingActivitiesDialog,
        showPoliciesDialog,
        setShowPoliciesDialog,
        showDataProcessorAgreementDialog,
        setShowDataProcessorAgreementDialog,
        showSubSuppliers,
        setShowSubSuppliers,
        getLegalEntityUrl,
        getDocument,
        permissions,
        showNoteDialog,
        setShowNoteDialog,
        showSuppliers,
        setShowSuppliers,
    };
}
