import { Box, IconButton } from "@mui/material";
import { closeDialogXIcon } from "../../../common/icons.tsx";
import { DotLegalButton, DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import Sparkle from "../../../common/images/Sparkle 4 x.png";
import React from "react";
import { useUpgradeCart } from "./UpgradeCart.hooks.ts";
import { useTranslation } from "../../../localization/useTranslation.ts";
import { useUpgradeCardStyles } from "./UpgradeCard.styles.ts";
import { getSettings } from "../../../common/settingsProvider.ts";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider.tsx";

function UpgradeCart() {
    const { translateString } = useTranslation();
    const { onHideUpgradeBox, showUpgradeBox } = useUpgradeCart();
    const { customerAdminWeb } = getSettings();
    const userContext = useUserContext();
    const styles = useUpgradeCardStyles();

    const redirectToPurchaseSite = () => {
        let returnUrl = window.location.href;
        window.location.href = customerAdminWeb + `/purchase?productCustomerId=${userContext.customerId}&returnUrl=${returnUrl}`;
    };

    return (
        showUpgradeBox && (
            <Box sx={styles.upgradePaper}>
                <Box className="closeButton" sx={{ position: "absolute", top: 6, right: 0 }}>
                    <IconButton aria-label="close" onClick={onHideUpgradeBox}>
                        {closeDialogXIcon}
                    </IconButton>
                </Box>

                <DotLegalHeader headerStyle={"extraSmall"} marginBottom={1} center color="white">
                    {translateString("unlockAdditionalFeatures").toUpperCase()}
                </DotLegalHeader>
                <DotLegalHeader headerStyle={"extraSmall"} marginBottom={1} center color="white" fontWeight={500}>
                    {translateString("upgradeYourPlan")}
                </DotLegalHeader>
                <Box sx={{ marginBottom: 2 }}>
                    <DotLegalButton
                        onClick={redirectToPurchaseSite}
                        buttonType={"primary"}
                        startIcon={<Box component={"img"} height={20} width={20} src={Sparkle} alt={""} sx={{ marginRight: -1 }} />}
                        endIcon={<Box component={"img"} height={20} width={20} src={Sparkle} alt={""} sx={{ marginRight: -1 }} />}
                    >
                        {translateString("upgradeNow")}
                    </DotLegalButton>
                </Box>
            </Box>
        )
    );
}

export default UpgradeCart;
