import { DotLegalHeader, DotLegalInfoDialog, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import useSendInternalAssessmentReminderDialogHooks from "./SendInternalAssessmentReminderDialog.hooks";
import { InternalAssessmentApiRow } from "../InternalAssessments.types";
import { Trans } from "react-i18next";
import { Box } from "@mui/material";
import React from "react";

export interface ISendInternalAssessmentReminderDialog {
    onCloseDialog: () => void;
    onReminderSent: () => void;
    selectedInternalAssessment: InternalAssessmentApiRow;
}

function SendInternalAssessmentReminderDialog(props: ISendInternalAssessmentReminderDialog) {
    const { translateString } = useTranslation();
    const { sendReminder, isSending, additionalMessage, setAdditionalMessage } = useSendInternalAssessmentReminderDialogHooks(props);

    return (
        <DotLegalInfoDialog
            header={translateString("sendReminder")}
            btnText={translateString("send")}
            content={
                <>
                    <Trans
                        i18nKey={"sendInternalAssessmentReminder"}
                        values={{
                            audit: props.selectedInternalAssessment.name,
                            respondent: props.selectedInternalAssessment.assignee.name,
                        }}
                        components={{
                            strong: <Box component="span" sx={(theme) => ({ fontWeight: "bold", color: theme.palette.primary.dark })}></Box>,
                            box: <Box component="span" sx={(theme) => ({ fontWeight: "bold", color: theme.palette.secondary.main })}></Box>,
                        }}
                    />

                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <DotLegalHeader marginTop={2} headerStyle={"small"} center marginBottom={1.5} fontWeight={400}>
                            {translateString("addAdditionMessageToManualAuditReminder")}
                        </DotLegalHeader>
                    </Box>
                    <DotLegalTextField
                        noMargin
                        label={translateString("freeTextField")}
                        value={additionalMessage ?? null}
                        onChange={setAdditionalMessage}
                        debounce={false}
                        multiline
                        rows={3}
                    />
                </>
            }
            onCloseDialog={props.onCloseDialog}
            onBtnClick={() => sendReminder()}
            inProgress={isSending}
        />
    );
}

export default SendInternalAssessmentReminderDialog;
