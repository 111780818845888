import { DocumentStatus, DotLegalDocumentType } from "../../documents/Documents.types";
import { BaseViewModel } from "../../system/systemOverview/SystemOverview.types";

export interface SelectedLegalEntityDocument {
    legalEntityId: string | undefined;
    documentId: string;
    name: string;
    isGroupEntity?: boolean;
}

export class LegalEntityDocumentUpdateModel {
    documentId: string;
    base64Data: string | undefined;
    fileName: string;
    name: string;
    type: DotLegalDocumentType | undefined;
    addDocumentToSharings: boolean;
    ownerId: string | null;
    link: string | undefined;
    isLink: boolean;
    status: DocumentStatus | undefined;
    allGroupEntities: boolean;
    groupEntityAccessIds: Array<string>;

    constructor(allGroupEntities: boolean, documentId?: string, name?: string, link?: string, type?: DotLegalDocumentType, ownerId?: string | null) {
        this.documentId = documentId ?? "";
        this.base64Data = undefined;
        this.fileName = "";
        this.name = name ?? "";
        this.type = type ?? undefined;
        this.addDocumentToSharings = false;
        this.ownerId = ownerId ?? null;
        this.link = link ?? "";
        this.isLink = link ? true : false;
        this.allGroupEntities = allGroupEntities;
        this.groupEntityAccessIds = [];
    }
}

export interface LegalEntityDocumentRow {
    documentId: string;
    name: string;
    type: DotLegalDocumentType;
    fileName: string;
    owner: BaseViewModel;
    link: string;
    version: number;
    dataId: string;
    status: DocumentStatus;
    allGroupEntities: boolean;
    groupEntities: Array<string>;
}

export class PolicyDocumentSaveModel {
    base64Data: string;
    fileName: string;
    name: string;
    link: string;
    isLink: boolean;

    constructor(name?: string, link?: string) {
        this.base64Data = "";
        this.fileName = "";
        this.name = name ?? "";
        this.link = link ?? "";
        this.isLink = link ? true : false;
    }
}
