import React from "react";
import DotLegalLink from "../../common/components/dotLegalLink/DotLegalLink.tsx";
import { useUrlProvider } from "../../useUrlProvider.ts";
import { exhaustiveGuard } from "../../common/utilities.ts";

export interface IQuestionnaireTemplateLinkProps {
    id: string;
    name: string;
    type: "audit" | "classification";
}

function QuestionnaireTemplateLink(props: IQuestionnaireTemplateLinkProps) {
    const urlProvider = useUrlProvider();

    function getUrl() {
        switch (props.type) {
            case "audit":
                return urlProvider.getQuestionnaireAuditOverview(props.id);
            case "classification":
                return urlProvider.getQuestionnaireVendorClassificationOverview(props.id);
            default:
                exhaustiveGuard(props.type);
        }
    }

    // Some day handle that user doesnt have access to questionnaire management
    return (
        <DotLegalLink bold linkColor={"primary"} to={getUrl()} stopPropagation>
            {props.name}
        </DotLegalLink>
    );
}

export default QuestionnaireTemplateLink;
