import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { get, put } from "../common/api/apiShared.ts";
import { InternalAssessmentApiModel, InternalAssessmentApiRow } from "./InternalAssessments.types.ts";
import { useStateUrlParamsArray } from "../common/hooks/useStateUrlParams.ts";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../localization/useTranslation.ts";

export default function useInternalAssessmentsHooks() {
    const snackbar = useDotLegalSnackbar();
    const { translateString } = useTranslation();

    const [showDialog, setShowDialog] = useState(false);
    const [selectedInternalAssessment, setSelectedInternalAssessment] = useState<InternalAssessmentApiRow | undefined>(undefined);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showCloseDialog, setShowCloseDialog] = useState(false);
    const [showReopenDialog, setShowReopenDialog] = useState(false);
    const [showChangeAssigneeDialog, setShowChangeAssigneeDialog] = useState(false);
    const [showChangeResponsibleDialog, setShowChangeResponsibleDialog] = useState(false);
    const [showReminderDialog, setShowReminderDialog] = useState(false);
    const [showToggleRemindersDialog, setShowToggleRemindersDialog] = useState(false);
    const [showEvaluationDialog, setShowEvaluationDialog] = useState(false);
    const [showNote, setShowNote] = useState(false);
    const [selectedTemplates, setSelectedTemplates] = useStateUrlParamsArray("templates", []);
    const [selectedAreas, setSelectedAreas] = useStateUrlParamsArray("areas", []);
    const [selectedStatus, setSelectedStatus] = useStateUrlParamsArray("status", []);
    const [selectedResponsibles, setSelectedResponsibles] = useStateUrlParamsArray("responsibles", []);
    const [selectedEvaluations, setSelectedEvaluations] = useStateUrlParamsArray("evaluations", []);

    const url = `/internalAssessments`;
    let { isLoading, data, refetch } = useQuery(url, () => get<InternalAssessmentApiModel>(url));

    const onInternalAssessmentSend = () => {
        setShowDialog(false);
        refetch();
    };

    const saveNoteMutation = useMutation(submitSaveNote);
    function submitSaveNote(note: string) {
        return put(`/internalAssessments/${selectedInternalAssessment!.id}/note`, note);
    }

    const saveNote = (note: string) => {
        saveNoteMutation.mutateAsync(note, {
            onSuccess: () => {
                snackbar.show(translateString("noteUpdated"));
                setShowNote(false);
                refetch!();
            },
        });
    };

    return {
        data,
        refetch,
        isLoading,
        showDialog,
        setShowDialog,
        onInternalAssessmentSend,
        selectedTemplates,
        setSelectedTemplates,
        selectedAreas,
        setSelectedAreas,
        selectedResponsibles,
        setSelectedResponsibles,
        selectedStatus,
        setSelectedStatus,
        selectedInternalAssessment,
        setSelectedInternalAssessment,
        setShowDeleteDialog,
        showDeleteDialog,
        setShowCloseDialog,
        showCloseDialog,
        setShowReopenDialog,
        showReopenDialog,
        setShowNote,
        showNote,
        setShowChangeAssigneeDialog,
        showChangeAssigneeDialog,
        setShowChangeResponsibleDialog,
        showChangeResponsibleDialog,
        showReminderDialog,
        setShowReminderDialog,
        setShowEvaluationDialog,
        showEvaluationDialog,
        selectedEvaluations,
        setSelectedEvaluations,
        setShowToggleRemindersDialog,
        showToggleRemindersDialog,
        saveNote,
        savingNote: saveNoteMutation.isLoading,
    };
}
