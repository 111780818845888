import { useMutation } from "react-query";
import { put } from "../../common/api/apiShared";
import { ICompleteDeclarationDialog } from "./CompleteDeclarationDialog";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { createElement } from "react";
import { Trans } from "react-i18next";
import { useUrlProvider } from "../../useUrlProvider";
import { useNavigate } from "react-router-dom";

export default function useCompleteDeclarationDialogHooks(props: ICompleteDeclarationDialog) {
    const snackbar = useDotLegalSnackbar();
    const navigate = useNavigate();
    const { getDeclarationUrl } = useUrlProvider();

    const completeDeclarationMutation = useMutation(completeDeclarationUrl);
    const onCompleteDeclarationClick = () => {
        completeDeclarationMutation.mutateAsync(undefined, {
            onSuccess: (response: any) => {
                if (response.success()) {
                    snackbar.show(createElement(Trans, { i18nKey: "itemCreatedSuccess", values: { name: props.selectedDeclaration.name } }));
                    props.onDialogClose();
                    navigate(getDeclarationUrl(response.value().id));
                }
            },
        });
    };

    return { onCompleteDeclarationClick, isSaving: completeDeclarationMutation.isLoading };

    function completeDeclarationUrl() {
        return put<string>(`/declaration/${props.selectedDeclaration.id}/complete`, null);
    }
}
