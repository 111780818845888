import { Box, styled } from "@mui/material";
import { useTranslation } from "../../../../localization/useTranslation";
import { useRiskIndicator } from "../../../../riskAssessment/riskIndicator/RiskIndicator.hooks";
import DotLegalLink from "../../dotLegalLink/DotLegalLink";
import { RiskLevel } from "../../../../legalEntity/legalEntityClassificationTab/LegalEntityClassificationTab.types";
import { warning } from "../../../../common/icons";
import { LegalEntityPageEnum } from "../../../../legalEntity/legalEntities/LegalEntities.types";
import { useUrlProvider } from "../../../../useUrlProvider";
import React from "react";
import { DotLegalHeader, DotLegalTooltip } from "@dotlegal/dotlegal-ui-components";
import { LegalEntityClassificationInformationViewModel } from "../../../../legalEntityAuditGroup/legalEntityAuditGroupDraftOverview/auditGroupLegalEntitySelector/AuditGroupLegalEntitySelector.types";

export interface IClassificationRisk {
    legalEntityId: string;
    legalEntityPage: LegalEntityPageEnum;
    classifications: Array<LegalEntityClassificationInformationViewModel>;
}

function ClassificationRisk(props: IClassificationRisk) {
    const { translateString } = useTranslation();
    const { getLegalEntityClassificationUrl, getLegalEntityUrl } = useUrlProvider();

    const { getRiskIcon } = useRiskIndicator();

    function getRisk(classification: LegalEntityClassificationInformationViewModel) {
        var icon = getIcon(classification);
        var name = getRiskName(classification);

        if (classification && !classification.submitted) {
            return (
                <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: 1 }}>
                    <Box mt={0.5}>{classification.name}:</Box>
                    {icon}
                    <Box sx={{ fontWeight: 700 }} mt={0.5} onClick={(e) => e.stopPropagation()}>
                        <DotLegalLink
                            linkColor={"primary"}
                            to={getLegalEntityClassificationUrl(props.legalEntityPage, props.legalEntityId, classification.responseId)}
                        >
                            <Box>{name}</Box>
                        </DotLegalLink>
                    </Box>
                </Box>
            );
        }
        return (
            <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: 1 }}>
                <Box>{classification.name}: </Box>
                {icon}
                {classification?.riskName ?? translateString("none")}
            </Box>
        );
    }

    function getIcon(classification: LegalEntityClassificationInformationViewModel | null) {
        if (classification && (classification.riskLevel || classification.riskLevel === 0)) {
            return getRiskIcon(classification.riskLevel);
        }

        if (classification && !classification.scoreEnabled && classification.submitted) return getRiskIcon(RiskLevel.None);

        return warning;
    }

    function getRiskName(classification: LegalEntityClassificationInformationViewModel | null) {
        if (classification && classification.name && classification.riskName) {
            return classification.name;
        }

        if (classification && !classification.scoreEnabled && classification.submitted) return translateString("none");

        return translateString("answerClassification");
    }

    function getContent() {
        const content = (
            <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                {getRisk(props.classifications[0])}
                {props.classifications.length > 1 && (
                    <Box ml={"4px"}>
                        <DotLegalHeader headerStyle={"extraSmall"} marginBottom={0}>
                            <DotLegalLink to={getLegalEntityUrl(props.legalEntityId)}> + {props.classifications.length - 1}</DotLegalLink>
                        </DotLegalHeader>
                    </Box>
                )}
            </Box>
        );

        if (props.classifications.length > 1) {
            return <DotLegalTooltip text={getToolTipContent()}>{content}</DotLegalTooltip>;
        }

        return content;
    }

    function getToolTipContent() {
        return (
            <Box>
                {props.classifications.map((x) => (
                    <React.Fragment key={x.id}> {getRisk(x)}</React.Fragment>
                ))}
            </Box>
        );
    }

    return <React.Fragment>{getContent()}</React.Fragment>;
}

export default ClassificationRisk;
