export enum TagEntityType {
    ProcessingActivity = 1,
    System = 2,
    LegalEntity = 3,
    Policy = 4,
    IncidentLog = 5,
    AnnualWheelActivity = 6,
    Task = 7,
}

export interface TagRelationCreateModel {
    tagId: string;
    entityId: string;
    entityType: TagEntityType;
}

export interface TagRelation {
    tagId: string;
    entityId: string;
    entityType: TagEntityType;
}
