import { DotLegalOverflowMenu, DotLegalOverflowMenuItem } from "@dotlegal/dotlegal-ui-components";
import React from "react";
import { download, eye } from "../../../icons";
import { Box, CircularProgress, IconButton } from "@mui/material";

export interface QuestionnaireReportOverflowButtonsProps {
    reportPdfUrl: string;
    reportPdfMenuItems: Array<DotLegalOverflowMenuItem>;
    showButtons: boolean;
    isDownloadingPdf: boolean;
}

function QuestionnaireReportOverflowButtons(props: QuestionnaireReportOverflowButtonsProps) {
    return (
        <>
            {props.showButtons ? (
                <Box
                    sx={{
                        display: "flex",
                        gap: 0.5,
                    }}
                >
                    <IconButton href={props.reportPdfUrl} target="_blank">
                        {eye}
                    </IconButton>
                    {props.isDownloadingPdf ? (
                        <CircularProgress sx={{ marginLeft: 1.2, marginTop: "4px" }} size={20} />
                    ) : (
                        <DotLegalOverflowMenu customOverflowIcon={download} items={props.reportPdfMenuItems} />
                    )}
                </Box>
            ) : undefined}
        </>
    );
}

export default QuestionnaireReportOverflowButtons;
