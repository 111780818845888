import { useTheme } from "@mui/material";
import { createSxStyles } from "../../../../common/createSxStyles";
import { useIsOnExtraSmallScreen } from "@dotlegal/dotlegal-ui-components";

export const useLegalEntityDescriptionStepStyle = () => {
    const theme = useTheme();
    const isExtraSmallScreen = useIsOnExtraSmallScreen();
    return createSxStyles({
        container: {
            display: "flex",
            flexDirection: isExtraSmallScreen ? "column" : "row",
        },
        subContainer: {
            width: "100%",
            paddingX: theme.spacing(2.5),
            paddingY: isExtraSmallScreen ? 0 : theme.spacing(2.5),
        },
    });
};
