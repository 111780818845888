import React from "react";
import { useTranslation } from "../../../localization/useTranslation";
import { useSystemSharingsTab } from "./SystemSharingsTab.hooks";
import DotLegalPaper from "../../../common/components/dotLegalPaper/DotLegalPaper";
import SharingsBaseTable from "../../../processingActivity/components/sharingsTable/SharingsTable";

export interface SystemSharingTabProps {
    systemId?: string;
}

function SystemSharingTab(props: SystemSharingTabProps) {
    const { translateString } = useTranslation();
    const { isLoading, data } = useSystemSharingsTab(props);

    return (
        <DotLegalPaper>
            <SharingsBaseTable data={data} isLoading={isLoading} emptyStateText={translateString("noSharingsOnSystem")} />
        </DotLegalPaper>
    );
}

export default SystemSharingTab;
