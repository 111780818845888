import { DotLegalDialog, DotLegalSelect, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import React from "react";
import { convertEnumToStringOrNull } from "../../../common/enumOperations";
import { useTranslation } from "../../../localization/useTranslation";
import { HelpTextSaveModel, HelpTextType } from "../helpTexts/HelpTexts.types";
import { useHelpTextDialogDataMapping } from "./HelpTextDialog.hooks";

export interface IHelpTextDialog {
    onDialogClose: () => void;
    onSave: () => void;
    usedHelpTextTypes: Array<HelpTextType>;
    selectedHelpText: HelpTextSaveModel;
}

function HelpTextDialog(props: IHelpTextDialog) {
    const { translateString } = useTranslation();
    const { typeOptions, saveModel, savePolicy, validation, isSaving, isEdit, onContentChange, onHeaderChange, onTypeChange } =
        useHelpTextDialogDataMapping(props);

    validation?.check(saveModel);
    return (
        <DotLegalDialog
            size="sm"
            inProgress={isSaving}
            buttonOkText={isEdit ? translateString("save") : translateString("create")}
            header={isEdit ? translateString("editHelpText") : translateString("createHelpText")}
            onOkClick={async () => {
                savePolicy();
            }}
            onDialogClose={props.onDialogClose}
            open
            disableBackdropOnCloseIfValueChange={saveModel}
        >
            <React.Fragment>
                <DotLegalTextField
                    debounce={false}
                    size="small"
                    label={translateString("header")}
                    value={saveModel?.header ?? ""}
                    errorText={validation?.getErrorsForDisplay("header")}
                    onChange={(header) => onHeaderChange(header)}
                />

                <DotLegalTextField
                    debounce={false}
                    size="small"
                    multiline
                    rows={5}
                    label={translateString("content")}
                    value={saveModel?.content ?? ""}
                    errorText={validation?.getErrorsForDisplay("content")}
                    onChange={(content) => onContentChange(content)}
                />

                <DotLegalSelect
                    label={translateString("type")}
                    options={typeOptions}
                    placeholder={""}
                    disableClearable
                    errorText={validation?.getErrorsForDisplay("type")}
                    selectedItem={convertEnumToStringOrNull(saveModel?.type)}
                    onChange={(type) => onTypeChange(type!)}
                    noOptionsLabel={translateString("noOptions")}
                />
            </React.Fragment>
        </DotLegalDialog>
    );
}

export default HelpTextDialog;
