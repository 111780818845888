import { createElement, useState } from "react";
import { Trans } from "react-i18next";
import { useMutation } from "react-query";
import { post, put } from "../../../common/api/apiShared";
import { getEnumValues } from "../../../common/enumOperations";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useValidator } from "../../../common/hooks/validation";
import { useTranslation } from "../../../localization/useTranslation";
import { HelpTextSaveModel, HelpTextType } from "../helpTexts/HelpTexts.types";
import { IHelpTextDialog } from "./HelpTextDialog";

export function useHelpTextDialogDataMapping(props: IHelpTextDialog) {
    const snackbar = useDotLegalSnackbar();
    const { translateHelpTextTypes, translateString } = useTranslation();
    const [saveModel, setSaveModel] = useState<HelpTextSaveModel>(props.selectedHelpText);

    const isEdit = props.selectedHelpText.id !== "";

    const validation = useValidator<HelpTextSaveModel>(
        (validators) => ({
            header: validators.validateNotEmpty((item) => item.header, translateString("header")),
            content: validators.validateNotEmpty((item) => item.content, translateString("content")),
            type: validators.validateNotEmpty((item) => item.type, translateString("type")),
        }),

        []
    );

    function save() {
        return post<string>("/helptexts/", saveModel);
    }

    const saveMutation = useMutation(save, {
        onSuccess: (response: any) => {
            props.onDialogClose();
            props.onSave();
            snackbar.show(createElement(Trans, { i18nKey: "helpTextCreated", values: { name: saveModel?.header } }));
        },
    });

    function update() {
        return put<string>("/helptexts/" + saveModel.id, saveModel);
    }

    const editMutation = useMutation(update, {
        onSuccess: (response: any) => {
            snackbar.show(translateString("helpTextUpdated"));
            props.onDialogClose();
            props.onSave();
        },
    });

    const saveHelpText = async () => {
        if (validation.anyHasErrors) {
            validation.setShowErrors(true);
            return;
        }

        if (isEdit) {
            await editMutation.mutateAsync();
        } else {
            await saveMutation.mutateAsync();
        }
    };

    var typeOptions = getEnumValues(HelpTextType)
        .filter((x) => !props.usedHelpTextTypes.includes(x) || props.selectedHelpText.type === x)
        .map((x) => {
            return { id: x.toString(), name: translateHelpTextTypes(x.toString()) };
        });

    function onHeaderChange(value: string) {
        let tempViewModel = { ...saveModel! };
        tempViewModel.header = value;
        setSaveModel(tempViewModel);
    }

    function onContentChange(value: string) {
        let tempViewModel = { ...saveModel! };
        tempViewModel.content = value;
        setSaveModel(tempViewModel);
    }

    function onTypeChange(type: string) {
        let tempViewModel = { ...saveModel! };
        tempViewModel.type = Number(type);
        setSaveModel(tempViewModel);
    }

    return {
        typeOptions,
        saveModel,
        setSaveModel,
        savePolicy: saveHelpText,
        validation,
        isSaving: saveMutation.isLoading,
        isEdit,
        onHeaderChange,
        onContentChange,
        onTypeChange,
    };
}
