import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { get } from "../../../common/api/apiShared";
import { useStateLocalStorage } from "@dotlegal/dotlegal-ui-components";
import { DeletionPeriodViewModel } from "./DeletionPeriods.types";

export function useDeletionPeriodsHooks() {
    const queryClient = useQueryClient();
    const [showAllDeletionsPeriods, setShowAllDeletionsPeriods] = useStateLocalStorage("onlyActiveDeletionPeriods", false);
    const [showAddEditDialog, setShowAddEditDialog] = useState(false);
    const [showDeleteDeletionPeriodDialog, setShowDeleteDeletionPeriodDialog] = useState(false);
    const [selectedDeletionPeriod, setSelectedDeletionPeriod] = useState<DeletionPeriodViewModel | undefined>(undefined);
    const [showChangeStatusDialog, setShowChangeStausDialog] = useState(false);

    const queryPart = `?showAllDeletionPeriod=${showAllDeletionsPeriods}`;
    const url = `/ProcessingActivity/allDeletionPeriods${queryPart}`;
    const { isLoading, data } = useQuery(url, () => get<Array<DeletionPeriodViewModel>>(url));

    let deletionPeriods;
    if (data) {
        deletionPeriods = data.map((dp) => {
            return {
                id: dp.id,
                name: dp.name,
                description: dp.description,
                deletionPeriodType: dp.deletionPeriodType,
                deletionPeriodLength: dp.deletionPeriodLength,
                active: dp.active,
                isCustom: dp.isCustom,
            };
        });
    }

    const refetchDeletionPeriods = () => {
        queryClient.refetchQueries(url);
    };

    return {
        isLoading,
        deletionPeriods,
        showAllDeletionsPeriods,
        setShowAllDeletionsPeriods,
        showAddEditDialog,
        setShowAddEditDialog,
        showDeleteDeletionPeriodDialog,
        setShowDeleteDeletionPeriodDialog,
        selectedDeletionPeriod,
        setSelectedDeletionPeriod,
        refetchDeletionPeriods,
        showChangeStatusDialog,
        setShowChangeStausDialog,
    };
}
