import React from "react";
import ReactPlayer from "react-player";
import { DotLegalDialog } from "@dotlegal/dotlegal-ui-components";

export interface IDotLegalPlayer {
    url: string;
    onCloseDialog: () => void;
}

function DotLegalPlayer(props: IDotLegalPlayer) {
    return (
        <DotLegalDialog size="lg" hideOkButton buttonOkText={""} open onDialogClose={props.onCloseDialog} onOkClick={() => {}}>
            <ReactPlayer playing width="100%" height="100%" controls url={props.url}></ReactPlayer>
        </DotLegalDialog>
    );
}

export default DotLegalPlayer;
