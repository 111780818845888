import DotLegalDeleteDialogWrapper from "../../../common/components/dotLegalDeleteDialog/DotLegalDeleteDialogWrapper";
import { DeclarationControlViewModel } from "../../Declaration.types";
import useDeclarationControlDialogHooks from "./DeleteDeclarationControlDialog.hooks";

export interface IDeleteDeclarationControlDialog {
    onDialogClose: () => void;
    onDeleted: () => void;
    selectedControl: DeclarationControlViewModel;
}

function DeleteDeclarationControlDialog(props: IDeleteDeclarationControlDialog) {
    const { ondDeleteControlClick, inProgess } = useDeclarationControlDialogHooks(props);

    return (
        <DotLegalDeleteDialogWrapper
            itemForDeletion={props.selectedControl.name}
            open
            onDialogClose={props.onDialogClose}
            onOkClick={() => ondDeleteControlClick()}
            inProgress={inProgess}
        />
    );
}

export default DeleteDeclarationControlDialog;
