import { createElement } from "react";
import { Trans } from "react-i18next";
import { useMutation } from "react-query";
import { deleteHttp } from "../../../common/api/apiShared";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { IDeleteSecurityMeasureDialog } from "./DeleteSecurityMeasureDialog";

export function DeleteSecurityMeasureDialogDataMapping(props: IDeleteSecurityMeasureDialog) {
    const snackbar = useDotLegalSnackbar();

    const removeMutation = useMutation(remove);
    const removePurpose = () => {
        removeMutation.mutateAsync(undefined, {
            onSuccess: (response: any) => {
                props.onCloseDialog();

                if (response.success()) {
                    props.onSaveCorrect();
                    snackbar.show(createElement(Trans, { i18nKey: "securityMeasureDeleted", values: { name: props.selectedSecurityMeasure.name } }));
                } else {
                    snackbar.show(
                        createElement(Trans, { i18nKey: "securityMeasureNotDeleted", values: { name: props.selectedSecurityMeasure.name } }),
                        "warning"
                    );
                }
            },
        });
    };

    async function remove() {
        return await deleteHttp<{}>(`/securitymeasures/${props.selectedSecurityMeasure.id}`);
    }

    return {
        removePurpose,
        inProgess: removeMutation.isLoading,
    };
}
