import { useState } from "react";

import { DataStorageLocationSaveModel } from "./DataStorageLocationDialog.types";
import { IDataStorageLocationDialog } from "./DataStorageLocationDialog";

export function useDataStorageLocationDialogHooks(props: IDataStorageLocationDialog) {
    const [saveModel, setSaveModel] = useState<DataStorageLocationSaveModel>({
        storageLocations: props.storageLocations,
        supportLocations: props.supportLocations,
    });

    function onStorageLocationChange(storageLocations: Array<string>) {
        let temp = { ...saveModel };

        temp.storageLocations = storageLocations;
        setSaveModel(temp);
    }

    function onSupportLocationChange(supportLocations: Array<string>) {
        let temp = { ...saveModel };

        temp.supportLocations = supportLocations;
        setSaveModel(temp);
    }

    return {
        saveModel,
        onStorageLocationChange,
        onSupportLocationChange,
    };
}
