import { DotLegalDialog, DotLegalHeader, DotLegalSelect } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { Trans } from "react-i18next";
import React from "react";
import { Box } from "@mui/material";
import useCloseAuditDialgHooks from "./CloseAuditDialog.hooks";
import { setFirstLetterToLowerCase } from "../../../common/stringOperations";
import { LegalEntityAuditClosedReason } from "./CloseAuditDialog.types";
import { getEnumValues } from "../../../common/enumOperations";

export interface ICloseAuditDialog {
    onDialogClose: () => void;
    onAuditClosed: () => void;
    auditId: string;
    questionnaireName: string;
    deadline: Date;
}

function CloseAuditDialog(props: ICloseAuditDialog) {
    const { translateString, translateDate } = useTranslation();
    const { saveModel, onStatusChange, validation, onCloseClick, isSaving } = useCloseAuditDialgHooks(props);

    validation.check(saveModel);

    return (
        <DotLegalDialog
            buttonOkText={translateString("close")}
            header={translateString("closeAudit")}
            size="sm"
            open
            onDialogClose={props.onDialogClose}
            onOkClick={() => onCloseClick()}
            inProgress={isSaving}
            disableBackdropOnCloseIfValueChange={saveModel}
            okButtonDisabled={saveModel.status === undefined}
        >
            <React.Fragment>
                <DotLegalHeader fontWeight={400} center headerStyle="small">
                    <Trans
                        i18nKey={"closeDialogContent"}
                        values={{
                            name: props.questionnaireName.trim(),
                            deadline: translateDate(props.deadline),
                        }}
                        components={{
                            wrapper: (
                                <Box
                                    sx={(theme) => ({ color: theme.palette.secondary.main, fontWeight: theme.typography.fontWeightBold })}
                                    component="span"
                                ></Box>
                            ),
                        }}
                    />
                </DotLegalHeader>
                <DotLegalSelect
                    options={getEnumValues(LegalEntityAuditClosedReason).map((x) => {
                        var name = translateString(setFirstLetterToLowerCase(LegalEntityAuditClosedReason[x].toString()));
                        return { name: name, id: x.toString() };
                    })}
                    onChange={(value) => onStatusChange(Number(value))}
                    label={translateString("reason")}
                    noOptionsLabel=""
                    placeholder={translateString("reason")}
                    selectedItem={saveModel.status !== undefined ? saveModel.status.toString() : ""}
                    disableClearable
                    disableSearch
                    errorText={validation.getErrorsForDisplay("status")}
                />
            </React.Fragment>
        </DotLegalDialog>
    );
}

export default CloseAuditDialog;
