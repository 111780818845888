import { DotLegalDialog, DotLegalSelect, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { Box, Grid } from "@mui/material";
import {
    systemControlProcedureFrequencyOptions,
    systemControlProcedureTypeOptions,
    useSystemControlProcedureDialogHooks,
} from "./SystemControlProcedureDialog.hooks";
import CompletedControlProcedureCheck from "./completedControlProcedureCheck/CompletedControlProcedureCheck";
import { formatScore } from "../../../riskAssessment/riskIndicator/RiskIndicator";
import { usePlanContext } from "../../../auth/planProvider/PlanProvider";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";

export interface ISystemControlProcedureDialog {
    onDialogClose: () => void;
}

function SystemControlProcedureDialog(props: ISystemControlProcedureDialog) {
    const { translateString } = useTranslation();
    const { data, isLoading, onFrequencyChange, onTypeChange, onCompletedControlProcedureAdded, onCompletedControlProcedureRemoved } =
        useSystemControlProcedureDialogHooks();

    const { permissions } = useUserContext();
    return (
        <DotLegalDialog
            size="md"
            header={translateString("systemInternalControlProcedureHeader")}
            buttonOkText={translateString("close")}
            open
            onDialogClose={props.onDialogClose}
            onOkClick={props.onDialogClose}
            disableBackdropOnCloseIfValueChange={data}
        >
            <Box sx={{ marginLeft: 2, marginRight: 2 }}>
                <Grid container columnSpacing={2}>
                    <Grid item sm={6}>
                        <DotLegalSelect
                            options={systemControlProcedureTypeOptions(translateString)}
                            onChange={(type) => {
                                if (type) {
                                    onTypeChange(Number(type));
                                }
                            }}
                            selectedItem={data?.type ? data.type.toString() : undefined}
                            placeholder={translateString("controlProcedure")}
                            label={translateString("controlProcedure")}
                            noOptionsLabel={""}
                            isLoading={isLoading}
                            disableClearable
                            disabled={!permissions.systemPermissions.edit}
                        ></DotLegalSelect>
                    </Grid>
                    <Grid item sm={6}>
                        <DotLegalSelect
                            options={systemControlProcedureFrequencyOptions(translateString)}
                            onChange={(frequency) => {
                                if (frequency) {
                                    onFrequencyChange(Number(frequency));
                                }
                            }}
                            selectedItem={data?.frequency ? data.frequency.toString() : undefined}
                            placeholder={translateString("frequenzy")}
                            label={translateString("frequenzy")}
                            noOptionsLabel={""}
                            isLoading={isLoading}
                            disableClearable
                            disabled={!permissions.systemPermissions.edit}
                        ></DotLegalSelect>
                    </Grid>
                    {permissions.riskPermissions.read && (
                        <Grid item sm={6}>
                            <DotLegalTextField
                                label={translateString("risk")}
                                value={formatScore(data?.riskScore, translateString, undefined, undefined)}
                                debounce={false}
                                disabled
                            ></DotLegalTextField>
                        </Grid>
                    )}
                </Grid>

                <CompletedControlProcedureCheck
                    onCompletedCheckRemoved={onCompletedControlProcedureRemoved}
                    onCompletedCheckAdded={onCompletedControlProcedureAdded}
                    selectableDocuments={data?.documents!}
                    controlProducereId={data?.id!}
                    completedControls={data?.completedControls ?? []}
                    isLoading={isLoading}
                />
            </Box>
        </DotLegalDialog>
    );
}

export default SystemControlProcedureDialog;
