import React from "react";
import ReactMarkdown from "react-markdown";
import DotLegalFullScreenSpinner from "../../common/components/dotLegalFullScreenSpinner/DotLegalFullScreenSpinner";
import { useNotificationMarkDownContentDataMapping } from "./NotificationMarkDownContent.hooks";

export interface INotificationMarkDownContent {
    url: string;
}

function NotificationMarkDownContent(props: INotificationMarkDownContent) {
    const { content, isLoading } = useNotificationMarkDownContentDataMapping(props.url);

    return <React.Fragment>{isLoading ? <DotLegalFullScreenSpinner /> : <ReactMarkdown>{content}</ReactMarkdown>}</React.Fragment>;
}

export default NotificationMarkDownContent;
