import React from "react";
import { useTranslation } from "../../localization/useTranslation";
import PlanDialog from "../planDialog/PlanDialog";
import { DotLegalButton, DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import { Box } from "@mui/material";
import PlanDialogUpgradeButton from "../planDialogComponents/PlanDialogUpgradeButton";
import { useTrialInformationHook } from "../../trial/TrialInformation.hooks";
import { useTrialHook } from "../../trial/Trial.hooks";
import { TrialInformationViewModel } from "../../trial/Trial.types";
import TimeGlass from "./timeglass.svg?react";
import PlanStatusMessage from "../planStatusMessage/PlanStatusMessage.tsx";

export interface ITrialSoonToExpireDialog {
    data: TrialInformationViewModel | undefined;
}

function TrialSoonToExpireDialog(props: ITrialSoonToExpireDialog) {
    const { translateString } = useTranslation();
    const { showSoonDialog, closeExpiresSoonDialog, dontAskAgainExpiresSoonDialog } = useTrialHook(props.data);

    function getContent() {
        return (
            <React.Fragment>
                {props.data?.plansOnTrials.filter((x) => x.isAlmostExpired).map((x) => <PlanStatusMessage plan={x} key={x.planType} />)}

                <DotLegalHeader marginTop={3} marginBottom={3} fontWeight={500} center headerStyle="small">
                    {translateString("trialSoonToExpireContent")}
                </DotLegalHeader>
            </React.Fragment>
        );
    }

    function getButtons() {
        return (
            <React.Fragment>
                <PlanDialogUpgradeButton />
                <DotLegalButton onClick={dontAskAgainExpiresSoonDialog} btnLength={300} buttonType="secondary">
                    {translateString("dontAskAgain")}
                </DotLegalButton>
            </React.Fragment>
        );
    }

    return (
        <PlanDialog
            showDialog={showSoonDialog}
            onDialogClose={closeExpiresSoonDialog}
            content={getContent()}
            header={
                <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                    <Box component={"span"}>{translateString("trialSoonToExpireHeader")}</Box>
                    <TimeGlass />
                </Box>
            }
            buttons={getButtons()}
        ></PlanDialog>
    );
}

export default TrialSoonToExpireDialog;
