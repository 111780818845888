import { Box, Collapse, Grid, RadioGroup } from "@mui/material";
import React, { useEffect } from "react";
import { _arrayBufferToBase64 } from "../../../common/arrayBufferToBase64";
import { DotLegalCheckbox, DotLegalDialog, DotLegalRadioButton, DotLegalSelect, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { isNullOrWhitespace } from "../../../common/stringOperations";
import { DotLegalDocumentType } from "../../../documents/Documents.types";
import { useTranslation } from "../../../localization/useTranslation";
import { useAddLegalEntityDocumentHook } from "./AddLegalEntityDocumentDialog.hooks";
import DotLegalFileUploadWrapper from "../../../common/components/dotLegalFileUpload/DotLegalFileUploadWrapper";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import GroupEntityAcceesSelector from "../../groupEntityAccessSelector/GroupEntityAccessSelector";
import { usePlanContext } from "../../../auth/planProvider/PlanProvider";

export interface IAddLegalEntityDocumentDialogProps {
    onCloseDialog: () => void;
    isGroupEntity: boolean;
}

function AddLegalEntityDocumentDialog(props: IAddLegalEntityDocumentDialogProps) {
    const { translateString } = useTranslation();
    const { permissions } = useUserContext();
    const { dataProtectionFeatures } = usePlanContext();
    const {
        document,
        setDocument,
        documentTypes,
        inProgress,
        validation,
        validateDocumentForm,
        userOptions,
        userOptionsIsLoading,
        documentationOption,
        onDocumentationTypeChange,
        documentStatus,
        onGroupEntityAccessIdsChange,
    } = useAddLegalEntityDocumentHook(props);

    useEffect(() => {
        if (!permissions.canSetDocumentStatus) {
            let tempModel = { ...document! };
            tempModel.status = 1;
            setDocument(tempModel);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permissions.canSetDocumentStatus]);

    function getLinkComponent() {
        return (
            <DotLegalTextField
                label={translateString("insertLink")}
                value={document?.link ?? ""}
                debounce={false}
                errorText={validation?.getErrorsForDisplay("link")}
                onChange={(value) => {
                    let tempModel = { ...document! };
                    tempModel.link = value;
                    setDocument(tempModel);
                }}
            ></DotLegalTextField>
        );
    }

    validation?.check(document);

    const getAddToSharingsCheckbox = (checked: boolean, label: string) => {
        return (
            <Box marginTop={2} marginBottom={2}>
                <DotLegalCheckbox
                    label={label}
                    checked={checked}
                    onChange={(add) => {
                        let tempModel = { ...document! };
                        tempModel.addDocumentToSharings = add;
                        setDocument(tempModel);
                    }}
                />
            </Box>
        );
    };

    return (
        <DotLegalDialog
            header={translateString("uploadDocument")}
            size="md"
            isLoading={false}
            buttonOkText={translateString("save")}
            open
            onDialogClose={() => props.onCloseDialog()}
            onOkClick={() => validateDocumentForm()}
            inProgress={inProgress}
            disableBackdropOnCloseIfValueChange={document}
        >
            <Grid container columnSpacing={2}>
                <Grid item md={12}>
                    <DotLegalTextField
                        label={translateString("name")}
                        value={document?.name!}
                        debounce={false}
                        onChange={(text) => {
                            let tempModel = { ...document! };
                            tempModel.name = text;
                            setDocument(tempModel);
                        }}
                        errorText={validation?.getErrorsForDisplay("name")}
                    />
                </Grid>

                <Grid item md={12}>
                    <DotLegalSelect
                        selectedItem={document?.status ? document?.status.toString() : undefined}
                        options={documentStatus}
                        placeholder={translateString("status")}
                        label={translateString("status")}
                        disableClearable
                        onChange={(status) => {
                            let tempModel = { ...document! };
                            tempModel.status = Number(status);
                            setDocument(tempModel);
                        }}
                        errorText={validation?.getErrorsForDisplay("status")}
                        noOptionsLabel={translateString("noOptions")}
                        disabled={!permissions.canSetDocumentStatus}
                    />
                </Grid>

                <Grid item md={6}>
                    <DotLegalSelect
                        selectedItem={document?.type ? document?.type.toString() : undefined}
                        options={documentTypes}
                        placeholder={translateString("type")}
                        label={translateString("type")}
                        disableClearable
                        onChange={(type) => {
                            let tempModel = { ...document! };
                            tempModel.type = Number(type);
                            tempModel.addDocumentToSharings = false;
                            setDocument(tempModel);
                        }}
                        errorText={validation?.getErrorsForDisplay("type")}
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>

                <Grid item md={6}>
                    <DotLegalSelect
                        selectedItem={document?.ownerId}
                        options={userOptions}
                        placeholder={translateString("owner")}
                        label={translateString("owner")}
                        onChange={(owner) => {
                            let tempModel = { ...document! };
                            tempModel.ownerId = owner;

                            setDocument(tempModel);
                        }}
                        isLoading={userOptionsIsLoading}
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>
            </Grid>

            {dataProtectionFeatures.dataProcessingAgreements && (
                <>
                    {document?.type === DotLegalDocumentType.DataProcessorAgreement &&
                        getAddToSharingsCheckbox(document?.addDocumentToSharings, translateString("adddataProcessorAgreementOnDataProcessor"))}

                    {document?.type === DotLegalDocumentType.TIA &&
                        getAddToSharingsCheckbox(document?.addDocumentToSharings, translateString("addTransferImpactAssessmentOnDataProcessor"))}

                    {document?.type === DotLegalDocumentType.JointDataControllerAgreement &&
                        getAddToSharingsCheckbox(document?.addDocumentToSharings, translateString("addJointDataControllerAgreementOnJointDataControllers"))}

                    {document?.type === DotLegalDocumentType.DisclosureAgreement &&
                        getAddToSharingsCheckbox(document?.addDocumentToSharings, translateString("addDisclosureAgreementOnDisclosures"))}
                </>
            )}

            <Box marginTop={2}>
                <RadioGroup
                    row
                    value={documentationOption}
                    onChange={(e) => {
                        onDocumentationTypeChange(Number((e.target as HTMLInputElement).value));
                    }}
                >
                    <DotLegalRadioButton value={1} labelPlacement="end" label={translateString("uploadDocument")} />
                    <DotLegalRadioButton value={0} labelPlacement="end" label={translateString("insertLink")} />
                </RadioGroup>

                <>
                    <Collapse in={documentationOption === 1}>
                        {
                            <Box sx={{ height: 70 }}>
                                <DotLegalFileUploadWrapper
                                    errorText={validation?.getErrorsForDisplay("fileName")}
                                    files={isNullOrWhitespace(document?.fileName!) ? [] : [document?.fileName!]}
                                    onFilesChanged={async (file) => {
                                        let tempDoc = { ...document! };
                                        tempDoc.base64Data = file ? await _arrayBufferToBase64(file[0].data) : "";
                                        tempDoc.fileName = file ? file[0].fileName : "";

                                        if (isNullOrWhitespace(tempDoc.name)) {
                                            tempDoc.name = tempDoc.fileName.substring(0, tempDoc.fileName.lastIndexOf("."));
                                        }

                                        setDocument(tempDoc);
                                    }}
                                    isLoading={false}
                                />
                            </Box>
                        }
                    </Collapse>
                    <Collapse in={documentationOption === 0}>
                        <Box sx={{ height: 70, "& > *": { marginTop: 0 } }}>{getLinkComponent()}</Box>
                    </Collapse>
                </>
            </Box>

            {!props.isGroupEntity && (
                <GroupEntityAcceesSelector
                    groupEntityAccessIds={document?.groupEntityAccessIds}
                    onGroupEntityAccessIdsChange={onGroupEntityAccessIdsChange}
                    allGroupEntities={document?.allGroupEntities}
                    isEdit={false}
                    errorMsg={validation?.getErrorsForDisplay("groupEntityAccessIds")}
                />
            )}
        </DotLegalDialog>
    );
}

export default AddLegalEntityDocumentDialog;
