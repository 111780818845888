import { useState } from "react";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { AddNotificationsSaveModel } from "./AddNotificationsSaveModel";
import { isNullOrWhitespace } from "../../common/stringOperations";
import { useTranslation } from "../../localization/useTranslation";
import { useMutation } from "react-query";
import { post } from "../../common/api/apiShared";
import { IAddNotificationsDialogProps } from "./AddNotificationsDialog";

export function useAddNotifications(props: IAddNotificationsDialogProps) {
    const snackbar = useDotLegalSnackbar();
    const { translateString } = useTranslation();
    const [errors, setErrors] = useState({ name: "", englishFile: "", danishFile: "" });
    const [saveModel, setSaveModel] = useState<AddNotificationsSaveModel | undefined>();
    const saveMutation = useMutation(save);
    const upload = () => {
        saveMutation.mutateAsync(undefined, {
            onSuccess: () => {
                snackbar.show(translateString("notificationsAdded"));
                props.onCloseDialog();
            },
        });
    };

    function save() {
        return post("/notifications/", saveModel);
    }

    function validate() {
        let formValid = true;
        let tempErrors = { name: "", englishFile: "", danishFile: "" };

        if (isNullOrWhitespace(saveModel?.name)) {
            formValid = false;
            tempErrors.name = translateString("fieldMandatory");
        }

        if (isNullOrWhitespace(saveModel?.englishFileName)) {
            formValid = false;
            tempErrors.englishFile = translateString("fieldMandatory");
        }

        if (isNullOrWhitespace(saveModel?.danishFileName)) {
            formValid = false;
            tempErrors.danishFile = translateString("fieldMandatory");
        }

        if (formValid) {
            upload();
        } else {
            setErrors(tempErrors);
        }
    }

    return {
        inProgress: saveMutation.isLoading,
        validate,
        errors,
        saveModel,
        setSaveModel,
    };
}
