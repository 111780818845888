import { DotLegalDialog, DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import React from "react";
import { Box } from "@mui/material";
import { useTranslation } from "../../localization/useTranslation";
import useReopenInternalAssessmentHooks from "./ReopenInternalAssessmentDialog.hooks";
import { Trans } from "react-i18next";

export interface IReopenInternalAssessmentDialog {
    onDialogClose: () => void;
    onInternalAssessmentReopened: () => void;
    internalAssessmentId: string;
    name: string;
    deadline: Date;
    isSubmitted: boolean;
}

function ReopenInternalAssessmentDialog(props: IReopenInternalAssessmentDialog) {
    const { translateString, translateDate } = useTranslation();

    const { onReopenClick, isSaving } = useReopenInternalAssessmentHooks(props);

    return (
        <DotLegalDialog
            buttonOkText={translateString("reopen")}
            header={props.isSubmitted ? translateString("reopenCompletedInternalAssessment") : translateString("reopenInternalAssessment")}
            size="sm"
            open
            onDialogClose={props.onDialogClose}
            onOkClick={() => onReopenClick()}
            inProgress={isSaving}
        >
            <React.Fragment>
                {props.isSubmitted ? (
                    <DotLegalHeader fontWeight={400} center headerStyle="small">
                        <Trans
                            i18nKey={"reopenCompletedInternalAssessmentContent"}
                            values={{
                                name: props.name.trim(),
                                deadline: translateDate(props.deadline),
                            }}
                            components={{
                                wrapper: (
                                    <Box
                                        sx={(theme) => ({ color: theme.palette.secondary.main, fontWeight: theme.typography.fontWeightBold })}
                                        component="span"
                                    ></Box>
                                ),
                            }}
                        />
                    </DotLegalHeader>
                ) : (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <DotLegalHeader fontWeight={400} center headerStyle="small">
                            {translateString("reopenInternalAssessmentContent")}
                        </DotLegalHeader>
                    </Box>
                )}
            </React.Fragment>
        </DotLegalDialog>
    );
}

export default ReopenInternalAssessmentDialog;
