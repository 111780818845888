import { QuestionType, ScenarioType } from "./RiskAssessment.types";
import { RiskAssessmentArea, RiskComplianceArea } from "./riskAssessments/RiskAssessments.types";

export function useRiskAssessmentItemsProvider() {
    function getAllQuestions(riskAssessmentArea: RiskAssessmentArea, riskComplianceArea: RiskComplianceArea): Array<QuestionType> {
        if (riskAssessmentArea === RiskAssessmentArea.ProcessingActivities && riskComplianceArea === RiskComplianceArea.GDPR) {
            return Object.keys(QuestionType)
                .filter((q) => isNaN(Number(q)) && q.startsWith("ProcessingActivity"))
                .map((q) => QuestionType[q as keyof typeof QuestionType])
                .filter((q) => q !== QuestionType.CustomQuestion);
        } else if (riskAssessmentArea === RiskAssessmentArea.ProcessingActivities && riskComplianceArea === RiskComplianceArea.NIS2) {
            return Object.keys(QuestionType)
                .filter((q) => isNaN(Number(q)) && q.startsWith("ProcessingActivityNis2"))
                .map((q) => QuestionType[q as keyof typeof QuestionType])
                .filter((q) => q !== QuestionType.CustomQuestion);
        } else if (riskAssessmentArea === RiskAssessmentArea.Systems && riskComplianceArea === RiskComplianceArea.GDPR) {
            return Object.keys(QuestionType)
                .filter((q) => isNaN(Number(q)) && q.startsWith("System"))
                .map((q) => QuestionType[q as keyof typeof QuestionType])
                .filter((q) => q !== QuestionType.CustomQuestion);
        } else if (riskAssessmentArea === RiskAssessmentArea.Systems && riskComplianceArea === RiskComplianceArea.NIS2) {
            return Object.keys(QuestionType)
                .filter((q) => isNaN(Number(q)) && q.startsWith("SystemNis2"))
                .map((q) => QuestionType[q as keyof typeof QuestionType])
                .filter((q) => q !== QuestionType.CustomQuestion);
        } else {
            throw new Error(`Unknown value RiskAssessmentArea: ${riskAssessmentArea} and RiskComplianceArea: ${riskComplianceArea}`);
        }
    }

    function getScenarios(): Array<ScenarioType> {
        let strings = Object.keys(ScenarioType);
        return strings.filter((q) => isNaN(Number(q))).map((q) => ScenarioType[q as keyof typeof ScenarioType]);
    }

    return {
        getScenarios,
        getAllQuestions,
    };
}
