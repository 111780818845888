import React from "react";
import { useRiskBusinessAreas } from "./RIskBusinessAreas.styles";
import { Grid } from "@mui/material";
import { RiskBusinessArea } from "./RiskBusinessArea.types";
import { DotLegalChip } from "@dotlegal/dotlegal-ui-components";

export interface IRiskBusinessAreas {
    businessAreas: Array<RiskBusinessArea>;
}

export default function RiskBusinessAreas(props: IRiskBusinessAreas) {
    const styles = useRiskBusinessAreas();

    return (
        <Grid container spacing={1} justifyContent={"center"} sx={styles.chip}>
            {props.businessAreas.map((businessArea) => {
                return (
                    <Grid key={businessArea.name} item>
                        {" "}
                        <DotLegalChip value={businessArea.name} color={businessArea.color} />
                    </Grid>
                );
            })}
        </Grid>
    );
}
