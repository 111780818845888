import { useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";

export const useSystemStepStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        container: {
            width: "100%",
            marginBottom: theme.spacing(3),
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        systemBox: {
            width: "100%",
            display: "flex",
            flexDirection: "column",
        },
        systemHeader: {
            textAlign: "center",
            fontSize: theme.typography.pxToRem(14),
            color: theme.palette.primary.dark,
            fontWeight: theme.typography.fontWeightBold,
        },
        addSystem: {
            marginTop: theme.spacing(3),
            display: "flex",
            justifyContent: "center",
        },
        addButton: {
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.grey["400"],
            textTransform: "capitalize",
            height: theme.spacing(6),
            marginTop: theme.spacing(4),
            alignSelf: "end",
        },
        divider: {
            backgroundColor: theme.palette.primary.light,
            position: "absolute",
            left: theme.spacing(15),
            right: theme.spacing(15),
        },
        collapse: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        collapseHeaderText: {
            color: theme.palette.primary.dark,
            fontWeight: theme.typography.fontWeightBold,
        },
        checkboxContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: theme.spacing(1),
        },
        error: {
            fontSize: theme.typography.pxToRem(12),
            color: theme.palette.error.main,
            textAlign: "center",
            marginTop: theme.spacing(1),
        },
    });
};
