import React from "react";
import { useTranslation } from "../../../localization/useTranslation";
import { SelectableSecurityMeasure } from "../../../processingActivity/ProcessingActivity.types";
import DotLegalMultiSelectWithButton from "../dotLegalSelectWithButton/DotLegalMultiSelectWithButton";
import DotLegalStepHeader from "../dotLegalStepHeader/DotLegalStepHeader";
import { SecurityMeasureModel } from "./DotLegalSecurityMeasure.types";
import DotLegalSecurityPeriodMet from "../dotLegalSecurityPeriodMet/DotLegalSecurityPeriodMet";
import { Typography } from "@mui/material";
import { useDotLegalSecurityMeasureStyles } from "./DotLegalSecurityMeasure.styles";

export interface IDotLegalSecurityMeasure {
    header?: string;
    isLoading: boolean;
    securityMeasureOptions: Array<SelectableSecurityMeasure>;
    securityMeasures: Array<SecurityMeasureModel>;
    onSecurityMeasureChange: (securityMeasures: Array<SecurityMeasureModel>) => void;
    canManageMasterData: boolean;
    setTypedSecurityMeasure: (value: string) => void;
    typedSecurityMeasure: string;
    setShowAddNewSecurityMeasure: (value: boolean) => void;
    tooltip?: string;
    disabled?: boolean;
}

export default function DotLegalSecurityMeasure(props: IDotLegalSecurityMeasure) {
    const { translateString } = useTranslation();
    const styles = useDotLegalSecurityMeasureStyles();

    const onSecurityMeasuresChanged = (securityMeasureIds: Array<string>) => {
        const updated = props.securityMeasures.filter((s) => securityMeasureIds.some((n) => s.securityMeasureId === n));
        let existingSecurityMeasureIds = props.securityMeasures.map((sm) => sm.securityMeasureId);
        const added: Array<SecurityMeasureModel> = securityMeasureIds
            .filter((s) => !existingSecurityMeasureIds.some((e) => e === s))
            .map((s) => {
                return { securityMeasureId: s, isMet: true };
            });

        props.onSecurityMeasureChange(updated.concat(added));
    };

    const GetSecurityMeasureContent = (model: SecurityMeasureModel) => {
        let securityMeasure = props.securityMeasureOptions!.find((d) => d.id === model.securityMeasureId);

        if (securityMeasure === undefined) return undefined;

        return (
            <div key={model.securityMeasureId}>
                <DotLegalSecurityPeriodMet
                    description={securityMeasure!.description}
                    name={securityMeasure!.name}
                    onchange={(value) => handleOnSecurityMeasuresMetChange(model.securityMeasureId, value)}
                    isMet={model.isMet}
                    disabled={props.disabled}
                />
            </div>
        );
    };

    const handleOnSecurityMeasuresMetChange = (id: string, isMet: boolean) => {
        props.securityMeasures!.find((r) => r.securityMeasureId === id)!.isMet = isMet;
        props.onSecurityMeasureChange([...props.securityMeasures!]);
    };

    const showSecurityMeasureContent = () => {
        return (
            props.securityMeasureOptions &&
            (globalSecurityMeasures.length > 0 || selectedSecurityMeasures.some((x) => props.securityMeasureOptions.find((y) => y.id === x.securityMeasureId)))
        );
    };

    let selectedSecurityMeasures: Array<SecurityMeasureModel> =
        props.securityMeasures && props.securityMeasureOptions
            ? props.securityMeasures!.filter(
                  (x) =>
                      !props
                          .securityMeasureOptions!.filter((s) => s.isGlobal)
                          .map((y) => y.id)
                          .includes(x.securityMeasureId)
              )
            : [];
    let globalSecurityMeasures = props.securityMeasureOptions?.filter((x) => x.isGlobal) ?? [];

    return (
        <React.Fragment>
            {props.header && <Typography sx={styles.header}>{props.header}</Typography>}

            <DotLegalMultiSelectWithButton
                label={translateString("securityMeasures")}
                placeholder={translateString("securityMeasures")}
                isLoading={props.isLoading}
                options={props.securityMeasureOptions?.filter((y) => !y.isGlobal)}
                chosenOptions={selectedSecurityMeasures.map((r) => r.securityMeasureId)}
                onChange={(securityMeasure) => onSecurityMeasuresChanged(securityMeasure)}
                toolTipText={props.tooltip ?? translateString("securitymeasuresHelperText")}
                onButtonClick={() => props.setShowAddNewSecurityMeasure(true)}
                buttonLabel={translateString("createNew")}
                hasPermission={props.canManageMasterData}
                onSearchValueChange={props.setTypedSecurityMeasure}
                searchValue={props.typedSecurityMeasure}
                disabled={props.disabled}
                noOptionsLabel={translateString("noOptions")}
            />

            {showSecurityMeasureContent() && (
                <React.Fragment>
                    <DotLegalStepHeader toolTipText={translateString("securityImplementationHelperText")} record={false} topMargin>
                        {translateString("securityMeasuresMetHeader")}
                    </DotLegalStepHeader>
                    <div>
                        {globalSecurityMeasures.map((g, index) => {
                            return (
                                <div key={g.id}>
                                    <DotLegalSecurityPeriodMet description={g.description} name={g.name} isMet disabled />
                                </div>
                            );
                        })}
                        {selectedSecurityMeasures.map((r) => {
                            return GetSecurityMeasureContent(r);
                        })}
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}
