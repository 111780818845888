import React from "react";
import { Box, Grid } from "@mui/material";
import { DotLegalTooltip, useIsOnSmallScreen } from "@dotlegal/dotlegal-ui-components";
import { getRiskImage } from "../../Images";
import { RiskAssessmentType } from "../../RiskAssessment.types";
import RiskIndicator from "../../riskIndicator/RiskIndicator";
import { useRiskBarometerStyles } from "./RiskBarometer.styles";

export interface IRiskBarometer {
    isLoading: boolean;
    riskAssessmentType: RiskAssessmentType;
    score: number | undefined;
    averageScore: number | undefined;
    riskTooltip?: string;
    averageTooltip?: string;
}

export default function RiskBarometer(props: IRiskBarometer) {
    const isOnSmallScreen = useIsOnSmallScreen();
    const styles = useRiskBarometerStyles(isOnSmallScreen);

    return (
        <Box sx={styles.imageContainer}>
            <Box sx={styles.imageContainerSubContainer}>
                {!props.isLoading && getRiskImage(props.score)}

                <Grid container sx={styles.scoreContainer}>
                    <DotLegalTooltip text={props.riskTooltip}>
                        <Grid item sm={6}>
                            <RiskIndicator
                                textSize={props.score !== null && props.score !== undefined && props.score !== 0 ? "lg" : "md"}
                                riskScoreTextColor="darkblue"
                                riskAssessmentType={props.riskAssessmentType}
                                score={props.score}
                            />
                        </Grid>
                    </DotLegalTooltip>
                    <DotLegalTooltip text={props.averageTooltip}>
                        <Grid item sm={6} sx={styles.averageScore}>
                            <RiskIndicator
                                textSize={props.score !== null && props.score !== undefined && props.score !== 0 ? "lg" : "md"}
                                riskScoreTextColor="darkblue"
                                riskAssessmentType={RiskAssessmentType.Average}
                                scoreType={props.riskAssessmentType}
                                score={props.averageScore}
                            />
                        </Grid>
                    </DotLegalTooltip>
                </Grid>
            </Box>
        </Box>
    );
}
