import { DotLegalDatePicker, DotLegalHeader, DotLegalSelect, DotLegalTable, ITableHeader } from "@dotlegal/dotlegal-ui-components";
import { Box, Grid, IconButton, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";
import EmptyStateSvg from "./completedControlEmptyState.svg?react";
import { removeIcon } from "../../../../common/icons";
import { CompletedControlProcedureViewModel, SystemControlProcedureType } from "../SystemControlProcedureDialog.types";
import useCompletedLegalEntityAuditHooks from "./CompletedControlProcedureCheck.hooks";
import { useTranslation } from "../../../../localization/useTranslation";
import { SelectableItem } from "../../../../processingActivity/ProcessingActivity.types";
import { setFirstLetterToLowerCase } from "../../../../common/stringOperations";
import { DotLegalEmptyState } from "@dotlegal/dotlegal-ui-components";
import { useCompletedControlProcedureCheck } from "./CompletedControlProcedureCheck.styles";
import { systemControlProcedureTypeOptions } from "../SystemControlProcedureDialog.hooks";
import { useUserContext } from "../../../../auth/userContextProvider/UserContextProvider";

export interface ICompletedControlProcedureCheck {
    isLoading: boolean;
    completedControls: Array<CompletedControlProcedureViewModel>;
    controlProducereId: string;
    selectableDocuments: Array<SelectableItem>;
    onCompletedCheckAdded: (model: CompletedControlProcedureViewModel) => void;
    onCompletedCheckRemoved: (completedControlProcedureId: string) => void;
}

function CompletedControlProcedureCheck(props: ICompletedControlProcedureCheck) {
    const { translateString, translateDate, getDateTimePickerLanguage } = useTranslation();
    const { permissions } = useUserContext();
    const styles = useCompletedControlProcedureCheck();

    const { saveModel, validation, saveCompletedControl, onCompletionDateChange, onTypeChange, onDocumentChange, onRemoveCompletedControlClick, saving } =
        useCompletedLegalEntityAuditHooks(props);

    function GetDocumentHeader() {
        const headers = Array<ITableHeader<CompletedControlProcedureViewModel>>();
        headers.push({ property: "completionDate", text: translateString("date"), align: "left", showOnMobile: true });
        headers.push({ property: "type", text: translateString("controlProcedure"), align: "left", showOnMobile: true });
        headers.push({ property: "documentation", text: translateString("documentation"), align: "left", showOnMobile: true });
        headers.push({ property: "id", text: "", align: "right", showOnMobile: true });

        return headers;
    }

    validation.check(saveModel);

    return (
        <React.Fragment>
            <Box sx={styles.container}>
                <Box sx={{ textTransform: "uppercase", marginLeft: -1 }}>
                    <DotLegalHeader marginTop={2} headerStyle="small" color="primary">
                        {translateString("completedControlProcedures")}
                    </DotLegalHeader>
                </Box>
                <Box sx={{ marginLeft: -1 }}>
                    {!props.isLoading && props.completedControls.length > 0 ? (
                        <DotLegalTable
                            headers={GetDocumentHeader()}
                            defaultOrderBy={"completionDate"}
                            defaultOrder={"asc"}
                            data={props.completedControls}
                            hidePagination
                            hideRowsPerPage
                            clickableRows={false}
                            isLoading={props.isLoading}
                            renderRow={(row, i) => (
                                <TableRow key={i}>
                                    <TableCell>{translateDate(row.completionDate)}</TableCell>
                                    <TableCell>{translateString(setFirstLetterToLowerCase(SystemControlProcedureType[row.type].toString()))}</TableCell>
                                    <TableCell>{row.documentation}</TableCell>
                                    <TableCell align="right">
                                        {permissions.systemPermissions.edit && (
                                            <IconButton onClick={() => onRemoveCompletedControlClick(row.id)}>{removeIcon}</IconButton>
                                        )}
                                    </TableCell>
                                </TableRow>
                            )}
                            paginationLabelOf={""}
                        />
                    ) : (
                        <DotLegalEmptyState icon={<EmptyStateSvg />} text={translateString("noCompletedControls")} />
                    )}
                </Box>
            </Box>

            <Box sx={{ textTransform: "uppercase" }}>
                <DotLegalHeader marginTop={2} marginBottom={0} headerStyle="small" color="primary">
                    {translateString("addCompletedControl")}
                </DotLegalHeader>
            </Box>

            <Grid container columnSpacing={2}>
                <Grid item sm={6}>
                    <Box marginTop={2}>
                        <DotLegalDatePicker
                            error={validation.getErrorsForDisplay("completionDate") !== undefined}
                            helperMessage={validation.getErrorsForDisplay("completionDate")}
                            label={translateString("dateForCompletion")}
                            onChange={(date: Date | null) => onCompletionDateChange(date ?? undefined)}
                            value={saveModel.completionDate ?? null}
                            disabled={!permissions.systemPermissions.edit}
                            language={getDateTimePickerLanguage()}
                            noMargin
                        />
                    </Box>
                </Grid>
                <Grid item sm={6}>
                    <DotLegalSelect
                        options={systemControlProcedureTypeOptions(translateString)}
                        onChange={(type) => {
                            if (type) {
                                onTypeChange(Number(type));
                            }
                        }}
                        placeholder={translateString("controlProcedure")}
                        selectedItem={saveModel?.type ? saveModel.type.toString() : undefined}
                        label={translateString("controlProcedure")}
                        noOptionsLabel={""}
                        errorText={validation.getErrorsForDisplay("type")}
                        disableClearable
                        disabled={!permissions.systemPermissions.edit}
                    ></DotLegalSelect>
                </Grid>
                <Grid item sm={6}>
                    <DotLegalSelect
                        options={props.selectableDocuments}
                        onChange={(document) => onDocumentChange(document ?? undefined)}
                        placeholder={translateString("documentation")}
                        label={translateString("documentation")}
                        noOptionsLabel={translateString("noOptions")}
                        selectedItem={saveModel?.documentId}
                        disabled={!permissions.systemPermissions.edit}
                    ></DotLegalSelect>
                </Grid>
            </Grid>

            {permissions.systemPermissions.edit && (
                <Typography
                    sx={{ ...styles.addLink, "&:hover": !saving ? { cursor: "pointer", textDecoration: "underline" } : undefined, opacity: saving ? 0.5 : 1 }}
                    onClick={() => {
                        if (!saving) {
                            saveCompletedControl();
                        }
                    }}
                >
                    {translateString("addControlProcedureToList")}
                </Typography>
            )}
        </React.Fragment>
    );
}

export default CompletedControlProcedureCheck;
