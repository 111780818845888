import React from "react";
import { AnnualWheelNameDescriptionSubtasksFormModel } from "./AnnualWheelNameDescriptionSubtasks.types.ts";
import { DotLegalHeader, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import AnnualWheelSubTasks from "../../AnnualWheelSubTasks.tsx";
import { useTranslation } from "../../../localization/useTranslation.ts";

export interface IAnnualWheelNameDescriptionSubtasksProps {
    saveModel: AnnualWheelNameDescriptionSubtasksFormModel;
    onChange: (saveModel: AnnualWheelNameDescriptionSubtasksFormModel) => void;
    showError: boolean;
}

function AnnualWheelNameDescriptionSubtasks(props: IAnnualWheelNameDescriptionSubtasksProps) {
    const { translateString } = useTranslation();
    return (
        <>
            <DotLegalHeader headerStyle="small" marginTop={2} marginBottom={0.5}>
                {translateString("createActivityName")}
            </DotLegalHeader>

            <DotLegalTextField
                noMargin
                debounce={false}
                label=""
                value={props.saveModel.name}
                onChange={(name) => {
                    props.onChange(new AnnualWheelNameDescriptionSubtasksFormModel(name, props.saveModel.description, props.saveModel.subTasks));
                }}
                errorText={props.showError && props.saveModel.isInValid() ? translateString("fieldMandatory") : undefined}
            />
            <DotLegalHeader headerStyle="small" marginTop={4} marginBottom={0.5}>
                {translateString("createActivityDescription")}
            </DotLegalHeader>
            <DotLegalTextField
                noMargin
                multiline
                rows={5}
                debounce
                label=""
                value={props.saveModel.description}
                onChange={(description) => {
                    props.onChange(new AnnualWheelNameDescriptionSubtasksFormModel(props.saveModel.name, description, props.saveModel.subTasks));
                }}
            />

            <AnnualWheelSubTasks
                subTasks={props.saveModel?.subTasks}
                onChange={(subTasks) => {
                    props.onChange(new AnnualWheelNameDescriptionSubtasksFormModel(props.saveModel.name, props.saveModel.description, subTasks));
                }}
            />
        </>
    );
}

export default AnnualWheelNameDescriptionSubtasks;
