import React, { useState } from "react";
import { DotLegalCheckbox, DotLegalDialog } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation.ts";
import { DataCategoryViewModel } from "../../DataCategoriesStep/DataCategoriesStep.types.ts";
import { DataType } from "../../../masterData/dataCategory/dataTypes/DataTypesList.types.ts";
import { useMutation, useQueryClient } from "react-query";
import { put } from "../../../common/api/apiShared.ts";
import { DataCategoryDataTypes, ProcessingActivityModel } from "../../ProcessingActivity.types.ts";
import { Box } from "@mui/material";

export interface IDataCategoryBoxDialogProps {
    processingActivityId?: string;
    dataCategory: DataCategoryViewModel;
    allDataTypes: Array<DataType> | undefined;
    onClose: () => void;
    onSave?: (dataCategoryDataTypes: DataCategoryDataTypes) => void;
}

function DataCategoryBoxDialog(props: IDataCategoryBoxDialogProps) {
    const [selectedDataTypes, setSelectedDataTypes] = useState<Array<string>>(props.dataCategory.selectedDataTypeIds);
    const { translateString } = useTranslation();
    const saveMutation = useMutation(save);
    const queryClient = useQueryClient();
    const onCheckedChanged = (dataTypeId: string) => {
        const existing = selectedDataTypes.find((s) => s === dataTypeId);
        if (existing) {
            setSelectedDataTypes(selectedDataTypes.filter((s) => s !== dataTypeId));
        } else {
            setSelectedDataTypes([...selectedDataTypes, dataTypeId]);
        }
    };

    function save() {
        return put<ProcessingActivityModel>(
            `/ProcessingActivity/${props.processingActivityId}/standardDataCategory/${props.dataCategory.id}/dataTypes`,
            selectedDataTypes
        );
    }

    const getDataTypeName = (id: string) => props.allDataTypes?.find((dataType) => dataType.id === id)?.name;

    const dataTypes = props.dataCategory.dataTypeIds
        .map((id) => {
            return {
                id: id,
                name: getDataTypeName(id),
            };
        })
        .sort((a, b) => a.name!.localeCompare(b.name!));

    const onSave = async () => {
        if (props.onSave) {
            props.onSave({ dataCategoryId: props.dataCategory.id, dataTypeIds: selectedDataTypes });
            props.onClose();
        } else {
            await saveMutation.mutateAsync();
            const queryKey = "processingActivity" + props.processingActivityId!;
            await queryClient.refetchQueries(queryKey);
            props.onClose();
        }
    };

    return (
        <DotLegalDialog
            size={"sm"}
            buttonOkText={translateString("save")}
            open
            onDialogClose={() => props.onClose()}
            onOkClick={onSave}
            header={props.dataCategory.name}
            inProgress={saveMutation.isLoading}
        >
            <Box sx={{ width: 250, margin: "auto" }}>
                {dataTypes.map((dt) => {
                    return (
                        <DotLegalCheckbox
                            key={dt.id}
                            checked={selectedDataTypes.some((s) => s === dt.id)}
                            label={dt.name}
                            onChange={() => onCheckedChanged(dt.id)}
                        />
                    );
                })}
            </Box>
        </DotLegalDialog>
    );
}

export default DataCategoryBoxDialog;
