import { useMutation, useQuery } from "react-query";
import { get, put } from "../../common/api/apiShared";
import { LegalBasisBaseClass, ProcessingActivityModel, SelectableDataCategoryItem, SelectableItem } from "../ProcessingActivity.types";
import { LegalBasisSelectorViewModel, ToggleAddDialog } from "./LegalBasesStep.types";
import { ValidationError } from "../../common/validationError";
import { useTranslation } from "../../localization/useTranslation";
import { useSelectableDataCategoriesQuery } from "../hooks/useSelectableDataCategoriesQuery";
import { useUpdateProcessingActivityModel } from "../hooks/useUpdateProcessingActivityModel";
import { useOptimisticUpdate } from "../../common/hooks/useOptimisticUpdate";
import { useState } from "react";
import { DotLegalSelectOption } from "../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { ILegalBasesStepProps } from "./LegalBasesStep";
import { useSelectablePurposesQuery } from "../../common/hooks/usePurposesQuery";
import { useSelectableDeletionPeriodsQuery } from "../../common/hooks/useSelectableDeletionPeriodsQuery";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";

export function useLegalBasesStepDataMapping(props: ILegalBasesStepProps) {
    const [selectedPurpose, setSelectedPurpose] = useState<string | null>("all");
    const { translateString } = useTranslation();
    const { permissions } = useUserContext();

    const [toggleAddDialog, setToggleAddDialog] = useState(new ToggleAddDialog());

    let { data, isLoading, refetch } = useQuery("legalbasis", () => get<LegalBasisSelectorViewModel>("/Legalbasis/getall/"), {
        enabled: permissions.processingActivityLegalBasesPermissions.hasLegalBasisPermission,
    });
    let deletionPeriodsQuery = useSelectableDeletionPeriodsQuery(!permissions.processingActivityLegalBasesPermissions.hasDeletionPeriodPermission);
    let purposeQuery = useSelectablePurposesQuery();

    let dataCategoriesQuery = useSelectableDataCategoriesQuery();
    const updateProcessingActivityModel = useUpdateProcessingActivityModel(props.processingActivityId);
    const optimisticUpdate = useOptimisticUpdate();
    const updateDataCategoryMutation = useMutation(updateDataCategory);
    const updateDataCategoriesMutation = useMutation(updateDataCategories);

    let deletionPeriodsLoading = deletionPeriodsQuery.isLoading;
    let dataCategoryIsLoading = dataCategoriesQuery.isLoading;
    let dataCategoryData = Array<SelectableDataCategoryItem>();
    let purposeData = Array<DotLegalSelectOption>();
    let purposeLoading = purposeQuery.isLoading;

    if (dataCategoriesQuery.allDataCategories) {
        const dataCategories = props.legalBasesViewModel?.allLegalBases?.map((d) => {
            return d.dataCategoryId!;
        });

        dataCategoryData = dataCategoriesQuery.allDataCategories.filter(function (item) {
            return dataCategories?.includes(item.id);
        });
    }

    let deletionPeriodsData: Array<SelectableItem> = [];
    if (deletionPeriodsQuery.data) {
        deletionPeriodsData = deletionPeriodsQuery.data.map((d, index) => {
            return {
                name: d.name,
                id: d.id,
            };
        });
    }

    if (purposeQuery.data) {
        purposeData = purposeQuery.data
            .filter((x) => props.purposes.indexOf(x.id) >= 0)
            .map((p) => {
                return {
                    name: p.name,
                    id: p.id,
                };
            });
        purposeData.push({ name: translateString("all"), id: "all" });
    }

    async function onChangeAllLegalBasisStandard(changeFunc: (dataCategory: LegalBasisBaseClass) => void, delay: boolean = false) {
        var updatedDatacategories: Array<LegalBasisBaseClass> = [];

        const newProcessingActivityModel = updateProcessingActivityModel.update((model) => {
            if (selectedPurpose && selectedPurpose !== "all") {
                model.legalBasesViewModel.purposeSpecificLegalBases.forEach((element) => {
                    dataCategoryData.forEach((x) => {
                        if (element.dataCategoryId === x.id && element.purposeId === selectedPurpose) {
                            changeFunc(element);
                            updatedDatacategories.push(element);
                        }
                    });
                });
            } else
                model.legalBasesViewModel.allLegalBases.forEach((element) => {
                    dataCategoryData.forEach((x) => {
                        if (element.dataCategoryId === x.id) {
                            changeFunc(element);
                            updatedDatacategories.push(element);
                        }
                    });
                });
        });
        await optimisticUpdate.updateWithProcessingActivityResponse(newProcessingActivityModel, updateDataCategoriesMutation.mutateAsync, {
            processingActivityId: newProcessingActivityModel.id,
            dataCategories: updatedDatacategories,
            selectedPurpose: selectedPurpose,
        });
    }

    async function onChangeLegalBasisStandard(id: string, changeFunc: (dataCategory: LegalBasisBaseClass) => void, delay: boolean = false) {
        const newProcessingActivityModel = updateProcessingActivityModel.update((model) => {
            if (selectedPurpose && selectedPurpose !== "all") {
                model.legalBasesViewModel.purposeSpecificLegalBases.forEach((element) => {
                    if (element.dataCategoryId === id && element.purposeId === selectedPurpose) {
                        changeFunc(element);
                    }
                });
            } else
                model.legalBasesViewModel.allLegalBases.forEach((element) => {
                    if (element.dataCategoryId === id) {
                        changeFunc(element);
                    }
                });
        });

        let category;
        if (selectedPurpose && selectedPurpose !== "all")
            category = newProcessingActivityModel.legalBasesViewModel.purposeSpecificLegalBases.find(
                (x) => x.dataCategoryId === id && x.purposeId === selectedPurpose
            )!;
        else category = newProcessingActivityModel.legalBasesViewModel.allLegalBases.find((x) => x.dataCategoryId === id)!;

        await optimisticUpdate.updateWithProcessingActivityResponse(newProcessingActivityModel, updateDataCategoryMutation.mutateAsync, {
            processingActivityId: newProcessingActivityModel.id,
            dataCategoryId: category.dataCategoryId,
            dataCategory: category,
            deletionPeriodId: category.deletionPeriodId,
            selectedPurpose: selectedPurpose,
        });
    }

    async function onDeletionPeriodChange(deletionPeriodId: string, dataCategoryId: string) {
        await onChangeLegalBasisStandard(dataCategoryId, (dc) => {
            dc.deletionPeriodId = deletionPeriodId;
            if (!deletionPeriodId) {
                dc.deletionPeriodReason = undefined;
            } else if (toggleAddDialog.deletionPeriod.displayOnClick) {
                var temp = { ...toggleAddDialog };
                temp.deletionPeriod.isDisplayed = true;
                temp.selectedEntityId = deletionPeriodId;
                setToggleAddDialog(temp);
            }
        });
    }

    async function onDirectiveChange(legalBasisId: string, dataCategoryId: string, showBalanceOfInterest: boolean) {
        await onChangeLegalBasisStandard(dataCategoryId, (dc) => {
            dc.directiveId = legalBasisId;
            if (!showBalanceOfInterest) dc.balanceOfInterest = undefined;

            if (legalBasisId && toggleAddDialog.directive.displayOnClick) {
                var temp = { ...toggleAddDialog };
                temp.directive.isDisplayed = true;
                temp.selectedEntityId = legalBasisId;
                setToggleAddDialog(temp);
            }
        });
    }

    async function onDoubleLegalBasisChange(legalBasisId: string, dataCategoryId: string, showBalanceOfInterest: boolean) {
        await onChangeLegalBasisStandard(dataCategoryId, (dc) => {
            dc.doubleLegalBasisId = legalBasisId;
            if (!showBalanceOfInterest) dc.balanceOfInterest = undefined;

            if (legalBasisId && toggleAddDialog.doubleLegalBasis.displayOnClick) {
                var temp = { ...toggleAddDialog };
                temp.doubleLegalBasis.isDisplayed = true;
                temp.selectedEntityId = legalBasisId;
                setToggleAddDialog(temp);
            }
        });
    }

    async function onNationalLawChange(legalBasisId: string, dataCategoryId: string, showBalanceOfInterest: boolean) {
        await onChangeLegalBasisStandard(dataCategoryId, (dc) => {
            dc.nationalLawId = legalBasisId;
            if (!showBalanceOfInterest) dc.balanceOfInterest = undefined;

            if (legalBasisId && toggleAddDialog.nationalLaw.displayOnClick) {
                var temp = { ...toggleAddDialog };
                temp.nationalLaw.isDisplayed = true;
                temp.selectedEntityId = legalBasisId;
                setToggleAddDialog(temp);
            }
        });
    }

    async function onBalanceOfInterestsChange(value: string, dataCategoryId: string) {
        await onChangeLegalBasisStandard(
            dataCategoryId,
            (dc) => {
                dc.balanceOfInterest = value;
            },
            true
        );
    }

    async function onDeletionPeriodReasonChange(value: string, dataCategoryId: string) {
        await onChangeLegalBasisStandard(
            dataCategoryId,
            (dc) => {
                dc.deletionPeriodReason = value;
            },
            true
        );
    }

    const onSaveDeletionPeriod = (response: any, name: string, dataCategoryId: string) => {
        var temp = [...deletionPeriodsData];
        temp.push({ id: response.id, name: name });
        onDeletionPeriodChange(response.id, dataCategoryId);
        deletionPeriodsData = temp;
        deletionPeriodsQuery.refetch();
    };

    const onSaveNationalLaw = (response: any, dataCategoryId: string) => {
        onNationalLawChange(response.id, dataCategoryId, false);
        refetch();
    };

    const getSelectedItems = () => {
        if (selectedPurpose === null || undefined) {
            return "all";
        }

        return selectedPurpose;
    };

    function saveAll() {
        onChangeAllLegalBasisStandard(getUpdateFunc());

        hideDialog(true);
    }

    const getUpdateFunc = (): ((datacategory: LegalBasisBaseClass) => void) => {
        if (toggleAddDialog.directive.isDisplayed) {
            return (dc) => (dc.directiveId = toggleAddDialog.selectedEntityId);
        }
        if (toggleAddDialog.deletionPeriod.isDisplayed) {
            return (dc) => (dc.deletionPeriodId = toggleAddDialog.selectedEntityId);
        }
        if (toggleAddDialog.nationalLaw.isDisplayed) {
            return (dc) => (dc.nationalLawId = toggleAddDialog.selectedEntityId);
        }
        if (toggleAddDialog.doubleLegalBasis.isDisplayed) {
            return (dc) => (dc.doubleLegalBasisId = toggleAddDialog.selectedEntityId);
        }

        throw Error(`No valid legalBasis Type is displayed: ${toggleAddDialog}`);
    };

    function hideDialog(displayOnClick: boolean) {
        var temp = { ...toggleAddDialog };

        if (temp.deletionPeriod.isDisplayed) {
            temp.deletionPeriod.displayOnClick = displayOnClick;
            temp.deletionPeriod.isDisplayed = false;
        } else if (temp.directive.isDisplayed) {
            temp.directive.displayOnClick = displayOnClick;
            temp.directive.isDisplayed = false;
        } else if (temp.doubleLegalBasis.isDisplayed) {
            temp.doubleLegalBasis.displayOnClick = displayOnClick;
            temp.doubleLegalBasis.isDisplayed = false;
        } else if (temp.nationalLaw.isDisplayed) {
            temp.nationalLaw.displayOnClick = displayOnClick;
            temp.nationalLaw.isDisplayed = false;
        }
        temp.selectedEntityId = "";

        setToggleAddDialog(temp);
    }

    function showDialog() {
        return (
            toggleAddDialog.directive.isDisplayed ||
            toggleAddDialog.deletionPeriod.isDisplayed ||
            toggleAddDialog.doubleLegalBasis.isDisplayed ||
            toggleAddDialog.nationalLaw.isDisplayed
        );
    }

    return {
        isLoading,
        data,
        dataCategoryData,
        dataCategoryIsLoading,
        deletionPeriodsData,
        deletionPeriodsLoading,
        purposeData,
        purposeLoading,
        selectedPurpose,
        onDeletionPeriodChange,
        onDirectiveChange,
        onDoubleLegalBasisChange,
        onNationalLawChange,
        onBalanceOfInterestsChange,
        onDeletionPeriodReasonChange,
        onSaveDeletionPeriod,
        setSelectedPurpose,
        getSelectedItems,
        toggleAddDialog,
        hideDialog,
        showDialog,
        saveAll,
        onSaveNationalLaw,
    };
}

export function useValidateLegalBasesStep(hasDeletionPeriodAccess: boolean, hasLegalBasesPermissions: boolean) {
    const { translateString } = useTranslation();
    return (model: ProcessingActivityModel, sensitiveDataCategories: Array<string> | undefined, legalObligationId: string) => {
        const result: Array<ValidationError> = [];

        model.legalBasesViewModel?.allLegalBases?.forEach((d) => {
            let purposeSpecific = model.legalBasesViewModel.purposeSpecificLegalBases?.filter((x) => x.dataCategoryId === d.dataCategoryId);

            purposeSpecific.forEach((p) => {
                if (hasLegalBasesPermissions) {
                    if (d.directiveId === legalObligationId && !d.nationalLawId) {
                        result.push(new ValidationError(`${d.dataCategoryId},nationalLawId${p.purposeId}`, translateString("nationalLawRequired")));
                    }
                    if (!p.nationalLawId && !p.directiveId) {
                        result.push(new ValidationError(`${d.dataCategoryId},directiveId${p.purposeId}`, translateString("legalBasisRequired")));
                    }
                    if (sensitiveDataCategories?.some((s) => s === p.dataCategoryId) && !p.doubleLegalBasisId) {
                        result.push(new ValidationError(`${d.dataCategoryId},doubleLegalBasisId${p.purposeId}`, translateString("doubleLegalBasisRequired")));
                    }
                }

                if (hasDeletionPeriodAccess && !p.deletionPeriodId) {
                    result.push(new ValidationError(`${d.dataCategoryId},deletionPeriodId${p.purposeId}`, translateString("deletionPeriodRequired")));
                }
            });

            if (d.canEdit) {
                if (hasLegalBasesPermissions) {
                    if (d.directiveId === legalObligationId && !d.nationalLawId) {
                        result.push(new ValidationError(`${d.dataCategoryId},nationalLawId`, translateString("nationalLawRequired")));
                    }
                    if (!d.nationalLawId && !d.directiveId) {
                        result.push(new ValidationError(`${d.dataCategoryId},directiveId`, translateString("legalBasisRequired")));
                    }

                    if (sensitiveDataCategories?.some((s) => s === d.dataCategoryId) && !d.doubleLegalBasisId) {
                        result.push(new ValidationError(`${d.dataCategoryId},doubleLegalBasisId`, translateString("doubleLegalBasisRequired")));
                    }
                }

                if (hasDeletionPeriodAccess && !d.deletionPeriodId) {
                    result.push(new ValidationError(`${d.dataCategoryId},deletionPeriodId`, translateString("deletionPeriodRequired")));
                }
            }
        });
        return result;
    };
}

async function updateDataCategories(data: { processingActivityId: string; dataCategories: Array<LegalBasisBaseClass>; selectedPurpose: string | null }) {
    return await put(
        `/processingactivity/${data.processingActivityId}/standardDatacategory/addall?forPurposeId=${
            data.selectedPurpose === "all" ? "" : data.selectedPurpose
        }`,
        data.dataCategories
    );
}

async function updateDataCategory(data: {
    processingActivityId: string;
    dataCategoryId: string;
    dataCategory: LegalBasisBaseClass;
    deletionPeriodId?: string | null;
    selectedPurpose: string | null;
}) {
    return await put(
        `/processingactivity/${data.processingActivityId}/standardDatacategory/${data.dataCategoryId}?forPurposeId=${
            data.selectedPurpose === "all" ? "" : data.selectedPurpose
        }`,
        {
            deletionPeriodId: data.deletionPeriodId,
            doubleLegalBasisId: data.dataCategory.doubleLegalBasisId,
            nationalLawId: data.dataCategory.nationalLawId,
            directiveId: data.dataCategory.directiveId,
            balanceOfInterest: data.dataCategory.balanceOfInterest,
            deletionPeriodReason: data.dataCategory.deletionPeriodReason,
        }
    );
}
