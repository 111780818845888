import React from "react";
import { Skeleton, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";

export interface ISmallCountTextProps {
    children: React.ReactNode;
    isPdf?: boolean;
}

function SmallCountText(props: ISmallCountTextProps) {
    const theme = useTheme();
    const textStyles = {
        fontSize: props.isPdf ? theme.typography.pxToRem(12) : theme.typography.pxToRem(24),
        lineHeight: 1.2,
    };
    return (
        <Typography variant={"h2"} sx={textStyles}>
            {props.children}
        </Typography>
    );
}

export default SmallCountText;
