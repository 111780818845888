import { appInsights } from "../AppInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { userManager } from "../auth/PrivacyAuthProvider";
import { setupCaptureConsoleError } from "./consoleErrorHandler";
import { decodeClaims } from "../auth/userContextProvider/UserContextProvider.tsx";
import { getJwtToken } from "./api/apiShared.ts";

export function setupErrorHandling() {
    window.addEventListener("unhandledrejection", async function (e) {
        await handleError(e.reason as Error);
    });

    window.addEventListener("error", async (event) => {
        await handleError(event.error, undefined, event);
    });

    setupCaptureConsoleError();
}

export async function handleError(e: Error, componentStack?: string | null, event?: ErrorEvent) {
    if (
        event &&
        event.message &&
        (event.message.includes("ResizeObserver loop completed with undelivered notifications") || event.message.includes("ResizeObserver loop limit exceeded"))
    ) {
        // These occur from time to time and can be safely ignored: https://stackoverflow.com/a/64257593
        event.stopImmediatePropagation();
        event.stopPropagation();
        event.preventDefault();
        return;
    }

    if (e.message?.includes("No matching state found in storage")) {
        // If login session is reused. This happens a lot, as this page is often bookmarked by the users
        logWarning(e.message);
        // eslint-disable-next-line no-console
        console.log("Login expired. Trying again...");
        try {
            await userManager.signinRedirect();
        } catch (e: any) {
            logError(e.exception as Error, componentStack);
        }
    } else {
        if (logAsWarning(e.message)) {
            logWarning(e.message);
        } else {
            logError(e, componentStack);
        }

        window.location.href = "/privacy/error";
    }
}

function logError(error: Error, componentStack?: string | null) {
    const customProperties = { componentStack: componentStack, ...getUserInfo() };
    appInsights.trackException(
        {
            exception: error,
            severityLevel: SeverityLevel.Error,
        },
        customProperties
    );
}

function logWarning(message: string) {
    appInsights.trackTrace({ message: message, severityLevel: SeverityLevel.Warning }, { ...getUserInfo() });
}

function getUserInfo() {
    const jwtToken = getJwtToken();
    if (!jwtToken) {
        return {};
    }
    const userClaims = decodeClaims(jwtToken);
    return {
        UserId: userClaims.UserProfileId,
        UserDomain: userClaims.EmailAddress.split("@")[1],
        CustomerId: userClaims.CustomerId,
        CustomerName: userClaims.CustomerName,
    };
}

function logAsWarning(message: string) {
    const logAsWarningWhiteList: Array<string> = [];
    return logAsWarningWhiteList.some((x) => message.includes(x));
}
