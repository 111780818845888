import { useTheme } from "@mui/material";
import { createSxStyles } from "../../../common/createSxStyles";

export const useRiskAssessmentVersionsStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        paperHeader: {
            display: "flex",
            alignItems: "center",
            height: "116px",
            marginBottom: theme.spacing(2),
            position: "relative",
        },
        headerImg: {
            position: "absolute",
            bottom: 0 + "px",
            right: "15%",
            maxWidth: 175 + "px",
        },
    });
};
