import React from "react";
import { Box, useTheme } from "@mui/material";

export interface IRecordNameWithSubNamesProps {
    name: string;
    subNames: Array<string>;
}

function RecordNameWithSubNames(props: IRecordNameWithSubNamesProps) {
    const theme = useTheme();
    return (
        <React.Fragment key={props.name}>
            <Box key={props.name} sx={{ mt: 1, mb: 1 }}>
                <Box component={"span"} sx={{ fontStyle: "italic" }}>
                    {props.name}
                </Box>
                {props.subNames.length > 0 && (
                    <Box component={"span"} sx={{ fontWeight: theme.typography.fontWeightBold }}>
                        :{" " + props.subNames.join(", ")}
                    </Box>
                )}
            </Box>
        </React.Fragment>
    );
}

export default RecordNameWithSubNames;
