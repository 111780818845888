import { useState } from "react";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { post } from "../../../common/api/apiShared";
import { Result } from "../../../common/api/result";
import { DotLegalSelectOption } from "../../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { useAllSelectableUsersQuery } from "../../../common/hooks/useSelectableItemQueries";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useValidator } from "../../../common/hooks/validation";
import { useTranslation } from "../../../localization/useTranslation";
import { LegalEntityDocumentUpdateModel } from "../LegalEntityDocumentTab.types";
import { IAddLegalEntityDocumentDialogProps } from "./AddLegalEntityDocumentDialog";
import { useDocumentHook } from "../../../documents/Documents.hook";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";

export function useAddLegalEntityDocumentHook(props: IAddLegalEntityDocumentDialogProps) {
    const snackbar = useDotLegalSnackbar();
    const { gdpo } = useUserContext();
    const { translateString, translateDocumentType } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const [document, setDocument] = useState<LegalEntityDocumentUpdateModel | undefined>(new LegalEntityDocumentUpdateModel(gdpo || props.isGroupEntity));
    const [documentationOption, setDocumentationOption] = useState(1);
    const { data, isLoading } = useAllSelectableUsersQuery();

    const validation = useValidator<LegalEntityDocumentUpdateModel>(
        (validators) => ({
            name: validators.validateNotEmpty((item) => item.name, translateString("name")),
            fileName: documentationOption === 0 ? undefined : validators.validateNotEmpty((item) => item.fileName, translateString("file")),
            link: !document?.isLink && documentationOption === 1 ? undefined : validators.validateUrlAndNotEmpty((item) => item.link, translateString("link")),
            type: validators.validateNotEmpty((item) => item.type, translateString("type")),
            status: validators.validateNotEmpty((item) => item.status, translateString("status")),
            groupEntityAccessIds: validators.validateNotEmpty(
                (item) => item.groupEntityAccessIds,
                "",
                (item) => item.allGroupEntities,
                translateString("oneGroupCompanyIsRequired")
            ),
        }),
        [documentationOption]
    );

    let documentTypes: Array<DotLegalSelectOption> = useDocumentHook().documentTypes.map((q) => {
        return {
            name: translateDocumentType(q[1].toString()),
            id: q[1].toString(),
        };
    });

    let documentStatus = useDocumentHook().selecableDocumentStatus;

    const createMutation = useMutation(getCreateMutation);

    const createDocument = () => {
        createMutation.mutateAsync(document!, {
            onSuccess: (response: Result<string>) => {
                snackbar.show(translateString("documentCreated"));
                props.onCloseDialog();
            },
        });
    };

    function onDocumentationTypeChange(option: number) {
        let tempModel = { ...document! };
        if (option === 0) {
            tempModel.base64Data = undefined;
            tempModel.fileName = "";
            setDocument(tempModel);
            setDocumentationOption(0);
        }

        if (option === 1) {
            tempModel.link = undefined;
            setDocument(tempModel);
            setDocumentationOption(1);
        }
    }

    function onGroupEntityAccessIdsChange(groupEntityIds: Array<string>) {
        let tempModel = { ...document! };
        tempModel.groupEntityAccessIds = groupEntityIds;

        if (gdpo) {
            if (groupEntityIds.length === 0) {
                tempModel.allGroupEntities = true;
            } else {
                tempModel.allGroupEntities = false;
            }
        }

        setDocument(tempModel);
    }

    return {
        document,
        setDocument,
        documentTypes,
        inProgress: createMutation.isLoading,
        validation,
        validateDocumentForm,
        userOptions: data,
        userOptionsIsLoading: isLoading,
        documentationOption,
        onDocumentationTypeChange,
        documentStatus,
        onGroupEntityAccessIdsChange,
    };

    function getCreateMutation(doc: LegalEntityDocumentUpdateModel) {
        return post<string>(`/LegalEntity/${id}/Documents`, doc);
    }

    function validateDocumentForm() {
        if (validation.anyHasErrors) {
            validation.setShowErrors(true);
            return;
        }

        createDocument();
    }
}
