import { DotLegalSelectOption } from "../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import {
    LegalEntityRiskAssessment,
    LegalEntityRiskAssessmentQuestion,
    LegalEntityRiskAssessmentQuestionAnswerId,
    LegalEntityRiskAssessmentQuestionId,
} from "./LegalEntityRiskQuestionnaire.types";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { get, post } from "../../common/api/apiShared";
import { useTranslation } from "../../localization/useTranslation";

export interface ILegalEntityRiskQuestionnaireProps {
    onChangeRiskAssessment?: (value: LegalEntityRiskAssessment | null) => void;
    riskAssessment: LegalEntityRiskAssessment | null;
    legalEntityId: string | undefined;
}

export function useLegalEntityRiskQuestionsHook(props: ILegalEntityRiskQuestionnaireProps) {
    const { translateLegalEntityRiskAssessment } = useTranslation();
    const [showCalculateRisk, setShowCalculateRisk] = useState(false);
    const [currentRiskAssessment, setCurrentRiskAssessment] = useState<LegalEntityRiskAssessment | null>(null);
    const [questions, setQuestions] = useState<Array<LegalEntityRiskAssessmentQuestion>>([]);
    const riskAssessmentKeys: DotLegalSelectOption[] = Object.keys(LegalEntityRiskAssessment)
        .filter((k) => typeof LegalEntityRiskAssessment[k as any] === "number")
        .map((i, k) => {
            return { name: translateLegalEntityRiskAssessment(k.toString()), id: k.toString() };
        });

    const url = `/LegalEntities/${props.legalEntityId}/riskAssessmentQuestions`;
    let { isLoading, data } = useQuery(url, () => get<Array<LegalEntityRiskAssessmentQuestion>>(url));

    useEffect(() => {
        if (data && questions.length === 0) {
            setQuestions(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (props.riskAssessment !== null || props.riskAssessment !== undefined) {
            setCurrentRiskAssessment(props.riskAssessment);
        }
    }, [props.riskAssessment]);

    const riskAssessmentMutation = useMutation(getRiskAssessment);
    function getRiskAssessment(questions: Array<LegalEntityRiskAssessmentQuestion>) {
        return post<LegalEntityRiskAssessment>("/LegalEntities/CalculateRisk", questions);
    }

    const handleRiskAssessmentCalcChange = (questionId: LegalEntityRiskAssessmentQuestionId, answer?: LegalEntityRiskAssessmentQuestionAnswerId) => {
        const tempDataQuestions = [...questions];
        tempDataQuestions.find((x) => x.questionId === questionId)!.answerId = answer;
        setQuestions(tempDataQuestions);

        if (questions.filter((x) => x.answerId !== undefined && x.answerId !== null).length === 4) {
            riskAssessmentMutation.mutateAsync(questions, {
                onSuccess: (riskAssessment: any) => {
                    setCurrentRiskAssessment(riskAssessment.value());
                    if (props.onChangeRiskAssessment) {
                        props.onChangeRiskAssessment(riskAssessment.value());
                    }
                },
            });
        } else {
            setCurrentRiskAssessment(null);
        }
    };

    const onRiskAssessmentChange = (value: string | null) => {
        resetQuestionnaireAnswers();
        let riskAssesment = null;
        if (value) {
            let riskString = LegalEntityRiskAssessment[Number(value)] as keyof typeof LegalEntityRiskAssessment;
            riskAssesment = LegalEntityRiskAssessment[riskString];
        }
        if (props.onChangeRiskAssessment) props.onChangeRiskAssessment(riskAssesment);
        else setCurrentRiskAssessment(riskAssesment);
    };

    const resetQuestionnaireAnswers = () => {
        let tempDataQuestions = [...questions!];
        tempDataQuestions.forEach((question) => {
            question.answerId = undefined;
        });
        setQuestions(tempDataQuestions);
    };

    return {
        handleRiskAssessmentCalcChange,
        onRiskAssessmentChange,
        showCalculateRisk,
        setShowCalculateRisk,
        riskAssessmentKeys,
        currentRiskAssessment,
        questions: data,
        questionsLoading: isLoading,
    };
}
