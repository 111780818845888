import { ApprovalOption, DialogStep } from "../AnnualWheel.types.ts";
import { useTranslation } from "../../localization/useTranslation.ts";
import { createElement, useState } from "react";
import { AnnualWheelDurationFormModel } from "../annualWheelDialog/annualWheelDurationForm/AnnualWheelDurationForm.types.ts";
import { AnnualWheelResponsibleFormModel } from "../annualWheelDialog/annualWheelResponsibleForm/AnnualWheelResponsibleForm.types.ts";
import { AnnualWheelBaseInformationFormModel } from "../annualWheelDialog/annualWheelBaseInformationForm/AnnualWheelBaseInformationForm.types.ts";
import { AnnualWheelNameDescriptionSubtasksFormModel } from "../annualWheelDialog/annualWheelNameDescriptionSubtasks/AnnualWheelNameDescriptionSubtasks.types.ts";
import { IAnnualWheelAddEditDialogProps } from "./AnnualWheelAddEditDialog.tsx";
import { AnnualWheelActivitySaveModel, AnnualWheelActivityType } from "../annualWheelDialog/AnnualWheelDialog.types.ts";
import { isNullOrWhitespace } from "../../common/stringOperations.ts";
import { useMutation } from "react-query";
import { Trans } from "react-i18next";
import { post, put } from "../../common/api/apiShared.ts";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";

export function useAnnualWheelAddEditDialog(props: IAnnualWheelAddEditDialogProps) {
    const { translateString } = useTranslation();
    const snackbar = useDotLegalSnackbar();
    const [dialogStep, setDialogStep] = useState<DialogStep>(DialogStep.NameDescriptionSubtasks);
    const [showFormError, setShowFormError] = useState<boolean>(false);

    const url = "/annualWheelActivity/";

    const [annualWheelNameDescriptionSubTasksFormModel, setAnnualWheelNameDescriptionSubTasksFormModel] = useState(
        new AnnualWheelNameDescriptionSubtasksFormModel(
            props.selectedAnnualWheelActivity.name,
            props.selectedAnnualWheelActivity.description,
            props.selectedAnnualWheelActivity.subTasks
        )
    );
    const [annualWheelDurationFormModel, setAnnualWheelDurationFormModel] = useState<AnnualWheelDurationFormModel>(
        new AnnualWheelDurationFormModel(
            props.selectedAnnualWheelActivity.startDate,
            props.selectedAnnualWheelActivity.notificationPeriodInDays,
            props.selectedAnnualWheelActivity.interval,
            props.selectedAnnualWheelActivity.type,
            false
        )
    );
    const [annualWheelResponsibleFormModel, setAnnualWheelResponsibleFormModel] = useState<AnnualWheelResponsibleFormModel>(
        new AnnualWheelResponsibleFormModel([], "", [], ApprovalOption.ApprovalNotRequired, [])
    );
    const [annualWheelBaseInformationFormModel, setAnnualWheelBaseInformationFormModel] = useState<AnnualWheelBaseInformationFormModel>(
        new AnnualWheelBaseInformationFormModel(
            props.selectedAnnualWheelActivity.priority,
            props.selectedAnnualWheelActivity.customId,
            undefined,
            undefined,
            undefined,
            undefined,
            props.selectedAnnualWheelActivity.framework
        )
    );

    const isEditActivity = false; // TODO THE
    const activityId = null; // TODO THE
    const isValidatePaActivity = props.selectedAnnualWheelActivity?.type === AnnualWheelActivityType.ProcessingActivityValidation; // TODO THE Ved edit, håndter, at isValidatePaActivity sættes rigtigt her

    const isFinalStep = () => {
        return dialogStep === DialogStep.ActivityBaseInformation;
    };
    const getOkBtnText = () => {
        if (!isFinalStep()) {
            return translateString("next");
        }

        if (isEditActivity) {
            return translateString("save");
        }

        return translateString("add");
    };

    function getDialogHeader() {
        if (isEditActivity) {
            return translateString("editActivity");
        } else {
            return translateString("createActivity");
        }
    }

    const getPreviousBtnText = () => {
        if (isEditActivity) {
            return translateString("cancel");
        } else {
            return translateString("previous");
        }
    };

    const onOkClick = () => {
        switch (dialogStep) {
            case DialogStep.NameDescriptionSubtasks: {
                /* Assigning a local variable, because the setShowFormError will not change the validation before we reach the if/else */
                const isInvalid = annualWheelNameDescriptionSubTasksFormModel.isInValid();
                setShowFormError(isInvalid);
                if (!isInvalid) {
                    setShowFormError(false);
                    setDialogStep(DialogStep.ActivityDurationData);
                }
                break;
            }
            case DialogStep.ActivityDurationData: {
                /* Assigning a local variable, because the setShowFormError will not change the validation before we reach the if/else */
                let isInvalid = annualWheelDurationFormModel!.isModelInvalid();
                setShowFormError(isInvalid);
                if (isValidatePaActivity && !isInvalid) {
                    setShowFormError(false);
                    setDialogStep(DialogStep.ActivityBaseInformation);
                } else if (!isInvalid) {
                    setShowFormError(false);
                    setDialogStep(DialogStep.ActivityResponsibleData);
                }
                break;
            }
            case DialogStep.ActivityResponsibleData: {
                /* Assigning a local variable, because the setShowFormError will not change the validation before we reach the if/else */
                let isInvalid = annualWheelResponsibleFormModel!.IsModelInvalid();
                setShowFormError(isInvalid);
                if (!isInvalid) {
                    setShowFormError(false);
                    setDialogStep(DialogStep.ActivityBaseInformation);
                }

                break;
            }
            case DialogStep.ActivityBaseInformation: {
                submitForm();
                break;
            }
        }
    };

    function submitForm() {
        if (isEditActivity) {
            updateActivity();
        } else {
            createActivity();
        }
    }

    const editMutation = useMutation(update);

    function update(id: string) {
        return put<{}>(`${url}${id}/`, mapToSaveModel());
    }
    const updateActivity = () => {
        editMutation.mutateAsync(activityId!, {
            onSuccess: () => {
                const activityName = getDialogHeader();
                snackbar.show(createElement(Trans, { i18nKey: "annualWheelActivityUpdated", values: { name: activityName } }));
                props.onSave();
                props.onDialogClose();
            },
        });
    };

    const createActivity = async () => {
        await createMutation.mutateAsync();
    };

    function save() {
        return post<{}>(`${url}create`, mapToSaveModel());
    }

    const createMutation = useMutation(save, {
        onSuccess: () => {
            props.onSave();
            props.onDialogClose();
            snackbar.show(createElement(Trans, { i18nKey: "annualWheelActivityAdded", values: { name: annualWheelNameDescriptionSubTasksFormModel.name } }));
        },
    });

    function mapToSaveModel() {
        const saveModel: AnnualWheelActivitySaveModel = {
            businessAreas: annualWheelResponsibleFormModel?.businessAreas,
            documentationRequired: annualWheelBaseInformationFormModel?.documentationRequired,
            interval: annualWheelDurationFormModel.interval,
            notificationPeriodInDays: annualWheelDurationFormModel.notificationPeriodInDays,
            priority: annualWheelBaseInformationFormModel.priority,
            responsible: isNullOrWhitespace(annualWheelResponsibleFormModel?.responsible) ? undefined : annualWheelResponsibleFormModel.responsible,
            startDate: annualWheelDurationFormModel.startDate,
            type: props.selectedAnnualWheelActivity.type,
            customTemplateId: props.selectedAnnualWheelActivity.customTemplateId,
            areas: annualWheelBaseInformationFormModel.areas,
            customId: annualWheelBaseInformationFormModel.customId,
            groupCompanies: annualWheelResponsibleFormModel?.groupCompanies,
            relatedActivities: annualWheelBaseInformationFormModel.relatedActivities,
            approval: annualWheelResponsibleFormModel?.approval,
            assignees: annualWheelResponsibleFormModel.assignees,
            tags: annualWheelBaseInformationFormModel.tags,
        };

        return saveModel;
    }

    const onPreviousButtonClick = () => {
        switch (dialogStep) {
            case DialogStep.NameDescriptionSubtasks: {
                props.onPreviousClick();
                break;
            }
            case DialogStep.ActivityDurationData: {
                setDialogStep(DialogStep.NameDescriptionSubtasks);
                break;
            }
            case DialogStep.ActivityResponsibleData: {
                setDialogStep(DialogStep.ActivityDurationData);
                break;
            }
            case DialogStep.ActivityBaseInformation: {
                if (isValidatePaActivity) {
                    setDialogStep(DialogStep.ActivityDurationData);
                } else {
                    setDialogStep(DialogStep.ActivityResponsibleData);
                }

                break;
            }
        }
    };

    return {
        getOkBtnText,
        getPreviousBtnText,
        getDialogHeader,
        isFinalStep,
        dialogStep,
        onOkClick,
        onPreviousButtonClick,
        annualWheelNameDescriptionSubTasksFormModel,
        setAnnualWheelNameDescriptionSubTasksFormModel,
        annualWheelBaseInformationFormModel,
        setAnnualWheelBaseInformationFormModel,
        annualWheelResponsibleFormModel,
        setAnnualWheelResponsibleFormModel,
        annualWheelDurationFormModel,
        setAnnualWheelDurationFormModel,
        showFormError,
        isEditActivity,
        isValidatePaActivity,
        activityId,
    };
}
