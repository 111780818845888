import {
    DotLegalActionBar,
    DotLegalBreadCrumbModel,
    DotLegalButton,
    DotLegalEmptyState,
    DotLegalOverflowMenu,
    DotLegalPageHeader,
    DotLegalPaper,
    DotLegalTableWithControls,
    DotLegalTooltip,
    ITableHeader,
    useDotLegalSnackbar,
} from "@dotlegal/dotlegal-ui-components";
import { PublicInternalAssessmentTemplateViewModel } from "./PublicInternalAssessmentTemplates.types.ts";
import { useTranslation } from "../../localization/useTranslation.ts";
import React, { createElement } from "react";
import usePublicInternalAssessmentTemplatesHooks from "./PublicInternalAssessmentTemplates.hooks.ts";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider.tsx";
import { Box, IconButton, TableCell, TableRow } from "@mui/material";
import EmptyState from "../../common/svgs/emptyState.svg?react";
import InternalAssessmentsIcon from "../../internalAssessment/internalAssessments.svg?react";
import CreatePublicInternalAssessmentTemplateDialog from "./createDialog/CreatePublicInternalAssessmentTemplateDialog.tsx";
import { getSettings } from "../../common/settingsProvider.ts";
import { useUrlProvider } from "../../useUrlProvider.ts";
import CopyIcon from "./Copy.svg?react";
import { Trans } from "react-i18next";
import QuestionnaireTemplateLink from "../../legalEntity/legalEntityAuditTab/QuestionnaireTemplateLink.tsx";
import DeletePublicInternalAssessmentTemplateDialog from "./deleteDialog/DeletePublicInternalAssessmentTemplateDialog.tsx";
import EditPublicInternalAssessmentTemplateDialog from "./editDialog/EditPublicInternalAssessmentTemplateDialog.tsx";

function PublicInternalAssessmentTemplates() {
    const { translateString } = useTranslation();
    const { customerName } = useUserContext();
    const {
        showCreateDialog,
        setShowCreateDialog,
        isLoading,
        data,
        hasData,
        onSave,
        selectedItem,
        setSelectedItem,
        showDeleteDialog,
        setShowDeleteDialog,
        showEditDialog,
        setShowEditDialog,
        refetch,
    } = usePublicInternalAssessmentTemplatesHooks();
    const snackbar = useDotLegalSnackbar();
    const settings = getSettings();
    const urlProvider = useUrlProvider();

    function getHeaders() {
        let headers = Array<ITableHeader<PublicInternalAssessmentTemplateViewModel>>();
        headers.push({ property: "name", text: translateString("name"), align: "left", showOnMobile: true });
        headers.push({ property: "responsible", text: translateString("responsible"), align: "left", showOnMobile: true });
        headers.push({ property: "questionnaireName", text: translateString("template"), align: "left", showOnMobile: true });
        headers.push({ property: "groupEntity", text: translateString("groupEntity"), align: "left", showOnMobile: true });
        headers.push({ property: "externalId", text: translateString("publicInternalAssessmentTemplateLink"), align: "left", showOnMobile: true });
        headers.push({
            property: "numberOfTimesInitiated",
            text: translateString("publicInternalAssessmentTemplateTimesUsed"),
            align: "left",
            showOnMobile: true,
        });

        return headers;
    }

    function getMenuItems(row: PublicInternalAssessmentTemplateViewModel) {
        let menuItems = [];

        menuItems.push({
            menuItem: translateString("edit"),
            onClick: () => {
                setSelectedItem(row);
                setShowEditDialog(true);
            },
        });

        menuItems.push({
            menuItem: translateString("delete"),
            onClick: () => {
                setSelectedItem(row);
                setShowDeleteDialog(true);
            },
        });

        return menuItems;
    }

    function getName(row: PublicInternalAssessmentTemplateViewModel) {
        if (row.description) {
            return (
                <DotLegalTooltip text={row.description}>
                    <Box>{row.name}</Box>
                </DotLegalTooltip>
            );
        }

        return row.name;
    }

    function getLink(row: PublicInternalAssessmentTemplateViewModel) {
        const url = settings.mainSiteBaseUrl + urlProvider.getPublicStartInternalAssessmentUrl(row.externalId);
        return (
            <Box>
                <IconButton sx={{ marginLeft: "-10px" }} onClick={() => copyLink(url)}>
                    <CopyIcon />
                </IconButton>
            </Box>
        );
    }

    function copyLink(url: string) {
        navigator.clipboard.writeText(url);
        snackbar.show(createElement(Trans, { i18nKey: "linkWasCopied", values: { link: url } }));
    }

    function getBreadCrumbs() {
        let breadCrumbs: Array<DotLegalBreadCrumbModel> = [];
        breadCrumbs.push({ name: translateString("internalAssessments"), link: urlProvider.getInternalAssessmentUrl() });
        breadCrumbs.push({ name: translateString("publicInternalAssessmentHeader") });
        return breadCrumbs;
    }

    return (
        <React.Fragment>
            <DotLegalPageHeader userContext={{ customerName }} breadCrumbs={getBreadCrumbs()} />
            <DotLegalActionBar
                primaryButton={
                    <DotLegalButton buttonType="primary" size="medium" onClick={() => setShowCreateDialog(true)}>
                        {translateString("create")} {translateString("publicInternalAssessment").toLowerCase()}
                    </DotLegalButton>
                }
                showDialog={showCreateDialog}
                canCreate
                icon={<InternalAssessmentsIcon />}
            >
                <CreatePublicInternalAssessmentTemplateDialog onCreate={onSave} onDialogClose={() => setShowCreateDialog(false)} />
                <Box></Box>
            </DotLegalActionBar>

            <DotLegalPaper>
                {hasData ? (
                    <DotLegalTableWithControls
                        headers={getHeaders()}
                        getUserSpecificPageLength={() => 30}
                        hideRowsPerPage
                        defaultOrderBy={"name"}
                        defaultOrder={"asc"}
                        isLoading={isLoading}
                        clickableRows={false}
                        labelRowsPerPage={translateString("showEntites")}
                        labelSearch={translateString("search")}
                        noOptionsLabel={translateString("noOptions")}
                        paginationLabelOf={translateString("labelOf")}
                        emptyText={translateString("noData")}
                        data={data}
                        renderRow={(row) => (
                            <TableRow key={row.id}>
                                <TableCell>{getName(row)}</TableCell>
                                <TableCell>{row.responsible}</TableCell>
                                <TableCell>
                                    <QuestionnaireTemplateLink type={"audit"} id={row.questionnaireId} name={row.questionnaireName} />
                                </TableCell>
                                <TableCell>{row.groupEntity}</TableCell>
                                <TableCell>{getLink(row)}</TableCell>
                                <TableCell>{row.numberOfTimesInitiated}</TableCell>
                                <TableCell align={"right"}>
                                    <DotLegalOverflowMenu items={getMenuItems(row)} />
                                </TableCell>
                            </TableRow>
                        )}
                    />
                ) : (
                    <Box
                        sx={{
                            img: {
                                width: "25%",
                            },
                        }}
                    >
                        <DotLegalEmptyState icon={<EmptyState />} text={translateString("noPublicInternalAssessmentTemplates")} />
                    </Box>
                )}
            </DotLegalPaper>
            {showDeleteDialog && selectedItem && (
                <DeletePublicInternalAssessmentTemplateDialog
                    id={selectedItem.id}
                    name={selectedItem.name}
                    onCloseDialog={() => {
                        setShowDeleteDialog(false);
                        setSelectedItem(null);
                    }}
                    onSuccessDelete={() => refetch()}
                />
            )}
            {showEditDialog && selectedItem && (
                <EditPublicInternalAssessmentTemplateDialog
                    id={selectedItem.id}
                    name={selectedItem.name}
                    description={selectedItem.description}
                    responsibleId={selectedItem.responsibleId}
                    onCreate={() => refetch()}
                    onDialogClose={() => {
                        setShowEditDialog(false);
                        setSelectedItem(null);
                    }}
                />
            )}
        </React.Fragment>
    );
}

export default PublicInternalAssessmentTemplates;
