import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import DotLegalStepHeader from "../../common/components/dotLegalStepHeader/DotLegalStepHeader";
import LegalBasisSelector from "../../legalBasis/legalBasisSelector/LegalBasisSelector";
import { useTranslation } from "../../localization/useTranslation";
import { DataPrivacyClassification } from "../DataCategoriesStep/DataCategoriesStep.types";
import { LegalBasisDataCategoryViewModel, ProcessingActivityLegalBases, SelectableDataCategoryItem } from "../ProcessingActivity.types";
import StepSegment from "../stepSegment/StepSegment";
import { useLegalBasesStepDataMapping } from "./LegalBasesStep.hooks";
import { useLegalBasesStepstyles } from "./LegalBasesStep.styles";
import { ValidationError } from "../../common/validationError";
import LegalBasisSelectorContainer from "../../legalBasis/legalBasisSelector/LegalBasisSelectorContainer";
import { DotLegalDialog, DotLegalHeader, DotLegalSelect } from "@dotlegal/dotlegal-ui-components";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";

export interface ILegalBasesStepProps {
    hasError: boolean | undefined;
    processingActivityId: string;
    name?: string;
    legalBasesViewModel: ProcessingActivityLegalBases;
    readOnly: boolean;
    validationErrors: Array<ValidationError>;
    hasDataCreationPermission: boolean;
    purposes: Array<string>;
}

function LegalBasesStep(props: ILegalBasesStepProps) {
    const { translateString } = useTranslation();
    const styles = useLegalBasesStepstyles();
    const { permissions } = useUserContext();

    const {
        data,
        isLoading,
        dataCategoryData,
        dataCategoryIsLoading,
        deletionPeriodsData,
        deletionPeriodsLoading,
        purposeLoading,
        purposeData,
        selectedPurpose,
        onDeletionPeriodChange,
        onDirectiveChange,
        onDoubleLegalBasisChange,
        onNationalLawChange,
        onSaveDeletionPeriod,
        onBalanceOfInterestsChange,
        onDeletionPeriodReasonChange,
        setSelectedPurpose,
        getSelectedItems,
        hideDialog,
        toggleAddDialog,
        showDialog,
        saveAll,
        onSaveNationalLaw,
    } = useLegalBasesStepDataMapping(props);

    const GetByDataPrivacyClassification = (classification: DataPrivacyClassification) => {
        return dataCategoryData.filter((x) => x.classification === classification);
    };

    const ContainsClassification = (classification: DataPrivacyClassification) => {
        return GetByDataPrivacyClassification(classification).length > 0;
    };

    const legalBasesPermissions = permissions.processingActivityLegalBasesPermissions;

    return (
        <StepSegment size={"large"}>
            <DotLegalStepHeader toolTipText={translateString("legalBasisPickerHelperText")} record={false}>
                {translateString("legalBasisStepHeader")}
            </DotLegalStepHeader>
            <DotLegalStepHeader toolTipText={translateString("deletionPeriodHelperText")} record>
                {translateString("deletionPeriodStepHeader")}
            </DotLegalStepHeader>

            {props.purposes.length > 1 && (
                <Grid container justifyContent="flex-start" spacing={3}>
                    <Grid item>
                        <Box sx={styles.purposeSelector}>
                            <DotLegalSelect
                                isLoading={purposeLoading}
                                label={translateString("purpose")}
                                placeholder={translateString("purpose")}
                                options={purposeData}
                                selectedItem={getSelectedItems()}
                                onChange={(purpose) => setSelectedPurpose(purpose)}
                                disableClearable
                                errorText={props.hasError ? translateString("legalBasesPurposeError") : ""}
                                toolTipText={translateString("legalBasesChangePurposeTooltip")}
                                noOptionsLabel={translateString("noOptions")}
                            />
                        </Box>
                    </Grid>
                </Grid>
            )}

            {props.legalBasesViewModel.allLegalBases.length > 0 ? (
                !dataCategoryIsLoading &&
                !deletionPeriodsLoading && (
                    <React.Fragment>
                        {ContainsClassification(DataPrivacyClassification.Ordinary) && (
                            <LegalBasisSelectorContainer
                                headerText={translateString("dataCategoriOrdinary")}
                                toolTipText={translateString("legalBasisNonSensitiveHelperText")}
                            >
                                {GetByDataPrivacyClassification(DataPrivacyClassification.Ordinary).map((d, i) => {
                                    return getSelector(d, false);
                                })}
                            </LegalBasisSelectorContainer>
                        )}

                        {ContainsClassification(DataPrivacyClassification.NationalIdentificationNumber) && (
                            <LegalBasisSelectorContainer headerText={translateString("nationalidentificationnumber")} toolTipText={""}>
                                {GetByDataPrivacyClassification(DataPrivacyClassification.NationalIdentificationNumber).map((d, i) => {
                                    return getSelector(d, false);
                                })}
                            </LegalBasisSelectorContainer>
                        )}

                        {ContainsClassification(DataPrivacyClassification.CriminalOffenses) && (
                            <LegalBasisSelectorContainer headerText={translateString("criminaloffenses")} toolTipText={""}>
                                {GetByDataPrivacyClassification(DataPrivacyClassification.CriminalOffenses).map((d, i) => {
                                    return getSelector(d, false);
                                })}
                            </LegalBasisSelectorContainer>
                        )}

                        {ContainsClassification(DataPrivacyClassification.Sensitive) && (
                            <LegalBasisSelectorContainer
                                headerText={translateString("dataCategoriSensitive")}
                                toolTipText={translateString("legalBasisSensitiveHelperText")}
                            >
                                {GetByDataPrivacyClassification(DataPrivacyClassification.Sensitive).map((d, i) => {
                                    return getSelector(d, true);
                                })}
                            </LegalBasisSelectorContainer>
                        )}
                        {ContainsClassification(DataPrivacyClassification.None) && (
                            <LegalBasisSelectorContainer headerText={translateString("dataCategoriNone")} toolTipText={""}>
                                {GetByDataPrivacyClassification(DataPrivacyClassification.None).map((d, i) => {
                                    return getSelector(d, false);
                                })}
                            </LegalBasisSelectorContainer>
                        )}
                    </React.Fragment>
                )
            ) : (
                <Typography sx={styles.EmptyState}>{translateString("noDataCategoriesSelected")}</Typography>
            )}

            {showDialog() && getAddToAllDialog()}
        </StepSegment>
    );

    function getAddToAllDialog() {
        return (
            <DotLegalDialog
                header={translateString("addToAllDataCategoriesHeader", { name: getType() })}
                size="md"
                buttonOkText={translateString("yes")}
                onSecondaryButtonClick={() => hideDialog(false)}
                secondaryButtonText={translateString("no")}
                open
                onDialogClose={() => hideDialog(true)}
                onOkClick={() => saveAll()}
            >
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <DotLegalHeader headerStyle={"small"} fontWeight={0} center>
                        {translateString("addToAllDataCategoriesContent", { name: getType()?.toLowerCase() })}
                    </DotLegalHeader>
                </Box>
            </DotLegalDialog>
        );
    }

    function getType() {
        if (toggleAddDialog.deletionPeriod.isDisplayed) {
            return translateString("deletionPeriod");
        } else if (toggleAddDialog.directive.isDisplayed) {
            return translateString("legalBasis");
        } else if (toggleAddDialog.doubleLegalBasis.isDisplayed) {
            return translateString("doubleLegalBasis");
        } else if (toggleAddDialog.nationalLaw.isDisplayed) {
            return translateString("nationalLaw");
        }
    }

    function getSelector(d: SelectableDataCategoryItem, isSensitive: boolean) {
        let selectedDataCategory;
        let purposeId = "";
        let canEdit = true;

        if (selectedPurpose && selectedPurpose !== "all") {
            selectedDataCategory = props.legalBasesViewModel.purposeSpecificLegalBases.find(
                (x) => x.dataCategoryId === d.id && x.purposeId === selectedPurpose
            );
            purposeId = selectedDataCategory?.purposeId ?? "";
        } else {
            let purposeSpecfic = props.legalBasesViewModel.purposeSpecificLegalBases.filter((x) => x.dataCategoryId === d.id);
            if (purposeSpecfic && purposeSpecfic.length === 1) {
                selectedDataCategory = purposeSpecfic[0];
                purposeId = selectedDataCategory?.purposeId ?? "";
            } else {
                selectedDataCategory = props.legalBasesViewModel.allLegalBases.find((x) => x.dataCategoryId === d.id);
                canEdit = selectedDataCategory!.canEdit!;
                if (!canEdit) selectedDataCategory = new LegalBasisDataCategoryViewModel(d.id);
            }
        }

        return (
            <React.Fragment key={d.id}>
                <LegalBasisSelector
                    hasCreateDataPermission={props.hasDataCreationPermission}
                    dataCategory={selectedDataCategory}
                    OnDirectiveChange={onDirectiveChange}
                    OnNationalLawChange={onNationalLawChange}
                    OnDoubleLegalBasisChange={onDoubleLegalBasisChange}
                    OnBalanceOfInterests={onBalanceOfInterestsChange}
                    onDeletionPeriodChange={onDeletionPeriodChange}
                    deletionPeriodOptions={deletionPeriodsData!}
                    toolTip={d.description}
                    warningText={!canEdit ? translateString("standardLegalBasisCantEditTooltip") : ""}
                    legalBases={data}
                    dataCategoryName={d.name}
                    isSensitive={isSensitive}
                    isLoading={isLoading}
                    disabled={props.readOnly || !canEdit}
                    directiveError={props.validationErrors.find((v) => v.field === `${d.id},directiveId${purposeId}`)?.error}
                    deletionPeriodError={props.validationErrors.find((v) => v.field === `${d.id},deletionPeriodId${purposeId}`)?.error}
                    doubleLegalBasisError={props.validationErrors.find((v) => v.field === `${d.id},doubleLegalBasisId${purposeId}`)?.error}
                    nationalLawError={props.validationErrors.find((v) => v.field === `${d.id},nationalLawId${purposeId}`)?.error}
                    onSaveDeletionPeriod={onSaveDeletionPeriod}
                    onDeletionPeriodReasonChange={onDeletionPeriodReasonChange}
                    hasLegalBasisPermissions={legalBasesPermissions.hasLegalBasisPermission}
                    hasDeletionPeriodPermission={legalBasesPermissions.hasDeletionPeriodPermission}
                    onSaveNationalLaw={onSaveNationalLaw}
                />
                <Divider sx={styles.divider} />
            </React.Fragment>
        );
    }
}

export default LegalBasesStep;
