import { alpha, useTheme } from "@mui/material";
import { IDotLegalStepHeader } from "./DotLegalStepHeader";
import { createSxStyles } from "../../createSxStyles";

export const useDotLegalStepHeaderStyles = (props: IDotLegalStepHeader) => {
    const theme = useTheme();
    return createSxStyles({
        centerHeader: {
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            marginTop: props.topMargin ? theme.spacing(3) : "",
            marginBottom: props.bottomMargin ? theme.spacing(3) : "",
        },
        firstContainer: {
            justifyContent: "center",
            flexDirection: "column",
        },
        firstTypographyContainer: {
            position: "relative",
            display: "inline-block",
        },
        toolTipContainer: {
            position: "absolute",
            top: 1 + "px",
            right: theme.spacing(-3),
        },
        listDescription: {
            position: "absolute",
            top: 0,
            marginLeft: props.toolTipText ? theme.spacing(2.7) : theme.spacing(-2.7),
        },
        firstHeader: {
            fontWeight: theme.typography.fontWeightBold,
            fontSize: theme.typography.pxToRem(14),
            color: theme.palette.primary.dark,
            display: "inline-block",
        },
        infoToolTip: {
            display: "inline-block",
            verticalAlign: "top",
        },
        subHeader: {
            fontWeight: theme.typography.fontWeightBold,
            textAlign: "center",
            fontSize: theme.typography.pxToRem(12),
            color: alpha(theme.palette.primary.dark, 0.4),
        },
    });
};
