import { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { get } from "../../common/api/apiShared";
import { PolicyViewModel } from "./PolicyOverview.types";
import { PolicySaveModel } from "../policies/Policies.types.ts";

export function usePolicyDataMapping() {
    const { policyId } = useParams<{ policyId: string }>();
    const [showProcessingActivityDialog, setShowProcessingActivityDialog] = useState(false);
    const [showSystemDialog, setShowSystemDialog] = useState(false);

    const [showPolicyDialog, setShowPolicyDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedPolicy, setSelectedPolicy] = useState<PolicySaveModel>();

    const url = "/policies/" + policyId;
    const { isLoading, data, refetch } = useQuery(url, () => get<PolicyViewModel>(url));

    const hasProcessingActivities = (data?.processingActivities.length ?? 0) + (data?.noOfProcessingActivitiesWithNoAccess ?? 0) > 0;
    const hasSystems = (data?.systems.length ?? 0) + (data?.noOfSystemsWithNoAccess ?? 0) > 0;

    return {
        policyId,
        data,
        isLoading,
        showProcessingActivityDialog,
        setShowProcessingActivityDialog,
        hasProcessingActivities,
        showSystemDialog,
        setShowSystemDialog,
        hasSystems,
        showPolicyDialog,
        setShowPolicyDialog,
        showDeleteDialog,
        setShowDeleteDialog,
        selectedPolicy,
        setSelectedPolicy,
        refetch,
    };
}
