import { useState } from "react";
import { LegalEntitySelectorViewModel } from "../LegalEntity.types";
import { ILegalEntitySelector } from "./LegalEntitySelector";

export function useLegalEntitySelector(props: ILegalEntitySelector) {
    const [searchString, setSearchString] = useState("");

    const onSelectAllEntities = (selected: Array<LegalEntitySelectorViewModel>) => {
        props.setSelectedEntities(selected.map((x) => x.id));
    };

    const onCheckboxClick = (row: LegalEntitySelectorViewModel) => {
        if (props.selectedEntities.includes(row.id)) {
            props.setSelectedEntities(props.selectedEntities.filter((x) => x !== row.id));
        } else {
            props.setSelectedEntities([...props.selectedEntities, row.id]);
        }
    };

    const setHeaderCheckBoxState = () => {
        let allSelected = props.legalEntityItems?.every(function (item) {
            return props.selectedEntities.includes(item.id);
        });

        return props.selectedEntities?.length === 0 ? "false" : allSelected ? "true" : "indeterminate";
    };

    const isChecked = (id: string) => {
        return props.selectedEntities.includes(id);
    };

    const selectOptions = [
        { name: "10", id: "10" },
        { name: "25", id: "25" },
        { name: "50", id: "50" },
        { name: "100", id: "100" },
    ];

    return {
        selectOptions,
        searchString,
        setSearchString,
        onSelectAllEntities,
        onCheckboxClick,
        setHeaderCheckBoxState,
        isChecked,
    };
}
