import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useState, createElement } from "react";
import { useMutation } from "react-query";
import { useUserContext } from "../../../../auth/userContextProvider/UserContextProvider";
import { InternalAssessmentApiRow, InternalAssessmentViewRow } from "../../../../internalAssessment/InternalAssessments.types";
import { LegalEntityAuditStatus } from "../../../../legalEntity/legalEntityAuditTab/LegalEntityAudit.types";
import { SelectableItem } from "../../../../processingActivity/ProcessingActivity.types";
import { useUrlProvider } from "../../../../useUrlProvider";
import { get, put } from "../../../api/apiShared";
import { downloadFile } from "../../../api/downloadFile";
import { useStateUrlParamsArray } from "../../../hooks/useStateUrlParams";
import { createPdfUrl } from "../../../pdfUrlHelper";
import { getQuestionnaireResponseScore } from "../../../questionnaireResponseScore";
import { getSettings } from "../../../settingsProvider";
import { isNullOrWhitespace, setFirstLetterToLowerCase } from "../../../stringOperations";
import { EvaluationStatus } from "../evaluateQuestionnaireDialog/EvaluateQuestionnaireDialog.types";
import { useTranslation } from "../../../../localization/useTranslation";
import { IInternalAssessmentTableProps } from "./InternalAssessmentTable";
import { Trans } from "react-i18next";

export default function useInternalAssessmentTableHooks(props: IInternalAssessmentTableProps) {
    const snackbar = useDotLegalSnackbar();
    const { language } = useUserContext();
    const { translateLegalEntityAuditStatus, translateDate, translateString } = useTranslation();
    const { getInternalAssessmentQuestionnaireResponseReport, getQuestionnaireResponseExternalreport } = useUrlProvider();

    const [selectedInternalAssessment, setSelectedInternalAssessment] = useState<InternalAssessmentApiRow | undefined>(undefined);
    const [showNote, setShowNote] = useState(false);
    const [isDownloadingPdf, setIsDownloadingPdf] = useState("");
    const [selectedTemplates, setSelectedTemplates] = useStateUrlParamsArray("templates", []);
    const [selectedAreas, setSelectedAreas] = useStateUrlParamsArray("areas", []);
    const [selectedStatus, setSelectedStatus] = useStateUrlParamsArray("status", []);
    const [selectedResponsibles, setSelectedResponsibles] = useStateUrlParamsArray("responsibles", []);
    const [selectedEvaluations, setSelectedEvaluations] = useStateUrlParamsArray("evaluations", []);

    const url = `/internalAssessments`;

    let audits;
    let selectableStatus: Array<SelectableItem> = [];
    let selectableEvaluationStatus: Array<SelectableItem> = [];
    if (props.internalAssessmentModel) {
        selectableStatus = getSelectableStatus();
        selectableEvaluationStatus = getSelectableEvaluationStatus();
        audits = getFilteredRows(props.internalAssessmentModel.rows);
    }

    const onDownloadReportAsPdf = async (viewModel: InternalAssessmentApiRow, isSimpleReport: boolean, showRecommendations: boolean) => {
        setIsDownloadingPdf(viewModel.id);
        let url = isSimpleReport ? getExternalPdfUrl(viewModel, true, showRecommendations) : getReportPdfUrl(viewModel, true, showRecommendations);
        url = getSettings().mainSiteBaseUrl + url;
        url = createPdfUrl(url, language);

        const fileName = `${viewModel.name}_${viewModel.sender}_${translateDate(viewModel.deadline)}.pdf`;
        await downloadFile(getSettings().apiBaseUrl + "/pdf/generate?noMargin=true&url=" + url, fileName);
        setIsDownloadingPdf("");
        snackbar.show(createElement(Trans, { i18nKey: "pdfDownloadComplete", values: { fileName: fileName } }));
    };

    function getReportPdfUrl(row: InternalAssessmentApiRow, pdf: boolean, showRecommendations: boolean) {
        const respondent = translateString("theRespondent").toLowerCase();
        return getInternalAssessmentQuestionnaireResponseReport(
            row.questionnaireResponseId,
            row.deadline,
            row.sender,
            row.name,
            pdf,
            showRecommendations,
            respondent
        );
    }

    function getExternalPdfUrl(row: InternalAssessmentApiRow, pdf: boolean, showRecommendations: boolean) {
        const respondent = translateString("theRespondent").toLowerCase();
        return getQuestionnaireResponseExternalreport(row.responseExternalId, row.deadline, row.sender, respondent, row.name, pdf, showRecommendations, false);
    }

    const saveNoteMutation = useMutation(submitSaveNote);
    function submitSaveNote(note: string) {
        return put(`/internalAssessments/${selectedInternalAssessment!.id}/note`, note);
    }

    const saveNote = (note: string) => {
        saveNoteMutation.mutateAsync(note, {
            onSuccess: () => {
                snackbar.show(translateString("noteUpdated"));
                setShowNote(false);
                props.refetch!();
            },
        });
    };

    const auditsViewModelRows: undefined | Array<InternalAssessmentViewRow> = audits?.map((r) => {
        return {
            ...r,
            responsibleName: r.responsible.name,
            assigneeName: r.assignee.name,
            complianceAreasString: r.complianceAreas.join(", "),
            questionnaireName: r.assessmentTemplate.name,
            statusString: translateLegalEntityAuditStatus(r.status.toString()),
            scoreName: getQuestionnaireResponseScore(
                r.status === LegalEntityAuditStatus.Submitted,
                r.scoreEnabled,
                r.score,
                translateString("scoreNotEnabled")
            ),
            evaluationName: r.evaluationStatus ? translateString(setFirstLetterToLowerCase(EvaluationStatus[r.evaluationStatus].toString())) : "",
        };
    });

    return {
        audits: auditsViewModelRows,
        hasData: props.isLoading || (props.internalAssessmentModel?.rows && props.internalAssessmentModel.rows.length > 0),
        selectableTemplates: props.internalAssessmentModel?.selectableAssessmentTemplate,
        selectedTemplates,
        setSelectedTemplates,
        selectableAreas: props.internalAssessmentModel?.selectableComplianceAreas,
        selectedAreas,
        setSelectedAreas,
        selectableResponsibles: props.internalAssessmentModel?.selectableResponsibles,
        selectedResponsibles,
        setSelectedResponsibles,
        selectableStatus,
        selectedStatus,
        setSelectedStatus,
        selectedInternalAssessment,
        setSelectedInternalAssessment,
        getReportPdfUrl,
        isDownloadingPdf,
        onDownloadReportAsPdf,
        showNote,
        setShowNote,
        saveNote,
        savingNote: saveNoteMutation.isLoading,
        selectableEvaluationStatus,
        selectedEvaluations,
        setSelectedEvaluations,
    };

    function getSelectableStatus() {
        var temp = props.internalAssessmentModel!.rows.map((x) => {
            return { id: x.status.toString(), name: translateLegalEntityAuditStatus(x.status.toString()) };
        });

        return temp?.filter((item, index) => temp?.findIndex((f) => f.id === item.id) === index);
    }

    function getSelectableEvaluationStatus() {
        var temp = props
            .internalAssessmentModel!.rows.filter((x) => x.evaluationStatus)
            .map((x) => {
                return {
                    id: x.evaluationStatus!.toString(),
                    name: translateString(setFirstLetterToLowerCase(EvaluationStatus[x.evaluationStatus!].toString())),
                };
            });

        return temp?.filter((item, index) => temp?.findIndex((f) => f.id === item.id) === index);
    }

    function getFilteredRows(rows: Array<InternalAssessmentApiRow>) {
        let tempData = [...rows];

        if (hasFilterData(selectedTemplates)) {
            tempData = tempData.filter((row) => selectedTemplates.includes(row.questionnaireId));
        }

        if (hasFilterData(selectedAreas)) {
            tempData = tempData.filter((activity) => activity.complianceAreas.filter((x) => selectedAreas.includes(x.id)).length > 0);
        }

        if (hasFilterData(selectedStatus)) {
            tempData = tempData.filter((row) => selectedStatus.includes(row.status.toString()));
        }

        if (hasFilterData(selectedEvaluations)) {
            tempData = tempData.filter((row) => row.evaluationStatus && selectedEvaluations.includes(row.evaluationStatus.toString()));
        }

        if (hasFilterData(selectedResponsibles)) {
            tempData = tempData.filter((row) => selectedResponsibles.includes(row.responsible.id));
        }

        return tempData;
    }

    function hasFilterData(filter: Array<string>) {
        return filter.length > 0 && !isNullOrWhitespace(filter[0]);
    }
}
