import { Box, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "../../../localization/useTranslation";
import { DotLegalInformationTooltip, DotLegalSelect, DotLegalTooltip } from "@dotlegal/dotlegal-ui-components";
import { useDotLegalCompanySelectorStyles } from "./DotLegalCompanySelector.styles";
import { SelectableCompany } from "./DotLegalCompanySelector.types";
import { isNullOrWhitespace } from "../../stringOperations";
import { useDotLegalCompanySelector } from "./GroupCompanySelectorContext";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";

export interface IDotLegalCompanySelector {
    companies: Array<SelectableCompany>;
    isLoading: boolean;
}

function DotLegalCompanySelector(props: IDotLegalCompanySelector) {
    const styles = useDotLegalCompanySelectorStyles();
    const { translateString } = useTranslation();

    const [showWarningTriangle, setShowWarningTriangle] = useState<boolean>(true);
    const { selectedGroupCompany: selectedCompany, setSelectedGroupCompany: setSelectedCompany } = useDotLegalCompanySelector();

    let allCompanies = [...props.companies];

    if (allCompanies.filter((x) => x.id === "null").length === 0) {
        allCompanies.unshift({ id: "null", name: translateString("allGroupEntities") });
    }

    const getContent = () => {
        if (props.isLoading || props.companies.length > 1) {
            return (
                <Box sx={styles.container}>
                    {props.isLoading ? (
                        <Skeleton variant="rectangular" sx={{ borderRadius: "10px" }} width={220} height={39} />
                    ) : (
                        <Box sx={{ display: "flex" }}>
                            <DotLegalSelect
                                noMargin
                                width="220px"
                                options={allCompanies}
                                onChange={(groupEntityId) => {
                                    setShowWarningTriangle(false);
                                    setSelectedCompany(groupEntityId && groupEntityId === "null" ? undefined : (groupEntityId ?? undefined));
                                }}
                                label={translateString("changeOrganisation")}
                                noOptionsLabel={""}
                                selectedItem={isNullOrWhitespace(selectedCompany) ? "null" : selectedCompany}
                                backgroundColor="white"
                            />
                            {showWarningTriangle && selectedCompany && selectedCompany !== "null" && (
                                <DotLegalTooltip text={translateString("specificCompanyChosenTooltip")}>
                                    <ReportProblemOutlinedIcon
                                        sx={(theme) => ({ height: 20, width: 20, alignSelf: "center", marginLeft: 1, fill: theme.palette.primary.main })}
                                    />
                                </DotLegalTooltip>
                            )}
                        </Box>
                    )}
                </Box>
            );
        }

        return undefined;
    };

    return <React.Fragment>{getContent()}</React.Fragment>;
}

export default DotLegalCompanySelector;
