import { usePlanContext } from "./planProvider/PlanProvider";
import { useUserContext } from "./userContextProvider/UserContextProvider";

export function usePermissionsProvider() {
    const { platformFeatures } = usePlanContext();
    const { permissions } = useUserContext();

    function hasAnnualWheelActivityAccess() {
        return platformFeatures.annualWheelOverview && permissions.annualWheelPermission.read;
    }

    function hasTaskManagementAccess() {
        return platformFeatures.annualWheelOverview && permissions.taskPermissions.read;
    }

    const hasTagsAccess = platformFeatures.tags && permissions.canAccessTags;

    return {
        hasAnnualWheelActivityAccess,
        hasTaskManagementAccess,
        hasTagsAccess,
    };
}
