export function _arrayBufferToBase64(buffer: ArrayBuffer): Promise<string> {
    const blob = new Blob([buffer], { type: "application/octet-binary" });
    return new Promise(function (resolve, reject) {
        const fileReader = new FileReader();
        fileReader.onload = function () {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const dataUrl: string = <string>fileReader.result!;
            const base64 = dataUrl?.substr(dataUrl.indexOf(",") + 1);
            resolve(base64);
        };

        fileReader.onerror = function () {
            reject(fileReader);
        };
        fileReader.readAsDataURL(blob);
    });
}
