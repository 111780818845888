import {
    LegalEntityAuditInformationViewModel,
    LegalEntityClassificationInformationViewModel,
} from "../../legalEntityAuditGroup/legalEntityAuditGroupDraftOverview/auditGroupLegalEntitySelector/AuditGroupLegalEntitySelector.types";
import { SelectableColoredItem, SelectableItem } from "../../processingActivity/ProcessingActivity.types";

export interface LegalEntitiesViewModel {
    legalEntityRows: Array<LegalEntitiesRowViewModel>;
}

export interface LegalEntitiesRowViewModel {
    legalEntityId: string;
    name: string;
    countryCode: string;
    isApproved: boolean;
    businessAreas: Array<SelectableColoredItem>;
    certifications: Array<SelectableItem>;
    complianceAreas: Array<SelectableItem>;
    classifications: Array<LegalEntityClassificationInformationViewModel>;
    responsible: SelectableItem | null;
    latestAudit: LegalEntityAuditInformationViewModel | null;
    assets: Array<SelectableItem>;
    audits: Array<LegalEntityAuditInformationViewModel>;
    isActive: boolean;
}

export interface LegalEntityRowModel extends LegalEntitiesRowViewModel {
    countryName: string;
    businessAreaNames: string;
    responsibleName: string | undefined;
}

export interface LegalEntityAuditInformationsDto {
    legalEntityIds: string[];
    fetchLatestAudit: boolean;
}

export enum LegalEntityPageEnum {
    Vendor = 1,
    Customer = 2,
    GroupEntity = 3,
    Other = 4,
}

export enum LegalEntityMasterType {
    Vendor = 1,
    Customer = 2,
    GroupEntity = 3,
    Other = 4,
}
