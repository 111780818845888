import { Result, useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useMutation } from "react-query";
import { IDeleteDeclarationControlDialog } from "./DeleteDeclarationControlDialog";
import { createElement } from "react";
import { Trans } from "react-i18next";
import { deleteHttp } from "../../../common/api/apiShared";

export default function useDeclarationControlDialogHooks(props: IDeleteDeclarationControlDialog) {
    const snackbar = useDotLegalSnackbar();

    const removeMutation = useMutation(remove);
    const ondDeleteControlClick = () => {
        removeMutation.mutateAsync(undefined, {
            onSuccess: (response: any) => {
                props.onDeleted();
                snackbar.show(createElement(Trans, { i18nKey: "itemDeletedSuccess", values: { name: props.selectedControl.name } }));
            },
        });
    };

    async function remove() {
        return await deleteHttp<string>(`/declarationControl/${props.selectedControl.id}`);
    }

    return {
        ondDeleteControlClick,
        inProgess: removeMutation.isLoading,
    };
}
