export class PolicyDocumentSaveModel {
    base64Data: string | undefined;
    fileName: string;
    name: string;
    link: string | undefined;
    isLink: boolean;

    constructor(name?: string, link?: string) {
        this.base64Data = undefined;
        this.fileName = "";
        this.name = name ?? "";
        this.link = link;
        this.isLink = link ? true : false;
    }
}
