import { Box, Typography } from "@mui/material";
import React from "react";
import { DotLegalDialog, DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { SelectableItem } from "../../../processingActivity/ProcessingActivity.types";
import { SystemOverviewDataSubjectDialogStyles } from "./SystemOverviewDataSubjectsDialog.styles";

export interface ISystemOverviewDataSubjectsDialogProps {
    onCloseDialog: () => void;
    dataSubjects: Array<SelectableItem> | undefined;
}

function SystemOverviewDataSubjectsDialog(props: ISystemOverviewDataSubjectsDialogProps) {
    const { translateString } = useTranslation();
    const styles = SystemOverviewDataSubjectDialogStyles();

    return (
        <DotLegalDialog
            header={translateString("dataSubjects")}
            buttonOkText={""}
            hideOkButton
            open
            onDialogClose={() => props.onCloseDialog()}
            onOkClick={() => {}}
            size="sm"
        >
            <Typography sx={styles.dialogDescription}>{translateString("systemDataSubjectDialogDescription")}</Typography>

            {props.dataSubjects?.map((dataSubject) => {
                return (
                    <Box key={dataSubject.id} sx={styles.dataSubjectContainer}>
                        <DotLegalHeader headerStyle={"extraSmall"} fontWeight={400}>
                            {dataSubject.name}
                        </DotLegalHeader>
                    </Box>
                );
            })}
        </DotLegalDialog>
    );
}

export default SystemOverviewDataSubjectsDialog;
