import React from "react";
import { IAddLegalEntityDialog } from "./AddLegalEntityDialog";
import { useTranslation } from "../../localization/useTranslation";
import { useAddEditLegalEntityHook } from "./AddLegalEntityDialog.hooks";
import { DotLegalDialog, DotLegalSelect, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { Collapse } from "@mui/material";
import { LevelOfProtectionType } from "./AddLegalEntityDialog.types";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { ProcessingActivityStatusManagement } from "../../auth/userContextProvider/UserContextProvider.types";

function AddSimpleLegalEntityDialog(props: IAddLegalEntityDialog) {
    const { translateString } = useTranslation();
    const {
        countriesIsLoading,
        viewModel,
        setViewModel,
        getSelectableCountries,
        onSaveClick,
        saveMutationLoading,
        validation,
        showProtectionLevel,
        lvlOfProtectionOptions,
    } = useAddEditLegalEntityHook(props);

    const { permissions } = useUserContext();

    validation?.check(viewModel);

    return (
        <DotLegalDialog
            header={translateString("createCompany")}
            buttonOkText={translateString("create")}
            secondaryButtonText={translateString("cancel")}
            size="sm"
            open
            onOkClick={() => {
                onSaveClick();
            }}
            onDialogClose={props.onDialogClose}
            inProgress={saveMutationLoading}
            disableBackdropOnCloseIfValueChange={viewModel}
        >
            <DotLegalTextField
                label={translateString("name")}
                value={viewModel?.name ?? null}
                onChange={(name) => {
                    const newLegalEntityModel = { ...viewModel! };
                    newLegalEntityModel.name = name;
                    setViewModel(newLegalEntityModel);
                }}
                errorText={validation?.getErrorsForDisplay("name")}
                debounce={false}
            />

            <DotLegalTextField
                label={translateString("address")}
                value={viewModel?.address ?? null}
                onChange={(address) => {
                    const newLegalEntityModel = { ...viewModel! };
                    newLegalEntityModel.address = address;
                    setViewModel(newLegalEntityModel);
                }}
                debounce={false}
            />

            <DotLegalTextField
                label={translateString("zipCode")}
                value={viewModel?.zipCode ?? null}
                onChange={(zipCode) => {
                    const newLegalEntityModel = { ...viewModel! };
                    newLegalEntityModel.zipCode = zipCode;
                    setViewModel(newLegalEntityModel);
                }}
                debounce={false}
            />

            <DotLegalTextField
                label={translateString("city")}
                value={viewModel?.city ?? null}
                onChange={(city) => {
                    const newLegalEntityModel = { ...viewModel! };
                    newLegalEntityModel.city = city;
                    setViewModel(newLegalEntityModel);
                }}
                debounce={false}
            />

            <DotLegalSelect
                isLoading={countriesIsLoading}
                label={translateString("country")}
                selectedItem={viewModel?.country}
                onChange={(country) => {
                    const newLegalEntityModel = { ...viewModel! };
                    newLegalEntityModel.country = country != null ? country : "";
                    newLegalEntityModel.levelOfProtection = null;

                    if (permissions.processingActivityStatusManagement === ProcessingActivityStatusManagement.Draft) {
                        if (country === "CA" || country === "US") {
                            newLegalEntityModel.levelOfProtection = LevelOfProtectionType.ThirdCountry;
                        }
                    }

                    setViewModel(newLegalEntityModel);
                }}
                options={getSelectableCountries()}
                placeholder={translateString("country")}
                errorText={validation?.getErrorsForDisplay("country")}
                noOptionsLabel={translateString("noOptions")}
            />

            <Collapse in={showProtectionLevel && permissions.processingActivityStatusManagement !== ProcessingActivityStatusManagement.Draft}>
                <DotLegalSelect
                    label={translateString("levelOfProtection")}
                    options={lvlOfProtectionOptions}
                    placeholder={translateString("levelOfProtection")}
                    selectedItem={viewModel?.levelOfProtection ? LevelOfProtectionType[viewModel!.levelOfProtection] : null}
                    onChange={(protection) => {
                        const newLegalEntityModel = { ...viewModel! };
                        newLegalEntityModel.levelOfProtection =
                            protection !== null ? LevelOfProtectionType[protection as keyof typeof LevelOfProtectionType] : null;
                        setViewModel(newLegalEntityModel);
                    }}
                    errorText={validation?.getErrorsForDisplay("levelOfProtection")}
                    noOptionsLabel={translateString("noOptions")}
                />
            </Collapse>
        </DotLegalDialog>
    );
}

export default AddSimpleLegalEntityDialog;
