import React, { useContext } from "react";
import { createContext } from "react";
import { useStateSessionStorage } from "../../hooks/useStateSessionStorage";

export interface GroupCompanySelectorModel {
    selectedGroupCompany: string | undefined;
    setSelectedGroupCompany: (selectedGroupCompany: string | undefined) => void;
}

export const GroupCompanySelectorContext = createContext<GroupCompanySelectorModel>({
    selectedGroupCompany: undefined,
    setSelectedGroupCompany: (selectedGroupCompany: string | undefined) => {},
});

export interface IGroupCompanySelectorProviderProps {
    children: any;
}

export const useDotLegalCompanySelector = () => {
    return useContext(GroupCompanySelectorContext);
};

export function GroupCompanySelectorProvider(props: IGroupCompanySelectorProviderProps) {
    const [selectedGroupCompany, setSelectedGroupCompany] = useStateSessionStorage<string | undefined>("dot-legal-selected-company", undefined);

    return (
        <GroupCompanySelectorContext.Provider value={{ selectedGroupCompany, setSelectedGroupCompany }}>{props.children}</GroupCompanySelectorContext.Provider>
    );
}

export default GroupCompanySelectorProvider;
