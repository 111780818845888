import { DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import { TrialPlanViewModel } from "../../trial/Trial.types";
import { Trans } from "react-i18next";
import { useTranslation } from "../../localization/useTranslation";
import { Box } from "@mui/material";

export interface IPlanStatusMessageProps {
    plan: TrialPlanViewModel;
}

function PlanStatusMessage(props: IPlanStatusMessageProps) {
    const { translatePlanType, translateDate, translateString } = useTranslation();
    const plan = props.plan;

    function getContent() {
        if (plan.isAlmostExpired) {
            return (
                <Box sx={{ marginBottom: 1.5 }}>
                    <Trans
                        i18nKey={"trialSoonToExpireOnPlan"}
                        values={{
                            plan: translatePlanType(props.plan.planType),
                            date: plan?.trialExpirationDate ? translateDate(plan.trialExpirationDate) : null,
                        }}
                        components={{
                            span: (
                                <Box component={"span"} sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, color: theme.palette.primary.main })} />
                            ),
                        }}
                    />
                </Box>
            );
        } else if (plan.isExpired) {
            return (
                <Box sx={{ marginBottom: 1.5 }}>
                    <Trans
                        i18nKey={"planTrialHasExpired"}
                        values={{
                            plan: translatePlanType(props.plan.planType),
                        }}
                        components={{
                            span: (
                                <Box component={"span"} sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, color: theme.palette.primary.main })} />
                            ),
                        }}
                    />
                </Box>
            );
        }
    }

    return (
        <DotLegalHeader key={plan.planType} marginBottom={1} fontWeight={500} center headerStyle="small">
            {getContent()}
        </DotLegalHeader>
    );
}

export default PlanStatusMessage;
