export interface SystemDocumentSaveModel {
    base64Data: string | undefined;
    fileName: string | undefined;
    name: string;
    link: string | undefined;
}

export interface SystemDocumentUpdateModel {
    name: string;
}

export interface SystemDocumentRowViewModel {
    fileName: string | undefined;
    name: string;
    link: string | undefined;
    documentId: string;
    dataId: string | undefined;
}

export class SystemDocument {
    documentId: string;
    fileName: string;
    name: string;
    link: string;

    constructor() {
        this.documentId = "";
        this.fileName = "";
        this.name = "";
        this.link = "";
    }
}

export class SystemDocumentViewModel {
    name: string | undefined = undefined;

    constructor(name: string | undefined) {
        this.name = name;
    }
}
