import React from "react";
import DotLegalDeleteDialogWrapper from "../../../common/components/dotLegalDeleteDialog/DotLegalDeleteDialogWrapper";
import { BusinessAreaSaveModel } from "../businessAreaDialog/BusinessAreaDialog.types";
import { DeleteBusinessAreaDialogDataMapping } from "./DeleteBusinessAreaDialog.hooks";

export interface IDeleteBusinessAreaDialog {
    onCloseDialog: () => void;
    onDeletion: () => void;
    selectedBusinessArea: BusinessAreaSaveModel;
}

export function DeleteBusinessAreaDialog(props: IDeleteBusinessAreaDialog) {
    const { removePurpose, inProgess } = DeleteBusinessAreaDialogDataMapping(props);

    return (
        <DotLegalDeleteDialogWrapper
            open
            itemForDeletion={props.selectedBusinessArea.name}
            onDialogClose={props.onCloseDialog}
            onOkClick={removePurpose}
            inProgress={inProgess}
        />
    );
}
