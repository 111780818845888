import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, styled } from "@mui/material";
import DotLegalDragNDrop from "../../common/components/dotLegalDragNDrop/DotLegalDragNDrop";
import { DotLegalDialog, DotLegalHeader, DotLegalPrettyScrollbar, DotLegalSearchField, useIsOnSmallScreen } from "@dotlegal/dotlegal-ui-components";
import DotLegalInformationBox from "../../common/components/dotLegalInformationBox/DotLegalInformationBox";
import { useTranslation } from "../../localization/useTranslation";
import { CustomListType } from "../CustomLists.types";
import { useEditCustomListDialog } from "./EditCustomListDialog.hooks";
import { useEditCustomDialogStyles } from "./EditCustomListDialog.styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ColumnDisplayModel, ColumnDefinition, CustomListColumnViewModel } from "./EditCustomListDialog.types";
import ColumnTypeContext from "../columnTypeContext/ColumnTypeContext";
import Icon from "./dragNDropIcon.svg?react";
import HideColomnSvg from "./hideIcon.svg?react";
import FilterIcon from "./filterIcon.svg?react";
import { getTranslationKey, hasFilterData } from "../customListOperations";
import DotLegalFullScreenSpinner from "../../common/components/dotLegalFullScreenSpinner/DotLegalFullScreenSpinner";

export interface IEditCustomListDialogProps {
    onSaveClick: () => void;
    onCloseClick: () => void;
    type: CustomListType;
    listId: string | undefined;
}

const HeaderBox = styled(Box)(({ theme }) => ({
    height: "75px",
    marginBottom: theme.spacing(2),
    paddingLeft: 20 + "px",
    paddingRight: 30 + "px",
}));

const GridItem = styled(Grid)(({ theme }) => ({
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
}));

const SummaryBox = styled(Box)(({ theme }) => ({
    display: "inherit",
    alignItems: "center",
    gap: theme.spacing(1.5),
}));

function EditCustomListDialog(props: IEditCustomListDialogProps) {
    const styles = useEditCustomDialogStyles();
    const { translateString, translateCustomListType, translatePropertyName } = useTranslation();
    const {
        searchInput,
        setSearchInput,
        toggleSelectedColumnType,
        reorderSelectedColumnTypes,
        handleCollapseClick,
        getExpandedId,
        chosenColumns,
        isLoading,
        includeFilterFunc,
        allColumns,
    } = useEditCustomListDialog(props);

    const isOnSmallScreen = useIsOnSmallScreen();
    const getDragableDiv = (column: CustomListColumnViewModel, isMandatoryField: boolean) => {
        let label = translatePropertyName(getTranslationKey(column.type));
        const includeFilter = includeFilterFunc(column.type);
        return (
            <Accordion
                key={column.type}
                TransitionProps={{ unmountOnExit: true }}
                expanded={includeFilter ? column.type.toString() === getExpandedId() : false}
                onChange={() => handleCollapseClick(column.type.toString())}
            >
                <AccordionSummary expandIcon={includeFilter ? <ExpandMoreIcon /> : undefined}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "95%" }}>
                        <SummaryBox>
                            <Box sx={{ display: "inline-flex", width: 10, cursor: "grab" }}>{!isMandatoryField && <Icon />}</Box>
                            <DotLegalHeader marginBottom={0} headerStyle={"extraSmall"}>
                                {label}
                            </DotLegalHeader>
                        </SummaryBox>
                        <SummaryBox>
                            {hasFilterData(column.filter) && <FilterIcon />}
                            {column.hideColumn && <HideColomnSvg />}
                        </SummaryBox>
                    </Box>
                </AccordionSummary>
                {includeFilter && (
                    <AccordionDetails>
                        <ColumnTypeContext column={column} customListId={props.listId} showHideColumnCbk={!isMandatoryField} />
                    </AccordionDetails>
                )}
            </Accordion>
        );
    };

    const getDragableContent = () => {
        if (chosenColumns) {
            return chosenColumns
                .filter((x) => x.isMandatory === false)
                .map((x) => {
                    return {
                        id: x.type.toString(),
                        content: getDragableDiv(x, false),
                        columnWithDeniedAccess: null,
                    };
                });
        }
        return [];
    };

    function getSortedColumns(columnTypeContentArray: Array<ColumnDefinition>) {
        let columns: Array<ColumnDisplayModel> = [];
        columnTypeContentArray.forEach((x) => {
            let languageKey = getTranslationKey(x.type);
            let tooltip = translatePropertyName(languageKey + CustomListType[props.type] + "ToolTip");
            let header = translatePropertyName(languageKey);
            if (header.toLowerCase().indexOf(searchInput.toLowerCase()) > -1) {
                columns.push({ ...x, header: header, tooltip: tooltip });
            }
        });

        return columns.sort((a, b) => {
            if (a.isMandatory && !b.isMandatory) {
                return -1; // a should come before b
            }
            if (!a.isMandatory && b.isMandatory) {
                return 1; // b should come before a
            }
            // If both have the same isMandatory status, sort by header alphabetically
            const headerA = a.header.toUpperCase();
            const headerB = b.header.toUpperCase();
            if (headerA < headerB) {
                return -1;
            }
            if (headerA > headerB) {
                return 1;
            }
            return 0; // Names must be equal
        });
    }

    return (
        <DotLegalDialog size="lg" open buttonOkText={translateString("save")} onDialogClose={props.onCloseClick} onOkClick={props.onSaveClick} fullHeight>
            {isLoading ? (
                <DotLegalFullScreenSpinner />
            ) : (
                <Grid sx={{ height: "100%" }} container>
                    <GridItem item sm={12} md={6}>
                        <HeaderBox>
                            <DotLegalHeader headerStyle={"medium"}>
                                {translateString("listReportFor", { type: translateCustomListType(props.type.toString()) })}
                            </DotLegalHeader>
                            <DotLegalSearchField
                                labelSearch={translateString("search")}
                                useFullWidth
                                value={searchInput}
                                onChange={setSearchInput}
                                autoFocus
                                margin={{ marginBottom: 2 }}
                            />
                        </HeaderBox>
                        <DotLegalPrettyScrollbar>
                            {chosenColumns &&
                                getSortedColumns(allColumns).map((x) => {
                                    return (
                                        <Box key={x.type} sx={styles.templateContainer}>
                                            <DotLegalInformationBox
                                                isDisabled={x.isMandatory}
                                                size="small"
                                                header={x.header}
                                                fullWidth
                                                isSelected={chosenColumns!.some((d) => d.type === x.type) ? "selected" : "unselected"}
                                                onChange={(_) => {
                                                    if (!x.isMandatory) toggleSelectedColumnType(x.type, x.contentType).then();
                                                }}
                                                description={x.tooltip}
                                            />
                                        </Box>
                                    );
                                })}
                        </DotLegalPrettyScrollbar>
                    </GridItem>

                    {!isOnSmallScreen && <Divider sx={{ marginRight: -1 }} orientation="vertical" flexItem />}

                    <GridItem item sm={12} md={6}>
                        <HeaderBox>
                            <DotLegalHeader headerStyle={"medium"}>{translateString("selectedColumns")}</DotLegalHeader>
                            <DotLegalHeader marginBottom={0} headerStyle={"extraSmall"}>
                                {translateString("selectedColumnsInfo")}
                            </DotLegalHeader>
                        </HeaderBox>
                        <DotLegalPrettyScrollbar fullHeight>
                            {chosenColumns && <Box sx={styles.mandatoryContainer}>{getDragableDiv(chosenColumns?.find((x) => x.isMandatory)!, true)}</Box>}

                            <DotLegalDragNDrop
                                removeTopPadding
                                isLoading={false}
                                disableBackgroundColor
                                removeFullHeight
                                items={[{ title: "", tasks: getDragableContent() }]}
                                onDragEnd={reorderSelectedColumnTypes}
                                gridSizes={{ xs: 12 }}
                            />
                        </DotLegalPrettyScrollbar>
                    </GridItem>
                </Grid>
            )}
        </DotLegalDialog>
    );
}

export default EditCustomListDialog;
