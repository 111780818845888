import React from "react";
import StepSegment from "../../../../processingActivity/stepSegment/StepSegment";
import { DotLegalSelect } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../../localization/useTranslation";
import { LegalEntityAuditFrequency, LegalEntityAuditType } from "../../LegalEntityManagement.types";
import { getEnumValues } from "../../../../common/enumOperations";
import { setFirstLetterToLowerCase } from "../../../../common/stringOperations";
import { legalEntityAuditFrequencyOptions, legalEntityAuditTypeOptions } from "./legalEntityAuditStep.hooks";

export interface ILegalEntityAuditStep {
    auditType?: LegalEntityAuditType;
    auditFrequency?: LegalEntityAuditFrequency;
    onAuditTypeChange: (auditType?: LegalEntityAuditType) => void;
    onAuditFrequencyChange: (auditfrequency?: LegalEntityAuditFrequency) => void;
}

function LegalEntityAuditStep(props: ILegalEntityAuditStep) {
    const { translateString } = useTranslation();

    return (
        <React.Fragment>
            <StepSegment size="small">
                <DotLegalSelect
                    options={legalEntityAuditTypeOptions(translateString)}
                    onChange={(type) => {
                        if (type) {
                            props.onAuditTypeChange(Number(type));
                        } else {
                            props.onAuditTypeChange(undefined);
                        }
                    }}
                    selectedItem={props.auditType ? props.auditType.toString() : undefined}
                    placeholder={translateString("legalEntityAuditType")}
                    label={translateString("legalEntityAuditType")}
                    noOptionsLabel={""}
                ></DotLegalSelect>
                <DotLegalSelect
                    options={legalEntityAuditFrequencyOptions(translateString)}
                    onChange={(frequency) => {
                        if (frequency) {
                            props.onAuditFrequencyChange(Number(frequency));
                        } else {
                            props.onAuditFrequencyChange(undefined);
                        }
                    }}
                    selectedItem={props.auditFrequency ? props.auditFrequency.toString() : undefined}
                    placeholder={translateString("legalEntityFrequency")}
                    label={translateString("legalEntityFrequency")}
                    noOptionsLabel={""}
                ></DotLegalSelect>
            </StepSegment>
        </React.Fragment>
    );
}

export default LegalEntityAuditStep;
