import { useTaskStatusStyles } from "./TaskStatus.styles";
import { Box, Typography } from "@mui/material";
import { DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import React from "react";
import { useUrlProvider } from "../../useUrlProvider";
import DotLegalLink from "../../common/components/dotLegalLink/DotLegalLink";

export interface ITaskStatus {
    activityName: string;
    taskName?: string;
    processingActivtiyId: string | null;
    customTaskId: string | undefined | null;
}

function TaskStatus(props: ITaskStatus) {
    const styles = useTaskStatusStyles();
    const { getProcessingActivityOverviewUrl } = useUrlProvider();

    return (
        <Box sx={styles.headerContainer}>
            <Box sx={{ marginRight: 0.5 }}>
                <DotLegalHeader marginBottom={0} headerStyle={"small"}>
                    {props.customTaskId}
                </DotLegalHeader>
            </Box>
            <DotLegalHeader marginBottom={0} headerStyle={"small"}>
                {props.activityName}
            </DotLegalHeader>
            {props.taskName && (
                <React.Fragment>
                    <Typography sx={styles.seperator}>/</Typography>
                    <DotLegalHeader marginBottom={0} color="secondary" headerStyle={"small"}>
                        {props.processingActivtiyId !== null ? (
                            <DotLegalLink to={getProcessingActivityOverviewUrl(props.processingActivtiyId!)}>{props.taskName}</DotLegalLink>
                        ) : (
                            <span>{props.taskName}</span>
                        )}
                    </DotLegalHeader>
                </React.Fragment>
            )}
        </Box>
    );
}

export default TaskStatus;
