import { AuditGroupLegalEntityRowApiModel } from "./auditGroupLegalEntitySelector/AuditGroupLegalEntitySelector.types";

export interface LegalEntityAuditGroupDraftOverviewModel {
    id: string;
    name: string;
    description?: string;
    sent?: Date;
    auditTemplate: string;
    auditTemplateId: string;
    auditTemplateVersion: number;
    questionnaireIntroductionTitle?: string;
    deadline: Date;
    responsible: string;
    responsibleId: string;
    sender?: string;
    senderId?: string;
    selectedEntities: Array<LegalEntityAuditGroupDraft>;
    rows: Array<AuditGroupLegalEntityRowApiModel>;
    isDeadlineOverdue: boolean;
}

export class LegalEntityAuditGroupDraft {
    legalEntityId: string = "";
    contactId?: string = undefined;
    systemIds: Array<string> = [];

    constructor(legalEntityId: string, contactId: string | undefined, systemIds: Array<string>) {
        this.legalEntityId = legalEntityId;
        this.contactId = contactId;
        this.systemIds = systemIds;
    }

    IsModelInvalid = () => {
        if (this.contactId) {
            return false;
        }

        return true;
    };
}
