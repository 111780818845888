import { useTheme } from "@mui/material";
import { createSxStyles } from "../../../common/createSxStyles";

export const useDataProcessorsStepStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        centerContainer: {
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            position: "relative",
            flexDirection: "column",
        },
        addNewProcess: {
            marginTop: theme.spacing(3),
            display: "flex",
            justifyContent: "center",
            gap: 1,
        },
        collapse: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        subProcessorRadio: {
            display: "flex",
            justifyContent: "center",
            marginBottom: theme.spacing(3),
            [theme.breakpoints.down("xs")]: {
                flexDirection: "column",
            },
        },
        yesSubProcessor: {
            marginRight: theme.spacing(3),
            [theme.breakpoints.down("xs")]: {
                marginRight: 0,
                marginBottom: theme.spacing(2),
            },
        },
        segmentDivider: {
            marginBottom: theme.spacing(6),
        },
        collapseArrow: {
            fill: theme.palette.secondary.main,
            transform: "rotate(180deg)",
            marginRight: theme.spacing(1.5),
            marginLeft: theme.spacing(1.5),
        },
        errorBorder: {
            borderColor: theme.palette.error.main,
        },
        spinnerWrapper: {
            display: "flex",
            justifyContent: "center",
            marginTop: theme.spacing(2),
        },
        systemImage: {
            "& .collapseSVG svg": {
                stroke: theme.palette.primary.main,
                fill: theme.palette.primary.light,
                width: theme.spacing(3),
                height: theme.spacing(3),
            },
        },
        disableDataprocessor: {
            display: "flex",
            justifyContent: "center",
            paddingTop: theme.spacing(2),
            flexDirection: "column",
            alignItems: "center",
        },
        error: {
            fontSize: theme.typography.pxToRem(12),
            color: theme.palette.error.main,
            textAlign: "center",
            marginTop: theme.spacing(1),
        },
        subProcessorWrapper: {
            width: "100%",
            marginBottom: theme.spacing(3),
        },
    });
};
