import { DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import { Backdrop, Box, Paper } from "@mui/material";
import React from "react";
import { useTranslation } from "../../localization/useTranslation";
import CommentButton from "../commentButton/CommentButton";
import { useCommentDialogDataMapping } from "./CommentDialog.hooks";
import { useCommentDialogStyle } from "./CommentDialog.styles";
import { CSSTransition } from "react-transition-group";
import cssStyles from "./CommentDialog.module.css";
import CommentOverview from "../commentOverview/CommentOverview";
import { ProcessingActivityCommentApiModel, CommentViewModel } from "../commentCard/CommentCard.types";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";

export interface ICommentDialog {
    processingActivityId: string;
    header?: string;
    disabled?: boolean;
}

function CommentDialog(props: ICommentDialog) {
    const { translateString, translateDate } = useTranslation();
    const { customerName } = useUserContext();
    const {
        showCommentDialog,
        data,
        toggleCommentDialog,
        onDeleteComment,
        onDeleteSubComment,
        onCreated,
        isLoading,
        onUpdateComment,
        setDialogHovered,
        createdCommentId,
        numberOfUnreadComments,
        onSubCommentCreated,
        onUpdateSubComment,
        onSubscriptionChange,
        subscriptionSaving,
        subscribed,
    } = useCommentDialogDataMapping(props);

    const styles = useCommentDialogStyle();

    function mapApiModelsToViewModel(apiModels: Array<ProcessingActivityCommentApiModel> | undefined): Array<CommentViewModel> {
        if (apiModels === undefined) {
            return [];
        }

        return (
            apiModels.map((comment) => {
                return {
                    content: comment.content,
                    id: comment.id,
                    date: `${translateDate(comment.created)} ${comment.isEdited ? `(${translateString("edited").toLowerCase()})` : ""}`,
                    isEdited: comment.isEdited,
                    isOwner: comment.isOwner,
                    isStatusChange: comment.isStatusChange,
                    currentStatus: comment.currentStatus,
                    pinned: false,
                    previousStatus: comment.previousStatus,
                    title: `${comment.userFullName ? comment.userFullName : translateString("deletedUser")}`,
                    subComments:
                        comment.subComments.map((subComment) => {
                            return {
                                content: subComment.content,
                                id: subComment.id,
                                date: `${translateDate(subComment.created)} ${subComment.isEdited ? `(${translateString("edited").toLowerCase()})` : ""}`,
                                isEdited: subComment.isEdited,
                                isOwner: subComment.isOwner,
                                isStatusChange: false,
                                currentStatus: subComment.currentStatus,
                                pinned: false,
                                previousStatus: subComment.previousStatus,
                                title: `${subComment.userFullName ? subComment.userFullName : translateString("deletedUser")}`,
                                subComments: [],
                            };
                        }) ?? [],
                };
            }) ?? []
        );
    }

    const nodeRef = React.useRef(null); /* In place for CSSTransition to work with strictMode */
    return (
        <React.Fragment>
            <Backdrop
                open={showCommentDialog}
                sx={{
                    zIndex: 1201,
                }}
                onMouseDown={() => toggleCommentDialog()}
            >
                <CSSTransition
                    timeout={300}
                    classNames={{
                        enter: cssStyles.itemEnter,
                        enterActive: cssStyles.itemEnterActive,
                        exit: cssStyles.itemExit,
                        exitActive: cssStyles.itemExitActive,
                    }}
                    in={showCommentDialog}
                    unmountOnExit
                    appear
                    nodeRef={nodeRef}
                >
                    <>
                        {showCommentDialog && (
                            <>
                                <Paper onMouseEnter={() => setDialogHovered(true)} onMouseLeave={() => setDialogHovered(false)} sx={styles.paper}>
                                    <Box sx={styles.paperContainer}>
                                        <Box sx={{ flexShrink: 0 }}>
                                            <DotLegalHeader marginBottom={4} headerStyle="small">
                                                {translateString("commentsOn", { name: props.header })}
                                            </DotLegalHeader>
                                        </Box>

                                        <CommentOverview
                                            id={props.processingActivityId}
                                            isLoading={isLoading}
                                            data={mapApiModelsToViewModel(data)}
                                            subscribed={subscribed ?? false}
                                            isSubscriptionSaving={subscriptionSaving}
                                            maxHeight={"80vh"}
                                            scrollToBottomOnNewComment={showCommentDialog}
                                            commentIdToScroll={createdCommentId}
                                            showEmptyState
                                            notificationText={translateString("notifyUserOnComments")}
                                            onSubscriptionChanged={(subscribed) => onSubscriptionChange(subscribed)}
                                            onDeleteComment={onDeleteComment}
                                            onDeleteSubComment={onDeleteSubComment}
                                            onCreateComment={onCreated}
                                            onCreateSubComment={onSubCommentCreated}
                                            onUpdateComment={onUpdateComment}
                                            onUpdateSubComment={onUpdateSubComment}
                                            disabled={props.disabled}
                                            disabledText={
                                                props.disabled
                                                    ? translateString("contactCustomerForCommentForProcesingActivity", { customerName: customerName })
                                                    : undefined
                                            }
                                        />
                                    </Box>
                                </Paper>
                            </>
                        )}
                    </>
                </CSSTransition>
            </Backdrop>

            <CommentButton unreadComments={numberOfUnreadComments} onButtonClick={() => toggleCommentDialog()} id={props.processingActivityId} />
        </React.Fragment>
    );
}

export default CommentDialog;
