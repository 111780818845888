import React from "react";
import { Route, Routes } from "react-router-dom";
import ErrorPage from "./errorPage/ErrorPage";
import { useTranslation } from "./localization/useTranslation";

export interface IErrorRouterSwitch {
    children: any;
}

function ErrorRouterSwitch(props: IErrorRouterSwitch) {
    const { translateString } = useTranslation();
    return (
        <Routes>
            <Route path="privacy">
                <Route path="forbidden" element={<ErrorPage errorMessage={translateString("forbiddenAccess")} image="NoAccess" />} />
                <Route path="account-closed" element={<ErrorPage errorMessage={translateString("customerDeactivated")} image="NoAccess" />} />
                <Route path="error" element={<ErrorPage errorMessage={translateString("unexpectedError")} image="Technical" />} />
                <Route path="resource-not-found" element={<ErrorPage errorMessage={translateString("resourceNotFound")} image="NotFound" />} />
            </Route>
            <Route path="*" element={props.children} />
        </Routes>
    );
}

export default ErrorRouterSwitch;
