import React from "react";
import { DotLegalDialog, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { CallPage, CustomListType } from "../CustomLists.types";
import { useCreateCustomListDialog } from "./CreateCustomListDialog.hooks";

export interface ICreateCustomListDialogProps {
    onHideDialog: () => void;
    open: boolean;
    customListType: CustomListType;
    onSave: () => void;
    customList: { name: string; description: string; id: string };
    copy?: boolean;
    callPage?: CallPage;
}

function CreateCustomListDialog(props: ICreateCustomListDialogProps) {
    const { translateString } = useTranslation();
    const { inProgress, createCustomList, saveModel, setSaveModel, isNameValid, isEdit } = useCreateCustomListDialog(props);

    return (
        <DotLegalDialog
            inProgress={inProgress}
            size="sm"
            buttonOkText={isEdit ? translateString("save") : translateString("create")}
            okButtonDisabled={!isNameValid.current}
            header={isEdit ? translateString("editCustomList") : translateString("createNewCustomList")}
            onDialogClose={props.onHideDialog}
            open={props.open}
            onOkClick={async () => {
                createCustomList();
            }}
            disableBackdropOnCloseIfValueChange={saveModel}
        >
            <DotLegalTextField
                debounce={false}
                size="small"
                label={translateString("name")}
                value={saveModel.name}
                onChange={(x) => {
                    let tempModel = { ...saveModel };
                    tempModel.name = x;
                    setSaveModel(tempModel);
                }}
                errorText={isNameValid.current ? "" : translateString("nameRequired")}
            />

            <DotLegalTextField
                debounce={false}
                size="small"
                multiline
                rows={5}
                label={translateString("description")}
                value={saveModel.description}
                onChange={(value) => {
                    let tempModel = { ...saveModel };
                    tempModel.description = value;
                    setSaveModel(tempModel);
                }}
            />
        </DotLegalDialog>
    );
}

export default CreateCustomListDialog;
