import { createSxStyles } from "../../common/createSxStyles";

export const useSystemIconStyles = () => {
    return createSxStyles({
        icon: {
            width: "25px",
        },
        fullSize: {
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            maxHeight: "115px",
            maxWidth: "115px",
            width: "50%",
        },
    });
};
