import { createSxStyles } from "../../createSxStyles";
import { useTheme } from "@mui/material";

export const useDotLegalSecurityMeasureStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        header: {
            textAlign: "center",
            fontSize: 14,
            color: theme.palette.primary.dark,
            fontWeight: theme.typography.fontWeightBold,
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
        },
    });
};
