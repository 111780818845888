import { useState } from "react";
import { GetGdprIncidentLogPlanLimit } from "../../auth/planProvider/PlanProvider.hooks";
import { useQuery } from "react-query";
import { get } from "../../common/api/apiShared";
import { GdprIncidentLogListViewModel } from "../IncidentLogDialog.types";

export function useIncidentLogDataMapping() {
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const url = "/gdprIncidentLog";
    const query = useQuery(url, () => get<Array<GdprIncidentLogListViewModel>>(url));
    const [selectedIncident, setSelectedIncident] = useState<GdprIncidentLogListViewModel>();
    const [showDeleteIncidentDialog, setShowDeleteIncidentDialog] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);
    let incidentLogPlanLimit = GetGdprIncidentLogPlanLimit();

    const refetch = () => {
        query.refetch();
        incidentLogPlanLimit.refetch();
    };

    return {
        showCreateDialog,
        setShowCreateDialog,
        isLoading: query.isLoading,
        logEntries: query.data,
        refetch,
        selectedIncident,
        setSelectedIncident,
        showDeleteIncidentDialog,
        setShowDeleteIncidentDialog,
        showEditDialog,
        setShowEditDialog,
        incidentLogPlanLimit: incidentLogPlanLimit.data,
    };
}
