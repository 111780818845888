import React from "react";
import { useTranslation } from "../../localization/useTranslation";
import { Box, TableCell, TableRow } from "@mui/material";
import {
    DotLegalActionBar,
    DotLegalEmptyState,
    DotLegalOverflowMenu,
    DotLegalPageHeader,
    DotLegalPaper,
    DotLegalTableWithControls,
    ITableHeader,
} from "@dotlegal/dotlegal-ui-components";
import { useTags } from "./Tags.hooks";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { useMasterDataBreadCrumbProvider } from "../useMasterDataBreadCrumbProvider";
import { TagViewModel } from "./Tags.types";
import TagDialog from "./tagDialog/TagDialog";
import EmptyStateIcon from "../../common/svgs/emptyState.svg?react";
import DeleteTagDialog from "./deleteTagDialog/DeleteTagDialog";

export interface TagsProps {}

function Tags() {
    const { translateString } = useTranslation();
    const { permissions, customerName } = useUserContext();
    const { getBreadCrumbs } = useMasterDataBreadCrumbProvider();
    const {
        isLoading,
        data,
        showTagDialog,
        setShowTagDialog,
        selectedTag,
        setSelectedTag,
        onTagSave,
        showDeleteTagDialog,
        setShowDeleteTagDialog,
        onTagDeleted,
    } = useTags();

    const getMenuItems = (tag: TagViewModel) => {
        if (permissions.canManageMasterData) {
            return [
                {
                    menuItem: translateString("edit"),
                    onClick: () => {
                        setSelectedTag(tag);
                        setShowTagDialog(true);
                    },
                },
                {
                    menuItem: translateString("deleteTag"),
                    onClick: () => {
                        setShowDeleteTagDialog(tag);
                    },
                },
            ];
        }

        return [];
    };

    return (
        <>
            <DotLegalPageHeader userContext={{ customerName }} breadCrumbs={getBreadCrumbs(translateString("tags"))} />

            <DotLegalActionBar
                canCreate={permissions.canManageMasterData}
                buttonText={translateString("createTag")}
                onOpenDialog={() => setShowTagDialog(true)}
                showDialog={showTagDialog}
            >
                {showTagDialog && (
                    <TagDialog
                        onHideDialog={() => {
                            setSelectedTag(undefined);
                            setShowTagDialog(false);
                        }}
                        onSave={onTagSave}
                        selectedTag={selectedTag}
                    />
                )}
            </DotLegalActionBar>

            <DotLegalPaper>
                {isLoading || (data && data.length > 0) ? (
                    <DotLegalTableWithControls
                        headers={getHeaders(translateString)}
                        getUserSpecificPageLength={() => 30}
                        hideRowsPerPage
                        defaultOrderBy={"name"}
                        defaultOrder={"asc"}
                        isLoading={isLoading}
                        clickableRows={false}
                        labelRowsPerPage={translateString("showEntites")}
                        labelSearch={translateString("search")}
                        noOptionsLabel={translateString("noOptions")}
                        paginationLabelOf={translateString("labelOf")}
                        emptyText={translateString("noData")}
                        data={data}
                        renderRow={(row, i) => (
                            <TableRow key={row.id}>
                                <TableCell sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>{row.name}</TableCell>
                                <TableCell>{row.description}</TableCell>
                                <TableCell align={"right"}>
                                    <DotLegalOverflowMenu items={getMenuItems(row)} />
                                </TableCell>
                            </TableRow>
                        )}
                    />
                ) : (
                    <DotLegalEmptyState icon={<EmptyStateIcon />} text={translateString("noTagsEmptyState")} />
                )}
            </DotLegalPaper>

            {showDeleteTagDialog && (
                <DeleteTagDialog onCloseDialog={() => setShowDeleteTagDialog(undefined)} onDeleted={() => onTagDeleted()} selectedTag={showDeleteTagDialog} />
            )}
        </>
    );
}

export default Tags;

function getHeaders(translateString: any) {
    let headers = Array<ITableHeader<TagViewModel>>();
    headers.push({ property: "name", text: translateString("name"), align: "left", showOnMobile: true });
    headers.push({ property: "description", text: translateString("description"), align: "left", showOnMobile: true });

    return headers;
}
