import { createElement, useState } from "react";
import { InternalAssessmentApiModel, InternalAssessmentApiRow } from "../../../internalAssessment/InternalAssessments.types";
import { deleteHttp, get } from "../../../common/api/apiShared";
import { useMutation, useQuery } from "react-query";
import { ISystemInternalAssessmentTabProps } from "./SystemAssociatedInternalAssessmentTab";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { Trans } from "react-i18next";

export default function useSystemInternalAssessmentTabHooks(props: ISystemInternalAssessmentTabProps) {
    const snackbar = useDotLegalSnackbar();

    const [showAddDialog, setShowAddDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedInternalAssessment, setSelectedInternalAssessment] = useState<InternalAssessmentApiRow | undefined>(undefined);
    const [showNote, setShowNote] = useState(false);

    const url = `/systemAssociatedInternalAssessment/${props.systemId}`;
    const { isLoading, data, refetch } = useQuery(url, () => get<InternalAssessmentApiModel>(url));

    const removeAssociatedInternalAssessmentMutation = useMutation(removeAssociatedInternalAssessmentAPI);

    const removeAssociatedInternalAssessment = async () => {
        removeAssociatedInternalAssessmentMutation.mutateAsync(undefined, {
            onSuccess: () => {
                refetch();
                setShowDeleteDialog(false);
                snackbar.show(createElement(Trans, { i18nKey: "dataDeleted", values: { name: selectedInternalAssessment?.name } }));
            },
        });
    };

    async function removeAssociatedInternalAssessmentAPI() {
        return await deleteHttp<string>("/systemAssociatedInternalAssessment/" + props.systemId + "/" + selectedInternalAssessment?.id);
    }

    return {
        showAddDialog,
        setShowAddDialog,
        isLoading,
        data,
        refetch,
        showDeleteDialog,
        setShowDeleteDialog,
        setSelectedInternalAssessment,
        removeAssociatedInternalAssessment,
        isRemoving: removeAssociatedInternalAssessmentMutation.isLoading,
        showNote,
        setShowNote,
        selectedInternalAssessment,
    };
}
