import { useTheme } from "@mui/material";
import { createSxStyles } from "../../../../common/createSxStyles";

export const useRelatedTasksSelectorStyle = () => {
    const theme = useTheme();
    return createSxStyles({
        tableCellLeft: {
            paddingLeft: 0,
            border: 0,
            paddingBottom: 0,
            paddingTop: 0,
        },
        tableCellRight: {
            paddingRight: 0,
            border: 0,
            paddingBottom: 0,
            paddingTop: 0,
        },
        tableCellTextLeft: {
            paddingLeft: 0,
            border: 0,
            paddingBottom: 0,
            paddingTop: 0,
            color: theme.palette.primary.dark,
        },
    });
};
