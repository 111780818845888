import { getEnumValues } from "../../../../common/enumOperations";
import { setFirstLetterToLowerCase } from "../../../../common/stringOperations";
import { LegalEntityAuditFrequency, LegalEntityAuditType } from "../../LegalEntityManagement.types";

export const legalEntityAuditTypeOptions = (translateString: any) => {
    return getEnumValues(LegalEntityAuditType).map((x) => {
        const name = translateString(setFirstLetterToLowerCase(LegalEntityAuditType[x].toString()));
        return { name: name, id: x.toString() };
    });
};

export const legalEntityAuditFrequencyOptions = (translateString: any) => {
    return getEnumValues(LegalEntityAuditFrequency).map((x) => {
        const name = translateString(setFirstLetterToLowerCase(LegalEntityAuditFrequency[x].toString()));
        return { name: name, id: x.toString() };
    });
};
