import React from "react";
import { DotLegalDialog } from "@dotlegal/dotlegal-ui-components";
import { LegalEntitySelectorViewModel } from "../LegalEntity.types";
import LegalEntitySelector from "../legalEntitySelector/LegalEntitySelector";

export interface ILegalEntitySelectorDialog {
    dialogHeader: string;
    buttonOkText: string;
    setSelectedEntities: (selectedEntities: Array<string>) => void;
    selectedEntities: Array<string>;
    setOkClicked: (searchClicked: boolean) => void;
    legalEntityItems?: Array<LegalEntitySelectorViewModel>;
    isLoading: boolean;
    setShowDialog: (showDialog: boolean) => void;
    allColumns: boolean;
    onGridChange: (gridlength: number | string | null) => void;
    gridLength: number | undefined;
}

export default function LegalEntitySelectorDialog(props: ILegalEntitySelectorDialog) {
    const dialogClose = () => {
        props.setShowDialog(false);
    };

    const onOkClick = () => {
        props.setOkClicked(true);
        props.setShowDialog(false);
    };

    const GetNumberOfSelectedItems = () => {
        return `${props.buttonOkText} (${props.selectedEntities.length})`;
    };

    return (
        <DotLegalDialog
            header={props.dialogHeader}
            buttonOkText={GetNumberOfSelectedItems()}
            open
            onDialogClose={dialogClose}
            onOkClick={onOkClick}
            buttonRight
            size={props.allColumns ? "lg" : "md"}
        >
            <LegalEntitySelector
                gridlength={props.gridLength}
                onGridChange={(e: any) => props.onGridChange(e)}
                allColumns={props.allColumns}
                isLoading={props.isLoading}
                selectedEntities={props.selectedEntities}
                legalEntityItems={props.legalEntityItems}
                setSelectedEntities={props.setSelectedEntities}
            />
        </DotLegalDialog>
    );
}
