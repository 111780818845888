import { Box } from "@mui/material";
import React from "react";
import DotLegalDragNDrop, { IDotLegalDragNDrop } from "../dotLegalDragNDrop/DotLegalDragNDrop";
import { useDotLegalDragNDropWithControlsStyles } from "./DotLegalDragNDropWithControls.styles";
import { useTranslation } from "../../../localization/useTranslation";
import { DotLegalFilterControls } from "@dotlegal/dotlegal-ui-components";

export interface IDotLegalDragNDropWithControls extends IDotLegalDragNDrop {
    extraControls: Array<JSX.Element>;
    searchString?: string;
    onSearchStringChange: (searchString: string) => void;
    disabled?: boolean;
}

export default function DotLegalDragNDropWithControls(props: IDotLegalDragNDropWithControls) {
    const styles = useDotLegalDragNDropWithControlsStyles();
    const { translateString } = useTranslation();

    return (
        <Box sx={styles.dragNDropWithControlsContainer}>
            <Box sx={styles.box}>
                <DotLegalFilterControls
                    extraControls={props.extraControls}
                    searchFieldPlaceholder={translateString("search")}
                    onSearchStringChange={props.onSearchStringChange}
                    searchString={props.searchString ?? ""}
                />
            </Box>
            <DotLegalDragNDrop {...props} />
        </Box>
    );
}
