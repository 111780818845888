import { lighten, Theme, useTheme } from "@mui/material";
import { IDotLegalNotificationCardProps } from "./DotLegalNotificationCard";
import { isNullOrWhitespace } from "../../stringOperations";
import { createSxStyles } from "../../createSxStyles";

export const useDotLegalNotificationCard = (props: IDotLegalNotificationCardProps) => {
    const theme = useTheme();
    return createSxStyles({
        paper: {
            backgroundColor: props.isClicked ? theme.palette.primary.light : "#fafafa",
            padding: "10px 16px",
            outline: props.isClicked ? "1px solid " + theme.palette.primary.main : "",
            margin: props.size === "expanded" ? `${theme.spacing(1)} ${theme.spacing(1)}` : `${theme.spacing(1)} 0px`,
            ...getNotificationHover(theme, !isNullOrWhitespace(props.taskHref)),
        },
        container: {
            display: "flex",
            justifyContent: "space-between",
            minHeight: 30 + "px",
        },
        badge: {
            marginRight: 10 + "px",
            "& .MuiBadge-badge": {
                backgroundColor: lighten(theme.palette.error.light, 0.5),
            },
        },
        columnWrapper: {
            display: "flex",
            alignItems: "center",
            "& svg": {
                height: 14 + "px",
                width: 14 + "px",
            },
        },
        date: {
            fontSize: props.size === "compact" ? theme.typography.pxToRem(12) : theme.typography.pxToRem(14),
            color: theme.palette.grey[500],
        },
        dateImage: {
            marginRight: 4 + "px",
        },
        link: {
            textDecoration: "none",
            fontSize: theme.typography.pxToRem(12),
            alignSelf: "flex-end",
            whiteSpace: "nowrap",
            "&:hover": {
                textDecoration: "underline",
            },
        },
        styleCorrection: {
            marginLeft: 10 + "px",
        },
        deleteWrapper: {
            width: 20 + "px",
            "& svg": {
                stroke: theme.palette.grey[600],
            },
        },
    });
};

function getNotificationHover(theme: Theme, showHover: boolean) {
    if (showHover) {
        return {
            "&:hover": {
                outline: "1px solid " + theme.palette.primary.main,
                backgroundColor: theme.palette.primary.light,
                cursor: "pointer",
            },
        };
    }
}
