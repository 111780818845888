import { useTheme } from "@mui/material";
import { createSxStyles } from "../common/createSxStyles";

export const useSettingStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        paperInfoContainer: {
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
        },
        header: {
            fontWeight: "bold",
            fontSize: theme.typography.pxToRem(14),
            color: theme.palette.primary.dark,
        },
        bodyText: {
            fontSize: theme.typography.pxToRem(14),
            color: theme.palette.primary.dark,
            marginTop: theme.spacing(2),
        },
    });
};
