import upgradePlanSky from "../../../common/svgs/upgradePlanSky.svg?url";
import { createSxStyles } from "../../../common/createSxStyles";

export const useUpgradeCardStyles = () => {
    return createSxStyles({
        upgradePaper: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundImage: `url("${upgradePlanSky}")`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            minHeight: 150,
            padding: 1.5,
            borderRadius: "15px",
            position: "relative",
            whiteSpace: "pre-wrap",
            "& .closeButton": {
                display: "none",
            },
            "&:hover": {
                "& .closeButton": {
                    display: "block",
                },
            },
        },
    });
};
