import { useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";

export const useDataCategoriesStepstyles = () => {
    const theme = useTheme();
    return createSxStyles({
        header: {
            fontWeight: theme.typography.fontWeightBold,
            fontSize: theme.typography.pxToRem(14),
            color: theme.palette.primary.dark,
        },
        infoHeader: {
            fontSize: theme.typography.pxToRem(12),
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.primary.dark,
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            display: "inline-block",
        },
        resetButton: {
            marginTop: theme.spacing(2),
        },
        mainContainer: {
            margin: 15 + "px",
        },
        listDecription: {
            color: theme.palette.primary.main,
            fontSize: theme.typography.pxToRem(10),
            display: "inline-block",
            marginLeft: theme.spacing(1),
            verticalAlign: "text-top",
        },
        buttonContainer: {
            marginTop: theme.spacing(3),
        },
        firstTypographyContainer: {
            position: "relative",
            display: "inline-block",
        },
        toolTipContainer: {
            position: "absolute",
            top: 15,
            right: theme.spacing(-4),
        },
        error: {
            fontSize: theme.typography.pxToRem(12),
            color: theme.palette.error.main,
        },
        EmptyState: {
            textAlign: "center",
            marginTop: theme.spacing(10),
            color: "#707070",
            fontSize: theme.typography.pxToRem(14),
        },
        datSubjectSelector: {
            marginRight: theme.spacing(23),
        },
    });
};
