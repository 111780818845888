export interface CloseAuditSaveModel {
    status?: LegalEntityAuditClosedReason;
}

export enum LegalEntityAuditClosedReason {
    DoesNotNeedToRespond = 0,
    DoesNotWantToRespond = 1,
    AuditOpinion = 2,
    Other = 3,
}
