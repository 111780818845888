import { useTheme } from "@mui/material";
import { createSxStyles } from "../../../../common/createSxStyles";

export const useCompletedControlProcedureCheck = () => {
    const theme = useTheme();

    return createSxStyles({
        container: {
            paddingBottom: 3,
            paddingLeft: 3,
            paddingRight: 3,
            paddingTop: 1,
            background: theme.palette.grey[100],
            borderRadius: 2,
            marginTop: 2,
            marginLeft: -2,
            marginRight: -2,
        },
        addLink: {
            fontSize: theme.typography.pxToRem(12),
            color: theme.palette.primary.main,
        },
    });
};
