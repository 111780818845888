import DotLegalDeleteDialogWrapper from "../../../common/components/dotLegalDeleteDialog/DotLegalDeleteDialogWrapper";
import { HelpTextSaveModel } from "../helpTexts/HelpTexts.types";
import { DeleteHelpTextDialogDataMapping } from "./HelpTextDeleteDialog.hooks";

export interface IDeleteHelpTextDialog {
    closeDialog: () => void;
    onDeleted: () => void;
    selectedHelpText: HelpTextSaveModel;
}

function DeleteHelpTextDialog(props: IDeleteHelpTextDialog) {
    const { deletePolicy, inProgess } = DeleteHelpTextDialogDataMapping(props);

    return (
        <DotLegalDeleteDialogWrapper
            open
            itemForDeletion={props.selectedHelpText.header}
            onDialogClose={props.closeDialog}
            onOkClick={deletePolicy}
            inProgress={inProgess}
        />
    );
}

export default DeleteHelpTextDialog;
