import React from "react";
import { DotLegalDialog, DotLegalHeader, DotLegalSelect } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { SelectableItem } from "../../../processingActivity/ProcessingActivity.types";
import { useAssociateWithExternalAudits } from "./AddToAuditGroup.hooks";
import { isNullOrWhitespace } from "../../../common/stringOperations";
import { Box } from "@mui/material";

export interface AddToAuditGroupProps {
    auditId: string;
    selectableAuditGroups: Array<SelectableItem>;
    onCloseDialog: () => void;
    onAddedToAuditGroup: () => void;
}

function AddToAuditGroup(props: AddToAuditGroupProps) {
    const { translateString } = useTranslation();
    const { selectedAuditGroup, setSelectedAuditGroup, onAuditGroupSelected, isSaving } = useAssociateWithExternalAudits(props);

    return (
        <DotLegalDialog
            header={translateString("addToAuditGroup")}
            buttonOkText={translateString("add")}
            open
            onDialogClose={props.onCloseDialog}
            onOkClick={onAuditGroupSelected}
            size="sm"
            inProgress={isSaving}
            okButtonDisabled={isNullOrWhitespace(selectedAuditGroup ?? undefined)}
        >
            <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                <Box sx={{ textAlign: "center" }}>
                    <DotLegalHeader headerStyle={"small"} fontWeight={400}>
                        {translateString("addAuditToAuditGroupRules")}
                    </DotLegalHeader>
                </Box>

                <DotLegalSelect
                    options={props.selectableAuditGroups}
                    onChange={(auditGroup) => setSelectedAuditGroup(auditGroup)}
                    selectedItem={selectedAuditGroup}
                    label={translateString("selectAuditGroup")}
                    noOptionsLabel={translateString("noOptions")}
                />
            </Box>
        </DotLegalDialog>
    );
}

export default AddToAuditGroup;
