import { DotLegalHeader, DotLegalSelect } from "@dotlegal/dotlegal-ui-components";
import { Box } from "@mui/material";
import { useTranslation } from "../../localization/useTranslation";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { CustomerReportType } from "../../customer/addEditCustomerDialog/AddEditCustomer.types";
import { setFirstLetterToLowerCase } from "../../common/stringOperations";

export interface IProcessingActivityReportDialogHeader {
    reportType: CustomerReportType;
    setReportType?: (type: CustomerReportType) => void;
    isPdf?: boolean;
}

function ProcessingActivityReportDialogHeader(props: IProcessingActivityReportDialogHeader) {
    const { translateString } = useTranslation();
    const { customerName, permissions } = useUserContext();

    let custName = customerName;
    if (custName.endsWith("s") && !custName.endsWith("'s")) {
        custName = custName + "'s";
    } else if (!custName.endsWith("'s")) {
        custName = custName + "s";
    }

    return (
        <Box sx={(theme) => ({ backgroundColor: "#b7d8ed", maxHeight: 240, width: "100%" })}>
            <Box sx={{ padding: 4 }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: 1 }}>
                    <DotLegalHeader headerStyle="large">{customerName}</DotLegalHeader>
                    {!props.isPdf && permissions.reportTypes.length > 1 && (
                        <DotLegalSelect
                            width={"330px"}
                            options={getReportOptions()}
                            onChange={(reportType) => props.setReportType!(Number(reportType!))}
                            placeholder={""}
                            label={""}
                            noOptionsLabel={""}
                            disableClearable
                            selectedItem={props.reportType.toString()}
                            noMargin
                            backgroundColor="white"
                        ></DotLegalSelect>
                    )}
                </Box>

                <DotLegalHeader marginBottom={0} headerStyle="xxl">
                    {getReportHeader()}
                </DotLegalHeader>

                {props.reportType !== CustomerReportType.ResearchEthicsApproval && (
                    <DotLegalHeader fontWeight={400} marginTop={0} marginBottom={0} headerStyle="xxl">
                        {getReportName()}
                    </DotLegalHeader>
                )}

                <DotLegalHeader fontWeight={400} marginTop={1} headerStyle="medium">
                    {getReportSubHeader()}
                </DotLegalHeader>
            </Box>
        </Box>
    );

    function getReportOptions() {
        return permissions.reportTypes.map((x) => {
            var name = translateString(setFirstLetterToLowerCase(CustomerReportType[x].toString()));
            return { name: name, id: x.toString() };
        });
    }

    function getReportHeader() {
        if (props.reportType === CustomerReportType.ResearchEthicsApproval) {
            return translateString("reportApplication");
        }
        return translateString("processingActivityReportDialog");
    }

    function getReportName() {
        switch (props.reportType) {
            case CustomerReportType.RegistrationOfResearchProject:
                return translateString("registrationOfResearchProject");
            case CustomerReportType.InternalAcquisitionOfSignature:
                return translateString("internalAcquisitionOfSignature");
            case CustomerReportType.ResearchEthicsApproval:
                return translateString("researchEthicsApproval");
        }
    }

    function getReportSubHeader() {
        switch (props.reportType) {
            case CustomerReportType.RegistrationOfResearchProject:
                return translateString("researchProjectRegistredOnDataControllerRecord", { environment: custName ?? "" });
            case CustomerReportType.InternalAcquisitionOfSignature:
                return translateString("internalAcquisitionOfSignatureReportInfo", { environment: custName ?? "" });
            case CustomerReportType.ResearchEthicsApproval:
                return translateString("researchEthicsApproval");
        }
    }
}

export default ProcessingActivityReportDialogHeader;
