import { useTranslation } from "../../localization/useTranslation";
import { DotLegalDialog, DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import { Box } from "@mui/material";
import React from "react";
import { useOverviewDialogStyles } from "./IncidentLogOverviewDialog.styles";

export interface IIncidentLogOverviewTypesDialogProps {
    onCloseDialog: () => void;
    types: Array<number> | undefined | null;
}
function IncidentLogOverviewTypesDialog(props: IIncidentLogOverviewTypesDialogProps) {
    const { translateString, translateGdprIncidentType } = useTranslation();
    const styles = useOverviewDialogStyles();

    return (
        <DotLegalDialog
            header={translateString("incidentTableTypes")}
            buttonOkText={""}
            hideOkButton
            open
            onDialogClose={() => props.onCloseDialog()}
            onOkClick={() => {}}
            size="sm"
        >
            {props.types?.map((type) => {
                return (
                    <Box key={type} sx={styles.container}>
                        <DotLegalHeader headerStyle={"extraSmall"} fontWeight={400}>
                            {translateGdprIncidentType(type.toString())}
                        </DotLegalHeader>
                    </Box>
                );
            })}
        </DotLegalDialog>
    );
}

export default IncidentLogOverviewTypesDialog;
