import { useState } from "react";
import { useUrlProvider } from "../../useUrlProvider";

export function useNotificaitionMenu() {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { getNotificationUrl } = useUrlProvider();

    return {
        anchorEl,
        setAnchorEl,
        open: Boolean(anchorEl),
        getNotificationUrl,
    };
}
