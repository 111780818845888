import { DotLegalHeader, DotLegalPaper } from "@dotlegal/dotlegal-ui-components";
import { Box, Grid } from "@mui/material";
import { AnnualWheelMonthEnum, TaskLaneStateEnum, TaskRawStateEnum } from "../../../../annualWheel/AnnualWheel.types";
import { getTaskDeadLine, getTaskResponsible, getRiskIcon, ITaskProps, getTaskSubTasks } from "../../../../taskManagement/task/Task";
import { getTaskColor, useTaskStyles } from "../../../../taskManagement/task/Task.styles";
import { useTranslation } from "../../../../localization/useTranslation";
import { TaskResponsibleViewModel } from "../../../../taskManagement/TaskManagement.types";
import { AnnualWheelPriority } from "../../../../annualWheel/annualWheelDialog/AnnualWheelDialog.types";

export interface ITasksWidgetReportTaskProps {
    activityName: string;
    taskName: string;
    responsibles: Array<TaskResponsibleViewModel>;
    month: AnnualWheelMonthEnum;
    year: number;
    subTaskCompleted: number;
    totalSubTasks: number;
    taskStatus: TaskLaneStateEnum;
    priority: AnnualWheelPriority;
    isOverdue: boolean;
    customId?: string;
    isSeparateTask: boolean;
    isReadyForApproval: boolean;
}

function TasksWidgetReportTask(props: ITasksWidgetReportTaskProps) {
    const { translateString, translateShortMonth } = useTranslation();
    const styles = useTaskStyles(props.taskStatus, props.isOverdue);

    return (
        <div className="avoid-page-break">
            <DotLegalPaper sx={styles.root}>
                <Box
                    sx={(_) => ({
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        minHeight: "55px",
                    })}
                >
                    <Box
                        sx={(theme) => ({
                            backgroundColor: getTaskColor(
                                props.isReadyForApproval ? TaskRawStateEnum.ReadyForApproval : props.taskStatus,
                                theme,
                                props.isOverdue
                            ),
                            marginRight: theme.spacing(2),
                            borderRadius: "12px",
                            paddingRight: "6px",
                        })}
                    />

                    <Box sx={{ display: "flex", flexDirection: "column", flex: 1, "& > .MuiBox-root": { marginTop: "2px", marginBottom: "2px" } }}>
                        <Box sx={styles.headerContainer}>
                            <Box sx={styles.statusAndHeader}>
                                {props.customId && (
                                    <Box sx={{ alignSelf: "flex-start", marginRight: "10px" }}>
                                        <DotLegalHeader marginBottom={0} headerStyle={"small"}>
                                            {props.customId}&nbsp;
                                        </DotLegalHeader>
                                    </Box>
                                )}

                                <DotLegalHeader hoverable marginBottom={0} headerStyle={"small"} fontWeight={400}>
                                    {props.activityName}
                                </DotLegalHeader>
                            </Box>
                            <Box>{getRiskIcon(props.priority, translateString, styles)}</Box>
                        </Box>

                        <Grid container>
                            <Grid item xs={4}>
                                <Box>{getTaskDeadLine(styles, translateString, translateShortMonth, props.isSeparateTask, props.month, props.year)}</Box>
                            </Grid>

                            <Grid item xs={4}>
                                <Box>{getTaskResponsible(props.isSeparateTask, props.responsibles, styles, translateString)}</Box>
                            </Grid>

                            <Grid item xs={4}>
                                <Box sx={styles.taskContainter}>
                                    {props.totalSubTasks > 0 && getTaskSubTasks(styles, props.subTaskCompleted, props.totalSubTasks)}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </DotLegalPaper>
        </div>
    );
}

export default TasksWidgetReportTask;
