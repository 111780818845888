import React from "react";
import { useControllerScoreGraphHooks } from "./AllocationOfRiskLevelChart.hooks";
import { useControllerScoreGraphStyles } from "./AllocationOfRiskLevelChart.styles";
import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "../../../../localization/useTranslation";
import { DotLegalHeader, DotLegalSelect } from "@dotlegal/dotlegal-ui-components";
import { AuditGroupChartDataViewModel } from "./AllocationOfRiskLevelChart.types";
import RiskLevelChart from "./riskLevelChart/RiskLevelChart";

export interface IControllerScoreGraphProps {
    data: AuditGroupChartDataViewModel;
    isPdf: boolean;
    displaySelectField: boolean;
}

function AllocationOfRiskLevelChart(props: IControllerScoreGraphProps) {
    const { selectedSection, sectionOptions, handleCategoriesChanged } = useControllerScoreGraphHooks(props);
    const { translateString } = useTranslation();
    const styles = useControllerScoreGraphStyles();

    const isPdf = props.isPdf;

    const isAllSectionChosen = selectedSection === "0";

    const getSelectedSection = () => props.data.sections.find((x) => x.sectionId === selectedSection)!;

    const getData = () => {
        if (isAllSectionChosen) {
            return props.data.allSectionsData.map((x) => {
                return { ...x };
            });
        }

        let section = getSelectedSection();
        return section.scores.map((x) => {
            return { ...x, count: section.sectionWeight === 0 ? 0 : x.count };
        });
    };

    const shouldDisplayHasWeightOfZero = () => {
        if (isAllSectionChosen) {
            return false;
        }

        return getSelectedSection().sectionWeight === 0;
    };

    function getFigureName(index: number): string {
        const alphabet = "abcdefghijklmnopqrstuvwxyz";

        let suffix = "";
        let cycleIndex = index;

        while (cycleIndex >= 0) {
            suffix = alphabet[cycleIndex % alphabet.length] + suffix;
            cycleIndex = Math.floor(cycleIndex / alphabet.length) - 1;
        }

        return `${translateString("figure")} 1${suffix}`;
    }

    return (
        <>
            {isPdf ? (
                <>
                    <Grid container>
                        <Grid item xs={props.displaySelectField ? 6 : 12}>
                            <RiskLevelChart
                                width={props.displaySelectField ? "135mm" : "270mm"}
                                chartHeader={
                                    <>
                                        <DotLegalHeader marginBottom={0} headerStyle={"small"}>
                                            {translateString("figure")} 1:
                                        </DotLegalHeader>
                                        <Typography>{translateString("allocationOfOverallSections")}</Typography>
                                    </>
                                }
                                isPdf={props.isPdf}
                                chartData={props.data.allSectionsData}
                                shouldDisplayHasWeightOfZero={false}
                            />
                        </Grid>

                        {props.displaySelectField &&
                            props.data.sections.map((x, i) => {
                                return (
                                    <Grid item xs={6} key={x.sectionId}>
                                        <RiskLevelChart
                                            width={isPdf ? "135mm" : "100%"}
                                            chartHeader={
                                                <>
                                                    <DotLegalHeader marginBottom={0} headerStyle={"small"}>
                                                        {getFigureName(i)}:
                                                    </DotLegalHeader>
                                                    <Typography>{x.sectionName}</Typography>
                                                </>
                                            }
                                            isPdf={props.isPdf}
                                            chartData={x.scores}
                                            shouldDisplayHasWeightOfZero={x.sectionWeight === 0}
                                        />
                                    </Grid>
                                );
                            })}
                    </Grid>
                </>
            ) : (
                <>
                    {props.displaySelectField && (
                        <Box sx={styles.dropDownWrapper}>
                            <Box sx={styles.dropDown}>
                                <DotLegalSelect
                                    label={translateString("allSections")}
                                    onChange={(val) => handleCategoriesChanged(val!)}
                                    options={sectionOptions}
                                    selectedItem={selectedSection}
                                    placeholder={translateString("allSections")}
                                    noOptionsLabel={translateString("noOptions")}
                                    disableClearable
                                />
                            </Box>
                        </Box>
                    )}
                    <RiskLevelChart
                        chartHeader={
                            <>
                                <DotLegalHeader marginBottom={0} headerStyle={"small"}>
                                    {translateString("figure")} 1:
                                </DotLegalHeader>
                                <Typography>{translateString("allocationOfOverallSections")}</Typography>
                            </>
                        }
                        isPdf={props.isPdf}
                        chartData={getData()}
                        shouldDisplayHasWeightOfZero={shouldDisplayHasWeightOfZero()}
                    />
                </>
            )}
        </>
    );
}

export default AllocationOfRiskLevelChart;
