import React from "react";
import { Box, Typography } from "@mui/material";
import { useLegalEntityAuditGroupManagementReport } from "./LegalEntityAuditGroupManagementReport.hooks";
import ManagementReportDescription from "./managementReportDescription/ManagementReportDescription";
import { DotLegalFullScreenSpinner, DotLegalHeader, DotLegalPdfBackPage, DotLegalPdfFrontPage } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import ManagementReportInProgressAudits from "./managementReportInProgressAudits/ManagementReportInProgressAudits";
import { LegalEntityAuditStatus } from "../../legalEntity/legalEntityAuditTab/LegalEntityAudit.types";
import ManagementReportCompletedAudits from "./managementReportCompletedAudits/ManagementReportCompletedAudits";
import ManagementReportCharts from "./managementReportCharts/ManagementReportCharts";
import { useSetPdfPageSize } from "../../common/pdfPageSizeHelper";
import ManagementReportExcludedAudits from "./managementReportExcludedAudits/ManagementReportExcludedAudits";
import ManagementReportSummary from "./managementReportSummary/ManagementReportSummary.tsx";

export interface ManagementReportProps {}

function LegalEntityAuditGroupManagementReport() {
    const { translateString, translateDate } = useTranslation();
    const { isLoading, data, isPdf, chartData, chartDataLoading } = useLegalEntityAuditGroupManagementReport();
    useSetPdfPageSize("landscape");

    return isLoading || data === undefined ? (
        <DotLegalFullScreenSpinner />
    ) : (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                color: "primary.dark",
                backgroundColor: isPdf ? "white" : "#fafafa",
            }}
        >
            <Box
                sx={(theme) => ({
                    width: "100vw",
                    maxWidth: "1300px",
                    [theme.breakpoints.down("sm")]: {
                        maxWidth: "100%",
                    },
                })}
            >
                <DotLegalPdfFrontPage header={data?.name ?? ""} subHeader={translateString("managementReport")} isPdf={isPdf} backgroundImage={"blue"}>
                    <Box sx={{ marginTop: 9 }}>
                        <Typography sx={{ fontSize: 25 }}>
                            <Box component={"span"} sx={{ fontWeight: 700 }}>
                                {translateString("sentBy")}: &nbsp;
                            </Box>
                            {data.sender}
                        </Typography>

                        <Typography sx={{ fontSize: 25, mt: 2 }}>
                            <Box component={"span"} sx={{ fontWeight: 700 }}>
                                {translateString("deadline")}: &nbsp;
                            </Box>
                            {translateDate(data.deadline)}
                        </Typography>
                    </Box>
                </DotLegalPdfFrontPage>

                <Box p={4} pt={isPdf ? 1 : 4} mb={isPdf ? 0 : 3} sx={{ backgroundColor: "white" }}>
                    <ManagementReportDescription isLoading={isLoading} scoreEnabled={data !== undefined && data.scoreEnabled} auditName={data?.name ?? ""} />

                    <ManagementReportSummary isLoading={isLoading} data={data} isPdf={isPdf} />

                    {!chartDataLoading && chartData !== undefined && data.scoreEnabled && (
                        <ManagementReportCharts
                            isPdf={isPdf}
                            data={{
                                averageRiskLevel: data.overallRiskLevel,
                                averageScore: data.overallRiskScore,
                                averageScoreName: data.overallRiskLevelName,
                                averageStatus: LegalEntityAuditStatus.Submitted,
                            }}
                            intervalScoreChartData={chartData!}
                        />
                    )}

                    <Box className="page-break-before">
                        <DotLegalHeader marginTop={isPdf ? 1 : 4} marginBottom={0} headerStyle="xxxl">
                            {translateString("overviewOfIndividualRespondents")}
                        </DotLegalHeader>
                        <ManagementReportCompletedAudits
                            scoreEnabled={data !== undefined && data.scoreEnabled}
                            isLoading={isLoading}
                            audits={data.audits.filter((x) => x.status === LegalEntityAuditStatus.Submitted)}
                            isPdf={isPdf}
                        />

                        <ManagementReportInProgressAudits
                            isLoading={isLoading}
                            audits={data.audits.filter(
                                (x) =>
                                    x.status === LegalEntityAuditStatus.InProgress ||
                                    x.status === LegalEntityAuditStatus.Other ||
                                    x.status === LegalEntityAuditStatus.Opened ||
                                    x.status === LegalEntityAuditStatus.Sent
                            )}
                        />

                        <ManagementReportExcludedAudits isLoading={isLoading} audits={data.audits.filter((x) => x.closed)} />
                    </Box>
                </Box>

                {isPdf && (
                    <Box sx={{ backgroundColor: "white" }} className={"page-break-before"}>
                        <DotLegalPdfBackPage />
                    </Box>
                )}
            </Box>
        </Box>
    );
}

export default LegalEntityAuditGroupManagementReport;
