import { useNavigate, useLocation } from "react-router-dom";

export function useExpandedId() {
    const location = useLocation();
    const navigate = useNavigate();
    const searchString = "#";
    const getExpandedId = () => {
        const parts = location.hash.split("#");
        return parts[1];
    };
    const setExpandedId = (id: string) => {
        const expandedPart = searchString + id;
        navigate(location.pathname + location.search + expandedPart, { replace: true });
    };
    return {
        getExpandedId: getExpandedId,
        setExpandedId: setExpandedId,
    };
}
