import React from "react";
import DotLegalDeleteDialogWrapper from "../../../common/components/dotLegalDeleteDialog/DotLegalDeleteDialogWrapper";
import { DataSubjectSaveModel } from "../DataSubject.types";
import { DeleteDataSubjectDialogDataMapping } from "./DeleteDataSubjectDialog.hooks";

export interface IDeleteDataSubjectDialogProps {
    onCloseDialog: () => void;
    onSaveCorrect: () => void;
    selectedDataSubject: DataSubjectSaveModel;
}

export function DeleteDataSubjectDialog(props: IDeleteDataSubjectDialogProps) {
    const { removeDataSubject, inProgess } = DeleteDataSubjectDialogDataMapping(props);

    return (
        <DotLegalDeleteDialogWrapper
            open
            itemForDeletion={props.selectedDataSubject.name}
            onDialogClose={props.onCloseDialog}
            onOkClick={removeDataSubject}
            inProgress={inProgess}
        />
    );
}
