import { useEffect, useState } from "react";
import { DotLegalSelectOption } from "../components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { useQuery } from "react-query";
import { get } from "../api/apiShared";
import { useTranslation } from "../../localization/useTranslation";

export default function useCountryHook() {
    const { translateCountry } = useTranslation();
    const [countryList, setCountryList] = useState<Array<DotLegalSelectOption>>([]);

    let { data, isLoading } = useQuery("countries", () => get<Array<CountryViewModel>>("/Countries"));

    useEffect(() => {
        if (data) {
            let countries = data.map((country) => {
                return {
                    name: translateCountry(country.countryCode),
                    id: country.countryCode,
                };
            });
            setCountryList(countries);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const getSelectableCountries = () => {
        if (countryList) {
            let object = { id: "EU", name: translateCountry("EU") };
            let newList = [...countryList];
            newList.push(object);
            return newList;
        }
        return [];
    };

    return {
        getSelectableCountries,
        countriesIsLoading: isLoading,
        countries: data ?? [],
        thirdCountries: data?.filter((x) => x.isThirdCountry).map((x) => x.countryCode) ?? [],
    };
}

export interface CountryViewModel {
    countryCode: string;
    isThirdCountry: boolean;
}
