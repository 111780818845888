import { DotLegalFile } from "@dotlegal/dotlegal-ui-components";
import { useMutation } from "react-query";
import { DocumentSaveModel } from "../../../documents/Documents.types";
import { deleteHttp, post } from "../../../common/api/apiShared";
import { IResearchEthicsApprovalReportDocuments } from "./ResearchEthicsApprovalReportDocuments";
import { _arrayBufferToBase64 } from "../../../common/arrayBufferToBase64";
import { useDotLegalFileUploadDataMapping } from "../../../common/components/dotLegalFileUploadTable/DotLegalFileUploadTable.hooks";
import { useState } from "react";

export function useResearchEthicsApprovalReportDocumentsHooks(props: IResearchEthicsApprovalReportDocuments) {
    const [isSaving, setIsSaving] = useState(false);

    const { downloadDocument } = useDotLegalFileUploadDataMapping();

    const removeDocumentMutation = useMutation((documentId: string) =>
        deleteHttp(`/researchEthicsApprovalReport/${props.processingActivityId}/documents/${documentId}`)
    );

    const removeDocument = (documentId: string) => {
        props.onRemoveDocument(documentId);
        removeDocumentMutation.mutateAsync(documentId);
    };

    const createDocumentMutation = useMutation((document: DocumentSaveModel) =>
        post(`/researchEthicsApprovalReport/${props.processingActivityId}/documents`, document)
    );

    const createDocuments = async (files: Array<DotLegalFile> | undefined) => {
        if (files) {
            setIsSaving(true);
            for (const file of files) {
                await createDocumentMutation.mutateAsync({
                    fileName: file.fileName,
                    base64Data: await _arrayBufferToBase64(file.data),
                });
            }
            props.refetch();
            setIsSaving(false);
        }
    };

    return {
        downloadDocument,
        createDocuments,
        removeDocument,
        isSaving,
    };
}
