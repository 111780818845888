import { useTheme } from "@mui/material";
import { IContentProps } from "./Content";
import { createSxStyles } from "../../common/createSxStyles";
import { drawerWidthExpanded, getDrawerWidthCollapsed } from "../privacyDrawer/PrivacyDrawer.styles";

export const useContentStyles = (props: IContentProps, isPdf: boolean) => {
    const theme = useTheme();
    return createSxStyles({
        content: {
            display: isPdf ? "block" : "flex",
            flexDirection: "column",
            padding: theme.spacing(2),
            width: "100%",
            height: "100%",
            backgroundColor: theme.palette.grey["50"],
            borderRadius: theme.spacing(4),
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            marginRight: theme.spacing(1),
            position: "relative",
        },
        wrapper: {
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
            width: `calc(100% - ${getDrawerWidth(props.isExpandedBigScreen, props.isOnSmallScreen)}px)`,
            backgroundColor: theme.customPalette.white,
        },
    });
};

function getDrawerWidth(isExpandedBigScreen: boolean, isOnSmallScreen: boolean) {
    if (isOnSmallScreen) {
        return 0;
    }
    if (isExpandedBigScreen) {
        return drawerWidthExpanded;
    } else {
        return getDrawerWidthCollapsed();
    }
}
