import { DotLegalDialog, DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { Alert, AlertTitle, Box } from "@mui/material";
import useTransferResponsibilityDialogHooks from "./TransferResponsibilityDialog.hooks";
import React from "react";
import { UserSelectableItem } from "../../../user/User.types";
import { Trans } from "react-i18next";

export interface ITransferResponsibilityDialog {
    onCloseDialog: () => void;
    toUser: UserSelectableItem;
    processingActivityId: string;
    fromUser: UserSelectableItem;
}

function TransferResponsibilityDialog(props: ITransferResponsibilityDialog) {
    const { translateString } = useTranslation();
    const { transferResponsibility, isSaving } = useTransferResponsibilityDialogHooks(props);

    return (
        <DotLegalDialog
            header={translateString("transferReponsibility")}
            size="md"
            buttonOkText={translateString("yes")}
            onSecondaryButtonClick={props.onCloseDialog}
            secondaryButtonText={translateString("no")}
            open
            onDialogClose={props.onCloseDialog}
            onOkClick={() => transferResponsibility()}
            inProgress={isSaving}
        >
            <React.Fragment>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <DotLegalHeader headerStyle={"small"} fontWeight={0}>
                        <Trans
                            i18nKey={"transferReponsibilityOnProcessingActivitiesAndDocuments"}
                            values={{ fromUser: props.fromUser.name, toUser: props.toUser.name }}
                        />
                    </DotLegalHeader>
                </Box>

                <Alert severity="warning" sx={(theme) => ({ "& .MuiSvgIcon-root": { fill: theme.palette.warning.main }, marginTop: 3 })}>
                    <AlertTitle>{translateString("warning")}</AlertTitle>
                    {translateString("transferResponsibilityWarning")}
                </Alert>
            </React.Fragment>
        </DotLegalDialog>
    );
}

export default TransferResponsibilityDialog;
