import { createElement } from "react";
import { Trans } from "react-i18next";
import { useMutation } from "react-query";
import { put } from "../../../common/api/apiShared";
import { Result } from "../../../common/api/result";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { PurposeSaveModel } from "../purposeDialog/PurposeDialog.types";
import { IChangePurposeStatusDialogDataMapping } from "./ChangePurposeStatusDialog";

export function useChangePurposeStatusDialogDataMapping(props: IChangePurposeStatusDialogDataMapping) {
    const snackbar = useDotLegalSnackbar();

    const changeActiveStatusMutation = useMutation(changeStatus);

    const changeActiveStatus = (purpose: PurposeSaveModel) => {
        changeActiveStatusMutation.mutateAsync(purpose, {
            onSuccess: (response: Result<string>) => {
                props.onCloseDialog();
                if (response.success()) {
                    snackbar.show(createElement(Trans, { i18nKey: "activeStatusChanged", values: { name: purpose.name } }));
                    props.refetchPurposes();
                } else {
                    snackbar.show(createElement(Trans, { i18nKey: "purposeStatusNotChanged", values: { name: purpose.name } }), "warning");
                }
            },
        });
    };

    function changeStatus(purpose: PurposeSaveModel) {
        if (purpose.isCustom) return put<string>(`/Purposes/${purpose.id}/changeActiveStatus`, !purpose.active);
        else return put<string>(`/Purposes/${purpose.id}/changeMasterDataActiveStatus`, !purpose.active);
    }

    return {
        changeActiveStatus,
        inProgress: changeActiveStatusMutation.isLoading,
    };
}
