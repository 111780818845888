import React from "react";
import { useTranslation } from "../../../../localization/useTranslation";
import { DotLegalDialog } from "@dotlegal/dotlegal-ui-components";
import { useCompleteValidationDialogStyles } from "./CompleteValidationDialog.styles";
import { Box } from "@mui/material";

export interface ICompleteValidationDialog {
    open: boolean;
    OnCompleteClick: () => void;
    onDialogClose: () => void;
    InProgress: boolean;
}

export default function CompleteValidationDialog(props: ICompleteValidationDialog) {
    const { translateString } = useTranslation();
    const classes = useCompleteValidationDialogStyles();
    return (
        <DotLegalDialog
            header={translateString("validating")}
            buttonOkText={translateString("validate")}
            open={props.open ?? false}
            size="md"
            inProgress={props.InProgress}
            secondaryButtonText={translateString("cancel")}
            onDialogClose={props.onDialogClose}
            onSecondaryButtonClick={props.onDialogClose}
            onOkClick={props.OnCompleteClick}
        >
            <Box sx={classes.textContainer}>{translateString("completeDialogContent")}</Box>
        </DotLegalDialog>
    );
}
