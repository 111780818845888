import { createElement } from "react";
import { Trans } from "react-i18next";
import { useMutation } from "react-query";
import { deleteHttp } from "../../common/api/apiShared";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { IDeleteAnnualWheelActivityTemplateDialog } from "./DeleteAnnualWheelActivityTemplateDialog";
import { DeleteAnnualWheelActivityTemplateResponse } from "../AnnualWheel.types";

export function DeleteAnnualWheelActivityTemplateDialogHook(props: IDeleteAnnualWheelActivityTemplateDialog) {
    const snackbar = useDotLegalSnackbar();

    const deleteMutation = useMutation(remove);
    const deleteTemplate = () => {
        deleteMutation.mutateAsync(undefined, {
            onSuccess: (result) => {
                const resultValue = result.value();
                if (resultValue.success) {
                    props.onCloseDialog();
                    props.onSaveCorrect();
                    snackbar.show(createElement(Trans, { i18nKey: "activityTemplateDeleted", values: { name: props.name } }));
                } else {
                    props.onCloseDialog();
                    snackbar.show(createElement(Trans, { i18nKey: "activityTemplateWasNotDeleted", values: { name: props.name } }), "error");
                }
            },
        });
    };

    async function remove() {
        return await deleteHttp<DeleteAnnualWheelActivityTemplateResponse>(`/annualWheelActivityTemplate/${props.id}`);
    }

    return {
        deleteTemplate,
        inProgress: deleteMutation.isLoading,
    };
}
