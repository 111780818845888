import React, { FunctionComponent } from "react";
import StepConnector from "@mui/material/StepConnector";

const QontoConnector: FunctionComponent = () => {
    return (
        <StepConnector
            sx={(theme) => ({
                "& .MuiStepConnector-alternativeLabel": {
                    top: 10 + "px",
                    left: "calc(-50% + 12px)",
                    right: "calc(50% + 12px)",
                },
                "& .MuiStepConnector-line ": {
                    borderTopWidth: 2 + "px",
                    borderRadius: 1 + "px",
                },
                "&.Mui-completed": {
                    "& .MuiStepConnector-line": {
                        borderColor: theme.palette.primary.main,
                    },
                },
            })}
        />
    );
};

export default QontoConnector;
