import { DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import { Box, Dialog, DialogContent, IconButton } from "@mui/material";
import { Trans } from "react-i18next";
import { useIsOnSmallScreen } from "@dotlegal/dotlegal-ui-components";
import { usePlanDialogStyle } from "./PlanDialog.styles";

export interface IPlanDialog {
    showDialog: boolean;
    onDialogClose: () => void;
    content: JSX.Element;
    header: React.ReactNode;
    buttons?: JSX.Element;
}

export const planDialogBottomMargin = 6;

function PlanDialog(props: IPlanDialog) {
    const isOnSmallScreen = useIsOnSmallScreen();
    const styles = usePlanDialogStyle();

    return (
        <Dialog open={props.showDialog} onClose={() => props.onDialogClose()} fullScreen={isOnSmallScreen} maxWidth={"md"} fullWidth sx={styles.dialog}>
            <Box sx={styles.closeButtonWrapper}>
                <IconButton aria-label="close" onClick={() => props.onDialogClose()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14.828" height="14.828" viewBox="0 0 14.828 14.828">
                        <g id="Group_967" data-name="Group 967" transform="translate(-1108.086 -210.086)">
                            <line
                                id="Line_169"
                                data-name="Line 169"
                                x2="12"
                                y2="12"
                                transform="translate(1109.5 211.5)"
                                fill="none"
                                stroke="#183683"
                                strokeLinecap="round"
                                strokeWidth="2"
                            />
                            <line
                                id="Line_170"
                                data-name="Line 170"
                                x1="12"
                                y2="12"
                                transform="translate(1109.5 211.5)"
                                fill="none"
                                stroke="#183683"
                                strokeLinecap="round"
                                strokeWidth="2"
                            />
                        </g>
                    </svg>
                </IconButton>
            </Box>
            <DialogContent sx={styles.dialogContent}>
                <Box sx={styles.dialogSection}>
                    <Box sx={styles.dialogHeader}>
                        <DotLegalHeader marginBottom={planDialogBottomMargin} headerStyle="medium">
                            {props.header}
                        </DotLegalHeader>
                    </Box>
                    {props.content}
                </Box>
                <Box sx={styles.dialogSection}>
                    {props.buttons && <Box sx={styles.btnWrapper}>{props.buttons}</Box>}

                    <DotLegalHeader marginTop={5} color="lightgrey" headerStyle="extraSmall" center>
                        <Trans
                            i18nKey={"contactDotLegal"}
                            components={{
                                phone: (
                                    <Box sx={styles.link} component={"a"} target="_blank" href="tel:+4570270127">
                                        +45 7027 0127
                                    </Box>
                                ),
                                mail: (
                                    <Box sx={styles.link} component={"a"} href="mailto:support@dotlegal.com">
                                        support@dotlegal.com
                                    </Box>
                                ),
                            }}
                        />
                    </DotLegalHeader>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default PlanDialog;
