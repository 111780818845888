import { useState } from "react";
import { useQuery } from "react-query";
import { get } from "../../../common/api/apiShared";
import { HelpTextSaveModel, HelpTextViewModel } from "./HelpTexts.types";

export function useHelpTextDataMapping() {
    const url = "/helptexts";
    const { isLoading, data, refetch } = useQuery(url, () => get<Array<HelpTextViewModel>>(url));
    const [showDialog, setShowDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedHelpText, setSelectedHelpText] = useState<HelpTextSaveModel>(getEmptyHelpText());

    function getEmptyHelpText() {
        return { id: "", header: "", content: "", type: undefined };
    }

    const hasHelpTexts = isLoading || data!.length > 0;

    return {
        isLoading,
        data,
        refetch,
        showDialog,
        setShowDialog,
        selectedHelpText,
        setSelectedHelpText,
        getEmptyHelpText,
        showDeleteDialog,
        setShowDeleteDialog,
        hasHelpTexts,
    };
}
