import { DotLegalDialog, DotLegalTextField, useIsOnSmallScreen } from "@dotlegal/dotlegal-ui-components";
import { Box } from "@mui/material";
import React from "react";
import { useTranslation } from "../../localization/useTranslation.ts";
import { DeclarationCategoryViewModel } from "../DeclarationCategories.types.ts";
import useAddEditDeclarationCategory from "./AddEditDeclarationCategory.hooks.ts";

export interface AddEditDeclarationCategoryDialogProps {
    onDialogClose: () => void;
    declarationId: string;
    model: DeclarationCategoryViewModel | undefined;
}

function AddEditDeclarationCategoryDialog(props: AddEditDeclarationCategoryDialogProps) {
    const { translateString } = useTranslation();
    const isUseOnSmallScreen = useIsOnSmallScreen();

    const { validation, updater, saveModel, save, isSaving, isEdit } = useAddEditDeclarationCategory(props);

    validation.check(saveModel);
    return (
        <DotLegalDialog
            buttonOkText={isEdit ? translateString("save") : translateString("create")}
            size="sm"
            header={`${isEdit ? translateString("edit") : translateString("create")} ${translateString("category")}`}
            open
            onDialogClose={props.onDialogClose}
            onOkClick={save}
            inProgress={isSaving}
            disableBackdropOnCloseIfValueChange={saveModel}
        >
            <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                <Box sx={{ width: "100%" }}>
                    <DotLegalTextField
                        errorText={validation.getErrorsForDisplay("name")}
                        label={translateString("name")}
                        value={saveModel.name}
                        debounce={false}
                        onChange={updater.onNameChange}
                    />

                    <DotLegalTextField
                        label={translateString("description")}
                        onChange={updater.onDescriptionChange}
                        multiline
                        rows={3}
                        value={saveModel.description}
                        debounce={false}
                    />
                </Box>
            </Box>
        </DotLegalDialog>
    );
}

export default AddEditDeclarationCategoryDialog;
