import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { get, put } from "../../common/api/apiShared";
import { DotLegalSelectOption } from "../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { getEnumValues } from "../../common/enumOperations";
import { useExpandedId } from "../../common/hooks/useExpandedId";
import { useOptimisticUpdate } from "../../common/hooks/useOptimisticUpdate";
import { useTranslation } from "../../localization/useTranslation";
import { Permission, PermissionLevel, PermissionType } from "../Roles.types";
import { IAddEditUserRoles } from "./AddEditRoles";
import { PermissionListType, PermissionTypeContent, RolesSaveModel } from "./AddEditRoles.types";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";

export function useEditUserRolesHooks(props: IAddEditUserRoles) {
    const { putOnQueueAndSetQueryData } = useOptimisticUpdate();
    const { translatePermissionTypes, translatePermissionLevel, translatePermissionListTypes } = useTranslation();
    const { getExpandedId, setExpandedId } = useExpandedId();
    const [searchInput, setSearchInput] = useState("");
    const { addonsFeatures, informationSecurityFeatures, hasVendorManagementAccess, platformFeatures, hasTaskManagementAccess } = usePlanContext();

    const key = `/role/${props.id}/permissions`;
    const { isLoading, data } = useQuery(key, () => get<RolesSaveModel>(`/role/${props.id}/permissions`));

    const savePermissionMutation = useMutation(savePermission);
    const removePermissionMutation = useMutation(removePermission);

    let chosenUserRoles;
    let options = new Array<DotLegalSelectOption>();
    if (data) {
        chosenUserRoles = data.permissions
            .map((permissionContent) => {
                let typeContent = getPermissionContent().find((x) => x.masterPermission.type === permissionContent.type);
                if (typeContent) {
                    return {
                        masterPermission: permissionContent,
                        subPermissions: typeContent!.subPermissions
                            ? typeContent!.subPermissions.map((sub) => {
                                  return sub;
                              })
                            : undefined,
                        type: typeContent.type,
                        accessLevel: typeContent.accessLevel,
                        order: typeContent.order,
                        associatedPermissions: typeContent!.associatedPermissions,
                        disableAccessLevels: typeContent.disableAccessLevels,
                    };
                }
                return undefined;
            })
            .filter((n) => n !== undefined);
        PermissionLevelOptions();
    }

    async function ToggleSelectedMasterPermissionUserRoles(content: PermissionTypeContent) {
        let temp = { ...data! };

        let index = temp.permissions.findIndex((x) => x.type === content.masterPermission.type);

        if (index < 0) {
            temp.permissions.push({ type: content.masterPermission.type, level: content.masterPermission.level });

            content.subPermissions
                ?.filter((x) => filterSubPermissionAddAddPermission(x))
                .forEach((x) => {
                    temp.permissions.push({ type: x, level: null });
                });

            await putOnQueueAndSetQueryData(temp, key, savePermissionMutation.mutateAsync, {
                type: content.masterPermission.type,
                level: content.masterPermission.level,
                subPermissions: content.subPermissions?.filter((x) => filterSubPermissionAddAddPermission(x)),
            });
            getPermissionContent().forEach((p) => {
                if (
                    p.associatedPermissions &&
                    p.associatedPermissions.includes(content.masterPermission.type) &&
                    !temp.permissions.some((t) => t.type === p.masterPermission.type)
                ) {
                    let associatedTempPermission = { type: p.masterPermission.type, level: p.masterPermission.level };
                    temp.permissions.push(associatedTempPermission);
                    putOnQueueAndSetQueryData(temp, key, savePermissionMutation.mutateAsync, associatedTempPermission);
                }
            });

            setExpandedId(content.masterPermission.type.toString());
        } else {
            var subPermissions = content.subPermissions ? temp.permissions.filter((x) => content.subPermissions!.some((y) => y === x.type)) : undefined;
            if (subPermissions && subPermissions.length > 0) {
                subPermissions.forEach((x) => {
                    let subPermissionIndex = temp.permissions.findIndex((y) => y.type === x.type);
                    temp.permissions.splice(subPermissionIndex, 1);
                    putOnQueueAndSetQueryData(temp, key, removePermissionMutation.mutateAsync, x.type);
                });
            }
            temp.permissions.splice(index, 1);
            await putOnQueueAndSetQueryData(temp, key, removePermissionMutation.mutateAsync, content.masterPermission.type);
            setExpandedId("");
        }
    }

    async function setMasterPermissionLevel(permissionLevel: PermissionLevel, masterPermission: PermissionType, isSelected: boolean) {
        let temp = { ...data! };
        let tempMasterPermission = temp.permissions.find((x) => x.type === masterPermission);

        var level = isSelected ? permissionLevel : PermissionLevel.Read;

        tempMasterPermission!.level = level;

        putOnQueueAndSetQueryData(temp, key, savePermissionMutation.mutateAsync, { type: tempMasterPermission!.type, level: level });
    }

    async function setSubPermission(content: PermissionTypeContent, chosenSubPermission: Array<string>) {
        let temp = { ...data! };

        content.subPermissions!.forEach((x) => {
            if (!temp.permissions.some((p) => p.type === x) && chosenSubPermission.some((s) => s === x.toString())) {
                temp.permissions.push({ type: x, level: null });
                putOnQueueAndSetQueryData(temp, key, savePermissionMutation.mutateAsync, { type: x, level: null });
            } else if (temp.permissions.some((p) => p.type === x) && !chosenSubPermission.some((s) => s === x.toString())) {
                let subIndex = temp.permissions.findIndex((c) => c.type === x);
                temp.permissions.splice(subIndex, 1);
                putOnQueueAndSetQueryData(temp, key, removePermissionMutation.mutateAsync, x);
            }
        });
    }

    function getSubPermissionSelectOptions(subPermissions: Array<PermissionType>): Array<DotLegalSelectOption> {
        let subSelectOptions = new Array<DotLegalSelectOption>();

        subPermissions.forEach((sub) => {
            subSelectOptions.push({ id: sub.toString(), name: translatePermissionTypes(sub.toString()) });
        });

        return subSelectOptions;
    }

    function GetSubPermissionChosenOptions(subPermissions: Array<PermissionType>) {
        let chosenObtions: Array<string> = [];

        subPermissions.forEach((x) => {
            if (data!.permissions.some((y) => y.type === x)) {
                chosenObtions.push(x.toString());
            }
        });

        return chosenObtions;
    }

    function PermissionLevelOptions() {
        getEnumValues(PermissionLevel).forEach((x) => {
            if (!options.some((p) => p.id === x.toString())) {
                var name = translatePermissionLevel(x.toString());
                options.push({ name: name, id: x.toString() });
            }
        });
    }

    function PermissionListTypeOptions(): Array<DotLegalSelectOption> {
        let options: Array<DotLegalSelectOption> = [];
        getEnumValues(PermissionListType).forEach((x) => {
            var name = translatePermissionListTypes(x.toString());
            options.push({ name: name, id: x.toString() });
        });
        return options;
    }

    const handleCollapseClick = (Id: string) => {
        if (Id) {
            if (getExpandedId() === Id) setExpandedId("");
            else setExpandedId(Id);
        }
    };

    function savePermission(permission: Permission) {
        return put<{}>(`/role/${props.id}/savePermission`, permission);
    }

    function removePermission(type: PermissionType) {
        return put<{}>(`/role/${props.id}/removePermission/${type}`, null);
    }

    const getSelectableValues = (listType: PermissionListType) => {
        var permissionContent = getPermissionContent();

        switch (listType) {
            case PermissionListType.ProcessingActivity:
                return permissionContent.filter((x) => x.type === PermissionListType.ProcessingActivity);
            case PermissionListType.Systems:
                return permissionContent.filter((x) => x.type === PermissionListType.Systems);
            case PermissionListType.AnnualWheel:
                return permissionContent.filter((x) => x.type === PermissionListType.AnnualWheel);
            case PermissionListType.LegalEntities:
                return permissionContent.filter((x) => x.type === PermissionListType.LegalEntities);
            case PermissionListType.Lists:
                return permissionContent.filter((x) => x.type === PermissionListType.Lists);
            case PermissionListType.Record:
                return permissionContent.filter((x) => x.type === PermissionListType.Record);
            case PermissionListType.Risk:
                return permissionContent.filter((x) => x.type === PermissionListType.Risk);
            case PermissionListType.Settings:
                return permissionContent.filter((x) => x.type === PermissionListType.Settings);
            case PermissionListType.TaskManagement:
                return permissionContent.filter((x) => x.type === PermissionListType.TaskManagement);
            case PermissionListType.Policies:
                return permissionContent.filter((x) => x.type === PermissionListType.Policies);
            case PermissionListType.AuditLog:
                return permissionContent.filter((x) => x.type === PermissionListType.AuditLog);
            case PermissionListType.IncidentLog:
                return permissionContent.filter((x) => x.type === PermissionListType.IncidentLog);
            case PermissionListType.VendorManagement:
                return permissionContent.filter((x) => x.type === PermissionListType.VendorManagement);
            case PermissionListType.Declarations:
                return permissionContent.filter((x) => x.type === PermissionListType.Declarations);
            case PermissionListType.Other:
                return permissionContent.filter((x) => x.type === PermissionListType.Other);
        }
    };

    return {
        isLoading,
        data,
        chosenUserRoles,
        getPermissionContent,
        ToggleSelectedMasterPermissionUserRoles,
        handleCollapseClick,
        getExpandedId,
        setMasterPermissionLevel,
        setSubPermission,
        getSubPermissionSelectOptions,
        getSelectableValues,
        PermissionListTypeOptions,
        GetSubPermissionChosenOptions,
        searchInput,
        setSearchInput,
    };

    function getPermissionContent(): Array<PermissionTypeContent> {
        let content: Array<PermissionTypeContent> = [
            {
                masterPermission: { type: PermissionType.ProcessingActivityManagement, level: PermissionLevel.Read },
                subPermissions: getProcessingActivitySubPermissions(),
                type: PermissionListType.ProcessingActivity,
                accessLevel: [PermissionLevel.Read, PermissionLevel.Create, PermissionLevel.Delete],
                order: 1,
                associatedPermissions: [
                    PermissionType.ProcessingActivityMasterData,
                    PermissionType.ProcessingActivityDataSubject,
                    PermissionType.ProcessingActivityAssociation,
                    PermissionType.ProcessingActivityDataCategories,
                    PermissionType.ProcessingActivityLegalBases,
                    PermissionType.ProcessingActivitySystems,
                    PermissionType.ProcessingActivityDataProcessors,
                    PermissionType.ProcessingActivityDisclosures,
                    PermissionType.ProcessingActivitySources,
                    PermissionType.ProcessingActivityDataControllers,
                    PermissionType.CanValidateProcessingActivities,
                    PermissionType.ProcessingActivityJointDataControllers,
                    PermissionType.ProcessingActivityPolicies,
                ],
                disableAccessLevels: false,
            },
            {
                masterPermission: { type: PermissionType.ProcessingActivityMasterData, level: PermissionLevel.Read },
                subPermissions: [PermissionType.ProcessingActivityMasterDataPurpose],
                type: PermissionListType.ProcessingActivity,
                accessLevel: [PermissionLevel.Read, PermissionLevel.Edit],
                order: 3,
                associatedPermissions: undefined,
                disableAccessLevels: false,
            },
            {
                masterPermission: { type: PermissionType.UserManagement, level: PermissionLevel.Delete },
                subPermissions: undefined,
                type: PermissionListType.Settings,
                accessLevel: [PermissionLevel.Read, PermissionLevel.Create, PermissionLevel.Edit, PermissionLevel.Delete],
                order: 1,
                associatedPermissions: undefined,
                disableAccessLevels: true,
            },
            {
                masterPermission: { type: PermissionType.CompanyManagement, level: PermissionLevel.Read },
                subPermissions: undefined,
                type: PermissionListType.LegalEntities,
                accessLevel: [PermissionLevel.Read, PermissionLevel.Create, PermissionLevel.Edit, PermissionLevel.Delete],
                order: 1,
                associatedPermissions: undefined,
                disableAccessLevels: false,
            },
            {
                masterPermission: { type: PermissionType.GroupCompanyManagement, level: PermissionLevel.Delete },
                subPermissions: undefined,
                type: PermissionListType.LegalEntities,
                accessLevel: [PermissionLevel.Read, PermissionLevel.Create, PermissionLevel.Edit, PermissionLevel.Delete],
                order: 2,
                associatedPermissions: undefined,
                disableAccessLevels: true,
            },
            {
                masterPermission: { type: PermissionType.MasterDataManagement, level: PermissionLevel.Delete },
                subPermissions: undefined,
                type: PermissionListType.Settings,
                accessLevel: [PermissionLevel.Read, PermissionLevel.Create, PermissionLevel.Edit, PermissionLevel.Delete],
                order: 3,
                associatedPermissions: undefined,
                disableAccessLevels: true,
            },
            {
                masterPermission: { type: PermissionType.RoleManagement, level: PermissionLevel.Delete },
                subPermissions: undefined,
                type: PermissionListType.Settings,
                accessLevel: [PermissionLevel.Read, PermissionLevel.Create, PermissionLevel.Edit, PermissionLevel.Delete],
                order: 4,
                associatedPermissions: undefined,
                disableAccessLevels: true,
            },
            {
                masterPermission: { type: PermissionType.ProcessingActivityDataSubject, level: PermissionLevel.Read },
                subPermissions: [
                    PermissionType.ProcessingActivityDataSubjectNumberOfProcessors,
                    PermissionType.ProcessingActivityDataSubjectSecurityMeasures,
                    PermissionType.ProcessingActivityDataSubjectNumberOfDataSubjects,
                    PermissionType.ProcessingActivityDataSubjectDataSource,
                    PermissionType.ProcessingActivityDataSubjectNumberOfProcessorsOnlyCustomValue,
                    PermissionType.ProcessingActivityDataSubjectNumberOfDataSubjectsOnlyCustomValue,
                ],
                type: PermissionListType.ProcessingActivity,
                accessLevel: [PermissionLevel.Read, PermissionLevel.Edit],
                order: 4,
                associatedPermissions: undefined,
                disableAccessLevels: false,
            },
            {
                masterPermission: { type: PermissionType.ProcessingActivityAssociation, level: PermissionLevel.Read },
                subPermissions: [
                    PermissionType.ProcessingActivityAssociationGroupEntities,
                    PermissionType.ProcessingActivityAssociationOwnership,
                    PermissionType.ProcessingActivityAssociationsBusinessAreas,
                ],
                type: PermissionListType.ProcessingActivity,
                accessLevel: [PermissionLevel.Read, PermissionLevel.Edit],
                order: 5,
                associatedPermissions: undefined,
                disableAccessLevels: false,
            },
            {
                masterPermission: { type: PermissionType.ProcessingActivityDataCategories, level: PermissionLevel.Read },
                subPermissions: undefined,
                type: PermissionListType.ProcessingActivity,
                accessLevel: [PermissionLevel.Read, PermissionLevel.Edit],
                order: 6,
                associatedPermissions: undefined,
                disableAccessLevels: false,
            },
            {
                masterPermission: { type: PermissionType.ProcessingActivityLegalBases, level: PermissionLevel.Read },
                subPermissions: [PermissionType.ProcessingActivityLegalBasisDeletionPeriods, PermissionType.ProcessingActivityLegalBasisLegalBases],
                type: PermissionListType.ProcessingActivity,
                accessLevel: [PermissionLevel.Read, PermissionLevel.Edit],
                order: 7,
                associatedPermissions: undefined,
                disableAccessLevels: false,
            },
            {
                masterPermission: { type: PermissionType.ProcessingActivitySystems, level: PermissionLevel.Read },
                subPermissions: undefined,
                type: PermissionListType.ProcessingActivity,
                accessLevel: [PermissionLevel.Read, PermissionLevel.Edit],
                order: 8,
                associatedPermissions: undefined,
                disableAccessLevels: false,
            },
            {
                masterPermission: { type: PermissionType.ProcessingActivityDataProcessors, level: PermissionLevel.Read },
                subPermissions: [
                    PermissionType.ProcessingActivityDataProcessorsDataProcessorAgreement,
                    PermissionType.ProcessingActivityDataProcessorsTiaAndTransferBasis,
                    PermissionType.ProcessingActivityDataProcessorsResponsible,
                    PermissionType.ProcessingActivityDataProcessorsSubProcessors,
                    PermissionType.ProcessingActivityDataProcessorsDataCategories,
                ],
                type: PermissionListType.ProcessingActivity,
                accessLevel: [PermissionLevel.Read, PermissionLevel.Edit],
                order: 9,
                associatedPermissions: undefined,
                disableAccessLevels: false,
            },
            {
                masterPermission: { type: PermissionType.ProcessingActivityDisclosures, level: PermissionLevel.Read },
                subPermissions: [
                    PermissionType.ProcessingActivityDisclosuresAgreement,
                    PermissionType.ProcessingActivityDisclosuresTransferBasis,
                    PermissionType.ProcessingActivityDisclosuresBasisOfDisclosure,
                    PermissionType.ProcessingActivityDisclosuresResponsible,
                    PermissionType.ProcessingActivityDisclosuresDataCategories,
                ],
                type: PermissionListType.ProcessingActivity,
                accessLevel: [PermissionLevel.Read, PermissionLevel.Edit],
                order: 10,
                associatedPermissions: undefined,
                disableAccessLevels: false,
            },
            {
                masterPermission: { type: PermissionType.ProcessingActivitySources, level: PermissionLevel.Read },
                subPermissions: [
                    PermissionType.ProcessingActivitySourcesAgreement,
                    PermissionType.ProcessingActivitySourcesResponsible,
                    PermissionType.ProcessingActivitySourcesDataCategories,
                ],
                type: PermissionListType.ProcessingActivity,
                accessLevel: [PermissionLevel.Read, PermissionLevel.Edit],
                order: 11,
                associatedPermissions: undefined,
                disableAccessLevels: false,
            },
            {
                masterPermission: { type: PermissionType.ProcessingActivityDataControllers, level: PermissionLevel.Read },
                subPermissions: [
                    PermissionType.ProcessingActivityDataControllersDataProcessorAgreement,
                    PermissionType.ProcessingActivityDataControllersResponsible,
                    PermissionType.ProcessingActivityDataControllersSubProcessors,
                    PermissionType.ProcessingActivityDataControllersTiaAndTransferBasis,
                    PermissionType.ProcessingActivityDataControllersDataCategories,
                ],
                type: PermissionListType.ProcessingActivity,
                accessLevel: [PermissionLevel.Read, PermissionLevel.Edit],
                order: 12,
                associatedPermissions: undefined,
                disableAccessLevels: false,
            },
            {
                masterPermission: { type: PermissionType.ProcessingActivityJointDataControllers, level: PermissionLevel.Read },
                subPermissions: [
                    PermissionType.ProcessingActivityJointDataControllersAgreement,
                    PermissionType.ProcessingActivityJointDataControllersResponsible,
                    PermissionType.ProcessingActivityJointDataControllersDataCategories,
                ],
                type: PermissionListType.ProcessingActivity,
                accessLevel: [PermissionLevel.Read, PermissionLevel.Edit],
                order: 13,
                associatedPermissions: undefined,
                disableAccessLevels: false,
            },
            {
                masterPermission: { type: PermissionType.Record301, level: null },
                subPermissions: undefined,
                type: PermissionListType.Record,
                accessLevel: [],
                order: 1,
                associatedPermissions: undefined,
                disableAccessLevels: false,
            },
            {
                masterPermission: { type: PermissionType.Record302, level: null },
                subPermissions: undefined,
                type: PermissionListType.Record,
                accessLevel: [],
                order: 2,
                associatedPermissions: undefined,
                disableAccessLevels: false,
            },
            {
                masterPermission: { type: PermissionType.SystemManagement, level: PermissionLevel.Read },
                subPermissions: [PermissionType.StandardTemplateSystemManagement],
                type: PermissionListType.Systems,
                accessLevel: [PermissionLevel.Read, PermissionLevel.Create, PermissionLevel.Delete],
                order: 1,
                associatedPermissions: [
                    PermissionType.SystemDescription,
                    PermissionType.SystemAssociation,
                    PermissionType.SystemDataProcessor,
                    PermissionType.SystemInformation,
                    PermissionType.SystemPolicies,
                    PermissionType.SystemDataAndStorage,
                    PermissionType.SystemPersonalData,
                    PermissionType.SystemAssociatedAssets,
                    PermissionType.SystemAI,
                ],
                disableAccessLevels: false,
            },
            {
                masterPermission: { type: PermissionType.SystemDescription, level: PermissionLevel.Read },
                subPermissions: undefined,
                type: PermissionListType.Systems,
                accessLevel: [PermissionLevel.Read, PermissionLevel.Edit],
                order: 2,
                associatedPermissions: undefined,
                disableAccessLevels: false,
            },
            {
                masterPermission: { type: PermissionType.SystemAssociation, level: PermissionLevel.Read },
                subPermissions: [PermissionType.SystemAssociationSystemType],
                type: PermissionListType.Systems,
                accessLevel: [PermissionLevel.Read, PermissionLevel.Edit],
                order: 3,
                associatedPermissions: undefined,
                disableAccessLevels: false,
            },
            {
                masterPermission: { type: PermissionType.SystemDataProcessor, level: PermissionLevel.Read },
                subPermissions: [
                    PermissionType.SystemDataProcessorDataProcessorAgreement,
                    PermissionType.SystemDataProcessorStorageLocaion,
                    PermissionType.SystemDataProcessorSupportAccess,
                    PermissionType.SystemDataProcessorSubProcessors,
                    PermissionType.SystemDataProcessorTiaAndTransferBasis,
                ],
                type: PermissionListType.Systems,
                accessLevel: [PermissionLevel.Read, PermissionLevel.Edit],
                order: 5,
                associatedPermissions: undefined,
                disableAccessLevels: false,
            },
            {
                masterPermission: { type: PermissionType.SystemInformation, level: PermissionLevel.Read },
                subPermissions: [PermissionType.SystemInformationSecurityMeasures],
                type: PermissionListType.Systems,
                accessLevel: [PermissionLevel.Read, PermissionLevel.Edit],
                order: 6,
                associatedPermissions: undefined,
                disableAccessLevels: false,
            },
            {
                masterPermission: { type: PermissionType.SystemAI, level: PermissionLevel.Read },
                subPermissions: undefined,
                type: PermissionListType.Systems,
                accessLevel: [PermissionLevel.Read, PermissionLevel.Edit],
                order: 7,
                associatedPermissions: undefined,
                disableAccessLevels: false,
            },
            {
                masterPermission: { type: PermissionType.CustomListManagement, level: PermissionLevel.Read },
                subPermissions: undefined,
                type: PermissionListType.Lists,
                accessLevel: getCustomListsAccessLevels(),
                order: 1,
                associatedPermissions: undefined,
                disableAccessLevels: false,
            },
            {
                masterPermission: { type: PermissionType.CompanyDocumentationManagement, level: PermissionLevel.Edit },
                subPermissions: undefined,
                type: PermissionListType.LegalEntities,
                accessLevel: [PermissionLevel.Create, PermissionLevel.Edit, PermissionLevel.Delete],
                order: 2,
                associatedPermissions: undefined,
                disableAccessLevels: false,
            },
            {
                masterPermission: { type: PermissionType.PolicyManagement, level: PermissionLevel.Read },
                subPermissions: undefined,
                type: PermissionListType.Policies,
                accessLevel: [PermissionLevel.Read, PermissionLevel.Create, PermissionLevel.Edit, PermissionLevel.Delete],
                order: 1,
                associatedPermissions: undefined,
                disableAccessLevels: false,
            },
            {
                masterPermission: { type: PermissionType.ProcessingActivityPolicies, level: PermissionLevel.Read },
                subPermissions: undefined,
                type: PermissionListType.ProcessingActivity,
                accessLevel: [PermissionLevel.Read, PermissionLevel.Edit],
                order: 13,
                associatedPermissions: undefined,
                disableAccessLevels: false,
            },
            {
                masterPermission: { type: PermissionType.DocumentStatus, level: null },
                subPermissions: undefined,
                type: PermissionListType.LegalEntities,
                accessLevel: [],
                order: 3,
                associatedPermissions: undefined,
                disableAccessLevels: false,
            },
            {
                masterPermission: { type: PermissionType.DeleteDocumentVersion, level: null },
                subPermissions: undefined,
                type: PermissionListType.LegalEntities,
                accessLevel: [],
                order: 4,
                associatedPermissions: undefined,
                disableAccessLevels: false,
            },
            {
                masterPermission: { type: PermissionType.SystemPolicies, level: PermissionLevel.Read },
                subPermissions: undefined,
                type: PermissionListType.Systems,
                accessLevel: [PermissionLevel.Read, PermissionLevel.Edit],
                order: 9,
                associatedPermissions: undefined,
                disableAccessLevels: false,
            },
            {
                masterPermission: { type: PermissionType.SystemDataAndStorage, level: PermissionLevel.Read },
                subPermissions: [PermissionType.SystemDataAndStorageStorageLocaion, PermissionType.SystemDataAndStorageSupportAccess],
                type: PermissionListType.Systems,
                accessLevel: [PermissionLevel.Read, PermissionLevel.Edit],
                order: 4,
                associatedPermissions: undefined,
                disableAccessLevels: false,
            },
        ];

        content.push({
            masterPermission: { type: PermissionType.RiskManagement, level: PermissionLevel.Read },
            subPermissions: undefined,
            type: PermissionListType.Risk,
            accessLevel: [PermissionLevel.Read, PermissionLevel.Edit],
            order: 1,
            associatedPermissions: [PermissionType.RiskTemplateManagement],
            disableAccessLevels: false,
        });

        content.push({
            masterPermission: { type: PermissionType.RiskTemplateManagement, level: PermissionLevel.Read },
            subPermissions: undefined,
            type: PermissionListType.Risk,
            accessLevel: [PermissionLevel.Read, PermissionLevel.Create, PermissionLevel.Edit, PermissionLevel.Delete],
            order: 2,
            associatedPermissions: undefined,
            disableAccessLevels: false,
        });

        content.push({
            masterPermission: { type: PermissionType.RiskVersionManagement, level: PermissionLevel.Read },
            subPermissions: undefined,
            type: PermissionListType.Risk,
            accessLevel: [PermissionLevel.Read, PermissionLevel.Create, PermissionLevel.Edit, PermissionLevel.Delete],
            order: 3,
            associatedPermissions: undefined,
            disableAccessLevels: false,
        });

        if (hasTaskManagementAccess) {
            content.push({
                masterPermission: { type: PermissionType.AnnualWheelManagement, level: PermissionLevel.Read },
                subPermissions: undefined,
                type: PermissionListType.AnnualWheel,
                accessLevel: [PermissionLevel.Read, PermissionLevel.Create, PermissionLevel.Edit, PermissionLevel.Delete],
                order: 1,
                associatedPermissions: undefined,
                disableAccessLevels: false,
            });

            content.push({
                masterPermission: { type: PermissionType.TaskManagement, level: PermissionLevel.Read },
                subPermissions: [PermissionType.DefaultNotificationReceiver, PermissionType.TaskMasterDataManagement],
                type: PermissionListType.TaskManagement,
                accessLevel: [PermissionLevel.Read, PermissionLevel.Create, PermissionLevel.Edit, PermissionLevel.Delete],
                order: 1,
                associatedPermissions: undefined,
                disableAccessLevels: false,
            });
        }

        if (platformFeatures.auditLog)
            content.push({
                masterPermission: { type: PermissionType.CanAccessAuditLog, level: null },
                subPermissions: undefined,
                type: PermissionListType.AuditLog,
                accessLevel: [],
                order: 14,
                associatedPermissions: undefined,
                disableAccessLevels: false,
            });

        content.push({
            masterPermission: { type: PermissionType.GdprIncidentLog, level: PermissionLevel.Read },
            subPermissions: undefined,
            type: PermissionListType.IncidentLog,
            accessLevel: [PermissionLevel.Read, PermissionLevel.Create, PermissionLevel.Edit, PermissionLevel.Delete],
            order: 1,
            associatedPermissions: undefined,
            disableAccessLevels: false,
        });

        if (informationSecurityFeatures.personalData)
            content.push({
                masterPermission: { type: PermissionType.SystemPersonalData, level: PermissionLevel.Read },
                subPermissions: undefined,
                type: PermissionListType.Systems,
                accessLevel: [PermissionLevel.Read, PermissionLevel.Edit],
                order: 10,
                associatedPermissions: undefined,
                disableAccessLevels: false,
            });

        if (informationSecurityFeatures.associatedAssets)
            content.push({
                masterPermission: { type: PermissionType.SystemAssociatedAssets, level: PermissionLevel.Read },
                subPermissions: undefined,
                type: PermissionListType.Systems,
                accessLevel: [PermissionLevel.Read, PermissionLevel.Edit],
                order: 8,
                associatedPermissions: undefined,
                disableAccessLevels: false,
            });

        if (hasVendorManagementAccess) {
            content.push({
                masterPermission: { type: PermissionType.InternalAssessment, level: PermissionLevel.Delete },
                subPermissions: undefined,
                type: PermissionListType.VendorManagement,
                accessLevel: [PermissionLevel.Read, PermissionLevel.Create, PermissionLevel.Edit, PermissionLevel.Delete],
                order: 1,
                associatedPermissions: undefined,
                disableAccessLevels: true,
            });
            content.push({
                masterPermission: { type: PermissionType.AuditGroups, level: PermissionLevel.Delete },
                subPermissions: undefined,
                type: PermissionListType.VendorManagement,
                accessLevel: [PermissionLevel.Read, PermissionLevel.Create, PermissionLevel.Edit, PermissionLevel.Delete],
                order: 2,
                associatedPermissions: undefined,
                disableAccessLevels: true,
            });
        }

        if (addonsFeatures.declarations) {
            content.push({
                masterPermission: { type: PermissionType.DeclarationManagement, level: PermissionLevel.Read },
                subPermissions: undefined,
                type: PermissionListType.Declarations,
                accessLevel: [PermissionLevel.Read, PermissionLevel.Create, PermissionLevel.Edit, PermissionLevel.Delete],
                order: 1,
                associatedPermissions: [PermissionType.DeclarationAuditorApproval],
                disableAccessLevels: false,
            });

            content.push({
                masterPermission: { type: PermissionType.DeclarationAuditorApproval, level: null },
                subPermissions: undefined,
                type: PermissionListType.Declarations,
                accessLevel: [],
                order: 2,
                associatedPermissions: undefined,
                disableAccessLevels: false,
            });
        }

        if (platformFeatures.tags) {
            content.push({
                masterPermission: { type: PermissionType.Tags, level: null },
                subPermissions: undefined,
                type: PermissionListType.Other,
                accessLevel: [],
                order: 1,
                associatedPermissions: undefined,
                disableAccessLevels: false,
            });
        }

        return content;
    }

    function getCustomListsAccessLevels() {
        var accessLevels = [PermissionLevel.Read];

        if (addonsFeatures.customLists) {
            accessLevels.push(PermissionLevel.Create);
            accessLevels.push(PermissionLevel.Edit);
            accessLevels.push(PermissionLevel.Delete);
        }

        return accessLevels;
    }

    function getProcessingActivitySubPermissions() {
        var subPermissions = [
            PermissionType.CanValidateProcessingActivities,
            PermissionType.StandardTemplateProcessingActivityManagement,
            PermissionType.ProcessingActivityStatus,
            PermissionType.ProcessingActivtyNote,
            PermissionType.ProcessingActivityCopy,
        ];

        if (platformFeatures.commentModule) {
            subPermissions.push(PermissionType.ProcessingActivityComments);
        }

        if (addonsFeatures.research) {
            subPermissions.push(PermissionType.ProcessingActivityDraftStatusManagement);
            subPermissions.push(PermissionType.ProcessingActivityStatistic);
        }

        return subPermissions;
    }

    function filterSubPermissionAddAddPermission(permissionType: PermissionType) {
        return (
            permissionType !== PermissionType.ProcessingActivityDataSubjectNumberOfProcessorsOnlyCustomValue &&
            permissionType !== PermissionType.ProcessingActivityDataSubjectNumberOfDataSubjectsOnlyCustomValue
        );
    }
}
