import { useQuery } from "react-query";
import { get } from "../../../api/apiShared";
import { SelectableItem } from "../../../../processingActivity/ProcessingActivity.types";
import { IAssociatedInternalAssessmentDialog } from "./AssociatedInternalAssessmentDialog";

export default function useAssociatedInternalAssessmentDialogHooks(props: IAssociatedInternalAssessmentDialog) {
    const url = "/internalAssessments/selectableItems";

    let { data, isLoading } = useQuery(url, () => get<Array<SelectableItem>>(url));

    if (data) {
        data = data.filter((x) => !props.existingInternalAssessments.includes(x.id));
    }

    const onSelectInternalAssessmentChange = (internalAssessmentIds: Array<string>) => {
        if (internalAssessmentIds.length > props.chosenOptions.length) {
            let addInternalAssessmentId = internalAssessmentIds.filter((id) => !props.chosenOptions.includes(id))[0];
            props.onAddInternalAssessment(addInternalAssessmentId);
        } else if (internalAssessmentIds.length < props.chosenOptions.length) {
            let deletedInternalAssessmentId = props.chosenOptions.filter((id) => !internalAssessmentIds.includes(id))[0];
            props.onDeleteInternalAssessment(deletedInternalAssessmentId);
        }
    };

    return { data, isLoading, onSelectInternalAssessmentChange };
}
