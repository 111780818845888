import { DotLegalDialog, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../../../localization/useTranslation";
import { useSystemSupplierDescriptionDialogHooks } from "./SystemSupplierDescriptionDialog.hooks";

export interface ISystemSupplierDescriptionDialog {
    setShowDescriptionDialog: () => void;
    readonly: boolean;
    description: string;
    onOkClick: (description: string) => void;
}

function SystemSupplierDescriptionDialog(props: ISystemSupplierDescriptionDialog) {
    const { translateString } = useTranslation();
    const { description, setDescription } = useSystemSupplierDescriptionDialogHooks(props);

    return (
        <DotLegalDialog
            header={translateString("description")}
            buttonOkText={translateString("save")}
            open
            onDialogClose={props.setShowDescriptionDialog}
            onOkClick={() => props.onOkClick(description)}
            size={"sm"}
            hideOkButton={props.readonly}
            disableBackdropOnCloseIfValueChange={description}
        >
            <DotLegalTextField
                debounce={false}
                label={translateString("description")}
                onChange={(e) => setDescription(e)}
                value={description}
                multiline
                rows={15}
                disabled={props.readonly}
            />
        </DotLegalDialog>
    );
}

export default SystemSupplierDescriptionDialog;
