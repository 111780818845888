import { useTheme } from "@mui/material";
import { createSxStyles } from "../common/createSxStyles";

export const useNotificationStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        root: {
            height: "100%",
        },
        notificationCard: {
            marginBottom: theme.spacing(3),
        },
        content: {
            marginBottom: theme.spacing(4),
        },
        buttons: {
            display: "flex",
            justifyContent: "flex-start",
            gap: theme.spacing(1),
        },
        introVideo: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(3),
        },
        section: {
            marginTop: theme.spacing(2),
        },
        processingActivityContent: {
            marginTop: theme.spacing(1),
            marginLeft: theme.spacing(2),
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.secondary.main,
        },
        detailsHeader: {
            display: "flex",
            justifyContent: "space-between",
            fontSize: 20 + "px",
            fontWeight: theme.typography.fontWeightBold,
            borderBottom: "1px solid " + theme.palette.grey[300],
            marginBottom: theme.spacing(3),
        },
        notificationType: {
            color: theme.palette.primary.dark,
        },
        notificationDate: {
            color: theme.palette.grey[400],
        },
        detailsContainer: {
            margin: theme.spacing(3),
        },
        detailsContent: {
            color: theme.palette.primary.dark,
            fontSize: theme.typography.pxToRem(14),
            "& img": {
                width: "100%",
            },
        },
        player: {
            display: "flex",
            justifyContent: "center",
            "& :first-child": {
                maxWidth: 895 + "px",
                maxHeight: 503 + "px",
                width: "100% !important",
                height: "100% !important",
            },
            "& video": {
                boxShadow: "0px 3px 6px #00000029",
                borderRadius: 19 + "px",
            },
        },
        notificationList: {
            height: "100%",
        },
    });
};
