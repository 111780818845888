import { useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";

export const useAnnualWheelListOverviewStyles = () => {
    const theme = useTheme();

    return createSxStyles({
        chip: {
            margin: theme.spacing(0.5),
            display: "inline-block",
        },
        nameContainer: {
            display: "flex",
            gap: 1,
            alignItems: "center",
        },
        img: {
            display: "flex",
            alignItems: "center",
        },
    });
};
