import { useMutation, useQuery, useQueryClient } from "react-query";
import { deleteHttp, get, post } from "../../../../common/api/apiShared";
import { ajaxQueue } from "@dotlegal/dotlegal-ui-components";
import { useState } from "react";
import { ISystemInternalAssessmentDialogProps } from "./SystemAssociatedInternalAssessmentDialog";

export default function useSystemAssociatedInternalAssessmentDialogHooks(props: ISystemInternalAssessmentDialogProps) {
    const [selectedInternalAssessments, setSelectedInternalAssessments] = useState<Array<string>>([]);

    const addAssociatedInternalAssessmentMutation = useMutation(addAssociatedInternalAssessmentAPI);

    const addAssociatedInternalAssessment = async (internalAssessmentId: string) => {
        let temp = [...selectedInternalAssessments];
        temp.push(internalAssessmentId);

        setSelectedInternalAssessments(temp);

        await ajaxQueue().addRequest(addAssociatedInternalAssessmentMutation.mutateAsync, {
            internalAssessmentId: internalAssessmentId,
        });
    };

    const removeAssociatedInternalAssessmentMutation = useMutation(removeAssociatedInternalAssessmentAPI);

    const removeAssociatedInternalAssessment = async (internalAssessmentId: string) => {
        let temp = [...selectedInternalAssessments];
        temp = temp.filter((id) => id !== internalAssessmentId);

        setSelectedInternalAssessments(temp);

        await ajaxQueue().addRequest(removeAssociatedInternalAssessmentMutation.mutateAsync, {
            internalAssessmentId: internalAssessmentId,
        });
    };

    return { addAssociatedInternalAssessment, removeAssociatedInternalAssessment, selectedInternalAssessments };

    async function addAssociatedInternalAssessmentAPI(data: { internalAssessmentId: string }) {
        return await post("/systemAssociatedInternalAssessment/" + props.systemId, data.internalAssessmentId);
    }

    async function removeAssociatedInternalAssessmentAPI(data: { internalAssessmentId: string }) {
        return await deleteHttp("/systemAssociatedInternalAssessment/" + props.systemId + "/" + data.internalAssessmentId);
    }
}
