import { useMutation, useQuery } from "react-query";
import { SelectableColoredItem } from "../../../processingActivity/ProcessingActivity.types";
import { deleteHttp, get, post } from "../../api/apiShared";
import { TagsMultiSelectProps } from "./TagsMultiSelect";
import { useEffect, useState } from "react";
import { useOptimisticUpdate } from "../../hooks/useOptimisticUpdate";
import { TagRelationCreateModel } from "./TagsMultiSelect.types";
import { isNullOrWhitespace } from "../../stringOperations";
import { useSelectableTagsQuery } from "../../hooks/useSelectableItemQueries.ts";
import { usePlanContext } from "../../../auth/planProvider/PlanProvider.tsx";

export function useTagsMultiSelect(props: TagsMultiSelectProps) {
    const { platformFeatures } = usePlanContext();
    const parentHandlesMutation = props.customControl !== undefined;

    const [showCreateTagDialog, setShowCreateTagDialog] = useState(false);
    const [selectedTags, setSelectedTags] = useState<Array<string>>(parentHandlesMutation ? props.customControl!.selectedTags : []);

    const { putOnQueueAndSetQueryData } = useOptimisticUpdate();

    const enableTagRelationsQuery = props.entityId !== undefined && !isNullOrWhitespace(props.entityId) && platformFeatures.tags;
    let selectableTagsQuery = useSelectableTagsQuery(platformFeatures.tags);

    const tagRelationUrl = `/tagRelation/${props.entityId}`;
    const { isLoading, data, isRefetching } = useQuery(tagRelationUrl, () => get<Array<string>>(tagRelationUrl), { enabled: enableTagRelationsQuery });

    useEffect(() => {
        if ((data && !parentHandlesMutation) || (selectedTags.length === 0 && data && !isRefetching)) {
            setSelectedTags(data);
            if (parentHandlesMutation) {
                props.customControl!.onChange(data);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isRefetching]);

    const onTagCreated = async (tagId: string) => {
        if (parentHandlesMutation) {
            let tempTags = [...selectedTags];
            tempTags.push(tagId);
            setSelectedTags(tempTags);
            props.customControl!.onChange!(tempTags);
        } else {
            await onAddTagRelation(tagId);
        }
        setShowCreateTagDialog(false);
        selectableTagsQuery.refetch();
    };

    const addTagRelationMutation = useMutation(addTagRelationApi);
    const onAddTagRelation = async (tagId: string) => {
        if (parentHandlesMutation) return;

        const newRelation = createNewRelation(tagId);
        let tempTags = [...selectedTags];
        tempTags.push(tagId);
        putOnQueueAndSetQueryData(tempTags, tagRelationUrl, addTagRelationMutation.mutateAsync, newRelation);
    };

    const removeTagRelationMutation = useMutation(removeTagRelationApi);
    const onRemoveTagRelation = async (tagId: string) => {
        if (parentHandlesMutation) return;

        let tempRelations = [...selectedTags];
        tempRelations = tempRelations.filter((x) => x !== tagId);
        putOnQueueAndSetQueryData(tempRelations, tagRelationUrl, removeTagRelationMutation.mutateAsync, { entityId: props.entityId!, tagId: tagId });
    };

    const onRelationChange = (tags: Array<string>) => {
        setSelectedTags(tags);
        props.customControl!.onChange!(tags);
    };

    return {
        isLoading: selectableTagsQuery.isLoading || isLoading,
        selectableTags: selectableTagsQuery.data ?? [],
        showCreateTagDialog,
        selectedTags,
        setShowCreateTagDialog,
        onTagCreated,
        onRemoveTagRelation,
        onAddTagRelation,
        onRelationChange,
        parentHandlesMutation,
    };

    function createNewRelation(tagId: string): TagRelationCreateModel {
        return { tagId: tagId, entityId: props.entityId!, entityType: props.entityType };
    }

    async function addTagRelationApi(tagRelation: TagRelationCreateModel) {
        return await post("/tagRelation", tagRelation);
    }

    async function removeTagRelationApi(data: { entityId: string; tagId: string }) {
        return await deleteHttp(`/tagRelation/${data.entityId}?tagId=${data.tagId}`);
    }
}
