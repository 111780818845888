import { useTheme } from "@mui/material";
import { createSxStyles } from "../../createSxStyles";

export const useDotLegalRecordHeaderStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        table: {
            marginTop: theme.spacing(2),
        },
        controls: {
            alignItems: "start",
        },
        exportContainer: {
            display: "flex",
            justifyContent: "right",
        },
        switchBoxContainer: {
            display: "inline-block",
        },
    });
};
