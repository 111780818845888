import React from "react";
import { useNotificationEmptyStateStyles } from "./NotificationEmptyState.styles";
import NoData from "./noNotificationSelected.svg?react";
import { Box } from "@mui/material";

export interface INotificationEmptyState {
    notificationText: string;
    isPopOver: boolean;
}

function NotificationEmptyState(props: INotificationEmptyState) {
    const styles = useNotificationEmptyStateStyles(props);

    return (
        <Box sx={styles.emptyState}>
            <Box sx={styles.imgContainer}>
                <NoData />
            </Box>
            <Box component={"span"} sx={styles.emptyStateText}>
                {props.notificationText}
            </Box>
        </Box>
    );
}

export default NotificationEmptyState;
