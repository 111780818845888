import React from "react";
import { DotLegalDialog, DotLegalSelect, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { DeletionPeriodViewModel } from "../deletionPeriods/DeletionPeriods.types";
import { useAddEditDeletionPeriodDialogHooks } from "./AddEditDeletionPeriodDialog.hooks";
import { isNullOrWhitespace } from "../../../common/stringOperations";
import { Grid } from "@mui/material";
import { useAddEditDeletionPeriodDialogStyles } from "./AddEditDeletionPeriodDialog.styles";

export interface IAddEditDeletionPeriodDialog {
    deletionPeriod?: DeletionPeriodViewModel;
    open: boolean;
    isAdd: boolean;
    onDialogClose: () => void;
    onOkClick: (response: any, name: string) => void;
    showGlobal?: boolean;
    refetch: () => void;
}

export function AddEditDeletionPeriodDialog(props: IAddEditDeletionPeriodDialog) {
    const { translateString } = useTranslation();
    const { viewModel, setViewModel, getDeletionPeriodFrequencyTypeOption, validateDeletionPeriodName, validateDeletionPeriodForm, errors, isSaving } =
        useAddEditDeletionPeriodDialogHooks(props);

    const styles = useAddEditDeletionPeriodDialogStyles();

    return (
        <DotLegalDialog
            size="sm"
            inProgress={isSaving}
            header={props.isAdd ? translateString("createDeletionPeriod") : translateString("editDelectionPeriod")}
            buttonOkText={props.isAdd ? translateString("create") : translateString("save")}
            open={props.open}
            onDialogClose={props.onDialogClose}
            onOkClick={validateDeletionPeriodForm}
            disableBackdropOnCloseIfValueChange={viewModel}
        >
            <Grid container justifyContent="center" alignContent="center" sx={styles.root}>
                <Grid item xs={12}>
                    <DotLegalTextField
                        label={translateString("name")}
                        value={viewModel?.name!}
                        disabled={!props.isAdd && !viewModel?.isCustom}
                        debounce={false}
                        errorText={errors.nameError}
                        onChange={(name) => {
                            let tempViewModel = { ...viewModel! };
                            tempViewModel.name = name;
                            validateDeletionPeriodName(name);
                            setViewModel(tempViewModel);
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <DotLegalTextField
                        label={translateString("description")}
                        value={viewModel?.description!}
                        debounce={false}
                        multiline
                        rows={5}
                        onChange={(description) => {
                            let tempViewModel = { ...viewModel! };
                            tempViewModel.description = description;
                            setViewModel(tempViewModel);
                        }}
                    />
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <DotLegalTextField
                            label={translateString("deletionPeriodLength")}
                            disabled={!props.isAdd && !viewModel?.isCustom}
                            value={viewModel?.deletionPeriodLength?.toString() ?? ""}
                            onlyNumber={"integer"}
                            errorText={errors.deletionPeriodFrequencyError}
                            onChange={(deletionPeriodLength) => {
                                let tempViewModel = { ...viewModel! };
                                tempViewModel.deletionPeriodLength = isNullOrWhitespace(deletionPeriodLength) ? undefined : Number(deletionPeriodLength);
                                setViewModel(tempViewModel);
                            }}
                            debounce={false}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <DotLegalSelect
                            paperSx={styles.paperComponent}
                            errorText={errors.deletionPeriodTypeError}
                            disabled={!props.isAdd && !viewModel?.isCustom}
                            label={translateString("type")}
                            options={getDeletionPeriodFrequencyTypeOption()}
                            placeholder={""}
                            selectedItem={
                                viewModel?.deletionPeriodType === undefined || viewModel?.deletionPeriodType === null
                                    ? null
                                    : viewModel?.deletionPeriodType!.toString()
                            }
                            onChange={(type: any) => {
                                let tempViewModel = { ...viewModel! };
                                tempViewModel.deletionPeriodType = type ? Number(type!) : undefined;
                                setViewModel(tempViewModel);
                            }}
                            noOptionsLabel={translateString("noOptions")}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </DotLegalDialog>
    );
}
