import { DotLegalDialog, DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { Box } from "@mui/material";

export interface SystemOverviewAIDialogProps {
    onDialogClose: () => void;
    usesAI: boolean;
    companyUsesAIService: boolean;
    aiUsageDescription: string | undefined;
    isUseOfAIConsideredHighRisk: boolean;
    isDataUsedForTraningOfAIModel: boolean;
}

function SystemOverviewAIDialog(props: SystemOverviewAIDialogProps) {
    const { translateString } = useTranslation();

    return (
        <DotLegalDialog
            header={translateString("aiHeader")}
            hideOkButton
            size="md"
            buttonOkText={""}
            open
            onDialogClose={props.onDialogClose}
            onOkClick={() => {}}
        >
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <DotLegalHeader marginBottom={0} fontWeight={400} headerStyle="small">
                    {translateString("assetUseAI")}
                </DotLegalHeader>
                <DotLegalHeader marginTop={0} fontWeight={400} headerStyle="extraSmall">
                    {translateString(props.usesAI.toString())}
                </DotLegalHeader>

                <DotLegalHeader marginBottom={0} fontWeight={400} headerStyle="small">
                    {translateString("companyUsesAIProvidedByAsset")}
                </DotLegalHeader>
                <DotLegalHeader marginTop={0} fontWeight={400} headerStyle="extraSmall">
                    {translateString(props.companyUsesAIService.toString())}
                </DotLegalHeader>

                {props.companyUsesAIService && (
                    <>
                        <DotLegalHeader marginBottom={0} fontWeight={400} headerStyle="small">
                            {translateString("descriptionOfAIUsage")}
                        </DotLegalHeader>
                        <Box sx={{ whiteSpace: "pre-wrap" }}>
                            <DotLegalHeader marginTop={0} fontWeight={400} headerStyle="extraSmall">
                                {props.aiUsageDescription}
                            </DotLegalHeader>
                        </Box>

                        <DotLegalHeader marginBottom={0} fontWeight={400} headerStyle="small">
                            {translateString("useOfAIConsideredHighRisk")}
                        </DotLegalHeader>
                        <DotLegalHeader marginTop={0} fontWeight={400} headerStyle="extraSmall">
                            {translateString(props.isUseOfAIConsideredHighRisk.toString())}
                        </DotLegalHeader>

                        <DotLegalHeader marginBottom={0} fontWeight={400} headerStyle="small">
                            {translateString("isDataUsedForTraningOfAIModel")}
                        </DotLegalHeader>
                        <DotLegalHeader marginTop={0} marginBottom={0} fontWeight={400} headerStyle="extraSmall">
                            {translateString(props.isDataUsedForTraningOfAIModel.toString())}
                        </DotLegalHeader>
                    </>
                )}
            </Box>
        </DotLegalDialog>
    );
}

export default SystemOverviewAIDialog;
