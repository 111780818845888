import { Box, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "../../localization/useTranslation";
import { useCommentOverviewStyles } from "./CommentOverview.styles";
import { DotLegalEmptyState } from "@dotlegal/dotlegal-ui-components";
import NoComments from "../commentDialog/noComments.svg?react";
import CommentTrack from "../commentTrack/CommentTrack";
import { CommentType } from "../commentDialog/CommentDialog.types";
import CreateEditComment from "../createEditComment/CreateEditComment";
import { CommentUpdateModel } from "../createEditComment/CreateEditComment.types";
import { CommentViewModel } from "../commentCard/CommentCard.types";
import { useStateSessionStorage } from "../../common/hooks/useStateSessionStorage";

export interface CommentOverviewProps {
    isLoading: boolean;
    id: string;
    data: Array<CommentViewModel> | undefined;
    scrollToBottomOnNewComment?: boolean;
    textFieldOnTop?: boolean;
    isSubscriptionSaving: boolean;
    subscribed: boolean;
    maxHeight: string | number;
    commentIdToScroll?: string;
    notificationText: string;
    expandInputOnFocus?: boolean;
    showEmptyState?: boolean;
    disabled?: boolean;
    disabledText?: string;
    disableInitialTextFieldFocus?: boolean;
    onDeleteComment: (commentId: string) => void;
    onDeleteSubComment: (commentId: string, subCommentId: string) => void;
    onCreateComment: (content: string) => void;
    onCreateSubComment: (commentId: string, content: string) => void;
    onUpdateComment: (updateModel: CommentUpdateModel) => void;
    onUpdateSubComment: (updateModel: CommentUpdateModel) => void;
    onSubscriptionChanged: (subscribed: boolean) => void;
    onPinClick?: (isPinned: boolean, commentId: string) => void;
}

function CommentOverview(props: CommentOverviewProps) {
    const styles = useCommentOverviewStyles();
    const { translateString } = useTranslation();
    const [commentType, setCommentType] = useStateSessionStorage(`commentType${props.id}`, CommentType.CreateComment);
    const [resetSessionContent, setResetSessionContent] = useState(false);

    useEffect(() => {
        if (commentType !== CommentType.CreateComment) {
            // Runs on mount - Handle when the components mouunts. First time or refresh
            setResetSessionContent(true);
            setCommentType(CommentType.CreateComment);
        }

        return () => {
            /* Cleans unused sessionStorage keys on umount */
            let commentType = sessionStorage.getItem(`commentType${props.id}`);
            if (commentType !== null && commentType !== CommentType.CreateComment.toString()) {
                sessionStorage.removeItem(`${props.id}`);
            }
            setResetSessionContent(false);
            sessionStorage.removeItem(`commentType${props.id}`);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [commentId, setCommentId] = useState<string | undefined>(undefined);
    const [commentUpdateModel, setCommentUpdateModel] = useState<CommentUpdateModel>();

    function getSkeletons() {
        return [1, 2, 3, 4, 5, 6].map((x) => {
            return <Skeleton key={x} variant="text" width="100%" height={30} />;
        });
    }

    function onCommentSubmitted(content: string) {
        switch (commentType) {
            case CommentType.CreateComment:
                props.onCreateComment(content);
                break;
            case CommentType.CreateSubComment:
                props.onCreateSubComment(commentId!, content);
                break;
            case CommentType.EditComment:
                props.onUpdateComment({ commentId: commentUpdateModel!.commentId, content: content });
                break;
            case CommentType.EditSubComment:
                props.onUpdateSubComment({ commentId: commentUpdateModel!.commentId, content: content, subCommentId: commentUpdateModel!.subCommentId });
                break;
        }
        setCommentType(CommentType.CreateComment);
    }

    return (
        <Box sx={{ display: "flex", flexDirection: props.textFieldOnTop ? "column-reverse" : "column", maxHeight: props.maxHeight }}>
            {props.isLoading ? (
                <React.Fragment>
                    <Box sx={styles.skeletons}>{getSkeletons()}</Box>
                </React.Fragment>
            ) : props.data?.length === 0 && props.showEmptyState ? (
                <Box sx={styles.empty}>
                    <DotLegalEmptyState icon={<NoComments />} text={translateString("noCommentsOnProcessingActivity")} />
                </Box>
            ) : (
                <CommentTrack
                    data={props.data}
                    maxHeight={props.maxHeight}
                    disableOverflowMenu={commentType !== CommentType.CreateComment || (props.disabled !== undefined && props.disabled)}
                    commentIdToScroll={props.commentIdToScroll}
                    scrollToBottomOnNewComment={props.scrollToBottomOnNewComment}
                    onDeleteComment={props.onDeleteComment}
                    onDeleteSubComment={props.onDeleteSubComment}
                    onEditComment={(commentId, content, pinned) => {
                        setCommentType(CommentType.EditComment);
                        setCommentUpdateModel({ commentId: commentId, content: content, pinned: pinned });
                    }}
                    onEditSubComment={(commentId, subCommentId, content) => {
                        setCommentType(CommentType.EditSubComment);
                        setCommentUpdateModel({
                            commentId: commentId,
                            subCommentId: subCommentId,
                            content: content,
                        });
                    }}
                    onReplyToComment={(commentId) => {
                        setCommentType(CommentType.CreateSubComment);
                        setCommentId(commentId);
                    }}
                    onPinClick={props.onPinClick}
                />
            )}

            <CreateEditComment
                id={props.id}
                textFieldOnTop
                onCommentSubmitted={(content) => {
                    onCommentSubmitted(content);
                }}
                onSubscriptionChanged={(subscribed) => props.onSubscriptionChanged(subscribed)}
                onCancelClicked={() => {
                    setCommentUpdateModel(undefined);
                    setCommentType(CommentType.CreateComment);
                }}
                commentType={commentType}
                isSaving={props.isSubscriptionSaving}
                subscribed={props.subscribed}
                content={commentUpdateModel?.content}
                notificationText={props.notificationText}
                expandInputOnFocus={props.expandInputOnFocus}
                disabled={props.disabled}
                disableInitialTextFieldFocus={props.disableInitialTextFieldFocus}
                disabledText={props.disabledText}
                shouldResetSessionContent={resetSessionContent}
            />
        </Box>
    );
}

export default CommentOverview;
