import React, { createContext, ReactNode, useCallback } from "react";
import i18n, { TOptions } from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next, useTranslation } from "react-i18next";
import { setJwtToken } from "../common/api/apiShared";
import { useQueryClient } from "react-query";
import { isNullOrWhitespace } from "../common/stringOperations";
import { getSettings } from "../common/settingsProvider";
import { PlanType } from "../auth/planProvider/PlanProvider.types";
import { OperationCriticalityLevelEnum } from "../system/systemEdit/SystemEdit.types.ts";

export type TranslationStringType = (key: string, options?: TOptions | string) => string;

export function formatAMPM(date: Date) {
    let hours = convertHoursToLocalHours(date);
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    let strTime = convertHoursOrMinutesToCorrectFormat(hours) + ":" + convertHoursOrMinutesToCorrectFormat(date.getMinutes()) + " " + ampm;
    return strTime;
}

export function convertHoursToLocalHours(date: Date) {
    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    return hours - offset;
}

export function convertLocalHoursToUTC(date: Date) {
    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();
    return hours + offset;
}

export function convertHoursOrMinutesToCorrectFormat(number: number) {
    return number < 10 ? "0" + number : number;
}

export const setupi18n = (defaultLanguage: string, cacheKey: string) => {
    const settings = getSettings();

    i18n.use(Backend)
        .use(initReactI18next)
        .init({
            backend: {
                loadPath: `${settings.apiBaseUrl}/translations/{{lng}}?cache-bust=${cacheKey}`,
            },
            debug: false,
            lng: defaultLanguage,
            returnEmptyString: false,
            fallbackLng: "en",
            interpolation: { escapeValue: false },
        });
};

export const TranslationContext = createContext<{
    translateString: TranslationStringType;
    translateNumber: (num: number) => string;
    translateDate: (date: Date, options?: Intl.DateTimeFormatOptions) => string;
    translateDateWithTime: (date: Date) => string;
    translateCountry: (countryCode: string) => string;
    translateCountriesObject: () => {};
    translateLanguage: (countryCode: string) => string;
    changeLanguage: (languageCode: string) => void;
    getBrowserLanguage: () => string;
    getChosenLanguage: () => string;
    getChosenDateFormat: () => string;
    translateLegalEntityRiskAssessment: (risk: string) => string;
    translateDocumentType: (risk: string) => string;
    translateCustomListType: (type: string) => string;
    translateAnnualWheelCategoryType: (type: string) => string;
    translateNIS2Category: (type: string) => string;
    translateISO27001CategoryTypes: (type: string) => string;
    translatePermissionTypes: (permission: string) => string;
    translatepermissionTypeToolTips: (permission: string) => string;
    translatePermissionLevel: (level: string) => string;
    translatePermissionListTypes: (listType: string) => string;
    translatePolicyTypes: (type: string) => string;
    translateStatus: (status: string) => string;
    translateHelpTextTypes: (type: string) => string;
    translateauditLogTypes: (type: string) => string;
    translatePropertyName: (name: string) => string;
    translateEntityType: (name: string) => string;
    translateDateWithFullMonthAndYear: (date: Date | undefined) => string;
    translateRiskAssessmentVersionAreas: (type: string) => string;
    translateGdprIncidentType: (name: string) => string;
    translateTime: (date: Date) => string;
    convertLocalHoursToUTC: (date: Date) => number;
    convertHoursToLocalHours: (date: Date) => number;
    translateShortMonth: (month: number) => string;
    getAllPropertyNames: () => Array<{ key: string; value: string }>;
    getDateTimePickerLanguage: () => "da" | "en";
    translateLegalEntityAuditStatus: (status: string) => string;
    translateLegalEntityAssetRole: (role: string) => string;
    translateDateToFullMonth: (date: Date | undefined) => string;
    translateDataSubjectsEnum: (dataSubject: string) => string;
    translateProcessorsEnum: (dataSubject: string) => string;
    translatePlanType: (planType: PlanType) => string;
    translateOperationCriticalityLevelEnum: (level: number) => string;
}>({
    translateString: () => "",
    translateNumber: () => "",
    translateDate: () => "",
    translateDateWithTime: () => "",
    translateLanguage: () => "",
    translateCountry: () => "",
    translateCountriesObject: () => Object,
    changeLanguage: () => {},
    getBrowserLanguage: () => "",
    getChosenLanguage: () => "",
    getChosenDateFormat: () => "",
    translateLegalEntityRiskAssessment: () => "",
    translateDocumentType: () => "",
    translateCustomListType: () => "",
    translateAnnualWheelCategoryType: () => "",
    translatePolicyTypes: () => "",
    translatePermissionTypes: () => "",
    translatepermissionTypeToolTips: () => "",
    translatePermissionLevel: () => "",
    translatePermissionListTypes: () => "",
    translateHelpTextTypes: () => "",
    translateStatus: () => "",
    translateauditLogTypes: () => "",
    translatePropertyName: () => "",
    translateEntityType: () => "",
    translateNIS2Category: () => "",
    translateISO27001CategoryTypes: () => "",
    translateDateWithFullMonthAndYear: () => "",
    translateRiskAssessmentVersionAreas: () => "",
    translateGdprIncidentType: () => "",
    translateTime: () => "",
    convertLocalHoursToUTC: () => 0,
    convertHoursToLocalHours: () => 0,
    translateShortMonth: () => "",
    getAllPropertyNames: () => [],
    getDateTimePickerLanguage: () => "en" || "da",
    translateLegalEntityAuditStatus: () => "",
    translateLegalEntityAssetRole: () => "",
    translateDateToFullMonth: () => "",
    translateDataSubjectsEnum: () => "",
    translateProcessorsEnum: () => "",
    translatePlanType: () => "",
    translateOperationCriticalityLevelEnum: () => "",
});

export const TranslationProvider: React.FunctionComponent<{ onLanguageChanged?: (languageCode: string) => void; children: ReactNode }> = ({
    children,
    onLanguageChanged,
}) => {
    const { t } = useTranslation("");
    const queryClient = useQueryClient();

    const getBrowserLanguage = () => navigator.language ?? "en";
    const getChosenLanguage = () => i18n.language;
    const getChoseDateFormat = () => i18n.language;
    const translateNumber = useCallback((num: number) => num.toLocaleString(i18n.language), []);

    const getDateTimePickerLanguage = () => (i18n.language === "da" || i18n.language === "da-DK" ? "da" : "en");

    const translateDate = useCallback((date: Date) => {
        if (!date) {
            return "";
        }

        const localDate = new Date(date);
        let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(localDate);
        let mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(localDate);
        let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(localDate);
        if (getChoseDateFormat() === "da") {
            return `${da}-${mo}-${ye}`;
        }
        return `${ye}-${mo}-${da}`;
    }, []);

    const translateDateWithTime = useCallback((date: Date) => {
        if (!date) {
            return "";
        }

        const localDate = new Date(date);
        let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(localDate);
        let mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(localDate);
        let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(localDate);
        let h = convertHoursOrMinutesToCorrectFormat(convertHoursToLocalHours(localDate));
        let m = convertHoursOrMinutesToCorrectFormat(localDate.getMinutes());

        if (getChoseDateFormat() === "da") {
            return `${da}-${mo}-${ye} ${h}:${m}`;
        }
        return `${ye}-${mo}-${da} ${formatAMPM(localDate)}`;
    }, []);

    const translateDateWithFullMonthAndYear = useCallback((date: Date | undefined) => {
        if (!date) {
            return "";
        }

        const localDate = new Date(date);
        const formatter = new Intl.DateTimeFormat(getChoseDateFormat(), { year: "numeric", month: "long" });
        const formattedDate = formatter.format(localDate);
        const capitalizedMonth = formattedDate.replace(/^\w/, (c) => c.toUpperCase());

        return capitalizedMonth;
    }, []);

    const translateDateToFullMonth = useCallback((date: Date | undefined) => {
        if (!date) {
            return "";
        }

        const localDate = new Date(date);
        const formatter = new Intl.DateTimeFormat(getChoseDateFormat(), { month: "long" });
        const formattedDate = formatter.format(localDate);
        const capitalizedMonth = formattedDate.replace(/^\w/, (c) => c.toUpperCase());

        return capitalizedMonth;
    }, []);

    const translateTime = useCallback((date: Date) => {
        if (!date) {
            return "";
        }

        const localDate = new Date(date);
        let h = convertHoursOrMinutesToCorrectFormat(convertHoursToLocalHours(localDate));
        let m = convertHoursOrMinutesToCorrectFormat(localDate.getMinutes());

        if (getChoseDateFormat() === "da") {
            return `${h}:${m}`;
        }

        return `${formatAMPM(localDate)}`;
    }, []);

    const changeLanguage = (language: string) => {
        const isPdf = new URLSearchParams(window.location.search).get("isPdf");
        let current = getChosenLanguage();

        if (current !== language && !isPdf) {
            localStorage.setItem("language", language);
            i18n.changeLanguage(language);

            setJwtToken("");
            queryClient.clear();
        }
    };

    const translateLanguage = (countryCode: string): string => {
        const json: any = t("languages", { returnObjects: true });
        return json[countryCode.toUpperCase()];
    };

    const translateLegalEntityRiskAssessment = (risk: string) => {
        const json: any = t("legalEntityRiskAssessments", { returnObjects: true });
        return json[risk];
    };

    const translateCustomListType = (type: string) => {
        const json: any = t("customListTypes", { returnObjects: true });
        return json[type];
    };

    const translateDocumentType = (documentType: string) => {
        const json: any = t("documentTypes", { returnObjects: true });

        return json[documentType];
    };

    const translateAnnualWheelCategoryType = (categoryType: string) => {
        const json: any = t("standardAnnualWheelActivityTypes", { returnObjects: true });
        return json[categoryType];
    };

    const translateISO27001CategoryTypes = (categoryType: string) => {
        const json: any = t("iso27001CategoryTypes", { returnObjects: true });
        return json[categoryType];
    };

    const translateNIS2Category = (categoryType: string) => {
        const json: any = t("standardNIS2ActivityTemplateCategories", { returnObjects: true });
        return json[categoryType];
    };

    const translatePermissionTypes = (permission: string) => {
        const json: any = t("permissionTypes", { returnObjects: true });
        return json[permission];
    };

    const translatePolicyTypes = (type: string) => {
        const json: any = t("policyTypes", { returnObjects: true });
        return json[type];
    };

    const translateHelpTextTypes = (type: string) => {
        const json: any = t("helpTextTypes", { returnObjects: true });
        return json[type];
    };

    const translateRiskAssessmentVersionAreas = (type: string) => {
        const json: any = t("riskAssessmentVersionArea", { returnObjects: true });
        return json[type];
    };

    const translatepermissionTypeToolTips = (permission: string) => {
        const json: any = t("permissionTypeToolTip", { returnObjects: true });
        return json[permission];
    };

    const countries: any = t("countries", { returnObjects: true });
    const translateCountry = (countryCode: string): string => {
        return countries[countryCode];
    };
    const translateAllCountries = (): {} => {
        return countries;
    };

    const translatePermissionLevel = (level: string) => {
        const json: any = t("permissionLevels", { returnObjects: true });
        return json[level];
    };

    const translatePermissionListTypes = (level: string) => {
        const json: any = t("permissionListTypes", { returnObjects: true });
        return json[level];
    };

    const translateStatus = (status: string) => {
        const json: any = t("processingActivityStatus", { returnObjects: true });
        return json[status];
    };

    const translateauditLogTypes = (type: string) => {
        const json: any = t("auditLogTypes", { returnObjects: true });
        return json[type];
    };

    const translatePropertyName = (name: string) => {
        const json: any = t("propertyNames", { returnObjects: true });
        var key = json[name];
        if (isNullOrWhitespace(key)) return name;
        return key;
    };

    const translateEntityType = (name: string) => {
        const json: any = t("entityTypes", { returnObjects: true });
        var key = json[name];
        if (isNullOrWhitespace(key)) return name;
        return key;
    };

    const translateGdprIncidentType = (name: string) => {
        const json: any = t("gdprIncidentTypes", { returnObjects: true });
        var key = json[name];
        if (isNullOrWhitespace(key)) return name;
        return key;
    };

    const translateShortMonth = (month: number) => {
        const json: any = t("shortMonth", { returnObjects: true });
        var key = json[month];
        if (isNullOrWhitespace(key)) {
            return "Invalid month";
        }
        return key;
    };

    const getAllPropertyNames = () => {
        const namesObject: any = t("propertyNames", { returnObjects: true });
        const result: Array<{ key: string; value: string }> = [];
        for (const key in namesObject) {
            result.push({ key: key, value: namesObject[key] });
        }
        return result;
    };

    const translateLegalEntityAuditStatus = (status: string) => {
        const json: any = t("legalEntityAuditStatuses", { returnObjects: true });
        var key = json[status];
        if (isNullOrWhitespace(key)) return status;
        return key;
    };

    const translateLegalEntityAssetRole = (status: string) => {
        const json: any = t("legalEntityAssetRoles", { returnObjects: true });
        var key = json[status];
        if (isNullOrWhitespace(key)) return status;
        return key;
    };

    const translateDataSubjectsEnum = (status: string) => {
        const json: any = t("processingActivityDataSubjectsEnum", { returnObjects: true });
        var key = json[status];
        if (isNullOrWhitespace(key)) return status;
        return key;
    };

    const translateProcessorsEnum = (status: string) => {
        const json: any = t("processingActivityProcessorsEnum", { returnObjects: true });
        var key = json[status];
        if (isNullOrWhitespace(key)) return status;
        return key;
    };

    const translatePlanType = (planType: PlanType) => {
        switch (planType) {
            case PlanType.DataProtection:
                return "Data Protection";
            case PlanType.InformationSecurity:
                return "Information & Cyber Security";
            case PlanType.VendorManagement:
                return "Vendor Management";
            case PlanType.SingleSignOn:
                return "Single Sign-On";
            case PlanType.UserManagementPremium:
                return "Advanced User Management";
            case PlanType.CustomLists:
                return "Custom lists";
            case PlanType.Declarations:
                return "Declarations";
            case PlanType.OrganizationalManagement:
                return "Organisational Management";
            default:
                return "";
        }
    };

    const translateOperationCriticalityLevelEnum = (level: OperationCriticalityLevelEnum) => {
        switch (level) {
            case OperationCriticalityLevelEnum.VeryLow:
                return t("veryLow");
            case OperationCriticalityLevelEnum.Low:
                return t("low");
            case OperationCriticalityLevelEnum.Moderate:
                return t("moderate");
            case OperationCriticalityLevelEnum.High:
                return t("high");
            case OperationCriticalityLevelEnum.VeryHigh:
                return t("veryHigh");
            default:
                return "";
        }
    };

    return (
        <TranslationContext.Provider
            value={{
                translateString: t as TranslationStringType,
                translateNumber,
                translateDate,
                translateDateWithTime,
                translateCountry,
                translateCountriesObject: translateAllCountries,
                translateLanguage,
                changeLanguage,
                translateLegalEntityRiskAssessment,
                translateDocumentType,
                getBrowserLanguage: getBrowserLanguage,
                getChosenLanguage: getChosenLanguage,
                getChosenDateFormat: getChoseDateFormat,
                translateCustomListType,
                translateAnnualWheelCategoryType,
                translatePermissionTypes,
                translatepermissionTypeToolTips,
                translatePermissionLevel,
                translatePermissionListTypes,
                translatePolicyTypes,
                translateStatus,
                translateHelpTextTypes,
                translateauditLogTypes,
                translatePropertyName,
                translateEntityType,
                translateNIS2Category,
                translateDateWithFullMonthAndYear,
                translateRiskAssessmentVersionAreas,
                translateGdprIncidentType,
                translateTime,
                convertLocalHoursToUTC,
                convertHoursToLocalHours,
                translateShortMonth,
                getAllPropertyNames,
                getDateTimePickerLanguage: getDateTimePickerLanguage,
                translateLegalEntityAuditStatus,
                translateISO27001CategoryTypes,
                translateLegalEntityAssetRole,
                translateDateToFullMonth,
                translateDataSubjectsEnum,
                translateProcessorsEnum,
                translateOperationCriticalityLevelEnum,
                translatePlanType,
            }}
        >
            {children}
        </TranslationContext.Provider>
    );
};
