import { useTheme } from "@mui/material";
import { createSxStyles } from "../common/createSxStyles";

export const useCustomListsStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        paperContainer: {
            flex: "1",
            display: "flex",
            flexDirection: "column",
        },
        container: {
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
        },
        bold: {
            fontWeight: theme.typography.fontWeightBold,
        },
    });
};
