import { Dispatch, SetStateAction, useState } from "react";
import { isNullOrWhitespace } from "../stringOperations";

export function useStateSessionStorage<T>(key: string, defaultValue: T): [T, Dispatch<SetStateAction<T>>] {
    let item = sessionStorage.getItem(key);
    if (item) {
        defaultValue = JSON.parse(item);
    }

    let [state, setState] = useState<T>(defaultValue);
    return [
        state,
        (value) => {
            if (value === undefined || (typeof value === "string" && isNullOrWhitespace(value.toString()))) {
                sessionStorage.removeItem(key);
            } else {
                sessionStorage.setItem(key, JSON.stringify(value));
            }
            setState(value);
        },
    ];
}
