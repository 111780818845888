import { DotLegalCompletedPercentage, DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import WhiteDashboardBox from "../../WhiteDashboardBox";
import { useTranslation } from "../../../localization/useTranslation";

export interface IRiskWidgetAssessmentProps {
    percentage: number | undefined;
    type: "GDPR" | "NIS2";
    isLoading: boolean;
    marginTop: number;
}

export default function RiskWidgetAssessment(props: IRiskWidgetAssessmentProps) {
    const { translateString } = useTranslation();

    return (
        <WhiteDashboardBox marginTop={props.marginTop}>
            <DotLegalHeader marginBottom={1} headerStyle={"small"}>
                {translateString("riskAssessmentHeader")}: {props.type}
            </DotLegalHeader>
            <DotLegalCompletedPercentage isLoading={props.isLoading} oneLiner completed={props.percentage ?? 0} />
        </WhiteDashboardBox>
    );
}
