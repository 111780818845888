import { EUToUSDataPrivacyFrameworkId } from "../legalBasis/TransferBasis.Helper";
import { LevelOfProtectionType } from "../legalEntity/addLegalEntityDialog/AddLegalEntityDialog.types";
import { dataContainedInThirdCountry } from "../legalEntity/isInsecureThirdCountry";
import { SelectableLegalUnitsItem } from "../processingActivity/ProcessingActivity.types";

export function isTransferImpactAssessmentMissingForDataProcessorSharing(
    sharingRecipient: SelectableLegalUnitsItem | undefined,
    hasTransferImpactAssessment: boolean,
    transferImpactAssessmentId: string | undefined | null,
    hasTransferBasisAccess: boolean,
    transferBasisId: string | undefined | null,
    thirdCountries: Array<string>,
    storageLocations: Array<string>,
    containsNotAccessibleTia: boolean
) {
    return (
        !containsNotAccessibleTia &&
        hasTransferBasisAccess &&
        dataContainedInThirdCountry(thirdCountries, storageLocations, sharingRecipient) &&
        sharingRecipient &&
        sharingRecipient.isTIAUnnecessary === false &&
        !hasTransferImpactAssessment &&
        !transferImpactAssessmentId &&
        transferBasisId !== EUToUSDataPrivacyFrameworkId
    );
}

export function isTransferImpactAssessmentMissing(
    sharingRecipient: SelectableLegalUnitsItem | undefined,
    hasTransferImpactAssessment: boolean,
    transferImpactAssessmentId: string | undefined | null,
    hasTransferBasisAccess: boolean,
    transferBasisId: string | undefined | null,
    containsNotAccessibleTia: boolean
) {
    return (
        !containsNotAccessibleTia &&
        hasTransferBasisAccess &&
        sharingRecipient &&
        (sharingRecipient.levelOfProtection === LevelOfProtectionType.ThirdCountry ||
            sharingRecipient.levelOfProtection === LevelOfProtectionType.DataPrivacyFramework) &&
        sharingRecipient.isTIAUnnecessary === false &&
        !hasTransferImpactAssessment &&
        !transferImpactAssessmentId &&
        transferBasisId !== EUToUSDataPrivacyFrameworkId
    );
}

export function isTransferBasisMissingForDataProcessorSharing(
    sharingRecipient: SelectableLegalUnitsItem | undefined,
    transferBasisId: string | undefined | null,
    hasTransferBasisAccess: boolean,
    thirdCountries: Array<string>,
    storageLocations: Array<string>
) {
    return (
        hasTransferBasisAccess &&
        sharingRecipient &&
        dataContainedInThirdCountry(thirdCountries, storageLocations, sharingRecipient) &&
        sharingRecipient.isTransferBasisUnnecessary === false &&
        !transferBasisId
    );
}

export function isTransferBasisMissing(
    sharingRecipient: SelectableLegalUnitsItem | undefined,
    transferBasisId: string | undefined | null,
    hasTransferBasisAccess: boolean
) {
    return (
        hasTransferBasisAccess &&
        sharingRecipient &&
        (sharingRecipient.levelOfProtection === LevelOfProtectionType.ThirdCountry ||
            sharingRecipient.levelOfProtection === LevelOfProtectionType.DataPrivacyFramework) &&
        sharingRecipient.isTransferBasisUnnecessary === false &&
        !transferBasisId
    );
}
