import React from "react";
import DotLegalDeleteDialogWrapper from "../../common/components/dotLegalDeleteDialog/DotLegalDeleteDialogWrapper";
import { useProcessingActivityDeleteDialogHooks } from "./ProcessingActivityDeleteDialog.hooks";

export interface IProcessingActivityDeleteDialog {
    id: string;
    name: string;
    onCloseDialog: () => void;
    onSuccessDelete: () => void;
}

function ProcessingActivityDeleteDialog(props: IProcessingActivityDeleteDialog) {
    const { removeSystemClick, inProgress } = useProcessingActivityDeleteDialogHooks(props);

    return (
        <DotLegalDeleteDialogWrapper
            open
            itemForDeletion={props.name}
            onDialogClose={props.onCloseDialog}
            onOkClick={removeSystemClick}
            inProgress={inProgress}
        />
    );
}

export default ProcessingActivityDeleteDialog;
