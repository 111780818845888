import React from "react";
import DotLegalDeleteDialogWrapper from "../../../common/components/dotLegalDeleteDialog/DotLegalDeleteDialogWrapper";
import { CertificationViewModel } from "../certifications/Certifications.types";
import { useDeleteCertificationDialogHooks } from "./DeleteCertificationDialog.hooks";

export interface IDeleteCertificationDialog {
    onCloseDialog: () => void;
    onDeleted: () => void;
    selectedCertification: CertificationViewModel;
}

export default function DeleteCertificationDialog(props: IDeleteCertificationDialog) {
    const { removeCertification, inProgess } = useDeleteCertificationDialogHooks(props);

    return (
        <DotLegalDeleteDialogWrapper
            open
            itemForDeletion={props.selectedCertification.name}
            onDialogClose={props.onCloseDialog}
            onOkClick={removeCertification}
            inProgress={inProgess}
        />
    );
}
