import React from "react";
import { Box, useTheme } from "@mui/material";
import { scrollbarWidth } from "../main/NavMenu/NavMenu.styles";
import { createSxStyles } from "./createSxStyles";

export interface IPrettyScrollbarProps {
    className?: string;
    maxHeight?: number;
    width?: number;
    children: any;
}

const useStyles = (props: IPrettyScrollbarProps) => {
    const theme = useTheme();
    const totalWidth = props.width ? props.width : scrollbarWidth;
    const leftRightSpacing = (totalWidth - 4) / 2;
    return createSxStyles({
        root: {
            overflowY: "auto",
            "&::-webkit-scrollbar": {
                width: totalWidth + "px",
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: theme.palette.grey["400"],
                borderRadius: leftRightSpacing * 2 + "px",
                border: leftRightSpacing + "px solid transparent",
                backgroundClip: "padding-box",
                "&:hover": {
                    borderWidth: leftRightSpacing - 1,
                },
            },
            scrollbarWidth: "thin",
            maxHeight: props.maxHeight ? props.maxHeight + "px" : "none",
        },
    });
};

function PrettyScrollbar(props: IPrettyScrollbarProps) {
    const styles = useStyles(props);
    return (
        <Box sx={[styles.root]} className={props.className}>
            {props.children}
        </Box>
    );
}

export default PrettyScrollbar;
