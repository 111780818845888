import { useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";

export const useLegalEntitiesStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        paperContainer: {
            flex: "1",
            display: "flex",
            flexDirection: "column",
        },
        bold: {
            fontWeight: theme.typography.fontWeightBold,
        },
        container: {
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
        },
        switchBoxContainer: {
            marginBottom: theme.spacing(2.5),
            width: 180 + "px",
        },
        filter: {
            "& .MuiFormLabel-root": {
                fontSize: theme.typography.pxToRem(14),
            },
            "& .MuiInputBase-input": {
                fontSize: theme.typography.pxToRem(14),
            },
            "& .MuiChip-root": {
                color: theme.palette.primary.dark,
                backgroundColor: theme.palette.primary.light,
                borderColor: theme.palette.primary.main,
                border: "1px solid",
                fontSize: theme.typography.pxToRem(14),
            },
        },
        tableRow: {
            "& svg": {
                height: 14 + "px",
            },
            "&: hover": {
                "& .MuiTableCell-root": {
                    borderRadius: 0,
                },
            },
        },
        greenArrowContainer: {
            display: "flex",
            justifyContent: "flex-end",
            marginRight: 20 + "px",
        },
        chip: {
            margin: theme.spacing(0.5),
            display: "inline-block",
        },
    });
};
