import React from "react";
import DotLegalNoteDialog from "../../../common/components/dotLegalNoteDialog/DotLegalNoteDialog";
import { useSystemOverviewNote } from "./SystemOverviewNoteDialog.hook";

export interface SystemOverviewNoteDialogProps {
    systemId: string;
    onCloseDialog: () => void;
    onSuccess: () => void;
    disabled: boolean;
}

function SystemOverviewNoteDialog(props: SystemOverviewNoteDialogProps) {
    const { isLoading, data, updateNote, isSaving } = useSystemOverviewNote(props);

    return (
        <DotLegalNoteDialog
            isLoading={isLoading}
            isSaving={isSaving}
            showDialog
            noteText={data?.note ?? ""}
            onDialogClose={props.onCloseDialog}
            onOkClick={(note) => updateNote(note)}
            disabled={props.disabled}
        ></DotLegalNoteDialog>
    );
}

export default SystemOverviewNoteDialog;
