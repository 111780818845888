import { IGdprIncidentDocumentDeleteDialogProps } from "./GdprIncidentDocumentDeleteDialog";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useMutation } from "react-query";
import { createElement } from "react";
import { Trans } from "react-i18next";
import { deleteHttp } from "../../../../common/api/apiShared";

export function useGdprIncidentDocumentDeleteDialog(props: IGdprIncidentDocumentDeleteDialogProps) {
    const snackbar = useDotLegalSnackbar();

    const deleteMutation = useMutation(deleteDocument);
    const deleteDocumentClick = (documentId: string) => {
        deleteMutation.mutateAsync(documentId!, {
            onSuccess: () => {
                props.onCloseDialog();
                props.onDeletedDocument();
                snackbar.show(createElement(Trans, { i18nKey: "documentDeleted", values: { document: props.selectedDocument!.name } }));
            },
        });
    };

    function deleteDocument(documentId: string) {
        return deleteHttp<string>(`/gdprIncidentLogEntry/${props.incidentLogId}/document/${documentId}`);
    }

    return {
        deleteDocumentClick,
        inProgress: deleteMutation.isLoading,
    };
}
