import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { get } from "../common/api/apiShared";
import { useTranslation } from "../localization/useTranslation";
import { useUrlProvider } from "../useUrlProvider";
import { RolesMasterDataSaveModel } from "./addEditRolesMasterData/AddEditRolesMasterData.types";
import { RoleMasterDataViewModel } from "./Roles.types";
import { DotLegalBreadCrumbModel } from "@dotlegal/dotlegal-ui-components";

export function useRolesHooks() {
    const { translateString } = useTranslation();
    const urlProvider = useUrlProvider();
    const queryClient = useQueryClient();
    const { id } = useParams<{ id: string }>();
    const { roleid } = useParams<{ roleid: string }>();
    const [showRolesDialog, setShowRolesDialog] = useState(false);
    const [showDeleteRoleDialog, setShowDeleteRoleDialog] = useState({ showDialog: false, name: "", id: "" });
    const [selectedRoleViewModel, setSelectedRoleViewModel] = useState<RolesMasterDataSaveModel | undefined>(undefined);

    const url = "/roles";
    let { isLoading, data } = useQuery(url, () => get<Array<RoleMasterDataViewModel>>(url));

    const hasRoles = isLoading || data!.length > 0;
    const save = () => {
        queryClient.refetchQueries(url);
    };

    const createNewUserRolesProps: RolesMasterDataSaveModel = {
        name: "",
        description: "",
        canOnlyAccessDataWhereResponsible: false,
        canBeChosenAsResponsible: false,
    };

    const getBreadCrumbs = () => {
        let breadCrumbs: Array<DotLegalBreadCrumbModel> = [];

        breadCrumbs.push({ name: translateString("settings"), link: urlProvider.getSettingsUrl() });
        breadCrumbs.push({ name: translateString("roles") });

        return breadCrumbs;
    };

    return {
        isLoading,
        data,
        save,
        selectedRoleViewModel,
        setSelectedRoleViewModel,
        showRolesDialog,
        setShowRolesDialog,
        createNewUserRolesProps,
        hasRoles,
        id,
        roleid,
        getBreadCrumbs,
        showDeleteRoleDialog,
        setShowDeleteRoleDialog,
    };
}
