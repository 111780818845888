import { createElement } from "react";
import { Trans } from "react-i18next";
import { useMutation } from "react-query";
import { deleteHttp } from "../../../common/api/apiShared";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { IDeleteDataCategoryDialog } from "./DeleteDataCategoryDialog";

export function useDeleteDataCategoryDialogHooks(props: IDeleteDataCategoryDialog) {
    const snackbar = useDotLegalSnackbar();

    const removeMutation = useMutation(remove);
    const removePurpose = () => {
        removeMutation.mutateAsync(undefined, {
            onSuccess: (response: any) => {
                props.onCloseDialog();

                if (response.success()) {
                    props.onSaveCorrect();
                    snackbar.show(createElement(Trans, { i18nKey: "dataCategoryDeleted", values: { name: props.selectedDataCatagory.name } }));
                } else {
                    snackbar.show(createElement(Trans, { i18nKey: "dataCategoryNotDeleted", values: { name: props.selectedDataCatagory.name } }), "warning");
                }
            },
        });
    };

    async function remove() {
        return await deleteHttp<{}>(`/DataCategories/${props.selectedDataCatagory.id}`);
    }

    return {
        removePurpose,
        inProgess: removeMutation.isLoading,
    };
}
