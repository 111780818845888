export function useSetPdfPageSize(page: "landscape" | "portrait") {
    const elementId = "pdfPrint";
    const css = `@page { size: A4 ${page}; }`;
    const head = document.head || document.getElementsByTagName("head")[0];
    const style = document.createElement("style");

    style.media = "print";
    style.id = elementId;

    const elementExists = document.getElementById(elementId);
    if (elementExists) {
        elementExists.remove();
    }
    style.appendChild(document.createTextNode(css));

    head.appendChild(style);
}
