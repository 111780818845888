import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { Box, Grid } from "@mui/material";
import DotLegalFullScreenSpinner from "../../../common/components/dotLegalFullScreenSpinner/DotLegalFullScreenSpinner";
import { useTranslation } from "../../../localization/useTranslation";
import { DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { SingleValidator } from "../../../common/hooks/validation/SingleValidator";
import { CustomQuestionType } from "../RiskAssessmentCustomQuestions.types";
import { isEmptyStringOrTruthy } from "../../../common/stringOperations";

export interface CreateEditCustomQuestionProps {
    onCloseDialog?: () => void;
    onCompleteDialog?: () => void;
    isNew?: boolean;
    questionId?: string;
    data?: CustomQuestionType;
    setData?: React.Dispatch<React.SetStateAction<CustomQuestionType | undefined>>;
    validator?: SingleValidator<CustomQuestionType>;
}

const CreateEditCustomQuestion: FunctionComponent<CreateEditCustomQuestionProps> = ({ data, setData, validator, isNew }) => {
    const { translateString, getChosenLanguage } = useTranslation();
    const chosenLanguage = useMemo(() => getChosenLanguage(), [getChosenLanguage]);
    const [initialData, setInitialData] = useState<CustomQuestionType>();

    const showDaBox = useMemo(() => {
        if (isEmptyStringOrTruthy(initialData?.localizationDa)) return true;
        return !isEmptyStringOrTruthy(initialData?.localizationEn) && (chosenLanguage === "da" || chosenLanguage.toLocaleLowerCase() === "da-dk");
    }, [chosenLanguage, initialData?.localizationDa, initialData?.localizationEn]);
    const showEnBox = useMemo(() => {
        if (isEmptyStringOrTruthy(initialData?.localizationEn)) return true;
        return !isEmptyStringOrTruthy(initialData?.localizationDa) && (chosenLanguage === "en" || chosenLanguage.toLocaleLowerCase() === "en-us");
    }, [chosenLanguage, initialData?.localizationDa, initialData?.localizationEn]);

    useEffect(() => {
        if (isNew) {
            const newData = { isNew: true, riskAssessmentArea: data?.riskAssessmentArea, riskComplianceArea: data?.riskComplianceArea } as CustomQuestionType;
            setData?.(newData);
            setInitialData(newData);
        }
    }, [data?.riskAssessmentArea, data?.riskComplianceArea, isNew, setData]);

    useEffect(() => {
        if (!initialData && data) {
            setInitialData(data);
        }
    }, [data, initialData]);

    if (!data) return <DotLegalFullScreenSpinner />;
    validator?.check(data);
    return (
        <Box>
            <Grid container paddingX={1}>
                {showDaBox ? (
                    <Grid xs={12} item marginX={1}>
                        <DotLegalTextField
                            toolTipText={translateString("danishLocalizationTooltip")}
                            label={`${translateString("customQuestionDanishLocalization")}${showEnBox ? " (DA)" : ""}`}
                            value={data?.localizationDa ?? ""}
                            onChange={(value) => {
                                setData?.((prevState) => ({ ...prevState, localizationDa: value }) as CustomQuestionType);
                            }}
                            rows={3}
                            size={"medium"}
                            multiline
                            debounce
                            hasError={validator?.shouldShowErrors("localizationDa")}
                            errorText={validator?.getErrorsForDisplay("localizationDa")}
                        />
                    </Grid>
                ) : (
                    <></>
                )}
                {showEnBox ? (
                    <Grid xs={12} item marginX={1}>
                        <DotLegalTextField
                            toolTipText={translateString("englishLocalizationTooltip")}
                            label={`${translateString("customQuestionEnglishLocalization")}${showDaBox ? " (EN)" : ""}`}
                            value={data?.localizationEn ?? ""}
                            onChange={(value) => {
                                setData?.((prevState) => ({ ...prevState, localizationEn: value }) as CustomQuestionType);
                            }}
                            rows={3}
                            size={"medium"}
                            multiline
                            debounce
                            hasError={validator?.shouldShowErrors("localizationEn")}
                            errorText={validator?.getErrorsForDisplay("localizationEn")}
                        />
                    </Grid>
                ) : (
                    <></>
                )}
            </Grid>
        </Box>
    );
};

export default CreateEditCustomQuestion;
