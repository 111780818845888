import { useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";

export const useProcessingActivityStatisticsStyles = () => {
    const theme = useTheme();

    return createSxStyles({
        paperContainer: {
            textAlign: "center",
            boxShadow: "none !important",
            paddingTop: 4,
        },
        header: {
            color: theme.palette.primary.dark,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: theme.typography.pxToRem(12),
            textTransform: "uppercase",
        },
        body: {
            color: theme.palette.primary.main,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: theme.typography.pxToRem(36),
        },
        footer: {
            display: "flex",
            marginTop: 2,
            fontSize: theme.typography.pxToRem(12),
            color: theme.palette.primary.dark,
            justifyContent: "space-between",
            textAlign: "left",
        },
    });
};
