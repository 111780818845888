import { aS } from "vitest/dist/reporters-yx5ZTtEV.js";
import { SelectableItem } from "../../../processingActivity/ProcessingActivity.types";
import { ApprovalOption } from "../../AnnualWheel.types";
import { AnnualWheelActivityFramework } from "../AnnualWheelDialog.types";

export class AnnualWheelResponsibleFormModel {
    businessAreas: Array<string> = [];
    responsible: string | undefined = undefined;
    groupCompanies: Array<GroupCompanyResponsible> = [];
    approval: ApprovalOption = ApprovalOption.ApprovalNotRequired;
    assignees: Array<string>;

    constructor(
        businessAreas: Array<string> | undefined,
        responsible: string | undefined | null,
        groupCompanies: Array<GroupCompanyResponsible> | undefined,
        approval: ApprovalOption | undefined,
        assigness: Array<string> | undefined
    ) {
        this.businessAreas = businessAreas ?? [];
        this.responsible = responsible ?? undefined;
        this.groupCompanies = groupCompanies ?? [];
        this.approval = approval ?? ApprovalOption.ApprovalNotRequired;
        this.assignees = assigness ?? [];
    }

    IsModelInvalid = () => {
        return !this.isResponsibleValid();
    };

    allCompaniesHasUsers = (groupCompanies: Array<GroupCompanyResponsible>) => {
        return groupCompanies.filter((x) => x.userId).length === this.groupCompanies.length;
    };

    private isResponsibleValid = () => {
        if (this.groupCompanies.length === 0) {
            return this.HasResponsibleOrBusinessArea();
        }

        return this.HasResponsibleOrBusinessArea() || this.allCompaniesHasUsers(this.groupCompanies);
    };

    private HasResponsibleOrBusinessArea() {
        return this.responsible || this.businessAreas?.length > 0;
    }
}

export class GroupCompanyResponsible {
    userId: string | null;
    groupCompanyId: string;

    constructor(userId: string, groupCompanyId: string) {
        this.userId = userId;
        this.groupCompanyId = groupCompanyId;
    }
}

export interface SelectableAreaItem extends SelectableItem {
    framework?: AnnualWheelActivityFramework;
}
