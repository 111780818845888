import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "../../localization/useTranslation";
import { SelectableColoredItem } from "../../processingActivity/ProcessingActivity.types";
import { useUrlProvider } from "../../useUrlProvider";
import { QuestionType, ScenarioType } from "../RiskAssessment.types";
import {
    getQuestionCategoryLists,
    RiskAssessmentTemplateApiModel,
    RiskAssessmentTemplateBusinessAreaResetModel,
    RiskAssessmentTemplateBusinessAreaSaveModel,
    RiskAssessmentTemplateItemType,
    RiskAssessmentTemplateItemViewModel,
    RiskAssessmentTemplateSaveModel,
    RiskAssessmentTemplateViewModel,
} from "./RiskAssessmentTemplate.types";
import { useRiskAssessmentItemsProvider } from "../useRiskAssessmentItemsProvider";
import { useMutation, useQuery } from "react-query";
import { deleteHttp, get, post, put } from "../../common/api/apiShared";
import { useOptimisticUpdate } from "../../common/hooks/useOptimisticUpdate";
import { ajaxQueue } from "../../common/api/ajaxQueue";
import { TrackingEvent, useTrackAIEvent } from "../../processingActivity/hooks/useTracking";
import { CustomQuestionType } from "../riskAssessmentTemplateCustomQuestions/RiskAssessmentCustomQuestions.types";
import { RiskAssessmentArea, RiskComplianceArea } from "../riskAssessments/RiskAssessments.types";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";
import { DotLegalBreadCrumbModel } from "@dotlegal/dotlegal-ui-components";

export function useRiskAssessmentTemplateDataMapping(
    riskAssessmentArea: RiskAssessmentArea,
    riskComplianceArea: RiskComplianceArea,
    riskAssessmentVersionId: string,
    customQuestionsSettings?: {
        editCustomQuestionId?: string;
        customerCustomQuestions?: CustomQuestionType[];
        getCustomQuestionDisplayText?: (customQuestionId: string) => string;
    }
) {
    const isProcessingActivityRiskAssessment = riskAssessmentArea === RiskAssessmentArea.ProcessingActivities;
    let riskAssessmentItemsProvider = useRiskAssessmentItemsProvider();
    const { translateString } = useTranslation();
    const { getRiskAssessmentsUrl } = useUrlProvider();
    const trackEvent = useTrackAIEvent();
    const { dataProtectionFeatures, informationSecurityFeatures } = usePlanContext();

    const [selectedTab, setSelectedTab] = useState(0);
    const [expandedItem, setExpandedItem] = useState("");
    const [itemForRemoval, setItemForRemoval] = useState<RiskAssessmentTemplateItemViewModel | undefined>(undefined);

    const optimisticUpdate = useOptimisticUpdate();

    const businessAreasQuery = useQuery("selectableBusinessAreas", () => get<Array<SelectableColoredItem>>("/BusinessAreas/businessareas"));

    let riskAssessmentTemplateUrl = "/RiskAssessmenTemplate";
    if (riskAssessmentArea === RiskAssessmentArea.Systems) riskAssessmentTemplateUrl = riskAssessmentTemplateUrl + "/system";

    riskAssessmentTemplateUrl = riskAssessmentTemplateUrl + `?riskComplianceArea=${riskComplianceArea}&riskAssessmentVersionId=${riskAssessmentVersionId}`;

    const riskAssessmentTemplateQuery = useQuery(riskAssessmentTemplateUrl, () => get<RiskAssessmentTemplateApiModel>(riskAssessmentTemplateUrl));

    const saveTemplateItemMutation = useMutation(saveTemplateItem);
    const deleteTemplateItemMutation = useMutation(deleteTemplateItem);
    const addBusinessAreaMutation = useMutation(addBusinessArea);
    const removeBusinessAreaMutation = useMutation(removeBusinessArea);
    const resetBusinessAreaMutation = useMutation(resetBusinessArea);

    let data: RiskAssessmentTemplateViewModel;

    if (riskAssessmentTemplateQuery.data) {
        riskAssessmentVersionId = riskAssessmentTemplateQuery.data?.versionId;
    }

    let businessAreaIds = Array<string>();
    if (businessAreasQuery.data) {
        businessAreaIds = businessAreasQuery.data!.map((x) => x.id);
    }

    const mapQuestion = useCallback(
        (q: QuestionType, customQuestionId?: string): RiskAssessmentTemplateItemViewModel => {
            const isCustomQuestion = q === QuestionType.CustomQuestion && !!customQuestionId;
            let selectedQuestion = riskAssessmentTemplateQuery?.data?.questions.find((apiQuestion) => {
                if (isCustomQuestion) return apiQuestion.customQuestionId === customQuestionId;
                return apiQuestion.questionType === q;
            });
            const viewModel = new RiskAssessmentTemplateItemViewModel(
                isCustomQuestion ? RiskAssessmentTemplateItemType.ProbabilityCustomQuestion : RiskAssessmentTemplateItemType.Probability,
                translateString(QuestionType[q]),
                undefined,
                q
            );
            viewModel.selected = selectedQuestion !== undefined;
            viewModel.businessAreas = selectedQuestion?.businessAreas ?? [];
            viewModel.probabilityScore = selectedQuestion?.probabilityScore ?? null;
            viewModel.consequenceScore = selectedQuestion?.consequenceScore ?? null;
            viewModel.inUse = selectedQuestion?.inUse ?? false;
            viewModel.customQuestionId = customQuestionId;
            return viewModel;
        },
        [riskAssessmentTemplateQuery?.data?.questions, translateString]
    );

    const customQuestions: Array<RiskAssessmentTemplateItemViewModel> = useMemo(() => {
        return !!customQuestionsSettings?.customerCustomQuestions && customQuestionsSettings?.customerCustomQuestions.length > 0
            ? customQuestionsSettings?.customerCustomQuestions
                  .map((value) => {
                      let mappedQuestion = mapQuestion(QuestionType.CustomQuestion, value.id);
                      mappedQuestion.name = customQuestionsSettings?.getCustomQuestionDisplayText?.(value.id ?? "") ?? value.id ?? "";
                      return mappedQuestion;
                  })
                  .filter((x) => !!x)
            : [];
    }, [customQuestionsSettings, mapQuestion]);

    const questions: Array<RiskAssessmentTemplateItemViewModel> = riskAssessmentItemsProvider
        .getAllQuestions(riskAssessmentArea, riskComplianceArea)
        .map((q) => {
            let key = `${QuestionType[q]}Empty`;
            let mappedQuestion = mapQuestion(q);
            const translationExists = translateString(key) !== key;
            mappedQuestion.name = translationExists ? translateString(key) : translateString(QuestionType[q]);
            return mappedQuestion;
        });

    const scenarios: Array<RiskAssessmentTemplateItemViewModel> = riskAssessmentItemsProvider.getScenarios().map((s) => {
        let selectedScenario = riskAssessmentTemplateQuery?.data?.scenarios.find((apiScenario) => apiScenario.scenarioType === s);
        const viewModel = new RiskAssessmentTemplateItemViewModel(RiskAssessmentTemplateItemType.Scenario, translateString(ScenarioType[s]), s, undefined);
        viewModel.selected = selectedScenario !== undefined;
        viewModel.businessAreas = selectedScenario?.businessAreas ?? [];
        viewModel.inUse = selectedScenario?.inUse ?? false;
        return viewModel;
    });

    data = {
        questions: questions,
        customQuestions: customQuestions,
        scenarios: scenarios,
    };

    const onBusinessAreaToggle = (item: RiskAssessmentTemplateItemViewModel, businessAreaId?: string, resetAreas?: boolean) => {
        let newData = riskAssessmentTemplateQuery.data ? { ...riskAssessmentTemplateQuery.data } : undefined;
        const isCustomQuestion = !!item.customQuestionId;

        if (item.itemType === RiskAssessmentTemplateItemType.Scenario) {
            let scenarioApiModel = isCustomQuestion
                ? data.customQuestions.find((s) => s.customQuestionId === item.customQuestionId)
                : newData?.scenarios?.find((s) => s.scenarioType === item.itemId());
            if (!scenarioApiModel) return;
            scenarioApiModel.businessAreas = resetAreas
                ? resetBusinessAreas(item)
                : !!businessAreaId
                  ? toggleBusinessArea(scenarioApiModel.businessAreas, businessAreaId, item)
                  : scenarioApiModel.businessAreas;
        } else {
            let questionApiModel = isCustomQuestion
                ? data.customQuestions.find((s) => s.customQuestionId === item.customQuestionId)
                : newData?.questions?.find((s) => s.questionType === item.itemId());
            if (!questionApiModel) return;
            questionApiModel.businessAreas = resetAreas
                ? resetBusinessAreas(item)
                : !!businessAreaId
                  ? toggleBusinessArea(questionApiModel.businessAreas, businessAreaId, item)
                  : questionApiModel.businessAreas;
            if (isCustomQuestion) {
                const questionToUpdate = riskAssessmentTemplateQuery.data?.questions.find((x) => x.customQuestionId === questionApiModel?.customQuestionId);
                if (questionToUpdate) questionToUpdate.businessAreas = questionApiModel.businessAreas;
            }
        }

        if (newData && !isCustomQuestion) optimisticUpdate.setQueryData<RiskAssessmentTemplateApiModel>(riskAssessmentTemplateUrl, newData);
    };

    const onTemplateItemToggle = async (item: RiskAssessmentTemplateItemViewModel) => {
        if (item.inUse && itemForRemoval === undefined) {
            setItemForRemoval(item);
            return;
        }

        let newData = { ...riskAssessmentTemplateQuery.data! };
        if (item.itemType === RiskAssessmentTemplateItemType.Scenario) {
            const existingScenario = newData.scenarios.find((q) => q.scenarioType === item.scenarioType)!;
            let saveModel = {
                itemId: item.scenarioType!,
                riskAssessmentTemplateItemType: RiskAssessmentTemplateItemType.Scenario,
                score: null,
                customQuestionId: item.customQuestionId,
            } as RiskAssessmentTemplateSaveModel;
            if (existingScenario) {
                ajaxQueue().addRequest(deleteTemplateItemMutation.mutateAsync, saveModel);
                newData.scenarios = newData.scenarios.filter((q) => q.scenarioType !== item.scenarioType);

                trackEvent(TrackingEvent.ProcessingActvitiyRiskAssessmentScenarioDeleted, { scenarioType: item.scenarioType });
            } else {
                ajaxQueue().addRequest(saveTemplateItemMutation.mutateAsync, saveModel);
                newData.scenarios.push({
                    scenarioType: item.scenarioType!,
                    businessAreas: businessAreaIds,
                    inUse: false,
                });

                trackEvent(TrackingEvent.ProcessingActivityRiskAssessmentScenarioAdded, { scenarioType: item.scenarioType });
            }
        } else {
            const isCustomQuestion = !!item.customQuestionId;
            const existingQuestion = isCustomQuestion
                ? newData.questions.find((q) => q.customQuestionId === item.customQuestionId)
                : newData.questions.find((q) => q.questionType === item.questionType);
            let saveModel = {
                itemId: item.questionType!,
                riskAssessmentTemplateItemType: item.itemType,
                score: null,
                customQuestionId: item.customQuestionId,
            } as RiskAssessmentTemplateSaveModel;
            if (existingQuestion) {
                await ajaxQueue().addRequest(deleteTemplateItemMutation.mutateAsync, saveModel);
                newData.questions = isCustomQuestion
                    ? newData.questions.filter((q) => q.customQuestionId !== item.customQuestionId)
                    : newData.questions.filter((q) => q.questionType !== item.questionType);

                trackEvent(TrackingEvent.ProcessingActivtyRiskAssessmentQuestionDeleted, { scenarioType: item.questionType });
            } else {
                await ajaxQueue().addRequest(saveTemplateItemMutation.mutateAsync, saveModel);
                const q = data.customQuestions.find((x) => x.customQuestionId === item.customQuestionId);
                if (q) {
                    q.selected = true;
                    q.businessAreas = businessAreaIds;
                }
                newData.questions.push({
                    customQuestionId: item.customQuestionId,
                    questionType: item.questionType!,
                    businessAreas: businessAreaIds,
                    probabilityScore: null,
                    consequenceScore: null,
                    inUse: false,
                });

                trackEvent(TrackingEvent.ProcessingActivityRiskAssessmentQuestionAdded, { scenarioType: item.questionType });
            }
        }

        setItemForRemoval(undefined);
        await optimisticUpdate.setQueryData<RiskAssessmentTemplateApiModel>(riskAssessmentTemplateUrl, newData);
    };

    const getBreadCrumbs = () => {
        let breadCrumbs: Array<DotLegalBreadCrumbModel> = [];
        let name = `${translateString("riskTemplate")} - ${
            isProcessingActivityRiskAssessment ? translateString("processingActivities") : translateString("systems")
        }`;

        name = name + ` (${riskComplianceArea === RiskComplianceArea.GDPR ? translateString("gdpr") : translateString("nis2")})`;

        breadCrumbs.push({ name: translateString("risk"), link: getRiskAssessmentsUrl() });
        breadCrumbs.push({
            name: name,
        });
        return breadCrumbs;
    };

    const onExpandToggle = (itemId: string) => {
        itemId === expandedItem ? setExpandedItem("") : setExpandedItem(itemId);
    };

    const onScoreChange = async (item: RiskAssessmentTemplateItemViewModel, type: RiskAssessmentTemplateItemType, score: number | null) => {
        let newData = { ...riskAssessmentTemplateQuery.data! };
        const isCustomQuestion = !!item.customQuestionId;

        const question = newData.questions.find((s) => (isCustomQuestion ? s.customQuestionId === item.customQuestionId : s.questionType === item.itemId()))!;
        if (type === RiskAssessmentTemplateItemType.Probability) question.probabilityScore = score;
        if (type === RiskAssessmentTemplateItemType.Consequence) question.consequenceScore = score;

        await optimisticUpdate.setQueryData<RiskAssessmentTemplateApiModel>(riskAssessmentTemplateUrl, newData);

        if (isCustomQuestion) {
            const customQuestion = data.customQuestions.find((s) => s.customQuestionId === item.customQuestionId)!;
            if (type === RiskAssessmentTemplateItemType.Probability) customQuestion.probabilityScore = score;
            if (type === RiskAssessmentTemplateItemType.Consequence) customQuestion.consequenceScore = score;
        }

        let saveModel = {
            itemId: item.questionType!,
            riskAssessmentTemplateItemType: type,
            score: score,
            customQuestionId: item.customQuestionId,
        };
        ajaxQueue().addRequest(saveTemplateItemMutation.mutateAsync, saveModel);
    };

    function resetBusinessAreas(item: RiskAssessmentTemplateItemViewModel): string[] {
        const saveModel: RiskAssessmentTemplateBusinessAreaResetModel = {
            itemId: item.itemId(),
            customQuestionId: item.customQuestionId,
            riskAssessmentTemplateItemType: item.itemType,
        };
        ajaxQueue().addRequest(resetBusinessAreaMutation.mutateAsync, saveModel);
        return [];
    }

    function toggleBusinessArea(areas: Array<string>, areaId: string, item: RiskAssessmentTemplateItemViewModel) {
        let saveModel = {
            businessAreaId: areaId,
            riskAssessmentTemplateItemType: item.itemType,
            itemId: item.itemId(),
            customQuestionId: item.customQuestionId,
        } as RiskAssessmentTemplateBusinessAreaSaveModel;
        if (areas.some((b) => b === areaId)) {
            ajaxQueue().addRequest(removeBusinessAreaMutation.mutateAsync, saveModel);
            areas = areas.filter((b) => b !== areaId);
        } else {
            ajaxQueue().addRequest(addBusinessAreaMutation.mutateAsync, saveModel);
            areas.push(areaId);
        }
        return areas;
    }

    const onWarningDialogClose = () => {
        setItemForRemoval(undefined);
    };

    const isTemplateLimitReached = () => {
        if (!riskAssessmentTemplateQuery.data) {
            return false;
        }

        const numberOfScenarios = riskAssessmentTemplateQuery.data?.questions.filter((x) => !x.customQuestionId).length;

        if (riskComplianceArea === RiskComplianceArea.GDPR) {
            if (dataProtectionFeatures.maxNumberOfGDPRRiskScenarios) {
                return numberOfScenarios >= dataProtectionFeatures.maxNumberOfGDPRRiskScenarios;
            }
        } else if (informationSecurityFeatures.maxNumberOfNIS2RiskScenarios) {
            return numberOfScenarios >= informationSecurityFeatures.maxNumberOfNIS2RiskScenarios;
        }

        return false;
    };

    return {
        getBreadCrumbs,
        selectedTab,
        setSelectedTab,
        data,
        categories: getQuestionCategoryLists(),
        onTemplateItemToggle,
        onBusinessAreaToggle,
        expandedItem,
        onExpandToggle,
        isLoading: businessAreasQuery.isLoading,
        businessAreas: businessAreasQuery.data,
        onScoreChange,
        itemForRemoval,
        onWarningDialogClose,
        isRiskAssessmentTemplateItemLimitReached: isTemplateLimitReached(),
        isLocked: riskAssessmentTemplateQuery.data?.isLocked,
    };

    async function saveTemplateItem(saveModel: RiskAssessmentTemplateSaveModel) {
        return await put(`/RiskAssessmentemplate?riskAssessmentVersionId=${riskAssessmentVersionId}`, saveModel);
    }

    async function deleteTemplateItem(saveModel: RiskAssessmentTemplateSaveModel) {
        return await deleteHttp(
            `/RiskAssessmentemplate/${saveModel.riskAssessmentTemplateItemType}/${saveModel.itemId}${
                saveModel.customQuestionId ? "/" + saveModel.customQuestionId : ""
            }?riskAssessmentVersionId=${riskAssessmentVersionId}`
        );
    }

    async function addBusinessArea(saveModel: RiskAssessmentTemplateBusinessAreaSaveModel) {
        return await post(`/RiskAssessmentemplate/BusinessArea?riskAssessmentVersionId=${riskAssessmentVersionId}`, saveModel);
    }

    async function removeBusinessArea(saveModel: RiskAssessmentTemplateBusinessAreaSaveModel) {
        return await deleteHttp(
            `/RiskAssessmentemplate/${saveModel.riskAssessmentTemplateItemType}/${saveModel.itemId}/BusinessArea/${saveModel.businessAreaId}${
                saveModel.customQuestionId ? "/" + saveModel.customQuestionId : ""
            }?riskAssessmentVersionId=${riskAssessmentVersionId}`
        );
    }

    async function resetBusinessArea(saveModel: RiskAssessmentTemplateBusinessAreaResetModel) {
        return await deleteHttp(
            `/RiskAssessmentemplate/${saveModel.riskAssessmentTemplateItemType}/${saveModel.itemId}/BusinessArea/reset${
                saveModel.customQuestionId ? "/" + saveModel.customQuestionId : ""
            }?riskAssessmentVersionId=${riskAssessmentVersionId}`
        );
    }
}
