import { useMutation } from "react-query";
import { IClassificationTableProps } from "./ClassificationTable";
import { LegalEntityClassificationApiRow, LegalEntityClassificationViewRow } from "../LegalEntityClassificationTab.types";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useState, createElement } from "react";
import { Trans } from "react-i18next";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import { put } from "../../../common/api/apiShared";
import { downloadFile } from "../../../common/api/downloadFile";
import { EvaluationStatus } from "../../../common/components/questionnaireComponents/evaluateQuestionnaireDialog/EvaluateQuestionnaireDialog.types";
import { createPdfUrl } from "../../../common/pdfUrlHelper";
import { getQuestionnaireResponseScore } from "../../../common/questionnaireResponseScore";
import { getSettings } from "../../../common/settingsProvider";
import { setFirstLetterToLowerCase } from "../../../common/stringOperations";
import { useUrlProvider } from "../../../useUrlProvider";
import { useTranslation } from "../../../localization/useTranslation";
import { getLegalEntityPermissions } from "../../LegalEntity.hooks";

export default function useClassificationTableHooks(props: IClassificationTableProps) {
    const snackbar = useDotLegalSnackbar();
    const { language } = useUserContext();
    const { translateString } = useTranslation();
    const { getClassificationQuestionnaireResponseReport } = useUrlProvider();
    const { permissions } = useUserContext();

    const [showEvaluateDialog, setShowEvaluateDialog] = useState(false);
    const [selectedSystems, setSelectedSystems] = useState<Array<string>>([]);
    const [selectedLegalEntities, setSelectedLegalEntities] = useState<Array<string>>([]);
    const [selectedClassification, setSelectedClassification] = useState<LegalEntityClassificationApiRow | undefined>(undefined);
    const [showNoteDialog, setShowNoteDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showReopenDialog, setShowReopenDialog] = useState(false);
    const [isDownloadingPdf, setIsDownloadingPdf] = useState("");

    let classifications: Array<LegalEntityClassificationApiRow> = [];

    if (props.data) {
        classifications = getFilteredRows(props.data.rows);
        classifications = classifications.map((c) => {
            return {
                ...c,
                submittedBy: c.submittedBy === "deleted" ? translateString("deletedUser") : c.submittedBy,
                permissions: getLegalEntityPermissions(permissions, c.legalEntity.isGroupEntity),
            };
        });
    }

    const onClassificationDeleted = () => {
        props.refetch();
        setShowDeleteDialog(false);
    };

    function onClassificationEvaluated() {
        props.refetch();
        setSelectedClassification(undefined);
        setShowEvaluateDialog(false);
    }

    const saveNoteMutation = useMutation(submitSaveNote);

    function submitSaveNote(note: string) {
        return put(`/legalentityclassification/${selectedClassification!.id}/note`, {
            note: note,
        });
    }

    const saveNote = (note: string) => {
        saveNoteMutation.mutateAsync(note, {
            onSuccess: () => {
                snackbar.show(translateString("noteUpdated"));
                setShowNoteDialog(false);
                props.refetch();
            },
        });
    };

    function getReportPdfUrl(viewModel: LegalEntityClassificationApiRow, pdf: boolean, showRecommendations: boolean) {
        return getClassificationQuestionnaireResponseReport(viewModel.questionnaireResponseId, viewModel.legalEntity.name, pdf, showRecommendations);
    }

    const onDownloadReportAsPdf = async (viewModel: LegalEntityClassificationApiRow, showRecommendations: boolean) => {
        setIsDownloadingPdf(viewModel.id);
        let url = getReportPdfUrl(viewModel, true, showRecommendations);
        url = getSettings().mainSiteBaseUrl + url;
        url = createPdfUrl(url, language);
        const fileName = `${viewModel.questionnaireName}_${viewModel.legalEntity.name}.pdf`;
        await downloadFile(getSettings().apiBaseUrl + "/pdf/generate?noMargin=true&url=" + url, fileName);
        setIsDownloadingPdf("");
        snackbar.show(createElement(Trans, { i18nKey: "pdfDownloadComplete", values: { fileName: fileName } }));
    };

    function getScore(row: LegalEntityClassificationApiRow) {
        return getQuestionnaireResponseScore(row.submitted !== undefined, row.scoreEnabled, row.score, translateString("scoreNotEnabled"));
    }

    const classificationViewRows: Array<LegalEntityClassificationViewRow> = classifications.map((c) => {
        return {
            ...c,
            systemName: c.system?.name,
            legalEntityName: c.legalEntity.name,
            scoreName: getScore(c),
            evaluationName: c.evaluationStatus ? translateString(setFirstLetterToLowerCase(EvaluationStatus[c.evaluationStatus].toString())) : "",
        };
    });

    return {
        hasData: props.isLoading ? true : props.data!.rows.length > 0,
        selectedSystems,
        setSelectedSystems,
        classifications: classificationViewRows,
        selectedClassification,
        setSelectedClassification,
        showNoteDialog,
        setShowNoteDialog,
        saveNote,
        isSavingNote: saveNoteMutation.isLoading,
        showDeleteDialog,
        setShowDeleteDialog,
        onClassificationDeleted,
        getReportPdfUrl,
        isDownloadingPdf,
        onDownloadReportAsPdf,
        showEvaluateDialog,
        setShowEvaluateDialog,
        onClassificationEvaluated,
        showReopenDialog,
        setShowReopenDialog,
        selectedLegalEntities,
        setSelectedLegalEntities,
    };

    function getFilteredRows(rows: Array<LegalEntityClassificationApiRow>) {
        let tempData = [...rows];

        if (selectedSystems.length > 0) {
            tempData = tempData.filter((row) => row.system && selectedSystems.includes(row.system.id));
        }

        if (selectedLegalEntities.length > 0) {
            tempData = tempData.filter((row) => selectedLegalEntities.includes(row.legalEntity.legalEntityId));
        }

        return tempData;
    }
}
