/* eslint-disable no-console */
import App from "./App";
import React from "react";
import ReactDOM from "react-dom/client";

// eslint-disable-next-line no-extend-native
Date.prototype.toJSON = function () {
    // THE 2021-06-30: This is to avoid conversion to UTC time when dates are converted to JSON.
    // This makes sure dates are always transmitted as midnigt (yyyy-MM-dd 00:00:00) to the backend.
    const hoursDiff = this.getHours() - this.getTimezoneOffset() / 60;
    this.setHours(hoursDiff);
    return this.toISOString();
};

ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
