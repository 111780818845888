import { Alert, AlertTitle, Box, Typography } from "@mui/material";
import React from "react";
import { DotLegalDialog, DotLegalInformationTooltip, useIsOnSmallScreen } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { RiskAssessmentType, ScenarioViewModel } from "../RiskAssessment.types";
import RiskAssessmentAnswerCollapse from "../riskAssessmentAnswerCollapse/RiskAssessmentAnswerCollapse";
import { UseProcessingActivityRiskScenariosDialogHook } from "./ProcessingActivityRiskScenariosDialog.hooks";
import { UseProcessingActivityRiskScenariosDialogStyles } from "./ProcessingActivtyRiskScenarionDialog.styles";
import RiskDialogHeader from "../riskAssessmentDialog/rIskDialogHeader/RIskDIalogHeader";
import RiskBarometer from "../riskAssessmentDialog/riskBarometer/RiskBarometer";
import RiskBusinessAreas from "../riskAssessmentDialog/riskBusinessAreas/RiskBusinessAreas";
import { useNavigate, useParams } from "react-router-dom";
import RiskAnswerGroup from "../riskAssessmentDialog/riskAnswerGroup/RiskAnswerGroup";
import { useUrlProvider } from "../../useUrlProvider";

function ProcessingActivityRiskScenariosDialog() {
    const { riskAssessmentVersionId } = useParams<{
        riskAssessmentVersionId: string;
    }>();

    const isOnSmallScreen = useIsOnSmallScreen();
    const navigate = useNavigate();
    const styles = UseProcessingActivityRiskScenariosDialogStyles(isOnSmallScreen);
    const { translateString } = useTranslation();
    const { data, isLoading, isSaving, saveScenario, resetScenarioClick } = UseProcessingActivityRiskScenariosDialogHook(riskAssessmentVersionId);
    const { getProcessingActivityOverviewUrl } = useUrlProvider();
    function getheader(text: string) {
        return <Box sx={styles.questionText}>{text}</Box>;
    }

    return (
        <DotLegalDialog
            size="md"
            header={""}
            buttonOkText={""}
            hideOkButton
            open
            onDialogClose={() => {
                navigate(-1);
            }}
            onOkClick={() => {}}
            isLoading={isLoading}
        >
            <RiskDialogHeader
                label={data?.currentRiskAssessmentVersionName ?? ""}
                name={data?.processingActivityName ?? ""}
                href={getProcessingActivityOverviewUrl(data?.processingActivityId ?? "")}
            />

            {!isLoading && data!.businessAreas.length > 0 && <RiskBusinessAreas businessAreas={data!.businessAreas!} />}

            <RiskBarometer score={data?.score} averageScore={data?.averageScore} isLoading={isLoading} riskAssessmentType={RiskAssessmentType.Classification} />

            {data?.isLocked && (
                <Alert sx={(theme) => ({ marginBottom: 2, "& .MuiSvgIcon-root": { fill: theme.palette.warning.main } })} severity="warning">
                    <AlertTitle>{translateString("lockedVersion")}</AlertTitle>
                    {translateString("lockedVersionInfo")}
                </Alert>
            )}

            {isLoading === false && data!.scenarios.length > 0 && (
                <Box sx={styles.root}>
                    <Typography sx={styles.dropdownHeader}>
                        {translateString("riskClassification")}
                        <Box sx={styles.dropdownHeaderTooltip}>
                            <DotLegalInformationTooltip text={translateString("riskAssessmentScenariosTooltip")} />
                        </Box>
                    </Typography>

                    {data?.scenarios.map((obj, index) => {
                        return (
                            <Box key={obj.id} sx={styles.dropdown}>
                                <RiskAssessmentAnswerCollapse
                                    componentKey={index.toString()}
                                    header={getheader(obj.header)}
                                    score={obj.score}
                                    isScoreOverrridden={obj.isOverridden}
                                    isExcluded={false}
                                >
                                    <RiskAnswerGroup
                                        headerText={translateString("riskClassification")}
                                        showHeader
                                        showResetButton={obj.isOverridden}
                                        disableResetButton={isSaving}
                                        score={obj.score}
                                        note={obj.note}
                                        isConsequence={false}
                                        onScoreChange={(score) => {
                                            let object = obj;
                                            object.score = score;
                                            saveScenario(object as ScenarioViewModel);
                                        }}
                                        onNoteChange={(note) => {
                                            let object = obj;
                                            object.note = note;
                                            saveScenario(object as ScenarioViewModel);
                                        }}
                                        onResetOverrideClick={() => {
                                            resetScenarioClick(obj.id);
                                        }}
                                        isDisabled={data.isLocked}
                                    />
                                </RiskAssessmentAnswerCollapse>
                            </Box>
                        );
                    })}
                </Box>
            )}
        </DotLegalDialog>
    );
}

export default ProcessingActivityRiskScenariosDialog;
