import React from "react";
import DotLegalDeleteDialogWrapper from "../../../common/components/dotLegalDeleteDialog/DotLegalDeleteDialogWrapper";

import { RiskVersionViewModel } from "../riskAssessmentVersions/RiskAssessmentVersions.types";
import { useDeleteRiskAssessmentVersionDialogHooks } from "./DeleteRIskAssessmentVersionDialog.hooks";
import { useTranslation } from "../../../localization/useTranslation";

export interface IDeleteRiskAssessmentVersionDialog {
    onCloseDialog: () => void;
    onDeletion: () => void;
    selectedVersion: RiskVersionViewModel;
}

export function DeleteRiskAssessmentVersionDialog(props: IDeleteRiskAssessmentVersionDialog) {
    const { translateString } = useTranslation();
    const { removePurpose, inProgess } = useDeleteRiskAssessmentVersionDialogHooks(props);

    return (
        <DotLegalDeleteDialogWrapper
            open
            itemForDeletion={props.selectedVersion.name}
            onDialogClose={props.onCloseDialog}
            onOkClick={removePurpose}
            inProgress={inProgess}
            additionalWarning={translateString("riskAssessmentDeletionWarning")}
        />
    );
}
