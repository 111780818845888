import Widget from "../Widget";
import { useTranslation } from "../../localization/useTranslation";
import { useUrlProvider } from "../../useUrlProvider";
import DashboardCountCard from "../DashboardCountCard";
import { useVendorsCountWidget } from "./VendorsCountWidget.hooks";
import LargeCountText from "../LargeCountText.tsx";

export interface VendorsCountWidgetProps {
    onlyUserSpecific: boolean;
    isPdf?: boolean;
}

function VendorsCountWidget(props: VendorsCountWidgetProps) {
    const { translateString } = useTranslation();
    const { getLegalEntitiesUrl } = useUrlProvider();
    const { isLoading, data, isEnabled } = useVendorsCountWidget(props);

    return (
        <Widget noPadding disabled={!isEnabled}>
            <DashboardCountCard
                isLoading={isLoading}
                header={translateString("vendors")}
                background="purple"
                icon="building"
                url={{ url: getLegalEntitiesUrl(), text: translateString("goToVendors") }}
                isPdf={props.isPdf}
            >
                {!isLoading && <LargeCountText count={data} />}
            </DashboardCountCard>
        </Widget>
    );
}

export default VendorsCountWidget;
