import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { deleteHttp, get, post, put } from "../../common/api/apiShared";
import { useOptimisticUpdate } from "../../common/hooks/useOptimisticUpdate";
import { useStateUrlParams } from "../../common/hooks/useStateUrlParams";
import { ICommentDialog } from "./CommentDialog";
import { useCreateCommentDataMapping } from "../createEditComment/CreateEditComment.hooks";
import { Result } from "../../common/api/result";
import { CommentUpdateModel } from "../createEditComment/CreateEditComment.types";
import { ProcessingActivityCommentApiModel, ProcessingActivityApiModelContent } from "../commentCard/CommentCard.types";

export function useCommentDialogDataMapping(props: ICommentDialog) {
    const queryClient = useQueryClient();
    const { putOnQueueAndSetQueryData } = useOptimisticUpdate();
    const [showDialog, setShowDialog] = useStateUrlParams("commentDialog", "false");
    const [dialogHovered, setDialogHovered] = useState(false);
    const [createdCommentId, setCreatedCommentId] = useState("");
    const showCommentDialog = showDialog === "true";

    const url = `/processingactivity/${props.processingActivityId}/comments`;
    const { isLoading, data } = useQuery(url, () => get<Array<ProcessingActivityCommentApiModel>>(url), { enabled: showCommentDialog });

    const noOfCommentsUrl = `/processingactivity/${props.processingActivityId}/comments/unreadcomments`;
    const noOfCommentsQuery = useQuery(noOfCommentsUrl, () => get<Number>(noOfCommentsUrl));

    const numberOfUnreadComments = noOfCommentsQuery.isLoading ? undefined : noOfCommentsQuery.data;

    const saveMutation = useMutation(save, {
        onSuccess: (response: any) => {
            noOfCommentsQuery.refetch();
        },
    });

    const { onSubscriptionChange, subscriptionSaving, subscribed } = useCreateCommentDataMapping(
        `/processingactivity/${props.processingActivityId}/comments/subscription`,
        `/processingactivity/${props.processingActivityId}/comments/subscription`
    );

    function save() {
        return post<{}>(`/processingactivity/${props.processingActivityId}/comments/seen`, undefined);
    }

    const updateSeen = async () => {
        await saveMutation.mutateAsync();
    };

    useEffect(() => {
        if (numberOfUnreadComments && showDialog === "true") {
            updateSeen();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showDialog, numberOfUnreadComments]);

    function toggleCommentDialog() {
        if (!dialogHovered) {
            if (showCommentDialog) {
                setShowDialog("false");
                document.body.style.overflow = "unset";
            } else {
                setShowDialog("true");
                document.body.style.overflow = "hidden";
            }
        }
    }

    const createMutation = useMutation(created, {
        onSuccess: (response: Result<ProcessingActivityCommentApiModel>) => {
            const responseModel = response.value();
            data?.push(responseModel);
            queryClient.setQueryData(url, data);
            setCreatedCommentId(responseModel.id);
        },
    });

    function created(content: string) {
        return post<ProcessingActivityCommentApiModel>(`/processingactivity/${props.processingActivityId}/comments`, { content: content });
    }

    const createSubCommentMutation = useMutation(createSubComment);

    function createSubComment(data: { commentId: string; content: string }) {
        return post<ProcessingActivityApiModelContent>(`/processingactivity/${data.commentId}/subComments`, { content: data.content });
    }

    const onCreated = (content: string) => {
        createMutation.mutateAsync(content);
    };

    const onSubCommentCreated = (commentId: string, content: string) => {
        createSubCommentMutation.mutateAsync(
            { commentId: commentId, content: content },
            {
                onSuccess: (response: Result<ProcessingActivityApiModelContent>) => {
                    const responseModel = response.value();
                    var comment = data?.find((x) => x.id === commentId);
                    comment?.subComments.push(responseModel);
                    queryClient.setQueryData(url, data);
                    setCreatedCommentId(responseModel.id);
                },
            }
        );
    };

    const deleteCommentMutation = useMutation(deleteComment);

    async function onDeleteComment(id: string) {
        let temp = [...data!];
        temp = temp.filter((x) => x.id !== id);

        putOnQueueAndSetQueryData(temp, url, deleteCommentMutation.mutateAsync, id);
    }

    async function deleteComment(id: string) {
        return await deleteHttp<string>("/processingactivity/" + props.processingActivityId + "/comments/" + id);
    }

    const deleteSubCommentMutation = useMutation(deleteSubComment);

    async function onDeleteSubComment(commentId: string, subCommentId: string) {
        let temp = [...data!];
        var comment = temp.find((x) => x.id === commentId)!;

        comment.subComments = comment.subComments.filter((x) => x.id !== subCommentId);

        putOnQueueAndSetQueryData(temp, url, deleteSubCommentMutation.mutateAsync, { commentId: commentId, subCommentId: subCommentId });
    }

    async function deleteSubComment(data: { commentId: string; subCommentId: string }) {
        return await deleteHttp<string>("/processingactivity/" + data.commentId + "/subcomments/" + data.subCommentId);
    }

    const updateCommentMutation = useMutation(updateComment);

    async function onUpdateComment(model: CommentUpdateModel) {
        let temp = [...data!];
        let comment = temp.find((x) => x.id === model.commentId)!;
        comment.content = model.content;
        comment.isEdited = true;

        putOnQueueAndSetQueryData(temp, url, updateCommentMutation.mutateAsync, model);
    }

    async function updateComment(model: CommentUpdateModel) {
        return await put<{}>("/processingactivity/" + props.processingActivityId + "/comments/" + model.commentId, { content: model.content });
    }

    const updateSubCommentMutation = useMutation(updateSubComment);

    async function onUpdateSubComment(model: CommentUpdateModel) {
        let temp = [...data!];
        let comment = temp.find((x) => x.id === model.commentId)!;

        let subcomment = comment.subComments.find((x) => x.id === model.subCommentId)!;
        subcomment.content = model.content;
        subcomment.isEdited = true;

        putOnQueueAndSetQueryData(temp, url, updateSubCommentMutation.mutateAsync, model);
    }

    async function updateSubComment(model: CommentUpdateModel) {
        return await put<{}>("/processingactivity/" + model.commentId + "/subcomments/" + model.subCommentId, { content: model.content });
    }

    return {
        showDialog,
        setShowDialog,
        isLoading,
        data,
        toggleCommentDialog,
        showCommentDialog,
        onDeleteComment,
        onDeleteSubComment,
        onCreated,
        onUpdateComment,
        setDialogHovered,
        setCreatedCommentId,
        createdCommentId,
        numberOfUnreadComments,
        onSubCommentCreated,
        onUpdateSubComment,
        onSubscriptionChange,
        subscriptionSaving,
        subscribed,
    };
}
