import { Theme, useTheme } from "@mui/material";
import { IDotLegalDragNDrop } from "./DotLegalDragNDrop";
import { createSxStyles } from "../../createSxStyles";

export const useDotLegalDragNDropStyles = (props: IDotLegalDragNDrop) => {
    const theme = useTheme();
    return createSxStyles({
        root: {
            height: props.removeFullHeight ? "auto" : "100%",
        },
        headerContainer: {
            display: "flex",
            alignItems: "center",
        },
        itemContainer: {
            backgroundColor: props.disableBackgroundColor ? "none" : theme.customPalette.collapseBackground,
            borderRadius: 15 + "px",
            height: "100%",
            paddingTop: props.removeTopPadding ? 0 : theme.spacing(1.5),
            paddingBottom: theme.spacing(1.5),
            paddingLeft: theme.spacing(1.5),
            paddingRight: theme.spacing(1.5),
            overflow: "hidden",
            marginTop: props.removeTopPadding ? 8 + "px" : 0,
        },
        fontSize: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(1),
            fontSize: theme.typography.pxToRem(18),
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.primary.dark,
        },
        taskCount: {
            backgroundColor: `${theme.palette.primary.main} !Important`,
            color: `${theme.palette.common.white} !Important`,
            fontSize: theme.typography.pxToRem(18),
        },
    });
};

export const getListStyle = (isDraggingOver: boolean, theme: Theme, disableBackgroundColor?: boolean) => ({
    background: isDraggingOver ? theme.palette.grey.A100 : disableBackgroundColor ? "none" : theme.customPalette.collapseBackground,
    padding: theme.spacing(1),
    borderRadius: 15 + "px",
    height: "100%",
});

export const getItemStyle = (
    draggingOver: string | undefined | null,
    deniedColumnAccess: number | null,
    isDragging: Boolean,
    theme: Theme,
    draggableStyle: any,
    disabled?: boolean
) => ({
    userSelect: "none",
    cursor: disabled
        ? "default"
        : deniedColumnAccess !== null && draggingOver !== undefined && draggingOver !== null && deniedColumnAccess === Number(draggingOver!) + 1
          ? "no-drop"
          : isDragging
            ? "grabbing"
            : "grab",
    marginBottom: theme.spacing(2),
    ...draggableStyle,
});
