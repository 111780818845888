import React from "react";
import { LatestInternalAssessmentSubmissionViewModel } from "../LatestAuditsSubmittedWidget.types";
import { DotLegalEmptyState, DotLegalTable, DotLegalTooltip, ITableHeader } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { Box, TableCell, TableRow } from "@mui/material";
import { useRiskIndicator } from "../../../riskAssessment/riskIndicator/RiskIndicator.hooks";
import { getQuestionnaireResponseScore } from "../../../common/questionnaireResponseScore";
import LatestAuditsEmptyStateIcon from "../latest-audits-empty-state.svg?react";
import { useUrlProvider } from "../../../useUrlProvider";
import DotLegalLink from "../../../common/components/dotLegalLink/DotLegalLink";
import { Trans } from "react-i18next";
import DashboardPrettyScrollbar from "../../DashboardPrettyScrollbar.tsx";

export interface LatestAssessmentsTableProps {
    data: Array<LatestInternalAssessmentSubmissionViewModel>;
    isEnabled: boolean;
}

function LatestAssessmentsTable(props: LatestAssessmentsTableProps) {
    const { translateString, translateDate } = useTranslation();
    const { getRiskIcon } = useRiskIndicator();
    const { getAuditsUrl, getInternalAssessmentUrl } = useUrlProvider();

    function getHeaders() {
        let headers = Array<ITableHeader<LatestInternalAssessmentSubmissionViewModel>>();
        headers.push({ property: "internalAssessmentName", text: translateString("name"), align: "left", showOnMobile: true });
        headers.push({ property: "assignee", text: translateString("assigned"), align: "left", showOnMobile: true });
        headers.push({ property: "score", text: translateString("riskLevel"), align: "left", showOnMobile: true });

        return headers;
    }

    const getScore = (row: LatestInternalAssessmentSubmissionViewModel) => {
        return getQuestionnaireResponseScore(true, row.scoreEnabled, row.score, translateString("scoreNotEnabled"));
    };

    return props.isEnabled && props.data.length > 0 ? (
        <DashboardPrettyScrollbar>
            <DotLegalTable
                marginTop={0}
                headers={getHeaders()}
                defaultOrderBy={"internalAssessmentName"}
                defaultOrder={"desc"}
                paginationLabelOf={""}
                data={props.data}
                hidePagination
                clickableRows={false}
                renderRow={(row) => {
                    return (
                        <DotLegalTooltip
                            text={<Trans i18nKey={"submittedOnDate"} values={{ date: translateDate(row.latestResponseChange) }} />}
                            key={row.id}
                            followCursor
                            position="right"
                        >
                            <TableRow>
                                <TableCell>
                                    <DotLegalLink
                                        bold
                                        linkColor={"primary"}
                                        to={`${getInternalAssessmentUrl()}?templates=${row.questionnaireId}`}
                                        stopPropagation
                                    >
                                        {row.internalAssessmentName}
                                    </DotLegalLink>
                                </TableCell>
                                <TableCell>{row.assignee}</TableCell>
                                <TableCell>
                                    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                                        {getRiskIcon(row.riskLevel)}
                                        {getScore(row)}
                                    </Box>
                                </TableCell>
                            </TableRow>
                        </DotLegalTooltip>
                    );
                }}
            />
        </DashboardPrettyScrollbar>
    ) : (
        <DotLegalEmptyState
            noFixedHeight
            noPadding
            textColor={"darkBlue"}
            icon={<LatestAuditsEmptyStateIcon />}
            text={
                <Trans
                    i18nKey={"sendCustomAssessmentsToYourColleagues"}
                    components={{
                        box: (
                            <DotLegalLink to={getAuditsUrl()} linkColor="primary">
                                {undefined}
                            </DotLegalLink>
                        ),
                    }}
                />
            }
        />
    );
}

export default LatestAssessmentsTable;
