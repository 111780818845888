import { createSxStyles } from "../../common/createSxStyles";

export const useCommentOverviewStyles = () => {
    return createSxStyles({
        skeletons: {
            height: 270,
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
        },
        empty: {
            height: 270,
        },
    });
};
