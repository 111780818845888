import { Box, Typography } from "@mui/material";
import React from "react";
import { useProcessingActivityStatisticsStyles } from "../processingActivityStatistics.styles";
import { useTranslation } from "../../../localization/useTranslation";
import DotLegalPaper from "../../../common/components/dotLegalPaper/DotLegalPaper";

export interface AdvisoryCasesProps {
    advisoryCases: number | undefined;
}

function AdvisoryCases(props: AdvisoryCasesProps) {
    const styles = useProcessingActivityStatisticsStyles();
    const { translateString } = useTranslation();
    return (
        <Box>
            <DotLegalPaper backgroundColor="darkLightBlue" fullHeight>
                <Typography sx={styles.header}>{translateString("advisoryCases")}</Typography>
                <Typography sx={styles.body}>{props?.advisoryCases}</Typography>
            </DotLegalPaper>
        </Box>
    );
}

export default AdvisoryCases;
