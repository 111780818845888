import { createElement } from "react";
import { Trans } from "react-i18next";
import { useMutation } from "react-query";
import { put } from "../../../common/api/apiShared";
import { Result } from "../../../common/api/result";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { SecurityMeasureSaveModel } from "../securityMeasures/SecurityMeasures.types";
import { IChangeSecurityMeasureStatusDialogDataMapping } from "./ChangeSecurityMeasureStatusDialog";

export function useChangeSecurityMeasureStatusDialogDataMapping(props: IChangeSecurityMeasureStatusDialogDataMapping) {
    const snackbar = useDotLegalSnackbar();

    const changeActiveStatusMutation = useMutation(changeStatus);

    const changeActiveStatus = (securityMeasure: SecurityMeasureSaveModel) => {
        changeActiveStatusMutation.mutateAsync(securityMeasure, {
            onSuccess: (response: Result<string>) => {
                props.onCloseDialog();

                if (response.success()) {
                    snackbar.show(createElement(Trans, { i18nKey: "activeStatusChanged", values: { name: securityMeasure.name } }));
                    props.refetch();
                } else {
                    snackbar.show(createElement(Trans, { i18nKey: "SecurityMeasureStatusNotChanged", values: { name: securityMeasure.name } }), "warning");
                }
            },
        });
    };

    function changeStatus(securityMeasure: SecurityMeasureSaveModel) {
        if (securityMeasure.isCustom) return put<string>(`/securitymeasures/${securityMeasure.id}/toggleSecurityMeasureActive`, !securityMeasure.active);
        else return put<string>(`/securitymeasures/${securityMeasure.id}/toggleSecurityMeasureMasterDataActive`, !securityMeasure.active);
    }

    return {
        changeActiveStatus,
        inProgress: changeActiveStatusMutation.isLoading,
    };
}
