import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { DotLegalInformationTooltip, DotLegalSelect, DotLegalTextField, DotLegalTooltip } from "@dotlegal/dotlegal-ui-components";
import { DotLegalSelectOption } from "../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import DotLegalSelectWithButton from "../../common/components/dotLegalSelectWithButton/DotLegalSelectWithButton";
import { Guid } from "../../common/guid";
import { useTranslation } from "../../localization/useTranslation";
import { AddEditDeletionPeriodDialog } from "../../masterData/deletionPeriod/addEditDeletionPeriodDialog/AddEditDeletionPeriodDialog";
import { LegalBasisSelectorViewModel, LegalBasisType } from "../../processingActivity/legalBasesStep/LegalBasesStep.types";
import { LegalBasisBaseClass } from "../../processingActivity/ProcessingActivity.types";
import { useLegalBasisSelectorStyles } from "./LegalBasisSelector.styles";
import { useLegalBasisSeletorHooks } from "./LegalBasisSelector.hooks";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { isNullOrWhitespace } from "../../common/stringOperations";
import NationalLawDialog from "../../masterData/nationalLaw/nationalLawDialog/NationalLawDialog";

export interface ILegalBasisSelectorProps {
    dataCategoryName: string;
    legalBases?: LegalBasisSelectorViewModel;
    deletionPeriodOptions?: Array<DotLegalSelectOption>;
    dataCategory?: LegalBasisBaseClass;
    directiveError?: string;
    deletionPeriodError?: string;
    doubleLegalBasisError?: string;
    nationalLawError?: string;
    isSensitive: boolean;
    isLoading: boolean;
    toolTip: string;
    warningText?: string;
    hasCreateDataPermission: boolean;
    OnDirectiveChange: (legalBasisId: string, dataCategoryId: string, showBalanceOfInterest: boolean) => void;
    OnNationalLawChange: (legalBasisId: string, dataCategoryId: string, showBalanceOfInterest: boolean) => void;
    OnDoubleLegalBasisChange: (legalBasisId: string, dataCategoryId: string, showBalanceOfInterest: boolean) => void;
    OnBalanceOfInterests: (legalBasisId: string, dataCategoryId: string) => void;
    onDeletionPeriodChange?: (deletionPeriodId: string, dataCategoryId: string) => void;
    onDeletionPeriodReasonChange?: (reason: string, dataCategoryId: string) => void;
    disabled?: boolean;
    onSaveDeletionPeriod?: (response: any, name: string, dataCategoryId: string) => void;
    hasLegalBasisPermissions: boolean;
    hasDeletionPeriodPermission?: boolean;
    onSaveNationalLaw: (response: any, dataCategoryId: string) => void;
}

function LegalBasisSelector(props: ILegalBasisSelectorProps) {
    const { translateString } = useTranslation();
    const styles = useLegalBasisSelectorStyles();
    const {
        typedDeletionPeriod,
        setTypedDeletionPeriod,
        typedNationalLaw,
        setTypedNationalLaw,
        showAddNewDeletionPeriod,
        setShowAddNewDeletionPeriod,
        showAddNationalLawDialog,
        setShowAddNationalLawDialog,
    } = useLegalBasisSeletorHooks();

    const OnDirectiveChange = (chosenItem: string) => {
        props.OnDirectiveChange(chosenItem, props.dataCategory!.dataCategoryId!, ShowBalanceOfInterest());
    };

    const OnDoubleLegalBasisChange = (chosenItem: string) => {
        props.OnDoubleLegalBasisChange(chosenItem, props.dataCategory!.dataCategoryId!, ShowBalanceOfInterest());
    };

    const OnNationalLawChange = (chosenItem: string) => {
        props.OnNationalLawChange(chosenItem, props.dataCategory!.dataCategoryId!, ShowBalanceOfInterest());
    };

    const OnBalanceOfInterests = (value: string) => {
        props.OnBalanceOfInterests(value, props.dataCategory!.dataCategoryId!);
    };
    let selectedDeletionPeriods: string[] = [];

    if (props.dataCategory?.deletionPeriodId) selectedDeletionPeriods.push(props.dataCategory?.deletionPeriodId!);

    const onDelectionPeriodChange = (value: string) => {
        if (props.onDeletionPeriodChange) {
            props.onDeletionPeriodChange(value, props.dataCategory!.dataCategoryId);
            if (value === undefined) selectedDeletionPeriods = [];
            else selectedDeletionPeriods.push(value);
        }
    };

    const onDeletionPeriodReasonChange = (value: string) => {
        if (props.onDeletionPeriodReasonChange) {
            props.onDeletionPeriodReasonChange(value, props.dataCategory!.dataCategoryId);
        }
    };

    const ShowBalanceOfInterest = () => {
        if (props.isLoading) return false;

        if (
            props.legalBases!.directives.find((x) => x.id === props.dataCategory?.directiveId)?.balanceOfInterests ||
            props.legalBases!.nationalLaws.find((x) => x.id === props.dataCategory?.nationalLawId)?.balanceOfInterests ||
            props.legalBases!.doubleLegalBases.find((x) => x.id === props.dataCategory?.doubleLegalBasisId)?.balanceOfInterests
        ) {
            return true;
        }

        return false;
    };

    const hasDeletionPeriod = () => {
        return !isNullOrWhitespace(props.dataCategory?.deletionPeriodId);
    };

    const GetSelectedId = (items: Array<DotLegalSelectOption> | undefined, type: LegalBasisType) => {
        if (props.isLoading) return "";
        else {
            if (type === LegalBasisType.Directive) return items?.find((x) => x.id === props.dataCategory?.directiveId)?.id;
            else if (type === LegalBasisType.NationalLaw) return items?.find((x) => x.id === props.dataCategory?.nationalLawId)?.id;
            else return items?.find((x) => x.id === props.dataCategory?.doubleLegalBasisId)?.id;
        }
    };

    const handleSaveDeletionPeriod = (response: any, name: string, dataCategoryId: string) => {
        if (props.onSaveDeletionPeriod) {
            props.onSaveDeletionPeriod(response, name, dataCategoryId);
        }
    };

    return (
        <Box sx={styles.overhead}>
            <Box sx={styles.container}>
                <Box sx={styles.dataCategoryContainer}>
                    <Typography sx={styles.dataCategory}>{props.dataCategoryName}</Typography>
                    {props.toolTip !== "" && (
                        <Box sx={styles.tooltip}>
                            <DotLegalInformationTooltip text={props.toolTip} />
                        </Box>
                    )}
                    {props.warningText !== "" && (
                        <DotLegalTooltip text={props.warningText}>
                            <PriorityHighIcon sx={styles.exclamationIcon} />
                        </DotLegalTooltip>
                    )}
                </Box>
            </Box>
            <Box sx={styles.gridContainer}>
                <Grid container spacing={1}>
                    {props.hasLegalBasisPermissions && (
                        <React.Fragment>
                            <Grid item xs={12} md={6} lg={3}>
                                <DotLegalSelect
                                    selectedItem={GetSelectedId(props.legalBases?.directives, LegalBasisType.Directive)}
                                    label={translateString("legalBasis")}
                                    placeholder={translateString("legalBasis")}
                                    isLoading={props.isLoading}
                                    options={props.legalBases?.directives}
                                    onChange={(id) => OnDirectiveChange(id!)}
                                    noMargin
                                    disabled={props.disabled}
                                    errorText={props.directiveError}
                                    showHoverOnInputField
                                    noOptionsLabel={translateString("noOptions")}
                                />
                                {ShowBalanceOfInterest() && (
                                    <Box
                                        sx={{
                                            marginTop: 2,
                                        }}
                                    >
                                        <DotLegalTextField
                                            onChange={(e) => OnBalanceOfInterests(e)}
                                            label={translateString("interrest")}
                                            value={props.dataCategory!.balanceOfInterest!}
                                            noMargin
                                            disabled={props.disabled}
                                            debounce
                                        />
                                    </Box>
                                )}
                            </Grid>
                            {props.isSensitive && (
                                <Grid item xs={12} md={6} lg={3}>
                                    <DotLegalSelect
                                        selectedItem={GetSelectedId(props.legalBases?.doubleLegalBases, LegalBasisType.DoubleLegalBasis)}
                                        label={translateString("doubleLegalBasis")}
                                        placeholder={translateString("doubleLegalBasis")}
                                        isLoading={props.isLoading}
                                        options={props.legalBases?.doubleLegalBases}
                                        onChange={(id) => OnDoubleLegalBasisChange(id!)}
                                        noMargin
                                        disabled={props.disabled}
                                        errorText={props.doubleLegalBasisError}
                                        showHoverOnInputField
                                        noOptionsLabel={translateString("noOptions")}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12} md={6} lg={3}>
                                <DotLegalSelectWithButton
                                    selectedItem={GetSelectedId(props.legalBases?.nationalLaws, LegalBasisType.NationalLaw)}
                                    label={translateString("nationalLaw")}
                                    placeholder={translateString("nationalLaw")}
                                    isLoading={props.isLoading}
                                    options={props.legalBases?.nationalLaws}
                                    onChange={(id) => OnNationalLawChange(id!)}
                                    noMargin
                                    disabled={props.disabled}
                                    showHoverOnInputField
                                    errorText={props.nationalLawError}
                                    noOptionsLabel={translateString("noOptions")}
                                    onButtonClick={() => setShowAddNationalLawDialog(true)}
                                    buttonLabel={translateString("createNew")}
                                    onSearchValueChange={setTypedNationalLaw}
                                    searchValue={typedNationalLaw}
                                    hasPermission={props.hasCreateDataPermission}
                                />
                            </Grid>
                        </React.Fragment>
                    )}
                    {props.deletionPeriodOptions && props.hasDeletionPeriodPermission && (
                        <Grid item xs={12} md={6} lg={3}>
                            <DotLegalSelectWithButton
                                label={translateString("deletionPeriod")}
                                placeholder={translateString("deletionPeriod")}
                                selectedItem={props.dataCategory?.deletionPeriodId}
                                isLoading={props.isLoading}
                                options={props.deletionPeriodOptions}
                                onChange={(deletionPeriodId) => onDelectionPeriodChange(deletionPeriodId!)}
                                noMargin
                                disabled={props.disabled}
                                errorText={props.deletionPeriodError}
                                hasPermission={props.hasCreateDataPermission}
                                onButtonClick={() => setShowAddNewDeletionPeriod(true)}
                                buttonLabel={translateString("createNew")}
                                onSearchValueChange={setTypedDeletionPeriod}
                                searchValue={typedDeletionPeriod}
                                showHoverOnInputField
                                noOptionsLabel={translateString("noOptions")}
                            />
                            {hasDeletionPeriod() && (
                                <Box
                                    sx={{
                                        marginTop: 2,
                                    }}
                                >
                                    <DotLegalTextField
                                        onChange={(e) => onDeletionPeriodReasonChange(e)}
                                        label={translateString("deletionPeriodReason")}
                                        value={props.dataCategory!.deletionPeriodReason!}
                                        noMargin
                                        disabled={props.disabled}
                                        debounce
                                    />
                                </Box>
                            )}
                        </Grid>
                    )}
                </Grid>

                {showAddNewDeletionPeriod && (
                    <AddEditDeletionPeriodDialog
                        open
                        isAdd
                        deletionPeriod={{
                            id: Guid.newGuid(),
                            name: typedDeletionPeriod!,
                            description: "",
                            deletionPeriodLength: undefined,
                            deletionPeriodType: undefined,
                            active: true,
                            isCustom: true,
                        }}
                        onDialogClose={() => setShowAddNewDeletionPeriod(false)}
                        onOkClick={(reponse, name) => handleSaveDeletionPeriod(reponse, name, props.dataCategory!.dataCategoryId)}
                        refetch={() => {}}
                    />
                )}

                {showAddNationalLawDialog && (
                    <NationalLawDialog
                        open
                        isAdd
                        onDialogClose={() => setShowAddNationalLawDialog(false)}
                        onOkClick={(response, name) => props.onSaveNationalLaw(response, props.dataCategory!.dataCategoryId)}
                        refetch={() => {}}
                        nationalLaw={{
                            active: true,
                            cf: "",
                            description: "",
                            legislationId: "",
                            name: typedNationalLaw,
                            number: "",
                            provision: "",
                            section: "",
                            isCustom: true,
                        }}
                    />
                )}
            </Box>
        </Box>
    );
}

export default LegalBasisSelector;
