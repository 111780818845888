import { createElement } from "react";
import { Trans } from "react-i18next";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { put } from "../../common/api/apiShared";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useUrlProvider } from "../../useUrlProvider";
import { IChangeCustomerOwnedDialog } from "./ChangeCustomerOwnedDialog";

export function ChangeCustomerOwnedDialogDataMapping(props: IChangeCustomerOwnedDialog) {
    const snackbar = useDotLegalSnackbar();
    const navigate = useNavigate();
    const { getGroupEntityUrl } = useUrlProvider();
    const changeCustomerOwnedMutation = useMutation(changeStatus);
    const changeCustomerOwned = () => {
        changeCustomerOwnedMutation.mutateAsync(undefined, {
            onSuccess: (resp: any) => {
                const editResponse = resp.value();
                if (editResponse.success) {
                    snackbar.show(createElement(Trans, { i18nKey: "companyIsCustomerOwned", values: { name: editResponse.name } }));
                    props.onCloseDialog();
                    props.onUpdate();
                    navigate(getGroupEntityUrl(props.legalEntityId), { replace: true });
                }
            },
        });
    };

    function changeStatus() {
        return put<{}>(`/LegalEntity/${props.legalEntityId}/togglecustomerowned/`, !props.isCustomerOwned);
    }

    return {
        changeCustomerOwned,
        isSaving: changeCustomerOwnedMutation.isLoading,
    };
}
