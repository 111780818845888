import { DotLegalPaper } from "@dotlegal/dotlegal-ui-components";
import React from "react";
import ClassificationTable from "../../../legalEntity/legalEntityClassificationTab/classificationTable/ClassificationTable";
import useSystemClassificationTabHooks from "./SystemClassificationTab.hooks";
import { useTranslation } from "../../../localization/useTranslation";

export interface ISystemClassificationTabProps {
    systemId: string;
}

function SystemClassificationTab(props: ISystemClassificationTabProps) {
    const { translateString } = useTranslation();
    const { isLoading, data, refetch } = useSystemClassificationTabHooks(props);

    return (
        <DotLegalPaper>
            <React.Fragment>
                <ClassificationTable
                    data={data}
                    isLoading={isLoading}
                    refetch={() => refetch()}
                    paqgeType="system"
                    emptyStateText={translateString("noClassifications")}
                />
            </React.Fragment>
        </DotLegalPaper>
    );
}

export default SystemClassificationTab;
