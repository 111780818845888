import { useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";

export const UseProcessingActivityRiskScenariosDialogStyles = (isSmallScreen: boolean) => {
    const theme = useTheme();
    return createSxStyles({
        root: {
            marginBottom: theme.spacing(3),
            marginTop: theme.spacing(3),
        },
        dropdown: {
            marginBottom: theme.spacing(1),
        },
        dropdownHeader: {
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            color: theme.palette.primary.dark,
            fontSize: theme.typography.pxToRem(14),
            fontWeight: "bold",
            marginBottom: theme.spacing(1),
        },
        dropdownHeaderTooltip: {
            marginLeft: theme.spacing(1),
            display: "flex",
        },
        questionText: {
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: theme.typography.pxToRem(14),
        },
    });
};
