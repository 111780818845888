import { ITaskOverviewWidgetProps } from "./TaskOverviewWidget.tsx";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider.tsx";
import { useQuery } from "react-query";
import { get } from "../../common/api/apiShared.ts";
import { DashboardTaskOverviewViewModel } from "./TaskOverviewWidget.types.ts";
import { useEffect, useState } from "react";
import { useStateLocalStorage } from "@dotlegal/dotlegal-ui-components";

export function useTaskOverviewWidget(props: ITaskOverviewWidgetProps) {
    const userContext = useUserContext();
    const isEnabled = userContext.permissions.taskPermissions.read;
    const [year, setYear] = useStateLocalStorage("annualWheelYear", props.year ?? new Date().getFullYear());
    const [graphData, setGraphData] = useState([
        { month: 0, numberOfCompletedTasks: 0, numberOfOverdueTasks: 0, numberOfPlannedTasks: 0, numberOfOngoingTasks: 0 },
    ]);

    const url = `/taskManagement/dashboard/tasksCount?year=${year}&onlyUserSpecific=${props.onlyUserSpecific}&groupentityid=${props.selectedGroupCompany}`;
    const { isLoading, data } = useQuery(url, () => get<DashboardTaskOverviewViewModel>(url), { enabled: isEnabled });

    let highestNumberOfTasksInAMonth = 0;
    if (data) {
        const numberOfTasksInEachMonth = data!.tasks.map(
            (x) => (x.numberOfCompletedTasks ?? 0) + (x.numberOfOverdueTasks ?? 0) + (x.numberOfPlannedTasks ?? 0) + (x.numberOfOngoingTasks ?? 0)
        );
        highestNumberOfTasksInAMonth = Math.max(...numberOfTasksInEachMonth);
    }

    useEffect(() => {
        if (data?.tasks) {
            setGraphData(data.tasks);
        }
    }, [data]);

    return {
        isLoading,
        data: graphData ?? [],
        isEnabled,
        year,
        setYear,
        highestNumberOfTasksInAMonth,
    };
}
