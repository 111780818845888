import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useMutation } from "react-query";
import { put } from "../../../common/api/apiShared";
import { EvaluationStatusSaveModel } from "../../../common/components/questionnaireComponents/evaluateQuestionnaireDialog/EvaluateQuestionnaireDialog.types";
import { IEvaluateAuditDialog } from "./EvaluateAuditDialog";
import { createElement } from "react";
import { Trans } from "react-i18next";

export default function useEvaluateAuditDialogHooks(props: IEvaluateAuditDialog) {
    const snackbar = useDotLegalSnackbar();

    const evaluationMutation = useMutation(evaluateApi);

    const saveEvaluation = (saveModel: EvaluationStatusSaveModel) => {
        evaluationMutation.mutateAsync(saveModel, {
            onSuccess: (resp: any) => {
                props.onEvaluated();
                snackbar.show(createElement(Trans, { i18nKey: "auditEvaluated", values: { name: props.questionnaireName } }));
            },
        });
    };

    function evaluateApi(saveModel: EvaluationStatusSaveModel) {
        return put<{}>(`/legalEntityAudit/${props.id}/evaluate`, saveModel);
    }

    return {
        saveEvaluation,
        isSaving: evaluationMutation.isLoading,
    };
}
