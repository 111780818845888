import { AnnualWheelActivityFramework, AnnualWheelPriority } from "../AnnualWheelDialog.types";

export class AnnualWheelBaseInformationFormModel {
    priority: AnnualWheelPriority | undefined = undefined;
    customId: string | undefined | null = null;
    documentationRequired: boolean = false;
    areas: Array<string> = [];
    frameWork?: AnnualWheelActivityFramework = undefined;
    relatedActivities: Array<string> = [];
    tags: Array<string> | undefined = undefined;

    constructor(
        priority: AnnualWheelPriority | undefined,
        customId: string | undefined | null,
        documentationRequired: boolean | undefined,
        areas: Array<string> | undefined,
        relatedActivities: Array<string> | undefined,
        tags: Array<string> | undefined,
        frameWork?: AnnualWheelActivityFramework | undefined
    ) {
        this.priority = priority;
        this.customId = customId;
        this.documentationRequired = documentationRequired ?? false;
        this.areas = areas ?? [];
        this.frameWork = frameWork;
        this.relatedActivities = relatedActivities ?? [];
        this.tags = tags;
    }
}
