import { useState } from "react";
import { IPolicySelectorDialog } from "./PolicySelectorDialog";

export function usePolicySelectorDialogDataMapping(props: IPolicySelectorDialog) {
    const [selectedPolicies, setSelectedPolicies] = useState<Array<string>>([]);

    function onCheckBoxClick(id: string) {
        var temp = [...selectedPolicies];

        var index = temp.indexOf(id);

        if (index < 0) {
            temp.push(id);
        } else {
            temp.splice(index, 1);
        }

        setSelectedPolicies(temp);
    }

    const onSelectAllPolicies = () => {
        const allPoliciesSelected = props.policies?.length === selectedPolicies.length;

        let selectedPolicyIds: Array<string> = [];

        if (!allPoliciesSelected) {
            selectedPolicyIds = props.policies!.map((x) => x.id);
        }

        setSelectedPolicies(selectedPolicyIds);
    };

    return { selectedPolicies, setSelectedPolicies, onCheckBoxClick, onSelectAllPolicies };
}
