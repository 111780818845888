import { useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";

export const useIncidentLogStyles = () => {
    const theme = useTheme();

    return createSxStyles({
        bold: {
            fontWeight: theme.typography.fontWeightBold,
        },
        iconContainer: {
            display: "flex",
        },
    });
};
