import { DotLegalDialog, DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import React from "react";
import { Box } from "@mui/material";
import { Trans } from "react-i18next";
import { useTranslation } from "../../../localization/useTranslation";
import useToogleAuditRemindersDialogHooks from "./ToggleAuditRemindersDialog.hooks";

export interface IToggleAuditRemindersDialog {
    onDialogClose: () => void;
    onInternalAssessmentPaused: () => void;
    remindersPaused: boolean;
    name: string;
    id: string;
}

function ToggleAuditRemindersDialog(props: IToggleAuditRemindersDialog) {
    const { translateString } = useTranslation();

    const { onPauseClick, isSaving } = useToogleAuditRemindersDialogHooks(props);

    return (
        <DotLegalDialog
            buttonOkText={props.remindersPaused ? translateString("resume") : translateString("pause")}
            header={props.remindersPaused ? translateString("resumeReminders") : translateString("pauseReminders")}
            size="sm"
            open
            onDialogClose={props.onDialogClose}
            onOkClick={() => onPauseClick()}
            inProgress={isSaving}
        >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <DotLegalHeader fontWeight={400} center headerStyle="small">
                    <Trans
                        i18nKey={props.remindersPaused ? "resumeRemindersContent" : "pauseRemindersContent"}
                        values={{
                            name: props.name.trim(),
                        }}
                        components={{
                            wrapper: (
                                <Box
                                    sx={(theme) => ({ color: theme.palette.secondary.main, fontWeight: theme.typography.fontWeightBold })}
                                    component="span"
                                ></Box>
                            ),
                        }}
                    />
                </DotLegalHeader>
            </Box>
        </DotLegalDialog>
    );
}

export default ToggleAuditRemindersDialog;
