import { useMutation } from "react-query";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { ISendReminderDialog } from "./SendReminderDialog";
import { put } from "../../../common/api/apiShared";
import { createElement, useState } from "react";
import { Trans } from "react-i18next";

export default function useSendReminderDialogHooks(props: ISendReminderDialog) {
    const snackbar = useDotLegalSnackbar();
    const [additionalMessage, setAdditionalMessage] = useState<string | undefined>(undefined);

    const sendReminderMutation = useMutation(sendReminderUrl);
    function sendReminderUrl() {
        return put(`/legalEntityAudit/${props.auditId}/sendreminder`, additionalMessage);
    }

    const sendReminder = () => {
        sendReminderMutation.mutateAsync(undefined, {
            onSuccess: () => {
                snackbar.show(createElement(Trans, { i18nKey: "reminderSent", values: { respondent: props.contactName } }));
                props.onReminderSent();
            },
        });
    };

    return { sendReminder, isSending: sendReminderMutation.isLoading, additionalMessage, setAdditionalMessage };
}
