import { Box, Divider, MenuItem } from "@mui/material";
import React from "react";
import { DotLegalMultiSelect, IDotLegalMultiSelect } from "@dotlegal/dotlegal-ui-components";
import { useDotLegalMultiSelectWithButtonStyles } from "./DotLegalMultiSelectWithButton.styles";
import AddIcon from "@mui/icons-material/Add";

export interface IDotLegalMultiSelectWithButton extends IDotLegalMultiSelect {
    onButtonClick: () => void;
    buttonLabel: string;
    hasPermission?: boolean;
    onSearchValueChange?: (value: string) => void;
    searchValue?: string;
}

export default function DotLegalMultiSelectWithButton(props: IDotLegalMultiSelectWithButton) {
    const styles = useDotLegalMultiSelectWithButtonStyles();

    return (
        <DotLegalMultiSelect {...props} onChange={(o) => props.onChange(o)} onSearchValueChange={props.onSearchValueChange} searchValue={props.searchValue}>
            <div>
                {props.hasPermission && (
                    <React.Fragment>
                        <Divider sx={styles.divider} />
                        <Box sx={{ paddingTop: 1, paddingBottom: 1 }}>
                            <MenuItem sx={styles.MenuItem} onMouseDown={() => props.onButtonClick()}>
                                <AddIcon fontSize="small" />
                                {props.buttonLabel}
                            </MenuItem>
                        </Box>
                    </React.Fragment>
                )}
            </div>
        </DotLegalMultiSelect>
    );
}
