import { createElement, useState } from "react";
import { Trans } from "react-i18next";
import { useMutation } from "react-query";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { put } from "../../common/api/apiShared";
import { downloadFile } from "../../common/api/downloadFile";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useSetPdfPageSize } from "../../common/pdfPageSizeHelper";
import { createPdfUrl } from "../../common/pdfUrlHelper";
import { getSettings } from "../../common/settingsProvider";
import { ProcessingActivityStatus } from "../../processingActivity/processingActivities/ProcessingActivities.types";
import { useUrlProvider } from "../../useUrlProvider";
import { IProcessingActivtyReportDialog } from "./ProcessingActivityReportDialog";
import { CustomerReportType } from "../../customer/addEditCustomerDialog/AddEditCustomer.types";

export function useProcessingActivityReportDialogHooks(props: IProcessingActivtyReportDialog) {
    useSetPdfPageSize("landscape");
    const snackBar = useDotLegalSnackbar();
    const { language } = useUserContext();
    const [reportType, setReportType] = useState<CustomerReportType>(CustomerReportType.RegistrationOfResearchProject);

    const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);
    const {
        getProcessingActivityRegistrationOfResearchProjectReportPDF,
        getProcessingActivityInternalAcquisitionOfSignatureReportPDF,
        getProcessingActivityResearchEthicsApprovalReportReportPDF,
    } = useUrlProvider();

    async function onDownloadPdfClick() {
        setIsDownloadingPdf(true);

        const fileName = `${props.processingActivityName}-${
            reportType === CustomerReportType.RegistrationOfResearchProject
                ? "registration-of-research-project"
                : reportType === CustomerReportType.InternalAcquisitionOfSignature
                  ? "internal-acquisition-of-signature"
                  : "research-ethics-approval"
        }-report`;

        let queryUrl =
            getSettings().mainSiteBaseUrl +
            (reportType === CustomerReportType.RegistrationOfResearchProject
                ? getProcessingActivityRegistrationOfResearchProjectReportPDF(props.processingActivityId!)
                : reportType === CustomerReportType.InternalAcquisitionOfSignature
                  ? getProcessingActivityInternalAcquisitionOfSignatureReportPDF(props.processingActivityId!)
                  : getProcessingActivityResearchEthicsApprovalReportReportPDF(props.processingActivityId));

        let url =
            getSettings().apiBaseUrl +
            (reportType === CustomerReportType.ResearchEthicsApproval
                ? "/researchEthicsApprovalReport/" + props.processingActivityId + "/download?url=" + createPdfUrl(queryUrl, language)
                : "/pdf/generate?url=" + createPdfUrl(queryUrl, language));

        await downloadFile(url, `${fileName}.${reportType === CustomerReportType.ResearchEthicsApproval ? "zip" : "pdf"}`).then((x) => {
            snackBar.show(createElement(Trans, { i18nKey: "pdfDownloadComplete", values: { fileName: fileName } }));
            setIsDownloadingPdf(false);
        });
    }

    function save() {
        return put(`/ProcessingActivity/${props.processingActivityId}/status/`, ProcessingActivityStatus.DraftSubmitted);
    }

    const saveMutation = useMutation(save);
    function submitReport() {
        saveMutation.mutateAsync(undefined, {
            onSuccess: () => {
                snackBar.show(createElement(Trans, { i18nKey: "reportSubmitted", values: { name: props.processingActivityName } }));
                props.onSubmitReport();
                props.onDialogClose();
            },
        });
    }

    return {
        onDownloadPdfClick,
        isDownloadingPdf,
        submitReport,
        submitInProgress: saveMutation.isLoading,
        reportType,
        setReportType,
    };
}
