import React from "react";
import { useSystemDataSubjects } from "./DataSubjects.hooks";
import DotLegalStepHeader from "../../../../common/components/dotLegalStepHeader/DotLegalStepHeader";
import { useTranslation } from "../../../../localization/useTranslation";
import { DotLegalHeader, DotLegalMultiSelect } from "@dotlegal/dotlegal-ui-components";
import { Box } from "@mui/material";
import { SystemModel } from "../../SystemEdit.types";
import { useUserContext } from "../../../../auth/userContextProvider/UserContextProvider";

export interface DataSubjectsProps {
    systemViewModel: SystemModel | undefined;
    isLoading: boolean;
    systemId: string;
}

function DataSubjects(props: DataSubjectsProps) {
    const { translateString } = useTranslation();
    const { isLoading, data, addDataSubject, removeDataSubject, systemDataSubjects, processingActivityDataSubjects } = useSystemDataSubjects(props);
    const { permissions } = useUserContext();

    return (
        <>
            <DotLegalStepHeader record={false} topMargin>
                {translateString("chooseDataSubjectsForSystem")}
            </DotLegalStepHeader>

            <DotLegalMultiSelect
                disabled={!permissions.systemPersonalDataPermissions?.permissions.edit}
                options={data?.filter((x) => processingActivityDataSubjects.indexOf(x.id) === -1)} // removes option chosen via a ProcessingActivity
                isLoading={isLoading || props.isLoading}
                chosenOptions={systemDataSubjects}
                onChange={(v) => {
                    if (v?.length > systemDataSubjects.length) {
                        const addedId = v.find((x) => systemDataSubjects.indexOf(x) === -1);
                        addDataSubject(addedId!);
                    } else {
                        const removedId = systemDataSubjects.find((x) => v.indexOf(x) === -1);
                        removeDataSubject(removedId!);
                    }
                }}
                placeholder={translateString("dataSubjects")}
                label={translateString("dataSubjects")}
                noOptionsLabel={translateString("noOptions")}
            />

            {processingActivityDataSubjects.length > 0 && (
                <React.Fragment>
                    <DotLegalStepHeader record={false} topMargin>
                        {translateString("dataSubjectsFromProcessingActivity")}
                    </DotLegalStepHeader>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <DotLegalHeader headerStyle="small" fontWeight={300}>
                            {processingActivityDataSubjects
                                ?.map((x, i) => {
                                    return data?.find((s) => s.id === x)?.name ?? "";
                                })
                                .join(", ")}
                        </DotLegalHeader>
                    </Box>
                </React.Fragment>
            )}
        </>
    );
}

export default DataSubjects;
