import { useMutation } from "react-query";
import { put } from "../../common/api/apiShared";
import { ISendInternalAssessmentReminderDialog } from "./SendInternalAssessmentReminderDialog";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { createElement, useState } from "react";
import { Trans } from "react-i18next";

export default function useSendInternalAssessmentReminderDialogHooks(props: ISendInternalAssessmentReminderDialog) {
    const snackbar = useDotLegalSnackbar();
    const [additionalMessage, setAdditionalMessage] = useState<string | undefined>(undefined);

    const sendReminderMutation = useMutation(sendReminderUrl);
    function sendReminderUrl() {
        return put(`/internalAssessments/${props.selectedInternalAssessment!.id}/sendreminder`, additionalMessage);
    }

    const sendReminder = () => {
        sendReminderMutation.mutateAsync(undefined, {
            onSuccess: () => {
                snackbar.show(createElement(Trans, { i18nKey: "reminderSent", values: { respondent: props.selectedInternalAssessment?.assignee.name } }));
                props.onReminderSent();
            },
        });
    };

    return { sendReminder, isSending: sendReminderMutation.isLoading, additionalMessage, setAdditionalMessage };
}
