import React from "react";
import { SystemModel } from "../../SystemEdit.types";
import { useSystemDataCategories } from "./DataCategories.hooks";
import { useTranslation } from "../../../../localization/useTranslation";
import DotLegalStepHeader from "../../../../common/components/dotLegalStepHeader/DotLegalStepHeader";
import DataCategoryPicker from "../../../../processingActivity/DataCategoriesStep/dataCategoryPicker/DataCategoryPicker";
import { DataCategoryViewModel } from "../../../../processingActivity/DataCategoriesStep/DataCategoriesStep.types";
import ProcessingActivityOverviewDialog from "../../../../processingActivity/processingActivityOverviewDialog/ProcessingActivityOverviewDialog";
import { useUserContext } from "../../../../auth/userContextProvider/UserContextProvider";

export interface DataCategoriesProps {
    systemViewModel: SystemModel | undefined;
    isLoading: boolean;
    systemId: string;
}

function DataCategories(props: DataCategoriesProps) {
    const { isLoading, allDataCategories, dataCategories, handleChange, showDataCategoryDialog, setShowDataCategoryDialog } = useSystemDataCategories(props);
    const { translateString } = useTranslation();
    const { permissions } = useUserContext();

    let dataCategoriesData: Array<DataCategoryViewModel> = [];
    if (allDataCategories) {
        dataCategoriesData = allDataCategories!.map((d) => {
            const foundDataCategory = dataCategories.find((x) => x.dataCategoryId === d.id);
            return {
                name: d.name,
                id: d.id,
                description: d.description,
                classification: d.classification,
                isSelected: foundDataCategory ? true : false,
                disabled: foundDataCategory && !foundDataCategory?.isSystemSelected,
                dataTypeIds: d.dataTypeIds,
                selectedDataTypeIds: [],
            };
        });
    }

    return (
        <>
            <DotLegalStepHeader subHeader={translateString("dataCategoriesStepHeaderSecond")} bottomMargin>
                {translateString("dataCategoriesOnAssetsHeader")}
            </DotLegalStepHeader>

            <DataCategoryPicker
                disableDataTypeSelection
                isLoading={isLoading || props.isLoading}
                readOnly={!permissions.systemPersonalDataPermissions?.permissions.edit}
                allDataCategories={dataCategoriesData}
                onDataCategoryClick={handleChange}
                disabledTooltip={translateString("dataCategoryAssociatedWithProcessingActivity")}
                onDisabledTooltipClick={setShowDataCategoryDialog}
            />

            {showDataCategoryDialog && (
                <ProcessingActivityOverviewDialog
                    onCloseDialog={() => setShowDataCategoryDialog(undefined)}
                    processingActivities={dataCategories.find((x) => x.dataCategoryId === showDataCategoryDialog)?.processingActivities}
                    header={translateString("systemProcessingsDialogDescription")}
                    numberOfProcessingWithoutaccess={dataCategories.find((x) => x.dataCategoryId === showDataCategoryDialog)?.processingActivitiesWithoutAccess}
                />
            )}
        </>
    );
}

export default DataCategories;
