export interface DocumentVersionViewModel {
    fileName: string;
    version: number;
    documentId: string;
    documentVersionId: string;
    dataId: string;
    link: string;
}

export class LegalEntityDocumentSaveModel {
    fileName?: string;
    link?: string;
    base64Data?: string;

    constructor() {
        this.link = "";
    }
}

export class DocumentLink {
    link: string;

    constructor(link: string) {
        this.link = link;
    }
}
