import React from "react";
import DotLegalDeleteDialogWrapper from "../../../common/components/dotLegalDeleteDialog/DotLegalDeleteDialogWrapper";
import { DeleteNationalLawDialogDataMapping } from "./DeleteNationalLawDialog.hooks";
import { NationalLawViewModel } from "../nationalLaws/NationalLaws.types";

export interface IDeleteNationalLawDialog {
    onCloseDialog: () => void;
    onSaveCorrect: () => void;
    selectedNationalLaw: NationalLawViewModel;
}

export function DeleteNationalLawDialog(props: IDeleteNationalLawDialog) {
    const { removeNationalLaw, inProgess } = DeleteNationalLawDialogDataMapping(props);

    return (
        <DotLegalDeleteDialogWrapper
            open
            itemForDeletion={props.selectedNationalLaw.name}
            onDialogClose={props.onCloseDialog}
            onOkClick={removeNationalLaw}
            inProgress={inProgess}
        />
    );
}
