import { createElement } from "react";
import { Trans } from "react-i18next";
import { useMutation } from "react-query";
import { deleteHttp } from "../../common/api/apiShared";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { TrackingEvent, useTrackAIEvent } from "../../processingActivity/hooks/useTracking";
import { IDeleteCustomListDialog } from "./DeleteCustomListDialog";

export function useDeleteCustomListDialogHook(props: IDeleteCustomListDialog) {
    const snackbar = useDotLegalSnackbar();
    const removeMutation = useMutation(remove);
    const trackEvent = useTrackAIEvent();

    const removeTask = () => {
        removeMutation.mutateAsync(undefined, {
            onSuccess: () => {
                trackEvent(TrackingEvent.CustomListDeleted, { customList: props.id });
                props.onDeleteClick();
                snackbar.show(createElement(Trans, { i18nKey: "dataDeleted", values: { name: props.name } }));
            },
        });
    };

    async function remove() {
        return await deleteHttp<string>(`/CustomLists/${props.id}`);
    }

    return {
        removeTask,
        inProgress: removeMutation.isLoading,
    };
}
