import { useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";

export const useStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        selectContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        subText: {
            fontSize: theme.typography.pxToRem(11),
            fontStyle: "italic",
            color: theme.palette.primary.main,
        },
    });
};
