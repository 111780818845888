import React from "react";
import { SeverityLevelEnum } from "../../../incidentLog/IncidentLogDialog.types";
import { useRiskIndicator } from "../../../riskAssessment/riskIndicator/RiskIndicator.hooks";
import { exhaustiveGuard } from "../../utilities";
import { Box } from "@mui/material";
import { useTranslation } from "../../../localization/useTranslation";
import { setFirstLetterToLowerCase } from "../../stringOperations";

export interface IncidentLogRiskProps {
    severityLevel: SeverityLevelEnum;
}

function IncidentLogRisk(props: IncidentLogRiskProps) {
    const { getRiskIcon } = useRiskIndicator();
    const { translateString } = useTranslation();

    const getIcon = () => {
        switch (props.severityLevel) {
            case SeverityLevelEnum.VeryLow:
                return getRiskIcon(1);
            case SeverityLevelEnum.Low:
                return getRiskIcon(2);
            case SeverityLevelEnum.Moderate:
                return getRiskIcon(3);
            case SeverityLevelEnum.High:
                return getRiskIcon(4);
            case SeverityLevelEnum.VeryHigh:
                return getRiskIcon(5);
            default:
                exhaustiveGuard(props.severityLevel);
        }
    };

    return (
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            {getIcon()} {translateString(setFirstLetterToLowerCase(SeverityLevelEnum[props.severityLevel].toString()))}
        </Box>
    );
}

export default IncidentLogRisk;
