import { useIsOnMediumScreen } from "@dotlegal/dotlegal-ui-components";
import { useTheme } from "@mui/material";
import { createSxStyles } from "../../../../common/createSxStyles";

export const useTwoColumnContainerStyles = () => {
    const theme = useTheme();
    const isOnMediumScreen = useIsOnMediumScreen();
    const paddingLeftRight = isOnMediumScreen ? 3 : 18;
    return createSxStyles({
        grid: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
        },
        commonContainer: {
            paddingLeft: paddingLeftRight,
            paddingRight: paddingLeftRight,
            flex: 1,
        },
        whiteArrow: {
            "& svg": {
                fill: theme.palette.common.white,
            },
        },
        rightBtnArrow: {
            "& .MuiButton-root": {
                "&:hover": {
                    "& svg": {
                        transform: "translateX(10px)",
                        transition: "0.2s",
                    },
                },
            },
        },
    });
};
