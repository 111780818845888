import { Box, Typography } from "@mui/material";
import React from "react";
import { DotLegalButton } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../localization/useTranslation";
import { useErrorStyles } from "./ErrorPage.styles";
import { useUrlProvider } from "../useUrlProvider";
import DotLegalPaper from "../common/components/dotLegalPaper/DotLegalPaper";
import { Trans } from "react-i18next";
import ErrorNotFound from "./error404.png";
import NoAccess from "./NoAccess.svg?url";
import TechnicalError from "./TechnicalError.svg?url";

export const customerSupportNumber = "+45 7027 0127";
const customerSupportEmail = "support@dotlegal.com";

export interface IErrorPageProps {
    errorMessage: string;
    image: "Technical" | "NotFound" | "NoAccess";
}

const redirectToHome = () => {
    window.location.href = "/";
};

function ErrorPage(props: IErrorPageProps) {
    const styles = useErrorStyles();
    const { translateString } = useTranslation();
    const urlProvider = useUrlProvider();

    const getErrorImage = () => {
        switch (props.image) {
            case "NoAccess":
                return NoAccess;
            case "Technical":
                return TechnicalError;
            case "NotFound":
                return ErrorNotFound;
            default:
                return ErrorNotFound;
        }
    };

    return (
        <DotLegalPaper fullHeight>
            <Box
                sx={{
                    display: "flex",
                    height: "100%",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: 3,
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Box component={"img"} width={350} height={328} src={getErrorImage()} alt={""} />
                </Box>
                <Typography sx={styles.errorText}>{props.errorMessage}</Typography>
                <Box sx={styles.subContainer}>
                    <Typography sx={styles.errorTextContact}>
                        <Trans
                            i18nKey={"contactSupport"}
                            components={{
                                email: <a href="mailto:support@dotlegal.com">{customerSupportEmail}</a>,
                                phone: <a href="tel:+45 7027 0127">{customerSupportNumber}</a>,
                            }}
                        />
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center", marginBottom: 10 }}>
                    <Box sx={styles.subContainer}>
                        <DotLegalButton onClick={redirectToHome} size="large" buttonType="primary">
                            {translateString("frontPage")}
                        </DotLegalButton>
                    </Box>
                    <Box sx={styles.subContainer}>
                        <DotLegalButton onClick={() => (window.location.href = urlProvider.getSignOutUrl())} size="large" buttonType="secondary">
                            {translateString("signOut")}
                        </DotLegalButton>
                    </Box>
                </Box>
            </Box>
        </DotLegalPaper>
    );
}

export default ErrorPage;
