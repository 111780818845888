import { useTheme } from "@mui/material";
import { createSxStyles } from "../../createSxStyles";

export const useDotLegalCreatePaper = () => {
    const theme = useTheme();
    let height = 116;
    return createSxStyles({
        paperHeader: {
            display: "flex",
            alignItems: "center",
            height: height,
            marginBottom: theme.spacing(2),
            position: "relative",
        },
        headerImg: {
            position: "absolute",
            bottom: 0 + "px",
            right: "15%",
            height: height,
        },
    });
};
