import { createElement, useState } from "react";
import { useMutation } from "react-query";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { post, put } from "../../../common/api/apiShared";
import { isNullOrWhitespace } from "../../../common/stringOperations";
import { Result } from "../../../common/api/result";
import { IAddEditDataSubjectDialogProps } from "./AddEditDataSubjectDialog";
import { DataSubjectSaveModel } from "../DataSubject.types";
import { Trans } from "react-i18next";

export function useAddEditDataSubjectDataMapping(props: IAddEditDataSubjectDialogProps) {
    const snackbar = useDotLegalSnackbar();
    const { translateString } = useTranslation();
    const [errors, setErrors] = useState({ dataSubjectNameError: "" });
    const [inProgress, setInProgress] = useState(false);
    const [viewModel, setViewModel] = useState<DataSubjectSaveModel | undefined>(props.selectedDataSubject);

    const saveMutation = useMutation(save, {
        onSuccess: (response: Result<string>) => {
            if (response.success()) {
                props.onHideDialog();
                props.onSave(response.value(), viewModel!.name);
                setInProgress(false);
                setViewModel(undefined);
                snackbar.show(createElement(Trans, { i18nKey: "dataSubjectCreated", values: { name: viewModel?.name } }));
            } else {
                setInProgress(false);
                snackbar.show(translateString("dataSubjectExist"), "warning");
            }
        },
    });

    const createDataSubject = async () => {
        await saveMutation.mutateAsync();
    };

    function save() {
        return post<string>("/datasubjects/", viewModel);
    }

    const editMutation = useMutation(update);
    const updateUser = () => {
        editMutation.mutateAsync(viewModel!.id, {
            onSuccess: (response: Result<string>) => {
                if (response.success()) {
                    snackbar.show(translateString("dataSubjectUpdated", { name: viewModel!.name }));
                    props.onHideDialog();
                    props.onSave("", "");
                } else {
                    setInProgress(false);
                    snackbar.show(translateString("dataSubjectExist"), "warning");
                }
            },
        });
    };

    function update(id: string) {
        return put<string>("/datasubjects/", viewModel);
    }

    return {
        inProgress,
        errors,
        viewModel,
        setViewModel,
        validateDataSubjectForm,
        validateDataSubjectName,
    };

    function validateDataSubjectName(name: string) {
        let tempErrors = { ...errors };
        tempErrors.dataSubjectNameError = isNullOrWhitespace(name) ? translateString("fieldMandatory") : "";
        setErrors(tempErrors);
    }

    function validateDataSubjectForm(name: string) {
        let formValid = true;
        let tempErrors = { ...errors };

        if (isNullOrWhitespace(name)) {
            tempErrors.dataSubjectNameError = translateString("fieldMandatory");
            formValid = false;
        }

        if (formValid) {
            setInProgress(true);

            if (props.isAdd) createDataSubject();
            else updateUser();
        } else {
            setErrors(tempErrors);
        }
    }
}
