import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { downloadFile } from "../../api/downloadFile";
import { createElement } from "react";
import { Trans } from "react-i18next";

export function useDotLegalFileUploadDataMapping() {
    const snackBar = useDotLegalSnackbar();

    async function downloadDocument(documentId: string, fileName: string) {
        let url = `/document/${documentId}/data`;

        snackBar.show(createElement(Trans, { i18nKey: "documentIsBeingDownloaded", values: { document: fileName } }));
        await downloadFile(url, fileName);
    }

    async function downloadDocumentVersion(documentId: string, dataId: string, fileName: string) {
        let url = `/document/${documentId}/${dataId}/data`;

        snackBar.show(createElement(Trans, { i18nKey: "documentIsBeingDownloaded", values: { document: fileName } }));
        await downloadFile(url, fileName);
    }

    async function addTaskToCalendar(taskId: string, taskName: string) {
        let url = `/task/${taskId}/addToCalendar`;

        await downloadFile(url, taskName + ".ics");
    }

    return {
        downloadDocument,
        addTaskToCalendar,
        downloadDocumentVersion,
    };
}
