import { Trans } from "react-i18next";
import { TaskRawStateEnum } from "../../../annualWheel/AnnualWheel.types";
import { AnnualWheelActivityType, AnnualWheelPriority } from "../../../annualWheel/annualWheelDialog/AnnualWheelDialog.types";
import { DotLegalSelectOption } from "../../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { getEnumValues } from "../../../common/enumOperations";
import { setFirstLetterToLowerCase } from "../../../common/stringOperations";
import { useTranslation } from "../../../localization/useTranslation";
import { MasterDataSectionProps } from "./MasterDataSection";
import { createElement, useEffect } from "react";
import { useQuery } from "react-query";
import { get, put } from "../../../common/api/apiShared";
import { SelectableColoredItem } from "../../../processingActivity/ProcessingActivity.types";
import { useResponsibleQuery } from "../../../user/hooks/useResponsibleQuery";
import { TaskMasterDataViewModel } from "./MasterDataSection.types";
import { useOptimisticUpdate } from "../../../common/hooks/useOptimisticUpdate";
import { TaskSaveModel } from "../EditTaskDialog.types";
import { TrackingEvent, useTrackAIEvent } from "../../../processingActivity/hooks/useTracking";
import { isApprovalRequired } from "../../../annualWheel/ApprovalOptions";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";

export function useMasterDataSection(props: MasterDataSectionProps) {
    const isParentFinishedLoading = !props.isLoading;
    const optimisticUpdate = useOptimisticUpdate();
    const trackEvent = useTrackAIEvent();
    const { translateString } = useTranslation();
    const { userProfileId } = useUserContext();

    const taskMasterDataUrl = `/task/${props.taskId}/masterdata`;
    const { isLoading, data, isSuccess } = useQuery(taskMasterDataUrl, () => get<TaskMasterDataViewModel>(taskMasterDataUrl), {
        enabled: isParentFinishedLoading,
    });
    let businessAreaQuery = useQuery("selectableBusinessAreas", () => get<Array<SelectableColoredItem>>("/BusinessAreas/businessareas"), {
        enabled: isParentFinishedLoading,
    });
    let responsibleQuery = useResponsibleQuery(isParentFinishedLoading);

    let completedAllowed: string | JSX.Element | undefined = undefined;
    let options = new Array<DotLegalSelectOption>();

    useEffect(() => {
        if (props.passIsDoneLoading && isSuccess) {
            props.passIsDoneLoading(true);
        }
    }, [props, isSuccess]);

    if (data) {
        options.push({ name: translateString("ready"), id: TaskRawStateEnum.Ready.toString() });
        options.push({ name: translateString("inProgress"), id: TaskRawStateEnum.InProgress.toString() });

        if (
            isApprovalRequired(data.approval) &&
            (data.responsible !== userProfileId || data!.taskState === TaskRawStateEnum.ReadyForApproval) &&
            data!.taskState !== TaskRawStateEnum.Completed
        ) {
            options.push({ name: translateString("readyForApproval"), id: TaskRawStateEnum.ReadyForApproval.toString() });
        }

        if (canShowCompletedForProcessingActivityTask() && canCompleteTaskWithRequiredDocumentation() && canCompleteTaskWithRequiredWithResponsible()) {
            options.push({ name: translateString("completed"), id: TaskRawStateEnum.Completed.toString() });
        }

        if (props.masterData!.type === AnnualWheelActivityType.ProcessingActivityValidation && data!.taskState !== TaskRawStateEnum.Completed)
            completedAllowed = createElement(Trans, { i18nKey: "processingActivityTaskValidationFailed" });
        else if (isApprovalRequired(data.approval) && data!.taskState !== TaskRawStateEnum.Completed) {
            completedAllowed = createElement(Trans, { i18nKey: "approvalRequiredByResponsibleBeforeCompletion", values: { taskName: props.masterData?.name } });
        }
    }

    let responsiblesData;
    let assigneesData;
    if (responsibleQuery.userData && data) {
        responsiblesData = responsibleQuery
            .data(data.responsible)
            ?.filter((x) => !data.assignees.includes(x.id) || data.assignees.includes(data.responsible))
            .map((d) => {
                return d;
            });

        assigneesData = responsibleQuery
            .dataForMultiSelect(data.assignees)
            ?.filter((x) => x.id !== data.responsible || data.assignees.includes(data.responsible))
            .map((d) => {
                return d;
            });
    }

    const priorityOptions = () => {
        return getEnumValues(AnnualWheelPriority).map((x) => {
            var name = translateString(setFirstLetterToLowerCase(AnnualWheelPriority[x].toString()));
            return { name: name, id: x.toString() };
        });
    };

    function showAddToCalenderLink() {
        return props.masterData!.isOverdue === false && data!.taskState !== TaskRawStateEnum.Completed && props.masterData?.isSeparateTask === false;
    }

    const onResponsibleChange = async (responsible: string | null) => {
        let model = { ...data! };
        model.responsible = responsible ?? "";
        await onTaskChange(model);
    };

    const onAssigneesChange = async (assignees: Array<string>) => {
        let model = { ...data! };
        model.assignees = assignees;
        await onTaskChange(model);
    };

    const onBusinessAreaChange = async (businessAreas: Array<string>) => {
        let model = { ...data! };
        model.businessAreas = businessAreas;
        await onTaskChange(model);
    };

    const onPriorityChange = async (priority: string | null) => {
        let model = { ...data! };
        model.priority = Number(priority);
        await onTaskChange(model);
    };

    const onProgressChange = async (state: string | null) => {
        let model = { ...data! };
        model.taskState = Number(state);
        await onTaskChange(model, false);
    };

    async function onTaskChange(viewModel: TaskMasterDataViewModel, updateTaskState: boolean = true) {
        await optimisticUpdate.putOnQueueAndSetQueryData(
            viewModel,
            taskMasterDataUrl,
            updateTask,
            mapToSaveModel(viewModel!, props.masterData!.isSeparateTask, updateTaskState)
        );

        trackEvent(TrackingEvent.AnnualWheelActivityTaskUpdated, { taskId: data?.id });
    }

    return {
        options,
        completedAllowed,
        data,
        responsiblesData,
        assigneesData,
        businessAreaData: businessAreaQuery.data,
        isSectionLoading: isLoading || businessAreaQuery.isLoading || responsibleQuery.isLoading || props.isLoading,
        showAddToCalenderLink,
        priorityOptions,
        onResponsibleChange,
        onBusinessAreaChange,
        onPriorityChange,
        onProgressChange,
        onAssigneesChange,
    };

    function mapToSaveModel(viewModel: TaskMasterDataViewModel, isSeparateTask: boolean, updateState: boolean) {
        const currentState = viewModel.taskState;
        return {
            businessAreas: viewModel.businessAreas,
            priority: viewModel.priority,
            responsible: viewModel.responsible,
            state: updateState ? (viewModel.taskState === TaskRawStateEnum.Ready ? TaskRawStateEnum.InProgress : currentState) : currentState,
            isSeparateTask: isSeparateTask,
            assignees: viewModel.assignees,
        };
    }

    async function updateTask(saveModel: TaskSaveModel) {
        return await put<TaskSaveModel>("/Task/" + props.taskId, saveModel);
    }

    function canShowCompletedForProcessingActivityTask() {
        return (
            props.masterData!.type !== AnnualWheelActivityType.ProcessingActivityValidation ||
            (props.masterData!.type === AnnualWheelActivityType.ProcessingActivityValidation && data!.taskState === TaskRawStateEnum.Completed)
        );
    }

    function canCompleteTaskWithRequiredDocumentation() {
        return props.masterData!.documentationRequired === false || (props.masterData!.documentationRequired && props.masterData!.hasDocument);
    }

    function canCompleteTaskWithRequiredWithResponsible() {
        return (
            !isApprovalRequired(data?.approval) ||
            (isApprovalRequired(data?.approval) && data?.responsible === userProfileId) ||
            data?.taskState === TaskRawStateEnum.Completed
        );
    }
}
