import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Box, styled, useTheme } from "@mui/material";
import { HashLink } from "react-router-hash-link";
import { RefererState, ToDto, useHistoryWithReferer } from "@dotlegal/dotlegal-ui-components";

export interface IDotLegalLinkProps {
    to: ToDto | string;
    noUnderlineOnHover?: boolean;
    children: React.ReactNode;
    linkColor?: "primary" | "primary-dark" | "secondary";
    bold?: boolean;
    stopPropagation?: boolean;
}

function DotLegalLink(props: IDotLegalLinkProps) {
    const location = useLocation();
    const historyWithReferer = useHistoryWithReferer();
    const theme = useTheme();

    const getColor = () => {
        if (props.linkColor) {
            return props.linkColor === "primary"
                ? theme.palette.primary.main
                : props.linkColor === "primary-dark"
                  ? theme.palette.primary.dark
                  : theme.palette.secondary.main;
        }
        return "inherit";
    };

    let styles = {
        color: `${getColor()} !important`,
        textDecoration: "none !important",
        fontWeight: props.bold ? "bold" : undefined,
        "&:hover": {
            textDecoration: props.noUnderlineOnHover ? "none !important" : "underline !important",
        },
    };

    let state: RefererState = {
        refererPath: location.pathname,
        refererSearch: location.search,
    };

    const urlWithReferer = historyWithReferer.addSearchIfReferer(props.to);

    const toIsTypeOfString = typeof props.to === "string";

    return (
        <>
            {toIsTypeOfString && (props.to as String).startsWith("https") ? (
                <Box component={"a"} sx={styles} href={props.to as string} target="_blank" rel={"noopener noreferrer"}>
                    {props.children}
                </Box>
            ) : (
                <Box
                    component={"span"}
                    sx={{
                        "& a": {
                            color: `${getColor()} !important`,
                            textDecoration: "none !important",
                            fontWeight: props.bold ? "bold" : undefined,
                            "&:hover": {
                                textDecoration: props.noUnderlineOnHover ? "none !important" : "underline !important",
                            },
                        },
                    }}
                >
                    {!!urlWithReferer.hash ? (
                        <HashLink smooth to={urlWithReferer} state={state} onClick={(e) => (props.stopPropagation ? e.stopPropagation() : undefined)}>
                            {props.children}
                        </HashLink>
                    ) : (
                        <Link to={urlWithReferer} state={state} onClick={(e) => (props.stopPropagation ? e.stopPropagation() : undefined)}>
                            {props.children}
                        </Link>
                    )}
                </Box>
            )}
        </>
    );
}

export default DotLegalLink;
