import { useMutation, useQueryClient } from "react-query";
import { put } from "../../../common/api/apiShared";
import { ITransferResponsibilityDialog } from "./TransferResponsibilityDialog";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";

function useTransferResponsibilityDialogHooks(props: ITransferResponsibilityDialog) {
    const snackbar = useDotLegalSnackbar();
    const { translateString } = useTranslation();
    const queryClient = useQueryClient();

    function update() {
        return put<string>(`/ProcessingActivity/${props.processingActivityId}/transferReponsibility/`, {
            fromUser: props.fromUser.id,
            toUser: props.toUser.id,
        });
    }
    const editMutation = useMutation(update);

    const transferResponsibility = () => {
        editMutation.mutateAsync(undefined, {
            onSuccess: (data: any) => {
                const processingActivity = data.value();
                queryClient.setQueryData("processingActivity" + props.processingActivityId, processingActivity);
                props.onCloseDialog();
                snackbar.show(translateString("responsibilityTransfered"));
            },
        });
    };

    return {
        transferResponsibility,
        isSaving: editMutation.isLoading,
    };
}

export default useTransferResponsibilityDialogHooks;
