import { ColorPicker } from "@dotlegal/dotlegal-ui-components";
import { LegalEntityAuditStatus } from "../../../legalEntity/legalEntityAuditTab/LegalEntityAudit.types";
import { RiskLevel } from "../../../legalEntity/legalEntityClassificationTab/LegalEntityClassificationTab.types";

export interface ManagementReportChartViewModel {
    averageScore: number | undefined;
    averageStatus: LegalEntityAuditStatus;
    averageScoreName: string | undefined;
    averageRiskLevel: RiskLevel | undefined;
}

export enum SectionColor {
    Purple = 1,
    WildBlueYonder = 2,
    DarkBlue = 3,
    BrightTeal = 4,
    BlueViolet = 5,
    JordyBlue = 6,
    Mauve = 7,
    Cranberry = 8,
    RichBlue = 9,
    Pink = 10,
    Turquoise = 11,
    Orange = 12,
}

export const sectionColors: Array<ColorPicker> = [
    { colorId: SectionColor.Purple.toString(), backgroundColor: "#7284FA", strokeColor: "#546af7", isDefaultColor: true },
    { colorId: SectionColor.WildBlueYonder.toString(), backgroundColor: "#8694C0", strokeColor: "#677dbf" },
    { colorId: SectionColor.DarkBlue.toString(), backgroundColor: "#182975", strokeColor: "#01156e" },
    { colorId: SectionColor.BrightTeal.toString(), backgroundColor: "#2CD5C4", strokeColor: "#159e90" },
    { colorId: SectionColor.BlueViolet.toString(), backgroundColor: "#6053C1", strokeColor: "#332a7a" },
    { colorId: SectionColor.JordyBlue.toString(), backgroundColor: "#73AEF2", strokeColor: "#3a6699" },
    { colorId: SectionColor.Mauve.toString(), backgroundColor: "#CB90FF", strokeColor: "#8d59ba" },
    { colorId: SectionColor.Cranberry.toString(), backgroundColor: "#D54678", strokeColor: "#78203f" },
    { colorId: SectionColor.RichBlue.toString(), backgroundColor: "#5C61A3", strokeColor: "#31345e" },
    { colorId: SectionColor.Pink.toString(), backgroundColor: "#EDAFC8", strokeColor: "#CF99AE" },
    { colorId: SectionColor.Turquoise.toString(), backgroundColor: "#31BBC1", strokeColor: "#2BA5AA" },
    { colorId: SectionColor.Orange.toString(), backgroundColor: "#FFB97C", strokeColor: "#E1A36D" },
];

export function getSectionBackgroundColor(color: SectionColor) {
    return sectionColors.find((x) => x.colorId === color.toString())!.backgroundColor;
}
