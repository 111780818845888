import React from "react";
import { EvaluationStatus } from "../evaluateQuestionnaireDialog/EvaluateQuestionnaireDialog.types";
import { Box } from "@mui/material";
import { notSatisfied, partlySatisfied, satisfied } from "../../../../common/icons";
import { exhaustiveGuard } from "../../../utilities";
import { useTranslation } from "../../../../localization/useTranslation";
import { setFirstLetterToLowerCase } from "../../../stringOperations";
import { DotLegalTooltip } from "@dotlegal/dotlegal-ui-components";

export interface IQuestionnaireEvaluation {
    evaluationStatus: EvaluationStatus | undefined;
    evaluation: string | undefined;
}

function QuestionnaireEvaluation(props: IQuestionnaireEvaluation) {
    const { translateString } = useTranslation();

    function getIcon() {
        const evaluationStatus = props.evaluationStatus!;

        switch (evaluationStatus) {
            case EvaluationStatus.NotSatisfied:
                return notSatisfied;
            case EvaluationStatus.PartlySatisfied:
                return partlySatisfied;
            case EvaluationStatus.Satisfied:
                return satisfied;
            default:
                exhaustiveGuard(evaluationStatus);
        }
    }

    function getContent() {
        if (props.evaluationStatus) {
            const content = (
                <Box sx={{ display: "flex", alignItems: "center", gap: 1, marginTop: 0.3 }}>
                    <Box
                        sx={{
                            "& svg": {
                                width: 20,
                                height: 20,
                                marginTop: 0.3,
                            },
                        }}
                    >
                        {getIcon()}
                    </Box>
                    <Box>{translateString(setFirstLetterToLowerCase(EvaluationStatus[props.evaluationStatus].toString()))}</Box>
                </Box>
            );

            if (props.evaluation) {
                return <DotLegalTooltip text={props.evaluation}>{content}</DotLegalTooltip>;
            }

            return content;
        }

        return <div></div>;
    }

    return getContent();
}

export default QuestionnaireEvaluation;
