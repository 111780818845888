import { createSxStyles } from "../../../createSxStyles";

export const useQuestionnaireRiskStyles = () => {
    return createSxStyles({
        risk: {
            display: "flex",
            alignItems: "center",
            gap: 1,
        },
        icon: {
            width: 20,
        },
    });
};
