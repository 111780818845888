import { DotLegalDatePicker, DotLegalDialog, DotLegalSelect, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { arrow, leftWhiteArrowButton } from "../../common/icons";
import GroupEntityStep from "../../legalEntity/legalEntityAuditTab/groupEntityStep/GroupEntityStep";
import { Box } from "@mui/material";
import { apiUrls } from "../../common/api/apiUrls";
import DotLegalSelectApi from "../../common/components/dotLegalSelectApi/DotLegalSelectApi";
import { QuestionnaireSelectableItem } from "../../legalEntity/legalEntityAuditTab/LegalEntityAudit.types";
import useCreateLegalEntityAuditGroupDialogHooks from "./CreateLegalEntityAuditGroupDialog.hooks";
import { LegalEntityAuditGroupSaveModel } from "./CreateLegalEntityAuditGroupDialog.types";
import TrialUsageWarning from "../../common/components/TrialUsageWarning";
import { useTrialInformationHook } from "../../trial/TrialInformation.hooks";

export interface ICreateAuditGroupDialog {
    auditGroupId?: string;
    auditGroup?: LegalEntityAuditGroupSaveModel;
    auditGroupInProgress?: boolean;
    onDialogClose: () => void;
    onAuditGroupUpdated?: () => void;
}

function CreateLegalEntityAuditGroupDialog(props: ICreateAuditGroupDialog) {
    const { translateString, getDateTimePickerLanguage } = useTranslation();
    const {
        saveModel,
        selectableGroupEntitiesData,
        auditUpdater,
        step,
        showSelectedGroupEntityStep,
        validation,
        selectableResponsiblesData,
        selectableresponsiblesLoading,
        disableOkButton,
        onNextOrSaveClick,
        onPreviousButtonClick,
        isSaving,
        isEdit,
    } = useCreateLegalEntityAuditGroupDialogHooks(props);
    const { hasActiveVendorManagementTrial } = useTrialInformationHook();

    function getDialogContent() {
        if (step === "formData") {
            return formStep;
        }

        return (
            <GroupEntityStep
                showGdpoIfAvailable
                onGroupEntityChanged={auditUpdater.onGroupEntityChanged}
                groupEntityId={saveModel.groupEntityId}
                errormsg={validation.getErrorsForDisplay("groupEntityId")}
                selectableGroupEntitiesData={selectableGroupEntitiesData}
                header={translateString("auditGroupGroupEntityHeader")}
                infoTranslationKey={"auditGroupGroupEntity"}
                checkboxTranslationKey={"auditGroupEntireGroup"}
                checkboxTooltip={translateString("auditGroupGroupEntityTooltip")}
            />
        );
    }

    function getButtonText() {
        if (props.auditGroupInProgress) {
            return translateString("save");
        }

        if (step === "formData") {
            return showSelectedGroupEntityStep ? translateString("next") : isEdit ? translateString("save") : translateString("createDraft");
        }

        return isEdit ? translateString("save") : translateString("createDraft");
    }

    const formStep = (
        <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
            <Box sx={{ width: 450 }}>
                <DotLegalTextField
                    errorText={validation.getErrorsForDisplay("name")}
                    label={translateString("name")}
                    value={saveModel.name}
                    debounce={false}
                    onChange={auditUpdater.onAssessmentNameChange}
                    toolTipText={translateString("auditGroupNameTooltip")}
                />

                <DotLegalTextField
                    label={translateString("description")}
                    onChange={auditUpdater.onDescriptionChange}
                    multiline
                    rows={3}
                    value={saveModel.description ?? null}
                    debounce={false}
                    toolTipText={translateString("auditGroupDescriptionTooltip")}
                />

                {!props.auditGroupInProgress && (
                    <DotLegalSelectApi<QuestionnaireSelectableItem>
                        url={apiUrls().questionnaires.selectableItems}
                        onChange={auditUpdater.onQuestionnaireChange}
                        label={translateString("template")}
                        noOptionsLabel={translateString("noOptions")}
                        selectedItem={saveModel.questionnaireId}
                        toolTipText={translateString("auditGroupTemplateTooltip")}
                        errorText={validation.getErrorsForDisplay("questionnaireId")}
                    />
                )}

                <DotLegalSelect
                    selectedItem={saveModel.responsibleId}
                    options={selectableResponsiblesData}
                    isLoading={selectableresponsiblesLoading}
                    onChange={auditUpdater.onResponsibleChanged}
                    label={translateString("responsible")}
                    toolTipText={translateString("auditGroupResponsibleTooltip")}
                    noOptionsLabel={translateString("noOptions")}
                    errorText={validation.getErrorsForDisplay("responsibleId")}
                ></DotLegalSelect>

                <DotLegalDatePicker
                    label={translateString("deadline")}
                    value={saveModel.deadline ?? null}
                    onChange={auditUpdater.onDateChanged}
                    language={getDateTimePickerLanguage()}
                    toolTipText={translateString("auditGroupDeadlineTooltip")}
                    error={!!validation.getErrorsForDisplay("deadline")}
                    helperMessage={validation.getErrorsForDisplay("deadline")}
                />
            </Box>
            {hasActiveVendorManagementTrial && <TrialUsageWarning textKey={"createAuditGroupWarning"} marginTop={2} />}
        </Box>
    );

    return (
        <DotLegalDialog
            buttonOkText={getButtonText()}
            size="md"
            header={isEdit || props.auditGroupInProgress ? translateString("editAuditGroup") : translateString("createAuditGroup")}
            open
            buttonOkIcon={arrow}
            previousButtonIcon={leftWhiteArrowButton}
            onDialogClose={props.onDialogClose}
            onOkClick={onNextOrSaveClick}
            okButtonDisabled={disableOkButton}
            previousButtonText={translateString("previous")}
            previousButtonClick={step !== "formData" ? onPreviousButtonClick : undefined}
            buttonRight
            inProgress={isSaving}
        >
            {getDialogContent()}
        </DotLegalDialog>
    );
}

export default CreateLegalEntityAuditGroupDialog;
