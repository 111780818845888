import { useState } from "react";
import { useTranslation } from "../../../localization/useTranslation";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useMutation } from "react-query";
import { put } from "../../../common/api/apiShared";
import { ChangeContactProps } from "./ChangeContact";
import { useLegalEntityContactsQuery } from "../../useLegalEntityContactsQuery";

export function useChangeContact(props: ChangeContactProps) {
    const { translateString } = useTranslation();
    const snackbar = useDotLegalSnackbar();
    const [contact, setContact] = useState(props.contactId);

    const contactQuery = useLegalEntityContactsQuery(props.legalEntityId);

    const changeContactMutation = useMutation(changeContactApi, {
        onSuccess: () => {
            snackbar.show(translateString("contactChanged"));
            props.onChangedContact();
        },
    });

    const onChangeContactClick = () => {
        changeContactMutation.mutateAsync();
    };
    function changeContactApi() {
        return put(`/legalEntityAudit/${props.auditId}/changeContact`, contact);
    }

    return {
        data: contactQuery.data,
        isLoading: contactQuery.isLoading,
        isChanging: changeContactMutation.isLoading,
        contact,
        setContact,
        onChangeContactClick,
    };
}
