import { IconButton, TableCell, TableRow } from "@mui/material";
import React from "react";
import { useTranslation } from "../../../localization/useTranslation";
import { DotLegalTable, ITableHeader } from "@dotlegal/dotlegal-ui-components";
import { downloadIcon, removeIcon } from "../../../common/icons";
import { DocumentViewModel } from "../../../documents/Documents.types";
import { useResearchEthicsApprovalReportDocumentsHooks } from "./ResearchEthicsApprovalReportDocuments.hooks";
import DotLegalFileUploadWrapper from "../../../common/components/dotLegalFileUpload/DotLegalFileUploadWrapper";

export interface IResearchEthicsApprovalReportDocuments {
    processingActivityId: string;
    documents: Array<DocumentViewModel> | undefined;
    refetch: () => void;
    onRemoveDocument: (documentId: string) => void;
}

function ResearchEthicsApprovalReportDocuments(props: IResearchEthicsApprovalReportDocuments) {
    const { translateString } = useTranslation();
    const { downloadDocument, createDocuments, removeDocument, isSaving } = useResearchEthicsApprovalReportDocumentsHooks(props);

    const getHeaders = () => {
        let headers: Array<ITableHeader<DocumentViewModel>> = [];
        headers.push({ property: "fileName", text: translateString("documentation"), align: "left", showOnMobile: true });
        headers.push({ property: "id", text: translateString("download"), align: "left", showOnMobile: true });
        headers.push({ property: "id", text: translateString("remove"), align: "left", showOnMobile: true });

        return headers;
    };

    return (
        <React.Fragment>
            <DotLegalFileUploadWrapper
                onFilesChanged={async (files) => {
                    await createDocuments(files);
                }}
                files={[]}
                isLoading={isSaving}
            />
            <DotLegalTable
                headers={getHeaders()}
                emptyText={translateString("noDocuments")}
                paginationLabelOf={translateString("labelOf")}
                defaultOrderBy={"fileName"}
                defaultOrder={"asc"}
                isLoading={false}
                hidePagination
                textStyle={"NoCapsHeaders"}
                data={props.documents!}
                marginTop={1}
                renderRow={(row) => (
                    <TableRow key={row.id}>
                        <TableCell sx={{ wordBreak: "break-word" }}>{row.fileName}</TableCell>
                        <TableCell width={50}>
                            <IconButton size="small" onClick={() => downloadDocument(row.id, row.fileName)}>
                                {downloadIcon}
                            </IconButton>
                        </TableCell>
                        <TableCell width={50}>
                            <IconButton size="small" onClick={() => removeDocument(row.id)}>
                                {removeIcon}
                            </IconButton>
                        </TableCell>
                    </TableRow>
                )}
            />
        </React.Fragment>
    );
}

export default ResearchEthicsApprovalReportDocuments;
