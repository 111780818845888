import { lighten, useTheme } from "@mui/material";
import { createSxStyles } from "../common/createSxStyles";
import { annualWheelTaskCardHeight, annualWheelTaskCardMargin } from "./annualWheelTask/AnnualWheelTask";

export const defaultActivitiesToShow = 3;
export const columContainerMinHeight = annualWheelTaskCardHeight * defaultActivitiesToShow + annualWheelTaskCardMargin * 3 + 16;
export const headerBorderRadius = 28;
export const useAnnualWheelStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        taskCounterContainer: {
            marginTop: theme.spacing(1.2),
        },
        taskCounterPaper: {
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
            height: 125 + "px",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom",
            backgroundSize: "cover",
        },
        calender: {
            padding: theme.spacing(5),
            marginTop: theme.spacing(2),
        },
        calenderColumnHeader: {
            display: "flex",
            justifyContent: "center",
            position: "relative",
            paddingTop: 15 + "px",
            paddingBottom: 15 + "px",
            backgroundColor: "#EEF4FA",
        },
        columnContainer: {
            minHeight: columContainerMinHeight,
            padding: `${theme.spacing(3)} ${theme.spacing(4)} !Important`,
            position: "relative",
            "& .MuiCollapse-root": {
                overflowY: "clip",
                overflow: "unset",
            },
            height: "100%",
        },
        currentMonth: {
            backgroundColor: lighten("#DBE0FD", 0.8),
        },
        overDueTaskCount: {
            backgroundColor: `${lighten(theme.palette.error.light, 0.5)} !Important`,
            color: `${theme.palette.common.white} !Important`,
        },
        plannedTaskCount: {
            backgroundColor: `${theme.palette.primary.main} !Important`,
            color: `${theme.palette.common.white} !Important`,
        },
        completedTaskCount: {
            backgroundColor: `${theme.palette.secondary.main} !Important`,
            color: `${theme.palette.common.white} !Important`,
        },
        totalTaskCounter: {
            backgroundColor: `${theme.palette.common.white} !Important`,
            color: `${theme.palette.primary.dark} !Important`,
            border: `1px solid ${theme.palette.primary.dark} !Important`,
        },
        expandContainer: {
            marginTop: theme.spacing(2),
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& .MuiTypography-root": {
                "&:hover": {
                    cursor: "pointer",
                    textDecoration: "underline",
                },
            },
        },
        breadCrumbContainer: {
            display: "flex",
        },
        yearPicker: {
            marginLeft: theme.spacing(2),
        },
        export: {
            marginRight: theme.spacing(2),
        },
        addIcon: {
            position: "absolute",
            right: 40 + "px",
            fill: theme.palette.primary.dark,
            "&:hover": {
                fill: theme.palette.primary.main,
                cursor: "pointer",
            },
        },
    });
};

export const overDueTaskCountColor = () => {
    return {
        backgroundColor: "error.light",
        color: "common.white",
    };
};
export const plannedTaskCountColor = () => {
    return {
        backgroundColor: "primary.main",
        color: "common.white",
    };
};

export const completedTaskCountColor = () => {
    return {
        backgroundColor: "secondary.main",
        color: "common.white",
    };
};

export const totalTaskCounterColor = () => {
    return {
        backgroundColor: "common.white",
        color: "primary.dark",
    };
};
