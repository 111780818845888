import { createElement } from "react";
import { Trans } from "react-i18next";
import { useMutation } from "react-query";
import { deleteHttp } from "../../../common/api/apiShared";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { IDeleteHelpTextDialog } from "./HelpTextDeleteDialog";

export function DeleteHelpTextDialogDataMapping(props: IDeleteHelpTextDialog) {
    const snackbar = useDotLegalSnackbar();

    const removeMutation = useMutation(remove);
    const deletePolicy = () => {
        removeMutation.mutateAsync(undefined, {
            onSuccess: (response: any) => {
                props.closeDialog();
                props.onDeleted();
                snackbar.show(createElement(Trans, { i18nKey: "itemDeleted", values: { name: props.selectedHelpText?.header } }));
            },
        });
    };

    async function remove() {
        return await deleteHttp<string>(`/helpTexts/${props.selectedHelpText.id}`);
    }

    return {
        deletePolicy,
        inProgess: removeMutation.isLoading,
    };
}
