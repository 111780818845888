import { useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";

export const useSystemTemplateDialogStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        whiteArrow: {
            "& svg": {
                fill: theme.palette.common.white,
            },
        },
        tabsContainer: {
            "& .MuiTabs-flexContainer": {
                justifyContent: "center",
            },
        },
        container: {
            paddingLeft: theme.spacing(8),
            paddingRight: theme.spacing(8),
        },
        gridContainer: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(1),
        },
        chooseAll: {
            fontSize: theme.typography.pxToRem(12),
            color: theme.palette.primary.main,
            textDecoration: "underline",
            "&:hover": {
                cursor: "pointer",
            },
        },
        gridContainerHeader: {
            marginBottom: theme.spacing(2),
            fontWeight: "bold",
            color: theme.palette.primary.dark,
            fontSize: theme.typography.pxToRem(12),
        },
        templateSelectorContainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        },
        templateHeaderContainer: {
            display: "flex",
            justifyContent: "space-between",
        },
    });
};
