import { LegalEntityMasterType } from "../../../legalEntity/legalEntities/LegalEntities.types";
import { LegalEntityAuditStatus } from "../../../legalEntity/legalEntityAuditTab/LegalEntityAudit.types";
import { RiskLevel } from "../../../legalEntity/legalEntityClassificationTab/LegalEntityClassificationTab.types";
import { LegalEntityContactType } from "../../../legalEntity/legalEntityContactTab/LegalEntityContactTab.types";
import { LegalEntityAuditFrequency, LegalEntityAuditType } from "../../../legalEntity/legalEntityManagement/LegalEntityManagement.types";
import { SelectableItem } from "../../../processingActivity/ProcessingActivity.types";

export interface AuditGroupLegalEntityRowViewModel extends AuditGroupLegalEntityRowApiModel {
    legalEntityTypesString: string;
    country: string;
    contactName: string;
    systemNames: string;
    classificationName: string;
    classificationRiskName: string | null;
    auditName: string;
    auditSubmission: Date | null;
    auditSubmissionName: string;
    auditTypeName: string;
    auditFrequencyName: string;
}

export interface AuditGroupLegalEntitySelectorViewModel {
    rows: Array<AuditGroupLegalEntityRowApiModel>;
}

export interface AuditGroupLegalEntityRowApiModel {
    id: string;
    name: string;
    legalEntityTypes: Array<LegalEntityMasterType>;
    countryCode: string;
    contacts: Array<AuditGroupLegalEntityContact>;
    assets: Array<SelectableItem>;
    classifications: Array<LegalEntityClassificationInformationViewModel>;
    latestAudit: LegalEntityAuditInformationViewModel;
    auditType: LegalEntityAuditType;
    auditFrequency: LegalEntityAuditFrequency;
    audits: Array<LegalEntityAuditInformationViewModel>;
}

export interface AuditGroupLegalEntityContact {
    id: string;
    name: string;
    email: string;
    isPrimaryContact: boolean;
    contactTypes: Array<LegalEntityContactType>;
}

export interface LegalEntityAuditGroupSelectableSystem {
    legalEntityId: string;
    selectableSystems: Array<SelectableItem>;
}

export interface LegalEntityAuditInformation {
    legalEntityId: string;
    classifications: Array<LegalEntityClassificationInformationViewModel>;
    latestAudit: LegalEntityAuditInformationViewModel;
    audits: Array<LegalEntityAuditInformationViewModel>;
}

export interface LegalEntityClassificationInformationViewModel {
    id: string;
    questionnaireId: string;
    name: string;
    riskLevel: RiskLevel | null;
    riskName: string | null;
    scoreEnabled: boolean;
    submitted: Date | null;
    responseId: string;
}

export interface LegalEntityAuditInformationViewModel {
    id: string;
    questionnaireId: string;
    name: string;
    lastestRecipientUpdate: Date | null;
    status: LegalEntityAuditStatus;
    numberOfQuestionsAnswered: number;
    totalNumberOfQuestions: number;
    submissionDate: Date | null;
    isClosed: boolean;
    showQuestionsStatistics: boolean;
}

export interface LegalEntitySupervisionInformationViewModel {
    type?: LegalEntityAuditType;
    frequency?: LegalEntityAuditFrequency;
}

export enum LatestAuditSubmissionFilterOptions {
    OlderThanLastMonth,
    OlderThan3Months,
    OlderThan6Months,
    OlderThanLastYear,
    OlderThan2Years,
    OlderThan3Years,
}
