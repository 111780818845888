import { Grid, RadioGroup } from "@mui/material";
import React from "react";
import { useTranslation } from "../../../localization/useTranslation";
import { useDotLegalSecurityPeriodMetStyles } from "./DotLegalSecurityPeriodMet.styles";
import { DotLegalHeader, DotLegalRadioButton } from "@dotlegal/dotlegal-ui-components";

export interface IDotLegalSecurityPeriodMet {
    description?: string;
    name: string;
    onchange?: (value: boolean) => void;
    isMet: boolean;
    disabled?: boolean;
}

export default function DotLegalSecurityPeriodMet(props: IDotLegalSecurityPeriodMet) {
    const { translateString } = useTranslation();
    const styles = useDotLegalSecurityPeriodMetStyles();

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).value === "true";
        if (props.onchange) props.onchange(value);
    };

    return (
        <Grid container sx={styles.container} alignContent="center" justifyContent="center">
            <RadioGroup row value={props.isMet} onChange={handleOnChange}>
                <Grid sx={styles.centerItem} item xs={4}>
                    <DotLegalHeader marginBottom={0} headerStyle="extraSmall" fontWeight={400} toolTip={props.description}>
                        {props.name}
                    </DotLegalHeader>
                </Grid>
                <Grid item>
                    <DotLegalRadioButton value label={translateString("yes")} disabled={props.disabled} />
                </Grid>
                <Grid item>
                    <DotLegalRadioButton value={false} label={translateString("no")} disabled={props.disabled} />
                </Grid>
            </RadioGroup>
        </Grid>
    );
}
