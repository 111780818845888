import { Box, Divider, Grid, GridSize, Typography } from "@mui/material";
import React from "react";
import { useSystemOverviewStyles } from "../SystemOverview.styles";
import { usePlanContext } from "../../../auth/planProvider/PlanProvider";
import { useTranslation } from "../../../localization/useTranslation";
import SystemOverviewDataCategoriesDialog from "../systemOverviewDataCategoriesDialog/SystemOverviewDataCategoiresDialog";
import SystemOverviewDataSubjectsDialog from "../systenOverviewDataSubjectsDialog/SystemOverviewDataSubjectsDialog";
import { setFirstLetterToLowerCase } from "../../../common/stringOperations";
import { SystemDataType, SystemStorageType } from "../../systemEdit/systemDataAndStorageStep/SystemDataAndStorageStep.types";
import { DotLegalDialog, DotLegalFixedHeightText, DotLegalHeader, DotLegalHeading } from "@dotlegal/dotlegal-ui-components";
import { useDataTabContent } from "./DataTabContent.hooks";

export interface DataTabContentProps {
    systemId?: string;
}

function DataTabContent(props: DataTabContentProps) {
    const styles = useSystemOverviewStyles();

    const propertiesGridProps: { item: boolean; xs: GridSize; sm: GridSize; md: GridSize; lg: GridSize; xl: GridSize } = {
        item: true,
        xs: 12,
        sm: 6,
        md: 4,
        lg: 2,
        xl: 2,
    };

    const { translateString, translateCountry } = useTranslation();
    const { informationSecurityFeatures } = usePlanContext();
    const {
        isLoading,
        infoData,
        showDataCategoryDialog,
        setShowDataCategoryDialog,
        showDataSubjectsDialog,
        setShowDataSubjectsDialog,
        showSupportOrStorageDialog,
        setShowSupportOrStorageDialog,
    } = useDataTabContent(props);

    const numberOfDataCategories = infoData?.dataCategories?.length ?? 0;
    const numberOfDataSubjects = infoData?.dataSubjects?.length ?? 0;

    const getNumberStyle = (disableCursor: boolean) => {
        return {
            "& .MuiTypography-root": { "&:hover": { cursor: disableCursor ? "default" : "pointer" } },
        };
    };

    const getDataLocations = () => {
        return (
            <Box sx={styles.dataLocationContainer}>
                <Box sx={styles.dataLocationItem}>
                    {infoData?.dataLocation?.storageLocationCountryCodes && infoData?.dataLocation?.storageLocationCountryCodes.length > 1 ? (
                        <React.Fragment>
                            <Typography>{translateString("storage")}: &nbsp; </Typography>
                            <Typography
                                sx={styles.multipleSupportLocations}
                                onClick={() =>
                                    setShowSupportOrStorageDialog({
                                        showDialog: true,
                                        data: infoData?.dataLocation?.storageLocationCountryCodes,
                                        isSupport: false,
                                    })
                                }
                            >
                                {translateString("multipleSupportLocations").toLowerCase()}{" "}
                            </Typography>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Typography>{translateString("storage")}: &nbsp; </Typography>
                            <Typography>
                                {infoData?.dataLocation.storageLocationCountryCodes
                                    ? translateCountry(infoData?.dataLocation!.storageLocationCountryCodes[0])
                                    : ""}
                            </Typography>
                        </React.Fragment>
                    )}
                </Box>
                <Box sx={styles.dataLocationItem}>
                    {infoData?.dataLocation?.supportLocationCountryCodes && infoData?.dataLocation?.supportLocationCountryCodes.length > 1 ? (
                        <React.Fragment>
                            <Typography>{translateString("support")}: &nbsp; </Typography>
                            <Typography
                                sx={styles.multipleSupportLocations}
                                onClick={() =>
                                    setShowSupportOrStorageDialog({
                                        showDialog: true,
                                        data: infoData?.dataLocation?.supportLocationCountryCodes,
                                        isSupport: true,
                                    })
                                }
                            >
                                {translateString("multipleSupportLocations").toLowerCase()}{" "}
                            </Typography>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Typography>{translateString("support")}: &nbsp; </Typography>
                            <Typography>
                                {infoData?.dataLocation.supportLocationCountryCodes
                                    ? translateCountry(infoData?.dataLocation!.supportLocationCountryCodes[0])
                                    : ""}
                            </Typography>
                        </React.Fragment>
                    )}
                </Box>
            </Box>
        );
    };

    return (
        <>
            <Grid sx={styles.gridRow} container spacing={3}>
                <Grid {...propertiesGridProps} sx={getNumberStyle(numberOfDataCategories === 0)}>
                    <DotLegalHeading name={translateString("dataCategories")}>
                        <DotLegalFixedHeightText
                            onClick={() => {
                                if (numberOfDataCategories > 0) setShowDataCategoryDialog(true);
                            }}
                            isLoading={isLoading}
                            content={numberOfDataCategories}
                            fontSize="xl"
                        />
                    </DotLegalHeading>
                </Grid>

                <Grid {...propertiesGridProps} sx={getNumberStyle(numberOfDataSubjects === 0)}>
                    <DotLegalHeading name={translateString("dataSubjects")}>
                        <DotLegalFixedHeightText
                            onClick={() => {
                                if (numberOfDataSubjects > 0) setShowDataSubjectsDialog(true);
                            }}
                            isLoading={isLoading}
                            content={numberOfDataSubjects}
                            fontSize="xl"
                        />
                    </DotLegalHeading>
                </Grid>

                <Grid {...propertiesGridProps}>
                    <DotLegalHeading name={translateString("dataLocation")}>
                        <DotLegalFixedHeightText isLoading={isLoading} content={getDataLocations()} />
                    </DotLegalHeading>
                </Grid>

                {informationSecurityFeatures.storageType && (
                    <Grid {...propertiesGridProps}>
                        <DotLegalHeading name={translateString("storageType")}>
                            <DotLegalFixedHeightText
                                isLoading={isLoading}
                                content={
                                    infoData?.storageType ? translateString(setFirstLetterToLowerCase(SystemStorageType[infoData?.storageType].toString())) : ""
                                }
                            />
                        </DotLegalHeading>
                    </Grid>
                )}

                {informationSecurityFeatures.dataType && (
                    <Grid {...propertiesGridProps}>
                        <DotLegalHeading name={translateString("dataType")}>
                            <DotLegalFixedHeightText
                                isLoading={isLoading}
                                content={
                                    infoData?.dataTypes
                                        ? infoData?.dataTypes.map((x) => translateString(setFirstLetterToLowerCase(SystemDataType[x].toString()))).join(", ")
                                        : ""
                                }
                            />
                        </DotLegalHeading>
                    </Grid>
                )}
            </Grid>

            <Divider sx={styles.divider} />

            {/* Creates an empty row. Important for making the size for each tabcontent same height */}
            <Grid sx={styles.gridRow} container spacing={3}></Grid>

            {showDataCategoryDialog && (
                <SystemOverviewDataCategoriesDialog onCloseDialog={() => setShowDataCategoryDialog(false)} dataCategories={infoData?.dataCategories} />
            )}

            {showDataSubjectsDialog && (
                <SystemOverviewDataSubjectsDialog onCloseDialog={() => setShowDataSubjectsDialog(false)} dataSubjects={infoData?.dataSubjects} />
            )}

            {showSupportOrStorageDialog.showDialog && (
                <DotLegalDialog
                    size="sm"
                    buttonOkText={""}
                    open
                    hideOkButton
                    header={showSupportOrStorageDialog.isSupport ? translateString("supportLocations") : translateString("storageLocations")}
                    onDialogClose={() => setShowSupportOrStorageDialog({ showDialog: false, data: [], isSupport: false })}
                    onOkClick={() => setShowSupportOrStorageDialog({ showDialog: false, data: [], isSupport: false })}
                >
                    <Box sx={styles.supportLocationsContainer}>
                        {showSupportOrStorageDialog.data.map((countryCode) => {
                            return (
                                <DotLegalHeader key={countryCode} headerStyle={"extraSmall"} fontWeight={400} marginBottom={0}>
                                    {translateCountry(countryCode)}
                                </DotLegalHeader>
                            );
                        })}
                    </Box>
                </DotLegalDialog>
            )}
        </>
    );
}

export default DataTabContent;
