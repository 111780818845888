import React from "react";
import { useTaskComments } from "./TaskComments.hook";
import { Box, Skeleton, Tooltip } from "@mui/material";
import CommentOverview from "../../../comment/commentOverview/CommentOverview";
import { DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { TopButton } from "../EditTaskDialog";
import { useTaskCommentsStyles } from "./TaskComments.styles";
import { newestFirstSortIcon, oldestFirstSortIcon, PurpleLockIcon, WhiteLockIcon } from "../../../common/icons";
import { CommentViewModel, TaskCommentApiModel } from "../../../comment/commentCard/CommentCard.types";
import CommentTrack from "../../../comment/commentTrack/CommentTrack";
import DotLegalLink from "../../../common/components/dotLegalLink/DotLegalLink";
import { useUrlProvider } from "../../../useUrlProvider";
import { useCommentOverviewStyles } from "../../../comment/commentOverview/CommentOverview.styles";

export interface TaskCommentsProps {
    taskId: string;
    taskName: string;
    customId: string | null;
    deadline: Date;
    allDataIsDoneLoading: boolean;
    passIsDoneLoading?: (newValue: boolean) => void;
}

function TaskComments(props: TaskCommentsProps) {
    const styles = useTaskCommentsStyles();
    const { skeletons } = useCommentOverviewStyles();
    const { translateString, translateDate, translateDateWithFullMonthAndYear, translateDateWithTime } = useTranslation();
    const { getTaskManagementUrl } = useUrlProvider();
    const {
        isLoading,
        commentsViewModel,
        historicCommentsLoading,
        historicComments,
        onDeleteComment,
        onDeleteSubComment,
        onCreated,
        onUpdateComment,
        createdCommentId,
        onSubCommentCreated,
        onUpdateSubComment,
        onSubscriptionChange,
        subscriptionSaving,
        subscribed,
        tab,
        setTab,
        showNewestFirst,
        setShowNewestFirst,
        onPinClick,
        hasPremium,
    } = useTaskComments(props);

    const getSortedCommentsByCreated = (data: Array<TaskCommentApiModel> | undefined) => {
        if (data) {
            if (showNewestFirst) {
                return data?.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());
            } else {
                return data?.sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime());
            }
        }
        return [];
    };

    const getSortedCommentsByDeadline = (data: Array<TaskCommentApiModel> | undefined) => {
        if (data) {
            if (showNewestFirst) {
                return data?.sort((a, b) => new Date(b.deadline ?? b.created).getTime() - new Date(a.deadline ?? a.created).getTime());
            } else {
                return data?.sort((a, b) => new Date(a.deadline ?? a.created).getTime() - new Date(b.deadline ?? b.created).getTime());
            }
        }
        return [];
    };

    function mapApiModelsToViewModel(apiModels: Array<TaskCommentApiModel> | undefined, isHistoricComments: boolean): Array<CommentViewModel> {
        if (apiModels === undefined) {
            return [];
        }

        return (
            apiModels.map((comment) => {
                return {
                    content: comment.content,
                    id: comment.id,
                    date: getCommentDate(comment.created, comment.isEdited, comment.deadline, isHistoricComments),
                    isEdited: comment.isEdited,
                    isOwner: comment.isOwner,
                    isStatusChange: false,
                    pinned: comment.pinned,
                    title: getCommentTitle(comment.userFullName, comment.taskId, isHistoricComments),
                    subComments:
                        comment.subComments.map((subComment) => {
                            return {
                                content: subComment.content,
                                id: subComment.id,
                                date: getCommentDate(subComment.created, subComment.isEdited, comment.deadline, isHistoricComments),
                                isEdited: subComment.isEdited,
                                isOwner: subComment.isOwner,
                                isStatusChange: false,
                                pinned: false,
                                title: getCommentTitle(subComment.userFullName, subComment.taskId, isHistoricComments),
                                subComments: [],
                            };
                        }) ?? [],
                };
            }) ?? []
        );
    }

    function getCommentDate(created: Date, isEdited: boolean, deadline: Date | null, isHistoricComments: boolean) {
        if (isHistoricComments) {
            if (!deadline) return `${translateDateWithTime(created)}`;
            return `${translateString("deadline")}: ${translateDateWithFullMonthAndYear(deadline ?? undefined)}`;
        }

        return `${translateDate(created)} ${isEdited ? `(${translateString("edited").toLowerCase()})` : ""}`;
    }
    function getCommentTitle(userFullName: string | undefined, taskId: string, isHistoricComments: boolean) {
        if (isHistoricComments) {
            return (
                <DotLegalLink linkColor={"primary"} to={getTaskManagementUrl() + `?taskId=${taskId}`}>
                    {props.customId ? `${props.customId} ${props.taskName}` : props.taskName}
                </DotLegalLink>
            );
        }

        return `${userFullName ? userFullName : translateString("deletedUser")}`;
    }

    const getLockedIcon = (useWhiteIcon: boolean) => {
        return <Box sx={{ marginLeft: 1, display: "flex" }}>{useWhiteIcon ? WhiteLockIcon : PurpleLockIcon}</Box>;
    };

    function getSkeletons() {
        return [1, 2, 3, 4, 5, 6].map((x) => {
            return <Skeleton key={x} variant="text" width="100%" height={30} />;
        });
    }

    return (
        <Box>
            {props.allDataIsDoneLoading && (
                <>
                    <DotLegalHeader marginTop={0} marginBottom={0.5} headerStyle="small">
                        {translateString("activity")}
                    </DotLegalHeader>

                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Box sx={[styles.topWrapper, { "& > *:not(:last-child) ": { marginRight: 1 } }]}>
                            <Box sx={{ marginRight: 2 }}>
                                <DotLegalHeader marginBottom={0} headerStyle={"extraSmall"} fontWeight={400}>
                                    {translateString("show")}:
                                </DotLegalHeader>
                            </Box>
                            <Tooltip title={!hasPremium ? translateString("upgradePrivacyPlanToday") : ""}>
                                <span>
                                    <TopButton
                                        disabled={!hasPremium}
                                        className="test"
                                        sx={[styles.buttonStyles, tab === 1 ? styles.activeButton : {}]}
                                        onClick={() => {
                                            if (tab !== 1) {
                                                setTab(1);
                                            }
                                        }}
                                    >
                                        {translateString("comments")}
                                        {!hasPremium && getLockedIcon(true)}
                                    </TopButton>
                                </span>
                            </Tooltip>

                            <Tooltip title={!hasPremium ? translateString("upgradePrivacyPlanToday") : ""}>
                                <span>
                                    <TopButton
                                        disabled={!hasPremium}
                                        sx={[styles.buttonStyles, tab === 2 ? styles.activeButton : {}]}
                                        onClick={() => {
                                            if (tab !== 2) {
                                                setTab(2);
                                            }
                                        }}
                                    >
                                        {translateString("history")}
                                        {!hasPremium && getLockedIcon(false)}
                                    </TopButton>
                                </span>
                            </Tooltip>
                        </Box>
                        {hasPremium && (
                            <Tooltip title={translateString("reverseSortDirection")}>
                                <Box
                                    onClick={() => setShowNewestFirst(!showNewestFirst)}
                                    sx={[styles.topWrapper, { "&:hover": { cursor: "pointer" }, "& > *:not(:last-child) ": { marginRight: 1 } }]}
                                >
                                    <DotLegalHeader fontWeight={400} headerStyle="extraSmall" marginBottom={0}>
                                        {showNewestFirst ? translateString("newestFirst") : translateString("oldestFirst")}
                                    </DotLegalHeader>
                                    {showNewestFirst ? newestFirstSortIcon : oldestFirstSortIcon}
                                </Box>
                            </Tooltip>
                        )}
                    </Box>

                    {tab === 1 ? (
                        <CommentOverview
                            id={props.taskId}
                            isLoading={isLoading}
                            data={mapApiModelsToViewModel(getSortedCommentsByCreated(commentsViewModel), false)}
                            isSubscriptionSaving={subscriptionSaving}
                            subscribed={subscribed ?? false}
                            maxHeight={"auto"}
                            textFieldOnTop
                            commentIdToScroll={createdCommentId}
                            expandInputOnFocus
                            disabled={!hasPremium}
                            disableInitialTextFieldFocus
                            notificationText={translateString("notifyUserOnCommentsForTask")}
                            onDeleteComment={onDeleteComment}
                            onDeleteSubComment={onDeleteSubComment}
                            onCreateComment={onCreated}
                            onCreateSubComment={onSubCommentCreated}
                            onUpdateComment={onUpdateComment}
                            onUpdateSubComment={onUpdateSubComment}
                            onSubscriptionChanged={onSubscriptionChange}
                            onPinClick={onPinClick}
                        />
                    ) : (
                        <Box sx={{ marginTop: 2 }}>
                            {historicCommentsLoading ? (
                                <React.Fragment>
                                    <Box sx={skeletons}>{getSkeletons()}</Box>
                                </React.Fragment>
                            ) : (
                                <CommentTrack
                                    data={mapApiModelsToViewModel(getSortedCommentsByDeadline(historicComments), true)}
                                    maxHeight={"auto"}
                                    disableOverflowMenu
                                    onDeleteComment={() => {}}
                                    onDeleteSubComment={() => {}}
                                    onEditComment={() => {}}
                                    onEditSubComment={() => {}}
                                    onReplyToComment={() => {}}
                                />
                            )}
                        </Box>
                    )}
                </>
            )}
        </Box>
    );
}

export default TaskComments;
