import { createElement, useState } from "react";
import { getSettings } from "../common/settingsProvider";
import { createPdfUrl } from "../common/pdfUrlHelper";
import { downloadFile } from "../common/api/downloadFile";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { Trans } from "react-i18next";
import { useTranslation } from "../localization/useTranslation";
import { useUrlProvider } from "../useUrlProvider";
import { useUserContext } from "../auth/userContextProvider/UserContextProvider.tsx";

export interface IDownloadAuditReportModel {
    legalEntityAuditId: string;
    questionnaireResponseId: string;
    responseExternalId: string;
    deadline: Date;
    sender: string;
    legalEntityName: string;
    questionnaireName: string;
    hasRecommendations: boolean;
}

export function useDownloadLegalEntityAuditReport() {
    const { translateDate, translateString } = useTranslation();

    const [isDownloadingPdf, setIsDownloadingPdf] = useState<string | undefined>(undefined);
    const [isDownloadingManagementReport, setIsDownloadingManagementReport] = useState(false);
    const { language } = useUserContext();
    const snackbar = useDotLegalSnackbar();
    const urlProvider = useUrlProvider();

    const onDownloadReportAsPdf = async (model: IDownloadAuditReportModel, isSimpleReport: boolean, showRecommendations: boolean) => {
        setIsDownloadingPdf(model.legalEntityAuditId);
        let url = isSimpleReport
            ? getExternalPdfUrl(model.responseExternalId, model.deadline, model.sender, model.legalEntityAuditId, true, showRecommendations)
            : getReportPdfUrl(model.questionnaireResponseId, model.deadline, model.sender, model.legalEntityName, true, showRecommendations);
        url = getSettings().mainSiteBaseUrl + url;
        url = createPdfUrl(url, language);
        const fileName = `${model.questionnaireName}_${model.legalEntityName}_${translateDate(model.deadline)}.pdf`;
        await downloadFile(getSettings().apiBaseUrl + "/pdf/generate?noMargin=true&url=" + url, fileName);
        setIsDownloadingPdf(undefined);
        snackbar.show(createElement(Trans, { i18nKey: "pdfDownloadComplete", values: { fileName: fileName } }));
    };

    const onDownloadManagementReport = async (auditGroupId: string, groupName: string, sender: string) => {
        setIsDownloadingManagementReport(true);
        let url = getSettings().mainSiteBaseUrl + urlProvider.getAuditGroupManagementReportUrl(auditGroupId) + "?isPdf=true";
        url = createPdfUrl(url, language);
        const fileName = `${translateString("managementReport")}_${groupName}_${sender}.pdf`;
        await downloadFile(getSettings().apiBaseUrl + "/pdf/generate?noMargin=true&url=" + url, fileName);
        setIsDownloadingManagementReport(false);
        snackbar.show(createElement(Trans, { i18nKey: "pdfDownloadComplete", values: { fileName: fileName } }));
    };

    function getReportPdfUrl(
        questionnaireResponseId: string,
        deadline: Date,
        sender: string,
        legalEntityName: string,
        pdf: boolean,
        hasRecommendations: boolean
    ) {
        return urlProvider.getQuestionnaireResponseReport(questionnaireResponseId, deadline, sender, legalEntityName, pdf, hasRecommendations);
    }

    function getExternalPdfUrl(responseExternalId: string, deadline: Date, sender: string, legalEntityName: string, pdf: boolean, hasRecommendations: boolean) {
        return urlProvider.getQuestionnaireResponseExternalreport(
            responseExternalId,
            deadline,
            sender,
            legalEntityName,
            undefined,
            pdf,
            hasRecommendations,
            false
        );
    }

    return {
        onDownloadReportAsPdf,
        getReportPdfUrl,
        isDownloadingPdf,
        onDownloadManagementReport,
        isDownloadingManagementReport,
    };
}
