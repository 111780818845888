import React from "react";
import { useTranslation } from "../../localization/useTranslation";
import { QuestionCategory, QuestionType, RiskAssessmentType } from "../RiskAssessment.types";
import { useRiskQuestionDataMapping } from "./RiskAssessmentQuestionDialog.hooks";
import RiskDialogHeader from "../riskAssessmentDialog/rIskDialogHeader/RIskDIalogHeader";
import RiskBusinessAreas from "../riskAssessmentDialog/riskBusinessAreas/RiskBusinessAreas";
import RiskBarometer from "../riskAssessmentDialog/riskBarometer/RiskBarometer";
import { Alert, AlertTitle, Box, CircularProgress } from "@mui/material";
import { useRiskQuestionDialogStyles } from "./RiskAssessmentQuestionDialog.styles";
import { useNavigate, useParams } from "react-router-dom";
import QuestionsCollapse from "../riskAssessmentDialog/questionContainer/QuestionsCollapse";
import { DotLegalDialog, DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import { RiskAssessmentArea, RiskComplianceArea } from "../riskAssessments/RiskAssessments.types";
import { isNullOrWhitespace, setFirstLetterToLowerCase } from "../../common/stringOperations";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";
import RiskAssessmentLocked from "./riskAssessmentLocked.svg?react";
import TrialUsageWarning from "../../common/components/TrialUsageWarning.tsx";

export interface IProcessingActivityRiskQuestionDialogProps {}

function ProcessingActivityRiskQuestionDialog(props: IProcessingActivityRiskQuestionDialogProps) {
    const { riskAssessmentAreaString, riskComplianceAreaString, riskAssessmentVersionId } = useParams<{
        riskAssessmentAreaString: string;
        riskComplianceAreaString: string;
        riskAssessmentVersionId: string;
    }>();
    const riskAssessmentArea: RiskAssessmentArea = Number(riskAssessmentAreaString);
    const riskComplianceArea: RiskComplianceArea = Number(riskComplianceAreaString);

    const { translateString } = useTranslation();
    const { dataProtectionFeatures, informationSecurityFeatures } = usePlanContext();

    const {
        score,
        averageScore,
        questions,
        name: processingActivityName,
        questionData,
        getCustomQuestionDisplayText,
        businessAreas,
        isLoading,
        isSaving,
        resetQuestionClick,
        saveQuestion,
        toggleExcludeQuestion,
        id,
        questionCategories,
        securityMeasures,
        dialogNameHref,
        isLocked,
        currentRiskAssessmentVersionName,
        disableQuestions,
    } = useRiskQuestionDataMapping(riskAssessmentArea, riskComplianceArea, riskAssessmentVersionId);
    const styles = useRiskQuestionDialogStyles();
    const navigate = useNavigate();

    const isScenarioLimitExceeded = (complianceArea: RiskComplianceArea, maxNumberOfScenarios: number | undefined | null) => {
        if (complianceArea === riskComplianceArea) {
            if (maxNumberOfScenarios) {
                let numberOfSelectedStandardScenarios = questions.filter((x) => isNullOrWhitespace(x.customQuestionId)).length;

                return maxNumberOfScenarios < numberOfSelectedStandardScenarios;
            }
        }

        return false;
    };

    const showMaxNumberOfNIS2ScenariosExceeded = isScenarioLimitExceeded(RiskComplianceArea.NIS2, informationSecurityFeatures.maxNumberOfNIS2RiskScenarios);
    const showMaxNumberOfGDPRScenariosExceeded = isScenarioLimitExceeded(RiskComplianceArea.GDPR, dataProtectionFeatures.maxNumberOfGDPRRiskScenarios);
    const showMaxNumberExceeded = showMaxNumberOfNIS2ScenariosExceeded || showMaxNumberOfGDPRScenariosExceeded;

    const getQuestions = () => {
        if (riskAssessmentArea === RiskAssessmentArea.ProcessingActivities && riskComplianceArea === RiskComplianceArea.GDPR) {
            return questionCategories.map((obj, index) => {
                return (
                    <React.Fragment key={index}>
                        {questions && questions.filter((q) => obj.questionTypes.some((qt) => qt === q.questionType)).length > 0 && (
                            <React.Fragment>
                                <DotLegalHeader marginBottom={1} headerStyle={"small"}>
                                    {translateString(`questionCategory${QuestionCategory[obj.questionCategory]}`)}
                                </DotLegalHeader>
                                <QuestionsCollapse
                                    questions={questions?.filter((q) => obj.questionTypes.some((qt) => qt === q.questionType))}
                                    riskAssessmentType={RiskAssessmentType.Probability}
                                    saveQuestion={saveQuestion}
                                    disableResetButton={isSaving}
                                    resetQuestionClick={resetQuestionClick}
                                    questionData={questionData}
                                    getCustomQuestionDisplayValue={getCustomQuestionDisplayText}
                                    id={id}
                                    securityMeasues={securityMeasures ?? []}
                                    onExcludeChange={toggleExcludeQuestion}
                                    disabled={disableQuestions}
                                />
                            </React.Fragment>
                        )}
                    </React.Fragment>
                );
            });
        } else {
            const customQuestions = questions.filter((x) => x.questionType === QuestionType.CustomQuestion);
            return (
                <>
                    <QuestionsCollapse
                        questions={questions.filter((x) => x.questionType !== QuestionType.CustomQuestion)}
                        riskAssessmentType={RiskAssessmentType.Probability}
                        saveQuestion={saveQuestion}
                        disableResetButton={isSaving}
                        resetQuestionClick={resetQuestionClick}
                        questionData={questionData}
                        getCustomQuestionDisplayValue={getCustomQuestionDisplayText}
                        id={id}
                        securityMeasues={securityMeasures ?? []}
                        onExcludeChange={toggleExcludeQuestion}
                        disabled={disableQuestions}
                    />
                    {customQuestions.length > 0 && (
                        <>
                            <DotLegalHeader marginBottom={1} headerStyle={"small"}>
                                {translateString("questionCategoryCustomQuestion")}
                            </DotLegalHeader>
                            <QuestionsCollapse
                                questions={questions.filter((x) => x.questionType === QuestionType.CustomQuestion)}
                                riskAssessmentType={RiskAssessmentType.Probability}
                                saveQuestion={saveQuestion}
                                disableResetButton={isSaving}
                                resetQuestionClick={resetQuestionClick}
                                questionData={questionData}
                                getCustomQuestionDisplayValue={getCustomQuestionDisplayText}
                                id={id}
                                securityMeasues={securityMeasures ?? []}
                                onExcludeChange={toggleExcludeQuestion}
                                disabled={disableQuestions}
                            />
                        </>
                    )}
                </>
            );
        }
    };

    const getContent = () => {
        return (
            <React.Fragment>
                {showMaxNumberExceeded ? (
                    <>
                        <TrialUsageWarning textKey={"riskAssessmentLockedWarningContent"} headerKey={"riskAssessmentLockedWarningHeader"} />
                        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 3, marginBottom: -5 }}>
                            <RiskAssessmentLocked />
                        </Box>
                    </>
                ) : (
                    <>
                        <RiskDialogHeader label={currentRiskAssessmentVersionName ?? ""} name={processingActivityName ?? ""} href={dialogNameHref} />

                        {(businessAreas?.length ?? 0) > 0 && <RiskBusinessAreas businessAreas={businessAreas!} />}

                        <RiskBarometer
                            score={score}
                            averageScore={averageScore}
                            isLoading={isLoading}
                            riskAssessmentType={RiskAssessmentType.Risk}
                            averageTooltip={translateString("riskDialogAverageScoreTooltip", {
                                riskAreaSingular: setFirstLetterToLowerCase(
                                    riskAssessmentArea === RiskAssessmentArea.ProcessingActivities
                                        ? translateString("processingActivity")
                                        : translateString("system")
                                ),
                            })}
                            riskTooltip={translateString("riskDialogRiskScoreTooltip")}
                        />

                        {isLocked && (
                            <Alert sx={(theme) => ({ marginBottom: 2, "& .MuiSvgIcon-root": { fill: theme.palette.warning.main } })} severity="warning">
                                <AlertTitle>{translateString("lockedVersion")}</AlertTitle>
                                {translateString("lockedVersionInfo")}
                            </Alert>
                        )}

                        <DotLegalHeader
                            headerStyle={"small"}
                            toolTip={
                                riskAssessmentArea === RiskAssessmentArea.ProcessingActivities
                                    ? translateString("riskQuestionTooltip")
                                    : translateString("ismsRiskQuestionTooltip")
                            }
                        >
                            {translateString("riskAssessmentQuestions")}
                        </DotLegalHeader>

                        {getQuestions()}
                    </>
                )}
            </React.Fragment>
        );
    };

    return (
        <DotLegalDialog
            open
            size="md"
            onDialogClose={() => {
                navigate(-1);
            }}
            header={""}
            buttonOkText={""}
            hideOkButton
            isLoading={isLoading}
            onOkClick={() => {}}
        >
            <Box sx={styles.content}>
                {isLoading ? (
                    <Box sx={styles.spinnerWrapper}>
                        <CircularProgress />
                    </Box>
                ) : (
                    getContent()
                )}
            </Box>
        </DotLegalDialog>
    );
}

export default ProcessingActivityRiskQuestionDialog;
