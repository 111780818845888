import React from "react";
import StepSegment from "../../../processingActivity/stepSegment/StepSegment";
import DataSubjects from "./dataSubjects/DataSubjects";
import { SystemModel } from "../SystemEdit.types";
import DataCategories from "./dataCategories/DataCategories";
import { Box } from "@mui/material";

export interface SystemPersonalDataStepProps {
    systemId: string;
    systemViewModel: SystemModel | undefined;
    isLoading: boolean;
}

function SystemPersonalDataStep(props: SystemPersonalDataStepProps) {
    return (
        <React.Fragment>
            <StepSegment size="small">
                <Box sx={{ marginBottom: 3, width: "100%" }}>
                    <DataSubjects systemId={props.systemId} isLoading={props.isLoading} systemViewModel={props.systemViewModel} />
                </Box>
            </StepSegment>
            <StepSegment size="large">
                <DataCategories systemViewModel={props.systemViewModel} isLoading={props.isLoading} systemId={props.systemId} />
            </StepSegment>
        </React.Fragment>
    );
}

export default SystemPersonalDataStep;
