import { createSxStyles } from "../../common/createSxStyles";

export const useCustomersStyle = () => {
    return createSxStyles({
        iconContainer: {
            display: "flex",
        },
        checkIcon: {
            height: "20px !important",
        },
    });
};
