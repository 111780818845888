import { Box, styled, Typography } from "@mui/material";
import { ProcessingActivityReportViewModel } from "./ProcessingActivityReport.types";

export const HeaderBox = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(3),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
}));

export const BodyTypography = styled(Typography)(({ theme }) => ({
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.primary.dark,
}));

export const HeaderBodyTypography = styled(Typography)(({ theme, fontSize }) => ({
    fontSize: theme.typography.pxToRem(fontSize ? Number.parseInt(fontSize.toString()) : 13),
    color: theme.palette.primary.dark,
    fontWeight: "bold",
    display: "inline",
    marginRight: theme.spacing(0.5),
}));

export const DateBox = styled(Box)(({ theme }) => ({
    width: theme.spacing(32),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
}));

export const Spinnerbox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    minHeight: 50,
}));

export const SpanBox = styled(Box)(({ theme, fontSize }) => ({
    whiteSpace: "break-spaces",
    fontSize: theme.typography.pxToRem(fontSize ? Number.parseInt(fontSize.toString()) : 13),
}));

export const ItalicHeader = styled(Box)(({ theme }) => ({
    fontStyle: "italic",
    display: "inline-block",
}));

export const TextBoxWrapper = styled(Box)(({ theme }) => ({
    "& > *": {
        marginTop: `${theme.spacing(1)} !important`,
    },
}));

export const getDataBasis = (translateString: any, data?: ProcessingActivityReportViewModel) => {
    const hasNoDataBasis =
        data &&
        data.dataProcessors.length === 0 &&
        data.disclosures.length === 0 &&
        data.jointDataControllers.length === 0 &&
        data.sources.length === 0 &&
        data.dataControllers.length === 0;

    return (
        <Box marginTop={hasNoDataBasis ? 0 : 0.75} marginBottom={3}>
            {data && data.dataProcessors.length > 0 && (
                <Box marginBottom={1}>
                    <BodyTypography>
                        <ItalicHeader>{translateString("dataProcessors") + ": "}</ItalicHeader>
                        <SpanBox component="span"> {data?.dataProcessors.join(", ")}</SpanBox>
                    </BodyTypography>
                </Box>
            )}

            {data && data.disclosures.length > 0 && (
                <Box marginBottom={1}>
                    <BodyTypography>
                        <ItalicHeader>{translateString("disclosures") + ": "}</ItalicHeader>
                        <SpanBox component="span"> {data?.disclosures.join(", ")}</SpanBox>
                    </BodyTypography>
                </Box>
            )}

            {data && data.jointDataControllers.length > 0 && (
                <Box marginBottom={1}>
                    <BodyTypography>
                        <ItalicHeader>{translateString("jointDataControllers") + ": "}</ItalicHeader>
                        <SpanBox component="span"> {data?.jointDataControllers.join(", ")}</SpanBox>
                    </BodyTypography>
                </Box>
            )}

            {data && data.sources.length > 0 && (
                <Box marginBottom={1}>
                    <BodyTypography>
                        <ItalicHeader>{translateString("sources") + ": "}</ItalicHeader>
                        <SpanBox component="span"> {data?.sources.join(", ")}</SpanBox>
                    </BodyTypography>
                </Box>
            )}

            {data && data.dataControllers.length > 0 && (
                <Box marginBottom={1}>
                    <BodyTypography>
                        <ItalicHeader>{translateString("dataControllers") + ": "}</ItalicHeader>
                        <SpanBox component="span"> {data?.dataControllers.join(", ")}</SpanBox>
                    </BodyTypography>
                </Box>
            )}
        </Box>
    );
};
