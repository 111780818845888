import { createElement } from "react";
import { Trans } from "react-i18next";
import { useMutation } from "react-query";
import { deleteHttp } from "../../common/api/apiShared";
import { Result } from "../../common/api/result";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { NotificationType } from "../Notification.types";
import { IDeleteNotificationDialog } from "./DeleteNotificationDialog";

export function useDeleteNotificationDialog(props: IDeleteNotificationDialog) {
    const snackbar = useDotLegalSnackbar();
    const { translateString } = useTranslation();

    const removeMutation = useMutation(remove);
    const removeNotification = () => {
        removeMutation.mutateAsync(undefined, {
            onSuccess: (response: Result<string>) => {
                props.onCloseDialog();
                props.onSaveCorrect();
                snackbar.show(createElement(Trans, { i18nKey: "notificationDeleted", values: { name: getNotificationItem() } }));
            },
        });
    };

    async function remove() {
        return await deleteHttp<string>(`/notification/${props.notification.id}`);
    }

    function getNotificationItem() {
        if (props.notification.notificationType === NotificationType.OnBoardingTask) return translateString("onBoardingNotificationDescription");
        else if (props.notification.notificationType === NotificationType.Release) return translateString("newFunctionsHeader");
        else if (props.notification.notificationType === NotificationType.LegalUpdates) return translateString("legalUpdatesNotification");

        return props.notification.description;
    }

    return {
        removeNotification,
        inProgess: removeMutation.isLoading,
        getNotificationItem,
    };
}
