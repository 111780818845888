import { useMutation } from "react-query";
import { put } from "../../../common/api/apiShared";
import { AddToAuditGroupProps } from "./AddToAuditGroup";
import { useState } from "react";

export function useAssociateWithExternalAudits(props: AddToAuditGroupProps) {
    const [selectedAuditGroup, setSelectedAuditGroup] = useState<string | null | undefined>();

    const associateToAuditGroupMutation = useMutation(associateToAuditGroupAPI);
    const onAuditGroupSelected = async () => {
        await associateToAuditGroupMutation.mutateAsync(selectedAuditGroup!, {
            onSuccess: (response: any) => {
                props.onAddedToAuditGroup();
            },
        });
    };

    return { selectedAuditGroup, setSelectedAuditGroup, onAuditGroupSelected, isSaving: associateToAuditGroupMutation.isLoading };

    async function associateToAuditGroupAPI(auditGroupId: string) {
        return await put(`/legalEntityAudit/${props.auditId}/auditGroup`, auditGroupId);
    }
}
