import React from "react";
export const reallyLowRiskIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="10.389" height="12.833" viewBox="0 0 10.389 12.833">
        <g id="Group_4779" data-name="Group 4779" transform="translate(0 0)">
            <path id="Path_8250" data-name="Path 8250" d="M10.389,0,5.195,4.23,0,0V3.112l5.195,4.23,5.194-4.23Z" transform="translate(0)" fill="#2CD5C4" />
            <path
                id="Path_8251"
                data-name="Path 8251"
                d="M5.194,26.907,0,22.677v3.112l5.195,4.23,5.194-4.23V22.677Z"
                transform="translate(0 -17.185)"
                fill="#2CD5C4"
            />
        </g>
    </svg>
);

export const lowRiskIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="10.643" height="7.521" viewBox="0 0 10.643 7.521">
        <path id="Path_8249" data-name="Path 8249" d="M10.643,0,5.321,4.333,0,0V3.187L5.321,7.52l5.321-4.333Z" transform="translate(0 0)" fill="#2CD5C4" />
    </svg>
);

export const moderateRiskIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="11.051" height="7.5" viewBox="0 0 11.051 7.5">
        <g id="Group_8772" data-name="Group 8772" transform="translate(-825.316 -760.915)">
            <path
                id="Path_13602"
                data-name="Path 13602"
                d="M-16483.9,1735.477h11.051"
                transform="translate(17309.219 -973.312)"
                fill="none"
                stroke="#ffea00"
                strokeWidth="2.5"
                opacity="0.86"
            />
            <path
                id="Path_13603"
                data-name="Path 13603"
                d="M-16483.9,1735.477h11.051"
                transform="translate(17309.219 -968.312)"
                fill="none"
                stroke="#ffea00"
                strokeWidth="2.5"
                opacity="0.86"
            />
        </g>
    </svg>
);

export const highRiskIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="10.643" height="7.521" viewBox="0 0 10.643 7.521">
        <path id="Path_8247" data-name="Path 8247" d="M5.321,0,0,4.333V7.521L5.321,3.188l5.321,4.333V4.333Z" transform="translate(0 0)" fill="#F7447A" />
    </svg>
);

export const reallyHighRiskIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="10.322" height="12.749" viewBox="0 0 10.322 12.749">
        <g id="Group_4778" data-name="Group 4778" transform="translate(0 0)">
            <path
                id="Path_8245"
                data-name="Path 8245"
                d="M0,26.879v3.091l5.161-4.2,5.161,4.2V26.879l-5.161-4.2Z"
                transform="translate(0 -17.221)"
                fill="#F7447A"
            />
            <path id="Path_8246" data-name="Path 8246" d="M5.16,0,0,4.2V7.294l5.161-4.2,5.161,4.2V4.2Z" fill="#F7447A" />
        </g>
    </svg>
);

export const noRiskIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="10.643" height="2.5" viewBox="0 0 10.643 2.5">
        <path
            id="Path_8308"
            data-name="Path 8308"
            d="M-16483.9,1735.477h10.643"
            transform="translate(16483.902 -1734.227)"
            fill="none"
            stroke="#d6d6d6"
            strokeWidth="2.5"
            opacity="0.86"
        />
    </svg>
);
