import { DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import StepSegment from "../../../processingActivity/stepSegment/StepSegment";
import SystemAIRadioGroup from "./SystemAIRadioGroup";
import { Accordion, Collapse } from "@mui/material";

export interface ISystemAIStepProps {
    usesAI: boolean;
    companyUsesAIService: boolean;
    aiUsageDescription: string | undefined;
    isUseOfAIConsideredHighRisk: boolean;
    isDataUsedForTrainingOfAIModel: boolean;
    onUsesAIChange: (value: boolean) => void;
    onCompanyUsesAIServiceChange: (value: boolean) => void;
    onDescriptionOfAIUsageChange: (value: string) => void;
    onIsUseOfAIConsideredHighRiskChange: (value: boolean) => void;
    onIsDataUsedForTrainingOfAIModelChange: (value: boolean) => void;
    readOnly: boolean;
}

function SystemAIStep(props: ISystemAIStepProps) {
    const { translateString } = useTranslation();
    return (
        <StepSegment size="small">
            <SystemAIRadioGroup
                isChecked={props.usesAI}
                onCheckedChange={props.onUsesAIChange}
                isReadOnly={props.readOnly}
                label={translateString("assetUseAI")}
            />
            <Collapse in={props.usesAI}>
                <SystemAIRadioGroup
                    isChecked={props.companyUsesAIService}
                    onCheckedChange={props.onCompanyUsesAIServiceChange}
                    isReadOnly={props.readOnly}
                    label={translateString("companyUsesAIProvidedByAsset")}
                />

                <Collapse in={props.companyUsesAIService}>
                    <DotLegalTextField
                        debounce
                        value={props.aiUsageDescription ?? ""}
                        label={translateString("descriptionOfAIUsage")}
                        multiline
                        rows={5}
                        disabled={props.readOnly}
                        onChange={props.onDescriptionOfAIUsageChange}
                    />
                    <SystemAIRadioGroup
                        isChecked={props.isUseOfAIConsideredHighRisk}
                        onCheckedChange={props.onIsUseOfAIConsideredHighRiskChange}
                        isReadOnly={props.readOnly}
                        label={translateString("useOfAIConsideredHighRisk")}
                    />
                    <SystemAIRadioGroup
                        isChecked={props.isDataUsedForTrainingOfAIModel}
                        onCheckedChange={props.onIsDataUsedForTrainingOfAIModelChange}
                        isReadOnly={props.readOnly}
                        label={translateString("isDataUsedForTraningOfAIModel")}
                    />
                </Collapse>
            </Collapse>
        </StepSegment>
    );
}

export default SystemAIStep;
