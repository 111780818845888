import { DotLegalButton, DotLegalCheckbox } from "@dotlegal/dotlegal-ui-components";
import DotLegalCollapse from "../../../common/components/dotLegalCollapse/DotLegalCollapse";
import DotLegalStepHeader from "../../../common/components/dotLegalStepHeader/DotLegalStepHeader";
import { useTranslation } from "../../../localization/useTranslation";
import StepSegment from "../../../processingActivity/stepSegment/StepSegment";
import useSystemRelationStepHooks from "./SystemRelationStep.hooks";
import SystemRelationSelector from "./systemRelationSelector/SystemRelationSelector";
import { useSystemRelationStepStyles } from "./SystemRelationStep.styles";
import { Box } from "@mui/system";
import { Skeleton, Typography } from "@mui/material";
import { useUrlProvider } from "../../../useUrlProvider";
import { dotlegalLinkIcon } from "../../../common/icons";

export interface ISystemRelationStep {
    id: string;
    relatedSystems: Array<string>;
    isLoading: boolean;
    disableSystemRelations: boolean;
    onDisableSystemRelationsChange: (checked: boolean) => void;
    readOnly: boolean;
}

function SystemRelationStep(props: ISystemRelationStep) {
    const { translateString } = useTranslation();
    const { getSystemEditUrl } = useUrlProvider();
    const {
        getExpandedId,
        setExpandedId,
        data,
        isLoading,
        handleRelationChange,
        isAddingNewRelation,
        setIsAddingNewRelation,
        onExpandClick,
        handleRemoveSystem,
    } = useSystemRelationStepHooks(props);
    const styles = useSystemRelationStepStyles();

    function getSelectableItems(existingSystemId?: string) {
        return data?.filter((x) => !props.relatedSystems.includes(x.id) || existingSystemId === x.id);
    }

    const getLinkIcon = () => {
        return <Box sx={{ "& svg": { height: 20, width: 20 } }}>{dotlegalLinkIcon}</Box>;
    };

    function getSelectedSystems() {
        return props.relatedSystems.map((s) => {
            var system = data!.find((x) => x.id === s)!;
            return (
                <DotLegalCollapse
                    disabled={props.readOnly}
                    centerContent={<Typography sx={styles.collapseHeaderText}>{system.name}</Typography>}
                    sx={{ marginBottom: 2 }}
                    key={system.id}
                    isExpanded={system.id === getExpandedId()}
                    onExpandClick={() => onExpandClick(system.id)}
                    onRemoveClick={() => handleRemoveSystem(system.id)}
                    removeItemText={translateString("removeAsset")}
                    collapseIcon={{
                        icon: getLinkIcon(),
                        iconHoverText: translateString("goToWithName", { name: system.name }),
                        href: `${getSystemEditUrl(system.id)}?step=5#${props.id}`,
                    }}
                >
                    <SystemRelationSelector
                        disabled={props.readOnly}
                        isLoading={isLoading}
                        selectedItem={system}
                        selecableItems={getSelectableItems(system.id)}
                        onAssetChange={(id) => handleRelationChange(id)}
                    />
                </DotLegalCollapse>
            );
        });
    }

    function getLoadingState() {
        return props.relatedSystems.map((s, i) => {
            return <Skeleton height={80} width={"100%"} key={i} />;
        });
    }

    return (
        <StepSegment size="large">
            <DotLegalStepHeader bottomMargin>{translateString("associatedAssetsHeader")}</DotLegalStepHeader>

            {isLoading ? getLoadingState() : getSelectedSystems()}

            {isAddingNewRelation && (
                <DotLegalCollapse
                    isExpanded
                    onExpandClick={() => {}}
                    onRemoveClick={() => setIsAddingNewRelation(false)}
                    removeItemText={translateString("removeAsset")}
                >
                    <SystemRelationSelector
                        disabled={props.readOnly}
                        isLoading={isLoading}
                        selecableItems={getSelectableItems()}
                        onAssetChange={(id) => handleRelationChange(id)}
                    />
                </DotLegalCollapse>
            )}

            <Box sx={styles.addSystem}>
                <DotLegalButton
                    disabled={isAddingNewRelation || props.disableSystemRelations || props.readOnly}
                    buttonType="secondary"
                    onClick={() => {
                        setIsAddingNewRelation(true);
                        setExpandedId("");
                    }}
                >
                    {translateString("addAsset")}
                </DotLegalButton>
            </Box>

            {props.relatedSystems.length === 0 && !isAddingNewRelation && (
                <Box sx={styles.checkboxContainer}>
                    <DotLegalCheckbox
                        disabled={props.readOnly}
                        onChange={(checked) => props.onDisableSystemRelationsChange(checked)}
                        label={translateString("disableSystemAssociatedAssets")}
                        checked={props.disableSystemRelations}
                    />
                </Box>
            )}
        </StepSegment>
    );
}

export default SystemRelationStep;
