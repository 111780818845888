import { useTranslation } from "../../localization/useTranslation";
import { useLanguageMenuStyle } from "./LanguageMenu.styles";
import Language from "./language.svg?react";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { DotLegalTooltip } from "@dotlegal/dotlegal-ui-components";
import { Box, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";

export interface ILanguageMenu {
    isExpanded: boolean;
}

function LanguageMenu(props: ILanguageMenu) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { changeLanguage, translateString } = useTranslation();
    const styles = useLanguageMenuStyle();
    const userContext = useUserContext();

    const handleMenuSelect = (lang: string | null) => {
        setAnchorEl(null);

        if (lang) changeLanguage(lang);
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const LangMenuItem = ({ lang }: { lang: string }) => (
        <MenuItem sx={userContext.language === lang ? styles.activeLanguage : {}} onClick={() => handleMenuSelect(lang)}>
            {lang.toUpperCase()}
        </MenuItem>
    );

    const handleClose = () => {
        setAnchorEl(null);
    };

    return props.isExpanded ? (
        <Box component={"li"} sx={styles.languageContainer}>
            <Box sx={styles.menuIcon}>
                <Language />
            </Box>
            <Box sx={styles.languageList}>
                <Box component={"span"} onClick={() => changeLanguage("da")} sx={[userContext.language === "da" ? styles.activeLanguage : {}, styles.language]}>
                    DA
                </Box>
                <Box component={"span"} sx={styles.splitter}>
                    |
                </Box>
                <Box component={"span"} onClick={() => changeLanguage("en")} sx={[userContext.language === "en" ? styles.activeLanguage : {}, styles.language]}>
                    EN
                </Box>
                {userContext.germanEnabled && (
                    <>
                        <Box component={"span"} sx={styles.splitter}>
                            |
                        </Box>
                        <Box
                            component={"span"}
                            onClick={() => changeLanguage("de")}
                            sx={[userContext.language === "de" ? styles.activeLanguage : {}, styles.language]}
                        >
                            DE
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    ) : (
        <React.Fragment>
            <Box component={"li"} sx={styles.minimizedLanguageContainer} onClick={(event: React.MouseEvent<HTMLElement>) => handleClick(event)}>
                <DotLegalTooltip text={translateString("language")} position={"right"} tooltipStyle="primary" useBoldText>
                    <Box id="simple-menu" sx={styles.minimizedLanguage}>
                        <Box sx={styles.menuIcon}>
                            <Language />
                        </Box>
                    </Box>
                </DotLegalTooltip>
            </Box>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={Boolean(anchorEl)}
                onClose={() => handleClose()}
            >
                <LangMenuItem lang={"da"} />
                <LangMenuItem lang={"en"} />
                {userContext.germanEnabled && <LangMenuItem lang={"de"} />}
            </Menu>
        </React.Fragment>
    );
}

export default LanguageMenu;
