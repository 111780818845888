import { Box, Typography } from "@mui/material";
import { DotLegalDialog, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import React from "react";
import DotLegalStepHeader from "../../../common/components/dotLegalStepHeader/DotLegalStepHeader";
import { useTranslation } from "../../../localization/useTranslation";
import { useSharingsInfoBoxHook } from "./SharingsInfoBox.hooks";
import { useSharingsInfoBoxStyles } from "./SharingsInfoBox.styles";

export interface ISharingsInfoProps {
    name?: string;
    description?: string;
    disabled?: boolean;
    onDescriptionChange: (description: string) => void;
    onNameChange: (name: string) => void;
    hideDescription?: boolean;
}

function SharingsInfoBox(props: ISharingsInfoProps) {
    const styles = useSharingsInfoBoxStyles();
    const { translateString } = useTranslation();
    const { showDescription, setShowDescription, description, setDescription } = useSharingsInfoBoxHook(props.description ?? "");

    const OkClick = () => {
        props.onDescriptionChange(description);
        setShowDescription(false);
    };

    return (
        <Box sx={styles.container}>
            <DotLegalStepHeader topMargin record={false}>
                {translateString("sharingsName")}
            </DotLegalStepHeader>

            <DotLegalTextField
                debounce
                disabled={props.disabled}
                onChange={(e) => props.onNameChange(e)}
                label={translateString("name")}
                value={props.name ?? ""}
                toolTipText={translateString("sharingName")}
                maxLength={255}
                maxLengthErrorText={translateString("sharingNameRestriction")}
            />

            {props.hideDescription !== true && (
                <Typography onClick={() => setShowDescription(true)} sx={styles.descriptionLabel}>
                    {props.description || props.disabled ? translateString("openDescription") : translateString("addDescription")}
                </Typography>
            )}

            <DotLegalDialog
                header={translateString("description")}
                buttonOkText={translateString("save")}
                open={showDescription}
                onDialogClose={() => setShowDescription(false)}
                onOkClick={OkClick}
                buttonRight
                size={"sm"}
                hideOkButton={props.disabled}
                disableBackdropOnCloseIfValueChange={description}
            >
                <DotLegalTextField
                    debounce={false}
                    label={translateString("description")}
                    onChange={(e) => setDescription(e)}
                    value={description}
                    multiline
                    rows={15}
                    disabled={props.disabled}
                />
            </DotLegalDialog>
        </Box>
    );
}

export default SharingsInfoBox;
