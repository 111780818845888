import React from "react";
import DotLegalMultiSelectWithButton from "./DotLegalMultiSelectWithButton";
import { IDotLegalSelect } from "@dotlegal/dotlegal-ui-components";

export interface IDotLegalSelectWithButton extends IDotLegalSelect {
    onButtonClick: () => void;
    buttonLabel: string;
    hasPermission?: boolean;
    onSearchValueChange?: (value: string) => void;
    searchValue?: string;
    noOptionsLabel: string;
    selectedValueText?: string;
}

export default function DotLegalSelectWithButton(props: IDotLegalSelectWithButton) {
    const selected = [];
    if (props.selectedItem) {
        selected.push(props.selectedItem);
    }

    return <DotLegalMultiSelectWithButton {...props} onChange={(o) => props.onChange(o.length > 0 ? o[0] : null)} chosenOptions={selected} singleSelect />;
}
