import { isNullOrWhitespace } from "../stringOperations";
import { useLocation } from "react-router-dom";

export function useUrlParams() {
    const location = useLocation();
    return {
        getUrlParams: getUrlParams(location.search),
    };
}

export function getUrlParams(params: string): UrlParams {
    if (isNullOrWhitespace(params)) {
        return new UrlParams();
    }
    params = params.substr(1, params.length - 1);
    const result = new UrlParams();
    params.split("&").forEach((p) => {
        var parts = p.split("=");
        result.push({ name: parts[0], value: parts[1] });
    });
    return result;
}

export class UrlParams {
    params: Array<urlParam> = [];
    push(p: urlParam) {
        this.params.push(p);
    }

    getValue(name: string) {
        return this.params.find((p) => p.name === name)?.value;
    }
}

export interface urlParam {
    name: string;
    value: string;
}
