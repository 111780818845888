import React, { ReactNode } from "react";
import { Box } from "@mui/material";
import backgroundWithBlobs from "../../../common/svgs/backgroundWithBlops.svg";
import TwoColumnContainer from "./twoColumnContainer/TwoColumnContainer..tsx";
import LeftColumn from "./twoColumnContainer/leftColumn/LeftColumn.tsx";
import RightColumn from "./twoColumnContainer/rightColumn/RightColumn.tsx";
import { DotLegalFullScreenSpinner, useIsOnSmallScreen } from "@dotlegal/dotlegal-ui-components";
import DotLegalLogo from "../../../main/NavMenu/dotLegalLogo.svg?react";
import { usePublicInternalAssessmentContainer } from "./PublicInternalAssessmentContainer.hooks.ts";

export interface IPublicInternalAssessmentContainerProps {
    children: ReactNode;
}

function PublicInternalAssessmentContainer(props: IPublicInternalAssessmentContainerProps) {
    const isOnSmallScreen = useIsOnSmallScreen();
    const { data, isLoading } = usePublicInternalAssessmentContainer();
    return (
        <>
            {isLoading ? (
                <DotLegalFullScreenSpinner />
            ) : (
                <>
                    <Box
                        sx={{
                            backgroundColor: isOnSmallScreen ? "rgb(114, 132, 250)" : undefined,
                            height: "5%",
                            "& svg": { height: "130px", width: "200px" },
                            paddingLeft: "20px",
                        }}
                    >
                        <a href="https://dotlegal.com/en">{<DotLegalLogo />}</a>
                    </Box>
                    <Box
                        sx={{
                            height: "95%",
                            backgroundImage: isOnSmallScreen ? undefined : `url("${backgroundWithBlobs}")`,
                            backgroundColor: isOnSmallScreen ? "rgb(114, 132, 250)" : undefined,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "102% 100%",
                            padding: "10%",
                        }}
                    >
                        <TwoColumnContainer>
                            <LeftColumn>
                                <Box sx={{ display: "flex", flexDirection: "column", color: "white" }}>
                                    <Box sx={{ fontSize: "30px", fontWeight: "semibold" }}>{data?.groupEntityName}</Box>
                                    <Box sx={{ fontSize: "56px", fontWeight: "bold" }}>{data?.name}</Box>
                                    <Box
                                        sx={{
                                            fontSize: "30px",
                                            fontWeight: "semibold",
                                            marginTop: "30px",
                                        }}
                                    >
                                        {data?.description}
                                    </Box>
                                </Box>
                            </LeftColumn>
                            <RightColumn>
                                <Box
                                    sx={{
                                        width: isOnSmallScreen ? "100%" : 500,
                                        background: "white",
                                        padding: "48px",
                                        borderRadius: "25px",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    {props.children}
                                </Box>
                            </RightColumn>
                        </TwoColumnContainer>
                    </Box>
                </>
            )}
        </>
    );
}

export default PublicInternalAssessmentContainer;
