import React from "react";
import DotLegalDeleteDialogWrapper from "../../../common/components/dotLegalDeleteDialog/DotLegalDeleteDialogWrapper";
import { LegislationSaveModel } from "../legislationDialog/LegislationDialog.types";
import { useDeleteLegislationDialogDataMapping } from "./DeleteLegislationDialog.hooks";

export interface IDeleteLegislationDialog {
    onCloseDialog: () => void;
    onSaveCorrect: () => void;
    selectedLegislation: LegislationSaveModel;
}

export function DeleteLegislationDialog(props: IDeleteLegislationDialog) {
    const { removeNationalLaw, inProgess } = useDeleteLegislationDialogDataMapping(props);

    return (
        <DotLegalDeleteDialogWrapper
            open
            itemForDeletion={props.selectedLegislation.name}
            onDialogClose={props.onCloseDialog}
            onOkClick={removeNationalLaw}
            inProgress={inProgess}
        />
    );
}
