import { useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";

export const useCustomListViewStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        paper: {
            display: "flex",
            alignItems: "center",
            height: 116 + "px",
            marginBottom: theme.spacing(2),
            position: "relative",
        },
    });
};
