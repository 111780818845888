import { createElement, useState } from "react";
import { Trans } from "react-i18next";
import { useMutation } from "react-query";
import { post, put } from "../../../common/api/apiShared";
import { Result } from "../../../common/api/result";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { ILegislationDialog } from "./LegislationDialog";
import { LegislationSaveModel } from "./LegislationDialog.types";
import { useValidator } from "../../../common/hooks/validation";

export function useLegislationDialogDataMapping(props: ILegislationDialog) {
    const snackbar = useDotLegalSnackbar();
    const { translateString } = useTranslation();
    const [errors, setErrors] = useState("");
    const [viewModel, setViewModel] = useState<LegislationSaveModel>(getLegislationSaveModel(props.legislation));

    const validation = useValidator<LegislationSaveModel>((validators) => ({
        name: validators.validateNotEmpty((item) => item.name, translateString("name")),
    }));

    function save() {
        return post<string>("/legislations/", viewModel);
    }

    function update(id: string) {
        return put<string>(`/legislations/${id}/`, viewModel);
    }

    const saveMutation = useMutation(save, {
        onSuccess: (response: Result<string>) => {
            if (response.success()) {
                props.onDialogClose();
                props.onSave(response.value());
                snackbar.show(createElement(Trans, { i18nKey: "legislationCreated", values: { name: viewModel?.name } }));
            } else {
                snackbar.show(createElement(Trans, { i18nKey: "legislationExist" }), "warning");
            }
        },
    });

    const createLegislation = async () => {
        await saveMutation.mutateAsync();
    };

    const editMutation = useMutation(update);
    const updateLegislation = () => {
        editMutation.mutateAsync(viewModel!.id, {
            onSuccess: (response: Result<string>) => {
                if (response.success()) {
                    snackbar.show(translateString("legislationUpdated"));
                    props.onDialogClose();
                    props.onSave("");
                } else {
                    snackbar.show(translateString("legislationExist"), "warning");
                }
            },
        });
    };

    function onSaveClick() {
        if (validation.anyHasErrors) {
            validation.setShowErrors(true);
            return;
        }

        if (props.isAdd) {
            createLegislation();
        } else {
            updateLegislation();
        }
    }

    function validateLegislationName(name: string | undefined) {
        if (name) setErrors("");
        else setErrors(translateString("fieldMandatory"));
    }

    function validateForm() {
        if (viewModel?.name) {
            if (props.isAdd) createLegislation();
            else updateLegislation();
        } else setErrors(translateString("fieldMandatory"));
    }

    return {
        viewModel,
        setViewModel,
        isSaving: editMutation.isLoading || saveMutation.isLoading,
        errors,
        validateForm,
        validateLegislationName,
        validation,
        onSaveClick,
    };

    function getLegislationSaveModel(model?: LegislationSaveModel) {
        return {
            name: model?.name ?? "",
            id: model?.id ?? "",
            active: false,
        };
    }
}
