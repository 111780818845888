import { useState } from "react";
import { DeleteLegalEntityAuditGroupDialogProps } from "./DeleteLegalEntityAuditGroupDialog";
import { deleteHttp } from "../../common/api/apiShared";
import { useMutation } from "react-query";
import { useUrlProvider } from "../../useUrlProvider";
import { useNavigate } from "react-router-dom";

export function useDeleteLegalEntityAuditGroupDialog(props: DeleteLegalEntityAuditGroupDialogProps) {
    const urlProvider = useUrlProvider();
    const navigate = useNavigate();

    const [confirmDelete, setConfirmDelete] = useState(false);

    const deleteAuditGroupMutation = useMutation(deleteAuditGroupAPI);
    const onDeleteAuditGroup = async () => {
        await deleteAuditGroupMutation.mutateAsync(undefined, {
            onSuccess: () => {
                navigate(urlProvider.getAuditGroupsUrl());
            },
        });
    };

    return { isDeleting: deleteAuditGroupMutation.isLoading, confirmDelete, setConfirmDelete, onDeleteAuditGroup };

    async function deleteAuditGroupAPI() {
        return deleteHttp(`/legalEntityAuditGroups/${props.auditGroupId}`);
    }
}
