import { IIncidentLogDeleteDialogProps } from "./IncidentLogDeleteDialog";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useMutation } from "react-query";
import { Result } from "../../common/api/result";
import { createElement } from "react";
import { Trans } from "react-i18next";
import { deleteHttp } from "../../common/api/apiShared";

export function useIncidentLogDeleteHook(props: IIncidentLogDeleteDialogProps) {
    const snackbar = useDotLegalSnackbar();

    const removeMutation = useMutation(remove);

    const removeIncidentClick = () => {
        removeMutation.mutateAsync(undefined, {
            onSuccess: (response: Result<string>) => {
                props.onCloseDialog();
                if (response.success()) {
                    props.onSuccessDelete();
                    snackbar.show(createElement(Trans, { i18nKey: "incidentLogDeleted", values: { name: props.name } }));
                } else {
                    snackbar.show(createElement(Trans, { i18nKey: "incidentLogNotDeleted", values: { name: props.name } }), "warning");
                }
            },
        });
    };

    return {
        removeIncidentClick,
        inProgress: removeMutation.isLoading,
    };

    async function remove() {
        return await deleteHttp<string>(`/gdprIncidentLogEntry/${props.id}`);
    }
}
