import { createElement } from "react";
import { Trans } from "react-i18next";
import { useMutation } from "react-query";
import { put } from "../../../common/api/apiShared";
import { Result } from "../../../common/api/result";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { DeletionPeriodViewModel } from "../deletionPeriods/DeletionPeriods.types";
import { IChangeDeletionPeriodStatusDialog } from "./ChangeDeletionPeriodStatusDialog";

export function useChangeDeletionPeriodStatusDialogHooks(props: IChangeDeletionPeriodStatusDialog) {
    const snackbar = useDotLegalSnackbar();

    const changeActiveStatusMutation = useMutation(changeStatus);

    const changeActiveStatus = (deletionPeriod: DeletionPeriodViewModel) => {
        changeActiveStatusMutation.mutateAsync(deletionPeriod, {
            onSuccess: (response: Result<string>) => {
                props.onCloseDialog();

                if (response.success()) {
                    snackbar.show(createElement(Trans, { i18nKey: "activeStatusChanged", values: { name: deletionPeriod.name } }));
                    props.refetch();
                } else {
                    snackbar.show(createElement(Trans, { i18nKey: "deletionPeriodStatusNotChanged", values: { name: deletionPeriod.name } }), "warning");
                }
            },
        });
    };

    function changeStatus(deletionPeriod: DeletionPeriodViewModel) {
        if (deletionPeriod.isCustom)
            return put<string>(`/ProcessingActivity/deletionperiod/${deletionPeriod.id}/toggleDeletionPeriodActive`, !deletionPeriod.active);
        else return put<string>(`/ProcessingActivity/deletionperiod/${deletionPeriod.id}/toggleDeletionPeriodMasterDataActive`, !deletionPeriod.active);
    }

    return {
        changeActiveStatus,
        inProgress: changeActiveStatusMutation.isLoading,
    };
}
