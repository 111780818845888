import { AuthProviderProps } from "oidc-react/build/src/AuthContextInterface";
import { AuthProvider, UserManager } from "oidc-react";
import { getSettings } from "../common/settingsProvider";
import { setJwtToken } from "../common/api/apiShared";

export interface IPrivacyAuthProviderProps {
    children: JSX.Element;
}

let settings = getSettings();

const pdfToken = new URLSearchParams(window.location.search).get("pdfToken");

export const userManager = new UserManager({
    authority: settings.idpAuthority,
    client_id: settings.idpClientId,
    response_type: "code",
    redirect_uri: settings.mainSiteBaseUrl + "/privacy/sign-in-callback",
    post_logout_redirect_uri: settings.mainSiteBaseUrl,
    automaticSilentRenew: true,
    scope: "openid profile offline_access role",
});

const oidcConfig: AuthProviderProps = {
    onBeforeSignIn: () => {
        sessionStorage.setItem("location-before-sign-in", window.location.pathname + window.location.search + window.location.hash);
        return "";
    },
    userManager: userManager,
    autoSignIn: pdfToken === null,
};

if (pdfToken) {
    setJwtToken(pdfToken);
}

function PrivacyAuthProvider(props: IPrivacyAuthProviderProps) {
    return <AuthProvider {...oidcConfig}>{props.children}</AuthProvider>;
}

export default PrivacyAuthProvider;
