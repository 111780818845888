import { useState } from "react";
import { useQuery } from "react-query";
import { get } from "../../common/api/apiShared.ts";
import { PublicInternalAssessmentTemplateViewModel } from "./PublicInternalAssessmentTemplates.types.ts";

export default function usePublicInternalAssessmentTemplatesHooks() {
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [selectedItem, setSelectedItem] = useState<PublicInternalAssessmentTemplateViewModel | null>();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const url = `/publicInternalAssessmentTemplates`;
    let { isLoading, data, refetch } = useQuery(url, () => get<Array<PublicInternalAssessmentTemplateViewModel>>(url));

    const onSave = () => {
        setShowCreateDialog(false);
        refetch();
    };

    return {
        showCreateDialog,
        setShowCreateDialog,
        hasData: isLoading || (data && data.length > 0),
        data,
        isLoading,
        onSave,
        showDeleteDialog,
        setShowDeleteDialog,
        selectedItem,
        setSelectedItem,
        showEditDialog,
        setShowEditDialog,
        refetch,
    };
}
