import { useTheme } from "@mui/material";
import { IDotLegalTextFieldWithSnippets } from "./DotLegalTextFieldWithSnippets";
import { createSxStyles } from "../../createSxStyles";

export const useDotLegalTextFieldWithSnippetsStyles = (props: IDotLegalTextFieldWithSnippets) => {
    const theme = useTheme();
    let borderColor = props.value || props.disabled || props.disableValidation ? theme.palette.primary.main : theme.palette.error.main;
    return createSxStyles({
        wrapper: {
            marginBottom: props.noMargin ? 0 : theme.spacing(4),
        },
        container: {
            border: "1px solid " + borderColor,
            borderRadius: 10 + "px",
            marginTop: theme.spacing(1),
        },
        snippets: {
            marginRight: theme.spacing(1),
            marginTop: theme.spacing(1),
        },
        snippetContainer: {
            display: "flex",
            flexWrap: "wrap",
            maxHeight: props.limitHeight ? 177 : undefined,
        },
        snippetHeader: {
            marginTop: theme.spacing(1),
            fontWeight: theme.typography.fontWeightBold,
            fontStyle: "italic",
            color: theme.palette.primary.dark,
            fontSize: theme.typography.pxToRem(14),
        },
        margin: {
            margin: theme.spacing(1),
            "& .MuiInputBase-root": {
                padding: theme.spacing(1),
                backgroundColor: theme.customPalette.white,
                borderRadius: theme.shape.borderRadius + "px",
            },
        },
        error: {
            color: theme.palette.error.main,
            fontSize: theme.typography.pxToRem(12),
            marginLeft: theme.spacing(1.5),
            marginTop: theme.spacing(0.5),
        },
    });
};
