import React from "react";
import { useContentStyles } from "./Content.styles";
import DotLegalPlayer from "../../common/components/dotLegalPlayer/DotLegalPlayer";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { useContentDataMapping } from "./Content.hooks";
import { Box } from "@mui/system";
import TrialSoonToExpireDialog from "../../plan/trialSoonToExpireDialog/TrialSoonToExpireDialog";
import TrialExpiredDialog from "../../plan/TrialExpiredDialog/TrialExpiredDialog.tsx";
import TrialRibbon from "../../trial/trialRibbon/TrialRibbon";
import { useTrialInformationHook } from "../../trial/TrialInformation.hooks";
import { usePlanContext } from "../../auth/planProvider/PlanProvider.tsx";
import OverviewOfTrialsDialog from "../../plan/overviewOfTrialsDialog/OverviewOfTrialsDialog.tsx";

export interface IContentProps {
    isExpandedBigScreen: boolean;
    isExpandedSmallScreen: boolean;
    isOnSmallScreen: boolean;
    children: React.ReactElement;
}

const pdfToken = new URLSearchParams(window.location.search).get("pdfToken");

function Content(props: IContentProps) {
    const styles = useContentStyles(props, !!pdfToken);
    const userContext = useUserContext();
    const { addonsFeatures } = usePlanContext();
    const { videoLink, showIntroVideo, setShowIntroVideo } = useContentDataMapping(userContext.isFirstLogin, addonsFeatures.research);
    const { isLoading, data, setShowOverviewOfTrialsDialog, showOverviewOfTrialsDialog } = useTrialInformationHook();

    return (
        <Box sx={styles.wrapper}>
            <>
                {!isLoading && data?.showTrialDialogs && (
                    <React.Fragment>
                        <TrialSoonToExpireDialog data={data} />
                        <TrialExpiredDialog data={data} />
                        <TrialRibbon onShowTrialsOverClick={() => setShowOverviewOfTrialsDialog(true)} data={data} />
                        {showOverviewOfTrialsDialog && (
                            <OverviewOfTrialsDialog
                                plansOnTrials={data.plansOnTrials?.filter((x) => !x.isExpired) ?? []}
                                onDialogClose={() => setShowOverviewOfTrialsDialog(false)}
                            />
                        )}
                    </React.Fragment>
                )}
            </>

            <Box sx={styles.content}>{props.children}</Box>
            {showIntroVideo && <DotLegalPlayer url={videoLink!} onCloseDialog={() => setShowIntroVideo(false)} />}
        </Box>
    );
}

export default Content;
