import { createSxStyles } from "../../common/createSxStyles";
import { useTheme } from "@mui/material";

export const useAnnualWheelDialogStyles = (isOnSmallScreen: boolean) => {
    const theme = useTheme();
    return createSxStyles({
        content: {
            width: "200px",
            display: "flex",
            alignItems: "start",
        },
        numberField: {
            width: 50,
            marginRight: theme.spacing(1),
        },
        questionHeader: {
            fontSize: theme.typography.pxToRem(14),
            color: theme.palette.primary.dark,
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(0.5),
        },
        lockedActivityItem: {
            marginTop: theme.spacing(1),
            height: 75,
            borderRadius: 1,
            display: "flex",
            alignItems: "center",
            opacity: 0.38,
        },
        imgContainer: {
            marginLeft: theme.spacing(1),
            backgroundColor: theme.palette.primary.light,
            height: 50,
            width: 50,
            justifyContent: "center",
            display: "grid",
            alignItems: "center",
            borderRadius: 1,
            marginRight: theme.spacing(2),
            "& svg": {
                width: 24,
                height: 24,
            },
        },
        error: {
            fontSize: 12,
            color: theme.palette.error.main,
        },
        interval: {
            display: "flex",
            alignItems: "center",
            verticalAlign: "center",
            color: theme.palette.primary.dark,
            fontSize: theme.typography.pxToRem(14),
        },
        each: {
            marginRight: theme.spacing(1),
        },
        notificationPeriod: {
            display: "flex",
            alignItems: "center",
            verticalAlign: "center",
            color: theme.palette.primary.dark,
            fontSize: theme.typography.pxToRem(14),
        },
        singularActivity: {
            display: "flex",
            alignItems: "center",
            color: theme.palette.primary.dark,
            fontSize: theme.typography.pxToRem(14),
            marginBottom: -1.5,
        },
        contentWrapper: {
            display: "flex",
            justifyContent: "center",
        },
        formSpacing: {
            width: isOnSmallScreen ? "100%" : "70%",
        },
        subTasksToolTip: {
            margin: 1.5,
        },
    });
};
