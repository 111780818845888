import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { DotLegalInformationTooltip } from "@dotlegal/dotlegal-ui-components";
import { useLegalBasisSelectorContainerStyles } from "./LegalBasisSelectorContainer.styles";

export interface ILegalBasisSelectorContainer {
    headerText: string;
    toolTipText: string;
    children?: any;
}

function LegalBasisSelectorContainer(props: ILegalBasisSelectorContainer) {
    const styles = useLegalBasisSelectorContainerStyles();

    return (
        <React.Fragment>
            <Box sx={styles.dataCategoryHeader}>
                <Typography sx={styles.infoHeader}>{props.headerText}</Typography>
                <Box sx={styles.tooltip}>
                    <DotLegalInformationTooltip text={props.toolTipText} />
                </Box>
            </Box>

            <Divider sx={styles.divider} />
            {props.children}
        </React.Fragment>
    );
}

export default LegalBasisSelectorContainer;
