import React from "react";
import DotLegalDeleteDialogWrapper from "../../../common/components/dotLegalDeleteDialog/DotLegalDeleteDialogWrapper";
import { DataCategorySaveModel } from "../dataCategories/DataCategories.types";
import { useDeleteDataCategoryDialogHooks } from "./DeleteDataCategoryDialog.hooks";

export interface IDeleteDataCategoryDialog {
    onCloseDialog: () => void;
    onSaveCorrect: () => void;
    selectedDataCatagory: DataCategorySaveModel;
}

export default function DeleteDataCategoryDialog(props: IDeleteDataCategoryDialog) {
    const { removePurpose, inProgess } = useDeleteDataCategoryDialogHooks(props);

    return (
        <DotLegalDeleteDialogWrapper
            open
            itemForDeletion={props.selectedDataCatagory.name}
            onDialogClose={props.onCloseDialog}
            onOkClick={removePurpose}
            inProgress={inProgess}
        />
    );
}
