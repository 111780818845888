import { Box, useTheme } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { useTranslation } from "../../../localization/useTranslation";
import React from "react";
import { DotLegalEmptyState, DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import { setFirstLetterToLowerCase } from "../../../common/stringOperations";
import { AnnualWheelMonthEnum } from "../../../annualWheel/AnnualWheel.types";
import WhiteDashboardBox from "../../WhiteDashboardBox";
import DotLegalLink from "../../../common/components/dotLegalLink/DotLegalLink";
import EmptyStateIcon from "../../svgs/Task overview.svg?react";
import { useUrlProvider } from "../../../useUrlProvider";
import { useNavigate } from "react-router-dom";
import { taskManagementAllMyStorageKey } from "../../../taskManagement/TaskManagement.hooks.ts";

export interface ITaskOverviewGraphProps {
    data: { month: number; numberOfCompletedTasks: number; numberOfOverdueTasks: number; numberOfPlannedTasks: number; numberOfOngoingTasks: number }[];
    highestNumberOfTasksInAMonth: number;
    year: number;
    isPdf?: boolean;
    isLoading: boolean;
    onlyUserSpecific: boolean;
    showLegends?: boolean;
}

function TaskOverviewGraph(props: ITaskOverviewGraphProps) {
    const { translateString, translateShortMonth } = useTranslation();
    const { getAnnualWheelUrl, getTaskManagementUrl } = useUrlProvider();
    const navigate = useNavigate();

    const overdueColor = "rgb(254, 159, 188)";
    const completedColor = "rgb(44, 213, 196)";
    const plannedColor = "rgb(114, 132, 250)";
    const ongoingColor = "rgb(189, 211, 236)";
    const theme = useTheme();

    function getGraphTooltipContent(numberOfTasks: number | undefined, title: string, color: string) {
        if (!numberOfTasks) return <React.Fragment></React.Fragment>;
        return (
            numberOfTasks !== 0 && (
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "10px", marginRight: "10px", fontSize: "14px" }}>
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Box sx={{ width: "15px", height: "15px", backgroundColor: color, marginRight: "10px" }}></Box>
                        <Box>{translateString(title)}</Box>
                    </Box>
                    <Box sx={{ display: "flex" }}>
                        <Box sx={{ fontWeight: "bold" }}>{numberOfTasks}</Box>
                    </Box>
                </Box>
            )
        );
    }

    function getGraphTooltip(month: number, index: number) {
        const shouldUseCustomTransform = index > 8;

        if (!props.data) return <Box></Box>;
        const tasksInMonth = props.data.find((x) => x.month === month);
        return (
            <Box
                sx={(theme) => ({
                    display: "flex",
                    flexDirection: "column",
                    background: "white",
                    width: "200px",
                    paddingLeft: "10px",
                    paddingTop: "5px",
                    paddingRight: "10px",
                    paddingBottom: "5px",
                    justifyContent: "center",
                    borderRadius: "10px",
                    color: theme.palette.primary.dark,
                    boxShadow: "0px 3px 10px #E7E7E7",
                    transform: shouldUseCustomTransform ? "translate(-80px,0)" : "translate(0,0)",
                })}
            >
                <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                    <DotLegalHeader headerStyle={"small"} marginBottom={0} color={"lightgrey"}>
                        {translateString(setFirstLetterToLowerCase(AnnualWheelMonthEnum[month].toString()))} {props.year}
                    </DotLegalHeader>
                </Box>
                {getGraphTooltipContent(tasksInMonth?.numberOfPlannedTasks, "planned", plannedColor)}
                {getGraphTooltipContent(tasksInMonth?.numberOfOngoingTasks, "inProgress", ongoingColor)}
                {getGraphTooltipContent(tasksInMonth?.numberOfCompletedTasks, "completed", completedColor)}
                {getGraphTooltipContent(tasksInMonth?.numberOfOverdueTasks, "overdue", overdueColor)}
            </Box>
        );
    }

    function getYAxisTicks() {
        if (props.highestNumberOfTasksInAMonth === 1) return ["0", "1"];
        if (props.highestNumberOfTasksInAMonth <= 10) return ["0", "2", "4", "6", "8", "10"];
        if (props.highestNumberOfTasksInAMonth <= 25) return ["0", "5", "10", "15", "20", "25"];
        if (props.highestNumberOfTasksInAMonth <= 50) return ["0", "10", "20", "30", "40", "50"];
        if (props.highestNumberOfTasksInAMonth <= 100) return ["0", "20", "40", "60", "80", "100"];
        return undefined; //returning undefined will fallback to the default graph ticks
    }

    const getLegends = (): Array<{ id: string; label: string; color: string }> => {
        let legends = [];

        if (props.data.some((x) => x.numberOfPlannedTasks > 0)) {
            legends.push({ id: "numberOfPlannedTasks", label: translateString("planned"), color: plannedColor });
        }
        if (props.data.some((x) => x.numberOfOngoingTasks > 0)) {
            legends.push({ id: "numberOfOngoingTasks", label: translateString("inProgress"), color: ongoingColor });
        }
        if (props.data.some((x) => x.numberOfCompletedTasks > 0)) {
            legends.push({ id: "numberOfCompletedTasks", label: translateString("completed"), color: completedColor });
        }
        if (props.data.some((x) => x.numberOfOverdueTasks > 0)) {
            legends.push({ id: "numberOfOverdueTasks", label: translateString("overdue"), color: overdueColor });
        }

        return legends;
    };

    return (
        <>
            {props.isLoading || props.highestNumberOfTasksInAMonth === 0 ? (
                <WhiteDashboardBox marginTop={1} isLoading={props.isLoading} fullHeight>
                    <DotLegalEmptyState
                        noFixedHeight
                        noPadding
                        textColor="darkBlue"
                        text={
                            <Box component={"span"} sx={(theme) => ({ display: "flex", flexDirection: "column" })}>
                                {translateString("taskOverviewWidgetNoTasks")}
                                <DotLegalLink linkColor={"primary"} to={getAnnualWheelUrl()}>
                                    {translateString("taskOverviewWidgetCreateTask")}
                                </DotLegalLink>
                            </Box>
                        }
                        icon={<EmptyStateIcon />}
                    />
                </WhiteDashboardBox>
            ) : (
                <Box
                    sx={{
                        width: "100%",
                        height: 0,
                        borderRadius: "20px",
                        flex: "1 1 auto",
                        backgroundColor: "white",
                        "g rect:hover": { cursor: "pointer" },
                        marginTop: 2,
                        paddingTop: "15px",
                        overflow: "hidden",
                        paddingBottom: props.isPdf ? 2 : 0,
                    }}
                >
                    {props.data && (
                        <ResponsiveBar
                            maxValue={props.highestNumberOfTasksInAMonth}
                            data={props.data}
                            keys={["numberOfPlannedTasks", "numberOfOngoingTasks", "numberOfCompletedTasks", "numberOfOverdueTasks"]}
                            indexBy="month"
                            margin={{ top: 10, right: 10, bottom: 50, left: 60 }}
                            padding={0.3}
                            label={"false"}
                            valueScale={{ type: "linear" }}
                            colors={({ id }) => {
                                if (id === "numberOfCompletedTasks") return completedColor;
                                if (id === "numberOfOverdueTasks") return overdueColor;
                                if (id === "numberOfPlannedTasks") return plannedColor;
                                return ongoingColor;
                            }}
                            onClick={(x) => {
                                sessionStorage.setItem(taskManagementAllMyStorageKey, props.onlyUserSpecific.toString());
                                navigate(getTaskManagementUrl(`?months=${x.index + 1}`));
                            }}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legendPosition: "middle",
                                legendOffset: 32,
                                format: (value) => `${translateShortMonth(value - 1)}`,
                            }}
                            axisLeft={{
                                legend: translateString("tasks"),
                                legendPosition: "middle",
                                legendOffset: -45,
                                tickValues: getYAxisTicks(),
                            }}
                            tooltip={(x) => getGraphTooltip(x.data.month, x.index)}
                            theme={{
                                axis: {
                                    legend: {
                                        text: {
                                            fontSize: 16,
                                            fill: theme.palette.primary.dark,
                                            fontWeight: "bold",
                                        },
                                    },
                                    ticks: {
                                        text: {
                                            fontSize: 14,
                                            fill: theme.palette.primary.dark,
                                            fontWeight: "bold",
                                        },
                                    },
                                },
                            }}
                            legends={
                                props.showLegends
                                    ? [
                                          {
                                              dataFrom: "keys",
                                              anchor: "bottom-left",
                                              direction: "row",
                                              justify: false,
                                              translateX: 10,
                                              translateY: 45,
                                              itemsSpacing: 2,
                                              itemWidth: 113,
                                              itemHeight: 10,
                                              itemDirection: "left-to-right",
                                              itemOpacity: 0.85,
                                              symbolSize: 20,

                                              data: getLegends(),
                                          },
                                      ]
                                    : undefined
                            }
                        />
                    )}
                </Box>
            )}
        </>
    );
}

export default TaskOverviewGraph;
