import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { get, post } from "../../common/api/apiShared";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { isNullOrWhitespace } from "../../common/stringOperations";
import { useTranslation } from "../../localization/useTranslation";
import { ProcessingActivityIndustryAreaEnum, SelectableItem } from "../ProcessingActivity.types";
import { IProcessingActivityTemplatesDialogProps } from "./ProcessingActivityTemplatesDialog";
import { ProcessingActivityTemplatesViewModel, SelectableProcessingActivityTemplate } from "./ProcessingActivityTemplatesDialog.types";
import { GetProcessingActivityPlanLimit } from "../../auth/planProvider/PlanProvider.hooks.ts";

export function useProcessingActivityTemplatesMapping(props: IProcessingActivityTemplatesDialogProps) {
    const snackbar = useDotLegalSnackbar();
    const { translateString } = useTranslation();

    const [selectedBusinessAreaId, setSelectedBusinessAreaId] = useState("");
    const [selectedIndustryAreaId, setSelectedIndustryAreaId] = useState("");
    const [numberOfProcessingActivities, setNumberOfProcessingActivities] = useState<number | undefined>(undefined);
    const [selectedTemplates, setSelectedTemplates] = useState<Array<string>>([]);
    const url = "/ProcessesingActivityTemplates";
    const { data: processingActivityPlanLimitData } = GetProcessingActivityPlanLimit();
    let { isLoading, data } = useQuery(url, () => get<ProcessingActivityTemplatesViewModel>(url));

    let businessAreaData;
    let industryAreaData;
    let businessAreaTemplates;
    let mostPopularTemplates;
    let industryAreaTemplates;

    useEffect(() => {
        setNumberOfProcessingActivities(processingActivityPlanLimitData?.currentCount);
    }, [processingActivityPlanLimitData?.currentCount]);

    if (data) {
        businessAreaData = data.businessAreas
            .map((obj) => {
                let templateCount = data?.templates.filter((x) => x.businessAreaIds.indexOf(obj.id) >= 0);
                return {
                    name: obj.name,
                    id: obj.id,
                    description: "",
                    isSelected: obj.id === selectedBusinessAreaId,
                    templateCount: calculateBusinessAreaTemplateCount(obj.id),
                    hasData: templateCount !== undefined && templateCount.length > 0,
                };
            })
            .sort((a, b) => (a.name > b.name ? 1 : -1));

        industryAreaData = Object.entries(ProcessingActivityIndustryAreaEnum)
            .filter((q) => isNaN(Number(q[0])))
            .map((q) => {
                let templateCount = data?.templates.filter((x) => x.industryAreas.indexOf(Number(q[1])) >= 0);
                return {
                    name: translateString(q[0].toLowerCase()),
                    id: q[1].toString(),
                    description: "",
                    isSelected: q[1].toString() === selectedIndustryAreaId,
                    templateCount: calculateIndustryAreaTemplateCount(q[1].toString()),
                    hasData: templateCount !== undefined && templateCount.length > 0,
                };
            })
            .sort((a, b) => (a.name > b.name ? 1 : -1));

        businessAreaTemplates = filterProcessingActivities();
        mostPopularTemplates = filterMostPopularActivities(data.businessAreas);
        industryAreaTemplates = filterIndustryAreaActivities(data.businessAreas);
    }

    const handleTemplateChange = (selected: boolean, templateId: string, templateIndex: number) => {
        if (selected) {
            addTemplate(templateId);
        } else {
            removeTemplate(templateIndex);
        }
    };

    function removeTemplate(templateIndex: number) {
        var tempSelectedTemplates = [...selectedTemplates];
        tempSelectedTemplates.splice(templateIndex, 1);
        setSelectedTemplates(tempSelectedTemplates);

        let temp = numberOfProcessingActivities !== undefined ? numberOfProcessingActivities - 1 : 0;
        setNumberOfProcessingActivities(temp);
    }

    function addTemplate(templateId: string) {
        var tempSelectedTemplates = [...selectedTemplates];
        tempSelectedTemplates.push(templateId);
        setSelectedTemplates(tempSelectedTemplates);

        let temp = numberOfProcessingActivities !== undefined ? numberOfProcessingActivities + 1 : 1;
        setNumberOfProcessingActivities(temp);
    }

    function calculateBusinessAreaTemplateCount(businessAreaId: string) {
        return data?.templates.filter((x) => x.businessAreaIds.indexOf(businessAreaId) >= 0 && selectedTemplates.indexOf(x.id) >= 0).length;
    }

    function calculateIndustryAreaTemplateCount(industryArea: string) {
        return data?.templates.filter((x) => x.industryAreas.indexOf(Number(industryArea)) >= 0 && selectedTemplates.indexOf(x.id) >= 0).length;
    }

    function filterProcessingActivities() {
        var tempProcessings = [...data!.templates!];
        if (isNullOrWhitespace(selectedBusinessAreaId) === false) {
            tempProcessings = tempProcessings.filter((x) => x.businessAreaIds.length > 0 && x.businessAreaIds.indexOf(selectedBusinessAreaId) >= 0);
            return tempProcessings.sort((a, b) => (a.name > b.name ? 1 : -1));
        } else return [];
    }

    function filterMostPopularActivities(businessAreas: Array<SelectableItem>) {
        var tempProcessings = [...data!.templates!];
        let pupolarTemplates = tempProcessings.filter((x) => x.mostPopular === true);
        return filterTemplatesByBusinessAreas(businessAreas, pupolarTemplates);
    }

    function filterIndustryAreaActivities(businessAreas: Array<SelectableItem>) {
        var tempProcessings = [...data!.templates!];

        if (isNullOrWhitespace(selectedIndustryAreaId) === false) {
            tempProcessings = tempProcessings.filter((x) => x.industryAreas.length > 0 && x.industryAreas.indexOf(Number(selectedIndustryAreaId)) >= 0);
            return filterTemplatesByBusinessAreas(businessAreas, tempProcessings);
        }
        return [];
    }

    function filterTemplatesByBusinessAreas(businessAreas: Array<SelectableItem>, templatesToFilter: Array<SelectableProcessingActivityTemplate>) {
        let processingIds: Array<string> = [];
        let areas = businessAreas.map((bus) => {
            return {
                id: bus.id,
                name: bus.name,
                // eslint-disable-next-line array-callback-return
                templates: templatesToFilter
                    .filter((x) => x.businessAreaIds.indexOf(bus.id) >= 0)
                    .map((processing) => {
                        if (!processingIds.includes(processing.id)) {
                            processingIds.push(processing.id);

                            return processing;
                        }
                        return undefined;
                    })
                    .filter((x) => x !== undefined)
                    .sort((a, b) => (a!.name > b!.name ? 1 : -1)),
            };
        });

        return areas;
    }

    const saveMutation = useMutation(save);
    const postTemplates = () => {
        saveMutation.mutateAsync(selectedTemplates!, {
            onSuccess: () => {
                snackbar.show(translateString("processingActivityTemplatesAddedSuccess"), "success");
                props.closeDialog();
            },
        });
    };

    return {
        isLoading,
        businessAreaData,
        businessAreaTemplates,
        handleTemplateChange,
        selectedTemplates,
        postTemplates,
        isSaving: saveMutation.isLoading,
        mostPopularTemplates,
        industryAreaData,
        industryAreaTemplates,
        setSelectedIndustryAreaId,
        setSelectedBusinessAreaId,
        addAllTemplatesClick,
        data,
        numberOfProcessingActivities,
        processingActivityPlanLimitData,
    };

    function save(templateIds: Array<string>) {
        return post<string>("/ProcessesingActivityTemplates", templateIds);
    }

    function addAllTemplatesClick(templatesToAdd: Array<SelectableProcessingActivityTemplate> | undefined) {
        if (templatesToAdd) {
            var tempSelectedTemplates = [...selectedTemplates];
            templatesToAdd.forEach((template) => {
                if (template && !selectedTemplates.includes(template.id)) {
                    tempSelectedTemplates.push(template.id);
                }
            });
            setSelectedTemplates(tempSelectedTemplates);
        }
    }
}
