import { Box, Divider, Grid } from "@mui/material";
import { AnnualWheelActivityTemplateItem, StandardAnnualWheelActivityCategory } from "../../AnnualWheelDialog.types";
import { SelectableActivityTemplate } from "../selectableActivityTemplate/SelectableActivityTemplate";
import React from "react";
import { DotLegalPrettyScrollbar } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../../localization/useTranslation";

export interface IGdprTemplates {
    templates: Array<StandardAnnualWheelActivityCategory>;
    onSelectActivity: (activity: AnnualWheelActivityTemplateItem) => void;
    selectedActivity?: string;
    searchField: JSX.Element;
    additionalFilterField?: JSX.Element;
    onCopyClick: (activity: AnnualWheelActivityTemplateItem) => void;
}

function GdprTemplates(props: IGdprTemplates) {
    const { translateAnnualWheelCategoryType, translateString } = useTranslation();

    const getSelectableActivity = (activity: AnnualWheelActivityTemplateItem) => {
        const isLocked = activity.notPartOfCurrentPlan;

        return (
            <SelectableActivityTemplate
                key={activity.id}
                activity={activity}
                selectedActivity={props.selectedActivity}
                isActivityLocked={isLocked}
                onClick={(activity: AnnualWheelActivityTemplateItem) => {
                    if (!isLocked) {
                        props.onSelectActivity(activity);
                    }
                }}
                onCopyClick={() => props.onCopyClick(activity)}
                upgradeText={translateString("upgradeToAccessAllGDPRTemplates")}
            />
        );
    };

    return (
        <React.Fragment>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                {props.additionalFilterField}
                {props.searchField}
            </Box>
            <DotLegalPrettyScrollbar>
                <Box sx={{ p: "2px" }}>
                    {props.templates
                        .sort((a, b) => (a!.categoryType > b!.categoryType ? 1 : -1))
                        .map((x, index) => {
                            return (
                                <React.Fragment key={x.categoryType + index}>
                                    <Box
                                        key={x.categoryType}
                                        sx={(theme) => ({
                                            marginTop: theme.spacing(2),
                                            marginLeft: theme.spacing(1),
                                            fontWeight: theme.typography.fontWeightBold,
                                            color: theme.palette.grey[500],
                                        })}
                                    >
                                        {translateAnnualWheelCategoryType(x.categoryType.toString())}
                                    </Box>
                                    <Grid container columnSpacing={1}>
                                        {x.templates.map((activity) => {
                                            return getSelectableActivity(activity);
                                        })}
                                    </Grid>
                                    {++index !== props.templates.length && <Divider sx={{ marginTop: 1, marginBottom: 3, color: "#D9DAEE" }} />}
                                </React.Fragment>
                            );
                        })}
                </Box>
            </DotLegalPrettyScrollbar>
        </React.Fragment>
    );
}

export default GdprTemplates;
