import { DotLegalSelectOption } from "../../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { useStateUrlParamsArray } from "../../../common/hooks/useStateUrlParams";
import { isNullOrWhitespace } from "../../../common/stringOperations";
import { LevelOfProtectionType } from "../../../legalEntity/addLegalEntityDialog/AddLegalEntityDialog.types";
import { useTranslation } from "../../../localization/useTranslation";
import { ControllerProcess, SharingTypeEnum } from "../../processingActivityOverview/ProcessingActivityOverview.types";
import { ISharingsBaseTableProps } from "./SharingsTable";
import { BaseSharingRowsModel } from "./SharingsTable.types";

export function useSharingsBaseTableHook(props: ISharingsBaseTableProps) {
    const { translateString } = useTranslation();
    const [searchedSenders, setSearchedSenders] = useStateUrlParamsArray("senders", []);
    const [searchedRecipients, setSearchedRecipients] = useStateUrlParamsArray("recipients", []);
    const [searchedSharingType, setSearchedSharingType] = useStateUrlParamsArray("sharingTypes", []);
    const [searchedLevelOfProtecetion, setsearchedLevelOfProtecetion] = useStateUrlParamsArray("levelOfProtecetion", []);
    const [searchedDataCategories, setSearchedDataCategories] = useStateUrlParamsArray("dataCategories", []);

    const filterRowsOnDataProcessorSearch = (activity: BaseSharingRowsModel) => {
        return (
            searchedSharingType.indexOf(SharingTypeEnum.dataProcessor.toString()) >= 0 &&
            (activity.sharingOrigin.systemId || activity.sharingOrigin.dataProcessorId)
        );
    };
    const filterRowsOnDisclosureSearch = (activity: BaseSharingRowsModel) => {
        return searchedSharingType.indexOf(SharingTypeEnum.disclosure.toString()) >= 0 && activity.sharingOrigin.transferId;
    };
    const filterRowsOnSourceSearch = (activity: BaseSharingRowsModel) => {
        return searchedSharingType.indexOf(SharingTypeEnum.source.toString()) >= 0 && activity.sharingOrigin.sourceId;
    };
    const filterRowsOnControllerSearch = (activity: BaseSharingRowsModel) => {
        return searchedSharingType.indexOf(SharingTypeEnum.dataController.toString()) >= 0 && activity.sharingOrigin.dataControllerId;
    };
    const filterRowsOnJointDataControllerSearch = (activity: BaseSharingRowsModel) => {
        return searchedSharingType.indexOf(SharingTypeEnum.jointDataController.toString()) >= 0 && activity.sharingOrigin.jointDataControllerId;
    };

    function recipientsContainsSearchedLevelOfProtection(sharing: BaseSharingRowsModel) {
        return searchedLevelOfProtecetion.indexOf(sharing.recipientLevelOfProtection.toString()) >= 0;
    }

    let sharingsDataRows;
    let datacategoriesData;
    let legalEntitiesData;
    let recipientsEntitiesData;
    let sharingsTypeOptions: Array<DotLegalSelectOption> = [];
    let sharingsLevelOfProtectionData: Array<DotLegalSelectOption> = [];
    const hasSharings = props.isLoading || props.data!.sharingRows?.length > 0;

    if (props.data) {
        sharingsDataRows = getFilteredRows(props.data.sharingRows).map((row) => {
            return {
                id: row.id,
                name: row.name,
                description: row.description,
                senderName: row.senderName,
                senderId: row.senderId,
                senderRole: translateString(ControllerProcess[Number(row.senderRole)]),
                recipientName: row.recipientName,
                recipientId: row.recipientId,
                recipientRole: translateString(ControllerProcess[Number(row.recipientRole)]),
                recipientInThirdCountry: row.recipientInThirdCountry,
                subStep: row.subStep,
                sharingId: row.sharingId,
                sharingOrigin: row.sharingOrigin,
                recipientLevelOfProtection: row.recipientLevelOfProtection,
                dataCategories: row.dataCategories,
            };
        });

        datacategoriesData = props.data.dataCategories?.map((datacategory) => {
            return {
                name: datacategory.name,
                id: datacategory.id,
            };
        });

        legalEntitiesData = props.data.senders?.map((entity) => {
            return {
                name: entity.name,
                id: entity.id,
            };
        });

        recipientsEntitiesData = props.data.recipients?.map((entity) => {
            return {
                name: entity.name,
                id: entity.id,
            };
        });
        CreateSharingsTypeFilter(props.data.sharingRows);
        CreateLevelOfProtectionFilter(props.data.sharingRows);
    }

    function getFilteredRows(sharingRowModel: Array<BaseSharingRowsModel>) {
        let tempData = [...(sharingRowModel ?? [])];

        if (searchedSharingType.length > 0 && !isNullOrWhitespace(searchedSharingType[0])) {
            tempData = tempData.filter(
                (activity) =>
                    filterRowsOnDataProcessorSearch(activity) ||
                    filterRowsOnDisclosureSearch(activity) ||
                    filterRowsOnSourceSearch(activity) ||
                    filterRowsOnControllerSearch(activity) ||
                    filterRowsOnJointDataControllerSearch(activity)
            );
        }
        if (searchedSenders.length > 0 && !isNullOrWhitespace(searchedSenders[0])) {
            tempData = tempData.filter((activity) => searchedSenders.indexOf(activity.senderId) >= 0);
        }
        if (searchedRecipients.length > 0 && !isNullOrWhitespace(searchedRecipients[0])) {
            tempData = tempData.filter((activity) => searchedRecipients.indexOf(activity.recipientId) >= 0);
        }
        if (searchedDataCategories.length > 0 && !isNullOrWhitespace(searchedDataCategories[0])) {
            tempData = tempData.filter((activity) => activity.dataCategories.filter((x) => searchedDataCategories.indexOf(x.id) >= 0).length > 0);
        }
        if (searchedLevelOfProtecetion.length > 0 && !isNullOrWhitespace(searchedLevelOfProtecetion[0])) {
            tempData = tempData.filter((activity) => recipientsContainsSearchedLevelOfProtection(activity));
        }
        return tempData;
    }

    function CreateSharingsTypeFilter(sharingRows: Array<BaseSharingRowsModel>) {
        if (sharingRows?.some((x) => x.sharingOrigin.dataProcessorId || x.sharingOrigin.systemId)) {
            sharingsTypeOptions.push({ name: translateString("dataProcessors"), id: SharingTypeEnum.dataProcessor.toString() });
        }
        if (sharingRows?.some((x) => x.sharingOrigin.dataControllerId)) {
            sharingsTypeOptions.push({ name: translateString("dataControllers"), id: SharingTypeEnum.dataController.toString() });
        }
        if (sharingRows?.some((x) => x.sharingOrigin.transferId)) {
            sharingsTypeOptions.push({ name: translateString("disclosures"), id: SharingTypeEnum.disclosure.toString() });
        }
        if (sharingRows?.some((x) => x.sharingOrigin.sourceId)) {
            sharingsTypeOptions.push({ name: translateString("sources"), id: SharingTypeEnum.source.toString() });
        }
        if (sharingRows?.some((x) => x.sharingOrigin.jointDataControllerId)) {
            sharingsTypeOptions.push({ name: translateString("jointDataControllers"), id: SharingTypeEnum.jointDataController.toString() });
        }
    }

    function CreateLevelOfProtectionFilter(sharingRows: Array<BaseSharingRowsModel>) {
        if (sharingRows?.some((x) => x.recipientLevelOfProtection === LevelOfProtectionType.EU)) {
            sharingsLevelOfProtectionData.push({ name: translateString("eu"), id: LevelOfProtectionType.EU.toString() });
        }
        if (sharingRows?.some((x) => x.recipientLevelOfProtection === LevelOfProtectionType.EEA)) {
            sharingsLevelOfProtectionData.push({ name: translateString("eea"), id: LevelOfProtectionType.EEA.toString() });
        }
        if (sharingRows?.some((x) => x.recipientLevelOfProtection === LevelOfProtectionType.ThirdCountry)) {
            sharingsLevelOfProtectionData.push({ name: translateString("unsafeThirdCountry"), id: LevelOfProtectionType.ThirdCountry.toString() });
        }
        if (sharingRows?.some((x) => x.recipientLevelOfProtection === LevelOfProtectionType.AdequateProtection)) {
            sharingsLevelOfProtectionData.push({ name: translateString("adequiteCountry"), id: LevelOfProtectionType.AdequateProtection.toString() });
        }
    }

    return {
        searchedSenders,
        setSearchedSenders,
        searchedRecipients,
        setSearchedRecipients,
        searchedSharingType,
        setSearchedSharingType,
        searchedLevelOfProtecetion,
        setsearchedLevelOfProtecetion,
        searchedDataCategories,
        setSearchedDataCategories,
        sharingsDataRows,
        datacategoriesData,
        legalEntitiesData,
        recipientsEntitiesData,
        sharingsTypeOptions,
        sharingsLevelOfProtectionData,
        hasSharings,
    };
}
