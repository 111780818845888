import { getEnumValues } from "../../common/enumOperations";
import { BaseViewModel } from "../../system/systemOverview/SystemOverview.types";
import { ApprovalOption } from "../AnnualWheel.types";
import { GroupCompanyResponsible } from "./annualWheelResponsibleForm/AnnualWheelResponsibleForm.types";

export interface StandardAnnualWheelActivityCategory {
    categoryType: AnnualWheelActivityCategoryType;
    templates: Array<AnnualWheelActivityTemplateItem>;
    framework: AnnualWheelActivityFramework;
}

export interface AnnualWheelActivityTemplateItem {
    name: string;
    description: string;
    recurringIntervalInMonths: number | null;
    notificationPeriodInDays: number;
    priority: AnnualWheelPriority;
    id: string; // TODO THE skal være customTemplateId - hvis standard, så null
    isCustom: boolean;
    subTasks: Array<BaseViewModel>;
    framework?: AnnualWheelActivityFramework;
    customId?: string;
    isScheduled: boolean;
    type: AnnualWheelActivityType;
    annaulWheelActivityId: string | null; // TODO THE skal være id - dvs. id'et fra db på AnnualWheelActivity
    templateId: string | null;
    notPartOfCurrentPlan: boolean; // TODO THE skal bruges i nye dialoger
    showTrialWarning: boolean; // TODO THE Denne skal flyttes til at ligge på den ny første side
}

export interface AnnualWheelActivityModel {
    startDate: Date | null;
    priority: AnnualWheelPriority | undefined;
    notificationPeriodInDays: number | undefined;
    interval: number | undefined | null;
    businessAreas: Array<string>;
    responsible: string;
    documentationRequired: boolean;
    id: string;
    areas: Array<string>;
    customId: string | null | undefined;
    groupCompanies: Array<GroupCompanyResponsible>;
    subTasks?: Array<BaseViewModel>;
    description?: string;
    name?: string;
    relatedActivities: Array<string>;
    type?: AnnualWheelActivityType;
    approval: ApprovalOption;
    assignees: Array<string>;
}

// TODO THE Slet AnnualWheelActivitySaveModelLegacy
export interface AnnualWheelActivitySaveModelLegacy {
    startDate: Date | null;
    priority: AnnualWheelPriority | undefined;
    notificationPeriodInDays: number | undefined;
    interval: number | undefined | null;
    businessAreas: Array<string>;
    responsible: string | undefined;
    assignees: Array<string>;
    documentationRequired: boolean;
    id: string;
    areas: Array<string>;
    customId: string | null | undefined;
    groupCompanies: Array<GroupCompanyResponsible>;
    relatedActivities: Array<string>;
    approval: ApprovalOption;
    tags: Array<string> | undefined;
}

export interface AnnualWheelActivitySaveModel {
    startDate: Date | null;
    priority: AnnualWheelPriority | undefined;
    notificationPeriodInDays: number | undefined;
    interval: number | undefined | null;
    businessAreas: Array<string>;
    responsible: string | undefined;
    assignees: Array<string>;
    documentationRequired: boolean;
    type: AnnualWheelActivityType;
    customTemplateId: string | undefined;
    areas: Array<string>;
    customId: string | null | undefined;
    groupCompanies: Array<GroupCompanyResponsible>;
    relatedActivities: Array<string>;
    approval: ApprovalOption;
    tags: Array<string> | undefined;
}

export enum AnnualWheelActivityType {
    Custom = 1,
    ProcessingActivityValidation = 2,
    InspectProceduresAndPolicies = 3,
    CustomizeGDPRTemplates = 4,
    ExamineGuidlineDecisionsFromDDPA = 5,
    ValidateRecord = 6,
    InspectThirdCountryTransfers = 7,
    FollowUpLastYearGDPRProjectsAndPlanNext = 8,
    CompleteYearlyAudit = 9,
    ValidateGDRPOnIntra = 10,
    ValidateGroupEntityBasisForProcessing = 11,
    ReviewConsents = 12,
    SpotChecksOnConsents = 13,
    PlanAwerenessCampaignOne = 14,
    PlanAwerenessCampaignTwo = 15,
    ValidateYourGroupCompanies = 16,
    ReviewConfidentailityAgreements = 17,
    DataProcessorAudit = 18,
    UpdateDataprocessorAgreements = 19,
    AuditSubProcessors = 20,
    UpdatePrivacyPolicyWebsite = 21,
    ReviewSocialMediaPrivacyPolicies = 22,
    CheckCookiePolicyAndBanner = 23,
    ReviewDeletionPolicy = 24,
    CheckForDeFactoDeletion = 25,
    ReviewImageAndVideo = 26,
    AssessNeedForTvSurveilance = 27,
    ReviewRequirementsForJobApplicants = 28,
    ReviewEmployeeDisclosure = 29,
    ReviewPolicyForDataSubjectsRights = 30,
    CheckPolicyForDataSubjectRights = 31,
    ReviewDisclosurRegForResignation = 32,
    ReviewOfRiskAssessment = 33,
    AssessImpactAssessmentsDPIA = 34,
    TestSecurityBreachProcedure = 35,
    CheckLogForSecurityBreaches = 36,
    TestAndFollowUpITContingencyPlan = 37,
    FollowUpAccessRights = 38,
    UpdateItSecurityPolicy = 39,
    CheckDPOsObligations = 40,

    //NIS2 - Implementation
    ClarifyTheDriversForEstablishingNIS2Compliance = 2001,
    ConductGAPAnalysis = 2002,
    PreparationOfFinalActionPlan = 2003,
    OngoingMonthlyStatusMeetingsDuringTheImplementationPhase = 2004,
    EstablishAnOverviewOfAllNetworkAndInformationSystems = 2005,
    DefinitionOfWhatIsCriticalAccordingToNIS2ForTheOrganisation = 2006,
    IdentificationOfCriticalProcessesSystemsBasedOnTheEstablishedOverview = 2007,
    OrganisationalAnchoringOfResponsibilityForTheIdentifiedSystems = 2008,
    DeterminationOfRiskAssessmentMethodology = 2009,
    IdentificationOfRelevantThreatsAndDangersInNetworkAndInformationSystems = 2010,
    SupplyChainSecurity = 2011,
    RiskAssessmentBasedOnIdentifiedThreats = 2012,
    IdentificationOfUnacceptableRisksBasedOnTheRiskAssessment = 2013,
    VerificationOfTheLevelOfTechnicalOperationalAndOrganisationalMeasures = 2014,
    ImplementationOfAdditionalMeasuresThatProveNecessaryBasedOnTheRiskAssessment = 2015,
    DealingWithUnforeseenFindingsDuringTheMappingPhaseThatPoseARiskOrBreachOfInternalGuidelines = 2016,
    PhysicalSecurity = 2017,
    InformationSecurityPolicy = 2018,
    TheOrganisationsRequirementsForSuppliers = 2019,
    TheOrganisationsEffectiveManagementPracticesInConnectionWithOutsourcing = 2020,
    ReviewOfContractsWithSuppliersOfCriticalSystemsEtc = 2021,
    ControlProceduresInRelationToSupplierServices = 2022,
    ProceduresForContractAndSupplierManagement = 2023,
    EstablishRelevantTrainingCoursesForDifferentEmployeeGroups = 2024,
    EmployeeOrientedGuidelines = 2025,
    OverallEmergencyManagement = 2026,
    EmergencyAndRecoveryPlans = 2027,
    EventLogForInternalDocumentationOfEvents = 2028,
    ProcedureForOngoingReviewOrFollowUpOfIncidentHistory = 2029,
    AnnualWheel = 2030,
    Governance = 2031,
    CommunicationToTheOrganisation = 2032,
    ReportingFromTheOrganisation = 2033,
    RevisitingInsuranceCoverage = 2034,

    //NIS - Ongoing operation
    AnnualUpdateOfTheAnnualWheel = 3001,
    ReviewOrganizationalChanges = 3002,
    OngoingTasksAccordingToRelevantStandardsCertificationSchemes = 3003,
    ExecutiveBoardMeetings = 3004,
    BoardMeetings = 3005,
    TheManagementsActiveSupervision = 3006,
    InsuranceIncludingCyberInsurance = 3007,
    NIS2AtStrategicAndTacticalLevel = 3008,
    AccessDepartureOrChangesInTheSystemsFunction = 3009,
    ChangesInTheThreatLevelAndRiskAssessment = 3010,
    VerificationOfTheLevelOfTechnicalOperationalAndOrganizationalMeasures = 3011,
    TestingOfSecurityMeasures = 3012,
    UpdateProcessAndSystemSpecificDocumentation = 3013,
    ChangesInSystemOwnership = 3014,
    ChangesToControlProcedures = 3015,
    AnyChangedSystemOrDataClassification = 3016,
    ChangesInTheOrganizationsProceduresForSupplierManagementAndProcurement = 3017,
    OngoingControlOrAuditsOfSuppliers = 3018,
    UpdateOfSupplierAgreements = 3019,
    YearlyPlanForTrainingTheOrganization = 3020,
    UpdatingOfGeneralGuidanceMaterial = 3021,
    TestOfEmergencyPlans = 3022,
    FollowUpOfEventsOnTheIncidenceLog = 3023,
    UpdatingOfDocumentationIncludingEmergencyPlans = 3024,
    Audits = 3025,
    EvaluationAndFollowUpOfTheControlResults = 3026,

    //ISO27001
    UnderstandingTheOrganisationAndItsContext = 4001,
    UnderstandingTheNeedsAndExpectationsOfInterestedParties = 4002,
    DeterminingTheScopeOfTheInformationSecurityManagementSystem = 4003,
    InformationSecurityManagementSystem = 4004,
    LeadershipAndCommitment = 4005,
    Policy = 4006,
    OrganisationalRolesResponsibilitiesAndAuthorities = 4007,
    GeneralActionsToAddressRisksAndOpportunities = 4008,
    PlanningInformationSecurityRiskAssessment = 4009,
    PlanningInformationSecurityRiskTreatment = 4010,
    InformationSecurityObjectivesAndPlanningToAchieveThem = 4011,
    PlanningOfChanges = 4012,
    Resources = 4013,
    Competence = 4014,
    Awareness = 4015,
    Communication = 4016,
    GeneralDocumentedInformation = 4017,
    CreatingAndUpdating = 4018,
    ControlOfDocumentedInformation = 4019,
    OperationalPlanningAndControl = 4020,
    OperationInformationSecurityRiskAssessment = 4021,
    OperationInformationSecurityRiskTreatment = 4022,
    MonitoringMeasurementAnalysisAndEvaluation = 4023,
    GeneralInternalAudit = 4024,
    InternalAuditProgramme = 4025,
    GeneralManagementReview = 4026,
    ManagementReviewInputs = 4027,
    ManagementReviewResults = 4028,
    ContinualImprovement = 4029,
    NonconformityAndCorrectiveAction = 4030,

    //ISO27002
    PoliciesForInformationSecurity = 5001,
    InformationSecurityRolesAndResponsibilities = 5002,
    SegregationOfDuties = 5003,
    ManagementResponsibilities = 5004,
    ContactWithAuthorities = 5005,
    ContactWithSpecialInterestGroups = 5006,
    ThreatIntelligence = 5007,
    InformationSecurityInProjectManagement = 5008,
    InventoryOfInformationAndOtherAssociatedAssets = 5009,
    AcceptableUseOfInformationAndOtherAssociatedAssets = 5010,
    ReturnOfAssets = 5011,
    ClassificationOfInformation = 5012,
    LabellingOfInformation = 5013,
    InformationTransfer = 5014,
    AccessControl = 5015,
    IdentityManagement = 5016,
    AuthenticationInformation = 5017,
    AccessRights = 5018,
    InformationSecurityInSupplierRelationships = 5019,
    AddressingInformationSecurityWithinSupplierAgreements = 5020,
    ManagingInformationSecurityInTheICTSupplyChain = 5021,
    MonitoringReviewAndChangeManagementOfSupplierServices = 5022,
    InformationSecurityForUseOfCloudServices = 5023,
    InformationSecurityIncidentManagementPlanningAndPreparation = 5024,
    AssessmentAndDecisionOnInformationSecurityEvents = 5025,
    ResponseToInformationSecurityIncidents = 5026,
    LearningFromInformationSecurityIncidents = 5027,
    CollectionOfEvidence = 5028,
    InformationSecurityDuringDisruption = 5029,
    ICTReadinessForBusinessContinuity = 5030,
    LegalStatutoryRegulatoryAndContractualRequirements = 5031,
    IntellectualPropertyRights = 5032,
    ProtectionOfRecords = 5033,
    PrivacyAndProtectionOfPII = 5034,
    IndependentReviewOfInformationSecurity = 5035,
    ComplianceWithPoliciesRulesAndStandardsForInformationSecurity = 5036,
    DocumentedOperatingProcedures = 5037,
    Screening = 5038,
    TermsAndConditionsOfEmployment = 5039,
    InformationSecurityAwarenessEducationAndTraining = 5040,
    DisciplinaryProcess = 5041,
    ResponsibilitiesAfterTerminationOrChangeOfEmployment = 5042,
    ConfidentialityOrNonDisclosureAgreements = 5043,
    RemoteWorking = 5044,
    InformationSecurityEventReporting = 5045,
    PhysicalSecurityPerimeters = 5046,
    PhysicalEntry = 5047,
    SecuringOfficesRoomsAndFacilities = 5048,
    PhysicalSecurityMonitoring = 5049,
    ProtectingAgainstPhysicalAndEnvironmentalThreats = 5050,
    WorkingInSecureAreas = 5051,
    ClearDeskAndClearScreen = 5052,
    EquipmentSitingAndProtection = 5053,
    SecurityOfAssetsOffPremises = 5054,
    StorageMedia = 5055,
    SupportingUtilities = 5056,
    CablingSecurity = 5057,
    EquipmentMaintenance = 5058,
    SecureDisposalOrReuseOfEquipment = 5059,
    UserEndpointDevices = 5060,
    PrivilegedAccessRights = 5061,
    InformationAccessRestriction = 5062,
    AccessToSourceCode = 5063,
    SecureAuthentication = 5064,
    CapacityManagement = 5065,
    ProtectionAgainstMalware = 5066,
    ManagementOfTechnicalVulnerabilities = 5067,
    ConfigurationManagement = 5068,
    InformationDeletion = 5069,
    DataMasking = 5070,
    DataLeakagePrevention = 5071,
    InformationBackup = 5072,
    RedundancyOfInformationProcessingFacilities = 5073,
    Logging = 5074,
    MonitoringActivities = 5075,
    ClockSynchronisation = 5076,
    UseOfPrivilegedUtilityPrograms = 5077,
    InstallationOfSoftwareOnOperationalSystems = 5078,
    NetworksSecurity = 5079,
    SecurityOfNetworkServices = 5080,
    SegregationOfNetworks = 5081,
    WebFiltering = 5082,
    UseOfCryptography = 5083,
    SecureDevelopmentLifeCycle = 5084,
    ApplicationSecurityRequirements = 5085,
    SecureSystemArchitectureAndEngineeringPrinciples = 5086,
    SecureCoding = 5087,
    SecurityTestingInDevelopmentAndAcceptance = 5088,
    OutsourcedDevelopment = 5089,
    SeparationOfDevelopmentTestAndProductionEnvironments = 5090,
    ChangeManagement = 5091,
    TestInformation = 5092,
    ProtectionOfInformationSystemsDuringAuditTesting = 5093,

    //ISO27002 Version 2013
    PoliciesForInformationSecurity2013 = 6001,
    ReviewOfThePoliciesForInformationSecurity2013 = 6002,
    InformationSecurityRolesAndResponsibilities2013 = 6003,
    SegregationOfDuties2013 = 6004,
    ContactWithAuthorities2013 = 6005,
    ContactWithSpecialInterestGroups2013 = 6006,
    InformationSecurityInProjectManagement2013 = 6007,
    MobileDevicePolicy2013 = 6008,
    Teleworking2013 = 6009,
    Screening2013 = 6010,
    TermsAndConditionsOfEmployment2013 = 6011,
    ManagementResponsibilities2013 = 6012,
    InformationSecurityAwarenessEducationAndTraining2013 = 6013,
    DisciplinaryProcess2013 = 6014,
    TerminationOrChangeOfEmploymentResponsibilities2013 = 6015,
    InventoryOfAssets2013 = 6016,
    OwnershipOfAssets2013 = 6017,
    AcceptableUseOfAssets2013 = 6018,
    ReturnOfAssets2013 = 6019,
    ClassificationOfInformation2013 = 6020,
    LabellingOfInformation2013 = 6021,
    HandlingOfAssets2013 = 6022,
    ManagementOfRemovableMedia2013 = 6023,
    DisposalOfMedia2013 = 6024,
    PhysicalMediaTransfer2013 = 6025,
    AccessControlPolicy2013 = 6026,
    AccessToNetworksAndNetworkServices2013 = 6027,
    UserRegistrationAndDeRegistration2013 = 6028,
    UserAccessProvisioning2013 = 6029,
    ManagementOfPrivilegedAccessRights2013 = 6030,
    ManagementOfSecretAuthenticationInformationOfUsers2013 = 6031,
    ReviewOfUserAccessRights2013 = 6032,
    RemovalOrAdjustmentOfAccessRights2013 = 6033,
    UseOfSecretAuthenticationInformation2013 = 6034,
    InformationAccessRestriction2013 = 6035,
    SecureLogOnProcedures2013 = 6036,
    PasswordManagementSystem2013 = 6037,
    UseOfPrivilegedUtilityPrograms2013 = 6038,
    AccessControlToProgramSourceCode2013 = 6039,
    PolicyOnTheUseOfCryptographicControls2013 = 6040,
    KeyManagement2013 = 6041,
    PhysicalSecurityPerimeter2013 = 6042,
    PhysicalEntryControls2013 = 6043,
    SecuringOfficesRoomsAndFacilities2013 = 6044,
    ProtectingAgainstExternalAndEnvironmentalThreats2013 = 6045,
    WorkingInSecureAreas2013 = 6046,
    DeliveryAndLoadingAreas2013 = 6047,
    EquipmentSitingAndProtection2013 = 6048,
    SupportingUtilities2013 = 6049,
    CablingSecurity2013 = 6050,
    EquipmentMaintenance2013 = 6051,
    RemovalOfAssets2013 = 6052,
    SecurityOfEquipmentAndAssetsOffPremises2013 = 6053,
    SecureDisposalOrReuseOfEquipment2013 = 6054,
    UnattendedUserEquipment2013 = 6055,
    ClearDeskAndClearScreenPolicy2013 = 6056,
    DocumentedOperatingProcedures2013 = 6057,
    ChangeManagement2013 = 6058,
    CapacityManagement2013 = 6059,
    SeparationOfDevelopmentTestingAndOperationalEnvironments2013 = 6060,
    ControlsAgainstMalware2013 = 6061,
    InformationBackup2013 = 6062,
    EventLogging2013 = 6063,
    ProtectionOfLogInformation2013 = 6064,
    AdministratorAndOperatorLogs2013 = 6065,
    ClockSynchronisation2013 = 6066,
    InstallationOfSoftwareOnOperationalSystems2013 = 6067,
    ManagementOfTechnicalVulnerabilities2013 = 6068,
    RestrictionsOnSoftwareInstallation2013 = 6069,
    InformationSystemsAuditControls2013 = 6070,
    NetworkControls2013 = 6071,
    SecurityOfNetworkServices2013 = 6072,
    SegregationInNetworks2013 = 6073,
    InformationTransferPoliciesAndProcedures2013 = 6074,
    AgreementsOnInformationTransfer2013 = 6075,
    ElectronicMessaging2013 = 6076,
    ConfidentialityOrNonDisclosureAgreements2013 = 6077,
    InformationSecurityRequirementsAnalysisAndSpecification2013 = 6078,
    SecuringApplicationServicesOnPublicNetworks2013 = 6079,
    ProtectingApplicationServicesTransactions2013 = 6080,
    SecureDevelopmentPolicy2013 = 6081,
    SystemChangeControlProcedures2013 = 6082,
    TechnicalReviewOfApplicationsAfterOperatingPlatformChanges2013 = 6083,
    RestrictionsOnChangesToSoftwarePackages2013 = 6084,
    SecureSystemEngineeringPrinciples2013 = 6085,
    SecureDevelopmentEnvironment2013 = 6086,
    OutsourcedDevelopment2013 = 6087,
    SystemSecurityTesting2013 = 6088,
    SystemAcceptanceTesting2013 = 6089,
    ProtectionOfTestData2013 = 6090,
    InformationSecurityPolicyForSupplierRelationships2013 = 6091,
    AddressingSecurityWithinSupplierAgreements2013 = 6092,
    InformationAndCommunicationTechnologySupplyChain2013 = 6093,
    MonitoringAndReviewOfSupplierServices2013 = 6094,
    ManagingChangesToSupplierServices2013 = 6095,
    ResponsibilitiesAndProcedures2013 = 6096,
    ReportingInformationSecurityEvents2013 = 6097,
    ReportingInformationSecurityWeaknesses2013 = 6098,
    AssessmentOfAndDecisionOnInformationSecurityEvents2013 = 6099,
    ResponseToInformationSecurityIncidents2013 = 6100,
    LearningFromInformationSecurityIncidents2013 = 6101,
    CollectionOfEvidence2013 = 6102,
    PlanningInformationSecurityContinuity2013 = 6103,
    ImplementingInformationSecurityContinuity2013 = 6104,
    VerifyReviewAndEvaluateInformationSecurityContinuity2013 = 6105,
    AvailabilityOfInformationProcessingFacilities2013 = 6106,
    IdentificationOfApplicableLegislationAndContractualRequirements2013 = 6107,
    IntellectualPropertyRights2013 = 6108,
    ProtectionOfRecords2013 = 6109,
    PrivacyAndProtectionOfPersonallyIdentifiableInformation2013 = 6110,
    RegulationOfCryptographicControls2013 = 6111,
    IndependentReviewOfInformationSecurity2013 = 6112,
    ComplianceWithSecurityPoliciesAndStandards2013 = 6113,
    TechnicalComplianceReview2013 = 6114,
}

export enum AnnualWheelPriority {
    VeryLow = 1,
    Low = 2,
    Moderate = 3,
    High = 4,
    VeryHigh = 5,
}

export enum AnnualWheelActivityCategoryType {
    ProcessingActivity,
    Risk,
    DataProcessor,
    DeletionPeriod,
    PolicyAndProcedure,
    TemplateAndInstructions,
    AnnualWheel,
    Group,
    ITAndSecurity,
    Other,
}

export enum AnnualWheelActivityFramework {
    GDPR = 1,
    InfoSec = 2,
    NIS2 = 3,
    ISO27001 = 4,
    ISO27002 = 5,
}

export enum NIS2CategoryType {
    //Implementation
    ScopeAndActionPlanForImplementationOfNIS2,
    MappingOfCriticalNetworkAndInformationSystems,
    RiskAssessmentOfEachNetworkAndInformationSystem,
    AppropriateTechnicalOperationalAndOrganisationalMeasures,
    Suppliers,
    TrainingAndAwarenessInImplementation,
    IncidentHandling,
    EstablishmentOfPrerequisitesForOngoingOperation,

    //Ongoing Operation
    AnnualWheelAndGovernance,
    TheManagement,
    NetworkAndInformationSystem,
    ContractManagement,
    TrainingAndAwarenessInOngoingOperation,
    EmergencyManagement,
    InternalControl,
}

export enum NIS2Area {
    Implementation,
    OngoingOperation,
}

export class NIS2TemplateContainer {
    implementationTemplates: Array<NIS2Templates> = [];
    ongoingOperationTemplates: Array<NIS2Templates> = [];

    constructor() {
        this.onInit();
    }

    onInit() {
        getEnumValues(NIS2CategoryType).forEach((x) => {
            if (this.getNIS2AreaByCategoryType(x) === NIS2Area.Implementation) {
                this.implementationTemplates.push(new NIS2Templates(x));
            } else {
                this.ongoingOperationTemplates.push(new NIS2Templates(x));
            }
        });
    }

    setTemplates(templates: Array<AnnualWheelActivityTemplateItem>) {
        templates.forEach((x) => this.setTemplate(x));
    }

    setTemplate(template: AnnualWheelActivityTemplateItem) {
        var activityType = Number(template.id) as AnnualWheelActivityType;

        switch (activityType) {
            case AnnualWheelActivityType.ClarifyTheDriversForEstablishingNIS2Compliance:
            case AnnualWheelActivityType.ConductGAPAnalysis:
            case AnnualWheelActivityType.PreparationOfFinalActionPlan:
            case AnnualWheelActivityType.OngoingMonthlyStatusMeetingsDuringTheImplementationPhase:
                this.addImplementationTemplate(NIS2CategoryType.ScopeAndActionPlanForImplementationOfNIS2, template);
                break;
            case AnnualWheelActivityType.EstablishAnOverviewOfAllNetworkAndInformationSystems:
            case AnnualWheelActivityType.DefinitionOfWhatIsCriticalAccordingToNIS2ForTheOrganisation:
            case AnnualWheelActivityType.IdentificationOfCriticalProcessesSystemsBasedOnTheEstablishedOverview:
            case AnnualWheelActivityType.OrganisationalAnchoringOfResponsibilityForTheIdentifiedSystems:
                this.addImplementationTemplate(NIS2CategoryType.MappingOfCriticalNetworkAndInformationSystems, template);
                break;
            case AnnualWheelActivityType.DeterminationOfRiskAssessmentMethodology:
            case AnnualWheelActivityType.IdentificationOfRelevantThreatsAndDangersInNetworkAndInformationSystems:
            case AnnualWheelActivityType.SupplyChainSecurity:
            case AnnualWheelActivityType.RiskAssessmentBasedOnIdentifiedThreats:
            case AnnualWheelActivityType.IdentificationOfUnacceptableRisksBasedOnTheRiskAssessment:
                this.addImplementationTemplate(NIS2CategoryType.RiskAssessmentOfEachNetworkAndInformationSystem, template);
                break;
            case AnnualWheelActivityType.VerificationOfTheLevelOfTechnicalOperationalAndOrganisationalMeasures:
            case AnnualWheelActivityType.ImplementationOfAdditionalMeasuresThatProveNecessaryBasedOnTheRiskAssessment:
            case AnnualWheelActivityType.DealingWithUnforeseenFindingsDuringTheMappingPhaseThatPoseARiskOrBreachOfInternalGuidelines:
            case AnnualWheelActivityType.PhysicalSecurity:
            case AnnualWheelActivityType.InformationSecurityPolicy:
                this.addImplementationTemplate(NIS2CategoryType.AppropriateTechnicalOperationalAndOrganisationalMeasures, template);
                break;
            case AnnualWheelActivityType.TheOrganisationsRequirementsForSuppliers:
            case AnnualWheelActivityType.TheOrganisationsEffectiveManagementPracticesInConnectionWithOutsourcing:
            case AnnualWheelActivityType.ReviewOfContractsWithSuppliersOfCriticalSystemsEtc:
            case AnnualWheelActivityType.ControlProceduresInRelationToSupplierServices:
            case AnnualWheelActivityType.ProceduresForContractAndSupplierManagement:
                this.addImplementationTemplate(NIS2CategoryType.Suppliers, template);
                break;
            case AnnualWheelActivityType.EstablishRelevantTrainingCoursesForDifferentEmployeeGroups:
            case AnnualWheelActivityType.EmployeeOrientedGuidelines:
                this.addImplementationTemplate(NIS2CategoryType.TrainingAndAwarenessInImplementation, template);
                break;
            case AnnualWheelActivityType.OverallEmergencyManagement:
            case AnnualWheelActivityType.EmergencyAndRecoveryPlans:
            case AnnualWheelActivityType.EventLogForInternalDocumentationOfEvents:
            case AnnualWheelActivityType.ProcedureForOngoingReviewOrFollowUpOfIncidentHistory:
                this.addImplementationTemplate(NIS2CategoryType.IncidentHandling, template);
                break;
            case AnnualWheelActivityType.AnnualWheel:
            case AnnualWheelActivityType.Governance:
            case AnnualWheelActivityType.CommunicationToTheOrganisation:
            case AnnualWheelActivityType.ReportingFromTheOrganisation:
            case AnnualWheelActivityType.RevisitingInsuranceCoverage:
                this.addImplementationTemplate(NIS2CategoryType.EstablishmentOfPrerequisitesForOngoingOperation, template);
                break;
            case AnnualWheelActivityType.AnnualUpdateOfTheAnnualWheel:
            case AnnualWheelActivityType.ReviewOrganizationalChanges:
            case AnnualWheelActivityType.OngoingTasksAccordingToRelevantStandardsCertificationSchemes:
                this.addongoingOprerationTemplate(NIS2CategoryType.AnnualWheelAndGovernance, template);
                break;
            case AnnualWheelActivityType.ExecutiveBoardMeetings:
            case AnnualWheelActivityType.BoardMeetings:
            case AnnualWheelActivityType.TheManagementsActiveSupervision:
            case AnnualWheelActivityType.InsuranceIncludingCyberInsurance:
            case AnnualWheelActivityType.NIS2AtStrategicAndTacticalLevel:
                this.addongoingOprerationTemplate(NIS2CategoryType.TheManagement, template);
                break;
            case AnnualWheelActivityType.AccessDepartureOrChangesInTheSystemsFunction:
            case AnnualWheelActivityType.ChangesInTheThreatLevelAndRiskAssessment:
            case AnnualWheelActivityType.VerificationOfTheLevelOfTechnicalOperationalAndOrganizationalMeasures:
            case AnnualWheelActivityType.TestingOfSecurityMeasures:
            case AnnualWheelActivityType.UpdateProcessAndSystemSpecificDocumentation:
            case AnnualWheelActivityType.ChangesInSystemOwnership:
            case AnnualWheelActivityType.ChangesToControlProcedures:
            case AnnualWheelActivityType.AnyChangedSystemOrDataClassification:
                this.addongoingOprerationTemplate(NIS2CategoryType.NetworkAndInformationSystem, template);
                break;
            case AnnualWheelActivityType.ChangesInTheOrganizationsProceduresForSupplierManagementAndProcurement:
            case AnnualWheelActivityType.OngoingControlOrAuditsOfSuppliers:
            case AnnualWheelActivityType.UpdateOfSupplierAgreements:
                this.addongoingOprerationTemplate(NIS2CategoryType.ContractManagement, template);
                break;
            case AnnualWheelActivityType.YearlyPlanForTrainingTheOrganization:
            case AnnualWheelActivityType.UpdatingOfGeneralGuidanceMaterial:
                this.addongoingOprerationTemplate(NIS2CategoryType.TrainingAndAwarenessInOngoingOperation, template);
                break;
            case AnnualWheelActivityType.TestOfEmergencyPlans:
            case AnnualWheelActivityType.FollowUpOfEventsOnTheIncidenceLog:
            case AnnualWheelActivityType.UpdatingOfDocumentationIncludingEmergencyPlans:
                this.addongoingOprerationTemplate(NIS2CategoryType.EmergencyManagement, template);
                break;
            case AnnualWheelActivityType.Audits:
            case AnnualWheelActivityType.EvaluationAndFollowUpOfTheControlResults:
                this.addongoingOprerationTemplate(NIS2CategoryType.InternalControl, template);
                break;
        }
    }

    addImplementationTemplate(category: NIS2CategoryType, template: AnnualWheelActivityTemplateItem) {
        this.implementationTemplates.find((x) => x.categoryType === category)?.templates.push(template);
    }

    addongoingOprerationTemplate(category: NIS2CategoryType, template: AnnualWheelActivityTemplateItem) {
        this.ongoingOperationTemplates.find((x) => x.categoryType === category)?.templates.push(template);
    }

    getNIS2AreaByCategoryType(type: NIS2CategoryType) {
        switch (type) {
            case NIS2CategoryType.ScopeAndActionPlanForImplementationOfNIS2:
            case NIS2CategoryType.MappingOfCriticalNetworkAndInformationSystems:
            case NIS2CategoryType.RiskAssessmentOfEachNetworkAndInformationSystem:
            case NIS2CategoryType.AppropriateTechnicalOperationalAndOrganisationalMeasures:
            case NIS2CategoryType.Suppliers:
            case NIS2CategoryType.TrainingAndAwarenessInImplementation:
            case NIS2CategoryType.IncidentHandling:
            case NIS2CategoryType.EstablishmentOfPrerequisitesForOngoingOperation:
                return NIS2Area.Implementation;
            case NIS2CategoryType.AnnualWheelAndGovernance:
            case NIS2CategoryType.TheManagement:
            case NIS2CategoryType.NetworkAndInformationSystem:
            case NIS2CategoryType.ContractManagement:
            case NIS2CategoryType.TrainingAndAwarenessInOngoingOperation:
            case NIS2CategoryType.EmergencyManagement:
            case NIS2CategoryType.InternalControl:
                return NIS2Area.OngoingOperation;
            default:
                throw new Error("Invalid NIS2 Category: " + type);
        }
    }
}

export class NIS2Templates {
    categoryType: NIS2CategoryType;
    templates: Array<AnnualWheelActivityTemplateItem> = [];

    constructor(categoryType: NIS2CategoryType) {
        this.categoryType = categoryType;
    }

    hasTemplates() {
        return this.templates.length > 0;
    }
}

export enum AnnualWheelFilterOption {
    All = 1,
    Planned = 2,
    NotPlanned = 3,
}
