import React from "react";
import { useTranslation } from "../../../localization/useTranslation";
import { useSystemOverviewStyles } from "../SystemOverview.styles";
import { Box, Divider, Grid, GridSize, Skeleton } from "@mui/material";
import { useSecurityTabContent } from "./SecurityTabContent.hooks";
import { SecurityMeasureOverviewModel } from "../SystemOverview.types";
import CheckIcon from "@mui/icons-material/Check";
import { usePlanContext } from "../../../auth/planProvider/PlanProvider";
import { setFirstLetterToLowerCase } from "../../../common/stringOperations";
import { SystemEntityType } from "../../systemEdit/systemDataAndStorageStep/SystemDataAndStorageStep.types";
import { warning } from "../../../common/icons";
import RiskIndicator from "../../../riskAssessment/riskIndicator/RiskIndicator";
import { RiskAssessmentType } from "../../../riskAssessment/RiskAssessment.types";
import { RiskAssessmentArea, RiskComplianceArea } from "../../../riskAssessment/riskAssessments/RiskAssessments.types";
import { useUrlProvider } from "../../../useUrlProvider";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import SystemControlProcedureDialog from "../systemControlProcedureDialog/SystemControlProcedureDialog";
import { SystemControlProcedureType } from "../systemControlProcedureDialog/SystemControlProcedureDialog.types";
import { DotLegalHeading, DotLegalFixedHeightText, DotLegalTooltip } from "@dotlegal/dotlegal-ui-components";
import SystemOverviewAIDialog from "../systemOverviewAIDialog/SystemOverviewAIDialog";
import PlanInputFieldWrapper from "../../../plan/planInputFieldWrapper/PlanInputFieldWrapper";
import { OperationCriticalityLevelEnum } from "../../systemEdit/SystemEdit.types.ts";

export interface SecurityTabContentProps {
    systemId?: string;
}

function SecurityTabContent(props: SecurityTabContentProps) {
    const styles = useSystemOverviewStyles();

    const propertiesGridProps: { item: boolean; xs: GridSize; sm: GridSize; md: GridSize; lg: GridSize; xl: GridSize } = {
        item: true,
        xs: 12,
        sm: 6,
        md: 4,
        lg: 2,
        xl: 2,
    };

    const { getRiskAssessmentQuestionsDialog } = useUrlProvider();
    const { permissions } = useUserContext();
    const { translateString, translateDate, translateOperationCriticalityLevelEnum } = useTranslation();
    const { informationSecurityFeatures } = usePlanContext();
    const { isLoading, infoData, showInternalControlProcedureDialog, setShowInternalControlProcedureDialog, refetch, setShowAIDialog, showAIDialog } =
        useSecurityTabContent(props);

    const getSecurityMeasures = (securityMeasures: Array<SecurityMeasureOverviewModel> | undefined) => {
        return (
            <React.Fragment>
                {isLoading ? (
                    <Skeleton variant={"text"} />
                ) : (
                    securityMeasures?.map((s, i) => {
                        if (s.isMet)
                            return (
                                <Box key={i} sx={styles.securityMeasure}>
                                    <CheckIcon />
                                    {s.name}
                                </Box>
                            );
                        else
                            return (
                                <Box key={i} sx={styles.securityMeasure}>
                                    {s.name}
                                </Box>
                            );
                    })
                )}
            </React.Fragment>
        );
    };

    const getIsCriticalForOperation = () => {
        let content: React.ReactNode = <></>;
        if (infoData?.isCriticalForOperation) {
            content = (
                <Box>
                    <Box>
                        {translateString(infoData?.isCriticalForOperation?.toString())} {warning}
                    </Box>
                    {infoData && infoData.isCriticalForOperation && (
                        <Box>
                            {translateString("levelOfCriticality")}: {translateOperationCriticalityLevelEnum(infoData.operationCriticalityLevel!)}
                        </Box>
                    )}
                </Box>
            );
        } else {
            content =
                infoData?.isCriticalForOperation !== null && infoData?.isCriticalForOperation !== undefined
                    ? translateString(infoData?.isCriticalForOperation?.toString())
                    : "";
        }

        return (
            <Grid {...propertiesGridProps}>
                <DotLegalHeading name={translateString("operationCriticalSystem")}>
                    <DotLegalFixedHeightText isLoading={isLoading} content={content} />
                </DotLegalHeading>
            </Grid>
        );
    };

    const getLatestCompletedAudit = () => {
        if (infoData?.latestCompletedSystemAudit) {
            return (
                <React.Fragment>
                    <Box>
                        {translateString("latestAudit", {
                            form: translateString(setFirstLetterToLowerCase(SystemControlProcedureType[infoData.latestCompletedSystemAudit.type].toString())),
                        })}
                    </Box>
                    <Box>{translateDate(infoData.latestCompletedSystemAudit.completetionDate)}</Box>
                </React.Fragment>
            );
        }

        return undefined;
    };

    return (
        <>
            <Grid sx={styles.gridRow} container spacing={3}>
                <Grid {...propertiesGridProps}>
                    <DotLegalHeading name={translateString("deletionPeriod")}>
                        <DotLegalFixedHeightText isLoading={isLoading} content={infoData?.deletionPeriod} />
                    </DotLegalHeading>
                </Grid>

                <Grid {...propertiesGridProps}>
                    <DotLegalHeading name={translateString("securityMeasures")}>
                        <DotLegalFixedHeightText isLoading={isLoading} content={getSecurityMeasures(infoData?.securityMeasures)} />
                    </DotLegalHeading>
                </Grid>

                {informationSecurityFeatures.entityType && (
                    <Grid {...propertiesGridProps}>
                        <DotLegalHeading name={translateString("entityType")}>
                            <DotLegalFixedHeightText
                                isLoading={isLoading}
                                content={
                                    infoData?.entityType ? translateString(setFirstLetterToLowerCase(SystemEntityType[infoData?.entityType].toString())) : ""
                                }
                            />
                        </DotLegalHeading>
                    </Grid>
                )}

                {informationSecurityFeatures.operationCriticalSystem && getIsCriticalForOperation()}

                {informationSecurityFeatures.certifications && (
                    <Grid {...propertiesGridProps}>
                        <DotLegalHeading name={translateString("certifications")}>
                            <DotLegalFixedHeightText
                                isLoading={isLoading}
                                content={infoData?.systemCertifications ? infoData?.systemCertifications.join(", ") : ""}
                            />
                        </DotLegalHeading>
                    </Grid>
                )}

                <Grid {...propertiesGridProps}>
                    <DotLegalHeading name={translateString("network")}>
                        <DotLegalFixedHeightText isLoading={isLoading} content={infoData?.network} />
                    </DotLegalHeading>
                </Grid>
            </Grid>

            <Divider sx={styles.divider} />

            <Grid sx={styles.gridRow} container spacing={3}>
                {informationSecurityFeatures.internalControlProcedures && (
                    <Grid {...propertiesGridProps} lg>
                        <DotLegalHeading name={translateString("systemInternalControlProcedureHeader")}>
                            <DotLegalFixedHeightText
                                sx={(theme) => ({
                                    color: `${theme.palette.secondary.main} !Important`,
                                    fontWeight: theme.typography.fontWeightBold,
                                    "&:hover": {
                                        cursor: "pointer",
                                    },
                                })}
                                onClick={() => setShowInternalControlProcedureDialog(true)}
                                isLoading={isLoading}
                                content={getLatestCompletedAudit()}
                            />
                        </DotLegalHeading>
                    </Grid>
                )}

                {permissions.riskPermissions.read && (
                    <Grid {...propertiesGridProps}>
                        <DotLegalHeading name={translateString("riskLevel")}>
                            {isLoading ? (
                                <Skeleton variant="text" width={"100%"} />
                            ) : (
                                <Box
                                    sx={(theme) => ({
                                        color: theme.palette.primary.dark,
                                        fontSize: theme.typography.pxToRem(12),
                                        height: "55px",
                                        display: "flex",
                                        justifyContent: "center",
                                        boxOrient: "vertical",
                                        overflow: "hidden",
                                    })}
                                >
                                    <RiskIndicator
                                        riskAssessmentType={RiskAssessmentType.Risk}
                                        fixedWidthLeftColumn
                                        textSize="sm"
                                        score={infoData?.riskAssessmentTotalScore}
                                        linkHref={getRiskAssessmentQuestionsDialog(
                                            props.systemId ?? "",
                                            RiskAssessmentArea.Systems,
                                            RiskComplianceArea.GDPR,
                                            infoData?.currentRiskAssessmentVersionId ? infoData.currentRiskAssessmentVersionId : "null"
                                        )}
                                    />
                                </Box>
                            )}
                        </DotLegalHeading>
                    </Grid>
                )}
                {informationSecurityFeatures.ai && permissions.systemAIPermissions?.permissions && (
                    <Grid {...propertiesGridProps}>
                        <DotLegalHeading name={translateString("assetUsesAI")}>
                            <DotLegalFixedHeightText
                                onClick={
                                    infoData && infoData.usesAI
                                        ? () => {
                                              if (infoData?.usesAI) {
                                                  setShowAIDialog(true);
                                              }
                                          }
                                        : undefined
                                }
                                isLoading={isLoading}
                                content={infoData && <Box>{translateString(infoData?.usesAI?.toString())}</Box>}
                            />
                        </DotLegalHeading>
                    </Grid>
                )}
            </Grid>

            {showInternalControlProcedureDialog && (
                <SystemControlProcedureDialog
                    onDialogClose={() => {
                        refetch();
                        setShowInternalControlProcedureDialog(false);
                    }}
                />
            )}

            {showAIDialog && (
                <SystemOverviewAIDialog
                    onDialogClose={() => {
                        setShowAIDialog(false);
                    }}
                    usesAI={infoData!.usesAI}
                    companyUsesAIService={infoData!.companyUsesAIService}
                    aiUsageDescription={infoData!.aiUsageDescription}
                    isUseOfAIConsideredHighRisk={infoData!.isUseOfAIConsideredHighRisk}
                    isDataUsedForTraningOfAIModel={infoData!.isDataUsedForTraningOfAIModel}
                />
            )}
        </>
    );
}

export default SecurityTabContent;
