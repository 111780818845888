import React from "react";
import { useTranslation } from "../../../localization/useTranslation";
import { useApproveDeclarationControlDialog } from "./ApproveDeclarationControlDialog.hooks";
import { Trans } from "react-i18next";
import { Box } from "@mui/material";
import { DotLegalInfoDialog } from "@dotlegal/dotlegal-ui-components";

export interface ApproveDeclarationControlDialogProps {
    controlId: string;
    isApproved: boolean;
    controlName: string;
    onDialogClose: () => void;
    onApprovedChanged: () => void;
}

function ApproveDeclarationControlDialog(props: ApproveDeclarationControlDialogProps) {
    const { translateString } = useTranslation();
    const { changeApprovedForControl, isSaving } = useApproveDeclarationControlDialog(props);

    return (
        <DotLegalInfoDialog
            header={props.isApproved ? translateString("declineControl") : translateString("approveControl")}
            btnText={props.isApproved ? translateString("decline") : translateString("approve")}
            content={
                <Trans
                    i18nKey={props.isApproved ? "declineDeclarationControlInfo" : "approveDeclarationControlInfo"}
                    values={{
                        name: props.controlName,
                    }}
                    components={{
                        wrapper: (
                            <Box sx={(theme) => ({ color: theme.palette.secondary.main, fontWeight: theme.typography.fontWeightBold })} component="span"></Box>
                        ),
                    }}
                />
            }
            onCloseDialog={props.onDialogClose}
            onBtnClick={() => changeApprovedForControl(!props.isApproved)}
            inProgress={isSaving}
        />
    );
}

export default ApproveDeclarationControlDialog;
