import { Box, Typography } from "@mui/material";
import React from "react";
import { useRiskDialogHeader } from "./RiskDialogHeader.styles";
import DotLegalLink from "../../../common/components/dotLegalLink/DotLegalLink";

export interface IRiskDialogHeader {
    label: string;
    name: string;
    href: string;
}

export default function RiskDialogHeader(props: IRiskDialogHeader) {
    const styles = useRiskDialogHeader();

    return (
        <Box sx={styles.headerContainer}>
            <Typography sx={styles.fontSize}>{props.label}</Typography>

            <Typography
                sx={[
                    styles.subHeaderText,
                    (theme) => ({
                        "& a": {
                            textDecoration: "none",
                            color: theme.palette.primary.dark,
                        },
                    }),
                ]}
            >
                <DotLegalLink to={props.href}> {props.name} </DotLegalLink>
            </Typography>
        </Box>
    );
}
