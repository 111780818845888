import { useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";

export const useTaskStatusStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        headerContainer: {
            marginBottom: theme.spacing(1),
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        },
        seperator: {
            marginLeft: theme.spacing(0.5),
            marginRight: theme.spacing(0.5),
        },
    });
};
