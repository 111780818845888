import { DotLegalCheckbox, DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import { Box, Typography } from "@mui/material";
import { createElement } from "react";
import { isNullOrWhitespace } from "../../../common/stringOperations";
import { useTranslation } from "../../../localization/useTranslation";
import { Trans } from "react-i18next";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import LegalEntitySelector from "../../legalEntitySelector/LegalEntitySelector";
import { LegalEntitySelectorViewModel } from "../../LegalEntity.types";

export interface IGroupEntityStep {
    groupEntityId?: string;
    errormsg?: string;
    onGroupEntityChanged: (groupEntityIds: Array<string>) => void;
    selectableGroupEntitiesData?: Array<LegalEntitySelectorViewModel>;
    header: string;
    infoTranslationKey: string;
    checkboxTranslationKey: string;
    checkboxTooltip: string;
    showGdpoIfAvailable: boolean;
}

function GroupEntityStep(props: IGroupEntityStep) {
    const { translateString } = useTranslation();
    const { customerName, gdpo } = useUserContext();

    return (
        <Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <DotLegalHeader center headerStyle={"small"} toolTip={gdpo ? undefined : translateString("legalEntityGroupEntityHeaderTooltip")}>
                    {props.header}
                </DotLegalHeader>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <DotLegalHeader center fontWeight={400} headerStyle="small">
                    {createElement(Trans, {
                        i18nKey: props.infoTranslationKey,
                        values: { name: customerName },
                    })}
                </DotLegalHeader>
            </Box>

            {gdpo && props.showGdpoIfAvailable && (
                <Box sx={{ display: "flex", justifyContent: "center", marginBottom: 3 }}>
                    <DotLegalCheckbox
                        checked={isNullOrWhitespace(props.groupEntityId)}
                        label={createElement(Trans, {
                            i18nKey: props.checkboxTranslationKey,
                            values: { name: customerName },
                        })}
                        tooltip={props.checkboxTooltip}
                        onChange={() => props.onGroupEntityChanged([])}
                    ></DotLegalCheckbox>
                </Box>
            )}

            <LegalEntitySelector
                marginTop={0}
                allColumns={false}
                isLoading={false}
                selectedEntities={props.groupEntityId ? [props.groupEntityId] : []}
                legalEntityItems={props.selectableGroupEntitiesData}
                setSelectedEntities={(values) => props.onGroupEntityChanged(values)}
                hideGridSize
                gridlength={10000}
                disableSelectAll
            />
            <Typography sx={(theme) => ({ color: theme.palette.error.main, fontSize: 12 })}>{props.errormsg}</Typography>
        </Box>
    );
}

export default GroupEntityStep;
