import { LevelOfProtectionType } from "./addLegalEntityDialog/AddLegalEntityDialog.types";
import { SelectableLegalUnitsItem } from "../processingActivity/ProcessingActivity.types";
import { EUToUSDataPrivacyFrameworkId } from "../legalBasis/TransferBasis.Helper";

export const isDataProcessorAThirdCountry = (
    legalEntity: SelectableLegalUnitsItem | undefined,
    storageLocations: Array<string>,
    thirdCountries: Array<string>
) => {
    if (legalEntity) {
        if (storageLocations.some((x) => thirdCountries.includes(x))) {
            return true;
        }
    }

    return isLegalEntityAInsecureThirdCountry(legalEntity);
};

export const isLegalEntityAInsecureThirdCountry = (legalEntity: SelectableLegalUnitsItem | undefined) => {
    if (legalEntity) {
        return (
            legalEntity.levelOfProtection === LevelOfProtectionType.ThirdCountry || legalEntity.levelOfProtection === LevelOfProtectionType.DataPrivacyFramework
        );
    }
    return false;
};

// TODO - Skal erstatte den eksisterende metode, når storage location på delinger er fuldt implementeret (Nikolaj)
// export function dataContainedInThirdCountry(
//     thirdCountries: Array<string>,
//     storageLocations: Array<string>,
//     sharingRecipient: SelectableLegalUnitsItem | undefined
// ) {
//     if (sharingRecipient) {
//         if (storageLocations.length > 0) {
//             return storageLocations.some((x) => thirdCountries.includes(x));
//         }

//         return (
//             sharingRecipient.levelOfProtection === LevelOfProtectionType.ThirdCountry ||
//             sharingRecipient.levelOfProtection === LevelOfProtectionType.DataPrivacyFramework
//         );
//     }

//     return false;
// }

export function dataContainedInThirdCountry(
    thirdCountries: Array<string>,
    storageLocations: Array<string>,
    sharingRecipient: SelectableLegalUnitsItem | undefined
) {
    if (sharingRecipient) {
        if (
            sharingRecipient.levelOfProtection === LevelOfProtectionType.ThirdCountry ||
            sharingRecipient.levelOfProtection === LevelOfProtectionType.DataPrivacyFramework
        ) {
            return true;
        }

        return storageLocations.some((x) => thirdCountries.includes(x));
    }

    return false;
}

export const showTransferBasis = (legalEntityData: Array<SelectableLegalUnitsItem> | undefined, legalEntityId: string | null | undefined) => {
    if (legalEntityData && legalEntityId) {
        let legalEntity = legalEntityData.find((x) => x.id === legalEntityId);

        if (legalEntity)
            return (
                (legalEntity.levelOfProtection === LevelOfProtectionType.ThirdCountry ||
                    legalEntity.levelOfProtection === LevelOfProtectionType.DataPrivacyFramework) &&
                legalEntity.isTransferBasisUnnecessary === false
            );
    }
    return false;
};

export const showTia = (
    legalEntityData: Array<SelectableLegalUnitsItem> | undefined,
    legalEntityId: string | null | undefined,
    hasPermision: boolean,
    transferBasisId: string | undefined
) => {
    if (legalEntityData && legalEntityId && hasPermision) {
        let legalEntity = legalEntityData.find((x) => x.id === legalEntityId);

        if (legalEntity)
            return (
                (legalEntity.levelOfProtection === LevelOfProtectionType.ThirdCountry ||
                    legalEntity.levelOfProtection === LevelOfProtectionType.DataPrivacyFramework) &&
                legalEntity.isTIAUnnecessary === false &&
                transferBasisId !== EUToUSDataPrivacyFrameworkId
            );

        return false;
    }
};
