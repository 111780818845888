import { usePlanContext } from "../../../../auth/planProvider/PlanProvider";
import { useCertificationsQuery, useComplianceAreaQuery } from "../../../../common/hooks/useSelectableItemQueries";

export default function useLegalEntityAreaAndCertificationsStepHook() {
    const { informationSecurityFeatures, vendorManagementFeatures } = usePlanContext();
    const complianceAreaQuery = useComplianceAreaQuery();
    const certificationsQuery = useCertificationsQuery(informationSecurityFeatures.certifications || vendorManagementFeatures.certifications);

    return {
        complianceAreas: complianceAreaQuery.data,
        complianceAreasIsLoading: complianceAreaQuery.isLoading,
        certifications: certificationsQuery.data,
        certificationsIsLoading: certificationsQuery.isLoading,
    };
}
