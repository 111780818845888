import { useTranslation } from "../localization/useTranslation.ts";
import { LegalEntityPageEnum } from "./legalEntities/LegalEntities.types.ts";
import { useUrlProvider } from "../useUrlProvider.ts";
import { DotLegalBreadCrumbModel } from "@dotlegal/dotlegal-ui-components";
import { SelectableItem } from "../processingActivity/ProcessingActivity.types.ts";

export function useLegalEntityBreadCrumbs(legalEntityPage: LegalEntityPageEnum, legalEntity: SelectableItem) {
    const { translateString } = useTranslation();
    const { getOtherLegalEntities, getCustomerLegalEntities, getGroupEntitiesUrl, getLegalEntitiesUrl, getLegalEntityUrl } = useUrlProvider();
    const result: Array<DotLegalBreadCrumbModel> = [];
    switch (legalEntityPage) {
        case LegalEntityPageEnum.Vendor:
            result.push({ name: translateString("vendors"), link: getLegalEntitiesUrl(), tooltip: legalEntity.description });
            break;
        case LegalEntityPageEnum.GroupEntity:
            result.push({ name: translateString("groupCompanies"), link: getGroupEntitiesUrl(), tooltip: legalEntity.description });
            break;
        case LegalEntityPageEnum.Customer:
            result.push({ name: translateString("customers"), link: getCustomerLegalEntities(), tooltip: legalEntity.description });
            break;
        case LegalEntityPageEnum.Other:
            result.push({ name: translateString("other"), link: getOtherLegalEntities(), tooltip: legalEntity.description });
            break;
        default: {
            throw new Error(`The given page '${legalEntityPage}' for legal entity was not found`);
        }
    }

    result.push({ name: legalEntity.name, link: getLegalEntityUrl(legalEntity.id), tooltip: legalEntity.description });

    return result;
}
