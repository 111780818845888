import { useRef, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { post } from "../../common/api/apiShared";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { TrackingEvent, useTrackAIEvent } from "../hooks/useTracking";
import { CreateProcessingActivityModel, CreateProcessingActivityViewModel, ProcessingActivityIndustryAreaEnum } from "../ProcessingActivity.types";
import { useUrlProvider } from "../../useUrlProvider";
import { DotLegalSelectOption } from "../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { hasAccessToProcessingActivitySteps } from "../processingActivities/ProcessingActivities.hooks";
import { ProcessingActivityStatus } from "../processingActivities/ProcessingActivities.types";
import { ProcessingActivityStatusManagement, UserPermissions } from "../../auth/userContextProvider/UserContextProvider.types";
import { useValidator } from "../../common/hooks/validation";

export function useAddProcessingActivityDialogDataMapping() {
    const { getEditProcessingActivityUrlCreateView, getProcessingActivityOverviewUrl } = useUrlProvider();
    const navigate = useNavigate();
    const snackbar = useDotLegalSnackbar();
    const { translateString } = useTranslation();
    const [saveModel, setSaveModel] = useState<CreateProcessingActivityViewModel>({
        name: "",
        industryAreas: [],
        mostPopular: false,
        processingActivityStatus: ProcessingActivityStatus.Active,
    });
    const trackEvent = useTrackAIEvent();
    const { permissions } = useUserContext();

    const validation = useValidator<CreateProcessingActivityViewModel>(
        (validators) => ({
            name: validators.validateNotEmpty((item) => item.name, translateString("name")),
        }),
        []
    );

    const saveMutation = useMutation(save, {
        onSuccess: (response: any) => {
            const value = response.value();
            if (value.isCreated) {
                trackEvent(TrackingEvent.ProcessingActivityAdded, { processingActivity: value.id });

                if (hasAccessToProcessingActivitySteps(permissions)) {
                    navigate(getEditProcessingActivityUrlCreateView(value.id));
                } else {
                    navigate(getProcessingActivityOverviewUrl(value.id));
                }
            } else {
                snackbar.show(translateString("processingActivityExists"), "warning");
            }
        },
    });

    const createProcessingActivity = async () => {
        if (validation.anyHasErrors) {
            validation.setShowErrors(true);
            return;
        }

        await saveMutation.mutateAsync();
    };

    function save() {
        return post<{}>("/ProcessingActivity/", ConvertToSaveModel(saveModel, permissions));
    }

    let industryAreas: Array<DotLegalSelectOption> = Object.entries(ProcessingActivityIndustryAreaEnum)
        .filter((q) => isNaN(Number(q[0])))
        .map((q) => {
            return {
                name: translateString(q[0].toLowerCase()),
                id: q[1].toString(),
            };
        });

    return {
        inProgress: saveMutation.isLoading,
        createProcessingActivity,
        saveModel,
        setSaveModel,
        validation,
        industryAreas,
    };
}

function ConvertToSaveModel(viewModel: CreateProcessingActivityViewModel, permissions: UserPermissions) {
    return new CreateProcessingActivityModel(
        viewModel.name,
        viewModel.industryAreas.map((x) => {
            return Number(x);
        }),
        viewModel.mostPopular,
        permissions.processingActivityStatusManagement === ProcessingActivityStatusManagement.Draft
            ? ProcessingActivityStatus.Draft
            : viewModel.processingActivityStatus
    );
}
