import { useState } from "react";
import { useQuery } from "react-query";
import { get } from "../../../common/api/apiShared";
import { ValidationError } from "../../../common/validationError";
import { useTranslation } from "../../../localization/useTranslation";
import { SelectableColoredItem, SelectableItem } from "../../../processingActivity/ProcessingActivity.types";
import { useResponsibleQuery } from "../../../user/hooks/useResponsibleQuery";
import { UserSelectableItem } from "../../../user/User.types";
import { SystemModel, SystemType } from "../SystemEdit.types";
import { IAssociationStep } from "./SystemAssociationStep";

export function useSystemAssociationStepHooks(props: IAssociationStep) {
    const [showBusinessAreaDialog, setShowBusinessAreaDialog] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    const { translateString } = useTranslation();

    let businessareaQuery = useQuery("selectableBusinessAreas", () => get<Array<SelectableColoredItem>>("/BusinessAreas/businessareas"));
    let responsibleQuery = useResponsibleQuery();

    let businessAreasLoading = businessareaQuery.isLoading;
    let businessAreasData = businessareaQuery.data;
    let responsibleLoading = responsibleQuery.isLoading;

    let systemTypeOptions: Array<SelectableItem> = [
        { id: SystemType.GlobalSystem.toString(), name: translateString("globalSystem") },
        { id: SystemType.LocalSystem.toString(), name: translateString("localSystem") },
        { id: SystemType.PhysicalArchive.toString(), name: translateString("physicalArchive") },
    ];

    const onSaveBusinessArea = (response: any) => {
        var temp = [...props.businessAreas!];
        temp.push(response.id);
        props.onBusinessAreaChange(temp);

        businessareaQuery.refetch();
        setSearchValue("");
    };

    let responsibleData;
    if (responsibleQuery.userData) {
        responsibleData = responsibleQuery.data(props.responsible)?.map((d, index) => {
            return {
                ...d,
            };
        });
    }

    return {
        businessAreasLoading,
        businessAreasData,
        systemTypeOptions,
        searchValue,
        setSearchValue,
        showBusinessAreaDialog,
        setShowBusinessAreaDialog,
        onSaveBusinessArea,
        responsibleData,
        responsibleLoading,
    };
}

export function useValidationSystemAssociationStep(hasSystemTypePermission: boolean) {
    const { translateString } = useTranslation();
    return (model: SystemModel, responsibles: Array<UserSelectableItem> | undefined): Array<ValidationError> => {
        const result = [];

        let user = responsibles?.find((x) => x.id === model.responsibleId);
        if (user && !user.active && hasSystemTypePermission) {
            result.push(new ValidationError("inActiveUserAffiliation", translateString("userDeactivated")));
        }

        return result;
    };
}
