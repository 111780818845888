import { ISingleValidator, ValidatorFn } from "./types";
import { toArray } from "../../utilities";

export class SingleValidator<TItem, TCheckInput = TItem> implements ISingleValidator<TItem> {
    private readonly _showErrorsFor: { [name in keyof TItem]?: boolean | undefined } = {};

    private readonly _validators: { [name in keyof TItem]?: ValidatorFn<TCheckInput>[] } = {};

    private readonly _renderTrigger: () => void;

    private _errors: { [name in keyof TItem]?: string[] } = {};

    private _showErrors = false;

    public get anyHasErrors() {
        return Object.values<string[] | undefined>(this._errors).some((e) => (e?.length ?? 0) > 0);
    }

    public get showErrors() {
        return this._showErrors;
    }

    constructor(validators: { [name in keyof TItem]?: ValidatorFn<TCheckInput>[] }, renderTrigger: () => void) {
        this._validators = { ...(validators ?? {}) };
        this._renderTrigger = renderTrigger;
    }

    public getErrors(name: keyof TItem, joined: true): string;

    public getErrors(name: keyof TItem, joined?: false): string[];

    public getErrors(name: keyof TItem, joined?: boolean): string[] | string {
        const errors = this._errors[name] ?? [];

        if (joined) return errors.join("; ");
        return errors;
    }

    public setShowErrors(value: boolean) {
        this._showErrors = value;

        this._renderTrigger();
    }

    public showErrorFor(name: keyof TItem): void {
        this._showErrorsFor[name] = true;

        this._renderTrigger();
    }

    public setShowErrorsFor(names: any): void {
        if (Array.isArray(names)) {
            names.forEach((n) => this.showErrorFor(n));
        } else if (typeof names === "object") {
            Object.entries(names).forEach(([key]) => {
                this.showErrorFor(key as keyof TItem);
            });
        }

        this._renderTrigger();
    }

    public hideErrorFor(name: keyof TItem): void {
        this._showErrorsFor[name] = false;

        this._renderTrigger();
    }

    public hideErrorsFor(names: any): void {
        if (Array.isArray(names)) {
            names.forEach((n) => this.hideErrorFor(n));
        } else if (typeof names === "object") {
            Object.entries(names).forEach(([key]) => {
                this.hideErrorFor(key as keyof TItem);
            });
        }

        this._renderTrigger();
    }

    public shouldShowErrors(name: keyof TItem): boolean {
        return this.hasErrors(name) && (this.showErrors || this.getShowErrorFor(name));
    }

    public getErrorsForDisplay(name: keyof TItem): string | undefined {
        return this.shouldShowErrors(name) ? this.getErrors(name, true) : undefined;
    }

    public hasErrors(name: keyof TItem) {
        return this.getErrors(name).length > 0;
    }

    public get isValid() {
        return !this.anyHasErrors;
    }

    public check(input?: TCheckInput, customErrors?: { [name in keyof TItem]?: string[] | string }) {
        if (!input) {
            return this;
        }

        const errors: { [name in keyof TItem]?: string[] } = {};
        Object.keys(this._validators).forEach((key) => {
            const name = key as keyof TItem;

            const validators = this._validators[name];
            if (!validators) {
                return;
            }

            errors[name as keyof TItem] = validators
                .map((v) => v(input))
                .map(toArray)
                .flatMap((v) => v)
                .filter((v) => v)
                .concat(toArray(customErrors?.[name]));
        });

        this._errors = errors;

        return this;
    }

    private getShowErrorFor(name: keyof TItem): boolean {
        return this._showErrorsFor[name] ?? false;
    }
}
