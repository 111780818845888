import React from "react";
import { useTranslation } from "../../../../localization/useTranslation";
import { DotLegalDialog, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { Box, Grid } from "@mui/material";
import DotLegalDocumentUploadWrapper from "../../../../common/components/DotLegalDocumentUploadWrapper";
import { ProcessingActivityDocumentViewModel } from "../ProcessingActivityDocumentTab.types";
import { useProcessingActivityDocumentDialogHooks } from "./ProcessingActivityDocumentDialog.hooks";

export interface IProcessingActivityDocumentDialog {
    onDialogClose: () => void;
    onSave: () => void;
    selectedDocument?: ProcessingActivityDocumentViewModel;
}

function ProcessingActivityDocumentDialog(props: IProcessingActivityDocumentDialog) {
    const { translateString } = useTranslation();
    const { document, documentUploadModel, isEdit, isLink, isSaving, onDocumentUpload, onNameChange, save, setDocumentUploadModel, validation } =
        useProcessingActivityDocumentDialogHooks(props);

    validation?.check(document);

    return (
        <DotLegalDialog
            size={"sm"}
            buttonOkText={isEdit ? translateString("save") : translateString("upload")}
            onDialogClose={props.onDialogClose}
            open
            onOkClick={() => save()}
            header={isEdit ? translateString("editDocument") : translateString("uploadDocument")}
            inProgress={isSaving}
            disableBackdropOnCloseIfValueChange={[document, documentUploadModel]}
        >
            <React.Fragment>
                <Grid item md={12}>
                    <DotLegalTextField
                        label={translateString("name")}
                        value={document?.name!}
                        debounce={false}
                        errorText={validation?.getErrorsForDisplay("name")}
                        onChange={(value) => {
                            onNameChange(value);
                        }}
                    />
                </Grid>
                <Box marginTop={2}>
                    {(!isEdit || (isEdit && isLink)) && (
                        <DotLegalDocumentUploadWrapper
                            showError={validation.showErrors}
                            viewModel={documentUploadModel}
                            onDataChange={(model) => {
                                onDocumentUpload(model.files);
                                setDocumentUploadModel(model);
                            }}
                            isEdit={isEdit}
                        />
                    )}
                </Box>
            </React.Fragment>
        </DotLegalDialog>
    );
}

export default ProcessingActivityDocumentDialog;
