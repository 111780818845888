import React, { useEffect, useState } from "react";
import DotLegalFullScreenSpinner from "./common/components/dotLegalFullScreenSpinner/DotLegalFullScreenSpinner";
import { getSettings } from "./common/settingsProvider";
import { isNullOrWhitespace } from "./common/stringOperations";
import { setupLocalization } from "./localization/setupLocalization";

export let currentVersion = "";

export interface IVersionProviderProps {
    children?: any;
}

function VersionProvider(props: IVersionProviderProps) {
    const [isFetching, setIsFetching] = useState(true);

    useEffect(() => {
        const headers: HeadersInit = new Headers();

        headers.set("pragma", "no-cache");
        headers.set("cache-control", "no-cache");

        fetch(getSettings().apiBaseUrl + "/version.txt", { headers: headers })
            .then((response) => {
                return response.text();
            })
            .then((data) => {
                if (isNullOrWhitespace(data)) {
                    throw new Error("Could not fetch current release version (VersionProvider)");
                }
                setIsFetching(false);
                currentVersion = data.trim();
                setupLocalization();
            });
    }, []);

    return <React.Fragment>{isFetching ? <DotLegalFullScreenSpinner /> : <React.Fragment>{props.children}</React.Fragment>}</React.Fragment>;
}

export default VersionProvider;
