import { createElement } from "react";
import { Trans } from "react-i18next";
import { useMutation } from "react-query";
import { deleteHttp } from "../../../common/api/apiShared";
import { Result } from "../../../common/api/result";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { IDeleteLegislationDialog } from "./DeleteLegislationDialog";

export function useDeleteLegislationDialogDataMapping(props: IDeleteLegislationDialog) {
    const snackbar = useDotLegalSnackbar();

    const removeMutation = useMutation(remove);
    const removeNationalLaw = () => {
        removeMutation.mutateAsync(undefined, {
            onSuccess: (response: Result<string>) => {
                props.onCloseDialog();

                if (response.success()) {
                    props.onSaveCorrect();
                    snackbar.show(createElement(Trans, { i18nKey: "legislationDeleted", values: { name: props.selectedLegislation.name } }));
                } else {
                    snackbar.show(createElement(Trans, { i18nKey: "legislationNotDeleted", values: { name: props.selectedLegislation.name } }), "warning");
                }
            },
        });
    };

    async function remove() {
        return await deleteHttp<string>(`/legislations/${props.selectedLegislation.id}`);
    }

    return {
        removeNationalLaw,
        inProgess: removeMutation.isLoading,
    };
}
