import { useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";

export const useRiskRadioGroupStyles = (isSmallScreen: boolean) => {
    const theme = useTheme();
    return createSxStyles({
        radiobuttonLabel: {
            fontSize: theme.typography.pxToRem(12),
            textAlign: "center",
            color: theme.palette.primary.dark,
        },
        radioButtonLabelInfo: {
            fontSize: theme.typography.pxToRem(10),
            textAlign: "center",
            color: theme.palette.primary.dark,
        },
        radioButtonsContainer: {
            marginTop: theme.spacing(2),
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
        },
        radioGroup: {
            flexWrap: isSmallScreen ? "wrap" : "unset",
        },
    });
};
