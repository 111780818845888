import { TableCell, TableRow } from "@mui/material";
import React from "react";
import { DotLegalDialog, DotLegalTable } from "@dotlegal/dotlegal-ui-components";
import { Guid } from "../../../common/guid";
import { useTranslation } from "../../../localization/useTranslation";
import { UseProcessingActivityOverViewSystemTable } from "./ProcessingActivityOverViewSystemTable.hooks";

export interface IProcessingActivityOverViewSystemTableProps {
    showDialog: boolean;
    setShowDialog: (show: boolean) => void;
    processingActivityId: string | undefined;
}

function ProcessingActivityOverViewSystemTable(props: IProcessingActivityOverViewSystemTableProps) {
    const { translateString } = useTranslation();
    const { headers, isLoading, systems } = UseProcessingActivityOverViewSystemTable(props.processingActivityId);

    return (
        <DotLegalDialog
            open={props.showDialog}
            onDialogClose={() => props.setShowDialog(false)}
            header={translateString("systems").toUpperCase()}
            buttonOkText={""}
            hideOkButton
            size={"md"}
            onOkClick={() => props.setShowDialog(false)}
        >
            <DotLegalTable
                headers={headers}
                data={systems}
                defaultOrderBy={"systemName"}
                defaultOrder={"asc"}
                isLoading={isLoading}
                paginationLabelOf={translateString("labelOf")}
                renderRow={(row) => (
                    <TableRow key={Guid.newGuid()}>
                        <TableCell>{row.systemName}</TableCell>
                        <TableCell>{row.systemType}</TableCell>
                    </TableRow>
                )}
            />
        </DotLegalDialog>
    );
}

export default ProcessingActivityOverViewSystemTable;
