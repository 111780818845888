import { useTheme } from "@mui/material";
import { createSxStyles } from "../../../common/createSxStyles";

export const useJointDataControllerStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        addContainer: {
            display: "flex",
            marginTop: 3,
            justifyContent: "center",
        },
        collapse: {
            marginTop: 2,
            marginBottom: 2,
        },
        disable: {
            display: "flex",
            justifyContent: "center",
            paddingTop: 2,
            flexDirection: "column",
            alignItems: "center",
        },
        error: {
            fontSize: theme.typography.pxToRem(12),
            color: theme.palette.error.main,
            textAlign: "center",
            marginTop: theme.spacing(1),
        },
    });
};
