export enum SystemStorageType {
    OnPremise = 1,
    Cloud = 2,
    Hybrid = 3,
}

export enum SystemEntityType {
    Application = 1,
    SaaS = 2,
    Sensor = 3,
    OperatingSystem = 4,
    Other = 5,
    OperationalTechnology = 6,
}

export enum SystemDataType {
    PersonalData = 1,
    BusinessData = 2,
}
