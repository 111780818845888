import { useTheme } from "@mui/material";
import { createSxStyles } from "../common/createSxStyles";

export const useUserStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        container: {
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
        },
        editButton: {
            marginTop: theme.spacing(-4),
            marginBottom: theme.spacing(2),
            marginRight: theme.spacingAsNumber(1) * -1 + "px",
            marginLeft: "97%",
            display: "flex",
            justifyContent: "right",
            "& .MuiIconButton-root": {
                backgroundColor: "#f0f0fa",
            },
            "& svg": {
                height: 25 + "px",
                stroke: theme.palette.primary.main,
                fill: theme.palette.primary.light,
            },
        },
        gridRow: {
            textAlign: "center",
        },
        divider: {
            backgroundColor: "#F0F7FC",
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
            marginLeft: theme.spacing(6),
            marginRight: theme.spacing(6),
        },
        menuItem: {
            color: theme.palette.primary.dark,
            fontSize: "0.875rem",
            fontWeight: theme.typography.fontWeightMedium,
        },
        tableHeader: {
            color: theme.palette.primary.dark,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: theme.typography.pxToRem(16),
            marginTop: theme.spacing(3),
        },
    });
};
