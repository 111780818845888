import { useDataSubjectsQuery } from "../../../../common/hooks/useSelectableItemQueries";
import { DataSubjectsProps } from "./DataSubjects";
import { useMutation, useQueryClient } from "react-query";
import { deleteHttp, post } from "../../../../common/api/apiShared";
import { getSystemEditQueryKey } from "../../SystemEdit.hooks";

export function useSystemDataSubjects(props: DataSubjectsProps) {
    const systemDataSubjects = props.systemViewModel?.systemPersonalData?.systemDataSubjects ?? [];
    const processingActivityDataSubjects = props.systemViewModel?.systemPersonalData?.processingActivityDataSubjects ?? [];

    const queryClient = useQueryClient();
    const queryKey = getSystemEditQueryKey(props.systemId);

    let { isLoading, data } = useDataSubjectsQuery();

    const addDataSubject = (dataSubjectId: string) => {
        const tempSubjects = [...systemDataSubjects];
        tempSubjects.push(dataSubjectId);
        props.systemViewModel!.systemPersonalData!.systemDataSubjects = tempSubjects;
        queryClient.setQueryData(queryKey, props.systemViewModel);
        saveMutation.mutateAsync(dataSubjectId);
    };

    const removeDataSubject = (dataSubjectId: string) => {
        let tempSubjects = [...systemDataSubjects];
        tempSubjects = systemDataSubjects.filter((x) => x !== dataSubjectId);
        props.systemViewModel!.systemPersonalData!.systemDataSubjects = tempSubjects;
        queryClient.setQueryData(queryKey, props.systemViewModel);
        deleteMutation.mutateAsync(dataSubjectId);
    };

    const saveMutation = useMutation(save, {
        onSuccess: () => {},
    });

    function save(dataSubjectId: string) {
        return post("/system/datasubject", { systemId: props.systemId, dataSubjectId: dataSubjectId });
    }

    const deleteMutation = useMutation(remove, {
        onSuccess: () => {},
    });

    function remove(dataSubjectId: string) {
        return deleteHttp(`/system/${props.systemId}/${dataSubjectId}/dataSubject`);
    }

    return { isLoading, data, addDataSubject, removeDataSubject, systemDataSubjects, processingActivityDataSubjects };
}
