import { Box, Typography } from "@mui/material";
import React from "react";
import { DotLegalDialog, DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { SelectableDataCategoryItem } from "../../../processingActivity/ProcessingActivity.types";
import { useDataCategoriesDialog } from "./SystemOverviewDataCategories.styles";

export interface IDataCategoriesDialogProps {
    onCloseDialog: () => void;
    dataCategories: Array<SelectableDataCategoryItem> | undefined;
}

function SystemOverviewDataCategoriesDialog(props: IDataCategoriesDialogProps) {
    const { translateString } = useTranslation();
    const styles = useDataCategoriesDialog();

    return (
        <DotLegalDialog
            header={translateString("dataCategoryHeader")}
            buttonOkText={""}
            hideOkButton
            open
            onDialogClose={() => props.onCloseDialog()}
            onOkClick={() => {}}
            size="sm"
        >
            <Typography sx={styles.dialogDescription}>{translateString("systemDataCategoryDialogDescription")}</Typography>

            {props.dataCategories?.map((category) => {
                return (
                    <Box key={category.id} sx={styles.datacategoryConatiner}>
                        <DotLegalHeader headerStyle={"extraSmall"} toolTip={category.description} fontWeight={400}>
                            {category.name}
                        </DotLegalHeader>
                    </Box>
                );
            })}
        </DotLegalDialog>
    );
}

export default SystemOverviewDataCategoriesDialog;
