import { SelectableTransferBasisItem } from "../processingActivity/ProcessingActivity.types";

export const EUToUSDataPrivacyFrameworkId = "f2945536-4284-4baa-ae78-2ddc5b8a7b18";

export function filterTransferBasis(legalEntityData: Array<SelectableTransferBasisItem> | undefined, countryCode: string | undefined) {
    if (legalEntityData && countryCode) {
        return legalEntityData.filter((x) => !x.showIfCountryCodeIs || x.showIfCountryCodeIs === countryCode);
    }

    return legalEntityData?.filter((x) => !x.showIfCountryCodeIs);
}
