import { useTheme } from "@mui/material";
import { createSxStyles } from "../../../common/createSxStyles";

export const useQuestionsCollapseStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        dropdown: {
            marginBottom: theme.spacing(1),
        },
        questionText: {
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: theme.typography.pxToRem(14),
        },
        tooltip: {
            display: "inline-block",
            marginLeft: theme.spacing(1),
            verticalAlign: "text-top",
        },
        /*         questionLink: {
            color: theme.palette.secondary.main,
            textDecoration: "none",
            fontWeight: 600,
            "&:hover": {
                color: theme.palette.primary.main,
            },
        }, */
        questionLinkSeperator: {
            color: theme.palette.secondary.main,
            fontWeight: 600,
        },
    });
};
