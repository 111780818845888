import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "../../../localization/useTranslation";
import { DotLegalBarChart, DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import QuestionnaireRisk from "../../../common/components/questionnaireComponents/questionnaireRisk/QuestionnaireRisk";
import { ManagementReportChartViewModel, SectionColor, getSectionBackgroundColor } from "./ManagementReportCharts.types";
import AllocationOfRiskLevelChart from "./allocationOfRiskLevelChart/AllocationOfRiskLevelChart";
import { AuditGroupChartDataViewModel, ControllerScoreGraphColor } from "./allocationOfRiskLevelChart/AllocationOfRiskLevelChart.types";

export interface ManagementReportChartsProps {
    isPdf: boolean;
    data: ManagementReportChartViewModel;
    intervalScoreChartData: AuditGroupChartDataViewModel;
}

function ManagementReportCharts(props: ManagementReportChartsProps) {
    const { translateString } = useTranslation();

    const hasData =
        (props.intervalScoreChartData.allSectionsData.length > 0 && props.intervalScoreChartData.sections.length > 0) ||
        props.intervalScoreChartData.sections.length > 0;
    return hasData ? (
        <Box mb={4} mt={props.isPdf ? 1 : 5} className="page-break-before">
            <Box sx={{ display: "flex", gap: 2, alignItems: "baseline" }}>
                <DotLegalHeader headerStyle={"xxxl"}>{translateString("averageOverallRiskLevelAndScore")}:</DotLegalHeader>
                <Box
                    sx={(theme) => ({
                        display: "flex",
                        fontSize: theme.typography.pxToRem(24),
                        fontWeight: 700,
                        color: theme.palette.primary.main,
                        alignItems: "baseline",
                        gap: 1,
                        "& svg": {
                            height: 15,
                            width: 15,
                        },
                    })}
                >
                    {props.data.averageScoreName && props.data.averageRiskLevel && props.data.averageScore && (
                        <>
                            <QuestionnaireRisk
                                riskLevel={props.data.averageRiskLevel}
                                riskName={props.data.averageScoreName}
                                scoreEnabled
                                status={props.data.averageStatus}
                            />
                            ({props.data.averageScore} %)
                        </>
                    )}
                </Box>
            </Box>

            {props.intervalScoreChartData.allSectionsData.length > 0 && props.intervalScoreChartData.sections.length > 0 && (
                <Box className="page-break-after">
                    <AllocationOfRiskLevelChart
                        isPdf={props.isPdf}
                        data={props.intervalScoreChartData}
                        displaySelectField={props.intervalScoreChartData.sections.length > 1}
                    />
                </Box>
            )}

            {props.intervalScoreChartData.sections.length > 1 && (
                <Box className="avoid-page-break">
                    <DotLegalBarChart
                        chartHeader={
                            <>
                                <DotLegalHeader headerStyle={"small"}>{translateString("figure")} 2:</DotLegalHeader>
                                <Typography>{translateString("averageScoreForEachSection")}</Typography>
                            </>
                        }
                        isPdf={props.isPdf}
                        chartBars={props.intervalScoreChartData.sections.map((x) => {
                            return {
                                id: x.sectionId,
                                name: x.sectionName,
                                yValue: x.averageScore.toString(),
                                backgroundColor: getSectionBackgroundColor(x.sectionColor),
                                strokeColor: getSectionBackgroundColor(x.sectionColor),
                                excludeFromGChart: x.sectionWeight === 0,
                            };
                        })}
                        yAxisLabel={`${translateString("averageScore")} %`}
                        xAxisLabel={translateString("sections")}
                        barHoverEndAdorment={"%"}
                        barExcludedFromGraphTranslation={
                            props.intervalScoreChartData.sections.some((x) => x.sectionWeight === 0)
                                ? translateString("sectionHasWeightZeroNotShownInChart")
                                : ""
                        }
                    />
                </Box>
            )}
        </Box>
    ) : undefined;
}

export default ManagementReportCharts;
