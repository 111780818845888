import { DotLegalFullScreenSpinner } from "@dotlegal/dotlegal-ui-components";
import { useExportCustomListHooks } from "./ExportCustomList.hooks";
import { Alert, Box } from "@mui/material";
import { useTranslation } from "../../localization/useTranslation";

function ExportCustomList() {
    const { translateString } = useTranslation();
    const { exportStatus } = useExportCustomListHooks();

    function getContent() {
        switch (exportStatus) {
            case "Loading":
                return <DotLegalFullScreenSpinner />;
            case "Succes":
                return (
                    <Alert sx={{ padding: 2 }} severity="success">
                        {translateString("exportFileIsDownloaded")}
                    </Alert>
                );
            case "Failure":
                return (
                    <Alert sx={{ padding: 2 }} severity="error">
                        {translateString("exportFileTokenExpired")}
                    </Alert>
                );
        }
    }

    return (
        <Box height="100%">
            <Box sx={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>{getContent()}</Box>
        </Box>
    );
}

export default ExportCustomList;
