import useCreatePublicInternalAssessmentTemplateDialogHooks from "./CreatePublicInternalAssessmentTemplateDialog.hooks.ts";
import { useTranslation } from "../../../localization/useTranslation.ts";
import GroupEntityStep from "../../../legalEntity/legalEntityAuditTab/groupEntityStep/GroupEntityStep.tsx";
import { Box } from "@mui/material";
import { DotLegalDialog, DotLegalSelect, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import DotLegalSelectApi from "../../../common/components/dotLegalSelectApi/DotLegalSelectApi.tsx";
import { QuestionnaireSelectableItem } from "../../../legalEntity/legalEntityAuditTab/LegalEntityAudit.types.ts";
import { apiUrls } from "../../../common/api/apiUrls.ts";
import { arrow, leftWhiteArrowButton } from "../../../common/icons.tsx";

export interface ICreatePublicInternalAssessmentTemplateDialog {
    onDialogClose: () => void;
    onCreate: () => void;
}

function CreatePublicInternalAssessmentTemplateDialog(props: ICreatePublicInternalAssessmentTemplateDialog) {
    const { translateString } = useTranslation();

    const {
        model,
        validation,
        updater,
        step,
        selectableGroupEntitiesData,
        selectableGroupEntitiesLoading,
        onNextOrSendClick,
        onPreviousButtonClick,
        selectableResponsiblesData,
        selectableResponsiblesLoading,
        isSending,
    } = useCreatePublicInternalAssessmentTemplateDialogHooks(props);

    validation.check(model);

    function getDialogContent() {
        if (step === "first") {
            return firstStep;
        }
        return (
            <GroupEntityStep
                showGdpoIfAvailable={false}
                onGroupEntityChanged={updater.onGroupEntityChanged}
                groupEntityId={model.groupEntityId}
                errormsg={validation.getErrorsForDisplay("groupEntityId")}
                selectableGroupEntitiesData={selectableGroupEntitiesData}
                header={translateString("internalAssessmentGroupEntityHeader")}
                infoTranslationKey={"internalAssessmentPublicGroupEntity"}
                checkboxTranslationKey={"internalAssessmentEntireGroup"}
                checkboxTooltip={translateString("internalAssessmentEntireGroupInfo")}
            />
        );
    }

    const firstStep = (
        <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
            <Box sx={{ width: 450 }}>
                <DotLegalTextField
                    errorText={validation.getErrorsForDisplay("name")}
                    label={translateString("assessmentName")}
                    value={model.name}
                    debounce={false}
                    onChange={updater.onAssessmentNameChange}
                    toolTipText={translateString("assessmentNameInfo")}
                />

                <DotLegalTextField
                    label={translateString("description")}
                    onChange={updater.onDescriptionChange}
                    multiline
                    rows={3}
                    value={model.description}
                    debounce={false}
                    toolTipText={translateString("assessmentDescriptionInfo")}
                />

                <DotLegalSelectApi<QuestionnaireSelectableItem>
                    url={apiUrls().questionnaires.internalAssessment}
                    onChange={updater.onQuestionnaireChange}
                    label={translateString("template")}
                    noOptionsLabel={translateString("noOptions")}
                    selectedItem={model.questionnaireId}
                    toolTipText={translateString("selectTemplatetInfo")}
                    errorText={validation.getErrorsForDisplay("questionnaireId")}
                />

                <DotLegalSelect
                    selectedItem={model.responsibleId}
                    options={selectableResponsiblesData}
                    isLoading={selectableResponsiblesLoading}
                    onChange={updater.onResponsibleChanged}
                    label={translateString("responsible")}
                    toolTipText={translateString("assessmentResponsbileInfo")}
                    noOptionsLabel={translateString("noOptions")}
                    errorText={validation.getErrorsForDisplay("responsibleId")}
                ></DotLegalSelect>
            </Box>
        </Box>
    );

    return (
        <DotLegalDialog
            buttonOkText={step === "selectGroupEntity" ? translateString("create") : translateString("next")}
            size="lg"
            header={`${translateString("create")} ${translateString("publicInternalAssessment")}`}
            open
            buttonOkIcon={arrow}
            previousButtonIcon={leftWhiteArrowButton}
            onDialogClose={props.onDialogClose}
            onOkClick={onNextOrSendClick}
            okButtonDisabled={selectableGroupEntitiesLoading}
            previousButtonText={translateString("previous")}
            previousButtonClick={step !== "first" ? onPreviousButtonClick : undefined}
            buttonRight
            inProgress={isSending}
            disableBackdropOnCloseIfValueChange={model}
        >
            {getDialogContent()}
        </DotLegalDialog>
    );
}

export default CreatePublicInternalAssessmentTemplateDialog;
