import React from "react";
import { CircularProgress, IconButton } from "@mui/material";
import { download, eye } from "../../../icons";

export interface IQuestionnaireReportButtons {
    showButtons: boolean;
    reportPdfUrl: string;
    onDownloadReportPdf: () => void;
    isDownloadingPdf: boolean;
}

function QuestionnaireReportButtons(props: IQuestionnaireReportButtons) {
    function getReportButtons() {
        if (props.showButtons) {
            return (
                <>
                    <IconButton sx={{ mr: 0.5 }} href={props.reportPdfUrl} target="_blank">
                        {eye}
                    </IconButton>

                    <IconButton disabled={props.isDownloadingPdf} onClick={() => props.onDownloadReportPdf()}>
                        {props.isDownloadingPdf ? <CircularProgress size={20} /> : download}
                    </IconButton>
                </>
            );
        }
        return undefined;
    }

    return <React.Fragment>{getReportButtons()}</React.Fragment>;
}

export default QuestionnaireReportButtons;
