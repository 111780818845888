import React from "react";
import { DotLegalColorPicker, DotLegalDialog, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { usePurposeDialogDataMapping } from "./BusinessAreaDialog.hooks";
import { BusinessAreaSaveModel } from "./BusinessAreaDialog.types";

export interface IBusinessAreaDialog {
    onHideDialog: () => void;
    onSave: (response: any) => void;
    selectedBusinessArea: BusinessAreaSaveModel | undefined;
}

function BusinessAreaDialog(props: IBusinessAreaDialog) {
    const { translateString } = useTranslation();
    const { inProgress, viewModel, setViewModel, validateBusinessAreaForm, errors, validateBusinessAreaName, isEdit } = usePurposeDialogDataMapping(props);

    return (
        <DotLegalDialog
            inProgress={inProgress}
            size="sm"
            buttonOkText={isEdit() ? translateString("save") : translateString("create")}
            header={isEdit() ? translateString("editBusinessArea") : translateString("createBusinessArea")}
            onOkClick={() => validateBusinessAreaForm(viewModel?.name!)}
            onDialogClose={props.onHideDialog}
            open
            disableBackdropOnCloseIfValueChange={viewModel}
        >
            <DotLegalTextField
                debounce={false}
                size="small"
                label={translateString("businessArea")}
                disabled={isEdit() && !viewModel?.isCustom}
                value={viewModel?.name!}
                onChange={(name) => {
                    const newBusinessAreaViewModel = { ...viewModel! };
                    newBusinessAreaViewModel.name = name;
                    validateBusinessAreaName(name);
                    setViewModel(newBusinessAreaViewModel);
                }}
                errorText={errors.businessAreaNameError}
            />

            <DotLegalTextField
                label={translateString("description")}
                onChange={(description) => {
                    const newBusinessAreaViewModel = { ...viewModel! };
                    newBusinessAreaViewModel.description = description;
                    setViewModel(newBusinessAreaViewModel);
                }}
                multiline
                rows={4}
                debounce={false}
                value={viewModel?.description!}
            />

            {viewModel?.isCustom && (
                <DotLegalColorPicker
                    label={translateString("businessArea")}
                    selectedColor={viewModel?.color?.toString()}
                    onColorChange={(color) => {
                        const newBusinessAreaViewModel = { ...viewModel! };
                        newBusinessAreaViewModel.color = color ? Number(color) : undefined;
                        setViewModel(newBusinessAreaViewModel);
                    }}
                />
            )}
        </DotLegalDialog>
    );
}

export default BusinessAreaDialog;
