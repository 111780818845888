import React from "react";
import { Trans } from "react-i18next";
import { useTranslation } from "../../../localization/useTranslation";
import { BusinessAreaSaveModel } from "../businessAreaDialog/BusinessAreaDialog.types";
import { useChangeBusinessAreaStatusDialogDataMapping } from "./ChangeBusinessAreaStatusDialog.hooks";
import { DotLegalInfoDialog } from "@dotlegal/dotlegal-ui-components";

export interface IChangeBusinessAreaStatusDialog {
    selectedBusinessArea: BusinessAreaSaveModel;
    onCloseDialog: () => void;
    OnSave: () => void;
}

export function ChangeBusinessAreaStatusDialog(props: IChangeBusinessAreaStatusDialog) {
    const { changeActiveStatus, inProgress } = useChangeBusinessAreaStatusDialogDataMapping(props);
    const { translateString } = useTranslation();

    return (
        <DotLegalInfoDialog
            btnText={props.selectedBusinessArea.active ? translateString("deactivate") : translateString("activate")}
            header={props.selectedBusinessArea.active ? translateString("deactivateBusinessAreaeHeader") : translateString("activateBusinessAreaHeader")}
            inProgress={inProgress}
            content={
                props.selectedBusinessArea.active ? (
                    <Trans i18nKey="deactivateDataInfo" values={{ name: props.selectedBusinessArea.name }}></Trans>
                ) : (
                    <Trans i18nKey="activateDataInfo" values={{ name: props.selectedBusinessArea.name }}></Trans>
                )
            }
            onBtnClick={() => changeActiveStatus(props.selectedBusinessArea)}
            onCloseDialog={props.onCloseDialog}
        />
    );
}
