import { createElement, useEffect, useState } from "react";
import { useTranslation } from "../../localization/useTranslation";
import { DotLegalFile, useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { get, post } from "../../common/api/apiShared";
import { useMutation, useQuery } from "react-query";
import { _arrayBufferToBase64 } from "../../common/arrayBufferToBase64";
import { Trans } from "react-i18next";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { ICustomizeEmailLogoDialog } from "./CustomizeEmailLogoDialog";
import { QuestionnaireEmailLogoCustomizationViewModel } from "./CustomizeEmailLogoDialog.types";

export default function useCustomizeEmailLogoDialogHooks(props: ICustomizeEmailLogoDialog) {
    const { emailAddress } = useUserContext();
    const { translateString } = useTranslation();

    const snackbar = useDotLegalSnackbar();

    const [error, setError] = useState("");
    const [viewModel, setViewModel] = useState<QuestionnaireEmailLogoCustomizationViewModel | undefined>(undefined);

    const queryKey = "questionnaireEmailLogo";
    const { isLoading, data } = useQuery(queryKey, () => get<QuestionnaireEmailLogoCustomizationViewModel>("/QuestionnaireEmailCustomization/GetLogoId"));

    useEffect(() => {
        if (data) {
            setViewModel({ fileName: data.fileName, logoId: data.logoId });
        }
    }, [data, isLoading]);

    function validateFile(files: DotLegalFile[]) {
        if (files.length > 1) {
            setError(translateString("oneFileOnly"));
            return false;
        } else if (files.length === 1) {
            if (!files[0].fileName.toLowerCase().endsWith(".png")) {
                setError(translateString("emailLogoWrongFileFormat"));
                return false;
            }
        }

        setError("");
        return true;
    }

    const sendEmailMutation = useMutation(sendTestEmailAPI, {
        onSuccess: (response: any) => {
            snackbar.show(createElement(Trans, { i18nKey: "testEmailSent", values: { email: emailAddress } }));
        },
    });

    const onSendEmailClick = async () => {
        await sendEmailMutation.mutateAsync();
    };

    const onSaveLogoClick = async () => {
        await saveMutation.mutateAsync();
    };

    const saveMutation = useMutation(saveAPI, {
        onSuccess: (response: any) => {
            snackbar.show(translateString("logoAddedToMails"));
            props.onDialogClose();
        },
    });

    const uploadLogoMutation = useMutation(uploadLogoApi);
    const onFileUpload = async (files: Array<DotLegalFile> | undefined) => {
        if (files) {
            var isValid = validateFile(files);

            if (isValid) {
                let tempFile = { ...files[0] };
                await uploadLogoMutation.mutateAsync(await _arrayBufferToBase64(tempFile.data), {
                    onSuccess: (response: any) => {
                        setViewModel({ fileName: tempFile.fileName, logoId: response.value() });
                    },
                });
            }
        }
    };

    const onRemoveFile = () => {
        setViewModel(undefined);
    };

    return {
        onFileUpload,
        error,
        onSendEmailClick,
        onSaveLogoClick,
        isSendingEmail: sendEmailMutation.isLoading,
        isSavingLogo: saveMutation.isLoading,
        isLoading,
        viewModel,
        onRemoveFile,
    };

    async function saveAPI() {
        return post<{}>("/QuestionnaireEmailCustomization/Logo", viewModel);
    }

    async function uploadLogoApi(base64Data: string) {
        return post<{}>("/QuestionnaireEmailCustomization/uploadLogo", base64Data);
    }

    async function sendTestEmailAPI() {
        return post<{}>("/QuestionnaireEmailCustomization/SendTestLogoEmail", viewModel?.logoId);
    }
}
