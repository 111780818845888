import { Box, RadioGroup, Skeleton, Typography } from "@mui/material";
import React from "react";
import { DotLegalInformationTooltip, DotLegalRadioButton } from "@dotlegal/dotlegal-ui-components";
import DotLegalPaper from "../../common/components/dotLegalPaper/DotLegalPaper";
import { useTranslation } from "../../localization/useTranslation";
import { useLegalEntityRiskQuestionnaireHook } from "./LegalEntityRiskQuestionnaire.hooks";
import { useLegalEntityRiskQuestionnaireStyles } from "./LegalEntityRiskQuestionnaire.styles";
import {
    LegalEntityRiskAssessmentQuestion,
    LegalEntityRiskAssessmentQuestionAnswerId,
    LegalEntityRiskAssessmentQuestionId,
} from "./LegalEntityRiskQuestionnaire.types";

export type riskAssessmentQuestionnaireBackground = "white" | "lightgrey";

export interface ILegalEntityRiskQuestionnaireProps {
    backgroundColor: riskAssessmentQuestionnaireBackground;
    questions: Array<LegalEntityRiskAssessmentQuestion>;
    onAnswerClick: (questionId: LegalEntityRiskAssessmentQuestionId, anwerId?: LegalEntityRiskAssessmentQuestionAnswerId) => void;
    isLoading: boolean;
    onClose?: () => void;
    enableClose: boolean;
}

function LegalEntityRiskQuestionnaire(props: ILegalEntityRiskQuestionnaireProps) {
    const styles = useLegalEntityRiskQuestionnaireStyles(props);
    const { translateString } = useTranslation();
    const { handleRadioChange } = useLegalEntityRiskQuestionnaireHook(props);

    const getToolTip = (tooltipText: string) => {
        return <DotLegalInformationTooltip text={tooltipText} />;
    };

    const getQuestionHeader = (header: string, tooltip: string) => {
        return (
            <Box sx={styles.questionHeader}>
                <Typography sx={styles.questionHeaderText} variant="h5">
                    {header}
                </Typography>
                {getToolTip(tooltip)}
            </Box>
        );
    };

    let question1Answer: LegalEntityRiskAssessmentQuestionAnswerId | undefined = props.isLoading === false ? props.questions[0]?.answerId : undefined;
    let question2Answer: LegalEntityRiskAssessmentQuestionAnswerId | undefined = props.isLoading === false ? props.questions[1]?.answerId : undefined;
    let question3Answer: LegalEntityRiskAssessmentQuestionAnswerId | undefined = props.isLoading === false ? props.questions[2]?.answerId : undefined;
    let question4Answer: LegalEntityRiskAssessmentQuestionAnswerId | undefined = props.isLoading === false ? props.questions[3]?.answerId : undefined;

    return (
        <DotLegalPaper sx={styles.paper}>
            <Box sx={styles.headerContainer}>
                <Box sx={styles.headerInfo}>
                    <Typography sx={styles.calcClassificationHeader} variant="h4">
                        {translateString("calculateClassification")}
                    </Typography>
                    <Typography sx={styles.subHeader}>{translateString("guideCalculateClassification")}</Typography>
                </Box>
                {props.enableClose && (
                    <Box sx={styles.closeBtn}>
                        <Typography onClick={props.onClose} sx={styles.closeBtnText}>
                            {translateString("close")}
                        </Typography>
                    </Box>
                )}
            </Box>

            <Box sx={styles.questionContainer}>
                {getQuestionHeader(translateString("questionNumberOfProcessors"), translateString("questionNumberOfProcessorsDescription"))}
                {props.isLoading ? (
                    <Skeleton height={150} />
                ) : (
                    <RadioGroup
                        sx={styles.radioGroup}
                        value={question1Answer ?? ""}
                        onClick={(e: any) => handleRadioChange(e, LegalEntityRiskAssessmentQuestionId.Question1)}
                    >
                        <DotLegalRadioButton value={LegalEntityRiskAssessmentQuestionAnswerId.Answer1} label={translateString("underThousand")} />
                        <DotLegalRadioButton value={LegalEntityRiskAssessmentQuestionAnswerId.Answer2} label={translateString("thousandToTenThousand")} />
                        <DotLegalRadioButton value={LegalEntityRiskAssessmentQuestionAnswerId.Answer3} label={translateString("overTenThousand")} />
                    </RadioGroup>
                )}
            </Box>

            <Box sx={styles.questionContainer}>
                {getQuestionHeader(translateString("questionSensitiveDataCategories"), translateString("questionSensitiveDataCategoriesDescription"))}
                {props.isLoading ? (
                    <Skeleton height={150} />
                ) : (
                    <RadioGroup
                        sx={styles.radioGroup}
                        value={question2Answer ?? ""}
                        onClick={(e: any) => handleRadioChange(e, LegalEntityRiskAssessmentQuestionId.Question2)}
                    >
                        <DotLegalRadioButton value={LegalEntityRiskAssessmentQuestionAnswerId.Answer2} label={translateString("yes")} />
                        <DotLegalRadioButton value={LegalEntityRiskAssessmentQuestionAnswerId.Answer1} label={translateString("no")} />
                    </RadioGroup>
                )}
            </Box>

            <Box sx={styles.questionContainer}>
                {getQuestionHeader(translateString("questionClassifiedDataCategories"), translateString("questionClassifiedDataCategoriesDescription"))}
                {props.isLoading ? (
                    <Skeleton height={150} />
                ) : (
                    <RadioGroup
                        sx={styles.radioGroup}
                        value={question3Answer ?? ""}
                        onClick={(e: any) => handleRadioChange(e, LegalEntityRiskAssessmentQuestionId.Question3)}
                    >
                        <DotLegalRadioButton value={LegalEntityRiskAssessmentQuestionAnswerId.Answer2} label={translateString("yes")} />
                        <DotLegalRadioButton value={LegalEntityRiskAssessmentQuestionAnswerId.Answer1} label={translateString("no")} />
                    </RadioGroup>
                )}
            </Box>

            <Box sx={styles.questionContainer}>
                {getQuestionHeader(translateString("questionSpecialDataCategories"), translateString("questionSpecialDataCategoriesDesciption"))}
                {props.isLoading ? (
                    <Skeleton height={150} />
                ) : (
                    <RadioGroup
                        sx={styles.radioGroup}
                        value={question4Answer ?? ""}
                        onClick={(e: any) => handleRadioChange(e, LegalEntityRiskAssessmentQuestionId.Question4)}
                    >
                        <DotLegalRadioButton value={LegalEntityRiskAssessmentQuestionAnswerId.Answer2} label={translateString("yes")} />
                        <DotLegalRadioButton value={LegalEntityRiskAssessmentQuestionAnswerId.Answer1} label={translateString("no")} />
                    </RadioGroup>
                )}
            </Box>
        </DotLegalPaper>
    );
}

export default LegalEntityRiskQuestionnaire;
