type Defined<T> = Exclude<T, undefined | null>;
export function toArray<T extends Exclude<any, undefined | null>>(value?: T | T[] | undefined | null): Defined<T>[] {
    if (!value) return [];

    if (Array.isArray(value)) return value.filter((v) => !!v) as Defined<T>[];

    return [value as Defined<T>];
}

export function exhaustiveGuard(_value: never): never {
    throw new Error(`ERROR! Reached forbidden guard function with unexpected value: ${JSON.stringify(_value)}`);
}
