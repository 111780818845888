import { useQuery } from "react-query";
import { LegalEntitySystemTableModel, LegalEntitySystemViewModel } from "./LegalEntitySystemTab.types";
import { get } from "../../common/api/apiShared";
import { ILegalEntityAssetsTab } from "./LegalEntitySystemTab";
import { useTranslation } from "../../localization/useTranslation";

export default function useLegalEntitySystemTabHooks(props: ILegalEntityAssetsTab) {
    const { translateLegalEntityAssetRole } = useTranslation();
    const url = `/legalEntitySystems/${props.legalEntityId}`;
    let { isLoading, data } = useQuery(url, () => get<Array<LegalEntitySystemViewModel>>(url));

    let hasData = isLoading ? true : data!.length > 0;

    let systems: Array<LegalEntitySystemTableModel> = [];
    if (data) {
        systems = data.map((x) => {
            return { id: x.id, name: x.name, roles: x.roles.map((role) => translateLegalEntityAssetRole(role.toString())).join(", ") };
        });
    }

    return { isLoading, systems, hasData };
}
