import { DotLegalDialog, DotLegalHeader, DotLegalMultiSelect } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../../localization/useTranslation";
import { Box } from "@mui/material";
import { Trans } from "react-i18next";
import { ReactNode } from "react";
import useAssociatedInternalAssessmentDialogHooks from "./AssociatedInternalAssessmentDialog.hooks";

export interface IAssociatedInternalAssessmentDialog {
    onDialogClose: () => void;
    name: string;
    dialogHeader: string;
    dialogInfo: ReactNode;
    chosenOptions: Array<string>;
    existingInternalAssessments: Array<string>;
    onDeleteInternalAssessment: (internalAssessmentId: string) => void;
    onAddInternalAssessment: (internalAssessmentId: string) => void;
}

function AssociatedInternalAssessmentDialog(props: IAssociatedInternalAssessmentDialog) {
    const { translateString } = useTranslation();
    const { data, isLoading, onSelectInternalAssessmentChange } = useAssociatedInternalAssessmentDialogHooks(props);

    return (
        <DotLegalDialog
            header={props.dialogHeader}
            size="sm"
            buttonOkText={translateString("add")}
            open
            onDialogClose={props.onDialogClose}
            onOkClick={props.onDialogClose}
        >
            <>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <DotLegalHeader center fontWeight={400} headerStyle={"small"}>
                        {props.dialogInfo}
                    </DotLegalHeader>
                    <DotLegalMultiSelect
                        options={data}
                        chosenOptions={props.chosenOptions}
                        onChange={onSelectInternalAssessmentChange}
                        label={translateString("assessments")}
                        noOptionsLabel={translateString("noOptions")}
                        isLoading={isLoading}
                        disableClearable
                    />
                </Box>
            </>
        </DotLegalDialog>
    );
}

export default AssociatedInternalAssessmentDialog;
