import React from "react";
import { AnnualWheelMonthEnum } from "./AnnualWheel.types.ts";
import { useUserContext } from "../auth/userContextProvider/UserContextProvider.tsx";
import { useTranslation } from "../localization/useTranslation.ts";
import { useUrlProvider } from "../useUrlProvider.ts";
import { DotLegalOverflowMenu } from "@dotlegal/dotlegal-ui-components";

export interface IAnnualWheelOverflowMenuProps {
    id: string;
    month: AnnualWheelMonthEnum;
    deleted: boolean;
    partOfCurrentPlan: boolean;
    onEditClick: () => void;
    onDeleteClick: () => void;
}

function AnnualWheelOverflowMenu(props: IAnnualWheelOverflowMenuProps) {
    const { translateString } = useTranslation();
    const { getTaskManagementUrlForTask } = useUrlProvider();
    const { permissions } = useUserContext();
    const showOverFlowMenu = () => {
        if (props.deleted) {
            return permissions.annualWheelPermission.delete;
        }

        return permissions.annualWheelPermission.edit || permissions.annualWheelPermission.delete || permissions.taskPermissions.read;
    };

    const getMenuItems = () => {
        if (props.deleted && permissions.annualWheelPermission.delete) {
            return [
                {
                    menuItem: translateString("delete"),
                    onClick: () => {
                        props.onDeleteClick();
                    },
                },
            ];
        }

        let menuItems = [];

        if (permissions.taskPermissions.read) {
            menuItems.push({
                menuItem: translateString("viewInTaskManagement"),
                href: getTaskManagementUrlForTask(props.month, props.id),
            });
        }

        if (permissions.annualWheelPermission.edit && props.partOfCurrentPlan) {
            menuItems.push({
                menuItem: translateString("edit"),
                onClick: () => {
                    props.onEditClick();
                },
            });
        }

        if (permissions.annualWheelPermission.delete) {
            menuItems.push({
                menuItem: translateString("delete"),
                onClick: () => {
                    props.onDeleteClick();
                },
            });
        }

        return menuItems;
    };

    return <>{showOverFlowMenu() && <DotLegalOverflowMenu items={getMenuItems()} />}</>;
}

export default AnnualWheelOverflowMenu;
