import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { get, put } from "../common/api/apiShared";
import { useStateUrlParams } from "../common/hooks/useStateUrlParams";
import { useTranslation } from "../localization/useTranslation";
import { useNavMenuDataMapping } from "../main/NavMenu/NavMenu.hooks";
import { NotificationViewModel } from "./Notification.types";
import { DotLegalBreadCrumbModel } from "@dotlegal/dotlegal-ui-components";

export function useNotificationDataMapping() {
    const { translateString } = useTranslation();

    const [showDeleteNotificationDialog, setShowDeleteNotificationDialog] = useState(false);
    const [selectedNotificationId, setSelectedNotificationId] = useStateUrlParams("notificationId", "");
    const { isLoading, data, refetch } = useQuery("notifications", () => get<Array<NotificationViewModel>>("/notification/details"));
    let videoQuery = useQuery("introVideoLink", () => get<string>("/videolink/intro"));

    const { refetchMenuNotifications } = useNavMenuDataMapping();

    const editMutation = useMutation(update);

    function update(id: string) {
        return put<string>(`/notification/${id}/markasread`, true);
    }

    const updateNotificationState = (notificationId: string) => {
        editMutation.mutateAsync(notificationId!, {
            onSuccess: (r: any) => {
                refetchNotifications();
            },
        });
    };

    let selectedNotification;
    if (data && selectedNotificationId) {
        selectedNotification = data?.find((x) => x.id === selectedNotificationId);

        if (selectedNotification && selectedNotification!.isRead === false) {
            selectedNotification!.isRead = true;
            updateNotificationState(selectedNotification!.id);
        }
    }

    const getBreadCrumbs = () => {
        let breadCrumbs: Array<DotLegalBreadCrumbModel> = [];

        breadCrumbs.push({ name: translateString("notifications") });
        return breadCrumbs;
    };
    const refetchNotifications = () => {
        refetch();
        refetchMenuNotifications();
    };

    return {
        isLoading,
        getBreadCrumbs,
        data,
        selectedNotificationId,
        setSelectedNotificationId,
        selectedNotification,
        videoData: videoQuery.data,
        hasNotifications: isLoading || data!.length > 0,
        updateNotificationState,
        showDeleteNotificationDialog,
        setShowDeleteNotificationDialog,
        refetchNotifications,
    };
}
