import { Theme, useTheme } from "@mui/material";
import { ILegalEntityRiskQuestionnaireProps } from "./LegalEntityRiskQuestionnaire";
import { createSxStyles } from "../../common/createSxStyles";

export const useLegalEntityRiskQuestionnaireStyles = (props: ILegalEntityRiskQuestionnaireProps) => {
    const theme = useTheme();
    return createSxStyles({
        paper: {
            width: "100%",
            padding: theme.spacing(2),
            backgroundColor: getBackgroundColor(props, theme),
            boxShadow: "none",
        },
        headerContainer: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
        },
        closeBtn: {
            textDecoration: "underline",
            "& :hover": {
                cursor: "pointer",
            },
        },
        closeBtnText: {
            fontSize: theme.typography.pxToRem(11),
            color: theme.palette.primary.main,
        },
        subHeader: {
            fontSize: theme.typography.pxToRem(11),
            fontStyle: "italic",
            color: theme.palette.primary.main,
        },
        questionContainer: {
            paddingBottom: theme.spacing(3),
            "& .MuiRadio-root": {
                padding: 4 + "px",
            },
            "& .MuiFormControlLabel-label": {
                fontSize: theme.typography.pxToRem(12),
            },
            "& .MuiTypography-root": {
                fontSize: theme.typography.pxToRem(12),
            },
            "& .MuiSkeleton-root": {
                marginTop: theme.spacing(-4) + "px !important",
                marginBottom: theme.spacing(-4) + "px !important",
            },
        },
        headerInfo: {
            paddingBottom: theme.spacing(2),
        },
        questionHeader: {
            display: "flex",
            marginBottom: theme.spacing(0.5),
        },
        questionHeaderText: {
            marginRight: theme.spacing(1),
        },
        radioGroup: {
            padding: theme.spacing(0.5),
            marginTop: theme.spacing(-1.5),
        },
        calcClassificationHeader: {
            fontSize: theme.typography.pxToRem(12),
        },
        checkBoxLabels: {
            fontSize: theme.typography.pxToRem(14) + " !important",
            color: theme.palette.primary.main,
        },
    });
};

function getBackgroundColor(props: ILegalEntityRiskQuestionnaireProps, theme: Theme) {
    let backgroundColor = "";

    switch (props.backgroundColor) {
        case "white":
            backgroundColor = "inherit";
            break;
        case "lightgrey":
            backgroundColor = theme.palette.grey[100];
            break;
    }
    return backgroundColor;
}
