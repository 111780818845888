import { Trans } from "react-i18next";
import { useTranslation } from "../../../localization/useTranslation";
import { SecurityMeasureSaveModel } from "../securityMeasures/SecurityMeasures.types";
import { useChangeSecurityMeasureStatusDialogDataMapping } from "./ChangeSecurityMeasureStatusDialog.hooks";
import { DotLegalInfoDialog } from "@dotlegal/dotlegal-ui-components";

export interface IChangeSecurityMeasureStatusDialogDataMapping {
    selectedSecurityMeasure: SecurityMeasureSaveModel;
    onCloseDialog: () => void;
    refetch: () => void;
}

export function ChangeSecurityMeasureStatusDialog(props: IChangeSecurityMeasureStatusDialogDataMapping) {
    const { changeActiveStatus, inProgress } = useChangeSecurityMeasureStatusDialogDataMapping(props);
    const { translateString } = useTranslation();

    return (
        <DotLegalInfoDialog
            btnText={props.selectedSecurityMeasure.active ? translateString("deactivate") : translateString("activate")}
            header={
                props.selectedSecurityMeasure.active ? translateString("deactivateSecurityMeasureHeader") : translateString("activateSecurityMeasureHeader")
            }
            inProgress={inProgress}
            content={
                props.selectedSecurityMeasure.active ? (
                    <Trans i18nKey="deactivateDataInfo" values={{ name: props.selectedSecurityMeasure.name }}></Trans>
                ) : (
                    <Trans i18nKey="activateDataInfo" values={{ name: props.selectedSecurityMeasure.name }}></Trans>
                )
            }
            onBtnClick={() => changeActiveStatus(props.selectedSecurityMeasure)}
            onCloseDialog={props.onCloseDialog}
        />
    );
}
