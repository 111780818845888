import { useQuery } from "react-query";
import { get } from "../../common/api/apiShared";
import { SelectableItem } from "../ProcessingActivity.types";

export function useTransferBasisQuery(hasPermissions: boolean) {
    const url = "/LegalBasis/getTransferBasis/";
    const transferBasisQuery = useQuery(url, () => get<Array<SelectableItem>>(url), { enabled: hasPermissions });
    return {
        data: transferBasisQuery.data,
        isLoading: transferBasisQuery.isLoading,
    };
}
