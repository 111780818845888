import React from "react";
import { Box, Skeleton } from "@mui/material";
import { Theme } from "@mui/material/styles/createTheme";

export interface IWhiteDashboardBoxProps {
    marginTop?: number;
    fullHeight?: boolean;
    isLoading?: boolean;
    children: React.ReactNode;
    height?: number;
}

function WhiteDashboardBox(props: IWhiteDashboardBoxProps) {
    const sx = (theme: Theme) => ({
        mt: props.marginTop,
        height: props.fullHeight ? "100%" : props.height ? props.height : undefined,
        padding: 2,
        borderRadius: "20px",
        backgroundColor: theme.customPalette.white,
    });
    return <>{props.isLoading ? <Skeleton sx={sx} variant={"rectangular"} /> : <Box sx={sx}>{props.children}</Box>}</>;
}

export default WhiteDashboardBox;
