import { createElement, useState } from "react";
import { useMutation } from "react-query";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { post, put } from "../../../common/api/apiShared";
import { PurposeSaveModel } from "./PurposeDialog.types";
import { IPurposeDialog } from "./PurposeDialog";
import { isNullOrWhitespace } from "../../../common/stringOperations";
import { Result } from "../../../common/api/result";
import { Trans } from "react-i18next";

export function usePurposeDialogDataMapping(props: IPurposeDialog) {
    const snackbar = useDotLegalSnackbar();
    const { translateString } = useTranslation();
    const [errors, setErrors] = useState({ purposeNameError: "" });
    const [inProgress, setInProgress] = useState(false);
    const [viewModel, setViewModel] = useState<PurposeSaveModel | undefined>(props.selectedPurpose);

    const saveMutation = useMutation(save, {
        onSuccess: (response: Result<string>) => {
            if (response.success()) {
                props.onHideDialog();
                props.onSave(response.value());
                setInProgress(false);
                setViewModel(undefined);
                snackbar.show(createElement(Trans, { i18nKey: "purposeCreated", values: { name: viewModel?.name } }));
            } else {
                setInProgress(false);
                snackbar.show(translateString("purposeExists"), "warning");
            }
        },
    });

    const createPurpose = async () => {
        await saveMutation.mutateAsync();
    };

    function save() {
        return post<string>("/purposes/", viewModel);
    }

    const editMutation = useMutation(update);
    const updateUser = () => {
        editMutation.mutateAsync(viewModel!.id, {
            onSuccess: (response: Result<string>) => {
                if (response.success()) {
                    snackbar.show(translateString("purposeUpdated", { name: viewModel!.name }));
                    props.onHideDialog();
                    props.onSave("");
                } else {
                    setInProgress(false);
                    snackbar.show(translateString("purposeExists"), "warning");
                }
            },
        });
    };

    function update(id: string) {
        return put<string>(`/purposes/${id}/`, viewModel);
    }

    return {
        inProgress,
        errors,
        viewModel,
        setViewModel,
        validatePurposeForm,
        validatePurposeName,
        isEdit,
    };

    function validatePurposeName(name: string) {
        let tempErrors = { ...errors };

        tempErrors.purposeNameError = isNullOrWhitespace(name) ? translateString("fieldMandatory") : "";

        setErrors(tempErrors);
    }

    function validatePurposeForm(name: string) {
        let formValid = true;
        let tempErrors = { ...errors };

        if (isNullOrWhitespace(name)) {
            tempErrors.purposeNameError = translateString("fieldMandatory");
            formValid = false;
        }

        if (formValid) {
            setInProgress(true);

            if (isEdit()) updateUser();
            else createPurpose();
        } else {
            setErrors(tempErrors);
        }
    }

    function isEdit() {
        return props.selectedPurpose !== undefined && props.selectedPurpose!.id !== "";
    }
}
