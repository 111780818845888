import { createElement, useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { get, post, put } from "../../common/api/apiShared";
import { Result } from "../../common/api/result";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { isNullOrWhitespace } from "../../common/stringOperations";
import { useTranslation } from "../../localization/useTranslation";
import { useUrlProvider } from "../../useUrlProvider";
import { RoleMasterDataViewModel } from "../Roles.types";
import { IAddEditRolesMasterData } from "./AddEditRolesMasterData";
import { RolesMasterDataSaveModel } from "./AddEditRolesMasterData.types";

export function useRolesMasterDataHooks(props: IAddEditRolesMasterData) {
    const snackbar = useDotLegalSnackbar();
    const { translateString } = useTranslation();
    const navigate = useNavigate();
    const urlProvider = useUrlProvider();

    const [errors, setErrors] = useState({ userRolesNameError: "" });
    const [saveModel, setSaveModel] = useState<RolesMasterDataSaveModel | undefined>(getSaveModel());

    const { isLoading, data } = useQuery(`/role/${props.id}/masterdata`, () => get<RolesMasterDataSaveModel>(`/role/${props.id}/masterdata`), {
        enabled: isEdit(),
    });

    useEffect(() => {
        if (data) {
            setSaveModel(data);
        }
    }, [data]);

    const saveMutation = useMutation(save);
    const createUserRole = async () => {
        await saveMutation.mutateAsync(undefined, {
            onSuccess: (response: Result<RoleMasterDataViewModel>) => {
                setSaveModel(undefined);
                if (props.onSave) props.onSave();
                props.onDialogClose();
                snackbar.show(createElement(Trans, { i18nKey: "userRolesCreated", values: { name: saveModel?.name } }));
                navigate(urlProvider.getEditRolesUrl(response.value().id));
            },
        });
    };

    const editMutation = useMutation(update);
    const updateUserRole = () => {
        editMutation.mutateAsync(undefined, {
            onSuccess: () => {
                snackbar.show(createElement(Trans, { i18nKey: "userRolesUpdated", values: { name: saveModel!.name } }));
                navigate(urlProvider.getUserRolesUrl());
                if (props.onSave) props.onSave();
            },
        });
    };

    function validateRolesMasterDataName(name: string) {
        let tempErrors = { ...errors };

        tempErrors.userRolesNameError = isNullOrWhitespace(name) ? translateString("fieldMandatory") : "";

        setErrors(tempErrors);
    }

    function getSaveModel() {
        if (!props.id) {
            props.selectedRoleViewModel!.canBeChosenAsResponsible = true;
        }

        return props.selectedRoleViewModel;
    }

    function validateRolesMasterDataSaveModel(name: string) {
        let formValid = true;
        let tempErrors = { ...errors };

        if (isNullOrWhitespace(name)) {
            tempErrors.userRolesNameError = translateString("fieldMandatory");
            formValid = false;
        }

        if (formValid) {
            if (isEdit()) updateUserRole();
            else createUserRole();
        } else {
            setErrors(tempErrors);
        }
    }

    function update() {
        return put(`/role/${props.id}/masterdata`, saveModel);
    }

    function save() {
        return post<RoleMasterDataViewModel>("/role/masterdata", saveModel);
    }

    function isEdit() {
        return props.id !== undefined;
    }

    return {
        isLoading,
        inProgress: editMutation.isLoading || saveMutation.isLoading,
        saveModel,
        setSaveModel,
        errors,
        validateRolesMasterDataName,
        validateRolesMasterDataSaveModel,
        isEdit,
    };
}
