import React from "react";
import { DotLegalDialog, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { usePurposeDialogDataMapping } from "./PurposeDialog.hooks";
import { PurposeSaveModel } from "./PurposeDialog.types";

export interface IPurposeDialog {
    onHideDialog: () => void;
    onSave: (response: any) => void;
    selectedPurpose: PurposeSaveModel | undefined;
}

function PurposeDialog(props: IPurposeDialog) {
    const { translateString } = useTranslation();
    const { inProgress, viewModel, setViewModel, validatePurposeForm, errors, validatePurposeName, isEdit } = usePurposeDialogDataMapping(props);

    return (
        <DotLegalDialog
            inProgress={inProgress}
            size="sm"
            buttonOkText={isEdit() ? translateString("save") : translateString("create")}
            header={isEdit() ? translateString("editPurpose") : translateString("createPurpose")}
            onOkClick={() => validatePurposeForm(viewModel?.name!)}
            onDialogClose={props.onHideDialog}
            open
            disableBackdropOnCloseIfValueChange={viewModel}
        >
            <DotLegalTextField
                debounce={false}
                size="small"
                disabled={isEdit() && !viewModel?.isCustom}
                label={translateString("purpose")}
                value={viewModel?.name!}
                onChange={(name) => {
                    const newPurposeViewModel = { ...viewModel! };
                    newPurposeViewModel.name = name;
                    validatePurposeName(name);
                    setViewModel(newPurposeViewModel);
                }}
                errorText={errors.purposeNameError}
            />

            <DotLegalTextField
                label={translateString("description")}
                onChange={(description) => {
                    const newPurposeViewModel = { ...viewModel! };
                    newPurposeViewModel.description = description;
                    setViewModel(newPurposeViewModel);
                }}
                multiline
                rows={4}
                debounce={false}
                value={viewModel?.description!}
            />
        </DotLegalDialog>
    );
}

export default PurposeDialog;
