import DotLegalDeleteDialogWrapper from "../../common/components/dotLegalDeleteDialog/DotLegalDeleteDialogWrapper";
import React from "react";
import { useIncidentLogDeleteHook } from "./IncidentLogDeleteDialog.hooks";

export interface IIncidentLogDeleteDialogProps {
    id: string;
    name: string;
    onCloseDialog: () => void;
    onSuccessDelete: () => void;
}

function IncidentLogDeleteDialog(props: IIncidentLogDeleteDialogProps) {
    const { inProgress, removeIncidentClick } = useIncidentLogDeleteHook(props);
    return (
        <DotLegalDeleteDialogWrapper
            open
            itemForDeletion={props.name}
            onDialogClose={props.onCloseDialog}
            onOkClick={removeIncidentClick}
            inProgress={inProgress}
        />
    );
}

export default IncidentLogDeleteDialog;
