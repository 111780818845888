import { useNavigate } from "react-router-dom";
import { useTranslation } from "../localization/useTranslation";
import { useUrlProvider } from "../useUrlProvider";
import { DotLegalBreadCrumbModel } from "@dotlegal/dotlegal-ui-components";
import MasterIcon from "./masterData.svg?react";
import React from "react";

export function useMasterDataMapping() {
    const navigate = useNavigate();
    const urlProvider = useUrlProvider();
    const { translateString } = useTranslation();

    const getBreadCrumbs = () => {
        let breadCrumbs: Array<DotLegalBreadCrumbModel> = [];

        breadCrumbs.push({ name: translateString("settings"), link: urlProvider.getSettingsUrl() });
        breadCrumbs.push({ name: translateString("masterData") });

        return breadCrumbs;
    };

    function redirectTo(url: string) {
        navigate(url);
    }

    return {
        redirectTo,
        getBreadCrumbs,
    };
}

export function getMasterIcon() {
    return React.createElement(MasterIcon);
}
