import { useState } from "react";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { useSelectablePurposesQuery } from "../../common/hooks/usePurposesQuery";
import { isNullOrWhitespace } from "../../common/stringOperations";
import { ValidationError } from "../../common/validationError";
import { useTranslation } from "../../localization/useTranslation";
import { ProcessingActivityModel } from "../ProcessingActivity.types";
import { IMasterDataStepProps } from "./MasterDataStep";

export function useMasterDataStepDataMapping(props: IMasterDataStepProps) {
    const [showPurposeDialog, setShowPurposeDialog] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const { permissions } = useUserContext();

    let { isLoading, data, refetch } = useSelectablePurposesQuery(!permissions.processingActivityMasterDataPermissions.hasPurposePermission);

    let tableData;

    if (data) {
        tableData = data.map((d, index) => {
            return {
                name: d.name,
                id: d.id,
            };
        });
    }

    const onSavePurpose = (response: any) => {
        var temp = [...props.purposes!];
        temp.push(response.id);
        props.OnPurposesChange(temp);

        refetch();
        setSearchValue("");
    };

    return {
        tableData,
        isLoading,
        showPurposeDialog,
        setShowPurposeDialog,
        onSavePurpose,
        searchValue,
        setSearchValue,
    };
}

export function useValidateMasterDataStep(hasPurposeAccess: boolean) {
    const { translateString } = useTranslation();
    return (model: ProcessingActivityModel): Array<ValidationError> => {
        const result = [];
        if (isNullOrWhitespace(model.name)) {
            result.push(new ValidationError("processingActivityName", translateString("nameRequired")));
        }

        if (hasPurposeAccess && (!model.purposes || model.purposes.length === 0)) {
            result.push(new ValidationError("purposes", translateString("purposesRequired")));
        }
        return result;
    };
}

export function useValidationDataSubjectStep() {
    const { translateString } = useTranslation();
    return (model: ProcessingActivityModel): Array<ValidationError> => {
        const result = [];
        if (!model.dataSubjects || model.dataSubjects.length === 0) {
            result.push(new ValidationError("dataSubjects", translateString("dataSubjectRequired")));
        }
        return result;
    };
}
