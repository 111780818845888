import { useTheme } from "@mui/material";
import { createSxStyles } from "../../createSxStyles";

export const useDotLegalCoreStepperStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        root: {
            padding: 20 + "px",
        },
        stepButton: {
            "& .MuiButtonBase-root": {
                "&::after": {
                    transition: "0.2s",
                    transform: "scale(1.2, 1.2)",
                },
            },
            "& .MuiStepButton-touchRipple": {
                color: "transparent",
            },
        },
        label: {
            overflowWrap: "break-word",
            wordWrap: "break-word",
            msWordBreak: "break-all",
            wordBreak: "break-word",
            msHyphens: "auto",
            MozHyphens: "auto",
            WebkitHyphens: "auto",
            hyphens: "auto",
            color: theme.palette.grey["400"],
            "& .MuiStepLabel-label": {
                fontSize: theme.typography.pxToRem(12),
                color: "inherit",

                "&.Mui-active": {
                    color: theme.palette.primary.dark,
                    fontWeight: theme.typography.fontWeightBold,
                },
                "&.Mui-error": {
                    color: theme.palette.error.main,
                },
            },
        },
        completedOrErrorClass: {
            color: theme.palette.primary.dark,
        },
        stepWrapper: {
            display: "flex",
            flexDirection: "column",
            marginTop: -24 + "px",
            alignItems: "center",
        },
        errorIcon: {
            borderColor: theme.palette.error.main,
            color: theme.palette.error.main,
        },
        notVisitedIcon: {
            borderColor: theme.palette.grey["400"],
        },
        activeIcon: {
            fill: theme.palette.grey["600"],
        },
        stepIcon: {
            borderWidth: 2 + "px",
            borderStyle: "solid",
            borderRadius: 50 + "px",
            display: "flex",
            width: 21 + "px",
            height: 21 + "px",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: theme.typography.fontWeightBold,
        },
        currentIcon: {
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
        },
        completedIcon: {
            backgroundColor: theme.palette.primary.main,
            color: "white",
            borderColor: theme.palette.primary.main,
        },
        validatedIcon: {
            backgroundColor: "#2CD5C4",
            color: "white",
            borderColor: "#2CD5C4",
        },
        validatedLine: {
            "& .MuiStepConnector-root": {
                "& .MuiStepConnector-line": {
                    borderColor: "#2CD5C4",
                },
            },
        },
    });
};
