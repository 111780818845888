import React from "react";
import { Trans } from "react-i18next";
import { DotLegalAlert, DotLegalLink } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation.ts";
import { useUrlProvider } from "../../useUrlProvider.ts";

export interface ITrialUsageWarningProps {
    textKey: string;
    headerKey?: string;
    marginBottom?: number;
    marginTop?: number;
    values?: {};
}

function TrialUsageWarning(props: ITrialUsageWarningProps) {
    const { translateString } = useTranslation();
    const { getPurchasePlansUrl } = useUrlProvider();
    return (
        <DotLegalAlert
            marginTop={props.marginTop}
            marginBottom={props.marginBottom}
            warningHeader={props.headerKey ? translateString(props.headerKey) : translateString("warning")}
            warningText={
                <Trans
                    i18nKey={props.textKey}
                    values={props.values}
                    components={{
                        anchor: (
                            <DotLegalLink to={getPurchasePlansUrl()} openExternalLinkInSameTab underline>
                                {translateString("here")}
                            </DotLegalLink>
                        ),
                    }}
                />
            }
        />
    );
}

export default TrialUsageWarning;
