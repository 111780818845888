import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { get } from "../common/api/apiShared";
import { useTranslation } from "../localization/useTranslation";
import { ICustomListsProps } from "./CustomLists";
import { CallPage, CustomListsViewModel, CustomListType } from "./CustomLists.types";
import { useUrlProvider } from "../useUrlProvider";
import { DotLegalBreadCrumbModel } from "@dotlegal/dotlegal-ui-components";

export function useCustomLists(props: ICustomListsProps) {
    const { getProcessingActivitiesOverviewUrl, getLegalEntitiesUrl, getSystemsUrl, getGroupEntitiesUrl, getSettingsUrl } = useUrlProvider();
    const queryClient = useQueryClient();
    const { translateString, translateCustomListType } = useTranslation();
    const [selectedCustomList, setSelectedCustomList] = useState({ show: false, description: "", name: "", id: "", copy: false });
    const [showDeleteDialog, setShowDeleteDialog] = useState({ show: false, id: "", name: "" });
    const key = "lists" + props.listType;
    const { isLoading, data } = useQuery(key, () => get<Array<CustomListsViewModel>>(`/CustomLists/${props.listType}/overview`));
    const hasLists = isLoading ? true : data?.length! > 0;

    const refetchLists = () => {
        queryClient.refetchQueries(key);
    };

    const getBreadCrumbs = () => {
        let breadCrumbs = getFirstBreadCrumbs();

        breadCrumbs.push({ name: `${translateString("customLists")} - ${translateCustomListType(props.listType.toString())}` });
        return breadCrumbs;
    };

    function getFirstBreadCrumbs(): Array<DotLegalBreadCrumbModel> {
        switch (props.listType) {
            case CustomListType.System:
                return [{ name: translateString("systems"), link: getSystemsUrl() }];
            case CustomListType.Company: {
                if (props.callPage === CallPage.GroupCompanies) {
                    return [
                        { link: getSettingsUrl(), name: translateString("settings") },
                        { name: translateString("groupEntities"), link: getGroupEntitiesUrl() },
                    ];
                }
                return [{ name: translateString("companies"), link: getLegalEntitiesUrl() }];
            }
            default:
                return [{ name: translateString("processingActivities"), link: getProcessingActivitiesOverviewUrl() }];
        }
    }

    return {
        hasLists,
        data,
        isLoading,
        selectedCustomList,
        setSelectedCustomList,
        refetchLists,
        showDeleteDialog,
        setShowDeleteDialog,
        getBreadCrumbs,
    };
}
