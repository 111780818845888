import { isNullOrWhitespace } from "../../../common/stringOperations";
import { useTranslation } from "../../../localization/useTranslation";
import { AnnualWheelDurationFormModelProps } from "./AnnualWheelDurationForm";
import { AnnualWheelDurationFormModel, maxNotificationPeriodInDays } from "./AnnualWheelDurationForm.types";

export function useAnnualWheelDurationForm(props: AnnualWheelDurationFormModelProps) {
    const { translateString } = useTranslation();

    const onDateChange = (date: Date | null) => {
        let newDate: Date | null = null;
        if (date === null || isNaN(date!.getTime())) newDate = null;
        else newDate = new Date(date!.getFullYear(), date!.getMonth(), 15);

        props.onChange(
            new AnnualWheelDurationFormModel(newDate, props.saveModel!.notificationPeriodInDays, props.saveModel?.interval, props.saveModel!.type, props.isEdit)
        );
    };

    const onIntervalChange = (interval: string) => {
        props.onChange(
            new AnnualWheelDurationFormModel(
                props.saveModel?.startDate ?? null,
                props.saveModel!.notificationPeriodInDays,
                isNullOrWhitespace(interval) ? undefined : Number(interval),
                props.saveModel!.type,
                props.isEdit
            )
        );
    };

    const onOnlyAppearOnceChange = (value: boolean) => {
        props.onChange(
            new AnnualWheelDurationFormModel(
                props.saveModel?.startDate ?? null,
                props.saveModel!.notificationPeriodInDays,
                value ? null : 0,
                props.saveModel!.type,
                props.isEdit
            )
        );
    };

    const onNotificationPeriodInDaysChange = (period: string) => {
        props.onChange(
            new AnnualWheelDurationFormModel(
                props.saveModel?.startDate ?? null,
                isNullOrWhitespace(period) ? undefined : Number(period),
                props.saveModel?.interval,
                props.saveModel!.type,
                props.isEdit
            )
        );
    };

    const getMinDate = () => {
        return new Date(new Date().getFullYear(), 0, 1);
    };

    const getMaxDate = () => {
        return new Date(new Date().getFullYear() + 1, 11, 31);
    };

    const getNotificationPeriodInDaysErrorText = () => {
        if (isNullOrWhitespace(props.saveModel?.notificationPeriodInDays?.toString())) {
            return translateString("fieldMandatory");
        }

        if (props.saveModel?.notificationPeriodInDays! < 1) {
            return translateString("invalidValue");
        }

        if (props.saveModel?.notificationPeriodInDays! > maxNotificationPeriodInDays) {
            return translateString("annualWheelNotificationPeriodInvalid");
        }

        return "";
    };

    return {
        getMinDate,
        getMaxDate,
        onDateChange,
        onIntervalChange,
        onOnlyAppearOnceChange,
        onNotificationPeriodInDaysChange,
        notificationPeriodInDaysErrorText: getNotificationPeriodInDaysErrorText(),
    };
}
