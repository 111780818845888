import { createElement, useState } from "react";
import { Trans } from "react-i18next";
import { useMutation } from "react-query";
import { post } from "../../common/api/apiShared";
import { getEnumValues } from "../../common/enumOperations";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { setFirstLetterToLowerCase } from "../../common/stringOperations";
import { useTranslation } from "../../localization/useTranslation";
import { ExternalLegalEntityModel, LegalEntitySaveModel, LegalEntityType, LevelOfProtectionType } from "./AddLegalEntityDialog.types";
import { IAddLegalEntityDialog } from "./AddLegalEntityDialog";
import { useValidator } from "../../common/hooks/validation";
import useCountryHook from "../../common/hooks/useCountryList";
import { useLevelsOfProtectionForSelection } from "../useLevelsOfProtectionForSelection.ts";

export function useAddEditLegalEntityHook(props: IAddLegalEntityDialog) {
    const [viewModel, setViewModel] = useState<ExternalLegalEntityModel>(new ExternalLegalEntityModel(!props.isSimpleDialog));
    const { getSelectableCountries, thirdCountries, countriesIsLoading } = useCountryHook();
    const snackbar = useDotLegalSnackbar();
    const { translateString } = useTranslation();
    const getLevelsOfProtection = useLevelsOfProtectionForSelection();

    const validation = useValidator<ExternalLegalEntityModel>(
        (validators) => ({
            name: validators.validateNotEmpty((item) => item.name, translateString("name")),
            country: validators.validateNotEmpty((item) => item.country, translateString("country")),
            levelOfProtection:
                viewModel?.country !== "CA" && viewModel?.country !== "US"
                    ? undefined
                    : validators.validateNotEmpty((item) => item.levelOfProtection, translateString("levelOfProtection")),
        }),
        [viewModel?.country]
    );

    const saveMutation = useMutation(save);

    const onSaveClick = () => {
        if (validation.anyHasErrors) {
            validation.setShowErrors(true);
            return;
        }

        createLegalEntity();
    };

    const createLegalEntity = () => {
        saveMutation.mutateAsync(viewModel, {
            onSuccess: (response: any) => {
                var createRespone = response.value();

                if (createRespone.isCreated) {
                    snackbar.show(createElement(Trans, { i18nKey: "legalEntityAdded", values: { company: createRespone.name } }));
                    props.onEntityCreated(createRespone.id);
                    props.onDialogClose();
                } else {
                    snackbar.show(translateString("addEntityDataExist"), "warning");
                }
            },
        });
    };

    function getLegalEntityTypeOptions() {
        return getEnumValues(LegalEntityType).map((x) => {
            var name = translateString(setFirstLetterToLowerCase(LegalEntityType[x].toString()));
            return { name: name, id: x.toString() };
        });
    }

    function isThirdCountry(countryCode: string | undefined) {
        let country = thirdCountries.find((x) => x === countryCode);

        if (country) {
            return true;
        }

        return false;
    }

    return {
        viewModel,
        setViewModel,
        getSelectableCountries,
        onSaveClick,
        getLegalEntityTypeOptions,
        isThirdCountry,
        saveMutationLoading: saveMutation.isLoading,
        validation,
        showProtectionLevel: viewModel.country === "CA" || viewModel.country === "US",
        lvlOfProtectionOptions: getLevelsOfProtection(viewModel?.country),
        countriesIsLoading,
    };

    function save(model: ExternalLegalEntityModel) {
        return post<{}>("/LegalEntity/", mapToSaveModel(model));
    }

    function mapToSaveModel(model: ExternalLegalEntityModel): LegalEntitySaveModel {
        return {
            isApproved: model.isApproved,
            isTIAUnnecessary: model.isTIAUnnecessary,
            isTransferBasisUnnecessary: model.isTransferBasisUnnecessary,
            levelOfProtection: model.levelOfProtection ?? LevelOfProtectionType.None,
            name: model.name,
            types: model.types,
            website: model.website,
            address: model.address,
            city: model.city,
            companyRegistrationNumber: model.companyRegistrationNumber,
            country: model.country,
            zipCode: model.zipCode,
        };
    }
}
