import { AnnualWheelActivityType } from "../AnnualWheelDialog.types";

export const maxNotificationPeriodInDays = 90;

export class AnnualWheelDurationFormModel {
    startDate: Date | null;
    notificationPeriodInDays: number | undefined = undefined;
    interval: number | undefined | null = undefined;
    type: AnnualWheelActivityType | undefined;
    isEdit: boolean;

    constructor(
        startDate: Date | null,
        notificationPeriodInDays: number | undefined,
        interval: number | undefined | null,
        type: AnnualWheelActivityType | undefined,
        isEdit: boolean
    ) {
        this.startDate = startDate;
        this.notificationPeriodInDays = notificationPeriodInDays;
        this.interval = interval;
        this.type = type;
        this.isEdit = isEdit;
    }

    isModelInvalid = () => {
        return this.isIntervalInvalid() || this.isStartDateEmpty() || this.isStartDateInvalid() || this.isNotificationPeriodInvalid();
    };

    isStartDateInvalid = () => {
        return !this.isEdit && this.startDate && new Date(this.startDate).getFullYear() < new Date().getFullYear();
    };

    isStartDateEmpty = () => {
        return this.startDate == null || this.startDate === undefined;
    };

    isNotificationPeriodInvalid = () => {
        return (
            this.notificationPeriodInDays === undefined ||
            this.notificationPeriodInDays === null ||
            this.notificationPeriodInDays < 1 ||
            this.notificationPeriodInDays > maxNotificationPeriodInDays
        );
    };

    isIntervalInvalid = () => {
        /* if interval is null when the activity should only appear once */
        if (this.interval === null) {
            return false;
        }

        return this.interval === undefined || this.interval < 1;
    };
}
