import { Box, CircularProgress, RadioGroup, styled, Typography } from "@mui/material";
import React from "react";
import { DotLegalDialog, DotLegalInformationTooltip, DotLegalRadioButton, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { useRolesMasterDataHooks } from "./AddEditRolesMasterData.hooks";
import { RolesMasterDataSaveModel } from "./AddEditRolesMasterData.types";

export interface IAddEditRolesMasterData {
    onDialogClose: () => void;
    onSave?: () => void;
    selectedRoleViewModel: RolesMasterDataSaveModel | undefined;
    id: string | undefined;
}

const Spinnerbox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    minHeight: 50,
}));

const RootBox = styled(Box)(({ theme }) => ({
    display: "flex",
    mb: 2,
    mt: 0,
}));

const ToolTipBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(1),
}));

export function AddEditRolesMasterData(props: IAddEditRolesMasterData) {
    const { translateString } = useTranslation();
    const { isLoading, inProgress, saveModel, setSaveModel, errors, validateRolesMasterDataName, validateRolesMasterDataSaveModel, isEdit } =
        useRolesMasterDataHooks(props);

    return (
        <DotLegalDialog
            size="sm"
            open
            header={isEdit() ? translateString("editRole") : translateString("addRole")}
            onDialogClose={props.onDialogClose}
            onOkClick={() => validateRolesMasterDataSaveModel(saveModel?.name!)}
            buttonOkText={isEdit() ? translateString("save") : translateString("create")}
            inProgress={inProgress}
            disableBackdropOnCloseIfValueChange={saveModel}
        >
            {isLoading ? (
                <Spinnerbox>
                    <CircularProgress />
                </Spinnerbox>
            ) : (
                <React.Fragment>
                    <DotLegalTextField
                        label={translateString("name")}
                        value={saveModel?.name ?? ""}
                        debounce={false}
                        onChange={(name: string) => {
                            let tempViewModel = { ...saveModel! };
                            tempViewModel.name = name;
                            validateRolesMasterDataName(name);
                            setSaveModel(tempViewModel);
                        }}
                        errorText={errors.userRolesNameError}
                    />

                    <DotLegalTextField
                        label={translateString("description")}
                        value={saveModel?.description ?? ""}
                        debounce={false}
                        multiline
                        rows={4}
                        onChange={(description: string) => {
                            let tempViewModel = { ...saveModel! };
                            tempViewModel.description = description;
                            setSaveModel(tempViewModel);
                        }}
                    />
                    <RootBox>
                        <Typography>{translateString("isUserResponsibleToAccessContent")}</Typography>
                        <ToolTipBox>{<DotLegalInformationTooltip text={translateString("canOnlyAccessDataWhereResponsibleToolTip")} />}</ToolTipBox>
                    </RootBox>
                    <RadioGroup
                        row
                        value={saveModel?.canOnlyAccessDataWhereResponsible ? 1 : 0}
                        onChange={(e) => {
                            const tempData = { ...saveModel! };
                            tempData.canOnlyAccessDataWhereResponsible = Number((e.target as HTMLInputElement).value) === 1;
                            setSaveModel(tempData);
                        }}
                    >
                        <DotLegalRadioButton value={1} labelPlacement="end" label={translateString("yes")} />
                        <DotLegalRadioButton value={0} labelPlacement="end" label={translateString("no")} />
                    </RadioGroup>
                    <RootBox>
                        <Typography>{translateString("canBeChosenAsResponsible")}</Typography>
                    </RootBox>
                    <RadioGroup
                        row
                        value={saveModel?.canBeChosenAsResponsible ? 1 : 0}
                        onChange={(e) => {
                            const tempData = { ...saveModel! };
                            tempData.canBeChosenAsResponsible = Number((e.target as HTMLInputElement).value) === 1;
                            setSaveModel(tempData);
                        }}
                    >
                        <DotLegalRadioButton value={1} labelPlacement="end" label={translateString("yes")} />
                        <DotLegalRadioButton value={0} labelPlacement="end" label={translateString("no")} />
                    </RadioGroup>
                </React.Fragment>
            )}
        </DotLegalDialog>
    );
}
