import React from "react";
import { Trans } from "react-i18next";
import { useTranslation } from "../../localization/useTranslation";
import { ChangeActiveStatusHooks } from "./ChangeActiveStatusDialog.hooks";
import { DotLegalInfoDialog } from "@dotlegal/dotlegal-ui-components";

export interface IChangeActiveStatusDialog {
    activeStatus: boolean;
    userId: string;
    onSaveCorrect: () => void;
    userName?: string;
    onCloseDialog: () => void;
}

export function ChangeActiveStatusDialog(props: IChangeActiveStatusDialog) {
    const { changeActiveStatus, isSaving } = ChangeActiveStatusHooks(props);
    const { translateString } = useTranslation();

    return (
        <DotLegalInfoDialog
            btnText={props.activeStatus ? translateString("deactivate") : translateString("activate")}
            header={props.activeStatus ? translateString("deactivateUserHeader") : translateString("activateUserHeader")}
            inProgress={isSaving}
            content={
                props.activeStatus ? (
                    <Trans i18nKey="deactivateDataInfo" values={{ name: props.userName }}></Trans>
                ) : (
                    <Trans i18nKey="activateDataInfo" values={{ name: props.userName }}></Trans>
                )
            }
            onBtnClick={() => changeActiveStatus()}
            onCloseDialog={props.onCloseDialog}
        />
    );
}
