import { useMutation, useQueryClient } from "react-query";
import { deleteHttp, post } from "../../../common/api/apiShared";
import { isNullOrWhitespace } from "../../../common/stringOperations";
import { ValidationError } from "../../../common/validationError";
import { useSelectableLegalEntityQuery } from "../../../legalEntity/useSelectableLegalEntityQuery";
import { useTranslation } from "../../../localization/useTranslation";
import { SystemModel } from "../SystemEdit.types";
import { useTransferBasisQuery } from "../../../processingActivity/hooks/useTransferBasisQuery";
import { SelectableLegalUnitsItem } from "../../../processingActivity/ProcessingActivity.types";
import { isTransferBasisMissing, isTransferImpactAssessmentMissing } from "../../../common/dataProcessorValidation";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import { SystemDataProcessorPermissions } from "../../../auth/userContextProvider/UserContextProvider.types";
import { useExpandedId } from "../../../common/hooks/useExpandedId";
import { getSystemEditQueryKey } from "../SystemEdit.hooks";
import { ISystemSupplierStep } from "./SystemSupplierStep";
import useCountryHook from "../../../common/hooks/useCountryList";

export function useSystemSupplerStepHooks(props: ISystemSupplierStep) {
    const queryClient = useQueryClient();
    const { getExpandedId, setExpandedId } = useExpandedId();

    const { permissions } = useUserContext();

    const queryKey = getSystemEditQueryKey(props.system.id);

    let legalEntitiesQuery = useSelectableLegalEntityQuery();

    let transferBasisQuery = useTransferBasisQuery(permissions.systemDataProcessorPermissions.hasTiaAndTransferBasis);
    let countriesQuery = useCountryHook();

    let legalEntityLoading = legalEntitiesQuery.isLoading;
    let transferBasisLoading = transferBasisQuery.isLoading;
    let countriesLoading = countriesQuery.countriesIsLoading;

    let legalEntityData = legalEntitiesQuery.data;
    let transferBasisData = transferBasisQuery.data;
    let countryList = countriesQuery.getSelectableCountries();

    function onExpandClick(id: string) {
        if (getExpandedId() === id) {
            setExpandedId("");
        } else {
            setExpandedId(id);
        }
    }

    const addSupplierMutation = useMutation(addSupplierAPI, {
        onSuccess: (data) => {
            const system = data.value();
            queryClient.setQueryData(queryKey, system);

            const lastItem = system.suppliers[system.suppliers.length - 1];
            setExpandedId(lastItem.id);
        },
    });

    const addSupplier = async () => {
        await addSupplierMutation.mutateAsync();
    };

    const removeSupplierMutation = useMutation(removeSupplierApi, {
        onSuccess: (data) => {
            const system = data.value();
            queryClient.setQueryData(queryKey, system);
        },
    });

    const removeSupplier = async (supplierId: string) => {
        await removeSupplierMutation.mutateAsync({ systemId: props.system.id, supplierId: supplierId });
    };

    return {
        legalEntityLoading,
        legalEntityData,
        transferBasisLoading,
        transferBasisData,
        countriesLoading,
        countryList,
        getExpandedId,
        setExpandedId,
        onExpandClick,
        addSupplier,
        removeSupplier,
        isAddingSupplier: addSupplierMutation.isLoading,
    };

    async function addSupplierAPI() {
        return await post<SystemModel>(`/system/${props.system.id}/supplier`, {});
    }

    async function removeSupplierApi(data: { systemId: string; supplierId: string }) {
        const temp = { ...props.system };
        temp.suppliers = temp.suppliers.filter((x) => x.id !== data.supplierId)!;

        queryClient.setQueryData(queryKey, temp);

        return await deleteHttp(`/system/${data.systemId}/supplier/${data.supplierId}`);
    }
}

export function useValidateSystemSupplierStep(systemDataProcessorPermissions: SystemDataProcessorPermissions) {
    const { translateString } = useTranslation();

    return (model: SystemModel, legalEntities: Array<SelectableLegalUnitsItem>): Array<ValidationError> => {
        const result: Array<ValidationError> = [];

        model.suppliers.forEach((supplier) => {
            var legalEntityId = legalEntities.find((x) => x.id === supplier.legalEntityId);

            if (supplier.isSupplierADataProcessor) {
                if (isTransferBasisMissing(legalEntityId, supplier.transferBasisId, systemDataProcessorPermissions.hasTiaAndTransferBasis)) {
                    result.push(new ValidationError(`${supplier.id}transferBasisId`, translateString("transferBasisRequired")));
                }

                if (
                    isTransferImpactAssessmentMissing(
                        legalEntityId,
                        supplier.hasTransferImpactAssessment,
                        supplier.transferImpactAssessmentId,
                        systemDataProcessorPermissions.hasTiaAndTransferBasis,
                        supplier.transferBasisId,
                        supplier.containsNotAccessibleTia
                    )
                ) {
                    result.push(new ValidationError(`${supplier.id}transferImpactAssessment`, translateString("tiaRequired")));
                }
            }

            if (systemDataProcessorPermissions.hasSubProcessorPermission) {
                supplier.secondarySuppliers
                    .filter((x) => !x.isLink)
                    .forEach((secondarySupplier) => {
                        var dataProcessor = legalEntities.find((x) => x.id === secondarySupplier.dataProcessorId);

                        if (isNullOrWhitespace(secondarySupplier.dataProcessorId)) {
                            result.push(
                                new ValidationError(`${supplier.id}${secondarySupplier.id}dataProcessorId`, translateString("secondarySupplierRequired"))
                            );
                        }

                        if (secondarySupplier.dataProcessorId === supplier.legalEntityId && supplier.legalEntityId) {
                            result.push(
                                new ValidationError(`${supplier.id}${secondarySupplier.id}dataProcessorId`, translateString("secondarySupplierUsedAsSupplier"))
                            );
                        }

                        if (
                            supplier.secondarySuppliers.filter((entry) => entry.dataProcessorId === secondarySupplier.dataProcessorId).length > 1 &&
                            secondarySupplier.dataProcessorId
                        ) {
                            result.push(
                                new ValidationError(`${supplier.id}${secondarySupplier.id}dataProcessorId`, translateString("duplicateSecondarySupplier"))
                            );
                        }

                        if (secondarySupplier.isSubDataProcessor) {
                            if (
                                isTransferBasisMissing(dataProcessor, secondarySupplier.transferBasisId, systemDataProcessorPermissions.hasTiaAndTransferBasis)
                            ) {
                                result.push(
                                    new ValidationError(`${supplier.id}${secondarySupplier.id}transferBasisId`, translateString("transferBasisRequired"))
                                );
                            }

                            if (
                                isTransferImpactAssessmentMissing(
                                    dataProcessor,
                                    secondarySupplier.hasTransferImpactAssessment,
                                    secondarySupplier.transferImpactAssessmentId,
                                    systemDataProcessorPermissions.hasTiaAndTransferBasis,
                                    secondarySupplier.transferBasisId,
                                    secondarySupplier.containsNotAccessibleTia
                                )
                            ) {
                                result.push(
                                    new ValidationError(`${supplier.id}${secondarySupplier.id}transferImpactAssessment`, translateString("tiaRequired"))
                                );
                            }
                        }
                    });
            }
        });

        return result;
    };
}
