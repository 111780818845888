import React from "react";
import { DotLegalMultiSelect, IDotLegalMultiSelect } from "@dotlegal/dotlegal-ui-components";
import { useQuery } from "react-query";
import { get } from "../../api/apiShared";
import { SelectableItem } from "../../../processingActivity/ProcessingActivity.types";

export interface IDotLegalMultiSelectApiProps<T> extends Omit<IDotLegalMultiSelect, "options" | "isLoading" | "renderOptions" | "onChange"> {
    url: string;
    onChange: (items: Array<T>) => void;
}

function DotLegalMultiSelectApi<T extends SelectableItem>(props: IDotLegalMultiSelectApiProps<T>) {
    const { isLoading, data } = useQuery(props.url, () => get<Array<T>>(props.url));
    return (
        <DotLegalMultiSelect
            {...props}
            onChange={(strings) => {
                let items = data!.filter((d) => strings.includes(d.id));
                return props.onChange(items);
            }}
            isLoading={isLoading}
            options={data}
        />
    );
}

export default DotLegalMultiSelectApi;
