import { createElement } from "react";
import { Trans } from "react-i18next";
import { useMutation } from "react-query";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { deleteHttp } from "../../../common/api/apiShared";
import { IDeleteLegalEntityContactDialog } from "./DeleteLegalEntityContactDialog,";
import { Result } from "../../../common/api/result";

export function useDeleteLegalEntityContactHooks(props: IDeleteLegalEntityContactDialog) {
    const snackbar = useDotLegalSnackbar();
    const removeMutation = useMutation(remove);

    const deleteContact = () => {
        removeMutation.mutateAsync(undefined, {
            onSuccess: (response: Result<string>) => {
                if (response.success()) {
                    props.onDeleted();
                    snackbar.show(createElement(Trans, { i18nKey: "itemDeleted", values: { name: props.contactName } }));
                } else {
                    props.onCloseDialog();
                    snackbar.show(createElement(Trans, { i18nKey: "legalEntityContactNotDeleted", values: { name: props.contactName } }), "warning");
                }
            },
        });
    };

    async function remove() {
        return await deleteHttp<string>(`/LegalEntityContacts/${props.contactId}`);
    }

    return {
        deleteContact,
        inProgess: removeMutation.isLoading,
    };
}
