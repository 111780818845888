import { DotLegalSelectOption } from "../../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { useStateUrlParamsArray } from "../../../common/hooks/useStateUrlParams";
import { setFirstLetterToLowerCase } from "../../../common/stringOperations";
import { LegalEntityAuditStatus } from "../../../legalEntity/legalEntityAuditTab/LegalEntityAudit.types";
import { RiskLevel } from "../../../legalEntity/legalEntityClassificationTab/LegalEntityClassificationTab.types";
import { useTranslation } from "../../../localization/useTranslation";
import { useDownloadLegalEntityAuditReport } from "../../DowloadLegalEntityAuditReport.hooks";
import { LegalEntityAuditGroupAuditModel } from "../../legalEntityAuditGroupOverview/LegalEntityAuditGroupOverview.types";
import { ManagementReportCompletedAuditsProps } from "./ManagementReportCompletedAudits";
import { EvaluationStatus } from "../../../common/components/questionnaireComponents/evaluateQuestionnaireDialog/EvaluateQuestionnaireDialog.types.ts";

export function useManagementReportCompletedAudits(props: ManagementReportCompletedAuditsProps) {
    const { translateString } = useTranslation();

    const [searchedRiskLevel, setSearchedRiskLevel] = useStateUrlParamsArray("riskLevel", []);
    const [searchedAuditScore, setSearchedAuditScore] = useStateUrlParamsArray("auditScore", []);
    const [searchedAuditEvaluation, setSearchedAuditEvaluation] = useStateUrlParamsArray("auditEvaluation", []);

    const { onDownloadReportAsPdf, getReportPdfUrl, isDownloadingPdf } = useDownloadLegalEntityAuditReport();

    let riskLevelFilterOptions: Array<DotLegalSelectOption> = [];
    let auditScoreFilterOptions: Array<DotLegalSelectOption> = [];
    let auditEvaluationFilterOptions: Array<DotLegalSelectOption> = [];
    if (props.audits) {
        createAuditRiskLevelOptions(props.audits.filter((x) => x.status === LegalEntityAuditStatus.Submitted).flatMap((x) => x.riskLevel));
        createAuditScoreOptions(props.audits.flatMap((x) => x.score));
        createAuditEvaluationOptions(props.audits.filter((x) => x.status === LegalEntityAuditStatus.Submitted).flatMap((x) => x.evaluationStatus));

        sortSelectOptions();
    }

    function createAuditRiskLevelOptions(riskLevels: Array<RiskLevel | undefined>) {
        riskLevels.forEach((level) => {
            if (level && !riskLevelFilterOptions.some((x) => x.id === level.toString())) {
                riskLevelFilterOptions.push({
                    id: level.toString(),
                    name: translateString(setFirstLetterToLowerCase(RiskLevel[level])),
                });
            }
        });
    }

    function createAuditScoreOptions(auditScores: Array<number>) {
        auditScores.forEach((score) => {
            if (!auditScoreFilterOptions.some((x) => x.id === score.toString())) {
                auditScoreFilterOptions.push({
                    id: score.toString(),
                    name: `${score} %`,
                });
            }
        });
    }

    function createAuditEvaluationOptions(auditEvaluations: Array<EvaluationStatus | undefined>) {
        auditEvaluations.forEach((evaluation) => {
            if (evaluation && !auditEvaluationFilterOptions.some((x) => x.id === evaluation.toString())) {
                auditEvaluationFilterOptions.push({
                    id: evaluation.toString(),
                    name: translateString(setFirstLetterToLowerCase(EvaluationStatus[evaluation])),
                });
            }
        });
    }

    return {
        rows: getFilteredRows(props.audits),
        searchedRiskLevel,
        setSearchedRiskLevel,
        onDownloadReportAsPdf,
        getReportPdfUrl,
        isDownloadingPdf,
        riskLevelFilterOptions,
        searchedAuditScore,
        setSearchedAuditScore,
        auditScoreFilterOptions,
        searchedAuditEvaluation,
        setSearchedAuditEvaluation,
        auditEvaluationFilterOptions,
    };

    function getFilteredRows(rows: Array<LegalEntityAuditGroupAuditModel>) {
        let tempData = [...rows];

        if (searchedRiskLevel.length > 0 && searchedRiskLevel[0] !== "") {
            tempData = tempData.filter((row) => row.riskLevel && searchedRiskLevel.includes(row.riskLevel.toString()));
        }

        if (searchedAuditScore.length > 0 && searchedAuditScore[0] !== "") {
            tempData = tempData.filter((row) => searchedAuditScore.includes(row.score.toString()));
        }

        if (searchedAuditEvaluation.length > 0 && searchedAuditEvaluation[0] !== "") {
            tempData = tempData.filter((row) => row.evaluationStatus && searchedAuditEvaluation.includes(row.evaluationStatus?.toString()));
        }

        return tempData;
    }

    function sortSelectOptions() {
        riskLevelFilterOptions.sort((a, b) => (a.name > b.name ? 1 : -1));
        auditScoreFilterOptions.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));
    }
}
