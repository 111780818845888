import { DeleteDeclarationCategoryDialogProps } from "./DeleteDeclarationCategoryDialog.tsx";
import { useMutation } from "react-query";
import { deleteHttp } from "../../common/api/apiShared.ts";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { createElement } from "react";
import { Trans } from "react-i18next";
import { Result } from "../../common/api/result.ts";

export default function useDeleteDeclarationCategory(props: DeleteDeclarationCategoryDialogProps) {
    const snackbar = useDotLegalSnackbar();

    const deleteMutation = useMutation(deleteUrl);
    const onDelete = async () => {
        await deleteMutation.mutateAsync(undefined, {
            onSuccess: (response: Result<string>) => {
                if (response.success()) {
                    snackbar.show(createElement(Trans, { i18nKey: "itemDeletedSuccess", values: { name: props?.name } }));
                    props.onCloseDialog();
                } else {
                    snackbar.show(createElement(Trans, { i18nKey: "declarationCategoryNotDeleted", values: { name: props?.name } }), "warning");
                }
            },
        });
    };

    function deleteUrl() {
        return deleteHttp<string>(`/declarationCategories/${props.id}/${props.declarationId}`);
    }

    return { onDelete, isDeleting: deleteMutation.isLoading };
}
