import React from "react";
import { useDeleteLegalEntityAuditGroupDialog } from "./DeleteLegalEntityAuditGroupDialog.hooks";
import { DotLegalDeleteDialog, DotLegalInfoDialog } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { Trans } from "react-i18next";
import { Box } from "@mui/material";

export interface DeleteLegalEntityAuditGroupDialogProps {
    auditGroupId: string;
    auditGroupName: string;
    deadline: Date;
    canDeleteAuditGroup: boolean;

    onCloseDialog: () => void;
    onDeletedAuditGroup: () => void;
}

function DeleteLegalEntityAuditGroupDialog(props: DeleteLegalEntityAuditGroupDialogProps) {
    const { translateString, translateDate } = useTranslation();
    const { isDeleting, confirmDelete, setConfirmDelete, onDeleteAuditGroup } = useDeleteLegalEntityAuditGroupDialog(props);

    return props.canDeleteAuditGroup ? (
        <DotLegalDeleteDialog
            open
            headerText={translateString("deleteAuditGroup")}
            alertBodyText={
                <Trans
                    i18nKey="deleteAuditGroupInfoText"
                    values={{ name: props.auditGroupName, deadline: translateDate(props.deadline) }}
                    components={{
                        wrapper: (
                            <Box sx={(theme) => ({ color: theme.palette.primary.main, fontWeight: theme.typography.fontWeightBold })} component="span"></Box>
                        ),
                    }}
                />
            }
            deleteBtnText={translateString("delete")}
            confirmLabel={translateString("deleteAuditGroup")}
            cancelBtnText={""}
            onDialogClose={props.onCloseDialog}
            confirmDeleteRequired
            onOkClick={() => onDeleteAuditGroup()}
            warningTranslation={""}
            confirmDelete={props.canDeleteAuditGroup ? confirmDelete : false}
            setConfirmDelete={(confirmed) => setConfirmDelete(confirmed)}
            inProgress={isDeleting}
        />
    ) : (
        <DotLegalInfoDialog
            header={translateString("deleteAuditGroup")}
            btnText={translateString("close")}
            content={
                <Trans
                    i18nKey="auditGroupCannotBeDeletedInfo"
                    values={{ name: props.auditGroupName, deadline: translateDate(props.deadline) }}
                    components={{
                        wrapper: (
                            <Box sx={(theme) => ({ color: theme.palette.primary.main, fontWeight: theme.typography.fontWeightBold })} component="span"></Box>
                        ),
                    }}
                />
            }
            onCloseDialog={props.onCloseDialog}
            onBtnClick={props.onCloseDialog}
        ></DotLegalInfoDialog>
    );
}

export default DeleteLegalEntityAuditGroupDialog;
