import { DotLegalButton, DotLegalOverflowMenu, DotLegalTableWithControls, ITableHeader } from "@dotlegal/dotlegal-ui-components";
import DotLegalPaper from "../../common/components/dotLegalPaper/DotLegalPaper";
import { LegalEntityContactRow, LegalEntityContactType, LegalEntityContactViewModel } from "./LegalEntityContactTab.types";
import { useTranslation } from "../../localization/useTranslation";
import { Box, TableCell, TableRow } from "@mui/material";
import { useLegalEntityContactTabDataMapping } from "./LegalEntityContactTab.hooks";
import CheckIcon from "@mui/icons-material/Check";
import LegalEntityContactDialog from "./legalEntityContactDialog/LegalEntityContactDialog";
import React from "react";
import DeleteLegalEntityContactDialog from "./deleteLegalEntityContactDialog/DeleteLegalEntityContactDialog,";
import { DotLegalEmptyState } from "@dotlegal/dotlegal-ui-components";
import NoContacts from "../../processingActivity/processingActivityOverview/processingActivitySharingsTab/noSharings.svg?react";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { setFirstLetterToLowerCase } from "../../common/stringOperations";
import { WhiteLockIcon } from "../../common/icons";

export interface ILegalEntityContactTab {
    legalEntityId: string | undefined;
    isGroupEntity: boolean;
}

function LegalEntityContactTab(props: ILegalEntityContactTab) {
    const { translateString } = useTranslation();
    const { permissions } = useUserContext();
    const {
        data,
        isLoading,
        getMenuItems,
        setShowDialog,
        showDialog,
        selectedContact,
        setSelectedContact,
        refetch,
        setShowDeleteDialog,
        showDeleteDialog,
        hasContacts,
    } = useLegalEntityContactTabDataMapping(props);

    const hasContactManagementPermissions = () => {
        if (props.isGroupEntity) {
            return permissions.canManageGroupEntities;
        }

        return permissions.companyPermissions.edit;
    };

    return (
        <React.Fragment>
            <DotLegalPaper>
                {hasContacts ? (
                    <DotLegalTableWithControls
                        data={data?.rows}
                        headers={GetTableHeader(translateString)}
                        defaultOrderBy={"name"}
                        defaultOrder={"asc"}
                        isLoading={isLoading}
                        hidePagination
                        hideRowsPerPage
                        emptyText={translateString("noData")}
                        clickableRows={false}
                        renderRow={(row, i) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.email}</TableCell>
                                <TableCell>
                                    {row.countryCode} {row.phoneNumber}
                                </TableCell>
                                <TableCell>
                                    {row.contactTypes &&
                                        row.contactTypes
                                            .map((x) => translateString(setFirstLetterToLowerCase(LegalEntityContactType[x].toString())))
                                            .join(", ")}
                                </TableCell>
                                <TableCell>{row.primaryContact && <CheckIcon />}</TableCell>
                                <TableCell align={"right"}>{hasContactManagementPermissions() && <DotLegalOverflowMenu items={getMenuItems(row)} />}</TableCell>
                            </TableRow>
                        )}
                        paginationLabelOf={""}
                        getUserSpecificPageLength={() => 10}
                        labelSearch={translateString("search")}
                        noOptionsLabel={translateString("noOptions")}
                        labelRowsPerPage={translateString("showEntites")}
                    ></DotLegalTableWithControls>
                ) : (
                    <DotLegalEmptyState icon={<NoContacts />} text={translateString("noContacts")} />
                )}
                {hasContactManagementPermissions() && (
                    <Box sx={{ display: "flex", justifyContent: "end", mt: 2 }}>
                        <DotLegalButton
                            buttonType={"primary"}
                            onClick={() => {
                                setSelectedContact(undefined);
                                setShowDialog(true);
                            }}
                            disabled={data?.isMaxNumberOfContactsReached || isLoading}
                            endIcon={data?.isMaxNumberOfContactsReached ? WhiteLockIcon : undefined}
                            toolTip={data?.isMaxNumberOfContactsReached ? translateString("upgradeInfoSecOrVendorManagementPlan") : undefined}
                        >
                            {translateString("createNew")}
                        </DotLegalButton>
                    </Box>
                )}
            </DotLegalPaper>
            {showDialog && (
                <LegalEntityContactDialog
                    isEdit={selectedContact !== undefined}
                    selectedContact={selectedContact}
                    onCloseDialog={() => setShowDialog(false)}
                    onSave={() => {
                        refetch();
                        setShowDialog(false);
                    }}
                />
            )}

            {showDeleteDialog && (
                <DeleteLegalEntityContactDialog
                    onCloseDialog={() => setShowDeleteDialog(false)}
                    onDeleted={() => {
                        refetch();
                        setShowDeleteDialog(false);
                    }}
                    contactId={selectedContact!.id}
                    contactName={selectedContact!.name}
                />
            )}
        </React.Fragment>
    );
}

export default LegalEntityContactTab;

function GetTableHeader(translateString: any) {
    const headers = Array<ITableHeader<LegalEntityContactRow>>();
    headers.push({ property: "name", text: translateString("name"), align: "left", showOnMobile: true });
    headers.push({ property: "email", text: translateString("email"), align: "left", showOnMobile: true });
    headers.push({ property: "phoneNumber", text: translateString("phone"), align: "left", showOnMobile: true });
    headers.push({ property: "contactTypes", text: translateString("type"), align: "left", showOnMobile: true });
    headers.push({ property: "primaryContact", text: translateString("primaryContact"), align: "left", showOnMobile: true });
    headers.push({ property: "id", text: "", align: "left", showOnMobile: true });
    return headers;
}
