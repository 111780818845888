import React from "react";
import { Trans } from "react-i18next";
import { useTranslation } from "../../../localization/useTranslation";
import { PurposeSaveModel } from "../purposeDialog/PurposeDialog.types";
import { useChangePurposeStatusDialogDataMapping } from "./ChangePurposeStatus.hooks";
import { DotLegalInfoDialog } from "@dotlegal/dotlegal-ui-components";

export interface IChangePurposeStatusDialogDataMapping {
    selectedPurpose: PurposeSaveModel;
    onCloseDialog: () => void;
    refetchPurposes: () => void;
}

export function ChangePurposeStatusDialog(props: IChangePurposeStatusDialogDataMapping) {
    const { changeActiveStatus, inProgress } = useChangePurposeStatusDialogDataMapping(props);
    const { translateString } = useTranslation();

    return (
        <DotLegalInfoDialog
            btnText={props.selectedPurpose.active ? translateString("deactivate") : translateString("activate")}
            header={props.selectedPurpose.active ? translateString("deactivatePurposeHeader") : translateString("activatePurposeHeader", { name: "" })}
            inProgress={inProgress}
            content={
                props.selectedPurpose.active ? (
                    <Trans i18nKey="deactivateDataInfo" values={{ name: props.selectedPurpose.name }}></Trans>
                ) : (
                    <Trans i18nKey="deactivateDataInfo" values={{ name: props.selectedPurpose.name }}></Trans>
                )
            }
            onBtnClick={() => changeActiveStatus(props.selectedPurpose)}
            onCloseDialog={props.onCloseDialog}
        />
    );
}
