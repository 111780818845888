import { ApprovalOption } from "./AnnualWheel.types";

export function isApprovalRequired(option: ApprovalOption | undefined) {
    return option === ApprovalOption.ApprovalRequiredByResponsible && option !== undefined;
}

export function isResponsibleRequired(option: ApprovalOption | undefined) {
    return (
        (option === ApprovalOption.ApprovalNotRequiredResponsibleGetNotified || option === ApprovalOption.ApprovalRequiredByResponsible) && option !== undefined
    );
}
