import { createElement, useState } from "react";
import { getSettings } from "../../../common/settingsProvider";
import { downloadFile } from "../../../common/api/downloadFile";
import { createPdfUrl } from "../../../common/pdfUrlHelper";
import { useUrlProvider } from "../../../useUrlProvider";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { Trans } from "react-i18next";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import { IComplianceReportDialogProps } from "./ComplianceReportDialog";
import { ComplianceReportDownloadModel } from "./ComplianceReportDialog.types";
import { useMutation } from "react-query";
import { post } from "../../../common/api/apiShared";
import { useTranslation } from "../../../localization/useTranslation";

export default function useComplianceReportDialogHooks(props: IComplianceReportDialogProps) {
    const { translateString } = useTranslation();
    const { getComplianceReportUrl } = useUrlProvider();
    const snackBar = useDotLegalSnackbar();
    const { language } = useUserContext();

    const [saveModel, setSaveModel] = useState<ComplianceReportDownloadModel>({
        onlyUserSpecific: props.onlyUserSpecific,
        selectedGroupEntity: props.selectedGroupCompany,
        note: "",
    });

    const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);

    const saveMutation = useMutation(save, {
        onSuccess: async (response: any) => {
            let id = response.value().id;

            await downloadPdfClick(id);
        },
    });

    function save() {
        return post<string>("/complianceReportDownloads/", saveModel);
    }

    async function downloadPdfClick(id: string) {
        const fileName = translateString("complianceReportFileName");
        let queryUrl = getSettings().mainSiteBaseUrl + getComplianceReportUrl(id);

        await downloadFile(getSettings().apiBaseUrl + "/pdf/generate?noMargin=true&url=" + createPdfUrl(queryUrl, language), `${fileName}.pdf`).then((x) => {
            snackBar.show(createElement(Trans, { i18nKey: "pdfDownloadComplete", values: { fileName: fileName } }));
            setIsDownloadingPdf(false);
            props.onDialogClose();
        });
    }

    const onNoteChange = (note: string) => {
        let temp = { ...saveModel };
        temp.note = note;
        setSaveModel(temp);
    };

    const onDownloadPdfClick = async () => {
        setIsDownloadingPdf(true);

        await saveMutation.mutateAsync();
    };

    return { saveModel, onDownloadPdfClick, isDownloadingPdf, onNoteChange };
}
