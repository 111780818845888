import { DotLegalDialog, DotLegalMultiSelect } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../../localization/useTranslation";
import React from "react";
import { Box } from "@mui/material";
import { DotLegalSelectOption } from "../../../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { useDataStorageLocationDialogHooks } from "./DataStorageLocationDialog.hooks";

export interface IDataStorageLocationDialog {
    countriesLoading: boolean;
    countryList: Array<DotLegalSelectOption>;
    storageLocations: Array<string>;
    supportLocations: Array<string>;
    readOnly: boolean;
    onCloseDialog: () => void;
    onSave: (storageLocation: Array<string>, supportLocation: Array<string>) => void;
}

function DataStorageLocationDialog(props: IDataStorageLocationDialog) {
    const { translateString } = useTranslation();
    const { saveModel, onStorageLocationChange, onSupportLocationChange } = useDataStorageLocationDialogHooks(props);

    return (
        <DotLegalDialog
            header={translateString("specifyStorageLocation")}
            buttonOkText={translateString("save")}
            size="sm"
            open
            onDialogClose={props.onCloseDialog}
            onOkClick={() => props.onSave(saveModel.storageLocations, saveModel.supportLocations)}
            okButtonDisabled={props.readOnly}
            disableBackdropOnCloseIfValueChange={saveModel}
        >
            <React.Fragment>
                <Box sx={{ width: "90%" }}>
                    <DotLegalMultiSelect
                        isLoading={props.countriesLoading}
                        chosenOptions={saveModel.storageLocations}
                        options={props.countryList}
                        disabled={props.readOnly}
                        placeholder={translateString("storageLocation")}
                        label={translateString("storageLocation")}
                        toolTipText={translateString("systemStorageTooltip")}
                        onChange={(countries) => onStorageLocationChange(countries)}
                        noOptionsLabel={translateString("noOptions")}
                    />

                    <DotLegalMultiSelect
                        isLoading={props.countriesLoading}
                        options={props.countryList}
                        disabled={props.readOnly}
                        chosenOptions={saveModel.supportLocations}
                        placeholder={translateString("supportAccess")}
                        label={translateString("supportAccess")}
                        toolTipText={translateString("systemSupportTooltip")}
                        onChange={(countries) => onSupportLocationChange(countries)}
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Box>
            </React.Fragment>
        </DotLegalDialog>
    );
}
export default DataStorageLocationDialog;
