import { useMutation, useQueryClient } from "react-query";
import { deleteHttp, post, put } from "../../common/api/apiShared";
import { DataProcessor, Disclosure, JointDataController, ProcessingActivityModel, SelectableLegalUnitsItem, Source } from "../ProcessingActivity.types";
import { ValidationError } from "../../common/validationError";
import { useValidateDataProcessorsStep } from "./dataProcessorsStep/DataProcessorsStep.hooks";
import { useValidateSourceStep } from "./sourceStep/SourceStep.hooks";
import { useOptimisticUpdate } from "../../common/hooks/useOptimisticUpdate";
import { ajaxQueue } from "../../common/api/ajaxQueue";
import { useExpandedId } from "../../common/hooks/useExpandedId";
import { TrackingEvent, useTrackAIEvent } from "../hooks/useTracking";
import { UserSelectableItem } from "../../user/User.types";
import { UserPermissions } from "../../auth/userContextProvider/UserContextProvider.types";
import { useValidateJointDataControllerStep } from "./jointDataControllerStep/JointDataControllerStep.hooks";
import useCountryHook from "../../common/hooks/useCountryList";
import { useValidateDisclosuresStep } from "./transfersStep/DisclosureStep.hooks.ts";

export function useSharingOfDataDataMapping(processingActivity: ProcessingActivityModel) {
    const queryClient = useQueryClient();
    const { setExpandedId } = useExpandedId();
    const trackEvent = useTrackAIEvent();
    const optimisticUpdate = useOptimisticUpdate();
    const { thirdCountries } = useCountryHook();

    let queryKey = "processingActivity" + processingActivity.id;
    const addDataProcessorMutation = useMutation(addDataProcessorApi, {
        onSuccess: (data) => {
            const processingActivity = data.value();
            queryClient.setQueryData(queryKey, processingActivity);
            const userCreatedSharings = processingActivity.dataProcessors.filter((x) => !x.isSystemGenerated);
            const newDataprocessorSharings = userCreatedSharings[userCreatedSharings.length - 1];
            setExpandedId(newDataprocessorSharings.id);
            trackEvent(TrackingEvent.DataProcessorAddedToProcessingActivity, {
                processingActivityId: processingActivity.id,
                dataProcessorId: newDataprocessorSharings.id,
            });
        },
    });

    const addTransferMutation = useMutation(addTransferAPI, {
        onSuccess: (data) => {
            const processingActivity = data.value();
            queryClient.setQueryData(queryKey, processingActivity);
            setExpandedId(processingActivity.disclosures[processingActivity.disclosures.length - 1].id!);
            trackEvent(TrackingEvent.DisclosureAddedToProcessingActivity, {
                processingActivityId: processingActivity.id,
                transferId: processingActivity.disclosures[processingActivity.disclosures.length - 1].id!,
            });
        },
    });

    const updateTransferMutation = useMutation(updateTransferAPI, {
        onSuccess: (data) => {
            const processingActivity = data.value();
            queryClient.setQueryData(queryKey, processingActivity);
        },
    });

    const updateDataProcessorMutation = useMutation(updateDataProcessorApi, {
        onSuccess: (data) => {
            const processingActivity = data.value();
            queryClient.setQueryData(queryKey, processingActivity);
        },
    });

    const removeTransferMutation = useMutation(removeTransferAPI, {
        onSuccess: (data) => {
            const processingActivity = data.value();
            queryClient.setQueryData(queryKey, processingActivity);
        },
    });

    const addJointDataControllerMutation = useMutation(addJointDataControllerAPI, {
        onSuccess: (data) => {
            const processingActivity = data.value();
            queryClient.setQueryData(queryKey, processingActivity);

            setExpandedId(processingActivity.jointDataControllers[processingActivity.jointDataControllers.length - 1].id!);

            trackEvent(TrackingEvent.JointControllerAddedToProcessingActivity, {
                processingActivityId: processingActivity.id,
                jointDataControlleId: processingActivity.jointDataControllers[processingActivity.jointDataControllers.length - 1].id!,
            });
        },
    });

    const updateJointDataControllerMutation = useMutation(updateJointDataControllerAPI, {
        onSuccess: (data) => {
            const processingActivity = data.value();
            queryClient.setQueryData(queryKey, processingActivity);
        },
    });

    const removeJointDataControllerMutation = useMutation(removeJointDataControllerAPI, {
        onSuccess: (data) => {
            const processingActivity = data.value();
            queryClient.setQueryData(queryKey, processingActivity);
        },
    });

    const addSourceMutation = useMutation(addSourceAPI, {
        onSuccess: (data) => {
            const processingActivity = data.value();
            queryClient.setQueryData(queryKey, processingActivity);
            setExpandedId(processingActivity.sources[processingActivity.sources.length - 1].id!);
            trackEvent(TrackingEvent.SourceAddedToProcessingActivity, {
                processingActivityId: processingActivity.id,
                sourceId: processingActivity.sources[processingActivity.sources.length - 1].id!,
            });
        },
    });

    const updateSourceMutation = useMutation(updateSourceAPI, {
        onSuccess: (data) => {
            const processingActivity = data.value();
            queryClient.setQueryData(queryKey, processingActivity);
        },
    });

    const removeSourceMutation = useMutation(removeSourceAPI, {
        onSuccess: (data) => {
            const processingActivity = data.value();
            queryClient.setQueryData(queryKey, processingActivity);
        },
    });

    const removeDataProcessorMutation = useMutation(removeDataProcessorApi, {
        onSuccess: (data) => {
            const processingActivity = data.value();
            queryClient.setQueryData(queryKey, processingActivity);
        },
    });

    const removeLegalEntityFromDataProcessorMutation = useMutation(removeLegalEntityFromDataProcessorApi);

    const removeLegalEntityFromTransferMutation = useMutation(removeLegalEntityFromTransferAPI);
    const removeDataProcessorFromSourceMutation = useMutation(removeDataProcessorFromSourceAPI);
    const removeLegalEntityFromJointDataControllerMutation = useMutation(removeLegalEntityFromJointDataControllerAPI);

    const addDataProcessor = async (createdAsGroupEntityIsDataProcessor: boolean) => {
        await addDataProcessorMutation.mutateAsync({
            processingActivityId: processingActivity.id,
            createdAsGroupEntityIsDataProcessor: createdAsGroupEntityIsDataProcessor,
        });
    };

    const removeDataProcessor = async (dataProcessorId: string) => {
        await ajaxQueue().addRequest(removeDataProcessorMutation.mutateAsync, {
            processingActivityId: processingActivity.id,
            dataProcessorId: dataProcessorId,
        });
    };

    const updateDataProcessor = async (dataProcessor: DataProcessor) => {
        await ajaxQueue().addRequest(updateDataProcessorMutation.mutateAsync, {
            processingActivityId: processingActivity.id,
            dataProcessor: dataProcessor,
        });
    };

    const legalEntitiesChangeOnDataProcessor = async (entities: Array<string>, dataProcessorId: string) => {
        const newProcessingActivityModel = { ...processingActivity };
        const dataProcessor = newProcessingActivityModel.dataProcessors.find((d) => d.id === dataProcessorId)!;
        const removed = dataProcessor.legalEntities.filter((l) => !entities.includes(l));
        dataProcessor.legalEntities = [...entities];
        if (removed.length > 0) {
            for (const removedEntity of removed) {
                await optimisticUpdate.updateWithProcessingActivityResponse(
                    newProcessingActivityModel,
                    removeLegalEntityFromDataProcessorMutation.mutateAsync,
                    {
                        processingActivityId: processingActivity.id,
                        dataProcessorId: dataProcessorId,
                        legalEntityId: removedEntity,
                    }
                );
            }
        } else {
            await optimisticUpdate.updateWithProcessingActivityResponse(newProcessingActivityModel, updateDataProcessorMutation.mutateAsync, {
                processingActivityId: processingActivity.id,
                dataProcessor: dataProcessor,
            });
        }
    };

    const addJointDataController = async () => {
        await addJointDataControllerMutation.mutateAsync(processingActivity.id);
    };

    const updateJointDataController = async (jointDataController: JointDataController) => {
        await ajaxQueue().addRequest(updateJointDataControllerMutation.mutateAsync, {
            processingActivityId: processingActivity.id,
            jointDataController: jointDataController,
        });
    };

    const removeJointDataController = async (joinDataControllerid: string) => {
        await ajaxQueue().addRequest(removeJointDataControllerMutation.mutateAsync, {
            processingActivityId: processingActivity.id,
            jointDataController: joinDataControllerid,
        });
    };

    const addTransfer = async () => {
        await addTransferMutation.mutateAsync(processingActivity.id);
    };

    const updateTransfer = async (transfer: Disclosure) => {
        await ajaxQueue().addRequest(updateTransferMutation.mutateAsync, {
            processingActivityId: processingActivity.id,
            transfer: transfer,
        });
    };

    const removeTransfer = async (transferId: string) => {
        await ajaxQueue().addRequest(removeTransferMutation.mutateAsync, {
            processingActivityId: processingActivity.id,
            transferId: transferId,
        });
    };

    const legalEntitiesChangedOnTransfer = async (entities: Array<string>, transferId: string) => {
        const newProcessingActivityModel = { ...processingActivity };
        const transfer = newProcessingActivityModel.disclosures.find((d) => d.id === transferId)!;
        const removed = transfer.legalEntities.filter((l) => !entities.includes(l));
        transfer.legalEntities = [...entities];
        if (removed.length > 0) {
            for (const removedEntity of removed) {
                await optimisticUpdate.updateWithProcessingActivityResponse(newProcessingActivityModel, removeLegalEntityFromTransferMutation.mutateAsync, {
                    processingActivityId: processingActivity.id,
                    transferId: transferId,
                    legalEntityId: removedEntity,
                });
            }
        } else {
            await optimisticUpdate.updateWithProcessingActivityResponse(newProcessingActivityModel, updateTransferMutation.mutateAsync, {
                processingActivityId: processingActivity.id,
                transfer: transfer,
            });
        }
    };

    const addSource = async () => {
        await addSourceMutation.mutateAsync(processingActivity.id);
    };

    const updateSource = async (source: Source) => {
        await ajaxQueue().addRequest(updateSourceMutation.mutateAsync, {
            processingActivityId: processingActivity.id,
            source: source,
        });
    };

    const removeSource = async (sourceId: string) => {
        await ajaxQueue().addRequest(removeSourceMutation.mutateAsync, {
            processingActivityId: processingActivity.id,
            sourceId: sourceId,
        });
    };

    const legalEntitiesChangedOnSource = async (entities: Array<string>, sourceId: string) => {
        const newProcessingActivityModel = { ...processingActivity };
        const source = newProcessingActivityModel.sources.find((d) => d.id === sourceId)!;
        const removed = source.groupEntities.filter((l) => !entities.includes(l));
        source.groupEntities = [...entities];
        if (removed.length > 0) {
            for (const removedEntity of removed) {
                await optimisticUpdate.updateWithProcessingActivityResponse(newProcessingActivityModel, removeDataProcessorFromSourceMutation.mutateAsync, {
                    processingActivityId: processingActivity.id,
                    sourceId: sourceId,
                    dataProcessorId: removedEntity,
                });
            }
        } else {
            await optimisticUpdate.updateWithProcessingActivityResponse(newProcessingActivityModel, updateSourceMutation.mutateAsync, {
                processingActivityId: processingActivity.id,
                source: source,
            });
        }
    };

    const legalEntitiesChangedOnJointDataController = async (entities: Array<string>, jointDataControllerId: string) => {
        const newProcessingActivityModel = { ...processingActivity };
        const jointDataController = newProcessingActivityModel.jointDataControllers.find((d) => d.id === jointDataControllerId)!;

        const removed = jointDataController.legalEntities.filter((l) => !entities.includes(l));
        jointDataController.legalEntities = [...entities];
        if (removed.length > 0) {
            for (const removedEntity of removed) {
                await optimisticUpdate.updateWithProcessingActivityResponse(
                    newProcessingActivityModel,
                    removeLegalEntityFromJointDataControllerMutation.mutateAsync,
                    {
                        processingActivityId: processingActivity.id,
                        jointDataControllerId: jointDataControllerId,
                        legalEntityId: removedEntity,
                    }
                );
            }
        } else {
            await optimisticUpdate.updateWithProcessingActivityResponse(newProcessingActivityModel, updateJointDataControllerMutation.mutateAsync, {
                processingActivityId: processingActivity.id,
                jointDataController: jointDataController,
            });
        }
    };

    return {
        isAddingDataProcessor: addDataProcessorMutation.isLoading,
        addDataProcessor: addDataProcessor,
        removeDataProcessor: removeDataProcessor,
        updateDataProcessor: updateDataProcessor,
        legalEntitiesChangeOnDataProcessor: legalEntitiesChangeOnDataProcessor,
        isAddingTransfer: addTransferMutation.isLoading,
        addTransfer: addTransfer,
        updateTransfer: updateTransfer,
        removeTransfer: removeTransfer,
        legalEntitiesChangedOnTransfer: legalEntitiesChangedOnTransfer,
        isAddingSource: addSourceMutation.isLoading,
        addSource: addSource,
        updateSource: updateSource,
        removeSource: removeSource,
        legalEntitiesChangedOnSource: legalEntitiesChangedOnSource,
        addJointDataController,
        removeJointDataController,
        updateJointDataController,
        legalEntitiesChangedOnJointDataController,
        thirdCountries,
    };
}

async function addDataProcessorApi(data: { processingActivityId: string; createdAsGroupEntityIsDataProcessor: boolean }) {
    return await post<ProcessingActivityModel>(
        "/processingactivity/" + data.processingActivityId + "/dataprocessor/" + data.createdAsGroupEntityIsDataProcessor,
        {}
    );
}

async function removeDataProcessorApi(data: { processingActivityId: string; dataProcessorId: string }) {
    return await deleteHttp("/processingactivity/" + data.processingActivityId + "/dataprocessor/" + data.dataProcessorId);
}

async function updateDataProcessorApi(data: { processingActivityId: string; dataProcessor: DataProcessor }) {
    return await put("/processingactivity/" + data.processingActivityId + "/dataprocessor/" + data.dataProcessor.id, data.dataProcessor);
}

async function removeLegalEntityFromDataProcessorApi(data: { processingActivityId: string; dataProcessorId: string; legalEntityId: string }) {
    return await deleteHttp<ProcessingActivityModel>(
        "/processingactivity/" + data.processingActivityId + "/dataprocessor/" + data.dataProcessorId + "/legalEntity/" + data.legalEntityId
    );
}

async function addTransferAPI(processingActivityId: string) {
    return await post<ProcessingActivityModel>("/processingactivity/" + processingActivityId + "/disclosure", {});
}

async function updateTransferAPI(data: { processingActivityId: string; transfer: Disclosure }) {
    return await put("/processingactivity/" + data.processingActivityId + "/disclosure/" + data.transfer.id, data.transfer);
}

async function removeTransferAPI(data: { processingActivityId: string; transferId: string }) {
    return await deleteHttp("/processingactivity/" + data.processingActivityId + "/disclosure/" + data.transferId);
}

async function removeLegalEntityFromTransferAPI(data: { processingActivityId: string; transferId: string; legalEntityId: string }) {
    return await deleteHttp<ProcessingActivityModel>(
        "/processingactivity/" + data.processingActivityId + "/disclosure/" + data.transferId + "/legalEntity/" + data.legalEntityId
    );
}

async function addSourceAPI(processingActivityId: string) {
    return await post<ProcessingActivityModel>("/processingactivity/" + processingActivityId + "/source", {});
}

async function updateSourceAPI(data: { processingActivityId: string; source: Source }) {
    return await put("/processingactivity/" + data.processingActivityId + "/source/" + data.source.id, data.source);
}

async function removeSourceAPI(data: { processingActivityId: string; sourceId: string }) {
    return await deleteHttp("/processingactivity/" + data.processingActivityId + "/source/" + data.sourceId);
}

async function removeDataProcessorFromSourceAPI(data: { processingActivityId: string; sourceId: string; dataProcessorId: string }) {
    return await deleteHttp<ProcessingActivityModel>(
        "/processingactivity/" + data.processingActivityId + "/source/" + data.sourceId + "/dataProcessor/" + data.dataProcessorId
    );
}

async function addJointDataControllerAPI(processingActivityId: string) {
    return await post<ProcessingActivityModel>("/processingactivity/" + processingActivityId + "/jointdatacontroller", {});
}

async function updateJointDataControllerAPI(data: { processingActivityId: string; jointDataController: JointDataController }) {
    return await put("/processingactivity/" + data.processingActivityId + "/jointdatacontroller/" + data.jointDataController.id, data.jointDataController);
}

async function removeLegalEntityFromJointDataControllerAPI(data: { processingActivityId: string; jointDataControllerId: string; legalEntityId: string }) {
    return await deleteHttp<ProcessingActivityModel>(
        "/processingactivity/" + data.processingActivityId + "/jointdatacontroller/" + data.jointDataControllerId + "/legalEntity/" + data.legalEntityId
    );
}

async function removeJointDataControllerAPI(data: { processingActivityId: string; jointDataController: string }) {
    return await deleteHttp("/processingactivity/" + data.processingActivityId + "/jointdatacontroller/" + data.jointDataController);
}

export function useValidateDataSharingStep(permissions: UserPermissions, canRegisterSelfAsDataProcessor: boolean) {
    const validateDataProcessorsStep = useValidateDataProcessorsStep(permissions, canRegisterSelfAsDataProcessor);
    const validateDisclosuresStep = useValidateDisclosuresStep(permissions.processingActivityDisclosuresPermissions);
    const validateSourcesStep = useValidateSourceStep(permissions.processingActivitySourcesPermissions);
    const validateJointDataController = useValidateJointDataControllerStep(permissions.processingActivityJointDataControllerPermissions);

    return (
        model: ProcessingActivityModel,
        thirdCountryLegalEntities: Array<SelectableLegalUnitsItem> | undefined,
        countries: Array<string>,
        sensitiveDataCategories: Array<string> | undefined,
        responsibles: Array<UserSelectableItem> | undefined,
        legalObligationId: string
    ): Array<ValidationError> => {
        const processingErrors = validateDataProcessorsStep(model, thirdCountryLegalEntities, countries, false, responsibles);
        const transfersErrors = validateDisclosuresStep(model, thirdCountryLegalEntities, sensitiveDataCategories, responsibles, legalObligationId);
        const sourcesErrors = validateSourcesStep(model, responsibles);
        const controllerProcessingErrors = validateDataProcessorsStep(model, thirdCountryLegalEntities, countries, true, responsibles);
        const jointDataControllerErrors = validateJointDataController(model, responsibles);

        const result: Array<ValidationError> = [];
        if (processingErrors.length > 0) {
            result.push(new ValidationError("dataSharingerror", "dataSharingerror"));
        }

        if (transfersErrors.length > 0) {
            result.push(new ValidationError("dataSharingerror", "dataSharingerror"));
        }

        if (sourcesErrors.length > 0) {
            result.push(new ValidationError("dataSharingerror", "dataSharingerror"));
        }

        if (controllerProcessingErrors.length > 0) {
            result.push(new ValidationError("dataSharingerror", "dataSharingerror"));
        }

        if (jointDataControllerErrors.length > 0) {
            result.push(new ValidationError("dataSharingerror", "dataSharingerror"));
        }

        return result;
    };
}
