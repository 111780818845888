import { useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";

export const useRiskAssessmentAnswerCollapseStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        collapse: {
            backgroundColor: theme.palette.grey[100] + " !important",
            "& .MuiIconButton-label": {
                "& .MuiSvgIcon-root": {
                    fill: theme.palette.secondary.main,
                },
            },
        },
        collapseContent: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            fontSize: theme.typography.pxToRem(12),
            marginTop: theme.spacing(2),
        },
        ratingHeader: {
            fontWeight: "bold",
            fontSize: theme.typography.pxToRem(14),
            color: theme.palette.primary.dark,
        },
        scoreOverridden: {
            display: "flex",
            alignItems: "center",
        },
        highestScore: {
            marginLeft: "10px",
        },
        dropdownHeaderTooltip: {
            display: "inline-block",
            marginLeft: theme.spacing(1),
            verticalAlign: "text-top",
        },
    });
};
