import { useEffect } from "react";
import { useQuery } from "react-query";
import { LegalEntitySelectorViewModel } from "../../../legalEntity/LegalEntity.types";
import { SelectableColoredItem, SelectableItem } from "../../../processingActivity/ProcessingActivity.types";
import { get } from "../../api/apiShared";
import { IDotLegalRecordheader } from "./DotLegalRecordHeader";

export function useDotLegalRecordHeaderDataMapping(props: IDotLegalRecordheader) {
    const groupEntityQuery = useQuery("groupEntities", () => get<Array<LegalEntitySelectorViewModel>>("/LegalEntity/groupentities/"));
    const areaQuery = useQuery("selectableBusinessAreas", () => get<Array<SelectableColoredItem>>("/BusinessAreas/businessareas"));
    const namesUrl = `/processingactivity/activeSelectableItems?onlyuserspecific=${props.onlyUserSpecific}`;
    const processingActivityNamesQuery = useQuery(namesUrl, () => get<Array<SelectableItem>>(namesUrl));

    let groupEntities = groupEntityQuery.data?.map((d) => {
        return { id: d.id, name: d.name };
    });

    useEffect(() => {
        if (groupEntities && groupEntities.length === 1 && props.isProcessingActivityLimitExceeded === false) {
            props.setSelectedGroupEntity(groupEntities[0].id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupEntityQuery.data]);

    return {
        isLoadingGroupEntities: groupEntityQuery.isLoading,
        groupEntities,
        isAreasLoading: areaQuery.isLoading,
        isProcessingActivityNamesLoading: processingActivityNamesQuery.isLoading,
        processingActivities: processingActivityNamesQuery.data,
        processingAreas: areaQuery.data,
    };
}
