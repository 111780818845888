export function apiUrls() {
    return {
        questionnaires: {
            selectableItems: "/Questionnaires",
            internalAssessment: "/Questionnaires/internalAssessment",
        },
        vendorClassifications: {
            selectableItems: "/Questionnaires/vendorclassifications",
        },
        legalEntities: {
            contacts: (legalEntityId: string) => `/LegalEntityContacts/${legalEntityId}/selectableContacts`,
            contactsOnAvaliableGroupEntities: `/LegalEntityContacts/availableContacts`,
        },
        systems: {
            selectableItems: "/System/selectableItems",
            selectableItemsByLegalEntityId: (legalEntityId: string) => `/System/selectableItems/${legalEntityId}`,
        },
        declarations: {
            selectableItems: (declarationId: string) => `/declarationCategories/selectableItems/${declarationId}`,
        },
    };
}
