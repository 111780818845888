import { useTheme } from "@mui/material";
import { createSxStyles } from "../../../common/createSxStyles";

export const useSharingsInfoBoxStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        container: {
            display: "flex",
            flexDirection: "column",
        },
        descriptionLabel: {
            alignSelf: "end",
            fontSize: theme.typography.pxToRem(12),
            color: theme.palette.primary.main,
            "&:hover": {
                cursor: "pointer",
                textDecoration: "underline",
            },
        },
    });
};
