import { useMutation, useQuery } from "react-query";
import { SystemOverviewNoteDialogProps } from "./SystemOverviewNoteDialog";
import { get, put } from "../../../common/api/apiShared";
import { useTranslation } from "../../../localization/useTranslation";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";

export interface SystemOverviewNoteViewModel {
    note: string;
}

export function useSystemOverviewNote(props: SystemOverviewNoteDialogProps) {
    const url = `/system/${props.systemId}/note`;
    const { isLoading, data } = useQuery(url, () => get<SystemOverviewNoteViewModel>(url));

    const { translateString } = useTranslation();

    const snackbar = useDotLegalSnackbar();

    const saveNoteMutation = useMutation(updateSystemsNote);
    const updateNote = (note: string) => {
        saveNoteMutation.mutateAsync(
            { id: props.systemId, note: note },
            {
                onSuccess: (resp: any) => {
                    snackbar.show(translateString("noteUpdated"), "success");
                    props.onSuccess();
                },
            }
        );
    };

    return { isLoading, data, updateNote, isSaving: saveNoteMutation.isLoading };

    function updateSystemsNote(data: { id: string; note: string }) {
        return put<{}>(`/System/${data.id}/note`, data.note);
    }
}
