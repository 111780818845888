import { ValidationError } from "../../validationError";

export interface StepHover {
    hoverContent: any;
    toolTipMaxWidth: number;
}

export class StepModel {
    private readonly validator?: (model: any) => Array<ValidationError>;

    constructor(name: string, stepType: number, validator?: (model: any) => Array<ValidationError>) {
        this.name = name;
        this.validator = validator;
        this.stepType = stepType;
    }

    name: string;
    stepType: number;

    errors(model: any, validateImmediately: boolean, visited: boolean): Array<ValidationError> {
        let errors: Array<ValidationError> = [];
        if ((visited || validateImmediately) && this.validator) {
            errors = this.validator(model);
        }

        return errors;
    }
}
