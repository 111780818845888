import React from "react";
import DotLegalDeleteDialogWrapper from "../../common/components/dotLegalDeleteDialog/DotLegalDeleteDialogWrapper";
import { useTranslation } from "../../localization/useTranslation";
import { useDeleteAnnualWheelActivity } from "./DeleteAnnualWheelActivityDialog.hooks";

export interface IDeleteAnnualWheelActivityDialogProps {
    activityId: string;
    activityName: string;
    onCloseDialog: () => void;
    onDeleteClick: () => void;
    hardDelete?: boolean;
}

function DeleteAnnualWheelActivityDialog(props: IDeleteAnnualWheelActivityDialogProps) {
    const { translateString } = useTranslation();
    const { removeAnnualWheelActivity, inProgress } = useDeleteAnnualWheelActivity(props);

    return (
        <DotLegalDeleteDialogWrapper
            open
            inProgress={inProgress}
            itemForDeletion={props.activityName}
            additionalWarning={props.hardDelete ? translateString("hardDeleteAnnualWheelActivityWarning") : translateString("deleteAnnualWheelActivityWarning")}
            onDialogClose={props.onCloseDialog}
            onOkClick={() => removeAnnualWheelActivity(props.hardDelete ?? false)}
        />
    );
}

export default DeleteAnnualWheelActivityDialog;
