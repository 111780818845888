import { DotLegalHeader, DotLegalSelect, DotLegalTooltip } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import React from "react";
import DotLegalLink from "../../../common/components/dotLegalLink/DotLegalLink";
import { useUrlProvider } from "../../../useUrlProvider";
import useTaskRelationsHooks from "./TaskRelations.hooks";
import { Box, IconButton, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Theme } from "@mui/material";
import { removeIcon } from "../../../common/icons";
import { setFirstLetterToLowerCase } from "../../../common/stringOperations";
import { TaskStateEnum } from "../../../annualWheel/AnnualWheel.types";
import AddIcon from "@mui/icons-material/Add";
import { useTaskRelationsStyle } from "./TaskRelations.styles";
import RelatedTasksSelector from "./relatedTasksSelector/RelatedTasksSelector";

export interface ITaskRelations {
    taskId: string;
    shouldAddRelatedTask: boolean;
    showRelatedTasks: boolean;
    allDataIsDoneLoading: boolean;
    updateRelatedTaskScenario: () => void;
    onHideRelatedTasks: () => void;
    passIsDoneLoading?: (newValue: boolean) => void;
    readOnly: boolean;
}

const TaskStateComponent = styled("div")({
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 140,
});

const getStatusColors = (theme: Theme, state: TaskStateEnum) => {
    switch (state) {
        case TaskStateEnum.Completed:
            return { backgroundColor: "#E3FCEF", color: "#2BD5C4" };
        case TaskStateEnum.Overdue:
            return { backgroundColor: "#F7E7F2", color: "#D54678" };
        case TaskStateEnum.InProgress: {
            return { backgroundColor: "#E9EDFF", color: theme.palette.primary.main };
        }
        default:
            return { backgroundColor: "#CCE4FF", color: "#1774DE" };
    }
};

function TaskRelations(props: ITaskRelations) {
    const { getTaskManagementUrl } = useUrlProvider();
    const { translateString } = useTranslation();
    const styles = useTaskRelationsStyle();
    const {
        data,
        selectableTasks,
        addTaskRelation,
        deleteTaskRelation,
        formatDate,
        showSelectActivities,
        sortedData,
        order,
        orderBy,
        onSortClick,
        hoveredTask,
        setHoveredTask,
        addClicked,
        showAndAddRelatedTask,
        setShowSelectActivities,
    } = useTaskRelationsHooks(props);

    const getSortProps = (name: string) => {
        return {
            active: name === orderBy,
            onClick: () => onSortClick(name),
            direction: name === orderBy ? order : "asc",
        };
    };
    let hasTasksOrMightBeAdding = props.showRelatedTasks || (data && data?.taskRelations?.length > 0) || showSelectActivities;

    const shouldShowAddRelationBox = () => {
        if (showSelectActivities || (props.showRelatedTasks && data && data?.taskRelations?.length === 0)) {
            return true;
        }

        return false;
    };

    return (
        <React.Fragment>
            {props.allDataIsDoneLoading && (
                <React.Fragment>
                    {data && data.annualWheelActivityRelations.length > 0 && (
                        <Box sx={{ marginBottom: hasTasksOrMightBeAdding ? 0 : 3 }}>
                            <DotLegalHeader marginTop={0} marginBottom={0.5} headerStyle="small">
                                {translateString("relatedActivities")}
                            </DotLegalHeader>
                            {data.annualWheelActivityRelations.map((x) => (
                                <DotLegalHeader marginBottom={0.5} headerStyle="small" key={x.id}>
                                    <DotLegalLink linkColor={"primary"} key={x.id} to={getTaskManagementUrl() + `?activity=${x.id}&months=`}>
                                        {x.name}
                                    </DotLegalLink>
                                </DotLegalHeader>
                            ))}
                        </Box>
                    )}
                    {(props.showRelatedTasks || (data && data.taskRelations.length > 0)) && (
                        <RelatedTasksSelector
                            firstColumnHeader={translateString("relatedTasks")}
                            readOnly={props.readOnly}
                            showRelatedTasks={props.showRelatedTasks}
                            selectableTasks={data?.selectableTasks ?? []}
                            shouldAddRelatedTask={props.shouldAddRelatedTask}
                            updateRelatedTaskScenario={props.updateRelatedTaskScenario}
                            onAddTask={(taskId) => addTaskRelation(taskId)}
                            onDeleteTaskClick={(taskId) => deleteTaskRelation(taskId)}
                            selectedTasks={data?.taskRelations}
                        />
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default TaskRelations;
