import { useQuery } from "react-query";
import { get } from "../../common/api/apiShared";
import { ComplianceReportDownloadModel } from "./complianceReportDialog/ComplianceReportDialog.types";
import { useParams } from "react-router-dom";

export default function useComplianceReportHooks() {
    const { id } = useParams<{ id: string }>();

    const url = `/complianceReportDownloads/${id}`;
    const { isLoading, data } = useQuery(url, () => get<ComplianceReportDownloadModel>(url));

    return { isLoading, data };
}
