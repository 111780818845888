import { createElement, useState } from "react";
import { useMutation } from "react-query";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { post, put } from "../../../common/api/apiShared";
import { IBusinessAreaDialog } from "./BusinessAreaDialog";
import { isNullOrWhitespace } from "../../../common/stringOperations";
import { Result } from "../../../common/api/result";
import { BusinessAreaSaveModel } from "./BusinessAreaDialog.types";
import { Trans } from "react-i18next";

export function usePurposeDialogDataMapping(props: IBusinessAreaDialog) {
    const snackbar = useDotLegalSnackbar();
    const { translateString } = useTranslation();
    const [errors, setErrors] = useState({ businessAreaNameError: "" });
    const [inProgress, setInProgress] = useState(false);
    const [viewModel, setViewModel] = useState<BusinessAreaSaveModel | undefined>(setSaveModel());

    const saveMutation = useMutation(save, {
        onSuccess: (response: Result<string>) => {
            if (response.success()) {
                props.onHideDialog();
                props.onSave(response.value());
                setInProgress(false);
                setViewModel(undefined);
                snackbar.show(createElement(Trans, { i18nKey: "dataCreated", values: { name: viewModel?.name } }));
            } else {
                setInProgress(false);
                snackbar.show(translateString("businessAreaExists"), "warning");
            }
        },
    });

    const createBusinessArea = async () => {
        await saveMutation.mutateAsync();
    };

    function save() {
        return post<string>("/businessareas/", viewModel);
    }

    const editMutation = useMutation(update);
    const updateBusinessArea = () => {
        editMutation.mutateAsync(viewModel!.id, {
            onSuccess: (response: Result<string>) => {
                if (response.success()) {
                    snackbar.show(translateString("businessAreaUpdated", { name: viewModel!.name }));
                    props.onHideDialog();
                    props.onSave("");
                } else {
                    setInProgress(false);
                    snackbar.show(translateString("businessAreaExists"), "warning");
                }
            },
        });
    };

    function update(id: string) {
        return put<string>(`/businessareas/${id}/`, viewModel);
    }

    return {
        inProgress,
        errors,
        viewModel,
        setViewModel,
        validateBusinessAreaForm,
        validateBusinessAreaName,
        isEdit,
    };

    function setSaveModel() {
        if (props.selectedBusinessArea) {
            return props.selectedBusinessArea;
        }

        return {
            name: "",
            description: "",
            id: "",
            active: false,
            color: undefined,
            isCustom: true,
        };
    }

    function validateBusinessAreaName(name: string) {
        let tempErrors = { ...errors };

        tempErrors.businessAreaNameError = isNullOrWhitespace(name) ? translateString("fieldMandatory") : "";

        setErrors(tempErrors);
    }

    function validateBusinessAreaForm(name: string) {
        let formValid = true;
        let tempErrors = { ...errors };

        if (isNullOrWhitespace(name)) {
            tempErrors.businessAreaNameError = translateString("fieldMandatory");
            formValid = false;
        }

        if (formValid) {
            setInProgress(true);

            if (isEdit()) updateBusinessArea();
            else createBusinessArea();
        } else {
            setErrors(tempErrors);
        }
    }

    function isEdit() {
        return props.selectedBusinessArea !== undefined && props.selectedBusinessArea!.id !== "";
    }
}
