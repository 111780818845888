import React from "react";
import { Trans } from "react-i18next";
import { useTranslation } from "../../../localization/useTranslation";
import { RiskVersionViewModel } from "../riskAssessmentVersions/RiskAssessmentVersions.types";
import { useChangeRiskAssessmentVersionLockedDialogHooks } from "./ChangeRiskAssessmentVersionLockedDialog.hooks";
import { DotLegalInfoDialog } from "@dotlegal/dotlegal-ui-components";

export interface IChangeRiskAssessmentVersionLockedDialog {
    selectedVersion: RiskVersionViewModel;
    onCloseDialog: () => void;
    onSave: () => void;
}

export function ChangeRiskAssessmentVersionLockedDialog(props: IChangeRiskAssessmentVersionLockedDialog) {
    const { changeIsLocked, inProgress } = useChangeRiskAssessmentVersionLockedDialogHooks(props);
    const { translateString } = useTranslation();

    return (
        <DotLegalInfoDialog
            btnText={props.selectedVersion.isLocked ? translateString("unlock") : translateString("lock")}
            header={props.selectedVersion.isLocked ? translateString("unlockVersionHeader") : translateString("lockVersionHeader")}
            inProgress={inProgress}
            content={
                props.selectedVersion.isLocked ? (
                    <Trans i18nKey="unlockVersionDataInfo" values={{ name: props.selectedVersion.name }}></Trans>
                ) : (
                    <Trans i18nKey="lockVersionDataInfo" values={{ name: props.selectedVersion.name }}></Trans>
                )
            }
            onBtnClick={() => changeIsLocked(props.selectedVersion)}
            onCloseDialog={props.onCloseDialog}
        />
    );
}
