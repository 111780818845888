import { useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";

export const useRiskAssessmentTemplateStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        root: {
            height: "100%",
        },
        questionText: {
            fontWeight: theme.typography.fontWeightRegular,
            display: "flex",
            fontSize: theme.typography.pxToRem(14),
        },
        tooltip: {
            display: "flex",
            marginLeft: theme.spacing(1),
            alignItems: "center",
        },
        templateContainer: {
            marginBottom: theme.spacing(2),
            ".collapse-header": {
                marginBottom: theme.spacing(2),
            },
        },
        emptyStateContainer: {
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
    });
};
