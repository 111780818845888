import { ValidationError } from "../../../common/validationError";
import { SystemModel } from "../SystemEdit.types";
import StepSegment from "../../../processingActivity/stepSegment/StepSegment";
import { useSystemSupplerStepHooks } from "./SystemSupplierStep.hooks";
import SystemSupplier from "./systemSupplier/SystemSupplier";
import { DotLegalButton } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { Box } from "@mui/material";

export interface ISystemSupplierStep {
    validationErrors: Array<ValidationError>;
    readOnly: boolean;
    system: SystemModel;
}

function SystemSupplierStep(props: ISystemSupplierStep) {
    const { translateString } = useTranslation();

    const {
        legalEntityLoading,
        legalEntityData,
        transferBasisLoading,
        transferBasisData,
        countriesLoading,
        countryList,
        getExpandedId,
        setExpandedId,
        onExpandClick,
        addSupplier,
        isAddingSupplier,
        removeSupplier,
    } = useSystemSupplerStepHooks(props);

    const suppliers = props.system.suppliers;

    function getSuppliers() {
        return suppliers.map((supplier) => {
            return (
                <SystemSupplier
                    system={props.system}
                    key={supplier.id}
                    supplier={supplier}
                    readOnly={props.readOnly}
                    legalEntityData={legalEntityData}
                    legalEntityLoading={legalEntityLoading}
                    transferBasisData={transferBasisData}
                    transferBasisLoading={transferBasisLoading}
                    countryList={countryList}
                    countriesLoading={countriesLoading}
                    getExpandedId={() => getExpandedId()}
                    onExpandedClick={() => onExpandClick(supplier.id)}
                    validationErrors={props.validationErrors}
                    setExpandedId={setExpandedId}
                    removeSupplier={() => removeSupplier(supplier.id)}
                />
            );
        });
    }

    return (
        <StepSegment size="large">
            {getSuppliers()}

            <Box sx={(theme) => ({ marginTop: theme.spacing(3), display: "flex", justifyContent: "center" })}>
                <DotLegalButton
                    isLoading={isAddingSupplier}
                    disabled={suppliers.some((x) => x.legalEntityId === undefined || x.legalEntityId === null) || props.readOnly}
                    buttonType="secondary"
                    onClick={() => {
                        addSupplier();
                    }}
                >
                    {translateString("addSupplier")}
                </DotLegalButton>
            </Box>
        </StepSegment>
    );
}

export default SystemSupplierStep;
