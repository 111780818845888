import { DotLegalDialog, DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { Trans } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { DeclarationViewModel } from "../Declaration.types";
import useCompleteDeclarationDialogHooks from "./CompleteDeclarationDialog.hooks";

export interface ICompleteDeclarationDialog {
    onDialogClose: () => void;
    selectedDeclaration: DeclarationViewModel;
}

function CompleteDeclarationDialog(props: ICompleteDeclarationDialog) {
    const { translateString, translateDateWithFullMonthAndYear } = useTranslation();
    const { onCompleteDeclarationClick, isSaving } = useCompleteDeclarationDialogHooks(props);

    return (
        <DotLegalDialog
            buttonOkText={translateString("complete")}
            open
            onDialogClose={props.onDialogClose}
            onOkClick={() => onCompleteDeclarationClick()}
            header={translateString("completeDeclaration")}
            size="sm"
            inProgress={isSaving}
        >
            <Box sx={{ textAlign: "center" }}>
                <Typography sx={{ fontSize: "14px" }}>
                    <Trans
                        i18nKey={"completeDeclarationDescription"}
                        values={{
                            name: props.selectedDeclaration.name,
                            deadline: translateDateWithFullMonthAndYear(props.selectedDeclaration.auditDate),
                        }}
                        components={{
                            strong: <Box component="span" sx={(theme) => ({ fontWeight: "bold", color: theme.palette.primary.main })}></Box>,
                        }}
                    />
                </Typography>
            </Box>
        </DotLegalDialog>
    );
}

export default CompleteDeclarationDialog;
