import {
    DotLegalActionBar,
    DotLegalButton,
    DotLegalCompletedPercentage,
    DotLegalEmptyState,
    DotLegalMultiSelect,
    DotLegalPageHeader,
    DotLegalPaper,
    DotLegalTableWithControls,
    ITableHeader,
} from "@dotlegal/dotlegal-ui-components";
import React from "react";
import { useTranslation } from "../../localization/useTranslation";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import DeclarationsIcon from "./Declarations.svg?react";
import useDeclarationsHooks from "./Declarations.hooks";
import { Box, Grid, GridSize, TableCell, TableRow } from "@mui/material";
import { AuditFrequency, DeclarationApprovalOption, DeclarationsTableModel, DeclarationsViewModel } from "./Declarations.types";
import EmptyState from "./DeclarationsEmptyState.svg?react";
import { greenArrowRight } from "../../common/icons";
import CreateEditDeclarationDialog from "../createEditDeclarationDialog/CreateEditDeclarationDialog";
import { useNavigate } from "react-router-dom";
import { isNullOrWhitespace, setFirstLetterToLowerCase } from "../../common/stringOperations";
import ComplianceAreaWrapper from "../../common/components/complianceAreaWrapper/ComplianceAreaWrapper";
import { SelectableItem } from "../../processingActivity/ProcessingActivity.types";
import { useUrlProvider } from "../../useUrlProvider";
import DeclarationGroupEntityDisplayDialog from "./declarationGroupEntityDisplayDialog/DeclarationGroupEntityDisplayDialog";

function Declarations() {
    const { translateString, translateDateWithFullMonthAndYear, translateDateToFullMonth } = useTranslation();
    const { customerName, permissions } = useUserContext();
    const {
        isLoading,
        rows,
        hasData,
        setShowAddDialog,
        showAddDialog,
        searchedComplianceAreas,
        setSearchedComplianceAreas,
        searchedAuditFrequencies,
        setSearchedAuditFrequencies,
        searchedGroupEntities,
        setSearchedGroupEntities,
        complianceAreaFilterOptions,
        auditFrequencyFilterOptinos,
        groupEntitiesFilterOptions,
        showGroupEntitiesDialog,
        setShowGroupEntitiesDialog,
    } = useDeclarationsHooks();
    const { getDeclarationUrl } = useUrlProvider();
    const navigate = useNavigate();

    const styles = {
        hover: {
            "&:hover": {
                cursor: "pointer",
            },
        },
    };

    function getHeaders() {
        let headers = Array<ITableHeader<DeclarationsTableModel>>();
        headers.push({ property: "name", text: translateString("declarationHeader"), align: "left", showOnMobile: true });
        headers.push({ property: "complianceAreasString", text: translateString("complianceAreas"), align: "left", showOnMobile: true });
        headers.push({ property: "auditFrequencyString", text: translateString("auditFrequency"), align: "left", showOnMobile: true });
        headers.push({ property: "approvalString", text: translateString("approval"), align: "left", showOnMobile: true });
        headers.push({ property: "groupEntitiesString", text: translateString("groupCompaniesParenthesis"), align: "left", showOnMobile: true });
        headers.push({ property: "auditDate", text: translateString("nextAudit"), align: "left", showOnMobile: true });
        headers.push({ property: "auditProgress", text: translateString("nextAuditProgress"), align: "left", showOnMobile: true });
        headers.push({ property: "auditDateString", text: "", align: "left", showOnMobile: false, hideHeader: true });

        return headers;
    }

    const tableGridProps: { item: boolean; xs: GridSize; sm: GridSize; md: GridSize; lg: GridSize; xl: GridSize } = {
        item: true,
        xs: 12,
        sm: 6,
        md: 4,
        lg: 2,
        xl: 2,
    };
    function getFilterControls() {
        return (
            <>
                <Grid {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedComplianceAreas}
                        chosenOptions={searchedComplianceAreas}
                        label={translateString("complianceAreas")}
                        placeholder={translateString("complianceAreas")}
                        isLoading={isLoading}
                        options={complianceAreaFilterOptions}
                        noMargin
                        limit={1}
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>
                <Grid {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedAuditFrequencies}
                        chosenOptions={searchedAuditFrequencies}
                        label={translateString("auditFrequency")}
                        placeholder={translateString("businessAreasfrequencyArea")}
                        isLoading={isLoading}
                        options={auditFrequencyFilterOptinos}
                        noMargin
                        limit={1}
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>
                <Grid {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedGroupEntities}
                        chosenOptions={searchedGroupEntities}
                        label={translateString("groupEntities")}
                        placeholder={translateString("groupEntities")}
                        isLoading={isLoading}
                        options={groupEntitiesFilterOptions}
                        noMargin
                        limit={1}
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>
            </>
        );
    }

    function getGroupEntityTableCell(groupEntities: Array<SelectableItem>, noOfGroupEntitiesWithoutAccess: number): React.ReactNode {
        let content;
        let canClickTableCell = false;

        if (groupEntities.length === 0 && noOfGroupEntitiesWithoutAccess === 0) {
            content = <></>;
        }

        if (groupEntities.length > 0) {
            let additionalEntities = groupEntities.length - 1 + noOfGroupEntitiesWithoutAccess;
            canClickTableCell = additionalEntities > 0;
            content = (
                <Box sx={styles.hover}>
                    {isNullOrWhitespace(groupEntities[0].name) ? translateString("deletedGroupCompany") : groupEntities[0].name}{" "}
                    {getAdditionalGroupEntities(additionalEntities)}
                </Box>
            );
        } else {
            canClickTableCell = noOfGroupEntitiesWithoutAccess > 0;
            content = <Box>{getAdditionalGroupEntities(noOfGroupEntitiesWithoutAccess)}</Box>;
        }

        return (
            <TableCell
                onClick={(e) => {
                    if (canClickTableCell) {
                        e.stopPropagation();
                        setShowGroupEntitiesDialog({ groupEntities: groupEntities, entitiesWithoutAccess: noOfGroupEntitiesWithoutAccess });
                    }
                }}
            >
                {content}
            </TableCell>
        );
    }

    function getAdditionalGroupEntities(numberOfAdditionalEntities: number) {
        if (numberOfAdditionalEntities === 0) return;

        return (
            <Box component={"span"} sx={(theme) => ({ fontSize: theme.typography.pxToRem(12), fontWeight: 700, color: "primary.dark" })}>
                + {numberOfAdditionalEntities}
            </Box>
        );
    }

    return (
        <React.Fragment>
            <DotLegalPageHeader userContext={{ customerName }} breadCrumbs={[{ name: translateString("declarations") }]} />

            <DotLegalActionBar
                primaryButton={
                    permissions.declarationPermissions.create ? (
                        <DotLegalButton buttonType="primary" size="medium" onClick={() => setShowAddDialog(true)}>
                            {translateString("createDeclaration")}
                        </DotLegalButton>
                    ) : (
                        <></>
                    )
                }
                //TODO - Skal ind igen når der findes templates
                // additionalButtons={
                //     permissions.declarationPermissions.create
                //         ? [
                //               <DotLegalButton key="1" buttonType="secondary" size="medium" onClick={() => {}}>
                //                   {translateString("addDeclarationTemplate")}
                //               </DotLegalButton>,
                //           ]
                //         : []
                // }
                showDialog={showAddDialog}
                canCreate
                icon={<DeclarationsIcon />}
            >
                {showAddDialog && <CreateEditDeclarationDialog onDialogClose={() => setShowAddDialog(false)} />}
            </DotLegalActionBar>

            <DotLegalPaper>
                {hasData ? (
                    <DotLegalTableWithControls
                        headers={getHeaders()}
                        extraControls={getFilterControls()}
                        hideRowsPerPage
                        defaultOrderBy={"name"}
                        defaultOrder={"asc"}
                        isLoading={isLoading}
                        clickableRows
                        paginationLabelOf={translateString("labelOf")}
                        getUserSpecificPageLength={() => 30}
                        labelRowsPerPage={translateString("showEntites")}
                        labelSearch={translateString("search")}
                        noOptionsLabel={translateString("noOptions")}
                        data={mapToDeclarationsTableModel(rows)}
                        emptyText={translateString("noData")}
                        renderRow={(row) => {
                            return (
                                <TableRow key={row.id} onClick={() => navigate(getDeclarationUrl(row.id))}>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>
                                        <Box sx={{ display: "flex", gap: 1 }}>
                                            {row.complianceAreas.map((x) => (
                                                <ComplianceAreaWrapper key={x.id} complianceArea={x.name} />
                                            ))}
                                        </Box>
                                    </TableCell>
                                    <TableCell>{row.auditFrequencyString}</TableCell>
                                    <TableCell>{row.approvalString}</TableCell>
                                    {getGroupEntityTableCell(row.groupEntities, row.noOfGroupEntitiesWithoutAccess)}
                                    <TableCell>{row.auditDateString}</TableCell>
                                    <TableCell>
                                        <Box width={238}>
                                            <DotLegalCompletedPercentage completed={row.auditProgress} />
                                        </Box>
                                    </TableCell>
                                    <TableCell align={"right"}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                marginRight: 1,
                                                "& svg": {
                                                    height: "14px",
                                                },
                                            }}
                                        >
                                            {greenArrowRight}
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            );
                        }}
                    />
                ) : (
                    <DotLegalEmptyState icon={<EmptyState />} text={translateString("declarationsEmptyState")} />
                )}
            </DotLegalPaper>

            {(showGroupEntitiesDialog.groupEntities.length > 0 || showGroupEntitiesDialog.entitiesWithoutAccess > 0) && (
                <DeclarationGroupEntityDisplayDialog
                    onCloseDialog={() => setShowGroupEntitiesDialog({ groupEntities: [], entitiesWithoutAccess: 0 })}
                    groupEntities={showGroupEntitiesDialog.groupEntities}
                    numberOfGroupEntitiesWithoutaccess={showGroupEntitiesDialog.entitiesWithoutAccess}
                />
            )}
        </React.Fragment>
    );

    function mapToDeclarationsTableModel(rows: Array<DeclarationsViewModel>): Array<DeclarationsTableModel> {
        return rows.map((row) => {
            return {
                ...row,
                complianceAreasString: row.complianceAreas.map((x) => x.name).join(", "),
                auditFrequencyString: translateString(setFirstLetterToLowerCase(AuditFrequency[row.auditFrequency].toString())),
                approvalString: translateString(setFirstLetterToLowerCase(DeclarationApprovalOption[row.approval].toString())),
                groupEntitiesString: row.groupEntities.map((x) => x.name).join(", "),
                auditDateString: translateDateWithFullMonthAndYear(row.auditDate),
            };
        });
    }
}

export default Declarations;
