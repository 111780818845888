import { useMutation } from "react-query";
import { put } from "../../common/api/apiShared";
import { Result } from "../../common/api/result";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { IResendInvitationDialogProps } from "./ResendInvitationDialog";

export function useResendInvitationHook(props: IResendInvitationDialogProps) {
    const { translateString } = useTranslation();
    const snackbar = useDotLegalSnackbar();

    const resendInvitationMutatuon = useMutation(changeStatus);
    const resendInvitationClick = () => {
        resendInvitationMutatuon.mutateAsync(undefined, {
            onSuccess: (resp: Result<string>) => {
                if (resp.hasErrors()) {
                    snackbar.show(translateString("userAllreadyActive"), "warning");
                    props.onCloseDialog();
                } else {
                    snackbar.show(translateString("invitationResend"));
                    props.onCloseDialog();
                }
            },
        });
    };

    function changeStatus() {
        return put<string>(`/user/${props.userId}/resendInvitation`, {});
    }

    return {
        resendInvitationClick,
        isSaving: resendInvitationMutatuon.isLoading,
    };
}
