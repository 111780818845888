import { useSystemIcon } from "./SystemIcon.hooks";
import DotLegalImg from "../../common/components/dotLegalImg/DotLegalImg";
import React from "react";
import { useSystemIconStyles } from "./SystemIcon.styles";
import CustomSystemOverview from "./icons/customSystemOverview.png";
import { Box } from "@mui/material";

export interface ISystemIconProps {
    systemName?: string;
    size: "small" | "medium";
}
function SystemIcon(props: ISystemIconProps) {
    const { getIcon } = useSystemIcon(props);
    const classes = useSystemIconStyles();

    const icon = getIcon();
    const className = props.size === "small" ? classes.icon : classes.fullSize;
    const fallback = <Box component={"img"} src={CustomSystemOverview} sx={className} />;

    return icon ? <Box component={"img"} src={icon} alt={props.systemName} sx={className} /> : fallback;
}

export default SystemIcon;
