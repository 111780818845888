import React from "react";
import { Box } from "@mui/material";
import { usePlanDialogButtonWrapperHooks } from "./planDialogButtonWrapper.hooks";
import PlanUpgradeDialogWrapper from "../planUpgradeDialogWrapper/PlanUpgradeDialogWrapper.tsx";

interface IPlanDialogButtonWrapper {
    enabled: boolean;
    children: any;
    text: string;
}

function PlanDialogButtonWrapper(props: IPlanDialogButtonWrapper) {
    const { showDialog, setShowDialog } = usePlanDialogButtonWrapperHooks();

    return (
        <React.Fragment>
            {props.enabled ? (
                <Box
                    sx={{
                        "&:hover": { cursor: "pointer !important" },
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setShowDialog(true);
                    }}
                >
                    {props.children}
                </Box>
            ) : (
                props.children
            )}

            {showDialog && <PlanUpgradeDialogWrapper text={props.text} onDialogClose={() => setShowDialog(false)} />}
        </React.Fragment>
    );
}

export default PlanDialogButtonWrapper;
