import { DotLegalHeader, DotLegalInformationTooltip, DotLegalSelect } from "@dotlegal/dotlegal-ui-components";
import { SelectableAssociatedAssetItem } from "../SystemRelationStep.types";
import React from "react";
import { Box } from "@mui/system";
import { useTranslation } from "../../../../localization/useTranslation";
import { Paper, styled } from "@mui/material";
import { useSystemRelationSelectorStyles } from "./SystemRelationSelector.styles";

export interface ISystemRelationSelector {
    selecableItems?: Array<SelectableAssociatedAssetItem>;
    isLoading: boolean;
    onAssetChange: (id: string) => void;
    selectedItem?: SelectableAssociatedAssetItem;
    disabled: boolean;
}

const BoxWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    marginRight: theme.spacing(0.5),
}));

const TooltipWrapper = styled("div")(({ theme }) => ({
    marginRight: theme.spacing(2),
}));

function SystemRelationSelector(props: ISystemRelationSelector) {
    const { translateString } = useTranslation();
    const styles = useSystemRelationSelectorStyles();

    return (
        <React.Fragment>
            <Box sx={{ width: "50%", paddingBottom: 2 }}>
                <DotLegalSelect
                    isLoading={props.isLoading}
                    options={
                        props.selecableItems
                            ? props.selecableItems.map((obj) => {
                                  return {
                                      id: obj.id,
                                      name: obj.name,
                                  };
                              })
                            : undefined
                    }
                    onChange={(id) => props.onAssetChange(id!)}
                    placeholder={translateString("asset")}
                    label={translateString("asset")}
                    noOptionsLabel={translateString("noOptions")}
                    selectedItem={props.selectedItem?.id}
                    disableClearable
                    disabled={props.disabled}
                />
            </Box>
            {props.selectedItem && (
                <Paper elevation={0} sx={styles.paper}>
                    <BoxWrapper>
                        <TooltipWrapper>
                            <DotLegalInformationTooltip text={translateString("systemRelationSupplierToolTip")} />
                        </TooltipWrapper>
                        <DotLegalHeader marginBottom={2} headerStyle="small">
                            {translateString("supplier")}:
                        </DotLegalHeader>
                        &nbsp;
                        <DotLegalHeader headerStyle="small" marginBottom={2} fontWeight={400}>
                            {props.selectedItem?.suppliers.join(", ")}
                        </DotLegalHeader>
                    </BoxWrapper>

                    <BoxWrapper>
                        <TooltipWrapper>
                            <DotLegalInformationTooltip text={translateString("systemRelationBusinessAreaToolTip")} />
                        </TooltipWrapper>
                        <DotLegalHeader marginBottom={2} headerStyle="small">
                            {translateString("businessAreas")}:
                        </DotLegalHeader>
                        &nbsp;
                        <DotLegalHeader headerStyle="small" marginBottom={2} fontWeight={400}>
                            {props.selectedItem?.businessAreas.join(", ")}
                        </DotLegalHeader>
                    </BoxWrapper>

                    <BoxWrapper>
                        <TooltipWrapper>
                            <DotLegalInformationTooltip text={translateString("systemRelationResponsibleToolTip")} />
                        </TooltipWrapper>
                        <DotLegalHeader marginBottom={2} headerStyle="small">
                            {translateString("responsible")}:
                        </DotLegalHeader>
                        &nbsp;
                        <DotLegalHeader headerStyle="small" marginBottom={2} fontWeight={400}>
                            {props.selectedItem?.responsible}
                        </DotLegalHeader>
                    </BoxWrapper>

                    <BoxWrapper>
                        <TooltipWrapper>
                            <DotLegalInformationTooltip text={translateString("systemRelationSecurityMeasuresToolTip")} />
                        </TooltipWrapper>
                        <DotLegalHeader marginBottom={0} headerStyle="small">
                            {translateString("securityMeasures")}:
                        </DotLegalHeader>
                        &nbsp;
                        <DotLegalHeader headerStyle="small" marginBottom={0} fontWeight={400}>
                            {props.selectedItem?.securityMeasures.join(", ")}
                        </DotLegalHeader>
                    </BoxWrapper>
                </Paper>
            )}
        </React.Fragment>
    );
}

export default SystemRelationSelector;
