import { StepModel } from "../../common/components/dotLegalStepper/DotLegalCoreStepper.types";
import { SystemModel } from "../systemEdit/SystemEdit.types";
import { useSteps } from "../../common/components/dotLegalStepper/useSteps";
import { isNullOrWhitespace } from "../../common/stringOperations";

export function useSystemSteps(system: SystemModel | undefined, systemSteps: Array<StepModel>, relations: Array<string>) {
    system = system ?? new SystemModel();
    const steps = useSteps(system, systemSteps, "step", HasStepDataAdded);

    function HasStepDataAdded(step: number) {
        switch (step) {
            case 0:
                return HasSystemData();
            case 1:
                return HasSystemAssociationStepData();
            case 2:
                return HasSystemDataAndStorageStepData();
            case 3:
                return HasSystemProcessorStepData();
            case 4:
                return HasSystemInformationStepData();
            case 5:
                return true;
            case 6:
                return HasRelations();
            case 7:
                return HasPolicyStepData();
            case 8:
                return hasPersonalData();
            default:
                return true;
        }
    }

    function HasSystemData() {
        return system?.name !== "" || !isNullOrWhitespace(system.description);
    }

    function HasSystemAssociationStepData() {
        return system!.businessAreas?.length > 0;
    }

    function HasSystemDataAndStorageStepData() {
        if (
            system!.dataTypes?.length > 0 ||
            system?.entityType ||
            system?.storageType ||
            system!.dataStorageLocations?.length > 0 ||
            system!.supportAccessLocations?.length > 0 ||
            !isNullOrWhitespace(system!.network)
        ) {
            return true;
        }

        return false;
    }

    function HasSystemProcessorStepData() {
        return system!.suppliers.some((x) => x.legalEntityId);
    }

    function HasSystemInformationStepData() {
        return !isNullOrWhitespace(system!.deletionPeriodId!) || system!.securityMeasures.length > 0;
    }

    function HasPolicyStepData() {
        return system!.policies?.length > 0;
    }

    function HasRelations() {
        return system!.disableSystemRelations || relations.length > 0;
    }

    function hasPersonalData() {
        const systemPersonalData = system?.systemPersonalData;
        return (
            systemPersonalData !== undefined &&
            systemPersonalData !== null &&
            (systemPersonalData?.dataCategories.length > 0 ||
                systemPersonalData.processingActivityDataSubjects.length > 0 ||
                systemPersonalData.systemDataSubjects.length > 0)
        );
    }

    return {
        getSteps: steps.getSystemSteps,
        getActiveStep: steps.getActiveStep,
        changeStep: steps.changeStep,
        validationErrors: steps.validationErrors,
    };
}
