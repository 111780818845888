import { DotLegalDialog, DotLegalHeader, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { Box } from "@mui/material";
import useComplianceReportDialogHooks from "./ComplianceReportDialog.hooks";

export interface IComplianceReportDialogProps {
    onDialogClose: () => void;
    onlyUserSpecific: boolean;
    selectedGroupCompany: string | undefined;
}

function ComplianceReportDialog(props: IComplianceReportDialogProps) {
    const { translateString } = useTranslation();
    const { saveModel, onNoteChange, onDownloadPdfClick, isDownloadingPdf } = useComplianceReportDialogHooks(props);

    return (
        <DotLegalDialog
            size="md"
            header={translateString("complianceReport")}
            buttonOkText={translateString("downloadPdf")}
            open
            onDialogClose={props.onDialogClose}
            onOkClick={onDownloadPdfClick}
            inProgress={isDownloadingPdf}
        >
            <Box>
                <DotLegalHeader center fontWeight={400} headerStyle={"small"}>
                    {translateString("complianceReportInfo")}
                </DotLegalHeader>
                <DotLegalTextField
                    multiline
                    rows={3}
                    label={translateString("writeANote")}
                    value={saveModel.note ?? null}
                    onChange={onNoteChange}
                    debounce={false}
                ></DotLegalTextField>
            </Box>
        </DotLegalDialog>
    );
}

export default ComplianceReportDialog;
