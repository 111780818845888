import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { get } from "../../../common/api/apiShared";
import { useStateLocalStorage } from "@dotlegal/dotlegal-ui-components";
import {
    SecurityMeasureSaveModel,
    SecurityMeasureRowViewModel,
    SecurityMeasureViewModel,
    BusinessAreaContainer as BusinessAreaIdContainer,
    GroupEntityContainer,
} from "./SecurityMeasures.types";

export function useSecurityMeasureMapping() {
    const queryClient = useQueryClient();
    const [showAllSecurityMeasures, setShowAllSecurityMeasures] = useStateLocalStorage("onlyActiveSecurityMeasures", false);
    const [showAddEditDialog, setShowAddEditDialog] = useState(false);
    const [showDeleteSecurityMeasureDialog, setShowDeleteSecurityMeasureDialog] = useState(false);
    const [selectedSecurityMeasure, setSelectedSecurityMeasure] = useState<SecurityMeasureSaveModel | undefined>(undefined);
    const [showChangeStatusDialog, setShowChangeStausDialog] = useState(false);

    const queryPart = `?showAllSecurityMeasures=${showAllSecurityMeasures}`;
    const url = `/securityMeasures${queryPart}`;
    const { isLoading, data } = useQuery(url, () => get<Array<SecurityMeasureViewModel>>(url));

    let securityMeasures = Array<SecurityMeasureRowViewModel>();
    let businessAreaIds = Array<BusinessAreaIdContainer>();
    let groupEntitiesIds = Array<GroupEntityContainer>();
    if (data) {
        data.forEach((sm) => {
            businessAreaIds.push({ id: sm.id, businessAreaIds: sm.businessAreaIds });
            groupEntitiesIds.push({ id: sm.id, groupEntitiesIds: sm.groupEntitiesIds });
            securityMeasures.push({
                id: sm.id,
                name: sm.name,
                description: sm.description,
                securityMeasureType: sm.securityMeasureType,
                isGlobal: sm.isGlobal,
                isCustom: sm.isCustom,
                isActive: sm.isActive,
                hasAllBusinessAreas: sm.hasAllBusinessAreas,
                hasAllGroupEntities: sm.hasAllGroupEntities,
            });
        });
    }

    const refetchSecurityMeasures = () => {
        queryClient.refetchQueries(url);
    };

    return {
        isLoading,
        securityMeasures,
        businessAreaIds,
        groupEntitiesIds,
        showAllSecurityMeasures,
        setShowAllSecurityMeasures,
        showAddEditDialog,
        setShowAddEditDialog,
        showDeleteSecurityMeasureDialog,
        setShowDeleteSecurityMeasureDialog,
        selectedSecurityMeasure,
        setSelectedSecurityMeasure,
        refetchSecurityMeasures,
        showChangeStatusDialog,
        setShowChangeStausDialog,
    };
}
