import { useMutation } from "react-query";
import { useUrlParams } from "../../common/hooks/useUrlParams";
import { downloadFile } from "../../common/api/downloadFile";
import { useEffect, useState } from "react";

export function useExportCustomListHooks() {
    const [exportStatus, setExportStatus] = useState<"Loading" | "Succes" | "Failure">("Loading");

    const urlParams = useUrlParams();
    const params = urlParams.getUrlParams;

    const token = params.getValue("token");
    const fileName = "privacy-data-export.zip";

    const exportFileMutation = useMutation(getExport, {
        onSuccess: (status: number) => {
            if (status === 200) {
                setExportStatus("Succes");
            } else {
                setExportStatus("Failure");
            }
        },
    });

    useEffect(() => {
        if (exportFileMutation.isIdle) {
            exportFileMutation.mutate();
        }
    }, [exportFileMutation]);

    async function getExport(): Promise<number> {
        return await downloadFile(`/CustomListExport?token=${token}`, fileName, "GET");
    }

    return { exportStatus };
}
