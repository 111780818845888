import { DotLegalButton, DotLegalCheckbox, DotLegalSelect } from "@dotlegal/dotlegal-ui-components";
import { Box } from "@mui/material";
import React from "react";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import DotLegalCollapse from "../../../common/components/dotLegalCollapse/DotLegalCollapse";
import DotLegalPersonalDataSelector from "../../../common/components/dotLegalPersonalDataSelector/DotLegalPersonalDataSelector";
import DotLegalStepHeader from "../../../common/components/dotLegalStepHeader/DotLegalStepHeader";
import { Guid } from "../../../common/guid";
import LegalEntityTable from "../../../legalEntity/legalEntityTable/LegalEntityTable";
import { useTranslation } from "../../../localization/useTranslation";
import { useUrlProvider } from "../../../useUrlProvider";
import { DataCategoryDataTypes, JointDataController, ProcessingActivityModel, StepTypeEnum } from "../../ProcessingActivity.types";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import SegmentStep from "../../stepSegment/StepSegment";
import SharingsInfoBox from "../sharingsInfoBox/SharingsInfoBox";
import SharingsLegalEntity from "../sharingsLegalEntityBox/sharingsLegalEntity";
import { useJointDataControllerStep } from "./JointDataControllerStep.hooks";
import { useJointDataControllerStyles } from "./JointDataControllerStep.styles";
import { ValidationError } from "../../../common/validationError";
import { isNullOrWhitespace } from "../../../common/stringOperations";
import { getSelectableDocumentItems, getSelectedDocuments } from "../../hooks/useDataProcessingAgreement";
import { DocumentStatus, DotLegalDocumentType } from "../../../documents/Documents.types";
import EditLegalEntityDocumentDialog from "../../../legalEntity/legalEntityDocumentTab/editLegalEntityDocumentDIalog/EditLegalEntityDocumentDialog";
import SharingsAgreementBox from "../sharingsAgreementBox/sharingsAgreementBox";
import { usePlanContext } from "../../../auth/planProvider/PlanProvider";
import { mapToDataCategorySelectorModel } from "../sharingOfDataStepFunctions.ts";

export interface IJointDataController {
    jointDataControllers: Array<JointDataController>;
    processingActivity: ProcessingActivityModel;
    isAddingJointDataController: boolean;
    onAddJointDataControllerClick: () => void;
    onRemoveJointDataControllerClick: (jointDataControllerId: string) => void;
    onSharingNameChange: (name: string, sharingId: string) => void;
    onSharingDescriptionChange: (description: string, sharingId: string) => void;
    onDataControllerChange: (legalUnitId: string | null, jointDataControllerId: string) => void;
    onResponsibleChange: (responsibleId: string | null, jointDataControllerId: string) => void;
    onChosenDataCategories: (chosenCategories: Array<DataCategoryDataTypes>, jointDataControllerId: string) => void;
    onLegalEntitiesChange: (entities: Array<string>, jointDataControllerId: string) => void;
    validationErrors: Array<ValidationError>;
    onDisableClick: (disabled: boolean) => void;
    readOnly: boolean;
    onAgreementChange: (agreements: Array<string>, sourceId: string) => void;
    onCreateNewDocument: (agreement: string, dataProcessorId: string) => void;
}

function JointDataControllerStep(props: IJointDataController) {
    const { translateString, translateDocumentType } = useTranslation();
    const { getUserUrl } = useUrlProvider();
    const styles = useJointDataControllerStyles();

    const { addonsFeatures } = usePlanContext();
    const { gdpo, permissions } = useUserContext();
    const jointDataControllerPermissions = permissions.processingActivityJointDataControllerPermissions;

    const {
        getExpandedId,
        setExpandedId,
        legalEntityData,
        legalEntityLoading,
        responsibleData,
        responsibleLoading,
        dataCategoriesData,
        groupEntityData,
        groupEntityLoading,
        jointDataControllerId,
        setJointDataControllerId,
        agreementSaveModel,
        setAgreementSaveModel,
        documentsData,
        documentsLoading,
        refetchDocuments,
        selectedDocument,
        setSelectedDocument,
        showEditDocumentDialog,
        setShowEditDocumentDialog,
        onAddAgreement,
        onDeleteAgreement,
        onHasAgreementChange,
    } = useJointDataControllerStep(props.processingActivity, props.jointDataControllers);

    const handleCollapseClick = (jointDataControllerId: string) => {
        if (getExpandedId() === jointDataControllerId) {
            setExpandedId("");
        } else {
            setExpandedId(jointDataControllerId);
        }
    };

    const handleAddJointDataControllerClick = (jointDataControllerId: string) => {
        setExpandedId(jointDataControllerId);
        props.onAddJointDataControllerClick();
    };

    const handleRemoveJointDataController = (jointDataControllerId: string) => {
        if (getExpandedId() === jointDataControllerId) {
            setExpandedId("");
        }

        props.onRemoveJointDataControllerClick(jointDataControllerId);
    };

    const handleChosenDataCategories = (datacategories: Array<DataCategoryDataTypes> | undefined, sourceId: string) => {
        if (dataCategoriesData) {
            props.onChosenDataCategories(datacategories!, sourceId);
        } else {
            props.onChosenDataCategories([], sourceId);
        }
    };

    const getGroupEntitiesForTable = () => {
        if (groupEntityData) {
            return groupEntityData.filter((entity) => props.processingActivity.legalEntities.indexOf(entity.id) >= 0);
        }
        return undefined;
    };

    const getCollapseDetails = (companies: Array<string>, dataprocessor: string | null | undefined) => {
        let sender = "";
        let recipient = "";

        if (companies.length > 0 && !legalEntityLoading) {
            let entity = legalEntityData?.find((x) => x.id === companies[0]);
            if (entity) {
                recipient = `${entity!.name} ${companies.length > 1 ? " + " + (companies.length - 1).toString() : ""}`;
            }
        }

        if (dataprocessor && !legalEntityLoading) {
            let entity = legalEntityData!.find((x) => x.id === dataprocessor);
            if (entity) {
                sender = entity.name;
            }
        }

        return (
            <React.Fragment>
                <div>{recipient}</div>
                <KeyboardBackspaceIcon
                    fontSize="large"
                    sx={(theme) => ({
                        fill: theme.palette.secondary.main,
                        transform: "rotate(180deg)",
                        marginRight: theme.spacing(1.5),
                        marginLeft: theme.spacing(1.5),
                    })}
                />
                <div>{sender}</div>
            </React.Fragment>
        );
    };

    return (
        <SegmentStep size="large">
            <React.Fragment>
                <DotLegalStepHeader toolTipText={translateString("jointDataControllerStepHeaderTooltip")} record={false} topMargin>
                    {translateString("jointDataControllerStepHeader")}
                </DotLegalStepHeader>

                {props.jointDataControllers.map((jointdataController, index) => {
                    const hasDocuments = jointdataController.documents.length !== 0;
                    const isReadOnly = props.readOnly || jointdataController.readOnly;
                    const selectedJointDataController = legalEntityData?.find((x) => x.id === jointdataController.dataControllerId);

                    return (
                        <Box key={jointdataController.id} sx={styles.collapse}>
                            <DotLegalCollapse
                                key={jointdataController.id}
                                onExpandClick={() => handleCollapseClick(jointdataController.id!)}
                                isExpanded={getExpandedId() === jointdataController.id}
                                collapseName={jointdataController.name}
                                error={props.validationErrors.some((e) => e.field.startsWith(index.toString()))}
                                removeItemText={translateString("removeJointDataController")}
                                centerContent={getCollapseDetails(jointdataController.legalEntities, jointdataController.dataControllerId)}
                                onRemoveClick={() => handleRemoveJointDataController(jointdataController.id!)}
                                disabled={isReadOnly}
                            >
                                <SegmentStep size="small">
                                    <SharingsInfoBox
                                        name={jointdataController.name}
                                        description={jointdataController.description}
                                        onDescriptionChange={(e) => props.onSharingDescriptionChange(e, jointdataController.id!)}
                                        onNameChange={(e) => props.onSharingNameChange(e, jointdataController.id!)}
                                        disabled={isReadOnly}
                                    />

                                    <DotLegalStepHeader toolTipText={translateString("whoIsJointDataControllerToolTip")} record={false} topMargin>
                                        {translateString("whoIsJointDataController")}
                                    </DotLegalStepHeader>

                                    <SharingsLegalEntity
                                        options={legalEntityData}
                                        isLoading={legalEntityLoading}
                                        selectedItem={jointdataController.dataControllerId}
                                        placeholder={translateString("Controller")}
                                        label={translateString("Controller")}
                                        errorText={props.validationErrors.find((v) => v.field === `${index}legalEntityId`)?.error}
                                        disabled={isReadOnly}
                                        onLegalEntityChange={(legalUnitId) => props.onDataControllerChange(legalUnitId, jointdataController.id!)}
                                        showAddNewEntityButton={!isReadOnly}
                                    />

                                    {jointDataControllerPermissions.hasAgreementPermission && (
                                        <SharingsAgreementBox
                                            options={getSelectableDocumentItems(
                                                documentsData,
                                                translateString,
                                                translateDocumentType,
                                                jointdataController.hasAgreement,
                                                jointdataController.documents,
                                                jointdataController.containsNotAccessibleDocuments
                                            )}
                                            showAddAgreementBox={!isNullOrWhitespace(jointdataController.dataControllerId) ? true : false}
                                            getSelectedDocuments={getSelectedDocuments(
                                                jointdataController.documents,
                                                jointdataController.hasAgreement,
                                                jointdataController.containsNotAccessibleDocuments
                                            )}
                                            saveAgreement={(agreementId) => {
                                                props.onCreateNewDocument(agreementId, jointDataControllerId);
                                                refetchDocuments();
                                            }}
                                            id={jointDataControllerId}
                                            disabled={isReadOnly}
                                            onYesOrNoChange={(hasAgreement) => onHasAgreementChange(hasAgreement, jointdataController.id!)}
                                            dataProcessorAgreementSaveModel={agreementSaveModel}
                                            onAddNewAgreementClick={() => {
                                                setJointDataControllerId(jointdataController!.id!);
                                                setAgreementSaveModel({
                                                    legalEntityId: jointdataController!.dataControllerId!,
                                                    name: "",
                                                    type: addonsFeatures.research ? undefined : DotLegalDocumentType.JointDataControllerAgreement,
                                                    base64Data: "",
                                                    fileName: "",
                                                    link: "",
                                                    isLink: false,
                                                    owner: props.processingActivity.owner,
                                                    status: permissions.canSetDocumentStatus ? undefined : DocumentStatus.draft,
                                                    allGroupEntities: gdpo || selectedJointDataController!.isCustomerOwned,
                                                    groupEntityAccessIds: [],
                                                    isGroupEntity: selectedJointDataController?.isCustomerOwned,
                                                });
                                            }}
                                            onChipClick={
                                                hasDocuments
                                                    ? (id) => {
                                                          setSelectedDocument({
                                                              documentId: id,
                                                              legalEntityId: jointdataController.dataControllerId!,
                                                              name: "",
                                                              isGroupEntity: selectedJointDataController?.isCustomerOwned,
                                                          });
                                                          setShowEditDocumentDialog(true);
                                                      }
                                                    : undefined
                                            }
                                            singleSelect={!hasDocuments}
                                            isLoading={documentsLoading}
                                            stepType={StepTypeEnum.jointDataControllers}
                                            onAddedAgreement={(agreementId) => onAddAgreement(agreementId, jointdataController.id!)}
                                            onDeletedAgreement={(agreementId) => onDeleteAgreement(agreementId, jointdataController.id!)}
                                        />
                                    )}

                                    {jointDataControllerPermissions.hasResponsiblePermission && (
                                        <DotLegalSelect
                                            selectedItem={jointdataController.responsible}
                                            isLoading={responsibleLoading}
                                            label={translateString("responsible")}
                                            placeholder={translateString("responsible")}
                                            onChange={(responsibleId) => props.onResponsibleChange(responsibleId, jointdataController.id!)}
                                            options={responsibleData(jointdataController.responsible)}
                                            toolTipText={translateString("sourceResponsibleHelperText")}
                                            errorText={props.validationErrors.find((x) => x.field === `${index}inactiveUser`)?.error}
                                            disabled={isReadOnly}
                                            link={
                                                jointdataController.responsible && permissions.canManageUsers
                                                    ? getUserUrl(jointdataController.responsible!)
                                                    : undefined
                                            }
                                            noOptionsLabel={translateString("noOptions")}
                                        />
                                    )}
                                </SegmentStep>

                                {jointDataControllerPermissions.hasDataCategoriesPermission && (
                                    <React.Fragment>
                                        <DotLegalStepHeader
                                            toolTipText={translateString("dataTypeSentHelperText")}
                                            record={false}
                                            subHeader={translateString("noDataCategoriesSelectedProcess")}
                                            topMargin
                                            bottomMargin
                                        >
                                            {translateString("jointDataControllerDataSent")}
                                        </DotLegalStepHeader>

                                        {dataCategoriesData && (
                                            <DotLegalPersonalDataSelector
                                                activityDataCategories={mapToDataCategorySelectorModel(
                                                    dataCategoriesData!,
                                                    props.processingActivity.dataCategories,
                                                    jointdataController.dataCategories
                                                )}
                                                onChange={(data) => handleChosenDataCategories(data, jointdataController.id!)}
                                                disabled={isReadOnly}
                                                errorText={props.validationErrors.find((v) => v.field === `${index}dataCategories`)?.error}
                                            />
                                        )}
                                    </React.Fragment>
                                )}

                                <SegmentStep size="large">
                                    <DotLegalStepHeader record={false} subHeader={translateString("entityChosenOnAssociation")} topMargin bottomMargin>
                                        {translateString("companiesAsJointDataControllers")}
                                    </DotLegalStepHeader>

                                    <LegalEntityTable
                                        disabled={isReadOnly}
                                        errorText={props.validationErrors.find((v) => v.field === `${index}companies`)?.error}
                                        isLoading={groupEntityLoading}
                                        legalEntities={getGroupEntitiesForTable()}
                                        selectedEntities={jointdataController.legalEntities}
                                        showAddNewEntityButton={false}
                                        showAddAllEntityButton
                                        onLegalEntitiesChange={(entities) => props.onLegalEntitiesChange(entities, jointdataController.id!)}
                                    />
                                </SegmentStep>
                            </DotLegalCollapse>
                        </Box>
                    );
                })}

                <Box sx={styles.addContainer}>
                    <DotLegalButton
                        disabled={props.processingActivity.disableJointDataControllers || props.readOnly}
                        buttonType="secondary"
                        isLoading={props.isAddingJointDataController}
                        onClick={() => handleAddJointDataControllerClick(Guid.newGuid())}
                    >
                        {props.jointDataControllers.length > 0 ? translateString("addAnotherJointDataController") : translateString("addJointDataController")}
                    </DotLegalButton>
                </Box>

                {!props.processingActivity.hasJointDataControllers && (
                    <Box sx={styles.disable}>
                        <Box id={"disable-joint-data-controllers"}>
                            <DotLegalCheckbox
                                disabled={props.readOnly}
                                checked={props.processingActivity.disableJointDataControllers}
                                onChange={props.onDisableClick}
                                label={translateString("disableJointDataControllers")}
                            />
                            {props.validationErrors.some((e) => e.field === "noDataSharingsJointDataControllers") && (
                                <Box sx={styles.error}>{props.validationErrors.find((e) => e.field === "noDataSharingsJointDataControllers")?.error}</Box>
                            )}
                        </Box>
                    </Box>
                )}

                {showEditDocumentDialog && (
                    <EditLegalEntityDocumentDialog
                        onCloseDialog={() => {
                            setShowEditDocumentDialog(false);
                        }}
                        selectedDocument={selectedDocument!}
                        isExtended={false}
                        onSave={() => refetchDocuments()}
                        stepType={StepTypeEnum.jointDataControllers}
                    />
                )}
            </React.Fragment>
        </SegmentStep>
    );
}

export default JointDataControllerStep;
