import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { useMutation } from "react-query";
import { put } from "../../common/api/apiShared";
import { IToggleInternalAssessmentRemindersDialog } from "./ToggleInternalAssessmentRemindersDialog";

export default function useToogleInternalAssessmentRemindersDialogHooks(props: IToggleInternalAssessmentRemindersDialog) {
    const { translateString } = useTranslation();
    const snackbar = useDotLegalSnackbar();

    const pauseRemindersMutation = useMutation(pauseRemindersAPI, {
        onSuccess: () => {
            snackbar.show(translateString("remindersPaused"));
            props.onInternalAssessmentPaused();
        },
    });

    const resumeRemindersMutation = useMutation(resumeRemindersAPI, {
        onSuccess: () => {
            snackbar.show(translateString("remindersResumed"));
            props.onInternalAssessmentPaused();
        },
    });

    const onPauseClick = () => {
        if (props.internalAssessment.remindersPaused) {
            resumeRemindersMutation.mutateAsync();
        } else {
            pauseRemindersMutation.mutateAsync();
        }
    };

    return { onPauseClick, isSaving: pauseRemindersMutation.isLoading || resumeRemindersMutation.isLoading };

    function pauseRemindersAPI() {
        return put(`/internalAssessments/${props.internalAssessment.id}/pauseReminders`, null);
    }

    function resumeRemindersAPI() {
        return put(`/internalAssessments/${props.internalAssessment.id}/resumeReminders`, null);
    }
}
