import { DotLegalBreadCrumbModel, DotLegalFixedHeightText, DotLegalHeading, DotLegalPaper } from "@dotlegal/dotlegal-ui-components";
import React from "react";
import { useTranslation } from "../../localization/useTranslation";
import { useUrlProvider } from "../../useUrlProvider";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { Grid, GridSize } from "@mui/material";
import useLegalEntityAuditGroupDraftOverviewHooks from "./LegalEntityAuditGroupDraftOverview.hooks";
import AuditGroupLegalEntitySelector from "./auditGroupLegalEntitySelector/AuditGroupLegalEntitySelector";
import AuditGroupHeader from "../legalEntityAuditGroupOverview/auditGroupHeader/AuditGroupHeader";
import QuestionnaireTemplateLink from "../../legalEntity/legalEntityAuditTab/QuestionnaireTemplateLink.tsx";
import DotLegalLink from "../../common/components/dotLegalLink/DotLegalLink.tsx";
import { usePlanContext } from "../../auth/planProvider/PlanProvider.tsx";

function LegalEntityAuditGroupDraftOverview() {
    const { customerName } = useUserContext();
    const { translateString, translateDate } = useTranslation();
    const { getAuditGroupsUrl, getAuditGroupUrl, getGroupEntityUrl } = useUrlProvider();
    const { vendorManagementFeatures } = usePlanContext();

    const { id, data, isLoading, onAuditGroupUpdated, updateSelectedLegalEntities, refetch } = useLegalEntityAuditGroupDraftOverviewHooks();

    const gridProps: { item: boolean; xs: GridSize; sm: GridSize; md: GridSize | boolean } = {
        item: true,
        xs: 12,
        sm: 6,
        md: 4,
    };

    const getBreadCrumbs = () => {
        let breadCrumbs: Array<DotLegalBreadCrumbModel> = [];

        breadCrumbs.push({ name: translateString("externalAudits"), link: getAuditGroupsUrl() });
        breadCrumbs.push({ name: data?.name, link: data?.sent ? getAuditGroupUrl(id!) : undefined, tooltip: data?.description });

        if (data && data.sent !== null) {
            breadCrumbs.push({ name: translateString("addLegalEntity") });
        }

        return breadCrumbs;
    };

    return (
        <React.Fragment>
            <AuditGroupHeader
                breadCrumbs={getBreadCrumbs()}
                customerName={customerName}
                onAuditGroupUpdated={onAuditGroupUpdated}
                auditGroupModel={
                    data
                        ? {
                              id: data.id,
                              auditTemplateId: data!.auditTemplateId,
                              auditTemplateName: data.auditTemplate,
                              auditTemplateVersion: data.auditTemplateVersion,
                              deadline: data.deadline,
                              name: data.name,
                              responsibleId: data.responsibleId,
                              description: data.description,
                              senderId: data.senderId,
                              sender: data.sender,
                              sent: data.sent,
                              questionnaireIntroductionTitle: data.questionnaireIntroductionTitle,
                          }
                        : undefined
                }
                showAddLegalEntityBtn={false}
                canDeleteAuditgroup={data !== undefined && data.sent === null}
                showDeleteMenuItem={data !== undefined && data.sent === null}
            />
            <DotLegalPaper sx={{ marginBottom: 2, minHeight: 132 }} background="smallPurpleWave" backgroundCover>
                <Grid container>
                    <Grid {...gridProps} lg>
                        <DotLegalHeading name={translateString("status")}>
                            <DotLegalFixedHeightText
                                isLoading={isLoading}
                                fontSize="xs"
                                content={data?.sent ? translateDate(data.sent) : translateString("draft")}
                            />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...gridProps} lg>
                        <DotLegalHeading name={translateString("auditTemplate")}>
                            <DotLegalFixedHeightText
                                isLoading={isLoading}
                                fontSize="xs"
                                content={
                                    data ? (
                                        vendorManagementFeatures.auditGroups ? (
                                            <QuestionnaireTemplateLink id={data.auditTemplateId} name={data.auditTemplate} type={"audit"} />
                                        ) : (
                                            data.auditTemplate
                                        )
                                    ) : (
                                        ""
                                    )
                                }
                            />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...gridProps} lg>
                        <DotLegalHeading name={translateString("deadline")}>
                            <DotLegalFixedHeightText isLoading={isLoading} fontSize="xs" content={data ? translateDate(data.deadline) : ""} />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...gridProps} lg>
                        <DotLegalHeading name={translateString("responsible")}>
                            <DotLegalFixedHeightText isLoading={isLoading} fontSize="xs" content={data ? data.responsible : ""} />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...gridProps} lg>
                        <DotLegalHeading name={translateString("sentFrom")}>
                            <DotLegalFixedHeightText
                                isLoading={isLoading}
                                fontSize="xs"
                                content={
                                    data ? (
                                        data.sender ? (
                                            <DotLegalLink bold linkColor={"primary"} to={getGroupEntityUrl(data.senderId)}>
                                                {data.sender}
                                            </DotLegalLink>
                                        ) : (
                                            customerName
                                        )
                                    ) : (
                                        ""
                                    )
                                }
                            />
                        </DotLegalHeading>
                    </Grid>
                </Grid>
            </DotLegalPaper>

            <AuditGroupLegalEntitySelector
                selectedEntities={data?.selectedEntities ?? []}
                onSelectedEntitiesUpdated={updateSelectedLegalEntities}
                selectedEntititesIsLoading={isLoading}
                rows={data?.rows ?? []}
                onContactCreated={() => refetch()}
                auditGroupId={data?.id}
                auditGroupName={data?.name ?? ""}
                isDeadlineOverdue={data?.isDeadlineOverdue ?? false}
            />
        </React.Fragment>
    );
}

export default LegalEntityAuditGroupDraftOverview;
