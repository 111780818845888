import React from "react";
import { useTranslation } from "../../localization/useTranslation";
import { useUrlProvider } from "../../useUrlProvider";
import { useProcessingActivityOverviewDialogStyles } from "../../processingActivity/processingActivityOverviewDialog/ProcessingActivityOverviewDialog.styles";
import { DotLegalDialog, DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import { Box, Typography } from "@mui/material";
import DotLegalLink from "../../common/components/dotLegalLink/DotLegalLink";
import { SelectableItem } from "../../processingActivity/ProcessingActivity.types";

export interface SystemOverviewDialogProps {
    onCloseDialog: () => void;
    systems: Array<SelectableItem> | undefined;
    header: string;
    numberOfSystemsWithoutaccess?: number | null;
}

function SystemOverviewDialog(props: SystemOverviewDialogProps) {
    const { translateString } = useTranslation();
    const { getSystemOverviewUrl } = useUrlProvider();
    const styles = useProcessingActivityOverviewDialogStyles();
    return (
        <DotLegalDialog
            header={translateString("systems")}
            buttonOkText={""}
            hideOkButton
            open
            onDialogClose={() => props.onCloseDialog()}
            onOkClick={() => {}}
            size="sm"
        >
            <Typography sx={styles.header}>{props.header}</Typography>

            <Box sx={{ marginTop: 3 }}>
                {props.systems?.map((system) => {
                    return (
                        <Box key={system.id} sx={styles.baContainer}>
                            <DotLegalHeader headerStyle={"extraSmall"} toolTip={system.description} fontWeight={400}>
                                <DotLegalLink to={getSystemOverviewUrl(system.id)}>
                                    <Box sx={styles.link}>{system.name}</Box>
                                </DotLegalLink>
                            </DotLegalHeader>
                        </Box>
                    );
                })}
                {props.numberOfSystemsWithoutaccess && (
                    <Box sx={styles.baContainer}>
                        <DotLegalHeader headerStyle={"extraSmall"} fontWeight={400}>
                            {translateString("noOfSystemsWithoutAccess", { count: props.numberOfSystemsWithoutaccess })}
                        </DotLegalHeader>
                    </Box>
                )}
            </Box>
        </DotLegalDialog>
    );
}

export default SystemOverviewDialog;
