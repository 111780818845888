import { createElement } from "react";
import { Trans } from "react-i18next";
import { useMutation } from "react-query";
import { put } from "../../common/api/apiShared";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { IChangeActiveStatusDialog } from "./ChangeActiveStatusDialog";

export function ChangeActiveStatusHooks(props: IChangeActiveStatusDialog) {
    const snackbar = useDotLegalSnackbar();

    const changeActiveStatusMutation = useMutation(changeStatus);
    const changeActiveStatus = () => {
        changeActiveStatusMutation.mutateAsync(undefined, {
            onSuccess: (resp: any) => {
                const editResponse = resp.value();
                if (editResponse.success) {
                    snackbar.show(createElement(Trans, { i18nKey: "activeStatusChanged", values: { name: editResponse.name } }));
                    props.onSaveCorrect();
                    props.onCloseDialog();
                }
            },
        });
    };

    function changeStatus() {
        return put<{}>(`/user/${props.userId}/changeActiveStatus`, !props.activeStatus);
    }

    return {
        changeActiveStatus,
        isSaving: changeActiveStatusMutation.isLoading,
    };
}
