import { useQuery } from "react-query";
import { get } from "../../../common/api/apiShared.ts";
import { PublicInternalAssessmentInitiationViewModel } from "../PublicInternalAssessmentTemplates.types.ts";
import { useParams } from "react-router-dom";

export function usePublicInternalAssessmentContainer() {
    const { externalId } = useParams<{ externalId: string }>();
    const url = `/publicInternalAssessmentInitiation/${externalId}`;
    let { isLoading, data } = useQuery(url, () => get<PublicInternalAssessmentInitiationViewModel>(url));

    return { isLoading, data };
}
