import { useState } from "react";
import { useQueryClient } from "react-query";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import { ISharingsLegalEntity } from "./sharingsLegalEntity";
import { ToggleCompanyLink } from "./sharingsLegalEntity.types";

export function useSharingsLegalEntityHook(props: ISharingsLegalEntity) {
    const [showAddLegalEntityDialog, setShowAddLegalEntityDialog] = useState(false);
    const [showStorageLocationDialog, setShowStorageLocationDialog] = useState(false);
    const { permissions } = useUserContext();
    const queryClient = useQueryClient();

    const addLegalEntity = (id: string) => {
        props.onLegalEntityChange(id);
        queryClient.refetchQueries("legalEntities");
    };

    const toggleCompanyLink = () => {
        let showCompanyLink = false;
        let showGroupCompanyLink = false;
        let companyId = "";

        if (props.selectedItem) {
            let company = props.options?.find((x) => x.id === props.selectedItem);

            if (company) {
                companyId = company.id;
                showGroupCompanyLink = company.isCustomerOwned && permissions.canManageGroupEntities;
                showCompanyLink = !company.isCustomerOwned && permissions.companyPermissions.read;
            }
        }

        return new ToggleCompanyLink(companyId, showGroupCompanyLink, showCompanyLink);
    };

    return {
        showAddLegalEntityDialog,
        setShowAddLegalEntityDialog,
        addLegalEntity,
        toggleCompanyLink: toggleCompanyLink(),
        showStorageLocationDialog,
        setShowStorageLocationDialog,
    };
}
