import { Grid } from "@mui/material";
import React from "react";

export interface TwoColumnContainerProps {
    children: React.ReactNode;
}

function TwoColumnContainer(props: TwoColumnContainerProps) {
    return (
        <Grid container sx={{ flex: 1 }}>
            {props.children}
        </Grid>
    );
}

export default TwoColumnContainer;
