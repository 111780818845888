import { CSSObject, useTheme } from "@mui/material";
import { INavMenuProps } from "./NavMenu";
import { createSxStyles } from "../../common/createSxStyles";

export const topBarHeight = 82;
export const scrollbarWidth = 10;
export const useNavMenuStyles = (props: INavMenuProps) => {
    const theme = useTheme();
    return createSxStyles({
        list: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingBottom: theme.spacing(1),
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
        },
        navList: {
            listStyleType: "none",
            padding: 0,
            margin: 0,
        },
        logo: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: 2,
            alignSelf: "center",
            marginTop: 2,
        },
        innerContainer: {
            height: `calc(100% - ${topBarHeight}px)`,
            display: "inline-grid",
            transition: "none",
        },
        languageContainer: {
            color: theme.palette.text.secondary,
            padding: theme.spacing(2),
            display: "flex",
        },
        languageList: {
            marginLeft: theme.spacing(2),
        },
        splitter: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        language: {
            cursor: "pointer",
            "&:hover": {
                color: theme.palette.text.primary,
                backgroundColor: theme.customPalette.white,
            },
        },
        activeLanguage: {
            color: theme.palette.text.primary,
        },
        menuIcon: {
            display: "inline-block",
            verticalAlign: "bottom",
            "& svg": {
                width: theme.customSpacing.menuIconSize,
                height: theme.customSpacing.menuIconSize,
                fill: "#DAD9EF",
            },
        },
        expandButtonLogo: {
            height: 40,
            color: theme.palette.primary.main,
            fontsize: theme.typography.pxToRem(50),
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(2),
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(1),
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            "& h1": {
                margin: 0,
            },
            "& a": {
                textDecoration: "none",
                "&:visited": {
                    color: "inherit",
                },
                "&:active": {
                    color: "inherit",
                },
            },
        },
        expandButton: {
            backgroundColor: theme.customPalette.white,
            borderRadius: "50%",
            width: 30,
            minWidth: 30,
            height: 30,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            transition: theme.transitions.create("left", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            "&:hover": {
                borderColor: theme.palette.primary.main,
                cursor: "pointer",
                borderWidth: 1,
                borderStyle: "solid",
            },
            "& svg": {
                width: 14,
                height: 14,
                stroke: theme.palette.primary.main,
            } as CSSObject,
        },
    });
};
