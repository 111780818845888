import React from "react";
import DotLegalDeleteDialogWrapper from "../../common/components/dotLegalDeleteDialog/DotLegalDeleteDialogWrapper";
import { NotificationViewModel } from "../Notification.types";
import { useDeleteNotificationDialog } from "./DeleteNotificationDialog.hooks";

export interface IDeleteNotificationDialog {
    onCloseDialog: () => void;
    onSaveCorrect: () => void;
    notification: NotificationViewModel;
}

function DeleteNotificationDialog(props: IDeleteNotificationDialog) {
    const { removeNotification, inProgess, getNotificationItem } = useDeleteNotificationDialog(props);

    return (
        <DotLegalDeleteDialogWrapper
            open
            itemForDeletion={getNotificationItem()}
            onDialogClose={props.onCloseDialog}
            onOkClick={removeNotification}
            inProgress={inProgess}
        />
    );
}

export default DeleteNotificationDialog;
