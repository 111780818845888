import { DotLegalDialog, DotLegalHeader, DotLegalMultiSelect } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../../localization/useTranslation";
import { Box } from "@mui/material";
import { Trans } from "react-i18next";
import AssociatedInternalAssessmentDialog from "../../../../common/components/questionnaireComponents/associatedInternalAssessmentDialog/AssociatedInternalAssessmentDialog";
import useSystemAssociatedInternalAssessmentDialogHooks from "./SystemAssociatedInternalAssessmentDialog.hooks";

export interface ISystemInternalAssessmentDialogProps {
    onDialogClose: () => void;
    systemName: string;
    systemId: string;
    existingInternalAssessments: Array<string>;
}

function ProcessingActivityAssociatedInternalAssessmentDialog(props: ISystemInternalAssessmentDialogProps) {
    const { translateString } = useTranslation();
    const { addAssociatedInternalAssessment, removeAssociatedInternalAssessment, selectedInternalAssessments } =
        useSystemAssociatedInternalAssessmentDialogHooks(props);
    return (
        <AssociatedInternalAssessmentDialog
            onDialogClose={props.onDialogClose}
            name={props.systemName}
            dialogHeader={translateString("associateAssessmentToSystem")}
            dialogInfo={
                <Trans
                    i18nKey="associateAssessmentsToSystem"
                    values={{ name: props.systemName }}
                    components={{
                        wrapper: (
                            <Box sx={(theme) => ({ color: theme.palette.primary.main, fontWeight: theme.typography.fontWeightBold })} component="span"></Box>
                        ),
                    }}
                />
            }
            chosenOptions={selectedInternalAssessments}
            onAddInternalAssessment={addAssociatedInternalAssessment}
            onDeleteInternalAssessment={removeAssociatedInternalAssessment}
            existingInternalAssessments={props.existingInternalAssessments}
        />
    );
}

export default ProcessingActivityAssociatedInternalAssessmentDialog;
