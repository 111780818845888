import React from "react";
import { LegalEntityPageEnum } from "./legalEntities/LegalEntities.types.ts";
import MicroFrontend from "../microFrontend/MicroFrontend.tsx";
import { Route, Routes, useParams } from "react-router-dom";
import { useUrlProvider } from "../useUrlProvider.ts";
import { DotLegalPageHeader } from "@dotlegal/dotlegal-ui-components";
import { useLegalEntityBreadCrumbs } from "./useLegalEntityBreadCrumbs.ts";
import { useQuery } from "react-query";
import { get } from "../common/api/apiShared.ts";
import { LegalEntityManagementModel } from "./legalEntityManagement/LegalEntityManagement.types.ts";
import { useUserContext } from "../auth/userContextProvider/UserContextProvider.tsx";
import { useTranslation } from "../localization/useTranslation.ts";

export interface ILegalEntityClassificationProps {
    legalEntityPage: LegalEntityPageEnum;
}

function LegalEntityClassification(props: ILegalEntityClassificationProps) {
    const { translateString } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const url = `/legalEntity/${id}/light`;
    const { customerName } = useUserContext();
    let { data } = useQuery(url, () => get<LegalEntityManagementModel>(url));
    let breadCrumbs = useLegalEntityBreadCrumbs(props.legalEntityPage, { name: data?.name ?? "", id: data?.id ?? "" });
    breadCrumbs = [...breadCrumbs, { name: translateString("classification") }];
    return (
        <>
            <DotLegalPageHeader userContext={{ customerName: customerName }} breadCrumbs={breadCrumbs} />
            <MicroFrontend />
        </>
    );
}

export default LegalEntityClassification;
