import React from "react";
import { Trans } from "react-i18next";
import { useTranslation } from "../../../localization/useTranslation";
import { DataSubjectSaveModel } from "../DataSubject.types";
import { useChangeDataSubjectStatusDialogDataMapping } from "./ChangeDataSubjectStatusDialog.hooks";
import { DotLegalInfoDialog } from "@dotlegal/dotlegal-ui-components";

export interface IChangeDataSubjectStatusDialogDataMapping {
    selectedDataSubject: DataSubjectSaveModel;
    onCloseDialog: () => void;
    refetchDataSubjects: () => void;
}

export function ChangeDataSubjectStatusDialog(props: IChangeDataSubjectStatusDialogDataMapping) {
    const { changeActiveStatus, inProgress } = useChangeDataSubjectStatusDialogDataMapping(props);
    const { translateString } = useTranslation();

    return (
        <DotLegalInfoDialog
            btnText={props.selectedDataSubject.active ? translateString("deactivate") : translateString("activate")}
            header={props.selectedDataSubject.active ? translateString("deactivateDataSubjectHeader") : translateString("acitivateDataSubjectHeader")}
            inProgress={inProgress}
            content={
                props.selectedDataSubject.active ? (
                    <Trans i18nKey="deactivateDataInfo" values={{ name: props.selectedDataSubject.name }}></Trans>
                ) : (
                    <Trans i18nKey="activateDataInfo" values={{ name: props.selectedDataSubject.name }}></Trans>
                )
            }
            onBtnClick={() => changeActiveStatus(props.selectedDataSubject)}
            onCloseDialog={props.onCloseDialog}
        />
    );
}
