import { useStateLocalStorage } from "@dotlegal/dotlegal-ui-components";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { LatestAuditsSubmittedWidgetProps } from "./LatestAuditsSubmittedWidget";
import { useQuery } from "react-query";
import { get } from "../../common/api/apiShared";
import { LatestInternalAssessmentSubmissionViewModel, LatestLegalEntityAuditSubmissionViewModel } from "./LatestAuditsSubmittedWidget.types";

export function useLatestAuditsSubmittedWidget(props: LatestAuditsSubmittedWidgetProps) {
    const { permissions } = useUserContext();
    const { hasVendorManagementAccess } = usePlanContext();

    const isAuditsEnabled = hasVendorManagementAccess && permissions.canManageAuditGroups;
    const isInternalAssessmentsEnabled = hasVendorManagementAccess && permissions.canManageInternalAssessment;

    const defaultAssessmentsToShow = isAuditsEnabled ? false : isInternalAssessmentsEnabled;

    const localStorageKey = "dashboard-latest-submissions";
    const storageValue = localStorage.getItem(localStorageKey);
    if (
        (storageValue === "false" && !isAuditsEnabled) ||
        (!defaultAssessmentsToShow && !isAuditsEnabled) ||
        (defaultAssessmentsToShow && !isInternalAssessmentsEnabled) ||
        (storageValue === "true" && !isInternalAssessmentsEnabled)
    ) {
        localStorage.removeItem(localStorageKey);
    }

    const disableSwitch = (!defaultAssessmentsToShow && !isInternalAssessmentsEnabled) || (defaultAssessmentsToShow && !isAuditsEnabled);
    const [showInternalAssessments, setShowInternalAssessments] = useStateLocalStorage(localStorageKey, defaultAssessmentsToShow);

    const latestAuditsQuery = useQuery(
        `audits${props.onlyUserSpecific}`,
        () =>
            get<Array<LatestLegalEntityAuditSubmissionViewModel>>(
                `/legalEntityAudit/dashboard/latestSubmissions?onlyUserSpecific=${props.onlyUserSpecific}&forGroupEntity=${props.selectedGroupCompany}`
            ),
        { enabled: isAuditsEnabled }
    );

    const latestAssessmentsQuery = useQuery(
        `assessments${props.onlyUserSpecific}`,
        () =>
            get<Array<LatestInternalAssessmentSubmissionViewModel>>(
                `/internalAssessments/dashboard/latestSubmissions?onlyUserSpecific=${props.onlyUserSpecific}&forGroupEntity=${props.selectedGroupCompany}`
            ),
        { enabled: isInternalAssessmentsEnabled }
    );

    return {
        isLoading: latestAuditsQuery.isLoading || latestAssessmentsQuery.isLoading,
        isAuditsEnabled,
        isInternalAssessmentsEnabled,
        hasVendorManagementAccess,
        showInternalAssessments,
        setShowInternalAssessments,
        latestAssessments: latestAssessmentsQuery.data ?? [],
        latestAudits: latestAuditsQuery.data ?? [],
        disableSwitch,
    };
}
