import { useMutation } from "react-query";
import { deleteHttp } from "../../common/api/apiShared";
import { DeleteDeclarationDialogProps } from "./DeleteDeclarationDialog";
import { useNavigate } from "react-router-dom";
import { useUrlProvider } from "../../useUrlProvider";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { createElement } from "react";
import { Trans } from "react-i18next";

export function useDeleteDeclarationDialog(props: DeleteDeclarationDialogProps) {
    const snackbar = useDotLegalSnackbar();
    const { getDeclarationsUrl } = useUrlProvider();
    const navigate = useNavigate();

    const deleteDeclarationMutation = useMutation(deleteDeclarationUrl);
    const onDeleteDeclaration = async () => {
        await deleteDeclarationMutation.mutateAsync(undefined, {
            onSuccess: (result) => {
                navigate(getDeclarationsUrl());
                snackbar.show(createElement(Trans, { i18nKey: "itemDeletedSuccess", values: { name: props.name } }));
            },
        });
    };

    return { onDeleteDeclaration, isDeleting: deleteDeclarationMutation.isLoading };

    function deleteDeclarationUrl() {
        return deleteHttp(`/declaration/${props.id}`);
    }
}
