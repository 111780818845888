import { Box, Grid, Tooltip } from "@mui/material";
import DotLegalCollapse from "../../common/components/dotLegalCollapse/DotLegalCollapse";
import { useTranslation } from "../../localization/useTranslation";
import { useRiskAssessmentAnswerCollapseHook } from "./RiskAssessmentAnswerCollapse.hooks";
import { useRiskAssessmentAnswerCollapseStyles } from "./RiskAssessmentAnswerCollapse.styles";
import { highRiskIcon, lowRiskIcon, moderateRiskIcon, reallyHighRiskIcon, reallyLowRiskIcon } from "../riskIndicator/riskIcons";
import { excludedIcon } from "../../common/icons";
import { overriddenRisk } from "../Images";
import React from "react";

interface CollapseData {
    icon: JSX.Element;
    iconHoverText: string;
}

export interface IRiskAssessmentAnswerCollapseProps {
    componentKey: string;
    header: JSX.Element;
    isScoreOverrridden: boolean;
    score: number;
    isPDF?: boolean;
    children?: any;
    error?: boolean;
    isExcluded: boolean;
}

function RiskAssessmentAnswerCollapse(props: IRiskAssessmentAnswerCollapseProps) {
    const { translateString } = useTranslation();
    const styles = useRiskAssessmentAnswerCollapseStyles();
    const { handleCollapseClick, getExpandedId } = useRiskAssessmentAnswerCollapseHook();

    const getRiskIconAndHover = (): CollapseData => {
        if (props.isExcluded) {
            return { icon: excludedIcon, iconHoverText: translateString("excludedRiskScenario") };
        }

        switch (props.score) {
            case 1:
                return { icon: reallyLowRiskIcon, iconHoverText: translateString("veryLow") };
            case 2:
                return { icon: lowRiskIcon, iconHoverText: translateString("low") };
            case 3:
                return { icon: moderateRiskIcon, iconHoverText: translateString("moderate") };
            case 4:
                return { icon: highRiskIcon, iconHoverText: translateString("high") };
            case 5:
                return { icon: reallyHighRiskIcon, iconHoverText: translateString("veryHigh") };
            default:
                return { icon: <></>, iconHoverText: "" };
        }
    };

    const getScoreIsOverrridenIcon = () => {
        if (props.isScoreOverrridden) {
            return (
                <Grid item sx={styles.scoreOverridden}>
                    <Tooltip title={translateString("processingActivityRiskAssessmentOverridden")}>{overriddenRisk}</Tooltip>
                </Grid>
            );
        }
    };

    const getCollapseIcon = (): CollapseData => {
        const iconInformation = getRiskIconAndHover();
        return {
            icon: (
                <Grid container>
                    {getScoreIsOverrridenIcon()}
                    <Grid item sx={styles.highestScore}>
                        <Tooltip title={iconInformation.iconHoverText}>{iconInformation.icon}</Tooltip>
                    </Grid>
                </Grid>
            ),
            iconHoverText: "",
        };
    };

    return (
        <DotLegalCollapse
            isExpanded={props.isPDF ? true : props.componentKey === getExpandedId()}
            onExpandClick={() => handleCollapseClick(props.componentKey)}
            centerContent=""
            sx={styles.collapse}
            collapseName={props.header}
            persistCollapseName
            hideCollapseCenterName
            collapseIcon={getCollapseIcon()}
            error={props.isPDF ? false : props.error}
            collapseStyle={"secondary"}
            headerColor={props.isExcluded ? "grey" : "primary"}
        >
            <Box sx={styles.collapseContent} className={`pdfCollapseContent`}>
                {props.children}
            </Box>
        </DotLegalCollapse>
    );
}

export default RiskAssessmentAnswerCollapse;
