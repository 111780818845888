import React from "react";
import { Trans } from "react-i18next";
import { useTranslation } from "../../../localization/useTranslation";
import { useChangeNationalLawStatusDialogDataMapping } from "./ChangeNationalLawDialog.hooks";
import { DotLegalInfoDialog } from "@dotlegal/dotlegal-ui-components";
import { NationalLawViewModel } from "../nationalLaws/NationalLaws.types";

export interface IChangeNationalLawStatusDialog {
    selectedNationalLaw: NationalLawViewModel;
    onCloseDialog: () => void;
    onStatusChange: () => void;
}

export function ChangeNationalLawStatusDialog(props: IChangeNationalLawStatusDialog) {
    const { changeActiveStatus, inProgress } = useChangeNationalLawStatusDialogDataMapping(props);
    const { translateString } = useTranslation();

    return (
        <DotLegalInfoDialog
            btnText={props.selectedNationalLaw.active ? translateString("deactivate") : translateString("activate")}
            header={props.selectedNationalLaw.active ? translateString("deactivateNationalLawHeader") : translateString("activateNationalLawHeader")}
            inProgress={inProgress}
            content={
                props.selectedNationalLaw.active ? (
                    <Trans i18nKey="deactivateDataInfo" values={{ name: props.selectedNationalLaw.name }}></Trans>
                ) : (
                    <Trans i18nKey="activateDataInfo" values={{ name: props.selectedNationalLaw.name }}></Trans>
                )
            }
            onBtnClick={() => changeActiveStatus(props.selectedNationalLaw)}
            onCloseDialog={props.onCloseDialog}
        />
    );
}
