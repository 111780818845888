import React from "react";
import { DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Paper, Skeleton, SxProps, Theme } from "@mui/material";
import { useAnnualYearPickerStyles } from "./AnnualWheelYearPicker.styles";

export interface IAnnualWheelYearPickerProps {
    isLoading?: boolean;
    onChange: (year: number) => void;
    showPreviousYear?: boolean;
    year: number;
    className?: any;
    sx?: SxProps<Theme>;
}

function AnnualWheelYearPicker(props: IAnnualWheelYearPickerProps) {
    const styles = useAnnualYearPickerStyles();

    const getMinDate = () => {
        var minDate = new Date().getFullYear();

        if (props.showPreviousYear) minDate = minDate - 1;

        return minDate;
    };
    const getMaxDate = () => {
        return new Date().getFullYear() + 2;
    };

    return (
        <React.Fragment>
            {!props.isLoading ? (
                <Paper sx={{ ...styles.paper, ...props.sx }} className={`${props.className}`}>
                    <NavigateBeforeIcon
                        onClick={() => {
                            if (props.year !== getMinDate()) props.onChange(props.year - 1);
                        }}
                    />

                    <DotLegalHeader marginBottom={0} headerStyle="small">
                        {props.year}
                    </DotLegalHeader>

                    <NavigateNextIcon
                        onClick={() => {
                            if (props.year !== getMaxDate()) props.onChange(props.year + 1);
                        }}
                    />
                </Paper>
            ) : (
                <Skeleton variant="rectangular" sx={styles.skeleton} />
            )}
        </React.Fragment>
    );
}

export default AnnualWheelYearPicker;
