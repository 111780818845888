import { DotLegalDialog, DotLegalHeader, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { Box } from "@mui/material";
import useCustomizeIntroductionEmailDialogHooks from "./CustomizeIntroductionEmailDialog.hooks";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";

export interface ICustomizeIntroductionEmailDialog {
    onDialogClose: () => void;
}

function CustomizeIntroductionEmailDialog(props: ICustomizeIntroductionEmailDialog) {
    const { emailAddress } = useUserContext();
    const { translateString } = useTranslation();
    const { introductionText, isLoading, onSendEmailClick, sendingEmail, onIntroductionChange, onSaveClick, isSavingIntroduction } =
        useCustomizeIntroductionEmailDialogHooks(props);

    return (
        <DotLegalDialog
            header={translateString("customizeIntroductionEmails")}
            onDialogClose={props.onDialogClose}
            size="md"
            onOkClick={onSaveClick}
            buttonOkText={translateString("save")}
            open={location.search.includes("customizeIntroductionEmails")}
            inProgress={isSavingIntroduction}
            secondaryButtonHoverText={emailAddress}
            secondaryButtonText={translateString("sendTestEmail")}
            onSecondaryButtonClick={() => onSendEmailClick()}
            secondaryButtonLoading={sendingEmail}
        >
            <>
                <Box ml={2} mr={2}>
                    <DotLegalHeader fontWeight={400} headerStyle="small" center>
                        {translateString("customizeIntroductionEmailsInfo")}
                    </DotLegalHeader>
                    <DotLegalTextField
                        isLoading={isLoading}
                        multiline
                        rows={5}
                        label={translateString("freeTextField")}
                        value={introductionText ?? null}
                        debounce
                        onChange={onIntroductionChange}
                    />
                </Box>
            </>
        </DotLegalDialog>
    );
}

export default CustomizeIntroductionEmailDialog;
