export interface SecurityMeasureViewModel {
    id: string;
    name: string;
    description: string;
    securityMeasureType: SecurityMeasureType;
    isGlobal: boolean;
    isActive: boolean;
    isCustom: boolean;
    businessAreaIds: Array<string>;
    groupEntitiesIds: Array<string>;
    hasAllBusinessAreas: boolean;
    hasAllGroupEntities: boolean;
}

export interface SecurityMeasureRowViewModel {
    id: string;
    name: string;
    description: string;
    securityMeasureType: SecurityMeasureType;
    isGlobal: boolean;
    isActive: boolean;
    isCustom: boolean;
    hasAllBusinessAreas: boolean;
    hasAllGroupEntities: boolean;
}

export interface BusinessAreaContainer {
    businessAreaIds: Array<string>;
    id: string;
}

export interface GroupEntityContainer {
    groupEntitiesIds: Array<string>;
    id: string;
}

export interface SecurityMeasureSaveModel {
    id: string;
    name: string;
    description: string;
    securityMeasureType: number | undefined;
    isGlobal: boolean;
    active: boolean;
    isCustom: boolean;
    businessAreaIds: Array<string>;
    groupEntitiesIds: Array<string>;
}

export enum SecurityMeasureType {
    Organizational,
    LogicalSecurity,
    PhysicalSecurity,
    Technical,
    Contractual,
}
