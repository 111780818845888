import { useTheme } from "@mui/material";
import { createSxStyles } from "../../../common/createSxStyles";

export const useDataSubjectSelectStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        container: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
        },
        tooltip: {
            marginTop: theme.spacing(2),
        },
    });
};
