import { createElement } from "react";
import { Trans } from "react-i18next";
import { useMutation } from "react-query";
import { deleteHttp } from "../../../common/api/apiShared";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { IDeleteCertificationDialog } from "./DeleteCertificationDialog";

export function useDeleteCertificationDialogHooks(props: IDeleteCertificationDialog) {
    const snackbar = useDotLegalSnackbar();

    const removeMutation = useMutation(remove);
    const removeCertification = () => {
        removeMutation.mutateAsync(undefined, {
            onSuccess: (response: any) => {
                props.onCloseDialog();

                if (response.success()) {
                    props.onDeleted();
                    snackbar.show(createElement(Trans, { i18nKey: "dataDeleted", values: { name: props.selectedCertification.name } }));
                } else {
                    snackbar.show(createElement(Trans, { i18nKey: "certificationNotDeleted", values: { name: props.selectedCertification.name } }), "warning");
                }
            },
        });
    };

    async function remove() {
        return await deleteHttp<{}>(`/certifications/${props.selectedCertification.id}`);
    }

    return {
        removeCertification,
        inProgess: removeMutation.isLoading,
    };
}
