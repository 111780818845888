import { isNullOrWhitespace } from "../../../common/stringOperations";
import { ValidationError } from "../../../common/validationError";
import { useTranslation } from "../../../localization/useTranslation";
import { SelectableItem } from "../../../processingActivity/ProcessingActivity.types";
import { SystemModel, SystemStatus } from "../SystemEdit.types";

export function useSystemStep() {
    const { translateString } = useTranslation();

    let systemTypeOptions: Array<SelectableItem> = [
        { id: SystemStatus.Active.toString(), name: translateString("active") },
        { id: SystemStatus.UnderImplementation.toString(), name: translateString("underImplementation") },
        { id: SystemStatus.PhasedOut.toString(), name: translateString("phasedOut") },
    ];

    return { systemTypeOptions };
}

export function useValidateSystemStep() {
    const { translateString } = useTranslation();

    return (model: SystemModel): Array<ValidationError> => {
        const result = [];
        if (isNullOrWhitespace(model.name)) {
            result.push(new ValidationError("systemName", translateString("nameRequired")));
        }

        return result;
    };
}
