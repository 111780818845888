import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { useTranslation } from "../../localization/useTranslation";
import { DotLegalButton } from "@dotlegal/dotlegal-ui-components";
import AddLegalEntityDialog from "../addLegalEntityDialog/AddLegalEntityDialog";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import AddSimpleLegalEntityDialog from "../addLegalEntityDialog/AddSimpleLegalEntityDialog";
import { Box } from "@mui/material";

export interface ILegalEntityAddButtonProps {
    onLegalEntityCreated: (id: string) => void;
    successMsg?: string;
}

export default function LegalEntityAddButton(props: ILegalEntityAddButtonProps) {
    const [showDialog, setShowDialog] = useState(false);
    const queryClient = useQueryClient();
    const { translateString } = useTranslation();
    const { permissions } = useUserContext();

    function OnEntityCreated(id: string) {
        props.onLegalEntityCreated(id);

        queryClient.refetchQueries("senderstrue");
        queryClient.refetchQueries("legalEntities");

        setShowDialog(false);
    }

    return (
        <React.Fragment>
            <Box
                sx={(theme) => ({
                    "& .MuiButton-root": {
                        fontWeight: theme.typography.fontWeightBold,
                    },
                })}
            >
                <DotLegalButton btnLength={190} buttonType="secondary" onClick={() => setShowDialog(true)}>
                    {translateString("createCompany")}
                </DotLegalButton>
            </Box>

            {showDialog &&
                (permissions.companyPermissions.create ? (
                    <AddLegalEntityDialog
                        onDialogClose={() => setShowDialog(false)}
                        onEntityCreated={(id) => OnEntityCreated(id)}
                        succesMsg={props.successMsg}
                        isSimpleDialog={false}
                    />
                ) : (
                    <AddSimpleLegalEntityDialog
                        onDialogClose={() => setShowDialog(false)}
                        onEntityCreated={(id) => OnEntityCreated(id)}
                        succesMsg={props.successMsg}
                        isSimpleDialog
                    />
                ))}
        </React.Fragment>
    );
}
