export interface PrivacySettings {
    apiBaseUrl: string;
    appInsightInsKey: string | undefined;
    jwtTokenUrl: string;
    mainSiteBaseUrl: string;
    idpAuthority: string;
    idpClientId: string;
    customerAdminWeb: string;
    backofficeUrl: string;
    questionnaireFrontEndUrl: string;
    isRunningOnLocalHost: boolean;
    isRunningOnProduction: boolean;
    userPilotToken: string;
}

export function getSettings(): PrivacySettings {
    if (window.location.host.startsWith("localhost")) {
        return {
            apiBaseUrl: "https://localhost:5301",
            appInsightInsKey: "Not used on dev",
            jwtTokenUrl: getJwtUrl("https://localhost:5301"),
            mainSiteBaseUrl: "http://localhost:3001",
            idpAuthority: "https://id-test.dotlegal.com",
            idpClientId: "PrivacyLocalhost",
            customerAdminWeb: "http://localhost:3002",
            backofficeUrl: "http://localhost:3003",
            questionnaireFrontEndUrl: "http://localhost:3004",
            isRunningOnLocalHost: true,
            isRunningOnProduction: false,
            userPilotToken: "Not used on dev",
        };
    } else {
        let isTest = window.location.host === getEnvVariable("VITE_APP_PRIVACY_URL_TEST");

        if (isTest) {
            let apiBaseUrl = "https://" + getEnvVariable("VITE_APP_PRIVACY_URL_TEST");
            return {
                apiBaseUrl: apiBaseUrl,
                appInsightInsKey: getEnvVariable("VITE_APP_APPLICATION_INSIGHT_INSTRUMENTATION_KEY_TEST"),
                jwtTokenUrl: getJwtUrl(apiBaseUrl),
                mainSiteBaseUrl: apiBaseUrl,
                idpAuthority: getEnvVariable("VITE_APP_IDP_AUTHORITY_TEST"),
                idpClientId: getEnvVariable("VITE_APP_IDP_CLIENT_ID_TEST"),
                customerAdminWeb: "https://" + getEnvVariable("VITE_APP_CUSTOMER_ADMIN_WEB_URL_TEST"),
                backofficeUrl: "https://" + getEnvVariable("VITE_APP_BACKOFFICE_URL_TEST"),
                questionnaireFrontEndUrl: getEnvVariable("VITE_APP_Questionnaire_URL_TEST"),
                isRunningOnLocalHost: false,
                isRunningOnProduction: false,
                userPilotToken: getEnvVariable("VITE_APP_USER_PILOT_TOKEN_TEST"),
            };
        }
        let isProd = window.location.host === getEnvVariable("VITE_APP_PRIVACY_URL_PROD");
        if (isProd) {
            let apiBaseUrl = "https://" + getEnvVariable("VITE_APP_PRIVACY_URL_PROD");
            return {
                apiBaseUrl: apiBaseUrl,
                appInsightInsKey: getEnvVariable("VITE_APP_APPLICATION_INSIGHT_INSTRUMENTATION_KEY_PROD"),
                jwtTokenUrl: getJwtUrl(apiBaseUrl),
                mainSiteBaseUrl: apiBaseUrl,
                idpAuthority: getEnvVariable("VITE_APP_IDP_AUTHORITY_PROD"),
                idpClientId: getEnvVariable("VITE_APP_IDP_CLIENT_ID_PROD"),
                customerAdminWeb: "https://" + getEnvVariable("VITE_APP_CUSTOMER_ADMIN_WEB_URL_PROD"),
                backofficeUrl: "https://" + getEnvVariable("VITE_APP_BACKOFFICE_URL_PROD"),
                questionnaireFrontEndUrl: getEnvVariable("VITE_APP_Questionnaire_URL_PROD"),
                isRunningOnLocalHost: false,
                isRunningOnProduction: true,
                userPilotToken: getEnvVariable("VITE_APP_USER_PILOT_TOKEN_PROD"),
            };
        }
        throw new Error("Unknown window.location.host: " + window.location.host);
    }
}

function getJwtUrl(apiUrl: string) {
    return `${apiUrl.replace(/\/$/, "")}/jwt/token`;
}

function getEnvVariable(key: string) {
    return import.meta.env[key] ?? `No env variable matching ${key}`;
}
