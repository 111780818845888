import { useQuery } from "react-query";
import { get } from "../common/api/apiShared";
import { SelectableLegalUnitsItem } from "../processingActivity/ProcessingActivity.types";

export function useSelectableLegalEntityQuery() {
    return useQuery("legalEntities", () => get<Array<SelectableLegalUnitsItem>>("/LegalEntity/selectableentities/"), {
        notifyOnChangePropsExclusions: ["isStale"],
        staleTime: 15000,
    });
}
