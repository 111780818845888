import DotLegalDeleteDialogWrapper from "../../../common/components/dotLegalDeleteDialog/DotLegalDeleteDialogWrapper.tsx";
import React from "react";
import useDeletePublicInternalAssessmentTemplate from "./DeletePublicInternalAssessmentTemplateDialog.hooks.ts";

export interface IDeletePublicInternalAssessmentTemplateProps {
    id: string;
    name: string;
    onCloseDialog: () => void;
    onSuccessDelete: () => void;
}

function DeletePublicInternalAssessmentTemplateDialog(props: IDeletePublicInternalAssessmentTemplateProps) {
    const { inProgress, deleteClick } = useDeletePublicInternalAssessmentTemplate(props);
    return (
        <DotLegalDeleteDialogWrapper open itemForDeletion={props.name} onDialogClose={props.onCloseDialog} onOkClick={deleteClick} inProgress={inProgress} />
    );
}

export default DeletePublicInternalAssessmentTemplateDialog;
