import { useQuery } from "react-query";
import { LegalEntityClassificationApiModel } from "./LegalEntityClassificationTab.types";
import { get } from "../../common/api/apiShared";
import { ILegalEntityClassificationTab } from "./LegalEntityClassificationTab";
import { useState } from "react";
import { GetLegalEntityClassificationPlanLimit } from "../../auth/planProvider/PlanProvider.hooks.ts";

export default function useLegalEntityClassificationTabHooks(props: ILegalEntityClassificationTab) {
    const [showClassificationDialog, setShowClassificationDialog] = useState(false);

    const url = `/LegalEntityClassification/${props.legalEntityId}`;
    let { isLoading, data, refetch } = useQuery(url, () => get<LegalEntityClassificationApiModel>(url));
    const { classificationPlanLimitIsLoading, classificationPlanLimitData, refetchClassificationPlanLimit } = GetLegalEntityClassificationPlanLimit();

    function refetchData() {
        refetch();
        refetchClassificationPlanLimit();
    }

    function onClassificationCreated() {
        refetchData();
        setShowClassificationDialog(false);
    }

    return {
        isLoading,
        data,
        onClassificationCreated,
        showClassificationDialog,
        setShowClassificationDialog,
        refetchData,
        classificationPlanLimitIsLoading,
        classificationPlanLimitData,
    };
}
