import { useTheme } from "@mui/material";
import { createSxStyles } from "../../../common/createSxStyles";

export const DataCategoryOverviewStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        marginBottom: {
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
        },
        topHeader: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(2),
        },
        header: {
            fontSize: theme.typography.pxToRem(12),
            fontWeight: theme.typography.fontWeightBold,
            textTransform: "uppercase",
            color: theme.palette.primary.dark,
        },
        tableCell: {
            color: theme.palette.primary.dark,
        },
        divider: {
            backgroundColor: "#B6D5EF",
            marginBottom: "-10px",
            marginTop: "-10px",
        },
        gridDivider: {
            paddingBottom: "0px !important",
        },
        gridPadding: {
            paddingRight: "10px",
        },
    });
};
