import { useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";

export const useSystemsStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        switchBoxContainer: {
            paddingBottom: theme.spacing(2.5),
        },
        iconContainer: {
            display: "flex",
        },
        bold: {
            fontWeight: theme.typography.fontWeightBold,
        },
        greenArrowContainer: {
            justifyContent: "flex-end",
            marginRight: 20 + "px",
        },
        tableRow: {
            "& svg": {
                height: 14 + "px",
            },
        },
        imgContainer: {
            display: "flex",
            alignItems: "center",
            "& svg": {
                width: "14px",
            },
        },
        value: {
            marginLeft: "10px",
            fontSize: "18px",
            fontWeight: theme.typography.fontWeightBold,
        },
        systemIcon: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        createPaper: {
            display: "flex",
            alignItems: "center",
            height: 116 + "px",
            marginBottom: theme.spacing(2),
            position: "relative",
            "& .MuiButton-root": {
                marginRight: theme.spacing(2),
            },
        },
        existingCompaniesContainer: {
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
        },
    });
};
