import { createElement, useState } from "react";
import { Trans } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { get, post, put } from "../../../common/api/apiShared";
import { Result } from "../../../common/api/result";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { isNullOrWhitespace } from "../../../common/stringOperations";
import { LegalEntitySelectorViewModel } from "../../../legalEntity/LegalEntity.types";
import { useTranslation } from "../../../localization/useTranslation";
import { SelectableColoredItem } from "../../../processingActivity/ProcessingActivity.types";
import { SecurityMeasureSaveModel, SecurityMeasureType } from "../securityMeasures/SecurityMeasures.types";
import { IAddEditSecurityMeasureProps } from "./AddEditSecurityMeasureDialog";

export function useAddEditSecurityMeasureDialog(props: IAddEditSecurityMeasureProps) {
    const snackbar = useDotLegalSnackbar();
    const { translateString } = useTranslation();
    const [errors, setErrors] = useState({ nameError: "", securitymeasureTypeError: "" });
    const [viewModel, setViewModel] = useState<SecurityMeasureSaveModel | undefined>(props.selectSecurityMeasure);

    const businessAreasQuery = useQuery("selectableBusinessAreas", () => get<Array<SelectableColoredItem>>("/BusinessAreas/businessareas"));
    const groupEntityQuery = useQuery("groupentities", () => get<Array<LegalEntitySelectorViewModel>>("/LegalEntity/groupentities"));

    let businessAreaIds = Array<string>();
    if (businessAreasQuery.data) {
        businessAreaIds = businessAreasQuery.data!.map((x) => x.id);
    }

    let groupEntityIds = Array<string>();
    if (groupEntityQuery.data) {
        groupEntityIds = groupEntityQuery.data!.map((x) => x.id);
    }

    function save() {
        return post<string>("/securitymeasures/", viewModel);
    }

    const saveMutation = useMutation(save, {
        onSuccess: (response: Result<string>) => {
            if (response.success()) {
                props.onDialogClose();
                props.refetch();
                props.onOkClick(response.value(), viewModel!.name);
                snackbar.show(createElement(Trans, { i18nKey: "securityMeasureCreated", values: { name: viewModel?.name } }));
            } else {
                snackbar.show(translateString("securityMeasureExist"), "warning");
            }
        },
    });

    const createSecurityMeasure = async () => {
        await saveMutation.mutateAsync();
    };

    const editMutation = useMutation(update);
    const updateSecurityMeasure = () => {
        editMutation.mutateAsync(viewModel!.id, {
            onSuccess: (response: Result<string>) => {
                if (response.success()) {
                    snackbar.show(translateString("securityMeasureUpdated", { name: viewModel!.name }));
                    props.onDialogClose();
                    props.refetch();
                } else {
                    snackbar.show(translateString("securityMeasureExist"), "warning");
                }
            },
        });
    };

    function update(id: string) {
        return put<string>(`/securitymeasures/${id}/`, viewModel);
    }

    function toggleBusinessArea(businessAreaId: string) {
        var temp = { ...viewModel! };

        if (temp.businessAreaIds?.some((x) => x === businessAreaId)) {
            temp.businessAreaIds = temp.businessAreaIds.filter((x) => x !== businessAreaId);
        } else {
            temp.businessAreaIds?.push(businessAreaId);
        }

        setViewModel(temp);
    }

    return {
        viewModel,
        setViewModel,
        getSecurityMeasureTypeOptions,
        validateSecurityMeasureName,
        validateSecurityMeasureType,
        validateSecurityMeasureForm,
        errors,
        isSaving: editMutation.isLoading || saveMutation.isLoading,
        businessAreaData: businessAreasQuery.data,
        businessAreaIsLoading: businessAreasQuery.isLoading,
        toggleBusinessArea,
        businessAreaIds,
        groupEntityIsLoading: groupEntityQuery.isLoading,
        groupEntityData: groupEntityQuery.data,
        groupEntityIds,
    };

    function getSecurityMeasureTypeOptions() {
        return [
            { name: translateString("logicalsecurity"), id: SecurityMeasureType.LogicalSecurity.toString() },
            { name: translateString("organizational"), id: SecurityMeasureType.Organizational.toString() },
            { name: translateString("physicalsecurity"), id: SecurityMeasureType.PhysicalSecurity.toString() },
            { name: translateString("technical"), id: SecurityMeasureType.Technical.toString() },
            { name: translateString("contractual"), id: SecurityMeasureType.Contractual.toString() },
        ];
    }

    function validateSecurityMeasureName(name: string) {
        let tempErrors = { ...errors };

        tempErrors.nameError = isNullOrWhitespace(name) ? translateString("fieldMandatory") : "";

        setErrors(tempErrors);
    }

    function validateSecurityMeasureType(type: SecurityMeasureType | undefined) {
        let tempErrors = { ...errors };

        tempErrors.securitymeasureTypeError = type ? "" : translateString("fieldMandatory");

        setErrors(tempErrors);
    }

    function validateSecurityMeasureForm() {
        let formValid = true;
        let tempErrors = { ...errors };

        if (isNullOrWhitespace(viewModel?.name)) {
            tempErrors.nameError = translateString("fieldMandatory");
            formValid = false;
        }

        if (viewModel?.securityMeasureType === undefined) {
            tempErrors.securitymeasureTypeError = translateString("fieldMandatory");
            formValid = false;
        }

        if (formValid) {
            if (props.isAdd) createSecurityMeasure();
            else updateSecurityMeasure();
        } else {
            setErrors(tempErrors);
        }
    }
}
