import { useTheme } from "@mui/material";
import { createSxStyles } from "../../../common/createSxStyles";

export const useAddEditDeletionPeriodDialogStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        root: {
            "& .MuiInputBase-root": {
                borderRadius: 5 + "px",
            },
        },
        paperComponent: {
            border: "2px solid",
            borderColor: theme.palette.primary.main,
            borderRadius: 5 + "px",
            color: theme.palette.primary.dark,
        },
    });
};
