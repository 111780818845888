import { DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import Widget from "../../Widget";

import { Box } from "@mui/material";
import { useTranslation } from "../../../localization/useTranslation";
import TaskOverviewGraph from "../../taskOverviewWidget/taskOverviewGraph/TaskOverviewGraph";
import { ITaskOverviewWidgetProps } from "../../taskOverviewWidget/TaskOverviewWidget";
import { useTaskOverviewWidget } from "../../taskOverviewWidget/TaskOverviewWidget.hooks";

function TasksOverviewWidgetReport(props: ITaskOverviewWidgetProps) {
    const { translateString } = useTranslation();
    const { data, highestNumberOfTasksInAMonth, isEnabled, isLoading } = useTaskOverviewWidget(props);
    return (
        <Widget disabled={!isEnabled}>
            <DotLegalHeader headerStyle={"medium"} marginBottom={0}>
                {translateString("taskOverviewWidgetHeader").toUpperCase()} -{" "}
                <Box sx={{ fontWeight: 400 }} component={"span"}>
                    {props.year}
                </Box>
            </DotLegalHeader>

            <Box sx={{ display: "flex", flexDirection: "column", height: "320px", width: "1040px" }}>
                <TaskOverviewGraph
                    onlyUserSpecific={props.onlyUserSpecific}
                    isLoading={isLoading}
                    isPdf
                    data={data}
                    highestNumberOfTasksInAMonth={highestNumberOfTasksInAMonth}
                    year={props.year!}
                    showLegends
                />
            </Box>
        </Widget>
    );
}

export default TasksOverviewWidgetReport;
