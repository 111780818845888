import { useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";

export const useSystemOverviewStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        container: {
            width: "100%",
            height: "100%",
        },
        gridContainer: {
            marginBottom: theme.spacing(1),
            alignItems: "center",
        },
        gridContentAlignment: {
            display: "flex",
            alignItems: "center",
        },
        gridAlignment: {
            display: "flex",
            justifyContent: "flex-end",
        },
        editButton: {
            marginLeft: theme.spacing(0.5),
            "& svg": {
                height: 25,
                stroke: theme.palette.primary.main,
                fill: theme.palette.primary.light,
            },
        },
        gridRow: {
            textAlign: "center",
            minHeight: 139,
            marginTop: 0,
            marginLeft: 0,
        },
        divider: {
            backgroundColor: "#F0F7FC",
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
            marginLeft: theme.spacing(6),
            marginRight: theme.spacing(6),
        },
        securityMeasure: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "& svg": {
                height: 20,
                marginRight: theme.spacing(0.5),
            },
        },
        dataLocationContainer: {},
        dataLocationItem: {
            display: "flex",
            justifyContent: "center",
            marginBottom: theme.spacing(1),
            "& .MuiTypography-root": {
                color: theme.palette.primary.dark,
                fontSize: theme.typography.pxToRem(12),
            },
        },
        infoImage: {
            width: 150,
            height: 115,
        },
        menuItem: {
            color: theme.palette.primary.dark,
            fontSize: theme.typography.pxToRem(14),
            fontWeight: theme.typography.fontWeightMedium,
        },
        tableHeader: {
            color: theme.palette.primary.dark,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: theme.typography.pxToRem(16),
            marginTop: theme.spacing(3),
        },
        country: {
            display: "flex",
            justifyContent: "center",
            "& svg": {
                fill: theme.palette.error.light,
                marginLeft: theme.spacing(0.5),
                height: 17,
                width: 17,
            },
        },
        multipleSupportLocations: {
            color: theme.palette.secondary.main + " !important",
            fontWeight: theme.typography.fontWeightBold,
            "&:hover": {
                cursor: "pointer",
            },
        },
        supportLocationsContainer: {
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
        },
        tableContainer: {
            flex: "1",
            display: "flex",
            flexDirection: "column",
            marginTop: theme.spacing(2),
        },
    });
};
