import React from "react";
import { Box } from "@mui/material";
import { exhaustiveGuard } from "../../../common/utilities";
import { DeclarationControlStatusEnum } from "../../Declaration.types";
import { auditInProgress, blueGreyCheckmark, closed, greenCheckmark } from "../../../common/icons";
import { useTranslation } from "../../../localization/useTranslation";
import { DeclarationApprovalOption } from "../../declarations/Declarations.types.ts";

export interface DeclarationControlStatusProps {
    approvalType: DeclarationApprovalOption;
    status: DeclarationControlStatusEnum;
    completedTasks: number;
    totalNumberOfTasks: number;
}

function DeclarationControlStatus(props: DeclarationControlStatusProps) {
    interface DeclarationControlStatusObject {
        icon: React.ReactNode;
        statusText: string;
    }

    const { translateString } = useTranslation();

    const getTasksCount = () => {
        if (props.totalNumberOfTasks === 0) return undefined;

        return (
            <Box component={"span"} sx={{ display: "inline-block" }}>
                ({props.completedTasks} / {props.totalNumberOfTasks})
            </Box>
        );
    };

    const getStatusObjects = (): DeclarationControlStatusObject => {
        switch (props.status) {
            case DeclarationControlStatusEnum.Planned:
                return { icon: auditInProgress, statusText: translateString("plannedSingular") };
            case DeclarationControlStatusEnum.Ongoing:
                return { icon: auditInProgress, statusText: translateString("inProgress") };
            case DeclarationControlStatusEnum.ReadyForApproval:
                return { icon: closed, statusText: translateString("readyForApproval") };
            case DeclarationControlStatusEnum.ApprovedInternal:
                return {
                    icon: props.approvalType == DeclarationApprovalOption.ExternalApprovalRequired ? blueGreyCheckmark : greenCheckmark,
                    statusText: translateString("internallyApproved"),
                };
            case DeclarationControlStatusEnum.Completed:
                return { icon: greenCheckmark, statusText: translateString("completed") };
            default:
                exhaustiveGuard(props.status);
        }
    };

    const status = getStatusObjects();

    return (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {status.icon}
            <Box component={"span"}>
                {status.statusText} {getTasksCount()}
            </Box>
        </Box>
    );
}

export default DeclarationControlStatus;
