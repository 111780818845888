import { IDeletePublicInternalAssessmentTemplateProps } from "./DeletePublicInternalAssessmentTemplateDialog.tsx";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useMutation } from "react-query";
import { Result } from "../../../common/api/result.ts";
import { createElement } from "react";
import { Trans } from "react-i18next";
import { deleteHttp } from "../../../common/api/apiShared.ts";

export default function useDeletePublicInternalAssessmentTemplate(props: IDeletePublicInternalAssessmentTemplateProps) {
    const snackbar = useDotLegalSnackbar();

    const removeMutation = useMutation(remove);

    const deleteClick = () => {
        removeMutation.mutateAsync(undefined, {
            onSuccess: (response: Result<string>) => {
                props.onCloseDialog();
                props.onSuccessDelete();
                snackbar.show(createElement(Trans, { i18nKey: "itemDeleted", values: { name: props.name } }));
            },
        });
    };

    return {
        deleteClick,
        inProgress: removeMutation.isLoading,
    };

    async function remove() {
        return await deleteHttp<string>(`/publicInternalAssessmentTemplates/${props.id}`);
    }
}
