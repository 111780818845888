import React from "react";
import { SystemDocument } from "../SystemDocuments.types";
import DotLegalDeleteDialogWrapper from "../../../../common/components/dotLegalDeleteDialog/DotLegalDeleteDialogWrapper";
import { useSystemDocumentDeleteDialog } from "./SystemDocumentDeleteDialog.hooks";

export interface SystemDocumentDeleteDialogProps {
    onCloseDialog: () => void;
    onDeletedDocument: () => void;
    selectedDocument: SystemDocument;
    systemId?: string;
}

function SystemDocumentDeleteDialog(props: SystemDocumentDeleteDialogProps) {
    const { deleteDocumentClick, inProgess } = useSystemDocumentDeleteDialog(props);
    return (
        <DotLegalDeleteDialogWrapper
            open
            itemForDeletion={props.selectedDocument!.name}
            onDialogClose={props.onCloseDialog}
            onOkClick={() => deleteDocumentClick(props.selectedDocument!.documentId)}
            inProgress={inProgess}
        />
    );
}

export default SystemDocumentDeleteDialog;
