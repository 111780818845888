import { DotLegalTooltip } from "@dotlegal/dotlegal-ui-components";
import { Box, MenuItem } from "@mui/material";
import { ReactNode } from "react";
import PurpleLockSvg from "../../common/svgs/purpleLock.svg?react";

export interface IPlanMenuItemWrapperProps {
    tooltip: string;
    menuName: string;
    menuItem: ReactNode;
    hasAccess: boolean;
}

export default function PlanMenuItemWrapper(props: IPlanMenuItemWrapperProps) {
    const getMenuItem = () => {
        if (props.hasAccess) {
            return props.menuItem;
        }

        return (
            <DotLegalTooltip text={props.tooltip}>
                <span>
                    <MenuItem
                        sx={(theme) => ({ color: theme.palette.primary.dark, fontSize: "0.875rem", fontWeight: theme.typography.fontWeightMedium })}
                        onClick={async () => {}}
                        disabled
                    >
                        <Box
                            sx={{
                                cursor: "default",
                                display: "flex",
                                alignContent: "center",
                            }}
                        >
                            <span>{props.menuName}</span>{" "}
                            <Box component={"span"} sx={{ marginLeft: 0.5, height: 0 }}>
                                <PurpleLockSvg />
                            </Box>
                        </Box>
                    </MenuItem>
                </span>
            </DotLegalTooltip>
        );
    };

    return getMenuItem();
}
