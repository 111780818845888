import React from "react";
import DotLegalDeleteDialogWrapper from "../../common/components/dotLegalDeleteDialog/DotLegalDeleteDialogWrapper";
import { useDeleteRoleDialog } from "./DeleteRoleDialog.hooks";

export interface IDeleteRoleDialogProps {
    name: string;
    id: string;
    onCloseDialog: () => void;
    onDeleteSuccess: () => void;
}

function DeleteRoleDialog(props: IDeleteRoleDialogProps) {
    const { deleteRole, inProgress } = useDeleteRoleDialog(props);

    return (
        <DotLegalDeleteDialogWrapper
            open
            itemForDeletion={props.name}
            onDialogClose={props.onCloseDialog}
            onOkClick={() => deleteRole()}
            inProgress={inProgress}
        ></DotLegalDeleteDialogWrapper>
    );
}

export default DeleteRoleDialog;
