import React from "react";
import { DotLegalColorPicker, DotLegalDialog, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { useTagDialog } from "./TagDialog.hooks";
import { TagViewModel } from "../Tags.types";
import { isNullOrWhitespace } from "../../../common/stringOperations";

export interface TagDialogProps {
    onHideDialog: () => void;
    onSave: (tagId: string) => void;
    selectedTag: TagViewModel | undefined;
}

function TagDialog(props: TagDialogProps) {
    const { translateString } = useTranslation();
    const { viewModel, inProgress, isEdit, tagUpdater, showError, onOkClick } = useTagDialog(props);

    return (
        <DotLegalDialog
            inProgress={inProgress}
            size="sm"
            buttonOkText={isEdit ? translateString("save") : translateString("create")}
            header={isEdit ? translateString("editTag") : translateString("createTag")}
            onOkClick={onOkClick}
            onDialogClose={props.onHideDialog}
            open
            disableBackdropOnCloseIfValueChange={viewModel}
        >
            <DotLegalTextField
                debounce={false}
                size="small"
                label={translateString("tag")}
                value={viewModel?.name!}
                onChange={(name) => tagUpdater.onNameChange(name)}
                errorText={showError && isNullOrWhitespace(viewModel?.name) ? translateString("nameRequired") : undefined}
            />

            <DotLegalTextField
                label={translateString("description")}
                onChange={(description) => tagUpdater.onDescriptionChange(description)}
                multiline
                rows={4}
                debounce={false}
                value={viewModel?.description!}
            />

            <DotLegalColorPicker
                label={translateString("tagColor")}
                selectedColor={viewModel?.color?.toString()}
                onColorChange={(color) => tagUpdater.onColorChange(color!)}
            />
        </DotLegalDialog>
    );
}

export default TagDialog;
