import { Box } from "@mui/material";
import { ReactNode } from "react";
import PurpleLockSvg from "../../common/svgs/purpleLock.svg?react";

export function getPlanTab(label: string, content: JSX.Element, index: number, hasAccess: boolean, tooltip: string) {
    return {
        label: label,
        content: content,
        index: index,
        disabled: !hasAccess,
        icon: !hasAccess ? (
            <Box sx={{ marginLeft: 0.5, marginTop: 1 }}>
                <PurpleLockSvg />
            </Box>
        ) : undefined,
        tooltip: !hasAccess ? tooltip : "",
    };
}
