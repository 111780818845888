import { highRiskIcon, lowRiskIcon, moderateRiskIcon, noRiskIcon, reallyHighRiskIcon, reallyLowRiskIcon } from "./riskIcons";

export function useRiskIndicator() {
    const getRiskIcon = (score: number | undefined | null) => {
        switch (score) {
            case 1:
                return reallyLowRiskIcon;
            case 2:
                return lowRiskIcon;
            case 3:
                return moderateRiskIcon;
            case 4:
                return highRiskIcon;
            case 5:
                return reallyHighRiskIcon;
            default:
                return noRiskIcon;
        }
    };

    return {
        getRiskIcon,
    };
}
