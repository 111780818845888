import EvaluateQuestionnaireDialog from "../../../common/components/questionnaireComponents/evaluateQuestionnaireDialog/EvaluateQuestionnaireDialog";
import { useTranslation } from "../../../localization/useTranslation";
import { LegalEntityClassificationApiRow } from "../LegalEntityClassificationTab.types";
import useEvaluateClassificationDialogHooks from "./EvaluateClassificationDialog.hooks";

export interface IEvaluateClassificationDialog {
    onEvaluated: () => void;
    onDialogClose: () => void;
    selectedClassification: LegalEntityClassificationApiRow;
}

function EvaluateClassificationDialog(props: IEvaluateClassificationDialog) {
    const { translateString } = useTranslation();
    const { saveEvaluation, isSaving } = useEvaluateClassificationDialogHooks(props);

    return (
        <EvaluateQuestionnaireDialog
            onDialogClose={props.onDialogClose}
            onOkClick={saveEvaluation}
            header={translateString("evaluateClassificationHeader")}
            info={translateString("evaluateClassificationDescription")}
            isSaving={isSaving}
            evaluation={props.selectedClassification.evaluation}
            evaluationStatus={props.selectedClassification.evaluationStatus}
        />
    );
}

export default EvaluateClassificationDialog;
