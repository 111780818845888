import { createElement } from "react";
import { Trans } from "react-i18next";
import { useMutation } from "react-query";
import { deleteHttp } from "../../common/api/apiShared";
import { Result } from "../../common/api/result";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { IDeleteRoleDialogProps } from "./DeleteRoleDialog";

export function useDeleteRoleDialog(props: IDeleteRoleDialogProps) {
    const snackbar = useDotLegalSnackbar();

    const removeMutation = useMutation(remove);
    const deleteRole = () => {
        removeMutation.mutateAsync(undefined, {
            onSuccess: (response: Result<string>) => {
                props.onCloseDialog();

                if (response.success()) {
                    props.onDeleteSuccess();
                    snackbar.show(createElement(Trans, { i18nKey: "roleDeleted", values: { name: props.name } }));
                } else {
                    snackbar.show(createElement(Trans, { i18nKey: "roleNotDeleted", values: { name: props.name } }), "warning");
                }
            },
        });
    };

    async function remove() {
        return await deleteHttp<string>(`/role/${props.id}`);
    }

    return { deleteRole, inProgress: removeMutation.isLoading };
}
