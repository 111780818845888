import { useQuery } from "react-query";
import { get } from "../../common/api/apiShared";
import { TagViewModel } from "./Tags.types";
import { useState } from "react";

export function useTags() {
    const [showTagDialog, setShowTagDialog] = useState(false);
    const [selectedTag, setSelectedTag] = useState<TagViewModel | undefined>(undefined);
    const [showDeleteTagDialog, setShowDeleteTagDialog] = useState<TagViewModel | undefined>(undefined);

    const { isLoading, data, refetch } = useQuery("tags", () => get<Array<TagViewModel>>("/Tag"));

    const onTagSave = () => {
        setSelectedTag(undefined);
        setShowTagDialog(false);
        refetch();
    };

    const onTagDeleted = () => {
        setShowDeleteTagDialog(undefined);
        refetch();
    };

    return {
        isLoading,
        data,
        showTagDialog,
        setShowTagDialog,
        selectedTag,
        setSelectedTag,
        onTagSave,
        showDeleteTagDialog,
        setShowDeleteTagDialog,
        onTagDeleted,
    };
}
