import React from "react";
import { useTranslation } from "../../localization/useTranslation";
import { useRevisionDialog } from "./RevisionDialog.hooks";
import { Trans } from "react-i18next";
import { Box } from "@mui/material";
import { DotLegalInfoDialog } from "@dotlegal/dotlegal-ui-components";

export interface RevisionDialogProps {
    declarationId: string;
    declarationName: string;
    auditDate: Date;
    onDialogClose: () => void;
    onReadyForRevisionSaved: () => void;
}

function RevisionDialog(props: RevisionDialogProps) {
    const { translateString, translateDateWithFullMonthAndYear } = useTranslation();
    const { onSaveReadyForRevision } = useRevisionDialog(props);

    return (
        <DotLegalInfoDialog
            header={translateString("readyForRevision")}
            btnText={translateString("save")}
            content={
                <Trans
                    i18nKey={"readyForRevisionInfoText"}
                    values={{
                        declarationName: props.declarationName,
                        deadline: translateDateWithFullMonthAndYear(props.auditDate),
                    }}
                    components={{
                        strong: <Box component="span" sx={{ fontWeight: "bold", color: "primary.main" }}></Box>,
                    }}
                />
            }
            onCloseDialog={props.onDialogClose}
            onBtnClick={onSaveReadyForRevision}
        />
    );
}

export default RevisionDialog;
