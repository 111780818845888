import { createElement, useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { get, put } from "../common/api/apiShared";
import { DotLegalBreadCrumbModel, useDotLegalSnackbar, ITableHeader } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../localization/useTranslation";
import { useUrlProvider } from "../useUrlProvider";
import { UserGroupEntities, UserViewModel } from "./User.types";

export function useUserHook() {
    const urlProvider = useUrlProvider();
    const queryClient = useQueryClient();
    const snackbar = useDotLegalSnackbar();
    const { id } = useParams<{ id: string }>();
    const { translateString, translateCountry } = useTranslation();

    const [showEditUserDialog, setShowEditUserDialog] = useState(false);
    const [showChangeActiveStatusDialog, setShowChangeActiveStatusDialog] = useState(false);
    const [showResendInvitationDialog, setShowResendInvitationDialog] = useState(false);
    const [showDeleteUserDialog, setShowDeleteUserDialog] = useState(false);
    let { isLoading, data } = useQuery("user" + id, () => get<UserViewModel>(`/user/${id}`));
    let { isLoading: loadingEntities, data: groupEntities } = useQuery("userGroupEntities" + id, () =>
        get<Array<UserGroupEntities>>(`/user/${id}/groupentities`)
    );
    const [userGroupEntities, setUserGroupEntities] = useState<Array<UserGroupEntities>>([]);
    const navigate = useNavigate();

    function saveGroupEntites() {
        put(
            `/user/${id}/updateUserGroupEntities`,
            userGroupEntities.filter((x) => x.isSelected === true).map((y) => y.legalEntityId)
        );
    }

    useEffect(() => {
        if (groupEntities) {
            let entities: Array<UserGroupEntities> = groupEntities.map((x) => {
                return {
                    legalEntityId: x.legalEntityId,
                    isSelected: x.isSelected,
                    name: x.name,
                    address: x.address,
                    zipCode: x.zipCode,
                    city: x.city,
                    country: translateCountry(x.country),
                };
            });

            setUserGroupEntities(entities);
        }
    }, [groupEntities, translateCountry]);

    const handleEditUserSuccess = () => {
        queryClient.refetchQueries("user" + id);
        queryClient.refetchQueries("userGroupEntities" + id);
        setShowEditUserDialog(false);
    };

    const handeDeleteUserSuccess = (name: string) => {
        setShowDeleteUserDialog(false);
        navigate(urlProvider.getUsersUrl());
        snackbar.show(createElement(Trans, { i18nKey: "userDeleteSuccess", values: { name: name } }));
    };

    const handleChangeStatus = () => {
        queryClient.refetchQueries("user" + id);
    };

    const setSelected = (entityId: string) => {
        let newEntities = [...userGroupEntities];

        newEntities.forEach((element) => {
            if (element.legalEntityId === entityId) {
                if (element.isSelected) element.isSelected = false;
                else element.isSelected = true;
            }
        });

        setUserGroupEntities(newEntities);
        saveGroupEntites();
    };

    const selectAllEntities = (selected: Array<UserGroupEntities>) => {
        const isChecked = selected.length > 0;
        let newEntities = [...userGroupEntities];

        newEntities.forEach((element) => {
            element.isSelected = isChecked;
        });

        setUserGroupEntities(newEntities);
        saveGroupEntites();
    };

    const getHeaderCheckBoxState = () => {
        const groupEntitiesSelectedCount = userGroupEntities.filter((x) => x.isSelected === true).length;

        return groupEntitiesSelectedCount === 0 ? "false" : groupEntitiesSelectedCount === userGroupEntities.length ? "true" : "indeterminate";
    };

    const getBreadCrumbs = () => {
        let breadCrumbs: Array<DotLegalBreadCrumbModel> = [];

        breadCrumbs.push({ name: translateString("settings"), link: urlProvider.getSettingsUrl() });
        breadCrumbs.push({ name: translateString("users"), link: urlProvider.getUsersUrl() });
        breadCrumbs.push({ name: data?.firstName ? data?.firstName + " " + data?.lastName : "" });

        return breadCrumbs;
    };

    return {
        isLoading,
        data,
        getBreadCrumbs,
        showEditUserDialog,
        setShowEditUserDialog,
        handleEditUserSuccess,
        showDeleteUserDialog,
        setShowDeleteUserDialog,
        handeDeleteUserSuccess,
        handleChangeStatus,
        groupEntitiesHeaders,
        loadingEntities,
        userGroupEntities,
        getHeaderCheckBoxState,
        setSelected,
        selectAllEntities,
        setShowChangeActiveStatusDialog,
        showChangeActiveStatusDialog,
        showResendInvitationDialog,
        setShowResendInvitationDialog,
    };

    function groupEntitiesHeaders() {
        const headers: Array<ITableHeader<UserGroupEntities>> = [
            { property: "name", text: translateString("name"), align: "left", showOnMobile: true },
            { property: "address", text: translateString("address"), align: "left", showOnMobile: true },
            { property: "zipCode", text: translateString("zipCode"), align: "left", showOnMobile: true },
            { property: "city", text: translateString("city"), align: "left", showOnMobile: true },
            { property: "country", text: translateString("country"), align: "left", showOnMobile: true },
        ];

        return headers;
    }
}
