import { useCallback, useMemo, useRef, useState } from "react";
import { IControllerScoreGraphProps } from "./AllocationOfRiskLevelChart";
import { DotLegalSelectOption } from "../../../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { useTranslation } from "../../../../localization/useTranslation";

export function useControllerScoreGraphHooks(props: IControllerScoreGraphProps) {
    const { translateString } = useTranslation();
    const [selectedSection, setSelectedSection] = useState<string>("0");

    const sectionOptions: DotLegalSelectOption[] = useMemo(() => {
        const categoryOptions = props.data.sections.map((s) => ({ id: s.sectionId, name: s.sectionName }));

        return [{ name: translateString("allSections"), id: "0" }, ...categoryOptions];
    }, [props.data.sections, translateString]);

    const handleCategoriesChanged = (sectionId: string) => {
        setSelectedSection(sectionId);
    };

    return { selectedSection, handleCategoriesChanged, sectionOptions };
}
