import { DotLegalSelectOption } from "../../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { useTranslation } from "../../../localization/useTranslation";
import { IDataSubjectSelect } from "./DataSubjectSelect";

export function useDataSubjectSelectHooks(props: IDataSubjectSelect) {
    const { translateString } = useTranslation();

    let dataSubjectOptions: Array<DotLegalSelectOption> = [{ id: "all", name: translateString("all") }];

    if (props.options) {
        props.options.forEach((d) => {
            dataSubjectOptions.push(d);
        });
    }

    const getSelectedItems = () => {
        if (props.selectedItem === null || undefined) {
            return "all";
        }

        return props.selectedItem;
    };

    return {
        dataSubjectOptions,
        getSelectedItems,
    };
}
