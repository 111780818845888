import { useState } from "react";
import { EvaluationStatus, EvaluationStatusSaveModel } from "./EvaluateQuestionnaireDialog.types";
import { useValidator } from "../../../hooks/validation";
import { useTranslation } from "../../../../localization/useTranslation";
import { IEvaluateQuestionnaireDialog } from "./EvaluateQuestionnaireDialog";

export default function useEvaluateQuestionnaireDialogHooks(props: IEvaluateQuestionnaireDialog) {
    const [evaluation, setEvaluation] = useState<EvaluationStatusSaveModel>({ evaluation: props.evaluation, evaluationStatus: props.evaluationStatus });

    function onSaveClick() {
        props.onOkClick(evaluation);
    }

    const evaluationStatusUpdater = {
        onStatusChange: (status: EvaluationStatus) => {
            let temp = { ...evaluation };
            if (temp.evaluationStatus === status) {
                temp.evaluationStatus = undefined;
            } else {
                temp.evaluationStatus = status;
            }

            setEvaluation(temp);
        },
        onEvaluationChange: (value: string) => {
            let temp = { ...evaluation };
            temp.evaluation = value;
            setEvaluation(temp);
        },
    };

    return { evaluationStatusUpdater, evaluation, onSaveClick };
}
