import { useTranslation } from "../../localization/useTranslation";
import { DotLegalDialog } from "@dotlegal/dotlegal-ui-components";
import { Box } from "@mui/material";
import React from "react";

export interface IncidentLogOverviewMeasuresDialogProps {
    onCloseDialog: () => void;
    content: string;
}
function IncidentLogOverviewMeasuresDialog(props: IncidentLogOverviewMeasuresDialogProps) {
    const { translateString } = useTranslation();

    return (
        <DotLegalDialog
            header={translateString("incidentLogOverviewConsequencesHeader")}
            buttonOkText={""}
            hideOkButton
            open
            onDialogClose={() => props.onCloseDialog()}
            onOkClick={() => {}}
            size="sm"
        >
            <Box>
                {props.content.split("\n").map((x, i) => (
                    <p key={i}>{x}</p>
                ))}
            </Box>
        </DotLegalDialog>
    );
}

export default IncidentLogOverviewMeasuresDialog;
