import React from "react";
import { Box, RadioGroup, Typography } from "@mui/material";
import { DotLegalButton, DotLegalRadioButton } from "@dotlegal/dotlegal-ui-components";
import { useRiskRadioGroupStyles } from "./RiskRadioGroup.styles";
import { useTranslation } from "../../localization/useTranslation";
import { useIsOnSmallScreen } from "@dotlegal/dotlegal-ui-components";

export interface IRiskRadioGroupProps {
    showResetButton: boolean;
    disableResetButton: boolean;
    isConsequenceQuestion: boolean;
    score: number | null;
    onResetClick: () => void;
    onScoreChange: (newScore: number) => void;
    disabled: boolean;
}

function RiskRadioGroup(props: IRiskRadioGroupProps) {
    const { translateString } = useTranslation();
    const isOnSmallScreen = useIsOnSmallScreen();

    const styles = useRiskRadioGroupStyles(isOnSmallScreen);

    const getLabelNode = (labelTranslationKey: string, labelInfoTranslationKey: string | undefined) => {
        if (props.isConsequenceQuestion) {
            return (
                <React.Fragment>
                    <Typography sx={styles.radiobuttonLabel}>{translateString(labelTranslationKey)}</Typography>
                    <Typography sx={styles.radioButtonLabelInfo}>{labelInfoTranslationKey && translateString(labelInfoTranslationKey)}</Typography>
                </React.Fragment>
            );
        }

        return undefined;
    };

    return (
        <Box sx={styles.radioButtonsContainer}>
            <RadioGroup sx={styles.radioGroup} row value={props.score} onChange={(e) => props.onScoreChange(Number((e.target as HTMLInputElement).value))}>
                <DotLegalRadioButton
                    labelPlacement="bottom"
                    value={1}
                    smallSideMargin={props.isConsequenceQuestion}
                    label={!props.isConsequenceQuestion ? translateString("veryLow") : undefined}
                    labelNode={getLabelNode("fewDisadvantages", undefined)}
                    disabled={props.disabled}
                />
                <DotLegalRadioButton
                    labelPlacement="bottom"
                    value={2}
                    smallSideMargin={props.isConsequenceQuestion}
                    label={!props.isConsequenceQuestion ? translateString("low") : undefined}
                    labelNode={getLabelNode("disadvantages", "disadvantagesInfo")}
                    disabled={props.disabled}
                />
                <DotLegalRadioButton
                    labelPlacement="bottom"
                    value={3}
                    smallSideMargin={props.isConsequenceQuestion}
                    label={!props.isConsequenceQuestion ? translateString("moderate") : undefined}
                    labelNode={getLabelNode("significantDisadvantages", "significantDisadvantagesInfo")}
                    disabled={props.disabled}
                />
                <DotLegalRadioButton
                    labelPlacement="bottom"
                    value={4}
                    smallSideMargin={props.isConsequenceQuestion}
                    label={!props.isConsequenceQuestion ? translateString("high") : undefined}
                    labelNode={getLabelNode("difficultDisadvantages", "difficultDisadvantagesInfo")}
                    disabled={props.disabled}
                />
                <DotLegalRadioButton
                    labelPlacement="bottom"
                    value={5}
                    smallSideMargin={props.isConsequenceQuestion}
                    label={!props.isConsequenceQuestion ? translateString("veryHigh") : undefined}
                    labelNode={getLabelNode("IrreparableDisadvantages", "IrreparableDisadvantagesInfo")}
                    disabled={props.disabled}
                />
            </RadioGroup>
            {props.showResetButton && (
                <DotLegalButton disabled={props.disableResetButton || props.disabled} buttonType={"primary"} onClick={props.onResetClick}>
                    {translateString("Reset")}
                </DotLegalButton>
            )}
        </Box>
    );
}

export default RiskRadioGroup;
