import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useProcessingActivityStatisticsStyles } from "../processingActivityStatistics.styles";
import { useTranslation } from "../../../localization/useTranslation";
import DotLegalPaper from "../../../common/components/dotLegalPaper/DotLegalPaper";
import { getCounter } from "../ProcessingActivityStatistics";
import { ContractCounterViewModel } from "../ProcessingActivityStatistics.types";

export interface CompletedContractCounterProps {
    completedContractCounter: ContractCounterViewModel | undefined;
}

function CompletedContractCounter(props: CompletedContractCounterProps) {
    const styles = useProcessingActivityStatisticsStyles();
    const { translateString, translateDocumentType } = useTranslation();
    return (
        <Box>
            <DotLegalPaper backgroundColor="lightBlue" fullHeight>
                <Typography sx={styles.header}>{translateString("completedContracts")}</Typography>
                <Typography sx={styles.body}>{props?.completedContractCounter?.totalNumber}</Typography>
            </DotLegalPaper>
            <Grid sx={styles.footer} container columnSpacing={5} rowSpacing={0.5}>
                {getCounter("CTA", props!.completedContractCounter!.numberOfCTA)}
                {getCounter(translateDocumentType("5"), props!.completedContractCounter!.numberOfCooperationAgreements)}
                {getCounter(translateDocumentType("7"), props!.completedContractCounter!.numberOfPHDAgreements)}
                {getCounter(translateDocumentType("2"), props!.completedContractCounter!.numberOfDataProcessorAgreements)}
                {getCounter(translateDocumentType("9"), props!.completedContractCounter!.numberOfJointDataControllerAgreements)}
                {getCounter(translateDocumentType("6"), props!.completedContractCounter!.numberOfServiceAgreements)}
                {getCounter(translateDocumentType("8"), props!.completedContractCounter!.numberOfDisclosureAgreements)}
                {getCounter(translateDocumentType("10"), props!.completedContractCounter!.numberOfOtherAgreements)}
            </Grid>
        </Box>
    );
}

export default CompletedContractCounter;
