import { DotLegalDialog, DotLegalHeader, DotLegalMultiSelect } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../../localization/useTranslation";
import { Box } from "@mui/material";
import { Trans } from "react-i18next";
import AssociatedInternalAssessmentDialog from "../../../../common/components/questionnaireComponents/associatedInternalAssessmentDialog/AssociatedInternalAssessmentDialog";
import useProcessingActivityAssociatedInternalAssessmentDialogHooks from "./ProcessingActivityAssociatedInternalAssessmentDialog.hooks";

export interface IProcessingActivityInternalAssessmentDialogProps {
    onDialogClose: () => void;
    processingActivityName: string;
    processingActivityId: string;
    existingInternalAssessments: Array<string>;
}

function ProcessingActivityAssociatedInternalAssessmentDialog(props: IProcessingActivityInternalAssessmentDialogProps) {
    const { translateString } = useTranslation();
    const { addAssociatedInternalAssessment, removeAssociatedInternalAssessment, selectedInternalAssessments } =
        useProcessingActivityAssociatedInternalAssessmentDialogHooks(props);
    return (
        <AssociatedInternalAssessmentDialog
            onDialogClose={props.onDialogClose}
            name={props.processingActivityName}
            dialogHeader={translateString("associateAssessmentToProcessingActivity")}
            dialogInfo={
                <Trans
                    i18nKey="associateAssessmentsToProcessingActivity"
                    values={{ name: props.processingActivityName }}
                    components={{
                        wrapper: (
                            <Box sx={(theme) => ({ color: theme.palette.primary.main, fontWeight: theme.typography.fontWeightBold })} component="span"></Box>
                        ),
                    }}
                />
            }
            chosenOptions={selectedInternalAssessments}
            onAddInternalAssessment={addAssociatedInternalAssessment}
            onDeleteInternalAssessment={removeAssociatedInternalAssessment}
            existingInternalAssessments={props.existingInternalAssessments}
        />
    );
}

export default ProcessingActivityAssociatedInternalAssessmentDialog;
