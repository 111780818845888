import { PropertyType } from "../common/PropertyTypes";

export enum CustomListType {
    ProcessingActivity,
    Sharing,
    System,
    Company,
}

export enum CallPage {
    GroupCompanies,
}

export interface CustomListSaveModel {
    name: string;
    description: string;
    type: CustomListType;
}

export interface CustomListsViewModel {
    id: string;
    name: string;
    description: string;
    isStandardList: boolean;
    columns: Array<PropertyType>;
}

export enum ContentType {
    Text,
    List,
    Number,
    Date,
    Boolean,
}
