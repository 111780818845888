import { useState } from "react";
import { CloseAuditSaveModel } from "./CloseAuditDialog.types";
import { useValidator } from "../../../common/hooks/validation";
import { useTranslation } from "../../../localization/useTranslation";
import { useMutation } from "react-query";
import { put } from "../../../common/api/apiShared";
import { ICloseAuditDialog } from "./CloseAuditDialog";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";

export default function useCloseAuditDialgHooks(props: ICloseAuditDialog) {
    const { translateString } = useTranslation();
    const [saveModel, setSaveModel] = useState<CloseAuditSaveModel>({ status: undefined });
    const snackbar = useDotLegalSnackbar();

    const validation = useValidator<Partial<CloseAuditSaveModel>>((validators) => {
        return {
            status: validators.validateNotEmpty((item) => item.status, translateString("reason")),
        };
    }, []);

    const onStatusChange = (status: number) => {
        const temp = { ...saveModel };
        temp.status = status;
        setSaveModel(temp);
    };

    const closeAuditMutation = useMutation(closeAuditAPI, {
        onSuccess: () => {
            snackbar.show(translateString("auditClosed"));
            props.onAuditClosed();
        },
    });

    const onCloseClick = () => {
        if (validation.anyHasErrors) {
            validation.setShowErrors(true);
            return;
        }

        closeAuditMutation.mutateAsync();
    };

    return { saveModel, onStatusChange, validation, onCloseClick, isSaving: closeAuditMutation.isLoading };

    function closeAuditAPI() {
        return put(`/legalEntityAudit/${props.auditId}/close`, saveModel.status);
    }
}
