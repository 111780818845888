import { createElement } from "react";
import { Trans } from "react-i18next";
import { useMutation } from "react-query";
import { deleteHttp } from "../../common/api/apiShared";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { IDeleteUserDialog } from "./DeleteUserDialog";

export function DeleteUserDialogHook(props: IDeleteUserDialog) {
    const snackbar = useDotLegalSnackbar();

    const removeMutation = useMutation(remove);
    const removeUser = () => {
        removeMutation.mutateAsync(undefined, {
            onSuccess: (resp: any) => {
                const deleteResponse = resp.value();
                if (deleteResponse.isDeleted) {
                    props.onSaveCorrect();
                } else {
                    snackbar.show(createElement(Trans, { i18nKey: "userDeleteFailed", values: { name: props.userName } }), "warning");
                    props.onCloseDialog();
                }
            },
        });
    };

    async function remove() {
        return await deleteHttp<{}>(`/user/${props.userId}`);
    }

    return {
        removeUser,
        inProgess: removeMutation.isLoading,
    };
}
