import { Box } from "@mui/material";
import PurpleLockSvg from "../../common/svgs/purpleLock.svg?react";

export interface IPlanOverflowMenuItemProps {
    onClick: () => void;
    hasAccess: boolean;
    label: string;
    toolTip: string;
}

export function getPlanOverflowMenuItem(onClick: () => void, hasAccess: boolean, label: string, toolTip: string) {
    if (hasAccess) {
        return { menuItem: label, onClick: () => onClick() };
    }

    return {
        menuItem: (
            <Box
                sx={{
                    cursor: "default",
                    display: "flex",
                    alignContent: "center",
                }}
            >
                <Box component={"span"}>{label}</Box>
                <Box component={"span"} sx={{ marginLeft: 0.5, height: 0 }}>
                    <PurpleLockSvg />
                </Box>
            </Box>
        ),
        tooltip: toolTip,
        disabled: true,
    };
}
