import React from "react";
import DotLegalDeleteDialogWrapper from "../../../common/components/dotLegalDeleteDialog/DotLegalDeleteDialogWrapper";
import { PurposeSaveModel } from "../purposeDialog/PurposeDialog.types";
import { DeletePurposeDialogDataMapping } from "./DeletePurposeDialog.hooks";

export interface IDeletePurposeDialog {
    onCloseDialog: () => void;
    onSaveCorrect: () => void;
    selectedPurpose: PurposeSaveModel;
}

export function DeletePurposeDialog(props: IDeletePurposeDialog) {
    const { removePurpose, inProgess } = DeletePurposeDialogDataMapping(props);

    return (
        <DotLegalDeleteDialogWrapper
            open
            itemForDeletion={props.selectedPurpose.name}
            onDialogClose={props.onCloseDialog}
            onOkClick={removePurpose}
            inProgress={inProgess}
        />
    );
}
