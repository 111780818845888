import { useTheme } from "@mui/material";
import { createSxStyles } from "../../../../common/createSxStyles";

export const useSystemRelationSelectorStyles = () => {
    const theme = useTheme();
    const stdWidth = "50%";
    let systemContainerWidth = window.innerWidth <= 1500 ? "100%" : stdWidth;

    return createSxStyles({
        systemContainer: {
            position: "relative",
            width: stdWidth,
            paddingBottom: theme.spacing(2),
            alignSelf: "center",
        },
        container: {
            display: "flex",
            padding: theme.spacing(1),
            color: theme.palette.primary.dark,
        },
        paper: {
            backgroundColor: theme.palette.grey[100],
            width: systemContainerWidth,
            alignSelf: "center",
            paddingLeft: theme.spacing(3),
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
    });
};
