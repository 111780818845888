import React from "react";
import RecordNameWithSubNames from "./RecordNameWithSubNames.tsx";
import { DataCategoryDataTypesNames } from "../processingActivity/ProcessingActivity.types.ts";

export interface IFormattedDataCategoriesProps {
    dataCategories: Array<DataCategoryDataTypesNames>;
}

function FormattedDataCategories(props: IFormattedDataCategoriesProps) {
    if (props.dataCategories.some((d) => d.dataTypes.length > 0)) {
        return props.dataCategories.map((d) => <RecordNameWithSubNames key={d.dataCategory} name={d.dataCategory} subNames={d.dataTypes} />);
    } else {
        return props.dataCategories.map((d) => d.dataCategory).join(", ");
    }
}

export default FormattedDataCategories;
