import { DotLegalDialog, DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import React from "react";
import { Box } from "@mui/material";
import useReopenAuditDialogHooks from "./ReopenAuditDialog.hooks";
import { Trans } from "react-i18next";

export interface IReopenAuditDialog {
    onDialogClose: () => void;
    onAuditReopened: () => void;
    auditId: string;
    isSubmitted: boolean;
    name: string;
    deadline: Date;
}

function ReopenAuditDialog(props: IReopenAuditDialog) {
    const { translateString, translateDate } = useTranslation();

    const { onReopenClick, isSaving } = useReopenAuditDialogHooks(props);

    return (
        <DotLegalDialog
            buttonOkText={translateString("reopen")}
            header={props.isSubmitted ? translateString("reopenCompledAudit") : translateString("reopenAudit")}
            size="sm"
            open
            onDialogClose={props.onDialogClose}
            onOkClick={() => onReopenClick()}
            inProgress={isSaving}
        >
            <React.Fragment>
                {props.isSubmitted ? (
                    <DotLegalHeader fontWeight={400} center headerStyle="small">
                        <Trans
                            i18nKey={"reopenCompletedAuditContent"}
                            values={{
                                name: props.name.trim(),
                                deadline: translateDate(props.deadline),
                            }}
                            components={{
                                wrapper: (
                                    <Box
                                        sx={(theme) => ({ color: theme.palette.secondary.main, fontWeight: theme.typography.fontWeightBold })}
                                        component="span"
                                    ></Box>
                                ),
                            }}
                        />
                    </DotLegalHeader>
                ) : (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <DotLegalHeader fontWeight={400} center headerStyle="small">
                            {translateString("reopenAuditContent")}
                        </DotLegalHeader>
                    </Box>
                )}
            </React.Fragment>
        </DotLegalDialog>
    );
}

export default ReopenAuditDialog;
