import { DotLegalDialog, DotLegalHeader, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../../localization/useTranslation";
import React from "react";
import { EvaluationStatus, EvaluationStatusSaveModel } from "./EvaluateQuestionnaireDialog.types";
import { notSatisfied, partlySatisfied, satisfied } from "../../../icons";
import { exhaustiveGuard } from "../../../utilities";
import { Box } from "@mui/material";
import { getEnumValues } from "../../../enumOperations";
import { setFirstLetterToLowerCase } from "../../../stringOperations";
import useEvaluateQuestionnaireDialogHooks from "./EvaluateQuestionnaireDialog.hooks";

export interface IEvaluateQuestionnaireDialog {
    onDialogClose: () => void;
    onOkClick: (saveModel: EvaluationStatusSaveModel) => void;
    header: string;
    info: string;
    isSaving: boolean;
    evaluation: string | undefined;
    evaluationStatus: EvaluationStatus | undefined;
}

function EvaluateQuestionnaireDialog(props: IEvaluateQuestionnaireDialog) {
    const { translateString } = useTranslation();
    const { evaluationStatusUpdater, evaluation, onSaveClick } = useEvaluateQuestionnaireDialogHooks(props);

    function getEvaluationStatus(status: EvaluationStatus) {
        return (
            <Box
                key={status}
                sx={(theme) => ({
                    paddingTop: 2.5,
                    width: 200,
                    heigt: 120,
                    backgroundColor: status === evaluation.evaluationStatus ? theme.palette.primary.light : "white",
                    border: `2px solid ${status === evaluation.evaluationStatus ? theme.palette.primary.main : "white"}`,
                    borderRadius: 2,
                    "&:hover": {
                        cursor: "pointer",
                    },
                })}
                onClick={() => evaluationStatusUpdater.onStatusChange(status)}
            >
                <Box sx={{ display: "flex", justifyContent: "center" }}>{getIcon(status)}</Box>
                <Box sx={{ display: "flex", justifyContent: "center", marginTop: 1 }}>
                    <DotLegalHeader center headerStyle={"small"}>
                        {translateString(setFirstLetterToLowerCase(EvaluationStatus[status].toString()))}
                    </DotLegalHeader>
                </Box>
            </Box>
        );
    }

    function getIcon(status: EvaluationStatus) {
        switch (status) {
            case EvaluationStatus.NotSatisfied:
                return notSatisfied;
            case EvaluationStatus.PartlySatisfied:
                return partlySatisfied;
            case EvaluationStatus.Satisfied:
                return satisfied;
            default:
                exhaustiveGuard(status);
        }
    }

    return (
        <DotLegalDialog
            inProgress={props.isSaving}
            header={props.header}
            buttonOkText={translateString("save")}
            size="md"
            open
            onDialogClose={props.onDialogClose}
            onOkClick={onSaveClick}
        >
            <React.Fragment>
                <Box sx={{ display: "flex", justifyContent: "center", marginBottom: 1 }}>
                    <DotLegalHeader center headerStyle={"small"} fontWeight={400}>
                        {props.info}
                    </DotLegalHeader>
                </Box>
                <Box sx={{ height: 120, display: "flex", justifyContent: "center" }}>
                    <Box sx={{ display: "flex" }}>{getEnumValues(EvaluationStatus).map((x) => getEvaluationStatus(x))}</Box>
                </Box>
                <DotLegalTextField
                    multiline
                    rows={5}
                    label={translateString("evaluation")}
                    onChange={evaluationStatusUpdater.onEvaluationChange}
                    value={evaluation.evaluation ?? ""}
                    debounce={false}
                />
            </React.Fragment>
        </DotLegalDialog>
    );
}

export default EvaluateQuestionnaireDialog;
