import { useMutation, useQuery, useQueryClient } from "react-query";
import { createElement, useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { DotLegalFile, useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { DocumentModel } from "../../../common/documents/documentModel";
import { deleteHttp, get, post } from "../../../common/api/apiShared";
import { _arrayBufferToBase64 } from "../../../common/arrayBufferToBase64";
import { TaskDocumentSaveModel } from "./TaskDocuments.types";
import { ITaskDocuments } from "./TaskDocuments";

export function useTaskDocumentsHooks(props: ITaskDocuments, updateDocumentState: (hasDocuments: boolean) => void) {
    const snackbar = useDotLegalSnackbar();

    const [documentationOption, setDocumentationOption] = useState(1);
    const [taskLink, setTaskLink] = useState<string | undefined>(undefined);
    const taskId = props.taskId;
    var queryKey = "documents" + taskId;
    let documentsQuery = useQuery(queryKey, () => get<Array<DocumentModel>>(`/task/${taskId}/documents`));
    const createDocumentMutation = useMutation((document: TaskDocumentSaveModel) => post(`/task/${taskId}/documents`, document));
    const queryClient = useQueryClient();

    useEffect(() => {
        if (props.passIsDoneLoading && documentsQuery.isSuccess) {
            props.passIsDoneLoading(true);
        }
    }, [documentsQuery.isSuccess, props]);

    const removeDocumentMutation = useMutation((documentId: string) => deleteHttp(`/task/${taskId}/documents/${documentId}`), {
        onMutate: async (documentId) => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries(queryKey);
            updateDocumentState(documentsQuery.data!.filter((q) => q.id !== documentId).length > 0);
            queryClient.setQueryData<Array<DocumentModel>>(queryKey, (old) => old!.filter((o) => o.id !== documentId));
        },
    });

    const createDocuments = async (files: Array<DotLegalFile> | undefined) => {
        if (files) {
            for (const f of files) {
                await createDocumentMutation.mutateAsync({
                    fileName: f.fileName,
                    base64Data: await _arrayBufferToBase64(f.data),
                    link: undefined,
                });
            }
            await documentsQuery.refetch();
            if (files.length === 1) {
                snackbar.show(createElement(Trans, { i18nKey: "documentUploaded", values: { document: files[0].fileName } }));
            } else {
                snackbar.show(createElement(Trans, { i18nKey: "multipleDocumentsUploaded", values: { count: files.length } }));
            }
        }

        if (props.onSuccessAddDocument) {
            props.onSuccessAddDocument();
        }
    };

    const removeDocument = (documentId: string) => {
        removeDocumentMutation.mutateAsync(documentId);
        const document = documentsQuery.data?.find((d) => d.id === documentId);
        snackbar.show(createElement(Trans, { i18nKey: "documentDeleted", values: { document: document?.fileName ?? document?.link } }));
    };

    async function uploadLink() {
        await createDocumentMutation.mutateAsync({
            fileName: undefined,
            base64Data: "",
            link: taskLink,
        });
        await documentsQuery.refetch();
        snackbar.show(createElement(Trans, { i18nKey: "linkUploaded", values: { link: taskLink } }));
        setTaskLink(undefined);
    }

    return {
        documentsData: documentsQuery.data,
        createDocuments: createDocuments,
        removeDocument: removeDocument,
        documentsUploading: createDocumentMutation.isLoading,
        documentationOption,
        setDocumentationOption,
        taskLink,
        setTaskLink,
        uploadLink,
    };
}
