import { useState } from "react";
import { useMutation } from "react-query";
import { post } from "../../common/api/apiShared";
import { DotLegalSelectOption } from "../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { useTranslation } from "../../localization/useTranslation";
import { SystemTemplateTypesEnum } from "../systemEdit/SystemEdit.types";
import { CreateSystemSaveModel } from "./AddSystemDialog.types";
import { useValidator } from "../../common/hooks/validation";
import { IAddSystemDialogProps } from "./AddSystemDialog";

export function useAddSystemDialogDataMapping(props: IAddSystemDialogProps) {
    const { translateString } = useTranslation();
    const [systemModel, setSystemModel] = useState<CreateSystemSaveModel>({ name: props.searchedName ?? "", mostPopular: false, systemTypes: [] });

    const validation = useValidator<CreateSystemSaveModel>(
        (validators) => ({
            name: validators.validateNotEmpty((item) => item.name, translateString("name")),
        }),
        []
    );

    const saveMutation = useMutation(save, {
        onSuccess: (response: any) => {
            const id = response.value().id;

            props.onSystemCreated(id);
        },
    });

    let templateTypes: Array<DotLegalSelectOption> = Object.entries(SystemTemplateTypesEnum)
        .filter((q) => isNaN(Number(q[0])))
        .map((q) => {
            return {
                name: translateString(q[0].toLowerCase()),
                id: q[1].toString(),
            };
        });

    const createSystem = async () => {
        if (validation.anyHasErrors) {
            validation.setShowErrors(true);
            return;
        }

        await saveMutation.mutateAsync(systemModel);
    };

    function save(model: CreateSystemSaveModel) {
        return post<{}>("/System/", ConvertToSaveModel(model));
    }

    return {
        inProgress: saveMutation.isLoading,
        createSystem,
        systemModel,
        setSystemModel,
        validation,
        templateTypes,
    };
}

function ConvertToSaveModel(model: CreateSystemSaveModel) {
    return {
        name: model.name,
        systemTypes: model.systemTypes.map((x) => {
            return Number(x);
        }),
        mostPopular: model.mostPopular,
    };
}
