import { DragDropColumn, DragDropItem } from "./DotLegalDragNDrop.types";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { useDotLegalDragNDropHooks } from "./DotLegalDragNDrop.hooks";
import { getItemStyle, getListStyle, useDotLegalDragNDropStyles } from "./DotLegalDragNDrop.styles";
import { Box, Grid, Skeleton, Theme, Typography, useTheme } from "@mui/material";
import React, { Fragment } from "react";
import { DotLegalChip } from "@dotlegal/dotlegal-ui-components";
import { GridSizes } from "../dotLegalTable/DotLegalTable.types";
import { StrictModeDroppable } from "./StrictModeDroppable";

export interface IDotLegalDragNDrop {
    items: Array<DragDropColumn>;
    isLoading: boolean;
    onDragEnd: (sourceId: string, sourceColumn: number, destinationId: string, destinationColumn: number) => void;
    onColumnDragFailed?: (sourceId: string) => void;
    disableBackgroundColor?: boolean;
    removeTopPadding?: boolean;
    removeFullHeight?: boolean;
    disabled?: boolean;
    gridSizes: GridSizes;
}

class InnerList extends React.Component<{ tasks: Array<DragDropItem>; itemInd: number; theme: Theme; disabled?: boolean }> {
    shouldComponentUpdate(
        nextProps: Readonly<{ tasks: Array<DragDropItem>; itemInd: number; theme: Theme; disabled?: boolean }>,
        nextState: Readonly<{}>,
        nextContext: any
    ): boolean {
        if (nextProps.tasks === this.props.tasks) {
            return false;
        }
        return true;
    }

    render() {
        return (
            <Fragment>
                {this.props.tasks.map((task, index) => (
                    <Draggable key={task.id} draggableId={task.id} index={index} isDragDisabled={this.props.disabled}>
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                    snapshot.draggingOver,
                                    task.columnWithDeniedAccess,
                                    snapshot.isDragging,
                                    this.props.theme,
                                    provided.draggableProps.style,
                                    this.props.disabled
                                )}
                            >
                                {task.content}
                            </div>
                        )}
                    </Draggable>
                ))}
            </Fragment>
        );
    }
}

export default function DotLegalDragNDrop(props: IDotLegalDragNDrop) {
    const theme = useTheme();
    const { onDragEnd } = useDotLegalDragNDropHooks(props);
    const styles = useDotLegalDragNDropStyles(props);
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Grid container spacing={2} sx={styles.root}>
                {props.items.map((item, ind) => (
                    <Grid key={ind} item {...props.gridSizes}>
                        <Box sx={styles.itemContainer}>
                            {item.title && (
                                <Box sx={styles.headerContainer}>
                                    <Typography sx={styles.fontSize}>{item.title}</Typography>
                                    <DotLegalChip
                                        isLoading={props.isLoading}
                                        alternativeColor={{ backgroundColor: "primary.main", color: "common.white" }}
                                        value={item.tasks.length.toString()}
                                        size="small"
                                        textSize="xl"
                                    />
                                </Box>
                            )}
                            <StrictModeDroppable droppableId={`${ind}`}>
                                {(provided, snapshot) => (
                                    <Box
                                        ref={provided.innerRef}
                                        sx={(theme1) => getListStyle(snapshot.isDraggingOver, theme1, props.disableBackgroundColor)}
                                        {...provided.droppableProps}
                                    >
                                        {props.isLoading ? (
                                            <Skeleton height={155} variant={"rectangular"} />
                                        ) : (
                                            <InnerList tasks={item.tasks} itemInd={ind} theme={theme} disabled={props.disabled} />
                                        )}

                                        {provided.placeholder}
                                    </Box>
                                )}
                            </StrictModeDroppable>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </DragDropContext>
    );
}
