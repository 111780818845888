import EvaluateQuestionnaireDialog from "../../../common/components/questionnaireComponents/evaluateQuestionnaireDialog/EvaluateQuestionnaireDialog";
import { EvaluationStatus } from "../../../common/components/questionnaireComponents/evaluateQuestionnaireDialog/EvaluateQuestionnaireDialog.types";
import { useTranslation } from "../../../localization/useTranslation";
import useEvaluateAuditDialogHooks from "./EvaluateAuditDialog.hooks";

export interface IEvaluateAuditDialog {
    onEvaluated: () => void;
    onDialogClose: () => void;
    evaluation: string | undefined;
    evaluationStatus?: EvaluationStatus;
    id: string;
    questionnaireName: string;
}

function EvaluateAuditDialog(props: IEvaluateAuditDialog) {
    const { translateString } = useTranslation();
    const { saveEvaluation, isSaving } = useEvaluateAuditDialogHooks(props);

    return (
        <EvaluateQuestionnaireDialog
            onDialogClose={props.onDialogClose}
            onOkClick={saveEvaluation}
            header={translateString("evaluateAuditHeader")}
            info={translateString("evaluateAuditDescription")}
            isSaving={isSaving}
            evaluation={props.evaluation}
            evaluationStatus={props.evaluationStatus}
        />
    );
}

export default EvaluateAuditDialog;
