import { useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";

export const useLegalBasisSelectorStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        dataCategoryContainer: {
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
        },
        dataCategory: {
            fontSize: theme.typography.pxToRem(12),
            color: theme.palette.primary.dark,
            marginLeft: theme.spacing(1),
        },
        container: {
            [theme.breakpoints.up("xs")]: {
                width: "250px",
            },
        },
        tooltip: {
            marginLeft: theme.spacing(1),
        },
        overhead: {
            display: "flex",
            alignItems: "center",
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            [theme.breakpoints.down("xs")]: {
                flexDirection: "column",
                alignItems: "normal",
            },
        },
        gridContainer: {
            flex: 1,
        },
        exclamationIcon: {
            fill: theme.palette.error.main,
            height: 18 + "px",
            width: 18 + "px",
        },
    });
};
