import { useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";

export const useNotificationMenuStyle = () => {
    const theme = useTheme();
    return createSxStyles({
        menu: {
            "& .MuiMenu-paper": {
                width: 385,
                padding: theme.spacing(2),
            },
        },
        menuContentContainer: {
            display: "flex",
            flexDirection: "column",
        },
    });
};
