import { useState } from "react";
import { IChangeInternalAssessmentResponsibleDialog } from "./ChangeInternalAssessmentResponsibleDialog";
import { useMutation } from "react-query";
import { useResponsibleQuery } from "../../user/hooks/useResponsibleQuery";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { put } from "../../common/api/apiShared";
import { useTranslation } from "../../localization/useTranslation";

export function useChangeInternalAssessmentResponsibleDialogHooks(props: IChangeInternalAssessmentResponsibleDialog) {
    const { translateString } = useTranslation();
    const [responsible, setResponsible] = useState(props.responsible);
    const snackbar = useDotLegalSnackbar();

    let responsibleQuery = useResponsibleQuery(true);

    const changeResponsibleMutation = useMutation(changeResponsibleApi, {
        onSuccess: () => {
            snackbar.show(translateString("responsibleChanged"));
            props.onChangedResponsible();
        },
    });

    const onChangeResponsibleClick = () => {
        changeResponsibleMutation.mutateAsync();
    };

    return {
        data: responsibleQuery.data(props.responsible),
        isLoading: responsibleQuery.isLoading,
        responsible,
        isChanging: changeResponsibleMutation.isLoading,
        setResponsible,
        onChangeResponsibleClick,
    };

    function changeResponsibleApi() {
        return put(`/internalAssessments/${props.internalAssessmentId}/changeResponsible`, responsible);
    }
}
