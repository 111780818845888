import { createElement, useEffect, useState } from "react";
import { DeclarationControlSaveModel } from "./DeclarationControlDialog.types";
import { useMutation, useQuery } from "react-query";
import { TaskRelationRow } from "../../../taskManagement/editTaskDialog/taskRelations/TaskRelations.types";
import { get, post, put } from "../../../common/api/apiShared";
import { IDeclarationControlDialog } from "./DeclarationControlDialog";
import { useValidator } from "../../../common/hooks/validation";
import { useTranslation } from "../../../localization/useTranslation";
import { SelectableItem } from "../../../processingActivity/ProcessingActivity.types";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { Trans } from "react-i18next";
import { useResponsibleQuery } from "../../../user/hooks/useResponsibleQuery";

export default function useDeclarationControlDialogHooks(props: IDeclarationControlDialog) {
    const snackbar = useDotLegalSnackbar();
    const { translateString } = useTranslation();
    const [saveModel, setSaveModel] = useState<DeclarationControlSaveModel>(getSaveModel());
    const isEdit = props.declarationControlId !== undefined;

    const url = `/taskRelation/selectableTasks?deadline=${props.deadline}`;
    let { data } = useQuery(url, () => get<Array<TaskRelationRow>>(url));

    const declarationControlUrl = `/declarationcontrol/${props.declarationControlId}`;
    let declarationControlQuery = useQuery(declarationControlUrl, () => get<DeclarationControlSaveModel>(declarationControlUrl), {
        enabled: props.declarationControlId !== undefined,
    });

    useEffect(() => {
        if (declarationControlQuery.data) {
            setSaveModel(getSaveModel(declarationControlQuery.data));
        }
    }, [declarationControlQuery.data]);

    const responsibleQuery = useResponsibleQuery(true);
    let selectableResponsiblesData = responsibleQuery.userData;
    let selectableresponsiblesLoading = responsibleQuery.isLoading;

    const validation = useValidator<DeclarationControlSaveModel>(
        (validators) => ({
            name: validators.validateNotEmpty((item) => item.name, translateString("name")),
            responsibleId: validators.validateNotEmpty((item) => item.responsibleId, translateString("responsible")),
        }),
        []
    );

    const saveDeclarationControlMutation = useMutation(createDeclarationControlApiUrl);

    const saveDeclarationControl = () => {
        saveDeclarationControlMutation.mutateAsync(saveModel!, {
            onSuccess: (response: any) => {
                snackbar.show(createElement(Trans, { i18nKey: "itemCreatedSuccess", values: { name: saveModel.name } }));
                props.onControlSaved();
            },
        });
    };

    const editDeclarationControlMutation = useMutation(editDeclarationControlApiUrl);

    const editDeclarationControl = () => {
        editDeclarationControlMutation.mutateAsync(saveModel!, {
            onSuccess: (response: any) => {
                snackbar.show(createElement(Trans, { i18nKey: "itemEditedSuccess", values: { name: saveModel.name } }));
                props.onControlSaved();
            },
        });
    };

    const modelUpdater = {
        onNameChange: (name: string) => {
            var temp = { ...saveModel };
            temp.name = name;
            setSaveModel(temp);
        },
        onIDChange: (id: string) => {
            var temp = { ...saveModel };
            temp.customId = id;
            setSaveModel(temp);
        },
        onDescriptionChange: (description: string) => {
            var temp = { ...saveModel };
            temp.description = description;
            setSaveModel(temp);
        },
        onResponsibleChanged: (responsible: string | null) => {
            const temp = { ...saveModel! };
            temp.responsibleId = responsible ?? undefined;
            setSaveModel(temp);
        },
        onCategoryChanged: (category: SelectableItem | undefined) => {
            const temp = { ...saveModel! };
            temp.categoryId = category?.id ?? undefined;
            setSaveModel(temp);
        },
        onAddTask: (taskId: string) => {
            const temp = { ...saveModel! };
            temp.taskIds.push(taskId);
            setSaveModel(temp);
        },
        onRemoveTask: (taskId: string) => {
            const temp = { ...saveModel! };
            temp.taskIds = temp.taskIds.filter((id) => id !== taskId);
            setSaveModel(temp);
        },
    };

    function onSaveClick() {
        if (validation.anyHasErrors) {
            validation.setShowErrors(true);
            return;
        }

        if (isEdit) {
            editDeclarationControl();
        } else {
            saveDeclarationControl();
        }
    }

    return {
        saveModel,
        setSaveModel,
        modelUpdater,
        data,
        validation,
        onSaveClick,
        isSaving: saveDeclarationControlMutation.isLoading || editDeclarationControlMutation.isLoading,
        selectableResponsiblesData,
        selectableresponsiblesLoading,
        isLoading: declarationControlQuery.isLoading,
        isEdit,
    };

    function createDeclarationControlApiUrl(saveModel: DeclarationControlSaveModel) {
        return post<string>(`/declarationControl/${props.declarationId}`, saveModel);
    }

    function editDeclarationControlApiUrl(saveModel: DeclarationControlSaveModel) {
        return put<string>(`/declarationControl/${props.declarationControlId}/`, saveModel);
    }
}

function getSaveModel(saveModel?: DeclarationControlSaveModel): DeclarationControlSaveModel {
    return {
        categoryId: saveModel?.categoryId ?? undefined,
        customId: saveModel?.customId ?? "",
        description: saveModel?.description ?? "",
        name: saveModel?.name ?? "",
        responsibleId: saveModel?.responsibleId ?? undefined,
        taskIds: saveModel?.taskIds ?? [],
    };
}
