import { SelectableItem } from "../../../processingActivity/ProcessingActivity.types";

export interface SystemControlProcedureViewModel {
    id: string;
    type: SystemControlProcedureType | null | undefined;
    frequency: SystemControlProcedureFrequency;
    riskScore?: number;
    completedControls: Array<CompletedControlProcedureViewModel>;
    documents: Array<SelectableItem>;
}

export interface CompletedControlProcedureViewModel {
    id: string;
    completionDate: Date;
    type: SystemControlProcedureType;
    documentation: string;
}

export interface SystemControlProcedureSaveModel {
    type?: SystemControlProcedureType;
    frequency?: SystemControlProcedureFrequency;
}

export interface LatestCompletedSystemAudit {
    type: SystemControlProcedureType;
    completetionDate: Date;
}

export enum SystemControlProcedureType {
    ControlNotNecessary = 1,
    WrittenConfirmation = 2,
    Declaration = 3,
    AuditStatement = 4,
    SelfAssessment = 5,
    Other = 6,
}

export enum SystemControlProcedureFrequency {
    Quarterly = 1,
    SemiAnnually = 2,
    Annual = 3,
    EveryTwoYears = 4,
    EveryThreeYears = 5,
}
