import { useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";

export const useRiskAssessmentsStyles = (isPdf: boolean) => {
    const theme = useTheme();
    return createSxStyles({
        noDataInfoHeader: {
            color: theme.palette.primary.main,
            marginTop: theme.spacing(1),
            fontWeight: theme.typography.fontWeightBold,
            fontSize: theme.typography.pxToRem(14),
        },
        noDataInfoBody: {
            color: theme.palette.grey[400],
            marginTop: theme.spacing(1),
            fontWeight: theme.typography.fontWeightBold,
            fontSize: theme.typography.pxToRem(12),
            whiteSpace: "pre-line",
        },
        noDataInfoBtn: {
            marginTop: theme.spacing(2.5),
        },
        bold: {
            fontWeight: theme.typography.fontWeightBold,
        },
        topPaper: {
            marginBottom: theme.spacing(4),
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
        },
        gridContainer: {
            marginBottom: theme.spacing(1.5),
            alignItems: "center",
        },
        switchBoxContainer: {
            display: "inline-block",
        },
        riskAssessmentScoreToolTip: {
            margin: theme.spacing(1),
        },
        paperHeader: {
            display: "flex",
            alignItems: "center",
            height: 116,
            marginBottom: theme.spacing(2),
            position: "relative",
            justifyContent: "space-between",
        },
        percentageContainer: {
            display: "flex",
            alignItems: "last baseline",
        },
        gridAlignment: {
            display: "flex",
            justifyContent: "flex-end",
        },
        noRiskTemplatesArrow: {
            position: "absolute",
            top: -58,
            left: 100,
            [theme.breakpoints.down("md")]: {
                display: "none",
            },
        },
        relativePosition: {
            position: "relative",
        },
        chip: {
            marginRight: theme.spacing(1),
            padding: isPdf ? "4px" : "none",
            paddingLeft: 0,
        },
        chipContainer: {
            display: "flex",
            alignItems: "center",
            flexWrap: isPdf ? "wrap" : "nowrap",
        },
    });
};
