import { DotLegalCheckbox, DotLegalDialog, DotLegalHeader, DotLegalSelect } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import React from "react";
import { Box, Collapse } from "@mui/material";
import useLegalEntityThirdCountryDialogHooks from "./LegalEntityThirdCountryDialog.hooks";
import { LegalEntityThirdCountrySettingsViewModel } from "./LegalEntityThirdCountryDialog.types";
import { LevelOfProtectionType } from "../addLegalEntityDialog/AddLegalEntityDialog.types";

export interface ILegalEntityThirdCountryDialog {
    thirdCountrySettings: LegalEntityThirdCountrySettingsViewModel;
    legalEntityId: string;
    onDialogClose: () => void;
    onSaved: () => void;
}

function LegalEntityThirdCountryDialog(props: ILegalEntityThirdCountryDialog) {
    const { translateString } = useTranslation();

    const { LevelOfProtections, viewModel, updateSaveModel, onSaveClick, isSaving } = useLegalEntityThirdCountryDialogHooks(props);

    function getSelectedLevelOfProtection() {
        if (viewModel.levelOfProtection === LevelOfProtectionType.AdequateProtection) {
            return LevelOfProtectionType.CPIEDA;
        }

        return viewModel.levelOfProtection;
    }

    return (
        <DotLegalDialog
            size="sm"
            header={translateString("thirdCountrySettings")}
            buttonOkText={translateString("save")}
            open
            onDialogClose={props.onDialogClose}
            onOkClick={() => onSaveClick()}
            inProgress={isSaving}
            disableBackdropOnCloseIfValueChange={viewModel}
        >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <DotLegalHeader center fontWeight={400} headerStyle="small">
                    {translateString("thirdCountrySettingsContent", { country: props.thirdCountrySettings.country })}
                </DotLegalHeader>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box sx={{ flexDirection: "column", minWidth: 350 }}>
                    <DotLegalCheckbox
                        checked={viewModel.isTIAUnnecessary}
                        onChange={updateSaveModel.onIsTIAUnnecessaryChange}
                        label={translateString("tiaIsUnnecessary")}
                    />

                    <DotLegalCheckbox
                        checked={viewModel.isTransferBasisUnnecessary}
                        onChange={updateSaveModel.onIsTransferBasisUnnecessaryChange}
                        label={translateString("transferBasisNotRequired")}
                    />
                    {(props.thirdCountrySettings.countryCode === "US" || props.thirdCountrySettings.countryCode === "CA") && (
                        <React.Fragment>
                            <DotLegalSelect
                                label={translateString("levelOfProtection")}
                                options={LevelOfProtections}
                                placeholder={translateString("levelOfProtection")}
                                selectedItem={LevelOfProtectionType[getSelectedLevelOfProtection()]}
                                onChange={(item) => updateSaveModel.onLevelOfProtectionChange(item!)}
                                noOptionsLabel={translateString("noOptions")}
                                disableClearable
                            />
                            <Collapse
                                in={
                                    props.thirdCountrySettings.showCheckBoxIfLevelOfProtectionIsDataPrivacyFramework &&
                                    viewModel.levelOfProtection === LevelOfProtectionType.DataPrivacyFramework
                                }
                            >
                                <DotLegalCheckbox
                                    checked={viewModel.addDataPrivacyFrameworkToSharings}
                                    onChange={updateSaveModel.onAddDataPrivacyFrameworkToSharingsChange}
                                    label={translateString("transferTransferBasis")}
                                    tooltip={translateString("transferDataPrivacyFrameworkToSharings")}
                                />
                            </Collapse>
                        </React.Fragment>
                    )}
                </Box>
            </Box>
        </DotLegalDialog>
    );
}

export default LegalEntityThirdCountryDialog;
