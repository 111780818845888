import { DotLegalDialog, DotLegalHeader, DotLegalTable, ITableHeader } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { Box, LinearProgress, TableCell, TableRow } from "@mui/material";
import { LegalEntityType } from "../../../legalEntity/addLegalEntityDialog/AddLegalEntityDialog.types";
import { setFirstLetterToLowerCase } from "../../../common/stringOperations";
import { LegalEntityAuditGroupSendModel } from "./SendAuditsDialog.types";
import useSendAuditsDialogHooks from "./SendAuditsDialog.hooks";
import { LegalEntityMasterType } from "../../../legalEntity/legalEntities/LegalEntities.types";

export interface ISendAuditsDialog {
    selectedEntities: Array<LegalEntityAuditGroupSendModel>;
    onDialogClose: () => void;
    auditGroupId?: string;
    auditGroupName: string;
}

function SendAuditsDialog(props: ISendAuditsDialog) {
    const { translateString, translateCountry } = useTranslation();
    const { send, uploadState, isSending, uploadCounter } = useSendAuditsDialogHooks(props);

    function getHeaders() {
        let headers = Array<ITableHeader<LegalEntityAuditGroupSendModel>>();
        headers.push({ property: "legalEntityName", text: translateString("legalEntity"), align: "left", showOnMobile: true });
        headers.push({ property: "legalEntityTypes", text: translateString("type"), align: "left", showOnMobile: true });
        headers.push({ property: "countryCode", text: translateString("country"), align: "left", showOnMobile: true });
        headers.push({ property: "contactName", text: translateString("contact"), align: "left", showOnMobile: true });
        headers.push({ property: "contactEmail", text: translateString("contactEmail"), align: "left", showOnMobile: true });
        headers.push({ property: "systemNames", text: translateString("assets"), align: "left", showOnMobile: true });

        return headers;
    }

    const formatLegalEntityTypes = (legalEntityTypes: Array<LegalEntityMasterType>) => {
        return legalEntityTypes
            .map((x) => {
                return translateString(setFirstLetterToLowerCase(LegalEntityMasterType[x].toString()));
            })
            .join(", ");
    };

    return (
        <DotLegalDialog
            buttonOkText={`${translateString("Send")} (${props.selectedEntities.length})`}
            open
            onDialogClose={props.onDialogClose}
            onOkClick={() => send()}
            size="xl"
            buttonRight
            header={translateString("sendAudits")}
            inProgress={isSending}
        >
            <DotLegalTable
                headers={getHeaders()}
                hideRowsPerPage
                defaultOrderBy={"legalEntityName"}
                defaultOrder={"asc"}
                isLoading={false}
                paginationLabelOf={translateString("labelOf")}
                data={props.selectedEntities}
                clickableRows={false}
                renderRow={(row) => {
                    return (
                        <TableRow key={row.legalEntityId}>
                            <TableCell>{row.legalEntityName}</TableCell>
                            <TableCell>{formatLegalEntityTypes(row.legalEntityTypes)}</TableCell>
                            <TableCell>{translateCountry(row.countryCode)}</TableCell>
                            <TableCell>{row.contactName}</TableCell>
                            <TableCell>{row.contactEmail}</TableCell>
                            <TableCell>{row.systemNames.join(", ")}</TableCell>
                        </TableRow>
                    );
                }}
            />

            <Box sx={{ height: 20, visibility: isSending ? "visible" : "hidden" }} marginTop={2} marginBottom={2}>
                <DotLegalHeader headerStyle={"small"} center>
                    {`Sender ${uploadCounter} af ${props.selectedEntities.length}`}
                </DotLegalHeader>
                <LinearProgress sx={{ height: 10, borderRadius: 5 }} variant="determinate" value={uploadState} />
            </Box>
        </DotLegalDialog>
    );
}

export default SendAuditsDialog;
