import { Box, Typography } from "@mui/material";
import React from "react";
import AddLegalEntityDialog from "../../../legalEntity/addLegalEntityDialog/AddLegalEntityDialog";
import { useTranslation } from "../../../localization/useTranslation";
import { useUrlProvider } from "../../../useUrlProvider";
import { SelectableLegalUnitsItem } from "../../ProcessingActivity.types";
import { useSharingsLegalEntityHook } from "./sharingsLegalEntity.hooks";
import { useSharingsLegalEntityStyles } from "./useSharingsLegalEntityStyles";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import AddSimpleLegalEntityDialog from "../../../legalEntity/addLegalEntityDialog/AddSimpleLegalEntityDialog";
import DataStorageLocationDialog from "../dataProcessorsStep/dataStorageLocationDialog/DataStorageLocationDialog";
import { DotLegalSelectOption } from "../../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import DotLegalSelectWithButton from "../../../common/components/dotLegalSelectWithButton/DotLegalSelectWithButton";

export interface ISharingsLegalEntity {
    options: Array<SelectableLegalUnitsItem> | undefined;
    selectedItem?: string | null;
    disabled?: boolean;
    placeholder: string;
    label: string;
    className?: string;
    isLoading?: boolean;
    toolTipText?: string;
    helperText?: string;
    errorText?: string;
    onLegalEntityChange: (legalEntity: string | null) => void;
    showAddNewEntityButton: boolean;
    showStorageLocationLink?: boolean;
    isThirdCountry?: boolean;
    onDataStorageLocationSave?: (storageLocations: Array<string>, supportLocations: Array<string>) => void;
    countriesLoading?: boolean;
    countryList?: Array<DotLegalSelectOption>;
    selectedStorageLocations?: Array<string>;
    selectedSupportLocations?: Array<string>;
    warningText?: string;
}

export default function SharingsLegalEntity(props: ISharingsLegalEntity) {
    const styles = useSharingsLegalEntityStyles(props);
    const { translateString } = useTranslation();
    const { permissions } = useUserContext();
    const {
        showAddLegalEntityDialog,
        setShowAddLegalEntityDialog,
        addLegalEntity,
        toggleCompanyLink,
        setShowStorageLocationDialog,
        showStorageLocationDialog,
    } = useSharingsLegalEntityHook(props);
    const { getLegalEntityUrl, getGroupEntityUrl } = useUrlProvider();

    function getLink() {
        if (toggleCompanyLink.showGroupCompanyLink) {
            return getGroupEntityUrl(toggleCompanyLink.companyId);
        } else if (toggleCompanyLink.showCompanyLink) {
            return getLegalEntityUrl(toggleCompanyLink.companyId);
        }
    }

    const onClickCreateNewCompany = () => {
        if (!props.disabled) {
            setShowAddLegalEntityDialog(true);
        }
    };

    let dataProcessorSelect = (
        <DotLegalSelectWithButton
            selectedItem={props.selectedItem}
            isLoading={props.isLoading}
            disabled={props.disabled}
            errorText={props.errorText}
            label={props.label}
            placeholder={props.placeholder}
            onChange={props.onLegalEntityChange}
            options={props.options}
            toolTipText={props.toolTipText}
            warningText={props.isThirdCountry ? (props.warningText ? props.warningText : translateString("insecureThirdCountry")) : ""}
            link={toggleCompanyLink.showLink() ? getLink() : undefined}
            noOptionsLabel={translateString("noOptions")}
            buttonLabel={translateString("createCompany")}
            onButtonClick={onClickCreateNewCompany}
            hasPermission={props.showAddNewEntityButton}
        />
    );
    return (
        <>
            {props.showAddNewEntityButton || props.showStorageLocationLink ? (
                <Box sx={styles.container}>
                    {dataProcessorSelect}
                    <Box sx={styles.linkLabel}>
                        {props.showStorageLocationLink && (
                            <Typography onClick={() => setShowStorageLocationDialog(true)} sx={styles.descriptionLabel}>
                                {props.disabled ? translateString("seeStorageLocation") : translateString("specifyStorageLocation")}
                            </Typography>
                        )}

                        {props.showAddNewEntityButton && (
                            <Typography onClick={onClickCreateNewCompany} sx={styles.descriptionLabel}>
                                {translateString("createCompany")}
                            </Typography>
                        )}
                    </Box>
                    {showStorageLocationDialog && (
                        <DataStorageLocationDialog
                            countriesLoading={props.countriesLoading ?? false}
                            countryList={props.countryList!}
                            storageLocations={props.selectedStorageLocations!}
                            supportLocations={props.selectedSupportLocations!}
                            readOnly={props.disabled ?? false}
                            onCloseDialog={() => setShowStorageLocationDialog(false)}
                            onSave={(storageLocations: Array<string>, supportLocations: Array<string>) => {
                                setShowStorageLocationDialog(false);
                                props.onDataStorageLocationSave!(storageLocations, supportLocations);
                            }}
                        ></DataStorageLocationDialog>
                    )}
                    {showAddLegalEntityDialog &&
                        !props.isLoading &&
                        (permissions.companyPermissions.create ? (
                            <AddLegalEntityDialog
                                isSimpleDialog={false}
                                onDialogClose={() => setShowAddLegalEntityDialog(false)}
                                onEntityCreated={(id) => addLegalEntity(id)}
                            />
                        ) : (
                            <AddSimpleLegalEntityDialog
                                isSimpleDialog
                                onDialogClose={() => setShowAddLegalEntityDialog(false)}
                                onEntityCreated={(id) => addLegalEntity(id)}
                            />
                        ))}
                </Box>
            ) : (
                <>{dataProcessorSelect}</>
            )}
        </>
    );
}
