import { DotLegalSelectOption } from "../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";

export interface LegalBasisSelectorViewModel {
    directives: Array<LegalBasisSelectOption>;
    nationalLaws: Array<LegalBasisSelectOption>;
    doubleLegalBases: Array<LegalBasisSelectOption>;
}

export class LegalBasisSelectOption implements DotLegalSelectOption {
    name: string;
    id: string;
    balanceOfInterests: boolean;

    constructor(name: string, id: string, balanceOfInterests: boolean) {
        this.name = name;
        this.id = id;
        this.balanceOfInterests = balanceOfInterests;
    }
}

export enum LegalBasisType {
    Directive,
    NationalLaw,
    DoubleLegalBasis,
}

export class ToggleAddDialog {
    directive: { displayOnClick: boolean; isDisplayed: boolean };
    nationalLaw: { displayOnClick: boolean; isDisplayed: boolean };
    doubleLegalBasis: { displayOnClick: boolean; isDisplayed: boolean };
    deletionPeriod: { displayOnClick: boolean; isDisplayed: boolean };
    selectedEntityId: string;

    constructor() {
        this.directive = { displayOnClick: true, isDisplayed: false };
        this.nationalLaw = { displayOnClick: true, isDisplayed: false };
        this.doubleLegalBasis = { displayOnClick: true, isDisplayed: false };
        this.deletionPeriod = { displayOnClick: true, isDisplayed: false };
        this.selectedEntityId = "";
    }
}
