import { useMutation } from "react-query";
import { put } from "../../../common/api/apiShared";
import { IReopenAuditDialog } from "./ReopenAuditDialog";
import { useTranslation } from "../../../localization/useTranslation";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";

export default function useReopenAuditDialogHooks(props: IReopenAuditDialog) {
    const { translateString } = useTranslation();
    const snackbar = useDotLegalSnackbar();

    const reopenAuditMutation = useMutation(reopenAuditAPI, {
        onSuccess: () => {
            snackbar.show(translateString("auditReopened"));
            props.onAuditReopened();
        },
    });

    const onReopenClick = () => {
        reopenAuditMutation.mutateAsync();
    };

    return { onReopenClick, isSaving: reopenAuditMutation.isLoading };

    function reopenAuditAPI() {
        return put(`/legalEntityAudit/${props.auditId}/reopen`, null);
    }
}
