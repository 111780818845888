import React, { useState } from "react";
import { DotLegalButton, DotLegalPageHeader, useStateLocalStorage, useStateSessionStorage } from "@dotlegal/dotlegal-ui-components";
import DotLegalAllOrMySwitchBox from "../common/components/dotLegalSwitch/dotLegalAllOrMySwitchBox/dotLegalAllOrMySwitchBox.tsx";
import { useUserContext } from "../auth/userContextProvider/UserContextProvider.tsx";
import { useTranslation } from "../localization/useTranslation.ts";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import ProcessingActivityCountWiget from "./processingActivityCountWidget/ProcessingActivityCountWiget.tsx";
import VendorsCountWidget from "./vendorsCountWidget/VendorsCountWidget.tsx";
import RiskWidget from "./riskWidget/RiskWidget.tsx";
import SystemsCountWidget from "./systemsCountWidget/SystemsCountWidget.tsx";
import TasksCountWidget from "./tasksCountWidget/TasksCountWidget.tsx";
import TasksWidget from "./tasksWidget/TaskWidget.tsx";
import TaskOverviewWidget from "./taskOverviewWidget/TaskOverviewWidget.tsx";
import LatestAuditsSubmittedWidget from "./latestAuditsSubmittedWidget/LatestAuditsSubmittedWidget.tsx";
import DotLegalCompanySelector from "../common/components/dotLegalCompanySelector/DotLegalCompanySelector.tsx";
import { useGroupEntitiesForUserQuery } from "../common/hooks/useSelectableItemQueries.ts";
import IncidentLogWidget from "./incidentLogWidget/IncidentLogWidget.tsx";
import { useDotLegalCompanySelector } from "../common/components/dotLegalCompanySelector/GroupCompanySelectorContext.tsx";
import ComplianceReportDialog from "./complianceReport/complianceReportDialog/ComplianceReportDialog.tsx";

export interface IDashboardProps {}

function Dashboard(props: IDashboardProps) {
    const { translateString } = useTranslation();
    const theme = useTheme();
    const isOnXlScreen = useMediaQuery(theme.breakpoints.up("xl"), { noSsr: true });
    const { permissions, customerName, fullName } = useUserContext();
    const [onlyUserSpecific, setOnlyUserSpecific] = useStateSessionStorage("recordOnlyUserSpecific", !permissions.canAccessAllData);
    const { selectedGroupCompany } = useDotLegalCompanySelector();
    const [showReportDialog, setShowReportDialog] = useState(false);

    let groupEntitiesForUserQuery = useGroupEntitiesForUserQuery();

    let mbCompensation = isOnXlScreen ? -2 : 0;

    const heightTaskAndRisk = isOnXlScreen ? "auto" : "740px";
    const heightOtherWidgets = isOnXlScreen ? "50%" : "450px";
    const minHeightOtherWidgets = "311px";
    return (
        <>
            <DotLegalPageHeader
                userContext={{ customerName }}
                breadCrumbs={[
                    {
                        name: translateString("userGreeting", { firstName: fullName.substring(0, fullName.indexOf(" ")) }),
                    },
                ]}
                rightComponent={
                    <Box sx={{ display: "flex", gap: 2 }}>
                        <DotLegalButton buttonType="primary" onClick={() => setShowReportDialog(true)}>
                            {translateString("complianceReport")}
                        </DotLegalButton>
                        <DotLegalCompanySelector isLoading={groupEntitiesForUserQuery.isLoading} companies={groupEntitiesForUserQuery.data ?? []} />
                    </Box>
                }
                leftComponent={<DotLegalAllOrMySwitchBox onChange={setOnlyUserSpecific} enabled={onlyUserSpecific} hidden={!permissions.canAccessAllData} />}
            />
            <Box sx={{ height: "100%", display: "flex", flexDirection: "column", mb: mbCompensation }}>
                <Box id={"counter-widgets"} sx={{ padding: 2, backgroundColor: "#EEEFFA", borderRadius: "20px", mb: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item lg={6} xs={12} xl={3}>
                            <ProcessingActivityCountWiget onlyUserSpecific={onlyUserSpecific} selectedGroupCompany={selectedGroupCompany} />
                        </Grid>
                        <Grid item lg={6} xs={12} xl={3}>
                            <SystemsCountWidget onlyUserSpecific={onlyUserSpecific} />
                        </Grid>
                        <Grid item lg={6} xs={12} xl={3}>
                            <VendorsCountWidget onlyUserSpecific={onlyUserSpecific} />
                        </Grid>
                        <Grid item lg={6} xs={12} xl={3}>
                            <TasksCountWidget onlyUserSpecific={onlyUserSpecific} selectedGroupCompany={selectedGroupCompany} />
                        </Grid>
                    </Grid>
                </Box>

                {/* Bottom Row Filling Remaining Space */}
                <Box style={{ flexGrow: 1 }}>
                    <Grid id={"helpfull-widgets"} container spacing={2} sx={{ height: "100%" }}>
                        <Grid item md={6} xl={3} xs={12} sx={{ height: heightTaskAndRisk }}>
                            <TasksWidget onlyUserSpecific={onlyUserSpecific} selectedGroupCompany={selectedGroupCompany} />
                        </Grid>
                        <Grid item md={6} xl={3} xs={12} sx={{ height: heightTaskAndRisk }}>
                            <RiskWidget onlyUserSpecific={onlyUserSpecific} selectedGroupCompany={selectedGroupCompany} />
                        </Grid>
                        <Grid mb={mbCompensation} item md={12} xl={6} xs={12}>
                            <Grid spacing={2} container sx={{ height: "100%" }}>
                                <Grid item md={6} lg={6} xs={12} sx={{ height: heightOtherWidgets, minHeight: minHeightOtherWidgets }}>
                                    <LatestAuditsSubmittedWidget onlyUserSpecific={onlyUserSpecific} selectedGroupCompany={selectedGroupCompany} />
                                </Grid>
                                <Grid item md={6} lg={6} xs={12} sx={{ height: heightOtherWidgets, minHeight: minHeightOtherWidgets }}>
                                    <IncidentLogWidget />
                                </Grid>
                                <Grid item xs={12} sx={{ height: heightOtherWidgets, minHeight: minHeightOtherWidgets }}>
                                    <TaskOverviewWidget onlyUserSpecific={onlyUserSpecific} selectedGroupCompany={selectedGroupCompany} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            {showReportDialog && (
                <ComplianceReportDialog
                    onDialogClose={() => setShowReportDialog(false)}
                    onlyUserSpecific={onlyUserSpecific}
                    selectedGroupCompany={selectedGroupCompany}
                />
            )}
        </>
    );
}

export default Dashboard;
