import React from "react";
import { DotLegalDialog, DotLegalMultiSelect, DotLegalSelect, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { DataPrivacyClassification } from "../../../processingActivity/DataCategoriesStep/DataCategoriesStep.types";
import { DataCategorySaveModel } from "../dataCategories/DataCategories.types";
import { useAddEditDataCategoryDialogHooks } from "./AddEditDataCategoryDialog.hooks";

export interface IAddEditDataCategoryDialog {
    dataCategory?: DataCategorySaveModel;
    open: boolean;
    isAdd: boolean;
    onDialogClose: () => void;
    onOkClick: (response: any, name: string) => void;
    refetch: () => void;
}

export default function AddEditDataCategoryDialog(props: IAddEditDataCategoryDialog) {
    const { translateString } = useTranslation();
    const {
        viewModel,
        setViewModel,
        getDataPrivacyClassificationOptions,
        validateDataCategoryForm,
        validateDataCategoryName,
        validateDataCategoryType,
        errors,
        isSaving,
        onDataTypesChanged,
        dataTypes,
        isLoadingDataTypes,
    } = useAddEditDataCategoryDialogHooks(props);

    return (
        <DotLegalDialog
            size="sm"
            inProgress={isSaving}
            header={props.isAdd ? translateString("createDataCategory") : translateString("editDataCategory")}
            buttonOkText={props.isAdd ? translateString("create") : translateString("save")}
            open={props.open}
            onDialogClose={props.onDialogClose}
            onOkClick={validateDataCategoryForm}
            disableBackdropOnCloseIfValueChange={viewModel}
        >
            <DotLegalTextField
                label={translateString("name")}
                value={viewModel?.name!}
                disabled={!props.isAdd && !viewModel?.isCustom}
                debounce={false}
                errorText={errors.nameError}
                onChange={(name) => {
                    let tempViewModel = { ...viewModel! };
                    tempViewModel.name = name;
                    validateDataCategoryName(name);
                    setViewModel(tempViewModel);
                }}
            />

            <DotLegalTextField
                label={translateString("description")}
                value={viewModel?.description!}
                debounce={false}
                multiline
                rows={5}
                onChange={(description) => {
                    let tempViewModel = { ...viewModel! };
                    tempViewModel.description = description;
                    setViewModel(tempViewModel);
                }}
            />

            <DotLegalSelect
                errorText={errors.classificationError}
                label={translateString("type")}
                options={getDataPrivacyClassificationOptions()}
                disabled={viewModel?.isCustom === false}
                placeholder={""}
                disableClearable
                selectedItem={viewModel?.classification === undefined || null ? null : viewModel?.classification!.toString()}
                onChange={(type) => {
                    let tempViewModel = { ...viewModel! };
                    let sType = DataPrivacyClassification[type! as keyof typeof DataPrivacyClassification];
                    tempViewModel.classification = Number(type!);
                    validateDataCategoryType(sType);
                    setViewModel(tempViewModel);
                }}
                noOptionsLabel={translateString("noOptions")}
            />

            {dataTypes && dataTypes.length > 0 && (
                <DotLegalMultiSelect
                    onChange={onDataTypesChanged}
                    chosenOptions={viewModel?.dataTypes ?? []}
                    label={translateString("associateDataTypes")}
                    noOptionsLabel={translateString("noOptions")}
                    options={dataTypes}
                    isLoading={isLoadingDataTypes}
                />
            )}
        </DotLegalDialog>
    );
}
