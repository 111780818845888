import { useTheme } from "@mui/material";
import { createSxStyles } from "../../../common/createSxStyles";

export const useRiskBusinessAreas = () => {
    const theme = useTheme();
    return createSxStyles({
        chip: {
            marginTop: theme.spacing(1),
        },
    });
};
