import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { get } from "../../../common/api/apiShared";
import { useStateLocalStorage } from "@dotlegal/dotlegal-ui-components";
import { DataCategorySaveModel, DataCategoryTableModel, DataCategoryViewModel } from "./DataCategories.types";
import { useNavigate } from "react-router-dom";
import { useUrlProvider } from "../../../useUrlProvider.ts";
import { DataType } from "../dataTypes/DataTypesList.types.ts";
import { DataPrivacyClassification } from "../../../processingActivity/DataCategoriesStep/DataCategoriesStep.types.ts";
import { useTranslation } from "../../../localization/useTranslation.ts";

export function useDataCategoriesHooks() {
    const queryClient = useQueryClient();
    const { getDataTypesUrl } = useUrlProvider();
    const navigate = useNavigate();
    const [showAllDataCategories, setShowAllDataCategories] = useStateLocalStorage("onlyActiveDataCategorys", false);
    const [showAddEditDialog, setShowAddEditDialog] = useState(false);
    const [showDeleteDataCategoryDialog, setShowDeleteDataCategoryDialog] = useState(false);
    const [selectedDataCategory, setSelectedDataCategory] = useState<DataCategorySaveModel | undefined>(undefined);
    const [showChangeStatusDialog, setShowChangeStausDialog] = useState(false);
    const [showActivateDataTypesDialog, setShowActivateDataTypesDialog] = useState(false);
    const { translateString } = useTranslation();

    const queryPart = `?showAllDataCategories=${showAllDataCategories}`;
    const url = `/DataCategories${queryPart}`;
    const { isLoading, data: dataCategories } = useQuery(url, () => get<Array<DataCategoryViewModel>>(url));

    const dataTypesUrl = "/DataTypes";
    const { data: dataTypes } = useQuery(dataTypesUrl, () => get<Array<DataType>>(dataTypesUrl));

    const refetchDataCategories = () => {
        queryClient.refetchQueries(url);
    };

    const onDataTypesActivated = () => {
        setShowActivateDataTypesDialog(false);
        navigate(getDataTypesUrl());
    };

    const customerHasDataTypesAlready = dataTypes?.length ?? 0 > 0;
    const onDataTypesClicked = () => {
        if (customerHasDataTypesAlready) {
            navigate(getDataTypesUrl());
        } else {
            setShowActivateDataTypesDialog(true);
        }
    };

    const tableModel: Array<DataCategoryTableModel> | undefined = dataCategories?.map((d) => {
        return {
            ...d,
            dataTypesString: d.dataTypes.map((d) => dataTypes?.find((dt) => dt.id === d)?.name).join(", "),
            classificationString: translateString(DataPrivacyClassification[d.classification as number].toLowerCase()),
        };
    });

    return {
        isLoading,
        tableModel,
        showAllDataCategories,
        setShowAllDataCategories,
        showAddEditDialog,
        setShowAddEditDialog,
        showDeleteDataCategoryDialog,
        setShowDeleteDataCategoryDialog,
        selectedDataCategory,
        setSelectedDataCategory,
        refetchDataCategories,
        showChangeStatusDialog,
        setShowChangeStausDialog,
        showActivateDataTypesDialog,
        setShowActivateDataTypesDialog,
        onDataTypesClicked,
        onDataTypesActivated,
        dataTypes,
        customerHasDataTypesAlready,
    };
}
