let queuedRequests = new Map<string, Array<any>>();

export function ajaxQueue(queryKey?: string) {
    if (!queryKey) queryKey = "onlyOneQueue";

    const executeRequest = function () {
        const reqWithVars = getQueue()[0];
        return reqWithVars
            .req(reqWithVars.vars)
            .then(function (d: any) {
                popQueue();
                if (getQueue().length > 0) {
                    return executeRequest();
                }
                return d;
            })
            .catch(function (e: Error) {
                clearQueue();
                throw e;
            });
    };

    function getQueue(): Array<any> {
        return queuedRequests.get(queryKey!) ?? [];
    }

    function popQueue() {
        getQueue().shift();

        if (getQueue().length === 0) queuedRequests.delete(queryKey!);
    }

    function clearQueue() {
        queuedRequests.set(queryKey!, []);
    }

    function addToQueue(item: any) {
        if (!queuedRequests.get(queryKey!)) {
            queuedRequests.set(queryKey!, []);
        }
        queuedRequests.get(queryKey!)!.push(item);
    }

    return {
        addRequest: function <TVariables, TResponse>(req: (vars: TVariables) => Promise<TResponse>, vars: TVariables): Promise<TResponse> | undefined {
            addToQueue({ req, vars });
            if (getQueue().length === 1) {
                return executeRequest();
            }
        },
        isItemsOnQueue: function () {
            return getQueue().length > 0;
        },
        clear() {
            clearQueue();
        },
    };
}
