import { DotLegalDialog, DotLegalSelect, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { arrow, leftWhiteArrowButton } from "../../../common/icons.tsx";
import { Box } from "@mui/material";
import { useTranslation } from "../../../localization/useTranslation.ts";
import useEditPublicInternalAssessmentTemplate from "./EditPublicInternalAssessmentTemplateDialog.hooks.ts";

export interface IEditPublicInternalAssessmentTemplateDialogProps {
    id: string;
    name: string;
    description: string | undefined;
    responsibleId: string;
    onDialogClose: () => void;
    onCreate: () => void;
}

function EditPublicInternalAssessmentTemplateDialog(props: IEditPublicInternalAssessmentTemplateDialogProps) {
    const { translateString } = useTranslation();

    const { model, validation, updater, selectableResponsiblesData, selectableResponsiblesLoading, isSending, onSendClick } =
        useEditPublicInternalAssessmentTemplate(props);

    validation.check(model);
    return (
        <DotLegalDialog
            buttonOkText={translateString("save")}
            size="lg"
            header={`${translateString("edit")} ${translateString("publicInternalAssessment")}`}
            open
            onDialogClose={props.onDialogClose}
            onOkClick={onSendClick}
            buttonRight
            inProgress={isSending}
            disableBackdropOnCloseIfValueChange={model}
        >
            <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                <Box sx={{ width: 450 }}>
                    <DotLegalTextField
                        errorText={validation.getErrorsForDisplay("name")}
                        label={translateString("assessmentName")}
                        value={model.name}
                        debounce={false}
                        onChange={updater.onAssessmentNameChange}
                        toolTipText={translateString("assessmentNameInfo")}
                    />

                    <DotLegalTextField
                        label={translateString("description")}
                        onChange={updater.onDescriptionChange}
                        multiline
                        rows={3}
                        value={model.description}
                        debounce={false}
                        toolTipText={translateString("assessmentDescriptionInfo")}
                    />

                    <DotLegalSelect
                        selectedItem={model.responsibleId}
                        options={selectableResponsiblesData}
                        isLoading={selectableResponsiblesLoading}
                        onChange={updater.onResponsibleChanged}
                        label={translateString("responsible")}
                        toolTipText={translateString("assessmentResponsbileInfo")}
                        noOptionsLabel={translateString("noOptions")}
                        errorText={validation.getErrorsForDisplay("responsibleId")}
                    ></DotLegalSelect>
                </Box>
            </Box>
        </DotLegalDialog>
    );
}

export default EditPublicInternalAssessmentTemplateDialog;
