import React from "react";
import { useDataTypesList } from "./DataTypesList.hooks.ts";
import {
    DotLegalActionBar,
    DotLegalButton,
    DotLegalOverflowMenu,
    DotLegalPageHeader,
    DotLegalPaper,
    DotLegalTableWithControls,
    DotLegalTooltip,
    ITableHeader,
} from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation.ts";
import AddEditDataTypeDialog from "./AddEditDataTypeDialog.tsx";
import { DataType } from "./DataTypesList.types.ts";
import { Box, TableCell, TableRow } from "@mui/material";
import DotLegalDeleteDialogWrapper from "../../../common/components/dotLegalDeleteDialog/DotLegalDeleteDialogWrapper.tsx";
import { informationIcon } from "../../../common/icons.tsx";

export interface IDataTypesListProps {}

function DataTypesList(props: IDataTypesListProps) {
    const {
        isLoading,
        dataTypes,
        customerName,
        breadCrumbs,
        onCreateClick,
        createDialogOpen,
        onUpdateClick,
        onDialogClose,
        onSaved,
        dataTypeToUpdate,
        getMenuItems,
        dataTypeForDeletion,
        setDataTypeForDeletion,
        onDelete,
    } = useDataTypesList();
    const { translateString } = useTranslation();

    const headers = Array<ITableHeader<DataType>>();
    headers.push({ property: "name", text: translateString("name") });
    return (
        <div>
            <DotLegalPageHeader userContext={{ customerName }} breadCrumbs={breadCrumbs} />
            <DotLegalActionBar
                canCreate
                primaryButton={
                    <>
                        <DotLegalButton buttonType={"primary"} onClick={onCreateClick}>
                            {translateString("createDataType")}
                        </DotLegalButton>
                        <Box ml={1}>
                            <DotLegalTooltip text={translateString("dataTypeExplanation")}>
                                <Box sx={{ display: "flex" }}>{informationIcon}</Box>
                            </DotLegalTooltip>
                        </Box>
                    </>
                }
                showDialog={createDialogOpen || dataTypeToUpdate !== undefined}
            >
                <AddEditDataTypeDialog isAdd={createDialogOpen} toUpdate={dataTypeToUpdate} onSaved={onSaved} onDialogClose={onDialogClose} />
            </DotLegalActionBar>
            <DotLegalPaper>
                <DotLegalTableWithControls
                    headers={headers}
                    isLoading={isLoading}
                    data={dataTypes}
                    emptyText={translateString("noDataTypes")}
                    defaultOrderBy={"name"}
                    hideRowsPerPage
                    getUserSpecificPageLength={() => 100}
                    labelRowsPerPage={translateString("showEntites")}
                    labelSearch={translateString("search")}
                    noOptionsLabel={translateString("noOptions")}
                    paginationLabelOf={translateString("labelOf")}
                    renderRow={(row) => (
                        <TableRow key={row.id} onClick={() => onUpdateClick(row.id)}>
                            <TableCell>{row.name}</TableCell>
                            <TableCell align={"right"}>
                                <DotLegalOverflowMenu items={getMenuItems(row)} />
                            </TableCell>
                        </TableRow>
                    )}
                />
            </DotLegalPaper>
            {dataTypeForDeletion && (
                <DotLegalDeleteDialogWrapper
                    open
                    onDialogClose={() => setDataTypeForDeletion(undefined)}
                    onOkClick={onDelete}
                    itemForDeletion={dataTypeForDeletion.name}
                />
            )}
        </div>
    );
}

export default DataTypesList;
