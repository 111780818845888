import React from "react";
import DotLegalDeleteDialogWrapper from "../../../../common/components/dotLegalDeleteDialog/DotLegalDeleteDialogWrapper";
import { useGdprIncidentDocumentDeleteDialog } from "./GdprIncidentDocumentDeleteDialog.hooks";
import { GdprIncidentDocument } from "../GdprIncidentDocument.types";

export interface IGdprIncidentDocumentDeleteDialogProps {
    onCloseDialog: () => void;
    onDeletedDocument: () => void;
    selectedDocument: GdprIncidentDocument;
    incidentLogId: string;
}

function GdprIncidentDocumentDeleteDialog(props: IGdprIncidentDocumentDeleteDialogProps) {
    const { deleteDocumentClick, inProgress } = useGdprIncidentDocumentDeleteDialog(props);
    return (
        <DotLegalDeleteDialogWrapper
            open
            itemForDeletion={props.selectedDocument!.name}
            onDialogClose={props.onCloseDialog}
            onOkClick={() => deleteDocumentClick(props.selectedDocument!.documentId)}
            inProgress={inProgress}
        />
    );
}

export default GdprIncidentDocumentDeleteDialog;
