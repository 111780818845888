import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { maleIcon, settingsIcon, whiteArrowLeft, whiteArrowRight, womanIcon } from "../../../common/icons";
import { useTranslation } from "../../../localization/useTranslation";
import { StepTypeEnum } from "../../ProcessingActivity.types";
import { useStepDescriptonBoxStyles } from "./StepDescriptionBox.styles";

export interface IStepDescriptionBoxProps {
    stepType: StepTypeEnum.dataprocessor | StepTypeEnum.disclosure | StepTypeEnum.sources | StepTypeEnum.dataController | StepTypeEnum.jointDataControllers;
}

function StepDescriptionBox(props: IStepDescriptionBoxProps) {
    const { translateString } = useTranslation();
    const styles = useStepDescriptonBoxStyles();

    function getBackgroundBox(icon: JSX.Element, iconName: string) {
        return (
            <Box
                sx={(theme) => ({
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#eff0fa",
                    borderRadius: 1,
                    alignItems: "center",
                    textAlign: "center",
                    padding: 2,
                    "& svg": {
                        fill: "none",
                        stroke: theme.palette.primary.dark,
                    },
                    "& #Group_1241": {
                        "& circle": {
                            strokeWidth: 0.5,
                        },
                        "& path": {
                            strokeWidth: 0.5,
                        },
                    },
                })}
            >
                <Box sx={{ height: 91, width: 90 }}>{icon}</Box>
                <Box
                    component={"p"}
                    sx={(theme) => ({ fontSize: theme.typography.pxToRem(14), color: "primary.dark", paddingTop: 1, fontWeight: "bold", margin: 0 })}
                >
                    {iconName}
                </Box>
            </Box>
        );
    }

    function getContent() {
        switch (+props.stepType) {
            case StepTypeEnum.dataprocessor:
                return (
                    <React.Fragment>
                        <Typography sx={styles.header}>{translateString("dataProcessors")}</Typography>
                        <Box sx={styles.centerContainer}>
                            <Grid container>
                                <Grid item xs={4}>
                                    <Typography sx={styles.textYou}>{translateString("labelYou")}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={styles.centerContainerImages}>
                                <Grid item xs={4}>
                                    {getBackgroundBox(maleIcon, translateString("Controller"))}
                                </Grid>
                                <Grid item xs={4} sx={styles.arrow}>
                                    {whiteArrowRight}
                                </Grid>
                                <Grid item xs={4}>
                                    {getBackgroundBox(settingsIcon, translateString("ProcessorW"))}
                                </Grid>
                            </Grid>
                        </Box>
                        <Typography sx={styles.descriptionText}>{translateString("dataProcessorDescription")}</Typography>
                    </React.Fragment>
                );
            case StepTypeEnum.disclosure:
                return (
                    <React.Fragment>
                        <Typography sx={styles.header}>{translateString("disclosures")}</Typography>
                        <Box sx={styles.centerContainer}>
                            <Grid container>
                                <Grid item xs={4}>
                                    <Typography sx={styles.textYou}>{translateString("labelYou")}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={styles.centerContainerImages}>
                                <Grid item xs={4}>
                                    {getBackgroundBox(maleIcon, translateString("Controller"))}
                                </Grid>
                                <Grid item xs={4} sx={styles.arrow}>
                                    {whiteArrowRight}
                                </Grid>
                                <Grid item xs={4}>
                                    {getBackgroundBox(womanIcon, translateString("Controller"))}
                                </Grid>
                            </Grid>
                        </Box>
                        <Typography sx={styles.descriptionText}>{translateString("disclosureDescription")}</Typography>
                    </React.Fragment>
                );
            case StepTypeEnum.sources:
                return (
                    <React.Fragment>
                        <Typography sx={styles.header}>{translateString("sources")}</Typography>
                        <Box sx={styles.centerContainer}>
                            <Grid container>
                                <Grid item xs={4}>
                                    <Typography sx={styles.textYou}>{translateString("labelYou")}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={styles.centerContainerImages}>
                                <Grid item xs={4}>
                                    {getBackgroundBox(maleIcon, translateString("Controller"))}
                                </Grid>
                                <Grid item xs={4} sx={styles.arrow}>
                                    {whiteArrowLeft}
                                </Grid>
                                <Grid item xs={4}>
                                    {getBackgroundBox(womanIcon, translateString("Controller"))}
                                </Grid>
                            </Grid>
                        </Box>
                        <Typography sx={styles.descriptionText}>{translateString("sourceDescription")}</Typography>
                    </React.Fragment>
                );
            case StepTypeEnum.dataController:
                return (
                    <React.Fragment>
                        <Typography sx={styles.header}>{translateString("Controller")}</Typography>
                        <Box sx={styles.centerContainer}>
                            <Grid container>
                                <Grid item xs={4}>
                                    <Typography sx={styles.textYou}>{translateString("labelYou")}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={styles.centerContainerImages}>
                                <Grid item xs={4}>
                                    {getBackgroundBox(settingsIcon, translateString("ProcessorW"))}
                                </Grid>
                                <Grid item xs={4} sx={styles.arrow}>
                                    {whiteArrowLeft}
                                </Grid>
                                <Grid item xs={4}>
                                    {getBackgroundBox(womanIcon, translateString("Controller"))}
                                </Grid>
                            </Grid>
                        </Box>
                        <Typography sx={styles.descriptionText}>{translateString("dataControllerDescription")}</Typography>
                    </React.Fragment>
                );
            case StepTypeEnum.jointDataControllers:
                return (
                    <React.Fragment>
                        <Typography sx={styles.header}>{translateString("jointDataControllers")}</Typography>
                        <Box sx={styles.centerContainer}>
                            <Grid container>
                                <Grid item xs={4}>
                                    <Typography sx={styles.textYou}>{translateString("labelYou")}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={styles.centerContainerImages}>
                                <Grid item xs={4}>
                                    {getBackgroundBox(maleIcon, translateString("jointDataControllers"))}
                                </Grid>
                                <Grid item xs={4} sx={[{ display: "flex", flexDirection: "column" }, styles.arrow]}>
                                    {whiteArrowRight}
                                    {whiteArrowLeft}
                                </Grid>
                                <Grid item xs={4}>
                                    {getBackgroundBox(womanIcon, translateString("jointDataControllers"))}
                                </Grid>
                            </Grid>
                        </Box>
                        <Typography sx={styles.descriptionText}>{translateString("jointDataControllersDescription")}</Typography>
                    </React.Fragment>
                );
        }
    }

    return <Box sx={styles.container}>{getContent()}</Box>;
}

export default StepDescriptionBox;
