import React from "react";
import { DotLegalCheckbox, DotLegalDialog, DotLegalMultiSelect } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { useUpdateProcessingTemplateInfo } from "./UpdateProcessingTemplateInfoDialog.hooks";

export interface IUpdateprocessingTemplateInfoDialogProps {
    processingActivityId: string;
    closeDialog: () => void;
}

function UpdateprocessingTemplateInfoDialog(props: IUpdateprocessingTemplateInfoDialogProps) {
    const { translateString } = useTranslation();
    const { industryAreasSelectOptions, saveTemplateInfo, inProgress, isLoading, model, setModel } = useUpdateProcessingTemplateInfo(props);

    return (
        <DotLegalDialog
            inProgress={inProgress}
            size="sm"
            buttonOkText={translateString("save")}
            header={""}
            open
            onDialogClose={() => props.closeDialog()}
            onOkClick={() => saveTemplateInfo()}
            isLoading={isLoading}
            disableBackdropOnCloseIfValueChange={model}
        >
            <DotLegalMultiSelect
                isLoading={isLoading}
                options={industryAreasSelectOptions}
                chosenOptions={model?.industryAreas!}
                placeholder={"Brancher"}
                label={"Brancer"}
                onChange={(areas) => {
                    let tempModel = { ...model! };
                    tempModel.industryAreas = areas;
                    setModel(tempModel);
                }}
                noOptionsLabel={translateString("noOptions")}
            />

            <DotLegalCheckbox
                label={"Mest populær?"}
                checked={model?.mostPopular ? model.mostPopular : false}
                onChange={(value) => {
                    let tempModel = { ...model! };
                    tempModel.mostPopular = value;
                    setModel(tempModel);
                }}
            />
        </DotLegalDialog>
    );
}

export default UpdateprocessingTemplateInfoDialog;
