import React from "react";
import { Route, Routes } from "react-router-dom";
import ErrorPage from "../errorPage/ErrorPage";
import { useTranslation } from "../localization/useTranslation";
import SignOut from "./SignOut";

export interface IUserRouterSwitch {
    children: any;
}

export default function UserRouterSwitch(props: IUserRouterSwitch) {
    const { translateString } = useTranslation();

    return (
        <Routes>
            <Route path="privacy">
                <Route path="user-not-found" element={<ErrorPage errorMessage={translateString("userNotFound")} image="NotFound" />} />
                <Route path="inactive-user" element={<ErrorPage errorMessage={translateString("inactiveUser")} image="NoAccess" />} />
                <Route path="sign-out" element={<SignOut />} />
            </Route>
            <Route path="*" element={props.children} />
        </Routes>
    );
}
