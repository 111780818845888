import DotLegalDeleteDialogWrapper from "../../common/components/dotLegalDeleteDialog/DotLegalDeleteDialogWrapper";
import { PolicySaveModel } from "../policies/Policies.types";
import { DeletePolicyDialogDataMapping } from "./DeletePolicyDialog.hooks";

export interface IDeletePolicyDialog {
    closeDialog: () => void;
    onDeleted: () => void;
    selectedPolicy: PolicySaveModel;
}

function DeletePolicyDialog(props: IDeletePolicyDialog) {
    const { deletePolicy, inProgess } = DeletePolicyDialogDataMapping(props);

    return (
        <DotLegalDeleteDialogWrapper
            open
            itemForDeletion={props.selectedPolicy.name}
            onDialogClose={props.closeDialog}
            onOkClick={deletePolicy}
            inProgress={inProgess}
        />
    );
}

export default DeletePolicyDialog;
