import { DotLegalEmptyState, DotLegalHeader, DotLegalTable, ITableHeader } from "@dotlegal/dotlegal-ui-components";
import { Box, TableCell, TableRow } from "@mui/material";
import WhiteDashboardBox from "../../WhiteDashboardBox";
import { Trans } from "react-i18next";
import DotLegalLink from "../../../common/components/dotLegalLink/DotLegalLink";
import DashboardPrettyScrollbar from "../../DashboardPrettyScrollbar";
import { RiskAssessmentType } from "../../../riskAssessment/RiskAssessment.types";
import { RiskAssessmentArea } from "../../../riskAssessment/riskAssessments/RiskAssessments.types";
import RiskIndicator from "../../../riskAssessment/riskIndicator/RiskIndicator";
import NavigateTo from "../../navigateTo/NavigateTo";
import { useTranslation } from "../../../localization/useTranslation";
import { DashboardRiskOverview, DashboardRiskOverviewRow } from "../RiskWidget.types";
import { useUrlProvider } from "../../../useUrlProvider";
import NoRisky from "./no-risky-entities.svg?react";
import NoTemplates from "./no-risk-templates.svg?react";
import { GetProcessingActivityPlanLimit } from "../../../auth/planProvider/PlanProvider.hooks";
import TrialExpiredRowWrapper from "../../../common/components/TrialExpiredRowWrapper";
import { usePlanContext } from "../../../auth/planProvider/PlanProvider";

export interface IRIskWidgetListProps {
    systemsChosen: boolean;
    isLoading: boolean;
    data?: DashboardRiskOverview;
    idPdf?: boolean;
    disableRows?: boolean;
}

function RiskWidgetList(props: IRIskWidgetListProps) {
    const { translateString } = useTranslation();
    const { getProcessingActivityOverviewUrl, getSystemOverviewUrl, getRiskAssessmentsUrl, getRiskAssessmentQuestionsDialog, getRiskAssessmentTemplateUrl } =
        useUrlProvider();
    const { dataProtectionFeatures, informationSecurityFeatures } = usePlanContext();

    const getHeaders = () => {
        const headers: Array<ITableHeader<DashboardRiskOverviewRow>> = [];
        headers.push({
            property: "name",
            text: props.systemsChosen ? translateString("asset") : translateString("processingActivity"),
        });
        headers.push({ property: "area", text: translateString("area") });
        headers.push({ property: "riskScore", text: translateString("risk") });

        return headers;
    };

    const getTable = () => {
        let table = (
            <DotLegalTable
                headers={getHeaders()}
                rowsPerPage={10000}
                clickableRows={false}
                defaultOrderBy={"riskScore"}
                defaultOrder={"desc"}
                data={props.data?.rows ?? []}
                hidePagination
                renderRow={(row) => (
                    <TrialExpiredRowWrapper
                        key={row.id + row.versionId}
                        onNonExpiredClick={() => {}}
                        tooltip={props.systemsChosen ? translateString("systemLockedMaxReached") : translateString("processingActivityLockedMaxReached")}
                        expired={props.disableRows ?? false}
                        planUpgradeText={
                            props.systemsChosen
                                ? translateString("systemLimitReached", {
                                      count: informationSecurityFeatures.maxNumberOfSystems,
                                  })
                                : translateString("processingActivityLimitReached", {
                                      count: dataProtectionFeatures.maxNumberOfProcessingActivities,
                                  })
                        }
                    >
                        <TableCell>
                            {props.disableRows ? (
                                row.name
                            ) : (
                                <>
                                    {props.systemsChosen ? (
                                        <DotLegalLink bold linkColor={"primary"} to={getSystemOverviewUrl(row.id)}>
                                            {row.name}
                                        </DotLegalLink>
                                    ) : (
                                        <DotLegalLink bold linkColor={"primary"} to={getProcessingActivityOverviewUrl(row.id)}>
                                            {row.name}
                                        </DotLegalLink>
                                    )}
                                </>
                            )}
                        </TableCell>
                        <TableCell>{row.areaString}</TableCell>
                        <TableCell>
                            <RiskIndicator
                                riskAssessmentType={RiskAssessmentType.None}
                                riskScoreTextColor={"darkblue"}
                                score={row.riskScore}
                                removeLeftMargin
                                linkHref={
                                    props.disableRows
                                        ? undefined
                                        : getRiskAssessmentQuestionsDialog(
                                              row.id,
                                              props.systemsChosen ? RiskAssessmentArea.Systems : RiskAssessmentArea.ProcessingActivities,
                                              row.area,
                                              row.versionId
                                          )
                                }
                            />
                        </TableCell>
                    </TrialExpiredRowWrapper>
                )}
            />
        );

        if (props.idPdf) {
            return table;
        }

        return <DashboardPrettyScrollbar>{table}</DashboardPrettyScrollbar>;
    };

    return (
        <>
            <Box sx={{ flex: 1, mt: 2, display: "flex", flexDirection: "column" }}>
                <DotLegalHeader marginBottom={1} headerStyle={"medium"}>
                    {translateString("highOrVeryHighIdentified")}
                </DotLegalHeader>
                <WhiteDashboardBox isLoading={props.isLoading} fullHeight>
                    {props.data?.noTemplate ? (
                        <DotLegalEmptyState
                            textColor={"darkBlue"}
                            noFixedHeight
                            noPadding
                            icon={<NoTemplates />}
                            text={
                                <Trans
                                    i18nKey={"setupRiskTemplate"}
                                    components={{
                                        riskTemplate: (
                                            <DotLegalLink to={getRiskAssessmentTemplateUrl()} linkColor="primary">
                                                {undefined}
                                            </DotLegalLink>
                                        ),
                                    }}
                                />
                            }
                        />
                    ) : (
                        <>
                            {(props.data?.rows.length ?? 0 > 0) ? (
                                getTable()
                            ) : (
                                <DotLegalEmptyState
                                    textColor={"darkBlue"}
                                    noFixedHeight
                                    noPadding
                                    icon={<NoRisky />}
                                    text={translateString("noHighOrVeryHighRisksContent")}
                                />
                            )}
                        </>
                    )}
                </WhiteDashboardBox>
            </Box>

            {!props.idPdf && (
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <NavigateTo color="purple" text={translateString("goToRisks")} to={getRiskAssessmentsUrl()} />
                </Box>
            )}
        </>
    );
}

export default RiskWidgetList;
