import React from "react";
import { useAreasHooks } from "./Areas.hooks";
import { useMasterDataBreadCrumbProvider } from "../../useMasterDataBreadCrumbProvider";
import { DotLegalActionBar, DotLegalOverflowMenu, DotLegalPageHeader, DotLegalTableWithControls, ITableHeader } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { AreaViewModel } from "./Areas.types";
import DotLegalPaper from "../../../common/components/dotLegalPaper/DotLegalPaper";
import { Box, TableCell, TableRow } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import AreaDialog from "../areaDialog/AreaDialog";
import DeleteAreaDialog from "../deleteAreaDialog/DeleteAreaDialog";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import { getMasterIcon } from "../../MasterData.hooks";

export default function Areas() {
    const { getBreadCrumbs } = useMasterDataBreadCrumbProvider();
    const { translateString } = useTranslation();
    const { customerName } = useUserContext();
    const {
        data,
        isLoading,
        setShowDeleteDialog,
        setShowDialog,
        showDeleteDialog,
        showDialog,
        onDeleteDialogClose,
        onDialogClose,
        refetch,
        selectedArea,
        setSelectedArea,
    } = useAreasHooks();

    const getMenuItems = (row: AreaViewModel) => {
        let menuItems = [];

        menuItems.push({
            menuItem: translateString("edit"),
            onClick: () => {
                setSelectedArea(row);
                setShowDialog(true);
            },
        });

        menuItems.push({
            menuItem: translateString("delete"),
            onClick: () => {
                setSelectedArea(row);
                setShowDeleteDialog(true);
            },
        });

        return menuItems;
    };

    function getHeaders() {
        let headers = Array<ITableHeader<AreaViewModel>>();
        headers.push({ property: "name", text: translateString("name"), align: "left", showOnMobile: true });
        headers.push({ property: "description", text: translateString("description"), align: "left", showOnMobile: true });
        headers.push({ property: "isCustom", text: translateString("standardMasterData"), align: "left", showOnMobile: true });

        return headers;
    }

    return (
        <React.Fragment>
            <DotLegalPageHeader userContext={{ customerName }} breadCrumbs={getBreadCrumbs(translateString("areas"))} />
            <DotLegalActionBar
                canCreate
                buttonText={translateString("createArea")}
                onOpenDialog={() => {
                    setShowDialog(true);
                }}
                showDialog={showDialog}
                icon={getMasterIcon()}
            >
                {showDialog && (
                    <AreaDialog
                        selectedArea={selectedArea}
                        onDialogClose={onDialogClose}
                        onAreaSaved={() => {
                            refetch();
                            onDialogClose();
                        }}
                        areaId={selectedArea?.id}
                    />
                )}
            </DotLegalActionBar>

            <DotLegalPaper>
                <DotLegalTableWithControls
                    headers={getHeaders()}
                    getUserSpecificPageLength={() => 30}
                    hideRowsPerPage
                    defaultOrderBy={"name"}
                    defaultOrder={"asc"}
                    isLoading={isLoading}
                    emptyText={translateString("noData")}
                    labelRowsPerPage={translateString("showEntites")}
                    labelSearch={translateString("search")}
                    noOptionsLabel={translateString("noOptions")}
                    paginationLabelOf={translateString("labelOf")}
                    data={data}
                    renderRow={(row) => (
                        <TableRow key={row.id}>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.description}</TableCell>
                            <TableCell>
                                <Box>{row.isCustom ? "" : <CheckIcon />}</Box>
                            </TableCell>
                            <TableCell align={"right"}>{row.isCustom && <DotLegalOverflowMenu items={getMenuItems(row)} />}</TableCell>
                        </TableRow>
                    )}
                />
            </DotLegalPaper>

            {showDeleteDialog && (
                <DeleteAreaDialog
                    onCloseDialog={() => onDeleteDialogClose()}
                    onDeleted={() => {
                        onDeleteDialogClose();
                        refetch();
                    }}
                    selectedArea={selectedArea!}
                />
            )}
        </React.Fragment>
    );
}
