import { DotLegalDialog, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { useCertificationDialogHooks } from "./CertificationDialog.hooks";
import { CertificationSaveModel } from "./CertificationDialog.types";

export interface ICertificationDialog {
    onDialogClose: () => void;
    selectedCertification?: CertificationSaveModel;
    onCertificationSaved: (response: any) => void;
    certificationId?: string;
}

function CertificationDialog(props: ICertificationDialog) {
    const { translateString } = useTranslation();
    const { inProgress, onSaveClick, isEdit, certification, onDescriptionChange, onNameChange, validation } = useCertificationDialogHooks(props);

    validation.check(certification);
    return (
        <DotLegalDialog
            buttonOkText={isEdit ? translateString("save") : translateString("create")}
            header={isEdit ? translateString("editCertification") : translateString("createCertificaion")}
            size="sm"
            open
            inProgress={inProgress}
            onDialogClose={() => props.onDialogClose()}
            onOkClick={() => onSaveClick()}
            disableBackdropOnCloseIfValueChange={certification}
        >
            <DotLegalTextField
                label={translateString("name")}
                onChange={(name) => onNameChange(name)}
                value={certification.name}
                debounce={false}
                errorText={validation.getErrorsForDisplay("name")}
            ></DotLegalTextField>
            <DotLegalTextField
                label={translateString("description")}
                onChange={(description) => onDescriptionChange(description)}
                value={certification.description}
                debounce={false}
            ></DotLegalTextField>
        </DotLegalDialog>
    );
}

export default CertificationDialog;
