import { DropResult } from "react-beautiful-dnd";
import { IDotLegalDragNDrop } from "./DotLegalDragNDrop";

export function useDotLegalDragNDropHooks(props: IDotLegalDragNDrop) {
    function onDragEnd(result: DropResult) {
        const { source, destination } = result;

        if (!destination) {
            return;
        }

        let sourceColumn = +source.droppableId;
        let destinationColumn = +destination!.droppableId;
        const sourceIndex = source.index;
        const destinationIndex = destination!.index;

        const sourceItem = props.items[sourceColumn].tasks[sourceIndex];
        const sourceId = sourceItem.id;
        const destinationItem = props.items[destinationColumn].tasks[destinationIndex];

        if (props.onColumnDragFailed) {
            if (sourceItem.columnWithDeniedAccess !== null && sourceItem.columnWithDeniedAccess === destinationColumn) {
                props.onColumnDragFailed(sourceId);
                return;
            }
        }

        let destinationId = "";
        if (destinationItem) destinationId = destinationItem.id;

        props.onDragEnd(sourceId, ++sourceColumn, destinationId, ++destinationColumn);
    }

    return {
        onDragEnd,
    };
}
