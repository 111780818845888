import { PropertyType } from "../common/PropertyTypes";
import { ColumnFilterType, FilterViewModel } from "./editCustomListDialog/EditCustomListDialog.types";

export function hasFilterData(filter?: FilterViewModel) {
    return (
        !filter ||
        filter.date ||
        filter.listValues.length > 0 ||
        (filter.numberValue !== undefined && filter.numberValue !== null) ||
        filter.textValue ||
        filter.filterType === ColumnFilterType.BooleanShowFalse ||
        filter.filterType === ColumnFilterType.BooleanShowTrue
    );
}

export function getTranslationKey(columnType: PropertyType) {
    return PropertyType[columnType].toString();
}
