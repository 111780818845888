import { createSxStyles } from "../../common/createSxStyles";

export const useAddEditCustomerStyles = () => {
    return createSxStyles({
        spinnerWrapper: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            minHeight: 50 + "px",
        },
    });
};
