import { useTheme } from "@mui/material";
import { createSxStyles } from "../../../common/createSxStyles";

export const SystemOverviewDataSubjectDialogStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        dataSubjectContainer: {
            display: "flex",
            justifyContent: "center",
            margin: theme.spacing(3),
        },
        dialogDescription: {
            fontSize: theme.typography.pxToRem(12),
            color: theme.palette.primary.dark,
            textAlign: "center",
        },
    });
};
