import { createElement } from "react";
import { Trans } from "react-i18next";
import { useMutation } from "react-query";
import { deleteHttp } from "../../../common/api/apiShared";
import { Result } from "../../../common/api/result";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { IDeleteDataSubjectDialogProps } from "./DeleteDataSubjectDialog";

export function DeleteDataSubjectDialogDataMapping(props: IDeleteDataSubjectDialogProps) {
    const snackbar = useDotLegalSnackbar();

    const removeMutation = useMutation(remove);
    const removeDataSubject = () => {
        removeMutation.mutateAsync(undefined, {
            onSuccess: (response: Result<string>) => {
                props.onCloseDialog();

                if (response.success()) {
                    props.onSaveCorrect();
                    snackbar.show(createElement(Trans, { i18nKey: "dataSubjectDeleted", values: { name: props.selectedDataSubject.name } }));
                } else {
                    snackbar.show(createElement(Trans, { i18nKey: "dataSubjectNotDeleted", values: { name: props.selectedDataSubject.name } }), "warning");
                }
            },
        });
    };

    async function remove() {
        return await deleteHttp<string>(`/datasubjects/${props.selectedDataSubject.id}`);
    }

    return {
        removeDataSubject,
        inProgess: removeMutation.isLoading,
    };
}
