import { useState } from "react";
import { TrialInformationViewModel, TrialPlanViewModel } from "./Trial.types";
import { PlanType } from "../auth/planProvider/PlanProvider.types";

export function useTrialHook(data: TrialInformationViewModel | undefined) {
    const [shouldShowTrialExpiresSoonDialog, setShouldShowTrialExpiresSoonDialog] = useState<Array<PlanType>>([]);
    const [shouldShowTrialExpiredDialog, setShouldShowTrialExpiredDialog] = useState<Array<PlanType>>([]);

    const almostExpiredPlans = data?.plansOnTrials.filter((x) => x.isAlmostExpired) ?? [];
    const expiredPlans = data?.plansOnTrials.filter((x) => x.isExpired) ?? [];

    function getExpiresSoonStorageKey(planType: PlanType) {
        return "privacy-trial-expires-soon-dialog-last-shown-" + planType;
    }

    function getExpiredStoragekey(planType: PlanType) {
        return "privacy-trial-expired-dialog-last-shown-" + planType;
    }

    let showSoonDialog = showDialog(data?.hasAlmostExpiredTrials ?? false, shouldShowTrialExpiresSoonDialog, almostExpiredPlans, getExpiresSoonStorageKey);

    let showExpiredDialog = showDialog(data?.hasExpiredTrials ?? false, shouldShowTrialExpiredDialog, expiredPlans, getExpiredStoragekey);

    function closeExpiresSoonDialog() {
        var date = new Date().getTime().toString();

        almostExpiredPlans.forEach((plan) => {
            localStorage.setItem(getExpiresSoonStorageKey(plan.planType), date);

            let temp = [...shouldShowTrialExpiresSoonDialog];
            temp.push(plan.planType);
            setShouldShowTrialExpiresSoonDialog(temp);
        });
    }

    function closeExpiredDialog() {
        var date = new Date().getTime().toString();

        expiredPlans.forEach((plan) => {
            localStorage.setItem(getExpiredStoragekey(plan.planType), date);

            let temp = [...shouldShowTrialExpiredDialog];
            temp.push(plan.planType);
            setShouldShowTrialExpiredDialog(temp);
        });
    }

    function dontAskAgainExpiresSoonDialog() {
        var date = new Date();
        date.setFullYear(date.getFullYear() + 1);

        almostExpiredPlans.forEach((plan) => {
            localStorage.setItem(getExpiresSoonStorageKey(plan.planType), date.getTime().toString());

            let temp = [...shouldShowTrialExpiresSoonDialog];
            temp.push(plan.planType);
            setShouldShowTrialExpiresSoonDialog(temp);
        });
    }

    function dontAskAgainExpiredDialog() {
        var date = new Date();
        date.setFullYear(date.getFullYear() + 1);

        expiredPlans.forEach((plan) => {
            localStorage.setItem(getExpiredStoragekey(plan.planType), date.getTime().toString());

            let temp = [...shouldShowTrialExpiredDialog];
            temp.push(plan.planType);
            setShouldShowTrialExpiredDialog(temp);
        });
    }

    function showDialog(
        showDialog: boolean,
        shouldShowTrialDialog: Array<PlanType>,
        plansOnTrials: Array<TrialPlanViewModel>,
        getStorageKey: (planType: PlanType) => string
    ) {
        let show = false;
        if (showDialog) {
            plansOnTrials.forEach((x) => {
                let storageKey = getStorageKey(x.planType);
                if ((localStorage.getItem(storageKey) === null || getHourSinceLastShown(storageKey) > 24) && !shouldShowTrialDialog.includes(x.planType)) {
                    show = true;
                }
            });
        }

        return show;
    }

    function getHourSinceLastShown(storageKey: string) {
        var diff = new Date().getTime() - new Date(Number(localStorage.getItem(storageKey))!).getTime();
        return diff / 1000 / 60 / 60; // Convert milliseconds to hours
    }

    return {
        showSoonDialog,
        showExpiredDialog,
        closeExpiresSoonDialog,
        closeExpiredDialog,
        dontAskAgainExpiresSoonDialog,
        dontAskAgainExpiredDialog,
    };
}
