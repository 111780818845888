import React from "react";
import { useQuery } from "react-query";
import { get } from "../../api/apiShared";
import { SelectableItem } from "../../../processingActivity/ProcessingActivity.types";
import { DotLegalSelect, IDotLegalSelect } from "@dotlegal/dotlegal-ui-components";

export interface IDotLegalSelectApiProps<T> extends Omit<IDotLegalSelect, "options" | "isLoading" | "renderOptions" | "onChange"> {
    url: string;
    onChange: (item: T | undefined) => void;
    onOptionsRender?: (item: T) => string;
}

function DotLegalSelectApi<T extends SelectableItem>(props: IDotLegalSelectApiProps<T>) {
    const { isLoading, data } = useQuery(props.url, () => get<Array<T>>(props.url));

    return (
        <DotLegalSelect
            {...props}
            onChange={(s) => {
                let item = data!.filter((d) => d.id === s)[0];
                return props.onChange(item);
            }}
            isLoading={isLoading}
            options={
                props.onOptionsRender
                    ? data?.map((d) => {
                          return {
                              name: props.onOptionsRender!(d),
                              id: d.id,
                          };
                      })
                    : data
            }
        />
    );
}

export default DotLegalSelectApi;
