import React from "react";
import {
    DataProcessor,
    Disclosure,
    DisclosureDataCategory,
    JointDataController,
    ProcessingActivityModel,
    SelectableLegalUnitsItem,
    Source,
    StepperChild,
    StepTypeEnum,
} from "../ProcessingActivity.types";
import DataProcessorsStep from "./dataProcessorsStep/DataProcessorsStep";
import DotLegalCoreStepper from "../../common/components/dotLegalStepper/DotLegalCoreStepper";
import SegmentStep from "../stepSegment/StepSegment";
import SourceStep from "./sourceStep/SourceStep";
import { useSharingOfDataDataMapping } from "./SharingOfDataStep.hooks";
import { ValidationError } from "../../common/validationError";
import DotLegalStepHeader from "../../common/components/dotLegalStepHeader/DotLegalStepHeader";
import { useTranslation } from "../../localization/useTranslation";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { Permissions, ProcessingActivityStatusManagement } from "../../auth/userContextProvider/UserContextProvider.types";
import { ProcessingActivityStatus } from "../processingActivities/ProcessingActivities.types";
import { onAgreementsChanged } from "../../common/onAgreementChanged";
import { isBool } from "../../common/booleanOperations";
import JointDataControllerStep from "./jointDataControllerStep/JointDataControllerStep";
import { LevelOfProtectionType } from "../../legalEntity/addLegalEntityDialog/AddLegalEntityDialog.types";
import { EUToUSDataPrivacyFrameworkId } from "../../legalBasis/TransferBasis.Helper";
import DisclosureStep from "./transfersStep/DisclosureStep.tsx";

export interface ISharingOfDataStep {
    processingActivityModel: ProcessingActivityModel;
    onChange: (processingActivityModel: ProcessingActivityModel) => void;
    disableDataProcessors: boolean;
    disableDisclosures: boolean;
    disableSources: boolean;
    disableDataControllers: boolean;
    disableJointDataControllers: boolean;
    onDisableSharingsChange: (checked: boolean, sharingType: StepTypeEnum) => void;
    validationErrors: Array<ValidationError>;
    changeSubStep: (step: StepTypeEnum) => void;
    getActiveSubStep: () => StepTypeEnum;
    getSubSteps: () => {
        name: string;
        error: boolean;
        visited: boolean;
        stepType: StepTypeEnum;
    }[];
    hasDataCreationPermission: boolean;
    isValidated: boolean;
    processingActivityStatus: ProcessingActivityStatus;
}

function SharingOfDataStep(props: ISharingOfDataStep) {
    const processingActivity = props.processingActivityModel;
    const { permissions } = useUserContext();
    const {
        isAddingDataProcessor,
        addDataProcessor,
        removeDataProcessor,
        updateDataProcessor,
        legalEntitiesChangeOnDataProcessor,
        isAddingTransfer,
        addTransfer,
        updateTransfer,
        removeTransfer,
        legalEntitiesChangedOnTransfer,
        isAddingSource,
        addSource,
        updateSource,
        removeSource,
        legalEntitiesChangedOnSource,
        addJointDataController,
        removeJointDataController,
        updateJointDataController,
        legalEntitiesChangedOnJointDataController,
    } = useSharingOfDataDataMapping(props.processingActivityModel);

    function permissionIsReadOnly(permission: Permissions) {
        return (
            !permission.edit ||
            (permissions.processingActivityStatusManagement === ProcessingActivityStatusManagement.Draft &&
                processingActivity.status !== ProcessingActivityStatus.Draft &&
                processingActivity.status !== ProcessingActivityStatus.AwaitingResearcher)
        );
    }

    function getDataProcessorStep(groupEntityIsDataProcessor: boolean) {
        return (
            <DataProcessorsStep
                isReadOnly={
                    props.isValidated ||
                    (groupEntityIsDataProcessor && permissionIsReadOnly(permissions.processingActivityDataControllersPermissions.permissions)) ||
                    (!groupEntityIsDataProcessor && permissionIsReadOnly(permissions.processingActivityDataProcessorsPermissions.permissions))
                }
                hasData={groupEntityIsDataProcessor ? props.processingActivityModel.hasDataControllers : props.processingActivityModel.hasDataProcessors}
                dataProcessors={processingActivity.dataProcessors}
                groupEntityIsDataProcessor={groupEntityIsDataProcessor}
                processingActivity={processingActivity}
                disableDataProcessors={groupEntityIsDataProcessor ? props.disableDataControllers : props.disableDataProcessors}
                validationErrors={props.validationErrors}
                isAddingDataProcessor={isAddingDataProcessor}
                entitesFromAssociation={processingActivity.legalEntities}
                onCreateNewDocument={async (agreement, dataProcessorId) => {
                    await changeDataProcessor(dataProcessorId, (d) => {
                        d.dataProcessAgreements.push(agreement);
                        d.hasDataProcessorAgreement = false;
                    });
                }}
                onDataProcessorChange={async (processorWId, dataProcessorId, legalEntity: SelectableLegalUnitsItem | undefined) => {
                    await changeDataProcessor(dataProcessorId, async (d) => {
                        d.dataProcessor = processorWId;
                        d.dataProcessAgreements = [];
                        d.transferImpactAssessmentId = undefined;
                        d.hasTransferImpactAssessment = false;
                        d.hasDataProcessorAgreement = false;
                        d.transferBasisId = undefined;
                        d.dataStorageLocations = [];
                        d.supportLocations = [];

                        if (legalEntity?.levelOfProtection === LevelOfProtectionType.DataPrivacyFramework) {
                            d.transferBasisId = EUToUSDataPrivacyFrameworkId;
                        }
                    });
                }}
                onTransferImpactAssessmentChange={async (agreement, dataProcessorId) => {
                    if (agreement) {
                        if (isBool(agreement)) {
                            let bool = agreement.toLowerCase() === "true";
                            await changeDataProcessor(dataProcessorId, (d) => {
                                d.hasTransferImpactAssessment = bool;
                                d.transferImpactAssessmentId = undefined;
                            });
                        } else {
                            await changeDataProcessor(dataProcessorId, (d) => {
                                d.transferImpactAssessmentId = agreement;
                                d.hasTransferImpactAssessment = false;
                            });
                        }
                    }
                }}
                onResponsibleChange={async (responseibleId, dataProcessorId) => {
                    await changeDataProcessor(dataProcessorId, (d) => (d.responsible = responseibleId));
                }}
                onChosenDataCategories={async (dataCategories, dataProcessorId) => {
                    await changeDataProcessor(dataProcessorId, (dataProcessor) => {
                        dataProcessor.dataCategories = dataCategories ?? [];
                        dataProcessor.subProcessors.forEach((subProcessor) => {
                            subProcessor.dataCategories = subProcessor.dataCategories?.filter((subProcessorDataCategory) =>
                                dataCategories.some((dataCategory) => dataCategory.dataCategoryId === subProcessorDataCategory.dataCategoryId)
                            );

                            subProcessor.dataCategories.forEach((subProcessorDataCategory) => {
                                const parentDataCategory = dataProcessor.dataCategories.find(
                                    (process) => subProcessorDataCategory.dataCategoryId === process.dataCategoryId
                                )!;
                                subProcessorDataCategory.dataTypeIds = subProcessorDataCategory.dataTypeIds.filter((dt) =>
                                    parentDataCategory.dataTypeIds.some((parent) => parent === dt)
                                );
                            });
                        });
                    });
                }}
                onStorageLocationChange={async (storageLocations, supportLocations, dataProcessorId, dataContainedInThirdCountry) => {
                    await changeDataProcessor(dataProcessorId, (d) => {
                        if (!dataContainedInThirdCountry) {
                            d.transferBasisId = undefined;
                            d.hasTransferImpactAssessment = false;
                            d.transferImpactAssessmentId = undefined;
                        }

                        d.dataStorageLocations = storageLocations;
                        d.supportLocations = supportLocations;
                    });
                }}
                onSubProcessorStorageLocationChange={async (
                    storageLocations,
                    supportLocations,
                    dataProcessorId,
                    subProcessorIndex,
                    dataContainedInThirdCountry
                ) => {
                    await changeDataProcessor(dataProcessorId, (d) => {
                        if (!dataContainedInThirdCountry) {
                            d.subProcessors[subProcessorIndex].transferBasisId = undefined;
                            d.subProcessors[subProcessorIndex].transferImpactAssessmentId = undefined;
                            d.subProcessors[subProcessorIndex].hasTransferImpactAssessment = false;
                        }

                        d.subProcessors[subProcessorIndex].dataStorageLocations = storageLocations;
                        d.subProcessors[subProcessorIndex].supportLocations = supportLocations;
                    });
                }}
                onLegalEntitiesChange={legalEntitiesChangeOnDataProcessor}
                onAddNewDataProcessorClick={async (groupEntityIsDataProcessor) => {
                    await addDataProcessor(groupEntityIsDataProcessor);
                }}
                onSubProcessorDataCategoryClick={async (dataCategories, dataProcessorId, subprocessorIndex) => {
                    await changeDataProcessor(dataProcessorId, (d) => {
                        d.subProcessors[subprocessorIndex].dataCategories = dataCategories ?? [];
                    });
                }}
                onAddNewSubprocessorClick={async (dataProcessorId, isLink) => {
                    await changeDataProcessor(dataProcessorId, (d) =>
                        d.subProcessors.push({
                            subProcessHasAgreement: false,
                            dataCategories: d.dataCategories.map((item) => item),
                            hasTransferImpactAssessment: false,
                            dataStorageLocations: [],
                            supportLocations: [],
                            containsNotAccessibleTia: false,
                            isLink: isLink,
                            link: "",
                        })
                    );
                }}
                onSubDataProcessorChange={async (subprocessorId, dataProcessorId, subprocessIndex, legalEntity: SelectableLegalUnitsItem | undefined) => {
                    await changeDataProcessor(dataProcessorId, (d) => {
                        d.subProcessors[subprocessIndex].processorId = subprocessorId;

                        d.subProcessors[subprocessIndex].transferBasisId = undefined;
                        d.subProcessors[subprocessIndex].hasTransferImpactAssessment = false;
                        d.subProcessors[subprocessIndex].transferImpactAssessmentId = undefined;
                        d.subProcessors[subprocessIndex].dataStorageLocations = [];
                        d.subProcessors[subprocessIndex].supportLocations = [];

                        if (legalEntity?.levelOfProtection === LevelOfProtectionType.DataPrivacyFramework)
                            d.subProcessors[subprocessIndex].transferBasisId = EUToUSDataPrivacyFrameworkId;
                    });
                }}
                onSubProcessorTransferImpactAssessmentChange={async (tia, dataProcessorId, subprocessorIndex) => {
                    if (tia) {
                        if (isBool(tia)) {
                            let bool = tia.toLowerCase() === "true";
                            await changeDataProcessor(dataProcessorId, (d) => {
                                d.subProcessors[subprocessorIndex].hasTransferImpactAssessment = bool;
                                d.subProcessors[subprocessorIndex].transferImpactAssessmentId = undefined;
                            });
                        } else {
                            await changeDataProcessor(dataProcessorId, (d) => {
                                d.subProcessors[subprocessorIndex].hasTransferImpactAssessment = false;
                                d.subProcessors[subprocessorIndex].transferImpactAssessmentId = tia;
                            });
                        }
                    }
                }}
                onRemoveDataProcessor={async (processorId) => {
                    const newProcessingActivityModel = { ...processingActivity };
                    newProcessingActivityModel.dataProcessors = newProcessingActivityModel.dataProcessors.filter((d) => d.id !== processorId);
                    props.onChange(newProcessingActivityModel);
                    await removeDataProcessor(processorId);
                }}
                onSubProcessorAgreementChange={async (hasAgreement, dataProcessorId, subprocessorIndex) => {
                    await changeDataProcessor(dataProcessorId, (d) => (d.subProcessors[subprocessorIndex].subProcessHasAgreement = hasAgreement));
                }}
                onRemoveSubDataProcessor={async (dataProcessorId, subProcessorIndex) => {
                    await changeDataProcessor(dataProcessorId, (d) => d.subProcessors.splice(subProcessorIndex, 1));
                }}
                onTransferBasisChange={async (transferBasis, dataProcessorId) => {
                    await changeDataProcessor(dataProcessorId, (d) => {
                        d.transferBasisId = transferBasis;

                        if (transferBasis === EUToUSDataPrivacyFrameworkId) {
                            d.transferImpactAssessmentId = undefined;
                            d.hasTransferImpactAssessment = false;
                        }
                    });
                }}
                onSubProcessorTransferBasisChange={async (transferBasis, dataProcessorId, subprocessorIndex) => {
                    await changeDataProcessor(dataProcessorId, (d) => {
                        d.subProcessors[subprocessorIndex].transferBasisId = transferBasis;

                        if (transferBasis === EUToUSDataPrivacyFrameworkId) {
                            d.subProcessors[subprocessorIndex].hasTransferImpactAssessment = false;
                            d.subProcessors[subprocessorIndex].transferImpactAssessmentId = undefined;
                        }
                    });
                }}
                onSharingNameChange={async (name, dataProcessorId) => {
                    await changeDataProcessor(dataProcessorId, (d) => {
                        d.name = name;
                    });
                }}
                onSharingDescriptionChange={async (description, dataProcessorId) => {
                    await changeDataProcessor(dataProcessorId, (d) => {
                        d.description = description;
                    });
                }}
                onDisableDataProcessorsClick={(e, groupEntityIsDataProcessor) =>
                    props.onDisableSharingsChange(e, groupEntityIsDataProcessor ? StepTypeEnum.dataController : StepTypeEnum.dataprocessor)
                }
                onSubDataProcessorLinkChange={async (link, dataProcessorId, subprocessorIndex) => {
                    await changeDataProcessor(dataProcessorId, (d) => {
                        d.subProcessors[subprocessorIndex].link = link;
                    });
                }}
            />
        );
    }

    function getStepContent(stepType: StepTypeEnum): StepperChild {
        switch (stepType) {
            case StepTypeEnum.dataprocessor:
                return {
                    childElement: getDataProcessorStep(false),
                    graphicType: undefined,
                };
            case StepTypeEnum.disclosure:
                return {
                    childElement: (
                        <DisclosureStep
                            isReadOnly={props.isValidated || permissionIsReadOnly(permissions.processingActivityDisclosuresPermissions.permissions)}
                            hasData={props.processingActivityModel.hasDisclosures}
                            hasDataCreationPermission={props.hasDataCreationPermission}
                            processingActivity={processingActivity}
                            isAddingTransfer={isAddingTransfer}
                            disableDisclosures={props.disableDisclosures}
                            transfers={processingActivity.disclosures}
                            dataCategories={processingActivity.dataCategories.map((x) => x.dataCategoryId)}
                            entitesFromAssociation={processingActivity.legalEntities}
                            validationErrors={props.validationErrors}
                            processingActivityOwner={props.processingActivityModel.owner}
                            hasContractsEnabled
                            onLegalUnitChange={async (legalUnitid, transferId, legalEntity: SelectableLegalUnitsItem | undefined) => {
                                await changeDisclosure(transferId, (t) => {
                                    t.transferAgreements = [];
                                    t.transferBasis = undefined;
                                    t.hasDataProcessingAgreement = false;
                                    t.legalUnitId = legalUnitid === null ? undefined : legalUnitid;

                                    if (legalEntity?.levelOfProtection === LevelOfProtectionType.DataPrivacyFramework)
                                        t.transferBasis = EUToUSDataPrivacyFrameworkId;
                                });
                            }}
                            onCreateNewDocument={async (documentId, transferId) => {
                                await changeDisclosure(transferId, (d) => {
                                    d.transferAgreements.push(documentId);
                                    d.hasDataProcessingAgreement = false;
                                });
                            }}
                            onTransferAgreementChange={async (agreements, transferId) => {
                                const transferAgreements = processingActivity.disclosures.find((d) => d.id === transferId)!.transferAgreements;
                                await onAgreementsChanged(
                                    agreements,
                                    transferAgreements,
                                    async (yesOrNo) =>
                                        await changeDisclosure(transferId, (s) => {
                                            s.hasDataProcessingAgreement = yesOrNo;
                                            s.transferAgreements = [];
                                        }),
                                    async () =>
                                        await changeDisclosure(transferId, (s) => {
                                            s.transferAgreements = agreements;
                                            s.hasDataProcessingAgreement = false;
                                        })
                                );
                            }}
                            onResponsibleChange={async (responsibleId, transferId) => {
                                await changeDisclosure(transferId, (t) => {
                                    t.responsible = responsibleId === null ? undefined : responsibleId;
                                });
                            }}
                            onChosenDataCategories={async (dataCategories, transferId) => {
                                await changeDisclosure(transferId, (t) => {
                                    if (dataCategories) {
                                        if (dataCategories.length > t.dataCategories.length) {
                                            const newDataCategories = dataCategories.filter(
                                                (x) => t.dataCategories.find((c) => c.dataCategoryId === x.dataCategoryId) === undefined
                                            );
                                            newDataCategories.forEach((newDc) => {
                                                t.dataCategories.push(new DisclosureDataCategory(newDc.dataCategoryId, newDc.dataTypeIds));
                                            });
                                        } else {
                                            t.dataCategories = t.dataCategories.filter(
                                                (x) => dataCategories.map((d) => d.dataCategoryId).indexOf(x.dataCategoryId) !== -1
                                            );
                                        }
                                        t.dataCategories.forEach((newDc) => {
                                            newDc.dataTypeIds = dataCategories.find((dc) => dc.dataCategoryId === newDc.dataCategoryId)!.dataTypeIds;
                                        });
                                    } else {
                                        t.dataCategories = [];
                                    }
                                });
                            }}
                            onLegalEntitiesChange={legalEntitiesChangedOnTransfer}
                            onRemoveTransfer={async (transferId) => {
                                const newProcessingActivityModel = { ...processingActivity };
                                newProcessingActivityModel.disclosures = newProcessingActivityModel.disclosures.filter((d) => d.id! !== transferId);
                                props.onChange(newProcessingActivityModel);
                                await removeTransfer(transferId);
                            }}
                            onAddNewTransferClick={async () => {
                                await addTransfer();
                            }}
                            onTransferBasisChange={async (transferBasis, transferId) => {
                                await changeDisclosure(transferId, (t) => {
                                    t.transferBasis = transferBasis;
                                });
                            }}
                            onDisclosureUpdate={async (dataCategory, transferId) => {
                                await changeDisclosure(transferId, (t) => {
                                    const dcIndex = t!.dataCategories.findIndex((x) => x.dataCategoryId === dataCategory.dataCategoryId);
                                    t.dataCategories[dcIndex] = dataCategory;
                                });
                            }}
                            onSharingNameChange={async (name, transferId) => {
                                await changeDisclosure(transferId, (d) => {
                                    d.name = name;
                                });
                            }}
                            onSharingDescriptionChange={async (description, transferId) => {
                                await changeDisclosure(transferId, (d) => {
                                    d.description = description;
                                });
                            }}
                            onDisableDisclosuresClick={(e) => props.onDisableSharingsChange(e, StepTypeEnum.disclosure)}
                        />
                    ),
                    graphicType: undefined,
                };
            case StepTypeEnum.sources:
                return {
                    childElement: (
                        <SourceStep
                            isReadOnly={props.isValidated || permissionIsReadOnly(permissions.processingActivitySourcesPermissions.permissions)}
                            hasData={props.processingActivityModel.hasSources}
                            processingActivity={processingActivity}
                            isAddingSource={isAddingSource}
                            disableSources={props.disableSources}
                            sources={processingActivity.sources}
                            dataCategories={processingActivity.dataCategories.map((x) => x.dataCategoryId)}
                            entitesFromAssociation={processingActivity.legalEntities}
                            validationErrors={props.validationErrors}
                            processingActivityOwner={props.processingActivityModel.owner}
                            onDataControllerChange={async (legalUnitid, sourceId) => {
                                await changeSource(sourceId, (s) => {
                                    s.agreementIds = [];
                                    s.dataControllerId = legalUnitid === null ? undefined : legalUnitid;
                                });
                            }}
                            onCreateNewDocument={async (documentId, sourceId) => {
                                await changeSource(sourceId, (d) => {
                                    d.agreementIds.push(documentId);
                                    d.hasDataProcessingAgreement = false;
                                });
                            }}
                            onResponsibleChange={async (responsibleId, sourceId) => {
                                await changeSource(sourceId, (s) => {
                                    s.responsible = responsibleId === null ? undefined : responsibleId;
                                });
                            }}
                            onChosenDataCategories={async (dataCategories, sourceId) => {
                                await changeSource(sourceId, (s) => {
                                    s.dataCategories = dataCategories ?? [];
                                });
                            }}
                            onGroupEntitiesChange={legalEntitiesChangedOnSource}
                            onRemoveSourceClick={async (sourceId) => {
                                const newProcessingActivityModel = { ...processingActivity };
                                newProcessingActivityModel.sources = newProcessingActivityModel.sources.filter((s) => s.id !== sourceId);
                                props.onChange(newProcessingActivityModel);
                                await removeSource(sourceId);
                            }}
                            onAddNewSourceClick={async () => {
                                await addSource();
                            }}
                            onSharingNameChange={async (name, sourceId) => {
                                await changeSource(sourceId, (d) => {
                                    d.name = name;
                                });
                            }}
                            onSharingDescriptionChange={async (description, sourceId) => {
                                await changeSource(sourceId, (d) => {
                                    d.description = description;
                                });
                            }}
                            onDisableSources={(e) => props.onDisableSharingsChange(e, StepTypeEnum.sources)}
                        />
                    ),
                    graphicType: undefined,
                };
            case StepTypeEnum.dataController:
                return {
                    childElement: getDataProcessorStep(true),
                    graphicType: undefined,
                };
            case StepTypeEnum.jointDataControllers:
                return {
                    childElement: (
                        <JointDataControllerStep
                            readOnly={props.isValidated || permissionIsReadOnly(permissions.processingActivityJointDataControllerPermissions.permissions)}
                            processingActivity={props.processingActivityModel}
                            jointDataControllers={processingActivity.jointDataControllers}
                            isAddingJointDataController={false}
                            onAddJointDataControllerClick={async () => {
                                await addJointDataController();
                            }}
                            onRemoveJointDataControllerClick={async (joinDataControllerId) => {
                                const newProcessingActivityModel = { ...processingActivity };
                                newProcessingActivityModel.jointDataControllers = newProcessingActivityModel.jointDataControllers.filter(
                                    (s) => s.id !== joinDataControllerId
                                );
                                props.onChange(newProcessingActivityModel);
                                await removeJointDataController(joinDataControllerId);
                            }}
                            onSharingNameChange={async (name, jointDataControllerId) => {
                                await changeJointDataController(jointDataControllerId, (d) => {
                                    d.name = name;
                                });
                            }}
                            onSharingDescriptionChange={async (description, jointDataControllerId) => {
                                await changeJointDataController(jointDataControllerId, (d) => {
                                    d.description = description;
                                });
                            }}
                            onDataControllerChange={async (legalUnitid, joinDataControllerId) => {
                                await changeJointDataController(joinDataControllerId, (s) => {
                                    s.documents = [];
                                    s.hasAgreement = false;
                                    s.dataControllerId = legalUnitid === null ? undefined : legalUnitid;
                                });
                            }}
                            onResponsibleChange={async (responsibleId, jointControllerId) => {
                                await changeJointDataController(jointControllerId, (s) => {
                                    s.responsible = responsibleId === null ? undefined : responsibleId;
                                });
                            }}
                            onChosenDataCategories={async (dataCategories, jointControllerId) => {
                                await changeJointDataController(jointControllerId, (s) => {
                                    s.dataCategories = dataCategories ?? [];
                                });
                            }}
                            onLegalEntitiesChange={legalEntitiesChangedOnJointDataController}
                            validationErrors={props.validationErrors}
                            onDisableClick={(e) => props.onDisableSharingsChange(e, StepTypeEnum.jointDataControllers)}
                            onCreateNewDocument={async (documentId, jointDataControllerId) => {
                                await changeJointDataController(jointDataControllerId, (d) => {
                                    d.documents.push(documentId);
                                    d.hasAgreement = false;
                                });
                            }}
                            onAgreementChange={async (agreements, jointDataControllerId) => {
                                const agreementIds = processingActivity.jointDataControllers.find((d) => d.id === jointDataControllerId)!.documents;
                                await onAgreementsChanged(
                                    agreements,
                                    agreementIds,
                                    async (yesOrNo) =>
                                        await changeJointDataController(jointDataControllerId, (s) => {
                                            s.hasAgreement = yesOrNo;
                                            s.documents = [];
                                        }),
                                    async () =>
                                        await changeJointDataController(jointDataControllerId, (s) => {
                                            s.documents = agreements;
                                            s.hasAgreement = false;
                                        })
                                );
                            }}
                        />
                    ),
                    graphicType: undefined,
                };
            default:
                return {
                    childElement: <div>Unknown stepIndex</div>,
                    graphicType: undefined,
                };
        }
    }

    let subSteps = props.getSubSteps();
    let activeSubStep = props.getActiveSubStep();
    const stepIndex = subSteps.indexOf(subSteps.find((s) => s.stepType === activeSubStep)!);
    const { translateString } = useTranslation();

    return (
        <React.Fragment>
            <SegmentStep size="small">
                <DotLegalStepHeader toolTipText={translateString("sharingOfDataSubStepperHelperText")} record={false} topMargin={false}>
                    {translateString("sharingOfDataSubStepperHeader")}
                </DotLegalStepHeader>
                <DotLegalCoreStepper steps={subSteps} isSubStep activeStepIndex={stepIndex} onStepClick={(stepType) => props.changeSubStep(stepType)} />
            </SegmentStep>

            <React.Fragment>{getStepContent(activeSubStep).childElement}</React.Fragment>
        </React.Fragment>
    );

    async function changeDataProcessor(dataProcessorId: string, changeFunc: (dataProcessor: DataProcessor) => void) {
        const newProcessingActivityModel = { ...processingActivity };
        const dataProcessor = newProcessingActivityModel.dataProcessors.find((d) => d.id === dataProcessorId)!;

        if (dataProcessor !== undefined) {
            changeFunc(dataProcessor);
            props.onChange(newProcessingActivityModel);
            await updateDataProcessor(dataProcessor);
        }
    }

    async function changeDisclosure(transferId: string, changeFunc: (transfer: Disclosure) => void) {
        const newProcessingActivityModel = { ...processingActivity };
        const transfer = newProcessingActivityModel.disclosures.find((d) => d.id === transferId)!;
        if (transfer !== undefined) {
            changeFunc(transfer);
            props.onChange(newProcessingActivityModel);
            await updateTransfer(transfer);
        }
    }

    async function changeSource(sourceId: string, changeFunc: (source: Source) => void) {
        const newProcessingActivityModel = { ...processingActivity };
        const source = newProcessingActivityModel.sources.find((d) => d.id === sourceId)!;
        if (source !== undefined) {
            changeFunc(source);
            props.onChange(newProcessingActivityModel);
            await updateSource(source);
        }
    }

    async function changeJointDataController(joinDataControllerId: string, changeFunc: (jointdataController: JointDataController) => void) {
        const newProcessingActivityModel = { ...processingActivity };
        const jointDataController = newProcessingActivityModel.jointDataControllers.find((d) => d.id === joinDataControllerId)!;

        if (jointDataController !== undefined) {
            changeFunc(jointDataController);
            props.onChange(newProcessingActivityModel);
            await updateJointDataController(jointDataController);
        }
    }
}

export default SharingOfDataStep;
