import { DotLegalInformationBoxModel } from "../../common/components/dotLegalInformationBox/DotLegalInformationBox.types";

export enum DataPrivacyClassification {
    None = 0,
    Ordinary = 1,
    Sensitive = 2,
    NationalIdentificationNumber = 3,
    CriminalOffenses = 4,
}

export interface DataCategoryViewModel extends DotLegalInformationBoxModel {
    classification: DataPrivacyClassification;
    dataTypeIds: Array<string>;
    selectedDataTypeIds: Array<string>;
}
