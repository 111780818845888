import { Box, Collapse, Grid, IconButton, Skeleton, SxProps, Theme, Tooltip } from "@mui/material";
import React from "react";
import { collapseMinHeight, useDotLegalCollapseStyles } from "./DotLegalCollapse.styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Guid } from "../../guid";
import { DotLegalButton, ToDto, UrlToPathObject } from "@dotlegal/dotlegal-ui-components";
import { HashLink } from "react-router-hash-link";

export interface IDotLegalCollapse {
    collapseName?: string | JSX.Element;
    children?: any;
    isExpanded: boolean;
    centerContent?: any;
    onExpandClick: () => void;
    removeItemText?: string;
    onRemoveClick?: () => void;
    className?: string;
    sx?: SxProps<Theme> | undefined;
    listKey?: string | number;
    disabled?: boolean;
    objectId?: string;
    objectName?: string;
    isLoading?: boolean;
    error?: boolean;
    collapseIcon?: { icon: JSX.Element; iconHoverText: string; href?: ToDto | string };
    id?: string;
    persistCollapseName?: boolean;
    hideCollapseCenterName?: boolean;
    isCollapseNameBold?: boolean;
    leftAlign?: boolean;
    collapseStyle?: "primary" | "secondary";
    invertExpandedArrow?: boolean;
    color?: "blue" | "green";
    headerColor?: "primary" | "grey";
}

function DotLegalCollapse(props: IDotLegalCollapse) {
    const styles = useDotLegalCollapseStyles(props);
    const getIconForCollapse = () => {
        if (props.collapseIcon) {
            return (
                <Box className="collapseSVG">
                    <Tooltip title={props.collapseIcon.iconHoverText}>
                        {props.collapseIcon.href ? (
                            <Box sx={styles.collapseIcons}>
                                <HashLink smooth to={UrlToPathObject(props.collapseIcon.href)} onClick={(event) => event.stopPropagation()}>
                                    <IconButton sx={styles.collapseIcon}>{props.collapseIcon.icon}</IconButton>
                                </HashLink>
                            </Box>
                        ) : (
                            props.collapseIcon.icon
                        )}
                    </Tooltip>
                </Box>
            );
        }
    };

    const GetCollapseArrow = () => {
        return (
            <React.Fragment>
                {props.isExpanded ? (
                    <>
                        {getIconForCollapse()}
                        <Box sx={styles.collapseIcons}>
                            <IconButton onClick={props.onExpandClick}>
                                {props.invertExpandedArrow ? <KeyboardArrowDownIcon fontSize="large" /> : <KeyboardArrowUpIcon fontSize="large" />}
                            </IconButton>
                        </Box>
                    </>
                ) : (
                    <Box sx={styles.collapseIcons}>
                        {getIconForCollapse()}
                        <IconButton onClick={props.onExpandClick}>
                            {props.invertExpandedArrow ? <KeyboardArrowUpIcon fontSize="large" /> : <KeyboardArrowDownIcon fontSize="large" />}
                        </IconButton>
                    </Box>
                )}
            </React.Fragment>
        );
    };

    const GetCollapsedJSX = () => {
        return (
            <Collapse
                className={props.className}
                sx={[styles.collapse, ...(Array.isArray(props.sx) ? props.sx : [props.sx])]}
                in={props.isExpanded}
                collapsedSize={collapseMinHeight}
            >
                {!props.isExpanded && (
                    <Grid container sx={styles.gridContainer}>
                        <Grid item xs={props.hideCollapseCenterName ? 9 : 3}>
                            {props.collapseName && <div>{props.collapseName}</div>}
                        </Grid>
                        {!props.hideCollapseCenterName && (
                            <Grid item xs={6}>
                                {props.isLoading ? (
                                    <Box sx={styles.skeletonContainer}>
                                        <Skeleton width={"30%"} />
                                    </Box>
                                ) : (
                                    <Box sx={styles.gridContainerCenter}>{props.centerContent}</Box>
                                )}
                            </Grid>
                        )}
                        <Grid sx={styles.gridContainerRight} item xs={3}>
                            {GetCollapseArrow()}
                        </Grid>
                    </Grid>
                )}

                {props.isExpanded && (
                    <React.Fragment>
                        <Grid container sx={styles.gridContainer}>
                            <Grid item xs={9}>
                                {props.collapseName && props.persistCollapseName && <div>{props.collapseName}</div>}
                            </Grid>
                            <Grid sx={styles.gridContainerRight} item xs={3}>
                                {GetCollapseArrow()}
                            </Grid>
                        </Grid>
                        <Box sx={styles.collapseChild} className={`collapseChildContainer`}>
                            {props.children}
                            {props.removeItemText && !props.disabled && (
                                <Box sx={styles.removeBtnContainer}>
                                    <DotLegalButton buttonType="danger" onClick={props.onRemoveClick}>
                                        {props.removeItemText}
                                    </DotLegalButton>
                                </Box>
                            )}
                        </Box>
                    </React.Fragment>
                )}
            </Collapse>
        );
    };

    return (
        <React.Fragment key={props.listKey ?? Guid.newGuid.toString()}>
            <Box id={props.id}>
                {!props.isExpanded ? (
                    <Box onClick={props.onExpandClick} sx={styles.onCollapsed}>
                        {GetCollapsedJSX()}{" "}
                    </Box>
                ) : (
                    GetCollapsedJSX()
                )}
            </Box>
        </React.Fragment>
    );
}

export default DotLegalCollapse;
