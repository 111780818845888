import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { post, put } from "../../common/api/apiShared";
import { LegalEntityRiskAssessment, LegalEntityRiskAssessmentQuestion } from "../legalEntityRiskQuestionnaire/LegalEntityRiskQuestionnaire.types";
import { ILegalEntityRiskAssessmentDialogProps } from "./LegalEntityRiskAssessmentDialog";

export function UseLegalEntityRiskAssessmentDialogHook(props: ILegalEntityRiskAssessmentDialogProps) {
    const { id } = useParams<{ id: string }>();

    const saveAnswerMutation = useMutation(saveQuestionnaire);
    function saveQuestionnaire(questions: Array<LegalEntityRiskAssessmentQuestion>) {
        return post<{}>(`/LegalEntities/${id}/riskAssessmentQuestions`, questions);
    }

    const riskAssessmentSave = useMutation(saveRiskAssessmentApi);
    function saveRiskAssessmentApi(riskAssessment: LegalEntityRiskAssessment | undefined | null) {
        return put<{}>(`/LegalEntity/${id}/riskAssessment`, riskAssessment);
    }

    const saveRiskAssessment = (questions: Array<LegalEntityRiskAssessmentQuestion>, riskAssessment: LegalEntityRiskAssessment | undefined | null) => {
        riskAssessmentSave.mutateAsync(riskAssessment, {
            onSuccess() {
                saveAnswerMutation.mutateAsync(questions, {
                    onSuccess: (riskAssessment: any) => {
                        props.refetchEntity();
                    },
                });
            },
        });
    };

    return {
        saveRiskAssessment,
        isSaving: riskAssessmentSave.isLoading || saveAnswerMutation.isLoading,
    };
}
