import { Box, Typography } from "@mui/material";
import DotLegalLogo from "../../../main/NavMenu/dotLegalLogo.svg?react";
import { useTranslation } from "../../../localization/useTranslation.ts";
import { useComplianceReportFrontPageStyles } from "./ComplianceReportFrontPage.styles.ts";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider.tsx";

function ComplianceReportFrontPage() {
    const styles = useComplianceReportFrontPageStyles();
    const { translateString, translateDate } = useTranslation();
    const { customerName } = useUserContext();

    return (
        <Box sx={styles.root} className="page-break-after">
            <Box
                sx={{
                    display: "flex",
                    color: "primary.dark",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                }}
            >
                <Box>
                    <Typography mb={4} sx={[styles.bold, { fontSize: 60, textTransform: "capitalize" }]}>
                        {translateString("complianceReport")}
                    </Typography>
                    <Typography sx={[styles.bold, { fontSize: 35 }]}>{customerName}</Typography>
                </Box>

                <Box mt={9}>
                    <Typography sx={{ fontSize: 25, mt: 2 }}>
                        <Box component={"span"} sx={styles.bold}>
                            {translateString("date")}:&nbsp;
                        </Box>
                        {translateDate(new Date())}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ position: "absolute", bottom: 0, right: 0 }}>
                <Box mr={5} mb={3} sx={{ "& svg": { height: 55, width: 165 } }}>
                    <DotLegalLogo />
                </Box>
            </Box>
        </Box>
    );
}

export default ComplianceReportFrontPage;
