import { useQuery } from "react-query";
import { get, put } from "../../../common/api/apiShared";
import { useParams } from "react-router";
import {
    CompletedControlProcedureViewModel,
    SystemControlProcedureFrequency,
    SystemControlProcedureSaveModel,
    SystemControlProcedureType,
    SystemControlProcedureViewModel,
} from "./SystemControlProcedureDialog.types";
import { getEnumValues } from "../../../common/enumOperations";
import { setFirstLetterToLowerCase } from "../../../common/stringOperations";
import { useOptimisticUpdate } from "../../../common/hooks/useOptimisticUpdate";

export function useSystemControlProcedureDialogHooks() {
    const { id } = useParams<{ id: string }>();
    const optimisticUpdate = useOptimisticUpdate();

    const url = `/systemControlProcedure/${id}`;
    let { isLoading, data } = useQuery(url, () => get<SystemControlProcedureViewModel>(url));

    const onTypeChange = (type: SystemControlProcedureType) => {
        const temp = { ...data! };
        temp.type = type;

        onControlProcedureChange(temp);
    };

    const onFrequencyChange = (frequency: SystemControlProcedureFrequency) => {
        const temp = { ...data! };
        temp.frequency = frequency;

        onControlProcedureChange(temp);
    };

    const onCompletedControlProcedureAdded = (completedAudit: CompletedControlProcedureViewModel) => {
        const temp = { ...data! };
        temp.completedControls.push(completedAudit);

        onCompletedContronProcedureChange(temp);
    };

    const onCompletedControlProcedureRemoved = (completedAuditId: string) => {
        const temp = { ...data! };
        temp.completedControls = temp.completedControls.filter((x) => x.id !== completedAuditId);

        onCompletedContronProcedureChange(temp);
    };

    async function onCompletedContronProcedureChange(completedAudit: SystemControlProcedureViewModel) {
        await optimisticUpdate.setQueryData(url, completedAudit);
    }

    async function onControlProcedureChange(completedAudit: SystemControlProcedureViewModel) {
        await optimisticUpdate.putOnQueueAndSetQueryData(completedAudit, url, updateControlProcedure, mapToSaveModel(completedAudit));
    }

    async function updateControlProcedure(saveModel: SystemControlProcedureSaveModel) {
        return await put<SystemControlProcedureSaveModel>("/systemControlProcedure/" + data?.id, saveModel);
    }

    return { isLoading, data, onTypeChange, onFrequencyChange, onCompletedControlProcedureAdded, onCompletedControlProcedureRemoved };
}

export const systemControlProcedureFrequencyOptions = (translateString: any) => {
    return getEnumValues(SystemControlProcedureFrequency).map((x) => {
        var name = translateString(setFirstLetterToLowerCase(SystemControlProcedureFrequency[x].toString()));
        return { name: name, id: x.toString() };
    });
};

export const systemControlProcedureTypeOptions = (translateString: any) => {
    return getEnumValues(SystemControlProcedureType).map((x) => {
        var name = translateString(setFirstLetterToLowerCase(SystemControlProcedureType[x].toString()));
        return { name: name, id: x.toString() };
    });
};

function mapToSaveModel(viewModel: SystemControlProcedureViewModel) {
    return {
        type: viewModel.type ?? undefined,
        frequency: viewModel.frequency,
    };
}
