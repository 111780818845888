export interface CustomListSelectorLinks {
    name: string;
    link: string;
}

export enum CustomListSelectorType {
    ProcessingActivity,
    Company,
    System,
}
