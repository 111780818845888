import React, { Fragment } from "react";
import { DotLegalDialog, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { _arrayBufferToBase64 } from "../../common/arrayBufferToBase64";
import DotLegalFileUploadWrapper from "../../common/components/dotLegalFileUpload/DotLegalFileUploadWrapper";
import { Box, Grid } from "@mui/material";
import { useAddNotifications } from "./AddNotifications.hooks";

export interface IAddNotificationsDialogProps {
    onCloseDialog: () => void;
}

function AddNotificationsDialog(props: IAddNotificationsDialogProps) {
    const { inProgress, validate, saveModel, setSaveModel, errors } = useAddNotifications(props);
    const { translateString, translateLanguage } = useTranslation();

    return (
        <DotLegalDialog
            inProgress={inProgress}
            size="sm"
            open
            header={translateString("addNotifications")}
            buttonOkText={translateString("addNotificationsOk")}
            onDialogClose={props.onCloseDialog}
            onOkClick={() => validate()}
            disableBackdropOnCloseIfValueChange={saveModel}
        >
            <Fragment>
                <Box marginBottom={2}>
                    <DotLegalTextField
                        label={translateString("addNotificationsName")}
                        value={saveModel?.name!}
                        errorText={errors?.name}
                        debounce={false}
                        onChange={(name) => {
                            let tempViewModel = { ...saveModel! };
                            tempViewModel.name = name;
                            setSaveModel(tempViewModel);
                        }}
                    />
                </Box>
                <Grid container columnSpacing={1} alignItems={"center"}>
                    <Grid item md={2} marginRight={1}>
                        {translateLanguage("DA")}:
                    </Grid>
                    <Grid item md={9} marginBottom={2}>
                        <DotLegalFileUploadWrapper
                            files={[saveModel?.danishFileName!]}
                            multiple={false}
                            errorText={errors?.danishFile}
                            onFilesChanged={async (file) => {
                                let tempSaveModel = { ...saveModel! };
                                tempSaveModel.danishBase64Data = file ? await _arrayBufferToBase64(file[0].data) : "";
                                tempSaveModel.danishFileName = file ? file[0].fileName : "";
                                setSaveModel(tempSaveModel);
                            }}
                            isLoading={false}
                        />
                    </Grid>
                    <Grid item md={2} marginRight={1}>
                        {translateLanguage("EN")}:
                    </Grid>
                    <Grid item md={9}>
                        <DotLegalFileUploadWrapper
                            files={[saveModel?.englishFileName!]}
                            multiple={false}
                            errorText={errors?.englishFile}
                            onFilesChanged={async (file) => {
                                let tempSaveModel = { ...saveModel! };
                                tempSaveModel.englishBase64Data = file ? await _arrayBufferToBase64(file[0].data) : "";
                                tempSaveModel.englishFileName = file ? file[0].fileName : "";
                                setSaveModel(tempSaveModel);
                            }}
                            isLoading={false}
                        />
                    </Grid>
                </Grid>
            </Fragment>
        </DotLegalDialog>
    );
}

export default AddNotificationsDialog;
