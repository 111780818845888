import { DataType } from "./DataTypesList.types.ts";
import { useMutation } from "react-query";
import { post, put } from "../../../common/api/apiShared.ts";
import { useValidator } from "../../../common/hooks/validation";
import { useTranslation } from "../../../localization/useTranslation.ts";
import { createElement, useState } from "react";
import { IAddEditDataTypeDialogProps } from "./AddEditDataTypeDialog.tsx";
import { Trans } from "react-i18next";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";

export function useAddEditDataTypeDialog(props: IAddEditDataTypeDialogProps) {
    const { translateString } = useTranslation();
    const [saveModel, setSaveModel] = useState<DataType>(props.toUpdate ?? { name: "", id: "" });
    const addMutation = useMutation(addDataType);
    const snackBar = useDotLegalSnackbar();

    const updateMutation = useMutation(updateDataType);
    function addDataType() {
        return post("/DataTypes", saveModel);
    }
    function updateDataType(id: string) {
        return put(`/DataTypes/${id}`, saveModel);
    }

    const validation = useValidator<DataType>(
        (validators) => ({
            name: validators.validateNotEmpty((item) => item.name, translateString("name")),
        }),
        [saveModel]
    );
    validation.check(saveModel);

    const onModelChange = (updateFunc: (model: DataType) => void) => {
        let tempViewModel = { ...saveModel! };
        updateFunc(tempViewModel);
        setSaveModel(tempViewModel);
    };

    const onSave = async () => {
        if (validation.anyHasErrors) {
            validation.setShowErrors(true);
            return;
        }
        if (props.isAdd) {
            await addMutation.mutateAsync();
            snackBar.show(createElement(Trans, { i18nKey: "itemCreatedSuccess", values: { name: saveModel.name } }));
        } else {
            await updateMutation.mutateAsync(props.toUpdate!.id);
            snackBar.show(createElement(Trans, { i18nKey: "itemEditedSuccess", values: { name: saveModel.name } }));
        }

        props.onSaved();
    };

    return {
        isSaving: addMutation.isLoading || updateMutation.isLoading,
        validation,
        onModelChange,
        saveModel,
        onSave,
    };
}
