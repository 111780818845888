import { Box, Menu, MenuItem, styled } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "../../localization/useTranslation";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { CustomListSelectorType } from "./CustomListSelector.types";
import { useCustomListSelectorHooks } from "./CustomListSelector.hooks";
import { DotLegalButton } from "@dotlegal/dotlegal-ui-components";
import { CallPage } from "../CustomLists.types";

export interface ICustomListSelector {
    type: CustomListSelectorType;
    callPage?: CallPage;
}

function CustomListSelector(props: ICustomListSelector) {
    const { translateString } = useTranslation();
    const { selectable, hasMultipleSelection } = useCustomListSelectorHooks(props);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const CustomListLink = styled(Link)(({ theme }) => ({
        color: theme.palette.primary.dark,
        fontWeight: theme.typography.fontWeightMedium,
        textDecoration: "none",
    }));

    return (
        <React.Fragment>
            {hasMultipleSelection ? (
                <Box
                    sx={(theme) => ({
                        marginLeft: theme.spacing(2),
                    })}
                >
                    <React.Fragment>
                        <DotLegalButton
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleClick}
                            endIcon={hasMultipleSelection && <KeyboardArrowDownIcon />}
                            buttonType={"secondary"}
                        >
                            {translateString("customLists")}
                        </DotLegalButton>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                "aria-labelledby": "basic-button",
                            }}
                        >
                            {selectable.map((link, index) => {
                                return (
                                    <CustomListLink key={index} to={{ pathname: link.link() }}>
                                        <MenuItem sx={{ fontSize: 14 }}>{link.name}</MenuItem>
                                    </CustomListLink>
                                );
                            })}
                        </Menu>
                    </React.Fragment>
                </Box>
            ) : (
                <DotLegalButton buttonType="secondary" href={selectable[0].link()}>
                    {selectable[0].name}
                </DotLegalButton>
            )}
        </React.Fragment>
    );
}

export default CustomListSelector;
