import { useCallback, useEffect, useMemo, useState } from "react";
import { useMutation } from "react-query";
import { deleteHttp, get, put } from "../../common/api/apiShared";
import { useTranslation } from "../../localization/useTranslation";
import { useValidator } from "../../common/hooks/validation";
import { CustomQuestionType, ExistingCustomQuestion, NewCustomQuestion } from "./RiskAssessmentCustomQuestions.types";
import { RiskAssessmentArea, RiskComplianceArea } from "../riskAssessments/RiskAssessments.types";

export function useRiskAssessmentCustomQuestions(
    riskAssessmentArea: RiskAssessmentArea,
    riskComplianceArea: RiskComplianceArea,
    riskAssessmentVersionId: string | undefined,
    options?: { editCustomQuestionId?: string; fetchCustomQuestions?: boolean }
) {
    const [customQuestion, setCustomQuestion] = useState<CustomQuestionType>();
    const [customQuestions, setCustomQuestions] = useState<ExistingCustomQuestion[]>([]);
    const [newCustomQuestion, setNewCustomQuestion] = useState<CustomQuestionType | undefined>({
        isNew: true,
        riskAssessmentArea: riskAssessmentArea,
        riskComplianceArea: riskComplianceArea,
    } as CustomQuestionType);
    const getQuestionMutation = useMutation(getCustomQuestion);
    const getQuestionsMutation = useMutation(getQuestionsUrl);
    const putQuestionMutation = useMutation(putNewCustomQuestion);
    const updateQuestionMutation = useMutation(updateExistingCustomQuestion);
    const deleteQuestionMutation = useMutation(deleteQuestion);
    const { getChosenLanguage, translateString } = useTranslation();
    const chosenLanguage = useMemo(() => {
        return getChosenLanguage();
    }, [getChosenLanguage]);
    const customQuestionValidator = useValidator<Partial<CustomQuestionType>>(
        (validators) => {
            return {
                localizationDa: validators.validateAnyNotEmpty(
                    (item) => [item.localizationDa, item.localizationEn],
                    translateString("customQuestionDanishLocalization")
                ),
                localizationEn: validators.validateAnyNotEmpty(
                    (item) => [item.localizationDa, item.localizationEn],
                    translateString("customQuestionEnglishLocalization")
                ),
            };
        },
        [chosenLanguage]
    );

    const onSaveCustomQuestion = async (edit?: boolean) => {
        let success = false;
        if (customQuestionValidator.anyHasErrors) {
            customQuestionValidator.setShowErrors(true);
            return;
        }

        if (!edit)
            await putQuestionMutation.mutateAsync(
                { newQuestion: newCustomQuestion as NewCustomQuestion, riskAssessmentVersionId: riskAssessmentVersionId },
                {
                    onSuccess: async (result) => {
                        const newQuestion = result.value();
                        setCustomQuestions((prevState) => [...prevState, newQuestion] as ExistingCustomQuestion[]);
                        success = true;
                    },
                }
            );
        if (edit)
            await updateQuestionMutation.mutateAsync(
                { existingQuestion: customQuestion as ExistingCustomQuestion, riskAssessmentVersionId: riskAssessmentVersionId },
                {
                    onSuccess: (result) => {
                        const updateExistingQuestion = result.value();
                        setCustomQuestions((prevState) => [...prevState.map((x) => (x.id === customQuestion?.id ? updateExistingQuestion : x))]);
                        success = true;
                    },
                }
            );

        return success;
    };

    const onDeleteCustomQuestion = async (customQuestionId: string) => {
        let success = false;
        await deleteQuestionMutation.mutateAsync(customQuestionId, {
            onSuccess: () => {
                success = true;
                setCustomQuestions((prevState) => prevState.filter((x) => x.id !== customQuestionId));
            },
        });
        return success;
    };

    const onGetCustomQuestion = useCallback(async () => {
        let success = false;
        if (!options?.editCustomQuestionId) return () => {};
        await getQuestionMutation.mutateAsync(options?.editCustomQuestionId, {
            onSuccess: (data) => {
                setCustomQuestion({
                    ...data,
                    isNew: false,
                    riskAssessmentArea: data.riskAssessmentArea,
                    riskComplianceArea: data.riskComplianceArea,
                } as ExistingCustomQuestion);
                success = true;
            },
        });
        return success;
    }, [getQuestionMutation, options?.editCustomQuestionId]);

    const onGetCustomQuestions = useCallback(async () => {
        let success = false;
        await getQuestionsMutation.mutateAsync(undefined, {
            onSuccess: (data) => {
                setCustomQuestions(data.map((x) => ({ ...x, isNew: false })) as ExistingCustomQuestion[]);
                success = true;
            },
        });
        return success;
    }, [getQuestionsMutation]);

    const getCustomQuestionDisplayText = (customQuestionId: string): string => {
        const question = customQuestions.find((x) => x.id === customQuestionId);
        const notFoundMessage = `Question Display Value not found for id: ${customQuestionId}`;
        if (!question) return notFoundMessage;

        if (chosenLanguage === "en" || chosenLanguage.toLocaleLowerCase() === "en-us")
            return question.localizationEn ?? question.localizationDa ?? notFoundMessage;
        if (chosenLanguage === "da" || chosenLanguage.toLocaleLowerCase() === "da-dk")
            return question.localizationDa ?? question.localizationEn ?? notFoundMessage;
        return notFoundMessage;
    };

    useEffect(() => {
        if (options?.fetchCustomQuestions) onGetCustomQuestions().then();
        if (options?.editCustomQuestionId) onGetCustomQuestion().then();
        // eslint-disable-next-line
    }, [options?.fetchCustomQuestions, options?.editCustomQuestionId]);

    return {
        customQuestion,
        setCustomQuestion,
        customQuestions,
        onDeleteCustomQuestion,
        onSaveCustomQuestion,
        onGetCustomQuestions,
        newCustomQuestion: newCustomQuestion,
        setNewCustomQuestion,
        getCustomQuestionDisplayText,
        customQuestionValidator: customQuestionValidator,
    };

    function getQuestionsUrl() {
        if (riskAssessmentArea === RiskAssessmentArea.ProcessingActivities && riskComplianceArea === RiskComplianceArea.GDPR) {
            return getCustomQuestions(RiskComplianceArea.GDPR, riskAssessmentVersionId);
        } else if (riskAssessmentArea === RiskAssessmentArea.ProcessingActivities && riskComplianceArea === RiskComplianceArea.NIS2) {
            return getCustomQuestions(RiskComplianceArea.NIS2, riskAssessmentVersionId);
        } else if (riskAssessmentArea === RiskAssessmentArea.Systems && riskComplianceArea === RiskComplianceArea.GDPR) {
            return getCustomSystemQuestions(RiskComplianceArea.GDPR, riskAssessmentVersionId);
        } else if (riskAssessmentArea === RiskAssessmentArea.Systems && riskComplianceArea === RiskComplianceArea.NIS2) {
            return getCustomSystemQuestions(RiskComplianceArea.NIS2, riskAssessmentVersionId);
        } else {
            throw new Error(`Unknown value RiskAssessmentArea: ${riskAssessmentArea} and RiskComplianceArea: ${riskComplianceArea}`);
        }
    }
}

function getCustomQuestions(complianceArea: RiskComplianceArea, riskAssessmentVersionId: string | undefined): Promise<ExistingCustomQuestion[]> {
    return get<ExistingCustomQuestion[]>(
        `/RiskAssessmentCustomQuestion/getCustomProcessingActivityQuestions?complianceArea=${complianceArea}&riskAssessmentVersionId=${riskAssessmentVersionId}`
    );
}

function getCustomSystemQuestions(complianceArea: RiskComplianceArea, riskAssessmentVersionId: string | undefined): Promise<ExistingCustomQuestion[]> {
    return get<ExistingCustomQuestion[]>(
        `/RiskAssessmentCustomQuestion/getCustomSystemQuestions?complianceArea=${complianceArea}&riskAssessmentVersionId=${riskAssessmentVersionId}`
    );
}

function getCustomQuestion(customQuestionId: string): Promise<ExistingCustomQuestion> {
    return get<ExistingCustomQuestion>(`/RiskAssessmentCustomQuestion/${customQuestionId}/getCustomQuestion`);
}

function putNewCustomQuestion(data: { newQuestion: NewCustomQuestion; riskAssessmentVersionId: string | undefined }) {
    return put<ExistingCustomQuestion>(
        `/RiskAssessmentCustomQuestion/createCustomQuestion?riskAssessmentVersionId=${data.riskAssessmentVersionId}`,
        data.newQuestion
    );
}

function updateExistingCustomQuestion(data: { existingQuestion: ExistingCustomQuestion; riskAssessmentVersionId: string | undefined }) {
    return put<ExistingCustomQuestion>(
        `/RiskAssessmentCustomQuestion/${data.existingQuestion.id}/updateCustomQuestion?riskAssessmentVersionId=${data.riskAssessmentVersionId}`,
        data.existingQuestion
    );
}

function deleteQuestion(existingQuestionId: string) {
    return deleteHttp<void>(`/RiskAssessmentCustomQuestion/${existingQuestionId}/deleteCustomQuestion`);
}
