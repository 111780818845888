import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useMutation } from "react-query";
import { deleteHttp } from "../../../common/api/apiShared";
import { createElement, useState } from "react";
import { Result } from "../../../common/api/result";
import { Trans } from "react-i18next";
import { IDeleteAuditDialog } from "./DeleteAuditDialog";

export default function useDeleteAuditDialogHooks(props: IDeleteAuditDialog) {
    const snackbar = useDotLegalSnackbar();

    const [confirmDelete, setConfirmDelete] = useState(false);

    const removeMutation = useMutation(remove);

    const removeAuditClick = () => {
        removeMutation.mutateAsync(undefined, {
            onSuccess: (response: Result<string>) => {
                props.onDialogClose();
                if (response.success()) {
                    props.onSuccessDelete();
                    snackbar.show(createElement(Trans, { i18nKey: "auditDeleted", values: { name: props.questionnaireName } }));
                } else {
                    snackbar.show(createElement(Trans, { i18nKey: "auditNotDeleted", values: { name: props.questionnaireName } }), "warning");
                }
            },
        });
    };

    return {
        removeAuditClick,
        inProgress: removeMutation.isLoading,
        confirmDelete,
        setConfirmDelete,
    };

    async function remove() {
        return await deleteHttp<string>(`/legalEntityAudit/${props.auditId}`);
    }
}
