import { useState } from "react";
import { useResponsibleQuery } from "../../../user/hooks/useResponsibleQuery";
import { ChangeResponsiblesProps } from "./ChangeResponsible";
import { useTranslation } from "../../../localization/useTranslation";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useMutation } from "react-query";
import { put } from "../../../common/api/apiShared";

export function useChangeResponsible(props: ChangeResponsiblesProps) {
    const { translateString } = useTranslation();
    const [responsible, setResponsible] = useState(props.responsible);
    const snackbar = useDotLegalSnackbar();

    let responsibleQuery = useResponsibleQuery(true);

    const changeResponsibleMutation = useMutation(changeResponsibleApi, {
        onSuccess: () => {
            snackbar.show(translateString("responsibleChanged"));
            props.onChangedResponsible();
        },
    });

    const onChangeResponsibleClick = () => {
        changeResponsibleMutation.mutateAsync();
    };

    return {
        data: responsibleQuery.data(props.responsible),
        isLoading: responsibleQuery.isLoading,
        responsible,
        isChanging: changeResponsibleMutation.isLoading,
        setResponsible,
        onChangeResponsibleClick,
    };

    function changeResponsibleApi() {
        return put(`/legalEntityAudit/${props.auditId}/changeResponsible`, responsible);
    }
}
