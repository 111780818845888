import React from "react";
import DotLegalCollapse from "../../../common/components/dotLegalCollapse/DotLegalCollapse";
import DotLegalStepHeader from "../../../common/components/dotLegalStepHeader/DotLegalStepHeader";
import { useTranslation } from "../../../localization/useTranslation";
import {
    DataCategoryDataTypes,
    Disclosure,
    DisclosureDataCategory,
    LegalBasisBaseClass,
    ProcessingActivityModel,
    SelectableDataCategoryItem,
    SelectableLegalUnitsItem,
    StepTypeEnum,
} from "../../ProcessingActivity.types";
import SegmentStep from "../../stepSegment/StepSegment";
import { useDisclosureSubStep } from "./DisclosureStep.hooks";
import { DotLegalButton, DotLegalCheckbox, DotLegalSelect } from "@dotlegal/dotlegal-ui-components";
import { useDisclosureSubStepStyles } from "./DisclosureStep.styles";
import { Guid } from "../../../common/guid";
import DotLegalPersonalDataSelector from "../../../common/components/dotLegalPersonalDataSelector/DotLegalPersonalDataSelector";
import LegalEntityTable from "../../../legalEntity/legalEntityTable/LegalEntityTable";
import { ValidationError } from "../../../common/validationError";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Box, Divider } from "@mui/material";
import LegalBasisSelector from "../../../legalBasis/legalBasisSelector/LegalBasisSelector";
import LegalBasisSelectorContainer from "../../../legalBasis/legalBasisSelector/LegalBasisSelectorContainer";
import { DataPrivacyClassification } from "../../DataCategoriesStep/DataCategoriesStep.types";
import SharingsInfoBox from "../sharingsInfoBox/SharingsInfoBox";
import SharingsLegalEntity from "../sharingsLegalEntityBox/sharingsLegalEntity";
import { isLegalEntityAInsecureThirdCountry } from "../../../legalEntity/isInsecureThirdCountry";
import { useUrlProvider } from "../../../useUrlProvider";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import { getSelectableDocumentItems, getSelectedDocuments } from "../../hooks/useDataProcessingAgreement";
import { isNullOrWhitespace } from "../../../common/stringOperations";
import { DocumentStatus, DotLegalDocumentType } from "../../../documents/Documents.types";
import EditLegalEntityDocumentDialog from "../../../legalEntity/legalEntityDocumentTab/editLegalEntityDocumentDIalog/EditLegalEntityDocumentDialog";
import { filterTransferBasis } from "../../../legalBasis/TransferBasis.Helper";
import SharingsAgreementBox from "../sharingsAgreementBox/sharingsAgreementBox";
import { usePlanContext } from "../../../auth/planProvider/PlanProvider";
import { mapToDataCategorySelectorModel } from "../sharingOfDataStepFunctions.ts";

export interface IDisclosureSubStep {
    processingActivity: ProcessingActivityModel;
    transfers: Array<Disclosure>;
    dataCategories: Array<string>;
    entitesFromAssociation: Array<string>;
    validationErrors: Array<ValidationError>;
    isAddingTransfer: boolean;
    hasDataCreationPermission: boolean;
    processingActivityOwner: string | null | undefined;
    onLegalUnitChange: (legalUnitId: string | null, transferId: string, legalEntity: SelectableLegalUnitsItem | undefined) => void;
    onTransferAgreementChange: (agreement: Array<string>, transferId: string) => void;
    onCreateNewDocument: (agreement: string, dataProcessorId: string) => void;
    onResponsibleChange: (responsibleId: string | null, transferId: string) => void;
    onChosenDataCategories: (chosenCategories: Array<DataCategoryDataTypes>, transferId: string) => void;
    onLegalEntitiesChange: (entities: Array<string>, transferId: string) => void;
    onRemoveTransfer: (transferId: string) => void;
    onAddNewTransferClick: () => void;
    disableDisclosures: boolean;
    onTransferBasisChange: (transferBasis: string | null, transferId: string) => void;
    onDisclosureUpdate: (dataCategory: DisclosureDataCategory, transferId: string) => void;
    hasContractsEnabled?: boolean;
    onSharingNameChange: (name: string, sharingId: string) => void;
    onSharingDescriptionChange: (description: string, sharingId: string) => void;
    onDisableDisclosuresClick: (disabled: boolean) => void;
    hasData: boolean;
    isReadOnly: boolean;
}

function DisclosureStep(props: IDisclosureSubStep) {
    const { getUserUrl } = useUrlProvider();
    const { translateString, translateDocumentType } = useTranslation();

    const styles = useDisclosureSubStepStyles();

    const { addonsFeatures } = usePlanContext();
    const { gdpo, permissions } = useUserContext();
    const disclosurePermissions = permissions.processingActivityDisclosuresPermissions;

    const {
        dataCategoriesData,
        legalEntityLoading,
        legalEntityData,
        responsibleLoading,
        transferBasisData,
        transferBasisLoading,
        groupEntityLoading,
        groupEntityData,
        legalBasesLoading,
        legalBasesData,
        documentsLoading,
        documentsData,
        getResponsibles,
        dataProcessorAgreementSaveModel,
        setDataProcessorAgreementSaveModel,
        transferId,
        setTransferId,
        refetchDocuments,
        selectedDocument,
        setSelectedDocument,
        showEditDocumentDialog,
        setShowEditDocumentDialog,
        getExpandedId,
        setExpandedId,
        onAddAgreement,
        onDeleteAgreement,
        onHasAgreementChange,
        onSaveNationalLaw,
    } = useDisclosureSubStep(props);

    const handleAddNewTransferClick = (transferId: string) => {
        setExpandedId(transferId);
        props.onAddNewTransferClick();
    };

    const handleCollapseClick = (transferId: string) => {
        if (transferId) {
            if (getExpandedId() === transferId) setExpandedId("");
            else setExpandedId(transferId);
        }
    };

    const handleRemoveTransfer = (transferId: string) => {
        if (getExpandedId() === transferId) setExpandedId("");

        props.onRemoveTransfer(transferId);
    };

    const handleChosenDataCategories = (datacategories: Array<DataCategoryDataTypes> | undefined, transferId: string) => {
        if (dataCategoriesData) {
            props.onChosenDataCategories(datacategories!, transferId);
        } else {
            props.onChosenDataCategories([], transferId);
        }
    };

    const getGroupEntitiesForTable = (entities: Array<string>) => {
        if (groupEntityData) {
            return groupEntityData.filter((entity) => props.entitesFromAssociation.indexOf(entity.id) >= 0);
        }
        return undefined;
    };

    const getCollapseDetails = (companies: Array<string>, dataprocessor: string | null | undefined) => {
        let sender = "";
        let processor = "";

        if (companies.length > 0 && !legalEntityLoading) {
            let entity = legalEntityData?.find((x) => x.id === companies[0]);
            if (entity) {
                sender = `${entity!.name} ${companies.length > 1 ? " + " + (companies.length - 1).toString() : ""}`;
            }
        }

        if (dataprocessor && !legalEntityLoading) {
            let entity = legalEntityData!.find((x) => x.id === dataprocessor);
            if (entity) {
                processor = entity.name;
            }
        }

        return (
            <React.Fragment>
                <div>{sender}</div>
                <KeyboardBackspaceIcon fontSize="large" sx={styles.collapseArrow} />
                <div>{processor}</div>
            </React.Fragment>
        );
    };

    const handleDirectiveChange = (legalBasisId: string, dataCategoryId: string, showBalanceOfInterest: boolean, transferId: string) => {
        const dc = props.transfers.find((x) => x.id! === transferId)!.dataCategories.find((dc) => dc.dataCategoryId === dataCategoryId)!;
        dc.directiveId = legalBasisId;
        if (!showBalanceOfInterest) dc.additionalDetails = "";

        props.onDisclosureUpdate(dc, transferId);
    };

    const handleBalanceOfInterestChange = (value: string, dataCategoryId: string, transfer: Disclosure) => {
        const dc = transfer.dataCategories.find((dc) => dc.dataCategoryId === dataCategoryId)!;
        dc.additionalDetails = value;
        props.onDisclosureUpdate(dc, transfer.id!);
    };

    const handleNationalLawChange = (legalBasisId: string, dataCategoryId: string, showBalanceOfInterest: boolean, transferId: string) => {
        const dc = props.transfers.find((x) => x.id! === transferId)!.dataCategories.find((dc) => dc.dataCategoryId === dataCategoryId)!;
        dc.nationalLawId = legalBasisId;
        if (!showBalanceOfInterest) dc.additionalDetails = "";

        props.onDisclosureUpdate(dc, transferId);
    };

    const handleDoubleLegalBasisChange = (legalBasisId: string, dataCategoryId: string, showBalanceOfInterest: boolean, transferId: string) => {
        const dc = props.transfers.find((x) => x.id! === transferId)!.dataCategories.find((dc) => dc.dataCategoryId === dataCategoryId)!;
        dc.doubleLegalBasisId = legalBasisId;
        if (!showBalanceOfInterest) dc.additionalDetails = "";

        props.onDisclosureUpdate(dc, transferId);
    };

    return (
        <SegmentStep size="large">
            <DotLegalStepHeader toolTipText={translateString("disclosuresOnProcessActivityHelperText")} record={false} topMargin>
                {translateString("disclosuresOnProcessActivity")}
            </DotLegalStepHeader>

            {props.transfers.map((transfer, index) => {
                var selectedEntity = legalEntityData?.find((x) => x.id === transfer.legalUnitId);
                let isThirdCountry = isLegalEntityAInsecureThirdCountry(selectedEntity);
                let readOnly = transfer.readOnly || props.isReadOnly;
                const GetByDataPrivacyClassification = (classification: DataPrivacyClassification) => {
                    return dataCategoriesData
                        .filter(
                            (x) =>
                                transfer.dataCategories
                                    .map((x) => {
                                        return x.dataCategoryId;
                                    })
                                    .indexOf(x.id) !== -1
                        )
                        .filter((d) => d.classification === classification);
                };

                const ContainsClassification = (classification: DataPrivacyClassification) => {
                    return GetByDataPrivacyClassification(classification).length > 0;
                };

                const hasDocuments = transfer.transferAgreements.length !== 0;

                return (
                    <DotLegalCollapse
                        collapseName={transfer.name}
                        disabled={transfer.readOnly || props.isReadOnly}
                        id={transfer.id}
                        key={transfer.id ?? index}
                        isLoading={legalEntityLoading || groupEntityLoading}
                        sx={[styles.collapse, styles.systemImage]}
                        isExpanded={getExpandedId() === transfer.id}
                        centerContent={getCollapseDetails(transfer.legalEntities, transfer.legalUnitId)}
                        removeItemText={translateString("removeDisclosure")}
                        error={props.validationErrors.some((e) => e.field.startsWith(index.toString()))}
                        onExpandClick={() => handleCollapseClick(transfer.id!)}
                        onRemoveClick={() => handleRemoveTransfer(transfer.id!)}
                    >
                        <SegmentStep size="small">
                            <SharingsInfoBox
                                name={transfer.name}
                                description={transfer.description}
                                onDescriptionChange={(e) => props.onSharingDescriptionChange(e, transfer.id!)}
                                onNameChange={(e) => props.onSharingNameChange(e, transfer.id!)}
                                disabled={readOnly}
                            />

                            <DotLegalStepHeader toolTipText={translateString("disclosureRecipientHelperText")} record topMargin>
                                {translateString("disclosureRecipient")}
                            </DotLegalStepHeader>

                            <div style={{ position: "relative" }}>
                                <SharingsLegalEntity
                                    disabled={readOnly}
                                    options={legalEntityData}
                                    isLoading={legalEntityLoading}
                                    selectedItem={transfer.legalUnitId}
                                    placeholder={translateString("legalUnit")}
                                    label={translateString("legalUnit")}
                                    errorText={props.validationErrors.find((v) => v.field === `${index}legalEntityId`)?.error}
                                    onLegalEntityChange={(legalUnitId) =>
                                        props.onLegalUnitChange(
                                            legalUnitId,
                                            transfer.id!,
                                            legalEntityData?.find((x) => x.id === legalUnitId)
                                        )
                                    }
                                    showAddNewEntityButton={!readOnly}
                                    isThirdCountry={isThirdCountry}
                                />
                            </div>

                            {isThirdCountry && disclosurePermissions.hasTransferBasisPermission && (
                                <DotLegalSelect
                                    disabled={readOnly}
                                    selectedItem={transfer.transferBasis}
                                    isLoading={transferBasisLoading}
                                    label={translateString("transferBasis")}
                                    placeholder={translateString("transferBasisRequiredController")}
                                    options={filterTransferBasis(transferBasisData, selectedEntity?.countryCode)}
                                    toolTipText={translateString("transferBasisSpecifyController")}
                                    errorText={props.validationErrors.find((v) => v.field === `${index}transferBasisId`)?.error}
                                    onChange={(transferBasis) => props.onTransferBasisChange(transferBasis, transfer.id!)}
                                    noOptionsLabel={translateString("noOptions")}
                                />
                            )}

                            {disclosurePermissions.hasAgreementPermission && (
                                <SharingsAgreementBox
                                    options={getSelectableDocumentItems(
                                        documentsData,
                                        translateString,
                                        translateDocumentType,
                                        transfer.hasDataProcessingAgreement,
                                        transfer.transferAgreements,
                                        transfer.containsNotAccessibleDocuments
                                    )}
                                    showAddAgreementBox={!isNullOrWhitespace(transfer.legalUnitId) ? true : false}
                                    getSelectedDocuments={getSelectedDocuments(
                                        transfer.transferAgreements,
                                        transfer.hasDataProcessingAgreement,
                                        transfer.containsNotAccessibleDocuments
                                    )}
                                    saveAgreement={(agreementId) => {
                                        props.onCreateNewDocument(agreementId, transferId);
                                        refetchDocuments();
                                    }}
                                    id={transferId}
                                    disabled={readOnly}
                                    onYesOrNoChange={(hasAgreement) => onHasAgreementChange(hasAgreement, transfer.id!)}
                                    dataProcessorAgreementSaveModel={dataProcessorAgreementSaveModel}
                                    onAddNewAgreementClick={() => {
                                        setTransferId(transfer!.id!);
                                        setDataProcessorAgreementSaveModel({
                                            legalEntityId: transfer!.legalUnitId!,
                                            name: "",
                                            type: addonsFeatures.research ? undefined : DotLegalDocumentType.DisclosureAgreement,
                                            base64Data: "",
                                            fileName: "",
                                            link: "",
                                            isLink: false,
                                            owner: props.processingActivityOwner,
                                            status: permissions.canSetDocumentStatus ? undefined : DocumentStatus.draft,
                                            groupEntityAccessIds: [],
                                            allGroupEntities: gdpo || selectedEntity!.isCustomerOwned,
                                            isGroupEntity: selectedEntity?.isCustomerOwned,
                                        });
                                    }}
                                    onChipClick={
                                        hasDocuments
                                            ? (id) => {
                                                  setSelectedDocument({
                                                      documentId: id,
                                                      legalEntityId: transfer.legalUnitId!,
                                                      name: "",
                                                      isGroupEntity: selectedEntity?.isCustomerOwned,
                                                  });
                                                  setShowEditDocumentDialog(true);
                                              }
                                            : undefined
                                    }
                                    singleSelect={!hasDocuments}
                                    isLoading={documentsLoading}
                                    stepType={StepTypeEnum.disclosure}
                                    onAddedAgreement={(agreementId) => onAddAgreement(agreementId, transfer.id!)}
                                    onDeletedAgreement={(agreementId) => onDeleteAgreement(agreementId, transfer.id!)}
                                />
                            )}

                            {disclosurePermissions.hasResponsiblePermission && (
                                <DotLegalSelect
                                    disabled={readOnly}
                                    selectedItem={transfer.responsible}
                                    isLoading={responsibleLoading}
                                    label={translateString("responsible")}
                                    placeholder={translateString("responsible")}
                                    onChange={(responsibleId) => props.onResponsibleChange(responsibleId, transfer.id!)}
                                    options={getResponsibles(transfer.responsible)}
                                    toolTipText={translateString("transferResponsibleHelperText")}
                                    errorText={props.validationErrors.find((x) => x.field === `${index}inactiveUser`)?.error}
                                    link={transfer.responsible && permissions.canManageUsers ? getUserUrl(transfer.responsible!) : undefined}
                                    noOptionsLabel={translateString("noOptions")}
                                />
                            )}
                        </SegmentStep>

                        {disclosurePermissions.hasDataCategoriesPermission && (
                            <React.Fragment>
                                <DotLegalStepHeader
                                    toolTipText={translateString("dataTypeSentHelperText")}
                                    record
                                    subHeader={translateString("noDataCategoriesSelectedProcess")}
                                    topMargin
                                    bottomMargin
                                >
                                    {translateString("dataTypeSent")}
                                </DotLegalStepHeader>

                                {dataCategoriesData && (
                                    <DotLegalPersonalDataSelector
                                        disabled={readOnly}
                                        activityDataCategories={mapToDataCategorySelectorModel(
                                            dataCategoriesData!,
                                            props.processingActivity.dataCategories,
                                            transfer.dataCategories
                                        )}
                                        errorText={props.validationErrors.find((v) => v.field === `${index}dataCategories`)?.error}
                                        onChange={(data) => handleChosenDataCategories(data, transfer.id!)}
                                    />
                                )}
                            </React.Fragment>
                        )}

                        {disclosurePermissions.hasBasisOfDisclosurePermission && transfer.dataCategories.length > 0 && (
                            <div style={{ width: "100%" }}>
                                <DotLegalStepHeader toolTipText={translateString("basisOfDisclosureHelperText")} record={false} topMargin>
                                    {translateString("basisOfDisclosure")}
                                </DotLegalStepHeader>

                                {ContainsClassification(DataPrivacyClassification.Ordinary) && (
                                    <LegalBasisSelectorContainer
                                        headerText={translateString("dataCategoriOrdinary")}
                                        toolTipText={translateString("legalBasisNonSensitiveHelperText")}
                                    >
                                        {GetByDataPrivacyClassification(DataPrivacyClassification.Ordinary).map((d, i) => {
                                            return getSelector(d, false, readOnly, transfer, index);
                                        })}
                                    </LegalBasisSelectorContainer>
                                )}
                                {ContainsClassification(DataPrivacyClassification.NationalIdentificationNumber) && (
                                    <LegalBasisSelectorContainer headerText={translateString("nationalidentificationnumber")} toolTipText={""}>
                                        {GetByDataPrivacyClassification(DataPrivacyClassification.NationalIdentificationNumber).map((d, i) => {
                                            return getSelector(d, false, readOnly, transfer, index);
                                        })}
                                    </LegalBasisSelectorContainer>
                                )}
                                {ContainsClassification(DataPrivacyClassification.CriminalOffenses) && (
                                    <LegalBasisSelectorContainer headerText={translateString("criminaloffenses")} toolTipText={""}>
                                        {GetByDataPrivacyClassification(DataPrivacyClassification.CriminalOffenses).map((d, i) => {
                                            return getSelector(d, false, readOnly, transfer, index);
                                        })}
                                    </LegalBasisSelectorContainer>
                                )}
                                {ContainsClassification(DataPrivacyClassification.Sensitive) && (
                                    <LegalBasisSelectorContainer
                                        headerText={translateString("dataCategoriSensitive")}
                                        toolTipText={translateString("legalBasisSensitiveHelperText")}
                                    >
                                        {GetByDataPrivacyClassification(DataPrivacyClassification.Sensitive).map((d, i) => {
                                            return getSelector(d, true, readOnly, transfer, index);
                                        })}
                                    </LegalBasisSelectorContainer>
                                )}
                                {ContainsClassification(DataPrivacyClassification.None) && (
                                    <LegalBasisSelectorContainer headerText={translateString("dataCategoriNone")} toolTipText={""}>
                                        {GetByDataPrivacyClassification(DataPrivacyClassification.None).map((d, i) => {
                                            return getSelector(d, false, readOnly, transfer, index);
                                        })}
                                    </LegalBasisSelectorContainer>
                                )}
                            </div>
                        )}

                        <SegmentStep size="large">
                            <DotLegalStepHeader record={false} subHeader={translateString("entityChosenOnAssociation")} topMargin bottomMargin>
                                {translateString("organisationsUsingDisclosure")}
                            </DotLegalStepHeader>
                            <LegalEntityTable
                                errorText={props.validationErrors.find((v) => v.field === `${index}companies`)?.error}
                                isLoading={groupEntityLoading}
                                legalEntities={getGroupEntitiesForTable(transfer.legalEntities)}
                                selectedEntities={transfer.legalEntities}
                                showAddNewEntityButton={false}
                                showAddAllEntityButton
                                onLegalEntitiesChange={(entities) => props.onLegalEntitiesChange(entities, transfer.id!)}
                                disabled={readOnly}
                            />
                        </SegmentStep>
                    </DotLegalCollapse>
                );
            })}

            <Box sx={styles.addNewTransfer}>
                <DotLegalButton
                    disabled={props.disableDisclosures || props.isReadOnly}
                    buttonType="secondary"
                    onClick={() => handleAddNewTransferClick(Guid.newGuid())}
                    isLoading={props.isAddingTransfer}
                >
                    {props.transfers.length > 0 ? translateString("addAnotherDisclosure") : translateString("addDisclosure")}
                </DotLegalButton>
            </Box>

            {!props.hasData && (
                <Box sx={styles.disableDisclosures}>
                    <Box id={"disable-disclosures"}>
                        <DotLegalCheckbox
                            disabled={props.isReadOnly}
                            checked={props.disableDisclosures}
                            onChange={props.onDisableDisclosuresClick}
                            label={translateString("disableDisclosures")}
                        />
                        {props.validationErrors.some((e) => e.field === "noDataSharingsDisclosures") && (
                            <Box sx={styles.error}>{props.validationErrors.find((e) => e.field === "noDataSharingsDisclosures")?.error}</Box>
                        )}
                    </Box>
                </Box>
            )}

            {showEditDocumentDialog && (
                <EditLegalEntityDocumentDialog
                    onCloseDialog={() => {
                        setShowEditDocumentDialog(false);
                    }}
                    selectedDocument={selectedDocument!}
                    isExtended={false}
                    onSave={() => refetchDocuments()}
                    stepType={StepTypeEnum.disclosure}
                />
            )}
        </SegmentStep>
    );

    function getSelector(d: SelectableDataCategoryItem, isSensitive: boolean, readOnly: boolean, transfer: Disclosure, index: number) {
        let dataCategory = transfer.dataCategories
            .map((t) => {
                return {
                    dataCategoryId: t.dataCategoryId,
                    directiveId: t.directiveId,
                    doubleLegalBasisId: t.doubleLegalBasisId,
                    nationalLawId: t.nationalLawId,
                    balanceOfInterest: t.additionalDetails,
                } as LegalBasisBaseClass;
            })
            .find((x) => x.dataCategoryId === d.id);
        return (
            <React.Fragment key={d.id}>
                <LegalBasisSelector
                    hasCreateDataPermission={props.hasDataCreationPermission}
                    dataCategory={dataCategory}
                    OnDirectiveChange={(legalBasisId: string, dataCategoryId: string, showBalanceOfInterest: boolean) =>
                        handleDirectiveChange(legalBasisId, dataCategoryId, showBalanceOfInterest, transfer.id!)
                    }
                    OnNationalLawChange={(legalBasisId: string, dataCategoryId: string, showBalanceOfInterest: boolean) =>
                        handleNationalLawChange(legalBasisId, dataCategoryId, showBalanceOfInterest, transfer.id!)
                    }
                    OnDoubleLegalBasisChange={(legalBasisId: string, dataCategoryId: string, showBalanceOfInterest: boolean) =>
                        handleDoubleLegalBasisChange(legalBasisId, dataCategoryId, showBalanceOfInterest, transfer.id!)
                    }
                    OnBalanceOfInterests={(value: string, dataCategoryId: string) => handleBalanceOfInterestChange(value, dataCategoryId, transfer)}
                    toolTip={d.description}
                    legalBases={legalBasesData}
                    dataCategoryName={d.name}
                    isSensitive={isSensitive}
                    isLoading={legalBasesLoading}
                    disabled={readOnly}
                    directiveError={props.validationErrors.find((v) => v.field === `${index},${d.id},directiveId`)?.error}
                    doubleLegalBasisError={props.validationErrors.find((v) => v.field === `${index},${d.id},doubleLegalBasisId`)?.error}
                    nationalLawError={props.validationErrors.find((v) => v.field === `${index},${d.id},nationalLawId`)?.error}
                    hasLegalBasisPermissions={disclosurePermissions.hasBasisOfDisclosurePermission}
                    onSaveNationalLaw={(response, dataCategoryId) => onSaveNationalLaw(transfer.id!, response, dataCategoryId)}
                />
                <Divider sx={styles.divider} />
            </React.Fragment>
        );
    }
}

export default DisclosureStep;
