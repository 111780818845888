import { DotLegalDialog, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { AreaSaveModel } from "./AreaDialog.types";
import { useAreaDialogHooks } from "./AreaDialog.hooks";

export interface IAreaDialog {
    onDialogClose: () => void;
    selectedArea?: AreaSaveModel;
    onAreaSaved: (response: any) => void;
    areaId?: string;
}

export default function AreaDialog(props: IAreaDialog) {
    const { translateString } = useTranslation();
    const { inProgress, onSaveClick, isEdit, area, onDescriptionChange, onNameChange, validation } = useAreaDialogHooks(props);

    validation.check(area);
    return (
        <DotLegalDialog
            buttonOkText={isEdit ? translateString("save") : translateString("create")}
            header={isEdit ? translateString("editArea") : translateString("createArea")}
            size="sm"
            open
            inProgress={inProgress}
            onDialogClose={() => props.onDialogClose()}
            onOkClick={() => onSaveClick()}
            disableBackdropOnCloseIfValueChange={area}
        >
            <DotLegalTextField
                label={translateString("name")}
                onChange={(name) => onNameChange(name)}
                value={area.name}
                debounce={false}
                errorText={validation.getErrorsForDisplay("name")}
            ></DotLegalTextField>
            <DotLegalTextField
                label={translateString("description")}
                onChange={(description) => onDescriptionChange(description)}
                value={area.description}
                debounce={false}
            ></DotLegalTextField>
        </DotLegalDialog>
    );
}
