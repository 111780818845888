import { useState } from "react";
import { useExpandedId } from "../../common/hooks/useExpandedId";
import { AnnualWheelMonthEnum } from "../AnnualWheel.types";

export function useAnnaulWheelCalendarOverviewHooks() {
    const { getExpandedId, setExpandedId } = useExpandedId();
    const [hoveredMonth, setHoveredMonth] = useState<AnnualWheelMonthEnum | undefined>(undefined);

    return { getExpandedId, setExpandedId, hoveredMonth, setHoveredMonth };
}
