import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { get } from "../../../common/api/apiShared";
import { useStateLocalStorage } from "@dotlegal/dotlegal-ui-components";
import { NationalLawViewModel } from "./NationalLaws.types";

export function useNationalLawsDataMapping() {
    const queryClient = useQueryClient();
    const [showAllNationalLaws, setShowAllNationalLaws] = useStateLocalStorage("onlyActiveNationalLaws", false);
    const [showNationalLawDialog, setShowNationalLawDialog] = useState(false);
    const [showDeleteNationalLawDialog, setShowDeleteNationalLawDialog] = useState(false);
    const [showChangeNationalLawStatusDialog, setShowChangeNationalLawStatusDialog] = useState(false);
    const [selectedNationalLaw, setSelectedNationalLaw] = useState<NationalLawViewModel | undefined>(undefined);

    const queryPart = `?showAllNationalLaws=${showAllNationalLaws}`;
    const url = `/nationalLaws${queryPart}`;
    let { isLoading, data } = useQuery(url, () => get<Array<NationalLawViewModel>>(url));

    const OnSave = () => {
        queryClient.refetchQueries(url);
    };

    return {
        isLoading,
        data,
        OnSave,
        showAllNationalLaws,
        setShowAllNationalLaws,
        showNationalLawDialog,
        setShowNationalLawDialog,
        showDeleteNationalLawDialog,
        setShowDeleteNationalLawDialog,
        showChangeNationalLawStatusDialog,
        setShowChangeNationalLawStatusDialog,
        selectedNationalLaw,
        setSelectedNationalLaw,
    };
}
