import React from "react";
import { DotLegalDialog, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { DataSubjectSaveModel } from "../DataSubject.types";
import { useAddEditDataSubjectDataMapping } from "./AddEditDataSubjectDialog.hooks";

export interface IAddEditDataSubjectDialogProps {
    onHideDialog: () => void;
    onSave: (response: any, name: string) => void;
    selectedDataSubject: DataSubjectSaveModel | undefined;
    isAdd: boolean;
}

function AddEditDataSubjectDialog(props: IAddEditDataSubjectDialogProps) {
    const { translateString } = useTranslation();
    const { inProgress, viewModel, setViewModel, validateDataSubjectForm, validateDataSubjectName, errors } = useAddEditDataSubjectDataMapping(props);

    return (
        <DotLegalDialog
            inProgress={inProgress}
            size="sm"
            buttonOkText={props.isAdd ? translateString("create") : translateString("save")}
            header={props.isAdd ? translateString("createDataSubject") : translateString("editDataSubject")}
            onOkClick={() => validateDataSubjectForm(viewModel?.name!)}
            onDialogClose={props.onHideDialog}
            open
            disableBackdropOnCloseIfValueChange={viewModel}
        >
            <DotLegalTextField
                debounce={false}
                size="small"
                label={translateString("name")}
                disabled={!props.isAdd && !viewModel?.isCustom}
                value={viewModel?.name!}
                onChange={(name) => {
                    const newDataSubjectViewModel = { ...viewModel! };
                    newDataSubjectViewModel.name = name;
                    validateDataSubjectName(name);
                    setViewModel(newDataSubjectViewModel);
                }}
                errorText={errors.dataSubjectNameError}
            />

            <DotLegalTextField
                label={translateString("description")}
                onChange={(description) => {
                    const newDataSubjectViewModel = { ...viewModel! };
                    newDataSubjectViewModel.description = description;
                    setViewModel(newDataSubjectViewModel);
                }}
                multiline
                rows={4}
                debounce={false}
                value={viewModel?.description!}
            />
        </DotLegalDialog>
    );
}

export default AddEditDataSubjectDialog;
