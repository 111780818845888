import { useMutation } from "react-query";
import { post } from "../../../common/api/apiShared";
import { ISendAuditsDialog } from "./SendAuditsDialog";
import { createElement, useState } from "react";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { Trans } from "react-i18next";
import { LegalEntityAuditGroupSendModel } from "./SendAuditsDialog.types";
import { useUrlProvider } from "../../../useUrlProvider";
import { useNavigate } from "react-router-dom";

export default function useSendAuditsDialogHooks(props: ISendAuditsDialog) {
    const snackbar = useDotLegalSnackbar();
    const createMutation = useMutation(sendApi);
    const [uploadState, setUploadState] = useState(0);
    const [isSending, setIsSending] = useState(false);
    const [uploadCounter, setUploadCounter] = useState(1);
    const { getAuditGroupUrl } = useUrlProvider();
    const navigate = useNavigate();

    async function send() {
        setIsSending(true);
        const numberOfEntities = props.selectedEntities.length - 1;
        let counter = 1;
        for (const entity of props.selectedEntities) {
            await createMutation.mutateAsync(
                { auditGroupId: props.auditGroupId!, audit: entity },
                {
                    onSuccess: (response: any) => {
                        if (counter === numberOfEntities) {
                            setUploadState(100);
                        } else {
                            setUploadState((counter / numberOfEntities) * 100);
                        }
                        setUploadCounter(++counter);
                    },
                }
            );
        }

        setIsSending(false);
        snackbar.show(createElement(Trans, { i18nKey: "auditGroupSent", values: { name: props.auditGroupName } }));
        navigate(getAuditGroupUrl(props.auditGroupId!));
    }

    return { send, uploadState, isSending, uploadCounter };

    function sendApi(data: { auditGroupId: string; audit: LegalEntityAuditGroupSendModel }) {
        return post<{}>(`/legalEntityAuditGroups/${data.auditGroupId}/sendAudit`, data.audit);
    }
}
