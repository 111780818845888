import React from "react";
import { useCreateEditDeclarationDialog } from "./CreateEditDeclarationDialog.hooks";
import {
    DotLegalDatePicker,
    DotLegalDialog,
    DotLegalHeader,
    DotLegalMultiSelect,
    DotLegalSelect,
    DotLegalTextField,
    useIsOnSmallScreen,
} from "@dotlegal/dotlegal-ui-components";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "../../localization/useTranslation";
import { arrow, leftWhiteArrowButton } from "../../common/icons";
import { AuditFrequency, DeclarationApprovalOption } from "../declarations/Declarations.types";
import LegalEntitySelector from "../../legalEntity/legalEntitySelector/LegalEntitySelector";
import { DeclarationSaveModel } from "./CreateEditDeclarationDialog.types";

export interface CreateEditDeclarationDialogProps {
    selectedDeclaration?: DeclarationSaveModel;
    onDialogClose: () => void;
    onUpdatedDeclaration?: () => void;
}

function CreateEditDeclarationDialog(props: CreateEditDeclarationDialogProps) {
    const isEdit = props.selectedDeclaration !== undefined;
    const isUseOnSmallScreen = useIsOnSmallScreen();
    const { translateString, getDateTimePickerLanguage } = useTranslation();
    const {
        groupEntitiesLoading,
        groupEntities,
        complianceAreas,
        complianceAreasLoading,
        declarationUpdateHelper,
        validation,
        step,
        saveModel,
        isSaving,
        onNextOrSendClick,
        onPreviousButtonClick,
    } = useCreateEditDeclarationDialog(props);

    validation.check(saveModel);

    function getDialogContent() {
        if (step === "first") {
            return firstStep;
        } else {
            return groupCompanyStep;
        }
    }

    const getMinDate = () => {
        return new Date(new Date().getFullYear(), 0, 1);
    };
    const getMaxDate = () => {
        return new Date(new Date().getFullYear() + 1, 11, 31);
    };

    const groupCompanyStep = (
        <>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <DotLegalHeader headerStyle={"extraSmall"} center marginBottom={4}>
                    {translateString("groupCompaniesDeclarationAppliesTo")}
                </DotLegalHeader>
            </Box>
            <LegalEntitySelector
                selectedEntities={saveModel.groupEntityIds}
                setSelectedEntities={declarationUpdateHelper.onGroupEntityIdsChange}
                legalEntityItems={groupEntities}
                isLoading={groupEntitiesLoading}
                allColumns={false}
            />
            {validation.getErrorsForDisplay("groupEntityIds") && (
                <Box>
                    <Typography sx={(theme) => ({ fontSize: theme.typography.pxToRem(12), color: theme.palette.error.main })}>
                        {validation.getErrorsForDisplay("groupEntityIds")}
                    </Typography>
                </Box>
            )}
        </>
    );

    const firstStep = (
        <>
            <DotLegalTextField
                errorText={validation.getErrorsForDisplay("name")}
                label={translateString("nameOfDeclaration")}
                value={saveModel.name}
                debounce={false}
                onChange={declarationUpdateHelper.onNameChange}
            />

            <DotLegalTextField
                label={translateString("description")}
                onChange={declarationUpdateHelper.onDescriptionChange}
                multiline
                rows={3}
                value={saveModel.description}
                debounce={false}
            />

            <DotLegalMultiSelect
                options={complianceAreas}
                chosenOptions={saveModel.complianceAreaIds}
                onChange={declarationUpdateHelper.onComplianceAreasChange}
                label={translateString("complianceAreas")}
                noOptionsLabel={translateString("noOptions")}
                isLoading={complianceAreasLoading}
            />

            {!isEdit && (
                <>
                    <DotLegalSelect
                        options={[
                            { id: AuditFrequency.Quarterly.toString(), name: translateString("quarterly") },
                            { id: AuditFrequency.SemiAnnually.toString(), name: translateString("semiAnnually") },
                            { id: AuditFrequency.Annual.toString(), name: translateString("annual") },
                            { id: AuditFrequency.EveryTwoYears.toString(), name: translateString("everyTwoYears") },
                            { id: AuditFrequency.EveryThreeYears.toString(), name: translateString("everyThreeYears") },
                        ]}
                        onChange={declarationUpdateHelper.onAuditFrequncyChange}
                        label={translateString("auditFrequency")}
                        noOptionsLabel={translateString("noOptions")}
                        errorText={validation.getErrorsForDisplay("auditFrequency")}
                        selectedItem={saveModel.auditFrequency?.toString()}
                    />

                    <DotLegalDatePicker
                        label={translateString("nextAuditDate")}
                        onChange={declarationUpdateHelper.onFirstAuditMonthChange}
                        value={saveModel.auditDate}
                        view={"month"}
                        minDate={getMinDate()}
                        maxDate={getMaxDate()}
                        language={getDateTimePickerLanguage()}
                        error={validation.getErrorsForDisplay("auditDate") !== undefined}
                        helperMessage={validation.getErrorsForDisplay("auditDate")}
                    />
                </>
            )}

            <DotLegalSelect
                options={[
                    { id: DeclarationApprovalOption.InternalApprovalSufficient.toString(), name: translateString("internalApprovalSufficient") },
                    { id: DeclarationApprovalOption.ExternalApprovalRequired.toString(), name: translateString("externalApprovalRequired") },
                ]}
                selectedItem={saveModel.approval?.toString()}
                onChange={declarationUpdateHelper.onApprovalChange}
                label={translateString("approval")}
                noOptionsLabel={translateString("noOptions")}
                errorText={validation.getErrorsForDisplay("approval")}
            />
        </>
    );

    return (
        <DotLegalDialog
            buttonOkText={
                step === "selectGroupEntity" || (groupEntities && groupEntities.length <= 1)
                    ? isEdit
                        ? translateString("save")
                        : translateString("create")
                    : translateString("next")
            }
            size="md"
            header={isEdit ? translateString("editDeclaration") : translateString("createDeclaration")}
            open
            buttonOkIcon={arrow}
            previousButtonIcon={leftWhiteArrowButton}
            onDialogClose={props.onDialogClose}
            onOkClick={onNextOrSendClick}
            okButtonDisabled={groupEntitiesLoading}
            previousButtonText={translateString("previous")}
            previousButtonClick={step !== "first" ? onPreviousButtonClick : undefined}
            buttonRight
            inProgress={isSaving}
        >
            <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                <Box sx={{ width: isUseOnSmallScreen ? "100%" : "60%" }}>{getDialogContent()}</Box>
            </Box>
        </DotLegalDialog>
    );
}

export default CreateEditDeclarationDialog;
