import { useState } from "react";
import { useMutation } from "react-query";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { ICloseInternalAssessmentDialog } from "./CloseInternalAssessmentDialog";
import { useTranslation } from "../../localization/useTranslation";
import { CloseInternalAssessmentSaveModel } from "./CloseInternalAssessmentDialog.types";
import { useValidator } from "../../common/hooks/validation";
import { put } from "../../common/api/apiShared";

export default function useCloseInternalAssessmentDialogHooks(props: ICloseInternalAssessmentDialog) {
    const { translateString } = useTranslation();
    const [saveModel, setSaveModel] = useState<CloseInternalAssessmentSaveModel>({ status: undefined });
    const snackbar = useDotLegalSnackbar();

    const validation = useValidator<Partial<CloseInternalAssessmentSaveModel>>((validators) => {
        return {
            status: validators.validateNotEmpty((item) => item.status, translateString("reason")),
        };
    }, []);

    const onStatusChange = (status: number) => {
        const temp = { ...saveModel };
        temp.status = status;
        setSaveModel(temp);
    };

    const closeInternalAssessmentMutation = useMutation(closeInternalAssessmentAPI, {
        onSuccess: () => {
            snackbar.show(translateString("internalAssessmentClosed"));
            props.onInternalAssessmentClosed();
        },
    });

    const onCloseClick = () => {
        if (validation.anyHasErrors) {
            validation.setShowErrors(true);
            return;
        }

        closeInternalAssessmentMutation.mutateAsync();
    };

    return { saveModel, onStatusChange, validation, onCloseClick, isSaving: closeInternalAssessmentMutation.isLoading };

    function closeInternalAssessmentAPI() {
        return put(`/internalAssessments/${props.selectedInternalAssessment.id}/close`, saveModel.status);
    }
}
