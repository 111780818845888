import { useMutation } from "react-query";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { IReopenClassificationDialog } from "./ReopenClassificationDialog";
import { useTranslation } from "../../../localization/useTranslation";
import { put } from "../../../common/api/apiShared";

export default function useReopenClassificationHooks(props: IReopenClassificationDialog) {
    const { translateString } = useTranslation();
    const snackbar = useDotLegalSnackbar();

    const reopenMutation = useMutation(reopenClassificationAPI, {
        onSuccess: () => {
            snackbar.show(translateString("classificationReopened"));
            props.onInternalAssessmentReopened();
        },
    });

    const onReopenClick = () => {
        reopenMutation.mutateAsync();
    };

    return { onReopenClick, isSaving: reopenMutation.isLoading };

    function reopenClassificationAPI() {
        return put(`/legalEntityClassification/${props.classificationId}/reopen`, null);
    }
}
