import { useState } from "react";
import { IPoliciesStep } from "./PoliciesStep";
import { usePoliciesQuery } from "../../common/hooks/useSelectableItemQueries";

export function usePoliciesStepDataMapping(props: IPoliciesStep) {
    const [openDialog, setOpenDialog] = useState(false);
    let { isLoading, data } = usePoliciesQuery();

    const onAddPoliciesClick = (policies: Array<string>) => {
        props.onChangePolicies(props.selectedPolicies?.concat(policies));
    };

    const onRemovePolicy = (id: string) => {
        const policies = props.selectedPolicies?.filter(function (item) {
            return item !== id;
        });

        props.onChangePolicies(policies);
    };

    return { openDialog, setOpenDialog, isLoading, policies: data, onAddPoliciesClick, onRemovePolicy };
}
