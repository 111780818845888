import { ILegalEntityRiskQuestionnaireProps } from "./LegalEntityRiskQuestionnaire";
import { LegalEntityRiskAssessmentQuestionId } from "./LegalEntityRiskQuestionnaire.types";

export function useLegalEntityRiskQuestionnaireHook(props: ILegalEntityRiskQuestionnaireProps) {
    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>, questionId: LegalEntityRiskAssessmentQuestionId) => {
        let clickedValue = Number((event.target as HTMLInputElement).value);
        if (questionId === LegalEntityRiskAssessmentQuestionId.Question1 && props.questions[0].answerId === clickedValue) {
            props.onAnswerClick(questionId, undefined);
        } else if (questionId === LegalEntityRiskAssessmentQuestionId.Question2 && props.questions[1].answerId === clickedValue) {
            props.onAnswerClick(questionId, undefined);
        } else if (questionId === LegalEntityRiskAssessmentQuestionId.Question3 && props.questions[2].answerId === clickedValue) {
            props.onAnswerClick(questionId, undefined);
        } else if (questionId === LegalEntityRiskAssessmentQuestionId.Question4 && props.questions[3].answerId === clickedValue) {
            props.onAnswerClick(questionId, undefined);
        } else {
            props.onAnswerClick(questionId, clickedValue);
        }
    };

    return {
        handleRadioChange,
    };
}
