import { useState } from "react";

import { IChangeAssigneeDialog } from "./ChangeAssigneeDialog";
import { useTranslation } from "../../localization/useTranslation";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useMutation, useQuery } from "react-query";
import { get, put } from "../../common/api/apiShared";
import { LegalEntityContactRow, LegalEntityContactViewModel } from "../../legalEntity/legalEntityContactTab/LegalEntityContactTab.types";

export function useChangeAssigneeDialogHooks(props: IChangeAssigneeDialog) {
    const { translateString } = useTranslation();
    const snackbar = useDotLegalSnackbar();
    const [assignee, setAssignee] = useState(props.internalAssessmentViewModel.assignee.id);
    const [assigneeSearch, setAssigneeSearch] = useState<string>("");

    const url = "/LegalEntityContacts/availableContacts";
    const { data, isLoading } = useQuery(url, () => get<Array<LegalEntityContactRow>>(url));

    const changeAssigneeMutation = useMutation(changeAssigneeApi, {
        onSuccess: () => {
            snackbar.show(translateString("contactChanged"));
            props.onChangedAssignee();
        },
    });

    const onChangeAssigneeClick = () => {
        changeAssigneeMutation.mutateAsync();
    };

    function changeAssigneeApi() {
        return put(`/internalAssessments/${props.internalAssessmentViewModel.id}/changeAssignee`, assignee);
    }

    return {
        data,
        isLoading,
        isChanging: changeAssigneeMutation.isLoading,
        assignee,
        setAssignee,
        onChangeAssigneeClick,
        assigneeSearch,
        setAssigneeSearch,
    };
}
