import { lighten, useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";
import { columContainerMinHeight } from "../AnnualWheel.styles";

export const useAnnualWheelCalendarOverviewStyles = () => {
    const theme = useTheme();

    return createSxStyles({
        addIcon: {
            position: "absolute",
            right: 40,
            fill: theme.palette.primary.dark,
            "&:hover": {
                fill: theme.palette.primary.main,
                cursor: "pointer",
            },
        },
        calenderColumnHeader: {
            display: "flex",
            justifyContent: "center",
            position: "relative",
            paddingTop: 2,
            paddingBottom: 2,
            backgroundColor: "#EEF4FA",
        },
        calender: {
            padding: theme.spacing(5),
            marginTop: theme.spacing(2),
        },
        expandContainer: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& .MuiTypography-root": {
                "&:hover": {
                    cursor: "pointer",
                    textDecoration: "underline",
                },
            },
        },
        columnContainer: {
            minHeight: columContainerMinHeight + 50,
            position: "relative",
            "& .MuiCollapse-root": {
                overflow: "clip",
            },
            height: "100%",
        },
        currentMonth: {
            backgroundColor: lighten("#DBE0FD", 0.8),
        },
    });
};
