import { useState } from "react";
import { useValidator } from "../../../common/hooks/validation";
import { LegalEntitySaveModel } from "./AddLegalEntityDialog.types";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { post } from "../../../common/api/apiShared";
import { useMutation } from "react-query";
import { setJwtToken } from "../../../common/api/apiShared";
import { IAddLegalEntityDialog } from "./AddLegalEntityDialog";
import useCountryHook from "../../../common/hooks/useCountryList";
import { useUrlProvider } from "../../../useUrlProvider";
import { useNavigate } from "react-router-dom";
import { LegalEntityPageEnum } from "../../legalEntities/LegalEntities.types";
import { LegalEntityType } from "../../addLegalEntityDialog/AddLegalEntityDialog.types";

export function useAddLegalEntityDialogHook(props: IAddLegalEntityDialog) {
    const [saveModel, setSaveModel] = useState<LegalEntitySaveModel>({
        name: "",
        countryCode: "",
        isCustomerOwned: props.legalEntityPage === LegalEntityPageEnum.GroupEntity,
        type:
            props.legalEntityPage === LegalEntityPageEnum.Customer
                ? LegalEntityType.Customer
                : props.legalEntityPage === LegalEntityPageEnum.Vendor
                  ? LegalEntityType.Vendor
                  : props.legalEntityPage === LegalEntityPageEnum.Other
                    ? LegalEntityType.Other
                    : undefined,
    });
    const snackbar = useDotLegalSnackbar();
    const { translateString } = useTranslation();
    const { getLegalEntityManagementUrl } = useUrlProvider();
    const navigate = useNavigate();
    const { getSelectableCountries } = useCountryHook();

    const validation = useValidator<LegalEntitySaveModel>(
        (validators) => ({
            name: validators.validateNotEmpty((item) => item.name, translateString("name")),
            countryCode: validators.validateNotEmpty((item) => item.countryCode, translateString("country")),
        }),
        [saveModel?.countryCode, saveModel?.name]
    );

    validation.check(saveModel);

    const saveMutation = useMutation(save, {
        onSuccess: (response: any) => {
            var createRespone = response.value();

            if (createRespone.isCreated) {
                props.onDialogClose();

                if (createRespone.refetchJwtToken) {
                    setJwtToken("");
                }
                navigate(getLegalEntityManagementUrl(props.legalEntityPage, createRespone.id));
            } else {
                snackbar.show(translateString("addEntityDataExist"), "warning");
            }
        },
    });

    const createLegalEntity = async () => {
        await saveMutation.mutateAsync();
    };

    const onSaveClick = () => {
        if (validation.anyHasErrors) {
            validation.setShowErrors(true);
            return;
        } else {
            createLegalEntity();
        }
    };

    const onSaveModelChange = {
        onNameChange: (name: string) => {
            let tempModel = { ...saveModel };
            tempModel.name = name;
            setSaveModel(tempModel);
        },
        onCountryChange: (countryCode: string) => {
            let tempModel = { ...saveModel };
            tempModel.countryCode = countryCode;
            setSaveModel(tempModel);
        },
    };

    return {
        saveModel,
        onSaveClick,
        isSaving: saveMutation.isLoading,
        validation,
        onSaveModelChange,
        countryList: getSelectableCountries(),
    };

    function save() {
        return post<{}>("/legalentitymanagement/", saveModel);
    }
}
