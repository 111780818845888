import { createElement, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { get, post, put } from "../../../common/api/apiShared";
import { Result } from "../../../common/api/result";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { NationalLawErrorsModel, NationalLawSaveModel } from "./NationalLawDialog.types";
import { INationalLawDialog } from "./NationalLawDialog";
import { SelectableItem } from "../../../processingActivity/ProcessingActivity.types";
import { LegislationSaveModel } from "../../legislation/legislationDialog/LegislationDialog.types";
import { Trans } from "react-i18next";

export function useNationalLawDialogDataMapping(props: INationalLawDialog) {
    const snackbar = useDotLegalSnackbar();
    const { translateString } = useTranslation();
    const [nationalLawErrors, setNationalLawErrors] = useState<NationalLawErrorsModel>(new NationalLawErrorsModel(translateString("fieldMandatory")));
    const [legislationErrors, setLegislationsErrors] = useState("");
    const [nationalLawViewModel, setNationalLawViewModel] = useState<NationalLawSaveModel | undefined>(props.nationalLaw);
    const [legislationViewmodel, setLegislationViewModel] = useState<LegislationSaveModel | undefined>(undefined);
    const [showDialogType, setShowDialogType] = useState<"NationalLawDialog" | "LegislationDialog">("NationalLawDialog");
    const [searchValue, setSearchValue] = useState("");

    let legislationItemsQuery = useQuery("legislationItems", () => get<Array<SelectableItem>>("/legislations/selectablelegislations"));

    let legislationItemsIsLoading = legislationItemsQuery.isLoading;
    let legislationItemsData = legislationItemsQuery.data;
    let legislationItemsRefetch = legislationItemsQuery.refetch;

    function saveLegislation() {
        return post<string>("/legislations/", legislationViewmodel);
    }

    const saveLegislationMutation = useMutation(saveLegislation, {
        onSuccess: (response: Result<string>) => {
            if (response.success()) {
                onSaveLegislation(response.value());
            } else {
                snackbar.show(createElement(Trans, { i18nKey: "legislationExist" }), "warning");
            }
        },
    });

    const onSaveLegislation = (response: any) => {
        let tempViewModel = { ...nationalLawViewModel! };
        tempViewModel.legislationId = response.id;
        setNationalLawViewModel(tempViewModel);

        legislationItemsRefetch();

        setShowDialogType("NationalLawDialog");
        setSearchValue("");
        setLegislationViewModel(undefined);

        validateNationalLawLegislation(tempViewModel.legislationId);

        snackbar.show(createElement(Trans, { i18nKey: "legislationCreated", values: { name: legislationViewmodel?.name } }));
    };

    function saveNationalLaw() {
        return post<string>("/NationalLaws/", nationalLawViewModel);
    }

    const saveMutation = useMutation(saveNationalLaw, {
        onSuccess: (response: Result<string>) => {
            if (response.success()) {
                props.onDialogClose();
                props.refetch();
                props.onOkClick(response.value(), nationalLawViewModel!.name);
                snackbar.show(createElement(Trans, { i18nKey: "nationalLawCreated", values: { name: nationalLawViewModel?.name } }));
            } else {
                snackbar.show(translateString("nationalLawExist"), "warning");
            }
        },
    });

    const createLegislation = async () => {
        await saveLegislationMutation.mutateAsync();
    };

    function update(id: string) {
        return put<string>(`/nationallaws/${id}/`, nationalLawViewModel);
    }

    const createNationalLaw = async () => {
        await saveMutation.mutateAsync();
    };

    const editMutation = useMutation(update);
    const updateNationalLaw = () => {
        editMutation.mutateAsync(props.nationalLawId!, {
            onSuccess: (response: Result<string>) => {
                if (response.success()) {
                    snackbar.show(translateString("nationalLawUpdated"));
                    props.onDialogClose();
                    props.refetch();
                } else {
                    snackbar.show(translateString("nationalLawExist"), "warning");
                }
            },
        });
    };

    const OnSave = () => {
        if (showDialogType === "NationalLawDialog") validateNationalLawForm();
        else validateLegislationForm();
    };

    const setSearchedValue = () => {
        let tempViewModel = { ...legislationViewmodel! };
        tempViewModel.name = searchValue;
        setLegislationViewModel(tempViewModel);
    };

    return {
        nationalLawViewModel,
        setNationalLawViewModel,
        nationalLawErrors,
        validateNationalLawName,
        validateNationalLawProvision,
        validateNationalLawLegislation,
        isSaving: editMutation.isLoading || saveMutation.isLoading || saveLegislationMutation.isLoading,
        legislationItemsIsLoading,
        legislationItemsData,
        searchValue,
        setSearchValue,
        showDialogType,
        setShowDialogType,
        legislationViewmodel,
        setLegislationViewModel,
        legislationErrors,
        OnSave,
        setSearchedValue,
        validateLegislationName,
    };

    function validateNationalLawName(name: string) {
        nationalLawErrors.validateName(name);
        setNationalLawErrors(nationalLawErrors);
    }

    function validateNationalLawProvision(provision: string) {
        nationalLawErrors.validateProvision(provision);
        setNationalLawErrors(nationalLawErrors);
    }

    function validateNationalLawLegislation(legislation: string) {
        nationalLawErrors.validateLegislation(legislation);
        setNationalLawErrors(nationalLawErrors);
    }

    function validateNationalLawForm() {
        let tempErrors = new NationalLawErrorsModel(translateString("fieldMandatory"));

        tempErrors.validateName(nationalLawViewModel?.name);
        tempErrors.validateProvision(nationalLawViewModel?.provision);
        tempErrors.validateLegislation(nationalLawViewModel?.legislationId);

        if (tempErrors.hasErrors()) {
            setNationalLawErrors(tempErrors);
        } else {
            if (props.isAdd) createNationalLaw();
            else updateNationalLaw();
        }
    }

    function validateLegislationName(name: string | undefined) {
        if (name) setLegislationsErrors("");
        else setLegislationsErrors(translateString("fieldMandatory"));
    }

    function validateLegislationForm() {
        if (legislationViewmodel?.name) createLegislation();
        else setLegislationsErrors(translateString("fieldMandatory"));
    }
}
