import { createElement } from "react";
import { Trans } from "react-i18next";
import { useMutation } from "react-query";
import { put } from "../../../common/api/apiShared";
import { Result } from "../../../common/api/result";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { DataSubjectSaveModel } from "../DataSubject.types";
import { IChangeDataSubjectStatusDialogDataMapping } from "./ChangeDataSubjectStatusDialog";

export function useChangeDataSubjectStatusDialogDataMapping(props: IChangeDataSubjectStatusDialogDataMapping) {
    const snackbar = useDotLegalSnackbar();

    const changeActiveStatusMutation = useMutation(changeStatus);

    const changeActiveStatus = (dataSubject: DataSubjectSaveModel) => {
        changeActiveStatusMutation.mutateAsync(dataSubject, {
            onSuccess: (response: Result<string>) => {
                props.onCloseDialog();
                if (response.success()) {
                    snackbar.show(createElement(Trans, { i18nKey: "activeStatusChanged", values: { name: dataSubject.name } }));
                    props.refetchDataSubjects();
                } else {
                    snackbar.show(createElement(Trans, { i18nKey: "dataSubjectStatusNotChanged", values: { name: dataSubject.name } }), "warning");
                }
            },
        });
    };

    function changeStatus(dataSubject: DataSubjectSaveModel) {
        if (dataSubject.isCustom) return put<string>(`/dataSubjects/${dataSubject.id}/changeActiveStatus`, !dataSubject.active);
        else return put<string>(`/dataSubjects/${dataSubject.id}/changeActiveMasterDataStatus`, !dataSubject.active);
    }

    return {
        changeActiveStatus,
        inProgress: changeActiveStatusMutation.isLoading,
    };
}
