import { useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";

export const useNavigationLinkStyles = (isExpanded: boolean, isActive?: boolean, isInGroup?: boolean) => {
    const theme = useTheme();
    let color = isActive ? theme.palette.primary.main : theme.palette.grey["700"];
    return createSxStyles({
        wrapper: {
            position: "relative",
            fontSize: theme.typography.pxToRem(14),
            fontWeight: theme.typography.fontWeightRegular,
            "&:hover": {
                cursor: "pointer",
            },
            borderRadius: 15 + "px",
            marginBottom: theme.spacing(1),
            "& .MuiPaper-elevation1": {
                boxShadow: "none",
            },
            "& .MuiAccordionSummary-content": {
                margin: 0,
                alignItems: "center",
            },
            "& .MuiPaper-root": {
                backgroundColor: "transparent",
            },
            "& .MuiIconButton-root": {
                padding: 0,
            },
            "& .MuiIconButton-edgeEnd": {
                marginRight: 0,
            },
            "& .Mui-expanded": {
                borderRadius: "15x",
            },
            "& .MuiAccordionDetails-root": {
                padding: 0,
            },
        },
        menuIcon: {
            display: "flex",
            justifyContent: "center",
            "& svg": {
                width: theme.customSpacing.menuIconSize,
                height: theme.customSpacing.menuIconSize,
                fill: "#DAD9EF",
            },
        },
        isMinimized: {
            display: "none",
        },
        text: {
            marginLeft: theme.spacing(1.5),
        },
        menuItem: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            textAlign: isExpanded ? "left" : "center",
            backgroundColor: isActive ? theme.palette.primary.light : "transparent",
            color: isActive ? theme.palette.primary.main : "black",
            "&:hover": {
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.primary.light,
            },
        },
        link: {
            display: isExpanded ? "flex" : "grid",
            alignItems: "center",
            height: 50,
            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(1.5),
            textDecoration: "none",
            color: color,
            fontWeight: theme.typography.fontWeightBold,
            borderRadius: 15 + "px",
            overflow: "hidden",
            textTransform: "uppercase",
            textOverflow: "elipsis",
            "&:hover": {
                color: isInGroup ? theme.palette.text.secondary : color,
            },
        },
        subMenuLink: {
            "&:hover": {
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.primary.light,
            },
            color: theme.palette.grey["700"],
            fontSize: theme.typography.pxToRem(13),
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5),
            marginTop: 0.5,
            "&:focus": {
                textDecoration: "none",
            },
        },
        popOverSubMenu: {
            zIndex: 1200,
            paddingLeft: theme.spacingAsNumber(2) + 2 + "px",
            "& a": {
                color: theme.palette.text.secondary,
            },
            "& .MuiMenuItem-root": {
                fontSize: theme.typography.pxToRem(13),
            },
            "& h4": {
                margin: 0,
                fontWeight: theme.typography.fontWeightBold,
                fontSize: theme.typography.pxToRem(14),
                padding: theme.spacing(1),
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
            },
            "& h5": {
                margin: 0,
                marginTop: theme.spacing(-1),
                fontWeight: theme.typography.fontWeightBold,
                fontSize: theme.typography.pxToRem(12),
                padding: theme.spacing(1),
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
            },
        },
        subMenuPopperLink: {
            "&:hover": {
                textDecoration: "none",
            },
            textDecoration: "none",
        },
        collapsedSubMenuItem: {
            display: "flex !important",
            justifyContent: "left !important",
            padding: 1,
            paddingLeft: 2,
            paddingRight: 2,
            height: 40,
            borderRadius: "15px",
            textTransform: "uppercase",
            fontWeight: "bold",
            backgroundColor: isActive ? theme.palette.primary.light : "transparent",
            "&:hover": {
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.primary.light,
            },
        },
    });
};
