import { useSelectableBusinessAreaQuery } from "../../../../common/hooks/useSelectableItemQueries";
import { useResponsibleQuery } from "../../../../user/hooks/useResponsibleQuery";

export default function useLegalEntityAssociationStepHooks() {
    const responsibleQuery = useResponsibleQuery();
    const businessAreaQuery = useSelectableBusinessAreaQuery();

    return {
        responsibles: responsibleQuery.data,
        responsiblesIsLoading: responsibleQuery.isLoading,
        businessAreas: businessAreaQuery.data,
        businessAreaIsLoading: businessAreaQuery.isLoading,
    };
}
