import { DotLegalDialog, DotLegalSelect, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { useAddLegalEntityDialogHook } from "./AddLegalEntityDialog.hooks";
import { LegalEntityPageEnum } from "../../legalEntities/LegalEntities.types";

export interface IAddLegalEntityDialog {
    onDialogClose: () => void;
    legalEntityPage: LegalEntityPageEnum;
}

function AddLegalEntityDialog(props: IAddLegalEntityDialog) {
    const { translateString } = useTranslation();
    const { saveModel, onSaveModelChange, onSaveClick, validation, countryList, isSaving } = useAddLegalEntityDialogHook(props);

    return (
        <DotLegalDialog
            size="sm"
            buttonOkText={translateString("create")}
            open
            onDialogClose={props.onDialogClose}
            onOkClick={onSaveClick}
            header={translateString("createLegalEntity")}
            inProgress={isSaving}
            disableBackdropOnCloseIfValueChange={saveModel}
        >
            <DotLegalTextField
                debounce={false}
                size="small"
                label={translateString("name")}
                value={saveModel.name}
                onChange={onSaveModelChange.onNameChange}
                errorText={validation?.getErrorsForDisplay("name")}
            />
            <DotLegalSelect
                label={translateString("country")}
                selectedItem={saveModel.countryCode === "" ? null : saveModel.countryCode}
                onChange={(countryCode) => onSaveModelChange.onCountryChange(countryCode ?? "")}
                options={countryList}
                placeholder={translateString("country")}
                errorText={validation?.getErrorsForDisplay("countryCode")}
                noOptionsLabel={translateString("noOptions")}
                disableClearable
            />
        </DotLegalDialog>
    );
}

export default AddLegalEntityDialog;
