import { useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";

export const useLegalBasisSelectorContainerStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        divider: {
            backgroundColor: "#B6D5EF",
        },
        dataCategoryHeader: {
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
        },
        infoHeader: {
            fontSize: theme.typography.pxToRem(12),
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.primary.dark,
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        tooltip: {
            marginLeft: theme.spacing(1),
        },
    });
};
