import { DotLegalHeader, DotLegalInformationTooltip, DotLegalOverflowMenu, DotLegalTooltip } from "@dotlegal/dotlegal-ui-components";
import { Box, Grid, MenuItem } from "@mui/material";
import React from "react";
import { useTranslation } from "../../../../localization/useTranslation";
import { AnnualWheelActivityTemplateItem, AnnualWheelActivityType } from "../../AnnualWheelDialog.types";
import ActivityIcon from "../../activity.svg?react";
import AssessNeedForTvSurveilance from "../icons/AssessNeedForTvSurveilance.svg?react";
import InspectProceduresAndPolicies from "../icons/InspectProceduresAndPolicies.svg?react";
import CustomizeGDPRTemplates from "../icons/CustomizeGDPRTemplates.svg?react";
import ExamineGuidlineDecisionsFromDDPA from "../icons/ExamineGuidlineDecisionsFromDDPA.svg?react";
import ValidateRecord from "../icons/ValidateRecord.svg?react";
import InspectThirdCountryTransfers from "../icons/InspectThirdCountryTransfers.svg?react";
import FollowUpLastYearGDPRProjectsAndPlanNext from "../icons/FollowUpLastYearGDPRProjectsAndPlanNext.svg?react";
import CompleteYearlyAudit from "../icons/CompleteYearlyAudit.svg?react";
import ValidateGDRPOnIntra from "../icons/ValidateGDRPOnIntra.svg?react";
import ValidateGroupEntityBasisForProcessing from "../icons/ValidateGroupEntityBasisForProcessing.svg?react";
import ReviewConsents from "../icons/ReviewConsents.svg?react";
import SpotChecksOnConsents from "../icons/SpotChecksOnConsents.svg?react";
import PlanAwerenessCampaign from "../icons/PlanAwerenessCampaign.svg?react";
import ValidateYourGroupComponies from "../icons/ValidateYourGroupComponies.svg?react";
import ReviewConfidentailityAgreements from "../icons/ReviewConfidentailityAgreements.svg?react";
import DataProcessorAudit from "../icons/DataProcessorAudit.svg?react";
import UpdateDataprocessorAgreements from "../icons/UpdateDataprocessorAgreements.svg?react";
import AuditSubProcessors from "../icons/AuditSubProcessors.svg?react";
import UpdatePrivacyPolicyWebsite from "../icons/UpdatePrivacyPolicyWebsite.svg?react";
import ReviewSocialMediaPrivacyPolicies from "../icons/ReviewSocialMediaPrivacyPolicies.svg?react";
import CheckCookiePolicyAndBanner from "../icons/CheckCookiePolicyAndBanner.svg?react";
import ReviewDeletionPolicy from "../icons/ReviewDeletionPolicy.svg?react";
import CheckForDeFactoDeletion from "../icons/CheckForDeFactoDeletion.svg?react";
import ReviewImageAndVideo from "../icons/ReviewImageAndVideo.svg?react";
import ReviewRequirementsForJobApplicants from "../icons/ReviewRequirementsForJobApplicants.svg?react";
import ReviewEmployeeDisclosure from "../icons/ReviewEmployeeDisclosure.svg?react";
import ReviewPolicyForDataSubjectsRights from "../icons/ReviewPolicyForDataSubjectsRights.svg?react";
import CheckPolicyForDataSubjectRights from "../icons/CheckPolicyForDataSubjectRights.svg?react";
import ReviewDisclosurRegForResignation from "../icons/ReviewDisclosurRegForResignation.svg?react";
import ReviewOfRiskAssessment from "../icons/ReviewOfRiskAssessment.svg?react";
import AssessImpactAssessmentsDPIA from "../icons/AssessImpactAssessmentsDPIA.svg?react";
import TestSecurityBreachProcedure from "../icons/TestSecurityBreachProcedure.svg?react";
import CheckLogForSecurityBreaches from "../icons/CheckLogForSecurityBreaches.svg?react";
import TestAndFollowUpITContingencyPlan from "../icons/TestAndFollowUpITContingencyPlan.svg?react";
import FollowUpAccessRights from "../icons/FollowUpAccessRights.svg?react";
import UpdateItSecurityPolicy from "../icons/UpdateItSecurityPolicy.svg?react";
import CheckDPOsObligations from "../icons/CheckDPOsObligations.svg?react";
import ClarifyTheDriversForEstablishingNIS2Compliance from "../icons/ClarifyTheDriversForEstablishingNIS2Compliance.svg?react";
import ConductGAPAnalysis from "../icons/ConductGAPAnalysis.svg?react";
import PreparationOfFinalActionPlan from "../icons/PreparationOfFinalActionPlan.svg?react";
import OngoingMonthlyStatusMeetingsDuringTheImplementationPhase from "../icons/OngoingMonthlyStatusMeetingsDuringTheImplementationPhase.svg?react";
import EstablishAnOverviewOfAllNetworkAndInformationSystems from "../icons/EstablishAnOverviewOfAllNetworkAndInformationSystems.svg?react";
import DefinitionOfWhatIsCriticalAccordingToNIS2ForTheOrganisation from "../icons/DefinitionOfWhatIsCriticalAccordingToNIS2ForTheOrganisation.svg?react";
import IdentificationOfCriticalProcessesSystemsBasedOnTheEstablishedOverview from "../icons/IdentificationOfCriticalProcessesSystemsBasedOnTheEstablishedOverview.svg?react";
import OrganisationalAnchoringOfResponsibilityForTheIdentifiedSystems from "../icons/OrganisationalAnchoringOfResponsibilityForTheIdentifiedSystems.svg?react";
import DeterminationOfRiskAssessmentMethodology from "../icons/DeterminationOfRiskAssessmentMethodology.svg?react";
import IdentificationOfRelevantThreatsAndDangersInNetworkAndInformationSystems from "../icons/IdentificationOfRelevantThreatsAndDangersInNetworkAndInformationSystems.svg?react";
import SupplyChainSecurity from "../icons/SupplyChainSecurity.svg?react";
import RiskAssessmentBasedOnIdentifiedThreats from "../icons/RiskAssessmentBasedOnIdentifiedThreats.svg?react";
import IdentificationOfUnacceptableRisksBasedOnTheRiskAssessment from "../icons/IdentificationOfUnacceptableRisksBasedOnTheRiskAssessment.svg?react";
import VerificationOfTheLevelOfTechnicalOperationalAndOrganisationalMeasures from "../icons/VerificationOfTheLevelOfTechnicalOperationalAndOrganisationalMeasures.svg?react";
import ImplementationOfAdditionalMeasuresThatProveNecessaryBasedOnTheRiskAssessment from "../icons/ImplementationOfAdditionalMeasuresThatProveNecessaryBasedOnTheRiskAssessment.svg?react";
import DealingWithUnforeseenFindingsDuringTheMappingPhaseThatPoseARiskOrBreachOfInternalGuidelines from "../icons/DealingWithUnforeseenFindingsDuringTheMappingPhaseThatPoseARiskOrBreachOfInternalGuidelines.svg?react";
import PhysicalSecurity from "../icons/PhysicalSecurity.svg?react";
import InformationSecurityPolicy from "../icons/InformationSecurityPolicy.svg?react";
import TheOrganisationsRequirementsForSuppliers from "../icons/TheOrganisationsRequirementsForSuppliers.svg?react";
import TheOrganisationsEffectiveManagementPracticesInConnectionWithOutsourcing from "../icons/TheOrganisationsEffectiveManagementPracticesInConnectionWithOutsourcing.svg?react";
import ReviewOfContractsWithSuppliersOfCriticalSystemsEtc from "../icons/ReviewOfContractsWithSuppliersOfCriticalSystemsEtc.svg?react";
import ControlProceduresInRelationToSupplierServices from "../icons/ControlProceduresInRelationToSupplierServices.svg?react";
import ProceduresForContractAndSupplierManagement from "../icons/ProceduresForContractAndSupplierManagement.svg?react";
import EstablishRelevantTrainingCoursesForDifferentEmployeeGroups from "../icons/EstablishRelevantTrainingCoursesForDifferentEmployeeGroups.svg?react";
import EmployeeOrientedGuidelines from "../icons/EmployeeOrientedGuidelines.svg?react";
import OverallEmergencyManagement from "../icons/OverallEmergencyManagement.svg?react";
import EmergencyAndRecoveryPlans from "../icons/EmergencyAndRecoveryPlans.svg?react";
import EventLogForInternalDocumentationOfEvents from "../icons/EventLogForInternalDocumentationOfEvents.svg?react";
import ProcedureForOngoingReviewOrFollowUpOfIncidentHistory from "../icons/ProcedureForOngoingReviewOrFollowUpOfIncidentHistory.svg?react";
import AnnualWheel from "../icons/AnnualWheel.svg?react";
import Governance from "../icons/Governance.svg?react";
import CommunicationToTheOrganisation from "../icons/CommunicationToTheOrganisation.svg?react";
import ReportingFromTheOrganisation from "../icons/ReportingFromTheOrganisation.svg?react";
import RevisitingInsuranceCoverage from "../icons/RevisitingInsuranceCoverage.svg?react";
import AnnualUpdateOfTheAnnualWheel from "../icons/AnnualUpdateOfTheAnnualWheel.svg?react";
import ReviewOrganizationalChanges from "../icons/ReviewOrganizationalChanges.svg?react";
import OngoingTasksAccordingToRelevantStandardsCertificationSchemes from "../icons/OngoingTasksAccordingToRelevantStandardsCertificationSchemes.svg?react";
import ExecutiveBoardMeetings from "../icons/ExecutiveBoardMeetings.svg?react";
import BoardMeetings from "../icons/BoardMeetings.svg?react";
import TheManagementsActiveSupervision from "../icons/TheManagementsActiveSupervision.svg?react";
import InsuranceIncludingCyberInsurance from "../icons/InsuranceIncludingCyberInsurance.svg?react";
import NIS2AtStrategicAndTacticalLevel from "../icons/NIS2AtStrategicAndTacticalLevel.svg?react";
import AccessDepartureOrChangesInTheSystemsFunction from "../icons/AccessDepartureOrChangesInTheSystemsFunction.svg?react";
import ChangesInTheThreatLevelAndRiskAssessment from "../icons/ChangesInTheThreatLevelAndRiskAssessment.svg?react";
import VerificationOfTheLevelOfTechnicalOperationalAndOrganizationalMeasures from "../icons/VerificationOfTheLevelOfTechnicalOperationalAndOrganizationalMeasures.svg?react";
import TestingOfSecurityMeasures from "../icons/TestingOfSecurityMeasures.svg?react";
import UpdateProcessAndSystemSpecificDocumentation from "../icons/UpdateProcessAndSystemSpecificDocumentation.svg?react";
import ChangesInSystemOwnership from "../icons/ChangesInSystemOwnership.svg?react";
import ChangesToControlProcedures from "../icons/ChangesToControlProcedures.svg?react";
import AnyChangedSystemOrDataClassification from "../icons/AnyChangedSystemOrDataClassification.svg?react";
import ChangesInTheOrganizationsProceduresForSupplierManagementAndProcurement from "../icons/ChangesInTheOrganizationsProceduresForSupplierManagementAndProcurement.svg?react";
import OngoingControlOrAuditsOfSuppliers from "../icons/OngoingControlOrAuditsOfSuppliers.svg?react";
import UpdateOfSupplierAgreements from "../icons/UpdateOfSupplierAgreements.svg?react";
import YearlyPlanForTrainingTheOrganization from "../icons/YearlyPlanForTrainingTheOrganization.svg?react";
import UpdatingOfGeneralGuidanceMaterial from "../icons/UpdatingOfGeneralGuidanceMaterial.svg?react";
import TestOfEmergencyPlans from "../icons/TestOfEmergencyPlans.svg?react";
import FollowUpOfEventsOnTheIncidenceLog from "../icons/FollowUpOfEventsOnTheIncidenceLog.svg?react";
import UpdatingOfDocumentationIncludingEmergencyPlans from "../icons/UpdatingOfDocumentationIncludingEmergencyPlans.svg?react";
import Audits from "../icons/Audits.svg?react";
import EvaluationAndFollowUpOfTheControlResults from "../icons/EvaluationAndFollowUpOfTheControlResults.svg?react";
import IsoIcon from "../icons/Iso.svg?react";

import { useSelectableActivityTemplateStyles } from "./SelectableActivityTemplate.styles";
import PurpleLockSvg from "../../../../common/svgs/purpleLock.svg?react";
import SubtasksSvg from "../../subtasks.svg?react";
import { useUserContext } from "../../../../auth/userContextProvider/UserContextProvider";
import PlanMenuItemWrapper from "../../../../plan/planMenuItemWrapper/PlanMenuItemWrapper";
import { getPlanOverflowMenuItem } from "../../../../plan/planOverflowMenuItem/PlanOverflowMenuItem";
import { usePlanContext } from "../../../../auth/planProvider/PlanProvider";

export interface ISelectableActivityTemplate {
    activity: AnnualWheelActivityTemplateItem;
    selectedActivity?: string;
    isActivityLocked?: boolean;
    addCustomIdToHeader?: boolean;
    onClick: (activity: AnnualWheelActivityTemplateItem) => void;
    onEditClick?: () => void;
    onDeleteClick?: () => void;
    onCopyClick?: () => void;
    upgradeText?: string;
}

export function SelectableActivityTemplate(props: ISelectableActivityTemplate) {
    const isScheduled = props.activity.isScheduled;
    const { translateString } = useTranslation();
    const styles = useSelectableActivityTemplateStyles(isScheduled);
    const { permissions } = useUserContext();
    const { platformFeatures } = usePlanContext();

    const getMenuItems = () => {
        const menuItems = [];

        if (permissions.annualWheelPermission.edit && props.onEditClick) {
            menuItems.push({
                menuItem: translateString("edit"),
                onClick: () => {
                    props.onEditClick!();
                },
            });
        }

        if (permissions.annualWheelPermission.delete && props.onDeleteClick) {
            menuItems.push({
                menuItem: translateString("delete"),
                onClick: () => {
                    props.onDeleteClick!();
                },
            });
        }

        if (permissions.annualWheelPermission.create && props.onCopyClick) {
            menuItems.push(
                getPlanOverflowMenuItem(
                    () => props.onCopyClick!(),
                    platformFeatures.annualWheelCopyActivity,
                    translateString("copy"),
                    translateString("upgradePrivacyPlanToday")
                )
            );
        }

        return menuItems;
    };

    const getSubTaskToolTip = (
        <React.Fragment>
            <Box sx={styles.subTasksToolTip}>{translateString("subTasks")}:</Box>
            {props.activity.subTasks.map((x, index) => (
                <Box sx={styles.subTasksToolTip} key={x.name}>
                    {x.name}
                </Box>
            ))}
        </React.Fragment>
    );
    const getSelectableActivity = () => {
        const menuItems = getMenuItems();
        let selectableActivity = (
            <Box
                key={props.activity.id}
                onClick={() => {
                    props.onClick(props.activity);
                }}
                sx={
                    props.isActivityLocked
                        ? styles.lockedActivityItem
                        : props.selectedActivity === props.activity.id
                          ? styles.selectedItem
                          : styles.activityItem
                }
            >
                <React.Fragment>
                    <DotLegalTooltip text={translateString("annualWhewelActivityAlreadyPlanned")} disableHoverlistener={!isScheduled}>
                        <Box sx={styles.imgContainer}>{getActivityIcon(props.activity.id, props.activity.isCustom)}</Box>
                    </DotLegalTooltip>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                        <DotLegalHeader headerStyle="medium" marginBottom={0}>
                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                <span>
                                    {props.addCustomIdToHeader ? props.activity.customId : ""} {props.activity.name}
                                </span>
                                {!props.isActivityLocked && (
                                    <>
                                        {props.activity.description && <DotLegalInformationTooltip text={props.activity.description} />}
                                        {props.activity.subTasks.length > 0 && (
                                            <DotLegalTooltip text={getSubTaskToolTip}>
                                                <Box sx={{ marginTop: 0.7 }}>
                                                    <SubtasksSvg />
                                                </Box>
                                            </DotLegalTooltip>
                                        )}
                                    </>
                                )}
                            </Box>
                        </DotLegalHeader>
                        {props.isActivityLocked ? (
                            <Box marginLeft={1} marginRight={1.5} component={"span"}>
                                <PurpleLockSvg />
                            </Box>
                        ) : (
                            menuItems.length > 0 && <DotLegalOverflowMenu items={menuItems} />
                        )}
                    </Box>
                </React.Fragment>
            </Box>
        );

        if (props.isActivityLocked) {
            selectableActivity = <DotLegalTooltip text={props.upgradeText}>{selectableActivity}</DotLegalTooltip>;
        }

        return (
            <Grid item xs={12} sm={6}>
                {selectableActivity}
            </Grid>
        );
    };

    return <React.Fragment>{getSelectableActivity()}</React.Fragment>;
}

function getActivityIcon(id: string, isCustom: boolean) {
    if (isCustom) {
        return <ActivityIcon />;
    }

    const type = Number(id) as AnnualWheelActivityType;

    switch (type) {
        case AnnualWheelActivityType.ProcessingActivityValidation:
            return <ActivityIcon />;
        case AnnualWheelActivityType.InspectProceduresAndPolicies:
            return <InspectProceduresAndPolicies />;
        case AnnualWheelActivityType.CustomizeGDPRTemplates:
            return <CustomizeGDPRTemplates />;
        case AnnualWheelActivityType.ExamineGuidlineDecisionsFromDDPA:
            return <ExamineGuidlineDecisionsFromDDPA />;
        case AnnualWheelActivityType.ValidateRecord:
            return <ValidateRecord />;
        case AnnualWheelActivityType.InspectThirdCountryTransfers:
            return <InspectThirdCountryTransfers />;
        case AnnualWheelActivityType.FollowUpLastYearGDPRProjectsAndPlanNext:
            return <FollowUpLastYearGDPRProjectsAndPlanNext />;
        case AnnualWheelActivityType.CompleteYearlyAudit:
            return <CompleteYearlyAudit />;
        case AnnualWheelActivityType.ValidateGDRPOnIntra:
            return <ValidateGDRPOnIntra />;
        case AnnualWheelActivityType.ValidateGroupEntityBasisForProcessing:
            return <ValidateGroupEntityBasisForProcessing />;
        case AnnualWheelActivityType.ReviewConsents:
            return <ReviewConsents />;
        case AnnualWheelActivityType.SpotChecksOnConsents:
            return <SpotChecksOnConsents />;
        case AnnualWheelActivityType.PlanAwerenessCampaignOne:
            return <PlanAwerenessCampaign />;
        case AnnualWheelActivityType.PlanAwerenessCampaignTwo:
            return <PlanAwerenessCampaign />;
        case AnnualWheelActivityType.ValidateYourGroupCompanies:
            return <ValidateYourGroupComponies />;
        case AnnualWheelActivityType.ReviewConfidentailityAgreements:
            return <ReviewConfidentailityAgreements />;
        case AnnualWheelActivityType.DataProcessorAudit:
            return <DataProcessorAudit />;
        case AnnualWheelActivityType.UpdateDataprocessorAgreements:
            return <UpdateDataprocessorAgreements />;
        case AnnualWheelActivityType.AuditSubProcessors:
            return <AuditSubProcessors />;
        case AnnualWheelActivityType.UpdatePrivacyPolicyWebsite:
            return <UpdatePrivacyPolicyWebsite />;
        case AnnualWheelActivityType.ReviewSocialMediaPrivacyPolicies:
            return <ReviewSocialMediaPrivacyPolicies />;
        case AnnualWheelActivityType.CheckCookiePolicyAndBanner:
            return <CheckCookiePolicyAndBanner />;
        case AnnualWheelActivityType.ReviewDeletionPolicy:
            return <ReviewDeletionPolicy />;
        case AnnualWheelActivityType.CheckForDeFactoDeletion:
            return <CheckForDeFactoDeletion />;
        case AnnualWheelActivityType.ReviewImageAndVideo:
            return <ReviewImageAndVideo />;
        case AnnualWheelActivityType.ReviewRequirementsForJobApplicants:
            return <ReviewRequirementsForJobApplicants />;
        case AnnualWheelActivityType.ReviewEmployeeDisclosure:
            return <ReviewEmployeeDisclosure />;
        case AnnualWheelActivityType.ReviewPolicyForDataSubjectsRights:
            return <ReviewPolicyForDataSubjectsRights />;
        case AnnualWheelActivityType.CheckPolicyForDataSubjectRights:
            return <CheckPolicyForDataSubjectRights />;
        case AnnualWheelActivityType.ReviewDisclosurRegForResignation:
            return <ReviewDisclosurRegForResignation />;
        case AnnualWheelActivityType.ReviewOfRiskAssessment:
            return <ReviewOfRiskAssessment />;
        case AnnualWheelActivityType.AssessImpactAssessmentsDPIA:
            return <AssessImpactAssessmentsDPIA />;
        case AnnualWheelActivityType.TestSecurityBreachProcedure:
            return <TestSecurityBreachProcedure />;
        case AnnualWheelActivityType.CheckLogForSecurityBreaches:
            return <CheckLogForSecurityBreaches />;
        case AnnualWheelActivityType.TestAndFollowUpITContingencyPlan:
            return <TestAndFollowUpITContingencyPlan />;
        case AnnualWheelActivityType.FollowUpAccessRights:
            return <FollowUpAccessRights />;
        case AnnualWheelActivityType.UpdateItSecurityPolicy:
            return <UpdateItSecurityPolicy />;
        case AnnualWheelActivityType.CheckDPOsObligations:
            return <CheckDPOsObligations />;
        case AnnualWheelActivityType.AssessNeedForTvSurveilance:
            return <AssessNeedForTvSurveilance />;

        case AnnualWheelActivityType.ClarifyTheDriversForEstablishingNIS2Compliance:
            return <ClarifyTheDriversForEstablishingNIS2Compliance />;
        case AnnualWheelActivityType.ConductGAPAnalysis:
            return <ConductGAPAnalysis />;
        case AnnualWheelActivityType.PreparationOfFinalActionPlan:
            return <PreparationOfFinalActionPlan />;
        case AnnualWheelActivityType.OngoingMonthlyStatusMeetingsDuringTheImplementationPhase:
            return <OngoingMonthlyStatusMeetingsDuringTheImplementationPhase />;
        case AnnualWheelActivityType.EstablishAnOverviewOfAllNetworkAndInformationSystems:
            return <EstablishAnOverviewOfAllNetworkAndInformationSystems />;
        case AnnualWheelActivityType.DefinitionOfWhatIsCriticalAccordingToNIS2ForTheOrganisation:
            return <DefinitionOfWhatIsCriticalAccordingToNIS2ForTheOrganisation />;
        case AnnualWheelActivityType.IdentificationOfCriticalProcessesSystemsBasedOnTheEstablishedOverview:
            return <IdentificationOfCriticalProcessesSystemsBasedOnTheEstablishedOverview />;
        case AnnualWheelActivityType.OrganisationalAnchoringOfResponsibilityForTheIdentifiedSystems:
            return <OrganisationalAnchoringOfResponsibilityForTheIdentifiedSystems />;
        case AnnualWheelActivityType.DeterminationOfRiskAssessmentMethodology:
            return <DeterminationOfRiskAssessmentMethodology />;
        case AnnualWheelActivityType.IdentificationOfRelevantThreatsAndDangersInNetworkAndInformationSystems:
            return <IdentificationOfRelevantThreatsAndDangersInNetworkAndInformationSystems />;
        case AnnualWheelActivityType.SupplyChainSecurity:
            return <SupplyChainSecurity />;
        case AnnualWheelActivityType.RiskAssessmentBasedOnIdentifiedThreats:
            return <RiskAssessmentBasedOnIdentifiedThreats />;
        case AnnualWheelActivityType.IdentificationOfUnacceptableRisksBasedOnTheRiskAssessment:
            return <IdentificationOfUnacceptableRisksBasedOnTheRiskAssessment />;
        case AnnualWheelActivityType.VerificationOfTheLevelOfTechnicalOperationalAndOrganisationalMeasures:
            return <VerificationOfTheLevelOfTechnicalOperationalAndOrganisationalMeasures />;
        case AnnualWheelActivityType.ImplementationOfAdditionalMeasuresThatProveNecessaryBasedOnTheRiskAssessment:
            return <ImplementationOfAdditionalMeasuresThatProveNecessaryBasedOnTheRiskAssessment />;
        case AnnualWheelActivityType.DealingWithUnforeseenFindingsDuringTheMappingPhaseThatPoseARiskOrBreachOfInternalGuidelines:
            return <DealingWithUnforeseenFindingsDuringTheMappingPhaseThatPoseARiskOrBreachOfInternalGuidelines />;
        case AnnualWheelActivityType.PhysicalSecurity:
            return <PhysicalSecurity />;
        case AnnualWheelActivityType.InformationSecurityPolicy:
            return <InformationSecurityPolicy />;
        case AnnualWheelActivityType.TheOrganisationsRequirementsForSuppliers:
            return <TheOrganisationsRequirementsForSuppliers />;
        case AnnualWheelActivityType.TheOrganisationsEffectiveManagementPracticesInConnectionWithOutsourcing:
            return <TheOrganisationsEffectiveManagementPracticesInConnectionWithOutsourcing />;
        case AnnualWheelActivityType.ReviewOfContractsWithSuppliersOfCriticalSystemsEtc:
            return <ReviewOfContractsWithSuppliersOfCriticalSystemsEtc />;
        case AnnualWheelActivityType.ControlProceduresInRelationToSupplierServices:
            return <ControlProceduresInRelationToSupplierServices />;
        case AnnualWheelActivityType.ProceduresForContractAndSupplierManagement:
            return <ProceduresForContractAndSupplierManagement />;
        case AnnualWheelActivityType.EstablishRelevantTrainingCoursesForDifferentEmployeeGroups:
            return <EstablishRelevantTrainingCoursesForDifferentEmployeeGroups />;
        case AnnualWheelActivityType.EmployeeOrientedGuidelines:
            return <EmployeeOrientedGuidelines />;
        case AnnualWheelActivityType.OverallEmergencyManagement:
            return <OverallEmergencyManagement />;
        case AnnualWheelActivityType.EmergencyAndRecoveryPlans:
            return <EmergencyAndRecoveryPlans />;
        case AnnualWheelActivityType.EventLogForInternalDocumentationOfEvents:
            return <EventLogForInternalDocumentationOfEvents />;
        case AnnualWheelActivityType.ProcedureForOngoingReviewOrFollowUpOfIncidentHistory:
            return <ProcedureForOngoingReviewOrFollowUpOfIncidentHistory />;
        case AnnualWheelActivityType.AnnualWheel:
            return <AnnualWheel />;
        case AnnualWheelActivityType.Governance:
            return <Governance />;
        case AnnualWheelActivityType.CommunicationToTheOrganisation:
            return <CommunicationToTheOrganisation />;
        case AnnualWheelActivityType.ReportingFromTheOrganisation:
            return <ReportingFromTheOrganisation />;
        case AnnualWheelActivityType.RevisitingInsuranceCoverage:
            return <RevisitingInsuranceCoverage />;
        case AnnualWheelActivityType.AnnualUpdateOfTheAnnualWheel:
            return <AnnualUpdateOfTheAnnualWheel />;
        case AnnualWheelActivityType.ReviewOrganizationalChanges:
            return <ReviewOrganizationalChanges />;
        case AnnualWheelActivityType.OngoingTasksAccordingToRelevantStandardsCertificationSchemes:
            return <OngoingTasksAccordingToRelevantStandardsCertificationSchemes />;
        case AnnualWheelActivityType.ExecutiveBoardMeetings:
            return <ExecutiveBoardMeetings />;
        case AnnualWheelActivityType.BoardMeetings:
            return <BoardMeetings />;
        case AnnualWheelActivityType.TheManagementsActiveSupervision:
            return <TheManagementsActiveSupervision />;
        case AnnualWheelActivityType.InsuranceIncludingCyberInsurance:
            return <InsuranceIncludingCyberInsurance />;
        case AnnualWheelActivityType.NIS2AtStrategicAndTacticalLevel:
            return <NIS2AtStrategicAndTacticalLevel />;
        case AnnualWheelActivityType.AccessDepartureOrChangesInTheSystemsFunction:
            return <AccessDepartureOrChangesInTheSystemsFunction />;
        case AnnualWheelActivityType.ChangesInTheThreatLevelAndRiskAssessment:
            return <ChangesInTheThreatLevelAndRiskAssessment />;
        case AnnualWheelActivityType.VerificationOfTheLevelOfTechnicalOperationalAndOrganizationalMeasures:
            return <VerificationOfTheLevelOfTechnicalOperationalAndOrganizationalMeasures />;
        case AnnualWheelActivityType.TestingOfSecurityMeasures:
            return <TestingOfSecurityMeasures />;
        case AnnualWheelActivityType.UpdateProcessAndSystemSpecificDocumentation:
            return <UpdateProcessAndSystemSpecificDocumentation />;
        case AnnualWheelActivityType.ChangesInSystemOwnership:
            return <ChangesInSystemOwnership />;
        case AnnualWheelActivityType.ChangesToControlProcedures:
            return <ChangesToControlProcedures />;
        case AnnualWheelActivityType.AnyChangedSystemOrDataClassification:
            return <AnyChangedSystemOrDataClassification />;
        case AnnualWheelActivityType.ChangesInTheOrganizationsProceduresForSupplierManagementAndProcurement:
            return <ChangesInTheOrganizationsProceduresForSupplierManagementAndProcurement />;
        case AnnualWheelActivityType.OngoingControlOrAuditsOfSuppliers:
            return <OngoingControlOrAuditsOfSuppliers />;
        case AnnualWheelActivityType.UpdateOfSupplierAgreements:
            return <UpdateOfSupplierAgreements />;
        case AnnualWheelActivityType.YearlyPlanForTrainingTheOrganization:
            return <YearlyPlanForTrainingTheOrganization />;
        case AnnualWheelActivityType.UpdatingOfGeneralGuidanceMaterial:
            return <UpdatingOfGeneralGuidanceMaterial />;
        case AnnualWheelActivityType.TestOfEmergencyPlans:
            return <TestOfEmergencyPlans />;
        case AnnualWheelActivityType.FollowUpOfEventsOnTheIncidenceLog:
            return <FollowUpOfEventsOnTheIncidenceLog />;
        case AnnualWheelActivityType.UpdatingOfDocumentationIncludingEmergencyPlans:
            return <UpdatingOfDocumentationIncludingEmergencyPlans />;
        case AnnualWheelActivityType.Audits:
            return <Audits />;
        case AnnualWheelActivityType.EvaluationAndFollowUpOfTheControlResults:
            return <EvaluationAndFollowUpOfTheControlResults />;

        default:
            return <IsoIcon />;
    }
}
