import { useTheme } from "@mui/material";
import { createSxStyles } from "../../../common/createSxStyles";

export const useBusinessAreaStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        container: {
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
        },
        switchBoxContainer: {
            marginBottom: theme.spacing(2.5),
            width: 180,
        },
        paperContainer: {
            flex: "1",
            display: "flex",
            flexDirection: "column",
        },
        checkIcon: {
            height: "20px !important",
        },
        bold: {
            fontWeight: theme.typography.fontWeightBold,
        },
        iconContainer: {
            display: "flex",
        },
    });
};
