import { useMemo, useState } from "react";
import { MultiValidator } from "./MultiValidator";
import { SingleValidator } from "./SingleValidator";
import { CompositeValidator, CompositeValidatorIndividual, ItemOfValidator, IValidatorBase, ValidatorFn, ValidatorFnConfig, ValidatorFnConfigs } from "./types";
import { Validators } from "./validators";
import { toArray } from "../../utilities";
import { useTranslation } from "../../../localization/useTranslation";

export * from "./types";
export { SingleValidator as Validator };

function normalizeValidator<TCheckInput>(validatorFnConfig: ValidatorFnConfig<TCheckInput>): ValidatorFn<TCheckInput> {
    if (Array.isArray(validatorFnConfig)) {
        const [fn, error] = validatorFnConfig;

        return (item) => (fn(item) ? [error] : []);
    }

    return (item) => toArray(validatorFnConfig(item));
}

function toValidatorFns<TItem, TCheckInput>(configuration: { [name in keyof TItem]?: ValidatorFnConfigs<TCheckInput> }) {
    const validatorFns: { [name in keyof TItem]?: ValidatorFn<TCheckInput>[] } = {};

    Object.keys(configuration).forEach((key) => {
        const name = key as keyof TItem;
        const validatorFnConfigs: ValidatorFnConfigs<TCheckInput> | undefined = configuration[name];
        validatorFns[name] = toArray(validatorFnConfigs).map(normalizeValidator);
    });

    return validatorFns;
}

function useRenderTrigger() {
    const [, setRerenderValue] = useState(0);

    return () => {
        setRerenderValue((v) => v + 1);
    };
}

export function useValidator<TItem, TCheckInput = TItem>(
    configuration: (validators: Validators<TCheckInput>) => { [name in keyof TItem]?: ValidatorFnConfigs<TCheckInput> },
    deps?: ReadonlyArray<any> | undefined
): SingleValidator<TItem, TCheckInput> {
    const renderTrigger = useRenderTrigger();
    const { translateString } = useTranslation();

    return useMemo(() => {
        const validatorFns = toValidatorFns<TItem, TCheckInput>(configuration(new Validators<TCheckInput>(translateString)));

        return new SingleValidator<TItem, TCheckInput>(validatorFns, renderTrigger);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps ?? []);
}

export function useValidators<TItem>(
    configuration: (validators: Validators<TItem>) => { [name in keyof TItem]?: ValidatorFnConfigs<TItem> },
    keySelector: (item: TItem) => string
): MultiValidator<TItem, TItem>;
export function useValidators<TItem, TCheckInput = TItem>(
    configuration: (validators: Validators<TCheckInput>) => { [name in keyof TItem]?: ValidatorFnConfigs<TCheckInput> },
    keySelector: (item: TItem) => string,
    nameSourceSelector: (item: TCheckInput) => TItem
): MultiValidator<TItem, TCheckInput>;
export function useValidators<TItem, TCheckInput = TItem>(
    configuration: (validators: Validators<TCheckInput>) => { [name in keyof TItem]?: ValidatorFnConfigs<TCheckInput> },
    keySelector: (item: TItem) => string,
    nameSourceSelector: (input: TCheckInput) => TItem = (i) => i as any as TItem
): MultiValidator<TItem, TCheckInput> {
    const renderTrigger = useRenderTrigger();
    const { translateString } = useTranslation();

    return useMemo(() => {
        const validatorFns = toValidatorFns<TItem, TCheckInput>(configuration(new Validators<TCheckInput>(translateString)));

        return new MultiValidator<TItem, TCheckInput>(validatorFns, renderTrigger, keySelector, nameSourceSelector);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}

export function useCompositeValidator<TInput extends { [key: string]: CompositeValidatorIndividual<unknown> }>(
    parts: TInput & { composed?: never; check?: never }
): CompositeValidator<TInput> {
    return useMemo(() => {
        const validators = Object.values<IValidatorBase>(parts);

        return {
            ...parts,
            composed: {
                get anyHasErrors() {
                    return validators.some((v) => v.anyHasErrors);
                },
                get showErrors() {
                    return validators.some((v) => v.showErrors);
                },

                setShowErrors(value: boolean) {
                    validators.forEach((v) => v.setShowErrors(value));
                },
            },
            check(items: { [key in keyof TInput]: ItemOfValidator<TInput[key]> }) {
                Object.keys(items).forEach((key) => {
                    const name = key as keyof TInput;

                    const item = items[key];
                    const validator = parts[name];

                    validator.check(item);
                });

                return this;
            },
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}
