import React, { useState } from "react";
import DotLegalTabs from "../dotLegalTabs/DotLegalTabs";
import { TabContent } from "../dotLegalTabs/DotLegalTabs.types";
import { useDotLegalTemplateDialog } from "./DotLegalTemplateDialog.styles";
import { arrow } from "../../icons";
import { DotLegalDialog, IDotLegalDialogProps } from "@dotlegal/dotlegal-ui-components";
import { Box } from "@mui/material";

export interface IDotLegalTemplateDialogProps extends IDotLegalDialogProps {
    tabContent: Array<TabContent>;
    templateCount: number;
    showMaxElementsExceeded?: React.ReactNode;
}

function DotLegalTemplateDialog(props: IDotLegalTemplateDialogProps) {
    const [tab, setTab] = useState(0);
    const styles = useDotLegalTemplateDialog();

    const saveTemplatesIcon = () => {
        return <Box sx={styles.whiteArrow}>{arrow}</Box>;
    };

    return (
        <DotLegalDialog
            isLoading={props.isLoading}
            inProgress={props.inProgress}
            size="lg"
            header={props.header}
            buttonOkText={props.buttonOkText}
            buttonOkIcon={saveTemplatesIcon()}
            okButtonDisabled={props.templateCount === 0}
            open
            buttonRight
            onDialogClose={props.onDialogClose}
            onOkClick={props.onOkClick}
        >
            <Box sx={styles.tabsContainer}>
                <DotLegalTabs fullWidth={false} tabStyle={"secondary"} selectedTab={tab} setSelectedTab={(tab) => setTab(tab)} content={props.tabContent} />
            </Box>

            {props.showMaxElementsExceeded}
        </DotLegalDialog>
    );
}

export default DotLegalTemplateDialog;
