import { useTheme } from "@mui/material";
import { DotLegalInformationBoxProps } from "./DotLegalInformationBox";
import { createSxStyles } from "../../createSxStyles";

export const useDotLegalInformationBoxStyles = (props: DotLegalInformationBoxProps) => {
    const theme = useTheme();
    function getMarginLeft(props: DotLegalInformationBoxProps) {
        if (props.size === "large") {
            return 0;
        }

        if (props.isSelected === "secondarySelected" || props.isSelected === "unselected") {
            return theme.spacing(2.5);
        }

        return 0;
    }

    return createSxStyles({
        informationBox: {
            width: props.fullWidth ? "100%" : props.size === "small" ? 210 + "px" : 280 + "px",
            background:
                props.isSelected === "selected"
                    ? theme.customPalette.collapseBackground
                    : props.isSelected === "secondarySelected"
                      ? theme.customPalette.white
                      : theme.palette.grey["200"],
            borderColor: props.isSelected === "selected" || props.isSelected === "secondarySelected" ? theme.palette.primary.main : theme.palette.grey["400"],
            borderWidth: 2,
            cursor: props.isDisabled ? "default" : "pointer",
            "& .MuiCardContent-root": {
                padding: props.size === "small" ? theme.spacing(1) : theme.spacing(2),
                height: props.size === "small" ? "100%" : 132 + "px",
                minHeight: 42 + "px",
                alignItems: "center",
                display: props.size === "small" ? "flex" : "block",
            },
            "& .MuiCardContent-root:last-child": {
                paddingBottom: props.size === "small" ? theme.spacing(1) : theme.spacing(2),
            },
            opacity: props.isDisabled ? 0.7 : undefined,
            position: "relative",
        },
        wrapper: {
            display: props.size === "small" ? "flex" : "block",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
        },
        logo: {
            "& svg": {
                fill: theme.palette.primary.main,
                width: 16 + "px",
                height: 16 + "px",
            },
            display: "flex",
            marginRight: theme.spacing(0.5),
        },
        headerAndCheck: {
            display: props.isSelected === "selected" || (props.disabledTooltip && props.onDisabledTooltipClick) ? "flex" : "block",
            width: "100%",
            overflow: props.size === "small" ? "hidden" : "unset",
            whiteSpace: props.size === "small" ? "nowrap" : "unset",
        },
        header: {
            marginLeft: getMarginLeft(props),
            fontWeight: theme.typography.fontWeightBold,
            fontSize: theme.typography.pxToRem(12),
            color: props.isSelected === "selected" || props.isSelected === "secondarySelected" ? theme.palette.primary.dark : theme.palette.grey["600"],
            overflow: props.size === "small" ? "hidden" : "unset",
            textOverflow: props.size === "small" ? "ellipsis" : "unset",
        },
        description: {
            fontSize: theme.typography.pxToRem(12),
            lineHeight: 1.6,
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1),
            color: props.isSelected === "selected" || props.isSelected === "secondarySelected" ? theme.palette.primary.dark : theme.palette.grey["600"],
            textOverflow: "ellipsis",
            overflow: "hidden",
            display: "-webkit-box",
            WebkitLineClamp: "4",
            WebkitBoxOrient: "vertical",
        },
        icon: {
            display: "flex",
            marginLeft: theme.spacing(1),
        },
    });
};
