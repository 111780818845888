import { useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";

export const useUsersStyles = () => {
    const theme = useTheme();

    return createSxStyles({
        container: {
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
        },
        switchBoxContainer: {
            marginBottom: theme.spacing(2.5),
            width: 180,
        },
        paperContainer: {
            flex: "1",
            display: "flex",
            flexDirection: "column",
        },
        tableRow: {
            "& svg": {
                height: 14,
            },
        },
        checkIcon: {
            height: "20px !important",
        },
        bold: {
            fontWeight: theme.typography.fontWeightBold,
        },
        iconContainer: {
            display: "flex",
        },
        greenArrowContainer: {
            justifyContent: "flex-end",
            marginRight: 20 + "px",
        },
    });
};
