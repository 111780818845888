import { useExpandedId } from "../../common/hooks/useExpandedId";

export function useRiskAssessmentAnswerCollapseHook() {
    const { getExpandedId, setExpandedId } = useExpandedId();

    const handleCollapseClick = (Id: string) => {
        if (Id) {
            if (getExpandedId() === Id) setExpandedId("");
            else setExpandedId(Id);
        }
    };

    return {
        handleCollapseClick,
        getExpandedId,
    };
}
