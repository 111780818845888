import React from "react";
import { DotLegalCheckbox, DotLegalDialog, DotLegalHeader, DotLegalTextField, useIsOnSmallScreen } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation.ts";
import { useCustomTemplateAddEditDialog } from "./CustomTemplateAddEditDialog.hooks.ts";
import { AnnualWheelActivityTemplateSaveModel } from "../AnnualWheelActivityTemplate/AnnualWheelActivityTemplate.types.ts";
import { useAnnualWheelDialogStyles } from "../annualWheelDialog/AnnualWheelDialog.styles.ts";
import { Box } from "@mui/material";
import AnnualWheelSubTasks from "../AnnualWheelSubTasks.tsx";

// TODO THE Disable transition mellem dialoger
export interface ICustomTemplateAddEditDialogProps {
    templateModel?: AnnualWheelActivityTemplateSaveModel;
    onCancel: () => void;
    onCreated: (customTemplateModel: AnnualWheelActivityTemplateSaveModel) => void;
    onUpdated: () => void;
}

function CustomTemplateAddEditDialog(props: ICustomTemplateAddEditDialogProps) {
    const { onSave, templateModel, setTemplateModel, templateValidation, isEdit } = useCustomTemplateAddEditDialog(props);
    const { translateString } = useTranslation();
    const isUseOnSmallScreen = useIsOnSmallScreen();
    const styles = useAnnualWheelDialogStyles(isUseOnSmallScreen);
    return (
        <DotLegalDialog
            buttonOkText={translateString("create")}
            open
            size={"md"}
            onDialogClose={props.onCancel}
            onOkClick={onSave}
            secondaryButtonText={translateString("cancel")}
            onSecondaryButtonClick={props.onCancel}
            buttonRight
        >
            <Box sx={styles.contentWrapper}>
                <Box sx={styles.formSpacing}>
                    <DotLegalHeader headerStyle="small" marginTop={2} marginBottom={0.5}>
                        {translateString("createActivityName")}
                    </DotLegalHeader>

                    <DotLegalTextField
                        noMargin
                        debounce={false}
                        label=""
                        value={templateModel.name}
                        onChange={(name) => {
                            let tempModel = { ...templateModel };
                            tempModel.name = name;
                            setTemplateModel(tempModel);
                        }}
                        errorText={templateValidation.name}
                    />
                    <DotLegalHeader headerStyle="small" marginTop={4} marginBottom={0.5}>
                        {translateString("createActivityDescription")}
                    </DotLegalHeader>
                    <DotLegalTextField
                        noMargin
                        multiline
                        rows={5}
                        debounce
                        label=""
                        value={templateModel.description}
                        onChange={(description) => {
                            let tempModel = { ...templateModel };
                            tempModel.description = description;
                            setTemplateModel(tempModel);
                        }}
                    />

                    <AnnualWheelSubTasks
                        subTasks={templateModel.subTasks}
                        onChange={(subTasks) => {
                            let tempModel = { ...templateModel };
                            tempModel.subTasks = subTasks;
                            setTemplateModel(tempModel);
                        }}
                    />

                    {!isEdit && (
                        <>
                            <DotLegalHeader
                                marginBottom={0.5}
                                marginTop={4}
                                headerStyle="small"
                                toolTip={translateString("planAnnualWheelActivityImmediatelyDesc")}
                            >
                                {translateString("planAnnualWheelActivityImmediately")}
                            </DotLegalHeader>

                            <Box sx={(theme) => ({ display: "flex", alignItems: "center", marginLeft: "-11px" })}>
                                <DotLegalCheckbox
                                    onChange={(checked) => {
                                        let tempModel = { ...templateModel };
                                        tempModel.planActivity = checked;
                                        setTemplateModel(tempModel);
                                    }}
                                    label={translateString("planNow")}
                                    checked={templateModel.planActivity}
                                ></DotLegalCheckbox>
                            </Box>
                        </>
                    )}
                </Box>
            </Box>
        </DotLegalDialog>
    );
}

export default CustomTemplateAddEditDialog;
