import { useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";

export const useEditTaskDialogStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        outlook: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            color: theme.palette.primary.main,
            fontSize: theme.typography.pxToRem(12),
            "&:hover": {
                textDecoration: "underline",
                cursor: "pointer",
            },
        },
        statusSelector: {
            width: "40%",
        },
        hr: {
            border: `1px solid ${theme.palette.grey["400"]}`,
        },
        text: {
            color: theme.palette.primary.dark,
            fontSize: theme.typography.pxToRem(14),
            whiteSpace: "pre-line",
        },
        alternativeIconContainer: {
            display: "flex",
            alignItems: "center",
        },
        selectorContainer: {
            display: "flex",
        },
        statusContainer: {
            display: "inline-flex",
            width: "100%",
        },
    });
};
