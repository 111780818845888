import { Theme, useTheme } from "@mui/material";
import { IDotLegalCollapse } from "./DotLegalCollapse";
import { createSxStyles } from "../../createSxStyles";

export const collapseMinHeight = 80;
export const useDotLegalCollapseStyles = (props: IDotLegalCollapse) => {
    const theme = useTheme();
    const backgroundColor = getBackGroundColor(props, theme);
    const borderColor = props.error ? theme.palette.error.main : props.collapseStyle === "secondary" ? "transparent" : theme.palette.primary.main;
    return createSxStyles({
        removeBtnContainer: {
            alignSelf: "flex-end",
        },
        collapse: {
            background: backgroundColor,
            borderRadius: `${theme.shape.borderRadius}px`,
            border: "2px solid " + borderColor,
            position: "relative",
            "& .MuiCollapse-wrapper": {
                height: "100%",
            },
            height: "auto",
        },
        collapseChild: {
            position: !props.isExpanded ? "relative" : "initial",
            zIndex: !props.isExpanded ? -1 : 1,
            padding: theme.spacing(3),
            display: "flex",
            alignItems: props.leftAlign ? "" : "center",
            flexDirection: "column",
            marginTop: theme.spacing(-5),
        },
        collapseIcons: {
            cursor: "pointer",
            display: "flex",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
        },
        gridContainer: {
            minHeight: `${collapseMinHeight}px`,
            alignItems: "center",
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            color: getHeaderColor(props.headerColor, theme),
            fontWeight: props.isCollapseNameBold ? theme.typography.fontWeightBold : theme.typography.fontWeightRegular,
        },
        gridContainerCenter: {
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
        },
        gridContainerRight: {
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
        },
        onCollapsed: {
            cursor: "pointer",
        },
        collapseIcon: {
            width: "59px",
            height: "59px",
        },
        skeletonContainer: {
            display: "flex",
            justifyContent: "center",
        },
    });
};

function getBackGroundColor(props: IDotLegalCollapse, theme: Theme) {
    if (props.color) {
        return props.color === "green" ? "#E7FAF8" : "#D7E5F4";
    }

    return !props.isExpanded ? theme.customPalette.collapseBackground : "none";
}

function getHeaderColor(headerColor: string | undefined, theme: Theme) {
    if (headerColor) {
        return headerColor === "primary" ? theme.palette.primary.dark : theme.palette.grey[500];
    }

    return theme.palette.primary.dark;
}
