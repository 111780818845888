import { createElement, useEffect, useState } from "react";
import { InternalAssessmentSaveModel, InternalAssessmentViewModel } from "./SendInternalAssessmentDialog.types";
import { useValidator } from "../../common/hooks/validation";
import { useTranslation } from "../../localization/useTranslation";
import { QuestionnaireSelectableItem } from "../../legalEntity/legalEntityAuditTab/LegalEntityAudit.types";
import { LegalEntitySelectorViewModel } from "../../legalEntity/LegalEntity.types";
import { useMutation, useQuery } from "react-query";
import { get, post } from "../../common/api/apiShared";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { useResponsibleQuery } from "../../user/hooks/useResponsibleQuery";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { Trans } from "react-i18next";
import { IInternalAssessmentDialog } from "./SendInternalAssessmentDialog";
import { LegalEntityContactRow, LegalEntityContactViewModel } from "../../legalEntity/legalEntityContactTab/LegalEntityContactTab.types";

export default function useSendInternalAssessmentHooks(props: IInternalAssessmentDialog) {
    const snackBar = useDotLegalSnackbar();
    const { gdpo, customerName } = useUserContext();
    const { translateString } = useTranslation();

    const [model, setModel] = useState<InternalAssessmentViewModel>(getDefaultModel());
    const [step, setStep] = useState<"first" | "selectGroupEntity" | "send">("first");

    const groupEntitiesQuery = useQuery("groupentities", () => get<Array<LegalEntitySelectorViewModel>>("/LegalEntity/groupentities"));
    const responsibleQuery = useResponsibleQuery(true);

    let selectableGroupEntitiesData = groupEntitiesQuery.data;
    let selectableGroupEntitiesLoading = groupEntitiesQuery.isLoading;

    let selectableResponsiblesData = responsibleQuery.data(undefined) ?? [];
    let selectableresponsiblesLoading = responsibleQuery.isLoading;

    useEffect(() => {
        if (selectableGroupEntitiesData && selectableGroupEntitiesData.length === 1 && !gdpo) {
            internalAssessmentUpdater.onGroupEntityChanged([selectableGroupEntitiesData[0].id]);
        } else if (gdpo) {
            internalAssessmentUpdater.onGroupEntityChanged([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectableGroupEntitiesData]);

    const validation = useValidator<InternalAssessmentViewModel>(
        (validators) => ({
            name: validators.validateNotEmpty((item) => item.name, translateString("assessmentName")),
            questionnaireId: validators.validateNotEmpty((item) => item.questionnaireId, translateString("audit")),
            deadline: validators.validateTodayOrInTheFuture((item) => item.deadline, translateString("deadline")),
            responsibleId: validators.validateNotEmpty((item) => item.responsibleId, translateString("responsible")),
            assigneeId: validators.validateNotEmpty((item) => item.assigneeId, translateString("assignee")),
            groupEntityId: validators.validateNotEmpty(
                (item) => item.groupEntityId,
                "",
                () => gdpo || step !== "selectGroupEntity",
                translateString("oneSenderIsRequired")
            ),
        }),
        [step]
    );

    const internalAssessmentUpdater = {
        onAssessmentNameChange: (name: string) => {
            var temp = { ...model };
            temp.name = name;
            setModel(temp);
        },
        onDescriptionChange: (description: string) => {
            var temp = { ...model };
            temp.description = description;
            setModel(temp);
        },
        onQuestionnaireChange: (questionnaire?: QuestionnaireSelectableItem) => {
            const temp = { ...model! };
            temp.questionnaireId = questionnaire?.id;
            temp.questionnaireName = questionnaire?.name;
            setModel(temp);
        },
        onDateChanged: (date: Date | null) => {
            const temp = { ...model! };
            temp.deadline = date!;
            setModel(temp);
        },
        onResponsibleChanged: (responsible: string | null) => {
            const temp = { ...model! };
            temp.responsibleId = responsible ?? undefined;
            setModel(temp);
        },
        onAssigneeChanged: (contact?: LegalEntityContactRow) => {
            const temp = { ...model! };
            temp.assigneeId = contact?.id;
            temp.assigneeEmail = contact?.email;
            temp.assigneeName = contact?.name;
            setModel(temp);
        },
        onGroupEntityChanged: (groupEntityIds: Array<string>) => {
            const newModel = { ...model! };

            if (groupEntityIds.length === 0) {
                newModel.groupEntityId = undefined;
                newModel.groupEntityName = customerName;
            } else if (groupEntityIds.length === 1) {
                newModel.groupEntityId = groupEntityIds[0];
                newModel.groupEntityName = selectableGroupEntitiesData!.find((x) => x.id === groupEntityIds[0])!.name;
            } else {
                newModel.groupEntityId = groupEntityIds[1];
                newModel.groupEntityName = selectableGroupEntitiesData!.find((x) => x.id === groupEntityIds[1])!.name;
            }

            setModel(newModel);
        },
    };

    const sendMutation = useMutation(sendApi);
    const isSending = sendMutation.isLoading;

    async function send() {
        await sendMutation.mutateAsync(undefined, {
            onSuccess: () => {
                snackBar.show(
                    createElement(Trans, {
                        i18nKey: "legalEntityAuditSentMessage",
                        values: { questionnaireName: model.questionnaireName, contactName: model.assigneeName },
                    })
                );

                props.onInternalAssessmentSend();
            },
        });
    }

    const showSelectedGroupEntityStep = selectableGroupEntitiesData && selectableGroupEntitiesData.length > 1;
    const onNextOrSendClick = async () => {
        if (validation.anyHasErrors) {
            validation.setShowErrors(true);
            return;
        }

        if (step === "first") {
            if (showSelectedGroupEntityStep) {
                setStep("selectGroupEntity");
            } else {
                setStep("send");
            }
        } else if (step === "selectGroupEntity") {
            setStep("send");
        } else {
            await send();
        }
    };

    let onPreviousButtonClick = () => {
        if (step === "send") {
            if (showSelectedGroupEntityStep) {
                setStep("selectGroupEntity");
            } else {
                setStep("first");
            }
        } else {
            setStep("first");
        }
    };

    return {
        model,
        validation,
        internalAssessmentUpdater,
        onNextOrSendClick,
        onPreviousButtonClick,
        step,
        selectableGroupEntitiesData,
        selectableGroupEntitiesLoading,
        selectableResponsiblesData,
        selectableresponsiblesLoading,
        isSending,
    };

    function getDefaultModel() {
        return {
            name: "",
            description: "",
            questionnaireId: "",
            questionnaireName: "",
            responsibleId: "",
            deadline: undefined,
        };
    }

    function sendApi() {
        const saveModel: InternalAssessmentSaveModel = {
            questionnaireId: model.questionnaireId!,
            deadline: model.deadline!,
            questionnaireName: model.questionnaireName!,
            groupEntityId: model.groupEntityId,
            groupEntityName: model.groupEntityName!,
            responsibleId: model.responsibleId!,
            description: model.description,
            name: model.name,
            assigneeId: model.assigneeId!,
            assigneeEmail: model.assigneeEmail!,
            assigneeName: model.assigneeName!,
        };

        return post<{}>("/internalassessments", saveModel);
    }
}
