import { createElement } from "react";
import { Trans } from "react-i18next";
import { useMutation } from "react-query";
import { deleteHttp } from "../../common/api/apiShared";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { TrackingEvent, useTrackAIEvent } from "../../processingActivity/hooks/useTracking";
import { IDeleteAnnualWheelActivityDialogProps } from "./DeleteAnnualWheelActivityDialog";

export function useDeleteAnnualWheelActivity(props: IDeleteAnnualWheelActivityDialogProps) {
    const snackbar = useDotLegalSnackbar();
    const trackEvent = useTrackAIEvent();

    const removeMutation = useMutation(remove);
    const removePermanentlyMutation = useMutation(removePermanently);
    const removeAnnualWheelActivity = (permanently: boolean) => {
        const onSuccess = () => {
            trackEvent(TrackingEvent.AnnualWheelActivityDeleted, { annualWheelActivityType: props.activityName });

            props.onDeleteClick();
            snackbar.show(createElement(Trans, { i18nKey: "dataDeleted", values: { name: props.activityName } }));
        };
        if (permanently) {
            removePermanentlyMutation
                .mutateAsync(undefined, {
                    onSuccess: onSuccess,
                })
                .then();
        } else {
            removeMutation
                .mutateAsync(undefined, {
                    onSuccess: onSuccess,
                })
                .then();
        }
    };

    async function remove() {
        return await deleteHttp<string>(`/annualWheelActivity/${props.activityId}`);
    }

    async function removePermanently() {
        return await deleteHttp<string>(`/annualWheelActivity/${props.activityId}/permanent`);
    }

    return {
        removeAnnualWheelActivity,
        inProgress: removeMutation.isLoading || removePermanentlyMutation.isLoading,
    };
}
