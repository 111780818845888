import { createSxStyles } from "../../common/createSxStyles";

export const useCommentDialogStyle = () => {
    return createSxStyles({
        paper: {
            width: "600px",
            position: "fixed",
            right: 56,
            bottom: 40,
            padding: 2,
        },
        paperContainer: {
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            justifyContent: "space-between",
        },
        skeletons: {
            height: 270,
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
        },
        empty: {
            height: 270,
        },
    });
};
