import { createSxStyles } from "../../common/createSxStyles";

export const useCreateCommentStyle = () => {
    return createSxStyles({
        btnContainer: {
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "4px",
            alignItems: "center",
        },
    });
};
