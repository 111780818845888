import React from "react";
import DotLegalFullScreenSpinner from "../../common/components/dotLegalFullScreenSpinner/DotLegalFullScreenSpinner";
import DotLegalStepper from "../../common/components/dotLegalStepper/DotLegalStepper";
import { useTranslation } from "../../localization/useTranslation";
import { StepperGraphicType } from "../../processingActivity/ProcessingActivity.types";
import { SystemAssociationStep } from "./systemAssociationStep/SystemAssociationStep";
import { SystemStep } from "./systemStep/SystemStep";
import { useSystemHook } from "./SystemEdit.hooks";
import { SystemInformationStep } from "./systemInfomationStep/SystemInformationStep";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { useUrlProvider } from "../../useUrlProvider";
import { DotLegalPageHeader, useHistoryWithReferer } from "@dotlegal/dotlegal-ui-components";
import { Permissions } from "../../auth/userContextProvider/UserContextProvider.types";
import PoliciesStep from "../../processingActivity/policiesStep/PoliciesStep";
import SystemDataAndStorageStep from "./systemDataAndStorageStep/SystemDataAndStorageStep";
import SystemRelationStep from "./systemAssociatedAssetsStep/SystemRelationStep";
import SystemSupplierStep from "./systemSupplierStep/SystemSupplierStep";
import SystemPersonalDataStep from "./systemPersonalDataStep/SystemPersonalDataStep";
import SystemAIStep from "./systemAIStep/SystemAIStep";
import { OperationCriticalityLevelEnum } from "./SystemEdit.types.ts";

function System() {
    const historyWithReferer = useHistoryWithReferer();
    const { translateString } = useTranslation();
    const { getSystemOverviewUrl } = useUrlProvider();

    const {
        saveClicked,
        setSaveClicked,
        getSteps,
        getActiveStep,
        changeStep,
        onSystemChange,
        onSaveSystem,
        validationErrors,
        isLoading,
        system,
        relations,
        relationsIsLoading,
    } = useSystemHook();
    const breadCrumbs = [
        { name: translateString("systems"), link: "/privacy/systems" },
        { name: system?.name, tooltip: system?.description },
    ];

    const { permissions, customerName } = useUserContext();

    let hasDataCreationPermission = () => {
        return permissions.canManageMasterData;
    };

    const activeStep = getActiveStep();
    const steps = getSteps();

    function isStepReadOnly(permissions: Permissions) {
        return !permissions.edit;
    }

    function getStepContent(currentStep: number) {
        switch (currentStep) {
            case 0:
                return {
                    childElement: (
                        <SystemStep
                            systemId={system.id}
                            systemName={system.name}
                            description={system.description}
                            status={system.status}
                            readOnly={isStepReadOnly(permissions.systemDescriptionPermissions.permissions)}
                            onDescriptionChange={async (description) => {
                                const newSystem = { ...system };
                                newSystem.description = description;
                                await onSystemChange(newSystem);
                            }}
                            onSystemNameChange={async (name) => {
                                const newSystem = { ...system };
                                newSystem.name = name;
                                await onSystemChange(newSystem);
                            }}
                            onStatusChange={async (status) => {
                                const newSystem = { ...system };
                                newSystem.status = status;
                                await onSystemChange(newSystem);
                            }}
                            validationErrors={validationErrors}
                        />
                    ),
                    graphicType: { wave: "purpleWave", graphic: "systemEdit", position: "left" } as StepperGraphicType,
                };
            case 1:
                return {
                    childElement: (
                        <SystemAssociationStep
                            canManageMasterData={hasDataCreationPermission()}
                            businessAreas={system.businessAreas}
                            validationErrors={validationErrors}
                            systemType={system.type}
                            responsible={system.responsibleId}
                            onBusinessAreaChange={async (businessAreas) => {
                                const newSystem = { ...system };
                                newSystem.businessAreas = businessAreas;
                                await onSystemChange(newSystem, true);
                            }}
                            onSystemTypeChange={async (systemType) => {
                                const newSystem = { ...system };
                                newSystem.type = systemType;
                                await onSystemChange(newSystem);
                            }}
                            onResponsibleChange={async (responsible) => {
                                const newSystem = { ...system };
                                newSystem.responsibleId = responsible;
                                await onSystemChange(newSystem);
                            }}
                            readOnly={isStepReadOnly(permissions.systemAssociationPermissions.permissions)}
                        />
                    ),
                    graphicType: { wave: "purpleWave", graphic: undefined, position: "right" } as StepperGraphicType,
                };
            case 2:
                return {
                    childElement: (
                        <SystemDataAndStorageStep
                            dataTypes={system.dataTypes}
                            storageType={system.storageType}
                            entityType={system.entityType}
                            supportAccessLocations={system.supportAccessLocations}
                            dataStorageLocations={system.dataStorageLocations}
                            network={system.network}
                            onStorageTypeChange={async (type) => {
                                const newSystem = { ...system };

                                let storageType = undefined;
                                if (type) {
                                    storageType = Number(type);
                                }
                                newSystem.storageType = storageType;

                                await onSystemChange(newSystem);
                            }}
                            onNetworkChange={async (network) => {
                                const newSystem = { ...system };
                                newSystem.network = network;

                                await onSystemChange(newSystem);
                            }}
                            onEntityTypeChange={async (type) => {
                                const newSystem = { ...system };

                                let entityType = undefined;
                                if (type) {
                                    entityType = Number(type);
                                }
                                newSystem.entityType = entityType;

                                await onSystemChange(newSystem);
                            }}
                            onDataTypeChange={async (types) => {
                                const newSystem = { ...system };

                                newSystem.dataTypes = types.map((x) => Number(x));

                                await onSystemChange(newSystem);
                            }}
                            onSupportAccesssLocationChange={async (locations) => {
                                const newSystem = { ...system };
                                newSystem.supportAccessLocations = locations;
                                await onSystemChange(newSystem);
                            }}
                            onDataStorageLocationChange={async (locations) => {
                                const newSystem = { ...system };
                                newSystem.dataStorageLocations = locations;
                                await onSystemChange(newSystem);
                            }}
                            readOnly={isStepReadOnly(permissions.systemDataAndStoragePermissions.permissions)}
                        />
                    ),
                    graphicType: { wave: "purpleWave", graphic: undefined, position: "right" } as StepperGraphicType,
                };
            case 3:
                return {
                    childElement: (
                        <SystemSupplierStep
                            readOnly={isStepReadOnly(permissions.systemDataProcessorPermissions.permissions)}
                            validationErrors={validationErrors}
                            system={system}
                        />
                    ),
                    graphicType: { wave: "greenWave", graphic: undefined, position: "right" } as StepperGraphicType,
                };
            case 4:
                return {
                    childElement: (
                        <SystemInformationStep
                            canManageMasterData={hasDataCreationPermission()}
                            deletionPeriodReason={system.deletionPeriodReason}
                            deletionPeriod={system.deletionPeriodId}
                            securityMeasures={system.securityMeasures}
                            certifications={system.certifications}
                            isCriticalForOperation={system.isCriticalForOperation}
                            criticalityLevel={system.operationCriticalityLevel}
                            onDeletionPeriodChange={async (deletionPeriod) => {
                                const newSystem = { ...system };
                                newSystem.deletionPeriodId = deletionPeriod;

                                if (!deletionPeriod) {
                                    newSystem.deletionPeriodReason = undefined;
                                }

                                await onSystemChange(newSystem);
                            }}
                            onDeletionPeriodReasonChange={async (value) => {
                                const newSystem = { ...system };
                                newSystem.deletionPeriodReason = value;

                                await onSystemChange(newSystem);
                            }}
                            onSecurityMeasureChange={async (securityMeasures) => {
                                const newSystem = { ...system };
                                newSystem.securityMeasures = securityMeasures;

                                await onSystemChange(newSystem);
                            }}
                            onCertificationChange={async (certifications) => {
                                const newSystem = { ...system };
                                newSystem.certifications = certifications;
                                await onSystemChange(newSystem);
                            }}
                            onIsCriticalOperationChange={async (isCritical) => {
                                const newSystem = { ...system };
                                newSystem.isCriticalForOperation = isCritical;
                                if (!isCritical) {
                                    newSystem.operationCriticalityLevel = undefined;
                                } else {
                                    newSystem.operationCriticalityLevel = OperationCriticalityLevelEnum.Moderate;
                                }
                                await onSystemChange(newSystem);
                            }}
                            onOperationCriticalityLevelChange={async (level) => {
                                const newSystem = { ...system };
                                newSystem.operationCriticalityLevel = level;
                                await onSystemChange(newSystem);
                            }}
                            validationErrors={validationErrors}
                            readOnly={isStepReadOnly(permissions.systemInformationPermissions.permissions)}
                        />
                    ),
                    graphicType: { wave: "greenWave", graphic: "systemInfomation", position: "right" } as StepperGraphicType,
                };
            case 5:
                return {
                    childElement: (
                        <SystemAIStep
                            usesAI={system.usesAI}
                            onUsesAIChange={async (usesAI) => {
                                const newSystem = { ...system };
                                newSystem.usesAI = usesAI;

                                if (!usesAI) {
                                    newSystem.companyUsesAIService = false;
                                    newSystem.aiUsageDescription = "";
                                    newSystem.isUseOfAIConsideredHighRisk = false;
                                    newSystem.isDataUsedForTraningOfAIModel = false;
                                }

                                await onSystemChange(newSystem);
                            }}
                            readOnly={isStepReadOnly(permissions.systemAIPermissions.permissions)}
                            companyUsesAIService={system.companyUsesAIService}
                            aiUsageDescription={system.aiUsageDescription}
                            isUseOfAIConsideredHighRisk={system.isUseOfAIConsideredHighRisk}
                            isDataUsedForTrainingOfAIModel={system.isDataUsedForTraningOfAIModel}
                            onCompanyUsesAIServiceChange={async (companyUsesAIService) => {
                                const newSystem = { ...system };
                                newSystem.companyUsesAIService = companyUsesAIService;

                                if (!companyUsesAIService) {
                                    newSystem.aiUsageDescription = "";
                                    newSystem.isUseOfAIConsideredHighRisk = false;
                                    newSystem.isDataUsedForTraningOfAIModel = false;
                                }

                                await onSystemChange(newSystem);
                            }}
                            onDescriptionOfAIUsageChange={async (aIUsageDescription) => {
                                const newSystem = { ...system };
                                newSystem.aiUsageDescription = aIUsageDescription;
                                await onSystemChange(newSystem);
                            }}
                            onIsUseOfAIConsideredHighRiskChange={async (isUseOfAIConsideredHighRisk) => {
                                const newSystem = { ...system };
                                newSystem.isUseOfAIConsideredHighRisk = isUseOfAIConsideredHighRisk;
                                await onSystemChange(newSystem);
                            }}
                            onIsDataUsedForTrainingOfAIModelChange={async (isDataUsedForTraningOfAIModel) => {
                                const newSystem = { ...system };
                                newSystem.isDataUsedForTraningOfAIModel = isDataUsedForTraningOfAIModel;
                                await onSystemChange(newSystem);
                            }}
                        />
                    ),
                };
            case 6:
                return {
                    childElement: (
                        <SystemRelationStep
                            id={system.id}
                            isLoading={relationsIsLoading}
                            relatedSystems={relations ?? []}
                            readOnly={isStepReadOnly(permissions.systemAssociatedAssetsPermissions.permissions)}
                            disableSystemRelations={system.disableSystemRelations}
                            onDisableSystemRelationsChange={async (checked) => {
                                const newSystem = { ...system };
                                newSystem.disableSystemRelations = checked;
                                await onSystemChange(newSystem);
                            }}
                        />
                    ),
                    graphicType: { wave: "blueWave", graphic: undefined, position: "right" } as StepperGraphicType,
                };
            case 7:
                return {
                    childElement: (
                        <PoliciesStep
                            isReadOnly={isStepReadOnly(permissions.systemPolicyPermissions.permissions)}
                            selectedPolicies={system.policies}
                            onChangePolicies={async (policies) => {
                                const newSystemModel = { ...system };
                                newSystemModel.policies = policies !== undefined ? policies : [];
                                await onSystemChange(newSystemModel);
                            }}
                            stepHeader={translateString("systemPolicyHeader")}
                            stepheaderTooltip={translateString("systemPolicyHeaderTooltip")}
                            addPolicyDialogHeader={translateString("addPolicyToSystem")}
                        />
                    ),
                    graphicType: { wave: "blueWave", graphic: "stepEight", position: "right" } as StepperGraphicType,
                };
            case 8:
                return {
                    childElement: <SystemPersonalDataStep systemId={system.id} systemViewModel={system} isLoading={isLoading} />,
                    graphicType: undefined,
                };
            default:
                return {
                    childElement: <div>Unknown stepIndex</div>,
                    graphicType: undefined,
                };
        }
    }

    function getLoadingState() {
        return <DotLegalFullScreenSpinner />;
    }

    const currentIndex = steps.indexOf(steps.find((s) => s.stepType === activeStep.stepType)!);
    return (
        <React.Fragment>
            <DotLegalPageHeader userContext={{ customerName: customerName }} breadCrumbs={breadCrumbs} />
            {isLoading ? (
                <React.Fragment> {getLoadingState()} </React.Fragment>
            ) : (
                <DotLegalStepper
                    steps={steps}
                    activeStep={activeStep.stepType}
                    header={activeStep.name}
                    graphicType={getStepContent(activeStep.stepType).graphicType}
                    inProgess={saveClicked}
                    onStepClick={(step) => changeStep(step)}
                    onSaveClick={async () => {
                        setSaveClicked(true);
                        await onSaveSystem();
                        setSaveClicked(false);
                        historyWithReferer.push(getSystemOverviewUrl(system.id));
                    }}
                >
                    {getStepContent(activeStep.stepType).childElement}
                </DotLegalStepper>
            )}
        </React.Fragment>
    );
}

export default System;
