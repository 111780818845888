import { DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import React from "react";
import Widget from "../Widget.tsx";
import { Box } from "@mui/material";
import { useTranslation } from "../../localization/useTranslation.ts";
import { useTaskOverviewWidget } from "./TaskOverviewWidget.hooks.ts";
import AnnualWheelYearPicker from "../../annualWheel/annualWheelYearPicker/AnnualWheelYearPicker.tsx";
import NavigateTo from "../navigateTo/NavigateTo.tsx";
import { useUrlProvider } from "../../useUrlProvider.ts";
import TaskOverviewGraph from "./taskOverviewGraph/TaskOverviewGraph.tsx";

export interface ITaskOverviewWidgetProps {
    onlyUserSpecific: boolean;
    selectedGroupCompany: string | undefined;
    year?: number;
}

function TaskOverviewWidget(props: ITaskOverviewWidgetProps) {
    const { translateString } = useTranslation();
    const { data, isLoading, isEnabled, year, setYear, highestNumberOfTasksInAMonth } = useTaskOverviewWidget(props);
    const { getTaskManagementUrl } = useUrlProvider();

    return (
        <Widget disabled={!isEnabled}>
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <DotLegalHeader headerStyle={"medium"} marginBottom={0}>
                        {translateString("taskOverviewWidgetHeader").toUpperCase()}
                    </DotLegalHeader>
                    <Box sx={{ marginLeft: "10px" }}>
                        <AnnualWheelYearPicker sx={{ height: "25px" }} showPreviousYear year={year!} onChange={setYear} />
                    </Box>
                </Box>
                <TaskOverviewGraph
                    data={data}
                    highestNumberOfTasksInAMonth={highestNumberOfTasksInAMonth}
                    year={year}
                    isLoading={isLoading}
                    onlyUserSpecific={props.onlyUserSpecific}
                />

                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <NavigateTo color="purple" text={translateString("taskOverviewWidgetGoToTasks")} to={getTaskManagementUrl()} />
                </Box>
            </Box>
        </Widget>
    );
}

export default TaskOverviewWidget;
