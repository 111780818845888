import { useMutation, useQueryClient } from "react-query";
import { put } from "../../common/api/apiShared";
import { useOptimisticUpdate } from "../../common/hooks/useOptimisticUpdate";
import { isNullOrWhitespace } from "../../common/stringOperations";
import { hasFilterData } from "../customListOperations";
import { ContentType } from "../CustomLists.types";
import { ColumnFilterType, EditCustomListViewModel, FilterViewModel } from "../editCustomListDialog/EditCustomListDialog.types";
import { IColumnTypeContextProps } from "./ColumnTypeContext";
import {
    UpdateBooleanFilterModel,
    UpdateDateFilterModel,
    UpdateListFilterModel,
    UpdateNumberFilterModel,
    UpdateTextFilterModel,
} from "./ColumnTypeContext.types";
import { useColumnTypeContextQueryProvider } from "./useColumnTypeContextQueryProvider";

export function useColumnTypeContext(props: IColumnTypeContextProps) {
    const { putOnQueueAndSetQueryData, setQueryData } = useOptimisticUpdate();
    const { getListOptions } = useColumnTypeContextQueryProvider(props.column.type);

    const queryClient = useQueryClient();
    const queryKey = "lists" + props.customListId;
    const model = queryClient.getQueryData<EditCustomListViewModel>(queryKey);

    const updateListFilterMutation = useMutation(updateListFilter);
    const updateTextFilterMutation = useMutation(updateTextFilter);
    const updateNumberFilterMutation = useMutation(updateNumberFilter);
    const updateBooleanFilterMutation = useMutation(updateBooleanFilter);
    const updateDateFilterMutation = useMutation(updateDateFilter);
    const updateHideColumnMutation = useMutation(updateHideColumn);

    async function updateListFilter(model: UpdateListFilterModel) {
        return await put<{}>("/customlists/" + props.customListId + "/updateListFilter/" + props.column.type, model);
    }

    async function updateTextFilter(model: UpdateTextFilterModel) {
        return await put<{}>("/customlists/" + props.customListId + "/updateTextFilter/" + props.column.type, model);
    }

    async function updateNumberFilter(model: UpdateNumberFilterModel) {
        return await put<{}>("/customlists/" + props.customListId + "/updateNumberFilter/" + props.column.type, model);
    }

    async function updateBooleanFilter(model: UpdateBooleanFilterModel) {
        return await put<{}>("/customlists/" + props.customListId + "/updateBooleanFilter/" + props.column.type, model);
    }

    async function updateDateFilter(model: UpdateDateFilterModel) {
        return await put<{}>("/customlists/" + props.customListId + "/updateDateFilter/" + props.column.type, model);
    }

    async function updateHideColumn(bool: boolean) {
        return await put<{}>("/customlists/" + props.customListId + "/updateHideColumn/" + props.column.type, bool);
    }

    async function onCheckBoxUpdate(hideColumn: boolean) {
        const newModel = { ...model };
        const column = newModel.selectedColumns?.find((x) => x.type === props.column.type)!;
        column.hideColumn = hideColumn;

        putOnQueueAndSetQueryData(newModel, queryKey, updateHideColumnMutation.mutateAsync, hideColumn);
    }

    async function onRadioButtonChange(event: React.ChangeEvent<HTMLInputElement>) {
        const filterType = Number((event.target as HTMLInputElement).value);

        const newModel = { ...model };
        const filter = newModel.selectedColumns?.find((x) => x.type === props.column.type)?.filter!;
        filter.filterType = filterType;

        if (setOnlyQueryData(filter)) {
            setQueryData(queryKey, newModel);
        } else {
            switch (props.column.contentType) {
                case ContentType.List:
                    putOnQueueAndSetQueryData(newModel, queryKey, updateListFilterMutation.mutateAsync, {
                        listValues: filter.listValues,
                        filterType: filter.filterType,
                    });
                    break;
                case ContentType.Text:
                    putOnQueueAndSetQueryData(newModel, queryKey, updateTextFilterMutation.mutateAsync, {
                        textValue: filter.textValue!,
                        filterType: filter.filterType,
                    });
                    break;
                case ContentType.Number:
                    putOnQueueAndSetQueryData(newModel, queryKey, updateNumberFilterMutation.mutateAsync, {
                        value: filter.numberValue,
                        filterType: filter.filterType,
                    });
                    break;
                case ContentType.Boolean:
                    putOnQueueAndSetQueryData(newModel, queryKey, updateBooleanFilterMutation.mutateAsync, { filterType: filter.filterType });
                    break;
                case ContentType.Date:
                    putOnQueueAndSetQueryData(newModel, queryKey, updateDateFilterMutation.mutateAsync, {
                        date: filter.date ?? undefined,
                        filterType: filter.filterType,
                    });
                    break;
            }
        }
    }

    async function onListUpdate(data: Array<string>) {
        const { newModel, column, filter } = getFilter();

        if (data.length < 1) {
            filter.filterType = ColumnFilterType.None;
            column!.hideColumn = false;
        } else if (filter.filterType === ColumnFilterType.None) filter.filterType = ColumnFilterType.ListContainsAnyOf;

        filter.listValues = data;
        putOnQueueAndSetQueryData(newModel, queryKey, updateListFilterMutation.mutateAsync, { listValues: data, filterType: filter.filterType });
    }

    async function onTextUpdate(text: string) {
        const { newModel, column, filter } = getFilter();
        filter.textValue = text;

        if (isNullOrWhitespace(text)) {
            filter.filterType = ColumnFilterType.None;
            column!.hideColumn = false;
        } else if (filter.filterType === ColumnFilterType.None) filter.filterType = ColumnFilterType.StringContainsValue;

        putOnQueueAndSetQueryData(newModel, queryKey, updateTextFilterMutation.mutateAsync, { textValue: text, filterType: filter.filterType });
    }

    async function onNumberUpdate(value: string) {
        let number = isNullOrWhitespace(value) ? undefined : Number(value);

        const { newModel, column, filter } = getFilter();
        filter.numberValue = number;

        if (number === undefined) {
            filter.filterType = ColumnFilterType.None;
            column!.hideColumn = false;
        } else if (filter.filterType === ColumnFilterType.None) {
            filter.filterType = ColumnFilterType.NumberIsEqualTo;
        }

        putOnQueueAndSetQueryData(newModel, queryKey, updateNumberFilterMutation.mutateAsync, { value: number, filterType: filter.filterType });
    }

    async function onDateUpdate(date: Date | null) {
        const { newModel, column, filter } = getFilter();
        filter.date = date;

        if (!date) {
            filter.filterType = ColumnFilterType.None;
            column!.hideColumn = false;
        } else if (filter.filterType === ColumnFilterType.None) filter.filterType = ColumnFilterType.DateSpecific;

        putOnQueueAndSetQueryData(newModel, queryKey, updateDateFilterMutation.mutateAsync, { date: date ?? undefined, filterType: filter.filterType });
    }

    return {
        getListOptions,
        onListUpdate,
        onRadioButtonChange,
        onNumberUpdate,
        onDateUpdate,
        onTextUpdate,
        onCheckBoxUpdate,
    };

    function getFilter() {
        const newModel = { ...model };
        const column = newModel.selectedColumns?.find((x) => x.type === props.column.type)!;
        const filter = column.filter!;
        return { newModel, column, filter };
    }

    function setOnlyQueryData(filter: FilterViewModel) {
        return !hasFilterData(filter) && filter.filterType !== ColumnFilterType.BooleanShowAll;
    }
}
