import { useTheme } from "@mui/material";
import { getSpacing, menuIconSize } from "../../privacyTheme";
import { scrollbarWidth } from "../NavMenu/NavMenu.styles";
import { createSxStyles } from "../../common/createSxStyles";

export const drawerWidthExpanded = 290;
export const usePrivacyDrawerStyles = (open: boolean) => {
    const theme = useTheme();
    return createSxStyles({
        drawer: {
            borderRight: 0,
            width: getDrawerWidthCollapsed(),
            flexShrink: 0,
            whiteSpace: "nowrap",
            display: "flex !important",
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            overflow: "hidden",
            "& .MuiDrawer-paperAnchorDockedLeft": {
                border: 0,
            },
            "& .MuiDrawer-paper": {
                width: open ? drawerWidthExpanded : getDrawerWidthCollapsed(),
            },
        },
        drawerExpanded: {
            width: drawerWidthExpanded,
        },
        logo: {
            height: "50px",
            color: theme.palette.primary.main,
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(2),
            marginTop: theme.spacing(3),
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            "& h1": {
                margin: 0,
            },
            "& a": {
                textDecoration: "none",
                "&:visited": {
                    color: "inherit",
                },
                "&:active": {
                    color: "inherit",
                },
            },
            "& svg": {
                width: 200,
            },
        },
        expandButton: {
            backgroundColor: theme.palette.grey["50"],
            borderRadius: "50%",
            width: 30,
            height: 30,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            transition: theme.transitions.create("left", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            "&:hover": {
                borderColor: theme.palette.primary.main,
                cursor: "pointer",
                borderWidth: 1,
                borderStyle: "solid",
            },
            "& svg": {
                width: 14,
                height: 14,
                stroke: theme.palette.primary.main,
            },
        },
    });
};

export function getDrawerWidthCollapsed() {
    return scrollbarWidth + menuIconSize + getSpacing(6);
}
