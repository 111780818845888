import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "../../../localization/useTranslation";
import { dataControllerRecordIcon } from "../../icons";
import { DotLegalInformationTooltip, DotLegalTooltip } from "@dotlegal/dotlegal-ui-components";
import { useDotLegalStepHeaderStyles } from "./DotLegalStepHeader.styles";

export interface IDotLegalStepHeader {
    toolTipText?: string;
    record?: boolean;
    children: any;
    topMargin?: boolean;
    bottomMargin?: boolean;
    subHeader?: string;
}

export default function DotLegalStepHeader(props: IDotLegalStepHeader) {
    const { translateString } = useTranslation();
    const styles = useDotLegalStepHeaderStyles(props);

    return (
        <Box sx={styles.centerHeader}>
            <Box sx={styles.firstContainer}>
                <Box sx={styles.firstTypographyContainer}>
                    <Typography sx={styles.firstHeader}>{props.children}</Typography>
                    <Box sx={styles.toolTipContainer}>
                        {props.toolTipText && <DotLegalInformationTooltip text={props.toolTipText} />}
                        {props.record && (
                            <DotLegalTooltip text={translateString("fieldIncludedInRecord")}>
                                <Box sx={styles.listDescription}>{dataControllerRecordIcon}</Box>
                            </DotLegalTooltip>
                        )}
                    </Box>
                </Box>
                {props.subHeader && <Typography sx={styles.subHeader}>{props.subHeader}</Typography>}
            </Box>
        </Box>
    );
}
