import { createSxStyles } from "../../common/createSxStyles";
import { useTheme } from "@mui/material";

export const useCommentCardStyle = () => {
    const theme = useTheme();
    return createSxStyles({
        paperContainer: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            minHeight: 20,
        },
        content: {
            whiteSpace: "pre-wrap",
            display: "flex",
            alignItems: "center",
            fontSize: theme.typography.pxToRem(12),
            color: theme.palette.primary.dark,
        },
        overflowMenu: {
            marginRight: 0.5,
        },
        headerContanier: {
            display: "flex",
            alignItems: "center",
        },
        badge: {
            marginRight: 1,
            "& .MuiBadge-badge": {
                backgroundColor: "#9e9e9e",
                width: 5,
                minWidth: 5,
                height: 5,
                borderRadius: "50%",
            },
        },
        pinnedComment: {
            backgroundColor: "#F3F3FB",
            borderRadius: "10px",
            padding: 1,
        },
        commentHover: {
            padding: 1,
            borderRadius: "10px",
            "&:hover": {
                backgroundColor: "primary.light",
            },
        },
    });
};
