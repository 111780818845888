import { DotLegalDeleteDialog } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { Trans } from "react-i18next";

export interface IDotLegalDeleteDialogWrapperProps {
    itemForDeletion: string;
    open: boolean;
    onDialogClose: () => void;
    onOkClick: () => void;
    inProgress?: boolean;
    additionalWarning?: string;
}

function DotLegalDeleteDialogWrapper(props: IDotLegalDeleteDialogWrapperProps) {
    const { translateString } = useTranslation();
    return (
        <DotLegalDeleteDialog
            headerText={translateString("delete") + " " + props.itemForDeletion}
            alertBodyText={<Trans i18nKey="deleteConfirmation" values={{ item: props.itemForDeletion }}></Trans>}
            cancelBtnText={translateString("cancel")}
            deleteBtnText={translateString("delete")}
            warningTranslation={translateString("warning")}
            additionalWarning={props.additionalWarning}
            onDialogClose={props.onDialogClose}
            open={props.open}
            onOkClick={props.onOkClick}
            inProgress={props.inProgress}
        />
    );
}

export default DotLegalDeleteDialogWrapper;
