import { DotLegalDeleteDialog } from "@dotlegal/dotlegal-ui-components";
import { Trans } from "react-i18next";
import { Box } from "@mui/material";

import { InternalAssessmentApiRow } from "../InternalAssessments.types";
import { useTranslation } from "../../localization/useTranslation";
import useDeleteInternalAssessmentDialogHooks from "./DeleteInternalAssessmentDialog.hooks";

export interface IDeleteInternalAssessmentDialog {
    onDialogClose: () => void;
    selectedAssessment: InternalAssessmentApiRow;
    onSuccessDelete: () => void;
}

function DeleteInternalAssessmentDialog(props: IDeleteInternalAssessmentDialog) {
    const { translateString } = useTranslation();
    const { removeAssessmentClick, inProgress, confirmDelete, setConfirmDelete } = useDeleteInternalAssessmentDialogHooks(props);

    return (
        <DotLegalDeleteDialog
            open
            headerText={translateString("deleteAudit")}
            alertBodyText={
                <Trans
                    i18nKey={"deleteInternalAssessmentContent"}
                    values={{
                        name: props.selectedAssessment.name.trim(),
                    }}
                    components={{
                        wrapper: (
                            <Box sx={(theme) => ({ color: theme.palette.secondary.main, fontWeight: theme.typography.fontWeightBold })} component="span"></Box>
                        ),
                    }}
                />
            }
            warningTranslation={""}
            deleteBtnText={translateString("delete")}
            cancelBtnText={translateString("cancel")}
            onDialogClose={props.onDialogClose}
            onOkClick={() => removeAssessmentClick()}
            confirmLabel={translateString("deleteInternalAssessment")}
            confirmDelete={confirmDelete}
            confirmDeleteRequired
            setConfirmDelete={setConfirmDelete}
            inProgress={inProgress}
        />
    );
}

export default DeleteInternalAssessmentDialog;
