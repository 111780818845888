import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { get } from "../../../common/api/apiShared";
import { useStateLocalStorage } from "@dotlegal/dotlegal-ui-components";
import { LegislationSaveModel } from "../legislationDialog/LegislationDialog.types";
import { LegislationViewModel } from "./Legislations.types";

export function useLegislationsDataMapping() {
    const queryClient = useQueryClient();
    const [showAll, setShowAll] = useStateLocalStorage("onlyActiveLegislations", false);
    const [showLegislationDialog, setShowLegislationDialog] = useState(false);
    const [showDeleteLegislationDialog, setShowDeleteLegislationDialog] = useState(false);
    const [showChangeLegislationStatusDialog, setShowChangeLegislationStatusDialog] = useState(false);
    const [selectedLegislation, setSelectedLegislation] = useState<LegislationSaveModel | undefined>(undefined);

    const queryPart = `?showAllLegislations=${showAll}`;
    const url = `/legislations${queryPart}`;
    let { isLoading, data } = useQuery(url, () => get<Array<LegislationViewModel>>(url));

    const OnSave = () => {
        queryClient.refetchQueries(url);
    };

    return {
        isLoading,
        data,
        OnSave,
        showAll,
        setShowAll,
        showLegislationDialog,
        setShowLegislationDialog,
        showDeleteLegislationDialog,
        setShowDeleteLegislationDialog,
        selectedLegislation,
        setSelectedLegislation,
        showChangeLegislationStatusDialog,
        setShowChangeLegislationStatusDialog,
    };
}
