import { useQuery } from "react-query";
import { get } from "../../common/api/apiShared";
import { ApplicationFeatures, PlanLimit, PlanType } from "./PlanProvider.types";
import { usePlanContext } from "./PlanProvider";

const ProcessingActivityCountURL = "/plan/processingActivityPlanLimit";
const SystemCountUrl = "/plan/systemPlanLimit";
const PolicyCountUrl = "/plan/policyPlanLimit";
const GdprIncidentLogCountUrl = "/plan/gdprIncidentLogPlanLimit";
const classificationsCountUrl = "/plan/classificationPlanLimit";
const auditCountUrl = "/plan/auditPlanLimit";
const internalAuditCountUrl = "/plan/internalAssessmentPlanLimit";
const contactCountUrl = "/plan/contact";

export function PlanProviderDataMapping() {
    const url = "/plan/";
    let oneHour = 3600000;
    const featureQuery = useQuery(url + "applicationFeatures", () => get<ApplicationFeatures>(url + "applicationFeatures"), { staleTime: oneHour });

    return { data: featureQuery.data, isLoading: featureQuery.isLoading };
}

//Processing Activities
export function GetProcessingActivityPlanLimit(enabled: boolean = true) {
    const { data, refetch } = useQuery(ProcessingActivityCountURL, () => get<PlanLimit>(ProcessingActivityCountURL), { enabled: enabled });
    return { data, refetch };
}

//Systems

export function GetSystemsPlanLimit(enabled: boolean = true) {
    const { data, refetch } = useQuery(SystemCountUrl, () => get<PlanLimit>(SystemCountUrl), { enabled: enabled });

    return { data, refetch };
}

//Policies
export function GetPolicyPlanLimit() {
    const { data, refetch } = useQuery(PolicyCountUrl, () => get<PlanLimit>(PolicyCountUrl));

    return { data, refetch };
}

//Incident logs
export function GetGdprIncidentLogPlanLimit() {
    const { data, refetch } = useQuery(GdprIncidentLogCountUrl, () => get<PlanLimit>(GdprIncidentLogCountUrl));
    return { data, refetch };
}

//Classifications
export const GetLegalEntityClassificationPlanLimit = () => {
    const { isLoading, data, refetch } = useQuery(classificationsCountUrl, () => get<PlanLimit>(classificationsCountUrl));

    return { classificationPlanLimitIsLoading: isLoading, classificationPlanLimitData: data, refetchClassificationPlanLimit: refetch };
};

//Audits
export const GetLegalEntityAuditPlanLimit = () => {
    const { isLoading, data, refetch } = useQuery(auditCountUrl, () => get<PlanLimit>(auditCountUrl));

    return { legalEntityAuditPlanLimitIsLoading: isLoading, legalEntityAuditPlanLimitData: data, refetchLegalEntityAuditPlanLimit: refetch };
};

//Internal assessments
export const GetInternalAssessmentsPlanLimit = () => {
    const { isLoading, data, refetch } = useQuery(internalAuditCountUrl, () => get<PlanLimit>(internalAuditCountUrl));

    return { internalAssessmentPlanLimitIsLoading: isLoading, internalAssessmentPlanLimitData: data, refetchInternalAssessmentPlanLimit: refetch };
};
