import { DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import { Box, Skeleton, Typography, styled } from "@mui/material";
import React from "react";
import { useTranslation } from "../../../localization/useTranslation";

export interface ManagementReportDescriptionProps {
    scoreEnabled: boolean;
    auditName: string;
    isLoading: boolean;
}

const DescriptionText = styled(Typography)(({ theme, fontSize }) => ({
    fontSize: theme.typography.pxToRem(16),
}));

function ManagementReportDescription(props: ManagementReportDescriptionProps) {
    const { translateString } = useTranslation();
    return (
        <Box>
            <DotLegalHeader headerStyle="xxxl">{translateString("managementReportHeader", { auditName: props.auditName })}</DotLegalHeader>

            {props.scoreEnabled ? (
                <DescriptionText>{translateString("managementReportDescriptionWithScore")}</DescriptionText>
            ) : (
                <DescriptionText>{translateString("managementReportDescriptionWithoutScore")}</DescriptionText>
            )}

            <DescriptionText sx={(theme) => ({ fontStyle: "italic", marginTop: 3, fontSize: theme.typography.pxToRem(14) })}>
                {translateString("managementReportDescriptionNotLawFirm")}
            </DescriptionText>
        </Box>
    );
}

export default ManagementReportDescription;
