import { createElement, useState } from "react";
import { Trans } from "react-i18next";
import { useMutation } from "react-query";
import { post, put } from "../../../common/api/apiShared";
import { Result } from "../../../common/api/result";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { isNullOrWhitespace } from "../../../common/stringOperations";
import { useTranslation } from "../../../localization/useTranslation";
import { DeletionPeriodFrequencyType, DeletionPeriodViewModel } from "../deletionPeriods/DeletionPeriods.types";
import { IAddEditDeletionPeriodDialog } from "./AddEditDeletionPeriodDialog";

export function useAddEditDeletionPeriodDialogHooks(props: IAddEditDeletionPeriodDialog) {
    const snackbar = useDotLegalSnackbar();
    const { translateString } = useTranslation();
    const [errors, setErrors] = useState({ nameError: "", deletionPeriodTypeError: "", deletionPeriodFrequencyError: "" });
    const [viewModel, setViewModel] = useState<DeletionPeriodViewModel | undefined>(props.deletionPeriod);

    function save() {
        return post<string>("/ProcessingActivity/deletionperiod/", viewModel);
    }

    function update() {
        return put<string>("/ProcessingActivity/deletionperiod/", viewModel);
    }

    const saveMutation = useMutation(save, {
        onSuccess: (response: Result<string>) => {
            if (response.success()) {
                props.onDialogClose();
                props.refetch();
                props.onOkClick(response.value(), viewModel!.name);
                snackbar.show(createElement(Trans, { i18nKey: "deletionPeriodCreated", values: { name: viewModel?.name } }));
            } else {
                snackbar.show(translateString("deletionPeriodExist"), "warning");
            }
        },
    });

    const editMutation = useMutation(update);
    const updateDeletionPeriod = () => {
        editMutation.mutateAsync(undefined, {
            onSuccess: (response: Result<string>) => {
                if (response.success()) {
                    snackbar.show(translateString("deletionPeriodUpdated", { name: viewModel!.name }));
                    props.onDialogClose();
                    props.refetch();
                } else {
                    snackbar.show(translateString("deletionPeriodExist"), "warning");
                }
            },
        });
    };

    const createDeletionPeriod = async () => {
        await saveMutation.mutateAsync();
    };

    return {
        viewModel,
        setViewModel,
        getDeletionPeriodFrequencyTypeOption,
        validateDeletionPeriodName,
        validateDeletionPeriodForm,
        errors,
        isSaving: editMutation.isLoading || saveMutation.isLoading,
    };

    function getDeletionPeriodFrequencyTypeOption() {
        return [
            { name: translateString("days"), id: DeletionPeriodFrequencyType.Days.toString() },
            { name: translateString("weeks"), id: DeletionPeriodFrequencyType.Weeks.toString() },
            { name: translateString("months"), id: DeletionPeriodFrequencyType.Months.toString() },
            { name: translateString("years"), id: DeletionPeriodFrequencyType.Years.toString() },
        ];
    }

    function validateDeletionPeriodName(name: string) {
        let tempErrors = { ...errors };

        tempErrors.nameError = isNullOrWhitespace(name) ? translateString("fieldMandatory") : "";

        setErrors(tempErrors);
    }

    function validateDeletionPeriodForm() {
        let formValid = true;
        let tempErrors = { nameError: "", deletionPeriodTypeError: "", deletionPeriodFrequencyError: "" };

        if (isNullOrWhitespace(viewModel?.name)) {
            tempErrors.nameError = translateString("fieldMandatory");
            formValid = false;
        }

        if (viewModel?.deletionPeriodType === undefined && viewModel?.deletionPeriodLength) {
            tempErrors.deletionPeriodTypeError = translateString("fieldMandatory");
            formValid = false;
        }

        if ((viewModel?.deletionPeriodLength === undefined || viewModel?.deletionPeriodLength === 0) && viewModel?.deletionPeriodType) {
            tempErrors.deletionPeriodFrequencyError = translateString("fieldMandatory");
            formValid = false;
        }

        if (formValid) {
            if (props.isAdd) createDeletionPeriod();
            else updateDeletionPeriod();
        } else {
            setErrors(tempErrors);
        }
    }
}
