import { useMutation, useQuery } from "react-query";
import { CustomerSaveModel } from "./AddEditCustomer.types";
import { get, post, put } from "../../common/api/apiShared";
import { createElement, useEffect, useState } from "react";
import { DotLegalSelectOption } from "../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { useTranslation } from "../../localization/useTranslation";
import { IAddEditCustomerDialogProps } from "./AddEditCustomerDialog";
import { isNullOrWhitespace } from "../../common/stringOperations";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { Trans } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useUrlProvider } from "../../useUrlProvider";
import { PlanStatus } from "../customers/Customers.types";

export function useDataMapping(props: IAddEditCustomerDialogProps) {
    const snackbar = useDotLegalSnackbar();
    const { translateLanguage, translateString } = useTranslation();
    const navigate = useNavigate();
    const urlProvider = useUrlProvider();
    const { id } = useParams<{ id: string }>();
    const [errors, setErrors] = useState({ customerNameError: "" });
    const [saveModel, setSaveModel] = useState<CustomerSaveModel | undefined>();

    const { isLoading, data } = useQuery(`/customer/${id}`, () => get<CustomerSaveModel>(`/customer/${id}`), { enabled: isEdit() });

    useEffect(() => {
        if (data) {
            setSaveModel(data);
        }
    }, [data]);

    const saveMutation = useMutation(save);
    const createCustomer = async () => {
        await saveMutation.mutateAsync(undefined, {
            onSuccess: () => {
                setSaveModel(undefined);
                if (props.onSave) props.onSave();
                snackbar.show(createElement(Trans, { i18nKey: "customerCreated", values: { name: saveModel?.name } }));
            },
        });
    };

    const editMutation = useMutation(update);
    const updateCustomer = () => {
        editMutation.mutateAsync(saveModel?.id!, {
            onSuccess: () => {
                snackbar.show(createElement(Trans, { i18nKey: "customerUpdated", values: { name: saveModel!.name } }));
                navigate(urlProvider.getCustomersUrl());
            },
        });
    };

    function languages(): Array<DotLegalSelectOption> {
        return [
            { id: "da", name: translateLanguage("da") },
            { id: "en", name: translateLanguage("en") },
        ];
    }

    function validateCustomerName(name: string) {
        let tempErrors = { ...errors };

        tempErrors.customerNameError = isNullOrWhitespace(name) ? translateString("fieldMandatory") : "";

        setErrors(tempErrors);
    }

    function validateCustomerViewModel(name: string) {
        let formValid = true;
        let tempErrors = { ...errors };

        if (isNullOrWhitespace(name)) {
            tempErrors.customerNameError = translateString("fieldMandatory");
            formValid = false;
        }

        if (formValid) {
            if (isEdit()) updateCustomer();
            else createCustomer();
        } else {
            setErrors(tempErrors);
        }
    }

    function update(customerId: string) {
        return put(`/customer/${customerId}/`, saveModel);
    }

    function save() {
        return post("/customer", saveModel);
    }

    function isEdit() {
        return id !== undefined;
    }

    return {
        isLoading,
        inProgress: editMutation.isLoading || saveMutation.isLoading,
        saveModel,
        setSaveModel,
        languages: languages(),
        validateCustomerViewModel,
        validateCustomerName,
        isEdit,
        errors,
    };
}
