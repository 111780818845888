import { createElement } from "react";
import { Trans } from "react-i18next";
import { useMutation } from "react-query";
import { deleteHttp } from "../../../common/api/apiShared";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { IDeleteRiskAssessmentVersionDialog } from "./DeleteRiskAssessmentVersionDialog";

export function useDeleteRiskAssessmentVersionDialogHooks(props: IDeleteRiskAssessmentVersionDialog) {
    const snackbar = useDotLegalSnackbar();

    const removeMutation = useMutation(remove);
    const removeVersions = () => {
        removeMutation.mutateAsync(undefined, {
            onSuccess: () => {
                props.onCloseDialog();

                props.onDeletion();
                snackbar.show(createElement(Trans, { i18nKey: "dataDeleted", values: { name: props.selectedVersion.name } }));
            },
        });
    };

    async function remove() {
        return await deleteHttp<string>(`/riskassesmentversions/${props.selectedVersion.id}`);
    }

    return {
        removePurpose: removeVersions,
        inProgess: removeMutation.isLoading,
    };
}
