import { createSxStyles } from "../../common/createSxStyles";
import { useTheme } from "@mui/material";

export const useAnnualYearPickerStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        paper: {
            display: "flex",
            width: 100 + "px",
            justifyContent: "space-around",
            alignItems: "center",
            "& .MuiSvgIcon-root": {
                height: 18 + "px",
                width: 18 + "px",
                borderRadius: 25 + "px",
                "&:hover": {
                    cursor: "pointer",
                    backgroundColor: theme.palette.grey[200],
                },
            },
        },
        skeleton: {
            width: 100 + "px",
            height: 24 + "px",
            marginLeft: 10 + "px",
            borderRadius: 25 + "px",
        },
    });
};
