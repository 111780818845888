import { useState } from "react";
import { DataTabContentProps } from "./DataTabContent";
import { useQuery } from "react-query";
import { get } from "../../../common/api/apiShared";
import { SystemDataTabContentViewModel } from "./DataTabContent.types";

export function useDataTabContent(props: DataTabContentProps) {
    const [showDataCategoryDialog, setShowDataCategoryDialog] = useState(false);
    const [showDataSubjectsDialog, setShowDataSubjectsDialog] = useState(false);
    const [showSupportOrStorageDialog, setShowSupportOrStorageDialog] = useState({ showDialog: false, data: [] as string[], isSupport: false });

    const queryKey = "systemOverview" + props.systemId + "datatab";
    const infoQuery = useQuery(`${queryKey}`, () => get<SystemDataTabContentViewModel>(`/SystemOverview/${props.systemId}/datatab`));

    return {
        isLoading: infoQuery.isLoading,
        infoData: infoQuery.data,
        showDataCategoryDialog,
        setShowDataCategoryDialog,
        showDataSubjectsDialog,
        setShowDataSubjectsDialog,
        showSupportOrStorageDialog,
        setShowSupportOrStorageDialog,
    };
}
