import React from "react";
import { Navigate } from "react-router-dom";

// THE 2024-05-29 - handles redirect for a few links (less than 10) sent out in emails. Can be safely removed in a few months
function RedirectLegacyExternalQuestionnaireUrls() {
    const pathParts = window.location.pathname.split("/");
    const externalId = pathParts[4];
    const link = `/privacy/questionnaires/external/response-external/${externalId}/introduction`;
    return <Navigate to={link} replace />;
}

export default RedirectLegacyExternalQuestionnaireUrls;
