import { useEffect, useState } from "react";
import { LegalEntityAuditTableOverflowMenuProps } from "./LegalEntityAuditTableOverflowMenu";

export function useLegalEntityAuditOverflowMenu(props: LegalEntityAuditTableOverflowMenuProps) {
    const [showReminderDialog, setShowReminderDialog] = useState(false);
    const [showCloseDialog, setShowCloseDialog] = useState(false);
    const [showReopenDialog, setShowReopenDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showNote, setShowNote] = useState(props.showNote);

    const [showChangeResponsible, setShowChangeResponsible] = useState(false);
    const [showChangeContact, setShowChangeContact] = useState(false);
    const [showEvaluateDialog, setShowEvaluateDialog] = useState(false);
    const [showToggleRemindersDialog, setShowToggleRemindersDialog] = useState(false);
    const [showCopyDialog, setShowCopyDialog] = useState(false);

    // Ensures, that the note dialog can be opened fro
    useEffect(() => {
        setShowNote(props.showNote);
    }, [props.showNote]);

    return {
        showCloseDialog,
        setShowCloseDialog,
        showReopenDialog,
        setShowReopenDialog,
        showDeleteDialog,
        setShowDeleteDialog,
        showReminderDialog,
        setShowReminderDialog,
        showNote,
        setShowNote,
        showChangeResponsible,
        setShowChangeResponsible,
        showChangeContact,
        setShowChangeContact,
        showEvaluateDialog,
        setShowEvaluateDialog,
        showToggleRemindersDialog,
        setShowToggleRemindersDialog,
        showCopyDialog,
        setShowCopyDialog,
    };
}
