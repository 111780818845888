import React from "react";
import { DotLegalTooltip } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { RiskAssessmentType } from "../RiskAssessment.types";
import { useRiskIndicatorStyles } from "./RiskIndicator.styles";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import DotLegalLink from "../../common/components/dotLegalLink/DotLegalLink";
import { Box } from "@mui/material";
import { useRiskIndicator } from "./RiskIndicator.hooks";

export interface IRiskIndicatorProps {
    score: number | undefined | null;
    hideRiskType?: boolean;
    linkHref?: string;
    textSize?: "sm" | "md" | "lg";
    riskScoreTextColor?: "white" | "darkblue" | "lightblue";
    riskIndicatorTextColor?: "white";
    fixedWidthLeftColumn?: boolean;
    setFontWeightNormal?: boolean;
    removeLeftMargin?: boolean;
    riskAssessmentType: RiskAssessmentType;
    isClassification?: boolean;
    scoreType?: RiskAssessmentType;
    showHighRiskWarning?: boolean;
    highRiskWarningTooltip?: string;
}

function RiskIndicator(props: IRiskIndicatorProps) {
    const { translateString } = useTranslation();
    const { getRiskIcon } = useRiskIndicator();
    const styles = useRiskIndicatorStyles(props);

    const getRiskIndicatorName = () => {
        switch (props.riskAssessmentType) {
            case RiskAssessmentType.Consequence:
                return translateString("consequence");
            case RiskAssessmentType.Probability:
                return translateString("probability");
            case RiskAssessmentType.Average:
                return translateString("averageScore");
            case RiskAssessmentType.Classification:
                return translateString("riskClassification");
            case RiskAssessmentType.Risk:
                return translateString("risk");
            default:
                return "";
        }
    };

    const score = formatScore(props?.score, translateString, props.isClassification, props.scoreType ?? props.riskAssessmentType);
    return (
        <Box sx={styles.root}>
            <Box sx={styles.riskIndicator}>{!props.hideRiskType && getRiskIndicatorName()}</Box>
            <Box sx={styles.icon}>{getRiskIcon(props?.score)}</Box>
            {props.linkHref ? (
                <Box sx={{ fontWeight: "bold" }}>
                    <DotLegalLink stopPropagation to={props.linkHref} linkColor="primary-dark">
                        {score}
                    </DotLegalLink>
                </Box>
            ) : (
                <Box sx={styles.riskIndicatorValue}>{score}</Box>
            )}
            {props.showHighRiskWarning && props && props.score! > 3 && (
                <Box sx={styles.warningIcon}>
                    <DotLegalTooltip text={props.highRiskWarningTooltip}>
                        <ReportProblemOutlinedIcon fontSize={"small"} />
                    </DotLegalTooltip>
                </Box>
            )}
        </Box>
    );
}

export default RiskIndicator;

export function formatScore(score: number | undefined | null, translateString: any, isClassification: boolean | undefined, scoreType?: RiskAssessmentType) {
    const isConsequence = scoreType === RiskAssessmentType.Consequence;

    switch (score) {
        case 1:
            return isConsequence ? translateString("fewDisadvantages") : translateString("veryLow");
        case 2:
            return isConsequence ? translateString("disadvantages") : translateString("low");
        case 3:
            return isConsequence ? translateString("significantDisadvantages") : translateString("moderate");
        case 4:
            return isConsequence ? translateString("difficultDisadvantages") : translateString("high");
        case 5:
            return isConsequence ? translateString("IrreparableDisadvantages") : translateString("veryHigh");
        default:
            return isClassification ? translateString("noData") : translateString("notAnswered");
    }
}
