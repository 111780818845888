import { useTheme } from "@mui/material";
import { createSxStyles } from "../../createSxStyles";

export const useDotLegalDragNDropWithControlsStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        box: {
            marginBottom: theme.spacing(2),
        },
        searchContainer: {
            display: "flex",
            justifyContent: "flex-end",
        },
        dragNDropWithControlsContainer: {
            height: "100%",
            display: "flex",
            flexDirection: "column",
        },
        collapseIcons: {
            cursor: "pointer",
            display: "flex",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            width: 20,
            position: "relative",
        },
    });
};
