import React from "react";
import DotLegalDeleteDialogWrapper from "../../common/components/dotLegalDeleteDialog/DotLegalDeleteDialogWrapper";
import { useDeleteTaskHook } from "./DeleteTaskDialog.hooks";
import { useTranslation } from "../../localization/useTranslation";

export interface IDeleteTaskDialog {
    taskId: string;
    taskName: string;
    onCloseDialog: () => void;
    onDeleteClick: () => void;
}

function DeleteTaskDialog(props: IDeleteTaskDialog) {
    const { translateString } = useTranslation();
    const { removeTask, inProgress } = useDeleteTaskHook(props);

    return (
        <DotLegalDeleteDialogWrapper
            open
            inProgress={inProgress}
            itemForDeletion={props.taskName}
            onDialogClose={props.onCloseDialog}
            onOkClick={() => removeTask()}
            additionalWarning={translateString("deleteTaskWarning")}
        />
    );
}

export default DeleteTaskDialog;
