import { useQuery, useQueryClient } from "react-query";
import { ResearchEthicsApprovalReportViewModel } from "./ResearchEthicsApprovalReport.types";
import { IResearchEthicsApprovalReport } from "./ResearchEthicsApprovalReport";
import { get, put } from "../../common/api/apiShared";
import { useOptimisticUpdate } from "../../common/hooks/useOptimisticUpdate";
import { useParams } from "react-router-dom";

export function useResearchEthicsApprovalReportHooks(props: IResearchEthicsApprovalReport) {
    const optimisticUpdate = useOptimisticUpdate();
    const { id } = useParams<{ id: string }>();
    const processingActivityId = id === undefined ? props.processingActivityId! : id;

    const url = `/researchEthicsApprovalReport/${processingActivityId}/`;

    const { isLoading, data, refetch } = useQuery(url, () => get<ResearchEthicsApprovalReportViewModel>(url));
    const queryClient = useQueryClient();

    const reportUpdater = {
        onPhoneChange: (value: string) => {
            let temp = { ...data! };
            temp.phone = value;
            onReportChange(temp);
        },
        onExpectedStartDateChange: (date: Date | null) => {
            let temp = { ...data! };
            temp.expectedStartDate = date ?? undefined;
            onReportChange(temp);
        },
        onExpectedEndDateChange: (date: Date | null) => {
            let temp = { ...data! };
            temp.expectedEndDate = date ?? undefined;
            onReportChange(temp);
        },
        onProjectFinancierChange: (value: string) => {
            let temp = { ...data! };
            temp.projectFinancier = value;
            onReportChange(temp);
        },
        onEthicalApprovalRequiredByChange: (value: string) => {
            let temp = { ...data! };
            temp.ethicalApprovalRequiredBy = value;
            onReportChange(temp);
        },
        onAreUninformedParticipantsPresentChange: (value: number) => {
            let temp = { ...data! };
            temp.areUninformedParticipantsPresent = value;
            onReportChange(temp);
        },
        onInvolvesCheatingOrDeceptionOfParticipantsChange: (value: number) => {
            let temp = { ...data! };
            temp.involvesCheatingOrDeceptionOfParticipants = value;
            onReportChange(temp);
        },
        onIncludesSensitiveTopicsChange: (value: number) => {
            let temp = { ...data! };
            temp.includesSensitiveTopics = value;
            onReportChange(temp);
        },
        onCanCauseStressOrDiscomfortForParticipantsChange: (value: number) => {
            let temp = { ...data! };
            temp.canCauseStressOrDiscomfortForParticipants = value;
            onReportChange(temp);
        },
        onRequiresFrequentTestingOfParticipantsChange: (value: number) => {
            let temp = { ...data! };
            temp.requiresFrequentTestingOfParticipants = value;
            onReportChange(temp);
        },
        onPosesSecurityRiskToResearchersChange: (value: number) => {
            let temp = { ...data! };
            temp.posesSecurityRiskToResearchers = value;
            onReportChange(temp);
        },
        onInvolvesVulnerableParticipantsChange: (value: number) => {
            let temp = { ...data! };
            temp.involvesVulnerableParticipants = value;
            onReportChange(temp);
        },
        onInvolvesParticipantsUnder15OrOver15ButUnableToProvideConsentChange: (value: number) => {
            let temp = { ...data! };
            temp.involvesParticipantsUnder15OrOver15ButUnableToProvideConsent = value;
            onReportChange(temp);
        },
        onInvestigatesIllegalBehaviorOrCriminalActionsChange: (value: number) => {
            let temp = { ...data! };
            temp.investigatesIllegalBehaviorOrCriminalActions = value;
            onReportChange(temp);
        },
        onIsInternationalAndRaisesSpecificEthicalQuestionsChange: (value: number) => {
            let temp = { ...data! };
            temp.isInternationalAndRaisesSpecificEthicalQuestions = value;
            onReportChange(temp);
        },
        onHasHighRiskOfResultsMisuseChange: (value: number) => {
            let temp = { ...data! };
            temp.hasHighRiskOfResultsMisuse = value;
            onReportChange(temp);
        },
        onParticipantsChange: (value: string) => {
            let temp = { ...data! };
            temp.participants = value;
            onReportChange(temp);
        },
        onParticipantsRecruitmentMethodChange: (value: string) => {
            let temp = { ...data! };
            temp.participantsRecruitmentMethod = value;
            onReportChange(temp);
        },
        onParticipantEngagementChange: (value: string) => {
            let temp = { ...data! };
            temp.participantEngagement = value;
            onReportChange(temp);
        },
        onInformedConsentObtainingMethodChange: (value: string) => {
            let temp = { ...data! };
            temp.informedConsentObtainingMethod = value;
            onReportChange(temp);
        },
        onDataGenerationAndStorageChange: (value: string) => {
            let temp = { ...data! };
            temp.dataGenerationAndStorage = value;
            onReportChange(temp);
        },
        onInclusionOrExclusionCriteriaChange: (value: string) => {
            let temp = { ...data! };
            temp.inclusionOrExclusionCriteria = value;
            onReportChange(temp);
        },
        onUsedRegistryDataChange: (value: string) => {
            let temp = { ...data! };
            temp.usedRegistryData = value;
            onReportChange(temp);
        },
        onForeseesGDPRChallengesChange: (value: boolean) => {
            let temp = { ...data! };
            temp.foreseesGDPRChallenges = value;
            onReportChange(temp);
        },
        onOtherInformationRelatedToPersonalDataChange: (value: string) => {
            let temp = { ...data! };
            temp.otherInformationRelatedToPersonalData = value;
            onReportChange(temp);
        },
        onIsCompensationProvidedToResearchSubjectsChange: (value: boolean) => {
            let temp = { ...data! };
            temp.isCompensationProvidedToResearchSubjects = value;
            onReportChange(temp);
        },
        onAmountAndFormOfCompensationChange: (value: string) => {
            let temp = { ...data! };
            temp.amountAndFormOfCompensation = value;
            onReportChange(temp);
        },
        onHarmMinimizationMeasuresChange: (value: string) => {
            let temp = { ...data! };
            temp.harmMinimizationMeasures = value;
            onReportChange(temp);
        },
        onAdditionalEthicalFactorsChange: (value: string) => {
            let temp = { ...data! };
            temp.additionalEthicalFactors = value;
            onReportChange(temp);
        },
        onEthicalPointsOfAttentionChange: (value: string) => {
            let temp = { ...data! };
            temp.ethicalPointsOfAttention = value;
            onReportChange(temp);
        },
        onSignatureChange: (value: string) => {
            let temp = { ...data! };
            temp.signature = value;
            onReportChange(temp);
        },
        onIsLegalBasisEstablishedBeforeProjectCommencesChange: (value: number) => {
            let temp = { ...data! };
            temp.isLegalBasisEstablishedBeforeProjectCommences = value;
            onReportChange(temp);
        },
        onIsDataCollectedAndProcessedSecurelyChange: (value: number) => {
            let temp = { ...data! };
            temp.isDataCollectedAndProcessedSecurely = value;
            onReportChange(temp);
        },
        onIsDataHandedOverToGDPRUnitChange: (value: number) => {
            let temp = { ...data! };
            temp.isDataHandedOverToGDPRUnit = value;
            onReportChange(temp);
        },
        onIsDataTransferredToUnsafeCountriesChange: (value: number) => {
            let temp = { ...data! };
            temp.isDataTransferredToUnsafeCountries = value;
            onReportChange(temp);
        },
        onIsDataUsedOrDisclosedWithoutConsentChange: (value: number) => {
            let temp = { ...data! };
            temp.isDataUsedOrDisclosedWithoutConsent = value;
            onReportChange(temp);
        },
        onAreEmployeesInformedAboutDataHandlingChange: (value: number) => {
            let temp = { ...data! };
            temp.areEmployeesInformedAboutDataHandling = value;
            onReportChange(temp);
        },
        onRemoveDocument: (documentId: string) => {
            let temp = { ...data };

            var index = temp.documents?.findIndex((x) => x.id === documentId)!;
            temp.documents?.splice(index, 1);

            queryClient.setQueryData(url, temp);
        },
    };

    async function onReportChange(report: ResearchEthicsApprovalReportViewModel) {
        await optimisticUpdate.putOnQueueAndSetQueryData(report, url, updateControlProcedure, report);
    }

    async function updateControlProcedure(saveModel: ResearchEthicsApprovalReportViewModel) {
        return await put<ResearchEthicsApprovalReportViewModel>(url, saveModel);
    }

    return { data, isLoading, reportUpdater, refetch, processingActivityId };
}
