import React from "react";
import { DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import { Box, Grid, styled } from "@mui/material";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import { useTranslation } from "../../../localization/useTranslation";
import { ProcessingActivityStatisticsDataMapping } from "../ProcessingActivityStatistics.hooks";
import NewCaseCounter from "../components/NewCaseCounter";
import ContractCounter from "../components/ContractCounter";
import { useProcessingActivityStatisticsStyles } from "../processingActivityStatistics.styles";
import CompletedContractCounter from "../components/CompletedContractCounter";
import CompletedCases from "../components/CompletedCases";
import CaseManagerAverage from "../components/CaseManagerAverage";
import ResearcherAverage from "../components/ResearcherAverage";
import DraftSubmittedToActiveAverage from "../components/DraftSubmittedToActiveAverage";
import TotalNumberOfCases from "../components/TotalNumberOfCases";
import AdvisoryCases from "../components/AdvisoryCases";

function ProcessingActivityStatisticsPdf() {
    const { customerName } = useUserContext();
    const { translateString, translateDate } = useTranslation();
    const { isLoading, data, dateRange, caseManager, researcher, users } = ProcessingActivityStatisticsDataMapping();
    const styles = useProcessingActivityStatisticsStyles();

    const Container = styled(Box)(({ theme }) => ({
        marginBottom: theme.spacing(3),
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    }));

    function getBoxWrapper(children: JSX.Element, key?: number) {
        return (
            <Grid item xs={6} sm={6} md={5} lg={5} xl={5}>
                {children}
            </Grid>
        );
    }

    const fromDateString = dateRange?.fromDate ? translateDate(dateRange.fromDate) : "";
    const toDateString = dateRange?.toDate ? translateDate(dateRange.toDate) : "";

    return (
        <Box>
            {isLoading ? (
                <Box></Box>
            ) : (
                <>
                    <Box
                        sx={(theme) => ({
                            background: theme.palette.primary.light,
                            borderRadius: "20px",
                            padding: 4,
                            marginBottom: 5,
                        })}
                    >
                        <DotLegalHeader headerStyle="large">{customerName}</DotLegalHeader>
                        <DotLegalHeader headerStyle="extraLarge">{translateString("statisticsReport")}</DotLegalHeader>
                        <DotLegalHeader headerStyle="medium">
                            {translateString("dateRange")}: {fromDateString} - {toDateString}
                        </DotLegalHeader>
                        <DotLegalHeader headerStyle="medium">
                            {translateString("caseManager")}: {users?.caseManagers?.find((x) => x.id === caseManager)?.name ?? translateString("all")}
                        </DotLegalHeader>
                        <DotLegalHeader headerStyle="medium">
                            {translateString("researcher")}: {users?.researchers?.find((x) => x.id === researcher)?.name}
                        </DotLegalHeader>
                    </Box>
                    <Box>
                        <Container>
                            <DotLegalHeader marginBottom={1} headerStyle="large">
                                {translateString("closedCasesWithinPeriod", { fromDate: fromDateString, toDate: toDateString })}
                            </DotLegalHeader>
                            <Box sx={{ width: "100%", ...styles.paperContainer }} paddingBottom={2}>
                                <Grid container spacing={4} sx={{ display: "flex", justifyContent: "center" }}>
                                    {getBoxWrapper(<NewCaseCounter newCaseCounter={data?.newCaseCounter} />)}
                                    {getBoxWrapper(<ContractCounter contractCounter={data?.contractCounter} />)}
                                </Grid>
                            </Box>
                        </Container>

                        <Container>
                            <DotLegalHeader marginBottom={1} headerStyle="large">
                                {translateString("closedContractsWithinPeriod", { fromDate: fromDateString, toDate: toDateString })}
                            </DotLegalHeader>
                            <Box sx={{ width: "100%", ...styles.paperContainer }} paddingBottom={2}>
                                <Grid container spacing={4} sx={{ display: "flex", justifyContent: "center" }}>
                                    {getBoxWrapper(<CompletedCases completedCases={data?.completedCases} />)}
                                    {getBoxWrapper(<CompletedContractCounter completedContractCounter={data?.completedContractCounter} />)}
                                </Grid>
                            </Box>
                        </Container>

                        <Container className="page-break-inside">
                            <Box sx={{ whiteSpace: "pre-line" }}>
                                <DotLegalHeader marginBottom={1} headerStyle="large" center>
                                    {translateString("averageProcessingTimesForCaseManager")}
                                </DotLegalHeader>
                            </Box>
                            <Box sx={{ width: "100%", ...styles.paperContainer }} paddingBottom={2}>
                                <Grid container spacing={4} sx={{ display: "flex", justifyContent: "center" }}>
                                    <Grid item xs={4}>
                                        <CaseManagerAverage caseManagerAverage={data?.caseManagerAverage} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <ResearcherAverage researcherAverage={data?.researcherAverage} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <DraftSubmittedToActiveAverage submittedToActiveAverage={data?.submittedToActiveAverage} />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Container>

                        <Container>
                            <DotLegalHeader marginBottom={3} headerStyle="large">
                                {translateString("totalNumberOfCases")}
                            </DotLegalHeader>
                            <Box sx={{ width: "100%", ...styles.paperContainer }} paddingBottom={2}>
                                <Grid container spacing={4} sx={{ display: "flex", justifyContent: "center" }}>
                                    {getBoxWrapper(<TotalNumberOfCases processingActivityCounter={data?.processingActivityCounter} />)}
                                    {getBoxWrapper(<AdvisoryCases advisoryCases={data?.advisoryCases} />)}
                                </Grid>
                            </Box>
                        </Container>
                    </Box>
                </>
            )}
        </Box>
    );
}

export default ProcessingActivityStatisticsPdf;
