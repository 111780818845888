import { isNullOrWhitespace } from "../../common/stringOperations";
import { BaseViewModel } from "../../system/systemOverview/SystemOverview.types";

export interface AnnualWheelActivityTemplateSaveModel {
    name: string;
    description: string;
    subTasks: Array<BaseViewModel>;
    planActivity: boolean;
    id: string;
}

export class AnnualWheelTemplateValidationModel {
    name: string;
    errorMsgRequiredField: string;

    constructor(errorMsgRequiredField: string) {
        this.name = "";
        this.errorMsgRequiredField = errorMsgRequiredField;
    }

    isValid = () => {
        return isNullOrWhitespace(this.name);
    };

    validateName = (name: string) => {
        this.name = isNullOrWhitespace(name) ? this.errorMsgRequiredField : "";
    };
}
