import { useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";

export const useCreateEditLegalEntityDialogStyle = () => {
    const theme = useTheme();
    return createSxStyles({
        bodyContainer: {
            display: "flex",
            paddingLeft: theme.spacing(5),
            paddingRight: theme.spacing(5),
        },
        bodySubContainer: {
            width: "100%",
            padding: theme.spacing(2.5),
        },
    });
};
