import { isNullOrWhitespace } from "../../../common/stringOperations";

export interface NationalLawSaveModel {
    name: string;
    description: string;
    provision: string;
    section: string;
    number: string;
    cf: string;
    legislationId: string;
    active: boolean;
    isCustom?: boolean;
}

export class NationalLawErrorsModel {
    name: string = "";
    provision: string = "";
    legislation: string = "";
    errorMsg: string;

    constructor(errorMsg: string) {
        this.errorMsg = errorMsg;
        // eslint-disable-next-line no-self-assign
        this.validateName = this.validateName;
        // eslint-disable-next-line no-self-assign
        this.validateProvision = this.validateProvision;
        // eslint-disable-next-line no-self-assign
        this.validateLegislation = this.validateLegislation;
    }

    hasErrors = () => {
        return this.name !== "" || this.provision !== "" || this.legislation !== "";
    };

    validateField = (field: string | undefined) => {
        return isNullOrWhitespace(field) ? this.errorMsg : "";
    };

    validateName = (nationalLawName: string | undefined) => {
        this.name = isNullOrWhitespace(nationalLawName) ? this.errorMsg : "";
    };

    validateProvision = (nationalLawProvision: string | undefined) => {
        this.provision = isNullOrWhitespace(nationalLawProvision) ? this.errorMsg : "";
    };

    validateLegislation = (nationalLawLegislation: string | undefined) => {
        this.legislation = isNullOrWhitespace(nationalLawLegislation) ? this.errorMsg : "";
    };
}
