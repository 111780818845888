import { SelectableItem } from "../processingActivity/ProcessingActivity.types";
import { AuditFrequency, DeclarationApprovalOption } from "./declarations/Declarations.types";

export interface DeclarationViewModel {
    id: string;
    name: string;
    description: string | null;
    complianceAreas: Array<SelectableItem>;
    auditDate: Date;
    approval: DeclarationApprovalOption;
    auditFrequency: AuditFrequency;
    auditProgress: number;
    groupEntities: Array<SelectableItem>;
    noOfGroupEntitiesWithoutAccess: number;
    controls: Array<DeclarationControlViewModel>;
    markedAsReadyForRevision: boolean;
    canMarkAsReadyForRevision: boolean;
    canComplete: boolean;
    versions: Array<SelectableItem>;
    isLocked: boolean;
    isApproved: boolean;
}

export interface DeclarationControlViewModel {
    id: string;
    customControlId: string;
    name: string;
    category: SelectableItem;
    responsible: SelectableItem;
    status: DeclarationControlStatusEnum;
    totalTasks: number;
    note: string;
    canApprove: boolean;
    completedTasks: number;
    auditorNote: string;
    auditorDeviationNote: string;
    sampling: boolean;
}

export enum DeclarationControlStatusEnum {
    Planned = 1,
    Ongoing = 2,
    ReadyForApproval = 3,
    ApprovedInternal = 4,
    Completed = 5,
}

export interface DeclarationControlTableModel extends DeclarationControlViewModel {
    categoryString: string;
    responsibleName: string;
    statusString: string;
}
