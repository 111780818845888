import { useTheme } from "@mui/material";
import { createSxStyles } from "../../../common/createSxStyles";
import { ISharingsLegalEntity } from "./sharingsLegalEntity";

export const useSharingsLegalEntityStyles = (props: ISharingsLegalEntity) => {
    const theme = useTheme();
    return createSxStyles({
        container: {
            display: "flex",
            flexDirection: "column",
        },
        descriptionLabel: {
            fontSize: theme.typography.pxToRem(12),
            color: theme.palette.primary.main,
            "&:hover": {
                cursor: "pointer",
                textDecoration: "underline",
            },
        },
        exclamation: {
            position: "absolute",
            right: theme.spacing(-8.125),
            top: theme.spacing(3),
        },
        exclamationIcon: {
            fill: theme.palette.error.main,
        },
        linkLabel: {
            display: "flex",
            justifyContent: "flex-end",
            gap: 2,
        },
    });
};
