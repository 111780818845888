import { AddEditDeclarationCategoryDialogProps } from "./AddEditDeclarationCategoryDialog.tsx";
import { useValidator } from "../../common/hooks/validation";
import { createElement, useState } from "react";
import { DeclarationCategorySaveModel, DeclarationCategoryViewModel } from "../DeclarationCategories.types.ts";
import { useTranslation } from "../../localization/useTranslation.ts";
import { useMutation } from "react-query";
import { post, put } from "../../common/api/apiShared.ts";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { Trans } from "react-i18next";

export default function useAddEditDeclarationCategory(props: AddEditDeclarationCategoryDialogProps) {
    const { translateString } = useTranslation();
    const snackbar = useDotLegalSnackbar();
    const [saveModel, setSaveModel] = useState<DeclarationCategorySaveModel>(getDefaultModel(props.model));

    const validation = useValidator<DeclarationCategorySaveModel>((validators) => ({
        name: validators.validateNotEmpty((item) => item.name, translateString("name")),
    }));

    const isEdit = props.model !== null && props.model !== undefined;

    const addDeclarationMutation = useMutation(createDeclarationApiUrl);
    const editDeclarationMutation = useMutation(editDeclarationApiUrl);
    const addDeclaration = () => {
        addDeclarationMutation.mutateAsync(saveModel!, {
            onSuccess: (response: any) => {
                snackbar.show(createElement(Trans, { i18nKey: "itemCreatedSuccess", values: { name: saveModel?.name } }));
                props.onDialogClose();
            },
        });
    };

    const editDeclaration = () => {
        editDeclarationMutation.mutateAsync(saveModel!, {
            onSuccess: (response: any) => {
                snackbar.show(createElement(Trans, { i18nKey: "itemEditedSuccess", values: { name: saveModel?.name } }));
                props.onDialogClose();
            },
        });
    };

    const save = async () => {
        if (validation.anyHasErrors) {
            validation.setShowErrors(true);
            return;
        }
        if (!isEdit) {
            addDeclaration();
        } else {
            editDeclaration();
        }
    };

    const updater = {
        onNameChange: (name: string) => {
            var temp = { ...saveModel };
            temp.name = name;
            setSaveModel(temp);
        },
        onDescriptionChange: (description: string) => {
            var temp = { ...saveModel };
            temp.description = description;
            setSaveModel(temp);
        },
    };

    function createDeclarationApiUrl(saveModel: DeclarationCategorySaveModel) {
        return post<string>("/declarationCategories/", saveModel);
    }

    function editDeclarationApiUrl(saveModel: DeclarationCategorySaveModel) {
        return put<{}>(`/declarationCategories/${props.model!.id}`, saveModel);
    }

    function getDefaultModel(model: DeclarationCategoryViewModel | undefined): DeclarationCategorySaveModel {
        return {
            name: model?.name ?? "",
            description: model?.description ?? null,
            declarationId: props.declarationId,
        };
    }

    return {
        validation,
        updater,
        saveModel,
        isSaving: addDeclarationMutation.isLoading || editDeclarationMutation.isLoading,
        isEdit,
        save,
    };
}
