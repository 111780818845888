import { useTheme } from "@mui/material";
import { createSxStyles } from "../../../../common/createSxStyles";

export const useSelectableActivityTemplateStyles = (isPlanned: boolean) => {
    const theme = useTheme();
    return createSxStyles({
        activityItem: {
            marginTop: theme.spacing(1),
            height: 75,
            borderRadius: 1,
            display: "flex",
            alignItems: "center",
            "&:hover": {
                backgroundColor: theme.palette.primary.light,
                cursor: "pointer",
            },
        },
        lockedActivityItem: {
            marginTop: theme.spacing(1),
            height: 75,
            borderRadius: 1,
            display: "flex",
            alignItems: "center",
            opacity: 0.38,
        },
        selectedItem: {
            outline: "2px solid " + theme.palette.primary.main,
            marginTop: theme.spacing(1),
            height: 75,
            borderRadius: 1,
            display: "flex",
            alignItems: "center",
            "&:hover": {
                backgroundColor: theme.palette.primary.light,
                cursor: "pointer",
            },
        },
        imgContainer: {
            marginLeft: theme.spacing(1),
            backgroundColor: theme.palette.primary.light,
            height: 50,
            width: 50,
            justifyContent: "center",
            display: "grid",
            alignItems: "center",
            borderRadius: 1,
            marginRight: theme.spacing(2),
            border: isPlanned ? `2px solid ${theme.palette.secondary.main}` : "none",
            "& svg": {
                width: 24,
                height: 24,
            },
        },
        each: {
            marginRight: theme.spacing(1),
        },
        subTasksToolTip: {
            margin: 1.5,
        },
    });
};
