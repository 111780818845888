import React from "react";
import { DotLegalDialog, DotLegalSelect } from "@dotlegal/dotlegal-ui-components";
import { useChangeResponsible } from "./ChangeResponsible.hooks";
import { useTranslation } from "../../../localization/useTranslation";

export interface ChangeResponsiblesProps {
    responsible: string;
    auditId: string;
    onDialogClose: () => void;
    onChangedResponsible: () => void;
}

function ChangeResponsibles(props: ChangeResponsiblesProps) {
    const { translateString } = useTranslation();
    const { data, isLoading, responsible, isChanging, setResponsible, onChangeResponsibleClick } = useChangeResponsible(props);

    return (
        <DotLegalDialog
            size="sm"
            buttonOkText={translateString("change")}
            open
            onDialogClose={props.onDialogClose}
            onOkClick={onChangeResponsibleClick}
            isLoading={isLoading}
            header={translateString("changeResponsible")}
            inProgress={isChanging}
        >
            <DotLegalSelect
                options={data}
                onChange={(responsible) => setResponsible(responsible!)}
                selectedItem={responsible}
                label={translateString("responsibles")}
                noOptionsLabel={translateString("noOptiosn")}
                disableClearable
            />
        </DotLegalDialog>
    );
}

export default ChangeResponsibles;
