import { useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";

export const useTaskStatusPaper = (isSelected?: boolean) => {
    const theme = useTheme();
    return createSxStyles({
        paper: {
            width: "100%",
            paddingBottom: theme.spacing(4),
            border: isSelected ? theme.palette.primary.dark + " solid" : "",
        },
        contentContainer: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
        },
        taskCountContainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginRight: theme.spacing(3),
        },
        iconContainer: {
            display: "flex",
            padding: theme.spacing(1.625),
            borderRadius: 15 + "px",
            backgroundColor: theme.palette.primary.light,
            marginRight: theme.spacing(1.5),
        },
        taskCounter: {
            fontSize: theme.typography.pxToRem(25),
            color: theme.palette.primary.dark,
        },
        percentageContainer: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            width: 310,
        },
        percentageChildren: {
            marginBottom: theme.spacing(-1.625),
        },
    });
};
