import { createElement, useState } from "react";
import { useMutation } from "react-query";
import { post, put } from "../../../common/api/apiShared";
import { Result } from "../../../common/api/result";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { useValidator } from "../../../common/hooks/validation";
import { RiskVersionSaveModel } from "../riskAssessmentVersions/RiskAssessmentVersions.types";
import { IRiskAssessmentVersionDialog } from "./RiskAssessmentVersionDialog";
import { Trans } from "react-i18next";

export function useRiskVersionDialogHooks(props: IRiskAssessmentVersionDialog) {
    const snackbar = useDotLegalSnackbar();
    const { translateString } = useTranslation();
    const [version, setVersion] = useState<RiskVersionSaveModel>(getVersionSaveModel(props.selectedVersion));

    const validation = useValidator<RiskVersionSaveModel>((validators) => ({
        name: validators.validateNotEmpty((item) => item.name, translateString("name")),
    }));

    const editMutation = useMutation(edit);
    const createMutation = useMutation(createPost);

    function edit(doc: RiskVersionSaveModel) {
        return put<string>(`/riskassesmentversions/${version.id!}`, doc);
    }

    function createPost(doc: RiskVersionSaveModel) {
        return post<string>(`/riskassesmentversions?riskAssessmentArea=${props.riskAssessmentArea}&riskComplianceArea=${props.riskComplianceArea}`, {
            id: doc.id,
            name: doc.name,
            description: doc.description,
        });
    }

    const update = () => {
        editMutation.mutateAsync(version!, {
            onSuccess: (response: Result<string>) => {
                if (response.success()) {
                    props.onVersionSaved("");
                    snackbar.show(translateString("riskVersionUpdated"));
                } else {
                    snackbar.show(translateString("riskVersionExists"), "warning");
                }
            },
        });
    };

    const create = () => {
        createMutation.mutateAsync(version!, {
            onSuccess: (response: Result<any>) => {
                if (response.success()) {
                    props.onVersionSaved(response.value().id);
                    snackbar.show(createElement(Trans, { i18nKey: "riskVersionCreated", values: { name: version.name } }));
                } else {
                    snackbar.show(translateString("riskVersionExists"), "warning");
                }
            },
        });
    };

    const onSaveClick = () => {
        if (validation.anyHasErrors) {
            validation.setShowErrors(true);
            return;
        }

        if (props.versionOptions) {
            create();
        } else {
            update();
        }
    };

    const onNameChange = (name: string) => {
        let temp = { ...version };
        temp.name = name;
        setVersion(temp);
    };

    const onDescriptionChange = (description: string) => {
        let temp = { ...version };
        temp.description = description;
        setVersion(temp);
    };

    const onVersionChange = (versionId: string) => {
        let temp = { ...version };
        temp.id = versionId === "null" ? null : versionId;
        setVersion(temp);
    };

    return {
        onSaveClick,
        inProgress: editMutation.isLoading || createMutation.isLoading,
        version,
        onDescriptionChange,
        onNameChange,
        onVersionChange,
        validation,
    };

    function getVersionSaveModel(model?: RiskVersionSaveModel) {
        return {
            id: model?.id ?? null,
            name: model?.name ?? "",
            description: model?.description ?? "",
        };
    }
}
