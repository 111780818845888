import { useTheme } from "@mui/material";
import { createSxStyles } from "../common/createSxStyles";

export const useLegalEntityStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        container: {
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
        },
        editButton: {
            "& svg": {
                height: 25 + "px",
                stroke: theme.palette.primary.main,
                fill: theme.palette.primary.light,
            },
        },
        gridRow: {
            textAlign: "center",
        },
        divider: {
            backgroundColor: "#F0F7FC",
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
            marginLeft: theme.spacing(6),
            marginRight: theme.spacing(6),
        },
        menuItem: {
            color: theme.palette.primary.dark,
            fontSize: "0.875rem",
            fontWeight: theme.typography.fontWeightMedium,
        },
        tableContainer: {
            flex: "1",
            display: "flex",
            flexDirection: "column",
            marginTop: theme.spacing(2),
        },
        legalEntityRiskAssessment: {
            color: `${theme.palette.secondary.main} !Important`,
            fontWeight: theme.typography.fontWeightBold,
            "&:hover": {
                cursor: "pointer",
            },
        },
        gridAlignment: {
            display: "flex",
            justifyContent: "flex-end",
        },
        gridContainer: {
            marginBottom: theme.spacing(1),
            alignItems: "center",
        },
        gridContentAlignment: {
            display: "flex",
            alignItems: "center",
        },
    });
};
