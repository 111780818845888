import { Accordion, AccordionDetails, Box, CircularProgress, Divider, Grid, styled } from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import React from "react";
import {
    DotLegalCheckbox,
    DotLegalDialog,
    DotLegalHeader,
    DotLegalMultiSelect,
    DotLegalPrettyScrollbar,
    DotLegalSearchField,
} from "@dotlegal/dotlegal-ui-components";
import DotLegalInformationBox from "../../common/components/dotLegalInformationBox/DotLegalInformationBox";
import { useIsOnSmallScreen } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { useEditUserRolesHooks } from "./AddEditRoles.hooks";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Permission, PermissionLevel, PermissionType } from "../Roles.types";
import { PermissionTypeContent } from "./AddEditRoles.types";
import { isNullOrWhitespace } from "../../common/stringOperations";

export interface IAddEditUserRoles {
    id: string;
    onDialogClose: () => void;
    onSave: () => void;
}

const Spinnerbox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    minHeight: 50,
}));

const GridItem = styled(Grid)(({ theme }) => ({
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
}));

const HeaderBox = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    paddingLeft: 20 + "px",
    paddingRight: 30 + "px",
}));

const TemplateBox = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    marginLeft: 20 + "px",
    marginRight: 20 + "px",
    ".collapse-header": {
        marginBottom: theme.spacing(2),
    },
    height: "48px",
}));

const SummaryBox = styled(Box)(({ theme }) => ({
    display: "inherit",
    alignItems: "center",
    gap: theme.spacing(1.5),
}));

const ContainerBox = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    marginLeft: 20 + "px",
    marginRight: 20 + "px",
    ".collapse-header": {
        marginBottom: theme.spacing(2),
    },
}));

const BreakBox = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(2.8),
    marginTop: theme.spacing(3),
}));

export default function AddEditRoles(props: IAddEditUserRoles) {
    const { translateString, translatePermissionTypes, translatepermissionTypeToolTips } = useTranslation();

    const {
        isLoading,
        chosenUserRoles,
        ToggleSelectedMasterPermissionUserRoles,
        handleCollapseClick,
        getExpandedId,
        setMasterPermissionLevel,
        setSubPermission,
        getSubPermissionSelectOptions,
        getSelectableValues,
        PermissionListTypeOptions,
        GetSubPermissionChosenOptions,
        searchInput,
        setSearchInput,
    } = useEditUserRolesHooks(props);

    const isOnSmallScreen = useIsOnSmallScreen();

    const getSearchResults = (content: PermissionTypeContent) => {
        let header = translatePermissionTypes(content.masterPermission.type.toString());
        let tooltip = translatepermissionTypeToolTips(content.masterPermission.type.toString());
        if (header.toLowerCase().indexOf(searchInput.toLowerCase()) > -1) {
            return (
                <TemplateBox key={content.masterPermission.type.toString()}>
                    <DotLegalInformationBox
                        size="small"
                        header={header}
                        isDisabled={
                            content.associatedPermissions
                                ? chosenUserRoles?.some((c) => content.associatedPermissions!.includes(c!.masterPermission.type))
                                : false
                        }
                        fullWidth
                        isSelected={chosenUserRoles?.some((d) => d!.masterPermission.type === content.masterPermission.type) ? "selected" : "unselected"}
                        onChange={async (_) => {
                            ToggleSelectedMasterPermissionUserRoles(content);
                        }}
                        description={!isNullOrWhitespace(tooltip) ? tooltip : undefined}
                    />
                </TemplateBox>
            );
        }
    };

    function getPermissionSelectorLabel(masterPermission: Permission) {
        return masterPermission.type === PermissionType.ProcessingActivityManagement || masterPermission.type === PermissionType.SystemManagement
            ? translateString("accessTo")
            : translateString("permissions");
    }

    function getCreateLabel(masterPermission: Permission) {
        return masterPermission.type === PermissionType.CompanyDocumentationManagement ? translateString("upload") : translateString("create");
    }

    return (
        <DotLegalDialog
            size="lg"
            open
            fullHeight
            onDialogClose={props.onDialogClose}
            onOkClick={() => {
                props.onSave();
                props.onDialogClose();
            }}
            buttonOkText={translateString("close")}
        >
            {isLoading ? (
                <Spinnerbox>
                    <CircularProgress />
                </Spinnerbox>
            ) : (
                <React.Fragment>
                    <Grid sx={{ height: "100%" }} container>
                        <GridItem item sm={12} md={6}>
                            <HeaderBox>
                                <DotLegalHeader headerStyle={"medium"}>{translateString("userRolesList")}</DotLegalHeader>
                                <DotLegalSearchField
                                    labelSearch={translateString("search")}
                                    useFullWidth
                                    value={searchInput}
                                    onChange={setSearchInput}
                                    autoFocus
                                />
                            </HeaderBox>
                            <DotLegalPrettyScrollbar>
                                {PermissionListTypeOptions().map((p) => {
                                    const options = getSelectableValues(Number(p.id))
                                        .sort((a, b) => a.order - b.order)
                                        .map((x) => {
                                            return getSearchResults(x);
                                        })
                                        .filter((x) => x !== undefined);

                                    if (options.length > 0) {
                                        return (
                                            <React.Fragment key={p.id}>
                                                <HeaderBox>
                                                    <DotLegalHeader headerStyle={"small"}>{p.name}</DotLegalHeader>
                                                </HeaderBox>
                                                {options.map((permission) => {
                                                    return <React.Fragment key={permission!.key}>{permission}</React.Fragment>;
                                                })}
                                            </React.Fragment>
                                        );
                                    }
                                    return <></>;
                                })}
                            </DotLegalPrettyScrollbar>
                        </GridItem>

                        {!isOnSmallScreen && <Divider sx={{ marginRight: -1 }} orientation="vertical" flexItem />}

                        <GridItem item sm={12} md={6}>
                            <HeaderBox>
                                <DotLegalHeader headerStyle={"medium"}>{translateString("selectedUserRoles")}</DotLegalHeader>
                                <BreakBox>
                                    <br />
                                </BreakBox>
                            </HeaderBox>
                            <DotLegalPrettyScrollbar fullHeight>
                                {chosenUserRoles &&
                                    PermissionListTypeOptions().map((p) => {
                                        return (
                                            <React.Fragment key={p.id.toString()}>
                                                {chosenUserRoles.some((x) => x!.type.toString() === p.id) && (
                                                    <HeaderBox>
                                                        <DotLegalHeader headerStyle={"small"}>{p.name}</DotLegalHeader>
                                                    </HeaderBox>
                                                )}
                                                <React.Fragment>
                                                    {chosenUserRoles
                                                        .filter((x) => x!.type.toString() === p.id)
                                                        .sort((a, b) => a!.order - b!.order)
                                                        .map((x) => {
                                                            var isExpandable = x?.masterPermission.level || x?.subPermissions;
                                                            return (
                                                                <ContainerBox key={x!.masterPermission.type.toString()}>
                                                                    <Accordion
                                                                        TransitionProps={{ unmountOnExit: true }}
                                                                        expanded={
                                                                            isExpandable ? x?.masterPermission.type.toString() === getExpandedId() : undefined
                                                                        }
                                                                        onChange={() => handleCollapseClick(x!.masterPermission.type.toString())}
                                                                    >
                                                                        <AccordionSummary
                                                                            disabled={!isExpandable}
                                                                            expandIcon={isExpandable ? <ExpandMoreIcon /> : undefined}
                                                                        >
                                                                            <Box sx={{ display: "flex", justifyContent: "space-between", width: "95%" }}>
                                                                                <SummaryBox>
                                                                                    <DotLegalHeader marginBottom={0} headerStyle={"extraSmall"}>
                                                                                        {translatePermissionTypes(x!.masterPermission.type.toString())}
                                                                                    </DotLegalHeader>
                                                                                </SummaryBox>
                                                                            </Box>
                                                                        </AccordionSummary>
                                                                        {isExpandable && (
                                                                            <AccordionDetails>
                                                                                {x?.masterPermission.level && (
                                                                                    <React.Fragment>
                                                                                        <Grid container>
                                                                                            {x?.accessLevel.includes(PermissionLevel.Read) && (
                                                                                                <Grid>
                                                                                                    <DotLegalCheckbox
                                                                                                        label={translateString("read")}
                                                                                                        checked={x?.masterPermission.level > 0}
                                                                                                        disabled
                                                                                                        onChange={async (level) => {
                                                                                                            setMasterPermissionLevel(
                                                                                                                Number(PermissionLevel.Read),
                                                                                                                x!.masterPermission.type,
                                                                                                                level
                                                                                                            );
                                                                                                        }}
                                                                                                    ></DotLegalCheckbox>
                                                                                                </Grid>
                                                                                            )}
                                                                                            {x?.accessLevel.includes(PermissionLevel.Edit) && (
                                                                                                <Grid>
                                                                                                    <DotLegalCheckbox
                                                                                                        label={translateString("edit")}
                                                                                                        checked={x?.masterPermission.level >= 2}
                                                                                                        disabled={
                                                                                                            x?.masterPermission.level > 2 ||
                                                                                                            x?.disableAccessLevels
                                                                                                        }
                                                                                                        onChange={async (level) => {
                                                                                                            setMasterPermissionLevel(
                                                                                                                Number(PermissionLevel.Edit),
                                                                                                                x!.masterPermission.type,
                                                                                                                level
                                                                                                            );
                                                                                                        }}
                                                                                                    ></DotLegalCheckbox>
                                                                                                </Grid>
                                                                                            )}
                                                                                            {x?.accessLevel.includes(PermissionLevel.Create) && (
                                                                                                <Grid>
                                                                                                    <DotLegalCheckbox
                                                                                                        label={getCreateLabel(x.masterPermission)}
                                                                                                        checked={x?.masterPermission.level >= 3}
                                                                                                        disabled={
                                                                                                            x?.masterPermission.level > 3 ||
                                                                                                            x?.disableAccessLevels
                                                                                                        }
                                                                                                        onChange={async (level) => {
                                                                                                            setMasterPermissionLevel(
                                                                                                                Number(PermissionLevel.Create),
                                                                                                                x!.masterPermission.type,
                                                                                                                level
                                                                                                            );
                                                                                                        }}
                                                                                                    ></DotLegalCheckbox>
                                                                                                </Grid>
                                                                                            )}
                                                                                            {x?.accessLevel.includes(PermissionLevel.Delete) && (
                                                                                                <Grid>
                                                                                                    <DotLegalCheckbox
                                                                                                        label={translateString("delete")}
                                                                                                        disabled={x?.disableAccessLevels}
                                                                                                        checked={x?.masterPermission.level === 4}
                                                                                                        onChange={async (level) => {
                                                                                                            setMasterPermissionLevel(
                                                                                                                Number(PermissionLevel.Delete),
                                                                                                                x!.masterPermission.type,
                                                                                                                level
                                                                                                            );
                                                                                                        }}
                                                                                                    ></DotLegalCheckbox>
                                                                                                </Grid>
                                                                                            )}
                                                                                        </Grid>
                                                                                    </React.Fragment>
                                                                                )}
                                                                                {x?.subPermissions && (
                                                                                    <DotLegalMultiSelect
                                                                                        options={getSubPermissionSelectOptions(x!.subPermissions!)}
                                                                                        chosenOptions={GetSubPermissionChosenOptions(x!.subPermissions!)}
                                                                                        onChange={(values) => {
                                                                                            setSubPermission(x!, values);
                                                                                        }}
                                                                                        placeholder={getPermissionSelectorLabel(x.masterPermission)}
                                                                                        label={getPermissionSelectorLabel(x.masterPermission)}
                                                                                        noOptionsLabel={translateString("noOptions")}
                                                                                    />
                                                                                )}
                                                                            </AccordionDetails>
                                                                        )}
                                                                    </Accordion>
                                                                </ContainerBox>
                                                            );
                                                        })}
                                                </React.Fragment>
                                            </React.Fragment>
                                        );
                                    })}
                            </DotLegalPrettyScrollbar>
                        </GridItem>
                    </Grid>
                </React.Fragment>
            )}
        </DotLegalDialog>
    );
}
