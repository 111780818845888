export interface LegalEntityContactViewModel {
    isMaxNumberOfContactsReached: boolean;
    rows: Array<LegalEntityContactRow>;
}
export interface LegalEntityContactRow {
    id: string;
    name: string;
    email: string;
    phoneNumber: string;
    countryCode: string;
    primaryContact: boolean;
    contactTypes: Array<LegalEntityContactType>;
}

export interface LegalEntityContactSaveModel {
    name: string;
    email: string;
    countryCode?: string;
    phoneNumber?: string;
    primaryContact: boolean;
    contactTypes: Array<LegalEntityContactType>;
}

export enum LegalEntityContactType {
    Dpo = 1,
    GDPRContact = 2,
    GeneralContactPerson = 3,
    ITSecurityContact = 4,
    SecurityBreachContact = 5,
    DataSubjectContact = 6,
}
