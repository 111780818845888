import { useQueryClient } from "react-query";
import { ProcessingActivityModel } from "../ProcessingActivity.types";

export function useUpdateProcessingActivityModel(processingActivityId: string) {
    const queryClient = useQueryClient();
    return {
        update: function (updateFunc: (model: ProcessingActivityModel) => void): ProcessingActivityModel {
            let queryKey = "processingActivity" + processingActivityId;
            const model = queryClient.getQueryData<ProcessingActivityModel>(queryKey);
            const newProcessingActivityModel = { ...model! };
            updateFunc(newProcessingActivityModel);
            return newProcessingActivityModel;
        },
    };
}
