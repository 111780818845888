import { Box, Grid } from "@mui/material";
import React from "react";
import { useTwoColumnContainerStyles } from "../TwoColumnContainer.styles";
import { useLeftColumnStyles } from "./LeftColumn.styles";

export interface LeftColumnProps {
    children: React.ReactNode;
}

function LeftColumn(props: LeftColumnProps) {
    const twoColumnContainerStyles = useTwoColumnContainerStyles();
    const styles = useLeftColumnStyles();
    return (
        <Grid item md={6} sx={twoColumnContainerStyles.grid}>
            <Box sx={[twoColumnContainerStyles.commonContainer, styles.leftContainer]}>{props.children}</Box>
        </Grid>
    );
}

export default LeftColumn;
