import { useMutation, useQuery, useQueryClient } from "react-query";
import { useOptimisticUpdate } from "../../../common/hooks/useOptimisticUpdate";
import { useEffect, useState } from "react";
import { TaskCommentsProps } from "./TaskComments";
import { deleteHttp, get, post, put } from "../../../common/api/apiShared";
import { useCreateCommentDataMapping } from "../../../comment/createEditComment/CreateEditComment.hooks";
import { Result } from "../../../common/api/result";
import { CommentUpdateModel } from "../../../comment/createEditComment/CreateEditComment.types";
import { usePlanContext } from "../../../auth/planProvider/PlanProvider";
import { TaskCommentApiModel, TaskCommentApiModelContent } from "../../../comment/commentCard/CommentCard.types";

export function useTaskComments(props: TaskCommentsProps) {
    const queryClient = useQueryClient();
    const { putOnQueueAndSetQueryData } = useOptimisticUpdate();
    const [createdCommentId, setCreatedCommentId] = useState("");
    const [tab, setTab] = useState(1);
    const [showNewestFirst, setShowNewestFirst] = useState(true);
    const { hasPremium } = usePlanContext();

    const url = `/TaskComment/${props.taskId}/comments`;
    const historyUrl = `/TaskComment/${props.taskId}/history`;
    const { isLoading, data, isSuccess } = useQuery(url, () => get<Array<TaskCommentApiModel>>(url), { enabled: hasPremium });
    const { isLoading: historicCommentsLoading, data: historicComments } = useQuery(historyUrl, () => get<Array<TaskCommentApiModel>>(historyUrl), {
        enabled: hasPremium && tab === 2,
    });

    useEffect(() => {
        if (props.passIsDoneLoading && (isSuccess || !hasPremium)) {
            props.passIsDoneLoading(true);
        }
    }, [props, isSuccess, hasPremium]);

    const { onSubscriptionChange, subscriptionSaving, subscribed } = useCreateCommentDataMapping(
        `/TaskComment/${props.taskId}/comments/subscription`,
        `/TaskComment/${props.taskId}/comments/subscription`,
        !hasPremium
    );

    const createMutation = useMutation(created, {
        onSuccess: (response: Result<TaskCommentApiModel>) => {
            const responseModel = response.value();
            data?.push(responseModel);
            queryClient.setQueryData(url, data);
            setCreatedCommentId(responseModel.id);
        },
    });

    function created(content: string) {
        return post<TaskCommentApiModel>(`/TaskComment/${props.taskId}/comments`, { content: content });
    }

    const createSubCommentMutation = useMutation(createSubComment);

    function createSubComment(data: { commentId: string; content: string }) {
        return post<TaskCommentApiModelContent>(`/TaskComment/${data.commentId}/subComments`, { content: data.content });
    }

    const onCreated = (content: string) => {
        createMutation.mutateAsync(content);
    };

    const onSubCommentCreated = (commentId: string, content: string) => {
        createSubCommentMutation.mutateAsync(
            { commentId: commentId, content: content },
            {
                onSuccess: (response: Result<TaskCommentApiModelContent>) => {
                    const responseModel = response.value();
                    var comment = data?.find((x) => x.id === commentId);
                    comment?.subComments.push(responseModel);
                    queryClient.setQueryData(url, data);
                    setCreatedCommentId(responseModel.id);
                    if (comment?.pinned) {
                        let tempHistoric: Array<TaskCommentApiModel> | undefined = historicComments ? [...historicComments] : undefined;

                        if (tempHistoric) {
                            let historicComment = tempHistoric!.find((x) => x.id === comment?.id)!;
                            historicComment.subComments.push(responseModel);
                            queryClient.setQueryData(historyUrl, data);
                        }
                    }
                },
            }
        );
    };

    const deleteCommentMutation = useMutation(deleteComment);

    async function onDeleteComment(id: string) {
        let tempComments = [...data!];
        let tempHistoric: Array<TaskCommentApiModel> | undefined = historicComments ? [...historicComments] : undefined;
        tempComments = tempComments.filter((x) => x.id !== id);

        putOnQueueAndSetQueryData(tempComments, url, deleteCommentMutation.mutateAsync, id);

        if (tempHistoric !== undefined) {
            tempHistoric = tempHistoric?.filter((x) => x.id !== id);
            queryClient.setQueryData(historyUrl, tempHistoric);
        }
    }

    async function deleteComment(id: string) {
        return await deleteHttp<string>("/TaskComment/" + props.taskId + "/comments/" + id);
    }

    const deleteSubCommentMutation = useMutation(deleteSubComment);

    async function onDeleteSubComment(commentId: string, subCommentId: string) {
        let tempComments = [...data!];
        let tempHistoric: Array<TaskCommentApiModel> | undefined = historicComments ? [...historicComments] : undefined;

        let comment = tempComments.find((x) => x.id === commentId)!;
        let historicComment = tempHistoric?.find((x) => x.id === commentId)!;

        comment.subComments = comment.subComments.filter((x) => x.id !== subCommentId);
        if (historicComment) {
            historicComment.subComments = comment.subComments.filter((x) => x.id !== subCommentId);
            queryClient.setQueryData(historyUrl, tempHistoric);
        }

        putOnQueueAndSetQueryData(tempComments, url, deleteSubCommentMutation.mutateAsync, { commentId: commentId, subCommentId: subCommentId });
    }

    async function deleteSubComment(data: { commentId: string; subCommentId: string }) {
        return await deleteHttp<string>("/TaskComment/" + data.commentId + "/subcomments/" + data.subCommentId);
    }

    const updateCommentMutation = useMutation(updateComment);

    async function onUpdateComment(model: CommentUpdateModel) {
        let tempComments = [...data!];
        let tempHistoric: Array<TaskCommentApiModel> | undefined = historicComments ? [...historicComments] : undefined;

        let comment = tempComments.find((x) => x.id === model.commentId)!;
        let historicComment = tempHistoric?.find((x) => x.id === model.commentId)!;
        comment.content = model.content;
        comment.isEdited = true;

        if (historicComment) {
            historicComment.content = model.content;
            historicComment.isEdited = true;
            queryClient.setQueryData(historyUrl, tempHistoric);
        }

        putOnQueueAndSetQueryData(tempComments, url, updateCommentMutation.mutateAsync, model);
    }

    async function updateComment(model: CommentUpdateModel) {
        return await put<{}>("/TaskComment/" + props.taskId + "/comments/" + model.commentId, { content: model.content, pinned: model.pinned });
    }

    const updateSubCommentMutation = useMutation(updateSubComment);

    async function onUpdateSubComment(model: CommentUpdateModel) {
        let tempComments = [...data!];
        let tempHistoric: Array<TaskCommentApiModel> | undefined = historicComments ? [...historicComments] : undefined;

        let comment = tempComments.find((x) => x.id === model.commentId)!;
        let historicComment = tempHistoric?.find((x) => x.id === model.commentId)!;

        let subcomment = comment.subComments.find((x) => x.id === model.subCommentId)!;
        subcomment.content = model.content;
        subcomment.isEdited = true;

        if (historicComment) {
            let subcomment = historicComment.subComments.find((x) => x.id === model.subCommentId)!;
            subcomment.content = model.content;
            subcomment.isEdited = true;
            queryClient.setQueryData(historyUrl, tempHistoric);
        }

        putOnQueueAndSetQueryData(tempComments, url, updateSubCommentMutation.mutateAsync, model);
    }

    async function updateSubComment(model: CommentUpdateModel) {
        return await put<{}>("/TaskComment/" + model.commentId + "/subcomments/" + model.subCommentId, { content: model.content });
    }

    const onPinClick = (isPinned: boolean, commentId: string) => {
        let tempComments = [...data!];
        let tempHistoric: Array<TaskCommentApiModel> | undefined = historicComments ? [...historicComments] : undefined;

        let comment = tempComments.find((x) => x.id === commentId)!;
        let historicComment = tempHistoric?.find((x) => x.id === commentId)!;
        comment.pinned = isPinned;
        comment.deadline = props.deadline;
        comment.taskId = props.taskId;

        if (historicComment) {
            tempHistoric = tempHistoric!.filter((comment) => comment.id !== commentId);
            queryClient.setQueryData(historyUrl, tempHistoric);
        }

        putOnQueueAndSetQueryData(tempComments, url, pinCommentMutation.mutateAsync, {
            commentId: commentId,
            isPinned: isPinned,
        });
    };

    const pinCommentMutation = useMutation(pinComment);

    async function pinComment(data: { commentId: string; isPinned: boolean }) {
        return await put<{}>("/TaskComment/" + data.commentId + "/comments/pinned", data.isPinned);
    }

    return {
        isLoading,
        commentsViewModel: data,
        historicCommentsLoading,
        historicComments,
        onDeleteComment,
        onDeleteSubComment,
        onCreated,
        onUpdateComment,
        setCreatedCommentId,
        createdCommentId,
        onSubCommentCreated,
        onUpdateSubComment,
        onSubscriptionChange,
        subscriptionSaving,
        subscribed,
        tab,
        setTab,
        showNewestFirst,
        setShowNewestFirst,
        hasPremium,
        onPinClick,
    };
}
