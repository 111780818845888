import React from "react";
import { TagViewModel } from "../Tags.types";
import DotLegalDeleteDialogWrapper from "../../../common/components/dotLegalDeleteDialog/DotLegalDeleteDialogWrapper";
import { useDeleteTagDialog } from "./DeleteTagDialog.hooks";
import { useTranslation } from "../../../localization/useTranslation";

export interface DeleteTagDialogProps {
    onCloseDialog: () => void;
    onDeleted: () => void;
    selectedTag: TagViewModel;
}

function DeleteTagDialog(props: DeleteTagDialogProps) {
    const { translateString } = useTranslation();
    const { onDeleteClick, inProgress } = useDeleteTagDialog(props);

    return (
        <DotLegalDeleteDialogWrapper
            open
            itemForDeletion={props.selectedTag.name}
            onDialogClose={props.onCloseDialog}
            onOkClick={onDeleteClick}
            inProgress={inProgress}
            additionalWarning={translateString("deleteTagInfoText")}
        />
    );
}

export default DeleteTagDialog;
