import { useQuery } from "react-query";
import { LegalEntityClassificationApiModel } from "../../../legalEntity/legalEntityClassificationTab/LegalEntityClassificationTab.types";
import { get } from "../../../common/api/apiShared";
import { ISystemClassificationTabProps } from "./SystemClassificationTab";

export default function useSystemClassificationTabHooks(props: ISystemClassificationTabProps) {
    const url = `/SystemOverview/${props.systemId}/classifications`;
    let { isLoading, data, refetch } = useQuery(url, () => get<LegalEntityClassificationApiModel>(url));
    return {
        isLoading,
        data,
        refetch,
    };
}
