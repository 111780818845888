import { useQuery } from "react-query";
import { get } from "../../../common/api/apiShared";
import { CertificationViewModel } from "./Certifications.types";
import { useState } from "react";

export function useCertificationsHooks() {
    const [showDialog, setShowDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedCertificaion, setSelectedCertification] = useState<CertificationViewModel | undefined>(undefined);

    const url = "/certifications";
    let { isLoading, data, refetch } = useQuery(url, () => get<Array<CertificationViewModel>>(url));

    const onDialogClose = () => {
        setShowDialog(false);
        setSelectedCertification(undefined);
    };

    const onDeleteDialogClose = () => {
        setShowDeleteDialog(false);
        setSelectedCertification(undefined);
    };

    return {
        isLoading,
        data,
        showDialog,
        setShowDialog,
        refetch,
        selectedCertificaion,
        setSelectedCertification,
        showDeleteDialog,
        setShowDeleteDialog,
        onDialogClose,
        onDeleteDialogClose,
    };
}
