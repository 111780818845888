import { useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";

export const useProcessingActityRiskMatrixStyle = (isSmallScreen: boolean, isPdf: boolean | undefined) => {
    const theme = useTheme();

    let leftLegendWidth = 50;
    let riskPaperWidth = isSmallScreen ? 150 : 201;
    let riskPaperHeight = isPdf ? 92 : 105;

    let skeletonHeight = riskPaperHeight * 5;
    let skeletonWidth = riskPaperWidth * 5;

    let bottomContainerHeight = isSmallScreen ? 120 : 105;
    return createSxStyles({
        wrapper: {
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
        },
        topRow: {
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
        },
        xAxisContainer: {
            display: "flex",
            justifyContent: "center",
            marginTop: theme.spacing(2),
        },
        skeletonRectContainer: {
            display: "flex",
            justifyContent: "space-around",
            padding: theme.spacing(2),
        },
        skeleton: {
            height: skeletonHeight,
            width: skeletonWidth,
        },
        paper: {
            borderRadius: "unset",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            fontSize: theme.typography.pxToRem(24),
        },
        selectedPaper: {
            border: "3px solid #002677",
        },
        topLeft: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: leftLegendWidth,
        },
        leftLegend: {
            transform: "rotate(-90deg) translateX(55%)",
        },
        leftLegendTest: {
            transform: "rotate(-90deg) translateX(4%)",
        },
        axisLabels: {
            fontSize: theme.typography.pxToRem(16),
            fontWeight: "bold",
        },
        gridBox: {
            width: riskPaperWidth,
            height: riskPaperHeight,
            padding: theme.spacing(0.5),
            "& > *": {
                borderRadius: "5px !important",
                "& > *": {
                    fontSize: theme.typography.pxToRem(40),
                },
            },
        },
        gridBoxHover: {
            "&:hover": {
                filter: "brightness(85%)",
                cursor: "pointer",
            },
        },
        yellowPaper: {
            backgroundColor: "#FFF79F",
        },
        greenPaper: {
            backgroundColor: "#2CD5C4",
        },
        paleGreenPaper: {
            backgroundColor: "#C2EDE9",
        },
        redPaper: {
            backgroundColor: "#F7447A",
        },
        paleRedPaper: {
            backgroundColor: "#FE9FBC",
        },
        bottomContainer: {
            height: bottomContainerHeight,
        },
        bottomLegendsContainer: {
            marginTop: 2.5,
            display: "flex",
            justifyContent: "space-evenly",
            "& .MuiTypography-root": {
                marginLeft: 1.25,
            },
        },
        bottomLegendContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& .MuiTypography-root": {
                fontSize: theme.typography.pxToRem(14),
                color: theme.palette.grey[500],
                width: isSmallScreen ? 80 : "unset",
                textAlign: isSmallScreen ? "left" : "unset",
            },
        },
        cordinateHelperLabels: {
            fontSize: theme.typography.pxToRem(14),
        },
        yCordinateHelperContainer: {
            marginBottom: `${bottomContainerHeight}px`,
            display: "flex",
            justifyContent: "space-around",
            flexDirection: "column",
            marginRight: theme.spacing(-1.25),
        },
        xCordinateHelperContainer: {
            marginTop: theme.spacing(2),
        },
    });
};
