import { useTheme } from "@mui/material";
import { createSxStyles } from "../../createSxStyles";

export const useDotLegalCompanySelectorStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        btnContent: {
            color: theme.palette.primary.dark,
            fontWeight: theme.typography.fontWeightBold,
            textOverflow: "ellipsis",
            overflow: "hidden",
            width: "150px",
            whiteSpace: "nowrap",
            textAlign: "left",
        },
        tableRow: {
            "& svg": {
                height: "14px",
            },
        },
        greenArrowContainer: {
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "20px",
        },
        tableCell: {
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.primary.main + " !important",
        },
        container: {
            "& .MuiButton-label": {
                display: "flex",
                justifyContent: "space-between",
                width: "200px",
            },
            "& .MuiButton-root": {
                width: "200px",
            },
            "& .MuiButton-outlined": {
                borderWidth: "1px !important",
            },
        },
    });
};
