import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { get } from "../../../common/api/apiShared";
import { useStateLocalStorage } from "@dotlegal/dotlegal-ui-components";
import { PurposeSaveModel } from "../purposeDialog/PurposeDialog.types";
import { PurposeViewModel } from "./Purposes.types";

export function usePurposesDataMapping() {
    const queryClient = useQueryClient();
    const [showAllPurposes, setShowAllPurposes] = useStateLocalStorage("onlyActivePurposes", false);
    const [showPurposeDialog, setShowPurposeDialog] = useState(false);
    const [showDeletePurposeDialog, setShowDeletePurposeDialog] = useState(false);
    const [selectedPurpose, setSelectedPurpose] = useState<PurposeSaveModel | undefined>(undefined);
    const [showChangeStatusDialog, setShowChangeStausDialog] = useState(false);

    const queryPart = `?showAllPurposes=${showAllPurposes}`;
    const url = `/Purposes${queryPart}`;
    let { isLoading, data } = useQuery(url, () => get<Array<PurposeViewModel>>(url));

    const OnSave = () => {
        queryClient.refetchQueries(url);
    };

    return {
        isLoading,
        data,
        showAllPurposes,
        setShowAllPurposes,
        showPurposeDialog,
        setShowPurposeDialog,
        showDeletePurposeDialog,
        setShowDeletePurposeDialog,
        OnSave,
        selectedPurpose,
        setSelectedPurpose,
        showChangeStatusDialog,
        setShowChangeStausDialog,
    };
}
