import React from "react";
import { useDataCategoryBox } from "./DataCategoryBox.hooks.tsx";
import DotLegalInformationBox from "../../../common/components/dotLegalInformationBox/DotLegalInformationBox.tsx";
import { DataCategoryViewModel } from "../../DataCategoriesStep/DataCategoriesStep.types.ts";
import { useTranslation } from "../../../localization/useTranslation.ts";
import DataCategoryBoxDialog from "./DataCategoryBoxDialog.tsx";
import { DataCategoryDataTypes } from "../../ProcessingActivity.types.ts";

export interface IDataCategoryBoxProps {
    processingActivityId?: string;
    readOnly: boolean | undefined;
    isLoading: boolean;
    dataCategory: DataCategoryViewModel;
    onDataCategoryClick: (isSelected: boolean, dataCategoryDataTypes: DataCategoryDataTypes) => void;
    disabledTooltip?: string;
    onDisabledTooltipClick?: (dataCategoryId: string) => void;
    onSave?: (dataCategoryDataTypes: DataCategoryDataTypes) => void;
    size: "large" | "small";
    disableDataTypeSelection?: boolean;
}

function DataCategoryBox(props: IDataCategoryBoxProps) {
    const { translateString } = useTranslation();
    const { setDataTypeSpecificationClicked, dataTypeSpecificationClicked, onDataCategoryClick, dataTypeNames, dataTypesLoading, allDataTypes } =
        useDataCategoryBox(props);

    const disabled = props.readOnly || props.dataCategory.disabled;
    return (
        <>
            <DotLegalInformationBox
                isDisabled={disabled}
                header={props.dataCategory.name}
                description={!props.disableDataTypeSelection && dataTypeNames.length > 0 ? dataTypeNames : props.dataCategory.description}
                isSelected={props.dataCategory.isSelected ? "selected" : "unselected"}
                size={props.size}
                isLoading={props.isLoading || dataTypesLoading}
                onChange={onDataCategoryClick}
                disabledTooltip={props.dataCategory.disabled ? props.disabledTooltip : undefined}
                onDisabledTooltipClick={props.onDisabledTooltipClick ? () => props.onDisabledTooltipClick!(props.dataCategory.id) : undefined}
                overFlowMenuItems={
                    props.dataCategory.dataTypeIds.length > 0 && props.dataCategory.isSelected && !disabled && !props.disableDataTypeSelection
                        ? [
                              {
                                  menuItem: translateString("specifyDataType"),
                                  onClick: () => setDataTypeSpecificationClicked(true),
                              },
                          ]
                        : undefined
                }
            />
            {dataTypeSpecificationClicked && (
                <DataCategoryBoxDialog {...props} allDataTypes={allDataTypes} onClose={() => setDataTypeSpecificationClicked(false)} />
            )}
        </>
    );
}

export default DataCategoryBox;
