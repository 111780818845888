import Box from "@mui/material/Box/Box";
import React from "react";
import { useTranslation } from "../../../../localization/useTranslation";
import DotLegalCollapse from "../../dotLegalCollapse/DotLegalCollapse";
import DotLegalInformationBox from "../../dotLegalInformationBox/DotLegalInformationBox";
import { useProcessingActivityValidationBoxStyles } from "./ProcessingActivityValidationBox.styles";

export interface IProcessingActivityValidationBox {
    stepHeader: JSX.Element;
    stepContent: JSX.Element;
    expanded: boolean;
    onExpandClick: () => void;
    onValidationClick?: (selected: boolean) => void;
    IsValidated?: boolean;
}

export default function ProcessingActivityValidationBox(props: IProcessingActivityValidationBox) {
    const styles = useProcessingActivityValidationBoxStyles();
    const { translateString } = useTranslation();

    return (
        <DotLegalCollapse
            centerContent={props.stepHeader}
            sx={styles.background}
            isExpanded={props.expanded}
            onExpandClick={() => props.onExpandClick()}
            invertExpandedArrow
            color={props.onValidationClick ? "green" : "blue"}
        >
            {props.stepHeader}
            {props.stepContent}
            {props.onValidationClick && (
                <Box sx={{ margin: 2 }}>
                    <DotLegalInformationBox
                        isSelected={props.IsValidated ? "selected" : "unselected"}
                        fullWidth={false}
                        header={translateString("validate")}
                        size="small"
                        onChange={(_) => props.onValidationClick!(_)}
                    />
                </Box>
            )}
        </DotLegalCollapse>
    );
}
