import { useQuery } from "react-query";
import { get } from "../../common/api/apiShared";
import { System } from "../systemsStep/SystemsStep.types";

export function useSystemsQuery() {
    return useQuery("systems", () => get<Array<System>>("/System/systemselectordata"), {
        notifyOnChangePropsExclusions: ["isStale"],
        staleTime: 10000,
    });
}
