import { useTheme } from "@mui/material";
import { createSxStyles } from "../../createSxStyles";

export const useDotLegalMultiSelectWithButtonStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        divider: {
            backgroundColor: theme.palette.primary.light,
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        MenuItem: {
            display: "flex",
            alignItems: "center",
            "&:hover": {
                backgroundColor: theme.palette.grey[200],
            },
            textColor: theme.palette.primary.main,
        },
    });
};
