import { useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";

export const usePolicyOverviewStyles = (hasProcessingActivities: boolean, hasSystems: boolean) => {
    const theme = useTheme();
    return createSxStyles({
        number: {
            fontSize: theme.typography.pxToRem(24),
            display: "flex",
            justifyContent: "center",
        },
        processingActivityHover: {
            "&:hover": {
                cursor: hasProcessingActivities ? "pointer" : "default",
            },
        },
        systemHover: {
            "&:hover": {
                cursor: hasSystems ? "pointer" : "default",
            },
        },
    });
};
