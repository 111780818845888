import React from "react";
import DotLegalDeleteDialogWrapper from "../../../../common/components/dotLegalDeleteDialog/DotLegalDeleteDialogWrapper";
import { ProcessingActivityDocumentViewModel } from "../ProcessingActivityDocumentTab.types";
import { useProcessingActivityDeleteDocumentDialogHooks } from "./ProcessingActivityDeleteDocumentDialog.hooks";

export interface IProcessingActivityDeleteDocumentDialog {
    onCloseDialog: () => void;
    onDeletedDocument: () => void;
    selectedDocument: ProcessingActivityDocumentViewModel;
    processingActivityId: string;
}

function ProcessingActivityDeleteDocumentDialog(props: IProcessingActivityDeleteDocumentDialog) {
    const { deleteDocumentClick, inProgess } = useProcessingActivityDeleteDocumentDialogHooks(props);
    return (
        <DotLegalDeleteDialogWrapper
            open
            itemForDeletion={props.selectedDocument!.name}
            onDialogClose={props.onCloseDialog}
            onOkClick={() => deleteDocumentClick(props.selectedDocument!.documentId)}
            inProgress={inProgess}
        />
    );
}

export default ProcessingActivityDeleteDocumentDialog;
