import { isNullOrWhitespace } from "../../common/stringOperations.ts";
import { post, put } from "../../common/api/apiShared.ts";
import { useMutation } from "react-query";
import { AnnualWheelActivityTemplateSaveModel, AnnualWheelTemplateValidationModel } from "../AnnualWheelActivityTemplate/AnnualWheelActivityTemplate.types.ts";
import { useState } from "react";
import { useTranslation } from "../../localization/useTranslation.ts";
import { ICustomTemplateAddEditDialogProps } from "./CustomTemplateAddEditDialog.tsx";

export function useCustomTemplateAddEditDialog(props: ICustomTemplateAddEditDialogProps) {
    const { translateString } = useTranslation();
    const [templateModel, setTemplateModel] = useState<AnnualWheelActivityTemplateSaveModel>(
        props.templateModel
            ? { ...props.templateModel }
            : {
                  id: "",
                  name: "",
                  description: "",
                  subTasks: [],
                  planActivity: true,
              }
    );
    const [templateValidation, setTemplateValidation] = useState(new AnnualWheelTemplateValidationModel(translateString("fieldMandatory")));

    const isEdit = !isNullOrWhitespace(templateModel.id);

    function isTemplateValid() {
        let tempValidationModel = new AnnualWheelTemplateValidationModel(translateString("fieldMandatory"));

        tempValidationModel.validateName(templateModel.name);
        setTemplateValidation(tempValidationModel);

        return tempValidationModel.isValid();
    }

    const onSave = () => {
        if (isTemplateValid()) {
            if (isEdit) {
                updateTemplate();
            } else {
                createTemplate();
            }
        }
    };

    const createTemplate = async () => {
        await saveMutation.mutateAsync();
    };

    const updateTemplate = async () => {
        await updateMutation.mutateAsync();
    };

    const updateActiveTemplate = async () => {
        await updateActiveTemplateMutation.mutateAsync();
    };

    function save() {
        return post<{}>("/annualWheelActivityTemplate", templateModel);
    }

    function update() {
        return put<{}>(`/annualWheelActivityTemplate/${templateModel.id}/`, templateModel);
    }

    const saveMutation = useMutation(save, {
        onSuccess: (response: any) => {
            const value = response.value();
            //TODO THE standardActivityCopy
            props.onCreated({ ...templateModel, id: value.id });
            setTemplateModel({ name: "", description: "", subTasks: [], planActivity: true, id: "" });
        },
    });

    const updateMutation = useMutation(update, {
        onSuccess: () => {
            //TODO THE
            // onTemplateCreated(templateModel.id, templateModel.planActivity, standardActivityCopy);
            setTemplateModel({ name: "", description: "", subTasks: [], planActivity: true, id: "" });
        },
    });

    const updateActiveTemplateMutation = useMutation(update, {
        onSuccess: () => {
            setTemplateModel({ name: "", description: "", subTasks: [], planActivity: true, id: "" });
        },
    });

    return {
        onSave,
        templateModel,
        setTemplateModel,
        templateValidation,
        isEdit,
    };
}
