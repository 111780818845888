import { ISegmentStepProps } from "./StepSegment";
import { createSxStyles } from "../../common/createSxStyles";
import { useIsOnExtraSmallScreen, useIsOnLargeScreen, useIsOnMediumScreen } from "@dotlegal/dotlegal-ui-components";

export const useStepSegmentStyles = (props: ISegmentStepProps) => {
    const isMediumScreen = useIsOnMediumScreen();
    const isExtraSmallScreen = useIsOnExtraSmallScreen();
    const isLargeScreen = useIsOnLargeScreen();
    return createSxStyles({
        container: {
            position: "relative",
            width:
                props.size === "small"
                    ? isExtraSmallScreen
                        ? "85%"
                        : isLargeScreen
                          ? "70%"
                          : isMediumScreen
                            ? "80%"
                            : "40%"
                    : props.size === "medium"
                      ? isLargeScreen
                          ? "90%"
                          : isMediumScreen
                            ? "100%"
                            : "60%"
                      : isLargeScreen
                        ? "100%"
                        : "90%",
        },
    });
};
