import { Box } from "@mui/material";
import { DotLegalButton } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../../localization/useTranslation";
import SubProcessorComponent from "../subProcessorComponent/SubProcessorComponent";
import React from "react";
import { DataProcessor } from "../subProcessorComponent/SubProcessorComponent.types";
import { useDataProcessorsStepStyles } from "../DataProcessorsStep.styles";
import {
    DataCategoryDataTypes,
    SelectableDataCategoryItem,
    SelectableDocumentItem,
    SelectableItem,
    SelectableLegalUnitsItem,
    SubProcessor,
} from "../../../ProcessingActivity.types";
import { ValidationError } from "../../../../common/validationError";
import { DotLegalSelectOption } from "../../../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";

export interface IDataProcessorsStep {
    subProcessors: Array<SubProcessor>;
    subDataProcessorLink?: string;
    onLinkChange: (link: string, dataProcessorId: string, index: number) => void;
    onAddNewSubprocessorClick: (isLink: boolean) => void;
    readonly: boolean;
    canAddNewSubDataProcessor: boolean;
    groupEntityIsDataProcessor: boolean;
    isReadOnly: boolean;
    dataProcessor: DataProcessor;
    isLegalEntityLoading: boolean;
    documentsLoading: boolean;
    documentsData: Array<SelectableDocumentItem> | undefined;
    legalEntityData?: SelectableLegalUnitsItem[];
    isTransferBasisLoading: boolean;
    transferBasisData: SelectableItem[] | undefined;
    validationErrors: Array<ValidationError>;
    onSubProcessorTransferBasisChange?: (transferBasis: string | null, dataProcessorId: string, index: number) => void;
    onRemoveSubDataProcessor?: (dataProcessorId: string, subprocessorIndex: number) => void;
    onSubProcessorAgreementChange?: (hasAgreement: boolean, dataProcessorId: string, subprocessorInde: number) => void;
    onSubDataProcessorChange?: (
        processorId: string | null,
        dataProcessorId: string,
        subprocessorInde: number,
        legalEntity: SelectableLegalUnitsItem | undefined
    ) => void;
    isDataCategoryLoading: boolean;
    dataCategoriesData: SelectableDataCategoryItem[] | undefined;
    onSubProcessorDataCategoryChange: (chosenCategories: Array<DataCategoryDataTypes>, dataProcessorId: string, subprocessorIndex: number) => void;
    key?: React.Key | null;
    onSubProcessorTransferImpactAssessmentChange?: (tia: string | null, dataProcessorId: string, index: number) => void;
    countriesLoading?: boolean;
    countryList?: Array<DotLegalSelectOption>;
    onSubProcessorStorageLocationChange?: (
        storageLocations: Array<string>,
        supportLocations: Array<string>,
        dataProcessorId: string,
        subprocessorIndex: number,
        dataContainedInThirdCountry: boolean
    ) => void;
    thirdCountries: Array<string>;
}

function SubDataProcessor(props: IDataProcessorsStep) {
    const { translateString } = useTranslation();
    const styles = useDataProcessorsStepStyles();

    return (
        <React.Fragment>
            {(props.subProcessors ?? []).length > 0 && (
                <Box marginBottom={3}>
                    {props.subProcessors?.map((subprocessor, index) => {
                        return <SubProcessorComponent key={index} subProcessor={subprocessor} subProcessorIndex={index} {...props} />;
                    })}
                </Box>
            )}

            {props.canAddNewSubDataProcessor && (
                <Box sx={styles.addNewProcess}>
                    <DotLegalButton buttonType="secondary" disabled={props.readonly} onClick={() => props.onAddNewSubprocessorClick(false)}>
                        {props.subProcessors?.length > 0 ? translateString("addAnotherSubProcessor") : translateString("addSubProcessor")}
                    </DotLegalButton>

                    <DotLegalButton buttonType="secondary" disabled={props.readonly} onClick={() => props.onAddNewSubprocessorClick(true)}>
                        {translateString("insertLinkToSubProcessors")}
                    </DotLegalButton>
                </Box>
            )}
        </React.Fragment>
    );
}

export default SubDataProcessor;
