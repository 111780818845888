import React from "react";
export const getRiskImage = (score: number | undefined) => {
    switch (score) {
        case 1:
            return veryLowRisk;
        case 2:
            return lowRisk;
        case 3:
            return moderateRisk;
        case 4:
            return highRisk;
        case 5:
            return veryHighRisk;
        default:
            return NoRisk;
    }
};

const cls1 = {
    fill: "none",
    strokeMiterlimit: 10,
    strokeWidth: 100,
    stroke: "url(#Ikke-navngivet_forløb_56)",
};

const cls3 = {
    fill: "#002C75",
};

const cls4 = {
    stroke: "#002C75",
    fill: "#002C75",
    strokMiterlimit: 10,
};

const cls5 = {
    opacity: 0.28,
};

const cls6 = {
    fill: "none",
    stroke: "#a0a0a2",
    strokeWidth: "100px",
};

const cls7 = {
    fill: "#032478",
    stroke: "#000",
    strokMiterlimit: 10,
};

const dots = (
    <g id="Prikker">
        <circle style={cls4} cx="221.71" cy="148.31" r="6.23" />
        <circle style={cls4} cx="51.73" cy="440.15" r="6.23" />
        <circle style={cls4} cx="513.4" cy="50" r="6.23" />
        <circle style={cls4} cx="800.36" cy="153.91" r="6.23" />
        <circle style={cls4} cx="964.64" cy="440.65" r="6.23" />
    </g>
);

export const veryLowRisk = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1019.4 519.08">
        <defs>
            <linearGradient
                id="Ikke-navngivet_forløb_56"
                x1="4.7"
                y1="280.5"
                x2="1020.33"
                y2="280.5"
                gradientTransform="translate(-43.93 47.95) rotate(-5.18)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0" stopColor="#2cd5c4" />
                <stop offset="0.22" stopColor="#8ee3b1" />
                <stop offset="0.41" stopColor="#dfeea1" />
                <stop offset="0.5" stopColor="#fef29b" />
                <stop offset="0.63" stopColor="#fde7a3" />
                <stop offset="0.86" stopColor="#fac8b8" />
                <stop offset="1" stopColor="#f8b2c7" />
            </linearGradient>
        </defs>
        <g id="Lag_2" data-name="Lag 2">
            <g id="Lag_1-2" data-name="Lag 1">
                <path style={cls1} d="M50,480.88c1.79-223.24,188.9-408.18,418.15-429,253.37-23,477.38,163.8,500.35,417.17.35,3.83.89,7.65.9,11.48" />
                <circle style={cls3} cx="509.02" cy="451.98" r="30" />
                <rect style={cls3} x="280.63" y="445.4" width="232.06" height="13.07" transform="translate(793.32 903.87) rotate(180)" />
                <polygon style={cls3} points="282.3 480.88 281.31 426.15 229.45 451.7 282.3 480.88" />
            </g>
        </g>
        {dots}
    </svg>
);

export const lowRisk = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1019.4 519.08">
        <defs>
            <linearGradient
                id="Ikke-navngivet_forløb_56"
                x1="4.7"
                y1="280.5"
                x2="1020.33"
                y2="280.5"
                gradientTransform="translate(-43.93 47.95) rotate(-5.18)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0" stopColor="#2cd5c4" />
                <stop offset="0.22" stopColor="#8ee3b1" />
                <stop offset="0.41" stopColor="#dfeea1" />
                <stop offset="0.5" stopColor="#fef29b" />
                <stop offset="0.63" stopColor="#fde7a3" />
                <stop offset="0.86" stopColor="#fac8b8" />
                <stop offset="1" stopColor="#f8b2c7" />
            </linearGradient>
        </defs>
        <g id="Lag_2" data-name="Lag 2">
            <g id="Lag_1-2" data-name="Lag 1">
                <path style={cls1} d="M50,480.88c1.79-223.24,188.9-408.18,418.15-429,253.37-23,477.38,163.8,500.35,417.17.35,3.83.89,7.65.9,11.48" />
                <circle style={cls3} cx="508.88" cy="451.91" r="30" />
                <rect style={cls3} x="314.85" y="364.48" width="232.06" height="13.07" transform="translate(473.21 938.04) rotate(-135)" />
                <polygon style={cls3} points="329.55 310.61 367.55 271.22 312.81 252.61 329.55 310.61" />
            </g>
        </g>
        {dots}
    </svg>
);

export const moderateRisk = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1019.4 519.08">
        <defs>
            <linearGradient
                id="Ikke-navngivet_forløb_56"
                x1="4.7"
                y1="280.5"
                x2="1020.33"
                y2="280.5"
                gradientTransform="translate(-43.93 47.95) rotate(-5.18)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0" stopColor="#2cd5c4" />
                <stop offset="0.22" stopColor="#8ee3b1" />
                <stop offset="0.41" stopColor="#dfeea1" />
                <stop offset="0.5" stopColor="#fef29b" />
                <stop offset="0.63" stopColor="#fde7a3" />
                <stop offset="0.86" stopColor="#fac8b8" />
                <stop offset="1" stopColor="#f8b2c7" />
            </linearGradient>
        </defs>
        <g id="Lag_2" data-name="Lag 2">
            <g id="Lag_1-2" data-name="Lag 1">
                <path style={cls1} d="M50,480.88c1.79-223.24,188.9-408.18,418.15-429,253.37-23,477.38,163.8,500.35,417.17.35,3.83.89,7.65.9,11.48" />
                <circle style={cls3} cx="508.69" cy="451.82" r="30" />
                <rect style={cls3} x="394.71" y="332.92" width="232.06" height="13.07" transform="translate(171.29 850.2) rotate(-90)" />
                <polygon style={cls3} points="481.8 225.1 536.53 224.11 510.97 172.25 481.8 225.1" />
            </g>
        </g>
        {dots}
    </svg>
);

export const highRisk = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1019.4 519.08">
        <defs>
            <linearGradient
                id="Ikke-navngivet_forløb_56"
                x1="4.7"
                y1="280.5"
                x2="1020.33"
                y2="280.5"
                gradientTransform="translate(-43.93 47.95) rotate(-5.18)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0" stopColor="#2cd5c4" />
                <stop offset="0.22" stopColor="#8ee3b1" />
                <stop offset="0.41" stopColor="#dfeea1" />
                <stop offset="0.5" stopColor="#fef29b" />
                <stop offset="0.63" stopColor="#fde7a3" />
                <stop offset="0.86" stopColor="#fac8b8" />
                <stop offset="1" stopColor="#f8b2c7" />
            </linearGradient>
        </defs>
        <g id="Lag_2" data-name="Lag 2">
            <g id="Lag_1-2" data-name="Lag 1">
                <path style={cls1} d="M50,480.88c1.79-223.24,188.9-408.18,418.15-429,253.37-23,477.38,163.8,500.35,417.17.35,3.83.89,7.65.9,11.48" />
                <circle style={cls3} cx="508.88" cy="451.91" r="30" />
                <rect style={cls3} x="473.76" y="367.37" width="232.06" height="13.07" transform="translate(-91.65 526.56) rotate(-45)" />
                <polygon style={cls3} points="650.18 272.58 689.58 310.58 708.19 255.84 650.18 272.58" />
            </g>
        </g>
        {dots}
    </svg>
);

export const veryHighRisk = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1019.4 518.79">
        <defs>
            <linearGradient
                id="Ikke-navngivet_forløb_56"
                x1="4.74"
                y1="280.36"
                x2="1020.37"
                y2="280.36"
                gradientTransform="translate(-43.94 47.95) rotate(-5.18)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0" stopColor="#2cd5c4" />
                <stop offset="0.22" stopColor="#8ee3b1" />
                <stop offset="0.41" stopColor="#dfeea1" />
                <stop offset="0.5" stopColor="#fef29b" />
                <stop offset="0.63" stopColor="#fde7a3" />
                <stop offset="0.86" stopColor="#fac8b8" />
                <stop offset="1" stopColor="#f8b2c7" />
            </linearGradient>
        </defs>
        <g id="Lag_2" data-name="Lag 2">
            <g id="Lag_1-2" data-name="Lag 1">
                <path style={cls1} d="M50,480.58C51.79,257.5,238.9,72.68,468.15,51.91,721.52,29,945.53,215.6,968.5,468.79c.35,3.83.89,7.65.9,11.47" />
                <ellipse style={cls3} cx="509.01" cy="451.25" rx="29.98" ry="30" transform="translate(-169.98 492.32) rotate(-45.02)" />
                <rect style={cls3} x="505.34" y="446.76" width="231.98" height="13.06" />
                <polygon style={cls3} points="735.63 424.37 736.66 479.07 788.49 453.53 735.63 424.37" />
            </g>
        </g>
        {dots}
    </svg>
);

export const overriddenRisk = (
    <svg xmlns="http://www.w3.org/2000/svg" width="13.304" height="13.304" viewBox="0 0 10.3 10.301">
        <g id="Group_4851" data-name="Group 4851" transform="translate(-97.329 -93.959)">
            <path
                id="Path_1383"
                data-name="Path 1383"
                d="M100.461,103.143l-2.632.617.617-2.631,6.35-6.352a.815.815,0,0,1,1.152,0l.864.864a.815.815,0,0,1,0,1.152Z"
                transform="translate(0)"
                fill="#b6d5ef"
                stroke="#5a61a8"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            />
            <line
                id="Line_180"
                data-name="Line 180"
                x1="2.016"
                y1="2.016"
                transform="translate(104.509 95.065)"
                fill="none"
                stroke="#5a61a8"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            />
            <line
                id="Line_181"
                data-name="Line 181"
                x1="2.011"
                y1="2.021"
                transform="translate(103.933 95.641)"
                fill="none"
                stroke="#5a61a8"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            />
            <line
                id="Line_182"
                data-name="Line 182"
                x1="2.015"
                y1="2.015"
                transform="translate(98.548 101.026)"
                fill="none"
                stroke="#5a61a8"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            />
        </g>
    </svg>
);

export const NoRisk = (
    <svg id="Lag_9" data-name="Lag 9" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1019.4 519.08">
        <g id="Lag_1" data-name="Lag 1" style={cls5}>
            <g id="Lag_2" data-name="Lag 2">
                <g id="Lag_1-2" data-name="Lag 1-2">
                    <path style={cls6} d="M51.51,512.58c1.79-223.23,188.9-408.17,418.15-429C723,60.58,947,247.38,970,500.76c.35,3.82.89,7.65.9,11.48" />
                </g>
            </g>
        </g>
        <circle style={cls7} cx="223.22" cy="179.98" r="6.23" />
        <circle style={cls7} cx="53.25" cy="471.82" r="6.23" />
        <circle style={cls7} cx="514.92" cy="81.67" r="6.23" />
        <circle style={cls7} cx="801.88" cy="185.58" r="6.23" />
        <circle style={cls7} cx="966.15" cy="472.32" r="6.23" />
    </svg>
);
