import React from "react";
import DotLegalDeleteDialogWrapper from "../../../common/components/dotLegalDeleteDialog/DotLegalDeleteDialogWrapper";
import { SecurityMeasureSaveModel } from "../securityMeasures/SecurityMeasures.types";
import { DeleteSecurityMeasureDialogDataMapping } from "./DeleteSecurityMeasureDialog.hooks";

export interface IDeleteSecurityMeasureDialog {
    onCloseDialog: () => void;
    onSaveCorrect: () => void;
    selectedSecurityMeasure: SecurityMeasureSaveModel;
}

export function DeleteSecurityMeasureDialog(props: IDeleteSecurityMeasureDialog) {
    const { removePurpose, inProgess } = DeleteSecurityMeasureDialogDataMapping(props);

    return (
        <DotLegalDeleteDialogWrapper
            open
            itemForDeletion={props.selectedSecurityMeasure.name}
            onDialogClose={props.onCloseDialog}
            onOkClick={removePurpose}
            inProgress={inProgess}
        />
    );
}
