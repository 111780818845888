import { DotLegalDialog, DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import React from "react";
import { SelectableItem } from "../../../processingActivity/ProcessingActivity.types";
import { useTranslation } from "../../../localization/useTranslation";
import { Box, Typography } from "@mui/material";
import { isNullOrWhitespace } from "../../../common/stringOperations";

export interface DeclarationGroupEntityDisplayDialogProps {
    onCloseDialog: () => void;
    groupEntities: Array<SelectableItem>;
    numberOfGroupEntitiesWithoutaccess: number;
}

function DeclarationGroupEntityDisplayDialog(props: DeclarationGroupEntityDisplayDialogProps) {
    const { translateString } = useTranslation();

    return (
        <DotLegalDialog
            header={translateString("groupCompanies")}
            buttonOkText={""}
            hideOkButton
            open
            onDialogClose={() => props.onCloseDialog()}
            onOkClick={() => {}}
            size="sm"
        >
            <Box>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                    {props.groupEntities?.map((entity) => {
                        return (
                            <DotLegalHeader key={entity.id} headerStyle={"extraSmall"} marginBottom={1} fontWeight={400}>
                                {entity.id === "null" ? translateString("deletedGroupCompany") : entity.name}
                            </DotLegalHeader>
                        );
                    })}

                    {props.numberOfGroupEntitiesWithoutaccess > 0 && (
                        <DotLegalHeader headerStyle={"extraSmall"} fontWeight={400}>
                            {translateString("noOfGroupEntitiesWithoutAccess", { count: props.numberOfGroupEntitiesWithoutaccess })}
                        </DotLegalHeader>
                    )}
                </Box>
            </Box>
        </DotLegalDialog>
    );
}

export default DeclarationGroupEntityDisplayDialog;
