import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useProcessingActivityStatisticsStyles } from "../processingActivityStatistics.styles";
import { useTranslation } from "../../../localization/useTranslation";
import DotLegalPaper from "../../../common/components/dotLegalPaper/DotLegalPaper";
import { getCounter } from "../ProcessingActivityStatistics";
import { ProcessingActivityCounterViewModel } from "../ProcessingActivityStatistics.types";

export interface TotalNumberOfCaseseProps {
    processingActivityCounter: ProcessingActivityCounterViewModel | undefined;
}

function TotalNumberOfCases(props: TotalNumberOfCaseseProps) {
    const styles = useProcessingActivityStatisticsStyles();
    const { translateString, translateStatus } = useTranslation();
    return (
        <Box>
            <DotLegalPaper backgroundColor="orange" fullHeight>
                <Typography sx={styles.header}>{translateString("numberOfCases")}</Typography>
                <Typography sx={styles.body}>{props?.processingActivityCounter?.totalNumber}</Typography>
            </DotLegalPaper>
            <Grid sx={styles.footer} container columnSpacing={5} rowSpacing={0.5}>
                {getCounter(translateStatus("0"), props!.processingActivityCounter!.numberOfActive)}
                {getCounter(translateStatus("4"), props!.processingActivityCounter!.numberOfAwaitingResearcher)}
                {getCounter(translateStatus("3"), props!.processingActivityCounter!.numberOfDraftSubmitted)}
                {getCounter(translateStatus("2"), props!.processingActivityCounter!.numberOfArchived)}
                {getCounter(translateStatus("1"), props!.processingActivityCounter!.numberOfDrafts)}
                {getCounter(translateStatus("5"), props!.processingActivityCounter!.numberOfCancelled)}
            </Grid>
        </Box>
    );
}

export default TotalNumberOfCases;
