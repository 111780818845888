import React, { Fragment } from "react";
import { DataCategoryViewModel, DataPrivacyClassification } from "../DataCategoriesStep.types";
import { Box, Grid, Typography } from "@mui/material";
import { DotLegalInformationTooltip } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { useDataCategoriesStepstyles } from "../DataCategoriesStep.styles";
import DataCategoryBox from "../../components/dataCategoryBox/DataCategoryBox.tsx";
import { DataCategoryDataTypes } from "../../ProcessingActivity.types.ts";

export interface DataCategoryPickerProps {
    processingActivityId?: string;
    isLoading: boolean;
    readOnly: boolean | undefined;
    allDataCategories: Array<DataCategoryViewModel> | undefined;
    onDataCategoryClick: (isSelected: boolean, dataCategoryDataTypes: DataCategoryDataTypes) => void;
    disabledTooltip?: string;
    onDisabledTooltipClick?: (dataCategoryId: string) => void;
    disableDataTypeSelection?: boolean;
}

function DataCategoryPicker(props: DataCategoryPickerProps) {
    const { translateString } = useTranslation();
    const styles = useDataCategoriesStepstyles();
    const gridSpacing = 3;

    const dataCategoriesLoadingData: Array<DataCategoryViewModel> = [
        { id: "1", name: "", classification: DataPrivacyClassification.Ordinary, description: "", isSelected: false, dataTypeIds: [], selectedDataTypeIds: [] },
        { id: "2", name: "", classification: DataPrivacyClassification.Ordinary, description: "", isSelected: false, dataTypeIds: [], selectedDataTypeIds: [] },
        { id: "3", name: "", classification: DataPrivacyClassification.Ordinary, description: "", isSelected: false, dataTypeIds: [], selectedDataTypeIds: [] },
        { id: "4", name: "", classification: DataPrivacyClassification.Ordinary, description: "", isSelected: false, dataTypeIds: [], selectedDataTypeIds: [] },
        { id: "5", name: "", classification: DataPrivacyClassification.Ordinary, description: "", isSelected: false, dataTypeIds: [], selectedDataTypeIds: [] },
        { id: "6", name: "", classification: DataPrivacyClassification.Ordinary, description: "", isSelected: false, dataTypeIds: [], selectedDataTypeIds: [] },
        { id: "7", name: "", classification: DataPrivacyClassification.Ordinary, description: "", isSelected: false, dataTypeIds: [], selectedDataTypeIds: [] },
        { id: "8", name: "", classification: DataPrivacyClassification.Ordinary, description: "", isSelected: false, dataTypeIds: [], selectedDataTypeIds: [] },
        {
            id: "9",
            name: "",
            classification: DataPrivacyClassification.NationalIdentificationNumber,
            description: "",
            isSelected: false,
            dataTypeIds: [],
            selectedDataTypeIds: [],
        },
        {
            id: "10",
            name: "",
            classification: DataPrivacyClassification.CriminalOffenses,
            description: "",
            isSelected: false,
            dataTypeIds: [],
            selectedDataTypeIds: [],
        },
        {
            id: "11",
            name: "",
            classification: DataPrivacyClassification.Sensitive,
            description: "",
            isSelected: false,
            dataTypeIds: [],
            selectedDataTypeIds: [],
        },
        {
            id: "12",
            name: "",
            classification: DataPrivacyClassification.Sensitive,
            description: "",
            isSelected: false,
            dataTypeIds: [],
            selectedDataTypeIds: [],
        },
        {
            id: "13",
            name: "",
            classification: DataPrivacyClassification.Sensitive,
            description: "",
            isSelected: false,
            dataTypeIds: [],
            selectedDataTypeIds: [],
        },
        {
            id: "14",
            name: "",
            classification: DataPrivacyClassification.Sensitive,
            description: "",
            isSelected: false,
            dataTypeIds: [],
            selectedDataTypeIds: [],
        },
    ];

    const data = props.isLoading ? dataCategoriesLoadingData : (props.allDataCategories ?? []);

    const getDataCategoryGridContainer = (classification: DataPrivacyClassification) => {
        return (
            <Grid container spacing={gridSpacing}>
                {data!
                    .filter((x) => x.classification === classification)
                    .map((d, index) => {
                        return (
                            <Grid key={d.id} item>
                                <DataCategoryBox {...props} size={"large"} dataCategory={d} isLoading={props.isLoading} />
                            </Grid>
                        );
                    })}
            </Grid>
        );
    };

    return (
        <Fragment>
            <Fragment>
                {data!.filter((x) => x.classification === DataPrivacyClassification.Ordinary).length > 0 && (
                    <Fragment>
                        <Box sx={styles.firstTypographyContainer}>
                            <Typography sx={styles.infoHeader}>{translateString("dataCategoriOrdinary")}</Typography>
                            <Box sx={styles.toolTipContainer}>
                                <DotLegalInformationTooltip text={translateString("nonSensitiveHelperText")} />
                            </Box>
                        </Box>
                        {getDataCategoryGridContainer(DataPrivacyClassification.Ordinary)}
                    </Fragment>
                )}
            </Fragment>

            <Fragment>
                {data!.filter((x) => x.classification === DataPrivacyClassification.NationalIdentificationNumber).length > 0 && (
                    <Fragment>
                        <Box sx={styles.firstTypographyContainer}>
                            <Typography sx={styles.infoHeader}>{translateString("nationalidentificationnumber")}</Typography>
                        </Box>
                        {getDataCategoryGridContainer(DataPrivacyClassification.NationalIdentificationNumber)}
                    </Fragment>
                )}
            </Fragment>

            <Fragment>
                {data!.filter((x) => x.classification === DataPrivacyClassification.CriminalOffenses).length > 0 && (
                    <Fragment>
                        <Box sx={styles.firstTypographyContainer}>
                            <Typography sx={styles.infoHeader}>{translateString("criminaloffenses")}</Typography>
                        </Box>
                        {getDataCategoryGridContainer(DataPrivacyClassification.CriminalOffenses)}
                    </Fragment>
                )}
            </Fragment>

            <Fragment>
                {data!.filter((x) => x.classification === DataPrivacyClassification.Sensitive).length > 0 && (
                    <Fragment>
                        <Box sx={styles.firstTypographyContainer}>
                            <Typography sx={styles.infoHeader}>{translateString("dataCategoriSensitive")}</Typography>
                            <Box sx={styles.toolTipContainer}>
                                <DotLegalInformationTooltip text={translateString("sensitiveHelperText")} />
                            </Box>
                        </Box>
                        {getDataCategoryGridContainer(DataPrivacyClassification.Sensitive)}
                    </Fragment>
                )}
            </Fragment>

            <Fragment>
                {data!.filter((x) => x.classification === DataPrivacyClassification.None).length > 0 && (
                    <Fragment>
                        <Box sx={styles.firstTypographyContainer}>
                            <Typography sx={styles.infoHeader}>{translateString("dataCategoriNone")}</Typography>
                        </Box>
                        {getDataCategoryGridContainer(DataPrivacyClassification.None)}
                    </Fragment>
                )}
            </Fragment>
        </Fragment>
    );
}

export default DataCategoryPicker;
