import React from "react";
import DotLegalDeleteDialogWrapper from "../../common/components/dotLegalDeleteDialog/DotLegalDeleteDialogWrapper";
import { useDeleteCustomListDialogHook } from "./DeleteCustomListDialog.hooks";

export interface IDeleteCustomListDialog {
    id: string;
    name: string;
    onCloseDialog: () => void;
    onDeleteClick: () => void;
}

function DeleteCustomListDialog(props: IDeleteCustomListDialog) {
    const { removeTask, inProgress } = useDeleteCustomListDialogHook(props);

    return (
        <DotLegalDeleteDialogWrapper
            open
            inProgress={inProgress}
            itemForDeletion={props.name}
            onDialogClose={props.onCloseDialog}
            onOkClick={() => removeTask()}
        />
    );
}

export default DeleteCustomListDialog;
