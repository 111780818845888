import { useTranslation } from "../../localization/useTranslation.ts";
import { useUrlProvider } from "../../useUrlProvider.ts";
import Widget from "../Widget.tsx";
import DashboardCountCard from "../DashboardCountCard.tsx";
import { useSystemsCount } from "./SystemsCountWidget.hooks.ts";
import { onlyUserSpecificSystemStorageKey } from "../../system/systems/Systems.hooks.ts";
import React from "react";
import LargeCountText from "../LargeCountText.tsx";

export interface ISystemsCountWidgetProps {
    onlyUserSpecific: boolean;
    isPdf?: boolean;
}

function SystemsCountWidget(props: ISystemsCountWidgetProps) {
    const { translateString } = useTranslation();
    const { getSystemsUrl } = useUrlProvider();
    const { isLoading, data, isEnabled } = useSystemsCount(props);

    return (
        <Widget noPadding disabled={!isEnabled}>
            <DashboardCountCard
                isLoading={isLoading}
                header={translateString("systems")}
                background="pink"
                icon="screen"
                url={{
                    url: getSystemsUrl(),
                    text: translateString("gotoSystems"),
                    allMyPageKey: onlyUserSpecificSystemStorageKey,
                    onlyUserSpecific: props.onlyUserSpecific,
                }}
                isPdf={props.isPdf}
            >
                {!isLoading && <LargeCountText count={data} />}
            </DashboardCountCard>
        </Widget>
    );
}

export default SystemsCountWidget;
