import React from "react";
import { DataProcessorViewModel } from "../SystemOverview.types";
import { DotLegalDialog, DotLegalPrettyScrollbar, DotLegalTable, ITableHeader } from "@dotlegal/dotlegal-ui-components";
import { Box, TableCell, TableRow } from "@mui/material";
import { useTranslation } from "../../../localization/useTranslation";
import { useUrlProvider } from "../../../useUrlProvider";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import DotLegalLink from "../../../common/components/dotLegalLink/DotLegalLink";

export interface SystemOverviewLegalEntityDialogProps {
    legalEntities: Array<DataProcessorViewModel> | undefined;
    onDialogClose: () => void;
    hideTransferBasis?: boolean;
}

function SystemOverviewLegalEntityDialog(props: SystemOverviewLegalEntityDialogProps) {
    const { translateString } = useTranslation();
    const { getLegalEntityUrl, getGroupEntityUrl } = useUrlProvider();
    const { permissions } = useUserContext();

    const getLinkComponent = (url: string, name: string) => {
        return (
            <DotLegalLink linkColor={"secondary"} to={url}>
                {name}
            </DotLegalLink>
        );
    };

    const getRowName = (row: DataProcessorViewModel) => {
        if (row.isGroupEntity && permissions.canManageGroupEntities) {
            return getLinkComponent(getGroupEntityUrl(row.id), row.name);
        } else if (row.isGroupEntity) {
            return row.name;
        } else {
            return getLinkComponent(getLegalEntityUrl(row.id), row.name);
        }
    };

    return (
        <DotLegalDialog buttonOkText={""} hideOkButton open onDialogClose={props.onDialogClose} onOkClick={() => {}} size="md">
            <DotLegalPrettyScrollbar>
                <Box sx={{ maxHeight: 525 }}>
                    <DotLegalTable
                        hideRowsPerPage
                        hidePagination
                        emptyText={translateString("noSecondarySuppliersOnSystem")}
                        paginationLabelOf={translateString("labelOf")}
                        headers={getHeaders(translateString, props.hideTransferBasis)}
                        defaultOrderBy={"name"}
                        data={props.legalEntities ?? []}
                        defaultOrder={"asc"}
                        isLoading={false}
                        clickableRows={false}
                        rowsPerPage={1000}
                        renderRow={(row) => (
                            <TableRow key={row.id}>
                                <TableCell>{getRowName(row)}</TableCell>
                                <TableCell>{row.isGroupEntity ? translateString("yes") : translateString("no")}</TableCell>
                                <TableCell>{row.isThirdCountry ? translateString("yes") : translateString("no")}</TableCell>
                                {!props.hideTransferBasis && <TableCell>{row.transferBasis}</TableCell>}
                            </TableRow>
                        )}
                    />
                </Box>
            </DotLegalPrettyScrollbar>
        </DotLegalDialog>
    );
}

function getHeaders(translateString: any, hideTransferBasis?: boolean) {
    let headers = Array<ITableHeader<DataProcessorViewModel>>();
    headers.push({ property: "name", text: translateString("name"), align: "left", showOnMobile: true });
    headers.push({ property: "isGroupEntity", text: translateString("groupCompany"), align: "left", showOnMobile: true });
    headers.push({ property: "isThirdCountry", text: translateString("thirdcountry"), align: "left", showOnMobile: true });
    if (!hideTransferBasis) {
        headers.push({ property: "transferBasis", text: translateString("transferBasis"), align: "left", showOnMobile: true });
    }

    return headers;
}

export default SystemOverviewLegalEntityDialog;
