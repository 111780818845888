import { isNullOrWhitespace } from "../../../common/stringOperations.ts";
import { BaseViewModel } from "../../../system/systemOverview/SystemOverview.types.ts";

export class AnnualWheelNameDescriptionSubtasksFormModel {
    constructor(name: string | null, description: string | null, subTasks: Array<BaseViewModel>) {
        this.name = name;
        this.description = description;
        this.subTasks = subTasks;
    }

    name: string | null;
    description: string | null;
    subTasks: Array<BaseViewModel>;

    isValid = () => {
        return !isNullOrWhitespace(this.name);
    };
    isInValid = () => {
        return isNullOrWhitespace(this.name);
    };
}
