import { createSxStyles } from "../../common/createSxStyles";

export const useCommentButtonStyle = () => {
    return createSxStyles({
        container: {
            position: "fixed",
            zIndex: 1202,
            bottom: 16,
            right: 40,
        },
        chatIcon: {
            fill: "white",
        },
    });
};
