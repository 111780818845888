import { useState } from "react";
import { ISharingsAgreementBox } from "./sharingsAgreementBox";

export function useSharingsAgreementBoxHooks(props: ISharingsAgreementBox) {
    const [showAddAgreementDialog, setShowAddAgreementDialog] = useState(false);
    const [removeDialog, setRemoveDialog] = useState<{ id: string; name: string; showDialog: boolean }>({ id: "", name: "", showDialog: false });

    function onRemoveAgreementClick(id: string) {
        let agreement = props.options?.find((x) => x.id === id);

        if (agreement) {
            setRemoveDialog({ id: id, name: agreement.name, showDialog: true });
        }
    }

    return {
        showAddAgreementDialog,
        setShowAddAgreementDialog,
        removeDialog,
        setRemoveDialog,
        onRemoveAgreementClick,
    };
}
