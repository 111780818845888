import { createSxStyles } from "../../common/createSxStyles";

export const useOverviewDialogStyles = () => {
    return createSxStyles({
        container: {
            display: "flex",
            justifyContent: "center",
        },
    });
};
