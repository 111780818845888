import { useMutation } from "react-query";
import { deleteHttp } from "../../../common/api/apiShared";
import { DeleteTagDialogProps } from "./DeleteTagDialog";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { createElement } from "react";
import { Trans } from "react-i18next";

export function useDeleteTagDialog(props: DeleteTagDialogProps) {
    const snackbar = useDotLegalSnackbar();

    const deleteMutation = useMutation(deleteTag);
    const onDeleteClick = async () => {
        await deleteMutation.mutateAsync(props.selectedTag.id, {
            onSuccess: () => {
                snackbar.show(createElement(Trans, { i18nKey: "dataDeleted", values: { name: props.selectedTag.name } }));
                props.onDeleted();
            },
        });
    };

    return { onDeleteClick, inProgress: deleteMutation.isLoading };

    async function deleteTag(tagId: string) {
        return await deleteHttp(`/tag/${tagId}`);
    }
}
