import { getEnumValues } from "../../../common/enumOperations";
import { setFirstLetterToLowerCase } from "../../../common/stringOperations";
import { SystemDataType, SystemEntityType, SystemStorageType } from "./SystemDataAndStorageStep.types";
import useCountryHook from "../../../common/hooks/useCountryList";

export function useSystemDataAndStorageStepHooks() {
    let countriesQuery = useCountryHook();

    return { countryList: countriesQuery.getSelectableCountries(), countriesLoading: countriesQuery.countriesIsLoading };
}

export const storageTypeOptions = (translateString: any) => {
    return getEnumValues(SystemStorageType).map((x) => {
        var name = translateString(setFirstLetterToLowerCase(SystemStorageType[x].toString()));
        return { name: name, id: x.toString() };
    });
};

export const entityTypeOptions = (translateString: any) => {
    return getEnumValues(SystemEntityType).map((x) => {
        var name = translateString(setFirstLetterToLowerCase(SystemEntityType[x].toString()));
        return { name: name, id: x.toString() };
    });
};

export const dataTypeOptions = (translateString: any) => {
    return getEnumValues(SystemDataType).map((x) => {
        var name = translateString(setFirstLetterToLowerCase(SystemDataType[x].toString()));
        return { name: name, id: x.toString() };
    });
};
