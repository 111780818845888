import { createElement } from "react";
import { deleteHttp } from "../../../../common/api/apiShared";
import { SystemDocumentDeleteDialogProps } from "./SystemDocumentDeleteDialog";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useMutation } from "react-query";
import { Trans } from "react-i18next";

export function useSystemDocumentDeleteDialog(props: SystemDocumentDeleteDialogProps) {
    const snackbar = useDotLegalSnackbar();

    const deleteMutation = useMutation(deleteDocument);
    const deleteDocumentClick = (documentId: string) => {
        deleteMutation.mutateAsync(documentId!, {
            onSuccess: () => {
                props.onCloseDialog();
                props.onDeletedDocument();
                snackbar.show(createElement(Trans, { i18nKey: "documentDeleted", values: { document: props.selectedDocument!.name } }));
            },
        });
    };

    function deleteDocument(documentId: string) {
        return deleteHttp<string>(`/system/${props.systemId}/document/${documentId}`);
    }

    return {
        deleteDocumentClick,
        inProgess: deleteMutation.isLoading,
    };
}
