import { useState } from "react";
import { IDataCategoryBoxProps } from "./DataCategoryBox.tsx";
import { useQuery } from "react-query";
import { get } from "../../../common/api/apiShared.ts";
import { DataType } from "../../../masterData/dataCategory/dataTypes/DataTypesList.types.ts";

export function useDataCategoryBox(props: IDataCategoryBoxProps) {
    const [dataTypeSpecificationClicked, setDataTypeSpecificationClicked] = useState(false);
    const url = "/DataTypes";
    const { isLoading: dataTypesLoading, data: allDataTypes } = useQuery(url, () => get<Array<DataType>>(url));
    const getDataTypeName = (id: string) => allDataTypes?.find((dataType) => dataType.id === id)?.name;

    let dataTypeNames: string;

    if (props.dataCategory.isSelected) {
        dataTypeNames = props.dataCategory.selectedDataTypeIds.map((sdt) => getDataTypeName(sdt)).join(", ");
    } else {
        dataTypeNames = props.dataCategory.dataTypeIds.map((sdt) => getDataTypeName(sdt)).join(", ");
    }
    const onDataCategoryClick = (isSelected: boolean) => {
        props.onDataCategoryClick(isSelected, { dataCategoryId: props.dataCategory.id, dataTypeIds: props.dataCategory.dataTypeIds });
    };

    return {
        setDataTypeSpecificationClicked,
        dataTypeSpecificationClicked,
        onDataCategoryClick,
        getDataTypeName,
        dataTypeNames,
        dataTypesLoading,
        allDataTypes,
    };
}
