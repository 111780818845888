import { Badge, Box, Menu } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import { DotLegalButton, DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import DotLegalFullScreenSpinner from "../../common/components/dotLegalFullScreenSpinner/DotLegalFullScreenSpinner";
import DotLegalNotificationCard from "../../common/components/dotLegalNotificationCard/DotLegalNotificationCard";
import { notificationIcon } from "../../common/icons";
import { useTranslation } from "../../localization/useTranslation";
import { getNotificationDescription, getNotificationHeader, NotificationViewModel } from "../../notification/Notification.types";
import { useNavigationLinkStyles } from "../navigationLink/NavigationLink.styles";
import { useNotificaitionMenu } from "./NotificationMenu.hooks";
import { useNotificationMenuStyle } from "./NotificationMenu.styles";
import NotificationEmptyState from "../../notification/notificationEmptyState/NotificationEmptyState";

export interface INotificationMenuProps {
    isExpanded: boolean;
    showNotificationIcon: boolean;
    isLoading: boolean;
    data: NotificationViewModel[] | undefined;
}

function NotificationMenu(props: INotificationMenuProps) {
    const location = useLocation();
    const navigationStyles = useNavigationLinkStyles(props.isExpanded, isActive(), true);
    const notificationStyles = useNotificationMenuStyle();

    const { translateString } = useTranslation();
    const { anchorEl, setAnchorEl, open, getNotificationUrl } = useNotificaitionMenu();

    const GetIconAndBadge = () => {
        if (props.showNotificationIcon)
            return (
                <Badge sx={{ "& .MuiBadge-badge": { backgroundColor: "#FA9CAE" } }} variant="dot">
                    {notificationIcon}
                </Badge>
            );
        return notificationIcon;
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function isActive() {
        let pathname = location.pathname;
        return pathname.indexOf("privacy/notifications") > -1;
    }

    const notificationCardSize = "compact";
    function getMenu() {
        return (
            <Menu
                sx={notificationStyles.menu}
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={open}
                onClose={() => handleClose()}
            >
                {props.isLoading ? (
                    <DotLegalFullScreenSpinner />
                ) : (
                    <Box sx={notificationStyles.menuContentContainer}>
                        {props.data!.length > 0 ? (
                            <React.Fragment>
                                <DotLegalHeader headerStyle="medium">{translateString("notifications")}</DotLegalHeader>

                                {props.data?.map((notification) => {
                                    return (
                                        <React.Fragment key={notification.id}>
                                            <DotLegalNotificationCard
                                                notificationType={notification.notificationType}
                                                size={notificationCardSize}
                                                header={getNotificationHeader(notification.notificationType, translateString)}
                                                description={getNotificationDescription(notification, translateString, notificationCardSize)}
                                                taskDate={notification.created}
                                                taskHref={`${getNotificationUrl()}?notificationId=${notification.id}`}
                                                taskHrefText={translateString("readMore")}
                                                isRead={notification.isRead}
                                                onDelete={() => {}}
                                                onReadMoreClick={() => setAnchorEl(null)}
                                                clickable
                                            />
                                        </React.Fragment>
                                    );
                                })}
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        "& .MuiButton-root": { alignSelf: "center", marginTop: 2 },
                                    }}
                                >
                                    <DotLegalButton onClick={() => setAnchorEl(null)} href={getNotificationUrl()} buttonType={"secondary"}>
                                        {translateString("seeAllNotifications")}
                                    </DotLegalButton>
                                </Box>
                            </React.Fragment>
                        ) : (
                            <NotificationEmptyState notificationText={translateString("noNotifications")} isPopOver />
                        )}
                    </Box>
                )}
            </Menu>
        );
    }

    return props.isExpanded ? (
        <>
            <Box component={"li"} sx={navigationStyles.wrapper} onClick={(event: React.MouseEvent<HTMLElement>) => handleClick(event)}>
                <Box sx={[navigationStyles.link, navigationStyles.menuItem]} className={isActive() ? "active" : ""}>
                    <Box sx={navigationStyles.menuIcon}>{GetIconAndBadge()}</Box>
                    <Box component={"span"} sx={navigationStyles.text}>
                        {translateString("notifications")}
                    </Box>
                </Box>
            </Box>
            {getMenu()}
        </>
    ) : (
        <>
            <Box component={"li"} sx={navigationStyles.wrapper} onClick={(event: React.MouseEvent<HTMLElement>) => handleClick(event)}>
                <Box sx={[navigationStyles.link, navigationStyles.menuItem]} className={isActive() ? "active" : ""}>
                    <Box sx={navigationStyles.menuIcon}>{GetIconAndBadge()}</Box>
                    <Box component={"span"} sx={[navigationStyles.text, navigationStyles.isMinimized]}>
                        {translateString("notifications")}
                    </Box>
                </Box>
            </Box>
            {getMenu()}
        </>
    );
}

export default NotificationMenu;
