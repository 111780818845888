import React from "react";
import DotLegalDeleteDialogWrapper from "../../../common/components/dotLegalDeleteDialog/DotLegalDeleteDialogWrapper";
import { PolicyDocument } from "../PolkcyDocuments.types";
import { useDeletePolicyDocumentHook } from "./DeletePolicyDocumentDialog.hooks";

export interface IDeletePolicyDocumentDialogProps {
    onCloseDialog: () => void;
    onSaveCorrect: () => void;
    selectedDocument: PolicyDocument;
    policyId: string | undefined;
}

export function DeletePolicyDocumentDialog(props: IDeletePolicyDocumentDialogProps) {
    const { deleteDocumentClick, inProgess } = useDeletePolicyDocumentHook(props);

    return (
        <DotLegalDeleteDialogWrapper
            open
            itemForDeletion={props.selectedDocument!.name}
            onDialogClose={props.onCloseDialog}
            onOkClick={() => deleteDocumentClick(props.selectedDocument!.documentId)}
            inProgress={inProgess}
        />
    );
}
