import { SelectableDocumentItem } from "../processingActivity/ProcessingActivity.types";
import { isNullOrWhitespace } from "./stringOperations";

export function downloadFileOrOpenLink(
    documentId: string,
    documents: Array<SelectableDocumentItem>,
    downloadDocument: (documentId: string, fileDownloadName: string) => void
) {
    const document = documents.find((x) => x.id === documentId);
    if (isNullOrWhitespace(document!.link)) {
        downloadDocument(documentId, document!.fileDownloadName);
    } else {
        window.open(document!.link, "_blank");
    }
}
