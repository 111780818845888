import { DotLegalPrettyScrollbar } from "@dotlegal/dotlegal-ui-components";
import { Box } from "@mui/material";
import React, { useEffect } from "react";
import CommentCard from "../commentCard/CommentCard";
import { CommentViewModel } from "../commentCard/CommentCard.types";

export interface ICommentTrack {
    data: Array<CommentViewModel> | undefined;
    scrollToBottomOnNewComment?: boolean;
    maxHeight: string | number;
    commentIdToScroll?: string;
    disableOverflowMenu: boolean;
    onDeleteComment: (commentId: string) => void;
    onDeleteSubComment: (commentId: string, subCommentId: string) => void;
    onPinClick?: (isPinned: boolean, commentId: string) => void;
    onEditComment: (commentId: string, content: string, pinned: boolean) => void;
    onEditSubComment: (commentId: string, subCommentId: string, content: string) => void;
    onReplyToComment: (commentId: string) => void;
}

function CommentTrack(props: ICommentTrack) {
    const messagesEndRef = React.createRef<HTMLDivElement>();

    useEffect(() => {
        if (props.scrollToBottomOnNewComment) {
            scrollToBottom();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.scrollToBottomOnNewComment, props.commentIdToScroll]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <DotLegalPrettyScrollbar>
            <Box marginBottom={"8px"} sx={{ width: "100%" }}>
                {props.data?.map((comment, i) => {
                    return (
                        <React.Fragment key={comment.id}>
                            {props.commentIdToScroll === comment.id && <div ref={messagesEndRef}></div>}
                            <CommentCard
                                key={comment.id}
                                onDeleteClick={() => props.onDeleteComment(comment.id)}
                                comment={comment}
                                disableOverFlowMenu={props.disableOverflowMenu}
                                onReplyClick={() => {
                                    props.onReplyToComment(comment.id);
                                }}
                                isSubComment={false}
                                onEditClick={() => {
                                    props.onEditComment(comment.id, comment.content, comment.pinned ?? false);
                                }}
                                hasSubComments={comment.subComments.length > 0}
                                onPinClick={
                                    props.onPinClick
                                        ? (val: boolean) => {
                                              if (props.onPinClick) {
                                                  props.onPinClick(val, comment.id);
                                              }
                                          }
                                        : undefined
                                }
                            />
                            {comment.subComments.map((subComment, i) => {
                                return (
                                    <React.Fragment key={subComment.id}>
                                        {props.commentIdToScroll === subComment.id && <div ref={messagesEndRef}></div>}
                                        <CommentCard
                                            key={subComment.id}
                                            onDeleteClick={() => props.onDeleteSubComment(comment.id, subComment.id)}
                                            comment={subComment}
                                            disableOverFlowMenu={props.disableOverflowMenu}
                                            isSubComment
                                            onEditClick={() => {
                                                props.onEditSubComment(comment.id, subComment.id, subComment.content);
                                            }}
                                            hasSubComments={false}
                                        />
                                    </React.Fragment>
                                );
                            })}
                        </React.Fragment>
                    );
                })}
            </Box>
        </DotLegalPrettyScrollbar>
    );
}

export default CommentTrack;
