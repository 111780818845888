import { useStateLocalStorage } from "@dotlegal/dotlegal-ui-components";
import { useTrialInformationHook } from "../../../trial/TrialInformation.hooks.tsx";
import { useQuery } from "react-query";
import { get } from "../../../common/api/apiShared.ts";
import { getSettings } from "../../../common/settingsProvider.ts";

export function useUpgradeCart() {
    const [upgradeCardDismissed, setUpgradeCardDismissed] = useStateLocalStorage<number | undefined>("upgrade-plan-card-dismissed", undefined);

    let { data: isFullyUpgraded } = useQuery("fullyUpgraded", () => get<boolean>("/plan/fullyUpgraded/"));

    let showUpgradeBox = (isFullyUpgraded ?? true) ? false : true;

    if (upgradeCardDismissed) {
        showUpgradeBox = getDaysSinceLastShown(upgradeCardDismissed) >= 14 && showUpgradeBox;
    }

    function onHideUpgradeBox() {
        setUpgradeCardDismissed(new Date().getTime());
    }

    function getDaysSinceLastShown(savedTimeAsString: number) {
        const diff = new Date().getTime() - new Date(savedTimeAsString).getTime();
        return diff / 1000 / 60 / 60 / 24; // Convert milliseconds to days
    }

    return { onHideUpgradeBox, showUpgradeBox };
}
