import React, { useEffect } from "react";
import { useAuth } from "oidc-react";

export interface ISignOutProps {}

function SignOut(props: ISignOutProps) {
    const auth = useAuth();

    useEffect(() => {
        localStorage.removeItem("customerId");
        auth.userManager.signoutRedirect();
    });

    return <div></div>;
}

export default SignOut;
