import { Box, Grid, Skeleton, styled, TableCell } from "@mui/material";
import React, { useRef, useState } from "react";
import { GridSizes } from "../../common/components/dotLegalTable/DotLegalTable.types";
import { useTranslation } from "../../localization/useTranslation";
import { hasAccessToProcessingActivitySteps, useProcessingActivitiesDataMapping } from "./ProcessingActivities.hooks";
import { useProcessingActivitiesStyle } from "./ProcessingActivities.styles";
import { ProcessingActivityOverViewRow, ProcessingActivityStatus } from "./ProcessingActivities.types";
import SystemSvg from "./systems.svg?react";
import AssociationSvg from "./associations.svg?react";
import DataProcessorSvg from "./dataProcessors.svg?react";
import TransferSvg from "./transfers.svg?react";
import SourceSvg from "./sources.svg?react";
import {
    DotLegalButton,
    DotLegalChip,
    DotLegalCompletedPercentage,
    DotLegalEmptyState,
    DotLegalMultiSelect,
    DotLegalOverflowMenu,
    DotLegalOverflowMenuItem,
    DotLegalPageHeader,
    DotLegalTableWithControls,
    DotLegalTooltip,
    ITableHeader,
    useHistoryWithReferer,
    useIsOnSmallScreen,
} from "@dotlegal/dotlegal-ui-components";
import DotLegalPaper from "../../common/components/dotLegalPaper/DotLegalPaper";
import HeaderImage from "./addProcessingActivity.svg?react";
import { reactPlugin } from "../../AppInsights";
import { useAppInsightsContext, useTrackMetric, withAITracking } from "@microsoft/applicationinsights-react-js";
import { TrackingEvent, useTrackAIEvent } from "../hooks/useTracking";
import AddProcessingActivityDialog from "../addProcessingActivityDialog/AddProcessingActivityDialog";
import { useUrlProvider } from "../../useUrlProvider";
import DotLegalCompanySelector from "../../common/components/dotLegalCompanySelector/DotLegalCompanySelector";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import Rocket from "./rocket.svg?react";
import ProcessingActivityTemplatesDialog from "../processingActivityTemplatesDialog/ProcessingActivityTemplatesDialog";
import RiskIndicator from "../../riskAssessment/riskIndicator/RiskIndicator";
import { RiskAssessmentType } from "../../riskAssessment/RiskAssessment.types";
import useWindowDimensions from "../../common/hooks/useWindowDimensions";
import UpdateprocessingTemplateInfoDialog from "../updateProcessingTemplateInfoDialog/UpdateProcessingTemplateInfoDialog";
import { isTemplateCustomer } from "../../common/templateCustomerHelper";
import ProcessingActivityDeleteDialog from "../processingActivityDeleteDialog/ProcessingActivityDeleteDialog";
import { useNavMenuDataMapping } from "../../main/NavMenu/NavMenu.hooks";
import CustomListSelector from "../../customLists/customListSelector/CustomListSelector";
import { CustomListSelectorType } from "../../customLists/customListSelector/CustomListSelector.types";
import CopyProcessingActivityDialog from "../copyProcessingActivityDialog/CopyProcessingActivityDialog";
import {
    processingActivityArchived,
    processingActivityAwaitingResearcher,
    processingActivityDraft,
    processingActivityDraftSubmitted,
    PurpleLockIcon,
    WhiteLockIcon,
} from "../../common/icons";
import PurpleLockSvg from "../../common/svgs/purpleLock.svg?react";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";
import PlanDialogButtonWrapper from "../../plan/planDialogButtonWrapper/planDialogButtonWrapper";
import DotLegalAllOrMySwitchBox from "../../common/components/dotLegalSwitch/dotLegalAllOrMySwitchBox/dotLegalAllOrMySwitchBox";
import { RiskAssessmentArea, RiskComplianceArea } from "../../riskAssessment/riskAssessments/RiskAssessments.types";
import { useProcessingActivityValidation } from "../processingActivityOverview/ProcessingActivityValidation.hooks";
import ProcessingActivtyReportDialog from "../../processingActivityReport/processingActivityReportDialog/ProcessingActivityReportDialog";
import { Trans } from "react-i18next";
import { GetProcessingActivityPlanLimit } from "../../auth/planProvider/PlanProvider.hooks.ts";
import TrialUsageWarning from "../../common/components/TrialUsageWarning.tsx";
import TrialExpiredRowWrapper from "../../common/components/TrialExpiredRowWrapper.tsx";

function ProcessingActivities() {
    const { getEditProcessingActivityUrl, getProcessingActivityOverviewUrl, getProcessingActivityStatisticUrl } = useUrlProvider();
    const { translateString, translateDateWithTime } = useTranslation();
    const { getProcessingActivityRiskScenariosDialog, getRiskAssessmentQuestionsDialog } = useUrlProvider();

    const {
        isLoading,
        processingActivityData,
        deleteProcessingActivtitySuccess,
        onlyUserSpecific,
        setOnlyUserSpecific,
        progressData,
        dataCategoryData,
        dataSubjectData,
        businessAreaData,
        risksItems,
        selectableCompanyItems,
        searchedStatus,
        setSearchedStatus,
        searchedDataCategories,
        setSearchedDataCategories,
        searchedDataSubjects,
        setSearchedDataSubjects,
        searchedBusinessAreas,
        setSearchedBusinessAreas,
        searchedRisks,
        setSearchedRisks,
        showAddStandardProcessings,
        setShowAddStandardProcessings,
        onProcessingActivityDialogClose,
        hasProcessingActivities,
        overAllStatus,
        showUpdateProcessingTemplateInfo,
        setShowUpdateProcessingTemplateInfo,
        refetchPage,
        dueProcessingsData,
        showCloneDialog,
        setShowCloneDialog,
        copyModel,
        setCopyModel,
        statusItems,
        status,
        setStatus,
        riskAndProgreesIsLoading,
        searchedPurposes,
        setSearchedPurposes,
        purposeData,
        setShowProcessingActivityReport,
        showProcessingActivityReport,
    } = useProcessingActivitiesDataMapping();
    const { refetchMenuNotifications } = useNavMenuDataMapping();
    const { addProcessingActivityValidation } = useProcessingActivityValidation();

    const { width } = useWindowDimensions();
    const styles = useProcessingActivitiesStyle(width);
    const historyWithReferer = useHistoryWithReferer();
    const trackEvent = useTrackAIEvent();
    const isOnSmallScreen = useIsOnSmallScreen();
    const appInsights = useAppInsightsContext();
    const trackComponent = useTrackMetric(appInsights, "ProcessingActivities");
    const hasTracked = useRef(false);
    const { permissions, customerName, customerId } = useUserContext();
    const { addonsFeatures, dataProtectionFeatures } = usePlanContext();
    const { data: processingActivityPlanLimitData, refetch: refetchProcessingActivityLimitPlan } = GetProcessingActivityPlanLimit();

    if (!hasTracked.current) {
        trackComponent();
        hasTracked.current = true;
    }

    const [showDialog, setShowDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState({
        showDialogState: false,
        chosenProcessingActivityId: "",
        processingActivityName: "",
    });

    const breadCrumbs = [
        {
            name: translateString("processingActivities"),
        },
    ];

    const getHeaders = () => {
        let headers = Array<ITableHeader<ProcessingActivityOverViewRow>>();
        headers.push({
            property: "name",
            text: translateString("processingActivity"),
            align: "left",
            showOnMobile: true,
        });

        if (addonsFeatures.research) {
            headers.push({ property: "approvelNumber", text: translateString("approvelNumber"), align: "left", showOnMobile: true });
        }

        headers.push({ property: "businessAreaSorting", text: translateString("processingActivityAreas"), align: "left", showOnMobile: false });

        if (addonsFeatures.research) {
            headers.push({ property: "lastStatusUpdate", text: translateString("lastUpdated"), align: "left", showOnMobile: false });
            headers.push({ property: "owner", text: translateString("owner"), align: "left", showOnMobile: false });
        }

        if (processingActivityData.hasMultipleGroupCompanies) {
            headers.push({
                property: "legalEntityCount",
                text: translateString("associatedCompaniesCount"),
                align: "left",
                showOnMobile: false,
            });
        }

        headers.push({ property: "systemCount", text: translateString("systems"), align: "left", showOnMobile: false });

        headers.push({
            property: "processingCount",
            text: translateString("sharings"),
            align: "left",
            showOnMobile: false,
        });

        if (permissions.canManageRiskAssessment) {
            headers.push({ property: "scenariosScore", text: translateString("riskClassification"), align: "left", showOnMobile: false, width: "130" });
            headers.push({ property: "riskAssessmentTotalScore", text: translateString("risk"), align: "left", showOnMobile: false, width: "130" });
        }

        if (!addonsFeatures.research) {
            headers.push({ property: "progress", text: translateString("processingActivityProgress"), align: "left", showOnMobile: false, width: "238px" });
            headers.push({ property: "userFriendlyId", text: "", align: "left", showOnMobile: false, hideHeader: true });
        }

        headers.push({ property: null, text: "", align: "left", showOnMobile: true });

        return headers;
    };

    const getMenuItems = (
        processingActivityId: string,
        processingActivityName: string,
        canValidateProcessingActivity: boolean,
        status: ProcessingActivityStatus
    ) => {
        let menuItems: Array<DotLegalOverflowMenuItem> = [];

        if (!processingActivityPlanLimitData?.limitExceeded) {
            menuItems.push({
                menuItem: translateString("overview"),
                onClick: () => {
                    gotoOverview(processingActivityId, processingActivityName);
                },
            });

            if (hasAccessToProcessingActivitySteps(permissions))
                menuItems.push({
                    menuItem: translateString("edit"),
                    onClick: () => onEditClick(processingActivityId, processingActivityName),
                });

            if (permissions.canCopyProcessingActivity) {
                if (permissions.processingActivityPermissions.create) {
                    if (processingActivityPlanLimitData?.limitReached) {
                        menuItems.push({
                            menuItem: (
                                <Box sx={{ display: "flex" }}>
                                    <span>{translateString("copy")}</span>
                                    <Box sx={{ marginLeft: 0.5 }}>
                                        <PurpleLockSvg />
                                    </Box>
                                </Box>
                            ),
                            tooltip: translateString("processingActivityLimitReached", { count: dataProtectionFeatures.maxNumberOfProcessingActivities }),
                            disabled: processingActivityPlanLimitData.limitReached,
                            tooltipPosition: "left",
                        });
                    } else {
                        menuItems.push({
                            menuItem: translateString("copy"),
                            onClick: () => {
                                setShowCloneDialog(true);
                                setCopyModel({ id: processingActivityId, name: processingActivityName });
                            },
                        });
                    }
                }
            }
        }

        if (permissions.processingActivityPermissions.delete)
            menuItems.push({
                menuItem: translateString("delete"),
                onClick: () => OnDeleteClick(processingActivityId, processingActivityName),
            });

        if (!processingActivityPlanLimitData?.limitExceeded) {
            if (permissions.canManageCustomers && isTemplateCustomer())
                menuItems.push({
                    menuItem: translateString("handleTemplateProcessing"),
                    onClick: () => setShowUpdateProcessingTemplateInfo({ showDialog: true, processingActivityId: processingActivityId }),
                });

            if (hasAccessToProcessingActivitySteps(permissions) && canValidateProcessingActivity) {
                if (dataProtectionFeatures.processingActivityValidationFlow) {
                    menuItems.push({
                        menuItem: translateString("validateProcessingActivity"),
                        onClick: async () => {
                            await addProcessingActivityValidation(processingActivityId);
                        },
                    });
                } else {
                    menuItems.push({
                        menuItem: (
                            <Box
                                sx={{
                                    cursor: "default",
                                    display: "flex",
                                    alignContent: "center",
                                }}
                            >
                                <Box component={"span"}>{translateString("validateProcessingActivity")}</Box>
                                <Box component={"span"} sx={{ marginLeft: 0.5, height: 0 }}>
                                    <PurpleLockSvg />
                                </Box>
                            </Box>
                        ),
                        tooltip: translateString("upgradeDataProtectionPlan"),
                        disabled: true,
                    });
                }
            }

            if (addonsFeatures.research) {
                menuItems.push({
                    menuItem: translateString("report"),
                    onClick: () => {
                        setShowProcessingActivityReport({
                            showDialog: true,
                            processingActivityId: processingActivityId,
                            processingActivityName: processingActivityName,
                            processingActivityStatus: status,
                        });
                    },
                });
            }
        }

        return menuItems;
    };

    const onEditClick = (processingActivityId: string, processingActivityName: string) => {
        trackEvent(TrackingEvent.EditProcessingActivityClicked, {
            name: processingActivityName,
            id: processingActivityId,
        });
        historyWithReferer.push(getEditProcessingActivityUrl(processingActivityId));
    };

    const OnDeleteClick = (processingActivityId: string, processingActivityName: string) => {
        setShowDeleteDialog({
            showDialogState: true,
            chosenProcessingActivityId: processingActivityId,
            processingActivityName: processingActivityName,
        });
        trackEvent(TrackingEvent.DeleteProcessingActivityClicked, {
            name: processingActivityName,
            id: processingActivityId,
        });
    };

    const OnHideDeleteDialog = () => {
        setShowDeleteDialog({ showDialogState: false, chosenProcessingActivityId: "", processingActivityName: "" });
    };

    const OnClick = () => {
        setShowDialog(true);
        trackEvent(TrackingEvent.AddProcessingActivityClicked);
    };

    const OnHideDialog = () => {
        setShowDialog(false);
    };

    const gotoOverview = (activity: string, processingActivityName: string) => {
        trackEvent(TrackingEvent.ProcessingActivityOverviewClicked, { name: processingActivityName, id: activity });
        historyWithReferer.push(getProcessingActivityOverviewUrl(activity));
    };

    const getIsValidationDueText = (id: string) => {
        if (dueProcessingsData?.find((x) => x.processingActivityId === id)?.isDue) return translateString("processingValidationDue");
    };

    const tableGridProps: GridSizes = {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 4,
        xl: true,
    };

    const IconTableCell = styled(TableCell)(({ theme }) => ({
        color: theme.palette.grey[600] + " !important",
        fontWeight: theme.typography.fontWeightBold,
        "& svg": {
            width: 13,
            height: 13,
            stroke: theme.palette.grey[300],
            marginLeft: 8,
        },
    }));

    const ArchiveTableCell = styled(TableCell)(({ theme }) => ({
        color: theme.palette.grey[600] + " !important",
        textDecoration: "line-through !important",
        fontWeight: theme.typography.fontWeightBold,
        "& svg": {
            width: 13,
            height: 13,
            stroke: theme.palette.grey[300],
            marginLeft: 8,
        },
    }));

    const getNameTableCell = (status: ProcessingActivityStatus, name: string, rowIndex: number) => {
        const htmlId = `processing-activity-row-${rowIndex}-name`;
        switch (status) {
            case ProcessingActivityStatus.Draft:
                return (
                    <IconTableCell sx={styles.bold} id={htmlId}>
                        <DotLegalTooltip text={translateProcessingActivityStatus(status)}>
                            <Box sx={styles.imgContainer}>
                                {name} {processingActivityDraft}
                            </Box>
                        </DotLegalTooltip>
                    </IconTableCell>
                );
            case ProcessingActivityStatus.Archived:
            case ProcessingActivityStatus.Cancelled:
                return (
                    <ArchiveTableCell id={htmlId}>
                        <DotLegalTooltip text={translateProcessingActivityStatus(status)}>
                            <Box sx={styles.imgContainer}>
                                {name} {processingActivityArchived}
                            </Box>
                        </DotLegalTooltip>
                    </ArchiveTableCell>
                );
            case ProcessingActivityStatus.AwaitingResearcher:
                return (
                    <IconTableCell sx={styles.bold} id={htmlId}>
                        <DotLegalTooltip text={translateProcessingActivityStatus(status)}>
                            <Box sx={styles.imgContainer}>
                                {name} {processingActivityAwaitingResearcher}
                            </Box>
                        </DotLegalTooltip>
                    </IconTableCell>
                );
            case ProcessingActivityStatus.DraftSubmitted:
                return (
                    <IconTableCell sx={styles.bold} id={htmlId}>
                        <DotLegalTooltip text={translateProcessingActivityStatus(status)}>
                            <Box sx={styles.imgContainer}>
                                {name} {processingActivityDraftSubmitted}
                            </Box>
                        </DotLegalTooltip>
                    </IconTableCell>
                );
            default:
                return (
                    <TableCell id={htmlId} sx={styles.bold}>
                        {name}
                    </TableCell>
                );
        }
    };

    function translateProcessingActivityStatus(status: ProcessingActivityStatus) {
        switch (status) {
            case ProcessingActivityStatus.DraftSubmitted:
                return translateString("processingActivityIsDraftSubmitted");
            case ProcessingActivityStatus.AwaitingResearcher:
                return translateString("processingActivityIsAwaitingResearcher");
            case ProcessingActivityStatus.Draft:
                return translateString("processingActivityIsDraft");
            case ProcessingActivityStatus.Archived:
                return translateString("processingActivityIsArchive");
            case ProcessingActivityStatus.Cancelled:
                return translateString("processingActivityIsCancelled");
            default:
                return "Unknown status";
        }
    }

    const getSearchFields = () => {
        return (
            <React.Fragment>
                {!addonsFeatures.research && (
                    <Grid item {...tableGridProps}>
                        <DotLegalMultiSelect
                            onChange={setSearchedStatus}
                            chosenOptions={searchedStatus}
                            label={translateString("processingActivityProgress")}
                            placeholder={translateString("processingActivityProgress")}
                            isLoading={isLoading}
                            options={progressData}
                            noMargin
                            limit={1}
                            noOptionsLabel={translateString("noOptions")}
                        />
                    </Grid>
                )}

                <Grid item {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedDataCategories}
                        chosenOptions={searchedDataCategories}
                        label={translateString("dataCategoryHeader")}
                        placeholder={translateString("dataCategoryHeader")}
                        isLoading={isLoading}
                        options={dataCategoryData}
                        noMargin
                        limit={1}
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>
                <Grid item {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedDataSubjects}
                        chosenOptions={searchedDataSubjects}
                        label={translateString("dataSubjects")}
                        placeholder={translateString("dataSubjects")}
                        isLoading={isLoading}
                        options={dataSubjectData}
                        noMargin
                        limit={1}
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>
                <Grid item {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedBusinessAreas}
                        chosenOptions={searchedBusinessAreas}
                        label={translateString("processingActivityAreas")}
                        placeholder={translateString("processingActivityAreas")}
                        isLoading={isLoading}
                        options={businessAreaData}
                        noMargin
                        limit={1}
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>
                <Grid item {...tableGridProps}>
                    <DotLegalMultiSelect
                        label={translateString("purpose")}
                        placeholder={translateString("purpose")}
                        isLoading={isLoading}
                        noMargin
                        limit={1}
                        noOptionsLabel={translateString("noOptions")}
                        options={purposeData}
                        onChange={setSearchedPurposes}
                        chosenOptions={searchedPurposes}
                    />
                </Grid>
                {permissions.canManageRiskAssessment && (
                    <Grid item {...tableGridProps}>
                        <DotLegalMultiSelect
                            onChange={setSearchedRisks}
                            chosenOptions={searchedRisks}
                            label={translateString("risk")}
                            placeholder={translateString("risk")}
                            isLoading={isLoading}
                            options={risksItems}
                            noMargin
                            limit={1}
                            noOptionsLabel={translateString("noOptions")}
                        />
                    </Grid>
                )}
                {permissions.processingActivityStatusManagement && (
                    <Grid item {...tableGridProps}>
                        <DotLegalMultiSelect
                            onChange={setStatus}
                            chosenOptions={status}
                            options={statusItems}
                            label={translateString("status")}
                            placeholder={translateString("status")}
                            noMargin
                            noOptionsLabel={translateString("noOptions")}
                            isLoading={isLoading}
                        />
                    </Grid>
                )}
            </React.Fragment>
        );
    };

    const getButtons = () => {
        if (processingActivityPlanLimitData?.limitReached !== undefined) {
            return (
                <Box sx={{ display: "flex" }}>
                    <React.Fragment>
                        {permissions.processingActivityPermissions.create && (
                            <PlanDialogButtonWrapper
                                text={translateString("processingActivityLimitReached", {
                                    count: dataProtectionFeatures.maxNumberOfProcessingActivities,
                                })}
                                enabled={processingActivityPlanLimitData.limitReached}
                            >
                                <DotLegalButton
                                    buttonType="primary"
                                    size="medium"
                                    onClick={OnClick}
                                    disabled={processingActivityPlanLimitData.limitReached}
                                    endIcon={processingActivityPlanLimitData.limitReached ? WhiteLockIcon : undefined}
                                    toolTip={
                                        processingActivityPlanLimitData.limitReached
                                            ? translateString("processingActivityLimitReached", {
                                                  count: dataProtectionFeatures.maxNumberOfProcessingActivities,
                                              })
                                            : undefined
                                    }
                                >
                                    {translateString("createProcessingActivity")}
                                </DotLegalButton>
                            </PlanDialogButtonWrapper>
                        )}
                        {permissions.canManageProcessingActivityStandardTemplates && (
                            <Box
                                sx={(theme) => ({
                                    "& .MuiButton-root": {
                                        marginLeft: theme.spacing(2),
                                        [theme.breakpoints.down("xs")]: {
                                            marginLeft: 0,
                                            marginTop: theme.spacing(1),
                                            display: "block",
                                        },
                                    },
                                })}
                            >
                                <PlanDialogButtonWrapper
                                    text={translateString("processingActivityLimitReached", {
                                        count: dataProtectionFeatures.maxNumberOfProcessingActivities,
                                    })}
                                    enabled={processingActivityPlanLimitData.limitReached}
                                >
                                    <DotLegalButton
                                        buttonType="secondary"
                                        size="medium"
                                        onClick={() => setShowAddStandardProcessings(true)}
                                        disabled={processingActivityPlanLimitData.limitReached}
                                        endIcon={processingActivityPlanLimitData.limitReached ? PurpleLockIcon : undefined}
                                        toolTip={
                                            processingActivityPlanLimitData.limitReached
                                                ? translateString("processingActivityLimitReached", {
                                                      count: dataProtectionFeatures.maxNumberOfProcessingActivities,
                                                  })
                                                : undefined
                                        }
                                    >
                                        {translateString("addStandrdProcessingActivity")}
                                    </DotLegalButton>
                                </PlanDialogButtonWrapper>
                            </Box>
                        )}
                    </React.Fragment>
                    {permissions.customListPermissions.read && <CustomListSelector type={CustomListSelectorType.ProcessingActivity} />}
                    {addonsFeatures.research && permissions.canAccessProcessingActivityStatistic && (
                        <Box
                            sx={(theme) => ({
                                "& .MuiButton-root": {
                                    marginLeft: theme.spacing(2),
                                    [theme.breakpoints.down("xs")]: {
                                        marginLeft: 0,
                                        marginTop: theme.spacing(1),
                                        display: "block",
                                    },
                                },
                            })}
                        >
                            <DotLegalButton buttonType="secondary" size="medium" href={getProcessingActivityStatisticUrl()}>
                                {translateString("statistic")}
                            </DotLegalButton>
                        </Box>
                    )}
                </Box>
            );
        } else {
            return <div></div>;
        }
    };

    return (
        <React.Fragment>
            <DotLegalPageHeader
                userContext={{ customerName }}
                rightComponent={<DotLegalCompanySelector isLoading={isLoading} companies={selectableCompanyItems} />}
                leftComponent={<DotLegalAllOrMySwitchBox onChange={setOnlyUserSpecific} enabled={onlyUserSpecific} hidden={!permissions.canAccessAllData} />}
                breadCrumbs={breadCrumbs}
            />

            <Box sx={styles.container}>
                <div>
                    <DotLegalPaper sx={styles.paperHeader} background="blueWave" backgroundCover>
                        {getButtons()}

                        {!addonsFeatures.research && (
                            <Box sx={styles.percentageContainer}>
                                <DotLegalCompletedPercentage label={translateString("overallStatus")} completed={overAllStatus} />
                            </Box>
                        )}

                        {!isOnSmallScreen && (
                            <Box sx={styles.headerImg}>
                                <HeaderImage />
                            </Box>
                        )}
                    </DotLegalPaper>
                </div>

                <Box sx={styles.paperContainer}>
                    <DotLegalPaper>
                        {hasProcessingActivities ? (
                            <>
                                {processingActivityPlanLimitData?.limitExceeded && (
                                    <TrialUsageWarning
                                        textKey={"processingActivitiesLockedWarningBodyText"}
                                        headerKey={"processingActivitiesLockedWarningHeader"}
                                        marginBottom={3}
                                    />
                                )}
                                <DotLegalTableWithControls
                                    searchFieldGridSizes={tableGridProps}
                                    searchFieldFullWidth
                                    extraControls={getSearchFields()}
                                    headers={getHeaders()}
                                    getUserSpecificPageLength={() => 30}
                                    hideRowsPerPage
                                    defaultOrderBy={"name"}
                                    defaultOrder={"asc"}
                                    isLoading={isLoading}
                                    emptyText={translateString("noProcessingActivitesMatchesQuery")}
                                    labelRowsPerPage={translateString("showEntites")}
                                    labelSearch={translateString("search")}
                                    noOptionsLabel={translateString("noOptions")}
                                    paginationLabelOf={translateString("labelOf")}
                                    clickableRows={!processingActivityPlanLimitData?.limitExceeded}
                                    data={processingActivityData?.processingActivityOverviewRows}
                                    renderRow={(row, i) => (
                                        <TrialExpiredRowWrapper
                                            key={i}
                                            onNonExpiredClick={() => gotoOverview(row.id, row.name)}
                                            tooltip={translateString("processingActivityLockedMaxReached")}
                                            expired={processingActivityPlanLimitData !== undefined && processingActivityPlanLimitData?.limitExceeded}
                                            backgroundColor={row.processingActivityStatus !== ProcessingActivityStatus.Active ? "lightgrey" : undefined}
                                            planUpgradeText={translateString("processingActivityLimitReached", {
                                                count: dataProtectionFeatures.maxNumberOfProcessingActivities,
                                            })}
                                        >
                                            {getNameTableCell(row.processingActivityStatus, row.name, i)}

                                            {addonsFeatures.research && <TableCell>{row.approvelNumber}</TableCell>}

                                            {!isOnSmallScreen && (
                                                <TableCell>
                                                    {row.businessAreas.map((x, index) => {
                                                        return (
                                                            <Box sx={styles.chip} key={x.name + index}>
                                                                <DotLegalChip
                                                                    value={x.name}
                                                                    color={x.color}
                                                                    size="small"
                                                                    isSelected
                                                                    collapse={row.businessAreas.length > 2 && index > 0}
                                                                />
                                                            </Box>
                                                        );
                                                    })}
                                                </TableCell>
                                            )}

                                            {addonsFeatures.research && !isOnSmallScreen && (
                                                <React.Fragment>
                                                    <TableCell sx={styles.bold}>{translateDateWithTime(row.lastStatusUpdate)}</TableCell>
                                                    <TableCell sx={styles.bold}>{row.owner}</TableCell>
                                                </React.Fragment>
                                            )}

                                            {!isOnSmallScreen && processingActivityData.hasMultipleGroupCompanies && (
                                                <TableCell>
                                                    <Box sx={styles.imgContainer}>
                                                        <AssociationSvg />
                                                        <Box component={"span"} sx={styles.value}>
                                                            {row.legalEntityCount}
                                                        </Box>
                                                    </Box>
                                                </TableCell>
                                            )}
                                            {!isOnSmallScreen && (
                                                <TableCell>
                                                    <Box sx={styles.imgContainer}>
                                                        <SystemSvg />
                                                        <Box component={"span"} sx={styles.value}>
                                                            {row.systemCount}
                                                        </Box>
                                                    </Box>
                                                </TableCell>
                                            )}
                                            {!isOnSmallScreen && (
                                                <TableCell>
                                                    <Box sx={{ display: "flex", flexDirection: "row", "& > * ": { marginLeft: 0.5, marginRight: 0.5 } }}>
                                                        <Box>
                                                            <DotLegalTooltip text={translateString("dataProcessors")}>
                                                                <Box sx={styles.imgContainer}>
                                                                    <DataProcessorSvg />
                                                                    <Box component={"span"} sx={styles.value}>
                                                                        {row.processingCount}
                                                                    </Box>
                                                                </Box>
                                                            </DotLegalTooltip>
                                                        </Box>
                                                        <Box>
                                                            <DotLegalTooltip text={translateString("disclosures")}>
                                                                <Box sx={styles.imgContainer}>
                                                                    <TransferSvg />
                                                                    <Box component={"span"} sx={styles.value}>
                                                                        {row.transferCount}
                                                                    </Box>
                                                                </Box>
                                                            </DotLegalTooltip>
                                                        </Box>
                                                        <Box>
                                                            <DotLegalTooltip text={translateString("sources")}>
                                                                <Box sx={styles.imgContainer}>
                                                                    <SourceSvg />
                                                                    <Box component={"span"} sx={styles.value}>
                                                                        {row.sourceCount}
                                                                    </Box>
                                                                </Box>
                                                            </DotLegalTooltip>
                                                        </Box>
                                                    </Box>
                                                </TableCell>
                                            )}
                                            {permissions.canManageRiskAssessment && !isOnSmallScreen && (
                                                <React.Fragment>
                                                    <TableCell>
                                                        {!riskAndProgreesIsLoading ? (
                                                            <RiskIndicator
                                                                riskAssessmentType={RiskAssessmentType.None}
                                                                riskScoreTextColor="darkblue"
                                                                isClassification
                                                                linkHref={
                                                                    processingActivityPlanLimitData?.limitExceeded
                                                                        ? undefined
                                                                        : getProcessingActivityRiskScenariosDialog(
                                                                              row.id,
                                                                              processingActivityData!.currentRiskAssessmentVersionId
                                                                          )
                                                                }
                                                                removeLeftMargin
                                                                score={row.scenariosScore}
                                                                hideRiskType
                                                            />
                                                        ) : (
                                                            <Skeleton width={"100%"} height={18} />
                                                        )}
                                                        <Box sx={styles.imgContainer}>
                                                            <div></div>
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Box sx={styles.imgContainer}>
                                                            {!riskAndProgreesIsLoading ? (
                                                                <RiskIndicator
                                                                    riskAssessmentType={RiskAssessmentType.None}
                                                                    riskScoreTextColor="darkblue"
                                                                    removeLeftMargin
                                                                    score={row.riskAssessmentTotalScore}
                                                                    hideRiskType
                                                                    showHighRiskWarning
                                                                    linkHref={
                                                                        processingActivityPlanLimitData?.limitExceeded
                                                                            ? undefined
                                                                            : getRiskAssessmentQuestionsDialog(
                                                                                  row.id,
                                                                                  RiskAssessmentArea.ProcessingActivities,
                                                                                  RiskComplianceArea.GDPR,
                                                                                  processingActivityData!.currentRiskAssessmentVersionId ?? "null"
                                                                              )
                                                                    }
                                                                    highRiskWarningTooltip={translateString("highRiskWarning")}
                                                                />
                                                            ) : (
                                                                <Skeleton width={"100%"} height={18} />
                                                            )}
                                                        </Box>
                                                    </TableCell>
                                                </React.Fragment>
                                            )}
                                            {!addonsFeatures.research && !isOnSmallScreen && (
                                                <TableCell>
                                                    <Box sx={styles.percentageContainer}>
                                                        {!riskAndProgreesIsLoading ? (
                                                            <DotLegalCompletedPercentage hoverText={getIsValidationDueText(row.id)} completed={row.progress!} />
                                                        ) : (
                                                            <Skeleton height={18} width={238} />
                                                        )}
                                                    </Box>
                                                </TableCell>
                                            )}
                                            <TableCell align={"right"} onClick={(e) => e.stopPropagation()}>
                                                <DotLegalOverflowMenu
                                                    items={getMenuItems(row.id, row.name, row.canValidateProcessingActivity, row.processingActivityStatus)}
                                                />
                                            </TableCell>
                                        </TrialExpiredRowWrapper>
                                    )}
                                />
                            </>
                        ) : (
                            <DotLegalEmptyState
                                icon={<Rocket />}
                                text={
                                    <React.Fragment>
                                        <Box component={"span"} sx={styles.noDataInfoHeader}>
                                            {translateString("noProcessingActivityHeader")}
                                        </Box>
                                        <Box>{translateString("noProcessingActivityText")}</Box>
                                    </React.Fragment>
                                }
                            />
                        )}
                    </DotLegalPaper>
                </Box>
            </Box>
            <AddProcessingActivityDialog
                onHideDialog={OnHideDialog}
                showDialog={showDialog}
                shouldDisplayCreateProcessingActivityWarning={
                    processingActivityPlanLimitData !== undefined && processingActivityPlanLimitData?.warnLimitReachedOnTrial
                }
            />
            {showCloneDialog && (
                <CopyProcessingActivityDialog
                    onHideDialog={() => setShowCloneDialog(false)}
                    onSave={() => {
                        setShowCloneDialog(false);
                        refetchPage();
                        refetchProcessingActivityLimitPlan();
                    }}
                    copyModel={copyModel}
                    shouldDisplayCreateProcessingActivityWarning={
                        processingActivityPlanLimitData !== undefined && processingActivityPlanLimitData?.warnLimitReachedOnTrial
                    }
                />
            )}
            {showDeleteDialog.showDialogState && (
                <ProcessingActivityDeleteDialog
                    id={showDeleteDialog.chosenProcessingActivityId}
                    name={showDeleteDialog.processingActivityName}
                    onCloseDialog={OnHideDeleteDialog}
                    onSuccessDelete={() => {
                        refetchMenuNotifications();
                        deleteProcessingActivtitySuccess(showDeleteDialog.chosenProcessingActivityId);
                        refetchProcessingActivityLimitPlan();
                    }}
                />
            )}
            {showAddStandardProcessings && (
                <ProcessingActivityTemplatesDialog
                    closeDialog={() => {
                        onProcessingActivityDialogClose();
                        refetchProcessingActivityLimitPlan();
                        refetchPage();
                    }}
                />
            )}
            {showUpdateProcessingTemplateInfo.showDialog && (
                <UpdateprocessingTemplateInfoDialog
                    processingActivityId={showUpdateProcessingTemplateInfo.processingActivityId}
                    closeDialog={() => {
                        setShowUpdateProcessingTemplateInfo({ showDialog: false, processingActivityId: "" });
                        refetchPage();
                    }}
                />
            )}
            {showProcessingActivityReport?.showDialog && (
                <ProcessingActivtyReportDialog
                    processingActivityId={showProcessingActivityReport.processingActivityId}
                    processingActivityName={showProcessingActivityReport.processingActivityName}
                    onDialogClose={() => setShowProcessingActivityReport(undefined)}
                    processingActivityStatus={showProcessingActivityReport.processingActivityStatus}
                    onSubmitReport={() => {}}
                />
            )}
        </React.Fragment>
    );
}

export default withAITracking(reactPlugin, ProcessingActivities, "Processing activities overiew", "tracking");
