import { useState } from "react";
import { TagDialogProps } from "./TagDialog";
import { TagCreateModel } from "./TagDialog.types";
import { Color, Result, useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { isNullOrWhitespace } from "../../../common/stringOperations";
import { post, put } from "../../../common/api/apiShared";
import { useMutation } from "react-query";
import { useTranslation } from "../../../localization/useTranslation";

export function useTagDialog(props: TagDialogProps) {
    const defaultColor = Color.lightBlue;
    const snackbar = useDotLegalSnackbar();

    const { translateString } = useTranslation();

    const [viewModel, setViewModel] = useState<TagCreateModel | undefined>(setSaveModel());
    const [showError, setShowError] = useState(false);

    const tagUpdater = {
        onNameChange: async (name: string) => {
            let tempModel = { ...viewModel! };
            tempModel.name = name;
            setViewModel(tempModel);
        },
        onDescriptionChange: async (description: string) => {
            let tempModel = { ...viewModel! };
            tempModel.description = description;
            setViewModel(tempModel);
        },
        onColorChange: async (color: string) => {
            let tempModel = { ...viewModel! };
            if (isNullOrWhitespace(color)) {
                tempModel.color = defaultColor;
            } else {
                tempModel.color = Number(color);
            }
            setViewModel(tempModel);
        },
    };

    const createMutation = useMutation(create);
    const createTag = async () => {
        createMutation.mutateAsync(undefined, {
            onSuccess: (response: any) => {
                if (response.success()) {
                    props.onSave(response.value().id);
                } else {
                    snackbar.show(translateString("tagWithNameExist"), "warning");
                }
            },
        });
    };

    const updateMutation = useMutation(update);
    const updateTag = async () => {
        updateMutation.mutateAsync(viewModel!.id, {
            onSuccess: (response: any) => {
                if (response.success()) {
                    props.onSave(viewModel!.id);
                } else {
                    snackbar.show(translateString("tagWithNameExist"), "warning");
                }
            },
        });
    };

    const onOkClick = async () => {
        if (isNullOrWhitespace(viewModel?.name)) {
            setShowError(true);
            return;
        }

        if (isEdit()) {
            await updateTag();
        } else {
            await createTag();
        }
    };

    return {
        viewModel,
        setViewModel,
        inProgress: createMutation.isLoading || updateMutation.isLoading,
        isEdit: isEdit(),
        tagUpdater,
        showError,
        onOkClick,
    };

    function create() {
        return post<string>("/tag/", viewModel);
    }

    function update(id: string) {
        return put<string>(`/tag/${id}/`, viewModel);
    }

    function setSaveModel() {
        if (props.selectedTag) {
            return {
                id: props.selectedTag.id,
                name: props.selectedTag.name,
                description: props.selectedTag.description,
                color: props.selectedTag.color,
            };
        }

        return {
            id: "",
            name: "",
            description: "",
            color: defaultColor,
        };
    }

    function isEdit() {
        return props.selectedTag !== undefined && props.selectedTag!.id !== "";
    }
}
