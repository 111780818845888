import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "../../../localization/useTranslation";
import { DataCategoryViewModel, DataPrivacyClassification } from "../../../processingActivity/DataCategoriesStep/DataCategoriesStep.types";
import { DotLegalInformationTooltip } from "@dotlegal/dotlegal-ui-components";
import { useDotLegalPersonalDataSelectorStyles } from "./DotLegalPersonalDataSelector.styles";
import DataCategoryBox from "../../../processingActivity/components/dataCategoryBox/DataCategoryBox.tsx";
import { DataCategoryDataTypes } from "../../../processingActivity/ProcessingActivity.types.ts";

export interface IDotLegalPersonalDataSelector {
    activityDataCategories: Array<DataCategoryViewModel>;
    onChange: (chosenCategories: Array<DataCategoryDataTypes>) => void;
    errorText?: string;
    disabled?: boolean;
}

export default function DotLegalPersonalDataSelector(props: IDotLegalPersonalDataSelector) {
    const styles = useDotLegalPersonalDataSelectorStyles(props);
    const { translateString } = useTranslation();

    const handleChange = (categoryDataTypes: DataCategoryDataTypes) => {
        const category = props.activityDataCategories.find((r) => r.id === categoryDataTypes.dataCategoryId);
        props.activityDataCategories.find((r) => r.id === categoryDataTypes.dataCategoryId)!.isSelected = !category!.isSelected;
        props.onChange([
            ...props.activityDataCategories
                .filter((x) => x.isSelected)
                .map((r) => {
                    return {
                        dataCategoryId: r.id,
                        dataTypeIds: r.selectedDataTypeIds,
                    };
                }),
        ]);
    };

    const onDataTypesChanged = (dataCategoryDataTypes: DataCategoryDataTypes) => {
        const category = props.activityDataCategories.find((r) => r.id === dataCategoryDataTypes.dataCategoryId)!;
        category.dataTypeIds = dataCategoryDataTypes.dataTypeIds;
        props.onChange(
            props.activityDataCategories
                .filter((x) => x.isSelected)
                .map((r) => {
                    return {
                        dataCategoryId: r.id,
                        dataTypeIds: r.dataTypeIds,
                    };
                })
        );
    };

    const getDatacategoryContent = (classification: DataPrivacyClassification) => {
        return (
            <Grid container item xs={8} spacing={2}>
                {props.activityDataCategories
                    .filter((x) => x.classification === classification)
                    .map((d) => {
                        return (
                            <Grid item key={d.id}>
                                <div>
                                    <DataCategoryBox
                                        readOnly={props.disabled}
                                        isLoading={false}
                                        dataCategory={d}
                                        size="small"
                                        onSave={onDataTypesChanged}
                                        onDataCategoryClick={(isSelected, dataCategoryDataTypes) => handleChange(dataCategoryDataTypes)}
                                    />
                                </div>
                            </Grid>
                        );
                    })}
            </Grid>
        );
    };

    return (
        <Box sx={styles.root}>
            {props.activityDataCategories.filter((x) => x.classification === DataPrivacyClassification.Ordinary).length > 0 && (
                <Grid container spacing={2} className={"data-category-box"}>
                    <Grid sx={styles.infoHeaderGrid} item xs={4} md={2}>
                        <Typography sx={{ ...styles.infoHeader, ...styles.infoHeaderChildMargin }}>{translateString("dataCategoriOrdinary")}</Typography>
                        <DotLegalInformationTooltip text={translateString("dataCategoryOrdinaryHelperText")} />
                    </Grid>
                    {getDatacategoryContent(DataPrivacyClassification.Ordinary)}
                </Grid>
            )}
            {props.activityDataCategories.filter((x) => x.classification === DataPrivacyClassification.NationalIdentificationNumber).length > 0 && (
                <Grid container spacing={2} className={"data-category-box"}>
                    <Grid sx={styles.infoHeaderGrid} item xs={4} md={2}>
                        <Typography sx={{ ...styles.infoHeader, ...styles.infoHeaderChildMargin }}>
                            {translateString("nationalidentificationnumber")}
                        </Typography>
                    </Grid>
                    {getDatacategoryContent(DataPrivacyClassification.NationalIdentificationNumber)}
                </Grid>
            )}
            {props.activityDataCategories.filter((x) => x.classification === DataPrivacyClassification.CriminalOffenses).length > 0 && (
                <Grid container spacing={2} className={"data-category-box"}>
                    <Grid sx={styles.infoHeaderGrid} item xs={4} md={2}>
                        <Typography sx={{ ...styles.infoHeader, ...styles.infoHeaderChildMargin }}>{translateString("criminaloffenses")}</Typography>
                    </Grid>
                    {getDatacategoryContent(DataPrivacyClassification.CriminalOffenses)}
                </Grid>
            )}
            {props.activityDataCategories.filter((x) => x.classification === DataPrivacyClassification.Sensitive).length > 0 && (
                <Grid container spacing={2} className={"data-category-box"}>
                    <Grid sx={styles.infoHeaderGrid} item xs={4} md={2}>
                        <Typography sx={{ ...styles.infoHeader, ...styles.infoHeaderChildMargin }}>{translateString("dataCategoriSensitive")}</Typography>
                        <DotLegalInformationTooltip text={translateString("dataCategorySensitiveHelperText")} />
                    </Grid>
                    {getDatacategoryContent(DataPrivacyClassification.Sensitive)}
                </Grid>
            )}
            {props.activityDataCategories.filter((x) => x.classification === DataPrivacyClassification.None).length > 0 && (
                <Grid container spacing={2} className={"data-category-box"}>
                    <Grid sx={styles.infoHeaderGrid} item xs={4} md={2}>
                        <Typography sx={{ ...styles.infoHeader, ...styles.infoHeaderChildMargin }}>{translateString("dataCategoriNone")}</Typography>
                    </Grid>
                    {getDatacategoryContent(DataPrivacyClassification.None)}
                </Grid>
            )}
            {props.errorText && <Box sx={styles.errorText}>{props.errorText}</Box>}
        </Box>
    );
}
