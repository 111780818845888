import { Theme, useTheme } from "@mui/material";
import { createSxStyles } from "../../../../common/createSxStyles";

export const useControllerScoreGraphStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        content: {
            height: "100%",
        },
        dropDownWrapper: {
            display: "flex",
            justifyContent: "flex-end",
        },
        dropDown: {
            width: "300px",
        },
        graph: {
            height: "250px",
            [theme.breakpoints.down("sm")]: {
                "& *": {
                    fontSize: "7px !important",
                },
            },
        },
    });
};
