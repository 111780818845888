import { Theme, useTheme } from "@mui/material";
import { IRiskIndicatorProps } from "./RiskIndicator";
import { createSxStyles } from "../../common/createSxStyles";

export const useRiskIndicatorStyles = (props: IRiskIndicatorProps) => {
    const theme = useTheme();
    return createSxStyles({
        root: {
            "& .MuiTypography-root": {
                fontSize: getTextSizeStyling(props, theme),
            },
            display: "flex",
            alignItems: "center",
        },
        riskIndicator: {
            color: getRiskIndicatorTextColor(props, theme),
            width: props.fixedWidthLeftColumn ? "85px" : "auto",
            fontWeight: props.setFontWeightNormal ? "bold" : "normal",
        },
        riskIndicatorValue: {
            color: getScoreTextColor(props, theme),
            fontWeight: props.setFontWeightNormal ? "normal" : "bold",
            textDecoration: "none",
        },
        icon: {
            marginLeft: props.removeLeftMargin ? undefined : theme.spacing(2),
            marginRight: theme.spacing(2),
            display: "flex",
        },
        warningIcon: {
            display: "flex",
            alignContent: "center",
            "& svg": {
                fill: theme.palette.error.light,
                marginLeft: theme.spacing(0.5),
                height: 17,
                width: 17,
            },
        },
    });
};

function getScoreTextColor(props: IRiskIndicatorProps, theme: Theme) {
    let textColor = theme.palette.secondary.main;

    if (props.riskScoreTextColor) {
        switch (props.riskScoreTextColor) {
            case "white":
                textColor = theme.customPalette.white;
                break;
            case "darkblue":
                textColor = theme.palette.primary.dark;
                break;
            case "lightblue":
                textColor = theme.palette.secondary.light;
                break;
        }
    }

    return textColor;
}

function getRiskIndicatorTextColor(props: IRiskIndicatorProps, theme: Theme) {
    let textColor = theme.palette.primary.dark;

    if (props.riskIndicatorTextColor) {
        switch (props.riskIndicatorTextColor) {
            case "white":
                textColor = theme.customPalette.white;
                break;
        }
    }

    return textColor;
}

function getTextSizeStyling(props: IRiskIndicatorProps, theme: Theme) {
    let fontSize = theme.typography.pxToRem(14);
    if (props.textSize) {
        switch (props.textSize) {
            case "sm":
                fontSize = theme.typography.pxToRem(12);
                break;
            case "md":
                fontSize = theme.typography.pxToRem(14);
                break;
            case "lg":
                fontSize = theme.typography.pxToRem(16);
                break;
        }
    }

    return fontSize;
}
