import { DotLegalDeleteDialog } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { Trans } from "react-i18next";
import { Box } from "@mui/material";
import useDeleteClassificationDialogHooks from "./DeleteClassificationDialog.hooks";
import { LegalEntityClassificationApiRow } from "../LegalEntityClassificationTab.types.ts";

export interface IDeleteClassificationDialog {
    onDialogClose: () => void;
    selectedClassification: LegalEntityClassificationApiRow;
    onSuccessDelete: () => void;
}

function DeleteAuditDialog(props: IDeleteClassificationDialog) {
    const { translateString } = useTranslation();
    const { removeClassificationClick, inProgress, confirmDelete, setConfirmDelete } = useDeleteClassificationDialogHooks(props);

    return (
        <DotLegalDeleteDialog
            open
            headerText={translateString("deleteClassification")}
            alertBodyText={
                <Trans
                    i18nKey={"deleteClassificationContent"}
                    values={{
                        name: props.selectedClassification.questionnaireName.trim(),
                    }}
                    components={{
                        wrapper: (
                            <Box sx={(theme) => ({ color: theme.palette.secondary.main, fontWeight: theme.typography.fontWeightBold })} component="span"></Box>
                        ),
                    }}
                />
            }
            warningTranslation={""}
            deleteBtnText={translateString("delete")}
            cancelBtnText={translateString("cancel")}
            onDialogClose={props.onDialogClose}
            onOkClick={() => removeClassificationClick()}
            confirmLabel={translateString("deleteClassification")}
            confirmDelete={confirmDelete}
            confirmDeleteRequired
            setConfirmDelete={setConfirmDelete}
            inProgress={inProgress}
        />
    );
}

export default DeleteAuditDialog;
