import { useStepSegmentStyles } from "./StepSegment.styles";
import { Box } from "@mui/material";

export interface ISegmentStepProps {
    size: "small" | "medium" | "large";
    children: any;
    id?: string | undefined;
}

function SegmentStep(props: ISegmentStepProps) {
    const styles = useStepSegmentStyles(props);

    return (
        <Box id={props.id} sx={styles.container}>
            {props.children}
        </Box>
    );
}

export default SegmentStep;
