import { useMutation } from "react-query";
import { put } from "../../common/api/apiShared";
import { RevisionDialogProps } from "./RevisionDialog";

export function useRevisionDialog(props: RevisionDialogProps) {
    const setReadyForRevisionMutation = useMutation(setReadyForRevisionApi);
    const onSaveReadyForRevision = async () => {
        await setReadyForRevisionMutation.mutateAsync(undefined, {
            onSuccess: () => {
                props.onReadyForRevisionSaved();
            },
        });
    };

    return {
        onSaveReadyForRevision,
    };

    async function setReadyForRevisionApi() {
        await put(`/declaration/${props.declarationId}/readyForRevision`, {});
    }
}
