import { createElement } from "react";
import { Trans } from "react-i18next";
import { useMutation } from "react-query";
import { deleteHttp } from "../../common/api/apiShared";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { IDeleteCustomerDialog } from "./DeleteCustomerDialog";

export function useDeleteCustomerDialogHooks(props: IDeleteCustomerDialog) {
    const snackbar = useDotLegalSnackbar();

    const removeMutation = useMutation(remove);
    const removeCustomer = () => {
        removeMutation.mutateAsync(undefined, {
            onSuccess: () => {
                props.onCloseDialog();
                props.onDeletion();
                snackbar.show(createElement(Trans, { i18nKey: "dataDeleted", values: { name: props.selectedCustomer.name } }));
            },
        });
    };

    async function remove() {
        return await deleteHttp(`/customer/${props.selectedCustomer.id}`);
    }

    return {
        removeCustomer,
        inProgess: removeMutation.isLoading,
    };
}
