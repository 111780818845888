import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useOptimisticUpdate } from "../common/hooks/useOptimisticUpdate";
import { ProcessingActivityReportViewModel } from "./ProcessingActivityReport.types";
import { get, put } from "../common/api/apiShared";
import { useUserContext } from "../auth/userContextProvider/UserContextProvider";
import { CustomerReportType } from "../customer/addEditCustomerDialog/AddEditCustomer.types";
import { isNullOrWhitespace } from "../common/stringOperations";
import { useEffect } from "react";

export function useProcessingActivityReportHooks(type: CustomerReportType, processingActivityId?: string, onValidChange?: (isValid: boolean) => void) {
    const optimisticUpdate = useOptimisticUpdate();
    const { id } = useParams<{ id: string }>();
    const reportType = type === CustomerReportType.RegistrationOfResearchProject ? "registrationOfResearchProject" : "internalAcquisitionOfSignature";

    const url = `/processingActivityReport/${id === undefined ? processingActivityId! : id}/${reportType}`;

    const { isLoading, data } = useQuery(url, () => get<ProcessingActivityReportViewModel>(url));
    const { customerName, permissions } = useUserContext();

    useEffect(() => {
        if (data && onValidChange) {
            onValidChange(isValid(data));
        }
    });

    async function onReportChange(reportModel: ProcessingActivityReportViewModel) {
        if (onValidChange) {
            onValidChange(isValid(reportModel));
        }
        await optimisticUpdate.putOnQueueAndSetQueryData(reportModel, url, updateReportChange, reportModel);
    }

    async function updateReportChange(viewModel: ProcessingActivityReportViewModel) {
        return put<ProcessingActivityReportViewModel>("/processingActivityReport/" + reportType, viewModel);
    }

    return {
        isLoading,
        data,
        onReportChange,
        permissions,
        customerName,
    };

    function isValid(reportModel: ProcessingActivityReportViewModel) {
        // We only validates RegistrationOfResearchProject at the moment
        if (type === CustomerReportType.RegistrationOfResearchProject) {
            return (
                !isNullOrWhitespace(reportModel.primaryResponsible ?? undefined) &&
                !isNullOrWhitespace(reportModel.contact ?? undefined) &&
                reportModel.endDate !== null &&
                reportModel.endDate !== undefined
            );
        }
        return true;
    }
}
