import { useTranslation } from "../../localization/useTranslation";
import { useUrlProvider } from "../../useUrlProvider";
import { CallPage } from "../CustomLists.types";
import { ICustomListSelector } from "./CustomListSelector";
import { CustomListSelectorType } from "./CustomListSelector.types";

export function useCustomListSelectorHooks(props: ICustomListSelector) {
    const { translateString } = useTranslation();
    const { getCustomCompanyListsUrl, getCustomSharingListsUrl, getCustomProcessingActivityListsUrl, getCustomSystemListsUrl, getCustomGroupCompanyListsUrl } =
        useUrlProvider();

    let selectable = [];

    switch (props.type) {
        case CustomListSelectorType.ProcessingActivity:
            selectable.push({ name: translateString("processingActivities"), link: getCustomProcessingActivityListsUrl });
            selectable.push({ name: translateString("sharings"), link: getCustomSharingListsUrl });
            break;
        case CustomListSelectorType.Company:
            if (props.callPage === CallPage.GroupCompanies) {
                selectable.push({ name: translateString("customLists"), link: getCustomGroupCompanyListsUrl });
            } else {
                selectable.push({ name: translateString("customLists"), link: getCustomCompanyListsUrl });
            }
            break;
        case CustomListSelectorType.System:
            selectable.push({ name: translateString("customLists"), link: getCustomSystemListsUrl });
    }

    let hasMultipleSelection = selectable.length > 1;

    return { selectable, hasMultipleSelection };
}
