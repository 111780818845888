import { useState } from "react";
import { LevelOfProtectionType } from "../addLegalEntityDialog/AddLegalEntityDialog.types";
import { ILegalEntityThirdCountryDialog } from "./LegalEntityThirdCountryDialog";
import { LegalEntityThirdCountrySettingsViewModel } from "./LegalEntityThirdCountryDialog.types";
import { put } from "../../common/api/apiShared";
import { useMutation } from "react-query";
import { useLevelsOfProtectionForSelection } from "../useLevelsOfProtectionForSelection.ts";

export default function useLegalEntityThirdCountryDialogHooks(props: ILegalEntityThirdCountryDialog) {
    const getLevelsOfProtection = useLevelsOfProtectionForSelection();

    const [viewModel, setViewModel] = useState<LegalEntityThirdCountrySettingsViewModel>(props.thirdCountrySettings);

    const saveMutation = useMutation(updateThirdCountrySettingsApi, {
        onSuccess: (response: any) => {
            props.onSaved();
        },
    });

    const onSaveClick = async () => {
        await saveMutation.mutateAsync();
    };

    const updateSaveModel = {
        onIsTIAUnnecessaryChange: (isTIAUnnecessary: boolean) => {
            const temp = { ...viewModel };
            temp.isTIAUnnecessary = isTIAUnnecessary;
            setViewModel(temp);
        },
        onIsTransferBasisUnnecessaryChange: (isTransferBasisUnnecessary: boolean) => {
            const temp = { ...viewModel };
            temp.isTransferBasisUnnecessary = isTransferBasisUnnecessary;
            setViewModel(temp);
        },
        onAddDataPrivacyFrameworkToSharingsChange: (addDataPrivacyFrameworkToSharings: boolean) => {
            const temp = { ...viewModel };
            temp.addDataPrivacyFrameworkToSharings = addDataPrivacyFrameworkToSharings;
            setViewModel(temp);
        },
        onLevelOfProtectionChange: (levelOfProtection: string) => {
            const temp = { ...viewModel };
            temp.levelOfProtection = LevelOfProtectionType[levelOfProtection as keyof typeof LevelOfProtectionType];
            temp.addDataPrivacyFrameworkToSharings = false;

            setViewModel(temp);
        },
    };

    function updateThirdCountrySettingsApi() {
        return put<{}>(`/LegalEntity/${props.legalEntityId}/thirdCountrySettings`, viewModel);
    }

    return {
        LevelOfProtections: getLevelsOfProtection(props.thirdCountrySettings.countryCode),
        viewModel,
        updateSaveModel,
        onSaveClick,
        isSaving: saveMutation.isLoading,
    };
}
