import { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { get } from "../../../common/api/apiShared";
import { useCertificationsQuery, useSelectableDeletionPeriodsQuery } from "../../../common/hooks/useSelectableItemQueries";
import { SelectableSecurityMeasure } from "../../../processingActivity/ProcessingActivity.types";
import { ISystemInformationStep } from "./SystemInformationStep";
import { usePlanContext } from "../../../auth/planProvider/PlanProvider";
import { DotLegalSelectOption } from "../../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types.ts";
import { OperationCriticalityLevelEnum } from "../SystemEdit.types.ts";
import { useTranslation } from "../../../localization/useTranslation.ts";

export function useSystemInfomationStepHooks(props: ISystemInformationStep) {
    const { translateString, translateOperationCriticalityLevelEnum } = useTranslation();
    const { informationSecurityFeatures } = usePlanContext();
    const { id } = useParams<{ id: string }>();

    const [showAddCertificationDialog, setShowAddCertificationDialog] = useState(false);
    const [typedCertification, setTypedCertification] = useState("");

    const [showAddNewSecurityMeasureDialog, setShowAddNewSecurityMeasureDialog] = useState(false);
    const [typedSecurityMeasure, setTypedSecurityMeasure] = useState("");

    const [showAddNewDeletionPeriod, setShowAddNewDeletionPeriod] = useState(false);
    const [typedDeletionPeriod, setTypedDeletionPeriod] = useState("");

    let securityMeasuresQuery = useQuery("systemSecurityMeasures" + id, () => get<Array<SelectableSecurityMeasure>>(`/securitymeasures/${id}/system`));
    let deletionPeriodsQuery = useSelectableDeletionPeriodsQuery();
    let certificationsQuery = useCertificationsQuery(informationSecurityFeatures.certifications);

    let securityMeasureLoading = securityMeasuresQuery.isLoading;
    let securityMeasureData = securityMeasuresQuery.data;
    let deletionPeriodsData = deletionPeriodsQuery.data;
    let deletionPeriodsLoading = deletionPeriodsQuery.isLoading;
    let certificationData = certificationsQuery.data;
    let certificationLoading = certificationsQuery.isLoading;

    const onSaveSecurityMeasure = (id: any, name: string) => {
        var temp = [...props.securityMeasures!];
        temp.push({ securityMeasureId: id.id, isMet: true });
        props.onSecurityMeasureChange(temp);

        var tempData = [...securityMeasuresQuery.data!];
        tempData.push({ id: id.id, isGlobal: false, name: name, description: "" });
        securityMeasuresQuery.data = tempData;

        securityMeasuresQuery.refetch();
        setTypedSecurityMeasure("");
    };

    const onSaveDeletionPeriod = (reponse: any, name: string) => {
        var temp = [...deletionPeriodsData!];
        temp.push({ id: reponse.id, name: name });
        props.onDeletionPeriodChange(reponse.id);
        deletionPeriodsData = temp;

        deletionPeriodsQuery.refetch();
    };

    const onSaveCertification = (response: any) => {
        var temp = [...props.certifications!];
        temp.push(response.id);
        props.onCertificationChange(temp);

        certificationsQuery.refetch();
        setShowAddCertificationDialog(false);
    };

    let levelOfCriticalityOptions: Array<DotLegalSelectOption> = [];
    if (props.isCriticalForOperation) {
        levelOfCriticalityOptions = [
            {
                id: OperationCriticalityLevelEnum.VeryLow.toString(),
                name: translateOperationCriticalityLevelEnum(OperationCriticalityLevelEnum.VeryLow),
            },
            { id: OperationCriticalityLevelEnum.Low.toString(), name: translateOperationCriticalityLevelEnum(OperationCriticalityLevelEnum.Low) },
            { id: OperationCriticalityLevelEnum.Moderate.toString(), name: translateOperationCriticalityLevelEnum(OperationCriticalityLevelEnum.Moderate) },
            { id: OperationCriticalityLevelEnum.High.toString(), name: translateOperationCriticalityLevelEnum(OperationCriticalityLevelEnum.High) },
            { id: OperationCriticalityLevelEnum.VeryHigh.toString(), name: translateOperationCriticalityLevelEnum(OperationCriticalityLevelEnum.VeryHigh) },
        ];
    }

    return {
        securityMeasureLoading,
        securityMeasureData,
        deletionPeriodsLoading,
        deletionPeriodsData,
        showAddNewSecurityMeasureDialog,
        setShowAddNewSecurityMeasureDialog,
        typedSecurityMeasure,
        setTypedSecurityMeasure,
        onSaveSecurityMeasure,
        onSaveDeletionPeriod,
        showAddNewDeletionPeriod,
        setShowAddNewDeletionPeriod,
        typedDeletionPeriod,
        setTypedDeletionPeriod,
        certificationData,
        certificationLoading,
        setShowAddCertificationDialog,
        showAddCertificationDialog,
        typedCertification,
        setTypedCertification,
        onSaveCertification,
        levelOfCriticalityOptions,
    };
}
