import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { ajaxQueue } from "../../common/api/ajaxQueue";
import { deleteHttp, get, put } from "../../common/api/apiShared";
import { useOptimisticUpdate } from "../../common/hooks/useOptimisticUpdate";
import { useSetPdfPageSize } from "../../common/pdfPageSizeHelper";
import { QuestionSaveModel, QuestionType, QuestionViewModel, RiskAssessmentQuestionApiModel, RiskAssessmentType } from "../RiskAssessment.types";
import { getQuestionCategoryLists } from "../riskAssessmentTemplate/RiskAssessmentTemplate.types";
import { useQuestionToolTip } from "../useQuestionToolTip";
import { useRiskAssessmentCustomQuestions } from "../riskAssessmentTemplateCustomQuestions/RiskAssessmentCustomQuestions.hooks";
import { RiskAssessmentArea, RiskComplianceArea } from "../riskAssessments/RiskAssessments.types";
import { useUrlProvider } from "../../useUrlProvider";
import { isNullOrWhitespace } from "../../common/stringOperations.ts";

export function useRiskQuestionDataMapping(
    riskAssessmentArea: RiskAssessmentArea,
    riskComplianceArea: RiskComplianceArea,
    riskAssessmentVersionId: string | undefined
) {
    const isProcessingActivityRiskAssessment = riskAssessmentArea === RiskAssessmentArea.ProcessingActivities;

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const shouldDisableQuestions = urlParams.get("disableDialog");

    const { getProcessingActivityOverviewUrl, getSystemOverviewUrl } = useUrlProvider();
    useSetPdfPageSize("portrait");
    const queryClient = useQueryClient();
    const optimisticUpdate = useOptimisticUpdate();
    const { getQuestionToolTip } = useQuestionToolTip();

    const { id } = useParams<{ id: string }>();

    const controllerToCall = isProcessingActivityRiskAssessment ? "ProcessingActivityRiskAssessment" : "SystemRiskAssessment";
    let url = `/${controllerToCall}/${id}/questions?complianceArea=${riskComplianceArea}&riskAssessmentVersionId=${riskAssessmentVersionId}`;

    const { data, isLoading, isFetching } = useQuery(url, () => get<RiskAssessmentQuestionApiModel>(url));
    const { customQuestions, getCustomQuestionDisplayText } = useRiskAssessmentCustomQuestions(
        riskAssessmentArea,
        riskComplianceArea,
        riskAssessmentVersionId,
        {
            fetchCustomQuestions: true,
        }
    );

    const removeQuestionMutation = useMutation(resetQuestion);
    const resetQuestionClick = (questionId: string, questionType: QuestionType, riskAssessmentType: RiskAssessmentType, customQuestionId?: string) => {
        let newData = [...data!.questions!];
        let question = newData.find((x) => x.id === questionId);
        question!.score = null;

        optimisticUpdate.setQueryData(url, data);
        removeQuestionMutation.mutateAsync(
            { questionId: questionId, questionType: questionType, riskAssessmentType: riskAssessmentType, customQuestionId: customQuestionId },
            {
                onSuccess: (response: any) => {
                    queryClient.refetchQueries(url);
                },
            }
        );
    };

    const editQuestionMutation = useMutation(convertToQuestionSaveModel);

    const saveQuestion = async (model: QuestionViewModel, riskAssessmentType: RiskAssessmentType) => {
        let newData = { ...data! }.questions;
        let question = newData.find((x) => x.questionType === model.questionType);

        if (!question) {
            newData.push({
                ...model,
                id: "",
            });
        }

        optimisticUpdate.setQueryData(url, data);

        await ajaxQueue()
            .addRequest(editQuestionMutation.mutateAsync, { model: model!, riskAssessmentType: riskAssessmentType })
            ?.then((x) => {
                queryClient.refetchQueries(url);
            });
    };

    const excludeQuestionMutation = useMutation(setExcludedQuestion);

    const toggleExcludeQuestion = async (model: QuestionViewModel, isExcluded: boolean) => {
        let newData = { ...data! }.questions;
        let question = newData.find((x) => x.questionType === model.questionType);

        if (question) {
            question.isExcluded = isExcluded;
        }

        optimisticUpdate.setQueryData(url, data);

        await ajaxQueue()
            .addRequest(excludeQuestionMutation.mutateAsync, { questionType: model!.questionType, customQuestionId: model!.customQuestionId })
            ?.then((x) => {
                queryClient.refetchQueries(url);
            });
    };

    return {
        score: data?.score,
        averageScore: data?.averageScore,
        questions: mapQuestions(data?.questions),
        name: isProcessingActivityRiskAssessment ? data?.processingActivityName : data?.systemName,
        businessAreas: data?.businessAreas,
        questionData: data?.questionData,
        customQuestions,
        getCustomQuestionDisplayText,
        isLoading,
        isSaving: isFetching || editQuestionMutation.isLoading,
        resetQuestionClick,
        saveQuestion,
        id,
        toggleExcludeQuestion,
        questionCategories: getQuestionCategoryLists(),
        securityMeasures: data?.securityMeasures,
        dialogNameHref: isProcessingActivityRiskAssessment ? getProcessingActivityOverviewUrl(id) : getSystemOverviewUrl(id),
        isLocked: data?.isLocked ?? false,
        currentRiskAssessmentVersionName: data?.currentRiskAssessmentVersionName,
        disableQuestions: shouldDisableQuestions === "true" || (data?.isLocked ?? true),
    };

    async function setExcludedQuestion(data: { questionType: QuestionType; customQuestionId?: string }) {
        return await put<{}>(
            `/${controllerToCall}/${id}/exclude/${riskAssessmentVersionId}/${data.questionType}/${data.customQuestionId ? data.customQuestionId : ""}`,
            null
        );
    }

    async function convertToQuestionSaveModel(data: { model: QuestionViewModel; riskAssessmentType: RiskAssessmentType }) {
        let isConsequence = data.riskAssessmentType === RiskAssessmentType.Consequence;
        const saveModel: QuestionSaveModel = {
            score: isConsequence ? data.model.consequenceScoreNote.score! : data.model.probabilityScoreNote.score!,
            note: isConsequence ? data.model.consequenceScoreNote.note : data.model.probabilityScoreNote.note,
            questionType: data.model.questionType,
            riskAssessmentType: data.riskAssessmentType,
            customQuestionId: data.model.customQuestionId,
        };

        return await put<{}>(`/${controllerToCall}/${id}/question?riskAssessmentVersionId=${riskAssessmentVersionId}`, saveModel);
    }

    async function resetQuestion(data: { questionId: string; questionType: QuestionType; riskAssessmentType: RiskAssessmentType; customQuestionId?: string }) {
        return await deleteHttp<string>(
            `/${controllerToCall}/${id}/resetQuestion/${data.questionType}/${data.riskAssessmentType}${
                data.customQuestionId ? "/" + data.customQuestionId : ""
            }?riskAssessmentVersionId=${riskAssessmentVersionId}`
        );
    }

    // Skal nok indføres når vi skal have tooltip på
    function mapQuestions(viewModelQuestions: Array<QuestionViewModel> | undefined): Array<QuestionViewModel> {
        let questions: Array<QuestionViewModel> = [];
        if (viewModelQuestions) {
            questions = viewModelQuestions?.map((question) => {
                return {
                    ...question,
                    id: question.id,
                    toolTip: getQuestionToolTip(question.questionType),
                };
            });
        }

        return questions;
    }
}
