import { DotLegalDatePicker, DotLegalFullScreenSpinner, DotLegalHeader, DotLegalRadioButton, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { Box, Grid, RadioGroup } from "@mui/material";
import { BodyTypography, HeaderBodyTypography, HeaderBox, SpanBox, TextBoxWrapper } from "../ProcessingActivityReport";
import React from "react";
import { useResearchEthicsApprovalReportHooks } from "./ResearchEthicsApprovalReport.hooks";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import ResearchEthicsApprovalReportDocuments from "./researchEthicsApprovalReportDocuments/ResearchEthicsApprovalReportDocuments";
import ProcessingActivityReportDialogHeader from "../processingActivityReportDialogHeader/ProcessingActivityReportDialogHeader";
import { CustomerReportType } from "../../customer/addEditCustomerDialog/AddEditCustomer.types";

export interface IResearchEthicsApprovalReport {
    processingActivityId?: string;
    isPDF?: boolean;
}

function ResearchEthicsApprovalReport(props: IResearchEthicsApprovalReport) {
    const { translateString, getDateTimePickerLanguage } = useTranslation();
    const { data, isLoading, reportUpdater, refetch, processingActivityId } = useResearchEthicsApprovalReportHooks(props);
    const { customerName } = useUserContext();

    function getApplicantContent() {
        return (
            <React.Fragment>
                {getContentHeader("applicant", true)}
                <HeaderBox>
                    <HeaderBodyTypography>{translateString("projectManager") + ": "}</HeaderBodyTypography>
                    <BodyTypography>{data?.projectManager}</BodyTypography>
                </HeaderBox>

                <HeaderBox>
                    <HeaderBodyTypography>{translateString("department") + ": "}</HeaderBodyTypography>
                    <BodyTypography>{data?.businessAreas.join(", ")}</BodyTypography>
                </HeaderBox>

                <HeaderBox>
                    <HeaderBodyTypography>{translateString("emailAddress") + ": "}</HeaderBodyTypography>
                    <BodyTypography>{data?.email}</BodyTypography>
                </HeaderBox>

                <HeaderBox>
                    <HeaderBodyTypography>{translateString("phoneNumber") + ": "}</HeaderBodyTypography>
                    <Box marginLeft={1}>
                        <DotLegalTextField
                            size="small"
                            noMargin
                            label={""}
                            value={data?.phone ?? ""}
                            debounce
                            onChange={(value) => reportUpdater.onPhoneChange(value)}
                        />
                    </Box>
                </HeaderBox>

                <HeaderBox>
                    <HeaderBodyTypography>{translateString("otherProjectParticipants") + ": "}</HeaderBodyTypography>
                    <BodyTypography>{data?.otherProjectParticipants.join(", ")}</BodyTypography>
                </HeaderBox>
            </React.Fragment>
        );
    }

    function getProjectInformationContent() {
        return (
            <React.Fragment>
                {getContentHeader("projectInformation")}
                <HeaderBox>
                    <HeaderBodyTypography>{translateString("projectName") + ": "}</HeaderBodyTypography>
                    <BodyTypography>{data?.projectName}</BodyTypography>
                </HeaderBox>
                <HeaderBox>
                    <BodyTypography>
                        <HeaderBodyTypography>{translateString("description") + ": "}</HeaderBodyTypography>
                        <SpanBox component="span">{data?.description}</SpanBox>
                    </BodyTypography>
                </HeaderBox>
                <HeaderBox>
                    <Box sx={{ width: 145 }}>
                        <HeaderBodyTypography>{translateString("expectedStartDate") + ": "}</HeaderBodyTypography>
                    </Box>
                    <Box>
                        <DotLegalDatePicker
                            label=""
                            value={data?.expectedStartDate ?? null}
                            onChange={reportUpdater.onExpectedStartDateChange}
                            language={getDateTimePickerLanguage()}
                            noMargin
                        />
                    </Box>
                </HeaderBox>
                <HeaderBox>
                    <Box sx={{ width: 145 }}>
                        <HeaderBodyTypography>{translateString("expectedEndDate") + ": "}</HeaderBodyTypography>
                    </Box>
                    <Box>
                        <DotLegalDatePicker
                            label=""
                            value={data?.expectedEndDate ?? null}
                            onChange={reportUpdater.onExpectedEndDateChange}
                            language={getDateTimePickerLanguage()}
                            noMargin
                        />
                    </Box>
                </HeaderBox>
                <div className="page-break-inside">
                    {getTextFieldWrapper("projectFinancierHeader", data?.projectFinancier, reportUpdater.onProjectFinancierChange)}
                </div>
                {getTextFieldWrapper("ethicalApprovalRequiredByHeader", data?.ethicalApprovalRequiredBy, reportUpdater.onEthicalApprovalRequiredByChange)}
            </React.Fragment>
        );
    }

    function getCheckListContent() {
        return (
            <React.Fragment>
                <div className="avoid-page-break">
                    {getContentHeader("checkList")}
                    <DotLegalHeader headerStyle="small" fontWeight={400}>
                        <Box sx={{ whiteSpace: "pre-line" }}>{translateString("checkListInfo")}</Box>
                    </DotLegalHeader>
                    {checkListRadioButtons()}
                </div>
            </React.Fragment>
        );
    }

    function getProjectDescriptionContent() {
        return (
            <React.Fragment>
                <div className="avoid-page-break">
                    {getContentHeader("projectDescription")}

                    <DotLegalHeader headerStyle="small" fontWeight={400}>
                        <Box sx={{ whiteSpace: "pre-line" }}>{translateString("projectDescriptionInfo")}</Box>
                    </DotLegalHeader>
                </div>
                {!props.isPDF && (
                    <React.Fragment>
                        <DotLegalHeader headerStyle="small">{translateString("uploadAttachments")}</DotLegalHeader>

                        <Box marginBottom={5}>
                            <ResearchEthicsApprovalReportDocuments
                                refetch={() => refetch()}
                                processingActivityId={processingActivityId}
                                documents={data?.documents ?? []}
                                onRemoveDocument={reportUpdater.onRemoveDocument}
                            />
                        </Box>
                    </React.Fragment>
                )}

                {getTextFieldWrapper("participantsHeader", data?.participants, reportUpdater.onParticipantsChange)}
                {getTextFieldWrapper(
                    "participantsRecruitmentMethodHeader",
                    data?.participantsRecruitmentMethod,
                    reportUpdater.onParticipantsRecruitmentMethodChange
                )}

                {getTextFieldWrapper("participantEngagementHeader", data?.participantEngagement, reportUpdater.onParticipantEngagementChange)}

                <div className="avoid-page-break">
                    {getTextFieldWrapper(
                        "informedConsentObtainingMethodHeader",
                        data?.informedConsentObtainingMethod,
                        reportUpdater.onInformedConsentObtainingMethodChange
                    )}
                </div>
                {getTextFieldWrapper("dataGenerationAndStorageHeader", data?.dataGenerationAndStorage, reportUpdater.onDataGenerationAndStorageChange)}
                {getTextFieldWrapper(
                    "inclusionOrExclusionCriteriaHeader",
                    data?.inclusionOrExclusionCriteria,
                    reportUpdater.onInclusionOrExclusionCriteriaChange
                )}
                {getTextFieldWrapper("usedRegistryDataHeader", data?.usedRegistryData, reportUpdater.onUsedRegistryDataChange)}
            </React.Fragment>
        );
    }

    function getParticipantsAndConfidentiallyContent() {
        return (
            <React.Fragment>
                <div className="avoid-page-break">
                    {getContentHeader("participantsAndConfidentially")}

                    <DotLegalHeader headerStyle="small" fontWeight={400}>
                        {translateString("participantsAndConfidentiallyInfo", { name: customerName })}
                    </DotLegalHeader>

                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 2 }}>
                        <HeaderBodyTypography>{translateString("foreseesGDPRChallenges") + ": "}</HeaderBodyTypography>

                        <RadioGroup
                            row
                            value={data?.foreseesGDPRChallenges}
                            onChange={(e) => {
                                const value = (e.target as HTMLInputElement).value === "true";
                                reportUpdater.onForeseesGDPRChallengesChange(value);
                            }}
                        >
                            <Box marginLeft={2}>
                                <DotLegalRadioButton value label={translateString("yes")}></DotLegalRadioButton>
                                <DotLegalRadioButton value={false} label={translateString("no")}></DotLegalRadioButton>
                            </Box>
                        </RadioGroup>
                    </Box>

                    <Box marginBottom={3}>{getParticipantsAndConfidentiallyRadioButtons()}</Box>

                    {getTextFieldWrapper(
                        "otherInformationRelatedToPersonalDataHeader",
                        data?.otherInformationRelatedToPersonalData,
                        reportUpdater.onOtherInformationRelatedToPersonalDataChange
                    )}
                </div>
            </React.Fragment>
        );
    }

    function getFinancialIncentivesContent() {
        return (
            <React.Fragment>
                <div className="avoid-page-break">
                    {getContentHeader("financialIncentives")}

                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <HeaderBodyTypography>{translateString("isCompensationProvidedToResearchSubjectsHeader") + ": "}</HeaderBodyTypography>

                        <RadioGroup
                            row
                            value={data?.isCompensationProvidedToResearchSubjects}
                            onChange={(e) => {
                                const value = (e.target as HTMLInputElement).value === "true";
                                reportUpdater.onIsCompensationProvidedToResearchSubjectsChange(value);
                            }}
                        >
                            <Box marginLeft={2}>
                                <DotLegalRadioButton value label={translateString("yes")}></DotLegalRadioButton>
                                <DotLegalRadioButton value={false} label={translateString("no")}></DotLegalRadioButton>
                            </Box>
                        </RadioGroup>
                    </Box>
                </div>
                <TextBoxWrapper>
                    <DotLegalTextField
                        onChange={reportUpdater.onAmountAndFormOfCompensationChange}
                        label={""}
                        placeholder={""}
                        value={data?.amountAndFormOfCompensation ?? ""}
                        debounce
                        multiline
                        rows={8}
                    />
                </TextBoxWrapper>
            </React.Fragment>
        );
    }

    function getEnvironmentAndSafetyContent() {
        return (
            <React.Fragment>
                {getContentHeader("environmentAndSafety")}

                {getTextFieldWrapper("harmMinimizationMeasuresHeader", data?.harmMinimizationMeasures, reportUpdater.onHarmMinimizationMeasuresChange)}
            </React.Fragment>
        );
    }

    function getOtherConditionsContent() {
        return (
            <React.Fragment>
                {getContentHeader("otherConditions")}

                {getTextFieldWrapper("additionalEthicalFactorsHeader", data?.additionalEthicalFactors, reportUpdater.onAdditionalEthicalFactorsChange)}
                <div className="avoid-page-break">
                    {getTextFieldWrapper("ethicalPointsOfAttentionHeader", data?.ethicalPointsOfAttention, reportUpdater.onEthicalPointsOfAttentionChange)}
                </div>
            </React.Fragment>
        );
    }

    function getSignatureContent() {
        return (
            <React.Fragment>
                {getContentHeader("signature")}

                {getTextFieldWrapper("signatureHeader", data?.signature, reportUpdater.onSignatureChange)}
            </React.Fragment>
        );
    }

    function getTextFieldWrapper(header: string, value: string | undefined, onChange: (value: string) => void) {
        return (
            <React.Fragment>
                <HeaderBodyTypography>{translateString(header)}</HeaderBodyTypography>
                <TextBoxWrapper>
                    <DotLegalTextField onChange={(value) => onChange(value)} label={""} placeholder={""} value={value ?? ""} debounce multiline rows={8} />
                </TextBoxWrapper>
            </React.Fragment>
        );
    }

    function checkListRadioButtons() {
        return (
            <Grid container>
                <Grid item xs={9}></Grid>
                <Grid item xs={1} display={"flex"} justifyContent={"center"}>
                    <DotLegalHeader headerStyle="extraSmall">{translateString("yesOrMaybe")}</DotLegalHeader>
                </Grid>
                <Grid item xs={1} display={"flex"} justifyContent={"center"}>
                    <DotLegalHeader headerStyle="extraSmall">{translateString("no")}</DotLegalHeader>
                </Grid>
                <Grid item xs={1} display={"flex"} justifyContent={"center"}>
                    <DotLegalHeader headerStyle="extraSmall">{translateString("notApplicable")}</DotLegalHeader>
                </Grid>

                {getCheckListRadioButton(
                    "areUninformedParticipantsPresent",
                    data?.areUninformedParticipantsPresent,
                    reportUpdater.onAreUninformedParticipantsPresentChange
                )}
                {getCheckListRadioButton(
                    "involvesCheatingOrDeceptionOfParticipants",
                    data?.involvesCheatingOrDeceptionOfParticipants,
                    reportUpdater.onInvolvesCheatingOrDeceptionOfParticipantsChange
                )}
                {getCheckListRadioButton("includesSensitiveTopics", data?.includesSensitiveTopics, reportUpdater.onIncludesSensitiveTopicsChange)}
                {getCheckListRadioButton(
                    "canCauseStressOrDiscomfortForParticipants",
                    data?.canCauseStressOrDiscomfortForParticipants,
                    reportUpdater.onCanCauseStressOrDiscomfortForParticipantsChange
                )}
                {getCheckListRadioButton(
                    "requiresFrequentTestingOfParticipants",
                    data?.requiresFrequentTestingOfParticipants,
                    reportUpdater.onRequiresFrequentTestingOfParticipantsChange
                )}
                {getCheckListRadioButton(
                    "posesSecurityRiskToResearchers",
                    data?.posesSecurityRiskToResearchers,
                    reportUpdater.onPosesSecurityRiskToResearchersChange
                )}
                {getCheckListRadioButton(
                    "involvesVulnerableParticipants",
                    data?.involvesVulnerableParticipants,
                    reportUpdater.onInvolvesVulnerableParticipantsChange
                )}
                {getCheckListRadioButton(
                    "involvesParticipantsUnder15OrOver15ButUnableToProvideConsent",
                    data?.involvesParticipantsUnder15OrOver15ButUnableToProvideConsent,
                    reportUpdater.onInvolvesParticipantsUnder15OrOver15ButUnableToProvideConsentChange
                )}
                {getCheckListRadioButton(
                    "investigatesIllegalBehaviorOrCriminalActions",
                    data?.investigatesIllegalBehaviorOrCriminalActions,
                    reportUpdater.onInvestigatesIllegalBehaviorOrCriminalActionsChange
                )}
                {getCheckListRadioButton(
                    "isInternationalAndRaisesSpecificEthicalQuestions",
                    data?.isInternationalAndRaisesSpecificEthicalQuestions,
                    reportUpdater.onIsInternationalAndRaisesSpecificEthicalQuestionsChange
                )}
                {getCheckListRadioButton("hasHighRiskOfResultsMisuse", data?.hasHighRiskOfResultsMisuse, reportUpdater.onHasHighRiskOfResultsMisuseChange)}
            </Grid>
        );
    }

    function getParticipantsAndConfidentiallyRadioButtons() {
        return (
            <Grid container>
                <Grid item xs={9}></Grid>
                <Grid item xs={1} display={"flex"} justifyContent={"center"}>
                    <DotLegalHeader headerStyle="extraSmall">{translateString("yesOrMaybe")}</DotLegalHeader>
                </Grid>
                <Grid item xs={1} display={"flex"} justifyContent={"center"}>
                    <DotLegalHeader headerStyle="extraSmall">{translateString("no")}</DotLegalHeader>
                </Grid>
                <Grid item xs={1} display={"flex"} justifyContent={"center"}>
                    <DotLegalHeader headerStyle="extraSmall">{translateString("dontKnow")}</DotLegalHeader>
                </Grid>
                {getCheckListRadioButton(
                    "isLegalBasisEstablishedBeforeProjectCommences",
                    data?.isLegalBasisEstablishedBeforeProjectCommences,
                    reportUpdater.onIsLegalBasisEstablishedBeforeProjectCommencesChange
                )}
                {getCheckListRadioButton(
                    "isDataCollectedAndProcessedSecurely",
                    data?.isDataCollectedAndProcessedSecurely,
                    reportUpdater.onIsDataCollectedAndProcessedSecurelyChange
                )}
                {getCheckListRadioButton("isDataHandedOverToGDPRUnit", data?.isDataHandedOverToGDPRUnit, reportUpdater.onIsDataHandedOverToGDPRUnitChange)}
                {getCheckListRadioButton(
                    "isDataTransferredToUnsafeCountries",
                    data?.isDataTransferredToUnsafeCountries,
                    reportUpdater.onIsDataTransferredToUnsafeCountriesChange
                )}
                {getCheckListRadioButton(
                    "isDataUsedOrDisclosedWithoutConsent",
                    data?.isDataUsedOrDisclosedWithoutConsent,
                    reportUpdater.onIsDataUsedOrDisclosedWithoutConsentChange
                )}
                {getCheckListRadioButton(
                    "areEmployeesInformedAboutDataHandling",
                    data?.areEmployeesInformedAboutDataHandling,
                    reportUpdater.onAreEmployeesInformedAboutDataHandlingChange
                )}
            </Grid>
        );
    }

    function getCheckListRadioButton(label: string, value: number | undefined, onChange: (value: number) => void) {
        return (
            <React.Fragment>
                <Grid item xs={9}>
                    <DotLegalHeader fontWeight={400} headerStyle="small">
                        {translateString(label)}
                    </DotLegalHeader>
                </Grid>
                <Grid item xs={3}>
                    <RadioGroup
                        row
                        value={value}
                        onChange={(e) => {
                            const value = Number((e.target as HTMLInputElement).value);
                            onChange(value);
                        }}
                    >
                        <Grid item xs={4} display={"flex"} justifyContent={"center"}>
                            <DotLegalRadioButton value={1} smallSideMargin></DotLegalRadioButton>
                        </Grid>
                        <Grid item xs={4} display={"flex"} justifyContent={"center"}>
                            <DotLegalRadioButton value={2} smallSideMargin></DotLegalRadioButton>
                        </Grid>
                        <Grid item xs={4} display={"flex"} justifyContent={"center"}>
                            <DotLegalRadioButton value={3} smallSideMargin></DotLegalRadioButton>
                        </Grid>
                    </RadioGroup>
                </Grid>
            </React.Fragment>
        );
    }

    function getContentHeader(label: string, removeTopMargin?: boolean) {
        return (
            <DotLegalHeader headerStyle={"medium"} marginTop={removeTopMargin ? 0 : 4}>
                {translateString(label)}
            </DotLegalHeader>
        );
    }

    return (
        <React.Fragment>
            {isLoading ? (
                <Box height={"70vh"}>
                    <DotLegalFullScreenSpinner />
                </Box>
            ) : (
                <Box sx={{ paddingLeft: 1, paddingRight: 1 }}>
                    {props.isPDF && <ProcessingActivityReportDialogHeader reportType={CustomerReportType.ResearchEthicsApproval} isPdf />}

                    <Box sx={{ marginTop: props.isPDF ? 2 : 0 }}>
                        {getApplicantContent()}

                        {getProjectInformationContent()}

                        {getCheckListContent()}

                        {getProjectDescriptionContent()}

                        {getParticipantsAndConfidentiallyContent()}

                        {getFinancialIncentivesContent()}

                        {getEnvironmentAndSafetyContent()}

                        {getOtherConditionsContent()}

                        {getSignatureContent()}
                    </Box>
                </Box>
            )}
        </React.Fragment>
    );
}

export default ResearchEthicsApprovalReport;
