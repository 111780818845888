import { useTheme } from "@mui/material";
import { createSxStyles } from "../../../common/createSxStyles";

export const useRiskBarometerStyles = (isSmallScreen: boolean) => {
    const theme = useTheme();
    return createSxStyles({
        imageContainer: {
            display: "flex",
            justifyContent: "center",
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(2),
            "& .MuiTypography-root": {
                color: theme.palette.primary.dark,
                fontSize: theme.typography.pxToRem(14),
                fontWeight: "bold",
                position: "absolute",
            },
        },
        imageContainerSubContainer: {
            width: isSmallScreen ? "100%" : "61%",
        },
        averageScore: {
            justifyContent: "flex-end",
            display: "flex",
        },
        scoreContainer: {
            justifyContent: "space-between",
            alignItems: "center",
        },
    });
};
