import { useMutation } from "react-query";
import { ApproveDeclarationControlDialogProps } from "./ApproveDeclarationControlDialog";
import { put } from "../../../common/api/apiShared";

export function useApproveDeclarationControlDialog(props: ApproveDeclarationControlDialogProps) {
    const changeApprovedForControlMutation = useMutation(approveControlApi);
    const changeApprovedForControl = async (approved: boolean) => {
        await changeApprovedForControlMutation.mutateAsync(approved, {
            onSuccess: () => {
                props.onApprovedChanged();
            },
        });
    };

    return { changeApprovedForControl, isSaving: changeApprovedForControlMutation.isLoading };

    function approveControlApi(approved: boolean) {
        return put(`/Declarationcontrol/${props.controlId}/changeApproved`, approved);
    }
}
