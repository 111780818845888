import { useTheme } from "@mui/material";
import { createSxStyles } from "../../../common/createSxStyles";

export const useSystemSelectorStyles = () => {
    const theme = useTheme();
    const stdWidth = "50%";
    let systemContainerWidth = window.innerWidth <= 1500 ? "100%" : stdWidth;
    return createSxStyles({
        systemContainer: {
            position: "relative",
            width: stdWidth,
            paddingBottom: theme.spacing(2),
            alignSelf: "center",
        },
        container: {
            display: "flex",
            padding: theme.spacing(1),
            color: theme.palette.primary.dark,
        },
        toolTip: {
            marginRight: theme.spacing(1),
        },
        paper: {
            backgroundColor: theme.palette.background.default,
            width: systemContainerWidth,
            alignSelf: "center",
        },
        primaryText: {
            fontWeight: theme.typography.fontWeightBold,
            fontSize: theme.typography.pxToRem(14),
        },
        secondaryText: {
            fontSize: theme.typography.pxToRem(14),
        },
        addSystemAnchor: {
            textDecoration: "underline",
            cursor: "pointer",
            color: theme.palette.primary.main,
        },
        flex: {
            display: "flex",
            marginRight: theme.spacing(0.5),
        },
    });
};
