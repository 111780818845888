import { useMutation, useQuery } from "react-query";
import { useExpandedId } from "../../../common/hooks/useExpandedId";
import { SelectableAssociatedAssetItem } from "./SystemRelationStep.types";
import { deleteHttp, get, post, put } from "../../../common/api/apiShared";
import { useState } from "react";
import { useOptimisticUpdate } from "../../../common/hooks/useOptimisticUpdate";
import { SystemModel } from "../SystemEdit.types";
import { ISystemRelationStep } from "./SystemRelationStep";

export default function useSystemRelationStepHooks(props: ISystemRelationStep) {
    const optimisticUpdate = useOptimisticUpdate();
    const { getExpandedId, setExpandedId } = useExpandedId();
    const selectableRelationsQuery = useQuery("selectableSystemRelations", () => get<Array<SelectableAssociatedAssetItem>>("/system/selectableRelations"));
    const [isAddingNewRelation, setIsAddingNewRelation] = useState(false);
    let queryKey = "systemRelation" + props.id;

    const addRelationMutation = useMutation(addRelationApi);
    const removeSystemMutation = useMutation(removeRelationApi);
    const updateRelationMutation = useMutation(updateRelationApi);

    const handleRelationChange = async (id: string) => {
        if (isAddingNewRelation) {
            let temp = [...props.relatedSystems];
            temp.push(id);

            await optimisticUpdate.setQueryData(queryKey, temp);
            addRelationMutation.mutate(id);
            setIsAddingNewRelation(false);
        } else {
            let fromSystemId = getExpandedId();

            let temp = [...props.relatedSystems];
            var systemIndex = temp.findIndex((x) => x === fromSystemId);
            temp[systemIndex] = id;

            await optimisticUpdate.setQueryData(queryKey, temp);
            updateRelationMutation.mutate({ fromSystemId: fromSystemId, toSystemId: id });
        }

        setExpandedId(id);
    };

    const handleRemoveSystem = async (systemId: string) => {
        if (getExpandedId() === systemId) setExpandedId("");

        let temp = [...props.relatedSystems];
        temp = temp.filter((x) => x !== systemId);

        await optimisticUpdate.setQueryData(queryKey, temp);

        removeSystemMutation.mutate(systemId);
    };

    function onExpandClick(id: string) {
        if (getExpandedId() === id) {
            setExpandedId("");
        } else {
            setExpandedId(id);
        }
    }

    return {
        getExpandedId,
        setExpandedId,
        data: selectableRelationsQuery.data?.filter((x) => x.id !== props.id),
        isLoading: selectableRelationsQuery.isLoading,
        handleRelationChange,
        isAddingNewRelation,
        setIsAddingNewRelation,
        onExpandClick,
        handleRemoveSystem,
    };

    function convertToSaveModel(systemId2: string) {
        return {
            systemId1: props.id,
            systemId2: systemId2,
        };
    }

    async function addRelationApi(systemId: string) {
        return await post<SystemModel>("/systemRelation/", convertToSaveModel(systemId));
    }

    async function removeRelationApi(systemId: string) {
        return await deleteHttp(`/systemRelation/${props.id}/${systemId}`);
    }

    async function updateRelationApi(data: { fromSystemId: string; toSystemId: string }) {
        return await put(`/systemRelation/${props.id}/`, { fromSystemId: data.fromSystemId, toSystemId: data.toSystemId });
    }
}
