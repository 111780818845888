import { useQuery } from "react-query";
import { get } from "../../common/api/apiShared";
import { DashboardTaskViewModel, TaskManagementDashboardTaskViewModel } from "./TaskWidget.types";
import { TasksWidgetProps } from "./TaskWidget";
import { useUrlProvider } from "../../useUrlProvider";
import { TaskLaneStateEnum } from "../../annualWheel/AnnualWheel.types";
import { useStateLocalStorage } from "@dotlegal/dotlegal-ui-components";
import { usePermissionsProvider } from "../../auth/usePermissionsProvider";
import { useStateUrlParams } from "../../common/hooks/useStateUrlParams";

export function useTasksWidget(props: TasksWidgetProps) {
    const permissionsProvider = usePermissionsProvider();

    const { getTaskManagementUrl } = useUrlProvider();
    const [showCurrentMonth, setShowCurrentMonth] = useStateLocalStorage("dashboard-tasks-current-month", props.showCurrentMonth ?? false);
    const [selectedTask, setSelectedTask] = useStateUrlParams("taskId", "");

    const isEnabled = permissionsProvider.hasTaskManagementAccess();
    const url = getTaskManagementDashboardUrl(props.onlyUserSpecific, props.selectedGroupCompany);
    const { isLoading, data, refetch } = useQuery(url, () => get<TaskManagementDashboardTaskViewModel>(url), { enabled: isEnabled });

    const tasks = getDataForView(showCurrentMonth, data?.tasks ?? []);

    const refetchTasks = () => {
        refetch();
    };

    return {
        isLoading,
        tasks,
        progress: getProgress(showCurrentMonth, tasks, data?.completedTasks ?? 0),
        noOverdueTaskStreakInMonths: data?.noOverdueTaskStreakInMonths ?? 0,
        isEnabled,
        showCurrentMonth,
        numberOfOverdueTasks: data?.tasks.filter((x) => x.isOverdue)?.length ?? 0,
        numberOfTasksThisMonth: data?.tasks.filter((x) => !x.isOverdue)?.length ?? 0,
        setShowCurrentMonth,
        getTaskManagementUrl,
        selectedTask,
        setSelectedTask,
        refetchTasks,
    };
}

export function getTaskManagementDashboardUrl(onlyUserSpecific: boolean, selectedGroupCompany: string | undefined) {
    return `/taskManagement/dashboard?onlyUserSpecific=${onlyUserSpecific}&groupCompany=${selectedGroupCompany}`;
}

export function getProgress(showCurrentMonth: boolean, tasks: Array<DashboardTaskViewModel>, completedTasks: number) {
    if (tasks.length === 0) return showCurrentMonth ? 0 : 100;

    if (showCurrentMonth) {
        const numberOfIncompletedTasks = tasks.filter((x) => x.laneState !== TaskLaneStateEnum.Completed).length;
        const result = (tasks.filter((x) => x.laneState === TaskLaneStateEnum.Completed).length / tasks.length) * 100;
        return numberOfIncompletedTasks > 0 && result >= 99 ? Math.floor(result) : Math.ceil(result);
    }

    const totalTasks = completedTasks + tasks.length;
    const result = (completedTasks / totalTasks) * 100;
    return tasks.length > 0 && result >= 99 ? Math.floor(result) : Math.ceil(result);
}

export function getDataForView(showCurrentMonth: boolean, tasks: Array<DashboardTaskViewModel>) {
    if (showCurrentMonth) {
        return tasks
            .filter((x) => !x.isOverdue)
            .sort((a, b) => {
                if (a.laneState === TaskLaneStateEnum.Ready && b.laneState !== TaskLaneStateEnum.Ready) {
                    return -1; // a comes first
                } else if (a.laneState !== TaskLaneStateEnum.Ready && b.laneState === TaskLaneStateEnum.Ready) {
                    return 1; // b comes first
                } else {
                    return a.laneState - b.laneState; // Sort by enum value for the rest
                }
            });
    }

    return tasks
        .filter((x) => x.isOverdue)
        .sort((a, b) => {
            return new Date(a.deadline).getTime() - new Date(b.deadline).getTime();
        });
}
