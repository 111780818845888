import { Badge, Box, Fab } from "@mui/material";
import { useCommentButtonStyle } from "./CommentButton.styles";
import CommentIcon from "./commentIcon.svg?react";
import { DotLegalTooltip } from "@dotlegal/dotlegal-ui-components";
import PlanInputFieldWrapper from "../../plan/planInputFieldWrapper/PlanInputFieldWrapper";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";
import { useTranslation } from "../../localization/useTranslation";

export interface ICommentButton {
    onButtonClick: () => void;
    id: string;
    unreadComments?: Number;
}

function CommentButton(props: ICommentButton) {
    const styles = useCommentButtonStyle();
    const { platformFeatures } = usePlanContext();
    const { translateString } = useTranslation();

    return (
        <Box sx={styles.container}>
            <PlanInputFieldWrapper hasAccess={platformFeatures.commentModule} hoverText={translateString("upgradePrivacyPlanToday")}>
                <Fab disabled={!platformFeatures.commentModule} onClick={() => props.onButtonClick()} color="primary" aria-label="add">
                    <Badge badgeContent={props.unreadComments ? <>{props.unreadComments}</> : undefined} color={"error"}>
                        <CommentIcon />
                    </Badge>
                </Fab>
            </PlanInputFieldWrapper>
        </Box>
    );
}

export default CommentButton;
