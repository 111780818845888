import { isBool } from "./booleanOperations";

export async function onAgreementsChanged(
    selectedAgreements: Array<string>,
    existingAgreements: Array<string>,
    yesNoSelected: (yesOrNo: boolean) => void,
    agreementSelected: () => void
) {
    if (selectedAgreements.length > 0) {
        const boolAgreement = selectedAgreements.find((x) => isBool(x));
        const isDocumentAdd = existingAgreements.length === 0 && selectedAgreements.length > 1;
        if (boolAgreement && isDocumentAdd === false) {
            let bool = boolAgreement.toLowerCase() === "true";
            await yesNoSelected(bool);
        } else {
            await agreementSelected();
        }
    } else {
        await yesNoSelected(false);
    }
}
