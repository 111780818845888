import { DotLegalCheckbox, DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import { Box, Skeleton, Typography } from "@mui/material";
import { useTranslation } from "../../localization/useTranslation";
import LegalEntitySelector from "../legalEntitySelector/LegalEntitySelector";
import { useGroupEntityAccessSelectorHooks } from "./GroupEntityAccessSelector.hooks";
import React from "react";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";

export interface IGroupEntityAcceesSelector {
    groupEntityAccessIds?: Array<string>;
    allGroupEntities?: boolean;
    onGroupEntityAccessIdsChange: (ids: Array<string>) => void;
    errorMsg?: string;
    isEdit: boolean;
}

function GroupEntityAcceesSelector(props: IGroupEntityAcceesSelector) {
    const { gdpo } = useUserContext();
    const { translateString } = useTranslation();
    const { selectableGroupCompaniesData, selectableGroupCompaniesLoading } = useGroupEntityAccessSelectorHooks(props);

    function showLegalEntitySelector() {
        if (!props.isEdit) {
            return true;
        }

        return (props.allGroupEntities && gdpo) || !props.allGroupEntities;
    }

    return (
        <Box sx={{ marginTop: 4, marginBottom: 0.5 }}>
            {selectableGroupCompaniesLoading && (
                <React.Fragment>
                    {[1, 2, 3, 4, 5].map((x) => {
                        return <Skeleton key={x} variant="rectangular" sx={{ width: "100%", marginTop: 2 }} height={20} />;
                    })}
                </React.Fragment>
            )}
            {selectableGroupCompaniesData && selectableGroupCompaniesData?.length > 1 && (
                <React.Fragment>
                    <DotLegalHeader headerStyle={"small"}>{translateString("whichGroupEntitiesShouldTheDocumentApplyTo")}</DotLegalHeader>

                    <Box
                        sx={{
                            marginBottom: 1,
                        }}
                    >
                        <DotLegalCheckbox
                            checked={props.allGroupEntities ?? false}
                            onChange={(value) => props.onGroupEntityAccessIdsChange([])}
                            label={translateString("globalDocument")}
                            tooltip={translateString("globalDocumentTooltip")}
                            disabled={!gdpo}
                        />
                    </Box>
                    {showLegalEntitySelector() && (
                        <React.Fragment>
                            {" "}
                            <Box sx={{ marginTop: 3 }}>
                                <LegalEntitySelector
                                    marginTop={0}
                                    allColumns={false}
                                    isLoading={false}
                                    selectedEntities={props.groupEntityAccessIds ?? []}
                                    legalEntityItems={selectableGroupCompaniesData}
                                    setSelectedEntities={(values) => props.onGroupEntityAccessIdsChange(values)}
                                    hideGridSize
                                    gridlength={10000}
                                />
                            </Box>
                            <Typography sx={(theme) => ({ color: theme.palette.error.main, fontSize: 12 })}>{props.errorMsg}</Typography>
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
        </Box>
    );
}

export default GroupEntityAcceesSelector;
