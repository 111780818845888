import { createElement } from "react";
import { Trans } from "react-i18next";
import { useMutation } from "react-query";
import { deleteHttp } from "../../../common/api/apiShared";
import { Result } from "../../../common/api/result";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { IDeleteDeletionPeriod } from "./DeleteDeletionPeriodDialog";

export function useDeleteDeletionPeriodDialogHooks(props: IDeleteDeletionPeriod) {
    const snackbar = useDotLegalSnackbar();

    const removeMutation = useMutation(remove);
    const removePurpose = () => {
        removeMutation.mutateAsync(undefined, {
            onSuccess: (response: Result<string>) => {
                props.onCloseDialog();

                if (response.success()) {
                    props.onSaveCorrect();
                    snackbar.show(createElement(Trans, { i18nKey: "deletionPeriodDeleted", values: { name: props.selectedDeletionPeriod.name } }));
                } else {
                    snackbar.show(
                        createElement(Trans, { i18nKey: "deletionPeriodNotDeleted", values: { name: props.selectedDeletionPeriod.name } }),
                        "warning"
                    );
                }
            },
        });
    };

    return {
        removePurpose,
        inProgess: removeMutation.isLoading,
    };

    async function remove() {
        return await deleteHttp<string>(`/ProcessingActivity/deletionperiod/${props.selectedDeletionPeriod.id}`);
    }
}
