import { useMutation } from "react-query";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { put } from "../../common/api/apiShared";
import { IReopenInternalAssessmentDialog } from "./ReopenInternalAssessmentDialog";
import { useTranslation } from "../../localization/useTranslation";

export default function useReopenInternalAssessmentHooks(props: IReopenInternalAssessmentDialog) {
    const { translateString } = useTranslation();
    const snackbar = useDotLegalSnackbar();

    const reopenMutation = useMutation(reopenInternalAssessmentAPI, {
        onSuccess: () => {
            snackbar.show(translateString("internalAssessmentReopened"));
            props.onInternalAssessmentReopened();
        },
    });

    const onReopenClick = () => {
        reopenMutation.mutateAsync();
    };

    return { onReopenClick, isSaving: reopenMutation.isLoading };

    function reopenInternalAssessmentAPI() {
        return put(`/internalAssessments/${props.internalAssessmentId}/reopen`, null);
    }
}
