import { createElement } from "react";
import { Trans } from "react-i18next";
import { useMutation } from "react-query";
import { put } from "../../../common/api/apiShared";
import { Result } from "../../../common/api/result";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { LegislationSaveModel } from "../legislationDialog/LegislationDialog.types";
import { IChangeLegislationStatusDialogDataMapping } from "./ChangeLegislationStatusDialog";

export function useChangeLegislationStatusDialogDataMapping(props: IChangeLegislationStatusDialogDataMapping) {
    const snackbar = useDotLegalSnackbar();

    const changeActiveStatusMutation = useMutation(changeStatus);

    const changeActiveStatus = (legislation: LegislationSaveModel) => {
        changeActiveStatusMutation.mutateAsync(legislation, {
            onSuccess: (response: Result<string>) => {
                props.onCloseDialog();
                if (response.success()) {
                    snackbar.show(createElement(Trans, { i18nKey: "activeStatusChanged", values: { name: legislation?.name } }));
                    props.onStatusChange();
                } else {
                    snackbar.show(createElement(Trans, { i18nKey: "legislationStatusNotChanged", values: { name: legislation?.name } }), "warning");
                }
            },
        });
    };

    function changeStatus(nationalLaw: LegislationSaveModel) {
        return put<string>(`/legislations/${nationalLaw.id}/changeActiveStatus`, !nationalLaw.active);
    }

    return {
        changeActiveStatus,
        inProgress: changeActiveStatusMutation.isLoading,
    };
}
