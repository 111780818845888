import React from "react";
import DotLegalDeleteDialogWrapper from "../../../common/components/dotLegalDeleteDialog/DotLegalDeleteDialogWrapper";
import { AreaViewModel } from "../areas/Areas.types";
import { useDeleteAreaDialogHooks } from "./DeleteAreaDialog.hooks";

export interface IDeleteAreaDialog {
    onCloseDialog: () => void;
    onDeleted: () => void;
    selectedArea: AreaViewModel;
}

export default function DeleteAreaDialog(props: IDeleteAreaDialog) {
    const { removeArea, inProgess } = useDeleteAreaDialogHooks(props);

    return (
        <DotLegalDeleteDialogWrapper
            open
            itemForDeletion={props.selectedArea.name}
            onDialogClose={props.onCloseDialog}
            onOkClick={removeArea}
            inProgress={inProgess}
        />
    );
}
