import { LegalEntityAuditGroupAuditModel } from "../../legalEntityAuditGroupOverview/LegalEntityAuditGroupOverview.types";
import { useTranslation } from "../../../localization/useTranslation";
import { LegalEntityAuditStatus } from "../../../legalEntity/legalEntityAuditTab/LegalEntityAudit.types";
import { DotLegalSelectOption } from "../../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { useStateUrlParamsArray } from "../../../common/hooks/useStateUrlParams";
import { IManagementReportExcludedAudits } from "./ManagementReportExcludedAudits";

export function useManagementReportExcludedAuditsHooks(props: IManagementReportExcludedAudits) {
    const [searchedStatus, setSearchedStatus] = useStateUrlParamsArray("exclusionReason", []);

    const { translateLegalEntityAuditStatus } = useTranslation();

    let exclusionFilterOptions: Array<DotLegalSelectOption> = [];

    if (props.audits) {
        createExclusionFilterOptions(props.audits.flatMap((x) => x.status));
    }

    function createExclusionFilterOptions(allAuditStatus: Array<LegalEntityAuditStatus>) {
        allAuditStatus.forEach((status) => {
            if (!exclusionFilterOptions.some((x) => x.id == status.toString())) {
                exclusionFilterOptions.push({
                    id: status.toString(),
                    name: translateLegalEntityAuditStatus(status.toString()),
                });
            }
        });

        exclusionFilterOptions.sort((a, b) => (a.name > b.name ? 1 : -1));
    }

    return {
        rows: getFilteredRows(props.audits),
        exclusionFilterOptions,
        searchedStatus,
        setSearchedStatus,
    };

    function getFilteredRows(rows: Array<LegalEntityAuditGroupAuditModel>) {
        let tempData = [...rows];

        if (searchedStatus.length > 0 && searchedStatus[0] !== "") {
            tempData = tempData.filter((row) => searchedStatus.indexOf(row.status.toString()) >= 0);
        }

        return tempData;
    }
}
