import { createElement, useRef, useState } from "react";
import { Trans } from "react-i18next";
import { useMutation } from "react-query";
import { post } from "../../common/api/apiShared";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { useTrackAIEvent, TrackingEvent } from "../hooks/useTracking";
import { ICloneProcessingActivityDialog } from "./CopyProcessingActivityDialog";

export function useCopyProcessingActivityDataMapping(props: ICloneProcessingActivityDialog) {
    const snackbar = useDotLegalSnackbar();
    const { translateString } = useTranslation();
    const [inProgress, setInProgress] = useState(false);
    const isNameValid = useRef(false);
    const trackEvent = useTrackAIEvent();
    const [copyModel, setCopyModel] = useState({ id: props.copyModel.id, name: `${translateString("copySingular")} ${props.copyModel.name}` });
    isNameValid.current = copyModel.name.trimEnd().length > 0;

    const saveMutation = useMutation(copy, {
        onSuccess: (response: any) => {
            const value = response.value();
            if (value.isCreated) {
                trackEvent(TrackingEvent.ProcessingActivityCloned, { processingActivity: value.id });
                props.onSave();
                snackbar.show(createElement(Trans, { i18nKey: "processingActivityCopied", values: { name: props.copyModel.name, newName: copyModel.name } }));
            } else {
                setInProgress(false);
                snackbar.show(translateString("processingActivityExists"), "warning");
            }
        },
    });

    const copyProcessingActivity = async () => {
        await saveMutation.mutateAsync();
    };

    function copy() {
        setInProgress(true);
        return post<{}>(`/ProcessingActivity/${copyModel.id}/copy`, { name: copyModel.name });
    }

    return {
        inProgress,
        setInProgress,
        copyProcessingActivity,
        isNameValid,
        copyModel,
        setCopyModel,
    };
}
