import { useMutation, useQuery } from "react-query";
import { deleteHttp, get, post } from "../../common/api/apiShared";
import { useDotLegalFileUploadDataMapping } from "../../common/components/dotLegalFileUploadTable/DotLegalFileUploadTable.hooks";
import { isNullOrWhitespace } from "../../common/stringOperations";
import { IDocumentVersionTable } from "./DocumentVersionTable";
import { createElement, useState } from "react";
import { useValidator } from "../../common/hooks/validation";
import { DocumentVersionViewModel, LegalEntityDocumentSaveModel } from "./DocumentVersionTable.types";
import { DotLegalFile, useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { Trans } from "react-i18next";
import { _arrayBufferToBase64 } from "../../common/arrayBufferToBase64";

export function useDocumentVersionTableHook(props: IDocumentVersionTable) {
    const snackbar = useDotLegalSnackbar();
    const { translateString } = useTranslation();
    const { downloadDocumentVersion } = useDotLegalFileUploadDataMapping();

    const [selectedDocumentVersion, setSelectedDocumentVersion] = useState("");
    const [documentationOption, setDocumentationOption] = useState(1);
    const [document, setDocument] = useState<LegalEntityDocumentSaveModel>(new LegalEntityDocumentSaveModel());

    const url = `/document/${props.documentId}`;
    const { data, isLoading, refetch } = useQuery(url, () => get<Array<DocumentVersionViewModel>>(url));

    const linkValidation = useValidator<LegalEntityDocumentSaveModel>(
        (validators) => ({
            link: documentationOption === 1 ? undefined : validators.validateUrlAndNotEmpty((item) => item.link, translateString("link")),
        }),
        [documentationOption]
    );

    const handleDownloadClick = (documentId: string, dataId: string, fileName: string, link: string) => {
        if (!isNullOrWhitespace(fileName)) {
            downloadDocumentVersion(documentId, dataId, fileName);
        } else {
            window.open(link, "_blank");
        }
    };

    const removeMutation = useMutation(getRemoveDocumentVersion);

    const deleteDocumentVersion = (documentId: string, documentVersionId: string) => {
        removeMutation.mutateAsync(
            { documentId: documentId, documentVersionId: documentVersionId },
            {
                onSuccess: (response: any) => {
                    refetch();
                },
            }
        );
    };

    async function getRemoveDocumentVersion(doc: { documentId: string; documentVersionId: string }) {
        return deleteHttp(`/document/${doc.documentId}/${doc.documentVersionId}`);
    }

    const uploadDocumentVersionMutation = useMutation((document: LegalEntityDocumentSaveModel) => post(`/document/${props.documentId}`, document));

    const uploadDocumentVersion = async (files: Array<DotLegalFile> | undefined) => {
        props.setDocumentsUploading(true);
        if (files) {
            await uploadDocumentVersionMutation.mutateAsync({
                fileName: files[0].fileName,
                base64Data: await _arrayBufferToBase64(files[0].data),
                link: undefined,
            });

            await refetch();

            snackbar.show(createElement(Trans, { i18nKey: "documentVersionUploadet", values: { document: files[0].fileName } }));
        }

        props.setDocumentsUploading(false);
    };

    async function uploadLink() {
        if (linkValidation.anyHasErrors) {
            linkValidation.setShowErrors(true);
            return;
        }

        props.setDocumentsUploading(true);

        await uploadDocumentVersionMutation.mutateAsync({
            fileName: undefined,
            base64Data: "",
            link: document?.link,
        });
        await refetch();

        props.setDocumentsUploading(false);

        snackbar.show(createElement(Trans, { i18nKey: "documentVersionUploadet", values: { document: document?.link } }));
        setDocument(new LegalEntityDocumentSaveModel());

        linkValidation.setShowErrors(false);
    }

    return {
        handleDownloadClick,
        deleteDocumentVersion,
        linkValidation,
        uploadDocumentVersion,
        document,
        setDocument,
        uploadLink,
        setDocumentationOption,
        documentationOption,
        setSelectedDocumentVersion,
        selectedDocumentVersion,
        isLoading,
        data,
    };
}
