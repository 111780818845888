import { useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";

export const useProcessingActivityOverviewDialogStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        link: {
            color: theme.palette.secondary.main,
            "&:hover": {
                textDecoration: "underline",
            },
        },
        baContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
        },
        header: {
            fontSize: theme.typography.pxToRem(12),
            color: theme.palette.primary.dark,
            textAlign: "center",
        },
    });
};
