import React from "react";
import { useRiskWidget } from "./RiskWidget.hooks.ts";
import Widget from "../Widget.tsx";
import { DotLegalHeader, DotLegalSwitch, DotLegalTooltip, ITableHeader } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation.ts";
import { Box } from "@mui/material";
import { DashboardRiskOverviewRow } from "./RiskWidget.types.ts";
import { WidgetLock } from "../WidgetLock.tsx";
import RiskWidgetAssessment from "./riskWidgetAssessment/RiskWidgetAssessment.tsx";
import RiskWidgetList from "./riskWidgetList/RiskWidgetList.tsx";
import { usePlanContext } from "../../auth/planProvider/PlanProvider.tsx";
import { GetProcessingActivityPlanLimit, GetSystemsPlanLimit } from "../../auth/planProvider/PlanProvider.hooks.ts";

export interface IRiskWidgetProps {
    onlyUserSpecific: boolean;
    selectedGroupCompany: string | undefined;
    isSystemChosen?: boolean;
}

function RiskWidget(props: IRiskWidgetProps) {
    const { translateString } = useTranslation();
    const { isLoading, onPaOrSystemChange, systemsChosen, data, isEnabled, systemsOptionEnabled, processingActivityOptionEnabled } = useRiskWidget(props);

    const { data: processingActivityPlanLimit } = GetProcessingActivityPlanLimit(!systemsChosen);
    const { data: systemPlanLimit } = GetSystemsPlanLimit(systemsChosen);
    let disableRows;

    if (systemsChosen) {
        disableRows = systemPlanLimit?.limitExceeded;
    } else {
        disableRows = processingActivityPlanLimit?.limitExceeded;
    }

    const headers: Array<ITableHeader<DashboardRiskOverviewRow>> = [];
    headers.push({
        property: "name",
        text: systemsChosen ? translateString("asset") : translateString("processingActivity"),
    });
    headers.push({ property: "area", text: translateString("area") });
    headers.push({ property: "riskScore", text: translateString("risk") });

    return (
        <Widget disabled={!isEnabled}>
            <DotLegalHeader marginBottom={0} headerStyle={"medium"}>
                {translateString("risk").toUpperCase()}
            </DotLegalHeader>
            <DotLegalSwitch
                onChange={onPaOrSystemChange}
                checked={systemsChosen}
                disabled={!systemsOptionEnabled || !processingActivityOptionEnabled}
                leftLabel={
                    <Box component={"span"}>
                        {!processingActivityOptionEnabled && (
                            <Box sx={{ display: "inline" }}>
                                <WidgetLock />
                            </Box>
                        )}
                        {translateString("processingActivities")}
                    </Box>
                }
                rightLabel={
                    <Box component={"span"}>
                        {translateString("assets")}
                        {!systemsOptionEnabled && (
                            <Box sx={{ display: "inline" }}>
                                <WidgetLock />
                            </Box>
                        )}
                    </Box>
                }
            />

            <RiskWidgetAssessment marginTop={1} isLoading={isLoading} percentage={data?.gdprPercentage} type="GDPR" />
            <RiskWidgetAssessment marginTop={2} isLoading={isLoading} percentage={data?.nisPercentage} type="NIS2" />

            <RiskWidgetList disableRows={disableRows} data={data} isLoading={isLoading} systemsChosen={systemsChosen} />
        </Widget>
    );
}

export default RiskWidget;
