import { useQuery } from "react-query";
import { postGet } from "../../common/api/apiShared";
import { SelectableDocumentItem } from "../ProcessingActivity.types";
import { DotLegalDocumentType } from "../../documents/Documents.types";

export function useSelectableDocumentsQuery(isEnabled: boolean = true, legalEntityId?: string, processingActivityId?: string) {
    return useSelectableDocumentsForLegalEntitiesQuery(isEnabled, legalEntityId ? [legalEntityId] : [], processingActivityId);
}

export function useSelectableDocumentsForLegalEntitiesQuery(isEnabled: boolean = true, legalEntityIds: Array<string>, processingActivityId?: string) {
    const url = getDocumentQueryUrl(processingActivityId);
    let query = useQuery(
        "useSelectableDocumentsForLegalEntitiesQuery" + legalEntityIds.join("|"),
        () => postGet<Array<SelectableDocumentItem>>(url, legalEntityIds),
        {
            enabled: isEnabled && legalEntityIds.length > 0,
        }
    );
    return {
        isLoading: query.isLoading,
        allDocuments: query.data,
        dataProcessorDocuments: query.data?.filter(
            (x) =>
                x.type !== DotLegalDocumentType.DisclosureAgreement &&
                x.type !== DotLegalDocumentType.JointDataControllerAgreement &&
                x.type !== DotLegalDocumentType.TIA
        ),
        disclosureDocuments: query.data?.filter(
            (x) =>
                x.type !== DotLegalDocumentType.DataProcessorAgreement &&
                x.type !== DotLegalDocumentType.JointDataControllerAgreement &&
                x.type !== DotLegalDocumentType.TIA
        ),
        jointDataControllerDocuments: query.data?.filter(
            (x) =>
                x.type !== DotLegalDocumentType.DisclosureAgreement &&
                x.type !== DotLegalDocumentType.DataProcessorAgreement &&
                x.type !== DotLegalDocumentType.TIA
        ),
        transferImpactAssessmentDocs: query.data?.filter((x) => x.type === DotLegalDocumentType.TIA),
        refetch: query.refetch,
    };
}

function getDocumentQueryUrl(processingActivityId?: string) {
    if (processingActivityId) {
        return `/LegalEntity/selectableDocuments/${processingActivityId}`;
    }

    return `/LegalEntity/selectableDocuments`;
}

export function getUserIdForDocumentQuery(userProfileId: string, sharedResponsibles: Array<string>, processingActivityOwner?: string | undefined | null) {
    if (processingActivityOwner && sharedResponsibles.some((x) => x === userProfileId)) {
        return processingActivityOwner;
    }

    return null;
}
