import { useMutation, useQuery } from "react-query";
import { deleteHttp, get } from "../../../common/api/apiShared.ts";
import { DataType } from "./DataTypesList.types.ts";
import { useMasterDataBreadCrumbProvider } from "../../useMasterDataBreadCrumbProvider.ts";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider.tsx";
import { createElement, useState } from "react";
import { useTranslation } from "../../../localization/useTranslation.ts";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { Trans } from "react-i18next";

export function useDataTypesList() {
    const url = "/DataTypes";
    const { isLoading, data: dataTypes, refetch } = useQuery(url, () => get<Array<DataType>>(url));
    const masterDataBreadCrumbProvider = useMasterDataBreadCrumbProvider();
    const { permissions, customerName } = useUserContext();
    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const [idForUpdate, setIdForUpdate] = useState<string>();
    const { translateString } = useTranslation();
    const [dataTypeForDeletion, setDataTypeForDeletion] = useState<DataType | undefined>(undefined);
    const snackBar = useDotLegalSnackbar();

    const breadCrumbs = masterDataBreadCrumbProvider.getDataTypesBreadCrumbs();

    const deleteDataType = async (id: string) => {
        return await deleteHttp(`/dataTypes/${id}`);
    };

    const deleteMutation = useMutation(deleteDataType, {
        onSuccess: async (result) => {
            if (result.success()) {
                await refetch();
                snackBar.show(createElement(Trans, { i18nKey: "itemDeletedSuccess", values: { name: dataTypeForDeletion?.name } }));
                setDataTypeForDeletion(undefined);
            } else {
                snackBar.show(createElement(Trans, { i18nKey: "cantDeleteDataType", values: { name: dataTypeForDeletion?.name } }), "warning");
            }
        },
    });

    const onCreateClick = () => {
        setCreateDialogOpen(true);
    };

    const onUpdateClick = (id: string) => {
        setIdForUpdate(id);
    };

    const onSaved = async () => {
        await refetch();
        onDialogClose();
    };

    const onDialogClose = () => {
        setIdForUpdate(undefined);
        setCreateDialogOpen(false);
    };

    const getMenuItems = (dc: DataType) => {
        let menuItems = [];
        if (permissions.canManageMasterData) {
            menuItems.push({
                menuItem: translateString("edit"),
                onClick: () => {
                    setIdForUpdate(dc.id);
                },
            });

            menuItems.push({
                menuItem: translateString("delete"),
                onClick: () => {
                    setDataTypeForDeletion(dc);
                },
            });
        }

        return menuItems;
    };

    const onDelete = () => {
        deleteMutation.mutate(dataTypeForDeletion!.id);
    };

    return {
        isLoading,
        dataTypes,
        breadCrumbs,
        customerName,
        canCreate: permissions.canManageMasterData,
        onCreateClick,
        createDialogOpen,
        onUpdateClick,
        onDialogClose,
        onSaved,
        dataTypeToUpdate: dataTypes?.find((d) => d.id === idForUpdate),
        getMenuItems,
        dataTypeForDeletion,
        setDataTypeForDeletion,
        onDelete,
    };
}
