import { createElement } from "react";
import { Trans } from "react-i18next";
import { useMutation } from "react-query";
import { put } from "../../../common/api/apiShared";
import { Result } from "../../../common/api/result";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { BusinessAreaSaveModel } from "../businessAreaDialog/BusinessAreaDialog.types";
import { IChangeBusinessAreaStatusDialog } from "./ChangeBusinessAreaStatusDialog";

export function useChangeBusinessAreaStatusDialogDataMapping(props: IChangeBusinessAreaStatusDialog) {
    const snackbar = useDotLegalSnackbar();

    const changeActiveStatusMutation = useMutation(changeStatus);

    const changeActiveStatus = (businessArea: BusinessAreaSaveModel) => {
        changeActiveStatusMutation.mutateAsync(businessArea, {
            onSuccess: (response: Result<string>) => {
                props.onCloseDialog();

                if (response.success()) {
                    snackbar.show(createElement(Trans, { i18nKey: "activeStatusChanged", values: { name: businessArea.name } }));
                    props.OnSave();
                } else {
                    snackbar.show(createElement(Trans, { i18nKey: "businessAreaStatusNotChanged", values: { name: businessArea.name } }), "warning");
                }
            },
        });
    };

    function changeStatus(businessArea: BusinessAreaSaveModel) {
        if (businessArea.isCustom) return put<string>(`/businessareas/${businessArea.id}/changeActiveStatus`, !businessArea.active);
        else return put<string>(`/businessareas/${businessArea.id}/changeMasterDataActiveStatus`, !businessArea.active);
    }

    return {
        changeActiveStatus,
        inProgress: changeActiveStatusMutation.isLoading,
    };
}
