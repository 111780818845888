import { DotLegalDeleteDialog } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { Trans } from "react-i18next";
import { Box } from "@mui/material";
import useDeleteAuditDialogHooks from "./DeleteAuditDialog.hooks";

export interface IDeleteAuditDialog {
    auditId: string;
    questionnaireName: string;
    deadline: Date;
    onDialogClose: () => void;
    onSuccessDelete: () => void;
}

function DeleteAuditDialog(props: IDeleteAuditDialog) {
    const { translateString, translateDate } = useTranslation();
    const { removeAuditClick, inProgress, confirmDelete, setConfirmDelete } = useDeleteAuditDialogHooks(props);

    return (
        <DotLegalDeleteDialog
            open
            headerText={translateString("deleteAudit")}
            alertBodyText={
                <Trans
                    i18nKey={"deleteAuditContent"}
                    values={{
                        name: props.questionnaireName.trim(),
                        deadline: translateDate(props.deadline),
                    }}
                    components={{
                        wrapper: (
                            <Box sx={(theme) => ({ color: theme.palette.secondary.main, fontWeight: theme.typography.fontWeightBold })} component="span"></Box>
                        ),
                    }}
                />
            }
            warningTranslation={""}
            deleteBtnText={translateString("delete")}
            cancelBtnText={translateString("cancel")}
            onDialogClose={props.onDialogClose}
            onOkClick={() => removeAuditClick()}
            confirmLabel={translateString("deleteAudit")}
            confirmDelete={confirmDelete}
            confirmDeleteRequired
            setConfirmDelete={setConfirmDelete}
            inProgress={inProgress}
        />
    );
}

export default DeleteAuditDialog;
