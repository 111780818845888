import { setupi18n } from "./LocalizationContext";
import { currentVersion } from "../VersionProvider";
import { getSettings } from "../common/settingsProvider";

export function setupLocalization() {
    const urlLang = new URLSearchParams(window.location.search).get("lang");
    let lang = localStorage.getItem("language");

    if (urlLang) lang = urlLang;

    let cacheKey = currentVersion;
    if (getSettings().isRunningOnLocalHost) {
        // When developing locally we don't want the browser to cache translations
        cacheKey = new Date().toISOString();
    }

    setupi18n(lang ?? "en", cacheKey);
}
