import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { deleteHttp, get, put } from "../../common/api/apiShared";
import { useExpandedId } from "../../common/hooks/useExpandedId";
import { useOptimisticUpdate } from "../../common/hooks/useOptimisticUpdate";
import { PropertyType } from "../../common/PropertyTypes";
import { TrackingEvent, useTrackAIEvent } from "../../processingActivity/hooks/useTracking";
import { ContentType } from "../CustomLists.types";
import { IEditCustomListDialogProps } from "./EditCustomListDialog";
import { EditCustomListViewModel, FilterViewModel } from "./EditCustomListDialog.types";

export function useEditCustomListDialog(props: IEditCustomListDialogProps) {
    const { putOnQueueAndSetQueryData } = useOptimisticUpdate();
    const [searchInput, setSearchInput] = useState("");
    const { getExpandedId, setExpandedId } = useExpandedId();
    const trackEvent = useTrackAIEvent();

    const key = "lists" + props.listId;
    let { isLoading, data } = useQuery(key, () => get<EditCustomListViewModel>("/CustomLists/" + props.listId));

    const addColumnTypeMutation = useMutation(addColumnType);
    const removeColumnTypeMutation = useMutation(removeColumnType);
    const updateColumnOrderMutation = useMutation(updateColumnOrder);

    async function toggleSelectedColumnType(columnType: PropertyType, contentType: ContentType) {
        let temp = { ...data! };

        let index = temp.selectedColumns.findIndex((y) => y.type === columnType);

        if (index < 0) {
            trackEvent(TrackingEvent.CustomListColumnAdded, { customList: props.listId, columnType: columnType });

            temp.selectedColumns.push({ type: columnType, contentType: contentType, isMandatory: false, filter: new FilterViewModel(), hideColumn: false });
            putOnQueueAndSetQueryData(temp, key, addColumnTypeMutation.mutateAsync, columnType);
            setExpandedId(columnType.toString());
        } else {
            trackEvent(TrackingEvent.CustomListColumnDeleted, { customList: props.listId, columnType: columnType });

            temp.selectedColumns.splice(index, 1);
            putOnQueueAndSetQueryData(temp, key, removeColumnTypeMutation.mutateAsync, columnType);
            setExpandedId("");
        }
    }

    async function reorderSelectedColumnTypes(sourceId: string, sourceColumn: number, destinationId: string, destinationColumn: number) {
        if (sourceId === destinationId) return;

        let temp = { ...data! };
        var columns = temp.selectedColumns;

        const sourceIndex = columns.findIndex((x) => x.type.toString() === sourceId);
        const destinationIndex = columns.findIndex((x) => x.type.toString() === destinationId);

        const [source] = columns.splice(sourceIndex, 1);
        const destination = columns.find((x) => x.type.toString() === destinationId)!;
        columns.splice(destinationIndex, 0, source);

        putOnQueueAndSetQueryData(temp, key, updateColumnOrderMutation.mutateAsync, { source: source.type, destination: destination?.type });
    }

    async function addColumnType(columnType: PropertyType) {
        return await put<{}>("/customlists/" + props.listId + "/addColumnType", columnType);
    }

    async function updateColumnOrder(data: { source: PropertyType; destination: PropertyType }) {
        return await put<{}>("/customlists/" + props.listId + "/updateOrder", data);
    }

    async function removeColumnType(columnType: PropertyType) {
        return await deleteHttp("/customlists/" + props.listId + "/removeColumnType/" + columnType);
    }

    const handleCollapseClick = (Id: string) => {
        if (Id) {
            if (getExpandedId() === Id) setExpandedId("");
            else setExpandedId(Id);
        }
    };

    let chosenColumns;

    if (data) {
        const filteredColumns = data.selectedColumns.filter((column) => {
            const content = data.allColumns.find((x) => x.type === column.type)!;
            if (content) {
                return true;
            }

            return false;
        });

        chosenColumns = filteredColumns.map((column) => {
            const content = data.allColumns.find((x) => x.type === column.type)!;
            return {
                type: column.type,
                contentType: content.contentType,
                isMandatory: content.isMandatory,
                filter: column.filter,
                hideColumn: column.hideColumn,
            };
        });
    }

    function includeFilterFunc(type: PropertyType) {
        return (
            type !== PropertyType.TotalProcessingTime &&
            type !== PropertyType.TotalCaseMangerProcessingTime &&
            type !== PropertyType.TotalResearcherTime &&
            type !== PropertyType.AverageCaseManagerProcessingTime &&
            type !== PropertyType.AverageResearcherProcessingTime
        );
    }

    return {
        searchInput,
        setSearchInput,
        isLoading,
        data,
        toggleSelectedColumnType,
        reorderSelectedColumnTypes,
        handleCollapseClick,
        getExpandedId,
        chosenColumns,
        includeFilterFunc,
        allColumns: data?.allColumns ?? [],
    };
}
