import { useQuery } from "react-query";
import { get } from "../../common/api/apiShared";
import { ProcessingActivitiesProgressViewModel } from "../../processingActivity/processingActivities/ProcessingActivities.types";
import { ProcessingActivityCountWigetProps } from "./ProcessingActivityCountWiget";
import { getProcessingActivitiesProgressUrl } from "../../processingActivity/processingActivities/ProcessingActivities.hooks";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider.tsx";
import { useState } from "react";

export function useProcessingActivityCountWidgetProps(props: ProcessingActivityCountWigetProps) {
    const userContext = useUserContext();
    const progreesUrl = getProcessingActivitiesProgressUrl(props.onlyUserSpecific, props.selectedGroupCompany);
    const isEnabled = userContext.permissions.processingActivityPermissions.read;

    let { isLoading, data } = useQuery(progreesUrl, () => get<ProcessingActivitiesProgressViewModel>(progreesUrl), { enabled: isEnabled });

    return { isLoading, data, isEnabled };
}
