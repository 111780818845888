import { DotLegalDialog, DotLegalRadioButton, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { Box, Collapse, Grid, RadioGroup } from "@mui/material";
import React from "react";
import DotLegalFileUploadWrapper from "../../../common/components/dotLegalFileUpload/DotLegalFileUploadWrapper";
import { useTranslation } from "../../../localization/useTranslation";
import { PolicyDocument } from "../PolkcyDocuments.types";
import { usePolicyDocumentDialogDataMapping } from "./PolicyDocumentDialog.hooks";

export interface IPolicyDocumentDialog {
    onDialogClose: () => void;
    onSave: () => void;
    selectedDocument?: PolicyDocument;
}

function PolicyDocumentDialog(props: IPolicyDocumentDialog) {
    const { translateString } = useTranslation();
    const { document, save, validation, isEdit, onNameChange, onLinkChange, onDocumentUpload, isSaving, documentationOption, onDocumentationTypeChange } =
        usePolicyDocumentDialogDataMapping(props);

    validation?.check(document);

    function getLinkComponent() {
        return (
            <DotLegalTextField
                label={translateString("insertLink")}
                value={document?.link ?? ""}
                debounce={false}
                errorText={validation?.getErrorsForDisplay("link")}
                onChange={(value) => {
                    onLinkChange(value);
                }}
            />
        );
    }

    return (
        <DotLegalDialog
            size={"sm"}
            buttonOkText={isEdit ? translateString("save") : translateString("upload")}
            onDialogClose={props.onDialogClose}
            open
            onOkClick={() => save()}
            header={isEdit ? translateString("editDocument") : translateString("uploadDocument")}
            inProgress={isSaving}
            disableBackdropOnCloseIfValueChange={document}
        >
            <React.Fragment>
                <Grid item md={12}>
                    <DotLegalTextField
                        label={translateString("name")}
                        value={document?.name!}
                        debounce={false}
                        errorText={validation?.getErrorsForDisplay("name")}
                        onChange={(value) => {
                            onNameChange(value);
                        }}
                    />
                </Grid>
                <Box marginTop={2}>
                    {!isEdit && (
                        <>
                            <RadioGroup
                                row
                                value={documentationOption}
                                onChange={(e) => {
                                    onDocumentationTypeChange(Number((e.target as HTMLInputElement).value));
                                }}
                            >
                                <DotLegalRadioButton value={1} labelPlacement="end" label={translateString("uploadDocument")} />
                                <DotLegalRadioButton value={0} labelPlacement="end" label={translateString("insertLink")} />
                            </RadioGroup>
                            <>
                                <Collapse in={documentationOption === 1}>
                                    {
                                        <Box sx={{ height: 70 }}>
                                            <DotLegalFileUploadWrapper
                                                files={[document?.fileName!]}
                                                onFilesChanged={async (file) => {
                                                    await onDocumentUpload(file);
                                                }}
                                                isLoading={false}
                                                errorText={validation?.getErrorsForDisplay("fileName")}
                                            />
                                        </Box>
                                    }
                                </Collapse>
                                <Collapse in={documentationOption === 0}>
                                    <Box sx={{ height: 70, "& > *": { marginTop: 0 } }}>{getLinkComponent()}</Box>
                                </Collapse>
                            </>
                        </>
                    )}
                    {isEdit && document.isLink && getLinkComponent()}
                </Box>
            </React.Fragment>
        </DotLegalDialog>
    );
}

export default PolicyDocumentDialog;
