import { DotLegalEmptyState } from "@dotlegal/dotlegal-ui-components";
import WhiteDashboardBox from "../../WhiteDashboardBox";
import { usePermissionsProvider } from "../../../auth/usePermissionsProvider";
import { Trans } from "react-i18next";
import DotLegalLink from "../../../common/components/dotLegalLink/DotLegalLink";
import { useTranslation } from "../../../localization/useTranslation";
import EmptyStateIcon from "../../svgs/tasks-widget-empty-state.svg?react";
import { useUrlProvider } from "../../../useUrlProvider";
import { Box } from "@mui/material";
import StreakIcon from "../../../common/svgs/streak.svg?react";

export interface ITaskWidgetEmptyStateProps {
    showCurrentMonth: boolean;
    noOverdueTaskStreakInMonths: number;
}

function TaskWidgetEmptyState(props: ITaskWidgetEmptyStateProps) {
    const permissionsProvider = usePermissionsProvider();
    const { translateString } = useTranslation();
    const { getAnnualWheelUrl } = useUrlProvider();

    const getEmptyStateData = () => {
        if (props.showCurrentMonth) {
            if (permissionsProvider.hasAnnualWheelActivityAccess()) {
                return {
                    icon: <EmptyStateIcon />,
                    text: (
                        <Trans
                            i18nKey={"dashboardCurrentTasksEmtpyState"}
                            components={{
                                box: (
                                    <DotLegalLink to={`${getAnnualWheelUrl()}?months=${new Date().getMonth() + 1}`} linkColor="primary">
                                        {undefined}
                                    </DotLegalLink>
                                ),
                            }}
                        />
                    ),
                };
            }

            return { icon: <EmptyStateIcon />, text: translateString("dashboardNoCurrentTasksNoAnnualWheelAccess") };
        }

        if (props.noOverdueTaskStreakInMonths === 0) {
            return { icon: <EmptyStateIcon />, text: translateString("noOverdueTasksDashboard") };
        }

        const taskStreakStyles = {
            fontSize: 14,
            marginBottom: 0.5,
        };
        const taskStreakOnFireStyles = {
            textTransform: "none !important",
            fontSize: 13,
        };

        if (props.noOverdueTaskStreakInMonths === 1) {
            return {
                icon: <StreakIcon />,
                text: (
                    <>
                        <Box sx={taskStreakStyles}>{translateString("taskStreakNumberSingle", { number: props.noOverdueTaskStreakInMonths })}</Box>
                        <Box sx={taskStreakOnFireStyles}>{translateString("taskStreakOnFireSingle", { number: props.noOverdueTaskStreakInMonths })}</Box>
                    </>
                ),
            };
        }
        return {
            icon: <StreakIcon />,
            text: (
                <>
                    <Box sx={taskStreakStyles}>{translateString("taskStreakNumberPlural", { number: props.noOverdueTaskStreakInMonths })}</Box>
                    <Box sx={taskStreakOnFireStyles}>{translateString("taskStreakOnFirePlural", { number: props.noOverdueTaskStreakInMonths })}</Box>
                </>
            ),
        };
    };

    const emptyStateData = getEmptyStateData();

    return (
        <WhiteDashboardBox fullHeight marginTop={2}>
            <DotLegalEmptyState textColor={"darkBlue"} icon={emptyStateData.icon} text={emptyStateData.text} />
        </WhiteDashboardBox>
    );
}

export default TaskWidgetEmptyState;
