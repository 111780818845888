import { useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";

export const useLanguageMenuStyle = () => {
    const theme = useTheme();
    return createSxStyles({
        languageContainer: {
            color: theme.palette.text.primary,
            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(1.5),
            paddingLeft: theme.spacing(1),
            display: "flex",
            position: "relative",
            fontSize: theme.typography.pxToRem(14),
            alignItems: "center",
            "&:hover": {
                backgroundColor: theme.palette.primary.light,
                borderRadius: 15 + "px",
            },
        },
        minimizedLanguageContainer: {
            padding: theme.spacing(1.5),
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "&:hover": {
                backgroundColor: theme.palette.primary.light,
                borderRadius: 15 + "px",
                cursor: "pointer",
            },
        },
        languageList: {
            marginLeft: theme.spacing(2),
        },
        splitter: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        language: {
            cursor: "pointer",
            "&:hover": {
                color: theme.palette.text.secondary,
            },
        },
        activeLanguage: {
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.secondary,
        },
        minimizedLanguage: {
            display: "flex",
            justifyContent: "center",
        },
        menuIcon: {
            display: "inline-block",
            verticalAlign: "bottom",
            "& svg": {
                width: theme.customSpacing.menuIconSize,
                height: theme.customSpacing.menuIconSize,
                stroke: theme.palette.primary.main,
                fill: "#DAD9EF",
            },
        },
    });
};
