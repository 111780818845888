import React from "react";
import { useTwoColumnContainerStyles } from "../TwoColumnContainer.styles";
import { Box, Grid } from "@mui/material";

export interface RightColumnProps {
    children: React.ReactNode;
}

function RightColumn(props: RightColumnProps) {
    const styles = useTwoColumnContainerStyles();
    return (
        <Grid item md={6} sx={styles.grid}>
            <Box
                sx={[
                    styles.commonContainer,
                    {
                        paddingBottom: 0,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                    },
                ]}
            >
                {props.children}
            </Box>
        </Grid>
    );
}

export default RightColumn;
