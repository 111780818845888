import { useEffect, useState } from "react";
import { IDotLegalNoteDialogProps } from "./DotLegalNoteDialog";

export function useDotLegalNoteDialogHook(props: IDotLegalNoteDialogProps) {
    const [note, setNote] = useState(props.noteText ?? "");

    useEffect(() => {
        if (props.noteText) {
            setNote(props.noteText);
        }
    }, [props.noteText]);

    return {
        note,
        setNote,
    };
}
