import React, { ErrorInfo } from "react";
import ErrorPage from "./ErrorPage";
import { handleError } from "../common/errorHandling";

export interface ErrorBoundaryProps {
    errorText: string;
    children: JSX.Element;
}

interface State {
    hasError: boolean;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, State> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error) {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        handleError(error, errorInfo.componentStack).then();
    }

    render() {
        if (this.state.hasError) {
            return <ErrorPage errorMessage={this.props.errorText} image="Technical" />;
        }

        return this.props.children;
    }
}
