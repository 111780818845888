import { useState } from "react";
import { CompletedControlProcedureCheckSaveModel } from "./CompletedControlProcedureCheck.types";
import { useMutation } from "react-query";
import { deleteHttp, put } from "../../../../common/api/apiShared";
import { CompletedControlProcedureViewModel, SystemControlProcedureType } from "../SystemControlProcedureDialog.types";
import { Result } from "../../../../common/api/result";
import { useValidator } from "../../../../common/hooks/validation";
import { useTranslation } from "../../../../localization/useTranslation";
import { ICompletedControlProcedureCheck } from "./CompletedControlProcedureCheck";

function useCompletedLegalEntityAuditHooks(props: ICompletedControlProcedureCheck) {
    const { translateString } = useTranslation();
    const [saveModel, setSaveModel] = useState<CompletedControlProcedureCheckSaveModel>({ completionDate: undefined, type: undefined, documentId: undefined });
    const [selectedCompletedAudit] = useState("");

    const validation = useValidator<Partial<CompletedControlProcedureCheckSaveModel>>((validators) => {
        return {
            type: validators.validateNotEmpty((item) => item.type, translateString("controlProcedure")),
            completionDate: validators.validateNotEmpty((item) => item.completionDate, translateString("dateForCompletion")),
        };
    }, []);

    function save() {
        return put<CompletedControlProcedureViewModel>(`/systemControlProcedure/${props.controlProducereId}/completedCheck`, saveModel);
    }

    const saveMutation = useMutation(save, {
        onSuccess: (data: Result<CompletedControlProcedureViewModel>) => {
            props.onCompletedCheckAdded(data.value());
            validation.setShowErrors(false);
            setSaveModel({ completionDate: undefined, type: undefined, documentId: undefined });
        },
    });

    const saveCompletedControl = () => {
        if (validation.anyHasErrors || saveMutation.isLoading) {
            validation.setShowErrors(true);
            return;
        }

        saveMutation.mutateAsync();
    };

    const removeMutation = useMutation(removeCompletedControl);

    const onRemoveCompletedControlClick = (completedAuditId: string) => {
        props.onCompletedCheckRemoved(completedAuditId);

        removeMutation.mutateAsync({ completedAuditId: completedAuditId });
    };

    function removeCompletedControl(model: { completedAuditId: string }) {
        return deleteHttp(`/systemControlProcedure/${props.controlProducereId}/completedCheck/${model.completedAuditId}`);
    }

    const onCompletionDateChange = (completionDate?: Date) => {
        const temp = { ...saveModel! };
        temp.completionDate = completionDate;
        setSaveModel(temp);
    };

    const onTypeChange = (form: SystemControlProcedureType) => {
        const temp = { ...saveModel! };
        temp.type = form;
        setSaveModel(temp);
    };

    const onDocumentChange = (documentId?: string) => {
        const temp = { ...saveModel! };
        temp.documentId = documentId;
        setSaveModel(temp);
    };

    return {
        saveModel,
        validation,
        saveCompletedControl,
        onCompletionDateChange,
        onTypeChange,
        onDocumentChange,
        onRemoveCompletedControlClick,
        selectedCompletedAudit,
        saving: saveMutation.isLoading,
    };
}

export default useCompletedLegalEntityAuditHooks;
