import { useParams } from "react-router-dom";
import { useMutation } from "react-query";
import { post } from "../../../common/api/apiShared.ts";
import {
    PublicInternalAssessmentInitiationContactModel,
    PublicInternalAssessmentInitiationContactSaveViewModel,
} from "../PublicInternalAssessmentTemplates.types.ts";
import { useValidator } from "../../../common/hooks/validation";
import { useState } from "react";
import { useTranslation } from "../../../localization/useTranslation.ts";
import { useNavigate } from "react-router";

export default function usePublicInternalAssessmentInitiationHooks() {
    const { externalId } = useParams<{ externalId: string }>();

    const { translateString } = useTranslation();
    const navigate = useNavigate();

    const [model, setModel] = useState<PublicInternalAssessmentInitiationContactSaveViewModel>({ firstName: "", lastName: "", email: "", remark: "" });

    const validation = useValidator<PublicInternalAssessmentInitiationContactSaveViewModel>((validators) => ({
        firstName: validators.validateNotEmpty((item) => item.firstName, translateString("firstName")),
        lastName: validators.validateNotEmpty((item) => item.lastName, translateString("lastName")),
        email: validators.validateNotEmpty((item) => item.email, translateString("emailAddress")),
    }));

    const updater = {
        onFirstNameChanged: (firstName: string) => {
            let temp = { ...model };
            temp.firstName = firstName;
            setModel(temp);
        },
        onLastNameChanged: (lastName: string) => {
            let temp = { ...model };
            temp.lastName = lastName;
            setModel(temp);
        },
        onEmailChanged: (email: string) => {
            let temp = { ...model };
            temp.email = email;
            setModel(temp);
        },
        onRemarkChanged: (remark: string) => {
            let temp = { ...model };
            temp.remark = remark;
            setModel(temp);
        },
    };

    const sendMutation = useMutation(sendApi);

    async function send() {
        if (validation.anyHasErrors) {
            validation.setShowErrors(true);
            return;
        }
        await sendMutation.mutateAsync(undefined, {
            onSuccess: () => {
                navigate("receipt");
            },
        });
    }

    function sendApi() {
        const saveModel: PublicInternalAssessmentInitiationContactModel = {
            contactName: `${model.firstName} ${model.lastName}`,
            contactEmail: model.email!,
            remark: model.remark,
        };

        return post<{}>(`/publicInternalAssessmentInitiation/${externalId}`, saveModel);
    }

    return { validation, updater, send, model, isSending: sendMutation.isLoading };
}
