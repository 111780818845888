import React from "react";
import PoliciesStep, { IPoliciesStep } from "./PoliciesStep";
import { useTranslation } from "../../localization/useTranslation";

export interface ProcessingActivityPolicyStepProps extends IPoliciesStep {
    processingActivityId: string;
    name?: string;
}

function ProcessingActivityPolicyStep(props: ProcessingActivityPolicyStepProps) {
    const { translateString } = useTranslation();

    return (
        <React.Fragment>
            <PoliciesStep
                {...props}
                stepHeader={translateString("policiesStepHeader")}
                stepheaderTooltip={translateString("policiesStepHeaderToolTip")}
                addPolicyDialogHeader={translateString("addPolicyToProcessingActivity")}
            />
        </React.Fragment>
    );
}

export default ProcessingActivityPolicyStep;
