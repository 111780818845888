import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { get, put } from "../../common/api/apiShared";
import { DotLegalSelectOption } from "../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { useTranslation } from "../../localization/useTranslation";
import { SystemTemplateTypesEnum } from "../systemEdit/SystemEdit.types";
import { IUpdateSystemTemplateInfoDialogProps } from "./UpdateSystemTemplateDialog";

export interface SystemTemplateInfoModel {
    mostPopular: boolean;
    systemTypes: Array<SystemTemplateTypesEnum>;
}

export function useUpdateSystemTemplateHook(props: IUpdateSystemTemplateInfoDialogProps) {
    const { translateString } = useTranslation();
    const [model, setModel] = useState({ mostPopular: false, systemTypes: [] as Array<string> });
    let url = `/SystemTemplate/${props.systemId}/templateInfo`;
    let { isLoading, data } = useQuery("templateInfo" + props.systemId, () => get<SystemTemplateInfoModel>(url));

    useEffect(() => {
        if (data) {
            setModel({
                mostPopular: data.mostPopular,
                systemTypes: data.systemTypes.map((x) => {
                    return x.toString();
                }),
            });
        }
    }, [props.systemId, data]);

    let systemTypesSelectOptions: Array<DotLegalSelectOption> = Object.entries(SystemTemplateTypesEnum)
        .filter((q) => isNaN(Number(q[0])))
        .map((q) => {
            return {
                name: translateString(q[0].toLowerCase()),
                id: q[1].toString(),
            };
        });

    const saveMutation = useMutation(save, {
        onSuccess: (response: any) => {
            props.closeDialog();
        },
    });

    const saveTemplateInfo = async () => {
        await saveMutation.mutateAsync();
    };

    function save() {
        return put<{}>(`/SystemTemplate/${props.systemId}/templateInfo`, {
            mostPopular: model?.mostPopular,
            systemTypes: model?.systemTypes.map((x) => {
                return Number(x);
            }),
        });
    }

    return {
        isLoading,
        systemTypesSelectOptions,
        saveTemplateInfo,
        inProgress: saveMutation.isLoading,
        model,
        setModel,
    };
}
