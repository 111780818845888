import { Box, Divider, Grid, GridSize, IconButton, MenuItem, Popover, Skeleton, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";
import DotLegalPaper from "../common/components/dotLegalPaper/DotLegalPaper";
import { editIcon } from "../common/icons";
import { useTranslation } from "../localization/useTranslation";
import { useUserHook } from "./User.hooks";
import { useUserStyles } from "./User.styles";
import CheckIcon from "@mui/icons-material/Check";
import AddEditUserDialog from "./addEditUserDialog/AddEditUserDialog";
import { DeleteUserDialog } from "./deleteUserDialog/DeleteUserDialog";
import { ChangeActiveStatusDialog } from "./changeActiveStatusDialog/ChangeActiveStatusDialog";
import { DotLegalCheckbox, DotLegalPageHeader, DotLegalTable, DotLegalHeading, DotLegalFixedHeightText } from "@dotlegal/dotlegal-ui-components";
import { DotLegalEmptyState } from "@dotlegal/dotlegal-ui-components";
import NoData from "../legalEntity/legalEntityTable/noData.svg?react";
import { useUserContext } from "../auth/userContextProvider/UserContextProvider";
import ResendInvitationDialog from "./resendInvitationDialog/ResendInvitationDialog";
import { getSettings } from "../common/settingsProvider";
import PageHeaderEditEntityButton, { PageHeaderEditEntityButtonMenuItem } from "../common/components/pageHeaderEditEntityButton/PageHeaderEditEntityButton.tsx";

function User() {
    const { translateString } = useTranslation();
    const userContext = useUserContext();
    const styles = useUserStyles();
    const {
        isLoading,
        data,
        getBreadCrumbs,
        showEditUserDialog,
        setShowEditUserDialog,
        handleEditUserSuccess,
        showDeleteUserDialog,
        setShowDeleteUserDialog,
        handeDeleteUserSuccess,
        handleChangeStatus,
        groupEntitiesHeaders,
        loadingEntities,
        userGroupEntities,
        getHeaderCheckBoxState,
        setSelected,
        selectAllEntities,
        setShowChangeActiveStatusDialog,
        showChangeActiveStatusDialog,
        showResendInvitationDialog,
        setShowResendInvitationDialog,
    } = useUserHook();

    const gridProps: { item: boolean; xs: GridSize; sm: GridSize; md: GridSize } = {
        item: true,
        xs: 12,
        sm: 6,
        md: 4,
    };

    const getIsActive = (isActive?: boolean) => {
        if (isActive) return <CheckIcon />;
        return "";
    };

    let hasGroupEntities = loadingEntities ? true : userGroupEntities!.length > 0;

    const getEditMenuItems = () => {
        let menuItems: Array<PageHeaderEditEntityButtonMenuItem> = [
            {
                title: translateString("editUser"),
                onClick: () => {
                    setShowEditUserDialog(true);
                },
            },
            {
                title: data?.isActive ? translateString("deactivateUser") : translateString("activateUser"),
                onClick: () => {
                    setShowChangeActiveStatusDialog(true);
                },
            },
            {
                title: translateString("deleteUser"),
                onClick: () => {
                    setShowDeleteUserDialog(true);
                },
            },
            {
                title: translateString("resendActivationEmail"),
                onClick: () => {
                    setShowResendInvitationDialog(true);
                },
            },
        ];

        return menuItems;
    };

    return (
        <Box sx={styles.container}>
            <DotLegalPageHeader
                userContext={{
                    customerName: userContext.customerName,
                }}
                breadCrumbs={getBreadCrumbs()}
                rightComponent={!isLoading ? <PageHeaderEditEntityButton menuItems={getEditMenuItems()} /> : <Skeleton variant="text" width={"5%"} />}
            />

            <DotLegalPaper background="purpleWave1" backgroundCover>
                <Grid sx={styles.gridRow} container spacing={3}>
                    <Grid {...gridProps} lg>
                        <DotLegalHeading name={translateString("name")}>
                            <DotLegalFixedHeightText isLoading={isLoading} content={data?.firstName + " " + data?.lastName} />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...gridProps} lg>
                        <DotLegalHeading name={translateString("emailAddress")}>
                            <DotLegalFixedHeightText isLoading={isLoading} content={data?.emailAddress} />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...gridProps} lg>
                        <DotLegalHeading name={translateString("role")}>
                            <DotLegalFixedHeightText isLoading={isLoading} content={data?.userRoles.map((x) => x.name).join(", ")} />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...gridProps} lg>
                        <DotLegalHeading name={translateString("businessAreas")}>
                            <DotLegalFixedHeightText isLoading={isLoading} content={data?.businessAreas.map((x) => x.name).join(", ")} />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...gridProps} lg>
                        <DotLegalHeading name={translateString("fullGroupAccess")}>
                            <DotLegalFixedHeightText isLoading={isLoading} content={getIsActive(data?.gdpo)} />{" "}
                        </DotLegalHeading>
                    </Grid>
                </Grid>
                <Divider sx={styles.divider} />
                <Grid sx={styles.gridRow} container spacing={3}>
                    <Grid {...gridProps} lg>
                        <DotLegalHeading name={translateString("active")}>
                            <DotLegalFixedHeightText isLoading={isLoading} content={getIsActive(data?.isActive)} />{" "}
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...gridProps} lg>
                        <DotLegalHeading name={translateString("lastLogin")}>
                            <DotLegalFixedHeightText isLoading={isLoading} content={data?.lastLogin} />{" "}
                        </DotLegalHeading>
                    </Grid>
                    {userContext.permissions.canChangeUserLoginProvider ? (
                        <Grid {...gridProps} lg>
                            <DotLegalHeading name={translateString("idpUser")}>
                                <DotLegalFixedHeightText
                                    onClick={() => (window.location.href = `${getSettings().backofficeUrl}/user-view/${data?.integrationIdentifier}/`)}
                                    isLoading={isLoading}
                                    content={translateString("editIdpUser")}
                                />
                            </DotLegalHeading>
                        </Grid>
                    ) : (
                        <Grid {...gridProps} lg />
                    )}
                    {/* Mocking grids, so placement fits top */}
                    <Grid {...gridProps} lg />
                    <Grid {...gridProps} lg />
                </Grid>
            </DotLegalPaper>
            <Typography sx={styles.tableHeader}>{translateString("groupCompanies")}</Typography>
            <DotLegalPaper>
                {hasGroupEntities ? (
                    <DotLegalTable
                        headers={groupEntitiesHeaders()}
                        isLoading={loadingEntities}
                        defaultOrderBy={"name"}
                        defaultOrder="asc"
                        data={userGroupEntities}
                        onSelectAllChanged={selectAllEntities}
                        headerCheckBoxState={getHeaderCheckBoxState()}
                        paginationLabelOf={translateString("labelOf")}
                        disableCheckAll={data?.gdpo}
                        renderRow={(row) => (
                            <TableRow key={row.legalEntityId} onClick={data?.gdpo ? undefined : () => setSelected(row.legalEntityId)}>
                                <TableCell>
                                    <DotLegalCheckbox checked={row.isSelected} disabled={data?.gdpo} />
                                </TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.address}</TableCell>
                                <TableCell>{row.zipCode}</TableCell>
                                <TableCell>{row.city}</TableCell>
                                <TableCell>{row.country}</TableCell>
                            </TableRow>
                        )}
                    />
                ) : (
                    <DotLegalEmptyState icon={<NoData />} text={translateString("noGroupEntities")} />
                )}
            </DotLegalPaper>
            {showEditUserDialog && (
                <AddEditUserDialog isEdit onCloseDialog={() => setShowEditUserDialog(false)} onSaveCorrect={handleEditUserSuccess} userId={data!.id} />
            )}
            {showDeleteUserDialog && (
                <DeleteUserDialog
                    userId={data!.id}
                    onCloseDialog={() => setShowDeleteUserDialog(false)}
                    onSaveCorrect={() => handeDeleteUserSuccess(data!.firstName + " " + data!.lastName)}
                    userName={data!.firstName + " " + data!.lastName}
                />
            )}
            {showChangeActiveStatusDialog && (
                <ChangeActiveStatusDialog
                    userId={data!.id}
                    onCloseDialog={() => setShowChangeActiveStatusDialog(false)}
                    onSaveCorrect={() => handleChangeStatus()}
                    userName={data!.firstName + " " + data!.lastName}
                    activeStatus={data!.isActive}
                />
            )}

            {showResendInvitationDialog && <ResendInvitationDialog userId={data!.id} onCloseDialog={() => setShowResendInvitationDialog(false)} />}
        </Box>
    );
}

export default User;
