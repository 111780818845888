import { Box } from "@mui/material";
import { DotLegalTrialExpiredRow, DotLegalTrialExpiredRowProps, IDotLegalMultiSelect } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation.ts";
import { Trans } from "react-i18next";
import { useUrlProvider } from "../../useUrlProvider.ts";

export interface TrialExpiredRowWrapperProps extends Omit<DotLegalTrialExpiredRowProps, "planDialogText" | "purchaseUrl"> {}

function TrialExpiredRowWrapper(props: TrialExpiredRowWrapperProps) {
    const { translateString } = useTranslation();
    const { getPurchasePlansUrl } = useUrlProvider();

    return (
        <DotLegalTrialExpiredRow
            {...props}
            purchaseUrl={getPurchasePlansUrl()}
            planDialogText={{
                upgradeNow: translateString("upgradeNow"),
                content: translateString("upgradePlanDialogContent"),
                planDialogText: {
                    contactDotLegalText: (
                        <Trans
                            i18nKey={"contactDotLegal"}
                            components={{
                                phone: (
                                    <Box sx={{ color: "#767676" }} component={"a"} target="_blank" href="tel:+4570270127">
                                        +45 7027 0127
                                    </Box>
                                ),
                                mail: (
                                    <Box sx={{ color: "#767676" }} component={"a"} href="mailto:support@dotlegal.com">
                                        support@dotlegal.com
                                    </Box>
                                ),
                            }}
                        />
                    ),
                },
            }}
        />
    );
}

export default TrialExpiredRowWrapper;
