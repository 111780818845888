import { createElement } from "react";
import { Trans } from "react-i18next";
import { useMutation } from "react-query";
import { put } from "../../../common/api/apiShared";
import { Result } from "../../../common/api/result";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { DataCategorySaveModel } from "../dataCategories/DataCategories.types";
import { IChangeDataCategoryStatusDialog } from "./ChangeDataCategoryStatusDialog";

export function useChangeDataCategoryDialogHooks(props: IChangeDataCategoryStatusDialog) {
    const snackbar = useDotLegalSnackbar();

    const changeActiveStatusMutation = useMutation(changeStatus);

    const changeActiveStatus = (dataCategory: DataCategorySaveModel) => {
        changeActiveStatusMutation.mutateAsync(dataCategory, {
            onSuccess: (response: Result<string>) => {
                props.onCloseDialog();

                if (response.success()) {
                    snackbar.show(createElement(Trans, { i18nKey: "activeStatusChanged", values: { name: dataCategory.name } }));
                    props.refetch();
                } else {
                    snackbar.show(createElement(Trans, { i18nKey: "dataCategoryStatusNotChanged", values: { name: dataCategory.name } }), "warning");
                }
            },
        });
    };

    function changeStatus(dataCategory: DataCategorySaveModel) {
        if (dataCategory.isCustom) return put<string>(`/DataCategories/${dataCategory.id}/changeActiveStatus`, !dataCategory.active);
        else return put<string>(`/DataCategories/${dataCategory.id}/changeActiveMasterDataStatus`, !dataCategory.active);
    }

    return {
        changeActiveStatus,
        inProgress: changeActiveStatusMutation.isLoading,
    };
}
