import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useMutation } from "react-query";
import { createElement, useState } from "react";
import { Trans } from "react-i18next";
import { IDeleteInternalAssessmentDialog } from "./DeleteInternalAssessmentDialog";
import { deleteHttp } from "../../common/api/apiShared";

export default function useDeleteInternalAssessmentDialogHooks(props: IDeleteInternalAssessmentDialog) {
    const snackbar = useDotLegalSnackbar();

    const [confirmDelete, setConfirmDelete] = useState(false);

    const removeMutation = useMutation(removeAPI);

    const removeAssessmentClick = () => {
        removeMutation.mutateAsync(undefined, {
            onSuccess: (response: any) => {
                props.onDialogClose();
                if (response.success()) {
                    props.onSuccessDelete();
                    snackbar.show(createElement(Trans, { i18nKey: "internalAssessmentDeleted", values: { name: props.selectedAssessment.name } }));
                } else {
                    snackbar.show(
                        createElement(Trans, { i18nKey: "internalAssessmentNotDeleted", values: { name: props.selectedAssessment.name } }),
                        "warning"
                    );
                }
            },
        });
    };

    return {
        removeAssessmentClick,
        inProgress: removeMutation.isLoading,
        confirmDelete,
        setConfirmDelete,
    };

    async function removeAPI() {
        return await deleteHttp(`/internalAssessments/${props.selectedAssessment.id}`);
    }
}
