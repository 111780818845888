import React from "react";
import { useTranslation } from "../../localization/useTranslation.ts";
import PlanDialog from "../planDialog/PlanDialog.tsx";
import { DotLegalButton, DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import PlanDialogUpgradeButton from "../planDialogComponents/PlanDialogUpgradeButton.tsx";
import { TrialInformationViewModel } from "../../trial/Trial.types.ts";
import { useTrialHook } from "../../trial/Trial.hooks.ts";
import { Box } from "@mui/material";
import Expired from "./expired.svg?react";
import PlanStatusMessage from "../planStatusMessage/PlanStatusMessage.tsx";

export interface ITrialExpiredDialog {
    data: TrialInformationViewModel | undefined;
}

function TrialExpiredDialog(props: ITrialExpiredDialog) {
    const { translateString } = useTranslation();
    const { showExpiredDialog, closeExpiredDialog, dontAskAgainExpiredDialog } = useTrialHook(props.data);
    function getContent() {
        return (
            <React.Fragment>
                {props.data?.plansOnTrials.filter((x) => x.isExpired).map((x) => <PlanStatusMessage plan={x} key={x.planType} />)}

                <DotLegalHeader marginTop={3} marginBottom={3} fontWeight={500} center headerStyle="small">
                    {translateString("trialSoonToExpireContent")}
                </DotLegalHeader>
            </React.Fragment>
        );
    }

    function getButtons() {
        return (
            <React.Fragment>
                <PlanDialogUpgradeButton />
                <DotLegalButton onClick={dontAskAgainExpiredDialog} btnLength={300} buttonType="secondary">
                    {translateString("doNotShowAgain")}
                </DotLegalButton>
            </React.Fragment>
        );
    }

    return (
        <PlanDialog
            showDialog={showExpiredDialog}
            onDialogClose={closeExpiredDialog}
            content={getContent()}
            header={
                <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                    <Box component={"span"}>{translateString("trialHasExpiredHeader")}</Box>
                    <Expired />
                </Box>
            }
            buttons={getButtons()}
        ></PlanDialog>
    );
}

export default TrialExpiredDialog;
