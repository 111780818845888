import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { get, put } from "../../common/api/apiShared";
import { ajaxQueue } from "../../common/api/ajaxQueue";
import { LegalEntityAuditGroupDraft, LegalEntityAuditGroupDraftOverviewModel } from "./LegalEntityAuditGroupOverview.types";

export default function useLegalEntityAuditGroupDraftOverviewHooks() {
    const queryClient = useQueryClient();

    const { id } = useParams<{ id: string }>();
    const queryKey = `/legalEntityAuditGroups/${id}`;

    let { isLoading, data, refetch } = useQuery(queryKey, () => get<LegalEntityAuditGroupDraftOverviewModel>(queryKey));

    if (data) {
        data.selectedEntities = data.selectedEntities.map((x) => new LegalEntityAuditGroupDraft(x.legalEntityId, x.contactId, x.systemIds));
    }

    function onAuditGroupUpdated() {
        refetch();
    }

    const updateSelectedEntitiesMutation = useMutation(updateSelectedEntitiesAPI, {
        onSuccess: (data) => {
            const system = data.value();
            queryClient.setQueryData(queryKey, system);
        },
    });

    const updateSelectedLegalEntities = async (selectedEntities: Array<LegalEntityAuditGroupDraft>) => {
        const temp = { ...data };
        temp.selectedEntities = selectedEntities;

        queryClient.setQueryData(queryKey, temp);

        await ajaxQueue().addRequest(updateSelectedEntitiesMutation.mutateAsync, {
            selectedEntities: selectedEntities,
        });
    };

    return {
        id,
        data,
        isLoading,
        onAuditGroupUpdated,
        updateSelectedLegalEntities,
        refetch,
    };

    async function updateSelectedEntitiesAPI(data: { selectedEntities: Array<LegalEntityAuditGroupDraft> }) {
        return await put("/legalEntityAuditGroups/" + id + "/drafts/", data.selectedEntities);
    }
}
