import React from "react";
import StepSegment from "../../../../processingActivity/stepSegment/StepSegment";
import { DotLegalMultiSelect } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../../localization/useTranslation";
import useLegalEntityAreaAndCertificationsStepHook from "./LegalEntityAreaAndCertificationsStep.hooks";
import { usePlanContext } from "../../../../auth/planProvider/PlanProvider";
import PlanInputFieldWrapper from "../../../../plan/planInputFieldWrapper/PlanInputFieldWrapper";
import { PurpleLockIcon } from "../../../../common/icons";

export interface ILegalEntityAreaAndCertificationsStep {
    onCertificationChange: (certifications: Array<string>) => void;
    onComplianceAreaChange: (complianceAreas: Array<string>) => void;
    certificationIds: Array<string>;
    complianceAreaIds: Array<string>;
}

function LegalEntityAreaAndCertificationsStep(props: ILegalEntityAreaAndCertificationsStep) {
    const { translateString } = useTranslation();
    const { informationSecurityFeatures, vendorManagementFeatures, hasVendorManagementAccess } = usePlanContext();
    const { certifications, certificationsIsLoading, complianceAreas, complianceAreasIsLoading } = useLegalEntityAreaAndCertificationsStepHook();

    const hasCertificationAccess = informationSecurityFeatures.certifications || vendorManagementFeatures.certifications;

    return (
        <React.Fragment>
            <StepSegment size="small">
                {hasVendorManagementAccess && (
                    <DotLegalMultiSelect
                        options={complianceAreas}
                        chosenOptions={props.complianceAreaIds}
                        onChange={props.onComplianceAreaChange}
                        label={translateString("areas")}
                        noOptionsLabel={translateString("noOptions")}
                        isLoading={complianceAreasIsLoading}
                    ></DotLegalMultiSelect>
                )}

                <PlanInputFieldWrapper hasAccess={hasCertificationAccess} hoverText={translateString("upgradeInfoSecOrVendorManagementPlan")}>
                    <DotLegalMultiSelect
                        options={certifications}
                        chosenOptions={props.certificationIds}
                        onChange={props.onCertificationChange}
                        label={translateString("certifications")}
                        noOptionsLabel={translateString("noOptions")}
                        isLoading={certificationsIsLoading}
                        disabled={!hasCertificationAccess}
                        icon={!hasCertificationAccess ? PurpleLockIcon : undefined}
                    ></DotLegalMultiSelect>
                </PlanInputFieldWrapper>
            </StepSegment>
        </React.Fragment>
    );
}

export default LegalEntityAreaAndCertificationsStep;
