import { useTheme } from "@mui/material";
import { createSxStyles } from "../common/createSxStyles";

export const useMasterDataStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        tableRow: {
            "& svg": {
                height: 14,
            },
        },
        bold: {
            fontWeight: theme.typography.fontWeightBold,
        },
        iconContainer: {
            display: "flex",
        },
        greenArrowContainer: {
            justifyContent: "flex-end",
            marginRight: 20 + "px",
        },
    });
};
