import React from "react";
import DotLegalDeleteDialogWrapper from "../../../common/components/dotLegalDeleteDialog/DotLegalDeleteDialogWrapper";
import { useSystemDeleteHook } from "./SystemDeleteDialog.hooks";

export interface ISystemDeleteDialog {
    systemId: string;
    systemName: string;
    onCloseDialog: () => void;
    onSuccessDelete: () => void;
}

function SystemDeleteDialog(props: ISystemDeleteDialog) {
    const { removeSystemClick, inProgress } = useSystemDeleteHook(props);

    return (
        <DotLegalDeleteDialogWrapper
            open
            itemForDeletion={props.systemName}
            onDialogClose={props.onCloseDialog}
            onOkClick={removeSystemClick}
            inProgress={inProgress}
        />
    );
}

export default SystemDeleteDialog;
