import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { get } from "../../../common/api/apiShared";
import { useStateLocalStorage } from "@dotlegal/dotlegal-ui-components";
import { BusinessAreaSaveModel } from "../businessAreaDialog/BusinessAreaDialog.types";
import { BusinessAreaViewModel } from "./BusinessAreas.types";

export function useBusinessAreasDataMapping() {
    const queryClient = useQueryClient();
    const [showAllBusinessAreas, setShowAllBusinessAreas] = useStateLocalStorage("onlyActivePurposes", false);
    const [showBusinessAreaDialog, setShowBusinessAreaDialog] = useState(false);
    const [showDeleteBusinessAreaDialog, setShowDeleteBusinessAreaDialog] = useState(false);
    const [selectedBusinessArea, setSelectedBusinessArea] = useState<BusinessAreaSaveModel | undefined>(undefined);
    const [showChangeStatusDialog, setShowChangeStausDialog] = useState(false);

    const queryPart = `?showAllPurposes=${showAllBusinessAreas}`;
    const url = `/businessareas${queryPart}`;
    let { isLoading, data } = useQuery(url, () => get<Array<BusinessAreaViewModel>>(url));

    const OnSave = () => {
        queryClient.refetchQueries(url);
    };

    return {
        isLoading,
        data,
        showAllBusinessAreas,
        setShowAllBusinessAreas,
        showBusinessAreaDialog,
        setShowBusinessAreaDialog,
        showDeleteBusinessAreaDialog,
        setShowDeleteBusinessAreaDialog,
        OnSave,
        selectedBusinessArea,
        setSelectedBusinessArea,
        showChangeStatusDialog,
        setShowChangeStausDialog,
    };
}
