import { DotLegalDialog, DotLegalHeader, DotLegalSelect } from "@dotlegal/dotlegal-ui-components";

import { Trans } from "react-i18next";
import React from "react";
import { Box } from "@mui/material";
import { InternalAssessmentApiRow } from "../InternalAssessments.types";
import useCloseInternalAssessmentDialogHooks from "./CloseInternalAssessmentDialog.hooks";
import { useTranslation } from "../../localization/useTranslation";
import { getEnumValues } from "../../common/enumOperations";
import { LegalEntityAuditClosedReason } from "../../legalEntity/legalEntityAuditTab/closeAuditDialog/CloseAuditDialog.types";
import { setFirstLetterToLowerCase } from "../../common/stringOperations";

export interface ICloseInternalAssessmentDialog {
    onDialogClose: () => void;
    onInternalAssessmentClosed: () => void;
    selectedInternalAssessment: InternalAssessmentApiRow;
}

function CloseInternalAssessmentDialog(props: ICloseInternalAssessmentDialog) {
    const { translateString, translateDate } = useTranslation();
    const { saveModel, onStatusChange, validation, onCloseClick, isSaving } = useCloseInternalAssessmentDialogHooks(props);

    validation.check(saveModel);

    return (
        <DotLegalDialog
            buttonOkText={translateString("close")}
            header={translateString("closeInternalAssessment")}
            size="sm"
            open
            onDialogClose={props.onDialogClose}
            onOkClick={() => onCloseClick()}
            inProgress={isSaving}
            disableBackdropOnCloseIfValueChange={saveModel}
        >
            <React.Fragment>
                <DotLegalHeader fontWeight={400} center headerStyle="small">
                    <Trans
                        i18nKey={"closeInternalAssessmentDialogContent"}
                        values={{
                            name: props.selectedInternalAssessment.name.trim(),
                            deadline: translateDate(props.selectedInternalAssessment.deadline),
                        }}
                        components={{
                            wrapper: (
                                <Box
                                    sx={(theme) => ({ color: theme.palette.secondary.main, fontWeight: theme.typography.fontWeightBold })}
                                    component="span"
                                ></Box>
                            ),
                        }}
                    />
                </DotLegalHeader>
                <DotLegalSelect
                    options={getEnumValues(LegalEntityAuditClosedReason).map((x) => {
                        return { name: translateString(setFirstLetterToLowerCase(LegalEntityAuditClosedReason[x].toString())), id: x.toString() };
                    })}
                    onChange={(value) => onStatusChange(Number(value))}
                    label={translateString("reason")}
                    noOptionsLabel=""
                    placeholder={translateString("reason")}
                    selectedItem={saveModel.status ? saveModel.status.toString() : ""}
                    disableClearable
                    disableSearch
                    errorText={validation.getErrorsForDisplay("status")}
                />
            </React.Fragment>
        </DotLegalDialog>
    );
}

export default CloseInternalAssessmentDialog;
