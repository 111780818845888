import { createSxStyles } from "../../common/createSxStyles";
import { useTheme } from "@mui/material";

export const useSubTasksStyles = (hasTasks: boolean) => {
    const theme = useTheme();
    return createSxStyles({
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            marginBottom: 2,
        },
        listItem: {
            minWidth: "auto",
            "& .MuiButtonBase-root": {
                padding: 0,
            },
            display: "flex",
            paddingLeft: 0,
            paddingRight: 0,
        },
        remove: {
            fill: theme.palette.error.main,
        },
        addNew: {
            marginTop: hasTasks ? theme.spacing(1) : theme.spacing(0),
            color: theme.palette.primary.main,
            fontSize: theme.typography.pxToRem(12),
            "&:hover": {
                textDecoration: "underline",
                cursor: "pointer",
            },
        },
        subTaskDescription: {
            flex: 1,
            marginLeft: theme.spacing(1),
        },
        hidden: {
            visibility: "hidden",
        },
        icon: {
            marginLeft: 2 + "px",
            marginRight: 2 + "px",
            width: 24,
            height: 24,
        },
        videoIcon: {
            marginLeft: theme.spacing(1),
        },
        markDown: {
            fontSize: 14,
            color: theme.palette.primary.dark,
            borderBottom: "1px solid grey",
            "& p": {
                marginBottom: 7 + "px",
                marginTop: 7 + "px",
            },
            "& p a": {
                textDecoration: "none",
                fontWeight: theme.typography.fontWeightBold,
                color: theme.palette.secondary.main,
                "&:hover": {
                    textDecoration: "underline",
                },
            },
        },
        taskCompleted: {
            textDecoration: "line-through",
            color: theme.palette.primary.dark,
        },
    });
};
