import { SecurityMeasureModel } from "../../common/components/dotLegalSecurityMeasure/DotLegalSecurityMeasure.types";
import { SystemStorageType, SystemEntityType, SystemDataType } from "./systemDataAndStorageStep/SystemDataAndStorageStep.types";
import { SystemPersonalDataViewModel } from "./systemPersonalDataStep/SystemPersonalDataStep.types";

export enum SystemType {
    GlobalSystem = 0,
    LocalSystem = 1,
    PhysicalArchive = 2,
}

export enum SystemStatus {
    Active = 1,
    UnderImplementation = 2,
    PhasedOut = 3,
}

export enum OperationCriticalityLevelEnum {
    VeryLow = 1,
    Low = 2,
    Moderate = 3,
    High = 4,
    VeryHigh = 5,
}

export class SystemModel {
    id: string;
    name: string | undefined;
    description: string | undefined;
    businessAreas: Array<string> = [];
    type: SystemType;
    dataStorageLocations: Array<string> = [];
    supportAccessLocations: Array<string> = [];
    deletionPeriodId: string | null;
    securityMeasures: Array<SecurityMeasureModel> = [];
    responsibleId: string | null = "";
    deletionPeriodReason: string | undefined;
    storageType?: SystemStorageType;
    entityType?: SystemEntityType;
    dataTypes: Array<SystemDataType> = [];
    policies: Array<string> = [];
    status: SystemStatus;
    certifications: Array<string> = [];
    isCriticalForOperation: boolean = false;
    network: string | undefined;
    disableSystemRelations: boolean = false;
    suppliers: Array<SystemSupplierViewModel> = [];
    systemPersonalData: SystemPersonalDataViewModel | undefined;
    usesAI: boolean = false;
    companyUsesAIService: boolean = false;
    aiUsageDescription: string | undefined;
    isUseOfAIConsideredHighRisk: boolean = false;
    isDataUsedForTraningOfAIModel: boolean = false;
    operationCriticalityLevel?: OperationCriticalityLevelEnum;

    constructor() {
        this.id = "";
        this.deletionPeriodId = "";
        this.type = SystemType.GlobalSystem;
        this.status = SystemStatus.Active;
    }
}

export interface SystemSupplierViewModel {
    id: string;
    legalEntityId: string | undefined;
    generateSharings: boolean;
    hasDataProcessorAgreement: boolean;
    containsNotAccessibleDocuments: boolean;
    hasTransferImpactAssessment: boolean;
    isSupplierADataProcessor: boolean;
    transferBasisId: string | undefined;
    transferImpactAssessmentId: string | undefined;
    containsNotAccessibleTia: boolean;
    dataProcessorAgreementIds: Array<string>;
    description: string;
    service: string;
    secondarySuppliers: Array<SystemSecondarySupplierViewModel>;
}

export interface SystemSecondarySupplierViewModel {
    id: string;
    dataProcessorId: string | undefined;
    dataStorageLocations: Array<string>;
    hasDataProcessorAgreement: boolean;
    hasTransferImpactAssessment: boolean;
    supportAccessLocations: Array<string>;
    transferBasisId: string | undefined;
    transferImpactAssessmentId: string | undefined;
    containsNotAccessibleTia: boolean;
    isSubDataProcessor: boolean;
    isLink: boolean;
    link: string | undefined;
}

export interface SystemUpdateModel {
    id: string;
    name: string | undefined;
    description: string | undefined;
    businessAreas: Array<string>;
    type: SystemType;
    dataStorageLocations: Array<string>;
    supportAccessLocations: Array<string>;
    deletionPeriodId: string | null;
    securityMeasures: Array<SecurityMeasureModel>;
    responsibleId: string | null;
    deletionPeriodReason: string | undefined;
    storageType?: SystemStorageType;
    entityType?: SystemEntityType;
    dataTypes: Array<SystemDataType>;
    policies: Array<string>;
    status: SystemStatus;
    certificationIds: Array<string>;
    isCriticalForOperation: boolean;
    network: string | undefined;
    disableSystemRelations: boolean;
    usesAI: boolean;
    companyUsesAIService: boolean;
    aIUsageDescription: string | undefined;
    isUseOfAIConsideredHighRisk: boolean;
    isDataUsedForTraningOfAIModel: boolean;
    operationCriticalityLevel?: OperationCriticalityLevelEnum;
}

export class SystemSubDataProcessorViewModel {
    id: string | undefined;
    dataProcessorId: string | undefined;
    hasDataProcessingAgreement: boolean = false;
    transferBasisId: string | undefined;
    hasTransferImpactAssessment: boolean = false;
    transferImpactAssessmentId: string | undefined;
    dataStorageLocations: Array<string> = [];
    supportAccessLocations: Array<string> = [];
}

export enum SystemTemplateTypesEnum {
    DailyOperation = 1,
    Newsletter = 2,
    PayrollAdministration = 3,
    RecruitmentSolution = 4,
    CRMSystem = 5,
    ERPSystem = 6,
    CloudServices = 7,
    ItSupportAndHosting = 8,
    WebsiteWebshopEtc = 9,
    MarketingServices = 10,
    Whistleblower = 11,
    IPTelephony = 12,
    Surveillance = 13,
    DataStorage = 14,
}
