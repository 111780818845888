import React, { Component } from "react";
import { Box, CircularProgress, IconButton, MenuItem, Popover } from "@mui/material";
import { editIcon } from "../../icons.tsx";
import { usePageHeaderEditEntityButton } from "./PageHeaderEditEntityButton.hooks.ts";
import PlanMenuItemWrapper from "../../../plan/planMenuItemWrapper/PlanMenuItemWrapper.tsx";
import { isNullOrWhitespace } from "../../stringOperations.ts";
import DotLegalLink from "../dotLegalLink/DotLegalLink.tsx";

export interface PageHeaderEditEntityButtonMenuItem {
    title: string;
    onClick: () => void;
    planMenuItemInfo?: {
        tooltip: string;
        menuName: string;
        hasAccess: boolean;
    };
    link?: string;
}

export interface PageHeaderEditEntityButtonProps {
    menuItems: Array<PageHeaderEditEntityButtonMenuItem>;
    showSpinner?: boolean;
}

function PageHeaderEditEntityButton(props: PageHeaderEditEntityButtonProps) {
    const { anchorEl, setAnchorEl, open, popoverId } = usePageHeaderEditEntityButton();

    if (props.menuItems.length === 0) {
        return undefined;
    }

    const getMenuItem = (menuItem: PageHeaderEditEntityButtonMenuItem, index?: number) => {
        let shouldRenderAsLink = !isNullOrWhitespace(menuItem.link);
        let menuItemElement = (
            <MenuItem
                key={shouldRenderAsLink ? undefined : index}
                sx={(theme) => ({ color: theme.palette.primary.dark, fontSize: "0.875rem", fontWeight: theme.typography.fontWeightMedium })}
                onClick={() => {
                    setAnchorEl(null);
                    menuItem.onClick();
                }}
            >
                {menuItem.title}
            </MenuItem>
        );

        if (!isNullOrWhitespace(menuItem.link)) {
            return (
                <DotLegalLink key={index} to={menuItem.link!} noUnderlineOnHover>
                    {menuItemElement}
                </DotLegalLink>
            );
        }

        return menuItemElement;
    };

    return (
        <Box
            sx={(theme) => ({
                "& svg": {
                    height: 25 + "px",
                    stroke: theme.palette.primary.main,
                    fill: theme.palette.primary.light,
                },
            })}
        >
            <React.Fragment>
                <IconButton
                    color={"primary"}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
                    aria-controls={open ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                >
                    {props.showSpinner ? (
                        <Box
                            sx={(theme) => ({
                                "& svg": {
                                    height: 25,
                                    stroke: theme.palette.primary.main,
                                    fill: theme.palette.primary.light,
                                },
                            })}
                        >
                            <CircularProgress size={24} />{" "}
                        </Box>
                    ) : (
                        editIcon
                    )}
                </IconButton>
                <Popover
                    id={popoverId}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{ vertical: "center", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                >
                    {props.menuItems.map((menuItem, index) => {
                        if (menuItem.planMenuItemInfo) {
                            const planInfo = menuItem.planMenuItemInfo;
                            return (
                                <PlanMenuItemWrapper
                                    key={index}
                                    tooltip={planInfo.tooltip}
                                    menuName={planInfo.menuName}
                                    menuItem={getMenuItem(menuItem)}
                                    hasAccess={planInfo.hasAccess}
                                />
                            );
                        } else {
                            return getMenuItem(menuItem, index);
                        }
                    })}
                </Popover>
            </React.Fragment>
        </Box>
    );
}

export default PageHeaderEditEntityButton;
