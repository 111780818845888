import { useQuery } from "react-query";

import { get } from "../../../common/api/apiShared";
import { RiskVersionViewModel } from "./RiskAssessmentVersions.types";
import { useState } from "react";

export default function useRiskAssessmentVersionsDataMapping() {
    const [showDialog, setShowDialog] = useState(false);
    const [selectedVersion, setSelectedVersion] = useState<RiskVersionViewModel | undefined>(undefined);
    const [showChangeLockedStatusDialog, setShowChangeLockedStatusDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const url = "/riskassesmentversions";
    let { isLoading, data, refetch } = useQuery(url, () => get<Array<RiskVersionViewModel>>(url));

    const onDialogClose = () => {
        setShowDialog(false);
        setSelectedVersion(undefined);
    };

    const onSave = () => {
        refetch();
    };

    const onDelete = () => {
        refetch();
        setShowDeleteDialog(false);
        setSelectedVersion(undefined);
    };

    return {
        isLoading,
        data,
        showDialog,
        setShowDialog,
        selectedVersion,
        setSelectedVersion,
        onDialogClose,
        showChangeLockedStatusDialog,
        setShowChangeLockedStatusDialog,
        onSave,
        showDeleteDialog,
        setShowDeleteDialog,
        onDelete,
    };
}
