export class ToggleCompanyLink {
    showGroupCompanyLink: boolean;
    showCompanyLink: boolean;
    companyId: string;

    constructor(companyId: string, showGroupCompanyLink: boolean, showCompanyLink: boolean) {
        this.companyId = companyId;
        this.showCompanyLink = showCompanyLink;
        this.showGroupCompanyLink = showGroupCompanyLink;
    }

    showLink() {
        return this.showCompanyLink || this.showGroupCompanyLink;
    }
}
