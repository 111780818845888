import { DotLegalEmptyState, DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import Widget from "../../Widget";
import { useTranslation } from "../../../localization/useTranslation";
import { Box } from "@mui/material";
import WhiteDashboardBox from "../../WhiteDashboardBox";
import LatestAuditsTable from "../../latestAuditsSubmittedWidget/latestAuditsTable/LatestAuditsTable";
import LatestAssessmentsTable from "../../latestAuditsSubmittedWidget/latestAssessmentsTable/LatestAssessmentsTable";
import { Trans } from "react-i18next";
import DotLegalLink from "../../../common/components/dotLegalLink/DotLegalLink";
import LatestAuditsEmptyStateIcon from "../../latestAuditsSubmittedWidget/latest-audits-empty-state.svg?react";
import { LatestAuditsSubmittedWidgetProps } from "../../latestAuditsSubmittedWidget/LatestAuditsSubmittedWidget";
import { useLatestAuditsSubmittedWidget } from "../../latestAuditsSubmittedWidget/LatestAuditsSubmittedWidget.hooks";

export interface ILatestAuditsSubmittedWidgetReportProps extends LatestAuditsSubmittedWidgetProps {
    isAudits: boolean;
}

function LatestAuditsSubmittedWidgetReport(props: ILatestAuditsSubmittedWidgetReportProps) {
    const { translateString } = useTranslation();
    const { hasVendorManagementAccess, isAuditsEnabled, isInternalAssessmentsEnabled, latestAssessments, latestAudits } = useLatestAuditsSubmittedWidget(props);

    return (
        <Widget disabled={hasVendorManagementAccess && !isAuditsEnabled && !isInternalAssessmentsEnabled}>
            <DotLegalHeader headerStyle={"medium"} marginBottom={0}>
                {(props.isAudits ? translateString("latestAuditSubmissions") : translateString("latestAssessmentSubmissions")).toUpperCase()}
            </DotLegalHeader>
            <DotLegalHeader headerStyle={"small"} marginBottom={0}>
                {props.isAudits ? translateString("external") : translateString("internal")}
            </DotLegalHeader>

            <Box sx={{ height: 300 }}>
                <WhiteDashboardBox marginTop={2} height={280}>
                    {hasVendorManagementAccess ? (
                        props.isAudits ? (
                            <LatestAuditsTable data={latestAudits} isEnabled={isAuditsEnabled} />
                        ) : (
                            <LatestAssessmentsTable data={latestAssessments} isEnabled={isInternalAssessmentsEnabled} />
                        )
                    ) : (
                        <DotLegalEmptyState
                            textColor={"darkBlue"}
                            noFixedHeight
                            noPadding
                            icon={<LatestAuditsEmptyStateIcon />}
                            text={
                                <Trans
                                    i18nKey={"upgradePrivacyForAccessToVendorManagement"}
                                    components={{
                                        box: (
                                            <DotLegalLink to={""} linkColor="primary">
                                                {undefined}
                                            </DotLegalLink>
                                        ),
                                    }}
                                />
                            }
                        />
                    )}
                </WhiteDashboardBox>
            </Box>
        </Widget>
    );
}

export default LatestAuditsSubmittedWidgetReport;
