import { useQuery } from "react-query";
import { get } from "../../common/api/apiShared";
import { UserSelectableItem } from "../User.types";

export function useResponsibleQuery(isEnabled: boolean = true) {
    var query = useQuery("responsibles", () => get<Array<UserSelectableItem>>("/users/responsibles"), {
        notifyOnChangePropsExclusions: ["isStale"],
        staleTime: 15000,
        enabled: isEnabled,
    });

    function getSelectableResponsibles(responsible: string | undefined | null) {
        return query.data?.filter((x) => x.active || x.id === responsible);
    }

    function getSelectableResponsiblesForMultiSelect(responsibles: Array<string>) {
        return query.data?.filter((x) => x.active || responsibles.includes(x.id));
    }

    return {
        isLoading: query.isLoading,
        data: getSelectableResponsibles,
        dataForMultiSelect: getSelectableResponsiblesForMultiSelect,
        userData: query.data,
    };
}
