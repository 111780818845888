import React from "react";
import Typography from "@mui/material/Typography";
import { Box, Skeleton } from "@mui/material";
import { useParams } from "react-router-dom";
import { DotLegalDialog, DotLegalMultiSelect } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import LegalEntityRiskQuestionnaire from "../legalEntityRiskQuestionnaire/LegalEntityRiskQuestionnaire";
import { LegalEntityRiskAssessment } from "../legalEntityRiskQuestionnaire/LegalEntityRiskQuestionnaire.types";
import { useLegalEntityRiskQuestionsHook } from "../legalEntityRiskQuestionnaire/LegalEntityRiskQuestions.hooks";
import { UseLegalEntityRiskAssessmentDialogHook } from "./LegalEntityRiskAssessmentDialog.hooks";
import { useStyles } from "./LegalEntityRiskAssessmentDialog.styles";

export interface ILegalEntityRiskAssessmentDialogProps {
    show: boolean;
    setShowDialog: (doShow: boolean) => void;
    riskAssessment: LegalEntityRiskAssessment | null;
    refetchEntity: () => void;
}

function LegalEntityRiskAssessmentDialog(props: ILegalEntityRiskAssessmentDialogProps) {
    const styles = useStyles();
    const { id } = useParams<{ id: string }>();
    const { translateString } = useTranslation();
    const { saveRiskAssessment, isSaving } = UseLegalEntityRiskAssessmentDialogHook(props);

    const { currentRiskAssessment, handleRiskAssessmentCalcChange, onRiskAssessmentChange, questions, riskAssessmentKeys, questionsLoading } =
        useLegalEntityRiskQuestionsHook({
            riskAssessment: props.riskAssessment,
            legalEntityId: id,
        });

    return (
        <DotLegalDialog
            header={translateString("calculateClassificationHeader")}
            buttonOkText={translateString("save")}
            open={props.show}
            onDialogClose={() => props.setShowDialog(false)}
            onOkClick={() => saveRiskAssessment(questions!, currentRiskAssessment)}
            inProgress={isSaving}
            disableBackdropOnCloseIfValueChange={currentRiskAssessment ?? {}}
        >
            {questionsLoading ? (
                <Skeleton></Skeleton>
            ) : (
                <Box sx={styles.selectContainer}>
                    <DotLegalMultiSelect
                        width={"200px"}
                        singleSelect
                        options={riskAssessmentKeys}
                        chosenOptions={currentRiskAssessment === null ? [] : [currentRiskAssessment!.toString()]}
                        onChange={(val) => onRiskAssessmentChange(val.length > 0 ? val.toString() : null)}
                        placeholder={translateString("riskAssessmentHeader")}
                        label={translateString("riskAssessmentHeader")}
                        noOptionsLabel={translateString("noOptions")}
                    />

                    <Typography sx={styles.subText}>{translateString("setRiskAssessmentOrCalculate")}</Typography>
                </Box>
            )}

            <LegalEntityRiskQuestionnaire
                enableClose={false}
                backgroundColor={"white"}
                onAnswerClick={(questionId, answer) => handleRiskAssessmentCalcChange(questionId, answer)}
                isLoading={questionsLoading}
                questions={questions!}
            />
        </DotLegalDialog>
    );
}

export default LegalEntityRiskAssessmentDialog;
