import React, { useState } from "react";
import { Box, useTheme } from "@mui/material";
import CountUp from "react-countup";
import Typography from "@mui/material/Typography";

export interface ILargeCountTextProps {
    count: number | undefined;
    isPdf?: boolean;
}

function LargeCountText(props: ILargeCountTextProps) {
    const [counterValue, setCounterValue] = useState(0);
    const theme = useTheme();
    const textStyles = {
        fontSize: props.isPdf ? theme.typography.pxToRem(20) : theme.typography.pxToRem(48),
        lineHeight: 1,
    };

    return (
        <Typography variant="h1" sx={textStyles}>
            {props.count && props.count > 0 && (
                <Box>
                    {counterValue != props.count ? <CountUp end={props.count} duration={1} onEnd={() => setCounterValue(props.count ?? 0)} /> : counterValue}
                </Box>
            )}
        </Typography>
    );
}

export default LargeCountText;
