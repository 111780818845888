import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { IToggleAuditRemindersDialog } from "./ToggleAuditRemindersDialog";
import { useMutation } from "react-query";
import { useTranslation } from "../../../localization/useTranslation";
import { put } from "../../../common/api/apiShared";

export default function useToogleAuditRemindersDialogHooks(props: IToggleAuditRemindersDialog) {
    const { translateString } = useTranslation();
    const snackbar = useDotLegalSnackbar();

    const pauseRemindersMutation = useMutation(pauseRemindersInternalAssessmentAPI, {
        onSuccess: () => {
            snackbar.show(translateString("remindersPaused"));
            props.onInternalAssessmentPaused();
        },
    });

    const resumeRemindersMutation = useMutation(resumeRemindersInternalAssessmentAPI, {
        onSuccess: () => {
            snackbar.show(translateString("remindersResumed"));
            props.onInternalAssessmentPaused();
        },
    });

    const onPauseClick = () => {
        if (props.remindersPaused) {
            resumeRemindersMutation.mutateAsync();
        } else {
            pauseRemindersMutation.mutateAsync();
        }
    };

    return { onPauseClick, isSaving: pauseRemindersMutation.isLoading || resumeRemindersMutation.isLoading };

    function pauseRemindersInternalAssessmentAPI() {
        return put(`/legalEntityAudit/${props.id}/pauseReminders`, null);
    }

    function resumeRemindersInternalAssessmentAPI() {
        return put(`/legalEntityAudit/${props.id}/resumeReminders`, null);
    }
}
