import React from "react";
import { useTranslation } from "../../localization/useTranslation";
import { useResendInvitationHook } from "./ResendInvitationDialog.hooks";
import { DotLegalInfoDialog } from "@dotlegal/dotlegal-ui-components";

export interface IResendInvitationDialogProps {
    userId: string;
    onCloseDialog: () => void;
}

function ResendInvitationDialog(props: IResendInvitationDialogProps) {
    const { translateString } = useTranslation();
    const { resendInvitationClick, isSaving } = useResendInvitationHook(props);

    return (
        <DotLegalInfoDialog
            header={translateString("resendActivationEmail")}
            btnText={translateString("send")}
            content={translateString("confirmResendInvitation")}
            inProgress={isSaving}
            onCloseDialog={() => props.onCloseDialog()}
            onBtnClick={() => resendInvitationClick()}
        />
    );
}

export default ResendInvitationDialog;
