import { IGdprIncidentDocumentTabProps } from "./GdprIncidentDocumentTab";
import { useQuery, useQueryClient } from "react-query";
import { useState } from "react";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import { useDotLegalFileUploadDataMapping } from "../../../common/components/dotLegalFileUploadTable/DotLegalFileUploadTable.hooks";
import { useTranslation } from "../../../localization/useTranslation";
import { get } from "../../../common/api/apiShared";
import { GdprIncidentDocument, GdprIncidentDocumentRowViewModel } from "./GdprIncidentDocument.types";
import { isNullOrWhitespace } from "../../../common/stringOperations";

export function useGdprIncidentDocuments(props: IGdprIncidentDocumentTabProps) {
    const queryClient = useQueryClient();

    const [selectedDocument, setSelectedDocument] = useState<GdprIncidentDocument>();
    const [showDocumentDialog, setShowDocumentDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const { permissions } = useUserContext();
    const { downloadDocumentVersion } = useDotLegalFileUploadDataMapping();
    const { translateString } = useTranslation();

    const url = `/gdprIncidentLogEntry/${props.incidentId}/Documents`;
    const { isLoading, data } = useQuery(url, () => get<Array<GdprIncidentDocumentRowViewModel>>(url));

    function refetchDocuments() {
        queryClient.refetchQueries(url);
    }

    function getMenuItems(document: GdprIncidentDocumentRowViewModel) {
        let menuItems = [];

        if (permissions.gdprIncidentLogPermissions.edit) {
            menuItems.push({
                menuItem: translateString("edit"),
                onClick: () => {
                    setSelectedDocument({
                        documentId: document.documentId,
                        name: document.name,
                        fileName: "",
                        link: document.link ?? "",
                    });
                    setShowDocumentDialog(true);
                },
            });
        }

        if (isNullOrWhitespace(document.fileName)) {
            menuItems.push({
                menuItem: translateString("openLink"),
                onClick: () => window.open(document.link!, "_blank"),
            });
        } else {
            menuItems.push({
                menuItem: translateString("download"),
                onClick: () => downloadDocumentVersion(document.documentId, document.dataId!, document.fileName!),
            });
        }

        if (permissions.companyDocumentationPermissions.delete) {
            menuItems.push({
                menuItem: translateString("delete"),
                onClick: () => {
                    setSelectedDocument({
                        documentId: document.documentId,
                        name: document.name,
                        fileName: "",
                        link: document.link ?? "",
                    });
                    setShowDeleteDialog(true);
                },
            });
        }

        return menuItems;
    }

    return {
        isLoading,
        data,
        hasDocuments: isLoading || data!.length > 0,
        refetchDocuments,
        getMenuItems,
        selectedDocument,
        setSelectedDocument,
        showDocumentDialog,
        setShowDocumentDialog,
        showDeleteDialog,
        setShowDeleteDialog,
    };
}
