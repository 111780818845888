import { useState } from "react";

export function useSharingsInfoBoxHook(sDescription: string) {
    const [showDescription, setShowDescription] = useState(false);
    const [description, setDescription] = useState(sDescription);

    return {
        showDescription,
        setShowDescription,
        description,
        setDescription,
    };
}
