import { SxProps, Theme, useTheme } from "@mui/material";
import { createSxStyles } from "../../createSxStyles";
import { IDotLegalTabs } from "./DotLegalTabs";

export type dotLegalTabStyles = "primary" | "secondary" | "paper-tabs";

export const useDotLegalTabsStyles = (props: IDotLegalTabs) => {
    const theme = useTheme();

    return createSxStyles({
        selectedTab: { ...getStyle(theme, props.ignoreTextTransformation, props.tabStyle) },
    });
};

function getStyle(theme: Theme, ignoreTextTransformation: boolean | undefined, style: dotLegalTabStyles): SxProps<Theme> {
    if (style === "secondary") {
        return getSecondaryStyling(theme, ignoreTextTransformation);
    } else if (style === "paper-tabs") {
        return getPaperTabStyling(theme, ignoreTextTransformation);
    } else {
        return getPrimaryStyling(theme, ignoreTextTransformation, style);
    }
}

function getPrimaryStyling(theme: Theme, ignoreTextTransformation: boolean | undefined, style: dotLegalTabStyles): SxProps<Theme> {
    const isPrimary = style === "primary";
    return {
        "& .MuiTab-textColorPrimary": {
            borderBottom: "1px solid " + theme.palette.primary.light,
            textTransform: ignoreTextTransformation ? "inherit" : "capitalize",
        },
        "& .MuiTab-textColorPrimary.topLeft": {
            borderTopLeftRadius: 20 + "px",
            borderRight: "1px solid " + theme.palette.primary.light,
        },
        "& .MuiTab-textColorPrimary.topRight": {
            borderTopRightRadius: 20 + "px",
            borderLeft: "1px solid " + theme.palette.primary.light,
        },
        "& .MuiTab-textColorPrimary.Mui-selected": {
            backgroundColor: theme.palette.primary.light,
            outline: "2px solid " + theme.palette.primary.main,
            outlineOffset: "-2px",
            color: isPrimary ? theme.palette.primary.main : theme.palette.primary.dark,
        },
        "& .MuiTab-root": {
            fontWeight: theme.typography.fontWeightBold,
            color: isPrimary ? theme.palette.primary.main : theme.palette.grey[500],
            flexDirection: "row-reverse",
            minWidth: isPrimary ? "unset" : 115,
            backgroundColor: isPrimary ? "unset" : theme.customPalette.white,
        },
        "& .MuiTabs-indicator": {
            display: "none",
        },
        "& .MuiTouchRipple-root": {
            color: theme.palette.primary.main,
        },
    };
}

function getSecondaryStyling(theme: Theme, ignoreTextTransformation: boolean | undefined): SxProps<Theme> {
    return {
        "& .MuiTab-textColorPrimary": {
            textTransform: ignoreTextTransformation ? "inherit" : "capitalize",
            width: 200 + "px",
        },
        "& .Mui-disabled": {
            pointerEvents: "auto",
        },
        "& .MuiTab-root": {
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.grey[500],
            "&.Mui-selected": {
                color: theme.palette.primary.dark,
            },
            flexDirection: "row-reverse",
            "& svg": {
                marginBottom: "3px !important",
            },
        },
        "& .MuiTouchRipple-root": {
            color: theme.palette.primary.main,
        },
    };
}

function getPaperTabStyling(theme: Theme, ignoreTextTransformation: boolean | undefined) {
    return {
        ...getPrimaryStyling(theme, ignoreTextTransformation, "paper-tabs"),
        "& .MuiTabs-flexContainer": {
            boxShadow: "0px 3px 10px #E7E7E7",
            borderRadius: 20,
            display: "flex", // Helps placing boxshadow
        },
        "& .MuiTabs-scroller": {
            display: "flex", // Helps placing boxshadow
        },
    };
}
