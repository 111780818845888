import { DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import { Box, keyframes, useMediaQuery, useTheme } from "@mui/material";
import React, { ReactNode } from "react";
import Buildings from "./svgs/buildings.svg?react";
import Globe from "./svgs/globe.svg?react";
import Calender from "./svgs/calender.svg?react";
import Screen from "./svgs/screen.svg?react";
import purpleWave from "./svgs/purpleWave.svg?url";
import pinkWave from "./svgs/pinkWave.svg?url";
import violetWave from "./svgs/violetWave.svg?url";
import darkblueWave from "./svgs/darkblueWave.svg?url";
import DotLegalLink from "../common/components/dotLegalLink/DotLegalLink.tsx";
import NavigateTo from "./navigateTo/NavigateTo.tsx";
import { isNullOrWhitespace } from "../common/stringOperations.ts";

export interface DashboardCountCardProps {
    header: string;
    children?: React.ReactNode;
    url?: { url: string; text: string; allMyPageKey?: string; onlyUserSpecific?: boolean };
    background?: "purple" | "pink" | "darkblue" | "violet";
    icon?: "globe" | "screen" | "building" | "calender";
    isLoading?: boolean;
    topRightElement?: ReactNode;
    isPdf?: boolean;
}

function DashboardCountCard(props: DashboardCountCardProps) {
    const theme = useTheme();
    const tallScreen = useMediaQuery("(min-height:1100px)");

    const shouldHaveDarkText = props.background && (props.background === "violet" || props.background === "pink");
    const textColor = shouldHaveDarkText ? theme.palette.primary.dark : theme.customPalette.white;

    const commonStyle = { height: tallScreen ? 250 : 180, width: "100%", borderRadius: "20px", ...getBackground() };

    const pulseKeyframe = keyframes`
        0% {
            opacity: 1;
        }

        50% {
            opacity: 0.4;
        }

        100% {
            opacity: 1;
        }
    `;
    const cardElement = (
        <Box
            p={tallScreen ? 4 : 2}
            pb={tallScreen ? 2 : 2}
            sx={{
                ...commonStyle,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                color: textColor,
                transition: "all .2s ease-in-out",
                "&:hover": {
                    transform: "scale(1.03)",
                    cursor: "pointer",
                },
                animation: props.isLoading ? `${pulseKeyframe} 2s ease-in-out 0.5s infinite` : undefined,
            }}
        >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ wordBreak: "break-all" }}>
                    <DotLegalHeader color={shouldHaveDarkText ? "primary" : "white"} headerStyle={"medium"} marginBottom={1}>
                        {props.header.toUpperCase()}
                    </DotLegalHeader>

                    <Box>{props.children}</Box>
                </Box>
                {props.icon && getIcon()}
                {props.topRightElement && props.topRightElement}
            </Box>

            {!props.isPdf && props.url && <NavigateTo onlyText color={shouldHaveDarkText ? "darkblue" : "white"} to={props.url.url} text={props.url.text} />}
        </Box>
    );

    return (
        <>
            {props.url ? (
                <DotLegalLink to={props.url.url} noUnderlineOnHover>
                    <Box
                        onClick={() => {
                            if (!isNullOrWhitespace(props.url?.allMyPageKey) && props.url!.onlyUserSpecific !== undefined) {
                                sessionStorage.setItem(props.url!.allMyPageKey!, props.url!.onlyUserSpecific!.toString());
                            }
                        }}
                    >
                        {cardElement}
                    </Box>
                </DotLegalLink>
            ) : (
                cardElement
            )}
        </>
    );

    function getBackground() {
        let backgroundImage = purpleWave;
        let backgroundColor = theme.palette.primary.main;

        switch (props.background) {
            case "pink": {
                backgroundImage = pinkWave;
                backgroundColor = "#F3BAED";
                break;
            }
            case "darkblue": {
                backgroundImage = darkblueWave;
                backgroundColor = theme.palette.primary.dark;
                break;
            }
            case "violet": {
                backgroundImage = violetWave;
                backgroundColor = "#D9BCFF";
                break;
            }
            default: {
                backgroundImage = purpleWave;
                backgroundColor = theme.palette.primary.main;
                break;
            }
        }

        return {
            backgroundImage: `url("${backgroundImage}")`,
            backgroundSize: "100% 55%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "100% 120%",
            backgroundColor: backgroundColor,
        };
    }

    function getIcon() {
        switch (props.icon) {
            case "screen":
                return <Screen />;
            case "calender":
                return <Calender />;
            case "building":
                return <Buildings />;
            default:
                return <Globe />;
        }
    }
}

export default DashboardCountCard;
