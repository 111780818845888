import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { deleteHttp, get, post, put } from "../../common/api/apiShared";
import { useOptimisticUpdate } from "../../common/hooks/useOptimisticUpdate";
import { ISubTasksProps } from "./SubTasks";
import { SubTasksViewModel, SubTaskViewModel } from "./SubTasks.types";
import { isNullOrWhitespace } from "../../common/stringOperations";

export function useSubTasksHook(props: ISubTasksProps) {
    const optimisticUpdate = useOptimisticUpdate();
    const url = `/subtask/${props.taskId}`;
    const queryKey = "subtask" + props.taskId;

    const [hoveredSubTask, setHoveredSubTask] = useState("");
    const [focusOnLastSubTask, setFocusOnLastSubTask] = useState(false);

    const { isLoading, data, isSuccess } = useQuery(queryKey, () => get<SubTasksViewModel>(url));
    const [videoUrl, setVideoUrl] = useState("");

    useEffect(() => {
        if (props.passIsDoneLoading && isSuccess) {
            props.passIsDoneLoading(true);
        }
    }, [isSuccess, props]);

    useEffect(() => {
        if (focusOnLastSubTask) {
            const elementToFocus = document.getElementById(data!.subTasks[data!.subTasks.length - 1].id);
            elementToFocus?.focus();
            setFocusOnLastSubTask(false);
        }
    }, [data, focusOnLastSubTask]);

    useEffect(() => {
        if (props.shouldAddTask) {
            addSubTask();
            if (props.onSuccessAddTask) {
                props.onSuccessAddTask();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.shouldAddTask]);

    async function addSubTask() {
        if (data) {
            if (data.subTasks.length === 0 || !isNullOrWhitespace(data.subTasks[data.subTasks.length - 1].description)) {
                await optimisticUpdate.putOnQueueAndSetQueryData(data, queryKey, add, undefined);
            }
        }

        setFocusOnLastSubTask(true);
    }

    async function removeSubTask(id: string) {
        let tempData = { ...data };
        tempData.subTasks = data!.subTasks!.filter((d) => d.id !== id);
        await optimisticUpdate.putOnQueueAndSetQueryData(tempData, queryKey, remove, id);
    }

    async function onSubTaskChange(taskViewModels: SubTasksViewModel, taskViewModel: SubTaskViewModel) {
        await optimisticUpdate.putOnQueueAndSetQueryData(taskViewModels, queryKey, updateSubTask, taskViewModel);
    }

    return {
        isLoading,
        data,
        onSubTaskChange,
        removeSubTask,
        hoveredSubTask,
        setHoveredSubTask,
        addSubTask,
        videoUrl,
        setVideoUrl,
    };

    async function updateSubTask(viewModel: SubTaskViewModel) {
        return await put<Array<SubTaskViewModel>>("/subtask/" + props.taskId + "/" + viewModel.id, convertToSaveModel(viewModel));
    }

    async function remove(id: string) {
        return await deleteHttp<Array<SubTaskViewModel>>(`/subtask/${props.taskId}/${id}`);
    }

    async function add() {
        return post<Array<SubTaskViewModel>>("/subtask/" + props.taskId, null);
    }

    function convertToSaveModel(viewModel: SubTaskViewModel) {
        return {
            id: viewModel.id,
            description: viewModel.description,
            isComplete: viewModel.isComplete,
        };
    }
}
