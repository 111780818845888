import { useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";

export const useCreateEditGdprIncidentStyles = () => {
    const theme = useTheme();

    return createSxStyles({
        container: {
            paddingLeft: theme.spacing(7.5),
            paddingRight: theme.spacing(7.5),
        },
        subContainer: {
            width: "100%",
            marginBottom: theme.spacing(3),
        },
    });
};
