import { createElement } from "react";
import { Trans } from "react-i18next";
import { useMutation } from "react-query";
import { put } from "../../../common/api/apiShared";
import { Result } from "../../../common/api/result";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { IChangeNationalLawStatusDialog } from "./ChangeNationalLawStatusDialog";
import { NationalLawViewModel } from "../nationalLaws/NationalLaws.types";

export function useChangeNationalLawStatusDialogDataMapping(props: IChangeNationalLawStatusDialog) {
    const snackbar = useDotLegalSnackbar();

    const changeActiveStatusMutation = useMutation(changeStatus);

    const changeActiveStatus = (nationalLaw: NationalLawViewModel) => {
        changeActiveStatusMutation.mutateAsync(nationalLaw, {
            onSuccess: (response: Result<string>) => {
                props.onCloseDialog();
                if (response.success()) {
                    snackbar.show(createElement(Trans, { i18nKey: "activeStatusChanged", values: { name: nationalLaw.name } }));
                    props.onStatusChange();
                } else {
                    snackbar.show(createElement(Trans, { i18nKey: "nationalLawStatusNotChanged", values: { name: nationalLaw.name } }), "warning");
                }
            },
        });
    };

    function changeStatus(nationalLaw: NationalLawViewModel) {
        return put<string>(`/nationallaws/${nationalLaw.id}/changeActiveStatus`, !nationalLaw.active);
    }

    return {
        changeActiveStatus,
        inProgress: changeActiveStatusMutation.isLoading,
    };
}
