import { useQuery } from "react-query";
import { get } from "../../common/api/apiShared";
import { LegalEntitySelectorViewModel } from "../LegalEntity.types";
import { useEffect } from "react";
import { IGroupEntityAcceesSelector } from "./GroupEntityAccessSelector";

export function useGroupEntityAccessSelectorHooks(props: IGroupEntityAcceesSelector) {
    const groupEntitiesQuery = useQuery("groupentities", () => get<Array<LegalEntitySelectorViewModel>>("/LegalEntity/groupentities"));

    let selectableGroupCompaniesData = groupEntitiesQuery.data;
    let selectableGroupCompaniesLoading = groupEntitiesQuery.isLoading;

    useEffect(() => {
        if (!props.isEdit && selectableGroupCompaniesData && selectableGroupCompaniesData.length === 1) {
            props.onGroupEntityAccessIdsChange(selectableGroupCompaniesData.map((x) => x.id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectableGroupCompaniesData, props.isEdit]);

    return {
        selectableGroupCompaniesData,
        selectableGroupCompaniesLoading,
    };
}
