import React from "react";
import { Trans } from "react-i18next";
import { useTranslation } from "../../../localization/useTranslation";
import { DataCategorySaveModel } from "../dataCategories/DataCategories.types";
import { useChangeDataCategoryDialogHooks } from "./ChangeDataCategoryStatusDialog.hooks";
import { DotLegalInfoDialog } from "@dotlegal/dotlegal-ui-components";

export interface IChangeDataCategoryStatusDialog {
    selectedDataCategory: DataCategorySaveModel;
    onCloseDialog: () => void;
    refetch: () => void;
}

export function ChangeDataCategoryStatusDialog(props: IChangeDataCategoryStatusDialog) {
    const { changeActiveStatus, inProgress } = useChangeDataCategoryDialogHooks(props);
    const { translateString } = useTranslation();

    return (
        <DotLegalInfoDialog
            btnText={props.selectedDataCategory.active ? translateString("deactivate") : translateString("activate")}
            header={props.selectedDataCategory.active ? translateString("deactivateDataCategoryHeader") : translateString("activateDataCategoryHeader")}
            inProgress={inProgress}
            content={
                props.selectedDataCategory.active ? (
                    <Trans i18nKey="deactivateDataInfo" values={{ name: props.selectedDataCategory.name }}></Trans>
                ) : (
                    <Trans i18nKey="activateDataInfo" values={{ name: props.selectedDataCategory.name }}></Trans>
                )
            }
            onBtnClick={() => changeActiveStatus(props.selectedDataCategory)}
            onCloseDialog={props.onCloseDialog}
        />
    );
}
