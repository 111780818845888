import { DataCategoryDataTypes, SelectableDataCategoryItem } from "../ProcessingActivity.types.ts";

export function mapToDataCategorySelectorModel(
    allDataCategories: Array<SelectableDataCategoryItem>,
    parentDataCategories: Array<DataCategoryDataTypes>,
    dataCategoriesOnShare: Array<DataCategoryDataTypes>
) {
    return allDataCategories!
        .filter((dataCategory) => parentDataCategories.some((paD) => paD.dataCategoryId === dataCategory.id))
        .map((dataCategory) => {
            const paDataCategory = parentDataCategories.find((paD) => paD.dataCategoryId === dataCategory.id);
            return {
                name: dataCategory.name,
                id: dataCategory.id,
                description: dataCategory.description,
                classification: dataCategory.classification,
                isSelected: dataCategoriesOnShare.some((dc) => dc.dataCategoryId === dataCategory.id),
                dataTypeIds: paDataCategory?.dataTypeIds ?? [],
                selectedDataTypeIds: dataCategoriesOnShare.find((dc) => dc.dataCategoryId === dataCategory.id)?.dataTypeIds ?? [],
            };
        });
}
